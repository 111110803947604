import { Injectable } from '@angular/core';
import { ApiService } from 'ui-elements';
import { Observable } from 'rxjs';
import { IResponse } from '@src/app/models/response.model';
import { API_URLS_APP } from '@src/app/constants/api-urls.constant';

@Injectable({
  providedIn: 'root'
})
export class EmailConfirmationPageService {

  constructor(private apiService: ApiService) { }

  public confirmEmail(token: string): Observable<TEmailConfirmationResponse> {
    return this.apiService.post<TEmailConfirmationResponse>(API_URLS_APP.EMAIL_CONFIRMATION, { token });
  }

  confirmEmailUpdate(token: string) {
    return this.apiService.post<TEmailConfirmationResponse>(API_URLS_APP.EMAIL_CONFIRMATION_UPDATE, { token });
  }
}

type TEmailConfirmationResponse = IResponse<{ authToken: string }>;
