import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-bubble-counter',
  templateUrl: './bubble-counter.component.html',
  styleUrls: ['./bubble-counter.component.sass']
})
export class BubbleCounterComponent implements OnInit {

  @Input() badgeCounter: number;
  @Input() isNew = false;
  @Input() isHome = false;
  @Input() isMessages = false;
  @Input() public style;
  constructor() { }

  ngOnInit() {
  }

}
