<div class="box-calculate box-lg">
    <div class="box-header">
        <h2 class="inverse text-center mb-15"><b>{{'landing-page.calculator.title' | localize}}</b></h2>
        <p class="text-center pt-0 mb-30">{{'landing-page.calculator.desc' | localize}}</p>
    </div>
    <div class="table-responsive" *ngIf="isLoaded">
        <table class="table table-calculate table-material table-lg">
            <thead>
            <tr>
                <th colspan="3">
                    <div class="content-flex">
                        {{'landing-page.calculator.table.title' | localize}}
                        <div class="input-group mb-0">
                            <input
                                    #input
                                    cleaveMask
                                    [cleaveValue]="input.value"
                                    (input)="onPriceChange()"
                                    class="form-control fix-rounded-left input-currency" type="text"
                                    placeholder="Ticketpreis (Brutto)"/>
                            <div class="dropdown currency">
                                <ano-dropdown *ngIf="currency"
                                              [values]="currency"
                                              [isEditMode]="true"
                                              [value]="selectedCurrency.value"
                                              (selectedValue)="onSelectedCurrency($event)">
                                </ano-dropdown>
                            </div>
                        </div>
                    </div>
                </th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td class="td-dropdown">
                    <div class="content-flex">
                        <b>{{'landing-page.calculator.table.field1' | localize}}</b>
                        <ano-dropdown *ngIf="vatZones"
                                      [values]="vatZones"
                                      [isEditMode]="true"
                                      [value]="selectedZoneCode.value"
                                      (selectedValue)="onSelectedZone($event)"></ano-dropdown>
                    </div>
                </td>
                <td>{{percentVat}}%</td>
                <td class="align-right">{{ {currency: 'EUR', amount: vatValue} | currency: false}} {{selectedCurrency.value}}</td>
            </tr>
            <tr>
                <td class="td-dropdown">
                    <div class="content-flex">
                        <b>{{'landing-page.calculator.table.field2' | localize}}</b>
                        <ano-dropdown *ngIf="plans"
                                      [values]="plans"
                                      [isEditMode]="true"
                                      [value]="selectedPlan.value"
                                      (selectedValue)="onSelectedPlan($event)"></ano-dropdown>
                    </div>
                </td>
                <td>{{percentStreamdustFee}}% + {{coefficientStreamdustFee}} {{selectedCurrency.value}}</td>
                <td class="align-right">{{ {currency: 'EUR', amount: sdProvision} | currency: false}} {{selectedCurrency.value}}</td>
            </tr>
            <tr>
                <td><b>{{'landing-page.ticket-price-calculator-table.payment-fee' | localize}}</b></td>
                <td>{{percentTransactionFee}}% + {{coefficientTransactionFee}} {{selectedCurrency.value}}</td>
                <td class="align-right">{{ {currency: 'EUR', amount: feeValue} | currency: false}} {{selectedCurrency.value}}</td>
            </tr>
            </tbody>
            <tfoot>
            <tr>
                <td colspan="2">{{'landing-page.ticket-price-calculator-table.withdrawal-amount' | localize}}</td>
                <td class="align-right"><b>{{ {currency: 'EUR', amount: total} | currency: false}} {{selectedCurrency.value}}</b></td>
            </tr>
            </tfoot>
        </table>
    </div>
    <P class="vat-warning">{{'landing-page.ticket-price-calculator-table.vat-warning' | localize}}</P>
</div>
