import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ITableConfig} from 'ui-elements';
import {HttpClient} from '@angular/common/http';
import {IPagedResponse} from '@src/app/models/response.model';

@Component({
  selector: 'app-test-page',
  templateUrl: './test-page.component.html',
  styleUrls: ['./test-page.component.css']
})
export class TestPageComponent implements OnInit {
  @ViewChild('actionsTmpl', {static: true}) public actionsTmpl: TemplateRef<any>;
  @ViewChild('priceTmpl', {static: true}) public priceTmpl: TemplateRef<any>;
  @ViewChild('statusTmpl', {static: true}) public statusTmpl: TemplateRef<any>;
  @ViewChild('dateTmpl', {static: true}) public dateTmpl: TemplateRef<any>;
  public config: ITableConfig<any>;

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    this.config = {
      filtersWidth100: true,
      customScroll: true,
      matPaginator: true,
      dataField: 'data',
      searchFn: (sortParams, pagingParams) => {
        return this.http.get<IPagedResponse<any>>('assets/mock-json/bills.mock.json');
      },
      columns: [
        {
          name: 'Status',
          tmpl: this.statusTmpl,
          sortField: 'STATUS'
        },
        {
          name: 'Titel',
          dataField: 'title'
        },
        {
          name: 'Price',
          tmpl: this.priceTmpl,
          class: 'price',
          sortField: 'PRICE'
        },
        {
          name: 'Amount',
          dataField: 'amount',
          class: 'price',
          sortField: 'SOLD_AMOUNT'
        },
        {
          name: 'Type',
          dataField: 'type',
          class: 'price',
          sortField: 'INCOME_AMOUNT'
        },
        {
          name: 'Pay out date',
          tmpl: this.dateTmpl
        },
        {
          name: 'ID',
          dataField: 'id',
        },
        {
          name: 'Actions',
          tmpl: this.actionsTmpl
        }
      ]

    };
  }

}
