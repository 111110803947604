import {AfterViewInit, Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {LoadingService} from "@src/app/services/loading/loading.service";
import {SubscriptionManageService} from "@src/app/services/subscription-manage/subscription-manage.service";
import {ITableConfig, TableComponent} from  'ui-elements';
import {
    ITariffPlanConfig,
    TARIFF_PLAN_TYPES
} from "@src/app/components/tariff-plan-cards-list/tariff-plan-cards-list.component";
import {
    ISubscriptionTariffPlan, ISubscriptionTariffPlans,
    TARIFF_PLAN_PERIOD
} from '@src/app/components/tariff-plan-cards-list/subscription-plan-card/subscription-plan-card.component';
import {PaymentService} from "@src/app/services/payment/payment.service";
import {ActivatedRoute, Router} from "@angular/router";
import {flatMap, take, takeUntil} from 'rxjs/operators';
import {AutoDestroyService} from "@src/app/services/auto-destroy-service/auto-destroy.service";
import {LocalizationProvider} from 'ui-elements';
import {of} from "rxjs";
import {MatDialog} from "@angular/material/dialog";
import {SubscriptionTariffsComparisonComponent} from "@src/app/pages/user-profile/components/subscription-manage/subscription-tariffs-comparison/subscription-tariffs-comparison.component";
import {DateHelper} from '@src/app/utils/date.helper';
import {UserService} from '@src/app/services/user/user.service';

@Component({
    selector: 'app-manage-subscription',
    templateUrl: './subscription-manage.component.html',
    styleUrls: ['./subscription-manage.component.sass'],
    providers: [AutoDestroyService]
})
export class SubscriptionManageComponent implements OnInit {

    @ViewChild('actionsTmpl', {static: true}) public actionsTmpl: TemplateRef<any>;
    @ViewChild('subscriptionPaymentTmpl', {static: true}) public subscriptionPaymentTmpl: TemplateRef<any>;
    @ViewChild('nextPaymentTmpl', {static: true}) public nextPaymentTmpl: TemplateRef<any>;
    @ViewChild('tariffPlanTmpl', {static: true}) public tariffPlanTmpl: TemplateRef<any>;
    @ViewChild('dateTmpl', {static: true}) public dateTmpl: TemplateRef<any>;
    @ViewChild('subscriptionsTable', {static: true}) public subscriptionsTable: TableComponent;
    @ViewChild('confirm', {static: false}) confirm: TemplateRef<any>;

    tableConfig: ITableConfig<ICurrentSubscriptionTable>;

    tariffPlansConfig: ITariffPlanConfig = {
        planType: TARIFF_PLAN_TYPES.SUBSCRIPTION,
        planPeriod: TARIFF_PLAN_PERIOD.MONTH,
        showPeriodToggle: true
    };

    confirmTextTitle: string = 'subscription-manage.cancel-subscription-confirm.title';
    confirmTextBody: string = 'subscription-manage.cancel-subscription-confirm.body';

    subscriptionTariffPlans: ISubscriptionTariffPlans;

    payment = false;

    queryParams: any;

    constructor(
        public loadingService: LoadingService,
        private subscriptionManageService: SubscriptionManageService,
        private paymentService: PaymentService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private destroy$: AutoDestroyService,
        private localizationProvider: LocalizationProvider,
        private dialog: MatDialog,
        private userService: UserService,
    ) {
    }

    ngOnInit(): void {
        this.loadingService.loadingStart();
        this.tableConfig = {
            dataField: 'data',
            matPaginator: true,
            searchFn: (sortParams, pagingParams) => this.subscriptionManageService.getCurrentSubscription(null, pagingParams, sortParams).pipe(flatMap(subscriptionData => {
                subscriptionData.results.data.items = subscriptionData.results.data.items.map(subscription => {
                    subscription.tariffPlan = this.localize(this.subscriptionManageService.getSubscriptionTypeTranslate(subscription.type)) + ' '
                        + this.localize(this.subscriptionManageService.getSubscriptionPlanTypeTranslate(subscription.planType));
                    subscription.period = this.localize(this.subscriptionManageService.getSubscriptionPeriodTranslate(subscription.period));
                    return subscription;
                });
                return of(subscriptionData);
            })),
            columns: [
                {
                    name: 'subscription-manage.table.headers.current-subscriptions',
                    tmpl: this.tariffPlanTmpl
                    // dataField: 'tariffPlan',
                },
                {
                    name: 'subscription-manage.table.headers.payment-method',
                    tmpl: this.subscriptionPaymentTmpl
                },
                {
                    name: 'subscription-manage.table.headers.next-payment',
                    tmpl: this.nextPaymentTmpl,
                },
                {
                    name: 'subscription-manage.table.headers.expiry-date',
                    // date: true,
                    // dataField: 'expireDate'
                    tmpl: this.dateTmpl
                },
                {
                    name: 'streams.title.table.column.action',
                    tmpl: this.actionsTmpl,
                    class: 'actions'
                }
            ]
        };
        this.tableConfig.columns.find(column => column.name === 'streams.title.table.column.action').tmpl = this.actionsTmpl;
        this.tableConfig.columns.find(column => column.name === 'subscription-manage.table.headers.payment-method').tmpl = this.subscriptionPaymentTmpl;
        this.tableConfig.columns.find(column => column.name === 'subscription-manage.table.headers.next-payment').tmpl = this.nextPaymentTmpl;
        this.tableConfig.columns.find(column => column.name === 'subscription-manage.table.headers.current-subscriptions').tmpl = this.tariffPlanTmpl;
        this.tableConfig.columns.find(column => column.name === 'subscription-manage.table.headers.expiry-date').tmpl = this.dateTmpl;
        this.getSubscriptionTariffs();
    }

    getSubscriptionTariffs(): void {
        this.subscriptionManageService.getSubscriptionTariffs().pipe(take(1)).subscribe(data => {
            this.subscriptionTariffPlans = Object.keys(data.data?.tariffPlans)?.length ? data.data.tariffPlans : null;
            this.loadingService.loadingEnd();
        });
    }

    openSubscriptionTariffsComparisonDialog(): void {
        this.userService.userProfile$
            .pipe(takeUntil(this.destroy$))
            .subscribe((userProfile) => {
                if (!userProfile) {
                    return;
                }
                this.dialog.open(SubscriptionTariffsComparisonComponent, {
                    panelClass: 'subscription-tariff-comparison-modal',
                    hasBackdrop: true,
                    data: {userProfile},
                });
            });
    }

    buySubscription(subscription: ISubscriptionTariffPlan): void {
        this.router.navigate(['subscription/upgrade', subscription.id, this.tariffPlansConfig.planPeriod]);
    }

    confirmSubscriptionCancel(subscription): void {
        const confirmTextTitle = 'subscription-manage.cancel-subscription-confirm.title';
        const confirmTextBody = 'subscription-manage.cancel-subscription-confirm.body';
        const cardActionFunction = this.subscriptionManageService.subscriptionCancel(subscription.id);
        this.confirmSubscriptionAction(confirmTextTitle, confirmTextBody, cardActionFunction);
    }

    confirmSubscriptionRenew(subscription): void {
        const confirmTextTitle = 'subscription-manage.renew-subscription-confirm.title';
        const confirmTextBody = 'subscription-manage.renew-subscription-confirm.body';
        const subscriptionFunction = this.subscriptionManageService.subscriptionRenew(subscription.id);
        this.confirmSubscriptionAction(confirmTextTitle, confirmTextBody, subscriptionFunction);
    }

    confirmSubscriptionAction(confirmTitle, confirmBody, subscriptionFunction): void {
        this.confirmTextTitle = confirmTitle;
        this.confirmTextBody = confirmBody;
        const dialogRef = this.dialog.open(this.confirm);
        dialogRef.afterClosed()
            .pipe(takeUntil(this.destroy$))
            .subscribe((confirm: boolean) => {
                if (confirm) {
                    subscriptionFunction.pipe(takeUntil(this.destroy$)).subscribe(success => {
                        if (success) {
                            this.refreshSubscriptionTable();
                        }
                        this.loadingService.loadingEnd();
                    });
                }
            });
    }

    refreshSubscriptionTable() {
        this.subscriptionsTable.resetTableData();
        this.subscriptionsTable.refreshData({});
        this.getSubscriptionTariffs();
    }

    localize(key: string): string {
        if (!key) {
            return '';
        }
        return this.localizationProvider.getByKey(key);
    }

    public formatDate(timestamp: bigint): string {
        return DateHelper.formatDateTime(timestamp, 'DD MMM YYYY, HH:mm');
    }

}

interface ICurrentSubscriptionTable {
    name: string;
    paymentMethod: string;
    nextPayment: number;
    expiryDate: number;
}
