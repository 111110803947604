import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {IResponse} from '@src/app/models/response.model';
import {INPUT_TYPES} from 'ui-elements';
import {ApiService} from 'ui-elements';
import {API_URLS_APP} from '@src/app/constants/api-urls.constant';
import {pluck} from 'rxjs/internal/operators';
import {IFieldsConfig} from '@src/app/pages/user-profile/interfaces/interfaces-common';

@Injectable()
export class WithdrAndConditionsService {

    constructor(private apiService: ApiService) {}
    public getFieldsConfig(): IFieldsConfig[] {
        return [
            {
                name: 'withdrawal',
                config: {
                    inputType: INPUT_TYPES.TEXTAREA,
                    label: 'profile.termsAndConditions.rights',
                    placeholder: '',
                    isRequired: true
                }
            },
            {
                name: 'termsConditionUrl',
                config: {
                    inputType: INPUT_TYPES.INPUT,
                    label: 'profile.termsAndConditions.link',
                    placeholder: '',
                    isRequired: false,
                    isLink: true
                }
            },
            {
                name: 'privacyPolicyUrl',
                config: {
                    inputType: INPUT_TYPES.INPUT,
                    label: 'profile.privacyPolicy.link',
                    placeholder: '',
                    isRequired: false,
                    isLink: true
                }
            }
        ];
    }
    public getUserAGB(): Observable<IUserAGB> {
        return this.apiService.get(API_URLS_APP.USER_AGB).pipe(
            pluck('results', 'data')
        );
    }
    public saveUserAGB(newAGB: IUserAGB): Observable<IResponse> {
        return this.apiService.post(API_URLS_APP.USER_AGB, newAGB)
            .pipe(
                pluck('results')
            );
    }
}
export interface IUserAGB {
    withdrawal: string;
    termsConditionUrl: string;
    privacyPolicyUrl: string;
}
