import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import {ActivatedRoute, NavigationEnd, Params, Router} from '@angular/router';
import {filter, mergeMap, take, takeUntil, tap} from 'rxjs/operators';
import {NEW_ID_PARAM} from '@src/app/utils/custom-validators.util';
import {ISideNavConfig} from '@src/app/components/side-nav/side-nav.component';
import {SideNavConfigModel} from '@src/app/models/side-nav-config.model';
import {NavService} from '@src/app/services/nav/nav.service';
import {StreamService} from '@src/app/services/stream-metadata/stream.service';
import {IStreamBroadcast} from '@src/app/models/response.model';
import {MatDialog} from '@angular/material/dialog';
import {VIDEO_TYPE} from '@src/app/models/core.model';
import {STREAM_STATUS} from '@src/app/components/streamdust-player/constants/status';
import {AuthService} from '@src/app/services/auth/auth.service';
import {ChatState} from '@src/app/state/chat-state';
import {MEDIA_TYPE} from '@src/app/components/streamdust-player/constants/mediaType';
import {BehaviorSubject} from 'rxjs';
import {CHAT_STATUS, ChatService} from '@src/app/services/chat/chat.service';
import {SurveyService} from '@src/app/services/survey/survey.service';
import {UserService} from '@src/app/services/user/user.service';
import {InfoPanelsConfig} from '@src/app/components/info-panel/info-panel.component';
import {scrollToTop} from '@src/app/utils/scroll.util';
import {USER_TYPE} from '@src/app/constants/user-type.constant';
import {IStreamModel} from '@src/app/models/stream.model';

@Component({
    selector: 'app-stream-page',
    templateUrl: './stream-page.component.html',
    styleUrls: ['./stream-page.component.sass'],
    providers: [AutoDestroyService, ChatState, ChatService, SurveyService],
})
export class StreamPageComponent implements OnInit, OnDestroy {
    public InfoPanelsConfig = InfoPanelsConfig;
    public mediaType: MEDIA_TYPE;
    public isCreateMode = false;
    public streamTitle: string;
    public mediaId: string;
    public streamBroadcastSettings: IStreamBroadcast;
    public sideNavConfig: ISideNavConfig;
    public STREAM_STATUS = STREAM_STATUS;
    @ViewChild('encoderModal', {static: false}) encoderModal: TemplateRef<any>;
    public chatEnabled: boolean;
    public premiumPublisher: boolean;
    private userType: USER_TYPE;
    public isLoading: boolean;
    public stream: IStreamModel;

    constructor(
        private readonly destroy$: AutoDestroyService,
        private readonly activatedRoute: ActivatedRoute,
        private router: Router,
        private navService: NavService,
        public streamService: StreamService,
        public dialog: MatDialog,
        public authService: AuthService,
        public chatState: ChatState,
        public chatService: ChatService,
        private userService: UserService
    ) {
    }

    ngOnInit() {
        this.loadData();
        this.router.events
            .pipe(takeUntil(this.destroy$))
            .subscribe((e: any) => {

                if (e instanceof NavigationEnd && (e.url.includes('info') || e.url.includes('statistic'))) {
                    if (this.mediaType === MEDIA_TYPE.STREAM) {
                        this.streamService.closeStreamStatusWebsocket();
                    }
                    this.isLoading = true;
                    this.loadData(true);
                }
            });

        this.streamService.saveStream$
            .pipe(takeUntil(this.destroy$))
            .subscribe(res => {
                this.setRigthMenu(res);
            });

        this.streamService.stream$.pipe(takeUntil(this.destroy$)).subscribe((res) => {
            this.stream = res;
        });
    }

    loadData(reload = false): void {
        this.activatedRoute.params.pipe(
            tap(({id}: Params) => {
                this.isCreateMode = id === NEW_ID_PARAM;
                this.mediaId = id;
                this.mediaType = this.resolveMediaType();
                this.userService.userType$.pipe(takeUntil(this.destroy$))
                    .subscribe((type) => {
                        this.userType = type;
                        this.streamService.stream$.pipe(takeUntil(this.destroy$)).subscribe(res => {
                            this.setRigthMenu(res);
                        });
                    });
            }),
            mergeMap(() => this.userService.userProfile$),
            filter(userProfile => !!userProfile),
            tap((userProfile) => {
                this.premiumPublisher = userProfile?.subscribed
            }),
            mergeMap(() => {
                return this.streamService.getMedia(this.mediaType, this.mediaId)
            }),
            takeUntil(this.destroy$))
            .subscribe((res) => {
                this.streamTitle = res?.media?.title;
                this.chatEnabled = res?.advancedSettings?.chatEnabled;
                this.listenChatStatus();
                this.setRigthMenu(res);
                if (this.mediaType === MEDIA_TYPE.STREAM) {
                    this.streamService.connectStreamStatusWebsocket(this.mediaId);
                    // this.streamService.getStreamBroadcast(this.mediaId)
                    //     .pipe(takeUntil(this.destroy$))
                    //     .subscribe((_res) => this.streamBroadcastSettings = _res);
                }
                this.isLoading = false;
            });

        if (reload) {
            return;
        }
        this.streamService.openModal$
            .pipe(takeUntil(this.destroy$))
            .subscribe(modalName => {
                if (!this[modalName]) {
                    return;
                }
                this[modalName + 'Opened'] = false;
            });
        this.streamService.openModal$
            .pipe(takeUntil(this.destroy$))
            .subscribe(modalName => {
                if (!this[modalName]) {
                    return;
                }
                if (this[modalName + 'Opened']) {
                    return;
                }
                this[modalName + 'Opened'] = true;
                const dialogRef = this.dialog.open(this[modalName], {
                    panelClass: modalName === 'encoderModal' ? 'encoder-data-modal' : ''
                });
                dialogRef.afterClosed()
                    .pipe(takeUntil(this.destroy$))
                    .subscribe(() => {
                        this.streamService.closeModal$.emit(modalName);
                    });
            });
    }

    private resolveMediaType(): MEDIA_TYPE {
        return (this.activatedRoute.data as BehaviorSubject<any>)?.value?.mediaType;
    }

    private resolveDashboardUrl(): string {
        return 'dashboard';
    }

    private listenChatStatus(): void {
        this.chatState.chatEnabledState$
            .pipe(takeUntil(this.destroy$))
            .subscribe((res) => {
                this.chatEnabled = res;
            });
    }

    ngOnDestroy(): void {
        this.navService.clearNavConfig();
        this.streamService.clearStream();
        this.streamService.clearStreamStatus();

    }

    private setRigthMenu(res) {
        this.sideNavConfig = SideNavConfigModel.getRightMenuConfig(this.mediaType, res?.media, this.resolveDashboardUrl(), () => {
            this.router.navigate([this.resolveDashboardUrl()]);
        }, this.premiumPublisher, this.userType, this.activatedRoute.children[0].snapshot.url[0].path);
    }
}
