<div class="content">
    <div class="panel panel-md">
        <div class="panel-header">
            <h2 class="title-lg">{{'profile.greetings' | localize}}, <b>{{userName}}</b></h2>
            <p class="page-description">{{'profile.termsAndConditions.description' | localize}}</p>
        </div>
    </div>
</div>
<ano-form class="withdrawal"
        [form]="form"
        [title]="'profile.termsAndConditions.title'"
        [fieldsConfig]="fieldsConfig"
        (submit$)="saveChanges()"
        [cancelButtonClass]="'btn-danger'">
</ano-form>
