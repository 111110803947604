<div class="card">
    <div class="card-header">
<!--        <h3 class="title-level-3">{{'stream.overview.visibilitySettings' | localize}}</h3>-->
<!--        <p>{{'stream.overview.description' | localize}}</p>-->
    </div>
    <ano-loader [isOpen]="pending"></ano-loader>
    <div class="card-body">
        <div class="row">
            <div class="col-12">
                <div class="row visibility">
                    <ng-container *ngFor="let formConfig of landingForms">
                        <div class="col-4 visibility__item">
                            <app-bordered-form
                                    [config]="formConfig"
                                    [defaultForm]="formConfig.isDefault"
                                    [toggleForm]="formConfig.isToggle"
                                    [childForm]="formConfig.isChild"
                                    [form]="formConfig.form"
                                    [fieldsConfig]="formConfig.formFields"
                                    [title]="formConfig.title"
                                    [teaserText]="formConfig.teaserText"
                                    [withoutTopPadding]="formConfig.withoutTopPadding"
                                    [disabledForm]="formConfig.forcedDisable"
                            >
                            </app-bordered-form>
                            <p *ngIf="formConfig.setupLink" class="setup-link"><a href="#"
                                                                                  (click)="goToSetup(formConfig.setupLink,$event)">
                                > {{formConfig.setupLinkDescription | localize}}</a></p>
                        </div>
                    </ng-container>
                </div>
            </div>

        </div>
    </div>
</div>
