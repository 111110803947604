<h2><b>Vorschaubild hochladen</b></h2>
<p>Hier kannst Du ein Vorschaubild für diesen Stream hochladen. Dieses Bild wird u.a als Thumbnail in der Programmübersicht angezeigt.</p>
<div class="card bg-white">
    <div class="row justify-content-between align-items-end">
        <div class="col-xl-8">
            <div class="card-body">
                <!--<div class="title-box">-->
                    <!--<h2 class="title-level-3">Vorschaubild hochladen</h2>-->
                    <!--<p>Hier kannst Du ein Vorschaubild für diesen Stream hochladen.-->
                        <!--Dieses Bild wird u.a als Thumbnail in der Programmübersicht angezeigt.</p>-->
                <!--</div>-->
                <app-bordered-form
                        [defaultForm]="true"
                        [form]="form"
                        [fieldsConfig]="fieldsConfig"
                        (onFileUpload$)="uploadPreviewPhoto($event)"
                        (clearImage$)="clearImage()"
                        [multimediaUrl]="photoUrl"
                >
                </app-bordered-form>
            </div>
        </div>
        <div class="col-auto">
            <div class="button-group text-right mr-20 mb-20">
                <!--<div class="form-group">-->
                    <!--<button class="btn btn-primary">KOntrollzentrum</button>-->
                <!--</div>-->
                <div class="form-group">
                    <button class="btn btn-success" (click)="submit()">speichern</button>
                </div>
            </div>
        </div>
    </div>
</div>
