import {IInfoPanel, InfoPanelsConfig} from './../info-panel/info-panel.component';
import {AuthService} from '@src/app/services/auth/auth.service';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {IFormConfig, IFormFieldsConfig} from '@src/app/services/stream-metadata/stream.service';
import {IInputConfig, INPUT_TYPES, IPlayerConfig} from 'ui-elements';

@Component({
  selector: 'app-bordered-form',
  templateUrl: './bordered-form.component.html',
  styleUrls: ['./bordered-form.component.sass']
})
export class BorderedFormComponent implements OnInit {
  @Input() public panel = false;
  @Input() public config: IFormConfig;
  @Input() public toggleForm = false;
  @Input() public childForm = false;
  @Input() public defaultForm = false;
  @Input() public disabledForm = false;
  @Input() public withoutLables = false;
  @Input() public showAdd = true;
  @Input() public addLimitReached = false;
  @Input() public showRemove = true;
  @Input() public title: string;
  @Input() public teaserText: string;
  @Input() public subTitle: string;
  @Input() public form: FormGroup;
  @Input() public fieldsConfig: IFormFieldsConfig[][];
  @Input() public disabled = false;
  @Input() public isClearButton = false;
  @Input() public readonly = false;
  @Input() public disabledToggle = false;
  @Input() public withoutTopPadding = false;
  @Input() public itemIndex: number;
  @Input() public formCountTitle: string;
  @Input() public addButtonText: string;
  @Input() public removeButtonText = 'common.button.remove';
  @Input() public multimediaUrl = '';
  @Input() public multimediaType = 'photo';
  @Input() public playerConfig: IPlayerConfig;
  @Input() public withoutBorder: boolean;
  @Input() public withoutMargin: boolean;
  @Input() public addButtonPositionRight: boolean;
  @Input() public rowPaddingImage30: boolean;
  @Input() public coursePreview: {text: string, color: string, position: string};

  @Output() public toggle$: EventEmitter<any> = new EventEmitter();
  @Output() public add$: EventEmitter<any> = new EventEmitter();
  @Output() public remove$: EventEmitter<any> = new EventEmitter();
  @Output() public onFileUpload$: EventEmitter<any> = new EventEmitter();
  @Output() public clearImage$: EventEmitter<any> = new EventEmitter();
  @Output() public blur$: EventEmitter<any> = new EventEmitter();
  @Output() public checkedPayments$: EventEmitter<any> = new EventEmitter();
  @Output() public confirmed$: EventEmitter<boolean> = new EventEmitter();
  public INPUT_TYPES = INPUT_TYPES;
  public phone: string;
  public infoPanel: IInfoPanel;
  public isDisabledBtn: boolean;

  constructor(private authService: AuthService) {
  }

  ngOnInit(): void {
    this.infoPanel = this.config?.withConfirmationPhone ? InfoPanelsConfig.needToInputCorrectPhone : null;
  }

  public getStartDate(config: IInputConfig, index: number): any {
    if (config.inputType === INPUT_TYPES.DATETIME && config?.dateFrontiers?.length) {
      return config.dateFrontiers[index]?.start?.getTime() || null;
    }
    if (this.form.controls.hasOwnProperty('startTimestamp')) {
      return this.form.get('startTimestamp').value;
    }

    return null;
  }

  public getEndDate(config: IInputConfig, index: number): any {
    if (config.inputType === INPUT_TYPES.DATETIME && config?.dateFrontiers?.length) {
      return config.dateFrontiers[index]?.end?.getTime() || null;
    }
    if (this.form.controls.hasOwnProperty('endTimestamp')) {
      return this.form.get('endTimestamp').value;
    }

    return null;
  }

  public toggle(event: any): void {
    this.toggle$.emit(event);
  }

  public add(): void {
    if (this.disabledForm) {
      return;
    }
    this.add$.emit(true);
  }

  public remove(): void {
    if (this.disabledForm) {
      return;
    }
    this.remove$.emit(true);
  }

  public getColSize(row: IFormFieldsConfig[], field: IFormFieldsConfig): string {
    if (field.config?.size === 0) {
      return 'col';
    }
    if (field.config.size) {
      return 'col-lg-' + field.config.size;
    }
    if (field.config.simpleToggle) {
      return 'col-lg-4';
    }
    if (this.panel && field.config.inputType === INPUT_TYPES.IMAGE) {
      return 'col-md-8';
    }
    if (this.panel && field.config.simplePanelToggle) {
      return 'col-md-5';
    }
    if (this.panel) {
      return 'col';
    }
    return field.config.inputType === INPUT_TYPES.CHECKBOX || field.config.halfSize || row.length > 1 ? 'col-lg-6' : 'col-lg-12';
  }

  public uploadPreviewPhoto(event: any): void {
    this.onFileUpload$.emit(event);
  }

  public clearImage(event: any): void {
    this.clearImage$.emit(event);
  }

  public getSubTitle(row: IFormFieldsConfig[]): string {
    let res = '';
    const found = row.find(field => field.subTitle);
    if (!found) {
      return res;
    }
    res = found.subTitle;
    return res;
  }

  onBlur(e: string) {
    this.blur$.emit(e);
  }

  sendSms(field): void {
    this.authService.sendSms(this.phone).subscribe(res => {
      if (res.errorKey === 'INVALID_PHONE_NUMBER') {
        this.form.get(field.name).setErrors({invalidPhone: true});
        return;
      }

      this.infoPanel = InfoPanelsConfig.needToConfirmPhone;
    }, error => {
        this.form.get(field.name).setErrors({somethingGoneWrong: true});
    });
  }

  onClickButton(field) {
    if(field?.config?.inputType === INPUT_TYPES.PHONE && field?.config?.isConfirmCode && this.phone) {
      field.config.buttonText = 'Resend';
      this.sendSms(field)
    }
  }

  onInput(value: string, field) {
    if(field?.config?.inputType === INPUT_TYPES.PHONE && field?.config?.isConfirmCode) {
      this.phone = '+' + value;
    }
    if(field.config?.inputType === INPUT_TYPES.INPUT
      && field.config?.isConfirmCode
      && this.phone
      && value.length === 6) {
        this.authService.phoneConfirm(this.phone, value).subscribe(res => {
          if(res.errorKey === 'INVALID_SMS_CODE') {
            this.form.get(field.name).setErrors({invalidSmsCode: true})
          } else if (res.errorKey === 'PHONE_ALREADY_CONFIRMED') {
            this.form.get(field.name).setErrors({alreadyConfirmed: true})
          } else {
            this.isDisabledBtn = true;
            this.confirmed$.emit(true)
            this.infoPanel = InfoPanelsConfig.phoneConfirmed;
            this.form.get(field.name).setErrors(null)
          }
        }, error => {
            this.form.get(field.name).setErrors({somethingGoneWrong: true});
        });
      }
  }

  public isHidden(row: IFormFieldsConfig[]): boolean {
    const found = row.find(field => field.hidden);
    if (!found) {
      return false;
    }
    return true;
  }

  getNewConfig() {
    return
  }
}
