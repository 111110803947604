import { AfterViewChecked, Directive, ElementRef, forwardRef, HostListener, Input, OnInit } from '@angular/core';
import * as Cleave from 'cleave.js/dist/cleave.js';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject } from "rxjs";
import { first } from "rxjs/operators";

const VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CleaveMaskDirective),
    multi: true
};

/**
 * TODO: Problem with cleave directive and input component
 * writeValue of cleave mask conflicts with input component (when we add onInput).
 *
 * When deleting value in currency input onInput calls writeValue of input component and writeValue of cleave mask
 * which immediately rewrites our changes with mask. Moreover it is called not on every input. Usual order:
 *
 * <we have 11.00 in input>
 * <user deletes symbol>
 * writeValue(input-component)
 * writeValue(cleave-component)
 *
 * <we have 11.00 in input>
 * <user deletes symbol>
 * writeValue(input-component)
 *
 * <we have 11.0 in input>
 * <user deletes symbol>
 * writeValue(input-component)
 * writeValue(cleave-component)
 *
 * <we have 11.00 in input>
 */
@Directive({
    selector: '[cleaveMask]',
    providers: [],

})
export class CleaveMaskDirective implements OnInit {

    @Input() appCleaveMask = DEFAULT_CONFIG;

    private _value = new BehaviorSubject<number>(0);

    @Input("cleaveValue") set value(value: number) {
        this._value.next(value);
    }

    get value() {
        return this._value.getValue();
    }

    private isInitial = true;
    private rawValue: string;
    private mask: any = null;

    constructor(
        private elementRef: ElementRef,
    ) {
        this.initMask();
    }

    initMask() {
        if (this.mask) {
            this.mask.destroy();
        }
        const {nativeElement} = this.elementRef;
        const config = this.appCleaveMask || DEFAULT_CONFIG;
        setTimeout(() => {
            this.mask = new Cleave(nativeElement, {
                numeral: true,
                numeralDecimalScale: config.decimalScale,
                numeralPositiveOnly: true,
                onValueChanged: ({target}) => {
                    if (this.rawValue === target.rawValue) {
                        return;
                    }
                    this.rawValue = target.rawValue;
                    this.onChange(target.rawValue);
                }
            });
            this.setMaskValue(this.rawValue);
        });
    }

    ngOnInit(): void {
        // Take first not empty number
        this._value.pipe(first(val => val && val > 0)).subscribe(val => {
            if (this.isInitial) {
                setTimeout(() => {
                    this.writeValue(val);
                    this.isInitial = false;
                }, 0);
            }
        });
    }

    @HostListener('input')
    public onInput() {
        this.isInitial = false;
    }

    @HostListener('keypress', ['$event']) onKeyPress(e) {
        if (this.rawValue.replace('.00', '').length >= 6) {
            e.preventDefault();
        }
    }

    @HostListener('blur')
    public onBlur() {
        this.writeValue(this.rawValue);
        this.onTouched();
    }

    private getWithDecimals(val: string) {
        if (val === '') {
            return '';
        }

        // Format value with thousands separator
        const formatWithThousandsSeparator = (val) => val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        val = formatWithThousandsSeparator(val);

        const config = this.appCleaveMask || DEFAULT_CONFIG;
        const [integer, decimal] = val.split('.');
        if (decimal) {
            const difference = config.decimalScale - decimal.length;
            return integer + '.' + decimal.split('').concat(Array(difference).fill(0)).join('');
        } else {
            return val + `${val.includes('.') ? '' : '.'}00`;
        }
    }

    public onChange: any = (_) => {
    };

    public onTouched: any = () => { /*Empty*/
    };

    writeValue(val: string | number): void {
        if (!val) {
            this.setMaskValue('');
        }
        this.rawValue = this.getWithDecimals(String(val));
        this.setMaskValue(this.rawValue);
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    private setMaskValue(val: string) {
        if (this.mask) {
            this.mask.setRawValue(val);
        }
    }

}

const DEFAULT_CONFIG = {
    decimalScale: 2,
    prefix: '$',
};
