import {EventEmitter, Injectable, Self} from '@angular/core';
import {
    ApiService,
    CmsService,
    IInfoPanel,
    IInputConfig,
    InfoPanelsConfig,
    INPUT_TYPES,
    LocalizationProvider,
    IOverlay as IUIOverlay
} from 'ui-elements';
import {
    DATETIME_INPUT_TYPE,
    IChatActive,
    ILanding,
    ILandingRequest,
    ILandingVisibility,
    IMediaContentRequest,
    IMediaMetadata,
    IMediaVisibility,
    IOverlayActive,
    IOverlayRequest, IShowStreamDateActive,
    IStreamLimitation,
    IStreamModel,
    IStreamPreview,
    IStreamPreviewTeaserTrailer,
    IStreamRecord,
    IStreamRecordModel,
    IStreamRevenuesTotal,
    IStreamStatus,
    ITimerange,
    IVideoStatus,
    IVoucher,
    IVoucherRedeemRequest,
    IVoucherRequest,
    MediaOverviewData,
    StreamMetadataSelectValues
} from '@src/app/models/stream.model';
import {fromEvent, Observable, Subject, Subscription} from 'rxjs';
import {
    IOverlay,
    IPagedResponse,
    IPublicOverlay,
    IResponse,
    IRevenue,
    IStreamBroadcast,
    PagingParams,
    SortParam
} from '@src/app/models/response.model';
import {API_URLS_APP, UrlGenerator} from '@src/app/constants/api-urls.constant';
import {flatMap, map, pluck, takeUntil, tap} from 'rxjs/operators';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {FormUtil} from '@src/app/utils/form.util';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import {AuthService} from '@src/app/services/auth/auth.service';
import {IListQuery} from '@src/app/components/media-list/media-list.component';
import {ModalService} from '@src/app/services/modal/modal.service';
import {GroupModalContainerComponent} from '@src/app/pages/groups-page/components/group-modal-container/group-modal-container.component';
import {MEDIA_TYPE} from '@src/app/components/streamdust-player/constants/mediaType';
import {WEB_SOCKET_CODE, WebSocketService} from '@src/app/services/web-socket/web-socket.service';
import {environment} from '@src/environments/environment';
import {IWebSocketDataResponse, IWebSocketResponse, WEBSOCKET_STATUS_TYPE} from '@src/app/models/web-socket-response.model';
import {CustomValidators} from '@src/app/utils/custom-validators.util';
import {IFiltersQuery} from '@src/app/services/video-on-demand/video-on-demand.service';
import {IAllCountryPurchases} from '@src/app/pages/stream-page/components/stream-revenue/components/country-revenues/model/revenues';
import {IBubbleMapData} from '@src/app/components/stream-world-map/bubble-map/bubble-map.component';
import * as _ from 'lodash';
import {DONATION_MIN_VALUE, IPublicMediaInfo} from '@src/app/models/core.model';
import {NewPaymentsSlots} from '@src/app/models/payments-stream';
import {UserService} from '@src/app/services/user/user.service';
import {StreamMarketingToolsOverlaysTypes} from '@src/app/pages/stream-page/components/stream-marketing-tools-overlays/stream-marketing-tools-overlays.component';
import {HttpClient} from '@angular/common/http';
import {WebSocketConnector} from '@src/app/services/web-socket-connector/web-socket-connector.service';
import {STREAM_STATUS} from '@src/app/components/streamdust-player/constants/status';
import {IPaymentReturnResponse} from '@src/app/services/payment/payment.service';
import {StreamWorldMapState} from '@src/app/state/stream-world-map';
import {IListFilterItem} from 'ui-elements/lib/types/types';
import {VIDEO_VISIBLE_SETTINGS} from '@src/app/models/video-on-demand.model';
import {Router} from '@angular/router';

export interface IPlayerAccessModal {
    data?: any;
    state: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class StreamService {

    constructor(
        private apiService: ApiService,
        private destroy$: AutoDestroyService,
        private cmsService: CmsService,
        private modalService: ModalService,
        private authService: AuthService,
        private userService: UserService,
        private http: HttpClient,
        private wmState: StreamWorldMapState,
        @Self() private webSocketService: WebSocketService,
        private localizationProvider: LocalizationProvider,
        private router: Router,
    ) {
        this.storeViewerAccessToken(StreamService.getViewerAccessTokenFromStorage());
        this.storeViewerVouchers(StreamService.getViewerVouchersFromStorage());
    }

    // get stream$(): Observable<IStreamModel> {
    //     return this.streamSubject.asObservable();
    // }

    // get streamStatus$(): Observable<IStreamStatus> {
    //     return this.streamStatus.asObservable();
    // }

    // get streamViewers$(): Observable<IBubbleMapData[]> {
    //     return this.streamViewers.asObservable();
    // }
    private WEB_SOCKET_URL = `${environment.webSocketHost}${API_URLS_APP.ROOT_URL}${API_URLS_APP.WEB_SOCKET_STREAM_STATUS}`;

    private streamSubject: BehaviorSubject<IStreamModel> = new BehaviorSubject<IStreamModel>(null);
    public stream$: Observable<IStreamModel> = this.streamSubject.asObservable();
    private streamStatus: BehaviorSubject<IVideoStatus> = new BehaviorSubject<IVideoStatus>(null);
    public streamStatus$: Observable<IVideoStatus> = this.streamStatus.asObservable();
    private streamChannel: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    public streamChannel$: Observable<string> = this.streamChannel.asObservable();
    private streamViewers = new BehaviorSubject<IBubbleMapData[]>(null);
    public streamViewers$: Observable<IBubbleMapData[]> = this.streamViewers.asObservable();
    private bandwidthWarning = new BehaviorSubject<any>(null);
    public bandwidthWarning$: Observable<any> = this.bandwidthWarning.asObservable();
    private overlaysUpdatedSubject = new BehaviorSubject<any>(null);
    public overlaysUpdated$: Observable<any> = this.overlaysUpdatedSubject.asObservable();

    public mediaOverview: BehaviorSubject<MediaOverviewData> = new BehaviorSubject<MediaOverviewData>(null);
    public openModal$: EventEmitter<string> = new EventEmitter<string>();
    public closeModal$: EventEmitter<string> = new EventEmitter<string>();
    public playerAccessModalShow$: EventEmitter<IPlayerAccessModal> = new EventEmitter<IPlayerAccessModal>();
    public viewerAccessToken: string;
    public viewerVouchers: IVoucherRedeemRequest[];
    public update$: EventEmitter<{ groupCreatedId$?: string }> = new EventEmitter<{ groupCreatedId$?: string }>();
    private wsMessagingSubscription: Subscription;
    private wsWasClosed = false;
    public openModalPublisher = new EventEmitter();
    public toggleStream = new EventEmitter();
    public toggleStreamShowDate$ = new EventEmitter<boolean>();
    public saveStream$ = new EventEmitter();
    private MIN_VALUE_PRICE = MIN_VALUE_PRICE;
    public connection: Subject<any>;
    private teaserMediaId: string;
    private trailerMediaId: string;
    private streamMetadataSelectValues: StreamMetadataSelectValues = new StreamMetadataSelectValues(this.localizationProvider);

    public static getViewerAccessTokenFromStorage(): string {
        try {
            return JSON.parse(localStorage.getItem('viewerAccessToken'));
        } catch {
            return '';
        }
    }

    private static getViewerVouchersFromStorage(): IVoucherRedeemRequest[] {
        try {
            return JSON.parse(localStorage.getItem('viewerVouchers'));
        } catch {
            return [];
        }
    }

    public getVideo(isMocked: boolean = false): Observable<IPublicMediaInfo> {
        if (isMocked) {
            return this.apiService.post('mock', {
                request: {},
                // response: null
                response: {
                    productType: MEDIA_TYPE.STREAM,
                    productId: '40a328ab-278c-4aaa-9d02-6c0a1af5f297'
                }
            }).pipe(pluck('results', 'data'));
        }
        return this.apiService.get(API_URLS_APP.MEDIA_INFO_FROM_SUBDOMAIN)
            .pipe(pluck('results', 'data'));
    }

    setStreamSubject(data: IStreamModel) {
        this.streamSubject.next(data);
    }

    setStreamStatus(data: IVideoStatus) {
        this.streamStatus.next(data);
        if (this.streamSubject.value?.media) {
            this.streamSubject.value.media.status = data;
            this.setStreamSubject(this.streamSubject.value);
        }
    }

    setStreamChannel(data: string) {
        this.streamChannel.next(data);
    }

    setStreamViewers(data: IBubbleMapData[]) {
        this.streamViewers.next(data);
    }

    setBandwidthWarning(data: any) {
        this.bandwidthWarning.next(data);
    }

    overlaysUpdated() {
        this.overlaysUpdatedSubject.next(new Date().getTime());
    }

    public clearStream(): void {
        this.setStreamSubject(null);
    }

    public clearStreamStatus(): void {
        this.webSocketService.closeWebSocketConnection();
        this.setStreamStatus(null);
        this.setStreamViewers(null);
    }

    public createStream(body: IStreamModel): Observable<IResponse<{ id: string, paymentResult: IPaymentReturnResponse }>> {
        return this.apiService.post(API_URLS_APP.STREAM_EDIT, body);
    }

    public updateStream(body: IStreamModel): Observable<IResponse<{ id: string, paymentResult: IPaymentReturnResponse }>> {
        return this.apiService.put(API_URLS_APP.STREAM_EDIT, body);
    }

    public updateStreamRecord(body: IStreamRecordModel): Observable<IResponse> {
        return this.apiService.put(API_URLS_APP.STREAM_RECORD_EDIT, body);
    }

    public getMedia(mediaType: MEDIA_TYPE, mediaId: string): Observable<IStreamModel> {
        return this.apiService.get(UrlGenerator.generate(API_URLS_APP.GET_MEDIA, {mediaType, mediaId}))
            .pipe(pluck('results', 'data'), tap(res => {
                // res.media.deactivated = true;
                this.setStreamSubject(res);
            }));
    }

    public getStreamRecord(streamId: string, recordingId: string): Observable<IStreamModel | any> {
        return this.apiService.get(UrlGenerator.generate(API_URLS_APP.STREAM_RECORD, {
            recordingId: recordingId,
            streamId: streamId
        }))
            .pipe(pluck('results', 'data'));
    }

    public getStreamRecordInfo(streamId: string): Observable<IStreamModel | any> {
        return this.apiService.get(UrlGenerator.generate(API_URLS_APP.STREAM_RECORD_INFO, {
            streamId: streamId
        })).pipe(pluck('results', 'data'));
    }

    public getStreamLandingInfo(productType: MEDIA_TYPE, productId: string, filters?: IListQuery, autoDetectEmbedded = true): Observable<IResponse<{ data: MediaOverviewData }>> {
        if (productType === MEDIA_TYPE.STREAM_RECORDING) {
            productType = MEDIA_TYPE.STREAM;
        }
        if (!filters) {
            filters = {
                paging: {
                    page: 0,
                    itemsOnPage: 6
                },
                sort: [{
                    order: 'DESC',
                    field: 'DATE'
                }]
            };
        }

        if (autoDetectEmbedded) {
            const isEmbedded = this.router.url.includes('/embedded/');
            if (filters.filters) {
                filters.filters = filters.filters.filter((item) => item.field !== 'VISIBLE');
            }
            if (filters.groupFilters) {
                filters.groupFilters = filters.groupFilters.filter((item) => item.field !== 'VISIBLE');
                filters.groupFilters.push({field: 'VISIBLE', value: ['everywhere', isEmbedded ? 'except_streamdust' : 'streamdust_only']});
            } else {
                filters.groupFilters = [{field: 'VISIBLE', value: ['everywhere', isEmbedded ? 'except_streamdust' : 'streamdust_only']}];
            }
        }


        return this.apiService.post(UrlGenerator.generate(API_URLS_APP.STREAM_LANDING_INFO, {
            productId,
            productType
        }), filters, {
            openApi: true,
            isWithoutRootUrl: true
        });
    }

    public getPublicLandingPageInfo(mediaType: MEDIA_TYPE, mediaId: string): Observable<string> {
        return this.apiService.get(UrlGenerator.generate(API_URLS_APP.PUBLIC_LANDING_PAGE, {mediaType, mediaId}),
            {isWithoutRootUrl: true, openApi: true}).pipe(pluck('results', 'data'));
    }

    public getStreamSchedule(streamId: string): Observable<Response> {
        return this.apiService.get(UrlGenerator.generate(API_URLS_APP.GET_STREAM_SCHEDULE, {streamId}),
            {isWithoutRootUrl: true, openApi: true, responseType: 'blob', observe: 'response'});
    }

    public getSlotSchedule(streamId: string, slotId: string): Observable<Response> {
        return this.apiService.get(UrlGenerator.generate(API_URLS_APP.GET_SLOT_SCHEDULE, {streamId, slotId}),
            {isWithoutRootUrl: true, openApi: true, responseType: 'blob', observe: 'response'});
    }

    public updateAccessToken(accessToken: string) {
        this.addViewerAccessTokenToStorage(accessToken);
        this.storeViewerAccessToken(StreamService.getViewerAccessTokenFromStorage());
    }

    private getTokenForStream(streamId: string): Observable<{ token: string }> {
        return this.apiService.get(UrlGenerator.generate(API_URLS_APP.STREAM_TOKEN, {streamId}), {
            openApi: true,
            isWithoutRootUrl: true
        });
    }

    public getPublicPlayerUrl(streamId: string): Observable<{ playerUrl: string }> {
        return this.getTokenForStream(streamId).pipe(pluck('results'), takeUntil(this.destroy$),
            flatMap((res: { token: string }) => {
                AuthService.setOpenTokenToStorage(res.token);
                return this.apiService.get(API_URLS_APP.STREAM_PLAYER, {openApi: true, isWithoutRootUrl: true});
            }));
    }

    public getStreams(searchQuery: string, paging: PagingParams = DEFAULT_PAGING, filters: IFiltersQuery[], sort?: SortParam[], timeRange?: ITimerange): Observable<IPagedResponse<IMediaMetadata>> {
        return this.apiService.post(API_URLS_APP.STREAMS, {
            paging,
            sort,
            searchTerm: searchQuery,
            timeRange,
            filters
        });
        // return this.apiService.get(API_URLS_APP.STREAMS, {isWithoutRootUrl: true});
    }

    public deleteStream(id: string): Observable<IResponse> {
        return this.apiService.delete(UrlGenerator.generate(API_URLS_APP.STREAM, {id: id}));
    }

    public addViewerAccessTokenToStorage(viewerAccessToken: string): void {
        this.viewerAccessToken = viewerAccessToken;
        localStorage.setItem('viewerAccessToken', JSON.stringify(this.viewerAccessToken));
    }

    private addViewerVoucherToStorage(code: string, productId: string, discount): void {
        if (this.viewerVouchers && this.viewerVouchers.length) {
            this.viewerVouchers.push({
                code,
                productId,
                discount
            });
        } else {
            this.viewerVouchers = [{
                code,
                productId,
                discount
            }];
        }
        localStorage.setItem('viewerVouchers', JSON.stringify(this.viewerVouchers));
    }

    public storeViewerAccessToken(viewerAccessToken: string): void {
        this.viewerAccessToken = viewerAccessToken || '';
    }

    public storeViewerVouchers(viewerVouchers: IVoucherRedeemRequest[]): void {
        this.viewerVouchers = viewerVouchers || [];
    }

    public createVoucher(body: IVoucherRequest): Observable<IResponse> {
        return this.apiService.post(API_URLS_APP.VOUCHER_CREATE, body);
    }

    public redeemVoucher(body: IVoucherRedeemRequest): Observable<IResponse> {
        return this.apiService.post(API_URLS_APP.VOUCHER_REDEEM, body)
            .pipe(tap((res) => {
                if (res && res.success && res.results && res.results.result) {
                    if (res.results.result.status === VOUCHER_ACTIVATION_STATUSES.SUCCESS_ACCESS) {
                        this.updateAccessToken(res.results.result.accessToken);
                    }
                    if (res.results.result.status === VOUCHER_ACTIVATION_STATUSES.SUCCESS_DISCOUNT) {
                        this.addViewerVoucherToStorage(body.code, body.mediaId, res.results.result.discount);
                        this.storeViewerVouchers(StreamService.getViewerVouchersFromStorage());
                    }
                }
            }));
    }

    public getVouchers(groupId: string, searchQuery: string, paging: PagingParams = DEFAULT_PAGING, sort: SortParam[] = DEFAULT_COUPONS_SORT): Observable<IPagedResponse<IVoucher>> {
        return this.apiService.post(UrlGenerator.generate(API_URLS_APP.VOUCHERS, {groupId}), {
            paging,
            sort,
            searchTerm: searchQuery
        });
        // return this.apiService.get(API_URLS_APP.VOUCHERS, {isWithoutRootUrl: true});
    }

    public exportVoucher(mediaType: MEDIA_TYPE, mediaId: string): Observable<Response> {
        return this.apiService.get(
            UrlGenerator.generate(API_URLS_APP.VOUCHERS_EXPORT, {mediaType, mediaId}),
            {responseType: 'blob', observe: 'response'},
        );
    }

    public uploadPreview(productId: string, body, productType = 'STREAM'): Observable<IResponse> {
        return this.apiService.post(UrlGenerator.generate(API_URLS_APP.UPLOAD_STREAM_PREVIEW, {
            productId,
            productType
        }), body);
    }

    public getPreview(videoId: string, videoType = 'STREAM'): Observable<IStreamPreview> {
        return this.apiService.get(UrlGenerator.generate(API_URLS_APP.GET_STREAM_PREVIEW_PUBLIC, {videoType, videoId}), {
            openApi: true,
            isWithoutRootUrl: true
        }).pipe(pluck('results', 'data'));
    }

    public getPreviewPublisher(productId: string, productType = 'STREAM'): Observable<IStreamPreview> {
        return this.apiService.get(UrlGenerator.generate(API_URLS_APP.GET_STREAM_PREVIEW, {productId, productType}))
            .pipe(pluck('results', 'preview'));
    }

    public uploadPreviewTeaser(productId: string, body, videoType: MEDIA_TYPE): Observable<IStreamPreviewTeaserTrailer> {
        return this.apiService.put(UrlGenerator.generate(API_URLS_APP.PREVIEW_TEASER, {productId, videoType}), body)
            .pipe(pluck('results', 'data'));
    }

    public deletePreviewTeaser(productId: string, videoType = 'STREAM'): Observable<IStreamPreviewTeaserTrailer> {
        return this.apiService.delete(UrlGenerator.generate(API_URLS_APP.PREVIEW_TEASER, {productId, videoType}))
            .pipe(pluck('results', 'data'));
    }

    public getPreviewTeaser(productId: string, videoType = 'STREAM'): Observable<IStreamPreviewTeaserTrailer> {
        return this.apiService.get(UrlGenerator.generate(API_URLS_APP.PREVIEW_TEASER, {productId, videoType}))
            .pipe(pluck('results', 'data'));
    }

    public uploadPreviewTrailer(productId: string, body, videoType = 'STREAM'): Observable<IStreamPreviewTeaserTrailer> {
        return this.apiService.put(UrlGenerator.generate(API_URLS_APP.PREVIEW_TRAILER, {productId, videoType}), body)
            .pipe(pluck('results', 'data'));
    }

    public deletePreviewTrailer(productId: string, videoType = 'STREAM'): Observable<IStreamPreviewTeaserTrailer> {
        return this.apiService.delete(UrlGenerator.generate(API_URLS_APP.PREVIEW_TRAILER, {productId, videoType}))
            .pipe(pluck('results', 'data'));
    }

    public getPreviewTrailer(productId: string, videoType = 'STREAM'): Observable<IStreamPreviewTeaserTrailer> {
        return this.apiService.get(UrlGenerator.generate(API_URLS_APP.PREVIEW_TRAILER, {productId, videoType}))
            .pipe(pluck('results', 'data'));
    }

    public getOverlays(apiField: string, productType: string, productId: string, searchQuery: string, paging: PagingParams = DEFAULT_PAGING, sort: SortParam[] = DEFAULT_OVERLAYS_SORT): Observable<IPagedResponse<IOverlay>> {
        if (sort.find(field => field.field === 'NO')) {
            sort = DEFAULT_OVERLAYS_SORT;
        }
        return this.apiService.post(UrlGenerator.generate(API_URLS_APP.OVERLAYS, {apiField, productType, productId}), {
            paging,
            sort,
            searchTerm: searchQuery
        });
    }

    public getOverlay(overlayId: string, overlaysType: StreamMarketingToolsOverlaysTypes): Observable<IOverlay> {
        let url;
        if (overlaysType === StreamMarketingToolsOverlaysTypes.COURSES) {
            url = UrlGenerator.generate(API_URLS_APP.COURSE, {overlaysType, overlayId});
        } else {
            url = UrlGenerator.generate(API_URLS_APP.OVERLAY, {overlaysType, overlayId});
        }

        return this.apiService.get(url)
            .pipe(pluck('results', 'data'));
    }

    public createOverlay(body: IOverlayRequest, overlaysType: StreamMarketingToolsOverlaysTypes): Observable<IResponse> {
        return this.apiService.post(UrlGenerator.generate(API_URLS_APP.OVERLAY_CREATE, {overlaysType}), body);
    }

    public updateOverlay(body: IOverlayRequest, overlaysType: StreamMarketingToolsOverlaysTypes): Observable<IResponse> {
        return this.apiService.put(UrlGenerator.generate(API_URLS_APP.OVERLAY_CREATE, {overlaysType}), body);
    }

    public overlayActive(body: IOverlayActive, overlaysType: StreamMarketingToolsOverlaysTypes): Observable<IResponse> {
        if (overlaysType === StreamMarketingToolsOverlaysTypes.COURSES) {
            body.courseId = body.overlayId;
            delete body.overlayId;
        }

        return this.apiService.put(UrlGenerator.generate(API_URLS_APP.OVERLAY_ACTIVE, {overlaysType}), body);
    }

    public startOverlay(body: IOverlayActive, overlaysType: StreamMarketingToolsOverlaysTypes): Observable<IResponse> {
        if (overlaysType === StreamMarketingToolsOverlaysTypes.COURSES) {
            body.courseId = body.overlayId;
            delete body.overlayId;
        }
        return this.apiService.put(UrlGenerator.generate(API_URLS_APP.OVERLAY_START, {overlaysType}), body);
    }

    public deleteOverlay(overlayId: string, overlaysType: StreamMarketingToolsOverlaysTypes): Observable<IResponse> {
        return this.apiService.delete(UrlGenerator.generate(API_URLS_APP.OVERLAY_DELETE, {overlayId, overlaysType}));
    }

    public createLandingPage(body: ILandingRequest): Observable<IResponse> {
        if (!body?.wallpaper?.workbenchId && body?.wallpaper?.previewSettings) {
            delete body.wallpaper;
        }
        return this.apiService.post(API_URLS_APP.LANDING_CREATE, body);
    }

    public getLandingPage(mediaType: string, mediaId: string): Observable<ILanding> {
        return this.apiService.get(UrlGenerator.generate(API_URLS_APP.LANDING_GET, {mediaType, mediaId}))
            .pipe(pluck('results', 'landingPage'));
    }

    public getRecords(streamId: string, searchQuery: string, paging: PagingParams = DEFAULT_PAGING, sort: SortParam[] = DEFAULT_RECORDS_SORT): Observable<IPagedResponse<IStreamRecord>> {
        // return this.apiService.get(API_URLS_APP.RECORDS, {isWithoutRootUrl: true, responseType: 'json'});
        return this.apiService.post(UrlGenerator.generate(API_URLS_APP.RECORDS, {streamId}), {
            paging,
            sort,
            searchTerm: searchQuery
        });
    }

    public getStreamBroadcast(streamId: string): Observable<IStreamBroadcast> {
        return this.apiService.get(UrlGenerator.generate(API_URLS_APP.BROADCAST, {streamId}))
            .pipe(pluck('results', 'data'));
    }

    public startStream(streamId: string): Observable<IResponse> {
        return this.apiService.put(UrlGenerator.generate(API_URLS_APP.START_STREAM, {streamId}), {});
    }

    public stopStream(mediaType: MEDIA_TYPE, streamId: string): Observable<IResponse> {
        return this.apiService.put(UrlGenerator.generate(API_URLS_APP.STOP_STREAM, {streamId}), {});
    }

    public getStreamStatus(streamId: string): Observable<STREAM_STATUS_OLD> {
        return this.apiService.get(UrlGenerator.generate(API_URLS_APP.STREAM_STATUS, {streamId}))
            .pipe(pluck('results', 'status'));
    }

    public getPublicStreamStatus(streamId: string): Observable<IStreamStatus> {
        return this.apiService.get(UrlGenerator.generate(API_URLS_APP.PUBLIC_STREAM_STATUS, {streamId}), {
            openApi: true,
            isWithoutRootUrl: true
        }).pipe(pluck('results', 'status'));
    }

    public registerLeadGeneration(request): Observable<IResponse> {
        return this.apiService.post(API_URLS_APP.REGISTER_LEAD_GENERATION, request);
    }

    public payLeadGeneration(request): Observable<IResponse> {
        return this.apiService.post(API_URLS_APP.PAYMENT_LEAD, request);
    }

    public registerViewerConfirmEmail(body: { token: string }): Observable<IResponse<any>> {
        return this.apiService.post(API_URLS_APP.REGISTER_VIEWER_CONFIRM_EMAIL, body);
    }

    public connectStreamStatusWebsocket(streamId: string) {
        this.connection = new WebSocketConnector().connect(this.WEB_SOCKET_URL, this.authService.getAuthToken() || 'null', [streamId], true);
        this.connection.subscribe((res) => {
            const result: IWebSocketDataResponse = JSON.parse(res.data);
            if (result.type === WEBSOCKET_STATUS_TYPE.STATUS) {
                const status: IVideoStatus = result.data;
                this.setStreamStatus(status);
            }
            if (result.type === WEBSOCKET_STATUS_TYPE.VIEWERS) {
                const bubbleMapData: IBubbleMapData[] = result.data;
                this.setStreamViewers(bubbleMapData);
            }

            if (result.type === WEBSOCKET_STATUS_TYPE.WORLD_MAP) {
                this.wmState.worldMapEnabled$.next(result.data);
            }

            if (result.type === WEBSOCKET_STATUS_TYPE.SHARE_BUTTON) {
                this.wmState.shareButtonEnabled$.next(result.data);
            }

            if (result.type === WEBSOCKET_STATUS_TYPE.CHANNEL) {
                const channelUsed: string = result.data.channelUsed;
                this.setStreamChannel(channelUsed);
            }

            if (result.type === WEBSOCKET_STATUS_TYPE.BANDWIDTH_WARNING) {
                const bandwidthLeft: number = result.data.bandwidthLeft;
                // const streamId: string = result.data.streamId;
                this.setBandwidthWarning({
                    bandwidthLeft,
                    streamId,
                    ownerId: this.streamSubject.value.media?.owner
                });
            }
        });
        // this.webSocketService.init(this.WEB_SOCKET_URL, this.authService.getAuthToken() || 'null', streamId);
        // this.webSocketService.connectWebSocket();
        // this.handleStreamStatusWsMessages();
        // this.handleStreamStatusWsClosing();
    }

    public closeStreamStatusWebsocket(): void {
        this.webSocketService.closeWebSocketConnection();
        this.connection?.complete();
    }

    public getVisibilitySettings(mediaType: MEDIA_TYPE, mediaId: string): Observable<IMediaVisibility> {
        return this.apiService.get(UrlGenerator.generate(API_URLS_APP.MEDIA_VISIBILITY_GET, {mediaType, mediaId}))
            .pipe(pluck('results', 'settings', 'visibilitySettings'));
    }

    public updateVisibilitySettings(mediaType: MEDIA_TYPE, mediaId: string, body: { visible: VIDEO_VISIBLE_SETTINGS }): Observable<IResponse> {
        return this.apiService.put(UrlGenerator.generate(API_URLS_APP.MEDIA_VISIBILITY_UPDATE, {mediaType, mediaId}), body);
    }

    public updateSiteVisibility(mediaType: MEDIA_TYPE, mediaId: string, body: ILandingVisibility): Observable<IResponse> {
        return this.apiService.put(UrlGenerator.generate(API_URLS_APP.STREAM_LANDING_VISIBILITY, {
            mediaType,
            mediaId
        }), body);
    }

    public toggleChat(body: IChatActive): Observable<IResponse> {
        return this.apiService.put(API_URLS_APP.CHAT_ACTIVATE, body);
    }

    public toggleShowStreamDate(body: IShowStreamDateActive): Observable<IResponse> {
        return this.apiService.put(API_URLS_APP.SHOW_STREAM_DATE, body);
    }

    public getBroadcastStatus(streamId: string): Observable<BROADCAST_STATUS> {
        return this.apiService.get(UrlGenerator.generate(API_URLS_APP.STREAM_BROADCAST_STATUS, {streamId}))
            .pipe(pluck('results', 'data', 'status'));
    }

    public getBroadcastStatusPublic(streamId: string): Observable<IBroadcastStatusResponce> {
        return this.apiService.get(UrlGenerator.generate(API_URLS_APP.STREAM_BROADCAST_STATUS_PUBLIC, {streamId}), {
            openApi: true,
            isWithoutRootUrl: true
        })
            .pipe(pluck('results', 'data'));
    }

    public getRevenuesList(timeRange: ITimerange, mediaType: MEDIA_TYPE | string, mediaId: string, searchQuery: string, paging: PagingParams = DEFAULT_PAGING, sort: SortParam[] = DEFAULT_RECORDS_SORT, filters?: IListFilterItem[]): Observable<IPagedResponse<IRevenue>> {
        return this.apiService.post(UrlGenerator.generate(API_URLS_APP.REVENUES_LIST, {mediaType, mediaId}), {
            paging,
            sort,
            searchTerm: searchQuery,
            timeRange,
            filters: filters,
        });
    }

    public getRevenuesTotal(timeRange: ITimerange, mediaType: MEDIA_TYPE | string, mediaId: string, filters?: IListFilterItem[]): Observable<IStreamRevenuesTotal> {
        return this.apiService.post(UrlGenerator.generate(API_URLS_APP.REVENUES_TOTAL, {mediaType, mediaId}), {timeRange, filters: filters})
            .pipe(pluck('results', 'data'));
    }

    public getPurchasesByCountry(timeRange: ITimerange, mediaType: MEDIA_TYPE, mediaId: string, filters?: IListFilterItem[]): Observable<IAllCountryPurchases> {
        return this.apiService.post(UrlGenerator.generate(API_URLS_APP.PURCHASE_BY_COUNTRY, {mediaType, mediaId}), {timeRange, filters: filters})
            // return this.apiService.get('assets/mock-json/country-purchases.json', {isWithoutRootUrl: true})
            .pipe(pluck('results', 'data'));
    }

    public downloadRevenuesPDF(timeRange: ITimerange, mediaType: MEDIA_TYPE | string, mediaId: string): Observable<any> {
        return this.apiService.post(UrlGenerator.generate(API_URLS_APP.REVENUES_PDF, {mediaType, mediaId}),
            {timeRange}, {responseType: 'blob', observe: 'response'});
    }

    public downloadRevenuesZIP(timeRange: ITimerange, mediaType: MEDIA_TYPE | string, mediaId: string): Observable<any> {
        return this.apiService.post(UrlGenerator.generate(API_URLS_APP.REVENUES_ZIP, {mediaType, mediaId}),
            {timeRange}, {responseType: 'blob', observe: 'response'});
    }

    public downloadTransactionPDF(purchaseId: string): Observable<any> {
        return this.apiService.get(UrlGenerator.generate(API_URLS_APP.PURCHASE_PDF, {purchaseId}),
            {responseType: 'blob', observe: 'response'});
    }

    public downloadPayoutPDF(purchaseId: string): Observable<any> {
        return this.apiService.get(UrlGenerator.generate(API_URLS_APP.PAYOUT_PDF, {purchaseId}),
            {responseType: 'blob', observe: 'response'});
    }

    public getMediaUrl(body: IMediaContentRequest): Observable<IResponse<{ data: IMediaUrl }>> {
        if (body.videoType === 'STREAM_RECORDING') {
            body.videoType = 'STREAM';
        }
        return this.apiService.post(API_URLS_APP.GET_STREAM_PLAYER_LINK, body);
    }

    public getMediaChannel(streamId: string): Observable<IResponse<{ data: {liveChannel: string} }>> {
        return this.apiService.get(UrlGenerator.generate(API_URLS_APP.GET_STREAM_CHANNEL, {streamId}));
    }

    public checkIfOwner(streamId: string): Observable<boolean> {
        return this.apiService.get(UrlGenerator.generate(API_URLS_APP.STREAM_IS_OWNER, {streamId}))
            .pipe(pluck('results', 'isOwner'));
    }

    public getStreamYears(): Observable<number[]> {
        return this.apiService.get(API_URLS_APP.STREAM_YEARS)
            .pipe(pluck('results', 'data'));
    }

    public getStreamLimitation(): Observable<IStreamLimitation> {
        return this.apiService.get(API_URLS_APP.STREAM_LIMITATION)
            .pipe(pluck('results', 'data'));
    }

    public getStreamTeaserTrailer(videoId: string, videoType: MEDIA_TYPE, teaserOrTrailer: 'teaser' | 'trailer'): Observable<IStreamPreviewTeaserTrailer> {
        if (videoType === MEDIA_TYPE.STREAM_RECORDING) {
            videoType = MEDIA_TYPE.STREAM;
        }
        if (teaserOrTrailer === 'trailer') {

            if (this.trailerMediaId !== videoId) {
                this.trailerMediaId = videoId;
            }

            return this.apiService.get(UrlGenerator.generate(API_URLS_APP.GET_STREAM_TRAILER_PUBLIC, {videoType, videoId}), {
                openApi: true,
                isWithoutRootUrl: true
            }).pipe(
                pluck('results', 'data'),
                tap((res) => {
                    this.modifyPreviewVideoUrl(res);
                })
            );
        }

        if (teaserOrTrailer === 'teaser') {

            if (this.teaserMediaId !== videoId) {
                this.teaserMediaId = videoId;
            }

            return this.apiService.get(UrlGenerator.generate(API_URLS_APP.GET_STREAM_TEASER_PUBLIC, {videoType, videoId}), {
                openApi: true,
                isWithoutRootUrl: true
            }).pipe(
                pluck('results', 'data'),
                tap((res) => {
                    this.modifyPreviewVideoUrl(res);
                })
            );
        }

    }

    private modifyPreviewVideoUrl(res: IStreamPreviewTeaserTrailer): void {
        if (!res) {
            return;
        }
        if (res?.videoUrl && !res.videoUrl.startsWith('http://') && !res.videoUrl.startsWith('https://')) {
            res.videoUrl = 'https://' + res.videoUrl;
        }
        if (res.advertisingUrl && !res.advertisingUrl.startsWith('http://') && !res.advertisingUrl.startsWith('https://')) {
            res.advertisingUrl = 'https://' + res.advertisingUrl;
        }
    }

    public getPublicOverlays(videoId: string, videoType: MEDIA_TYPE, overlaysTypes: any): Observable<IPublicOverlay[]> {
        if (videoType === MEDIA_TYPE.STREAM_RECORDING) {
            videoType = MEDIA_TYPE.STREAM;
        }
        overlaysTypes += 's';
        return this.apiService.get(UrlGenerator.generate(API_URLS_APP.GET_STREAM_OVERLAYS_PUBLIC, {videoType, videoId, overlaysTypes}), {
            openApi: true,
            isWithoutRootUrl: true
        }).pipe(pluck('results', 'data'));
    }

    public overlayClick(mediaType: string, mediaId: string, overlay: IUIOverlay): Observable<IResponse> {
        return this.apiService.put(UrlGenerator.generate(API_URLS_APP.OVERLAY_CLICK, {
            mediaType,
            mediaId,
            overlayId: overlay.id,
            overlayType: StreamMarketingToolsOverlaysTypes.OVERLAY
        }), {}, {isWithoutRootUrl: true, openApi: true});
    }

    public overlayView(mediaType: string, mediaId: string, overlay: IUIOverlay): Observable<IResponse> {
        return this.apiService.put(UrlGenerator.generate(API_URLS_APP.OVERLAY_VIEW, {
            mediaType,
            mediaId,
            overlayId: overlay.id,
            overlayType: StreamMarketingToolsOverlaysTypes.OVERLAY
        }), {}, {isWithoutRootUrl: true, openApi: true});
    }

    public toggleTeaser(body: IVideoToggler): Observable<IResponse> {
        return this.apiService.put(API_URLS_APP.TOGGLE_TEASER, body);
    }

    public toggleTrailer(body: IVideoToggler): Observable<IResponse> {
        return this.apiService.put(API_URLS_APP.TOGGLE_TRAILER, body);
    }

    public getStreamFieldsConfig(mediaType: MEDIA_TYPE, premiumPublisher): IFormFieldsConfig[][] {
        return [
            [
                {
                    name: 'title',
                    control: ['', Validators.compose([Validators.required, Validators.maxLength(80)])],
                    config: {
                        inputType: INPUT_TYPES.INPUT,
                        placeholder: mediaType === MEDIA_TYPE.STREAM ? 'stream.title.placeholder.name_of_the_stream' : 'vod.field.title',
                        isRequired: true
                    }
                },
            ],
            [
                {
                    name: 'description',
                    control: ['', Validators.required],
                    config: {
                        inputType: INPUT_TYPES.TEXTAREA,
                        placeholder: 'stream.title.placeholder.description',
                        isRequired: true
                    }
                },
            ],
            [
                {
                    name: 'sourceCountry',
                    control: ['', Validators.required],
                    config: {
                        inputType: INPUT_TYPES.SELECT,
                        placeholder: 'stream.sourceCountry',
                        selectOptions: _.cloneDeep(this.cmsService.restrictedRegionsList),
                        halfSize: true,
                        isAutocomplete: true,
                        isRequired: true
                    }
                },
                {
                    name: 'genre',
                    control: ['', Validators.required],
                    config: {
                        inputType: INPUT_TYPES.SELECT,
                        placeholder: 'Genre',
                        isRequired: true,
                        halfSize: true,
                        selectOptions: []
                    }
                },
            ],
            [
                {
                    name: 'streamType',
                    control: ['', Validators.required],
                    config: {
                        inputType: INPUT_TYPES.SELECT,
                        placeholder: 'stream.placeholder.type',
                        selectOptions: StreamMetadataSelectValues.getLivestreamTypes(),
                        isRequired: true
                    }
                },
                {
                    name: 'recordingEnabled',
                    control: mediaType === MEDIA_TYPE.STREAM ? ['', Validators.required] : [''],
                    hidden: mediaType === MEDIA_TYPE.VIDEO_ON_DEMAND,
                    config: {
                        inputType: INPUT_TYPES.SELECT,
                        placeholder: 'stream.placeholder.recordOptions',
                        selectOptions: this.streamMetadataSelectValues.getConfirmation(),
                        isRequired: true
                    }
                },
            ],
            [

                {
                    name: 'backupChannelEnabled',
                    control: mediaType === MEDIA_TYPE.STREAM ? ['', Validators.required] : [''],
                    hidden: mediaType === MEDIA_TYPE.VIDEO_ON_DEMAND,
                    config: {
                        inputType: INPUT_TYPES.SELECT,
                        placeholder: 'stream.placeholder.backupChannelEnabled',
                        explanationField: 'stream.backup_channel.explanation',
                        selectOptions: [
                            {
                                key: true,
                                value: this.localizationProvider.getByKey('stream.selector.backup_channel.selector.yes'),
                            },
                            {
                                key: false,
                                value: this.localizationProvider.getByKey('stream.selector.backup_channel.selector.no'),
                            }
                        ],
                        isRequired: true
                    }
                },
            ],
            [
                {
                    name: 'startTimestamp',
                    control: mediaType === MEDIA_TYPE.STREAM ? ['', Validators.required] : [''],
                    hidden: mediaType === MEDIA_TYPE.VIDEO_ON_DEMAND,
                    config: {
                        inputType: INPUT_TYPES.DATETIME,
                        placeholder: 'stream.title.start',
                        isRequired: true,
                        datetimeType: DATETIME_INPUT_TYPE.START,
                        explanationField: 'stream.startDate.explanation'
                    }
                },
                {
                    name: 'endTimestamp',
                    hidden: mediaType === MEDIA_TYPE.VIDEO_ON_DEMAND,
                    control: mediaType === MEDIA_TYPE.STREAM ? ['', Validators.required] : [''],
                    config: {
                        inputType: INPUT_TYPES.DATETIME,
                        placeholder: 'stream.title.end',
                        isRequired: true,
                        datetimeType: DATETIME_INPUT_TYPE.END,
                        errorConfig: {
                            class: 'mb-30',
                            error: 'endDateBeforeStart'
                        },
                        explanationField: 'stream.endDate.explanation'
                    }
                },
            ],
            [
                {
                    name: 'paymentSystem',
                    control: !premiumPublisher ? [''] : ['', Validators.required],
                    hidden: !premiumPublisher,
                    config: {
                        inputType: INPUT_TYPES.SELECT,
                        placeholder: 'stream.title.selector.payment_system',
                        selectOptions: StreamMetadataSelectValues.getPaymentSystem(premiumPublisher, mediaType),
                        isRequired: true,
                    }
                },
            ],
            [
                {
                    name: 'chatEnabled',
                    control: [false],
                    hidden: true,
                    config: {
                        inputType: INPUT_TYPES.SELECT,
                        // placeholder: 'Beschreibung Ihres Streams',
                        selectOptions: this.streamMetadataSelectValues.getConfirmation(),
                    }
                },
            ],
            [
                {
                    name: 'price',
                    control: ['', Validators.compose([Validators.required, CustomValidators.priceAmount(this.MIN_VALUE_PRICE)]), {updateOn: 'change'}],
                    config: {
                        inputType: INPUT_TYPES.CURRENCY_AMOUNT,
                        placeholder: 'stream.title.selector.price',
                        selectOptions: StreamMetadataSelectValues.getPrices(),
                        isRequired: true,
                        showErrorsOnDirty: true,
                        size: 6
                    }
                }
            ],
            [
                {
                    name: 'regionRestriction',
                    hidden: true,
                    control: [''],
                    config: {
                        inputType: INPUT_TYPES.SELECT,
                        selectOptions: this.cmsService.countriesList,
                        isAutocomplete: true
                    }
                },
            ],
            [
                {
                    name: 'visible',
                    hidden: true,
                    control: [true],
                    config: {
                        inputType: INPUT_TYPES.SELECT,
                        selectOptions: this.streamMetadataSelectValues.getConfirmation(),
                    }
                },
            ]
        ];
    }

    public getStreamRecordFieldsConfig(mediaType: MEDIA_TYPE, premiumPublisher): IFormFieldsConfig[][] {
        return [
            [
                {
                    name: 'title',
                    control: ['', Validators.compose([Validators.required, Validators.maxLength(80)])],
                    config: {
                        inputType: INPUT_TYPES.INPUT,
                        placeholder: 'Mein Livestream',
                        isRequired: true
                    }
                },
            ],
            [
                {
                    name: 'description',
                    control: ['', Validators.required],
                    config: {
                        inputType: INPUT_TYPES.TEXTAREA,
                        placeholder: 'Beschreibung Ihres Streams',
                        isRequired: true
                    }
                },
            ],
            [
                {
                    name: 'sourceCountry',
                    control: [''],
                    config: {
                        padding0: true,
                        inputType: INPUT_TYPES.SELECT,
                        placeholder: 'stream.sourceCountry',
                        selectOptions: _.cloneDeep(this.cmsService.restrictedRegionsList),
                        halfSize: true,
                        isAutocomplete: true,
                        isRequired: true
                    }
                },
                {
                    name: 'genre',
                    control: ['', Validators.required],
                    config: {
                        inputType: INPUT_TYPES.SELECT,
                        placeholder: 'Genre',
                        isRequired: true,
                        selectOptions: []
                    }
                },
            ],
            [
                {
                    name: 'streamType',
                    control: mediaType === MEDIA_TYPE.STREAM ? ['', Validators.required] : [''],
                    config: {
                        inputType: INPUT_TYPES.SELECT,
                        placeholder: 'stream.placeholder.type',
                        selectOptions: StreamMetadataSelectValues.getLivestreamTypes(),
                        isRequired: true
                    }
                },
            ],
            [
                {
                    name: 'paymentSystem',
                    control: !premiumPublisher ? [''] : ['', Validators.required],
                    hidden: !premiumPublisher,
                    config: {
                        inputType: INPUT_TYPES.SELECT,
                        placeholder: 'Bezahlmöglichkeiten',
                        selectOptions: StreamMetadataSelectValues.getPaymentSystem(premiumPublisher, mediaType),
                    }
                },
            ],
            [
                {
                    name: 'price',
                    control: ['', {updateOn: 'change'}],
                    config: {
                        inputType: INPUT_TYPES.CURRENCY_AMOUNT,
                        placeholder: 'stream.title.selector.price',
                        selectOptions: StreamMetadataSelectValues.getPrices(),
                        isRequired: true,
                        showErrorsOnDirty: true,
                        size: 6
                    }
                }
            ]
        ];
    }

    // public getVodFieldsConfig(premiumPublisher, mediaType: MEDIA_TYPE): IFormFieldsConfig[][] {
    //     return [
    //         [
    //             {
    //                 name: 'title',
    //                 control: ['', Validators.compose([Validators.required, Validators.maxLength(80)])],
    //                 config: {
    //                     inputType: INPUT_TYPES.INPUT,
    //                     placeholder: 'vod.field.title',
    //                     isRequired: true
    //                 }
    //             },
    //         ],
    //         [
    //             {
    //                 name: 'description',
    //                 control: ['', Validators.required],
    //                 config: {
    //                     inputType: INPUT_TYPES.TEXTAREA,
    //                     placeholder: 'vod.field.description',
    //                     isRequired: true
    //                 }
    //             },
    //         ],
    //         [
    //             {
    //                 name: 'genre',
    //                 control: ['', Validators.required],
    //                 config: {
    //                     inputType: INPUT_TYPES.SELECT,
    //                     placeholder: 'vod.field.genre',
    //                     isRequired: true,
    //                     selectOptions: []
    //                 }
    //             },
    //         ],
    //         [
    //             {
    //                 name: 'paymentSystem',
    //                 control: !premiumPublisher ? [''] : ['', Validators.required],
    //                 hidden: !premiumPublisher,
    //                 config: {
    //                     inputType: INPUT_TYPES.SELECT,
    //                     placeholder: 'vod.field.payment',
    //                     selectOptions: StreamMetadataSelectValues.getPaymentSystem(premiumPublisher, mediaType),
    //                     isRequired: true
    //                 }
    //             },
    //         ],
    //         [
    //             {
    //                 name: 'priceAmount',
    //                 control: ['', Validators.compose([Validators.required, CustomValidators.priceAmount(this.MIN_VALUE_PRICE)])],
    //                 config: {
    //                     inputType: INPUT_TYPES.CURRENCY_AMOUNT,
    //                     placeholder: 'vod.field.price',
    //                     selectOptions: StreamMetadataSelectValues.getPrices(),
    //                     isRequired: true,
    //                     size: 6
    //                 }
    //             }
    //         ]
    //     ];
    // }

    // public getGroupsToggleConfig(): IFormFieldsConfig[][] {
    //     return [
    //         [
    //             {
    //                 name: 'enabled',
    //                 control: [false],
    //                 config: {
    //                     inputType: INPUT_TYPES.CHECKBOX,
    //                     placeholder: 'Gruppierung',
    //                     isRequired: true
    //                 }
    //             },
    //         ],
    //     ];
    // }

    public getPreviewFieldsConfig(): IFormFieldsConfig[][] {
        return [
            [
                {
                    name: 'preview',
                    control: [''],
                    config: {
                        inputType: INPUT_TYPES.IMAGE,
                        accept: 'image/*',
                        placeholder: 'Preview',
                        isRequired: true
                    }
                },
            ],
        ];
    }

    public getGroupsConfig(): IFormFieldsConfig[][] {
        return [[
            {
                name: 'groups',
                control: ['', Validators.required],
                subTitle: 'stream.groupsSettings.groupName.subtitle',
                config: {
                    inputType: INPUT_TYPES.MULTISELECT,
                    placeholder: 'stream.groupsSettings.groupName',
                    selectOptions: [],
                    isAutocomplete: true,
                    halfSize: true,
                    // actionLink: {
                    //     link: '/groups/new',
                    //     text: 'stream.newGroupCreate'
                    // },
                    actionCallback: {
                        action: () => {
                            const modalRef = this.modalService.open<GroupModalContainerComponent>(GroupModalContainerComponent);
                            modalRef.onResult().pipe(takeUntil(this.destroy$))
                                .subscribe(closed => {
                                    this.update$.emit({groupCreatedId$: closed.groupCreatedId});
                                });
                        },
                        text: 'stream.newGroupCreate'
                    },
                    isRequired: true
                }
            },
        ]];
    }

    public getProgramToggleConfig(): IFormFieldsConfig[][] {
        return [
            [
                {
                    name: 'enabled',
                    control: [false],
                    config: {
                        inputType: INPUT_TYPES.CHECKBOX,
                        placeholder: 'Programm',
                        isRequired: true
                    }
                },
            ],
            [
                {
                    name: 'name',
                    control: ['', [Validators.required]],
                    config: {
                        inputType: INPUT_TYPES.INPUT,
                        placeholder: 'Programmnamen eingeben',
                        isRequired: true,
                        selectOptions: StreamMetadataSelectValues.getNames(),
                        halfSize: true
                    }
                },
            ],
        ];
    }

    public getProgramNameConfig(): IFormFieldsConfig[][] {
        return [
            [
                {
                    name: 'name',
                    control: ['', [Validators.required]],
                    config: {
                        inputType: INPUT_TYPES.INPUT,
                        placeholder: 'stream.programSettings.programName',
                        isRequired: true,
                        selectOptions: StreamMetadataSelectValues.getNames(),
                        halfSize: true
                    }
                },
            ],
        ];
    }

    public getProgramConfig(premiumPublisher, mediaType, isRecord): IFormFieldsConfig[][] {
        return [
            [
                {
                    name: 'title',
                    control: ['', [Validators.required]],
                    config: {
                        inputType: INPUT_TYPES.INPUT,
                        placeholder: 'stream.title.placeholder.name_of_the_stream',
                    }
                },
            ],

            [
                {
                    name: 'startTimestamp',
                    control: ['', mediaType === MEDIA_TYPE.VIDEO_ON_DEMAND || isRecord ? Validators.compose([Validators.required, CustomValidators.timer]) : [Validators.required]],
                    // config: {
                    //     inputType: INPUT_TYPES.INPUT,
                    //     placeholder: 'stream.title.start.payment_system',
                    //     size: 6,
                    //     appendText: 'mm:ss',
                    //     mask: '00:s0',
                    //     isRequired: true,
                    //     time: true
                    // }
                    config: this.resolveSlotStartConfig(mediaType, isRecord)
                },
                {
                    name: 'endTimestamp',
                    // hidden: true,
                    control: ['', mediaType === MEDIA_TYPE.VIDEO_ON_DEMAND || isRecord ? Validators.compose([Validators.required, CustomValidators.timer]) : [Validators.required]],
                    // config: {
                    //     inputType: INPUT_TYPES.DATETIME,
                    //     placeholder: 'stream.title.end',
                    //     isRequired: true,
                    //     datetimeType: DATETIME_INPUT_TYPE.END
                    // }
                    config: this.resolveSlotEndConfig(mediaType, isRecord)
                },

            ],
            [
                {
                    name: 'slotPayment',
                    control: ['', [Validators.required]],
                    config: {
                        inputType: INPUT_TYPES.SELECT,
                        placeholder: 'stream.title.selector.payment_system',
                        selectOptions: NewPaymentsSlots,
                        excludeSelected: false,
                        size: 6
                    }
                },
                {
                    name: 'price',
                    control: ['', [Validators.required, CustomValidators.priceAmount(this.MIN_VALUE_PRICE)]],
                    hideInForms: [],
                    config: {
                        inputType: INPUT_TYPES.CURRENCY_AMOUNT,
                        placeholder: 'stream.programSettings.price',
                        halfSize: true,
                        fieldType: 'IPriceAmount',
                        size: 6
                    }
                },
            ],
            [
                {
                    name: 'active',
                    control: [true, [Validators.required]],
                    hidden: true,
                    config: {
                        inputType: INPUT_TYPES.INPUT,
                    }
                },
            ]
        ];
    }

    private resolveSlotStartConfig(mediaType: MEDIA_TYPE, isRecord: boolean): IInputConfig {
        if (mediaType === MEDIA_TYPE.VIDEO_ON_DEMAND || isRecord) {
            return {
                inputType: INPUT_TYPES.INPUT,
                placeholder: 'stream.title.start',
                size: 6,
                isRequired: true,
                appendText: 'hh:mm:ss',
                mask: 'h0:m0:s0',
                time: true,
                dateFrontiers: [],
                errorConfig: {
                    class: 'mb-30',
                    error: 'timerFormat'
                },
            };
        }

        return {
                inputType: INPUT_TYPES.DATETIME,
                placeholder: 'stream.title.start',
                isRequired: true,
                datetimeType: DATETIME_INPUT_TYPE.START,
                dateFrontiers: []
            };
    }

    private resolveSlotEndConfig(mediaType: MEDIA_TYPE, isRecord: boolean): IInputConfig {
        if (mediaType === MEDIA_TYPE.VIDEO_ON_DEMAND || isRecord) {
            return {
                inputType: INPUT_TYPES.INPUT,
                placeholder: 'stream.title.end',
                size: 6,
                isRequired: true,
                appendText: 'hh:mm:ss',
                mask: 'h0:m0:s0',
                time: true,
                dateFrontiers: [],
                errorConfig: {
                    class: 'mb-30',
                    error: 'timerFormat'
                },
            };
        }

        return {
            inputType: INPUT_TYPES.DATETIME,
            placeholder: 'stream.title.end',
            isRequired: true,
            datetimeType: DATETIME_INPUT_TYPE.END,
            dateFrontiers: []
        };
    }

    public getDonationsToggleConfig(): IFormFieldsConfig[][] {
        return [
            [
                {
                    name: 'enabled',
                    control: [false],
                    config: {
                        inputType: INPUT_TYPES.CHECKBOX,
                        placeholder: 'Spenden',
                        isRequired: true
                    }
                },
            ],
        ];
    }

    public getDonationsConfig(premiumPublisher, mediaType: MEDIA_TYPE): IFormFieldsConfig[][] {
        return [
            [
                {
                    name: 'sum',
                    control: ['', [Validators.required, CustomValidators.priceAmount(DONATION_MIN_VALUE)]],
                    config: {
                        inputType: INPUT_TYPES.CURRENCY_AMOUNT,
                        placeholder: 'vod.enterSum',
                        isRequired: true
                    }
                },

                {
                    name: 'name',
                    control: ['', [Validators.required]],
                    config: {
                        inputType: INPUT_TYPES.INPUT,
                        placeholder: 'vod.donationName',
                        isRequired: true
                    }
                },
            ]
        ];
    }

    public getFreeSumConfig(): IFormFieldsConfig[][] {
        return [
            [
                {
                    name: 'name',
                    control: ['', [Validators.required]],
                    config: {
                        inputType: INPUT_TYPES.INPUT,
                        placeholder: 'vod.donationName',
                        isRequired: true,
                        halfSize: true
                    }
                }
            ]
        ];
    }

    public getVisibilityConfig(): IFormFieldsConfig[][] {
        return [
            [
                {
                    name: 'visibleNone',
                    control: [false],
                    config: {
                        inputType: INPUT_TYPES.CHECKBOX,
                        placeholder: 'stream.overview.visibility.not.everywhere',
                        padding0: true
                    }
                },
            ],
            [
                {
                    name: 'visibleStreamdust',
                    control: [false],
                    config: {
                        inputType: INPUT_TYPES.CHECKBOX,
                        placeholder: 'stream.overview.visibility.streamdust',
                        padding0: true
                    }
                },
            ],
            [
                {
                    name: 'visibleNotStreamdust',
                    control: [false],
                    config: {
                        inputType: INPUT_TYPES.CHECKBOX,
                        placeholder: 'stream.overview.visibility.except.streamdust',
                        padding0: true
                    }
                },
            ],
            [
                {
                    name: 'visibleEverywhere',
                    control: [false],
                    config: {
                        inputType: INPUT_TYPES.CHECKBOX,
                        placeholder: 'stream.overview.visibility.everywhere',
                        padding0: true
                    }
                }
            ]
        ];
    }

    public getAdvancedConfig(withChat: boolean): IFormFieldsConfig[][] {
        return [
            [
                {
                    name: 'chatEnabled',
                    hidden: true,
                    control: [false],
                    config: {
                        inputType: INPUT_TYPES.CHECKBOX,
                        placeholder: 'stream.advancedSettings.chat',
                        padding0: true,
                    }
                },
            ],
            [
                {
                    name: 'ageRestriction',
                    control: [false],
                    config: {
                        inputType: INPUT_TYPES.CHECKBOX,
                        placeholder: 'stream.advancedSettings.age',
                        padding0: true
                    }
                },
            ],
            [
                {
                    name: 'onlyForRegistered',
                    control: [false],
                    config: {
                        inputType: INPUT_TYPES.CHECKBOX,
                        placeholder: 'stream.advancedSettings.onlyForRegistered',
                        padding0: true
                    }
                },
            ],
            [
                {
                    name: 'domainRestrictionEnabled',
                    control: [false],
                    config: {
                        inputType: INPUT_TYPES.CHECKBOX,
                        placeholder: 'stream.advancedSettings.domainRestrictionEnabled',
                        padding0: true
                    }
                },
            ],
        ];
    }

    public getRestrictedRegionsConfig(): IFormFieldsConfig[][] {
        return [[
            {
                name: 'restrictedRegions',
                control: [''],
                config: {
                    padding0: true,
                    inputType: INPUT_TYPES.MULTISELECT,
                    placeholder: 'stream.advancedSettings.region',
                    selectOptions: _.cloneDeep(this.cmsService.restrictedRegionsList),
                    halfSize: true,
                    isAutocomplete: true
                }
            },
        ]];
    }

    public getStreamFormConfig(isRecord = false, isStreamStarted = false, mediaType: MEDIA_TYPE, premiumPublisher, forcedDisable = false): IFormConfig {
        return {
            name: 'media',
            readonly: isStreamStarted,
            premiumPublisher: premiumPublisher,
            formFields: isRecord ? this.getStreamRecordFieldsConfig(mediaType, premiumPublisher) : this.getStreamFieldsConfig(mediaType, premiumPublisher),
            title: 'stream.generalSettings.title',
            teaserText: mediaType === MEDIA_TYPE.STREAM ? 'stream.generalSettings.description' : 'video.generalSettings.description',
            isDefault: true,
            form: isRecord ? FormUtil.buildForm(this.getStreamRecordFieldsConfig(mediaType, premiumPublisher)) : FormUtil.buildForm(this.getStreamFieldsConfig(mediaType, premiumPublisher)),
            withoutTopPadding: true,
            mediaType: mediaType,
            forcedDisable
        };
    }

    // public getVodFormConfig(premiumPublisher, mediaType): IFormConfig {
    //     return {
    //         name: 'vod',
    //         addFieldsToMainObject: true,
    //         formFields: this.getVodFieldsConfig(premiumPublisher, mediaType),
    //         title: 'Allgemeine Einstellungen',
    //         isDefault: true,
    //         form: FormUtil.buildForm(this.getVodFieldsConfig(premiumPublisher, mediaType)),
    //         withoutTopPadding: false
    //     };
    // }

    public getGroupsFormsConfig(isStreamStarted = false, premiumPublisher = false): IFormConfig {
        return {
            multiValue: true,
            readonly: isStreamStarted,
            isToggle: true,
            enabled: false,
            forcedDisable: isStreamStarted,
            togglePlaceholder: 'stream.groupsSettings.toggle',
            name: 'groupsSettings',
            injectTo: 'groupsSettings',
            itemsObject: 'groups',
            formsFields: this.getGroupsConfig(),
            forms: [FormUtil.buildForm(this.getGroupsConfig())],
            toggleForm: 'groupsSettingsToggle',
            toggleField: 'enabled',
            addButtonText: 'stream.groupsSettings.addGroup',
            title: 'stream.groupsSettings.title',
            teaserText: 'stream.groupsSettings.description',
            hidden: !premiumPublisher
        };
    }

    public getProgramsFormsConfig(isStreamStarted = false, premiumPublisher = false, mediaType: MEDIA_TYPE, isRecord: boolean = false): IFormConfig {
        return {
            readonly: isStreamStarted,
            isToggle: true,
            enabled: false,
            forcedDisable: isStreamStarted,
            name: 'programSettings',
            itemsObject: 'slots',
            formFields: this.getProgramNameConfig(),
            formsFields: this.getProgramConfig(premiumPublisher, mediaType, isRecord),
            form: FormUtil.buildForm(this.getProgramNameConfig()),
            forms: [FormUtil.buildForm(this.getProgramConfig(premiumPublisher, mediaType, isRecord))],
            formCountTitle: 'stream.programSettings.itemTitle',
            addButtonText: 'stream.programSettings.addProgram',
            togglePlaceholder: 'stream.programSettings.toggle',
            title: 'stream.programSettings.title',
            teaserText: 'stream.programSettings.description',
            preview: true,
            hidden: !premiumPublisher || mediaType === MEDIA_TYPE.VIDEO_ON_DEMAND || isRecord,
            formInfoPanel: {
                show: false,
                config: InfoPanelsConfig.streamNoDate
            },
            hideAdd: true
        };
    }

    public getDonationsFormsConfig(isStreamStarted = false, premiumPublisher = false, mediaType: MEDIA_TYPE): IFormConfig {
        return {
            isToggle: true,
            enabled: false,
            forcedDisable: isStreamStarted,
            togglePlaceholder: 'vod.donate',
            title: 'vod.donations.title',
            teaserText: 'vod.donations.description',
            name: 'donationSettings',
            itemsObject: 'donations',
            formsFields: this.getDonationsConfig(premiumPublisher, mediaType),
            forms: [FormUtil.buildForm(this.getDonationsConfig(premiumPublisher, mediaType))],
            toggleForm: 'donationSettingsToggle',
            toggleField: 'enabled',
            addButtonText: 'vod.addDonation',
            childForm: 'freeSumSettings',
            withoutBottomPadding: true,
            hidden: !premiumPublisher
        };
    }


    public geFreeSumsFormsConfig(isStreamStarted = false, premiumPublisher = false, mediaType: MEDIA_TYPE): IFormConfig {
        return {
            isToggle: true,
            enabled: false,
            forcedDisable: isStreamStarted,
            multiValue: true,
            togglePlaceholder: 'vod.freeSum',
            name: 'freeSumSettings',
            itemsObject: 'freeSumNames',
            formsFields: this.getFreeSumConfig(),
            forms: [FormUtil.buildForm(this.getFreeSumConfig())],
            toggleForm: 'freeSumsToggle',
            toggleField: 'freeEntryEnabled',
            parentForm: 'donationSettings',
            addButtonText: 'vod.addDonation',
            isChild: true,
            withoutTopPadding: true,
            toggleText: 'vod.freeSumDescription',
            hidden: !premiumPublisher
        };
    }

    public getVisibilityFormConfig(isStreamStarted = false): IFormConfig {
        return {
            name: 'visible',
            readonly: isStreamStarted,
            formFields: this.getVisibilityConfig(),
            title: 'stream.visibilitySettings.title',
            isDefault: true,
            form: FormUtil.buildForm(this.getVisibilityConfig()),
            withoutBottomPadding: true
        };
    }

    public getAdvancedFormConfig(withChat = true, isRecord = false, isStreamStarted = false): IFormConfig {
        return {
            name: 'advancedSettings',
            readonly: isStreamStarted,
            formFields: this.getAdvancedConfig(withChat),
            title: 'stream.advancedSettings.title',
            isDefault: true,
            form: FormUtil.buildForm(this.getAdvancedConfig(withChat)),
            withoutBottomPadding: true
        };
    }

    public getRestrictedRegionsFormConfig(isRecord = false, isStreamStarted = false): IFormConfig {
        return {
            padding0: true,
            readonly: isStreamStarted,
            forcedDisable: isStreamStarted,
            isToggle: true,
            enabled: false,
            togglePlaceholder: 'stream.advancedSettings.region',
            name: 'restrictedRegions',
            formsFields: this.getRestrictedRegionsConfig(),
            forms: [FormUtil.buildForm(this.getRestrictedRegionsConfig())],
            simpleToggle: true,
            itemsObject: 'restrictedRegions',
            addButtonText: 'stream.advancedSettings.addRegion',
            injectTo: 'advancedSettings',
            multiValue: true,
            withoutTopPadding: true
        };
    }

    public getPreviewFormConfig(): IFormConfig {
        return {
            name: 'preview',
            formFields: this.getPreviewFieldsConfig(),
            title: 'Vorschaubild hochladen',
            teaserText: 'Hier kannst Du ein Vorschaubild für diesen Stream hochladen.' +
                ' Dieses Bild wird u.a als Thumbnail in der Programmübersicht angezeigt.',
            isDefault: true,
            form: FormUtil.buildForm(this.getPreviewFieldsConfig()),
            payload: {}
            // withoutTopPadding: true
        };
    }

    public getStreamFormsConfig(isStreamStarted = false, isRecord = false, premiumPublisher = false, mediaType: MEDIA_TYPE): IFormConfig[] {
        return [
            this.getStreamFormConfig(isRecord, isStreamStarted, mediaType, premiumPublisher),
            this.getGroupsFormsConfig(isStreamStarted, premiumPublisher),
            this.getProgramsFormsConfig(isStreamStarted, premiumPublisher, mediaType, isRecord),
            this.getDonationsFormsConfig(isStreamStarted, premiumPublisher, mediaType),
            // this.getFreeSumsToggleFormConfig(),
            this.geFreeSumsFormsConfig(isStreamStarted, premiumPublisher, mediaType),
            this.getVisibilityFormConfig(isStreamStarted),
            this.getAdvancedFormConfig(true, isRecord, isStreamStarted),
            // this.getRestrictedRegionsToggleFormConfig(),
            this.getRestrictedRegionsFormConfig(isRecord, isStreamStarted)
        ];
    }

    public getLandingSettingsFieldsConfig(): IFormFieldsConfig[][] {
        return [
            [
                {
                    name: 'visibleNone',
                    control: [false],
                    config: {
                        inputType: INPUT_TYPES.CHECKBOX,
                        placeholder: 'stream.overview.visibility.not.everywhere',
                        simpleToggle: false,
                        size: 3,
                    }
                },
                {
                    name: 'visibleStreamdust',
                    control: [false],
                    config: {
                        inputType: INPUT_TYPES.CHECKBOX,
                        placeholder: 'stream.overview.visibility.streamdust',
                        simpleToggle: true,
                        size: 3,
                    }
                },
                {
                    name: 'visibleNotStreamdust',
                    control: [false],
                    config: {
                        inputType: INPUT_TYPES.CHECKBOX,
                        placeholder: 'stream.overview.visibility.except.streamdust',
                        simpleToggle: true,
                        size: 3,
                    }
                },
                {
                    name: 'visibleEverywhere',
                    control: [false],
                    config: {
                        inputType: INPUT_TYPES.CHECKBOX,
                        placeholder: 'stream.overview.visibility.everywhere',
                        simpleToggle: true,
                        size: 3,
                    }
                }
            ]
        ];
    }

    public getVisibilitySettingsFieldsConfig(): IFormFieldsConfig[][] {
        return [
            [
                {
                    name: 'visibleNone',
                    control: [false],
                    config: {
                        inputType: INPUT_TYPES.CHECKBOX,
                        placeholder: 'stream.overview.visibility.not.everywhere',
                        simpleToggle: true,
                        size: 4,
                    }
                },
                {
                    name: 'visibleStreamdust',
                    control: [false],
                    config: {
                        inputType: INPUT_TYPES.CHECKBOX,
                        placeholder: 'stream.overview.visibility.streamdust',
                        simpleToggle: true,
                        size: 4,
                    }
                },
                {
                    name: 'visibleNotStreamdust',
                    control: [false],
                    config: {
                        inputType: INPUT_TYPES.CHECKBOX,
                        placeholder: 'stream.overview.visibility.except.streamdust',
                        simpleToggle: true,
                        size: 4,
                    }
                },
                {
                    name: 'visibleEverywhere',
                    control: [false],
                    config: {
                        inputType: INPUT_TYPES.CHECKBOX,
                        placeholder: 'stream.overview.visibility.everywhere',
                        simpleToggle: true,
                        size: 4,
                    }
                }
            ],
            [
                {
                    name: 'websiteEnabled',
                    control: [false],
                    config: {
                        inputType: INPUT_TYPES.CHECKBOX,
                        placeholder: 'stream.overview.websiteEnabled',
                        padding0: true
                    }
                },
            ],
            [
                {
                    name: 'recordDeactivated',
                    control: [false],
                    config: {
                        inputType: INPUT_TYPES.CHECKBOX,
                        placeholder: 'Deactiveren',
                        padding0: true
                    }
                },
            ]
        ];
    }

    public getTeaserVisibilityFieldsConfig(isRecord = false): IFormFieldsConfig[][] {
        return [
            [
                {
                    name: 'teaserActive',
                    control: [false],
                    config: {
                        inputType: INPUT_TYPES.CHECKBOX,
                        placeholder: 'stream.overview.teaserEnabled',
                        simpleToggle: !isRecord,
                        padding0: isRecord,
                        size: 12,
                        isVisibilitySettings: true
                    }
                },
            ]
        ];
    }

    public getTrailerVisibilityFieldsConfig(isRecord = false): IFormFieldsConfig[][] {
        return [
            [
                {
                    name: 'trailerActive',
                    control: [false],
                    config: {
                        inputType: INPUT_TYPES.CHECKBOX,
                        placeholder: 'stream.overview.trailerEnabled',
                        simpleToggle: !isRecord,
                        padding0: isRecord,
                        size: 12,
                        isVisibilitySettings: true
                    }
                },
            ],
        ];
    }

    public getSiteVisibilityFieldsConfig(isRecord = false): IFormFieldsConfig[][] {
        return [
            [
                {
                    name: 'active',
                    control: [false],
                    config: {
                        inputType: INPUT_TYPES.CHECKBOX,
                        placeholder: 'stream.overview.websiteEnabled',
                        simpleToggle: !isRecord,
                        padding0: isRecord,
                        size: 12,
                        isVisibilitySettings: true
                    }
                },
            ],
        ];
    }

    public getWorldmapVisibilityFieldsConfig(isRecord = false): IFormFieldsConfig[][] {
        return [
            [
                {
                    name: 'active',
                    control: [false],
                    config: {
                        inputType: INPUT_TYPES.CHECKBOX,
                        placeholder: 'stream.overview.worldmapEnabled',
                        simpleToggle: !isRecord,
                        padding0: isRecord,
                        size: 12,
                        isVisibilitySettings: true
                    }
                },
            ],
        ];
    }

    public getShareMediaFieldsConfig(isRecord = false): IFormFieldsConfig[][] {
        return [
            [
                {
                    name: 'active',
                    control: [false],
                    config: {
                        inputType: INPUT_TYPES.CHECKBOX,
                        placeholder: 'stream.overview.shareMediaEnabled',
                        simpleToggle: !isRecord,
                        padding0: isRecord,
                        size: 12,
                        isVisibilitySettings: true
                    }
                },
            ],
        ];
    }

    public getVideoTitleFieldsConfig(isRecord = false): IFormFieldsConfig[][] {
        return [
            [
                {
                    name: 'active',
                    control: [false],
                    config: {
                        inputType: INPUT_TYPES.CHECKBOX,
                        placeholder: 'stream.overview.displayVideoTitle',
                        simpleToggle: !isRecord,
                        padding0: isRecord,
                        size: 12,
                        isVisibilitySettings: true
                    }
                },
            ],
        ];
    }


    public getLandingSettingsForm(isRecord = false): IFormConfig {
        return {
            name: isRecord ? 'visibilitySettings' : 'landingSettings',
            formFields: isRecord ? this.getVisibilitySettingsFieldsConfig() : this.getLandingSettingsFieldsConfig(),
            title: 'stream.overview.visibilitySettings',
            teaserText: 'stream.overview.description',
            isDefault: true,
            size: 12,
            form: isRecord ? FormUtil.buildForm(this.getVisibilitySettingsFieldsConfig()) : FormUtil.buildForm(this.getLandingSettingsFieldsConfig()),
        };
    }

    public getTeaserSettingsForm(mediaType: string, isRecord = false, premiumPublisher: boolean): IFormConfig {
        if (!premiumPublisher) {
            return null;
        }
        return {
            name: 'teaserVisibility',
            formFields: this.getTeaserVisibilityFieldsConfig(isRecord),
            isDefault: true,
            form: FormUtil.buildForm(this.getTeaserVisibilityFieldsConfig(isRecord)),
            setupLink: mediaType + '/{id}/teaser',
            setupLinkDescription: 'stream.visibility.teaser.setup',
            size: 4
        };
    }

    public getTrailerSettingsForm(mediaType: string, isRecord = false, premiumPublisher: boolean): IFormConfig {
        if (!premiumPublisher) {
            return null;
        }
        return {
            name: 'trailerVisibility',
            formFields: this.getTrailerVisibilityFieldsConfig(isRecord),
            isDefault: true,
            form: FormUtil.buildForm(this.getTrailerVisibilityFieldsConfig(isRecord)),
            setupLink: mediaType + '/{id}/trailer',
            setupLinkDescription: 'stream.visibility.trailer.setup',
            size: 4
        };
    }

    public getSiteVisibilityForm(mediaType: string, isRecord = false): IFormConfig {
        return {
            name: 'siteVisibility',
            formFields: this.getSiteVisibilityFieldsConfig(isRecord),
            isDefault: true,
            form: FormUtil.buildForm(this.getSiteVisibilityFieldsConfig(isRecord)),
            size: 4,
        };
    }

    public getWorldmapVisibilityForm(mediaType: string, isRecord: boolean): IFormConfig {
        return {
            name: 'worldmapVisibility',
            formFields: this.getWorldmapVisibilityFieldsConfig(isRecord),
            isDefault: true,
            form: FormUtil.buildForm(this.getSiteVisibilityFieldsConfig(isRecord)),
            hidden: mediaType !== MEDIA_TYPE.STREAM,
            size: 4,
        };
    }

    public getShareMediaForm(mediaType: string, isRecord: boolean): IFormConfig {
        return {
            name: 'shareMedia',
            formFields: this.getShareMediaFieldsConfig(isRecord),
            isDefault: true,
            form: FormUtil.buildForm(this.getSiteVisibilityFieldsConfig(isRecord)),
            size: 4,
        };
    }

    public getVideoTitleForm(mediaType: string, isRecord: boolean): IFormConfig {
        return {
            name: 'videoTitle',
            formFields: this.getVideoTitleFieldsConfig(isRecord),
            isDefault: true,
            form: FormUtil.buildForm(this.getSiteVisibilityFieldsConfig(isRecord)),
            size: 4,
        };
    }

    public getVisibilitySettingsForms(): IFormConfig[] {
        return [this.getLandingSettingsForm()];
    }

    public getLandingSettingsForms(mediaType: MEDIA_TYPE, premiumPublisher: boolean): IFormConfig[] {
        let mediaUrlType = '';
        switch (mediaType) {
            case MEDIA_TYPE.STREAM:
            case MEDIA_TYPE.STREAM_RECORDING:
                mediaUrlType = 'streams';
                break;
            case MEDIA_TYPE.VIDEO_ON_DEMAND:
                mediaUrlType = 'vod';
                break;

        }

        return [
            this.getChatStateForm(mediaType),
            this.getTeaserSettingsForm(mediaUrlType, null, premiumPublisher),
            this.getTrailerSettingsForm(mediaUrlType, null, premiumPublisher),
            this.getSiteVisibilityForm(mediaUrlType),
            this.getWorldmapVisibilityForm(mediaType, null),
            this.getShareMediaForm(mediaType, null),
            this.getVideoTitleForm(mediaType, null),
            this.getShowStreamDateForm(mediaType),
        ]
            .filter(config => config);
    }

    private getChatStateForm(mediaType: MEDIA_TYPE): IFormConfig {
        if (mediaType === MEDIA_TYPE.VIDEO_ON_DEMAND) {
            return null;
        }
        return {
            name: 'chatState',
            formFields: this.getChatStateFieldsConfig(),
            isDefault: true,
            form: FormUtil.buildForm(this.getChatStateFieldsConfig()),
            size: 4,
        };
    }

    private getChatStateFieldsConfig(): IFormFieldsConfig[][] {
        return [
            [
                {
                    name: 'chatEnabled',
                    control: [false],
                    config: {
                        inputType: INPUT_TYPES.CHECKBOX,
                        placeholder: 'stream.advancedSettings.chat',
                        simpleToggle: true,
                        size: 12,
                        isVisibilitySettings: true
                    }
                },
            ]
        ];
    }

    private getShowStreamDateForm(mediaType: MEDIA_TYPE): IFormConfig {
        if (mediaType === MEDIA_TYPE.VIDEO_ON_DEMAND) {
            return null;
        }
        return {
            name: 'showStreamDateState',
            formFields: this.getShowStreamDateConfig(),
            isDefault: true,
            form: FormUtil.buildForm(this.getShowStreamDateConfig()),
            size: 4,
        };
    }

    private getShowStreamDateConfig(): IFormFieldsConfig[][] {
        return [
            [
                {
                    name: 'showStreamDateEnabled',
                    control: [false],
                    config: {
                        inputType: INPUT_TYPES.CHECKBOX,
                        placeholder: 'stream.advancedSettings.showStreamDate',
                        simpleToggle: true,
                        size: 12,
                        isVisibilitySettings: true
                    }
                },
            ]
        ];
    }

    public getStreamThumbnailForm(): IFormFieldsConfig[][] {
        return [
            [
                {
                    name: 'preview',
                    control: [''],
                    config: {
                        inputType: INPUT_TYPES.IMAGE,
                        placeholder: 'Preview',
                        isRequired: true,
                        accept: 'image/*',
                    }
                },
            ]
        ];
    }

    public getStreamPresentationForm(): IFormFieldsConfig[][] {
        return [
            [
                {
                    name: 'file',
                    control: ['', [Validators.required]],
                    config: {
                        inputType: INPUT_TYPES.IMAGE,
                        placeholder: 'Preview',
                        isRequired: true,
                        accept: 'application/pdf',
                        maxFileSize: 20
                    }
                },
            ]
        ];
    }

    public getStreamThumbnailURLForm(): IFormFieldsConfig[][] {
        return [
            [
                {
                    name: 'photoUrl',
                    control: [''],
                    config: {
                        inputType: INPUT_TYPES.INPUT,
                        placeholder: 'URL',
                        isRequired: true
                    }
                },
            ]
        ];
    }

    public getStreamThumbnailFormConfig(): IFormConfig {
        return {
            name: 'preview',
            formFields: this.getStreamThumbnailForm(),
            title: 'Upload',
            subtitle: 'video.upload',
            isDefault: true,
            form: FormUtil.buildForm(this.getStreamThumbnailForm()),
            withoutTopPadding: true,
            payload: {}
        };
    }

    public getStreamPresentationFormConfig(): IFormConfig {
        return {
            name: 'file',
            formFields: this.getStreamPresentationForm(),
            subtitle: '',
            isDefault: true,
            form: FormUtil.buildForm(this.getStreamPresentationForm()),
            withoutTopPadding: true,
            payload: {}
        };
    }

    // public getStreamThumbnailURLFormConfig(): IFormConfig {
    //     return {
    //         name: 'photoUrl',
    //         formFields: this.getStreamThumbnailURLForm(),
    //         title: 'Verlinkung',
    //         subtitle: '',
    //         readonly: true,
    //         isDefault: true,
    //         form: FormUtil.buildForm(this.getStreamThumbnailURLForm()),
    //         withoutTopPadding: true,
    //     };
    // }

    public getStreamThumbnailFormsConfig(): IFormConfig[] {
        return [
            this.getStreamThumbnailFormConfig()
            // this.getStreamThumbnailURLFormConfig()
        ];
    }

    public getStreamPresentationFormsConfig(): IFormConfig[] {
        return [
            this.getStreamPresentationFormConfig()
        ];
    }

    public getStreamPreviewTeaserForm(videoDurationLimit = 0): IFormFieldsConfig[][] {
        return [
            [
                {
                    name: 'preview',
                    control: ['', Validators.required],
                    config: {
                        inputType: INPUT_TYPES.IMAGE,
                        label: 'Preview',
                        placeholder: 'Preview',
                        isRequired: true,
                        accept: 'video/*',
                        videoDurationLimit: videoDurationLimit,
                        size: 12
                    }
                },
            ]
        ];
    }

    public getStreamPreviewTeaserURLForm(): IFormFieldsConfig[][] {
        return [
            [
                {
                    name: 'advertisingUrl',
                    control: [''],
                    config: {
                        inputType: INPUT_TYPES.INPUT,
                        label: 'trailer.link.title',
                        placeholder: 'trailer.link.placeholder',
                        isRequired: true,
                        url: true
                    }
                },
            ]
        ];
    }

    public getStreamPreviewTeaserFormConfig(videoDurationLimit = 0): IFormConfig {
        return {
            name: 'preview',
            formFields: this.getStreamPreviewTeaserForm(videoDurationLimit),
            title: 'teaser.form.title',
            subtitle: 'teaser.form.subtitle',
            isDefault: true,
            form: FormUtil.buildForm(this.getStreamPreviewTeaserForm(videoDurationLimit)),
            withoutTopPadding: true,
            payload: {}
        };
    }

    public getStreamPreviewTeaserURLFormConfig(): IFormConfig {
        return {
            name: 'advertisingUrl',
            formFields: this.getStreamPreviewTeaserURLForm(),
            title: 'trailer.link.title',
            subtitle: '',
            isDefault: true,
            form: FormUtil.buildForm(this.getStreamPreviewTeaserURLForm()),
            withoutTopPadding: true,
        };
    }

    public getStreamPreviewTeaserFormsConfig(videoDurationLimit = 0): IFormConfig[] {
        return [
            this.getStreamPreviewTeaserFormConfig(videoDurationLimit),
            this.getStreamPreviewTeaserURLFormConfig()
        ];
    }

    public getStreamPreviewTrailerFormConfig(): IFormConfig {
        return {
            name: 'preview',
            formFields: this.getStreamPreviewTeaserForm(),
            title: 'trailer.form.title',
            subtitle: 'trailer.form.subTitle',
            isDefault: true,
            form: FormUtil.buildForm(this.getStreamPreviewTeaserForm()),
            withoutTopPadding: true,
            payload: {}
        };
    }

    public getStreamPreviewTrailerFormsConfig(): IFormConfig[] {
        return [
            this.getStreamPreviewTrailerFormConfig(),
            this.getStreamPreviewTeaserURLFormConfig()
        ];
    }

    public getVideoOnDemandUploadFormConfig(): IFormConfig[] {
        return [{
            name: 'video',
            formFields: this.getVideoOnDemandUploadForm(),
            title: 'Video Upload',
            subtitle: 'video.upload',
            isDefault: true,
            form: FormUtil.buildForm(this.getVideoOnDemandUploadForm()),
            withoutTopPadding: true,
            payload: {}
        }];
    }

    public getVideoOnDemandUploadForm(): IFormFieldsConfig[][] {
        return [
            [
                {
                    name: 'video',
                    control: ['', [Validators.required]],
                    config: {
                        inputType: INPUT_TYPES.IMAGE,
                        label: 'video',
                        placeholder: 'video',
                        isRequired: true,
                        accept: 'video/*',
                        size: 12,
                        padding0: true
                    }
                },
            ]
        ];
    }

    private handleStreamStatusWsMessages(): void {
        this.wsMessagingSubscription = fromEvent<IWebSocketResponse>(this.webSocketService.webSocket, 'message')
            .pipe(takeUntil(this.destroy$))
            .subscribe(({data}: IWebSocketResponse) => {
                const result: IWebSocketDataResponse = JSON.parse(data);
                if (result.type === WEBSOCKET_STATUS_TYPE.STATUS) {
                    const status: IVideoStatus = result.data;
                    this.setStreamStatus(status);
                }
                if (result.type === WEBSOCKET_STATUS_TYPE.VIEWERS) {
                    const bubbleMapData: IBubbleMapData[] = result.data;
                    this.setStreamViewers(bubbleMapData);
                }
                if (result.type === WEBSOCKET_STATUS_TYPE.CHANNEL) {
                    const channelUsed: string = result.data.channelUsed;
                    this.setStreamChannel(channelUsed);
                }
            });
    }

    private handleStreamStatusWsClosing() {
        fromEvent(this.webSocketService.webSocket, 'close')
            .pipe(takeUntil(this.destroy$))
            .subscribe((event: CloseEvent) => {
                this.wsWasClosed = true;
                this.wsMessagingSubscription.unsubscribe();
                if (event.code === WEB_SOCKET_CODE.ABNORMAL_CLOSURE) {
                    this.webSocketService.setWebsocketRunning(false);
                    this.webSocketService.connectWebSocket();
                    this.handleStreamStatusWsMessages();
                }
            });
    }

    getAccessOptionsPayments() {

    }
}

export interface IFormFieldsConfig {
    name: string;
    control?: any[] | FormControl;
    accept?: string;
    hidden?: boolean;
    defaultValue?: any;
    title?: string;
    disabled?: string;
    config: IInputConfig;
    formGroup?: boolean;
    subTitle?: string;
    setupLink?: string;
    hideInForms?: number[];
}

export interface IFormConfig {
    forcedDisable?: boolean;
    enabled?: boolean;
    isToggle?: boolean;
    togglePlaceholder?: string;
    name: string;
    title?: string;
    subtitle?: string;
    teaserText?: string;
    isDefault?: boolean;
    disabledToggle?: boolean;
    isChild?: boolean;
    childForm?: string;
    parentForm?: string;
    itemsObject?: string;
    parentToggleForm?: string;
    toggleForm?: string;
    toggleField?: string;
    withoutTopPadding?: boolean;
    withoutBottomPadding?: boolean;
    formFields?: IFormFieldsConfig[][];
    formsFields?: IFormFieldsConfig[][];
    form?: FormGroup;
    forms?: FormGroup[];
    formCountTitle?: string;
    addButtonText?: string;
    simpleToggle?: boolean;
    readonly?: boolean;
    premiumPublisher?: boolean;
    injectTo?: string;
    multiValue?: boolean;
    payload?: any;
    padding0?: boolean;
    addFieldsToMainObject?: boolean;
    toggleText?: string;
    setupLink?: string;
    setupLinkDescription?: string;
    hideAdd?: boolean;
    hidden?: boolean;
    maxLength?: number;
    withConfirmationPhone?: boolean;
    withConfirmationCompanyInformation?: boolean;
    preview?: boolean;
    mediaType?: MEDIA_TYPE;
    infoPanel?: {
        show?: boolean;
        config: InfoPanelsConfig
        size: number;
    };
    size?: number;
    formInfoPanel?: {
        show?: boolean;
        config: IInfoPanel
    };
}

export interface IPreviewPicture {
    photoId?: string;
    photoUrl?: string;
    workbenchId?: string;
    previewSettings?: {
        x: number;
        y: number;
        width: number;
        height: number;
        rotation: number;
    };
}

export interface IMultimediaHeaders {
    mainHeader?: string;
    description?: string;
    subDescription?: string;
    actualResultText?: string;
}

export enum STREAM_SORT_PROPERTY_NAME {
    TITLE = 'TITLE',
    DATE = 'DATE',
    PRICE = 'PRICE'
}

const DEFAULT_PAGING = {
    page: 0,
    itemsOnPage: 10
} as PagingParams;

const DEFAULT_COUPONS_SORT = [{
    field: 'CODE',
    order: 'ASC'
}] as SortParam[];

const DEFAULT_OVERLAYS_SORT = [{
    field: 'NAME',
    order: 'ASC'
}] as SortParam[];

const DEFAULT_RECORDS_SORT = [{
    field: 'DATE',
    order: 'ASC'
}] as SortParam[];

export enum STREAM_STATUS_OLD {
    STARTED = 'STARTED',
    STOPPED = 'STOPPED',
    STARTING = 'STARTING',
    STOPPING = 'STOPPING',
    RESETTING = 'RESETTING',
    PENDING = 'PENDING'
}

// export enum STREAM_STATUS {
//     SCHEDULED = 'SCHEDULED',
//     ACTIVATION = 'ACTIVATION',
//     ACTIVATED = 'ACTIVATED',
//     LIVE = 'LIVE',
//     FINISHED = 'FINISHED'
// }

// export enum RECORD_STATUS {
//     NONE = 'NONE',
//     RECORDING = 'RECORDING',
//     STORING = 'STORING',
//     RECORDED = 'RECORDED'
// }

export enum BROADCAST_STATUS {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE'
}

export enum PAYMENT_TRANSLATES {
    ONLY_WITH_VOUCHER = 'stream.payment-types.only-with-voucher',
    AVAILABLE_FOR_EVERYONE = 'stream.payment-types.available-for-everyone',
    FREE = 'stream.payment-types.free',
    LEAD_GENERATION = 'stream.payment-types.lead-generation',
    ENTERPRISE_LOGIN = 'stream.payment-types.enterprise-login',
}

export enum VOUCHER_ACTIVATION_STATUSES {
    SUCCESS_ACCESS = 'SUCCESS_ACCESS',
    SUCCESS_DISCOUNT = 'SUCCESS_DISCOUNT',
    VOUCHER_INVALID = 'VOUCHER_INVALID'
}

export enum VOUCHER_ACTIVATION_REJECT_REASONS {
    NOT_FOUND = 'NOT_FOUND',
    INACTIVE = 'INACTIVE',
    REDEEMED = 'REDEEMED',
    EXPIRED = 'EXPIRED'
}

export interface IBroadcastStatusResponce {
    status: BROADCAST_STATUS;
    started: any;
    lastSeen: any;
}

export interface IVideoToggler {
    productId: string;
    videoType: string;
    active: boolean;
}

export interface IMediaUrl {
    error?: MEDIA_URL_ERROR;
    mediaUrl: string;
    backupPlayerUrl: string | null;
    paymentSlots: string[];
    skipOpenSlots?: boolean;
}

export const MIN_VALUE_PRICE = 2;

export enum MEDIA_URL_ERROR {
    MEDIA_DEACTIVATED = 'MEDIA_DEACTIVATED'
}
