<div class="timeline">
    <div class="row row-padding-0">
        <div class="col-md-4">
            <app-timeline-panel [date]="scheduledStart" [actualDate]="startAt" [classList]="'start'" [cssClass]="'start'"
                                dateTranlate="stream.title.start"
                                [finished]="status?.stream === STREAM_STATUS.FINISHED"
                                [showCountdown]="status?.stream === STREAM_STATUS.SCHEDULED && status?.stream !== STREAM_STATUS.FINISHED"
                                [now]="now"></app-timeline-panel>
        </div>
        <div class="col text-center">
            <div class="timeline-container duration">
                <h3 class="title">{{'stream.title.duration' | localize}}</h3>
                <div class="date-time">
                    <span class="date-time-title">{{'Scheduled'}}</span>
                    <span class="time">{{formattedDuration}}</span>
                </div>
                <div class="date-time" *ngIf="startAt && formattedActualDuration">
                    <span class="date-time-title">{{'Actual'}}</span>
                    <span class="time actual-duration">{{formattedActualDuration}}</span>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <app-timeline-panel [date]="scheduledEnd" [actualDate]="endAt" [cssClass]="'finish'" dateTranlate="stream.title.end"
                                [showCountdown]="status?.stream !== STREAM_STATUS.SCHEDULED && status?.stream !== STREAM_STATUS.FINISHED"
                                [finished]="status?.stream === STREAM_STATUS.FINISHED"
                                [now]="now"></app-timeline-panel>
        </div>
    </div>
</div>
