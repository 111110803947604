<div class="blue-theme-filter">
    <form [formGroup]="linkedStreamsFilterForm" *ngIf="linkedStreamsFilterConfig?.selectOptions?.length">
        <ano-input [formControlName]="linkedStreamsFilterConfig.name"
                   [isEditMode]="true"
                   [noBorder]="false"
                   [config]="{
                            inputType: INPUT_TYPES.SELECT,
                            placeholder: linkedStreamsFilterConfig.placeholder,
                            selectOptions: linkedStreamsFilterConfig.selectOptions
                          }"></ano-input>
    </form>
</div>
