import {Injectable, OnDestroy, Self} from '@angular/core';
import {WebSocketConnector} from "@src/app/services/web-socket-connector/web-socket-connector.service";
import {BehaviorSubject, Observable, ReplaySubject, Subject} from 'rxjs';
import { environment } from "@src/environments/environment";
import {API_URLS_APP, UrlGenerator} from '@src/app/constants/api-urls.constant';
import {map, switchMap, tap} from 'rxjs/operators';
import {AuthService} from "@src/app/services/auth/auth.service";
import {IResponse} from "@src/app/models/response.model";
import {HttpClient, HttpEvent, HttpRequest} from '@angular/common/http';

const CHAT_URL = `${environment.webSocketHost}${API_URLS_APP.ROOT_URL}${API_URLS_APP.WEB_SOCKET_CHAT}`;
@Injectable()
export class ChatService implements OnDestroy {
  public connection: Subject<any>;
  public cachedConnection: ReplaySubject<IResponse<IChatResponse>> = new ReplaySubject<IResponse<IChatResponse>>(100);


  constructor(
      private authService: AuthService,
      private http: HttpClient,
  ) {
  }

  public connect(mediaId: string): ReplaySubject<IResponse<IChatResponse>> {
    if (!this.connection) {
      this.connection = new WebSocketConnector().connect(CHAT_URL, this.authService.getMediaAccessToken(mediaId), [mediaId], true);
    }
    this.chatResponse().subscribe(res => this.cachedConnection.next(res));
    return this.cachedConnection;
  }

  public reconnect(mediaId: string): ReplaySubject<IResponse<IChatResponse>> {
    this.connection.complete();
    this.connection = undefined;
    return this.connect(mediaId);
  }

  private chatResponse(): Subject<IResponse<IChatResponse>> {
    return <Subject<IResponse<IChatResponse>>>this.connection.pipe(map(
        (response: MessageEvent): IResponse<IChatResponse> => {
          return  JSON.parse(response.data);
        }
    ));
  }

  public send(request: IChatRequest): void {
    this.connection?.next(request);
  }

  public uploadAttachment(file: File, streamId: string): Observable<HttpEvent<any>> {
    const formData = new FormData();
    formData.append('files', file);

    const req = new HttpRequest('POST', '/api/v1/' + UrlGenerator.generate(API_URLS_APP.UPLOAD_MESSAGE_ATTACHMENT, {streamId}), formData, {
      reportProgress: true,
      responseType: 'json'
    });

    return this.http.request(req);
  }

  ngOnDestroy(): void {
    this.connection?.complete();
  }


}


export interface IChatRequest {
  content?: string;
  type: CONTENT_TYPE;
  messageType?: MESSAGE_TYPE;
}
export interface IChatResponse {
  status: CHAT_STATUS;
  nickname?: string;
  type: CONTENT_TYPE;
  messages?: IMessage[];
  isPinned?: boolean;
  messageId?: string;
  limitReached?: boolean;
}

export interface IMessage {
  action?: string;
  id: string;
  conversationId: string;
  created: any;
  owner?: IMessageOwner;
  content: string;
  type: MESSAGE_TYPE;
  date?: number;
  isPinned?: boolean;
}

export interface IMessageOwner {
  id: string
  conversationOwner: boolean;
  name: string;
  profilePhoto: {
    photoId: string;
    photoUrl: string
  }
  current: boolean;
}


export enum CHAT_STATUS {
  SCHEDULED = 'SCHEDULED',
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
  FINISHED = 'FINISHED'
}

export enum CONTENT_TYPE {
  OPEN = 'OPEN',
  NICKNAME = 'NICKNAME',
  CONVERSATION = 'CONVERSATION',
  STATUS = 'STATUS',
  SEND = 'SEND',
  MESSAGE_DELETED = 'MESSAGE_DELETED',
  MESSAGE_PINNED = 'MESSAGE_PINNED',
  CMD = 'CMD',
}

export enum MESSAGE_TYPE {
  PLAIN = 'PLAIN',
  CMD = 'CMD',
  SYSTEM = 'SYSTEM',
  FILE = 'FILE',
}
