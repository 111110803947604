<ng-container *ngIf="!isModal">
    <h2><b>{{ (isCreateMode ? 'group.create' : 'group.edit') | localize}}</b></h2>
    <p class="mb-40"></p>
</ng-container>
<div class="card bg-white" appScrollToFirstError [forms]="[formConfig.form]">
    <div class="row justify-content-between align-items-end">
        <div [class.col-xl-8]="!isModal" [class.col]="isModal">
            <div class="card-body">
                <div class="title-box">
                    <h2 class="title-level-3">{{'groups.title' | localize}}</h2>
                </div>
                <app-bordered-form
                        [form]="formConfig.form"
                        [fieldsConfig]="formConfig.formFields"
                        [defaultForm]="true"
                ></app-bordered-form>
            </div>
        </div>
        <div class="col-auto" *ngIf="!isModal">
            <div class="button-group text-right mr-20 mb-20 flex flex-row justify-content-sm-between">
                <div class="form-group">
                    <button class="btn btn-secondary back-button" (click)="goToGroups()">{{'common.button.back' | localize}}</button>
                </div>
                <div class="form-group">
                    <button class="btn btn-success" (click)="submit()">{{'common.button.apply' | localize}}</button>
                </div>
            </div>
        </div>
    </div>
    <ano-loader [isOpen]="loading"></ano-loader>
</div>

<div class="empty-stream-list">
    <p *ngIf="showPlaceholder">
        {{'groups.no-data-groups' | localize}}
    </p>
</div>

<ng-container *ngFor="let mediaList of groupMediaListSettings; let i = index">
    <ng-container *ngIf="mediaList?.originalLength">
        <span class="small-media-list">
            <app-media-list #mediaLists
                    (removeMediaItem$)="removeMediaFromGroup($event, i)"
                    [implicitFilters]="mediaList?.implicitFilters"
                    [config]="{
                                    headerConfig: {
                                        filtration: true
                                    },
                                    fetchMethod: mediaList?.fetchMethod,
                                    loadMore: LOAD_TYPE.BUTTON,
                                    cardConfig: {
                                            streamHeaderCssClass: 'video-on-demand-card-header',
                                            actions: {
                                                remove: true,
                                                removeConfirmTitle: 'groups.remove-media.confirm-title',
                                                removeConfirmBody: 'groups.remove-media.confirm-body'
                                            },
                                            infoLineCssClass: 'left-infoline',
                                            skeleton: {
                                                headerCssClass: 'header-sm'
                                            }
                                   }
                                }"
                    [customClick]="true"
                    (customClick$)="goToMediaDetails($event)"
                    [title]="mediaList?.title"
                    [data]="mediaList?.media">
            </app-media-list>
        </span>
    </ng-container>
</ng-container>
