<div class="wrapper-linked-streams" *ngIf="state.isOpened$ | async">
    <div class="linked-streams-box">
        <i class="icon icon-close" (click)="close()"></i>
        <div class="linked-streams-header">
            <h2 class="title">{{'stream.share.title' | localize}}</h2>
        </div>
        <div class="linked-streams-body profile-data">
            <ul class="list-social">
                <!--        <li><a href="https://www.instagram.com/" target="_blank"><i class="icon-instagram icon-round"></i></a></li>-->
                <li><a [href]="'https://twitter.com/share?url=' + shareUrl" target="_blank"><i class="icon-twitter icon-round"></i></a></li>
                <li><a [href]="'https://www.facebook.com/sharer/sharer.php?u=' + shareUrl" target="_blank"><i class="icon-facebook icon-round"></i></a></li>
                <li><a [href]="'https://www.linkedin.com/sharing/share-offsite/?url=' + shareUrl" target="_blank"><i class="icon-linkedin icon-round"></i></a></li>
                <li><a [href]="'https://www.xing.com/spi/shares/new?url=' + shareUrl" target="_blank"><i class="icon-xing icon-round"></i></a></li>
                <li><a [href]="'https://api.whatsapp.com/send?text=' + shareUrl" target="_blank"><i class="icon-whatsapp icon-round"></i></a></li>
            </ul>
        </div>
    </div>
</div>
