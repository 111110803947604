import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PaymentDetailsPageComponent} from "@src/app/pages/payment-details-page/payment-details-page.component";
import {SharedModule} from "@src/app/modules/shared/shared.module";


@NgModule({
    declarations: [PaymentDetailsPageComponent],
    imports: [
        CommonModule,
        SharedModule
    ]
})
export class PaymentDetailsPageModule {
}
