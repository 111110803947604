import { Injectable } from '@angular/core';
import {ApiService} from "ui-elements";
import {IPagedResponse, IResponse, PagingParams, SortParam} from '@src/app/models/response.model';
import {IMediaTimerange, ITimerange} from "@src/app/models/stream.model";
import {Observable} from "rxjs";
import {IRevenue, IRevenueTimerange} from "@src/app/pages/revenues-page/model/revenue.model";
import {API_URLS_APP, UrlGenerator} from "@src/app/constants/api-urls.constant";
import {map, pluck, tap} from "rxjs/operators";
import * as moment from 'moment';

@Injectable()
export class RevenuesService {
  private language = localStorage.getItem('localLanguage');
  constructor(
      private apiService: ApiService
  ) { }

  public getRevenue(id: string): Observable<IRevenue> {
    // return this.apiService.get('assets/mock-json/revenue.json', {isWithoutRootUrl: true})
    return this.apiService.get(UrlGenerator.generate(API_URLS_APP.PUBLISHER_PAYOUT, {id}))
        .pipe(pluck('results', 'data'));
  }
  public getRevenues(searchTerm: string = '', pagingParams: PagingParams = DEFAULT_PAGING, sortParams: SortParam[] = DEFAULT_SORTING, timeRange: ITimerange = { start: +moment().startOf('month').format('x'), end: +moment().endOf('month').format('x'), }): Observable<IPagedResponse<IRevenue>> {
    return this.apiService.post(API_URLS_APP.PUBLISHER_PAYOUTS, {
      searchTerm: searchTerm,
      sort: sortParams,
      paging: pagingParams,
      timeRange: timeRange
    });
  }

  public sendPdf(id: string): Observable<IResponse> {
    return this.apiService.post(UrlGenerator.generate(API_URLS_APP.SEND_PAYOUT_PDF, {id}), {});
  }

  public getTimeRanges(): Observable<IMediaTimerange[]> {
    return this.apiService.get(API_URLS_APP.PUBLISHER_PAYOUTS_TIMERANGE)
        // return this.apiService.get('assets/mock-json/revenues-timerange.json', {isWithoutRootUrl: true})
        .pipe(pluck('results', 'data'), map((res: IRevenueTimerange) => {
          const firstDateTimeRange = res.options.monthTimeRanges[0];
          const currentDate = new Date();
          const diffYears = currentDate.getFullYear() - firstDateTimeRange.year;
          let ranges: IMediaTimerange[] = [];
          for(let i = 0; i <= diffYears; i++) {
            const year = firstDateTimeRange.year + i;
            if (year === firstDateTimeRange.year && diffYears > 1) {
              ranges = ranges.concat(this.fillMonths(firstDateTimeRange.month, 11, year));
              // ranges.push(this.getYearRange(year));
            }
            if (year === currentDate.getFullYear()) {
              ranges = ranges.concat(this.fillMonths(diffYears > 1 ? 0 : firstDateTimeRange.month, currentDate.getMonth(), year));
              // ranges.push(this.getYearRange(year));
            }
            if (year !== firstDateTimeRange.year && year !== currentDate.getFullYear()) {
              ranges = ranges.concat(this.fillMonths(0, 11, year));
              // ranges.push(this.getYearRange(year));
            }
          }

          return ranges.reverse();
        }));
  }

  public getYearRange(year: number): IMediaTimerange {
    const yearStartDate = moment([year]);
    const yearEndDate = moment(yearStartDate).endOf('year');
    return {
      year: year,
      timeRange: {
        start: yearStartDate.toDate().getTime(),
        end: yearEndDate.toDate().getTime()
      }
    }
  }

  public fillMonths(from: number = 0, to: number = 11, year: number): IMediaTimerange[] {
    const res = [];
    for (let i = from; i <= to; i++) {
      const startDate = moment([year, i]);
      const endDate = moment(startDate).endOf('month');

      const timeRange: IMediaTimerange = {
        year: year,
        month: moment(new Date(year, i, 1)).locale(this.language).format('MMMM'),
        timeRange: {
          start: startDate.toDate().getTime(),
          end: endDate.toDate().getTime()
        }
      };
      res.push(timeRange)
    }
    return res;
  }
}

const DEFAULT_PAGING = {
  page: 0,
  itemsOnPage: 10
} as PagingParams;

const DEFAULT_SORTING = [{
  field: 'PRODUCT_ID',
  order: 'DESC'
}] as SortParam[];
