<div class="modal-backdrop fade in"></div>
<div class="modal show">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div class="row row-padding-10">
                    <div class="col">
                        <div class="payment-cards-list-wrapper">
                            <app-payment-cards-list #paymentCardsList
                                                    id="paymentCardsList"
                                                    [controls]="paymentCardsListControls"
                                                    [returnUrl]="returnUrl"
                                                    [addNewCardModalSize]="12"
                                                    [onlyCard]="onlyCard"
                                                    (proceedPayment$)="proceedPayment($event)">
                            </app-payment-cards-list>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button (click)="cancel()" type="button" class="btn btn-secondary"
                            data-dismiss="modal">{{ dismissButtonText | localize}}</button>
                </div>
            </div>
        </div>
    </div>
</div>
