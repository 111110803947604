import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {DashboardPageComponent} from '@src/app/pages/dashboard-page/dashboard-page.component';
import {SharedModule} from '@src/app/modules/shared/shared.module';
import { PublisherDashboardComponent } from './components/publisher-dashboard/publisher-dashboard.component';
import { ViewerDashboardComponent } from './components/viewer-dashboard/viewer-dashboard.component';

@NgModule({
    declarations: [DashboardPageComponent, PublisherDashboardComponent, ViewerDashboardComponent],
    imports: [
        CommonModule,
        SharedModule
    ]
})
export class DashboardPageModule { }
