import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {take, takeUntil} from 'rxjs/operators';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import {CmsService, DatepickerComponent, IStreamGenre} from 'ui-elements';
import {IFilterListItem, IListFilterItem, IListQuery} from '@src/app/components/media-list/media-list.component';
import {INPUT_TYPES, IUnitKeyValue} from 'ui-elements';
import {LocalizationProvider} from 'ui-elements';
import {MEDIA_FILTERS_FIELDS, MediaListService} from "@src/app/services/media-list/media-list.service";
import {ISuggestedMediaFilters} from "@src/app/pages/public-media-page/public-media-page.component";
import {ITimeRangeConfig} from 'ui-elements';
import {ITimerange} from 'ui-elements';
import {IMediaCardConfig, IMediaCardElements} from '@src/app/components/media-card/media-card.component';

@Component({
    selector: 'app-media-list-header',
    templateUrl: './media-list-header.component.html',
    styleUrls: ['./media-list-header.component.sass']
})
export class MediaListHeaderComponent implements OnInit {
    filtersForm: FormGroup;
    filtersList: IFilterListItem[];
    oldFilters: IFilterListItem[];
    genres: IStreamGenre[] = [];
    @Input() title: string;
    @Input() config: IMediaHeaderConfig;

    @Input() set filters(filters: ISuggestedMediaFilters) {
        this._filters = filters?.filterTypes || [];

        if (!this._filters?.length) {
            this.filtersList = [];
            return;
        }

        this.mediaListService.getMediaListFilters(filters).pipe(take(1)).subscribe(filtersOptions => {
            this.buildFiltersOptions(filtersOptions);
        });
    }

    public _filters: FILTER_TYPES[];
    @Input() borderTop = false;
    @Input() activeQuery: IListQuery = {
        paging: {
            page: 0,
            itemsOnPage: 6
        },
        sort: [
            {
                order: 'DESC',
                field: 'DATE'
            }
        ],
        filters: []
    };
    @Input() implicitFilters: IListFilterItem[] = [];
    @Output() public filter$ = new EventEmitter<IListQuery>();
    INPUT_TYPES = INPUT_TYPES;

    constructor(
        private fb: FormBuilder,
        private destroy$: AutoDestroyService,
        private cmsService: CmsService,
        private localizationProvider: LocalizationProvider,
        private mediaListService: MediaListService
    ) {
    }

    ngOnInit(): void {
        this.filtersForm = this.fb.group({});

        this._filters.forEach(filter => {
            this.filtersForm.addControl(filter, new FormControl('', []));
        });

        if (this.activeQuery && Object.keys(this.activeQuery)?.length) {
            this.filtersForm.patchValue(this.activeQuery);
        }

        this.filtersForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(newValue => {
            this.doFilter(newValue);
        });

        this.oldFilters = this.filtersForm.value;
    }

    doSearch(searchTerm): void {
        this.activeQuery.searchTerm = searchTerm || null;
        this.filter$.emit(this.activeQuery);
    }

    doFilterByTimeRange(timeRangeFilter: ITimerange): void {
        this.activeQuery.timeRange = timeRangeFilter;
        this.filter$.emit(this.activeQuery);
    }

    doFilter(filtersSelection): void {
        if (!this.detectFiltersChanged(filtersSelection)) {
            return;
        }

        this.oldFilters = this.filtersForm.value;

        Object.assign(this.activeQuery, this.buildQuery(filtersSelection));
        this.filter$.emit(this.activeQuery);
    }

    buildQuery(filtersSelection): IListQuery {
        const ret: IListQuery = {
            filters: []
        };
        Object.keys(filtersSelection).forEach(key => {
            if (!filtersSelection[key] || filtersSelection[key] === 'all') {
                return;
            }
            if (key === FILTER_TYPES.SORT) {
                ret.sort = [{
                    order: filtersSelection[FILTER_TYPES.SORT],
                    field: 'DATE'
                }];
                return;
            }
            if (key === FILTER_TYPES.HAS_ACCESS_MEDIA_GROUP) {
                ret.filters.push({
                    field: MEDIA_FILTERS_FIELDS.GROUP_ID,
                    value: filtersSelection[key]
                });
                return;
            }
            if (key === FILTER_TYPES.PUBLISHER_GROUP) {
                ret.filters.push({
                    field: MEDIA_FILTERS_FIELDS.GROUP_ID,
                    value: filtersSelection[key]
                });
                return;
            }
            ret.filters.push({
                field: key,
                value: filtersSelection[key]
            });
        });
        return ret;
    }

    detectFiltersChanged(filtersSelection): boolean {
        for (let key in filtersSelection) {
            if (this.oldFilters[key] !== filtersSelection[key]) {
                return true;
            }
        }
        return false;
    }

    buildFiltersOptions(filtersOptions): void {
        this.filtersList = [];
        Object.keys(filtersOptions).forEach(filterOptionKey => {
            const selectOptions = this.resolveFilterSelectOptions(filtersOptions[filterOptionKey], filterOptionKey as FILTER_TYPES);
            if (filterOptionKey !== FILTER_TYPES.SORT) {
                selectOptions.unshift({
                    key: 'all',
                    value: this.localize('media-list.filters.all')
                });
            }
            this.filtersList.push({
                name: filterOptionKey,
                placeholder: `media-list.filters.${filterOptionKey.toLowerCase()}.placeholder`,
                selectOptions,
                isAutocomplete: filterOptionKey === FILTER_TYPES.COUNTRY
            });
        });
    }

    resolveFilterSelectOptions(filtersOptionsList, filterType: FILTER_TYPES): IUnitKeyValue[] {
        switch (filterType) {
            case FILTER_TYPES.SORT: {
                const sortOptions = [];
                filtersOptionsList.forEach(filterOptionData => [
                    sortOptions.push(
                        {
                            key: 'ASC',
                            value: this.localize(`media-list.filters.${filterType.toLowerCase()}.${filterOptionData.toLowerCase()}.asc`)
                        },
                        {
                            key: 'DESC',
                            value: this.localize(`media-list.filters.${filterType.toLowerCase()}.${filterOptionData.toLowerCase()}.desc`)
                        }
                    )
                ]);
                return sortOptions;
            }
            case FILTER_TYPES.COUNTRY: {
                filtersOptionsList.sort((a, b) => a.name.localeCompare(b.name));
                return filtersOptionsList.map(filterOptionData => ({
                    key: filterOptionData.code,
                    value: this.localize(filterOptionData.name)
                }));
            }
            case FILTER_TYPES.GENRE: {
                return filtersOptionsList.map(filterOptionData => ({
                    key: filterOptionData.id,
                    value: this.localize(filterOptionData.name)
                }));
            }
            case FILTER_TYPES.HAS_ACCESS_MEDIA_GROUP:
            case FILTER_TYPES.PUBLISHER_GROUP: {
                return filtersOptionsList.map(filterOptionData => ({
                    key: filterOptionData.id,
                    value: filterOptionData.name
                }));
            }
            default: {
                return filtersOptionsList.map(filterOptionData => ({
                    key: filterOptionData,
                    value: this.localize(`media-list.filters.${filterType.toLowerCase()}.${filterOptionData.toLowerCase()}`)
                }));
            }
        }

    }

    localize(key): string {
        return this.localizationProvider.getByKey(key);
    }
}

export interface IMediaHeaderConfig {
    withSearch?: boolean;
    cssClass?: string;
    filtersCssClass?: string;
    filtration?: boolean;
    timeRangeConfig?: ITimeRangeConfig;
}

export interface IMediaListsConfig extends IMediaHeaderConfig {
    filters?: ISuggestedMediaFilters;
    cardBackgroundCssClass?: string;
    skeletonBackgroundClass?: string;
    smallLists?: boolean;
    mediaCardElements?: IMediaCardElements;
    hideMediaPrice?: boolean;
    basicSkeleton?: boolean;
    queryParams?: {[key: string]: any};
    cardConfig?: IMediaCardConfig;
}

export enum FILTER_TYPES {
    SORT = 'SORT',
    MEDIA_TYPE = 'MEDIA_TYPE',
    GENRE = 'GENRE',
    CONTENT_ACCESS_TYPE = 'CONTENT_ACCESS_TYPE',
    COUNTRY = 'COUNTRY',
    HAS_ACCESS_MEDIA_GROUP = 'HAS_ACCESS_MEDIA_GROUP',
    PUBLISHER_GROUP = 'PUBLISHER_GROUP'
}

export enum FILTER_MEDIA_TYPES {
    STREAM = 'STREAM',
    VIDEO_ON_DEMAND = 'VIDEO',
    STREAM_RECORDING = 'STREAM_RECORDING'
}


