import { Injectable } from '@angular/core';
import {ApiService} from 'ui-elements';
import {Observable} from 'rxjs/internal/Observable';
import {IPagedResponse, PagingParams, SortParam} from '@src/app/models/response.model';
import {API_URLS_APP, UrlGenerator} from '@src/app/constants/api-urls.constant';
import {IPrice} from "@src/app/models/stream.model";
import {IListFilterItem} from "@src/app/components/media-list/media-list.component";
import {MEDIA_TYPE} from '@src/app/components/streamdust-player/constants/mediaType';

@Injectable()
export class BillsService {

  constructor(private apiService: ApiService) {}

  public getBills(searchTerm: string = '', filters: IListFilterItem[], paging: PagingParams = DEFAULT_PAGING, sort: SortParam[] = DEFAULT_BILLS_SORT): Observable<IPagedResponse<IBill>> {
      return this.apiService.post(API_URLS_APP.BILLS_LIST, {
          filters,
          paging,
          sort,
          searchTerm
      });
  }

    public downloadPDFPublisher(id: number): Observable<any> {
        return this.apiService.get(UrlGenerator.generate(API_URLS_APP.BILLS_PDF_PUBLISHER, {id}),
            {responseType: 'blob', observe: 'response'});
    }

    public downloadPDFPublisherChargeBack(id: number): Observable<any> {
        return this.apiService.get(UrlGenerator.generate(API_URLS_APP.BILLS_PDF_PUBLISHER_CHARGEBACK, {id}),
            {responseType: 'blob', observe: 'response'});
    }

    public downloadPDFViewer(id: number): Observable<any> {
        return this.apiService.get(UrlGenerator.generate(API_URLS_APP.BILLS_PDF_VIEWER, {id}),
            {responseType: 'blob', observe: 'response'});
    }
}

export interface IBill {
    purchaseId: number;
    title?: string;
    type: BILL_TYPE;
    mediaType: BILL_MEDIA_TYPE;
    billNumber: number;
    date: number;
    amount: IPrice;
    status: BILL_STATUS;
    dataSize?: {
        value: number
        unit: string;
    };
    period?: {
        starDate: number;
        endDate: number;
    };
    subscriptionType?: SUBSCRIPTION_TYPE;
    chargeBack?: boolean;
}

export enum BILL_TYPE {
    MEDIA = 'MEDIA',
    SUBSCRIPTION = 'SUBSCRIPTION',
    BANDWIDTH = 'BANDWIDTH',
    STORAGE_SPACE = 'STORAGE_SPACE',
    MULTIBITRATE = 'MULTIBITRATE',
}

export enum BILL_STATUS {
    PAID = 'PAID',
    UNPAID = 'UNPAID',
    REFUNDED = 'REFUNDED',
}

export enum BILL_MEDIA_TYPE {
    VOD = 'VOD',
    LS = 'LS'
}

export enum SUBSCRIPTION_TYPE {
    ADVANCED = 'Advanced'
}

const DEFAULT_BILLS_SORT = [{
    field: 'DATE',
    order: 'DESC'
}] as SortParam[];

const DEFAULT_PAGING = {
    page: 0,
    itemsOnPage: 10
} as PagingParams;
