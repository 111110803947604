import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChangePasswordPageComponent } from '@src/app/pages/change-password-page/change-password-page.component';
import { SharedModule } from '@src/app/modules/shared/shared.module';

@NgModule({
  declarations: [ChangePasswordPageComponent],
  imports: [
    CommonModule,
    SharedModule,
  ],
  exports: [ChangePasswordPageComponent]
})
export class ChangePasswordPageModule { }
