import {EventEmitter, Injectable} from '@angular/core';
import {BehaviorSubject, Observable, ReplaySubject, Subject} from 'rxjs';

export interface IHeaderTitle {
    title: string;
    itemsCounter?: number;
    avatar?: string;
    profileId?: string;
    partnerStatus?: Array<string>;
    messagingIconLink?: string;
    contactStatus?: string;
}

export enum HeaderBtnTypes {
    ADD,
    SEARCH,
    SEARCH_REQUESTS
}

export enum HeaderPopupType {
    NONE,
    LOGIN,
    EMAIL_SENT,
    FORGOT_PASSWORD,
    CHANGE_FORGOTTEN_PASSWORD,
    REGISTRATION_SET_PASSWORD
}

@Injectable()
export class HeaderService {
  public headerEmitter = new ReplaySubject(1);
  public languageChanged$: EventEmitter<boolean> = new EventEmitter();
  public hideRoundingEmitter: EventEmitter<boolean> = new EventEmitter();

    private headerBtnClicked$: Subject<HeaderBtnTypes> = new Subject();
    private headerSearchText$: Subject<string> = new Subject();

    private activePopupSubject: BehaviorSubject<HeaderPopupType> = new BehaviorSubject(HeaderPopupType.NONE);
    public isTokenExpiredPage: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public activePopup$ = this.activePopupSubject.asObservable();

    constructor() {
    }

  public clearHeader(): void {
    this.headerEmitter.next({
      title: '',
      itemsCounter: 0
    });
  }


    public onHeaderBtnClicked(btn: HeaderBtnTypes): void {
        this.headerBtnClicked$.next(btn);
    }

    public onSearchTextChanged(text: string): void {
        this.headerSearchText$.next(text);
    }

    public getHeaderBtnClickedObs(): Observable<HeaderBtnTypes> {
        return this.headerBtnClicked$.asObservable();
    }

    public getHeaderSearchTextObs(): Observable<string> {
        return this.headerSearchText$.asObservable();
    }

    public showHeaderPopup(modalType: HeaderPopupType) {
        this.activePopupSubject.next(modalType);
    }

    public hideHeaderPopup() {
        this.activePopupSubject.next(HeaderPopupType.NONE);
    }
}
