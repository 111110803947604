<div class="panel panel-md">
    <div class="panel-header">
        <h2><b>{{configPage.title | localize}}</b></h2>
        <p class="text-sm">{{configPage.description | localize}}</p>
    </div>
    <div class="panel-body bg-white padding-bottom-30">
        <div class="section-panel">
            <div class="section-panel-header">
                <h4><b>{{configPage.tableTitle | localize}}</b></h4>
            </div>
        </div>
        <ano-table class="leads-table stream-overlay-table" [class.course-table]="overlaysType === StreamMarketingToolsOverlaysTypes.COURSES" #tableComponent [tableConfig]="config"
                   [withSearch]="true"
                   [defaultSortField]="overlaysType === StreamMarketingToolsOverlaysTypes.LEADGENERATION ? 'FIRST_NAME' : 'VIEW_DATE'"
                   (search$)="search($event)"
                   [buttonsConfig]="[tableButtonConfig]"></ano-table>
    </div>
</div>

<ng-template #statusTmpl let-data="rowData">
    <i class="icon text-primary" [class.icon-close]="!data.verified" [class.icon-check]="data.verified"></i>
</ng-template>

<ng-template #actionsTmpl let-data="rowData">
    <div class="table-actions-list">
        <i (click)="deleteLead(data.accountId?.internalId)"
           class="icon icon-trash text-danger cursor-pointer"></i>
    </div>
</ng-template>

<ng-template #dateTmpl let-data="rowData">
    {{data.date | dateTimeFormat: 'DD MMM YYYY, HH:mm'}}
</ng-template>
<ng-template #dateTmplCourses let-data="rowData">
    {{data.viewDate | dateTimeFormat: 'DD MMM YYYY, HH:mm'}}
</ng-template>
