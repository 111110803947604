<ng-container *ngIf="!failed">
    <div class="card bg-white">
        <div style="width: 100%; height: 600px; display: flex;justify-content: center; align-items: center">
            <h1>Will be implemented soon</h1>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="failed">
    <section class="section mh-480">
        <div class="container">
            <app-token-expired-page [title]="'error.' + errorMessage"></app-token-expired-page>
            <!-- <div class="bg-light registration-complete">
                <app-info-box>
                    <ng-container role="message" class="mb-0">
                       {{'error.' + errorMessage | localize}}
                    </ng-container>
                </app-info-box>
            </div> -->
        </div>
    </section>
</ng-container>

<!--<div class="profile-section login-section">-->
<!--  <div class="container">-->
<!--        <span class="main-icon-label">-->
<!--            <i class="icon-person"></i>-->
<!--        </span>-->
<!--  </div>-->
<!--</div>-->

<!--<div class="content content-profile">-->
<!--  <div class="container">-->
<!--&lt;!&ndash;    <div class="cards-list">&ndash;&gt;-->
<!--&lt;!&ndash;      <a class="box card-item">&ndash;&gt;-->
<!--&lt;!&ndash;        <span class="ava ava-sm">&ndash;&gt;-->
<!--&lt;!&ndash;            <img src="../../../assets/mock/ava1.jpg" alt="">&ndash;&gt;-->
<!--&lt;!&ndash;        </span>&ndash;&gt;-->
<!--&lt;!&ndash;        <span class="card-content">&ndash;&gt;-->
<!--&lt;!&ndash;            <span class="card-subtitle">&ndash;&gt;-->
<!--&lt;!&ndash;              <ng-container *ngIf="loading">&ndash;&gt;-->
<!--&lt;!&ndash;                Loading...&ndash;&gt;-->
<!--&lt;!&ndash;              </ng-container>&ndash;&gt;-->

<!--&lt;!&ndash;              <ng-container *ngIf="!loading && isConfirmed">&ndash;&gt;-->
<!--&lt;!&ndash;                Your email successfully confirmed&ndash;&gt;-->
<!--&lt;!&ndash;              </ng-container>&ndash;&gt;-->

<!--&lt;!&ndash;              <ng-container *ngIf="!loading && !isConfirmed">&ndash;&gt;-->
<!--&lt;!&ndash;                An error occurred&ndash;&gt;-->
<!--&lt;!&ndash;              </ng-container>&ndash;&gt;-->
<!--&lt;!&ndash;            </span>&ndash;&gt;-->
<!--&lt;!&ndash;        </span>&ndash;&gt;-->
<!--&lt;!&ndash;      </a>&ndash;&gt;-->
<!--&lt;!&ndash;    </div>&ndash;&gt;-->

<!--    <app-info-box *ngIf="loading"-->
<!--                  class="d-block mb-3"-->
<!--                  [type]="INFO_BOX_TYPES.INFO">-->
<!--      <ng-container role="title">{{ 'emailConfirmation.label.loading' | localize }}</ng-container>-->
<!--      <ng-container role="message">-->
<!--        {{ 'emailConfirmation.label.pleaseWaitWhileYourEmailConfirmationStatusIsLoading' | localize }}-->
<!--      </ng-container>-->

<!--      <ng-container role="buttons">-->
<!--        <ano-button-->
<!--          [config]="{-->
<!--          styleType: 'secondary',-->
<!--          sizeType: 'block'-->
<!--        }"-->
<!--          routerLink="login">-->
<!--          {{ 'emailConfirmation.button.backToLogin' | localize }}-->
<!--        </ano-button>-->
<!--      </ng-container>-->
<!--    </app-info-box>-->

<!--    <app-info-box *ngIf="!loading && isConfirmed"-->
<!--                  class="d-block mb-3"-->
<!--                  [type]="INFO_BOX_TYPES.SUCCESS">-->
<!--      <ng-container role="title">{{ 'emailConfirmation.label.done' | localize }}</ng-container>-->
<!--      <ng-container role="message">-->
<!--        {{ 'emailConfirmation.label.yourEmailSuccessfullyConfirmed' | localize }}-->
<!--      </ng-container>-->

<!--      <ng-container role="buttons">-->
<!--        <ano-button-->
<!--          [config]="{-->
<!--          type: 'button',-->
<!--          styleType: 'primary',-->
<!--          sizeType: 'block',-->
<!--          isDisabled: loading-->
<!--          }"-->
<!--          routerLink="home">-->
<!--          {{ 'emailConfirmation.button.continueUsingTheApplication' | localize }}-->
<!--        </ano-button>-->
<!--      </ng-container>-->
<!--    </app-info-box>-->

<!--    <app-info-box *ngIf="!loading && !isConfirmed"-->
<!--                  [type]="INFO_BOX_TYPES.FAILURE"-->
<!--                  class="d-block mb-3">-->
<!--      <ng-container role="title">{{ 'emailConfirmation.label.error' | localize }}</ng-container>-->
<!--      <ng-container role="message">-->
<!--        {{ errorMessage }}-->
<!--      </ng-container>-->

<!--      <ng-container role="buttons">-->
<!--        <ano-button-->
<!--          [config]="{-->
<!--          styleType: 'secondary',-->
<!--          sizeType: 'block'-->
<!--        }"-->
<!--          routerLink="login">-->
<!--          {{ 'emailConfirmation.button.backToLogin' | localize }}-->
<!--        </ano-button>-->
<!--      </ng-container>-->
<!--    </app-info-box>-->
<!--  </div>-->
<!--</div>-->
