import {AfterViewInit, Component, ElementRef, HostListener, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import {ActivatedRoute, Params} from '@angular/router';
import {filter, takeUntil, tap} from 'rxjs/operators';
import {CustomValidators, NEW_ID_PARAM, PRICE_VALIDATION_TYPE} from '@src/app/utils/custom-validators.util';
import {AbstractControlOptions, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {
    IStreamModel,
    IStreamStatuses,
    IVoucher,
    IVoucherRequest,
    PAYMENT_SYSTEM,
    StreamMetadataSelectValues,
    VOUCHER_SORTING_PROPERTY
} from '@src/app/models/stream.model';
import {IFormFieldsConfig, StreamService} from '@src/app/services/stream-metadata/stream.service';
import {Pagination} from '@src/app/models/sorting.model';
import {DATETIME_PICKER_TYPE, INPUT_TYPES, LocalizationProvider} from 'ui-elements';
import {saveAs} from 'file-saver/FileSaver';
import {getFileNameFromResponseContentDisposition} from '@src/app/utils/file-name-from-content-disposition.util';
import {MatDialog} from '@angular/material/dialog';
import {
    ITableButtonConfig,
    ITableColumn,
    ITableConfig,
    TableComponent
} from 'ui-elements';
import {GroupTableComponent, ITableGroupEntry} from '@src/app/components/group-table/group-table.component';
import {IVoucherGroupModel} from '@src/app/models/voucher.model';
import moment from 'moment';
import {VOUCHER_STATUS, VoucherGroupsService} from '@src/app/services/voucher-groups/voucher-groups.service';
import {LoadingService} from '@src/app/services/loading/loading.service';
import {DateHelper} from '@src/app/utils/date.helper';
import {COUPON_TYPE, INameId} from '@src/app/models/core.model';
import {MEDIA_TYPE} from '@src/app/components/streamdust-player/constants/mediaType';
import {BehaviorSubject} from 'rxjs';
import {ITEM_TYPE, SETTING_TYPE} from '@src/app/pipes/item-setting';
import {STREAM_STATUS} from '@src/app/components/streamdust-player/constants/status';

@Component({
    selector: 'app-stream-coupons',
    templateUrl: './stream-coupons.component.html',
    styleUrls: ['./stream-coupons.component.sass'],
    providers: [AutoDestroyService]
})
export class StreamCouponsComponent implements OnInit, AfterViewInit {
    public STREAM_STATUS = STREAM_STATUS;
    public DISCOUNT_TYPE = DISCOUNT_TYPE;
    public mediaType: MEDIA_TYPE;
    public tableConfig: ITableConfig<IVoucher>;
    @ViewChild('actionsTmpl', {static: true}) public actionsTmpl: TemplateRef<any>;
    @ViewChild('statusTmpl', {static: true}) public statusTmpl: TemplateRef<any>;
    @ViewChild('voucherGroupStatusTmpl', {static: true}) public voucherGroupStatusTmpl: TemplateRef<any>;
    @ViewChild('vouchersAmountTmpl', {static: true}) public vouchersAmountTmpl: TemplateRef<any>;
    @ViewChild('dateTmpl', {static: true}) public dateTmpl: TemplateRef<any>;
    @ViewChild('disposableTmpl', {static: true}) public disposableTmpl: TemplateRef<any>;
    @ViewChild('discountTmpl', {static: true}) public discountTmpl: TemplateRef<any>;
    @ViewChild('tableComponent', {static: false}) public table: TableComponent;
    @ViewChild('groupTableComponent', {static: false}) public groupTable: GroupTableComponent;
    @ViewChild('modalAddNew', {static: false}) modalAddNew: TemplateRef<any>;
    @ViewChild('modalEdit', {static: false}) modalEdit: TemplateRef<any>;
    @ViewChild('confirm', {static: false}) confirm: TemplateRef<any>;

    public searchQuery = '';
    public mediaId: string;
    public deactivateConfirmTitle: string;
    public deactivateConfirmBody: string;
    public data: IVoucher[] = [];
    public page = 0;
    public pagination: Pagination = new Pagination(VOUCHER_SORTING_PROPERTY[0].value);
    public INPUT_TYPES = INPUT_TYPES;
    public addCouponsForm = this.fb.group({
        amount: ['', [Validators.required, Validators.max(50000), Validators.min(1)]]
    });
    private streamMetadataSelectValues: StreamMetadataSelectValues = new StreamMetadataSelectValues(this.localizationProvider);

    public dropdownOpened = false;
    public groupId = '';

    public title = '';

    public VOUCHER_STATUS = VOUCHER_STATUS;

    public groupsList: INameId[] = [];

    public form: FormGroup;

    public formConfig: IFormFieldsConfig[][] = [];

    public formConfigMock: IFormFieldsConfig[][] = [];

    public editGroupFormConfig: IFormFieldsConfig[][] = [
        [
            {
                name: 'id',
                hidden: true,
                config: {
                    inputType: INPUT_TYPES.INPUT,
                }
            },
        ],
        [
            {
                name: 'expiryDate',
                config: {
                    inputType: INPUT_TYPES.DATETIME,
                    datetimePickerType: DATETIME_PICKER_TYPE.CALENDAR,
                    label: 'coupons.date',
                    isRequired: true,
                    halfSize: true
                }
            },
        ],
        [
            {
                name: 'vouchersAmount',
                config: {
                    inputType: INPUT_TYPES.INPUT,
                    max: 999,
                    mask: '000',
                    min: 1,
                    label: 'coupons.count',
                    isRequired: true,
                    halfSize: true
                }
            },
        ],
        [
            {
                name: 'disposable',
                config: {
                    inputType: INPUT_TYPES.SELECT,
                    label: 'coupons.field.deactivate',
                    selectOptions: this.streamMetadataSelectValues.getConfirmation(),
                    halfSize: true
                }
            },
        ],
    ];

    public editForm = this.fb.group({
        id: ['', [Validators.required]],
        expiryDate: ['', [Validators.required]],
        vouchersAmount: ['', [Validators.required]],
        disposable: ['', [Validators.required]],
    });

    public couponsFieldsConfig = [
        [{
            name: 'amount',
            config: {
                inputType: INPUT_TYPES.INPUT,
                type: 'number',
                placeholder: 'Anzahl zu erstellender Gutscheine eingeben',
                isRequired: true
            }
        }]
    ];

    public streamStatuses: IStreamStatuses;

    public couponGroupsColumns: ITableColumn[];
    public couponGroupsData: ITableGroupEntry[];

    public couponsGroupsTableConfig: ITableConfig<IVoucherGroupModel>;
    private currency: string;
    private maxPriceValidator: any;

    SETTING_TYPE = SETTING_TYPE;
    ITEM_TYPE = ITEM_TYPE;

    public tableButtonConfig: ITableButtonConfig = {
        buttonConfig: {styleType: 'success', sizeType: 'block'},
        text: 'CODES DOWNLOADEN(.CSV)',
        action: () => this.downloadCsv()
    };

    constructor(
        public dialog: MatDialog,
        private readonly destroy$: AutoDestroyService,
        private readonly activatedRoute: ActivatedRoute,
        private fb: FormBuilder,
        public streamService: StreamService,
        private voucherGroupsService: VoucherGroupsService,
        public loadingService: LoadingService,
        private elementRef: ElementRef,
        private localizationProvider: LocalizationProvider,
    ) {
    }

    ngOnInit() {
        this.title = this.localizationProvider.getByKey('coupons.edit');
        this.loadingService.loadingStart();
        this.createCouponsForm();
        this.streamService.stream$.pipe(takeUntil(this.destroy$))
            .subscribe((res) => {
                if (!res) {
                    return;
                }
                this.removeFreeCoupon(res);
                const currency = res?.media?.price?.currency;
                const currencySymbol = StreamMetadataSelectValues.getCurrencies().find(item => item.key === currency);
                if (currencySymbol) {
                    this.currency = (currencySymbol.value as string);
                }
                // this.maxPrice = res.media.price.amount;
                this.maxPriceValidator = [CustomValidators.priceAmount(res.media.price.amount, PRICE_VALIDATION_TYPE.MAX)];

            });
        this.couponGroupsColumns = [
            {
                name: 'coupons.groups.name',
                dataField: 'name'
            },
            {
                name: 'coupons.groups.type',
                dataField: 'article'
            },
            {
                name: 'coupons.date',
                dataField: 'expiryDate'
            },
            {
                name: 'coupons.allVouchersToRedeemed',
                dataField: 'vouchersAmount'
            },
            {
                name: 'coupons.deactivate',
                dataField: 'disposable'
            },
            {
                name: 'coupons.discount.amount',
                dataField: 'value'
            },
        ];
        this.activatedRoute.parent.params.pipe(
            takeUntil(this.destroy$),
            tap(({id}: Params) => {
                this.mediaId = id;
            }),
            filter(({id}: Params) => id !== NEW_ID_PARAM)
        ).subscribe(() => {
            this.mediaType = this.resolveMediaType();
            this.getGroups();
            this.couponsGroupsTableConfig = {
                dataField: 'groups',
                searchFn: (sortParams, pagingParams) => {
                    return this.voucherGroupsService.getGroups(this.mediaId, this.mediaType, this.searchQuery, pagingParams);
                },
                columns: [
                    {
                        name: 'coupons.groups.name',
                        dataField: 'name'
                    },
                    {
                        name: 'coupons.groups.type',
                        dataField: 'article'
                    },
                    {
                        name: 'coupons.date',
                        tmpl: this.dateTmpl
                    },
                    {
                        name: 'coupons.allVouchersToRedeemed',
                        tmpl: this.vouchersAmountTmpl
                    },
                    {
                        name: 'coupons.deactivate',
                        tmpl: this.disposableTmpl
                    },
                    {
                        name: 'coupons.groups.status',
                        tmpl: this.voucherGroupStatusTmpl
                    },
                    {
                        name: 'coupons.discount.amount',
                        tmpl: this.discountTmpl
                    }
                ]

            };
            this.tableConfig = {
                dataField: 'vouchers',
                matPaginator: true,
                searchFn: (sortParams, pagingParams) => {
                    return this.streamService.getVouchers(this.groupId, this.searchQuery, pagingParams, sortParams).pipe(takeUntil(this.destroy$));
                },
                columns: [
                    {
                        name: 'Coupon',
                        sortField: 'CODE',
                        dataField: 'code'
                    },
                    {
                        name: 'coupons.groups.type',
                        dataField: 'article'
                    },
                    {
                        name: 'coupons.date',
                        tmpl: this.dateTmpl
                    },
                    {
                        name: 'Status',
                        tmpl: this.statusTmpl
                    },
                    {
                        name: 'coupons.discount.amount',
                        tmpl: this.discountTmpl,
                        class: 'right'
                    },
                    {
                        name: 'Aktionen',
                        tmpl: this.actionsTmpl
                    }
                ]
            };
            this.loadingService.loadingEnd();
        });
    }

    @HostListener('document: click', ['$event'])
    closeDropdown(event: any) {
        if (!this.elementRef.nativeElement.contains(event.target) && this.dropdownOpened) {
            this.dropdownOpened = false;
        }
    }

    private resolveMediaType(): MEDIA_TYPE {
        return (this.activatedRoute.data as BehaviorSubject<any>)?.value?.mediaType;
    }

    private removeFreeCoupon(res: IStreamModel) {
        this.formConfig = this.getVouchersGroupFormFieldsConfig();
        if ((res?.media?.paymentSystem?.paymentSystemGroup === PAYMENT_SYSTEM.LIMIT_ACCESS) && !res?.media?.paymentSystem?.psPayPerView) {
            this.formConfig = [
                [...this.formConfig[0]],
                [...this.formConfig[1]],
                [...this.formConfig[2]],
                [
                    {
                        name: 'type',
                        config: {
                            inputType: INPUT_TYPES.SELECT,
                            placeholder: 'coupons.field.discountType',
                            selectOptions: [
                                {
                                    value: 'coupons.field.discountType.fixedPrice',
                                    key: DISCOUNT_TYPE.FIXED_PRICE,
                                    selected: false
                                },
                                {
                                    value: 'coupons.field.discountType.free',
                                    key: DISCOUNT_TYPE.FREE,
                                    selected: false
                                }
                            ],
                        }
                    },
                    {
                        name: 'value',
                        hidden: true,
                        config: {
                            inputType: INPUT_TYPES.INPUT,
                            placeholder: 'coupons.discount.amount',
                            max: 99,
                            mask: '00',
                            min: 1,
                            appendText: '%',
                            halfSize: true
                        }
                    }
                ],
            ]
        } else {
            // this.formConfig = [ ...this.formConfigMock ]
        }
    }

    getVouchersGroupFormFieldsConfig(): IFormFieldsConfig[][] {
        return [
            [
                {
                    name: 'name',
                    config: {
                        inputType: INPUT_TYPES.INPUT,
                        placeholder: 'coupons.groups.name',
                        isRequired: true
                    }
                },
            ],
            [
                {
                    name: 'article',
                    config: {
                        inputType: INPUT_TYPES.SELECT,
                        placeholder: 'coupons.groups.type',
                        selectOptions: [
                            {
                                key: COUPON_TYPE.SINGLE,
                                value: COUPON_TYPE.SINGLE
                            },
                            {
                                key: COUPON_TYPE.MULTI,
                                value: COUPON_TYPE.MULTI
                            },
                        ],
                        isRequired: true,
                    }
                },
                {
                    name: 'expiryDate',
                    config: {
                        inputType: INPUT_TYPES.DATETIME,
                        datetimePickerType: DATETIME_PICKER_TYPE.CALENDAR,
                        placeholder: 'coupons.date',
                        isRequired: true,
                    }
                },
            ],
            [
                {
                    name: 'vouchersAmount',
                    config: {
                        inputType: INPUT_TYPES.INPUT,
                        placeholder: 'coupons.count',
                        max: 999,
                        mask: '000',
                        min: 1,
                        isRequired: true,
                        size: 12
                    }
                },
                {
                    name: 'disposable',
                    hidden: true,
                    config: {
                        inputType: INPUT_TYPES.SELECT,
                        placeholder: 'coupons.field.deactivate',
                        selectOptions: this.streamMetadataSelectValues.getConfirmation(),
                    }
                }
            ],
            [
                {
                    name: 'type',
                    config: {
                        inputType: INPUT_TYPES.SELECT,
                        placeholder: 'coupons.field.discountType',
                        selectOptions: [
                            {
                                value: 'coupons.field.discountType.discount',
                                key: DISCOUNT_TYPE.DISCOUNT,
                                selected: true
                            },
                            {
                                value: 'coupons.field.discountType.fixedPrice',
                                key: DISCOUNT_TYPE.FIXED_PRICE,
                                selected: false
                            },
                            {
                                value: 'coupons.field.discountType.free',
                                key: DISCOUNT_TYPE.FREE,
                                selected: false
                            }
                        ],
                    }
                },
                {
                    name: 'value',
                    hidden: true,
                    config: {
                        inputType: INPUT_TYPES.INPUT,
                        placeholder: 'coupons.discount.amount',
                        max: 99,
                        mask: '00',
                        min: 1,
                        appendText: '%',
                        halfSize: true
                    }
                }
            ],
        ];
    }

    public openAddCouponsModal(): void {
        const dialogRef = this.dialog.open(this.modalAddNew);
        dialogRef.afterClosed()
            .pipe(takeUntil(this.destroy$))
            .subscribe((res: boolean) => {
                if (res) {
                    this.submit();
                }
            });
    }

    public openEditGroupModal(group: IVoucherGroupModel): void {
        Object.keys(this.editForm.controls).forEach(control => {
            if (control === 'expiryDate') {
                this.editForm.get(control).patchValue(moment(group[control]).toDate());
                return;
            }
            this.editForm.get(control).patchValue(group[control]);
        });
        const dialogRef = this.dialog.open(this.modalEdit);
        dialogRef.afterClosed()
            .pipe(takeUntil(this.destroy$))
            .subscribe((res: boolean) => {
                if (res) {
                    this.updateGroup();
                    return;
                }

                this.clearEditForm();
            });
    }

    ngAfterViewInit() {
    }

    public search(event: string): void {
        this.searchQuery = event;
        this.table.refreshData({});
    }

    public downloadCsv(): void {
        this.streamService.exportVoucher(this.mediaType, this.mediaId)
            .pipe(takeUntil(this.destroy$))
            .subscribe(res => {
                saveAs(
                    res.body,
                    getFileNameFromResponseContentDisposition(res),
                );
            });
    }

    public submit(): void {
        if (this.addCouponsForm.invalid) {
            return;
        }
        const body: IVoucherRequest = {
            streamId: this.mediaId,
            amount: this.addCouponsForm.get('amount').value
        };
        this.streamService.createVoucher(body)
            .pipe()
            .subscribe(res => {
                this.table.resetTableData();
                this.table.refreshData({});
                this.addCouponsForm.reset();
            });
    }

    public createGroup(): void {
        this.form.markAllAsTouched();
        if (this.form.invalid) {
            return;
        }

        const payload: IVoucherGroupModel = this.form.value;

        delete payload.freeVoucher;
        payload.expiryDate = moment(payload.expiryDate).endOf('day').toDate().getTime();
        payload.productId = this.mediaId;
        payload.productType = this.mediaType; //TODO use proper product type

        this.loadingService.loadingStart();

        this.voucherGroupsService.createGroup(payload)
            .pipe(takeUntil(this.destroy$))
            .subscribe((res) => {
                this.loadingService.loadingEnd();
                if (!res.success) {
                    return;
                }
                this.getGroups(true);
                this.form.reset();
                // this.form.get('type').patchValue('');
                this.form.markAsUntouched();
                this.form.markAsPristine();
                // return;
                if (this.table) {
                    this.table.resetTableData();
                    this.table.refreshData({});
                }
                if (this.groupTable) {
                    this.groupTable.refreshTable();
                }
            }, () => this.loadingService.loadingEnd());
    }

    private createCouponsForm(): void {
        this.form = this.fb.group({
            name: ['', [Validators.required]],
            article: ['', [Validators.required]],
            expiryDate: ['', [Validators.required]],
            vouchersAmount: ['', [Validators.required]],
            disposable: ['', [Validators.required]],
            value: ['', [Validators.required]],
            type: ['', [Validators.required]]
        });
        this.listenDiscountTypeChange();
        this.listenCouponTypeChange();
    }

    private listenDiscountTypeChange(): void {
        this.form.get('type').valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((type) => {
                if (!type) {
                    return;
                }
                const discountField = this.formConfig.find(row => row.find(field => field.name === 'value'));
                if (!discountField) {
                    return;
                }

                switch (type) {
                    case DISCOUNT_TYPE.DISCOUNT:
                        discountField[1].hidden = false;
                        discountField[1].config = {
                            inputType: INPUT_TYPES.INPUT,
                            placeholder: 'coupons.discount.amount',
                            max: 99,
                            mask: '00',
                            min: 1,
                            appendText: '%',
                            size: 6
                        };
                        this.form.get('value').setValue('');
                        this.form.get('value').clearValidators();
                        this.form.get('value').setValidators([Validators.required]);
                        break;
                    case DISCOUNT_TYPE.FIXED_PRICE:
                        discountField[1].hidden = false;
                        discountField[1].config = {
                            inputType: INPUT_TYPES.CURRENCY_AMOUNT,
                            placeholder: 'coupons.field.discountType.fixedPrice.cost',
                            isRequired: true,
                            showErrorsOnDirty: true,
                            size: 6,
                        };
                        this.form.get('value').setValue('');
                        this.form.get('value').clearValidators();
                        this.form.get('value').setValidators([Validators.required, ...this.maxPriceValidator]);
                        break;
                    case DISCOUNT_TYPE.FREE:
                        discountField[1].hidden = true;
                        discountField[1].config.mask = '000';
                        this.form.get('value').setValue(100);
                        break;

                }
            });

    }

    private listenCouponTypeChange(): void {
        this.form.get('article').valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((type) => {
                if (!type) {
                    return;
                }

                this.form.get('disposable').patchValue(type === COUPON_TYPE.SINGLE);
            });
    }

    public getDate(timestamp: any): string {
        return DateHelper.formatDate(timestamp);
    }

    public getDisposable(value: boolean): string {
        return (this.streamMetadataSelectValues.getConfirmation().find(item => item.key === value).value as string);
    }

    public toggleFilter(): void {
        this.dropdownOpened = !this.dropdownOpened;
    }

    public filter(group: INameId): void {
        this.groupId = group.id;
        this.groupsList.forEach(_group => _group.selected = false);
        group.selected = true;
        this.toggleFilter();
        this.table.resetTableData();
        this.table.refreshData({});
    }

    public getCurrentFilter(): string {
        let res = '';
        const found = this.groupsList.find(group => group.selected);
        if (!found) {
            return res;
        }

        res = found.name;
        return res;
    }

    public updateGroup(): void {
        this.loadingService.loadingStart();
        this.editForm.get('expiryDate').patchValue(moment(this.editForm.get('expiryDate').value).toDate().getTime());
        this.voucherGroupsService.updateGroup(this.editForm.value)
            .pipe(takeUntil(this.destroy$))
            .subscribe(res => {
                this.loadingService.loadingEnd();
                this.clearEditForm();
                this.getGroups(true);
                this.groupTable.refreshTable();
                if (!res) {
                    return;
                }
            }, () => this.loadingService.loadingEnd());
    }

    private getGroups(preventDefaultSelect?: boolean): void {
        this.voucherGroupsService.getAllGroups(this.mediaId, this.mediaType)
            .pipe(takeUntil(this.destroy$))
            .subscribe((groups) => {
                this.groupsList = groups;
                if (this.groupsList.length === 1) {
                    preventDefaultSelect = false;
                }
                if (this.groupsList.length && !preventDefaultSelect) {
                    this.groupsList[0].selected = true;
                    this.groupId = this.groupsList[0].id;
                }
            });
    }

    private clearEditForm(): void {
        Object.keys(this.editForm.controls).forEach(control => this.editForm.get(control).patchValue(null));
        this.editForm.markAsUntouched();
    }

    public deactivateCoupon(code: string): void {
        this.deactivateConfirmTitle = 'coupons.deactivate-coupon.title';
        this.deactivateConfirmBody = 'coupons.deactivate-coupon.body';
        const dialogRef = this.dialog.open(this.confirm);
        dialogRef.afterClosed()
            .pipe(takeUntil(this.destroy$))
            .subscribe((confirm: boolean) => {
                if (confirm) {
                    this.loadingService.loadingStart();
                    // TODO: Change to a proper product type
                    this.voucherGroupsService.deactivateCode(this.mediaId, code, this.mediaType)
                        .pipe(takeUntil(this.destroy$))
                        .subscribe(res => {
                            this.loadingService.loadingEnd();
                            if (!res) {
                                return;
                            }
                            this.table.resetTableData();
                            this.table.refreshData({});
                        }, () => this.loadingService.loadingEnd());
                }
            });
    }

    public deactivateGroup(groupId: string): void {
        this.deactivateConfirmTitle = 'coupons.deactivate-coupon-group.title';
        this.deactivateConfirmBody = 'coupons.deactivate-coupon-group.body';
        const dialogRef = this.dialog.open(this.confirm);
        dialogRef.afterClosed()
            .pipe(takeUntil(this.destroy$))
            .subscribe((confirm: boolean) => {
                if (confirm) {
                    this.loadingService.loadingStart();
                    this.voucherGroupsService.deactivateGroup(this.mediaId, groupId)
                        .pipe(takeUntil(this.destroy$))
                        .subscribe(res => {
                            this.loadingService.loadingEnd();
                            if (!res) {
                                return;
                            }
                            this.groupTable.refreshTable();
                            this.table.resetTableData();
                            this.table.refreshData({});
                        }, () => this.loadingService.loadingEnd());
                }
            });
    }
}

enum DISCOUNT_TYPE {
    FREE = 'FREE', DISCOUNT = 'DISCOUNT', FIXED_PRICE = 'FIXED_PRICE'
}
