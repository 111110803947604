import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {takeUntil} from "rxjs/internal/operators";
import {Router} from "@angular/router";
import {AutoDestroyService} from "@src/app/services/auto-destroy-service/auto-destroy.service";
import {ChangePasswordPageService} from "@src/app/pages/change-password-page/services/change-password-page/change-password-page.service";
import {INPUT_TYPES} from "ui-elements";
import {CustomValidators} from "@src/app/utils/custom-validators.util";
import {IFieldsConfig} from "@src/app/pages/user-profile/interfaces/interfaces-common";
import {AuthService} from "@src/app/services/auth/auth.service";
import {LocalizationProvider} from 'ui-elements';
import {HeaderService} from "@src/app/services/header/header.service";

@Component({
    selector: 'app-change-forgotten-password',
    templateUrl: './change-forgotten-password.component.html',
    styleUrls: ['./change-forgotten-password.component.sass'],
    providers: [AutoDestroyService]
})
export class ChangeForgottenPasswordComponent implements OnInit {

    @Input() showPopup = false;
    public loading = false;
    public form: FormGroup;
    public fieldsConfig: IFieldsConfig[] = [
        {
            name: 'password',
            config: {
                inputType: INPUT_TYPES.PASSWORD,
                type: 'password',
                label: this.localizationProvider.getByKey('change_forgotten_pass.form.new.password'),
                placeholder: '',
                isRequired: true,
            }
        },
        {
            name: 'passwordConfirm',
            config: {
                inputType: INPUT_TYPES.PASSWORD,
                label: this.localizationProvider.getByKey('change_forgotten_pass.form.repreat.password'),
                type: 'password',
                placeholder: '',
                isRequired: true
            }
        },
    ];

    constructor(private fb: FormBuilder,
                private changePasswordService: ChangePasswordPageService,
                private authService: AuthService,
                private headerService: HeaderService,
                private readonly destroy$: AutoDestroyService,
                private localizationProvider: LocalizationProvider,
                private router: Router) {
    }

    ngOnInit(): void {
        const passwordControl = new FormControl('',
            [Validators.required, CustomValidators.password]);
        this.form = this.fb.group({
            password: passwordControl,
            passwordConfirm: ['', [CustomValidators.passwordsMatch(passwordControl)]],
        });
    }

    public setPassword(): void {
        this.form.markAllAsTouched();
        if (this.form.invalid) {
            return;
        }

        this.loading = true;

        this.authService.storeTokenForCall(this.authService.tempToken);
        this.changePasswordService.forgotPassword(this.password.value)
            .pipe(takeUntil(this.destroy$))
            .subscribe((res) => {
                this.loading = false;
                if (!res.success) {
                    console.error(res.message);
                    return;
                }

                this.authService.storeToken(this.authService.tempToken);
                this.headerService.hideHeaderPopup();
                this.router.navigate(['home']);
            }, (err) => {
                this.loading = false;
                console.error(err);
            })
    }

    get password(): AbstractControl {
        return this.form.get('password');
    }
}
