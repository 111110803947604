import {Component, Input, OnChanges, OnDestroy} from '@angular/core';
import {IVideoStatus} from '@src/app/models/stream.model';
import {DateHelper} from '@src/app/utils/date.helper';
import {STREAM_STATUS} from '@src/app/components/streamdust-player/constants/status';
import * as moment from 'moment';
import {Duration} from 'moment';
import {DateUntilPipe} from '@src/app/pipes/date-until.pipe';

@Component({
    selector: 'app-timeline',
    templateUrl: './timeline.component.html',
    styleUrls: ['./timeline.component.sass'],
    providers: [DateUntilPipe]
})
export class TimelineComponent implements OnDestroy, OnChanges {
    @Input() status: IVideoStatus;
    @Input() startAt: number;
    @Input() scheduledStart: number;
    @Input() endAt: number;
    @Input() scheduledEnd: number;
    @Input() customDuration: number;
    @Input() customScheduledDuration: number;

    STREAM_STATUS = STREAM_STATUS;

    formattedDuration: string;
    formattedActualDuration: string;

    now = new Date().getTime();

    refreshOneMinInterval: ReturnType<typeof setInterval>;
    refreshOneSecInterval: ReturnType<typeof setInterval>;
    durationInterval: ReturnType<typeof setInterval>;

    leftPanelClass: string;
    rightPanelClass: string;

    constructor() {
    }

    ngOnChanges(): void {
        this.customDuration = this.customDuration || this.endAt - this.startAt;
        this.customScheduledDuration = this.scheduledEnd - this.scheduledStart;
        this.formatScheduledDuration();
        this.formatDuration();
        this.initRefreshInterval();
    }

    ngOnDestroy(): void {
        this.clearIntervals();
    }

    initRefreshInterval(): void {
        this.clearIntervals();

        this.refreshInterval();

        this.refreshOneMinInterval = setInterval(() => {
            this.refreshInterval();
        }, 60000);
    }

    refreshInterval() {
        if (this.countdownOneSecFrequency()) {
            if (!this.refreshOneSecInterval) {
                this.refreshNow();
                this.refreshOneSecInterval = setInterval(() => {
                    this.refreshNow();
                }, 1000);
            }
        } else {
            clearInterval(this.refreshOneSecInterval);
            this.refreshOneSecInterval = null;
            this.refreshNow();
        }
    }

    clearIntervals(): void {
        clearInterval(this.refreshOneMinInterval);
        this.refreshOneMinInterval = null;
        clearInterval(this.refreshOneSecInterval);
        this.refreshOneSecInterval = null;
    }

    refreshNow(): void {
        this.now = new Date().getTime();
    }

    countdownOneSecFrequency(): boolean {
        return (DateHelper.timeInRange(this.startAt, 10) || DateHelper.timeInRange(this.endAt, 10));
    }

    formatScheduledDuration(): void {
        let d: Duration;
        d = moment.duration(this.customScheduledDuration);
        this.formattedDuration = Math.floor(d.asHours()) + moment.utc(this.customScheduledDuration).format(':mm') + moment.utc(this.customScheduledDuration).format(':ss') + 's';
    }

    formatDuration(): void {
        let d: Duration;
        let sheduledDuration: Duration = moment.duration(this.customScheduledDuration)
        let duration = this.status?.live?.duration || new Date().getTime() - this.startAt;
        if (this.durationInterval) {
            clearInterval(this.durationInterval);
        }

        if (Math.floor(sheduledDuration.asHours()) <= Math.floor(moment.duration(duration).asHours())) {
            duration = this.customScheduledDuration;
        }

        if ([STREAM_STATUS.PAUSED, STREAM_STATUS.FINISHED, STREAM_STATUS.ACTIVATED, STREAM_STATUS.CONNECTED, STREAM_STATUS.ACTIVATION].includes(this?.status?.stream)) {
            d = moment.duration(duration || this.customDuration);
            this.formattedActualDuration = Math.floor(d.asHours()) + moment.utc(duration || this.customDuration).format(':mm') + moment.utc(duration || this.customDuration).format(':ss') + 's';
            return;
        }

        if ([STREAM_STATUS.LIVE].includes(this?.status?.stream) && Math.floor(sheduledDuration.asHours()) >= Math.floor(moment.duration(duration).asHours())) {
            this.durationInterval = setInterval(() => {
                duration = duration + 1000;
                d = moment.duration(duration);
                this.formattedActualDuration = Math.floor(d.asHours()) + moment.utc(duration).format(':mm') + moment.utc(duration).format(':ss') + 's';
            }, 1000);
            return;
        }
        d = moment.duration(this.customDuration);
        this.formattedActualDuration = Math.floor(d.asHours()) + moment.utc(this.customDuration).format(':mm') + moment.utc(this.customDuration).format(':ss') + 's';
        if (this.formattedActualDuration.startsWith('-')) {
            this.formattedActualDuration = null;
        }
    }

}
