import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-progress-spinner',
  templateUrl: './progress-spinner.component.html',
  styleUrls: ['./progress-spinner.component.sass']
})
export class ProgressSpinnerComponent {
  @Input() isOpen = false;
  @Input('progress') set _progress(val: number) {
    this.progress = val;
    this.bg = 'conic-gradient(#0e54a9 ' + this.progress + '%, #c6ced6 ' + this.progress + '% )';
  }

  progress: number;
  bg: string;
}
