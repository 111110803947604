import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {DonationsService, IDonation, IDonationRequest, IPaidDonation} from '@src/app/services/donations/donations.service';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import {takeUntil} from 'rxjs/operators';
import {DonationState} from '@src/app/state/donation-state';
import {ChatState} from '@src/app/state/chat-state';
import {CHAT_MODE} from '@src/app/components/chat/chat.component';
import {MEDIA_TYPE} from '@src/app/components/streamdust-player/constants/mediaType';
import {UserService} from '@src/app/services/user/user.service';
import {IUserProfile} from '@src/app/pages/user-profile/components/profile/services/profile.service';
import {PAYMENT_FAIL_MESSAGES, PaymentService} from '@src/app/services/payment/payment.service';
import {
    IPaymentCard,
    IPaymentCardsListControls,
    PaymentCardsListComponent
} from '@src/app/components/payment-cards-list/payment-cards-list.component';
import {IHeidelpayPaymentDetails} from '@src/app/components/payment-cards-list/heidelpay-payment/heidelpay-payment.component';
import {PAYMENT_STATUS} from '@src/app/services/subscription-manage/subscription-manage.service';
import {AuthService} from '@src/app/services/auth/auth.service';
import {DEFAULT_DONATIONS_POSITION} from '@src/app/models/core.model';
import {StreamWorldMapState} from '@src/app/state/stream-world-map';
import {IResizeEvent} from 'ngx-draggable-resize/lib/models/resize-event';
import {IResizableElementButton, ResizableElementComponent} from '@src/app/components/resizable-element/resizable-element.component';
import {PLAYER_MODE} from '@src/app/components/streamdust-player/constants/playerMode';
import {CURRENCY_VALUES} from '@src/app/components/streamdust-player/media-access-modal/media-access-modal.component';
import {Observable, Subject} from 'rxjs';
import {IResponse} from '@src/app/models/response.model';
import {GenerateUtil} from '@src/app/utils/generate.util';
import { LocalizationProvider } from 'ui-elements';

@Component({
    selector: 'app-donations',
    templateUrl: './donations.component.html',
    styleUrls: ['./donations.component.sass'],
    providers: [AutoDestroyService]
})
export class DonationsComponent implements OnInit {
    public width: number;
    public height: number;
    @ViewChild('scrollingContainer', {static: false}) private scrollingContainer: ElementRef<HTMLDivElement>;
    @ViewChild('resizableElement', {static: false}) private resizableElement: ResizableElementComponent;
    @ViewChild('messagesContainer', {static: false}) private messagesContainer: ElementRef<HTMLDivElement>;
    @ViewChild('cardList', {static: false}) private cardList: PaymentCardsListComponent;
    public MODE = MODE;
    public PLAYER_MODE = PLAYER_MODE;
    public CHAT_MODE = CHAT_MODE;
    public mode = MODE.PAID;
    public pending: boolean;
    @Input() playerMode: PLAYER_MODE;
    @Input() mediaType: MEDIA_TYPE;
    @Input() mediaId: string;
    @Input() currency: string;
    @Input() donationsMode: CHAT_MODE = CHAT_MODE.DEFAULT;
    @Input() fullScreen$: Observable<boolean>;
    @Input() connection$: Subject<IResponse<{ data: IPaidDonation[] }>>;
    @Input() paidDonations: IPaidDonation[] = [];
    public donations: IDonation[];
    public dragPosition: { x: number; y: number } = DEFAULT_DONATIONS_POSITION;
    private username: string;
    payment = false;
    title = 'spendenverlauf streamtitel';
    paymentCardsListControls: IPaymentCardsListControls = {
        payButton: true,
        cancelButton: {
            show: true,
            action: () => {
                this.mode = MODE.PAID;
            }
        },
        addNewCard: {
            show: true,
            text: 'payment-cards-list.use-another-card',
            customHandler: (paymentDetails: IHeidelpayPaymentDetails) => this.donate(paymentDetails, true)
        }
    };
    payload: IDonationRequest;
    useActiveCard = true;
    public closeButton: IResizableElementButton;
    public fullscreenButton: IResizableElementButton;
    public embeddedButton: IResizableElementButton;
    public instanceId = GenerateUtil.uuidv4();

    constructor(
        private donationService: DonationsService,
        private destroy$: AutoDestroyService,
        public donationState: DonationState,
        public chatState: ChatState,
        public userService: UserService,
        public paymentService: PaymentService,
        private authService: AuthService,
        public worldMapState: StreamWorldMapState,
        public localizationProvider: LocalizationProvider
    ) {
    }

    ngOnInit(): void {
        this.title = this.localizationProvider.getByKey('donations.history')
        this.resolveUsername();
        this.listenToDonationPaymentError();
        this.initButtons();
        this.donationService.getDonationOptions(this.mediaType, this.mediaId, this.currency)
            .pipe(takeUntil((this.destroy$)))
            .subscribe(res => {
                this.donations = res;
            });

        this.donationService.donations$.pipe(takeUntil(this.destroy$))
            .subscribe((res) => {
                let newDonations = [];
                if (!this.paidDonations) {
                    newDonations = res;
                } else {
                    res.forEach(item => {
                        const donation = this.paidDonations.find(_item => _item.date === item.date);
                        if (donation) {
                            return;
                        }
                        newDonations.push(item);
                    });
                }
                this.paidDonations = this.paidDonations.concat(newDonations).sort((a, b) => a.date < b.date ? -1 : 1);
                this.scrollToBottom(false);
            });

        this.donationState.donationActiveState$.pipe(takeUntil(this.destroy$))
            .subscribe((state) => {
                if (state) {
                    this.scrollToBottom(false);
                }
            });

        this.donationState.failed$.pipe(takeUntil(this.destroy$))
            .subscribe((failedInfo) => {
                if (!failedInfo) {
                    return;
                }

                this.pending = true;

                this.payload = JSON.parse(localStorage.getItem('currentDonation'));
                setTimeout(() => {
                    localStorage.removeItem('currentDonation');
                    this.paymentService.failedTransactionInfo.next(failedInfo);
                    this.pending = false;
                }, 3000);
            });
    }

    public initButtons(): void {
        this.embeddedButton = {
            show: true,
            callback: () => this.donationState.toggleOverlay()
        };
        this.closeButton = {
            show: true,
            callback: () => this.donationState.closeDonations()
        };
    }

    private resolveUsername(): void {
        this.userService.userProfile$.pipe(takeUntil(this.destroy$))
            .subscribe((res: IUserProfile) => {
                if (!res || (!res.firstName && !res.lastName && !res.email)) {
                    this.username = 'Anonyme Nutzer';
                    return;
                }

                if (res.firstName || res.lastName) {
                    this.username = res.firstName + ' ' + res.lastName;
                } else {
                    this.username = res.email;
                }
            });
    }

    public initiateDonationPayload(donation: IDonation): void {
        this.payload = {
            donationName: donation.name,
            productId: this.mediaId,
            price: {
                amount: donation.sum,
                currency: CURRENCY_VALUES[donation.currency]
            },
            userName: this.username,
            returnUrl: window.location.href
        };
        this.mode = MODE.PAYMENT_CARD_SELECTION;
        localStorage.setItem('currentDonation', JSON.stringify(this.payload));
    }

    donate(cardDetails: IHeidelpayPaymentDetails | IPaymentCard, isNewCardPayment = false) {
        if (!this.payload) {
            this.mode = MODE.PAID;
            return;
        }
        const card = (cardDetails as IHeidelpayPaymentDetails).card;

        if (isNewCardPayment) {
            this.payload.email = (cardDetails as IHeidelpayPaymentDetails)?.email;
            this.payload.firstName = (cardDetails as IHeidelpayPaymentDetails)?.firstName;
            this.payload.lastName = (cardDetails as IHeidelpayPaymentDetails)?.lastName;
            this.payload.paymentMethod = {
                method: card.method,
                resourceId: card.id
            }

            if (this.authService.isAuthorized && card.method === 'card') {
                this.payload.paymentMethod.saveCard = true;
            }

            if (card.method === 'card') {
                this.payload.paymentMethod.paymentCard = {
                    externalCardId: card.id,
                    expiryDate: card.expiryDate,
                    type: card.brand,
                    number: card.number.slice(-4),
                    cardDetails: {
                        id: card.id,
                        brand: card.brand,
                        cvc: card.cvc,
                        expiryDate: card.expiryDate,
                        number: card.number.slice(-4),
                        method: card.method,
                        account: card.cardDetails.account,
                        cardType: card.cardDetails.cardType,
                        countryIsoA2: card.cardDetails.countryIsoA2,
                        countryName: card.cardDetails.countryName,
                        issuerName: card.cardDetails.issuerName,
                        issuerPhoneNumber: card.cardDetails.issuerPhoneNumber,
                        issuerUrl: card.cardDetails.issuerUrl,
                        '3ds': card['3ds']
                    }
                };
            }
        } else {
            this.payload.paymentMethod = {
                userCardId: (cardDetails as IPaymentCard).id,
                resourceId: (cardDetails as IPaymentCard).externalCardId,
                method: 'card'
            };
        }

        this.donationService.donate(this.payload)
            .pipe(takeUntil(this.destroy$))
            .subscribe((result) => {
                switch (result?.results?.result?.paymentStatus) {
                    case PAYMENT_STATUS.FAILED: {
                        this.paymentService.failedTransactionInfo.next({
                            failedCardId: result.results.result.externalCardId,
                            transactionFailReason: result.results.result.reason || PAYMENT_FAIL_MESSAGES.FAILED
                        });
                        this.cardList.fetchCardsList();
                        break;
                    }
                    case PAYMENT_STATUS.PENDING: {
                        if (result?.results?.result?.redirectUrl) {
                            window.location.href = result.results.result.redirectUrl;
                        }
                        return;
                    }
                    case PAYMENT_STATUS.SUCCESS: {
                        this.mode = MODE.PAID;
                        setTimeout(() => {
                            this.scrollToBottom(false);
                        });
                    }
                }
            }, () => {
                this.mode = MODE.PAID;
                setTimeout(() => {
                    this.scrollToBottom(false);
                });
            });
    }

    private scrollToBottom(withAnimation: boolean): void {
        if (!this.messagesContainer?.nativeElement) {
            return;
        }
        setTimeout(() => {
            const scrollConfig: ScrollToOptions = {top: this.messagesContainer?.nativeElement?.clientHeight};
            if (withAnimation) {
                scrollConfig.behavior = 'smooth';
            }
            this.resizableElement.scrollingContainer.nativeElement.scroll(scrollConfig);
        });
    }

    listenToDonationPaymentError(): void {
        this.paymentService.failedTransactionInfo$.pipe(takeUntil(this.destroy$)).subscribe(data => {
            if (!localStorage.getItem('currentDonation')) {
                return;
            }
            if (data?.failedCardId || data?.transactionFailReason) {
                this.useActiveCard = false;
                this.payload = JSON.parse(localStorage.getItem('currentDonation'));
                this.mode = MODE.PAYMENT_CARD_SELECTION;
            }
        });
    }

    onDragEnd(event: any): void {
        // const newPosition = {x: this.dragPosition.x, y: this.dragPosition.y};
        // newPosition.x += event.distance.x;
        // newPosition.y += event.distance.y;
        // this.dragPosition = newPosition;
    }

    public onResize(evt: IResizeEvent): void {
        this.height = evt.size.height;
        this.width = evt.size.width;
    }

}

enum MODE {
    PAID, PAY, PAYMENT_CARD_SELECTION
}
