import {MEDIA_TYPE} from './../../../../../components/streamdust-player/constants/mediaType';
import {FILTER_TYPES} from './../../../../../components/media-list/media-list-header/media-list-header.component';
import {IListFilterItem, IFilterListItem} from './../../../../../components/media-list/media-list.component';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import {INPUT_TYPES, LocalizationProvider} from 'ui-elements';

@Component({
    selector: 'app-linked-streams-filter',
    templateUrl: './linked-streams-filter.component.html',
    styleUrls: ['./linked-streams-filter.component.sass']
})
export class LinkedStreamsFilterComponent implements OnInit {

    @Output() filterByLinkedStreams$ = new EventEmitter<any>();
    @Input() filters: IListFilterItem[];

    selectedFilter: IListFilterItem[];
    selectOptions: { key: string, value: string, filters: IListFilterItem[] }[] = [
        {
            key: this.localizationProvider.getByKey('linkedStreamsType.Streams'),
            value: this.localizationProvider.getByKey('linkedStreamsType.Streams'),
            filters: [{field: FILTER_TYPES.MEDIA_TYPE, value: MEDIA_TYPE.STREAM}]
        },
        {
            key: this.localizationProvider.getByKey('linkedStreamsType.VideoOnDemand'),
            value: this.localizationProvider.getByKey('linkedStreamsType.VideoOnDemand'),
            filters: [{field: FILTER_TYPES.MEDIA_TYPE, value: MEDIA_TYPE.VIDEO}]
        }
    ];
    linkedStreamsFilterForm: FormGroup = this.formBuilder.group({
        linkedStreamsType: ['']
    });
    linkedStreamsFilterConfig: IFilterListItem = {
        name: 'linkedStreamsType',
        placeholder: this.localizationProvider.getByKey('linkedStreamsType.placeholder'),
        selectOptions: this.selectOptions
    };
    INPUT_TYPES = INPUT_TYPES;

    constructor(
        private localizationProvider: LocalizationProvider,
        private formBuilder: FormBuilder,
        private destroy$: AutoDestroyService
    ) {
    }

    ngOnInit(): void {
        this.init();
    }

    init(): void {
        const field = this.linkedStreamsFilterForm.get('linkedStreamsType');
        const defaultValue = this.linkedStreamsFilterConfig
            .selectOptions[this.filters[0].value === MEDIA_TYPE.STREAM ? 0 : 1].value;

        field.setValue(defaultValue);
        this.selectedFilter = this.setFilter(defaultValue.toString());
        field.valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe(filter => {
                if (filter && filter !== this.selectedFilter) {
                    this.selectedFilter = this.setFilter(filter);
                    this.filterByLinkedStreams$.emit(this.selectedFilter);
                }
            });
    }

    setFilter(value: string): IListFilterItem[] {
        return this.selectOptions.find(o => o.value === value).filters;
    }

}
