/**
 * Created by Тима on 09.04.2020.
 */
import {Injectable} from '@angular/core';
import {INPUT_TYPES} from 'ui-elements';
import {API_URLS_APP} from '@src/app/constants/api-urls.constant';
import {IFieldsConfig} from '@src/app/pages/user-profile/interfaces/interfaces-common';
import {Observable} from 'rxjs';
import {IResponse} from '@src/app/models/response.model';
import {ApiService} from 'ui-elements';

@Injectable()
export class DeleteAccountService {

    constructor(
        private apiService: ApiService
    ) {}

    public deleteAccount(body: IAccountDelete): Observable<IResponse> {
        return this.apiService.post(API_URLS_APP.ACCOUNT_DELETE, body);
    }

    public getFieldsConfig(): IFieldsConfig[] {
        return [
            {
                name: 'reason',
                config: {
                    inputType: INPUT_TYPES.INPUT,
                    label: 'profile.deleteAccount.comment',
                    placeholder: '',
                    isRequired: true
                }
            }
        ];
    }
}

export interface IAccountDelete {
    reason: string;
}
