<section class="section section-dashboard">
    <div class="container">
        <div class="wrapper-content bg-light">
            <div class="card">
                <div class="card-body">
                    <ano-loader [isOpen]="loading"></ano-loader>
                    <div class="row">
                        <div class="col-12">
                            <h2 class="body-title mb-25">{{'payment-details.title' | localize}}</h2>
                            <p>{{'payment-details.body' | localize}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
