import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '@src/app/services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ShouldNotBeAuthorizedGuard implements CanActivate {
  public constructor(
    private authService: AuthService,
    private router: Router,
  ) {
  }

  public canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (this.authService.isAuthorized) {
      this.router.navigate(['home']);
      return false;
    }

    return true;
  }

}
