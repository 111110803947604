import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IPaymentCard, IPaymentCardsListControls} from '@src/app/components/payment-cards-list/payment-cards-list.component';
import {
    IHeidelpayCard,
    IHeidelpayPaymentDetails
} from '@src/app/components/payment-cards-list/heidelpay-payment/heidelpay-payment.component';
import {mergeMap, pluck, take, takeUntil} from 'rxjs/operators';
import {IStartPaymentReq, PAYMENT_FAIL_MESSAGES, PaymentService} from '@src/app/services/payment/payment.service';
import {ActivatedRoute, Router} from '@angular/router';
import {LoadingService} from '@src/app/services/loading/loading.service';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import {PAYMENT_STATUS, SubscriptionManageService} from '@src/app/services/subscription-manage/subscription-manage.service';
import {MediaGroupsService} from '@src/app/services/media-groups/media-groups.service';
import {IMediaGroup} from '@src/app/models/stream-groups.model';
import {AuthService} from '@src/app/services/auth/auth.service';
import {StreamService} from '@src/app/services/stream-metadata/stream.service';

@Component({
    selector: 'app-group-get-access',
    templateUrl: './group-get-access.component.html',
    styleUrls: ['./group-get-access.component.css']
})
export class GroupGetAccessComponent implements OnInit {

    groupId: string;
    groupToGetAccess: IMediaGroup;

    @Input() paymentCardsListControls: IPaymentCardsListControls;
    @Output() cancelPayment$ = new EventEmitter<string>();

    constructor(
        private paymentService: PaymentService,
        private router: Router,
        private loadingService: LoadingService,
        private activatedRoute: ActivatedRoute,
        private destroy$: AutoDestroyService,
        private subscriptionManageService: SubscriptionManageService,
        private groupService: MediaGroupsService,
        private authService: AuthService,
        private streamService: StreamService
    ) {
    }

    ngOnInit(): void {
        this.loadingService.loadingStart();

        this.paymentCardsListControls.addNewCard.customHandler = (paymentDetails: IHeidelpayPaymentDetails) => this.purchaseGroup(paymentDetails.card, true);

        this.activatedRoute.params
            .pipe(
                takeUntil(this.destroy$),
                mergeMap(params => {
                    this.groupId = params.id;
                    return this.groupService.getGroup(this.groupId, true)
                        .pipe(takeUntil(this.destroy$), pluck('group'));
                })
            ).subscribe(group => {
            this.groupToGetAccess = group as IMediaGroup;
            this.loadingService.loadingEnd();
        });
    }

    purchaseGroup(paymentCard: IPaymentCard | IHeidelpayCard, heidelpayCard = false): void {
        this.loadingService.loadingStart();

        const externalCardId = (paymentCard as IPaymentCard)?.id;

        const req: IStartPaymentReq = {
            groupId: this.groupId,
            returnUrl: `https://${window.location.hostname}/payment-details`,
            successUrl: `https://${window.location.hostname}/groups/${this.groupId}/details`,
            failUrl: `https://${window.location.hostname}${this.router.url}`,
        };

        if (heidelpayCard) {
            Object.assign(req, { paymentMethod: {
                    method: (paymentCard as IHeidelpayCard).method,
                    resourceId: (paymentCard as IHeidelpayCard).id}
            });

            if (req.paymentMethod.method === 'card') {
                req.paymentMethod.paymentCard = this.paymentService.getNewPaymentCardData(paymentCard as IHeidelpayCard);
                req.paymentMethod.saveCard = true;
            }
        } else {
            Object.assign(req, { paymentMethod: {
                    method: (paymentCard as IHeidelpayCard).method,
                    resourceId: (paymentCard as IPaymentCard)?.externalCardId,
                    userCardId: externalCardId}
            });
        }

        if (this.streamService.viewerAccessToken) {
            req.accessToken = this.streamService.viewerAccessToken;
        }

        this.paymentService.getAccessToGroup(req).pipe(take(1)).subscribe(response => {
            switch (response?.results?.result?.paymentStatus) {
                case PAYMENT_STATUS.FAILED: {
                    this.paymentService.failedTransactionInfo.next({
                        failedCardId: response?.results?.result?.externalCardId,
                        transactionFailReason: response?.results?.result?.reason || PAYMENT_FAIL_MESSAGES.FAILED
                    });
                    this.loadingService.loadingEnd();
                    break;
                }
                case PAYMENT_STATUS.PENDING: {
                    if (response?.results?.result?.redirectUrl) {
                        window.location.href = response.results.result.redirectUrl;
                    }
                    break;
                }
                case PAYMENT_STATUS.SUCCESS: {
                    this.router.navigate(['groups', this.groupId, 'details']);
                    this.loadingService.loadingEnd();
                    break;
                }
            }
        });
    }

    cancelPayment(): void {
        this.cancelPayment$.emit(`groups/${this.groupId}/details`);
    }

}
