import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '@src/app/services/auth/auth.service';
import {AutoDestroyService, INPUT_TYPES, LocalizationProvider} from 'ui-elements';
import {CustomValidators} from '@src/app/utils/custom-validators.util';
import {catchError, filter, takeUntil} from 'rxjs/operators';
import {of} from 'rxjs';
import {IUserAGB} from '@src/app/pages/user-profile/components/withdrawal-and-conditions/services/withdrawal-and-conditions.service';
import {IPaymentCard, IPaymentCardsListControls} from '@src/app/components/payment-cards-list/payment-cards-list.component';
import {UserService} from '@src/app/services/user/user.service';
import {HeaderPopupType, HeaderService} from '@src/app/services/header/header.service';
import {OpenCurrentSlotPayment, FailurePaymentService} from '@src/app/services/slot-failure/failure-payment.service';
import {IUserProfile} from '@src/app/pages/user-profile/components/profile/services/profile.service';
import {environment} from '@src/environments/environment';
import {LoadingService} from '@src/app/services/loading/loading.service';
import {MediaGroupsService} from '@src/app/services/media-groups/media-groups.service';


@Component({
  selector: 'app-access-window',
  templateUrl: './access-window.component.html',
  styleUrls: ['./access-window.component.sass']
})
export class AccessWindowComponent implements OnInit {
  @Input() termsConditions: IUserAGB;
  @Input() loading: boolean;
  @Input() paymentCardsListControls: IPaymentCardsListControls;
  @Input() isGroup: boolean;
  @Output() proceedPayment$: EventEmitter<IPaymentCard> = new EventEmitter<IPaymentCard>();
  @Output() close$: EventEmitter<void> = new EventEmitter<void>();
  INPUT_TYPES = INPUT_TYPES;
  userLoginForm: FormGroup;
  checkModel = {
    firstTerms: false,
    secondTerms: false
  };

  isPayment: boolean;

  configModalPublisher = {
    isOpened: false,
    title: '',
    htmlText: '',
  };

  public userProfile: IUserProfile;
  public userPhoto = '';
  public userFirstName = '';
  public userLastName = '';
  public userEmail = '';

  constructor(
      public authService: AuthService,
      private destroy$: AutoDestroyService,
      private loadingService: LoadingService,
      private fb: FormBuilder,
      private localizationProvider: LocalizationProvider,
      public userService: UserService,
      public headerService: HeaderService,
      private failurePaymentService: FailurePaymentService,
      private mediaGroupsService: MediaGroupsService,
  ) { }

  ngOnInit(): void {
    this.userLoginForm = this.fb.group({
      email: ['', [CustomValidators.email, Validators.required]],
      password: ['', [Validators.required]]
    });

    this.failurePaymentService.accessWindowAction
        .pipe(
            takeUntil(this.destroy$),
            filter(val => !!val)
        )
        .subscribe((data: OpenCurrentSlotPayment) => {
          this.openCardList();
          this.failurePaymentService.cardListAction.next(data.method);
        });

    this.listenUserProfile();
  }

  private listenUserProfile() {
    this.userService.userProfile$.pipe(takeUntil(this.destroy$)).subscribe((userProfile) => {
      this.userProfile = userProfile;
      this.userPhoto = this.userProfile?.profilePhoto?.photoUrl ? environment?.backendApiHost + this.userProfile?.profilePhoto?.photoUrl : '';
      this.userFirstName = this.userProfile?.firstName || '';
      this.userLastName = this.userProfile?.lastName || '';
      this.userEmail = this.userProfile?.email || '';
    });
  }

  loginUser(): void {
    this.userLoginForm.markAllAsTouched();

    if (this.userLoginForm.invalid) {
      return;
    }

    this.loading = true;

    this.authService.login(this.userLoginForm.value)
        .pipe(
            takeUntil(this.destroy$),
            catchError((error) => of(error))
        ).subscribe(response => {
      this.loading = false;
      if (response && response.success) {
        this.userLoginForm.get('password').setErrors(null);
      } else {
        if (response.errorKey === 'NAME_OR_PASSWORD_MISMATCH') {
          this.userLoginForm.get('password').setErrors({nameOrPasswordMismatch: true});
        }
      }
    });
  }

  goForgotPassword(): void {
    this.headerService.showHeaderPopup(HeaderPopupType.FORGOT_PASSWORD);
  }

  goRegistration(): void {
    this.close$.emit();
    this.mediaGroupsService.goRegistration();
  }

  cancel(): void {

  }

  changeCheck(key: string) {
    if (this.checkModel.firstTerms && this.checkModel.secondTerms) {
      this.openCardList();
    }
  }

  goNextStep() {
    this.isPayment = true;
  }

  private openCardList(): void {
    this.checkModel.firstTerms = false;
    this.checkModel.secondTerms = false;
    this.goNextStep();
  }

  showPopUp(event) {
    event.stopPropagation();
    this.configModalPublisher.title = 'media-access-modal.privacy-policy.right-of-withdrawal';
    this.configModalPublisher.htmlText = this.termsConditions?.withdrawal || this.localizationProvider.getByKey('profile.termsAndConditions.withdrawalText');
    this.configModalPublisher.isOpened = true;
  }

  isAuthorized(): boolean {
    return this.authService.isAuthorized;
  }

  proceedPayment(event: IPaymentCard): void {
    this.proceedPayment$.emit(event);
  }

  // getUserPhoto() {
  //   return this.userService.getUserPhoto();
  // }

  logout(): void {
    this.authService.logout(true, false);
  }

}
