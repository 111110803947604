<div>
    <div class="preview-form"
         [class.hidden]="formConfig.preview && (!previewDisabled || !formConfig.enabled)"
         [class.timeline]="formConfig.preview"
         [class.timeline-last]="formConfig.preview && index === formConfig.forms.length - 1">
        <app-bordered-form #borderedForm
                [disabled]="disabled"
                [readonly]="readonly"
                [itemIndex]="index"
                [formCountTitle]="formConfig.formCountTitle"
                [form]="form"
                [fieldsConfig]="formConfig.formsFields"
                [disabledForm]="formConfig.isToggle && !formConfig.enabled"
                [addButtonText]="formConfig.addButtonText"
                [showAdd]="index === formConfig.forms.length - 1 && !formConfig?.hideAdd"
                [withoutBorder]="formConfig?.hideAdd"
                [withoutMargin]="formConfig.preview"
                [addLimitReached]="addLimitReached"
                [showRemove]="formConfig.forms.length > 1"
                (add$)="addForm()"
                (remove$)="removeForm()"
        >
        </app-bordered-form>
        <div *ngIf="formConfig.preview" class="actions-form">
            <button class="btn btn-success" (click)="applyChanges()">{{'common.button.apply.v2' | localize}}</button>
            <button class="btn btn-danger" (click)="close()">{{'common.button.close' | localize}}</button>
        </div>
    </div>
    <div *ngIf="formConfig.preview" class="preview timeline"
         [class.timeline-last]="formConfig.preview && index === formConfig.forms.length - 1"
         [class.hidden]="previewDisabled && formConfig.enabled || formConfig.forms.length <= 1 && !previewData.title"
         [class.disabled]="!formConfig.enabled">
        <div class="slot">
            <p *ngIf="previewData.title" class="slot-duration">{{previewData?.startTimestamp}} — {{previewData?.endTimestamp}}</p>
            <p class="slot-title">{{previewData?.title}}</p>
            <p *ngIf="previewData.slotPayment === PAYMENT_SYSTEM.PAY_PER_VIEW" class="slot-price">{{previewData?.price}} {{currencyProfile}}</p>
            <p *ngIf="previewData.slotPayment === PAYMENT_SYSTEM.FREE" class="slot-price">{{PAYMENT_SYSTEM.FREE}}</p>
        </div>
        <div class="actions">
            <b class="text-primary" (click)="hidePreview()">
                <i class="icon icon-edit cursor-pointer"></i>
            </b>
            <b class="text-primary" *ngIf="formConfig.forms.length > 1" (click)="removeForm()">
                <i class="icon icon-trash text-danger cursor-pointer"></i>
            </b>
        </div>
    </div>
    <div class="add-form"
         *ngIf="index === formConfig.forms.length - 1 && !readonly && !addLimitReached && !disabled && formConfig.preview"
         [class.disabled]="!formConfig.enabled">
        <span class="add-group" (click)="addForm()">
            <i class="icon icon-plus-circle"></i>
            <span>{{ formConfig.addButtonText | localize }}</span>
        </span>
    </div>
</div>

