import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-record-page',
  templateUrl: './record-page.component.html',
  styleUrls: ['./record-page.component.css']
})
export class RecordPageComponent implements OnInit {

  public url = 'https://www.radiantmediaplayer.com/media/bbb-360p.mp4';
  constructor() { }

  ngOnInit(): void {
  }

}
