import { Injectable } from '@angular/core';
import {ApiService} from 'ui-elements';
import {MEDIA_TYPE} from '@src/app/components/streamdust-player/constants/mediaType';
import {IPagedResponse, IResponse, PagingParams, SortParam} from '@src/app/models/response.model';
import {Observable} from 'rxjs';
import {ILead} from '@src/app/pages/stream-page/components/media-leads/config/lead-model';
import {API_URLS_APP, UrlGenerator} from '@src/app/constants/api-urls.constant';

@Injectable()
export class CoursesOverviewService {

  constructor(
      private apiService: ApiService
  ) { }

  public get(mediaType: MEDIA_TYPE, mediaId: string, searchQuery: string = '', sort: SortParam[] = DEFAULT_SORTING, paging: PagingParams = DEFAULT_PAGING): Observable<IPagedResponse<ILead>> {
      return this.apiService.post(UrlGenerator.generate(API_URLS_APP.GET_COURSE_PARTICIPANTS, {mediaId}), {
          paging,
          sort,
          searchTerm: searchQuery
      });
  }

    public downloadCsv(mediaType: MEDIA_TYPE, mediaId: string): Observable<Response> {
        return this.apiService.get(
            UrlGenerator.generate(API_URLS_APP.COURSES_PARTICIPANTS_CSV, {mediaId}),
            {responseType: 'blob', observe: 'response'},
        );
    }

    public delete(mediaType: MEDIA_TYPE, mediaId: string, accountId: string): Observable<IResponse> {
      return this.apiService.delete(UrlGenerator.generate(API_URLS_APP.LEAD_DELETE, {mediaType, mediaId, accountId}));
    }
}

const DEFAULT_PAGING = {
    page: 0,
    itemsOnPage: 10
} as PagingParams;

const DEFAULT_SORTING = [{
    field: 'VIEW_DATE',
    order: 'DESC'
}] as SortParam[];
