import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-carousel',
    templateUrl: './carousel.component.html',
    styleUrls: ['./carousel.component.css']
})
export class CarouselComponent implements OnInit {

    @Input() config: ICarouselConfig;
    @Input() set itemsCount(itemsCount: number) {
        this._itemsCount = itemsCount;
        this.canScrollRight = this.checkIfCanScrollRight();
    };

    scrollTo = 0;
    _itemsCount: number;
    canScrollRight: boolean = true;
    canScrollLeft = false;

    constructor() {
    }

    ngOnInit(): void {
    }

    performScroll(): void {
        const itemsWrapperId = `app-list-${this.config?.carouselId}`;
        const itemsListId = `row-${this.config?.carouselId}`;
        this.scrollList(itemsWrapperId, itemsListId);
    }

    scrollList(itemsWrapperId: string, itemsListId: string): void {
        const itemsWrapper = document.getElementById(itemsWrapperId);
        const itemsList = document.getElementById(itemsListId);
        itemsWrapper.style.overflow = 'scroll';
        itemsList.style.overflow = 'scroll';
        itemsList.scrollTo({
            left: this.scrollTo,
            behavior: 'smooth'
        });
        itemsList.style.overflow = 'hidden';
        itemsWrapper.style.overflow = 'hidden';
        this.canScrollRight = this.checkIfCanScrollRight();
        this.canScrollLeft = !!this.scrollTo;
    }

    scrollRight(): void {
        if (!this.canScrollRight) {
            return;
        }
        this.scrollTo += this.config?.scrollStep * this.config?.itemsToScrollAtOnce;
        this.performScroll();
    }

    scrollLeft(): void {
        if (this.scrollTo - this.config?.scrollStep * this.config?.itemsToScrollAtOnce < 0) {
            return;
        }
        this.scrollTo -= this.config?.scrollStep * this.config?.itemsToScrollAtOnce;
        this.performScroll();
    }

    checkIfCanScrollRight(): boolean {
        return this.scrollTo + this.config?.scrollStep * this.config?.itemsToScrollAtOnce <= (this._itemsCount - this.config?.itemsPerList) * this.config?.scrollStep * this.config?.itemsToScrollAtOnce;
    }

}

export interface ICarouselConfig {
    scrollStep: number;
    itemsPerList: number;
    carouselId: string;
    itemsToScrollAtOnce: number;
}
