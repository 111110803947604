<div class="streamdust-player-container"
     [class.streamdust-player-container-embedded]="playerDisplayMode === PLAYER_DISPLAY_MODE.EMBEDDED" #videoWrapper>
    <ano-loader [isOpen]="loading"></ano-loader>
    <ng-container *ngIf="!deactivated">
        <ng-container
                *ngIf="(playerDisplayMode === PLAYER_DISPLAY_MODE.DEFAULT || (playerDisplayMode === PLAYER_DISPLAY_MODE.EMBEDDED && !restrictedByCountry)) && !restrictedByDomain">
            <app-stream-age-restrictions-modal class="center" [mediaId]="mediaId" *ngIf="ageRestricted && !isOwner"
                                               (answer$)="confirmAge($event)">
            </app-stream-age-restrictions-modal>

            <div *ngIf="displayVideoTitle" class="video-title" [class.video-title--show]="playerPaused || accessProps.isTeaserOrTrailer">
                <div class="video-title-inner">
                    {{data.video.title}}
                </div>
            </div>

            <ng-container
                    *ngIf="data && config && !loading && !ageRestricted && (accessProps | accessRestricted) || showLandscapeMode"
            >
                <ng-container *ngIf="!showLandscapeMode">
                    <app-stream-access-button
                            *ngIf="(!showAccessModal && playerPaused) || accessProps.isTeaserOrTrailer"
                            [isTeaserOrTrailer]="accessProps.isTeaserOrTrailer"
                            [displayVideoTitle]="displayVideoTitle"
                            (click)="openAccessModal()"
                    >
                    </app-stream-access-button>

                    <button *ngIf="!showAccessModal && playerPaused && !(mediaType === MEDIA_TYPE.STREAM)"
                            class="fake-play-button"
                            (click)="openAccessModal()">
                    </button>
                </ng-container>

                <!-- && !streamStarted -->
            </ng-container>


            <app-stream-access-modal #accessModal class="media-access-modal"
                                     [hidden]="!showLandscapeMode && (!data || !config || loading || ageRestricted || !playerPaused || !showAccessModal || !(accessProps | accessRestricted))"
                                     [showLandscapeMode]="showLandscapeMode"
                                     [landscapeModeClosed]="landscapeModeClosed"
                                     [mediaId]="mediaId"
                                     [productType]="mediaType"
                                     [paymentSystem]="data?.video?.paymentSystem"
                                     [termsConditions]="data?.termsConditions"
                                     [data]="data"
                                     [playerMode]="playerDisplayMode"
                                     [parentLoader]="loading"
                                     [isPayed]="accessProps.fullAccess"
                                     [leadTextField1]="data?.publisher?.leadTextField1"
                                     [leadTextField2]="data?.publisher?.leadTextField2"
                                     [customFieldOn]="data?.publisher?.customFieldOn"
                                     [customFieldName]="data?.publisher?.customFieldName"
                                     [showRegistrationOffer]="accessProps.showRegistrationOffer"
                                     [showLogin]="accessProps.showLogin"
                                     [slot]="currentSlot"
                                     (groupPaymentFailure$)="groupPaymentFailure($event)"
                                     (openGroupModal$)="openGroupModal()"
                                     (openSlotModal$)="openSlotModal()"
                                     (closeAccessModal$)="closeAccessModal()"
                                     (closeRegistrationOffer$)="closeRegistrationOffer()"
                                     (openAccessModal$)="openAccessModal()"
                                     (getMedia$)="initMediaInfo()"
                                     (closeLandscapeMode$)="onCloseLandscapeMode()"
            >
            </app-stream-access-modal>

            <app-info-modal
                *ngIf="showScheduleMessage"
                [title]="'info-modal.schedule.title'"
                [description]="'info-modal.schedule.description'"
                [closeButtonText]="'info-modal.schedule.btn'"
                (closed)="onScheduleMessageClosed()"
            ></app-info-modal>

            <div *ngIf="accessProps.isTeaserOrTrailer" class="trailer-shadow"></div>

            <app-player *ngIf="config"
                        [config]="config"
                        [buttonsConfig]="buttonsConfig"
                        [currentLivestreamTime]="currentLivestreamTime"
                        [marketingSocket]="MARKETING_SOCKET"
                        [playerController]="playerController"
                        (videoEnded)="onVideoEnd($event)"
                        (videoClicked)="onVideoClick($event)"
                        (playerSignal)="onPlayerSignal($event)"
                        (overlayClicked)="onOverlayClick($event)"
                        (overlayViewed)="onOverlayViewed($event)"
                        (fullscreenToggle)="onFullscreenToggle($event)"
                        (timeUpdated)="onTimeUpdate($event)"
                        (updateOverlaysEmitter)="updateOverlays()"
            ></app-player>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="deactivated && mode === PLAYER_MODE.VIEWER">
        <div class="deactivated-container">
            <h1>{{'public-media-page.deactivatedByStreamdust' | localize}}</h1>
        </div>
    </ng-container>
    <ng-template #linkedStreamTmpl></ng-template>
    <ng-template #groupsTmpl></ng-template>
    <ng-template #slotsTmpl></ng-template>
    <ng-template #presentationTmpl></ng-template>
    <ng-template #chatTmpl></ng-template>
    <ng-template #donationsTmpl></ng-template>
    <ng-template #mapTmpl></ng-template>
    <ng-template #surveysTmpl></ng-template>
    <ng-template #infoTmpl></ng-template>
    <ng-template #shareTmpl></ng-template>
</div>


<div *ngIf="playerDisplayMode === PLAYER_DISPLAY_MODE.EMBEDDED && restrictedByCountry" class="content-center">
    <h1>{{'public-media-page.content-is-on-restricted_by_country' | localize}}</h1>
</div>

<div *ngIf="restrictedByDomain" class="content-center">
    <a [href]="environment.domain + 'watch/' + (mediaType === MEDIA_TYPE.VIDEO_ON_DEMAND ? 'vod/' : 'stream/') + mediaId" target="_blank">
        <h1>{{'public-media-page.content-is-on-restricted_by_domain' | localize}}</h1>
    </a>
</div>
