<div class="panel panel-md">
    <div class="panel-header">
        <h2><b>{{'domainRestriction.title' | localize}}</b></h2>
        <p class="text-sm">{{'domainRestriction.subtitle' | localize}}</p>
    </div>
    <div class="panel-body bg-white padding-bottom-30" *ngIf="mediaId">
        <div class="section-panel">
            <div class="section-panel-header">
                <h4><b>{{'domainRestriction.table.title' | localize}}</b></h4>
                <p class="text-sm">{{'domainRestriction.table.description' | localize}}</p>
            </div>
<!--            <ano-table #tableComponent-->
<!--                        [tableConfig]="tableConfig" -->
<!--                        [defaultSortField]="'LINKED_MEDIA_NAME'"-->
<!--                        [sortOrder]="1"-->
<!--                        [withSearch]="true"-->
<!--                        [addButton]="(!((streamService?.stream$ | async)?.media?.status?.stream === STREAM_STATUS.FINISHED && !(streamService?.stream$ | async)?.media?.hasRecording) && !(streamService.stream$ | async)?.media?.deactivated) && ((streamService.stream$ | async)?.media?.status?.stream !== STREAM_STATUS.PENDING)"-->
<!--                        [addButtonText]="'common.button.create'"-->
<!--                        (addItem$)="openModal()"-->
<!--                        (search$)="search($event)"-->
<!--            ></ano-table>-->

            <div class="domain-restriction">
                <h4 class="domain-restriction__title">
                    <b>{{'domainRestriction.management.title' | localize}}</b>
                </h4>
                <ul class="domain-restriction__list">
                    <li *ngFor="let item of domainRestrictionData" class="domain-restriction__item">
                        <div class="domain-restriction__link">
                            {{ item.allowedDomain }}
                        </div>

                        <div class="domain-restriction__actions-list" [class.disabled]="isDisable">
                            <div class="domain-restriction__actions-item">
                                <i *ngIf="!((streamService.stream$ | async)?.media?.status?.stream === STREAM_STATUS.PENDING)" (click)="openModal(item)"
                                    class="icon icon-edit text-primary cursor-pointer"></i>
                            </div>
                            <div class="domain-restriction__actions-item">
                                <i *ngIf="!((streamService.stream$ | async)?.media?.status?.stream === STREAM_STATUS.PENDING)" (click)="deleteLink(item)"
                                    class="icon icon-trash text-danger cursor-pointer"></i>
                            </div>
                        </div>
                    </li>
                </ul>
                <hr>
                <button class="domain-restriction__add-btn" (click)="openModal()">
                    <b>{{'domainRestriction.add.btn' | localize}}</b>
                </button>
            </div>

        </div>
    </div>
</div>

<!--<ng-template #statusTmpl let-data="rowData" class="ss-test">-->
<!--    <div class="long-text">-->
<!--        <app-toggle-->
<!--                [value]="data.active"-->
<!--                [config]="formConfig"-->
<!--                (toggleEmit)="onToggle($event, data)"-->
<!--        ></app-toggle>-->
<!--    </div>-->
<!--</ng-template>-->
<!--<ng-template #titleTmpl let-data="rowData" class="ss-test">-->
<!--    <div class="long-text text-left">-->
<!--        <span>-->
<!--            {{data.linkedMedia.title}}-->
<!--        </span>-->
<!--    </div>-->
<!--</ng-template>-->
<!--<ng-template #linksNameTmpl let-data="rowData" class="ss-test">-->
<!--    <div class="long-text text-left">-->
<!--        <span>-->
<!--            {{data.linkedMediaName}}-->
<!--        </span>-->
<!--    </div>-->
<!--</ng-template>-->
<!--<ng-template #nameTmpl let-data="rowData" class="ss-test">-->
<!--    <div class="long-text text-left">-->
<!--        <span>-->
<!--            {{data.name}}-->
<!--        </span>-->
<!--    </div>-->
<!--</ng-template>-->
<!--<ng-template #dateTmpl let-data="rowData">-->
<!--    <div class="long-text text-left">-->
<!--        {{data.created | dateFormat: 'DD MMM YYYY'}}-->
<!--    </div>-->
<!--</ng-template>-->
<!--<ng-template #callTmpl let-data="rowData">-->
<!--    <div class="text-right">-->
<!--        {{data?.clickAmount | number}}-->
<!--    </div>-->
<!--</ng-template>-->
<!--<ng-template #actionsTmpl let-data="rowData">-->
<!--    <div class="table-actions-list" [class.disabled]="isDisable">-->
<!--        <b class="text-primary" *ngIf="!((streamService.stream$ | async)?.media?.status?.stream === STREAM_STATUS.PENDING)">-->
<!--            <i (click)="openModal(data)"-->
<!--                class="icon icon-edit cursor-pointer"></i> |-->
<!--        </b>-->
<!--        <i *ngIf="!((streamService.stream$ | async)?.media?.status?.stream === STREAM_STATUS.PENDING)" (click)="deleteLink(data)"-->
<!--            class="icon icon-trash text-danger cursor-pointer"></i>-->
<!--    </div>-->
<!--</ng-template>-->
<ng-template #confirm let-dialogRef="dialogRef">
    <ano-modal [dialogRef]="dialogRef" [title]="'domainRestriction.deleteModalTitle' | localize" [isConfirmation]="true">
        {{confirmText}}
    </ano-modal>
</ng-template>
