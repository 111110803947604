import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmailConfirmationPageComponent } from '@src/app/pages/email-confirmation-page/email-confirmation-page.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@src/app/modules/shared/shared.module';

@NgModule({
  declarations: [EmailConfirmationPageComponent],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule
  ],
  exports: [EmailConfirmationPageComponent]
})
export class EmailConfirmationPageModule { }
