import {Injectable} from '@angular/core';
import {ApiService} from "ui-elements";
import {Observable} from "rxjs";
import {API_URLS_APP} from "@src/app/constants/api-urls.constant";
import {pluck} from "rxjs/operators";
import {SIZE_UNITS} from "@src/app/utils/unit-size-to-bytes";
import {ITariffPlanPrice} from "@src/app/components/tariff-plan-cards-list/subscription-plan-card/subscription-plan-card.component";
import {TARIFF_PLAN_TYPES} from '@src/app/components/tariff-plan-cards-list/tariff-plan-cards-list.component';

@Injectable({
    providedIn: 'root'
})
export class StorageAndBandwidthService {

    constructor(
        private apiService: ApiService
    ) {
    }

    public getDataLimit(): Observable<IStorageAndBandwidthTariffs> {
        return this.apiService.get(API_URLS_APP.GET_DATA_LIMIT)
        // return this.apiService.get('assets/mock-json/datalimit.json', {isWithoutRootUrl: true})
            .pipe(pluck('results', 'data'));
    }

    public getTariffPlans(): Observable<ITariffPlan[]> {
        return this.apiService.get(API_URLS_APP.GET_TARIFFS)
            .pipe(pluck('results', 'data'));
    }
}

export interface IStorageAndBandwidthTariffs {
    dataLimits: IStorageAndBandwidth;
    bandwidthTariffPlans: ITariffPlan[];
    storageSpaceTariffPlans: ITariffPlan[];
    hiddenDataLimits: boolean;
}

export interface IStorageAndBandwidth {
    bandwidth: IDataLimit;
    storageSpace: IDataLimit;
    bandwidthTariffPlans: ITariffPlan[];
    storageSpaceTariffPlans: ITariffPlan[];
}

export interface IDataLimit {
    accountId: string;
    limit: IValueUnit;
    used: IValueUnit;
    percent: number;
    tariffPlans: ITariffPlan[];
    limitReached?: boolean;
}

export interface ITariffPlan {
    id?: string;
    limit: IValueUnit;
    prices: ITariffPlanPrice[];
    mostPopular?: boolean;
    type?: TARIFF_PLAN_TYPES;
}

interface IValueUnit {
    value: number;
    unit: SIZE_UNITS;
}
