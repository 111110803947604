import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {INPUT_TYPES} from 'ui-elements';
import {IFieldsConfig} from './../../../user-profile/interfaces/interfaces-common';
import {IFormConfig, StreamService} from './../../../../services/stream-metadata/stream.service';
import {IPresentationMetadata} from './../../../../models/stream.model';
import {Component, EventEmitter, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {STREAM_STATUS} from '@src/app/components/streamdust-player/constants/status';
import {takeUntil, tap} from 'rxjs/operators';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import {IPresentationList, PresentationService} from '@src/app/services/file/presentation.service';
import {LoadingService} from '@src/app/services/loading/loading.service';
import {ActivatedRoute, Params} from '@angular/router';
import {CustomValidators} from '@src/app/utils/custom-validators.util';
import {ITableConfig, TableComponent} from 'ui-elements';
import {PLAYER_MODE} from "@src/app/components/streamdust-player/constants/playerMode";
import {PresentationsTableComponent} from '@src/app/pages/stream-page/components/stream-presentation/components/presentations-table/presentations-table.component';

@Component({
    selector: 'app-stream-presentation',
    templateUrl: './stream-presentation.component.html',
    styleUrls: ['./stream-presentation.component.scss']
})
export class StreamPresentationComponent implements OnInit {
    @ViewChild('tableComponent', {static: false}) public table: PresentationsTableComponent;
    @ViewChild('statusTmpl', {static: true}) public statusTmpl: TemplateRef<any>;
    @ViewChild('dateTmplCourses', {static: true}) public dateTmplCourses: TemplateRef<any>;

    public playerMode: PLAYER_MODE = PLAYER_MODE.PUBLISHER;

    presentation: IPresentationMetadata;
    pdfForm: FormGroup;
    settingsForm: FormGroup;

    pdfFormConfig: IFieldsConfig[][];
    settingsFormConfig: IFieldsConfig[][];
    formsConfig: IFormConfig[];
    presentationUrl = '';
    public STREAM_STATUS = STREAM_STATUS;
    pdf = {
        isRemove: true,
    };

    file = {
        fileId: null,
        slides: [],
        fileName: '',
        fileUploadDate: null,
    };
    activateForm = this.fb.group({
        active: [false]
    });

    public payload = {
        id: null,
        mediaId: '',
        title: '',
        link: '',
        fileId: '',
        viewerDownloadAllowed: false
    };
    tableConfig: ITableConfig<IPresentationList>;
    private searchQuery: string;
    private fileForm: any;


    constructor(
        private fb: FormBuilder,
        public streamService: StreamService,
        public presentationService: PresentationService,
        private readonly destroy$: AutoDestroyService,
        public loadingService: LoadingService,
        public activatedRoute: ActivatedRoute,
    ) {
    }

    ngOnInit(): void {
        this.activatedRoute.parent.params.pipe(
            takeUntil(this.destroy$))
            .subscribe(({id}: Params) => {
                if (!id) {
                    return;
                }
                this.payload.mediaId = id;
                this.getPresentationByMediaID(this.payload.mediaId);
            });

        this.formsConfig = this.streamService.getStreamPresentationFormsConfig();
        this.initForms();
        this.initFormsConfig();
        this.listenActiveForm();
        this.initTableConfig();
    }

    private initForms(): void {
        this.pdfForm = this.fb.group({
            title: ['', [Validators.required]],
            link: ['', [CustomValidators.noWhitespaceValidator]]
        });
        this.settingsForm = this.fb.group({
            viewerDownloadAllowed: [false],
        });
        this.fileForm = this.formsConfig.find(form => form.name === 'file').form;
    }

    initFormsConfig(): void {
        this.pdfFormConfig = [
            [
                {
                    name: 'title',
                    config: {
                        inputType: INPUT_TYPES.INPUT,
                        placeholder: 'presentations.placeholder.presentationTitle',
                        isRequired: true
                    }
                }
            ],
            [
                {
                    name: 'link',
                    config: {
                        inputType: INPUT_TYPES.INPUT,
                        placeholder: 'presentations.presentationLink',
                        isRequired: false
                    }
                }
            ]
        ];
        this.settingsFormConfig = [
            [
                {
                    name: 'viewerDownloadAllowed',
                    config: {
                        inputType: INPUT_TYPES.CHECKBOX,
                        placeholder: 'presentation.field.viewerDownloadAllowed',
                    }
                }
            ],
        ];
    }

    uploadPresentation(media): void {
        this.file = media.results.data;
        this.fileForm.get('file').setValue(this.file);
    }

    clearPresentation(media): void {
    }

    public onSave(): void {
        this.pdfForm.markAllAsTouched();
        this.fileForm.markAllAsTouched();

        if (this.pdfForm.invalid || this.fileForm.invalid) {
            return;
        }

        this.payload = {
            ...this.payload,
            ...this.pdfForm.getRawValue(),
            ...this.settingsForm.getRawValue(),
        };

        this.payload.fileId = this.file.fileId;

        if (!this.payload.id) {
            delete this.payload.id;
        }

        this.loadingService.loadingStart();
        this.presentationService.save(this.payload, true)
            .pipe(takeUntil(this.destroy$))
            .subscribe((res) => {
                this.loadingService.loadingEnd();
                if (res.success) {
                    let isNew;
                    if (!this.payload.id) {
                        isNew = true;
                    }

                    this.payload.id = res.results['data'];

                    if (isNew) {
                        this.activateForm.get('active').setValue(true);
                    }

                    this.table.refreshTable();
                }
            }, () => this.loadingService.loadingEnd());
    }

    removePresentation($event) {
        this.file = $event;
        this.fileForm.get('file').setValue(null);
    }

    private listenActiveForm() {
        this.activateForm.get('active').valueChanges
            .pipe(takeUntil(this.destroy$))
            .subscribe(active => {
                this.toggleActive(active, this.payload);
                this.presentationService.toggleTableStatus$.emit({
                    active: active,
                    payload: this.payload
                });
            });
    }

    private toggleActive(active, model) {
        const payload = {
            presentationId: model.id,
            active: active,
        };
        this.loadingService.loadingStart();
        this.presentationService.active(payload)
            .pipe(takeUntil(this.destroy$))
            .subscribe((res) => {
                this.loadingService.loadingEnd();
            }, () => this.loadingService.loadingEnd());
    }

    public search(event: string): void {
        this.searchQuery = event;
        this.table.refreshTable();
    }

    private initTableConfig() {
        this.tableConfig = {
            dataField: 'data',
            searchFn: (sortParams, pagingParams) => {
                return this.presentationService.getList(this.payload.mediaId, this.searchQuery, pagingParams, sortParams, true);
            },
            columns: [
                {
                    name: 'overlays.table.status',
                    sortField: 'ACTIVE',
                    tmpl: this.statusTmpl,
                    width: 70,
                    class: 'center'
                },
                {
                    name: 'presentation.table.field.fileName',
                    sortField: 'FILE_NAME',
                    dataField: 'fileName',
                    width: 100,
                    class: 'left'
                }, {
                    name: 'presentation.table.field.upload',
                    sortField: 'FILE_UPLOAD_DATE',
                    dataField: 'fileUploadDate',
                    width: 100,
                    tmpl: this.dateTmplCourses,
                    class: 'left'
                }, {
                    name: 'presentation.table.field.showClickAmount',
                    sortField: 'SHOW_CLICKS',
                    dataField: 'showClickAmount',
                    width: 100,
                    class: 'right',
                }, {
                    name: 'presentation.table.field.downloads',
                    sortField: 'DOWNLOAD_CLICKS',
                    dataField: 'fileDownloadAmount',
                    width: 100,
                    class: 'right',
                }, {
                    name: 'presentation.table.field.link',
                    sortField: 'LINK_CLICKS',
                    dataField: 'linkClickAmount',
                    width: 100,
                    class: 'right',
                }
            ]
        };
    }

    onToggle($event: any, data) {
        this.toggleActive($event.value, data);
        this.activateForm.get('active').setValue($event.value);
    }

    private getPresentationByMediaID(mediaId: string) {
        return this.presentationService.getPresentationByMediaID(mediaId).subscribe(res => {
            if (!res.success || !res.results.data) {
                return;
            }

            this.payload.id = res.results.data.id;
            this.pdfForm.patchValue(res.results.data);

            this.file.fileId = res.results.data.fileId;
            this.file.slides = res.results.data.slides;
            this.file.fileName = res.results.data.fileName;
            this.file.fileUploadDate = res.results.data.fileUploadDate;
            this.fileForm.get('file').setValue(this.file, {emitEvent: false});

            this.settingsForm.get('viewerDownloadAllowed').setValue(res.results.data.viewerDownloadAllowed);
            this.activateForm.get('active').setValue(res.results.data.active, {emitEvent: false});
        });
    }
}
