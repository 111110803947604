import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { HeaderPopupType, HeaderService } from '@src/app/services/header/header.service';

@Component({
  selector: 'app-token-expired-page',
  templateUrl: './token-expired-page.component.html',
  styleUrls: ['./token-expired-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TokenExpiredPageComponent {

  @Input() title = '';
  @Input() desc = '';
  @Input() info = '';

  constructor(
    public headerService: HeaderService
  ) { }

  ngOnInit(){
    this.headerService.isTokenExpiredPage.next(true);
  }

  ngOnDestroy(){
    this.headerService.isTokenExpiredPage.next(false);
  }

  resetClicked(){
    this.headerService.showHeaderPopup(HeaderPopupType.FORGOT_PASSWORD);
  }

}
