<div class="modal show" *ngIf="mediaId" (click)="closeModal()">
    <div class="modal-dialog modal-dialog-centered" role="document" (click)="stopImmediatePropagation($event)">
        <div mat-dialog-content class="modal-content">
            <div class="modal-header">
                <h4><b>{{modalTitle | localize}}</b></h4>
            </div>
            <div class="explanations-container">
                <p class="first">{{'domainRestriction.modal.description' | localize}}</p>
            </div>
            <div class="modal-top">
                <div class="input-wrap">
                    <app-bordered-form
                            [defaultForm]="true"
                            [form]="form"
                            [fieldsConfig]="formConfig"
                    ></app-bordered-form>
                </div>
            </div>
            <div class="footer-container">
                <div class="buttons-wrap">
                    <button class="btn btn-danger cancel-button"
                            (click)="closeModal()">{{'common.button.close' | localize}}</button>
                    <button class="btn btn-success"
                            (click)="onSubmit()">{{ 'domainRestriction.modal.add' | localize }}</button>
                </div>
            </div>
        </div>
    </div>
</div>
