import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Modal} from '@src/app/components/modal-container/models/modal.model';
import {IPaymentCard, IPaymentCardsListControls} from '@src/app/components/payment-cards-list/payment-cards-list.component';
import {IHeidelpayPaymentDetails} from '@src/app/components/payment-cards-list/heidelpay-payment/heidelpay-payment.component';

@Component({
  selector: 'app-payment-modal',
  templateUrl: './payment-modal.component.html',
  styleUrls: ['./payment-modal.component.sass']
})
export class PaymentModalComponent extends Modal {
  @Input() returnUrl: string;
  @Output() proceedPayment$: EventEmitter<IPaymentCard> = new EventEmitter<IPaymentCard>();
  @Output() cancel$: EventEmitter<any> = new EventEmitter<any>();
  paymentCardsListControls: IPaymentCardsListControls;
  onlyCard: boolean;
  dismissButtonText = 'common.button.close';
  submitButtonText = 'common.button.apply';

  public submit(e: any): void {
  }

  public save(id: string): void {
    // this.streamService.update$.emit({groupCreated$: true});
    this.close({});
  }

  public cancel(output?: any): void {
    this.cancel$.emit();
    this.dismiss(output);
  }

  public proceedPayment(event: IPaymentCard): void {
    this.proceedPayment$.emit(event);
  }

}
