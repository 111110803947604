import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';

@Component({
  selector: 'app-header-popup',
  templateUrl: './header-popup.component.html',
  styleUrls: ['./header-popup.component.sass']
})
export class HeaderPopupComponent implements OnInit {
  @Input() opened = false;
  @Input() withBackdrop = false;
  @Input() title = '';
  @Input() titleSuccess = false;
  @Input() subTitle = '';
  @Input() description = '';
  @Input() successText = '';
  @Input() loading = false;
  @Input() withoutBody = false;
  // @Output() closed$: EventEmitter<any> = new
  // @HostListener('document: click', ['$event'])
  // closeDropdown(event: any) {
  //   if (this.withBackdrop) {
  //     return;
  //   }
  //   if (!this.elementRef.nativeElement.contains(event.target)) {
  //     this.opened = false;
  //   }
  // }
  constructor(
      private elementRef: ElementRef
  ) { }

  ngOnInit(): void {
  }
}
