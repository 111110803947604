import {EventEmitter, Injectable} from '@angular/core';
import {IMediaPaymentRequest} from '@src/app/models/stream.model';
import {BehaviorSubject, Observable} from 'rxjs';
import {IMediaPaymentProceedResponse, IPaymentConfig, IResponse} from '@src/app/models/response.model';
import {API_URLS_APP, UrlGenerator} from '@src/app/constants/api-urls.constant';
import {pluck, tap} from 'rxjs/operators';
import {ApiService} from 'ui-elements';
import {StreamService} from '@src/app/services/stream-metadata/stream.service';
import {IPaymentCard, IPaymentCardAddReq, IPaymentUrlsReq} from '@src/app/components/payment-cards-list/payment-cards-list.component';
import {TARIFF_PLAN_PERIOD} from '@src/app/components/tariff-plan-cards-list/subscription-plan-card/subscription-plan-card.component';
import {Location} from '@angular/common';
import {TARIFF_PLAN_TYPES} from '@src/app/components/tariff-plan-cards-list/tariff-plan-cards-list.component';
import {
    ICommonPaymentCard,
    IHeidelpayCard,
    IHeidelpayPaymentDetails
} from '@src/app/components/payment-cards-list/heidelpay-payment/heidelpay-payment.component';
import {PAYMENT_STATUS} from '@src/app/services/subscription-manage/subscription-manage.service';

@Injectable({
    providedIn: 'root'
})
export class PaymentService {
    private readonly TEMP_ERROR_INFO = 'tempErrorInfo';

    transactionFailReason: PAYMENT_FAIL_MESSAGES;
    failedTransactionInfo = new BehaviorSubject<IFailedTransactionInfo>(null);

    slotPayment$: EventEmitter<ISlotPayment> = new EventEmitter<ISlotPayment>();
    groupPayment$: EventEmitter<IGroupPayment> = new EventEmitter<IGroupPayment>();

    constructor(
        private apiService: ApiService,
        private streamService: StreamService,
    ) {
    }

    get failedTransactionInfo$(): Observable<IFailedTransactionInfo> {
        return this.failedTransactionInfo.asObservable();
    }

    resetPaymentCardError(): void {
        this.failedTransactionInfo.next(null);
    }

    public getPaymentConfig(): Observable<IPaymentConfig> {
        return this.apiService.get(API_URLS_APP.GET_PAYMENT_CONFIG).pipe(pluck('results', 'config'));
    }

    public proceedMediaPayment(body: IMediaPaymentRequest): Observable<IResponse<IMediaPaymentProceedResponse>> {
        return this.apiService.post(UrlGenerator.generate(API_URLS_APP.PROCEED_PAYMENT, {}, {testFail: false}), body)
            .pipe(tap((res) => {
                if (res && res.results && res.results.result && res.results.result.accessToken) {
                    this.streamService.updateAccessToken(res.results.result.accessToken);
                }
            }));
    }

    public proceedSlotPayment(body: IMediaPaymentRequest): Observable<IResponse<IMediaPaymentProceedResponse>> {
        return this.apiService.post(UrlGenerator.generate(API_URLS_APP.PROCEED_SLOT_PAYMENT, {}), body)
            .pipe(tap((res) => {
                if (res && res.results && res.results.result && res.results.result.accessToken) {
                    this.streamService.updateAccessToken(res.results.result.accessToken);
                }
            }));
    }

    public getPaymentCards(): Observable<IPaymentCard[]> {
        return this.apiService.get(API_URLS_APP.PAYMENT_CARD).pipe(pluck('results', 'data'));
    }

    public createPaymentCard(paymentCard: IPaymentCardAddReq): Observable<IPaymentReturnResponse> {
        return this.apiService.post(API_URLS_APP.PAYMENT_CARD, paymentCard)
            .pipe(pluck('results', 'data'));
    }

    public updateCard(card: IPaymentCard): Observable<boolean> {
        return this.apiService.put(API_URLS_APP.PAYMENT_CARD, card).pipe(pluck('success'));
    }

    public setCardAsActive(cardId: string): Observable<boolean> {
        return this.apiService.put(UrlGenerator.generate(API_URLS_APP.SET_PAYMENT_CARD_AS_ACTIVE, {cardId}), {})
            .pipe(pluck('success'));
    }

    public deletePaymentCard(cardId): Observable<boolean> {
        return this.apiService.delete(UrlGenerator.generate(API_URLS_APP.DELETE_PAYMENT_CARD, {cardId}))
            .pipe(pluck('success'));
    }

    public activateCard(transactionId: string, failTransaction: boolean, viewerAccount: boolean): Observable<IResponse<{ data: IPaymentReturnResponse }>> {
        return this.apiService.post(UrlGenerator.generate(API_URLS_APP.ACTIVATE_PAYMENT_CARD, {}, {testFail: failTransaction}), {transactionId, viewerAccount});
    }

    public getNewPaymentCardData(card: IHeidelpayCard): ICommonPaymentCard {
        return {
            externalCardId: card.id,
            expiryDate: card.expiryDate,
            type: card.brand,
            number: card.number.slice(-4),
            cardDetails: {
                id: card.id,
                brand: card.brand,
                cvc: card.cvc,
                expiryDate: card.expiryDate,
                number: card.number.slice(-4),
                method: card.method,
                account: card.cardDetails.account,
                cardType: card.cardDetails.cardType,
                countryIsoA2: card.cardDetails.countryIsoA2,
                countryName: card.cardDetails.countryName,
                issuerName: card.cardDetails.issuerName,
                issuerPhoneNumber: card.cardDetails.issuerPhoneNumber,
                issuerUrl: card.cardDetails.issuerUrl,
                '3ds': card['3ds']
            }
        };
    }

    public getAccessToGroup(body): Observable<IResponse<{ result: IPaymentReturnResponse }>> {
        return this.apiService.post(UrlGenerator.generate(API_URLS_APP.GROUP_GET_ACCESS, {}, {testFail: false}), body).pipe(tap((res) => {
            if (res?.results?.result?.accessToken) {
                this.streamService.updateAccessToken(res.results.result.accessToken);
            }
        }));
    }

    public checkTransactionStatus(transactionId: string): Observable<IResponse<{ result: IPaymentReturnResponse }>> {
        const body = {
            accessToken: '',
            transactionId
        };

        if (this.streamService.viewerAccessToken) {
            body.accessToken = this.streamService.viewerAccessToken;
        }

        return this.apiService.post(UrlGenerator.generate(API_URLS_APP.PAYMENT_RETURN, {}, {testFail: false}), body)
            .pipe(tap((res) => {
                if (res?.results?.result?.accessToken && res?.results?.result?.purchaseInfo) {
                    this.streamService.updateAccessToken(res.results.result.accessToken);
                }
            }));
    }

}

export interface INewPaymentCard {
    newCard: boolean;
    expiryDate: string;
    type: string;
    number: string;
    heidelpayCard: IHeidelpayCard;
}

export interface IStartPaymentReq extends IPaymentUrlsReq {
    userCardId?: string;
    paymentCard?: IPaymentCard;
    paymentMethod?: {
        resourceId: string;
        method: string;
        userCardId?: string;
        saveCard?: boolean;
        paymentCard?: ICommonPaymentCard;
    };
    saveCard?: boolean;
    subscriptionTariffId?: string;
    groupId?: string;
    // externalCardId?: string,
    subscriptionPeriod?: TARIFF_PLAN_PERIOD,
    subscriptionTariffType?: TARIFF_PLAN_TYPES;
    // type?: PAYMENT_CARD_TYPE;
    // number?: string;
    // expiryDate?: string;
    // heidelpayCard?: IHeidelpayCard;
    // newCard?: boolean;
    accessToken?: string;
}

export interface IPaymentReturnResponse {
    externalCardId?: string;
    reason?: PAYMENT_FAIL_MESSAGES;
    message: string;
    paymentStatus: PAYMENT_STATUS;
    redirectUrl: string;
    purchaseInfo: {
        productId: string;
        purchaseType: PAYMENT_PURCHASE_TYPES;
    };
    success: boolean;
}

export enum PAYMENT_FAIL_MESSAGES {
    SYSTEM_ERROR = 'SYSTEM_ERROR',
    PROVIDER_ERROR = 'PROVIDER_ERROR',
    FAILED = 'FAILED'
}

export enum PAYMENT_PURCHASE_TYPES {
    STREAM = 'STREAM',
    STREAM_GROUP = 'STREAM_GROUP',
    STREAM_RECORDING = 'STREAM_RECORDING',
    VIDEO_ON_DEMAND = 'VIDEO_ON_DEMAND',
    SUBSCRIPTION = 'SUBSCRIPTION',
    STORAGE_SPACE = 'STORAGE_SPACE',
    BANDWIDTH = 'BANDWIDTH',
    MULTIBITRATE = 'MULTIBITRATE',
    DONATION = 'DONATION'
}

export interface IFailedTransactionInfo {
    failedCardId: string;
    transactionFailReason: PAYMENT_FAIL_MESSAGES;
    absoluteError?: boolean;
}

interface IPayment {
    paymentCard: IHeidelpayPaymentDetails | IPaymentCard;
    isNewCardPayment: boolean;
}

export interface ISlotPayment extends IPayment {
    slotId: string;
}

export interface IGroupPayment extends IPayment {
    groupId: string;
}
