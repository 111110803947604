<div class="content">
    <div class="panel">
        <div class="panel-header pb-0">
            <h2 class="title-lg"><b>{{'storage-and-bandwidth.title' | localize}}</b></h2>
        </div>
    </div>

    <div class="row row-padding-10">
        <div *ngIf="!hiddenBandwidthLimit" class="col-lg-6">
            <div class="card bg-white mb-20">
                <ano-loader [isOpen]="loadingService.loading | async"></ano-loader>
                <div class="card-body pt-30">
                    <div class="chart-bandwidth">
                        <div class="row justify-content-center chart-img">
                            <img class="bandwidth-speedometer"
                                 [src]="'assets/img/graphics/bandwidth-speedometer-' + (bandwidthIconIndex || 1) + '.svg'"
                                 alt="bandwidth"/>
                        </div>
                        <span class="data-bandwidth">{{storageAndBandwidth?.dataLimits?.bandwidth?.used?.value}} {{storageAndBandwidth?.dataLimits?.bandwidth?.used?.unit}}</span>
                        <p>{{'storage-and-bandwidth.limit-details.of' | localize}}
                            <b class="link-color">{{storageAndBandwidth?.dataLimits?.bandwidth?.limit?.value}} {{storageAndBandwidth?.dataLimits?.bandwidth?.limit?.unit}}</b>
                            {{'storage-and-bandwidth.limit-details.currently-used' | localize}}
                            <br>{{'storage-and-bandwidth.limit-details.this-is' | localize}} <b
                                    class="link-color">{{storageAndBandwidth?.dataLimits?.bandwidth?.percent.toFixed(2)}} %.</b>
                        </p>
                        <div class="row justify-content-center" *ngIf="(userService.userType$ | async) !== USER_TYPE.SUB_ACCOUNT">
                            <div class="col-auto">
                                <button class="btn btn-primary" (click)="scrollToBandwidthPlans()">
                                    {{'storage-and-bandwidth.buttons.increase-bandwidth' | localize}}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6">
            <div class="card bg-white mb-20">
                <ano-loader [isOpen]="loadingService.loading | async"></ano-loader>
                <div class="card-body pt-30">
                    <div class="chart-bandwidth">
                        <div class="row justify-content-center">
                            <div class="col-auto">
                                <div class="chart-img">
                                    <svg height="150" width="150" viewBox="0 0 20 20">
                                        <circle r="10" cx="10" cy="10" fill="#e6eef6"/>
                                        <circle r="5" cx="10" cy="10" fill="transparent"
                                                id="storage-fill-circle"
                                                stroke="#0e54a9"
                                                stroke-width="10"
                                                stroke-dasharray="0 31.4"
                                                transform="rotate(-90) translate(-20)"/>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <span class="data-bandwidth">{{storageAndBandwidth?.dataLimits?.storageSpace?.used?.value}} {{storageAndBandwidth?.dataLimits?.storageSpace?.used?.unit}}</span>
                        <p>{{'storage-and-bandwidth.limit-details.of' | localize}}
                            <b class="link-color">{{storageAndBandwidth?.dataLimits?.storageSpace?.limit?.value}} {{storageAndBandwidth?.dataLimits?.storageSpace?.limit?.unit}}</b>
                            {{'storage-and-bandwidth.limit-details.currently-used' | localize}}
                            <br>{{'storage-and-bandwidth.limit-details.this-is' | localize}} <b
                                    class="link-color">{{storageAndBandwidth?.dataLimits?.storageSpace?.percent.toFixed(2)}} %.</b></p>
                        <div class="row justify-content-center" *ngIf="(userService.userType$ | async) !== USER_TYPE.SUB_ACCOUNT">
                            <div class="col-auto">
                                <button class="btn btn-primary" (click)="scrollToStoragePlans()">
                                    {{'storage-and-bandwidth.buttons.expand-storage-space' | localize}}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ng-container *ngIf="(userService.userType$ | async) !== USER_TYPE.SUB_ACCOUNT">
        <div class="card bg-transparent" #bandwidthPlans>
            <div class="card-header pl-0 pr-0">
                <h2 class="title-level-3">{{'storage-and-bandwidth.increase-bandwidth.title' | localize}}</h2>
                <p class="mb-5">{{'storage-and-bandwidth.increase-bandwidth.description' | localize}}</p>
            </div>
            <div class="card-body pl-0 pr-0">
                <ano-loader [isOpen]="loadingService.loading | async"></ano-loader>
                <div class="panel panel-md">
                    <app-plan-cards-list
                            [config]="bandWidthTariffPlansConfig"
                            [isAuthorized]="true"
                            [tariffPlans]="bandWidthTariffPlans"
                                         (actionButtonPressed$)="activateBandwidthTariffPlan($event)"></app-plan-cards-list>
                </div>
            </div>
        </div>
    </ng-container>

        <div class="card bg-transparent">
            <div class="card-header pl-0 pr-0">
                <h2 class="title-level-3">{{'storage-and-bandwidth.bandwidth-calculator.title' | localize}}</h2>
                <p class="mb-5">{{'storage-and-bandwidth.bandwidth-calculator.description' | localize}}</p>
            </div>
            <div class="card-body bg-white pt-30">
                <app-bitrate-calculator></app-bitrate-calculator>
            </div>
        </div>

    <ng-container *ngIf="(userService.userType$ | async) !== USER_TYPE.SUB_ACCOUNT">
        <div class="card bg-transparent" #storagePlans>
            <div class="card-header pl-0 pr-0">
                <h2 class="title-level-3">{{'storage-and-bandwidth.increase-storage.title' | localize}}</h2>
                <p class="mb-5" *ngIf="storageTariffPlansConfig.planPeriod === TARIFF_PLAN_PERIOD.MONTH">{{'storage-and-bandwidth.increase-storage.description.month' | localize}}</p>
                <p class="mb-5" *ngIf="storageTariffPlansConfig.planPeriod === TARIFF_PLAN_PERIOD.YEAR">{{'storage-and-bandwidth.increase-storage.description.year' | localize}}</p>
            </div>
            <div class="card-body pl-0 pr-0">
                <ano-loader [isOpen]="loadingService.loading | async"></ano-loader>
                <div class="panel panel-md">
                    <app-plan-cards-list
                            [tariffPlans]="storageTariffPlans"
                            [config]="storageTariffPlansConfig"
                            [isAuthorized]="true"
                                         (actionButtonPressed$)="activateStorageTariffPlan($event)"
                    ></app-plan-cards-list>
                </div>
            </div>
        </div>
    </ng-container>

</div>
