import {Component, OnInit, ViewChild} from '@angular/core';
import {
    IStorageAndBandwidth, IStorageAndBandwidthTariffs,
    ITariffPlan,
    StorageAndBandwidthService
} from '@src/app/services/storage-and-bandwidth/storage-and-bandwidth.service';
import {take} from "rxjs/operators";
import {LoadingService} from "@src/app/services/loading/loading.service";
import {
    ITariffPlanConfig,
    TARIFF_PLAN_TYPES
} from "@src/app/components/tariff-plan-cards-list/tariff-plan-cards-list.component";
import {Router} from "@angular/router";
import {TARIFF_PLAN_PERIOD} from "@src/app/components/tariff-plan-cards-list/subscription-plan-card/subscription-plan-card.component";
import {UserService} from '@src/app/services/user/user.service';
import {USER_TYPE} from '@src/app/constants/user-type.constant';

@Component({
    selector: 'app-storage-and-bandwidth',
    templateUrl: './storage-and-bandwidth.component.html',
    styleUrls: ['./storage-and-bandwidth.component.sass']
})
export class StorageAndBandwidthComponent implements OnInit {
    public TARIFF_PLAN_PERIOD = TARIFF_PLAN_PERIOD;
    public USER_TYPE = USER_TYPE;
    @ViewChild('bandwidthPlans', {static: false}) bandwidthPlans;
    @ViewChild('storagePlans', {static: false}) storagePlans;

    storageAndBandwidth: IStorageAndBandwidthTariffs;
    hiddenBandwidthLimit = false;

    bandwidthIconIndex: number;

    bandWidthTariffPlans: ITariffPlan[];
    storageTariffPlans: ITariffPlan[];

    bandWidthTariffPlansConfig: ITariffPlanConfig = {
        planType: TARIFF_PLAN_TYPES.BANDWIDTH,
        iconName: 'bandwidth',
        planPeriod: TARIFF_PLAN_PERIOD.MONTH
    };
    storageTariffPlansConfig: ITariffPlanConfig = {
        planType: TARIFF_PLAN_TYPES.STORAGE_SPACE,
        iconName: 'memoryspace',
        showPeriodToggle: true,
        planPeriod: TARIFF_PLAN_PERIOD.MONTH
    };

    constructor(
        private storageAndBandwidthService: StorageAndBandwidthService,
        public loadingService: LoadingService,
        private router: Router,
        public userService: UserService
    ) {
    }

    ngOnInit(): void {
        this.loadingService.loadingStart();

        this.storageAndBandwidthService.getDataLimit().pipe(take(1)).subscribe(data => {
            this.storageAndBandwidth = data;
            this.hiddenBandwidthLimit = data.hiddenDataLimits;

            const bandwidthPercent = data?.dataLimits?.bandwidth?.percent || 0;

            if (bandwidthPercent > 100) {
                this.bandwidthIconIndex = 5;
            } else {
                this.bandwidthIconIndex = Math.max(Math.ceil(bandwidthPercent / 20), 1);
            }


            this.bandWidthTariffPlans = data?.bandwidthTariffPlans?.sort(this.tariffPlanSortFunc);
            this.storageTariffPlans = data?.storageSpaceTariffPlans?.sort(this.tariffPlanSortFunc);

            this.setStorageDataCircleFill(data?.dataLimits?.storageSpace?.percent);

            this.loadingService.loadingEnd();
        });
    }

    activateBandwidthTariffPlan(tariffPlan: ITariffPlan): void {
        this.router.navigate(['bandwidth/upgrade', tariffPlan.id, TARIFF_PLAN_PERIOD.WEEK]);
    }

    activateStorageTariffPlan(tariffPlan: ITariffPlan): void {
        this.router.navigate(['storage/upgrade', tariffPlan.id, this.storageTariffPlansConfig.planPeriod]);
    }

    tariffPlanSortFunc(a: ITariffPlan, b: ITariffPlan): number {
        return (a.limit.value > b.limit.value) ? 1 : (a.limit.value === b.limit.value) ? a.prices[0].prices[0].price.amount ? -1 : 1 : -1;
    }

    setStorageDataCircleFill(percent: number): void {
        const storageCircle = document.getElementById('storage-fill-circle');
        storageCircle.setAttribute('stroke-dasharray', `calc(${percent} * 31.4 / 100) 31.4`);
    }

    scrollToBandwidthPlans(): void {
        this.bandwidthPlans.nativeElement.scrollIntoView({block: 'center', behavior: 'smooth'});
    }

    scrollToStoragePlans(): void {
        this.storagePlans.nativeElement.scrollIntoView({block: 'center', behavior: 'smooth'});
    }

}
