export const SERVER_ERRORS = {
    NOT_LOGGED_IN: 'You must be logged in to access this page',
    NOT_FOUND: '',
    NAME_OR_PASSWORD_MISMATCH: 'Name or password mismatch',
    SYSTEM_ERROR: 'Something very unexpected happened. Our team is working on a solution already.',
    EMAIL_CONFIRMATION_TOKEN_IS_INVALID: 'The email confirmation link is not valid or expired. Maybe you opened wrong confirmation email?',
    REGISTRATION_EMAIL_ALREADY_EXISTS: 'There is already another account with this email-address. If this is your account, use password forgotten.',
    TOKEN_IS_INVALID: 'Invalid token (Sorry, it is what it is)',
    PASSWORD_DOES_NOT_MEET_THE_REQUIREMENTS: 'Your password should have at least 12 characters with at least one capital letter and one special symbol',
    PASSWORD_LOST_TOKEN_IS_INVALID: 'The password reset link has expired. Please make sure that you open the most recent password reset e-mail message and you did not use it to reset password already',
    OLD_PASSWORD_MISMATCH: 'Wrong old password. Try again.',
    NO_USER_EXISTS_WITH_SUCH_EMAIL: 'No user exists with such email',
};

export const DEFAULT_ERROR_MESSAGE = 'Something went wrong';
