import {ModalRef} from "@src/app/components/modal-container/models/modal-ref.model";

export abstract class Modal {

    modalInstance: ModalRef;

    close(output?: any): void {
        this.modalInstance.close(output);
    }

    dismiss(output?: any): void {
        this.modalInstance.dismiss(output);
    }

}