import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {
  ISurvey,
  MARKETING_ACTION,
  MARKETING_ENTITY_TYPE,
  SURVEY_STATUS,
  SurveyService
} from '@src/app/services/survey/survey.service';
import {
  ISchedule,
  IScheduleSignal,
  SchedulerService,
  TIME_POINT
} from '@src/app/services/scheduler-service/scheduler.service';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import {filter, take, takeUntil, tap} from 'rxjs/operators';
import {Observable} from 'rxjs/internal/Observable';
import {MEDIA_TYPE} from '@src/app/components/streamdust-player/constants/mediaType';
import * as _ from 'lodash';
import {MarketingService} from '@src/app/services/marketing/marketing.service';
import {asBoolean} from 'ui-elements';
import {StreamService} from '@src/app/services/stream-metadata/stream.service';
import {STREAM_STATUS} from '@src/app/components/streamdust-player/constants/status';

@Component({
    selector: 'app-viewer-surveys',
    templateUrl: './viewer-surveys.component.html',
    styleUrls: ['./viewer-surveys.component.sass'],
    providers: [SchedulerService, AutoDestroyService, SurveyService]
})
export class ViewerSurveysComponent implements OnInit, OnDestroy {
    @Input() mediaType: MEDIA_TYPE;
    @Input() mediaId: string;
    @Input() streamScheduledTime: number;
    @Input() signalObservable: Observable<IScheduleSignal>;
    @Input() playerSignalObservable: Observable<IScheduleSignal>;
    @Input() isRecord: boolean;
    @Input() startedLiveAt: number;
    public activeSurvey: ISurvey;
    private data: ISurvey[];
    private _data: ISurvey[];
    private started: boolean;
    private marketingService: MarketingService;

    constructor(
        private surveyService: SurveyService,
        private schedulerService: SchedulerService,
        private destroy: AutoDestroyService,
        private streamService: StreamService
    ) {
      this.marketingService = MarketingService.getInstance();
    }

    ngOnInit(): void {
        this.refreshData()
            .subscribe(() => {
                const schedules = this.schedules();
                this.schedulerService.schedule(schedules, !!this.streamScheduledTime);
                this.listenSchedulersEvents();
                this.listenTimeSignals();
                this.listenPlayerEvents();
                if (this.streamScheduledTime) {
                    this.schedulerService.start(new Date().getTime());
                }
            });
        // this.listenManualSurveysActions();
    }

    public refreshData(): Observable<ISurvey[]> {
        return this.surveyService.getAllPublic(this.mediaType, this.mediaId)
            .pipe(
                takeUntil(this.destroy),
                tap((res) => {
                    if (!res?.length) {
                        this.data = [];
                        this._data = [];
                        return;
                    }
                    res.forEach((item) => {
                        item.endTime = item.duration;
                        item.isManual = item.startTime === null;
                    });
                    this.data = res;
                    this._data = _.cloneDeep(this.data);
                })
            );
    }

    private schedules(): ISchedule[] {
        return this._data.filter(survey => survey.startTime !== null && (this.streamScheduledTime ? new Date().getTime() : 0) <= this.resolveSurveyEndTime(survey)).map(survey => {
            return {
                entityId: survey.id,
                eventType: 'SURVEY',
                timePointStart: this.resolveSurveyStartTime(survey),
                timePointStop: this.resolveSurveyEndTime(survey),
                mediaType: this.mediaType,
                isRecord: this.isRecord,
            };
        });
    }

    private getTimezoneOffsetInSeconds() {
      return new Date().getTimezoneOffset() * 60;
    }

    private calculateStartedLiveAtInSeconds() {
      const startedLiveAtDate = new Date(this.startedLiveAt);
      return (startedLiveAtDate.getHours() * 60 * 60) + (startedLiveAtDate.getMinutes() * 60) + (startedLiveAtDate.getSeconds());
    }

    private resolveSurveyStartTime(survey: ISurvey): number {
        if (this.streamScheduledTime) {
            return survey.isManual || survey.resultsPublished ? survey.startTime : this.streamScheduledTime + (survey.startTime * 1000);
        }

        if (this.mediaType === MEDIA_TYPE.STREAM) {
          const timezoneOffset = this.getTimezoneOffsetInSeconds();
          if (!this.isRecord) {
            return (survey.startTime - timezoneOffset) * 1000;
          }

          if (this.isRecord && this.startedLiveAt) {
            const startedLiveAt = this.calculateStartedLiveAtInSeconds();

            return (survey.startTime - startedLiveAt - timezoneOffset) * 1000;
          }
        }

        return survey.startTime * 1000;
    }

    private resolveSurveyEndTime(survey: ISurvey): number {
        if (this?.streamScheduledTime && !survey.isManual) {
            return this.streamScheduledTime + ((survey.startTime + survey.endTime) * 1000);
        }
        if (this.mediaType === MEDIA_TYPE.STREAM) {
          const timezoneOffset = this.getTimezoneOffsetInSeconds();
          if (!this.isRecord) {
            return ((survey.startTime + survey.duration) - timezoneOffset) * 1000;
          }
          if (this.isRecord && this.startedLiveAt) {
            const startedLiveAt = this.calculateStartedLiveAtInSeconds();

            return ((survey.startTime + survey.duration) - startedLiveAt - timezoneOffset) * 1000;
          }
        }
        return (survey.startTime + survey.duration) * 1000;
    }

    private listenSchedulersEvents(): void {
        this.schedulerService.events
            .pipe(takeUntil(this.destroy))
            .subscribe((ev) => {
                if (!ev) {
                    return;
                }
                switch (ev.timePoint) {
                    case TIME_POINT.START:
                        this.activateSurvey(ev.entityId);
                        break;
                    case TIME_POINT.STOP:
                        this.deactivateSurvey(ev.entityId);
                        break;
                }
            });
    }

    private listenManualSurveysActions(): void {
        this.marketingService.connectToSocket(this.mediaId, this.mediaType)
            .pipe(takeUntil(this.destroy), filter(res => !!res))
            .subscribe(res => {
                if (!res) {
                    return;
                }
                if (res.results.data.type !== MARKETING_ENTITY_TYPE.SURVEY) {
                    return;
                }

                if (res.results.data.action === MARKETING_ACTION.UPDATE_STATUS && ![SURVEY_STATUS.PUBLISH_RESULTS, SURVEY_STATUS.HIDE_RESULTS].includes(res.results.data.status)) {
                    this.refreshData().pipe(take(1))
                        .subscribe(() => {
                            this.schedulerService.reschedule(this.schedules(), !!this.streamScheduledTime);
                            return;
                        });
                    return;
                }
                const status = res.results.data.status;
                const survey = this._data.find(srv => srv.id === res.results.data.id);
                this.adjustSurveySchedule(survey, res.results.data.action as MARKETING_ACTION, status as SURVEY_STATUS);
                // this.schedulerService.reschedule(this.schedules(), !!this.streamScheduledTime);
            });
    }

    private adjustSurveySchedule(survey: ISurvey, action: MARKETING_ACTION, status: SURVEY_STATUS): void {
        const newEventTime = this.schedulerService.timer + 1000;
        if (action === MARKETING_ACTION.SHOW) {
            this.activateSurvey(survey.id);
            // survey.startTime = newEventTime;
            // survey.endTime = survey.startTime + (survey.duration * 1000);
        }

        if (status === SURVEY_STATUS.PUBLISH_RESULTS) {
            // survey.startTime = newEventTime;
            // survey.endTime = newEventTime + 99999999;
            survey.resultsPublished = true;
            this.activateSurvey(survey.id);
        }

        if (status === SURVEY_STATUS.HIDE_RESULTS) {
            this.deactivateSurvey(survey.id);
            // survey.startTime = newEventTime;
            // survey.endTime = new Date().getTime() + 1000;
            survey.resultsPublished = false;
        }

        if (action === MARKETING_ACTION.HIDE) {
            // survey.endTime = new Date().getTime() + 1000;
            this.deactivateSurvey(survey.id);
        }

        survey.status = status;
    }

    private listenTimeSignals(): void {
        this.signalObservable.pipe(takeUntil(this.destroy))
            .subscribe((signal: IScheduleSignal) => {
                if (signal.start !== undefined) {
                    this.schedulerService.start(this.streamScheduledTime ? new Date().getTime() : signal.start);
                    if (!this.started) {
                        this.listenManualSurveysActions();
                        this.started = true;
                    }
                }
                if (signal.stop) {
                    this.schedulerService.stop();
                }

                if (signal.pause) {
                    this.schedulerService.pause();
                }
            });
    }

    private activateSurvey(id: string): void {
        const survey = this._data.find(_survey => _survey.id === id);
        if (asBoolean(localStorage.getItem(id + '.answered')) && !survey.resultsPublished) {
            return;
        }
        this.activeSurvey = survey;
    }

    private deactivateSurvey(id: string): void {
        if (!this.activeSurvey) {
            return;
        }
        if (this.activeSurvey.id !== id) {
            return;
        }
        this.activeSurvey = null;
    }

    public answered(): void {
        this.refreshData().subscribe(() => {
            if (this.activeSurvey) {
                this.activeSurvey = this._data.find(survey => survey.id === this.activeSurvey.id);
                this.activeSurvey.hide = true;
                if (this.mediaType === MEDIA_TYPE.VIDEO_ON_DEMAND) {
                    this.activeSurvey.resultsPublished = true;
                    return;
                }
                this.streamService.streamStatus$.pipe(take(1))
                    .subscribe((res) => {
                        if (res?.stream !== STREAM_STATUS.FINISHED) {
                            this.activeSurvey = null;
                            return;
                        }
                       this.activeSurvey.resultsPublished = true;
                    });
            }
        });
    }

    private listenPlayerEvents(): void {
        if (!this.playerSignalObservable) {
            return;
        }
        this.playerSignalObservable.pipe(takeUntil(this.destroy))
            .subscribe((signal: IScheduleSignal) => {
                if (signal.start !== undefined) {
                    this.schedulerService.start(this.streamScheduledTime ? new Date().getTime() : signal.start, true);
                }
                if (signal.stop) {
                    this.schedulerService.stop();
                }

                if (signal.pause) {
                    this.schedulerService.pause();
                }
            });
    }

    ngOnDestroy() {
        this.schedulerService.stop();
    }

    public close() {
        if (this.activeSurvey.resultsPublished) {
            this.activeSurvey.endTime = 0;
        }
        this.activeSurvey = null;
    }


}

