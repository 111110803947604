import {ApplicationRef, ComponentFactory, ComponentFactoryResolver, ComponentRef, Injectable, Type} from '@angular/core';
import {ModalContainerComponent} from '@src/app/components/modal-container/modal-container.component';
import {ModalRef} from '@src/app/components/modal-container/models/modal-ref.model';
import {Modal} from "@src/app/components/modal-container/models/modal.model";

@Injectable({
  providedIn: 'root',
})
export class ModalService {
    private modalContainer: HTMLElement;
    private modalContainerFactory: ComponentFactory<ModalContainerComponent>;

  constructor(
      private componentFactoryResolver: ComponentFactoryResolver,
      private appRef: ApplicationRef
  ) {
      this.setupModalContainerFactory();
  }

    open<T extends Modal>(component: Type<T>, cb = (arg: ComponentRef<T>) => {}): ModalRef {
        this.setupModalContainerDiv();
        const modalContainerRef = this.appRef.bootstrap(this.modalContainerFactory, this.modalContainer);

        const modalComponentRef = modalContainerRef.instance.createModal(component);
        cb(modalComponentRef);

        return new ModalRef(modalContainerRef, modalComponentRef);

    }

    private setupModalContainerDiv(): void {
        this.modalContainer = document.createElement('div');
        document.getElementsByTagName('body')[0].appendChild(this.modalContainer);
    }

    private setupModalContainerFactory(): void {
        this.modalContainerFactory = this.componentFactoryResolver.resolveComponentFactory(ModalContainerComponent);
    }
}
