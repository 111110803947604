import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UserService} from '@src/app/services/user/user.service';

@Component({
    selector: 'app-subscription-tariffs-comparison',
    templateUrl: './subscription-tariffs-comparison.component.html',
    styleUrls: ['./subscription-tariffs-comparison.component.scss']
})
export class SubscriptionTariffsComparisonComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<SubscriptionTariffsComparisonComponent>,
        public userService: UserService,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
    }

    ngOnInit(): void {
    }

    closeModal(): void {
        this.dialogRef.close();
    }

    stopImmediatePropagation(event): void {
        event.stopImmediatePropagation();
    }

}
