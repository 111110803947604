import { Pipe, PipeTransform } from '@angular/core';
import {environment} from '@src/environments/environment';

@Pipe({
  name: 'image'
})
export class ImagePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    if (value.startsWith('data:image')) {
      return value;
    }
    return environment.backendApiHost + value;
  }

}
