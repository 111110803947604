import {Pipe, PipeTransform} from '@angular/core';
import {IPrice} from "@src/app/models/stream.model";
import {getWithDecimals} from "@src/app/utils/currency.util";
import {CURRENCY_SYMBOLS} from "@src/app/components/streamdust-player/media-access-modal/media-access-modal.component";

@Pipe({
    name: 'currency'
})
export class CurrencyPipe implements PipeTransform {

    transform(value: IPrice, withSymbol: boolean = true): string {
        if (value?.currency) {
            let res = (withSymbol ? CURRENCY_SYMBOLS[value.currency] : '')  + ' ' + getWithDecimals(Math.abs(value.amount).toFixed(2));
            if (Math.sign(value.amount) === -1) {
                res = '-' + res;
            }
            return res;
        }

        return null;
    }

}
