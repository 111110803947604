import {takeUntil} from 'rxjs/operators';
import {SETTING_TYPE} from '@src/app/pipes/item-setting';
import {ITEM_TYPE} from '@src/app/pipes/item-setting';
import {StreamService} from '@src/app/services/stream-metadata/stream.service';
import {AutoDestroyService} from 'ui-elements';
import {IFieldsConfig} from './../../../../user-profile/interfaces/interfaces-common';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {OnInit, Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {INPUT_TYPES} from 'ui-elements';
import {IMediaItem} from '@src/app/models/core.model';
import {StreamDomainRestrictionsService} from '@src/app/services/stream-domain-restrictions/stream-domain-restrictions.service';
import {CustomValidators} from '@src/app/utils/custom-validators.util';
import {urlWithProtocol} from '@src/app/utils/url.util';

@Component({
    selector: 'app-stream-domain-restrictions-modal',
    templateUrl: './stream-domain-restrictions-modal.component.html',
    styleUrls: ['./stream-domain-restrictions-modal.component.scss'],
    providers: [AutoDestroyService]
})
export class StreamDomainRestrictionsModalComponent implements OnInit {
    form: FormGroup;
    formConfig: IFieldsConfig[][];
    selectedStreams: IMediaItem;
    mediaId: string;
    ITEM_TYPE = ITEM_TYPE;
    SETTING_TYPE = SETTING_TYPE;
    modalTitle: string = titles[0];

    constructor(
        private streamService: StreamService,
        private streamDomainRestrictionsService: StreamDomainRestrictionsService,
        private fb: FormBuilder,
        public dialogRef: MatDialogRef<StreamDomainRestrictionsModalComponent>,
        private destroy$: AutoDestroyService,
        @Inject(MAT_DIALOG_DATA) public data
    ) {
    }

    ngOnInit(): void {
        if (this.data.hasOtherData) {
            this.modalTitle = titles[1];
        }
        this.init();
    }

    init(): void {
        this.initConfig();
        this.initForms();
        this.streamService.stream$.subscribe(s => this.mediaId = s.media.id);
    }

    private initForms(): void {
        this.form = this.fb.group({
            name: [this.data?.allowedDomain || '', [Validators.required, CustomValidators.URL]],
        });
    }

    private initConfig(): void {
        this.formConfig = [
            [
                {
                    name: 'name',
                    config: {
                        inputType: INPUT_TYPES.INPUT,
                        placeholder: 'domainRestriction.name',
                        isRequired: true
                    }
                },
            ]
        ];
    }

    onSubmit() {
        this.form.markAllAsTouched();
        if (this.form.invalid) {
            return;
        }

        if (!this.data.hasOtherData) {
            this.onSaveAllowedDomains();
        } else {
            this.onUpdateAllowedDomains(this.data);
        }
    }

    onSaveAllowedDomains(): void {
        const allowedDomain = urlWithProtocol(this.form.value.name);
        const payload = {
            allowedDomain: [allowedDomain],
            mediaId: this.mediaId,
        };

        this.streamDomainRestrictionsService.saveStreamAllowedDomains(payload)
            .pipe(takeUntil(this.destroy$))
            .subscribe((res) => {
                this.closeModal({isSave: true, domainItem: res});
        });
    }

    onUpdateAllowedDomains(data): void {
        const payload = {
            allowedDomain: [data.allowedDomain],
            mediaId: this.mediaId,
            newDomain: this.form.value.name,
            domainId: data.domainId,
        };

        this.streamDomainRestrictionsService.updateStreamAllowedDomains(payload)
            .pipe(takeUntil(this.destroy$))
            .subscribe((res) => {
                this.closeModal({isSave: true, newDomain: this.form.value.name, domainId: data.domainId});
            });
    }

    closeModal(dialogResults?): void {
        this.dialogRef.close(dialogResults);
    }

    stopImmediatePropagation(event): void {
        event.stopImmediatePropagation();
    }
}

export const titles: string[] = [
    'domainRestriction.modal.titleOnCreation',
    'domainRestriction.modal.titleOnEdit'
];
