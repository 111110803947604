import {Component, OnInit, ViewChild} from '@angular/core';
import {switchMap, takeUntil, tap} from 'rxjs/operators';
import {PhotoService} from '@src/app/services/photo/photo.service';
import {PhotoCropperService} from '@src/app/services/photo-cropper/photo-cropper.service';
import {IFormConfig, IFormFieldsConfig, StreamService} from '@src/app/services/stream-metadata/stream.service';
import {IStreamPreview, IStreamPreviewPayload} from '@src/app/models/stream.model';
import {ActivatedRoute, Params} from '@angular/router';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import {DragAndDropComponent} from  'ui-elements';
import {INPUT_TYPES} from 'ui-elements';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {of} from 'rxjs';

@Component({
    selector: 'app-stream-preview',
    templateUrl: './stream-preview.component.html',
    styleUrls: ['./stream-preview.component.sass']
})
export class StreamPreviewComponent implements OnInit {
    @ViewChild('photoUploader', {static: false}) private photoUploader: DragAndDropComponent;
    public fieldsConfig: IFormFieldsConfig[][];
    public formConfig: IFormConfig;
    public form: FormGroup
    public fileValue = '';
    public photoUrl: string;
    private photoPreviewQuality = 300;
    public streamId: string;
    public payload: IStreamPreviewPayload = {
        streamId: '',
        workbenchId: '',
        previewSettings: {
            x: 0,
            y: 0,
            width: 0,
            height: 0,
            rotation: 0,
        }
    };

    constructor(
        private photoService: PhotoService,
        private readonly photoCropperService: PhotoCropperService,
        private streamService: StreamService,
        private readonly activatedRoute: ActivatedRoute,
        private readonly destroy$: AutoDestroyService,
        private fb: FormBuilder
    ) {
    }

    ngOnInit() {
        this.buildForm();
        this.activatedRoute.parent.params.pipe(
            takeUntil(this.destroy$),
            tap(({id}: Params) => {
                this.streamId = id;
                this.payload.streamId = this.streamId;
            }))
            .subscribe(() => {
            });
        // this.streamService.getPreview(this.streamId)
        //     .pipe(takeUntil(this.destroy$))
        //     .subscribe((res) => this.photoUrl = res && res.photoUrl);
    }

    private buildForm(): void {
        this.fieldsConfig = [
            [
                {
                    name: 'photo',
                    control: ['', [Validators.required]],
                    config: {
                        inputType: INPUT_TYPES.IMAGE,
                        accept: 'image/*',
                        label: 'Preview',
                        isRequired: true
                    }
                },
            ]
        ];
        this.form = this.fb.group({
            photo: ['', [Validators.required]]
        });
    }

    public uploadPreviewPhoto({files}): void {
        const file = files;
        this.photoCropperService.completeWorkbenchReadySubject();

        if (!file) {
            return;
        }

        this.photoService.generateUploader({file, aspectRatio: 4 / 3})
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                ({croppingParams, workbenchId, croppedImage}) => {
                    this.photoCropperService.completeWorkbenchReadySubject();
                    if (croppedImage) {
                        this.photoUrl = croppedImage;
                    } else {
                        const reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = () => {
                            this.photoUrl = reader.result.toString();
                        };
                    }
                    this.payload.workbenchId = workbenchId;
                    this.payload.previewSettings = croppingParams;
                    this.form.get('photo').patchValue(workbenchId);
                },
            );
    }

    public submit(): void {
        this.form.markAllAsTouched();
        if (this.form.invalid) {
            return;
        }
        // this.streamService.uploadPreview(this.payload)
        //     .pipe(
        //         takeUntil(this.destroy$),
        //         switchMap((res) => {
        //             if (!res.success) {
        //                 return of([]);
        //             }
        //             return this.streamService.getPreview(this.streamId);
        //         })
        //     )
        //     .subscribe((res: IStreamPreview) => {
        //         if (!res) {
        //             return;
        //         }
        //
        //         this.photoUrl = res.photoUrl;
        //     });
    }

    public clearImage(): void {
        this.photoUrl = '';
        this.form.get('photo').patchValue('');
        this.payload = {
            streamId: this.streamId,
            workbenchId: '',
            previewSettings: {
                x: 0,
                y: 0,
                width: 0,
                height: 0,
                rotation: 0
            }
        };
    }

}
