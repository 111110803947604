import {IStreamModelMetadata, PAYMENT_SYSTEM} from '@src/app/models/stream.model';
import {IVideoInfo} from "@src/app/services/video-upload/video-upload.service";

export enum VIDEO_VISIBLE_SETTINGS {
    NOWHERE = 'nowhere',
    STREAMDUST_ONLY = 'streamdust_only',
    EXCEPT_STREAMDUST = 'except_streamdust',
    EVERYWHERE = 'everywhere'
}

export interface IVideoOnDemandModel {
    id?: string;
    accountId?: {
        internalId: string;
    };
    video?: IVideoInfo;
    videoId?: string;
    videoUrl?: string;
    title: string;
    description: string;
    paymentSystem: PAYMENT_SYSTEM;
    priceAmount: number;
    priceCurrency: string;
    price: {
        amount: number;
        currency: string
    };
    groupsSettings: {
        enabled: true;
        groups: string[];
    };
    donationSettings: {
        enabled: true;
        donations: [
            {
                name: string;
                sum: number;
            }
        ]
    };
    freeSumSettings: {
        enabled: true;
        freeSumNames: string[]
    };
    advancedSettings: {
        chatEnabled: true;
        ageRestriction: true;
        restrictedRegions: string;
    };
    created: Date;
    updated: Date;
    media: IStreamModelMetadata;
    visible?: VIDEO_VISIBLE_SETTINGS;
}
