<section class="section section-dashboard">
    <div class="container">
        <div class="wrapper-content bg-light">
            <h1><b>Dashboard</b></h1>
            <div class="row row-padding-10">
                <div class="col-md-auto col-lg-auto col-sm-12">
                    <app-side-nav [config]="navConfig"></app-side-nav>
                </div>
                <div class="col">
                    <app-stream-metadata [isCreateMode]="true"></app-stream-metadata>
                </div>
            </div>
        </div>
    </div>
</section>
