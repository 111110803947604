import {SUBSCRIPTION_PLAN_TYPES} from './../../services/subscription-manage/subscription-manage.service';
import {forkJoin} from 'rxjs';
import {
    CalculateTariffsService,
    ICalculatorVats,
    ICalculatorFees,
    IFee
} from './../../services/calculate-tariffs/calculate-tariffs.service';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import {takeUntil, tap, map} from 'rxjs/operators';
import {IUnitKeyValue} from 'ui-elements';
import {Component, OnInit, ViewChild, ElementRef} from '@angular/core';

@Component({
    selector: 'app-calculate-ticket',
    templateUrl: './calculate-ticket.component.html',
    styleUrls: ['./calculate-ticket.component.scss']
})
export class CalculateTicketComponent implements OnInit {
    @ViewChild('input', {static: false}) private input: ElementRef<HTMLInputElement>;

    public isLoaded: boolean = false;
    public vatValue: number;
    public feeValue: number;
    public sdProvision: number;
    public total: number;
    public vatZones: ICalcData[];
    public selectedZoneCode: ICalcData;
    public percentVat: number;
    public percentStreamdustFee: number;
    public percentTransactionFee: number;
    public currency: ICalcData[];
    public selectedCurrency: ICalcData;
    public coefficientStreamdustFee: number;
    public coefficientTransactionFee: number;
    public selectedPlan: ICalcData;
    public plans: ICalcData[];

    constructor(
        private calculateTariffsService: CalculateTariffsService,
        private destroy$: AutoDestroyService
    ) {
    }

    ngOnInit() {
        this.initCalculator();
    }

    public onPriceChange(): void {
        const ticketPrice = parseFloat(this.input.nativeElement.value.replace(/,/g, ''));
        this.vatValue = (+ticketPrice * +this.selectedZoneCode.actualValue / 100) || 0;
        this.feeValue = ((+ticketPrice * this.percentTransactionFee / 100) + this.coefficientTransactionFee) || 0;
        this.sdProvision = ((+ticketPrice * this.percentStreamdustFee / 100) + this.coefficientStreamdustFee) || 0;
        this.total = (+ticketPrice - this.vatValue - this.feeValue - this.sdProvision) || 0;
    }

    private initCalculator(): void {
        this.vatValue = 0;
        this.feeValue = 0;
        this.sdProvision = 0;
        this.total = 0;
        forkJoin({
            zones: this.calculateTariffsService.getVatZones(),
            fees: this.calculateTariffsService.getFees()
        }).pipe(
            takeUntil(this.destroy$),
            map(({zones, fees}) => {
                return {
                    zones: zones.map((_res) => ({
                        key: _res.country,
                        value: _res.country,
                        actualValue: _res.value
                    })),
                    currency: fees[0].streamdustFees.map((_res) => ({
                        key: _res.currency,
                        value: _res.currency
                    })),
                    plans: fees.map((_res) => ({
                        key: _res.subscriptionPlan,
                        value: _res.subscriptionPlan,
                        streamdustFees: _res.streamdustFees,
                        transactionFees: _res.transactionFees
                    }))
                };
            })).subscribe(({zones, currency, plans}) => {
            this.vatZones = zones;
            this.selectedZoneCode = this.vatZones[0];
            this.percentVat = +this.vatZones[0].actualValue;

            this.plans = plans;
            this.selectedPlan = this.plans[0];
            this.isLoaded = true;
            this.currency = currency;
            this.selectedCurrency = this.currency[0];

            this.coefficientStreamdustFee = this.plans[0].streamdustFees[0].initialValue;
            this.percentStreamdustFee = this.plans[0].streamdustFees[0].percent;

            this.coefficientTransactionFee = this.plans[0].transactionFees[0].initialValue;
            this.percentTransactionFee = this.plans[0].transactionFees[0].percent;
        });
    }

    onSelectedCurrency(currency: string) {
        const selectedStreamdustFees = this.selectedPlan.streamdustFees.find(s => s.currency === currency);
        const selectedTransactionFees = this.selectedPlan.transactionFees.find(s => s.currency === currency);

        this.coefficientStreamdustFee = selectedStreamdustFees.initialValue;
        this.percentStreamdustFee = selectedStreamdustFees.percent;
        this.coefficientTransactionFee = selectedTransactionFees.initialValue;
        this.percentTransactionFee = selectedTransactionFees.percent;
        this.selectedCurrency = this.currency.find(c => c.value === currency);
        this.onPriceChange();
    }

    onSelectedZone(zone: string) {
        this.selectedZoneCode = this.vatZones.find(c => c.key === zone);
        this.percentVat = +this.selectedZoneCode.actualValue;
        this.onPriceChange();
    }

    onSelectedPlan(plan: SUBSCRIPTION_PLAN_TYPES) {
        this.selectedPlan = this.plans.find(p => p.value === plan);
        this.onSelectedCurrency(this.selectedCurrency.value.toString());
    }
}

export interface ICalcData extends IUnitKeyValue {
    percent?: number;
    streamdustFees?: IFee[];
    transactionFees?: IFee[];
    actualValue?: number;
}
