import {Pipe, PipeTransform} from '@angular/core';
import {revertString} from "@src/app/utils/string.util";

@Pipe({
    name: 'numberWithSeparator'
})
export class NumberWithSeparatorPipe implements PipeTransform {

    transform(number: number): unknown {
        const stringNumber = number.toString();
        if (stringNumber.length > 3) {
            const numberWithSeparator = revertString(revertString(number.toString()).replace(/(.{3})/g, '$1.'));
            if (numberWithSeparator[0] === '.') {
              return numberWithSeparator.slice(1);
            }
            return numberWithSeparator;
        }
        return stringNumber;
    }

}
