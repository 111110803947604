import {Injectable, OnDestroy} from '@angular/core';
import {ApiService} from 'ui-elements';
import {Observable, Subject} from 'rxjs';
import {map, pluck} from 'rxjs/operators';
import {MEDIA_TYPE} from '@src/app/components/streamdust-player/constants/mediaType';
import {API_URLS_APP, UrlGenerator} from '@src/app/constants/api-urls.constant';
import {IResponse} from '@src/app/models/response.model';
import {WebSocketConnector} from '@src/app/services/web-socket-connector/web-socket-connector.service';
import {environment} from '@src/environments/environment';
import {IPrice} from '@src/app/models/stream.model';
import {IPaymentReturnResponse} from '@src/app/services/payment/payment.service';
import {PAYMENT_CARD_TYPE} from '@src/app/components/payment-cards-list/payment-cards-list.component';
import {ICommonPaymentCard, IHeidelpayCard} from '@src/app/components/payment-cards-list/heidelpay-payment/heidelpay-payment.component';
import {StreamService} from '@src/app/services/stream-metadata/stream.service';
import {DONATION_MIN_VALUE} from '@src/app/models/core.model';
import {CURRENCY_SYMBOLS} from '@src/app/components/streamdust-player/media-access-modal/media-access-modal.component';
import {GenerateUtil} from '@src/app/utils/generate.util';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';

const DONATION_SOCKET = `${environment.webSocketHost}${API_URLS_APP.ROOT_URL}${API_URLS_APP.DONATION_SOCKET}`;

@Injectable({
    providedIn: 'root'
})
export class DonationsService implements OnDestroy {
    public connection: Subject<any>;
    public instanceId = GenerateUtil.uuidv4();
    public donations$: BehaviorSubject<IPaidDonation[]> = new BehaviorSubject<IPaidDonation[]>([]);
    private donations: IPaidDonation[] = [];
    private donationsCached = false;
    private donationOptions$: BehaviorSubject<IDonation[]> = new BehaviorSubject<IDonation[]>(null);
    private currentMediaId: string;

    constructor(
        private apiService: ApiService,
        private streamService: StreamService
    ) {
    }

    public getDonationOptions(mediaType: MEDIA_TYPE, mediaId: string, currency: string): Observable<IDonation[]> {
        if (!this.donationsCached && (this.currentMediaId !== mediaId)) {
            this.currentMediaId = mediaId;
            this.donationsCached = true;
            this.apiService.get(UrlGenerator.generate(API_URLS_APP.DONATION_OPTIONS, {
                mediaType,
                mediaId
            }), {isWithoutRootUrl: true})
                .pipe(pluck('results', 'data'), map((resp: any) => {
                    let res: IDonation[] = resp.donations.map(donation => ({
                        ...donation,
                        currency: CURRENCY_SYMBOLS[currency]
                    }));
                    res = res.concat(resp.freeSums.map(donation => ({
                        name: donation.name,
                        sum: null,
                        currency: CURRENCY_SYMBOLS[currency],
                        min: DONATION_MIN_VALUE
                    })));
                    this.donationOptions$.next(res);
                })).subscribe();
        }
        return this.donationOptions$.asObservable();
    }

    public donate(body: IDonationRequest): Observable<IResponse<{ result: IPaymentReturnResponse }>> {
        return this.apiService.post(API_URLS_APP.DONATE, body);
    }

    public checkDonation(transactionId: string): Observable<IResponse<{ result: IPaymentReturnResponse }>> {
        const body = {
            accessToken: '',
            transactionId
        };

        if (this.streamService.viewerAccessToken) {
            body.accessToken = this.streamService.viewerAccessToken;
        }
        return this.apiService.post(UrlGenerator.generate(API_URLS_APP.PAYMENT_RETURN, {}, {testFail: false}), body);
    }

    public connectToSocket(mediaId: string): Subject<IResponse<{ data: IPaidDonation[] }>> {
        if (!this.connection) {
            this.connection = new WebSocketConnector().connect(DONATION_SOCKET, null, [mediaId], true);
        }
        return <Subject<IResponse<{ data: IPaidDonation[] }>>>this.connection.pipe(map(
            (response: MessageEvent): IResponse<{ data: IPaidDonation[] }> => {
                const data = JSON.parse(response.data);
                let newDonations = [];
                if (!this.donations) {
                    newDonations = data.results.data;
                } else {
                    if (Array.isArray(data.results.data)) {
                        data.results.data.forEach(item => {
                            if (this.donations.find(_item => item.date === item.date)) {
                                return;
                            }
                            newDonations.push(item);
                        });
                    } else {
                        newDonations.push(data.results.data);
                    }
                }
                this.donations = this.donations.concat(newDonations);
                this.donations$.next(this.donations);
                return data;
            }
        ));
    }

    ngOnDestroy(): void {
        if (!this.connection) {
            return;
        }
        this.connection.complete();
    }
}


export interface IDonation {
    id?: string;
    sum: number;
    currency: string;
    name: string;
    min?: string;
}

export interface IPaidDonation {
    name: string;
    date: number;
    userName: string;
    price: IPrice;
}

export interface IDonationRequest {
    donationName: string;
    productId: string;
    price: {
        amount: number;
        currency: string;
    };
    userName: string;
    email?: string;
    returnUrl: string;
    userCardId?: string;
    paymentMethod?: {
        resourceId: string;
        method: string;
        userCardId?: string;
        saveCard?: boolean;
        paymentCard?: ICommonPaymentCard;
    };
    paymentCard?: ICommonPaymentCard;
    saveCard?: boolean;
    firstName?: string;
    lastName?: string;
}
