import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RevenuesPageComponent } from './revenues-page.component';
import {SharedModule} from "@src/app/modules/shared/shared.module";
import { RevenueDetailsComponent } from './components/revenue-details/revenue-details.component';



@NgModule({
  declarations: [RevenuesPageComponent, RevenueDetailsComponent],
    imports: [
        CommonModule,
        SharedModule
    ]
})
export class RevenuesPageModule { }
