<ng-container *ngIf="mode === FORM_MODE.VIEW">
    <div class="description-container">
        <p class="description-line" *ngFor="let descriptionLine of media?.media?.description.split('\n')">{{descriptionLine}}</p>
    </div>
</ng-container>
<ng-container *ngIf="mode === FORM_MODE.EDIT">
    <form class="border-form" [formGroup]="form" id="description-form">
        <div class="form-group">
            <ano-input formControlName="description"
                       [isEditMode]="true"
                       [errors]="form.get('description').errors"
                       [isTouched]="form.get('description').touched"
                       [isDirty]="form.get('description').dirty"
                       [invalid]="form.get('description').invalid"
                       [config]="{
                                                                inputType: INPUT_TYPES.TEXTAREA,
                                                                placeholder: ''
                                                            }"
            ></ano-input>
        </div>
    </form>
</ng-container>
<button class="btn btn-primary mt-20" *ngIf="mode === FORM_MODE.VIEW" (click)="goToEditMode()">{{ 'media-access-modal.button.edit' | localize }}</button>
<button class="btn btn-secondary mt-20 mr-20" *ngIf="mode === FORM_MODE.EDIT" (click)="cancel()">{{ 'media-access-modal.button.cancel' | localize }}</button>
<button class="btn btn-success mt-20" *ngIf="mode === FORM_MODE.EDIT" (click)="save()">{{ 'media-access-modal.button.save' | localize }}</button>
