import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {IPaymentSlot, IPublicStreamInfo, PAYMENT_SYSTEM} from '@src/app/models/stream.model';
import {AutoDestroyService, IPrice, MEDIA_TYPE} from 'ui-elements';
import {DragScrollComponent} from 'ngx-drag-scroll';
import {environment} from '@src/environments/environment';
import {STREAM_STATUS} from '../../../constants/status';
import {StreamService} from '@src/app/services/stream-metadata/stream.service';
import {saveAs} from 'file-saver/FileSaver';
import {take} from 'rxjs/operators';
import {SlotsModalState} from '@src/app/state/slots-modal-state';
import {takeUntil} from 'rxjs/internal/operators';
import {Subject} from 'rxjs/internal/Subject';
import {GenerateUtil} from '@src/app/utils/generate.util';

@Component({
  selector: 'app-slots-info',
  templateUrl: './slots-info.component.html',
  styleUrls: ['./slots-info.component.scss'],
  providers: [AutoDestroyService]
})
export class SlotsInfoComponent implements OnInit, OnDestroy {
  PAYMENT_SYSTEM = PAYMENT_SYSTEM;
  STREAM_STATUS = STREAM_STATUS;
  @Input() isPayedAll: boolean;
  @Input() data: IPaymentSlot[];
  @Input() media: IPublicStreamInfo;
  public totalPrice: IPrice = {amount: 0, currency: 'EUR'};
  public isVideo: boolean;
  @Output() proceedPayment$: EventEmitter<string> = new EventEmitter<string>();
  @Output() playSlot$: EventEmitter<string> = new EventEmitter<string>();
  instanceId = GenerateUtil.uuidv4();

  @ViewChild('sliderComp', {read: DragScrollComponent}) set sliderComp(component: DragScrollComponent) {
    if (component) {
      this.slider = component;
      this.slider.reachesLeftBound.pipe(takeUntil(this.destroy$)).subscribe(res => this.sliderArrow.leftArrow = !res);
      this.slider.reachesRightBound.pipe(takeUntil(this.destroy$)).subscribe(res => this.sliderArrow.rightArrow = !res);
      this.modalState.isOpened$.pipe(takeUntil(this.destroy$))
          .subscribe((opened) => {
            if (!opened) {
              return;
            }
            this.modalState.currentSlot$.pipe(takeUntil(this.currentSlotNotifier$))
                .subscribe((slotId) => {
                  if (!slotId) {
                    return;
                  }

                  this.slider.moveTo(this.data.findIndex(item => item.id === slotId));
                  this.currentSlotNotifier$.next();
                });
          });
    }
  }

  slider: DragScrollComponent;
  numberOfSlides: number;
  sliderArrow = {leftArrow: false, rightArrow: true};
  firstSlotWithoutAccess: number = 0;
  currentSlotNotifier$: Subject<void> = new Subject<void>();
  opened = false;


  constructor(
      private streamService: StreamService,
      private modalState: SlotsModalState,
      private destroy$: AutoDestroyService
  ) { }

  ngOnInit(): void {
    if (this.media.videoType === MEDIA_TYPE.VIDEO_ON_DEMAND || this.media.hasRecord) {
      this.isVideo = true;
    }
    this.data.forEach((item, index) => {
      if (item.slotPayment === PAYMENT_SYSTEM.FREE) {
        item.hasAccess = true;
      }
      if (item?.price) {
        this.totalPrice.amount += item.price.amount;
        this.totalPrice.currency = item.price.currency;
      }

      if (!item.hasAccess && !this.firstSlotWithoutAccess) {
        this.firstSlotWithoutAccess = index;
      }
    });

    this.numberOfSlides = this.data?.length;
  }

  getCurrency(media): IPrice {
    const price = media?.price;
    return price.amount ? {amount: price.amount, currency: price.currency || 'EUR'} : null;
  }

  proceedPayment(id: string): void {
    this.proceedPayment$.emit(id);
  }

  moveLeft(): void {
    this.slider.moveLeft();
    this.modalState.resetCurrentSlot();
  }

  moveRight(): void {
    this.slider.moveRight();
    this.modalState.resetCurrentSlot();
  }

  getBackground(media): string {
    return media?.previewPicture ? 'url(' + environment.backendApiHost + media?.previewPicture?.photoUrl + ')' : '';
  }

  public playSlot(id: string): void {
    this.playSlot$.emit(id);
    this.modalState.resetCurrentSlot();
  }

  public downloadIcs(slot: IPaymentSlot): void {
    this.streamService.getSlotSchedule(this.media.id, slot.id)
        .pipe(take(1))
        .subscribe(res => {
          saveAs(
              res.body,
              slot.title.replace(/\s+/g, '_').trim() + '.ics'
          );
        });
  }

  ngOnDestroy() {
    this.currentSlotNotifier$.next();
    this.currentSlotNotifier$.complete();
  }

}
