import { Injectable } from '@angular/core';
import {ApiService} from "ui-elements";
import {Observable} from "rxjs/internal/Observable";
import {IResponse} from "@src/app/models/response.model";
import {API_URLS_APP, UrlGenerator} from "@src/app/constants/api-urls.constant";

@Injectable({
  providedIn: 'root'
})
export class AdvertisementService {
  public readonly advertisementShownProperty = 'advertisementShown';

  constructor(private apiService: ApiService) { }

  public advertisementShown(videoId: string): void {
    localStorage.setItem(this.advertisementShownProperty + '_' + videoId, new Date().getTime().toString());
  }

  public isAdvertisementShown(videoId: string): boolean {
    const shown = localStorage.getItem(this.advertisementShownProperty + '_' + videoId);
    if (!shown) {
      return false;
    }

    // tslint:disable-next-line:radix
    const expiryDate = Number.parseInt(shown) + (10 * 60 * 1000);
    if (new Date().getTime() > expiryDate) {
      localStorage.removeItem(this.advertisementShownProperty + '_' + videoId);
      return false;
    }

    return true;
  }

  public teaserClick(videoType: string, productId: string): Observable<IResponse> {
    return this.apiService.put(UrlGenerator.generate(API_URLS_APP.TEASER_CLICK, {videoType, productId}), {},
        {
          openApi: true,
          isWithoutRootUrl: true
        });
  }

  public teaserView(videoType: string, productId: string): Observable<IResponse> {
    return this.apiService.put(UrlGenerator.generate(API_URLS_APP.TEASER_VIEW, {videoType, productId}), {},
        {
          openApi: true,
          isWithoutRootUrl: true
        });
  }

  public trailerClick(videoType: string, productId: string): Observable<IResponse> {
    return this.apiService.put(UrlGenerator.generate(API_URLS_APP.TRAILER_CLICK, {videoType, productId}), {},
        {
          openApi: true,
          isWithoutRootUrl: true
        });
  }

  public trailerView(videoType: string, productId: string): Observable<IResponse> {
    return this.apiService.put(UrlGenerator.generate(API_URLS_APP.TRAILER_VIEW, {videoType, productId}), {},
        {
          openApi: true,
          isWithoutRootUrl: true
        });
  }
}
