import {IBillsTableConfig} from '@src/app/pages/bills-page/components/bills-table/bills-table.component';
import {BILL_TYPE, BillsService, IBill} from '@src/app/services/bills/bills.service';
import {Injectable} from '@angular/core';
import {UserService} from '@src/app/services/user/user.service';
import {map} from 'rxjs/operators';
import {USER_TYPE} from '@src/app/constants/user-type.constant';
import {Observable} from 'rxjs/internal/Observable';
import {ITableColumn, TEMPLATE_ID} from 'ui-elements';
import {IListFilterItem} from '@src/app/components/media-list/media-list.component';

@Injectable()
export class BillsConfig {
    private baseColumns: ITableColumn[] = [
        {
            name: 'bills.field.number',
            dataField: 'billNumber',
            width: 100,
            class: 'right'
        },
        {
            name: 'bills.field.date',
            sortField: 'DATE',
            tmplId: TEMPLATE_ID.DATE,
            tmpl: null,
            width: 100
        },
        {
            name: 'bills.field.amount',
            tmplId: TEMPLATE_ID.PRICE,
            tmpl: null,
            width: 100,
            class: 'right'
        },
        {
            name: 'bills.field.status',
            tmplId: TEMPLATE_ID.STATUS,
            tmpl: null,
            width: 100
        },
        {
            name: 'bills.field.actions',
            tmplId: TEMPLATE_ID.ACTIONS,
            class: 'center',
            tmpl: null,
            width: 100
        }
    ];
    public publisherConfigs: IBillsTableConfig<IBill>[] = [
        {
            groupTitle: 'bills.title.invoicesSubscription',
            title: 'bills.title.invoicesOverview',
            id: BILL_TYPE.SUBSCRIPTION,
            tableConfig: {
                defaultFilter: [SUBSCRIPTION_BILL],
                dataField: 'data',
                searchWithFilters: true,
                matPaginator: true,
                searchWithFilters$: (searchTerm, filters, sortParams, pagingParams) => {
                    return this.billsService.getBills(searchTerm, filters, pagingParams, sortParams);
                },
                columns: [
                    {
                        name: 'bills.field.abo',
                        tmplId: TEMPLATE_ID.SUBSCRIPTION_TYPE,
                        width: 100
                    },
                    ...this.baseColumns
                ]

            }
        },
        {
            groupTitle: 'bills.title.extraCharges',
            title: 'bills.title.bandwidthMonthly',
            id: BILL_TYPE.BANDWIDTH,
            tableConfig: {
                defaultFilter: [BANDWIDTH_BILL],
                dataField: 'data',
                searchWithFilters: true,
                matPaginator: true,
                searchWithFilters$: (searchTerm, filters, sortParams, pagingParams) => {
                    return this.billsService.getBills(searchTerm, filters, pagingParams, sortParams);
                },
                columns: [
                    {
                        name: 'bills.field.bandwidth',
                        // dataField: 'dataSize',
                        tmplId: TEMPLATE_ID.VALUE_UNIT,
                        tmpl: null,
                        width: 100
                    },
                    {
                        name: 'bills.field.validity',
                        tmplId: TEMPLATE_ID.DATE,
                        tmpl: null
                    },
                    ...this.baseColumns
                ]

            }
        },
        {
            id: BILL_TYPE.STORAGE_SPACE,
            title: 'bills.title.storageMonthly',
            tableConfig: {
                defaultFilter: [STORAGE_SPACE_BILL],
                searchWithFilters: true,
                dataField: 'data',
                matPaginator: true,
                searchWithFilters$: (searchTerm, filters, sortParams, pagingParams) => {
                    return this.billsService.getBills(searchTerm, filters, pagingParams, sortParams);
                },
                columns: [
                    {
                        name: 'bills.field.storage',
                        tmplId: TEMPLATE_ID.VALUE_UNIT,
                        tmpl: null,
                        width: 100
                    },
                    {
                        name: 'bills.field.validity',
                        tmplId: TEMPLATE_ID.DATE,
                        tmpl: null,
                        width: 100
                    },
                    ...this.baseColumns
                ]
            }
        },
        {
            id: BILL_TYPE.MULTIBITRATE,
            title: 'biils.title.multibitrate',
            tableConfig: {
                defaultFilter: [MULTIBITRATE_BILL],
                searchWithFilters: true,
                dataField: 'data',
                matPaginator: true,
                searchWithFilters$: (searchTerm, filters, sortParams, pagingParams) => {
                    return this.billsService.getBills(searchTerm, filters, pagingParams, sortParams);
                },
                columns: [
                    {
                        name: 'Media Id',
                        tmplId: TEMPLATE_ID.MEDIA_ID,
                        class: 'left'
                    },
                    ...this.baseColumns
                ]

            }
        },

    ];
    public viewerConfigs: IBillsTableConfig<IBill>[] = [
        {
            groupTitle: 'bills.title.bills',
            id: BILL_TYPE.MEDIA,
            withSearch: true,
            withFilters: true,
            withTimeRange: true,
            tableConfig: {
                defaultFilter: [MEDIA_BILL],
                searchWithFilters: true,
                filtersWidth100: true,
                matPaginator: true,
                searchWithFilters$: (searchTerm, filters, sortParams, pagingParams) => {
                    return this.billsService.getBills(searchTerm, filters, pagingParams, sortParams);
                },
                dataField: 'data',
                columns: [
                    {
                        name: 'bills.field.title',
                        dataField: 'title',
                        width: 100
                    },
                    {
                        name: 'bills.field.type',
                        dataField: 'mediaType',
                        width: 100
                    },
                    ...this.baseColumns
                ]

            }
        }
    ];

    constructor(
        private billsService: BillsService,
        private userService: UserService
    ) {
    }

    public getConfig(viewer: boolean): Observable<IBillsTableConfig<IBill>[]> {
        return this.userService.userType$.pipe(map(userType => {
            if (userType === USER_TYPE.PUBLISHER && !viewer) {
                return this.publisherConfigs;
            }
            return this.viewerConfigs;
        }));
    }
}

const MEDIA_BILL = {
    field: 'TYPE',
    value: BILL_TYPE.MEDIA
} as IListFilterItem;

const SUBSCRIPTION_BILL = {
    field: 'TYPE',
    value: BILL_TYPE.SUBSCRIPTION
} as IListFilterItem;

const BANDWIDTH_BILL = {
    field: 'TYPE',
    value: BILL_TYPE.BANDWIDTH
} as IListFilterItem;

const STORAGE_SPACE_BILL = {
    field: 'TYPE',
    value: BILL_TYPE.STORAGE_SPACE
} as IListFilterItem;

const MULTIBITRATE_BILL = {
    field: 'TYPE',
    value: BILL_TYPE.MULTIBITRATE
} as IListFilterItem;
