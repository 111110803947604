import {IStreamStatus, IVideoStatus, PAYMENT_SYSTEM} from "@src/app/models/stream.model";
import {IUserPublisher} from "@src/app/pages/user-profile/components/profile/services/profile.service";
import {MEDIA_TYPE} from "@src/app/components/streamdust-player/constants/mediaType";
import {IMediaGroup} from "@src/app/models/stream-groups.model";
import {VIDEO_VISIBLE_SETTINGS} from '@src/app/models/video-on-demand.model';

export interface INameId {
    id: string;
    name: string;
    selected?: boolean;
}


export interface IMediaItem {
    id?: string;
    type?: MEDIA_TYPE;
    videoType?: MEDIA_TYPE;
    title: string;
    description: string;
    paymentSystem: PAYMENT_SYSTEM;
    price: {
        amount: number;
        currency: string
    };
    created?: any;
    updated?: any;
    startTimestamp?: any;
    startedAt?: any;
    endTimestamp?: any;
    genre?: string;
    publisherInfo?: IUserPublisher;
    defaultPreviewPicture?: IPicture;
    previewPicture?: {
      photoId: string;
      photoUrl: string;
    };
    groups?: IMediaGroup[];
    hasRecord?: boolean;
    status?: IVideoStatus;
    supportRecording?: string;
    showStreamDate?: boolean;
    visibility?: VIDEO_VISIBLE_SETTINGS;
}

export enum VIDEO_TYPE {
    VOD = 'vod',
    STREAM = 'streams',
    RECORD = 'records'
}

export interface IActionLink {
    link: string;
    text: string;
}

export interface IActionCallback {
    action: () => void;
    text: string;
}
export interface IPicture {
    photoId: string;
    photoUrl: string;
    waitingForApproval?: boolean;
}

export interface IMediaSliderConfig {
    itemsPerList?: number;
    carouselId?: string;
    scrollStep?: number;
    itemsToScrollAtOnce?: number;
    groups?: IMediaGroup[];
    selector?: {title: string; items: string[]};
    selectorTitle?: string;
    moreButton?: {
        show: boolean;
        text: string;
        navigationClick: () => void;
    };
}

export interface IPublicMediaInfo {
    productId: string;
    productType: MEDIA_TYPE;
}

export const DEFAULT_CHAT_POSITION: {x: number; y: number} = {x: -25, y: -100};
export const DEFAULT_DONATIONS_POSITION: {x: number; y: number} = {x: -390, y: -100};
export const DEFAULT_WORLD_MAP_POSITION: {x: number; y: number} = {x: isPortrait() ? -100 : -500, y: -100};

export function isPortrait(): boolean {
    return window.innerHeight > window.innerWidth;
}

export enum COUPON_TYPE {
    SINGLE = 'Single code',
    MULTI = 'Multi-use code'
}

export const DONATION_MIN_VALUE = 2;

export const MAX_STREAM_DURATION = 57600000;

export interface IRegistration {
    firstName: string;
    lastName: string;
    address: string;
    city: string;
    postalCode: number;
    country: string;
    privatePhone: string;
    company: string;
    vatId: string;
    confirmationCode: string;
    publicPhone?: string;
}
