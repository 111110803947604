import {Component, Input, OnInit} from '@angular/core';
import {
    ISubscriptionTariffPlan,
    TARIFF_PLAN_PERIOD
} from "@src/app/components/tariff-plan-cards-list/subscription-plan-card/subscription-plan-card.component";
import {DateHelper} from "@src/app/utils/date.helper";
import moment from "moment";

@Component({
    selector: 'app-checkout-details',
    templateUrl: './checkout-details.component.html',
    styleUrls: ['./checkout-details.component.scss']
})
export class CheckoutDetailsComponent implements OnInit {

    @Input() set tariffPlan(tariffPlan: ISubscriptionTariffPlan) {
        if (!tariffPlan) {
            return;
        }

        this._tariffPlan = tariffPlan;
    }

    @Input() set planPeriod(planPeriod: TARIFF_PLAN_PERIOD) {
        if (planPeriod) {
            this.setExpiryDate(planPeriod);
        }
    }

    @Input() title: string;
    @Input() details: string;

    _tariffPlan: ISubscriptionTariffPlan;
    expiryDate: string;

    constructor() {
    }

    ngOnInit(): void {
    }

    setExpiryDate(planPeriod: TARIFF_PLAN_PERIOD): void {
        const expiryDateMoment = this.getExpiryDateMoment(planPeriod);
        this.expiryDate = DateHelper.formatDateTime(expiryDateMoment);
    }


    getExpiryDateMoment(planPeriod: TARIFF_PLAN_PERIOD) {
        switch (planPeriod) {
            case TARIFF_PLAN_PERIOD.WEEK: {
                return moment(moment()).add(1, 'w');
            }
            case TARIFF_PLAN_PERIOD.MONTH: {
                return moment(moment()).add(1, 'M');
            }
            case TARIFF_PLAN_PERIOD.YEAR: {
                return moment(moment()).add(1, 'y');
            }
        }
    }

}
