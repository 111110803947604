import { Router } from '@angular/router';
import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ITariffPlan} from "@src/app/services/storage-and-bandwidth/storage-and-bandwidth.service";
import {
    ISubscriptionTariffPlan, ISubscriptionTariffPlans,
    TARIFF_PLAN_PERIOD
} from '@src/app/components/tariff-plan-cards-list/subscription-plan-card/subscription-plan-card.component';
import {AutoDestroyService, INPUT_TYPES, IUnitKeyValue} from 'ui-elements';
import {FormBuilder} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';
import {currentCurrency} from '@src/app/providers/currentCurrency';
import {BehaviorSubject} from 'rxjs';

@Component({
    selector: 'app-plan-cards-list',
    templateUrl: './tariff-plan-cards-list.component.html',
    styleUrls: ['./tariff-plan-cards-list.component.sass'],
    providers: [AutoDestroyService]
})
export class TariffPlanCardsListComponent {
    userCurrency: BehaviorSubject<string> = currentCurrency;
    TARIFF_PLAN_PERIOD = TARIFF_PLAN_PERIOD;
    INPUT_TYPES = INPUT_TYPES;

    @Input() config: ITariffPlanConfig;
    @Input() tariffPlans: ITariffPlan[] | ISubscriptionTariffPlans;
    @Input() isAuthorized: boolean;

    PLAN_LIST_TYPES = TARIFF_PLAN_TYPES;

    @Output() actionButtonPressed$ = new EventEmitter<ITariffPlan | ISubscriptionTariffPlan>();

    public currentCurrency = 'EUR';
    currencyForm = this.formBuilder.group({
        tariffCurrency: ['EUR']
    });

    currencySelectOptions: IUnitKeyValue[] = [
        {
            key: 'EUR',
            value: 'EUR'
        },
        {
            key: 'USD',
            value: 'USD'
        }
    ];

    constructor(public router: Router,
                private formBuilder: FormBuilder,
                private destroy$: AutoDestroyService) {
        this.currencyForm.get('tariffCurrency').valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((currency) => this.currentCurrency = currency);
    }

    changeTariffPeriod(tariffPlanPeriod: TARIFF_PLAN_PERIOD): void {
        this.config.planPeriod = tariffPlanPeriod;
    }

    activatePlan(tariffPlan: ISubscriptionTariffPlan | ITariffPlan): void {
        if(!this.isAuthorized) {
            this.router.navigate(['/registration'], {queryParams: {step: 'publisher'}});
            return;
        }

        if (tariffPlan.prices[0].prices[0].price.amount) {
            this.actionButtonPressed$.emit(tariffPlan);
        } else {
            window.open('https://beta.streamdust.tv/info/kontakt/', '_blank');
        }
    }
}

export interface ITariffPlanConfig {
    iconName?: string;
    planType?: TARIFF_PLAN_TYPES;
    planPeriod?: TARIFF_PLAN_PERIOD;
    hideActionButton?: boolean;
    showPeriodToggle?: boolean;
}

export enum TARIFF_PLAN_TYPES {
    SUBSCRIPTION = 'SUBSCRIPTION',
    STORAGE_SPACE = 'STORAGE_SPACE',
    BANDWIDTH = 'BANDWIDTH'
}
