import {Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {IFormConfig, StreamService} from '@src/app/services/stream-metadata/stream.service';
import {ITableConfig, ITableNoDataLink, LocalizationProvider, TableComponent} from 'ui-elements';
import {IOverlay} from '@src/app/models/response.model';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import {ActivatedRoute, Router} from '@angular/router';
import {
  IStreamMarketingToolsOverlaysConfig,
  StreamMarketingToolsOverlaysTypes
} from '@src/app/pages/stream-page/components/stream-marketing-tools-overlays/stream-marketing-tools-overlays.component';
import {mergeMap, takeUntil} from 'rxjs/operators';
import {BehaviorSubject} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {STREAM_STATUS} from '@src/app/components/streamdust-player/constants/status';
import {environment} from '@src/environments/environment';
import {MEDIA_TYPE} from '@src/app/components/streamdust-player/constants/mediaType';
import {of} from 'rxjs/internal/observable/of';

@Component({
  selector: 'app-overlays-table',
  templateUrl: './overlays-table.component.html',
  styleUrls: ['./overlays-table.component.scss'],
  providers: [AutoDestroyService]
})
export class OverlaysTableComponent implements OnInit {
  public STREAM_STATUS = STREAM_STATUS;
  public MEDIA_TYPE = MEDIA_TYPE;
  public environment = environment;
  @Input() config: IStreamMarketingToolsOverlaysConfig;
  @Input() mediaType: MEDIA_TYPE;
  @Input() mediaId: string;
  @Input() standAlone: boolean;
  @Input() itemsOnPage = 10;
  @Input() noDataLink: ITableNoDataLink;
  public tableConfig: ITableConfig<IOverlay>;
  @ViewChild('actionsTmpl', {static: true}) public actionsTmpl: TemplateRef<any>;
  @ViewChild('statusTmpl', {static: true}) public statusTmpl: TemplateRef<any>;
  @ViewChild('imageTmpl', {static: true}) public imageTmpl: TemplateRef<any>;
  @ViewChild('nameTmpl', {static: true}) public nameTmpl: TemplateRef<any>;
  @ViewChild('posTmpl', {static: true}) public posTmpl: TemplateRef<any>;
  @ViewChild('startTimeTmpl', {static: true}) public startTimeTmpl: TemplateRef<any>;
  @ViewChild('durationTmpl', {static: true}) public durationTmpl: TemplateRef<any>;
  @ViewChild('tableComponent', {static: false}) public table: TableComponent;
  @ViewChild('confirm', {static: false}) confirm: TemplateRef<any>;
  private searchQuery: string;
  public configLoaded = false;
  public overlaysType: StreamMarketingToolsOverlaysTypes;
  public formConfig: IFormConfig;
  private confirmTextTpl = this.localizationProvider.getByKey('overlays.deleteOverlayText');
  public confirmText = '';
  public position = {
    LEFT: 'overlay.position.left',
    RIGHT: 'overlay.position.right',
    BOTTOM: 'overlay.position.bottom'
  };

  constructor(
      public streamService: StreamService,
      private readonly destroy$: AutoDestroyService,
      private router: Router,
      private activatedRoute: ActivatedRoute,
      private localizationProvider: LocalizationProvider,
      public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.initConfig();
  }

  public initConfig(): void {
    // TODO: fix navigation on row click
    this.formConfig = this.streamService.getStreamFormConfig(false, false, MEDIA_TYPE.STREAM, true);
    this.overlaysType = (this.activatedRoute.data as BehaviorSubject<any>)?.value?.overlaysType;
    this.tableConfig = {
      dataField: this.config.dataField,
      // navigationUrl: this.resolveNavigationUrl(),
      itemsOnPage: this.itemsOnPage,
      noDataLink: this.noDataLink,
      searchFn: (sortParams, pagingParams) => {
        return this.streamService
            .getOverlays(this.config.apiField, this.mediaType, this.mediaId, this.searchQuery, pagingParams, sortParams)
            .pipe(mergeMap((response) => {
              const currentOverlays = response.results.overlays || response.results.courses;
              currentOverlays.items = currentOverlays.items.map((item) => {
                if (this.mediaType === MEDIA_TYPE.STREAM && item.startTime) {
                  item.startTime = +item.startTime - (new Date().getTimezoneOffset() * 60);
                }
                return item;
              });
              return of(response);
            }));
      },
      columns: [
        {
          name: 'overlays.table.status',
          sortField: 'ACTIVE',
          tmpl: this.statusTmpl,
          preventNavigation: true
        },
        {
          name: 'overlays.table.image',
          tmpl: this.imageTmpl
        },
        {
          name: 'overlays.table.name',
          sortField: 'NAME',
          tmpl: this.nameTmpl
        },
        {
          name: 'overlays.table.position',
          dataField: 'position',
          tmpl: this.posTmpl
        },
        {
          name: 'overlays.survey-start',
          sortField: 'START_TIME',
          tmpl: this.startTimeTmpl
        }, {
          name: 'overlays.survey-duration',
          tmpl: this.durationTmpl
        },
        {
          name: 'overlays.table.show',
          dataField: 'showAmount',
          sortField: 'SHOW_AMOUNT',
        },
        {
          name: 'overlays.table.clicks',
          dataField: 'clickAmount',
          sortField: 'CLICK_AMOUNT',
        },
        {
          name: 'overlays.table.actions',
          tmpl: this.actionsTmpl,
          class: 'actions'
        }
      ]

    };
    this.configLoaded = true;
  }

  public search(event: string): void {
    this.table.isSearch = true;
    this.searchQuery = event;
    this.table.refreshData({});
  }

  public addNewItem(): void {
    this.router.navigate(['new'], {relativeTo: this.activatedRoute});
  }

  private resolveNavigationUrl(): string {
    const mediaIdRelatedUrlSegment = this.mediaId + '/' + this.config.newRoute + '/{id}/edit';
    switch (this.mediaType) {
      case MEDIA_TYPE.STREAM:
      case MEDIA_TYPE.STREAM_RECORDING:
        return 'streams/' + mediaIdRelatedUrlSegment;
      case MEDIA_TYPE.VIDEO_ON_DEMAND:
        return 'vod/' + mediaIdRelatedUrlSegment;

    }
  }

  goToOverlayEdit(id) {
    this.router.navigate([`${this.standAlone ? this.config?.newRoute + '/' : ''}${id}/edit`], {relativeTo: this.activatedRoute});
  }

  goToOverlayInfo(id) {
    this.router.navigate([`${this.standAlone ? this.config?.newRoute + '/' : ''}${id}/info`], {relativeTo: this.activatedRoute});
  }

  public delete(overlay: IOverlay, event): void {
    event.stopPropagation();
    this.confirmText = this.confirmTextTpl.replace('{overlayName}', overlay.name);
    const dialogRef = this.dialog.open(this.confirm);
    dialogRef.afterClosed()
        .pipe(takeUntil(this.destroy$))
        .subscribe((confirm: boolean) => {
          this.confirmText = '';
          if (confirm) {
            this.streamService.deleteOverlay(overlay.id, this.overlaysType)
                .pipe(takeUntil(this.destroy$)).subscribe((res) => {
              if (res.success) {
                this.table.resetTableData();
                this.table.refreshData({});
              }
            });
          }
        });
  }

  onToggle(event: any, object: IOverlay) {
    this.streamService.overlayActive({
      overlayId: object.id,
      active: event.value
    }, this.overlaysType).pipe(takeUntil(this.destroy$)).subscribe(() => {
      // this.form.get('status').patchValue(this.localizeStatus(SURVEY_STATUS.ACTIVE));
    });
  }

  public goToNoDataUrl(url: string): void {
    this.router.navigate([url]);
  }

}
