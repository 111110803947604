import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TARIFF_PLAN_PERIOD} from "@src/app/components/tariff-plan-cards-list/subscription-plan-card/subscription-plan-card.component";

@Component({
    selector: 'app-period-toggle',
    templateUrl: './period-toggle.component.html',
    styleUrls: ['./period-toggle.component.sass']
})
export class PeriodToggleComponent {

    @Output() planPeriodChange = new EventEmitter<TARIFF_PLAN_PERIOD>();
    currentPeriod = TARIFF_PLAN_PERIOD.MONTH;
    @Input() hideHeader = false;

    TARIFF_PLAN_PERIOD = TARIFF_PLAN_PERIOD;

    constructor() {
    }

    setPlanPeriod(period: TARIFF_PLAN_PERIOD): void {
        this.currentPeriod = period;
        this.planPeriodChange.emit(period);
    }

}
