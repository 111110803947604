<div class="linked-streams-body">
    <div class="linked-streams">
        <div class="linked-streams-nav" *ngIf="numberOfSlides > 3">
            <div class="linked-streams-prev"
                 *ngIf="sliderArrow.leftArrow"
                 (click)="moveLeft()">
                <i class="icon icon-prev"></i>
            </div>
            <div class="linked-streams-next"
                 *ngIf="sliderArrow.rightArrow"
                 (click)="moveRight()">
                <i class="icon icon-next"></i>
            </div>
        </div>
        <div class="row row-padding-5 flex-nowrap">
            <drag-scroll #sliderComp class="wrapper-slider">
                <div drag-scroll-item class="col-4 item-wrap"
                     *ngFor="let group of data" (click)="selectGroup(group)">
                    <div class="item bg-item">
                        <div class="streams-item-header">
                            <h3 class="title">{{group.name}}</h3>
                        </div>
                        <div class="stream-item-footer">
                                <span class="text">
                                    {{ (group.price | currency)}}
                                </span>
                        </div>
                    </div>
                </div>
            </drag-scroll>
        </div>
    </div>
</div>
