import {IFormConfig, IFormFieldsConfig} from '@src/app/services/stream-metadata/stream.service';
import {INPUT_TYPES} from 'ui-elements';
import {Injectable} from "@angular/core";
import {FormUtil} from "@src/app/utils/form.util";
import {Validators} from "@angular/forms";
import {BehaviorSubject} from 'rxjs';
import {MEDIA_TYPE} from '@src/app/components/streamdust-player/constants/mediaType';
import {ActivatedRoute} from '@angular/router';

@Injectable()
export class SurveyFieldsConfig {
    public name: IFormFieldsConfig[][] = [
        [
            {
                name: 'name',
                config: {
                    inputType: INPUT_TYPES.INPUT,
                    placeholder: 'survey.field.name',
                    isRequired: true,
                    size: 8
                }
            }
        ]
    ];

    public question: IFormFieldsConfig[][] = [
        [
            {
                name: 'question',
                config: {
                    inputType: INPUT_TYPES.INPUT,
                    placeholder: 'survey.field.question',
                    isRequired: true,
                    size: 8
                }
            },
        ]
    ];

    public answer: IFormFieldsConfig[][] = [
        [
            {
                name: 'answer',
                control: ['', Validators.required],
                config: {
                    inputType: INPUT_TYPES.INPUT,
                    placeholder: 'survey.field.answer',
                    isRequired: true,
                    size: 8
                }
            },
        ]
    ];

    public duration: IFormFieldsConfig[][] = [
        [
            {
                name: 'manualStart',
                config: {
                    inputType: INPUT_TYPES.CHECKBOX,
                    placeholder: 'survey.field.manualStart',
                    size: 4,
                }
            },
        ],
        [
            {
                name: 'startTime',
                title: 'survey.field.startTime',
                config: {
                    inputType: INPUT_TYPES.INPUT,
                    placeholder: 'survey.field.startTime',
                    bottomText: 'survey.start-time.explanation.' + this.resolveMediaType(),
                    halfSize: true,
                    appendText: 'hh:mm:ss',
                    mask: 'h0:m0:s0',
                    isRequired: true,
                }
            },
            {
                name: 'duration',
                title: 'survey.field.duration',
                config: {
                    inputType: INPUT_TYPES.INPUT,
                    placeholder: 'survey.field.duration',
                    bottomText: 'survey.duration.explanation.' + this.resolveMediaType(),
                    halfSize: true,
                    appendText: 'hh:mm:ss',
                    mask: 'h0:m0:s0',
                    isRequired: true,
                }
            },
        ]
    ];

    public answerForm: IFormConfig = {
        multiValue: true,
        name: 'answers',
        formsFields: this.answer,
        forms: [FormUtil.buildForm(this.answer), FormUtil.buildForm(this.answer)],
        addButtonText: 'survey.field.answer.add',
        title: 'survey.field.answer',
    };

    constructor(private readonly activatedRoute: ActivatedRoute) {}

    private resolveMediaType(): MEDIA_TYPE {
        return (this.activatedRoute.data as BehaviorSubject<any>)?.value?.mediaType;
    }
}
