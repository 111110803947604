import {Injectable} from '@angular/core';
import {ApiService} from 'ui-elements';
import {Observable, of} from 'rxjs';
import {IVoucherGroupModel} from '@src/app/models/voucher.model';
import {API_URLS_APP, UrlGenerator} from '@src/app/constants/api-urls.constant';
import {map, pluck, switchMap} from 'rxjs/operators';
import {IPagedResponse, IResponse, PagingParams, SortParam} from '@src/app/models/response.model';
import {INameId} from "@src/app/models/core.model";
import {MEDIA_TYPE} from "@src/app/components/streamdust-player/constants/mediaType";

@Injectable({
    providedIn: 'root'
})
export class VoucherGroupsService {

    constructor(private apiService: ApiService) {
    }

    public getGroups(productId: string, productType: MEDIA_TYPE, searchQuery: string, paging: PagingParams = DEFAULT_PAGING, sort: SortParam[] = DEFAULT_COUPONS_SORT): Observable<IPagedResponse<IVoucherGroupModel>> {
        return this.apiService.post(UrlGenerator.generate(API_URLS_APP.VOUCHER_GROUP_GET, {productType, productId}), {
            paging,
            sort,
            searchTerm: searchQuery
        });
    }

    public createGroup(body: IVoucherGroupModel): Observable<IResponse> {
        return this.apiService.post(API_URLS_APP.VOUCHER_GROUP_CREATE, body);
    }

    public getVoucherGroupLimitations(): Observable<{discountValue: number}> {
        return this.apiService.get(API_URLS_APP.VOUCHER_GROUP_LIMITATIONS)
            .pipe(pluck('results', 'data'));
    }

    public getAllGroups(mediaId: string, mediaType: MEDIA_TYPE): Observable<INameId[]> {
        return this.getGroups(mediaId, mediaType,  '').pipe(switchMap((res) => {
            const itemsLength = res.results.groups.items.length;
            const totalCount = res.results.groups.paging.items;
            if (itemsLength === totalCount) {
                return of<INameId[]>(res.results.groups.items.map(group => ({id: group.id, name: group.name})));
            }
            return this.getGroups(mediaId, mediaType, '', {page: 0, itemsOnPage: res.results.groups.paging.items})
                .pipe(map(result => result.results.groups.items.map(_group => ({id: _group.id, name: _group.name}))));
        }));
    }

    public updateGroup(group: IVoucherGroupModel): Observable<IResponse> {
        return this.apiService.put(API_URLS_APP.VOUCHER_GROUP_UPDATE, group);
    }

    public deactivateCode(streamId: string, code: string, productType: string): Observable<IResponse> {
        return this.apiService.put(UrlGenerator.generate(API_URLS_APP.VOUCHER_DEACTIVATE, {streamId, code, productType}), {});
    }

    public deactivateGroup(streamId: string, id: string): Observable<IResponse> {
        return this.apiService.put(UrlGenerator.generate(API_URLS_APP.VOUCHER_GROUP_DEACTIVATE, {streamId, id}), {});
    }
}

const DEFAULT_PAGING = {
    page: 0,
    itemsOnPage: 10,
} as PagingParams;

const DEFAULT_COUPONS_SORT = [{
    field: 'NAME',
    order: 'ASC'
}] as SortParam[];

export enum VOUCHER_STATUS {
    ACTIVE = 'ACTIVE',
    DISABLED = 'INACTIVE',
    EXPIRED = 'EXPIRED',
    REDEEMED = 'REDEEMED'
}
