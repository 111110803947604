import {IPrice} from "@src/app/models/stream.model";
import {IMediaItem} from "@src/app/models/core.model";

export interface IMediaGroupPayload {
    id?: string;
    name: string;
    paymentType: GROUP_PAYMENT_SYSTEM;
    priceAmount: number;
}

export interface IMediaGroup {
    id: string;
    owner: string;
    name: string;
    paymentType: GROUP_PAYMENT_SYSTEM;
    price: IPrice;
    mediaItems: IMediaItem[];
    created: Date;
    genre: string;
    hasAccess?: boolean;
}

export enum GROUP_PAYMENT_SYSTEM {
    PPV = 'PPV',
    PAY_FOR_THE_EVENT = 'PAY_FOR_THE_Event'
}
