import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AutoDestroyService, LoadingService} from 'ui-elements';
import {IUserAGB} from '@src/app/pages/user-profile/components/withdrawal-and-conditions/services/withdrawal-and-conditions.service';
import {IPaymentCard, IPaymentCardsListControls} from '@src/app/components/payment-cards-list/payment-cards-list.component';
import {
  IHeidelpayCard,
  IHeidelpayPaymentDetails
} from '@src/app/components/payment-cards-list/heidelpay-payment/heidelpay-payment.component';
import {MEDIA_TYPE} from '@src/app/components/streamdust-player/constants/mediaType';
import {PLAYER_DISPLAY_MODE, PLAYER_MODE} from '@src/app/components/streamdust-player/constants/playerMode';
import {IMediaPaymentRequest} from '@src/app/models/stream.model';
import {catchError, takeUntil} from 'rxjs/operators';
import {of} from 'rxjs';
import {PAYMENT_STATUS} from '@src/app/services/subscription-manage/subscription-manage.service';
import {IPaymentRequest} from '@src/app/components/streamdust-player/media-access-modal/media-access-modal.component';
import {AuthService} from '@src/app/services/auth/auth.service';
import {StreamService} from '@src/app/services/stream-metadata/stream.service';
import {PaymentService} from '@src/app/services/payment/payment.service';

@Component({
  selector: 'app-slots-payment',
  templateUrl: './slots-payment.component.html',
  styleUrls: ['./slots-payment.component.sass'],
  providers: [AutoDestroyService]
})
export class SlotsPaymentComponent implements OnInit {
  @Input() termsConditions: IUserAGB;
  @Input() public slotId: string;
  @Input() public productType: string;
  @Input() public playerMode: PLAYER_DISPLAY_MODE;
  @Input() public mediaId: string;
  @Output() public paymentCanceled$: EventEmitter<void> = new EventEmitter<void>();
  @Output() public slotPaymentSuccess$: EventEmitter<string> = new EventEmitter<string>();
  paymentCardsListControls: IPaymentCardsListControls = {
    payButton: true,
    addNewCard: {
      show: true,
      text: 'payment-cards-list.use-another-card',
      customHandler: (paymentDetails) => this.payForSlot(paymentDetails, true)
    },
    cancelButton: {
      show: true,
      action: () => {
        this.paymentCanceled$.emit();
      }
    },
  };

  constructor(
      public loadingService: LoadingService,
      private destroy$: AutoDestroyService,
      private authService: AuthService,
      private streamService: StreamService,
      private paymentService: PaymentService,
  ) { }

  ngOnInit(): void {
  }

  payForSlot(paymentCard: IHeidelpayPaymentDetails | IPaymentCard, isNewCardPayment = false): void {
    this.paymentService.slotPayment$.emit({paymentCard, isNewCardPayment, slotId: this.slotId});
  }


}
