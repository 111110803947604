export function asBoolean(input: string): boolean {
    return input?.toLowerCase() === 'true';
}

export function obfuscateEmail(email: string): string {
    const address = email.substring(0, email.lastIndexOf('@'));
    const domain = email.substring(email.lastIndexOf('@') + 1, email.length);
    const domainAddress = domain.substring(0, domain.indexOf('.'));
    const topLevelDomain = domain.substring(domain.indexOf('.') + 1, domain.length);
    let obfuscatedEmail = address[0] + address[1];
    for (let i = 0; i < address.length - 3; i++) {
        obfuscatedEmail += '.';
    }
    let obfuscatedDomain = domainAddress[0];
    for (let i = 0; i < domainAddress.length - 2; i++) {
        obfuscatedDomain += '.';
    }
    return obfuscatedEmail + '@' + obfuscatedDomain + '.' + topLevelDomain;
}

export function cutString(string: string, from: number, to: number): string {
    if (!string) {
        return null;
    }
    return string.substring(from, to);
}

export function revertString(string: string): string {
    if (!string) {
        return null;
    }
    return string.split('').reverse().join('');
}
