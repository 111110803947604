import * as moment from 'moment';
import {FormControl, FormGroup} from '@angular/forms';
import * as _ from 'lodash';
import {DurationPipe} from '@src/app/pipes/duration.pipe';
import {DateHelper, IFormConfig, IFormFieldsConfig, INPUT_TYPES, TIME_TYPE} from 'ui-elements';

export class FormUtil {

    constructor() {
    }

    static buildForm(config: IFormFieldsConfig[][]): FormGroup {
        const res: any = {};
        config.forEach(row => {
            row.forEach(field => {
                if (field.control instanceof FormControl) {
                    res[field.name] = field.control;
                    return;
                }
                res[field.name] = new FormControl(field.control[0], field.control.length > 1 ? field.control[1] : null);
            });
        });
        return new FormGroup(res);
    }

    static mapResponseToForms<T>(payload: T, formsConfig: IFormConfig[]): IFormConfig[] {
        const durationPipe = new DurationPipe();
        formsConfig.forEach(form => {

                if (form.isDefault) {
                    if (!form.addFieldsToMainObject) {
                        if (!payload[form.name]) {
                            return;
                        }
                        form.formFields.forEach(row => {
                            row.forEach(field => {
                                let value = payload[form.name][field.name];
                                if (field.config.inputType === INPUT_TYPES.DATETIME) {
                                    value = moment(value).toDate();
                                }
                                if (field && field.config.time) {
                                    value = durationPipe.transform(value);
                                }
                                form.form.get(field.name).patchValue(value);
                                if (field.name === 'startTimestamp') {
                                    if (Date.now() > Date.parse(value)) {
                                        form.form.get(field.name).setErrors({'dateIsNotCorrect': true});
                                    }
                                }
                            });
                        });
                    } else {
                        form.formFields.forEach(row => {
                            row.forEach(field => {
                                let value = payload[field.name];
                                if (field.config.inputType === INPUT_TYPES.DATETIME) {
                                    value = moment(value).toDate();
                                }
                                if (field && field.config.time) {
                                    value = durationPipe.transform(value);
                                }
                                form.form.get(field.name).patchValue(value);
                            });
                        });
                    }
                }

                if (form.isToggle) {
                    if (form.simpleToggle) {
                        if (form.injectTo) {
                            const mainSection = payload[form.injectTo];
                            if (!mainSection) {
                                return;
                            }

                            const valueFromPayload: any[] = mainSection[form.name];
                            if (!valueFromPayload || !valueFromPayload.length || (valueFromPayload.length === 1 && !valueFromPayload[0])) {
                                return;
                            }

                            form.enabled = true;

                            if (form.multiValue) {
                                form.forms = [];
                                valueFromPayload.forEach(item => {
                                    const formItem = this.buildForm(form.formsFields);
                                    form.formsFields.forEach(row => {
                                        row.forEach(field => {
                                            formItem.get(field.name).patchValue(item);
                                        });
                                    });
                                    form.forms.push(formItem);
                                });
                            }
                        }
                    }

                    if (!form.simpleToggle) {
                        const valueFromPayload = payload[form.name];
                        if (!valueFromPayload) {
                            return;
                        }

                        form.enabled = valueFromPayload.enabled;
                        if (form.forcedDisable) {
                            form.enabled = false;
                        }

                        const items: any[] = valueFromPayload[form.itemsObject];
                        if (!items || !items.length || (items.length === 1 && !items[0])) {
                            return;
                        }

                        form.forms = [];
                        items.forEach(item => {
                            const formItem = this.buildForm(form.formsFields);
                            if (!form.multiValue) {
                                form.formsFields.forEach(row => {
                                    row.forEach(field => {
                                        let value = item[field.name];
                                        if (value === null || typeof value === 'undefined') {
                                            return;
                                        }
                                        if (field && field.config.fieldType === 'IPriceCurrency') {
                                            return;
                                        }
                                        if (field.config.inputType === INPUT_TYPES.DATETIME) {
                                            value = moment(value).toDate();
                                        }
                                        // if (field && field.config.time) {
                                        //     value = durationPipe.transform(value);
                                        // }

                                        if (field && field.config.time) {
                                            value = moment.utc(value).format('HH') +
                                                moment.utc(value).format(':mm') + moment.utc(value).format(':ss');
                                        }
                                        if (field && field.config.fieldType === 'IPriceAmount') {
                                            formItem.get(field.name).patchValue(value.amount);
                                            return;
                                        }
                                        formItem.get(field.name).patchValue(value);
                                    });
                                });
                            }
                            if (form.multiValue) {
                                formItem.get(form.formsFields[0][0].name).patchValue(item);
                            }
                            form.forms.push(formItem);

                        });
                        if (form.formFields) {
                            form.formFields.forEach(row => {
                                row.forEach(field => {
                                    let value = payload[form.name][field.name];
                                    if (field.config.inputType === INPUT_TYPES.DATETIME) {
                                        value = moment(value).toDate();
                                    }
                                    if (field && field.config.time) {
                                        value = durationPipe.transform(value);
                                    }
                                    form.form.get(field.name).patchValue(value);
                                });
                            });
                        }

                    }
                }
            }
        );
        return formsConfig;
    }

    static mapFormsToPayload<T>(formsConfig: IFormConfig[], currency?: string): T {
        const res = {};
        formsConfig.forEach(form => {
            const section: any = {};
            if (form.isDefault) {
                if (!form.addFieldsToMainObject) {
                    res[form.name] = section;
                    if (form.payload) {
                        res[form.name] = form.payload;
                    }
                    if (!form.payload) {
                        Object.keys(form.form.controls).forEach(control => {
                            let value = form.form.get(control).value;
                            const field = form.formFields
                                .filter(row => row
                                    .find(fld => fld.name === control))[0]
                                .find(fld => fld.name === control);
                            if (field && field.config.inputType === INPUT_TYPES.DATETIME) {
                                value = moment(value).toDate().getTime();
                            }
                            if (field && field.config.inputType === INPUT_TYPES.MULTISELECT) {
                                value = [];
                            }
                            if (field && field.config.inputType === INPUT_TYPES.CURRENCY_AMOUNT) {
                                if (typeof value === 'string') {
                                    value = Number(value.replace(/[$,]/g, ''));
                                }
                            }

                            // if (field && field.config.time) {
                            //     value = DateHelper.parseTime(value, TIME_TYPE.MILLISECONDS);
                            // }
                            if (field && field.config.time) {
                                value = moment(value, 'HH:mm:ss: A').diff(moment().startOf('day'), 'milliseconds');
                            }
                            section[control] = value;
                        });
                    }
                } else {
                    Object.keys(form.form.controls).forEach(control => {
                        let value = form.form.get(control).value;
                        const field = form.formFields
                            .filter(row => row
                                .find(fld => fld.name === control))[0]
                            .find(fld => fld.name === control);
                        if (field && field.config.inputType === INPUT_TYPES.DATETIME) {
                            value = moment(value).toDate().getTime();
                        }
                        if (field && field.config.inputType === INPUT_TYPES.CURRENCY_AMOUNT) {
                            if (typeof value === 'string') {
                                value = Number(value.replace(/[$,]/g, ''));
                            }
                        }
                        // if (field && field.config.time) {
                        //     value = DateHelper.parseTime(value, TIME_TYPE.MILLISECONDS);
                        // }

                        if (field && field.config.time) {
                            value = moment(value, 'HH:mm:ss: A').diff(moment().startOf('day'), 'milliseconds');
                        }
                        res[control] = value;
                    });
                }
            }

            if (form.isToggle) {
                if (form.simpleToggle) {
                    if (form.injectTo) {
                        res[form.injectTo][form.name] = form.multiValue ? [] : {};

                        if (form.multiValue && !form.enabled) {
                            return;
                        }
                        if (form.multiValue) {
                            form.forms.forEach(frm => {
                                Object.keys(frm.controls).forEach(control => {
                                    res[form.injectTo][form.name].push(frm.get(control).value);
                                });
                            });
                        }
                    }
                }

                if (!form.simpleToggle) {
                    res[form.name] = section;
                    section.enabled = form.enabled;
                    if (form.form) {
                        Object.keys(form.form.controls).forEach(control => {
                            section[control] = form.form.get(control).value;
                        });
                    }

                    if (!form.multiValue) {
                        section[form.itemsObject] = form.forms.map(frm => {
                            if ((frm.invalid && !frm.touched)) {
                                return;
                            }
                            const items = {};
                            Object.keys(frm.controls).forEach(control => {
                                let value = frm.get(control).value;

                                const field = form.formsFields
                                    .filter(row => row.find(fld => fld.name === control))[0]
                                    .find(fld => fld.name === control);
                                if (field && field.config.fieldType === 'IPriceCurrency') {
                                    return;
                                }
                                if (field && field.config.inputType === INPUT_TYPES.DATETIME) {
                                    value = moment(value).toDate().getTime();
                                }
                                if (field && field.config.inputType === INPUT_TYPES.CURRENCY_AMOUNT) {
                                    if (typeof value === 'string') {
                                        value = Number(value.replace(/[$,]/g, ''));
                                    }
                                }
                                // if (field && field.config.time) {
                                //     value = DateHelper.parseTime(value, TIME_TYPE.MILLISECONDS);
                                // }

                                if (field && field.config.time) {
                                    value = moment(value, 'HH:mm:ss: A').diff(moment().startOf('day'), 'milliseconds');
                                }

                                if (field && field.config.fieldType === 'IPriceAmount') {
                                    //TODO: remove after backend done https://jira.anotheria.net/browse/STREAM-1253
                                    items[control] = {amount: value};
                                    return;
                                }
                                items[control] = value;
                            });
                            return items;
                        });
                    }

                    if (form.multiValue) {
                        const result = [];
                        form.forms.forEach(item => {
                            Object.keys(item.controls).forEach(control => {
                                const value = item.get(control).value;
                                if (!value) {
                                    return;
                                }
                                result.push(value);
                            });
                        });
                        section[form.itemsObject] = result;
                    }
                }
            }
        });
        return res as T;
    }

    public static unselectValues(formConfig: IFormConfig, indexToRemove: number): void {
        const formValuesKeys = Object.keys(formConfig.forms[indexToRemove].value);
        const formFields = _.cloneDeep(formConfig.formsFields);
        formFields.forEach(row => {
            row.forEach(field => {
                if (formValuesKeys.includes(field.name)
                    && (field.config.inputType === INPUT_TYPES.MULTISELECT
                        || field.config.inputType === INPUT_TYPES.SELECT)) {
                    const key = formConfig.forms[indexToRemove].value[field.name];
                    const found = field.config.selectOptions.find(item => item.key === key);
                    if (!found) {
                        return;
                    }
                    found.selected = false;
                }
            });
        });
        formConfig.formsFields = formFields;
    }
}
