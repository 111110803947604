<div *ngIf="!config?.hideEmptyList || _data?.items?.length" id="streams-list"
     [class.card-livestreams-with-selector]="config.selector"
     class="card bg-transparent content-p0 card-livestreams box-slide">
    <app-media-list-header *ngIf="title || config?.headerConfig?.filtration || config?.headerConfig?.withSearch"
                           [title]="title"
                           [config]="config?.headerConfig"
                           [filters]="config?.filters"
                           [activeQuery]="activeQuery"
                           [implicitFilters]="implicitFilters"
                           (filter$)="doFilter($event)"
                           [borderTop]="config?.borderTop"></app-media-list-header>
    <div class="row stream-row" [id]="config?.elementId" [class.inline]="config?.carouselLayout"
         [class.empty-stream-list]="!_data?.items?.length && !filtrationLoading && !(loadingService.loading | async)">
        <ng-container *ngIf="!filtrationLoading && !(loadingService.loading | async)">
            <div *ngIf="config?.addMediaButton?.show" class="cursor-pointer"
                 [class.col-xl-4]="_data?.items?.length" [class.col-lg-6]="_data?.items?.length"
                 (click)="navigateToNewMediaPage()" [class.col-12]="!_data?.items?.length">
                <div class="area-stream create-stream" [class.mh-320]="_data?.items?.length"
                     [class.create-stream-sm]="!_data?.items?.length">
                    <i class="icon icon-plus"></i>
                    <span class="title">{{config?.addMediaButton?.text | localize}}</span>
                </div>
            </div>
            <div *ngIf="config?.selector" class="cursor-pointer col-sm-6 col-xl-4">
                <div class="slide-item">
                    <div class="slide-item-header">
                        <h3 class="title">{{config.selector.title | localize}}</h3>
                    </div>
                    <div class="slide-item-body">
                        <ul class="slide-list">
                            <ng-container *ngFor="let item of config.selector.items; let i = index">
                                <li [class.active]="i === selectorIndex" (click)="onSelectorClick(i)">
                                    <span>{{item}}</span></li>
                            </ng-container>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="wrapper-slider" *ngIf="config.isLanding">
                <drag-scroll #nav class="wrapper-slider">
                    <div drag-scroll-item *ngFor="let item of _data?.items" class="col-sm-6 col-xl-4 {{config?.cssClass || ''}}">
                        <app-media-card [data]="item"
                                        [config]="config?.cardConfig"
                                        (click$)="click($event)"
                                        (remove$)="removeMediaItem($event)">
                        </app-media-card>
                    </div>
                </drag-scroll>

                <div class="slider-nav" *ngIf="_data?.items.length > 3">
                    <div class="slider-prev" (click)="moveLeft()">
                        <img src="assets/img/ico/icon-prev-primary.png" alt="">
                    </div>
                    <div class="slider-next" (click)="moveRight()">
                        <img src="assets/img/ico/icon-next-primary.png" alt="">
                    </div>
                </div>
            </div>

            <ng-container *ngIf="!config.isLanding">
                <div *ngFor="let item of _data?.items; trackBy: identify" class="col-sm-6 col-xl-4 {{config?.cssClass || ''}}">
                    <app-media-card [data]="item"
                                    [config]="config?.cardConfig"
                                    (click$)="click($event)"
                                    (remove$)="removeMediaItem($event)">
                    </app-media-card>
                </div>
            </ng-container>

            <div *ngIf="config?.moreButton" class="cursor-pointer"
                 [class.col-xl-4]="_data?.items?.length">
                <!--<div class="area-stream create-stream">-->
                <!--<span class="title">{{ config?.moreButton?.text | localize}}</span>-->
                <!--</div>-->
                <div class="more-button-container">
                    <button class="btn btn-primary" (click)="moreClick()">{{'common.load-more' | localize}}</button>
                </div>
            </div>
            <ng-container
                    *ngIf="!(!_data?.items?.length && !filtrationLoading && !(loadingService.loading | async) && !config?.addMediaButton?.show)">
                <div *ngFor="let skeletonItem of skeletonItemsAmount" class="col-sm-6 col-xl-4 empty-skeleton">
                    <div class="area-stream empty">
                        <div class="area-stream-header {{config?.cardConfig?.skeleton?.headerCssClass}}"></div>
                        <div class="area-stream-body {{(config?.cardConfig?.skeletonBackgroundClass ? config?.cardConfig?.skeletonBackgroundClass : config?.cardConfig?.backgroundClass) || ''}}">
                            <div class="empty-block empty-block-25 mb-10" *ngIf="!config.basicSkeleton"></div>
                            <div class="empty-block empty-block-25 mb-25"></div>
                            <div class="row align-items-end">
                                <div class="col">
                                    <div class="empty-block empty-block-17 mb-10"></div>
                                    <div class="empty-block empty-block-17 mb-10" *ngIf="!config.basicSkeleton"></div>
                                    <div class="empty-block empty-block-17"></div>
                                </div>
                                <div class="col-4">
                                    <div class="empty-block empty-block-30"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-container>
        <p *ngIf="!_data?.items?.length && !filtrationLoading && !(loadingService.loading | async) && !config?.addMediaButton?.show">{{'media-list.no-media' | localize}}</p>
        <ano-loader class="list-loader" *ngIf="filtrationLoading || (loadingService.loading | async)"
                    [isOpen]="filtrationLoading || (loadingService.loading | async)" [theme]="2"></ano-loader>
        <div style="width: 100%">
            <ano-loader [isOpen]="loading" [noAbsolute]="true" [theme]="2"></ano-loader>
        </div>
    </div>
    <div *ngIf="config?.loadMore === LOAD_TYPE.BUTTON && (_hasMore || config?.loadMoreCustomClick) && !filtrationLoading && !(loadingService.loading | async) && _data?.items?.length > 0 && !loading"
         class="table-footer-area justify-content-center">
        <button class="btn btn-primary" (click)="loadNextPage()">{{'common.load-more' | localize}}</button>
    </div>
</div>
