import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {LeadsConfig} from '@src/app/pages/stream-page/components/media-leads/config/leads-config';
import {ITableButtonConfig, ITableColumn, ITableConfig, TableComponent, TEMPLATE_ID} from 'ui-elements';
import {ILead} from '@src/app/pages/stream-page/components/media-leads/config/lead-model';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import {MediaLeadsService} from '@src/app/services/media-leads/media-leads.service';
import {MEDIA_TYPE} from '@src/app/components/streamdust-player/constants/mediaType';
import {ActivatedRoute, Params} from '@angular/router';
import {takeUntil, tap} from 'rxjs/operators';
import {BehaviorSubject} from 'rxjs';
import {getFileNameFromResponseContentDisposition} from '@src/app/utils/file-name-from-content-disposition.util';
import {saveAs} from 'file-saver/FileSaver';
import {StreamMarketingToolsOverlaysTypes} from '@src/app/pages/stream-page/components/stream-marketing-tools-overlays/stream-marketing-tools-overlays.component';
import {CoursesOverviewConfig} from '@src/app/pages/stream-page/components/media-leads/config/courses-overview-config';
import {CoursesOverviewService} from '@src/app/services/courses-overview/courses-overview.service';

interface IPresentation {
    id?: string;
    accountId?: { internalId?: string };
    firstName: string;
    lastName: string;
    email: string;
    verified: boolean;
    city: string;
    country: string;
}

@Component({
    selector: 'app-media-leads',
    templateUrl: './media-leads.component.html',
    styleUrls: ['./media-leads.component.scss'],
    providers: [AutoDestroyService, LeadsConfig, MediaLeadsService, CoursesOverviewConfig, CoursesOverviewService]
})
export class MediaLeadsComponent implements OnInit {
    @ViewChild('statusTmpl', {static: true}) public statusTmpl: TemplateRef<any>;
    @ViewChild('actionsTmpl', {static: true}) public actionsTmpl: TemplateRef<any>;
    @ViewChild('dateTmpl', {static: true}) public dateTmpl: TemplateRef<any>;
    @ViewChild('dateTmplCourses', {static: true}) public dateTmplCourses: TemplateRef<any>;
    @ViewChild('tableComponent', {static: false}) public table: TableComponent;
    public config: ITableConfig<IPresentation>;
    private mediaType: MEDIA_TYPE;
    private mediaId: string;
    private searchTerm = '';
    public tableButtonConfig: ITableButtonConfig = {
        buttonConfig: {styleType: 'success', sizeType: 'block'},
        text: 'lead.table.button.download',
        action: () => this.downloadCsv()
    };
    public configPage: IPageLeadsConfig;
    public overlaysType: StreamMarketingToolsOverlaysTypes;
    public StreamMarketingToolsOverlaysTypes = StreamMarketingToolsOverlaysTypes;

    constructor(
        private leadsConfig: LeadsConfig,
        private coursesOverviewConfig: CoursesOverviewConfig,
        private leadsService: MediaLeadsService,
        private coursesOverviewService: CoursesOverviewService,
        private activatedRoute: ActivatedRoute,
        private destroy$: AutoDestroyService
    ) {
    }

    ngOnInit(): void {
        this.setPageConfig();

        this.activatedRoute.parent.params.pipe(
            tap(({id}: Params) => {
                this.mediaId = id;
                this.mediaType = this.resolveMediaType();
            }),
        ).subscribe(() => {
            if (this.overlaysType === StreamMarketingToolsOverlaysTypes.LEADGENERATION) {
                this.config = this.leadsConfig.resolveConfig((sortParams, pagingParams) => {
                    return this.leadsService.getLeads(this.mediaType, this.mediaId, this.searchTerm, sortParams, pagingParams)
                        .pipe(takeUntil(this.destroy$));
                });
            } else if (this.overlaysType === StreamMarketingToolsOverlaysTypes.COURSES) {
                this.config = this.coursesOverviewConfig.resolveConfig((sortParams, pagingParams) => {
                    return this.coursesOverviewService.get(this.mediaType, this.mediaId, this.searchTerm, sortParams, pagingParams)
                        .pipe(takeUntil(this.destroy$));
                });
            }

            this.addTemplatesToConfigs();
        });
    }

    private resolveMediaType(): MEDIA_TYPE {
        return (this.activatedRoute.data as BehaviorSubject<any>)?.value?.mediaType;
    }

    private addTemplatesToConfigs(): void {
        this.config.columns.forEach((column: ITableColumn) => {
            if (column?.tmplId === TEMPLATE_ID.STATUS) {
                column.tmpl = this.statusTmpl;
            }
            if (column?.tmplId === TEMPLATE_ID.ACTIONS) {
                column.tmpl = this.actionsTmpl;
            }
            if (column?.tmplId === TEMPLATE_ID.DATE) {
                column.tmpl = this.dateTmpl;
                if (this.overlaysType === StreamMarketingToolsOverlaysTypes.COURSES) {
                    column.tmpl = this.dateTmplCourses;
                }
            }
        });
    }

    public downloadCsv(): void {
        if (this.overlaysType === StreamMarketingToolsOverlaysTypes.LEADGENERATION) {
            this.leadsService.downloadCsv(this.mediaType, this.mediaId)
                .pipe(takeUntil(this.destroy$))
                .subscribe(res => {
                    saveAs(
                        res.body,
                        getFileNameFromResponseContentDisposition(res),
                    );
                });
        } else if (this.overlaysType === StreamMarketingToolsOverlaysTypes.COURSES) {
            this.coursesOverviewService.downloadCsv(this.mediaType, this.mediaId)
                .pipe(takeUntil(this.destroy$))
                .subscribe(res => {
                    saveAs(
                        res.body,
                        getFileNameFromResponseContentDisposition(res),
                    );
                });
        }

    }

    public deleteLead(id: string): void {
        if (this.overlaysType === StreamMarketingToolsOverlaysTypes.LEADGENERATION) {
            this.leadsService.deleteLead(this.mediaType, this.mediaId, id)
                .pipe(takeUntil(this.destroy$))
                .subscribe(() => {
                    this.table.resetTableData();
                    this.table.refreshData({});
                });
        } else if (this.overlaysType === StreamMarketingToolsOverlaysTypes.COURSES) {
            this.coursesOverviewService.delete(this.mediaType, this.mediaId, id)
                .pipe(takeUntil(this.destroy$))
                .subscribe(() => {
                    this.table.resetTableData();
                    this.table.refreshData({});
                });
        }
    }

    public search(event: string): void {
        this.searchTerm = event;
        this.table.refreshData({});
    }


    private setPageConfig() {
        this.overlaysType = (this.activatedRoute.data as BehaviorSubject<any>)?.value?.overlaysType;

        if (this.overlaysType === StreamMarketingToolsOverlaysTypes.LEADGENERATION) {
            this.configPage = {
                title: 'lead.title',
                description: 'lead.description',
                tableTitle: 'lead.table.title',
            };
        } else if (this.overlaysType === StreamMarketingToolsOverlaysTypes.COURSES) {
            this.configPage = {
                title: 'courses-overview.title',
                description: 'courses-overview.description',
                tableTitle: 'courses-overview.table.title',
            };
        }
    }
}

export interface IPageLeadsConfig {
    description: string;
    title: string;
    tableTitle: string;
}
