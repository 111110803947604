import {Component, Input, OnInit} from '@angular/core';
import {
    IFormConfig,
    IMultimediaHeaders,
    StreamService
} from '@src/app/services/stream-metadata/stream.service';
import {ActivatedRoute, Params} from '@angular/router';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import {LoadingService} from '@src/app/services/loading/loading.service';
import {switchMap, takeUntil, tap} from 'rxjs/operators';
import {IStreamPreviewVideoMetadata} from '@src/app/models/stream.model';
import {AbstractControl, Validators} from '@angular/forms';
import {IVideoInfo} from "@src/app/services/video-upload/video-upload.service";
import {CustomValidators} from '@src/app/utils/custom-validators.util';
import {BehaviorSubject, forkJoin, Observable} from "rxjs";
import {MEDIA_TYPE} from "@src/app/components/streamdust-player/constants/mediaType";
import { STREAM_STATUS } from '@src/app/components/streamdust-player/constants/status';

@Component({
    selector: 'app-stream-teaser',
    templateUrl: './stream-teaser.component.html',
    styleUrls: ['./stream-teaser.component.css']
})
export class StreamTeaserComponent implements OnInit {
    public STREAM_STATUS = STREAM_STATUS;
    public mediaType: MEDIA_TYPE;
    @Input() videoType = 'STREAM';

    textHeaders: IMultimediaHeaders = {
        mainHeader: 'teaser.title',
        description: 'teaser.description',
        subDescription: 'teaser.subDescription',
        actualResultText: 'teaser.latest'
    };

    streamId: string;

    directToUrlFormConfig: IFormConfig;
    previewFormConfig: IFormConfig;

    formsConfig: IFormConfig[];

    video: IStreamPreviewVideoMetadata = {
        views: null,
        clicks: null,
        videoId: null,
        advertisingUrl: null
    };

    isDataUploaded = false;
    isVideoActive = false;

    constructor(
        public streamService: StreamService,
        private readonly activatedRoute: ActivatedRoute,
        private readonly destroy$: AutoDestroyService,
        private loadingService: LoadingService
    ) {
    }

    ngOnInit(): void {
        this.formsConfig = this.streamService.getStreamPreviewTeaserFormsConfig(180);
        this.directToUrlFormConfig = this.formsConfig.find(form => form.name === 'advertisingUrl');
        this.previewFormConfig = this.formsConfig.find(form => form.name === 'preview');

        this.advertisingUrl.setValidators([Validators.required, CustomValidators.URL]);

        this.activatedRoute.parent.params.pipe(
            takeUntil(this.destroy$),
            tap(({id}: Params) => {
                if (!id) {
                    return;
                }
                this.streamId = id;
                this.mediaType = this.resolveMediaType();
            }),
            switchMap(({id}: Params) => {
                this.loadingService.regularLoadingStart();
                return this.streamService.getPreviewTeaser(id, this.mediaType);
            }))
            .subscribe((res) => {
                this.loadingService.regularLoadingEnd();
                this.isVideoActive = res.active;
                if (!res?.video?.url) {
                    return;
                }
                this.isDataUploaded = true;
                this.advertisingUrl.setValue(res.advertisingUrl, {emitEvent: false});
                this.video = {
                    views: res.views,
                    clicks: res.clicks,
                    videoUrl: 'https://' + res.video.url,
                    advertisingUrl: res.advertisingUrl,
                    videoId: res.video.id
                };
                this.preview.setValue(res.video.id);
            });
    }

    private resolveMediaType(): MEDIA_TYPE {
        return (this.activatedRoute.data as BehaviorSubject<any>)?.value?.mediaType
    }


    updateVideo(newVideo): void {
        this.video.videoUrl = newVideo;
    }

    save(): void {
        this.advertisingUrl.updateValueAndValidity();

        if (this.advertisingUrl.invalid || this.preview.invalid) {
            this.advertisingUrl.markAsTouched();
            this.preview.markAsTouched();
            return;
        }

        let body = {
            videoId: this.video.videoId || '',
            advertisingUrl: this.advertisingUrl.value
        };

        this.loadingService.regularLoadingStart();

        let teaserUpdateObservable = new Observable();

        if (body.videoId) {
            teaserUpdateObservable = this.streamService.uploadPreviewTeaser(this.streamId, body, this.mediaType);
        } else {
            teaserUpdateObservable = forkJoin({
                teaserDelete: this.streamService.deletePreviewTeaser(this.streamId),
                urlUpdate: this.streamService.uploadPreviewTeaser(this.streamId, body, this.mediaType)
            });
        }

        teaserUpdateObservable
            .pipe(takeUntil(this.destroy$))
            .subscribe((res: any) => {
                if (res?.video?.id) {
                    // this.toggleVideoActive(true);
                    this.video = {
                        views: res.views,
                        clicks: res.clicks,
                        videoUrl: 'https://' + res.video.url,
                        advertisingUrl: res.advertisingUrl,
                        videoId: res.video.id
                    };
                    this.isDataUploaded = true;
                }
                this.loadingService.regularLoadingEnd();
            });
    }

    uploadVideo(newVideo: IVideoInfo): void {
        this.video.videoUrl = newVideo.url;
        this.video.videoId = newVideo.id;
        this.preview.setValue(newVideo);
    }

    clearVideo(clearVideo): void {
        this.video = clearVideo;
        this.preview.setValue('');

    }

    get advertisingUrl(): AbstractControl {
        return this.directToUrlFormConfig.form.get('advertisingUrl')
    }

    get preview(): AbstractControl {
        return this.previewFormConfig.form.get('preview');
    }

    toggleVideoActive(isActive) {
        this.streamService.toggleTeaser({
            productId: this.streamId,
            videoType: this.mediaType,
            active: isActive
        })
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                this.isVideoActive = isActive;
            });
    }

}
