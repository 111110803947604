import {EventEmitter, Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class FailurePaymentService {
    public slotModalInitialized: EventEmitter<void> = new EventEmitter<void>();
    public slotsModalAction: EventEmitter<OpenCurrentSlotPayment> = new EventEmitter<OpenCurrentSlotPayment>();
    // tslint:disable-next-line:max-line-length
    public accessWindowAction: BehaviorSubject<OpenCurrentSlotPayment> = new BehaviorSubject<OpenCurrentSlotPayment>(null); // here we use behavior subject cause
    // tslint:disable-next-line:max-line-length
    public cardListAction: BehaviorSubject<('CARD' | 'PAYPAL' | 'SOFORT')> = new BehaviorSubject<('CARD' | 'PAYPAL' | 'SOFORT')>(null);  // we need to wait when components will render
}

export interface OpenCurrentSlotPayment {
    slotId: string;
    method: ('CARD' | 'PAYPAL' | 'SOFORT');
}
