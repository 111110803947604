import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {IMediaGroup} from '@src/app/models/stream-groups.model';
import {MediaListService} from '@src/app/services/media-list/media-list.service';
import {STREAM_STATUS} from '@src/app/components/streamdust-player/constants/status';
import {AutoDestroyService, CmsService, IPrice, MEDIA_FILTERS_FIELDS} from 'ui-elements';
import {IListQuery} from '@src/app/components/media-list/media-list.component';
import {pluck, take, takeUntil} from 'rxjs/operators';
import {Router} from '@angular/router';
import {MediaGroupsService} from '@src/app/services/media-groups/media-groups.service';
import {StreamService} from '@src/app/services/stream-metadata/stream.service';
import {GroupModalState} from '@src/app/state/group-modal-state';
import {PLAYER_MODE} from '@src/app/components/streamdust-player/constants/playerMode';
import {PaymentService} from '@src/app/services/payment/payment.service';
import {IUserAGB} from '@src/app/pages/user-profile/components/withdrawal-and-conditions/services/withdrawal-and-conditions.service';

@Component({
  selector: 'app-groups-modal',
  templateUrl: './groups-modal.component.html',
  styleUrls: ['./groups-modal.component.scss'],
  providers: [AutoDestroyService]
})
export class GroupsModalComponent implements OnInit, OnDestroy {
  @Input() termsConditions: IUserAGB;
  @Input() groups: IMediaGroup[];
  @Input() mediaId: string;
  @Input() mode: PLAYER_MODE;
  @Input() embedded: boolean;
  private query: IListQuery = {
    paging: {
      page: 0,
      itemsOnPage: 6
    },
    sort: [
      {
        order: 'DESC',
        field: 'DATE'
      }
    ],
    filters: []
  };
  public currentGroup: IMediaGroup;
  public isPayment: boolean;
  public loading: boolean;

  constructor(
      private router: Router,
      private cmsService: CmsService,
      private mediaListService: MediaListService,
      private mediaGroupsService: MediaGroupsService,
      private streamService: StreamService,
      public groupModalState: GroupModalState,
      private destroy$: AutoDestroyService,
      private paymentService: PaymentService
  ) { }

  ngOnInit(): void {
    this.initGroupsMediaItems();
  }

  private initGroupsMediaItems(): void {
    if (!this.groups?.length) {
      return;
    }

    const accessToken = this.streamService.viewerAccessToken;

    this.groups.forEach((mediaGroup) => {
      const filters: { filters: {field: MEDIA_FILTERS_FIELDS, value: any}[] } = {
        filters: [
          {
            field: MEDIA_FILTERS_FIELDS.GROUP_ID,
            value: mediaGroup.id
          },
          {
            field: MEDIA_FILTERS_FIELDS.STREAM_STATUS,
            value: [
              STREAM_STATUS.SCHEDULED,
              STREAM_STATUS.ACTIVATION,
              STREAM_STATUS.ACTIVATED,
              STREAM_STATUS.CONNECTED,
              STREAM_STATUS.PAUSED,
              STREAM_STATUS.LIVE,
              STREAM_STATUS.FINISHED,
            ],
          }
        ],
      };

      this.mediaListService.getMediaList({
        ...this.query,
        ...filters
      }).pipe(pluck('results', 'data', 'items'), take(1))
          .subscribe((res) => {
            mediaGroup.mediaItems = res;
            this.mediaGroupsService.checkAccessToGroup(mediaGroup.id, accessToken || '')
                .pipe(take(1))
                .subscribe((hasAccess) => mediaGroup.hasAccess = hasAccess);
          });
    });

    this.groupModalState.paymentStatus$.pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          if (!res) {
            return;
          }
          // this.loading = true;

          if (!res.status) {
            this.isPayment = true;
            // const failedInfo = this.paymentService.getTempErrorInfo();
            // if (failedInfo) {
            //   setTimeout(() => {
            //     this.paymentService.failedTransactionInfo.next(failedInfo);
            //     this.paymentService.removeTempErrorInfo();
            //     this.loading = false;
            //   }, 5000);
            // }
          }
        });
  }

  public onGroupSelect(group: IMediaGroup): void {
    this.currentGroup = group;
  }

  public closeCurrentGroup(): void {
    this.groupModalState.paymentStatus$.next(null);
    if (this.isPayment) {
      this.isPayment = false;
      return;
    }
    this.currentGroup = null;
  }

  public close(): void {
    this.groupModalState.setState(false);
    this.isPayment = false;
    this.closeCurrentGroup();
  }

  public onPaymentProceed(): void {
    this.isPayment = true;
  }

  public paymentSuccess(): void {
    this.isPayment = false;
    const accessToken = this.streamService.viewerAccessToken;
    this.mediaGroupsService.checkAccessToGroup(this.currentGroup.id, accessToken || '')
        .pipe(take(1))
        .subscribe((hasAccess) => {
          this.currentGroup.hasAccess = hasAccess;
          if (hasAccess) {
            this.mediaGroupsService.successfulPayment(this.currentGroup.id);
            this.close();
          }
        });
  }

  ngOnDestroy() {
    this.close();
  }

}
