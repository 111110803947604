<div class="wrapper-linked-streams" *ngIf="streamLinksService.isShowLinkedStreamsComponent">
    <div class="linked-streams-box">
        <i class="icon icon-close" (click)="onClose()"></i>
        <div class="linked-streams-header">
            <h2 class="title">{{'linkedStreams.title' | localize}}</h2>
        </div>
        <div class="linked-streams-body">
            <div class="linked-streams">
                <div class="linked-streams-nav" *ngIf="numbeOfSlides > 3">
                    <div class="linked-streams-prev" 
                            *ngIf="sliderArrow.leftArrow" 
                            (click)="moveLeft()">
                        <i class="icon icon-prev"></i>
                    </div>
                    <div class="linked-streams-next" 
                                *ngIf="sliderArrow.rightArrow"
                                (click)="moveRight()">
                        <i class="icon icon-next"></i>
                    </div>
                </div>
                <div class="row row-padding-5 flex-nowrap">
                    <drag-scroll #sliderComp class="wrapper-slider">
                        <div drag-scroll-item class="col-4 item-wrap" 
                            *ngFor="let media of linkedMedia"
                            (click)="goToLinks(media.linkedMedia.video)">
                            <div class="item bg-item" [ngStyle]="{'background-image': getBackground(media.linkedMedia.video)}">
                                <div class="streams-item-header">
                                    <span class="status live" *ngIf="media.linkedMedia?.video.status?.stream === STREAM_STATUS.LIVE">
                                        <i class="icon-dot-5"></i>
                                        Live
                                    </span>
                                    <h3 class="title">{{media.name}}</h3>
                                    <p>{{media.linkedMedia.video.description | croppedText:120}}</p>
                                </div>
                                <div class="stream-item-footer">
                                    <span class="text">
                                        {{media.linkedMedia?.video.startTimestamp | dateFormat: 'DD MMM YYYY, HH:mm'}} | 
                                        {{media.linkedMedia?.video.genre  | keyToValue: genres}} | 
                                        {{ getCurrency(media) ? (getCurrency(media) | currency) : 'Free'}}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </drag-scroll>
                </div>
            </div>
        </div>
    </div>
</div>