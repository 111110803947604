import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import {ITableConfig, TableComponent} from 'ui-elements';
import {IRevenue, REVENUE_STATUS} from '@src/app/pages/revenues-page/model/revenue.model';
import {ITimerange} from '@src/app/models/stream.model';
import {RevenuesService} from '@src/app/services/revenues/revenues.service';
import {ITimeRangeConfig} from 'ui-elements';
import {takeUntil} from 'rxjs/operators';

@Component({
    selector: 'app-revenues-page',
    templateUrl: './revenues-page.component.html',
    styleUrls: ['./revenues-page.component.sass'],
    providers: [AutoDestroyService, RevenuesService]
})
export class RevenuesPageComponent implements OnInit {
    @ViewChild('idTmpl', {static: true}) public idTmpl: TemplateRef<any>;
    @ViewChild('statusTmpl', {static: true}) public statusTmpl: TemplateRef<any>;
    @ViewChild('titleTmpl', {static: true}) public titleTmpl: TemplateRef<any>;
    @ViewChild('priceTmpl', {static: true}) public priceTmpl: TemplateRef<any>;
    @ViewChild('soldAmountTmpl', {static: true}) public soldAmountTmpl: TemplateRef<any>;
    @ViewChild('openPayoutTmpl', {static: true}) public openPayoutTmpl: TemplateRef<any>;
    @ViewChild('dateTmpl', {static: true}) public dateTmpl: TemplateRef<any>;
    @ViewChild('transactionTmpl', {static: true}) public transactionTmpl: TemplateRef<any>;
    @ViewChild('actionsTmpl', {static: true}) public actionsTmpl: TemplateRef<any>;
    @ViewChild('typeTmpl', {static: true}) public typeTmpl: TemplateRef<any>;
    @ViewChild('table', {static: true}) public table: TableComponent;
    public REVENUE_STATUS = REVENUE_STATUS;
    public tableConfig: ITableConfig<IRevenue>;
    public searchQuery: string;
    public timeRange: ITimerange;
    public timeRangeConfig: ITimeRangeConfig;

    constructor(
        private revenuesService: RevenuesService,
        private destroy$: AutoDestroyService,
    ) {
    }

    ngOnInit(): void {
        this.timeRangeConfig = {
            fetchMethod: (filters) => this.revenuesService.getTimeRanges(),
            filters: null,
            withoutFilters: true,
            withoutAll: true
        };

        this.initTableConfig();
    }

    private initTableConfig(): void {
        this.tableConfig = {
            filtersWidth100: true,
            customScroll: true,
            matPaginator: true,
            dataField: 'data',
            searchFn: (sortParams, pagingParams) => {
                return this.revenuesService.getRevenues(this.searchQuery, pagingParams, sortParams, this.timeRange)
                    .pipe(takeUntil(this.destroy$));
            },
            columns: [
                {
                    name: 'table.status',
                    tmpl: this.statusTmpl,
                    sortField: 'PAYOUT_STATUS',
                    class: 'center'
                },
                {
                    name: 'table.title',
                    tmpl: this.titleTmpl,
                    sortField: 'TITLE',
                    class: 'left'
                },
                {
                    name: 'table.type',
                    tmpl: this.typeTmpl,
                    class: 'left'
                },
                {
                    name: 'table.price',
                    tmpl: this.priceTmpl,
                    class: 'right',
                    sortField: 'PRICE',
                },
                {
                    name: 'table.sold',
                    tmpl: this.soldAmountTmpl,
                    class: 'right',
                    sortField: 'SOLD_AMOUNT'
                },
                {
                    name: 'table.payout',
                    tmpl: this.openPayoutTmpl,
                    class: 'right',
                    sortField: 'INCOME_AMOUNT'
                },
                {
                    name: 'table.payDate',
                    tmpl: this.dateTmpl,
                    class: 'left'
                },
                {
                    name: 'table.creditNumber',
                    tmpl: this.transactionTmpl,
                    class: 'right'
                },
                {
                    name: 'table.actions',
                    tmpl: this.actionsTmpl,
                    class: 'center'
                }
            ]

        };
    }

    public timeRangeFilter(event): void {
        this.timeRange = event;
        this.table.refreshData({});
    }

    public search(event): void {
        this.searchQuery = event;
        this.table.refreshData({});
    }

}
