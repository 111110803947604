<div class="modal">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <div class="title-box">
                    <h4 class="title">Gutscheine erstellen</h4>
                </div>
                <a aria-hidden="true" (click)="close()" class="close"><span>×</span></a>

            </div>
            <div class="modal-body">
                <p>
                    Bitte gebe die Anzahl zu erstellender Gutschein ein.
                </p>
                <form [formGroup]="form" (ngSubmit)="submit()">
                    <input formControlName="amount" type="number" class="form-control"
                           placeholder="Anzahl zu erstellender Gutscheine eingeben" required min="1" max="50000">
                    <div align="center"><input type="submit" class="btn btn-color" value="Anlegen"></div>
                </form>


            </div>
            <div class="modal-footer">
                <button (click)="close()" class="btn btn-default" type="button">
                    Close
                </button>
            </div>
        </div>
    </div>
</div>
