import {Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ITableConfig, ITableNoDataLink, TableComponent} from 'ui-elements';
import {IPresentationList, PresentationService} from '@src/app/services/file/presentation.service';
import {IFormConfig, StreamService} from '@src/app/services/stream-metadata/stream.service';
import {takeUntil} from 'rxjs/operators';
import {FormBuilder} from '@angular/forms';
import {LoadingService} from '@src/app/services/loading/loading.service';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import { STREAM_STATUS } from '@src/app/components/streamdust-player/constants/status';
import { MEDIA_TYPE } from '@src/app/components/streamdust-player/constants/mediaType';
import {Router} from '@angular/router';

@Component({
  selector: 'app-presentations-table',
  templateUrl: './presentations-table.component.html',
  styleUrls: ['./presentations-table.component.scss'],
  providers: [AutoDestroyService]
})
export class PresentationsTableComponent implements OnInit {
  public STREAM_STATUS = STREAM_STATUS;
  public MEDIA_TYPE = MEDIA_TYPE;
  @Input() mediaId: string;
  @Input() noDataLink: ITableNoDataLink;
  @ViewChild('tableComponent', {static: false}) public table: TableComponent;
  @ViewChild('statusTmpl', {static: true}) public statusTmpl: TemplateRef<any>;
  @ViewChild('dateTmplCourses', {static: true}) public dateTmplCourses: TemplateRef<any>;
  tableConfig: ITableConfig<IPresentationList>;
  formsConfig: IFormConfig[];
  activateForm = this.fb.group({
    active: [false]
  });
  searchQuery: string;

  constructor(
      public streamService: StreamService,
      public fb: FormBuilder,
      public presentationService: PresentationService,
      public loadingService: LoadingService,
      private readonly destroy$: AutoDestroyService,
      private router: Router
  ) { }

  ngOnInit(): void {
    this.initTableConfig();
  }

  private initTableConfig() {
    this.tableConfig = {
      dataField: 'data',
      noDataLink: this.noDataLink,
      searchFn: (sortParams, pagingParams) => {
        return this.presentationService.getList(this.mediaId, this.searchQuery, pagingParams, sortParams, true);
      },
      columns: [
        {
          name: 'overlays.table.status',
          sortField: 'ACTIVE',
          tmpl: this.statusTmpl,
          width: 70,
          class: 'center'
        },
        {
          name: 'presentation.table.field.fileName',
          sortField: 'FILE_NAME',
          dataField: 'fileName',
          width: 100,
          class: 'left'
        }, {
          name: 'presentation.table.field.upload',
          sortField: 'FILE_UPLOAD_DATE',
          dataField: 'fileUploadDate',
          width: 100,
          tmpl: this.dateTmplCourses,
          class: 'left'
        }, {
          name: 'presentation.table.field.showClickAmount',
          sortField: 'SHOW_CLICKS',
          dataField: 'showClickAmount',
          width: 100,
          class: 'right',
        }, {
          name: 'presentation.table.field.downloads',
          sortField: 'DOWNLOAD_CLICKS',
          dataField: 'fileDownloadAmount',
          width: 100,
          class: 'right',
        }, {
          name: 'presentation.table.field.link',
          sortField: 'LINK_CLICKS',
          dataField: 'linkClickAmount',
          width: 100,
          class: 'right',
        }
      ]
    };
  }

  onToggle($event: any, data) {
    this.toggleActive($event.value, data);
    this.activateForm.get('active').setValue($event.value);
  }

  private toggleActive(active, model) {
    const payload = {
      presentationId: model.id,
      active: active,
    };
    this.loadingService.loadingStart();
    this.presentationService.active(payload)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          this.loadingService.loadingEnd();
        }, () => this.loadingService.loadingEnd());
  }

  public search(event: string): void {
    this.searchQuery = event;
    this.table.refreshData({});
  }

  public goToNoDataUrl(url: string): void {
    this.router.navigate([url]);
  }

  public refreshTable(): void {
    this.table.refreshData({});
  }

}
