<div class="content">
    <div class="panel panel-md">
        <div class="panel-header page-title-panel">
            <h2 class="title-lg"><b>{{'subscription-manage.title' | localize}}</b></h2>
        </div>

        <div class="card">
            <div class="card-header">
                <h4 class="mb-0"><b>{{'subscription-manage.table.title' | localize}}</b></h4>
            </div>

            <div class="card-body">
                <ano-table #subscriptionsTable [tableConfig]="tableConfig"></ano-table>
            </div>
        </div>

        <div *ngIf="subscriptionTariffPlans" class="panel panel-md mb-30 plan-period-select-wrapper">
            <div class="card-body pl-0 pr-0 subscription-plans-wrapper">
                <app-plan-cards-list *ngIf="!payment"
                                     [isAuthorized]="true"
                                     [tariffPlans]="subscriptionTariffPlans"
                                     [config]="tariffPlansConfig"
                                     (actionButtonPressed$)="buySubscription($event)">
                </app-plan-cards-list>
            </div>
        </div>

        <div *ngIf="subscriptionTariffPlans" class="panel panel-md mb-20">
            <div class="panel-header text-center">
                <button class="btn btn-primary mw-180" (click)="openSubscriptionTariffsComparisonDialog()">
                    {{'subscription-manage.buttons.view-subscription-details' | localize}}
                </button>
            </div>
        </div>

        <div class="card mb-40">
            <div class="card-header">
                <p class="text-lg text-primary mb-30">
                    {{'subscription-manage.enterprise.title' | localize}}
                    <br/><strong>{{'subscription-manage.enterprise.sub-title' | localize}}</strong>
                </p>
                <div class="special-list">
                    <div class="special-item">
                        <img src="assets/img/ico/si1.svg" class="special-icon special-icon-40">
                        <p>
                            <strong class="text-primary">{{'subscription-manage.enterprise.benefit-1.title' | localize}}</strong>
                            {{'subscription-manage.enterprise.benefit-1.body' | localize}}
                        </p>
                    </div>
                    <div class="special-item">
                        <img src="assets/img/ico/si2.svg" class="special-icon">
                        <p>
                            <strong class="text-primary">{{'subscription-manage.enterprise.benefit-2.title' | localize}}</strong>
                            {{'subscription-manage.enterprise.benefit-2.body' | localize}}
                        </p>
                    </div>
                    <div class="special-item">
                        <img src="assets/img/ico/si3.svg" class="special-icon special-icon-40">
                        <p>
                            <strong class="text-primary">{{'subscription-manage.enterprise.benefit-3.title' | localize}}</strong>
                            {{'subscription-manage.enterprise.benefit-3.body' | localize}}
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="panel">
            <div class="text-center">
                <a class="btn btn-primary mw-200" href="mailto:info@streamdust.tv" target="_blank">
                    {{'subscription-manage.buttons.request-enterprise' | localize}}
                </a>
            </div>
        </div>
    </div>
</div>

<ng-template #actionsTmpl let-subscription="rowData">
    <span *ngIf="subscription.cancelable" class="table-link text-danger" (click)="confirmSubscriptionCancel(subscription)">
        <i class="icon-cross"></i>{{'subscription-manage.table.cancel-button' | localize}}
    </span>
    <span *ngIf="!subscription.cancelable" class="table-link text-primary" (click)="confirmSubscriptionRenew(subscription)">
        <i class="icon-rotate-left"></i>{{'subscription-manage.table.renew-button' | localize}}
    </span>
</ng-template>

<ng-template #subscriptionPaymentTmpl let-rowData="rowData">
    {{rowData?.price?.amount}} {{rowData?.price?.currency}}/{{rowData?.period}}
</ng-template>

<ng-template #dateTmpl let-rowData="rowData">
    {{rowData.expireDate | dateFormat: 'DD MMM YYYY'}}
</ng-template>

<ng-template #tariffPlanTmpl let-rowData="rowData">
    <ng-container *ngIf="rowData.type === 'BANDWIDTH'">
        {{ rowData.tariffPlan }} {{rowData?.bandwidth ? '(' + rowData.bandwidth?.dataSize?.value + ' ' + rowData.bandwidth?.dataSize?.unit + ')' : ''}}
    </ng-container>
    <ng-container *ngIf="rowData.type === 'STORAGE_SPACE'">
        {{ rowData.tariffPlan }} {{rowData?.storageSpace ? '(' + rowData.storageSpace?.dataSize?.value + ' ' + rowData.storageSpace?.dataSize?.unit + ')' : ''}}
    </ng-container>
    <ng-container *ngIf="rowData.type === 'SUBSCRIPTION'">
        {{ rowData.tariffPlan }}
    </ng-container>
</ng-template>

<ng-template #nextPaymentTmpl let-rowData="rowData">
    <span *ngIf="rowData.cancelable">
        {{rowData.nextPaymentDate | dateFormat: 'DD MMM YYYY'}}
    </span>
    <span *ngIf="!rowData.cancelable">
         -
    </span>
</ng-template>

<ng-template #confirm let-dialogRef="dialogRef">
    <ano-modal [dialogRef]="dialogRef" [title]="(confirmTextTitle  | localize)"
               [isConfirmation]="true">
        {{confirmTextBody | localize}}
    </ano-modal>
</ng-template>
