import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MediaPreviewPageComponent } from './media-preview-page.component';
import {SharedModule} from '@src/app/modules/shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [
    MediaPreviewPageComponent
  ],
  declarations: [MediaPreviewPageComponent],
})
export class MediaPreviewPageModule { }
