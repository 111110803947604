import {Component, OnChanges, OnInit} from '@angular/core';
import {Chart, ChartType} from 'chart.js';
import {COLORS} from '@src/app/utils/COLORS';
import {ChartComponent} from '@src/app/components/bar-chart/bar-chart.component';

export interface IChartItem {
    name: string;
    value: number;
}

@Component({
    selector: 'app-pie-chart',
    templateUrl: './pie-chart.component.html',
    styleUrls: ['./pie-chart.component.css']
})
export class PieChartComponent extends ChartComponent implements OnInit, OnChanges {

    public type: ChartType = 'pie';
    public pieChartStatus = {isEmpty: true};
    public totalProfiles = 0;


    constructor() {
        super();
    }

    ngOnChanges() {
        if (this.datasets) {
            this.pieChartStatus.isEmpty = this.checkIsEmpty();
            this.totalProfiles = this.getOfTotalProfiles();
            if(this.pieChartStatus.isEmpty) {return;}
            this.generateConfig();
        }
    }

    protected generateConfig(): void {
        this.data = {
            labels: this.datasets.map(i => i.name),
            datasets: [
                {
                    data: this.datasets.map(i => i.value),
                    backgroundColor: COLORS
                },
            ],
        };
        this.options = {
            legend: {
                position: 'right',
                display: true,
                labels: {
                    fontSize: 10
                }
            },
            tooltips: {
                bodyFontSize: 10
            },
            // animation: {
            //     duration: 0,
            //     onComplete: function () {
            //         const chartInstance = this.chart,
            //             ctx = chartInstance.ctx;
            //
            //         ctx.font = Chart.helpers.fontString(
            //             10,
            //             Chart.defaults.global.defaultFontStyle,
            //             'Roboto, sans-serif;'
            //         );
            //         ctx.textAlign = 'right';
            //         ctx.textBaseline = 'right';
            //
            //         this.data.datasets.forEach(function (dataset, i) {
            //             const meta = chartInstance.controller.getDatasetMeta(i);
            //             meta.data.forEach(function (bar, index) {
            //                 const data = dataset.data[index];
            //                 ctx.fillText(data, bar._model.x + 15, bar._model.y + 4);
            //             });
            //         });
            //     }
            // },
        };

    }

    ngOnInit() {

    }

    private checkIsEmpty() {
        let isEmpty = true;
        const fountNotEmpty = this.datasets.find(item => item.value !== 0);
        if(fountNotEmpty) {
            isEmpty = false;
        }
        return isEmpty;
    }

    private getOfTotalProfiles() {
        let total = 0;
        this.datasets.forEach(data => {
            total += data.value;
        });
        return total;
    }
}
