import { Injectable } from '@angular/core';
import {ApiService} from "ui-elements";
import {Observable} from "rxjs";
import {IResponse} from "@src/app/models/response.model";
import {API_URLS_APP, UrlGenerator} from "@src/app/constants/api-urls.constant";

@Injectable({
  providedIn: 'root'
})
export class OverviewPageService {

  constructor(
      private apiService: ApiService
  ) {}

  public playStream(mediaId: string): Observable<IResponse<any>> {
    return this.apiService.put(UrlGenerator.generate(API_URLS_APP.PAUSE_STREAM, {mediaId}), {pause: false});
  }

  public pauseStream(mediaId: string): Observable<IResponse<any>> {
    return this.apiService.put(UrlGenerator.generate(API_URLS_APP.PAUSE_STREAM, {mediaId}), {pause: true});
  }
}
