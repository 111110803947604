import {Component} from '@angular/core';
import {AbstractControl, FormBuilder, Validators} from '@angular/forms';
import {IInputConfig, INPUT_TYPES} from 'ui-elements';
import {IUnitKeyValue} from 'ui-elements'

@Component({
    selector: 'app-bitrate-calculator',
    templateUrl: './bitrate-calculator.component.html',
    styleUrls: ['./bitrate-calculator.component.sass']
})
export class BitrateCalculatorComponent {

    bitrateForm = this.formBuilder.group({
        bitrate: [0, Validators.required],
        numberOfViews: [null, Validators.required],
        mediaLength: [null, Validators.required]
    });

    INPUT_TYPES = INPUT_TYPES;

    estimatedBandwidthInGB = '0.000';
    estimatedBandwidthInTB = '0.000';

    bitrateSelectInputConfig: IInputConfig = {
        inputType: INPUT_TYPES.SELECT,
        selectOptions: this.getBitrateSelectOptions(),
        placeholder: '-',
    };
    bitrateNumberInputConfig: IInputConfig = {
        inputType: INPUT_TYPES.INPUT,
        mask: '000000',
        min: 0
    };

    constructor(
        private formBuilder: FormBuilder
    ) {
    }

    getBitrateSelectOptions(): IUnitKeyValue[] {
        const bitrateValues = [];

        for (let i = 0; i < 21; i++) {
            bitrateValues.push(i);
        }

        return bitrateValues.map(bitrateValue => ({
            key: bitrateValue,
            value: `${bitrateValue} Mbps`
        }));
    }

    calculateBandwidth(): void {
        this.bitrateForm.markAllAsTouched();

        if (this.bitrateForm.invalid) {
            return;
        }

        const bandwidthAsNumber = this.mediaLength.value * this.numberOfViews.value * (this.bitrate.value * 1000) * 60 / 8 / 1024 / 1024;
        this.estimatedBandwidthInGB = bandwidthAsNumber.toFixed(3);
        this.estimatedBandwidthInTB = (bandwidthAsNumber / 1024).toFixed(3);
    }

    get bitrate(): AbstractControl {
        return this.bitrateForm.get('bitrate');
    }

    get numberOfViews(): AbstractControl {
        return this.bitrateForm.get('numberOfViews');
    }

    get mediaLength(): AbstractControl {
        return this.bitrateForm.get('mediaLength');
    }
}
