import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {EmbeddedStreamPageComponent} from '@src/app/pages/embedded-stream-page/embedded-stream-page.component';
import {SharedModule} from '@src/app/modules/shared/shared.module';



@NgModule({
  declarations: [EmbeddedStreamPageComponent],
    imports: [
        CommonModule,
        SharedModule
    ]
})
export class EmbeddedStreamPageModule { }
