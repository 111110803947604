<table class="table table-striped layout-fixed">
    <thead class="align-middle without-background text-lg">
    <tr>
        <th class="table-col-2">
            <div class="subscription-tariffs-comparison-currency blue-theme-filter">
                <form [formGroup]="currencyForm" *ngIf="!this.authService.isAuthorized">
                    <ano-input formControlName="currency"
                               [isEditMode]="true"
                               [config]="{
                                                    inputType: INPUT_TYPES.SELECT,
                                                    placeholder: '',
                                                    selectOptions: currencySelectOptions
                                                }"
                    ></ano-input>
                </form>
            </div>
        </th>
        <th class="table-col-2">{{'landing-page.streamdust-subscription.Free' | localize}}</th>
        <th class="table-col-2">{{'landing-page.streamdust-subscription.Beginner' | localize}}</th>
        <th class="table-col-2">{{'landing-page.streamdust-subscription.Advanced' | localize}}</th>
        <th class="table-col-2">{{'landing-page.streamdust-subscription.Professional' | localize}}</th>
        <th class="table-col-2">{{'landing-page.streamdust-subscription.Enterprise' | localize}}</th>
    </tr>
    </thead>

    <tbody class="our-offers-title">
    <tr class="table-title-tr">
        <td colspan="6">
            <div class="table-title-box" [class.active]="displayOurOffers"
                 (click)="displayOurOffers = !displayOurOffers">
                <a href="javascript:void(0)">
                                            <span class="flex-block">
                                                <i class="icon-table_arrow"></i>
                                                <span class="text-block">
                                                    Unsere
                                                    <br/>Angebote
                                                </span>
                                            </span>
                </a>
            </div>
        </td>
    </tr>
    </tbody>
    <tbody class="our-offers data-table-body"
           [ngStyle]="{'display' : displayOurOffers ? 'table-row-group' : 'none'}">
    <tr>
        <th>Monatliche Kosten</th>
        <td>
            <b>{{monthlyChargesPricesToShow?.monthly[selectedCurrency][0]}} {{selectedCurrency}}</b>
            <br/>pro Monat
        </td>
        <td>
            <b>{{monthlyChargesPricesToShow?.monthly[selectedCurrency][1]}} {{selectedCurrency}}</b>
            <br/>pro Monat,
            <ng-container *ngIf="currentTariffPeriod === TARIFF_PLAN_PERIOD.MONTH">monatlich
                <br/>kündbar
            </ng-container>
            <ng-container *ngIf="currentTariffPeriod === TARIFF_PLAN_PERIOD.YEAR">
                <br/>einmalige zahlung
                <br/><b>von {{monthlyChargesPricesToShow?.yearly[selectedCurrency][1]}} {{selectedCurrency}} jährlich</b>
            </ng-container>
        </td>
        <td>
            <b>{{monthlyChargesPricesToShow?.monthly[selectedCurrency][2]}} {{selectedCurrency}}</b>
            <br/>pro Monat,
            <ng-container *ngIf="currentTariffPeriod === TARIFF_PLAN_PERIOD.MONTH">monatlich
                <br/>kündbar
            </ng-container>
            <ng-container *ngIf="currentTariffPeriod === TARIFF_PLAN_PERIOD.YEAR">
                <br/>einmalige zahlung
                <br/><b>von {{monthlyChargesPricesToShow?.yearly[selectedCurrency][2]}} {{selectedCurrency}} jährlich</b>
            </ng-container>
        </td>
        <td>
            <b>{{monthlyChargesPricesToShow?.monthly[selectedCurrency][3]}} {{selectedCurrency}}</b>
            <br/>pro Monat,
            <ng-container *ngIf="currentTariffPeriod === TARIFF_PLAN_PERIOD.MONTH">monatlich
                <br/>kündbar
            </ng-container>
            <ng-container *ngIf="currentTariffPeriod === TARIFF_PLAN_PERIOD.YEAR">
                <br/>einmalige zahlung
                <br/><b>von {{monthlyChargesPricesToShow?.yearly[selectedCurrency][3]}} {{selectedCurrency}} jährlich</b>
            </ng-container>
        </td>
        <td>
            Sie suchen eine
            <br/>spezielle
            <br/>Unternehmenslösung?
        </td>
    </tr>
    <tr>
        <th>Bandbreite</th>
        <td>
            <b>bis 8 Mbps</b>
        </td>
        <td>
            <b>bis 8 Mbps</b>
        </td>
        <td>
            <b>bis 8 Mbps</b>
        </td>
        <td>
            <b>bis 8 Mbps</b>
        </td>
        <td>
            <b>Sie benötigen
                <br/>mehr Bandbreite?</b>
        </td>
    </tr>
    <tr>
        <th>
            Multibitrate +
            <br/>Redundant Pipeline +
            <br/>Encoding
        </th>
        <td>
            <b>{{streamProcessingPrices[selectedCurrency][0]}} {{selectedCurrency}} pro Stunde</b>
        </td>
        <td>
            <b>{{streamProcessingPrices[selectedCurrency][0]}} {{selectedCurrency}} pro Stunde</b>
        </td>
        <td>
            <b>{{streamProcessingPrices[selectedCurrency][0]}} {{selectedCurrency}} pro Stunde</b>
        </td>
        <td>
            <b>{{streamProcessingPrices[selectedCurrency][0]}} {{selectedCurrency}} pro Stunde</b>
        </td>
        <td>

        </td>
    </tr>
    <tr>
        <th>
            Bandbreiten-
            <br/>verbrauch pro Monat
            <br/>für kostenlose
            <br/>Livestreams
        </th>
        <td>
            <b>0 TB pro Monat</b>
        </td>
        <td>
            <b>1 TB pro Monat</b>
        </td>
        <td>
            <b>3 TB pro Monat</b>
        </td>
        <td>
            <b>5 TB pro Monat</b>
        </td>
        <td>
            <b>
                Unbegrenzte
                <br/>Streaming-
                <br/>Bandbreite?
            </b>
        </td>
    </tr>
    <tr>
        <th>
            Speicherplatz
        </th>
        <td>
            <b>1 GB frei pro Jahr</b>
        </td>
        <td>
            <b>150 GB frei pro Jahr</b>
        </td>
        <td>
            <b>250 GB frei pro Jahr</b>
        </td>
        <td>
            <b>500 GB frei pro Jahr</b>
        </td>
        <td>

        </td>
    </tr>
    <tr>
        <th>
            Livestreams kostenlos
            <br/>für Zuschauer/innen
        </th>
        <td>
            <i class="icon-cross status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
    </tr>
    <tr>
        <th>
            Pay-per-View-
            <br/>Livestreaming
        </th>
        <td>
            Unbegrentze Anzahl
            <br/>an Zuschauern
        </td>
        <td>
            Unbegrentze Anzahl
            <br/>an Zuschauern
        </td>
        <td>
            Unbegrentze Anzahl
            <br/>an Zuschauern
        </td>
        <td>
            Unbegrentze Anzahl
            <br/>an Zuschauern
        </td>
        <td>
            Unbegrentze Anzahl
            <br/>an Zuschauern
        </td>
    </tr>
    <tr>
        <th>
            Bearbeitungsgebühr
            Pay-per-View-Lives-
            <br/>treaming
        </th>
        <td>
            <b>
                {{payPerViewPrices[selectedCurrency][0]}} {{selectedCurrency}} plus 10 %
                <br/>vom Bruttoticketpreis
            </b>
        </td>
        <td>
            <b>
                {{payPerViewPrices[selectedCurrency][1]}} {{selectedCurrency}} plus 9 %
                <br/>vom Bruttoticketpreis
            </b>
        </td>
        <td>
            <b>
                {{payPerViewPrices[selectedCurrency][2]}} {{selectedCurrency}} plus 8 %
                <br/>vom Bruttoticketpreis
            </b>
        </td>
        <td>
            <b>
                {{payPerViewPrices[selectedCurrency][3]}} {{selectedCurrency}} plus 7,5 %
                <br/>vom Bruttoticketpreis
            </b>
        </td>
        <td>
            <b>Minimale Gebühren</b>
        </td>
    </tr>
    <tr>
        <th>
            Video kostenlos
            <br/>für Zuschauer/innen
        </th>
        <td>
            <i class="icon-cross status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
    </tr>
    <tr>
        <th>
            Bearbeitungsgebühr
            <br/>Pay-per-View-Video
        </th>
        <td>
            <b>
                {{payPerViewPrices[selectedCurrency][0]}} {{selectedCurrency}} plus 10 %
                <br/>vom Bruttoticketpreis
            </b>
        </td>
        <td>
            <b>
                {{payPerViewPrices[selectedCurrency][1]}} {{selectedCurrency}} plus 9 %
                <br/>vom Bruttoticketpreis
            </b>
        </td>
        <td>
            <b>
                {{payPerViewPrices[selectedCurrency][2]}} {{selectedCurrency}} plus 8 %
                <br/>vom Bruttoticketpreis
            </b>
        </td>
        <td>
            <b>
                {{payPerViewPrices[selectedCurrency][3]}} {{selectedCurrency}} plus 7,5 %
                <br/>vom Bruttoticketpreis
            </b>
        </td>
        <td>
            <b>Minimale Gebühren</b>
        </td>
    </tr>
    <tr>
        <th>
            Transaktionskosten
            <br/><span class="info-block">(Kosten für die Zahlungsabwicklung von Livestreams und Videos: MasterCard, VisaCard, Sofortüberweisung)</span>
        </th>
        <td>
            {{transactionPrices[selectedCurrency][0]}} {{selectedCurrency}} plus 2,6% vom Bruttoticketpreis (MasterCard,
            VisaCard)
        </td>
        <td>
            {{transactionPrices[selectedCurrency][0]}} {{selectedCurrency}} plus 2,6% vom Bruttoticketpreis (MasterCard,
            VisaCard)
        </td>
        <td>
            {{transactionPrices[selectedCurrency][0]}} {{selectedCurrency}} plus 2,6% vom Bruttoticketpreis (MasterCard,
            VisaCard)
        </td>
        <td>
            {{transactionPrices[selectedCurrency][0]}} {{selectedCurrency}} plus 2,6% vom Bruttoticketpreis (MasterCard,
            VisaCard)
        </td>
        <td>
            Weitere Zahlungs-
            <br/>möglichkeiten?
        </td>
    </tr>
    <tr>
        <th>
            <a href="">PayPal nur gegen
                <br/>Aufpreis</a>
        </th>
        <th>
            <a href="">Bitte kontaktieren
                <br/>Sie uns</a>
        </th>
        <th>
            <a href="">Bitte kontaktieren
                <br/>Sie uns</a>
        </th>
        <th>
            <a href="">Bitte kontaktieren
                <br/>Sie uns</a>
        </th>
        <th>
            <a href="">Bitte kontaktieren
                <br/>Sie uns</a>
        </th>
        <th>
            <a href="">Bitte kontaktieren
                <br/>Sie uns</a>
        </th>
    </tr>
    </tbody>
    <tbody class="top-possible-combinations-title">
    <tr class="table-title-tr">
        <td colspan="6">
            <div class="table-title-box" [class.active]="displayTopPossibleCombinations"
                 (click)="displayTopPossibleCombinations = !displayTopPossibleCombinations">
                <a href="javascript:void(0)">
                                        <span class="flex-block">
                                            <i class="icon-table_arrow"></i>
                                            <span class="text-block">
                                            Top-
                                            <br/>Kombinations-
                                            <br/>möglichkeiten
                                        </span>
                                        </span>
                </a>
            </div>
        </td>
    </tr>
    </tbody>
    <tbody class="top-possible-combinations data-table-body"
           [ngStyle]="{'display' : displayTopPossibleCombinations ? 'table-row-group' : 'none'}">
    <tr>
        <th>
            Livestream-
            <br/>Programm/Slots
        </th>
        <td>
            <i class="icon-cross status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
    </tr>
    <tr>
        <th>
            Gruppierungen
            <br/>von Livestreams
        </th>
        <td>
            <i class="icon-cross status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
    </tr>
    <tr>
        <th>
            Gruppierungen
            <br/>von Videos
        </th>
        <td>
            <i class="icon-cross status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
    </tr>
    <tr>
        <th>
            Online-Kurse
        </th>
        <td>
            <i class="icon-cross status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
    </tr>
    <tr>
        <th>
            Spenden-Button
        </th>
        <td>
            <i class="icon-cross status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
    </tr>
    <tr>
        <th>
            Bearbeitungsgebühr <br>+ Transaktionskosten
        </th>
        <td>
            <i class="icon-cross status-element text-primary"></i>
        </td>
        <td>
            {{feeAndTransactionPrices[selectedCurrency][0][0]}} {{selectedCurrency}} plus 9%
            <br/> vom Bruttoticketpreis
            <br/> sowie {{feeAndTransactionPrices[selectedCurrency][0][1]}} {{selectedCurrency}} plus
            <br/> 2.6% vom Bruttoticket-
            <br/> preis (MasterCard,
            VisaCard)*
<!--            Transaktionskosten-->
<!--            <br/>plus 9 % Provision vom-->
<!--            <br/>Bruttoticketpreis-->
        </td>
        <td>
            {{feeAndTransactionPrices[selectedCurrency][1][0]}} {{selectedCurrency}} plus 8%
            <br/> vom Bruttoticketpreis
            <br/> sowie {{feeAndTransactionPrices[selectedCurrency][1][1]}} {{selectedCurrency}} plus
            <br/> 2.6% vom Bruttoticket-
            <br/> preis (MasterCard,
            VisaCard)*
<!--            Transaktionskosten-->
<!--            <br/>plus 8 % Provision vom-->
<!--            <br/>Bruttoticketpreis-->
        </td>
        <td>
            {{feeAndTransactionPrices[selectedCurrency][2][0]}} {{selectedCurrency}} plus 7.5%
            <br/> vom Bruttoticketpreis
            <br/> sowie {{feeAndTransactionPrices[selectedCurrency][2][1]}} {{selectedCurrency}} plus
            <br/> 2.6% vom Bruttoticket-
            <br/> preis (MasterCard,
            VisaCard)*
<!--            Transaktionskosten-->
<!--            <br/>plus 6 % Provision vom-->
<!--            <br/>Bruttoticketpreis-->
        </td>
        <td>
            Minimale Gebühren
<!--            <br/>gebühren-->
        </td>
    </tr>
    </tbody>
    <tbody class="marketing-tools-title">
    <tr class="table-title-tr">
        <td colspan="6">
            <div class="table-title-box" [class.active]="displayMarketingTools"
                 (click)="displayMarketingTools = !displayMarketingTools">
                <a href="javascript:void(0)">
                                    <span class="flex-block">
                                        <i class="icon-table_arrow"></i>
                                        <span class="text-block">
                                        Marketing-
                                        <br/>Tools
                                    </span>
                                    </span>
                </a>
            </div>
        </td>
    </tr>
    </tbody>
    <tbody class="marketing-tools data-table-body"
           [ngStyle]="{'display' : displayMarketingTools ? 'table-row-group' : 'none'}">
    <tr>
        <th>
            Rabattcodes
            <br/>Livestreaming
        </th>
        <td>
            <i class="icon-cross status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
    </tr>
    <tr>
        <th>
            Rabattcodes
            <br/>Video
        </th>
        <td>
            <i class="icon-cross status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
    </tr>
    <tr>
        <th>
            Preview-Teaser
        </th>
        <td>
            <i class="icon-cross status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
    </tr>
    <tr>
        <th>
            Werbetrailer
        </th>
        <td>
            <i class="icon-cross status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
    </tr>
    <tr>
        <th>
            Verlinkbare
            <br/>Werbebilder-
            <br/>Einblendungen
        </th>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
    </tr>
    <tr>
        <th>
            Lead-Generierung
            <br/>Livestreaming
        </th>
        <td>
            <i class="icon-cross status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
    </tr>
    <tr>
        <th>
            Lead-Generierung
            <br/>Videos
        </th>
        <td>
            <i class="icon-cross status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
    </tr>
    <tr>
        <th>
            Kalender +
            <br/>Reminder E-Mail
        </th>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
    </tr>
    <tr>
        <th>
            Interaktive Umfragen
        </th>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
    </tr>
    <tr>
        <th>
            Vorschaubild
            <br/>im Player
        </th>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
    </tr>
    <tr>
        <th>
            IP-basierte
            <br/>Werbebanner-
            <br/>Einblendungen
        </th>
        <td>
            <i class="icon-cross status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
    </tr>
    <tr>
        <th>
            Einblendung von
            <br/>Zuschauerbildern
            <br/><span class="info-block">(coming soon)</span>
        </th>
        <td>
            <i class="icon-cross status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
    </tr>
<!--    <tr>-->
<!--        <th>-->
<!--            Einblendung von-->
<!--            <br/>verlinkbaren-->
<!--            <br/>Werbebildern auf-->
<!--            <br/>der Eventseite-->
<!--            <br/><span class="info-block">(coming soon)</span>-->
<!--        </th>-->
<!--        <td>-->
<!--            <i class="icon-cross status-element text-primary"></i>-->
<!--        </td>-->
<!--        <td>-->
<!--            <i class="icon-check status-element text-primary"></i>-->
<!--        </td>-->
<!--        <td>-->
<!--            <i class="icon-check status-element text-primary"></i>-->
<!--        </td>-->
<!--        <td>-->
<!--            <i class="icon-check status-element text-primary"></i>-->
<!--        </td>-->
<!--        <td>-->
<!--            <i class="icon-check status-element text-primary"></i>-->
<!--        </td>-->
<!--    </tr>-->
    </tbody>
    <tbody class="embeddings-title">
    <tr class="table-title-tr">
        <td colspan="6">
            <div class="table-title-box" [class.active]="displayEmbeddings"
                 (click)="displayEmbeddings = !displayEmbeddings">
                <a href="javascript:void(0)">
                                    <span class="flex-block">
                                        <i class="icon-table_arrow"></i>
                                        <span class="text-block">
                                        Einbettungen
                                    </span>
                                    </span>
                </a>
            </div>
        </td>
    </tr>
    </tbody>
    <tbody class="embeddings data-table-body"
           [ngStyle]="{'display' : displayEmbeddings ? 'table-row-group' : 'none'}">
    <tr>
        <th>
            Einrichten eigener
            <br/>gestaltbarer
            <br/>Eventseite
        </th>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
    </tr>
    <tr>
        <th>
            Einbindung des Players
            <br/>inkl. des gesamten
            <br/>Systems per iframe
        </th>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
    </tr>
    <tr>
        <th>
            API für eigenen
            <br/>Kunden- Login zu Ihrer
            <br/>Kundendatenbank
        </th>
        <td>
            <i class="icon-cross status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-cross status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-cross status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-cross status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
    </tr>
    <tr>
        <th>
            Connect Video-Grafik-
            <br/>Software
        </th>
        <td>
            <i class="icon-cross status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
    </tr>
    </tbody>
    <tbody class="other-features-title">
    <tr class="table-title-tr">
        <td colspan="6">
            <div class="table-title-box" [class.active]="displayOtherFeatures"
                 (click)="displayOtherFeatures = !displayOtherFeatures">
                <a href="javascript:void(0)">
                                    <span class="flex-block">
                                        <i class="icon-table_arrow"></i>
                                        <span class="text-block">
                                        Interaktive
                                        <br/>Tools und
                                        <br/>weitere
                                        <br/>Features
                                    </span>
                                    </span>
                </a>
            </div>
        </td>
    </tr>
    </tbody>
    <tbody class="other-features data-table-body"
           [ngStyle]="{'display' : displayOtherFeatures ? 'table-row-group' : 'none'}">
    <tr>
        <th>
            Chat
        </th>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
    </tr>
    <tr>
        <th>
            Statistiken
        </th>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
    </tr>
    <tr>
        <th>
            Geoblocking
        </th>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
    </tr>
    <tr>
        <th>
            Altersbeschränkung
        </th>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
    </tr>
    <tr>
        <th>
            Echtzeit-Anzeige der
            <br/>aktuellen Zuschauer
            <br/>weltweit
        </th>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
    </tr>
    <tr>
        <th>
            Extrem robustes CDN
        </th>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
    </tr>
    <tr>
        <th>
            Weltweite genaue
            <br/>Abrechnung
        </th>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
    </tr>
    <tr>
        <th>

            Werbefrei von
            Streamdust
        </th>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
    </tr>
    <tr>
        <th>
            Cloud-Recording
        </th>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
    </tr>
    <tr>
        <th>
            Adaptive Bitrate
        </th>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
    </tr>
    <tr>
        <th>
            VoD-Archive
        </th>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
    </tr>
    <tr>
        <th>
            Simultanes Streaming
        </th>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
    </tr>
    <tr>
        <th>
            Streamen in Full HD
        </th>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
    </tr>
    <tr>
        <th>
            360°-Livestreams
            und -Videos
        </th>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
    </tr>
    </tbody>
</table>
