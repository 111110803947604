import {BehaviorSubject} from "rxjs";
import {Injectable} from "@angular/core";

@Injectable()
export class StreamWorldMapState {
    public worldMapActiveState$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private worldMapActive = false;
    public worldMapOverlayState$ = new BehaviorSubject<boolean>(false);
    public currentPosition$ = new BehaviorSubject<{x: number, y: number}>(null);
    public worldMapEnabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public shareButtonEnabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

    private worldMapOverlayState = false;

    constructor() {
    }

    public closeWorldMap(): void {
        this.worldMapActive = false;
        this.worldMapActiveState$.next(this.worldMapActive);
    }

    public toggleWorldMap(): void {
        this.worldMapActive = !this.worldMapActive;
        this.worldMapActiveState$.next(this.worldMapActive);
    }

    public toggleOverlay(): void {
        this.worldMapOverlayState = !this.worldMapOverlayState;
        this.worldMapOverlayState$.next(this.worldMapOverlayState);
    }

    public setOverlayState(state: boolean): void {
        this.worldMapOverlayState = state;
        this.worldMapOverlayState$.next(this.worldMapOverlayState);
    }

    public togglePresentation(): void {
        this.worldMapActive = !this.worldMapActive;
        this.worldMapActiveState$.next(this.worldMapActive);
    }
}
