import {Component, Input, OnInit, ViewChildren} from '@angular/core';
import {ISuggestedMedia, ISuggestedMediaFilters} from '@src/app/pages/public-media-page/public-media-page.component';
import {
    IListFilterItem,
    IListQuery,
    IMediaListConfig,
    LOAD_TYPE, MediaListComponent
} from '@src/app/components/media-list/media-list.component';
import {
    FILTER_MEDIA_TYPES, FILTER_TYPES,
    IMediaHeaderConfig, IMediaListsConfig
} from '@src/app/components/media-list/media-list-header/media-list-header.component';
import {LoadingService} from '@src/app/services/loading/loading.service';
import {IMediaItem} from '@src/app/models/core.model';
import {IMediaGroup} from '@src/app/models/stream-groups.model';

@Component({
    selector: 'app-media-lists',
    templateUrl: './media-lists.component.html',
    styleUrls: ['./media-lists.component.css']
})
export class MediaListsComponent implements OnInit {

    public LOAD_TYPE = LOAD_TYPE;
    public FILTER_TYPE = FILTER_TYPES;

    @ViewChildren('mediaLists') mediaListsViewChildren: MediaListComponent[];

    @Input() mediaLists: ISuggestedMedia[];

    @Input() config: IMediaListsConfig;
    @Input() isPlaceholder = false;

    @Input() activeQuery: IListQuery = {
        paging: {
            page: 0,
            itemsOnPage: 6
        },
        sort: [
            {
                order: 'DESC',
                field: 'DATE'
            }
        ],
        filters: []
    };

    showNoFilterResultsBlock: boolean;
    public showPlaceholder = false;

    constructor(
        public loadingService: LoadingService
    ) {
    }

    ngOnInit(): void {
        if (this.isPlaceholder && !this.mediaLists.length) {
            this.showPlaceholder = true;
        }
    }

    doFilter(filtersQuery: IListQuery) {
        const promises = this.mediaListsViewChildren.map(mediaListsViewChild => mediaListsViewChild.doFilterAsPromise(JSON.parse(JSON.stringify(filtersQuery))));
        Promise.all([...promises]).then(results => {
            this.showNoFilterResultsBlock = !results.some(res => res?.items?.length);
        });
    }

    customClick(media: IMediaGroup | IMediaItem, mediaItem: ISuggestedMedia): void {
        if (mediaItem.mediaItemCustomClick) {
            mediaItem.mediaItemCustomClick(media.id);
        }
    }

}
