import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {take, takeUntil, tap} from 'rxjs/operators';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import {StreamService} from '@src/app/services/stream-metadata/stream.service';
import {MEDIA_TYPE} from '@src/app/components/streamdust-player/constants/mediaType';

@Component({
    selector: 'app-media-preview-page',
    templateUrl: './media-preview-page.component.html',
    styleUrls: ['./media-preview-page.component.css'],
    providers: [AutoDestroyService]
})
export class MediaPreviewPageComponent implements OnInit {
    private mediaId: string;

    constructor(
        private readonly activatedRoute: ActivatedRoute,
        private readonly router: Router,
        private readonly destroy$: AutoDestroyService,
        private readonly streamService: StreamService
    ) {
    }

    ngOnInit() {
        this.activatedRoute.params.pipe(
            takeUntil(this.destroy$),
            tap(({id}: Params) => this.mediaId = id))
            .subscribe(() => {
                this.streamService.getStreamLandingInfo(MEDIA_TYPE.STREAM, this.mediaId)
                    .pipe(take(1))
                    .subscribe(
                        (data) => {
                            const type = data.results.data.video.videoType === MEDIA_TYPE.VIDEO_ON_DEMAND ? 'vod' : 'stream';
                            this.router.navigate([`/watch/${type}/${this.mediaId}`]);
                        });
            });
    }
}
