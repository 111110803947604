<ano-loader class="donation-loading"
            [isOpen]="(loading || (loadingService.loading | async)) && donationsMode"></ano-loader>

<div class="card" [class.chat-body]="donationsMode && !addNewCard">
    <ano-loader [isOpen]="(loading || (loadingService.loading | async)) && !donationsMode"></ano-loader>
    <ng-container *ngIf="!paymentType">
        <div class="card-body cards-list-wrapper">
            <h2 class="body-title mb-25">
                <a *ngIf="controls?.cancelButton" href="javascript:void(0);" (click)="cancelPayment()"
                   class="back-link">
                    <i class="icon icon-back-arrow"></i>
                </a>
                {{'payment-cards-list.title' | localize}}
            </h2>
            <span class="text d-block text-xs mb-20">{{'payment-cards.heidelpay.payment-type-selection' | localize}}</span>
            <div class="payment-type-list">
                <div class="payment-type" (click)="selectPaymentType(PAYMENT_TYPE.UNZER)"><img
                        src="/assets/img/visa-mastercard.jpg" alt=""/></div>
                <div class="payment-type" (click)="selectPaymentType(PAYMENT_TYPE.PAYPAL)"><img
                        src="/assets/img/paypal-logo.png" alt=""/></div>
                <div class="payment-type" (click)="selectPaymentType(PAYMENT_TYPE.SOFORT)"><img
                        src="/assets/img/sofort-logo.png" alt=""/></div>
            </div>
        </div>

    </ng-container>

    <ng-container *ngIf="paymentType">
        <div *ngIf="!addNewCard && paymentType === PAYMENT_TYPE.UNZER" class="card-body cards-list-wrapper"
             [class.hidden]="useActiveCard && loading"
             [class.transparent]="isTransparent">
            <h2 class="body-title mb-25">
                <a *ngIf="controls?.cancelButton" href="javascript:void(0);" (click)="cancelPayment()"
                   class="back-link">
                    <i class="icon icon-back-arrow"></i>
                </a>
                {{'payment-cards-list.title' | localize}}
            </h2>
            <p>{{'payment-cards-list.body' | localize}}</p>
            <p *ngIf="(transactionFailInfo$ | async)?.transactionFailReason" class="invalid-feedback"
               style="display: block">{{('payment-error.' + (transactionFailInfo$ | async)?.transactionFailReason) | localize}}</p>
            <div class="cards-list-nav" *ngIf="numberOfSlides > 1">
                <div class="cards-list-prev"
                     *ngIf="sliderArrow.leftArrow"
                     (click)="moveLeft()">
                    <i class="icon icon-prev"></i>
                </div>
                <div class="cards-list-next"
                     *ngIf="sliderArrow.rightArrow"
                     (click)="moveRight()">
                    <i class="icon icon-next"></i>
                </div>
            </div>
            <div class="cards-list">
                <drag-scroll #sliderComp class="row row-padding-8">
                    <div drag-scroll-item *ngFor="let card of cardsList" class="col-auto">
                        <app-payment-card [card]="card" (deleteCard$)="deleteCard($event)"
                                          [showRemoveButton]="controls?.removeCard"
                                          [failedCardId]="(transactionFailInfo$ | async)?.failedCardId"
                                          (setCardAsActive$)="setCardAsActive($event)"></app-payment-card>
                    </div>
                    <div drag-scroll-item *ngIf="controls?.addNewCard?.show" (click)="setAddNewCardMode()" class="col-auto">
                                <span class="credit-card add-card cursor-pointer">
                                    <i class="icon icon-plus"></i>
                                    <span class="text">{{'payment-cards-list.add-new-card-text' | localize}}</span>
                                </span>
                    </div>
                </drag-scroll>
            </div>

            <ng-container *ngIf="agreementText && !addNewCard">
                <app-agreement-box [text]="agreementText" [error]="termsRequiredError"
                                   (change$)="toggleTermsAgreed($event)"></app-agreement-box>
            </ng-container>
            <!--        <div class="row row-padding-8" *ngIf="agreementText">-->
            <!--            <div *ngIf="!addNewCard"-->
            <!--                 class="required-box second-info-box col-6">-->
            <!--                <div class="form-check">-->
            <!--                    <input [ngModel]="termsAgreed"-->
            <!--                           [ngModelOptions]="{standalone: true}"-->
            <!--                           (ngModelChange)="toggleTermsAgreed()"-->
            <!--                           type="checkbox" class="form-check-input" id="privacyPolicy">-->
            <!--                    <label for="privacyPolicy"-->
            <!--                           class="form-check-label">{{agreementText | localize}}</label>-->
            <!--                    <div class="invalid-feedback" *ngIf="termsRequiredError">{{'error.required' | localize}}</div>-->
            <!--                </div>-->
            <!--            </div>-->
            <!--        </div>-->

            <ng-container *ngIf="!donationsMode">
                <ng-container *ngTemplateOutlet="proceedButton"></ng-container>
            </ng-container>
        </div>

        <app-heidelpay-payment-page *ngIf="addNewCard || paymentType !== PAYMENT_TYPE.UNZER"
                                    (paymentConfirmed$)="doAddNewCard($event)"
                                    [paymentType]="paymentType"
                                    [hasCards]="!!cardsList?.length"
                                    [isModal]="isModal"
                                    [termsAgreed]="termsAgreed"
                                    [agreementText]="agreementText"
                                    [showBackButton]="showHeidelpayBackButton"
                                    [addNewCardModalSize]="addNewCardModalSize"
                                    [donationsMode]="donationsMode"
                                    [isTransparent]="isTransparent"
                                    (paymentCancelled$)="cancelAddingNewCard()">
        </app-heidelpay-payment-page>
    </ng-container>
</div>

<ng-container *ngIf="donationsMode && !addNewCard">
    <ng-container *ngTemplateOutlet="proceedButton"></ng-container>
</ng-container>

<ng-template #confirm let-dialogRef="dialogRef">
    <ano-modal [dialogRef]="dialogRef" [title]="(confirmTextTitle  | localize)" [isConfirmation]="true">
        {{confirmTextBody | localize}}
    </ano-modal>
</ng-template>

<ng-template #proceedButton>
    <div [class.chat-footer]="donationsMode">
        <div class="payment-cards-proceed-button row justify-content-between" [class.hidden]="useActiveCard && loading">
            <div *ngIf="!donationsMode" class="col"></div>
            <div class="col-auto">
                <button *ngIf="controls?.payButton" class="btn btn-primary"
                        [class.btn-primary-disabled]="this.activeCard?.expired" [disabled]="this.activeCard?.expired"
                        (click)="proceedPayment(this.cardsList)">{{'payment-cards-list.proceed-checkout-button' | localize}}</button>
            </div>
            <div *ngIf="donationsMode" class="col"></div>
        </div>
    </div>
</ng-template>
