<div class="dropdown-box" [class.open]="opened">
    <div class="dropdown-box-header">
        <h3 *ngIf="title" [class.text-success]="titleSuccess"><b>{{title | localize}}</b></h3>
        <h3 *ngIf="subTitle">{{subTitle}}</h3>
        <p class="text text-xs" *ngIf="description">{{description | localize}}</p>
        <p *ngIf="successText">{{ successText }}</p>
    </div>
    <div class="dropdown-box-body" *ngIf="!withoutBody">
        <ng-content select="[role=body]"></ng-content>
    </div>
    <div class="dropdown-box-footer">
        <ng-content select="[role=footer]"></ng-content>
    </div>
    <ano-loader [isOpen]="loading"></ano-loader>
</div>

<div class="dropdown-box-backdrop" *ngIf="withBackdrop"></div>
