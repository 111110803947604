<div class="profile-header">
    <!-- <p>{{'registration.description' | localize}}</p> -->
    <!-- <span class="required">* Pflichtangaben</span> -->
</div>

<div class="card bg-white">
    <div class="card-header">
        <h2 class="title">{{'registration.profileInfo' | localize}}</h2>
    </div>

    <div class="row row-align-stretch">
        <div class="col-lg-8 mr-auto">
            <div class="card-body" *ngIf="viewerForm">
                <app-info-panel
                        [infoPanel]="{textKey: 'media-access-modal.lead-generation-warning'}">
                </app-info-panel>
                <app-bordered-form
                        [defaultForm]="true"
                        [form]="viewerForm"
                        [fieldsConfig]="viewerFieldsConfig"
                >
                </app-bordered-form>
            </div>
        </div>
    </div>
</div>

<div class="card bg-white">
    <div class="card-header">
        <h2 class="title">{{'registration.firmInfo' | localize }}</h2>
    </div>

    <div class="row row-align-stretch">
        <div class="col-lg-8 mr-auto">
            <div class="card-body">
                <app-info-panel
                        [infoPanel]="{textKey: 'registration.needToInputCompanyInformation'}">
                </app-info-panel>
                <app-bordered-form
                        [defaultForm]="true"
                        [form]="firmForm"
                        [fieldsConfig]="firmFieldsConfig">
                </app-bordered-form>
            </div>
        </div>
    </div>
</div>

<div class="card bg-white">
    <div class="card-header">
        <h2 class="title">{{'registration.viewer.accessData' | localize}}</h2>
    </div>

    <div class="row row-align-stretch">
        <div class="col-lg-8 mr-auto">
            <div class="card-body" *ngIf="viewerForm">
                <form [formGroup]="viewerForm" class="user-address-form">
                    <div class="form-inputs border-form">
                        <div class="row form-group multi-fields">
                            <div class="col-6 col-md-6 col-sm-12">
                                <div class="mb-3">
                                    <ano-input
                                    class="input-form-field"
                                    formControlName="email"
                                    [invalid]="viewerForm.get('email').invalid"
                                    [errors]="viewerForm.get('email').errors"
                                    [isTouched]="viewerForm.get('email').touched"
                                    [isEditMode]="true"
                                    [config]="{ inputType: INPUT_TYPES.INPUT, type: 'text', placeholder: 'payment-cards.heidelpay.email-input.placeholder', isRequired: true }"></ano-input>
                                </div>
                                <div class="mb-3">
                                    <ano-input
                                    class="input-form-field"
                                    formControlName="password"
                                    [invalid]="viewerForm.get('password').invalid"
                                    [errors]="viewerForm.get('password').errors"
                                    [isTouched]="viewerForm.get('password').touched"
                                    [isEditMode]="true"
                                    [config]="{ inputType: INPUT_TYPES.INPUT, type: 'password', placeholder: 'media-access-modal.viewer-register-modal.password-placeholder', isRequired: true }"></ano-input>
                                </div>
                                <div class="mb-3">
                                    <ano-input
                                    class="input-form-field"
                                    formControlName="passwordConfirmation"
                                    [invalid]="viewerForm.get('passwordConfirmation').invalid"
                                    [errors]="viewerForm.get('passwordConfirmation').errors"
                                    [isTouched]="viewerForm.get('passwordConfirmation').touched"
                                    [isEditMode]="true"
                                    [config]="{ inputType: INPUT_TYPES.INPUT, type: 'password', placeholder: 'media-access-modal.viewer-register-modal.repeat-password-placeholder', isRequired: true }"></ano-input>
                                </div>
                            </div>
                            <div class="col-6 col-md-6 col-sm-12 mt-auto">
                                <ano-password-validator [password]="viewerForm.get('password').value"></ano-password-validator>
                            </div>
                        </div>

                        <p *ngIf="userAlreadyExistsError"
                            class="error-message">{{'media-access-modal.viewer-register-modal.user-already-exists-error' | localize}}
                        </p>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<div class="card bg-white">
    <div class="card-header">
            <p class="mb-0">{{'registration.termsOfUse'|localize}}</p>
    </div>

    <div class="row row-align-stretch">
        <div class="col-lg-8 mr-auto">
            <div class="card-body">
                <app-bordered-form
                        [defaultForm]="true"
                        [form]="termsForm"
                        [fieldsConfig]="termsFieldsConfig"
                >
                </app-bordered-form>
            </div>
        </div>
    </div>
</div>
<div class="row row-align-stretch">
    <div class="col-lg-8 mr-auto">
        <div class="m-button align-self-start">
            <ano-button (click)="submit()"
                        style="justify-content: flex-start !important;"
                        [config]="{
                type: 'submit',
                styleType: 'success',
                isDisabled: loading.registration
                }">
                {{ 'registration.button.register' | localize }}
            </ano-button>
        </div>
    </div>
</div>
