export const NewPaymentsNoPremiun = [
    {
        key: 'FREE_ACCESS',
        value: 'stream.payments.free',
        desc: 'stream.payments.free.desc',
        values: [
        ],
    }
];


export const PaymentsStream = [
    {
        key: 'FREE_ACCESS',
        value: 'stream.payments.free',
        desc: 'stream.payments.free.desc',
        values: [
        ],
    },
    {
        key: 'LIMIT_ACCESS',
        value: 'stream.payments.limit',
        desc: 'stream.payments.limit.desc',
        values: [
            {
                name: 'stream.payments.payView.name',
                key: 'psPayPerView',
                desc: 'stream.payments.payView.desc',
                required: true,
            },
            {
                name: 'stream.payments.slots.name',
                key: 'psSlot',
                desc: 'stream.payments.slots.desc',
                required: true,
            },
            {
                name: 'stream.payments.voucher.name',
                key: 'psVoucher',
                desc: 'stream.payments.voucher.desc',
                required: true,
            },
            {
                name: 'stream.payments.leadGeneration.name',
                key: 'psLeadGeneration',
                desc: 'stream.payments.leadGeneration.desc',
                required: true,
            }
        ],
    },
    {
        key: 'COURSE_ACCESS',
        value: 'stream.payments.courses.name',
        desc: 'stream.payments.courses.desc',
        values: [
            {
                name: 'stream.payments.payView.name',
                key: 'psPayPerView',
                desc: 'stream.payments.payView.desc',
                required: true,
            },
            {
                name: 'stream.payments.voucher.name',
                key: 'psVoucher',
                desc: 'stream.payments.voucher.desc',
                required: true,
            },
            {
                name: 'stream.payments.leadGeneration.name',
                key: 'psLeadGeneration',
                desc: 'stream.payments.leadGeneration.desc',
                required: true,
            },
            {
                name: 'stream.payments.customIntegration.name',
                key: 'psCustomIntegration',
                desc: 'stream.payments.customIntegration.desc',
                required: true,
            }
        ],
    }
];

export const PaymentsVideo = [
    {
        key: 'FREE_ACCESS',
        value: 'stream.payments.free',
        desc: 'stream.payments.free.desc',
        values: [
        ],
    },
    {
        key: 'LIMIT_ACCESS',
        value: 'stream.payments.limit',
        desc: 'stream.payments.limit.desc',
        values: [
            {
                name: 'stream.payments.payView.name',
                key: 'psPayPerView',
                desc: 'stream.payments.payView.desc',
                required: true,
            },
            {
                name: 'stream.payments.slots.name',
                key: 'psSlot',
                desc: 'stream.payments.slots.desc',
                required: true,
            },
            {
                name: 'stream.payments.voucher.name',
                key: 'psVoucher',
                desc: 'stream.payments.voucher.desc',
                required: true,
            },
            {
                name: 'stream.payments.leadGeneration.name',
                key: 'psLeadGeneration',
                desc: 'stream.payments.leadGeneration.desc',
                required: true,
            }
        ],
    },
    {
        key: 'COURSE_ACCESS',
        value: 'stream.payments.courses.name',
        desc: 'stream.payments.courses.desc',
        values: [
            {
                name: 'stream.payments.payView.name',
                key: 'psPayPerView',
                desc: 'stream.payments.payView.desc',
                required: true,
            },
            {
                name: 'stream.payments.voucher.name',
                key: 'psVoucher',
                desc: 'stream.payments.voucher.desc',
                required: true,
            },
            {
                name: 'stream.payments.leadGeneration.name',
                key: 'psLeadGeneration',
                desc: 'stream.payments.leadGeneration.desc',
                required: true,
            },
            {
                name: 'stream.payments.customIntegration.name',
                key: 'psCustomIntegration',
                desc: 'stream.payments.customIntegration.desc',
                required: true,
            }
        ],
    }
];



export const NewPaymentsSlots = [
    {
        key: 'FREE',
        value: 'stream.payments.free',
        desc: 'stream.payments.free.desc',
    },
    // {
    //     key: 'VOUCHER',
    //     value: 'stream.payments.voucher.name',
    //     desc: 'stream.payments.free.desc',
    // },
    {
        key: 'PAY_PER_VIEW',
        value: 'stream.payments.payView.name',
        desc: 'stream.payments.free.desc',
    },
]
