import {Component, Input, OnInit, ViewChild, Output, EventEmitter} from '@angular/core';
import {IMediaGroup} from '@src/app/models/stream-groups.model';
import {DragScrollComponent} from 'ngx-drag-scroll';
import {STREAM_STATUS} from '@src/app/components/streamdust-player/constants/status';
import {environment} from '@src/environments/environment';
import {CmsService, IPrice} from 'ui-elements';
import {IUnitKeyValue} from '@src/app/pages/user-profile/interfaces/interfaces-common';
import {IStreamModelMetadata} from '@src/app/models/stream.model';
import {MEDIA_TYPE} from '@src/app/components/streamdust-player/constants/mediaType';
import {PLAYER_MODE} from '@src/app/components/streamdust-player/constants/playerMode';
import {Router} from '@angular/router';

@Component({
    selector: 'app-group-info',
    templateUrl: './group-info.component.html',
    styleUrls: ['./group-info.component.scss']
})
export class GroupInfoComponent implements OnInit {
    STREAM_STATUS = STREAM_STATUS;
    @Input() data: IMediaGroup;
    @Input() mode: PLAYER_MODE;
    @Input() mediaId: string;
    @Output() proceedPayment$: EventEmitter<void> = new EventEmitter<void>();
    @Output() navigate$: EventEmitter<void> = new EventEmitter<void>();

    @ViewChild('sliderComp', {read: DragScrollComponent}) set sliderComp(component: DragScrollComponent) {
        if (component) {
            this.slider = component;
            this.slider.reachesLeftBound.subscribe(res => this.sliderArrow.leftArrow = !res);
            this.slider.reachesRightBound.subscribe(res => this.sliderArrow.rightArrow = !res);
        }
    }

    slider: DragScrollComponent;
    numberOfSlides: number;
    sliderArrow = {leftArrow: false, rightArrow: true};
    genres: IUnitKeyValue[];

    constructor(
        private cmsService: CmsService,
        private router: Router
    ) {
    }

    ngOnInit(): void {
        this.genres = this.cmsService.genres;
        this.numberOfSlides = this.data?.mediaItems?.length;
    }

    moveLeft(): void {
        this.slider.moveLeft();
    }

    moveRight(): void {
        this.slider.moveRight();
    }


    getBackground(media): string {
        return media?.previewPicture ? 'url(' + environment.backendApiHost + media?.previewPicture?.photoUrl + ')' : '';
    }

    getCurrency(media): IPrice {
        const price = media?.price;
        return price.amount ? {amount: price.amount, currency: price.currency || 'EUR'} : null;
    }

    proceedPayment(): void {
        this.proceedPayment$.emit();
    }

    goToMedia(media: any) {
        if (media.id === this.mediaId) {
            return;
        }
        const typeForPublisher = media.videoType === MEDIA_TYPE.STREAM ? 'streams' : 'vod';
        const typeForViewer = media.videoType === MEDIA_TYPE.STREAM ? 'stream' : 'vod';
        const url = this.mode === PLAYER_MODE.PUBLISHER
            ? `${typeForPublisher}/${media.id}/info`
            : `watch/${typeForViewer}/${media.id}`;
        this.router.navigate([url]);
        this.navigate$.emit();
    }
}
