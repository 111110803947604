import {Injectable} from '@angular/core';
import {asyncScheduler, BehaviorSubject, interval, Observable, Subject, Subscription} from 'rxjs';
import {AsyncScheduler} from 'rxjs/internal/scheduler/AsyncScheduler';
import * as _ from 'lodash';
import {observeOn, takeUntil} from 'rxjs/operators';
import {MEDIA_TYPE} from '@src/app/components/streamdust-player/constants/mediaType';

@Injectable()
export class SchedulerService {
    public events: BehaviorSubject<IScheduleEvent> = new BehaviorSubject<IScheduleEvent>(null);
    private stop$: Subject<any> = new Subject<any>();
    public timer = 0;
    private schedules: ISchedule[];
    private _asyncScheduler: AsyncScheduler = asyncScheduler;
    private tasksConfigs: IScheduledTaskConfig[][] = [];
    private _tasksConfigs: IScheduledTaskConfig[][] = [];
    private tasks: Subscription[] = [];
    private readonly clockPeriod = 1000;
    private restartEvents: boolean;
    private started = false;

    constructor() {
    }

    public schedule(schedules: ISchedule[], restartEventIfStarTimePassed: boolean = false): void {
        if (!schedules?.length) {
            return;
        }
        this.restartEvents = restartEventIfStarTimePassed;
        this.schedules = schedules;
        this.initScheduler();
        this.initTaskConfigs();
    }

    public reschedule(schedules: ISchedule[], restartEventIfStarTimePassed: boolean = false): void {
        this.schedule(schedules, restartEventIfStarTimePassed);
        this.pause();
        this.start();
    }

    private initScheduler(): void {
        this._asyncScheduler = _.cloneDeep(asyncScheduler);
        this._asyncScheduler.now = () => this.timer;
    }

    public initTaskConfigs(): void {
        this.tasksConfigs = [];
        this.schedules.forEach(schedule => {
            this.tasksConfigs.push([
                {
                    completed: false,
                    state: TIME_POINT.START,
                    delay: schedule.timePointStart,
                    schedule: schedule
                },
                {
                    completed: false,
                    state: TIME_POINT.STOP,
                    delay: schedule.timePointStop,
                    schedule: schedule
                },
            ]);
        });
        this._tasksConfigs = this.tasksConfigs;
    }

    public start(time?: number, filterNotPassed: boolean = false): void {
        if (this.started) {
            return;
        }

        this.started = true;
        this.stop$ = new Subject<any>();
        this.setTimer(time);
        this.filterNotPassed(filterNotPassed);
        this.startClock();
        this.runTasks();
    }

    private setTimer(time?: number): void {
        if (this.schedules?.length && this.schedules[0]?.mediaType === MEDIA_TYPE.STREAM && !this.schedules[0]?.isRecord) {
            this.timer = ((new Date().getHours() * 60 * 60) + (new Date().getMinutes() * 60) + (new Date().getSeconds())) * 1000;
            return;
        }
        if (time) {
            this.timer = time;
        } else {
            this.timer = this.timer || 0;
        }
    }

    private filterNotPassed(filterNotPassed: boolean): void {
        if (!filterNotPassed) {
            return;
        }

        this.tasksConfigs = this._tasksConfigs.filter(item => item.find(config => config.state === TIME_POINT.STOP && config.delay > this.timer));

    }

    public getTasks() {
        return this.tasks;
    }

    private startClock(): void {
        const interval$ = interval(this.clockPeriod);
        interval$.pipe(observeOn(this._asyncScheduler), takeUntil(this.stop$)).subscribe(() => {
            if (this.schedules?.length && this.schedules[0]?.mediaType === MEDIA_TYPE.STREAM && !this.schedules[0]?.isRecord) {
                this.timer = ((new Date().getHours() * 60 * 60) + (new Date().getMinutes() * 60) + (new Date().getSeconds())) * 1000;
            } else {
                this.timer += this.clockPeriod;
            }
        });
    }

    private runTasks(): void {
        this.clearTasks();
        this.resetTasksToNotCompleted();
        this.tasksConfigs.forEach(taskConfigs => {
            taskConfigs.forEach((task) => {
                if (task.completed) {
                    return;
                }

                if (this.timeIsPassed(task.delay) && this.restartEvents) {
                    this.addTaskEvent(task);
                    return;
                }

                if (task.state === 'START' && this.timeIsActual(task)  && !this.timeIsEarlier(task)) {
                    this.addTaskEvent(task);
                    return;
                }

                if (this.timeIsPassed(task.delay)) {
                    return;
                }

                this.tasks.push(this._asyncScheduler.schedule(this.task, task.delay - this.timer, {
                    context: this,
                    state: task.state,
                    schedule: task.schedule
                } as ISchedulerState));
            });
        });
    }

    private timeIsPassed(delay: number): boolean {
        return delay - this.timer < 0;
    }

    private addTaskEvent(task: IScheduledTaskConfig): void {
        task.completed = false;
        this.events.next({
            type: task.schedule.eventType,
            entityId: task.schedule.entityId,
            timePoint: task.state,
            entity: task.schedule.entity
        });
    }

    private timeIsActual(task: IScheduledTaskConfig): boolean {
        if (task.state === 'STOP') {
            console.error('[scheduler.service] incorrect use of timeIsActual function');
            return false;
        }
        return this.timer >= task.schedule.timePointStart && this.timer <= task.schedule.timePointStop;
    }

    private timeIsEarlier(task: IScheduledTaskConfig): boolean {
        if (task.state === 'STOP') {
            console.error('[scheduler.service] incorrect use of timeIsActual function');
            return false;
        }
        return this.timer <= task.schedule.timePointStart;
    }

    private task(schedulerState: ISchedulerState): void {
        schedulerState.context.tasksConfigs.forEach(taskConfigRow => {
            taskConfigRow.forEach(taskConfig => {
                if (taskConfig.schedule.entityId === schedulerState.schedule.entityId && taskConfig.state === schedulerState.state) {
                    taskConfig.completed = true;
                }
            });
        });
        schedulerState.context.events.next({
            type: schedulerState.schedule.eventType,
            entityId: schedulerState.schedule.entityId,
            timePoint: schedulerState.state,
            entity: schedulerState.schedule.entity
        });
    }


    public pause(): void {
        this.stop$.next();
        this.stop$.complete();
        this.clearTasks();
        this.started = false;
    }

    public stop(): void {
        if (this.stop$) {
            this.stop$.next();
            this.stop$.complete();
            this.stop$ = null;
        }

        this.clearTasks();
        // this.resetTasksToNotCompleted();
        this.timer = 0;
        this.started = false;
    }

    private clearTasks(): void {
        this.tasks.forEach(task => task.unsubscribe());
        this.tasks = [];
    }

    private resetTasksToNotCompleted(): void {
        this.tasksConfigs.forEach(taskConfigs => taskConfigs.forEach(task => {
            task.completed = false;
            this.events.next({
                type: task.schedule.eventType,
                entityId: task.schedule.entityId,
                timePoint: TIME_POINT.STOP,
                entity: task.schedule.entity
            });
        }));
    }
}

export interface IScheduledTaskConfig {
    completed: boolean;
    state: TIME_POINT;
    delay: number;
    schedule: ISchedule;
}

interface ISchedulerState {
    context: SchedulerService;
    state: TIME_POINT;
    schedule: ISchedule;
}

export interface IScheduleEvent {
    type: string;
    entityId: string;
    entity: any;
    timePoint: TIME_POINT;
}

export interface ISchedule {
    eventType: string;
    entityId: string;
    timePointStart: number;
    timePointStop: number;
    entity?: any;
    mediaType?: MEDIA_TYPE;
    isRecord?: boolean;
}


export enum TIME_POINT {
    START = 'START',
    STOP = 'STOP'
}

export interface IScheduleSignal {
    start?: number;
    stop?: boolean;
    pause?: boolean;

}
