import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PublicGroupPageComponent} from "@src/app/pages/public-group-page/public-group-page.component";
import {SharedModule} from "@src/app/modules/shared/shared.module";


@NgModule({
    declarations: [PublicGroupPageComponent],
    imports: [
        CommonModule,
        SharedModule
    ]
})
export class PublicGroupPageModule {
}
