<div class="table-responsive">
    <table class="table table-material table-sm table-coupon">
        <thead>
        <tr>
            <th class="pl-20 text-left">{{ title | localize }}</th>
        </tr>
        </thead>
        <tbody>
        <tr>
            <td>
                <table class="table table-border">
                    <tbody>
                    <tr *ngFor="let column of config.columns">
                        <td class="title">{{column.name | localize}}</td>
                        <ng-container *ngFor="let item of data">
                            <ng-container *ngIf="!column.tmpl">
                                <td class="td-content">{{item[column.dataField]}}</td>
                            </ng-container>
                            <ng-container *ngIf="column.tmpl">
                                <td class="td-content"><ng-container *ngTemplateOutlet="column.tmpl; context:{rowData: item}"></ng-container></td>
                            </ng-container>
                        </ng-container>
                    </tr>
                    <tr>
                        <td></td>
                        <ng-container *ngFor="let item of data">
                        <td>
                            <div class="btn-box" *ngIf="item.active && !((streamService.stream$ | async)?.media?.status?.stream === STREAM_STATUS.FINISHED && !(streamService.stream$ | async)?.media?.hasRecording) && !(streamService.stream$ | async)?.media?.deactivated">
                                <button class="btn btn-primary btn-sm" (click)="edit(item)">{{'common.button.edit' | localize}}</button>
                                <button class="btn btn-danger btn-sm" (click)="deactivateGroup(item.id)">{{'common.button.deactivate' | localize}}</button>
                            </div>
                        </td>
                        </ng-container>
                    </tr>
                    </tbody>
                </table>
            </td>
        </tr>
        </tbody>
    </table>
    <ano-loader [isOpen]="loading"></ano-loader>
    <div class="align-content-end bg-white">
        <mat-paginator *ngIf="totalPages > 1" [hidePageSize]="true" [length]="itemsLength"
                       [pageSize]="itemsOnPage" (page)="goToPage($event)">
        </mat-paginator>
    </div>
</div>

<!--<app-paginator [totalPages]="totalPages" [currentPage]="page" (goToPage$)="goToPage($event)"></app-paginator>-->
