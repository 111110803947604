import {EventEmitter, Injectable} from '@angular/core';
import {BehaviorSubject, fromEvent, Observable} from 'rxjs';
import {ApiService} from 'ui-elements';
import {API_URLS_APP, UrlGenerator} from '@src/app/constants/api-urls.constant';
import {IResponse} from '@src/app/models/response.model';
import {tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {getQueryParamsFromUrl} from '@src/app/utils/query-params.util';
import {IRegistration} from '@src/app/models/core.model';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private readonly dummyPhone = '84145718595256';
    public static keepLoggedProperty = 'keepLogged';
    public static expirationDateProperty = 'expirationDate';
    public authToken: string;
    public openToken: string;
    public isAuthorized: boolean;
    public isUserRegistered: boolean;
    public isAuthorizedSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public isAuthorized$ = this.isAuthorizedSubject.asObservable();
    public credentialsEmitter = new EventEmitter();
    public tokenEmitter = new EventEmitter();
    public logoutEmitter = new EventEmitter();
    public registrationComplete$: EventEmitter<boolean> = new EventEmitter<boolean>();
    public tempToken: string;

    constructor(
        private apiService: ApiService,
        private router: Router,
    ) {
        this.handleAutoLoginTokenUrlParam();
        this.storeToken(AuthService.getTokenFromStorage());
    }

    private static getTokenFromStorage(): string {
        return localStorage.getItem('authToken');
    }

    private static setTokenToStorage(token: string): void {
        localStorage.setItem('authToken', token);
    }

    public static getOpenTokenFromStorage(): string {
        return localStorage.getItem('openToken') || '';
    }

    public static setOpenTokenToStorage(token: string): void {
        localStorage.setItem('openToken', token);
    }

    private getMediaAccessTokens(): string {
        return JSON.parse(localStorage.getItem('viewerAccessToken'));
    }

    public getMediaAccessToken(mediaId: string): string {
        if (this.authToken) {
            return this.authToken;
        }

        return this.getMediaAccessTokens();
    }

    public getAuthToken(): string {
        return this.authToken;
    }

    public login(credentials: ICredentials): Observable<IResponse<IAuthorizationResult>> {
        return this.apiService.post<IResponse<IAuthorizationResult>>(API_URLS_APP.LOGIN, credentials)
            .pipe(
                tap((res) => {
                    this.saveCredentialsOnDevice(credentials);
                    this.handleAuthorizationResult(res);
                }),
            );
    }

    public loginByForeignId(mediaId: string, fId: string, hash: string): Observable<IResponse<IAuthorizationResult>> {
        return this.apiService.get<IResponse<IAuthorizationResult>>(UrlGenerator.generate(API_URLS_APP.LOGIN_BY_FOREIGN_ID, {mediaId, fId, hash}))
            .pipe(
                tap((res) => {
                    this.handleForeignAuthorizationResult(res.results);
                }),
            );
    }

    private saveCredentialsOnDevice(credentials: ICredentials) {
        this.credentialsEmitter.emit(credentials);
    }

    public loginFromDevice(authToken: string) {
        this.storeToken(authToken);
        ApiService.setLanguageToStorage(null);
    }

    public logout(sendRequest: boolean = true, withNavigate = true): void {
        this.isAuthorizedSubject.next(false);
        if (!sendRequest) {
            this.clearAuthInfo(withNavigate);
            return;
        }
        // TODO uncomment when endpoint will work
        this.apiService.post(API_URLS_APP.LOGOUT, {}).subscribe(() => {
            this.clearAuthInfo(withNavigate);
        });
    }

    private clearAuthInfo(withNavigate = true): void {
        this.logoutEmitter.emit(true);
        this.clearAuthorizationData();
        localStorage.removeItem(AuthService.expirationDateProperty);
        if (withNavigate) {
            this.router.navigate(['']);
        }
    }

    public storeToken(token: string): void {
        this.authToken = token || '';
        this.isAuthorized = !!this.authToken;
        this.isAuthorizedSubject.next(this.isAuthorized);
        AuthService.setTokenToStorage(this.authToken);
    }

    public storeTokenForCall(token: string): void {
        this.authToken = token || '';
        AuthService.setTokenToStorage(this.authToken);
    }

    public register(credentials: ICredentials): Observable<IResponse> {
        return this.apiService.post<IResponse>(API_URLS_APP.REGISTRATION, credentials);
    }

    public registerViewer(credentials: IViewerCredentials): Observable<IResponse> {
        return this.apiService.post<IResponse>(API_URLS_APP.REGISTRATION_VIEWER, credentials)
            .pipe(tap((res) => this.handleAuthorizationResult(res)));
    }

    public registrationFirstStep(credentials: IRegistration): Observable<IResponse> {
        return this.apiService.post<IResponse>(API_URLS_APP.REGISTRATION_FIRST_STEP, credentials);
    }

    public registrationSecondStep(credentials: IPassword): Observable<IResponse<{ email: string }>> {
        return this.apiService.post(API_URLS_APP.REGISTRATION_SECOND_STEP, credentials);
    }

    public handleAuthorizationResult({results: {authToken, language, isUserRegistred}}: IResponse<IAuthorizationResult>): void {
        this.tokenEmitter.emit(authToken);
        this.storeToken(authToken);
        if (!localStorage.getItem('localLanguage')) {
            ApiService.setLanguageToStorage(language);
        }
    }

    public handleForeignAuthorizationResult(res: any): void {
        console.log('resresresresresresres', res);
        this.tokenEmitter.emit(res.token);
        this.storeToken(res.token);
        // if (!localStorage.getItem('localLanguage')) {
        //     ApiService.setLanguageToStorage(language);
        // }
    }

    private clearAuthorizationData(): void {
        localStorage.removeItem('themeColor');
        localStorage.removeItem('isLoginAs');
        this.storeToken('');
    }

    private handleAutoLoginTokenUrlParam(): void {
        fromEvent(window, 'load')
            .subscribe(() => {
                const {'auto-login-token': authToken, 'target': target} = getQueryParamsFromUrl(location.href);

                if (!authToken) {
                    return;
                }

                AuthService.setTokenToStorage(authToken);
                this.storeToken(authToken);
                if (target) {
                    window.location.href = target;
                }
            });
    }

    public storeKeepLogged(keepLogged: boolean): void {
        localStorage.setItem(AuthService.keepLoggedProperty, keepLogged ? KEEP_LOGGED_STATE.KEEP_LOGGED : KEEP_LOGGED_STATE.NOT_KEEP_LOGGED);
    }

    public getKeepLogged(): boolean {
        let res = false;
        const keepLogged = localStorage.getItem(AuthService.keepLoggedProperty);
        if (!keepLogged) {
            return true;
        }
        if (keepLogged === KEEP_LOGGED_STATE.KEEP_LOGGED) {
            res = true;
        }
        return res;
    }

    public setExpirationDate(): void {
        const keepLogged = localStorage.getItem(AuthService.keepLoggedProperty);
        if (!keepLogged) {
            return;
        }

        if (keepLogged === KEEP_LOGGED_STATE.NOT_KEEP_LOGGED) {
            const expirationTime = new Date().getTime() + (30 * 60 * 1000);
            localStorage.setItem(AuthService.expirationDateProperty, expirationTime.toString());
        }
    }

    public isSessionExpired(): boolean {
        let res = false;
        const keepLogged = localStorage.getItem(AuthService.keepLoggedProperty);
        if (!keepLogged || keepLogged === KEEP_LOGGED_STATE.KEEP_LOGGED) {
            return res;
        }

        const expirationTime = localStorage.getItem(AuthService.expirationDateProperty);
        if (!expirationTime) {
            return res;
        }

        if (+expirationTime < new Date().getTime()) {
            res = true;
        }
        return res;
    }

    public sendSms(phoneNumber: string): Observable<IResponse> {
        if (this.isDummyPhone(phoneNumber)) {
            phoneNumber = this.phoneWithoutPlus(phoneNumber);
        }
        return this.apiService.post<IResponse>(API_URLS_APP.REGISTRATION_SMS_SEND, {phoneNumber});
    }

    public phoneConfirm(phoneNumber: string, code: string): Observable<IResponse> {
        if (this.isDummyPhone(phoneNumber)) {
            phoneNumber = this.phoneWithoutPlus(phoneNumber);
        }
        return this.apiService.post<IResponse>(API_URLS_APP.REGISTRATION_PHONE_CONFIRM, {phoneNumber, code});
    }

    private isDummyPhone(phoneNumber: string): boolean {
        return phoneNumber === '+' + this.dummyPhone;
    }

    private phoneWithoutPlus(phoneNumber: string): string {
        return phoneNumber.substr(1, phoneNumber.length - 1);
    }
}

export interface ICredentials {
    email: string;
    password: string;
}

export interface IViewerCredentials {
    email?: string;
    password?: string;
    passwordConfirmation?: string;
    accessToken?: string;
    firstName?: string;
    lastName?: string;
}

export interface IFinishRegistrationModel {
    firstName: string;
    lastName: string;
    gender: string;
}

export interface IAuthorizationResult {
    authToken: string;
    language: string;
    isUserRegistred: boolean;
    email?: string;
    theme?: string;
}

export interface IPassword {
    password: string;
}

export enum KEEP_LOGGED_STATE {
    KEEP_LOGGED = 'keep',
    NOT_KEEP_LOGGED = 'notKeep'
}

export type TRegistrationResponse = IResponse<{ isUserRegistred: boolean }>;
