<!--<app-payment-cards-list *ngIf="!loading" [controls]="paymentCardsListControls"-->
<!--                        [isTransparent]="true"-->
<!--                        [chargeImmediately]="true"-->
<!--                        [addNewCardModalSize]="12"-->
<!--                        (proceedPayment$)="purchaseGroup($event)">-->
<!--</app-payment-cards-list>-->
<app-access-window [termsConditions]="termsConditions"
                   [paymentCardsListControls]="paymentCardsListControls"
                   [loading]="loadingService.loading | async"
                   [isGroup]="true"
                   (proceedPayment$)="purchaseGroup($event)"
                   (close$)="close()"
></app-access-window>
