<ng-container *ngIf="!isRegistered">
    <section *ngIf="!isRegisteredViewer" class="section section-dashboard" appScrollToFirstError [forms]="getForms()">
        <div [ngSwitch]="stepRegistration" class="container">
            <div class="wrapper-content bg-light">

                <h1 *ngIf="isInitialStep || isViewerStep; else titlePublisher" 
                    (click)="changeState(stateRegistartion.initial)"
                    class="cursor-pointer title-reg">
                    <b *ngIf="isInitialStep">{{ 'registration.initial.title' | localize }}</b>
                    <ng-container *ngIf="isViewerStep">
                        <i class="icon icon-back-arrow"></i>
                        <b>{{ 'registration.viewer.title' | localize }}</b>
                    </ng-container>
                    
                </h1>
                <ng-template #titlePublisher>
                    <h1 (click)="changeState(stateRegistartion.initial)" class="cursor-pointer">
                        <i class="icon icon-back-arrow"></i>
                        <b>{{ (upgradeToPublisher ? 'publisher.upgrade.title' : 'registration.title') | localize}}</b>
                    </h1>
                </ng-template>

                <div class="content">
                    <ng-container *ngSwitchCase="stateRegistartion.initial">
                        <app-publisher-viewer-cards (change)="changeState($event)"></app-publisher-viewer-cards>
                    </ng-container>
                    <ng-container *ngSwitchCase="stateRegistartion.publisher">
                        <app-publisher
                            [upgradeToPublisher]="upgradeToPublisher"
                            [form]="form"
                            [phoneForm]="phoneForm"
                            [firmForm]="firmForm"
                            [currencyForm]="currencyForm"
                            [termsForm]="termsForm"
                            [formConfig]="formConfig"
                            [phoneFieldsConfig]="phoneFieldsConfig"
                            [firmFieldsConfig]="firmFieldsConfig"
                            [currencyFieldsConfig]="currencyFieldsConfig"
                            [termsFieldsConfig]="termsFieldsConfig"
                            [loading]="loading"
                            [isPhoneConfirmed]="isPhoneConfirmed"
                            (action)="handlePublisherAction($event)"
                        ></app-publisher>
                    </ng-container>
                    <ng-container *ngSwitchCase="stateRegistartion.viewer">
                        <app-viewer 
                            [viewerForm]="viewerForm"
                            [firmForm]="firmForm"
                            [termsForm]="termsForm"
                            [termsFieldsConfig]="termsFieldsConfig"
                            [viewerFieldsConfig]="viewerFieldsConfig"
                            [firmFieldsConfig]="firmFieldsConfig"
                            [loading]="loading"
                            (action)="handleViewerAction($event)"
                        ></app-viewer>
                    </ng-container>
                </div>
            </div>
        </div>
        <ano-loader [isOpen]="loading.registration"></ano-loader>
    </section>
</ng-container>
<ng-container *ngIf="isRegistered">
    <section class="section section-header-area">
        <div class="container">
            <img class="img-responsive" src="../../../../assets/img/background/bg-header-current-streams.png">
        </div>
    </section>

    <section class="section section-live-streams">
        <div class="container">
            <div class="wrapper-content bg-light">
                <div class="box-registration-success">
                    <i class="icon icon-circle-check"></i>
                    <h2 class="title">Vielen Dank für Ihre Anmeldung! Wir überprüfen ihre Anmeldung und senden Ihnen nach Freigabe einen Aktivierungslink.</h2>
                    <p>Die endgültige Aktivierung Ihres Accounts und damit eine erfolgreiche Registrierung wird erst nach einer eingehenden Prüfung Ihrer Angaben erfolgen. Mit der Anmeldung auf Streamdust.tv wurde automatisch eine E-Mail mit Ihren Angaben an uns versendet. Die Prüfung Ihrer Angaben kann bis zu 24 Stunden dauern.</p>
                    <span class="title-social-list">{{ "forgottenPassword.followUs" | localize}}</span>
                    <ul class="social-list">
                        <li><a href="https://www.facebook.com/Stageyourbusiness" target="_blank"><i class="icon icon-facebook"></i></a></li>
                        <li><a href="https://www.instagram.com/streamdust/" target="_blank"><i class="icon icon-instagram"></i></a></li>
                        <li><a href="https://www.linkedin.com/company/streamdust-tv/" target="_blank"><i class="icon icon-linkedin"></i></a></li>
                        <li><a href="https://www.xing.com/companies/streamdust.tvug" target="_blank"><i class="icon icon-xing"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
</ng-container>
<ng-container *ngIf="isRegisteredViewer">
    <section class="section section-header-area">
        <div class="container">
            <img class="img-responsive" src="../../../../assets/img/background/bg-header-current-streams.png">
        </div>
    </section>

    <section class="section section-live-streams">
        <div class="container">
            <div class="wrapper-content bg-light">
                <div class="box-registration-success">
                    <i class="icon icon-circle-check"></i>
                    <h2 class="title">{{ 'registration.viewer.success.title' | localize }}</h2>
                    <p>{{ 'registration.viewer.success.description' | localize }}</p>
                    <span class="title-social-list">{{ 'registration.viewer.success.social' | localize }}</span>
                    <ul class="social-list">
                        <li><a href="https://www.facebook.com/Stageyourbusiness" target="_blank"><i class="icon icon-facebook"></i></a></li>
                        <li><a href="https://www.instagram.com/streamdust/" target="_blank"><i class="icon icon-instagram"></i></a></li>
                        <li><a href="https://www.linkedin.com/company/streamdust-tv/" target="_blank"><i class="icon icon-linkedin"></i></a></li>
                        <li><a href="https://www.xing.com/companies/streamdust.tvug" target="_blank"><i class="icon icon-xing"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
</ng-container>

<ng-template #terms>
    <label for="labelTermsOfService" class="form-check-label">{{'registration.agree' | localize}} <a routerLink="agb"
                                                                           target="_blank"><b>{{'registration.conditions'
        | localize}}</b></a>
        {{'registration.and' | localize}} <a
                routerLink="private-policy"><b>{{'registration.policy' | localize}}</b></a> {{'registration.zu' |
            localize}}
    </label>
</ng-template>
