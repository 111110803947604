/**
 * Created by Тима on 03.04.2020.
 */
import { Component } from '@angular/core';

@Component({
    selector: 'app-credits',
    templateUrl: './credits.component.html',
    styleUrls: ['./credits.component.css']
})
export class CreditsComponent {
    constructor() {}
}
