<div class="wrapper-linked-streams left-side" *ngIf="groupModalState.isOpened$ | async">
    <ano-loader [class.transparent-loader]="true" [isOpen]="loading"></ano-loader>
    <div class="linked-streams-box">
        <div class="linked-streams-header">
            <i *ngIf="currentGroup && groups?.length > 1" class="icon icon-back-arrow" (click)="closeCurrentGroup()"></i>
            <h2 class="title">{{currentGroup?.name || ('groups.modal.title' | localize)}}</h2>
            <i class="icon icon-close" (click)="close()"></i>
        </div>
        <app-groups-list *ngIf="!currentGroup" [data]="groups" (groupSelected$)="onGroupSelect($event)"></app-groups-list>
        <app-group-info *ngIf="currentGroup && !isPayment"
                        [mediaId]="mediaId"
                        [data]="currentGroup"
                        (navigate$)="close()"
                        (proceedPayment$)="onPaymentProceed()"></app-group-info>
        <app-group-payment *ngIf="currentGroup && isPayment"
                           [termsConditions]="termsConditions"
                           [mediaId]="mediaId"
                           [groupId]="currentGroup.id"
                           (paymentSuccess$)="paymentSuccess()"
                           (close$)="close()"
        ></app-group-payment>
    </div>
</div>
