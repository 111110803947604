import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SubaccountsPageComponent} from './subaccounts-page.component';
import {SubAccountComponent} from './components/sub-account/sub-account.component';
import {SharedModule} from '@src/app/modules/shared/shared.module';
import { SubAccountInvitationComponent } from './components/sub-account-invitation/sub-account-invitation.component';
import { AcceptInvitationComponent } from './components/accept-invitation/accept-invitation.component';


@NgModule({
    declarations: [SubaccountsPageComponent, SubAccountComponent, SubAccountInvitationComponent, AcceptInvitationComponent],
    imports: [
        CommonModule,
        SharedModule
    ]
})
export class SubaccountsPageModule {
}
