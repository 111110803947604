<ano-form
        [isLightForm]="true"
        [form]="form"
        [borderForm]="true"
        [fieldsConfig]="fieldsConfig"
        [forcedViewMode]="true">
</ano-form>
<form class="inline-form light-form full-20">
    <div class="row row-padding-0 align-items-center ng-star-inserted">
        <label class="col-md-4 form-control-label ng-star-inserted"><i class="icon icon-share-alt"></i> {{'encoder.data.share' | localize}}</label>
        <div class="col-md-8">
            <a [attr.href]="sendEmailLink" target="_blank"><i class="icon icon-paper-plane link"></i></a>
        </div>
    </div>
</form>
<ano-loader [isOpen]="loading"></ano-loader>
