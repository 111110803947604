<ng-container *ngIf="!passwordForgottenConfirmationResult.isTokenInvalid">
  <div class="card bg-white">
    <div style="width: 100%; height: 600px; display: flex;justify-content: center; align-items: center">
      <h1>Will be implemented soon</h1>
    </div>
    <ano-loader [isOpen]="loading"></ano-loader>
  </div>
</ng-container>

<ng-container *ngIf="passwordForgottenConfirmationResult.isTokenInvalid">
  <section class="section">
    <div class="container">
      <app-token-expired-page [title]="passwordForgottenConfirmationResult.errorMessage"></app-token-expired-page>
      <!-- <div class="bg-light registration-complete mb-3">
        <app-info-box>
          <ng-container role="message" class="mb-0">
            
          </ng-container>
        </app-info-box>
      </div> -->
    </div>
    <ano-loader [isOpen]="loading"></ano-loader>
  </section>
</ng-container>