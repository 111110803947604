<div class="media-visibility">
  <div class="row mt-30">
    <div class="col-12">
      <p class="media-visibility-description">{{'stream.overview.description' | localize}}</p>
      <ng-container *ngFor="let formConfig of landingForms">
        <div class="row">
          <div class="col-{{formConfig?.size || 12}}">
            <app-bordered-form
                [config]="formConfig"
                [defaultForm]="formConfig.isDefault"
                [toggleForm]="formConfig.isToggle"
                [childForm]="formConfig.isChild"
                [form]="formConfig.form"
                [fieldsConfig]="formConfig.formFields"
                [title]="formConfig.title"
                [teaserText]="formConfig.teaserText"
                [withoutTopPadding]="formConfig.withoutTopPadding"
                [disabledForm]="formConfig.forcedDisable"
            >
            </app-bordered-form>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
