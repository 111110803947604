<span class="credit-card" [class.disabled-credit-card]="_card?.expired" [class.active-credit-card]="_card?.active" [class.cant-be-deleted]="!showRemoveButton"
      [class.failed-credit-card]="_card?.externalCardId === failedCardId" (click)="setCardAsActive($event)">
	<span *ngIf="_card?.active" class="selected">{{'payment-cards-list.payment-card.selected' | localize}}</span>
	<div class="card-logo">
		<img [src]="resolvePaymentCardSrc(_card.type)" alt="card logo">
	</div>
	<span class="card-number">
		<div class="row row-padding-0 justify-content-between align-items-center">
			<div *ngFor="let i of [0, 1, 2]" class="col-auto">
				<ul class="num">
					<li *ngFor="let i of [0, 1, 2, 3]">
						<i class="dot"></i>
					</li>
				</ul>
			</div>
			<div class="col-auto">
				<ul class="num">
					<li>{{_card?.number}}</li>
				</ul>
			</div>
		</div>
	</span>
	<span class="actuality">
		<span class="title">{{'payment-cards-list.payment-card.expires' | localize}}</span>
		<span class="date">{{_card.expiryDate}}</span>
		<span *ngIf="_card.expired" class="date expired">{{'payment-cards-list.payment-card.expired' | localize}}</span>
	</span>
	<span *ngIf="!_card?.active && showRemoveButton" (click)="deleteCard($event)" class="delete-card cursor-pointer">
		<i class="icon-trash"></i> {{'payment-cards-list.payment-card.delete' | localize}}
	</span>
</span>


