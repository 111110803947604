<div class="card">
    <div class="card-header pb-0">
        <h3 class="title-level-3">{{'stream.quickAccess.title' | localize}}</h3>
    </div>
    <div class="card-body">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
                <a class="nav-link active" (click)="switchTab(TABS.DESCRIPTION)" id="desc-tab" data-toggle="tab" href="#desc" role="tab" aria-controls="desc" aria-selected="true">{{'stream.quickAccess.description' | localize}}</a>
            </li>
            <li class="nav-item" role="presentation">
                <a class="nav-link" (click)="switchTab(TABS.OVERLAYS)" id="overlays-tab" data-toggle="tab" href="#overlays" role="tab" aria-controls="overlays" aria-selected="false">{{'stream.quickAccess.overlays' | localize}}</a>
            </li>
            <li class="nav-item" role="presentation">
                <a class="nav-link" (click)="switchTab(TABS.SURVEYS)" id="survey-tab" data-toggle="tab" href="#survey" role="tab" aria-controls="survey" aria-selected="false">{{'stream.quickAccess.surveys' | localize}}</a>
            </li>
            <li class="nav-item" role="presentation">
                <a class="nav-link" (click)="switchTab(TABS.PRESENTATIONS)" id="presentations-tab" data-toggle="tab" href="#presentations" role="tab" aria-controls="presentations" aria-selected="false">{{'stream.quickAccess.presentations' | localize}}</a>
            </li>
            <li class="nav-item" role="presentation">
                <a class="nav-link" (click)="switchTab(TABS.VISIBILITY)" id="visibility-tab" data-toggle="tab" href="#visibility" role="tab" aria-controls="visibility" aria-selected="false">{{'stream.quickAccess.visibility' | localize}}</a>
            </li>
        </ul>
        <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="desc" role="tabpanel" aria-labelledby="desc-tab" #descrTab>
<!--                <ng-container *ngFor="let descriptionLine of media?.media?.description.split('\n')">-->
<!--                    <p class="description-line">{{descriptionLine}}</p>-->
<!--                </ng-container>-->
<!--                <button class="btn btn-primary mt-20">Bearbeiten</button>-->
                <app-media-description *ngIf="activeTab === TABS.DESCRIPTION" [media]="media"></app-media-description>
            </div>
            <div class="tab-pane fade" id="overlays" role="tabpanel" aria-labelledby="overlays-tab" #overlaysTab>
                <app-overlays-table
                        *ngIf="activeTab === TABS.OVERLAYS"
                        [config]="overlayConfig"
                        [mediaId]="mediaId"
                        [mediaType]="mediaType"
                        [standAlone]="true"
                        [itemsOnPage]="5"
                        [noDataLink]="{
                            url: quickLinkTmpl + 'info/overlays/new',
                            title: 'common.button.create' | localize
                        }"
                ></app-overlays-table>
            </div>
            <div class="tab-pane fade" id="survey" role="tabpanel" aria-labelledby="survey-tab" #surveysTab>
                <app-survey-table
                        *ngIf="activeTab === TABS.SURVEYS"
                        [hideAddButton]="true"
                        [hideSearch]="true"
                        [standAlone]="true"
                        [itemsOnPage]="5"
                        [noDataLink]="{
                            url: quickLinkTmpl + 'info/surveys/new',
                            title: 'common.button.create' | localize
                        }"
                ></app-survey-table>
            </div>
            <div class="tab-pane fade" id="presentations" role="tabpanel" aria-labelledby="presentations-tab" #presentationsTab>
                <app-presentations-table
                        *ngIf="activeTab === TABS.PRESENTATIONS"
                        [mediaId]="mediaId"
                        [noDataLink]="{
                            url: quickLinkTmpl + 'info/presentations',
                            title: 'common.button.create' | localize
                        }"
                ></app-presentations-table>
            </div>
            <div class="tab-pane fade" id="visibility" role="tabpanel" aria-labelledby="visibility-tab">
                <app-media-visibility
                        *ngIf="activeTab === TABS.VISIBILITY"
                        [mediaId]="mediaId"
                        [mediaType]="mediaType"
                        [stream]="media"
                ></app-media-visibility>
            </div>
        </div>
    </div>
</div>
