import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output, SimpleChanges,
    TemplateRef,
    ViewChild
} from '@angular/core';
import {PaymentService} from '@src/app/services/payment/payment.service';
import {catchError, take, takeUntil} from 'rxjs/operators';
import {LoadingService} from '@src/app/services/loading/loading.service';
import {MatDialog} from '@angular/material/dialog';
import {
    ICommonPaymentCard,
    IHeidelpayCard,
    IHeidelpayPaymentDetails
} from '@src/app/components/payment-cards-list/heidelpay-payment/heidelpay-payment.component';
import {ActivatedRoute, Router} from '@angular/router';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import {PAYMENT_STATUS} from '@src/app/services/subscription-manage/subscription-manage.service';
import {of} from 'rxjs';
import {AuthService} from '@src/app/services/auth/auth.service';
import {Subject} from 'rxjs/internal/Subject';
import {FailurePaymentService} from '@src/app/services/slot-failure/failure-payment.service';
import {DragScrollComponent} from 'ngx-drag-scroll';
import {clearQueryParams} from '@src/app/utils/query-params.util';
import {Location} from '@angular/common';
import {UserService} from '@src/app/services/user/user.service';
import {USER_TYPE} from '@src/app/constants/user-type.constant';
import {IUserProfile} from '@src/app/pages/user-profile/components/profile/services/profile.service';

@Component({
    selector: 'app-payment-cards-list',
    templateUrl: './payment-cards-list.component.html',
    styleUrls: ['./payment-cards-list.component.scss']
})
export class PaymentCardsListComponent implements OnInit, OnDestroy, OnChanges {

    @Input() controls: IPaymentCardsListControls;
    @Input() addNewCardModalSize = 6;
    @Input() isTransparent: boolean;
    @Input() donationsMode = false;
    @Input() useActiveCard = false;
    @Input() agreementText: string;
    @Input() isModal: boolean;
    @Input() chargeImmediately: boolean;
    @Input() returnUrl = 'user/bank-details';

    @Input() set onlyCard(onlyCard: boolean) {
        this._onlyCard = onlyCard;
        if (!onlyCard) {
            this.paymentType = null;
            return;
        }
        this.paymentType = PAYMENT_TYPE.UNZER;
    }

    private _onlyCard: boolean;

    get onlyCard(): boolean {
        return this._onlyCard;
    }

    @Output() proceedPayment$ = new EventEmitter<IPaymentCard>();

    @ViewChild('confirm', {static: false}) confirm: TemplateRef<any>;

    @ViewChild('sliderComp', {read: DragScrollComponent}) set sliderComp(component: DragScrollComponent) {
        if (component) {
            this.slider = component;
            this.slider.reachesLeftBound.pipe(takeUntil(this.destroy$)).subscribe(res => this.sliderArrow.leftArrow = !res);
            this.slider.reachesRightBound.pipe(takeUntil(this.destroy$)).subscribe(res => this.sliderArrow.rightArrow = !res);
        }
    }

    slider: DragScrollComponent;
    numberOfSlides: number;
    sliderArrow = {leftArrow: false, rightArrow: true};

    cardsList: IPaymentCard[];
    transactionFailInfo$ = this.paymentService.failedTransactionInfo$;

    addNewCard = false;
    showHeidelpayBackButton = true;
    cardActivation = false;
    loading = false;
    confirmTextTitle: string;
    confirmTextBody: string;
    successActivationRedirectUrl: string;
    failActivationRedirectUrl: string;
    queryParams: any;
    termsAgreed = false;
    termsRequiredError = false;
    paymentType: PAYMENT_TYPE;
    PAYMENT_TYPE = PAYMENT_TYPE;
    paymentTypeSelected$: Subject<void> = new Subject<void>();
    activeCard: IPaymentCard;

    constructor(
        private authService: AuthService,
        private paymentService: PaymentService,
        public loadingService: LoadingService,
        private dialog: MatDialog,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private destroy$: AutoDestroyService,
        private slotFailure: FailurePaymentService,
        private location: Location,
        private userService: UserService,
    ) {
    }

    ngOnInit(): void {
        this.activatedRoute.queryParams.pipe(takeUntil(this.destroy$)).subscribe(params => {
            this.loading = true;
            this.queryParams = params;

            if (params.hasOwnProperty('successUrl') && params.hasOwnProperty('failUrl')) {
                this.successActivationRedirectUrl = params['successUrl'];
                this.failActivationRedirectUrl = params['failUrl'];
            }

            if (params.hasOwnProperty('cardReturn') && params.hasOwnProperty('transactionId')) {
                this.cardActivation = true;
                this.activateCard(params['transactionId']);
                clearQueryParams(this.location);
            }

            if (this.authService.isAuthorized) {
                this.fetchCardsList();
            } else {
                this.showHeidelpayBackButton = this.controls?.cancelButton?.show;
                this.addNewCard = true;
                this.loading = false;
            }
        });

        this.slotFailure.cardListAction
            .pipe(takeUntil(this.destroy$))
            .subscribe((method) => {
                switch (method) {
                    case 'CARD':
                        this.selectPaymentType(PAYMENT_TYPE.UNZER);
                        break;
                    case 'PAYPAL':
                        this.selectPaymentType(PAYMENT_TYPE.PAYPAL);
                        break;
                    case 'SOFORT':
                        this.selectPaymentType(PAYMENT_TYPE.SOFORT);
                        break;
                }
            });
    }

    ngOnDestroy(): void {
        this.paymentService.failedTransactionInfo.next(null);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes?.useActiveCard && changes?.useActiveCard?.currentValue !== changes?.useActiveCard?.previousValue) {
            this.loading = false;
        }
    }

    proceedPayment(cardsList): void {
        if (this.agreementText && !this.termsAgreed) {
            this.termsRequiredError = true;
            return;
        }
        if (cardsList?.length) {
            const activeCard = cardsList.find(card => card.active);
            this.proceedPayment$.emit(activeCard);
        } else {
            this.paymentService.resetPaymentCardError();
            this.addNewCard = true;
        }
    }

    cancelPayment(): void {
        if (!!this.paymentType && !this.onlyCard) {
            this.paymentType = null;
            return;
        }
        if (this.controls?.cancelButton?.action) {
            this.controls?.cancelButton?.action();
        }

        if (this.controls?.cancelButton?.returnUrl) {
            this.router.navigate([this.controls?.cancelButton?.returnUrl]);
        }

        this.paymentService.resetPaymentCardError();
    }

    activateCard(transactionId: string): void {
        this.loading = true;
        this.userService.userProfile$.pipe(takeUntil(this.destroy$))
            .subscribe((user: IUserProfile) => {
                if (user) {
                    const viewerAccount = user.accountType === USER_TYPE.VIEWER;
                    this.paymentService.activateCard(transactionId, false, viewerAccount)
                        .pipe(
                            take(1),
                            catchError((e) => of(e))
                        )
                        .subscribe(result => {
                            this.cardActivation = false;
                            if (!result?.success) {
                                this.loading = false;
                                return;
                            }
                            if (result.results.data.paymentStatus === PAYMENT_STATUS.SUCCESS) {
                                this.loading = false;
                                this.paymentService.resetPaymentCardError();
                                return this.fetchCardsList();
                            }
                            if (result.results.data.paymentStatus === PAYMENT_STATUS.FAILED) {
                                this.loading = false;
                                this.paymentService.failedTransactionInfo.next({
                                    transactionFailReason: result.results.data.reason,
                                    failedCardId: result.results.data.externalCardId
                                });
                                return;
                            }
                            if (result.results.data.paymentStatus === PAYMENT_STATUS.PENDING) {
                                setTimeout(() => {
                                    return this.activateCard(transactionId);
                                }, 1500);
                            }
                        });
                }
            });
    }

    doAddNewCard(paymentDetails: IHeidelpayPaymentDetails): void {
        // console.log('paymentDetails', paymentDetails);
        if (this.controls?.addNewCard?.customHandler) {
            // console.log('custom pay');
            return this.controls?.addNewCard?.customHandler(paymentDetails);
        }

        let paymentCard: ICommonPaymentCard;

        const card = paymentDetails.card;
        if (paymentDetails.card.method === 'card') {
            paymentCard = {
                externalCardId: card.id,
                expiryDate: card.expiryDate,
                type: card.brand,
                number: card.number.slice(-4),
                cardDetails: {
                    id: card.id,
                    brand: card.brand,
                    cvc: card.cvc,
                    expiryDate: card.expiryDate,
                    number: card.number.slice(-4),
                    method: card.method,
                    account: card.cardDetails.account,
                    cardType: card.cardDetails.cardType,
                    countryIsoA2: card.cardDetails.countryIsoA2,
                    countryName: card.cardDetails.countryName,
                    issuerName: card.cardDetails.issuerName,
                    issuerPhoneNumber: card.cardDetails.issuerPhoneNumber,
                    issuerUrl: card.cardDetails.issuerUrl,
                    '3ds': card['3ds']
                }
            };
            this.loading = true;
            this.paymentService.createPaymentCard({
                returnUrl: `https://${window.location.hostname}/${this.returnUrl}`,
                paymentMethod: {
                    saveCard: true,
                    viewerAccount: this.userService.userProfile?.accountType === USER_TYPE.VIEWER,
                    resourceId: card.id,
                    method: card.method,
                    paymentCard,
                },
                // paymentCard: {
                //     externalCardId: card.id,
                //     expiryDate: card.expiryDate,
                //     type: card.brand,
                //     number: card.number.slice(-4),
                //     cardDetails: {
                //         id: card.id,
                //         brand: card.brand,
                //         cvc: card.cvc,
                //         expiryDate: card.expiryDate,
                //         number: card.number.slice(-4),
                //         method: card.method,
                //         account: card.cardDetails.account,
                //         cardType: card.cardDetails.cardType,
                //         countryIsoA2: card.cardDetails.countryIsoA2,
                //         countryName: card.cardDetails.countryName,
                //         issuerName: card.cardDetails.issuerName,
                //         issuerPhoneNumber: card.cardDetails.issuerPhoneNumber,
                //         issuerUrl: card.cardDetails.issuerUrl,
                //         '3ds': card['3ds']
                //     }
                // },
                successUrl: `https://${window.location.hostname}/${this.returnUrl}`,
                failUrl: `https://${window.location.hostname}/${this.returnUrl}`
            }).pipe(take(1)).subscribe((response) => {
                if (response?.redirectUrl) {
                    window.location.href = response.redirectUrl;
                } else {
                    this.loading = false;
                }
            });
            return;
        }

        this.loading = true;
        this.paymentService.createPaymentCard({
            returnUrl: `https://${window.location.hostname}/${this.returnUrl}`,
            paymentMethod: {
                resourceId: card.id,
                method: card.method,
            },
            successUrl: `https://${window.location.hostname}/${this.returnUrl}`,
            failUrl: `https://${window.location.hostname}/${this.returnUrl}`
        }).pipe(take(1)).subscribe((response) => {
            if (response?.redirectUrl) {
                window.location.href = response.redirectUrl;
            } else {
                this.loading = false;
            }
        });
    }

    deleteCard(cardId: string): void {
        const confirmTextTitle = 'payment-cards-list.delete-card-confirmation.title';
        const confirmTextBody = 'payment-cards-list.delete-card-confirmation.body';
        const cardActionFunction = () => {
            this.paymentService.deletePaymentCard(cardId).pipe(take(1)).subscribe((success) => {
                if (success) {
                    this.cardsList = this.cardsList.filter(card => card.id !== cardId);
                }
                this.loading = false;
            });
        };
        this.confirmCardAction(confirmTextTitle, confirmTextBody, cardActionFunction);
    }

    setCardAsActive(newActiveCard: IPaymentCard): void {
        if (newActiveCard.expired) {
            return;
        }
        const previousActiveCard = this.cardsList.find(card => card.active);
        if (this.controls?.canSetCardAsActive) {
            const confirmTextTitle = 'payment-cards-list.set-card-as-active-confirmation.title';
            const confirmTextBody = 'payment-cards-list.set-card-as-active-confirmation.body';
            const cardActionFunction = () => {
                this.paymentService.setCardAsActive(newActiveCard.id).pipe(take(1)).subscribe(result => {
                    if (result) {
                        this.swapActiveCards(previousActiveCard, newActiveCard);
                    }
                    this.loading = false;
                });
            };
            this.confirmCardAction(confirmTextTitle, confirmTextBody, cardActionFunction);
        } else {
            this.swapActiveCards(previousActiveCard, newActiveCard);
        }
    }

    confirmCardAction(confirmTitle, confirmBody, cardActionFunction): void {
        this.confirmTextTitle = confirmTitle;
        this.confirmTextBody = confirmBody;
        const dialogRef = this.dialog.open(this.confirm);
        dialogRef.afterClosed()
            .pipe(take(1))
            .subscribe((confirm: boolean) => {
                if (confirm) {
                    this.loading = true;
                    cardActionFunction();
                }
            });
    }

    fetchCardsList(): void {
        this.loading = true;
        this.paymentService.getPaymentCards().pipe(take(1)).subscribe(paymentCards => {
            paymentCards.forEach(card => {
                card.method = 'card';
            });
            if (this.useActiveCard && paymentCards?.length) {
                return this.proceedPayment(paymentCards);
            }

            if (this.chargeImmediately) {
                this.paymentService.failedTransactionInfo.pipe(take(1)).subscribe((res) => {
                    if (!res && paymentCards?.length === 1) {
                        return this.proceedPayment(paymentCards);
                    }
                });
            }

            this.cardsList = paymentCards;
            this.numberOfSlides = this.cardsList.length;
            this.activeCard = this.cardsList.find(card => card.active);

            this.addNewCard = !this.cardsList?.length && !this.cardActivation;

            this.showHeidelpayBackButton = !!this.cardsList?.length || this.controls?.cancelButton?.show;

            this.loading = false;
        });
    }

    cancelAddingNewCard(): void {
        this.paymentService.failedTransactionInfo.next(null);

        if (!this.cardsList?.length) {
            return this.cancelPayment();
        }
        this.addNewCard = false;
    }

    setAddNewCardMode(): void {
        if (this.agreementText && !this.termsAgreed) {
            this.termsRequiredError = true;
            return;
        }
        this.paymentService.resetPaymentCardError();
        this.addNewCard = true;
    }

    swapActiveCards(previousActiveCard, newActiveCard): void {
        previousActiveCard.active = false;
        newActiveCard.active = true;
        this.activeCard = newActiveCard;
    }

    public toggleTermsAgreed(event: boolean): void {
        this.termsAgreed = event;
        this.termsRequiredError = false;
    }

    public selectPaymentType(type: PAYMENT_TYPE): void {
        this.paymentType = type;
        this.paymentTypeSelected$.next();
    }

    moveLeft(): void {
        this.slider.moveLeft();
    }

    moveRight(): void {
        this.slider.moveRight();
    }
}

export enum PAYMENT_CARD_TYPE {
    VISA = 'VISA',
    MASTER_CARD = 'MASTER'
}

export interface IPaymentCard {
    accountId?: string;
    active?: boolean;
    createdAt?: number;
    expired?: boolean;
    expiryDate?: string;
    externalCardId: string;
    id?: string;
    method?: string;
    number?: string;
    paymentType?: string;
    type: PAYMENT_CARD_TYPE;
    updatedAt?: number;
    heidelpayCard?: IHeidelpayCard;
}

export interface IPaymentCardAddReq extends IPaymentUrlsReq {
    paymentMethod?: {
        resourceId: string;
        method: string;

        userCardId?: string;
        saveCard?: boolean;
        viewerAccount?: boolean;
        paymentCard?: ICommonPaymentCard;
    };
    paymentCard?: ICommonPaymentCard;
    saveCard?: boolean;
}

export interface IPaymentUrlsReq {
    returnUrl: string;
    successUrl: string;
    failUrl: string;
}

export interface IPaymentCardsListControls {
    payButton?: boolean;
    cancelButton?: {
        show: boolean;
        returnUrl?: string;
        action?: () => void;
    };
    removeCard?: boolean;
    addNewCard?: {
        show: boolean;
        text: string;
        customHandler?: (paymentDetails: IHeidelpayPaymentDetails) => void;
    };
    canSetCardAsActive?: boolean;
}

enum PAYMENT_TYPE {
    UNZER = 'UNZER', PAYPAL = 'PAYPAL', SOFORT = 'SOFORT'
}
