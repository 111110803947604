import {Component, OnInit} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {ActivatedRoute, Params} from '@angular/router';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import { FormGroup} from '@angular/forms';
import {IFieldsConfig} from '@src/app/pages/user-profile/interfaces/interfaces-common';
import {StreamService} from '@src/app/services/stream-metadata/stream.service';
import {STREAM_STATUS} from '@src/app/components/streamdust-player/constants/status';

@Component({
    selector: 'app-stream-remind',
    templateUrl: './stream-remind.component.html',
    styleUrls: ['./stream-remind.component.scss']
})
export class StreamRemindComponent implements OnInit {
    STREAM_STATUS = STREAM_STATUS;
    public mediaId: string;
    fieldsConfig: IFieldsConfig[][];
    form: FormGroup;

    constructor(
        public activatedRoute: ActivatedRoute,
        private readonly destroy$: AutoDestroyService,
        public streamService: StreamService
    ) {
    }

    ngOnInit(): void {
        this.activatedRoute.parent.params.pipe(
            takeUntil(this.destroy$))
            .subscribe(({id}: Params) => {
                if (!id) {
                    return;
                }
                this.mediaId = id;
            });
    }


    // getAvailableWithState() {
    //     this.remindService.getAvailableWithState(this.mediaId)
    //         .pipe(
    //             takeUntil(this.destroy$),
    //             pluck('results', 'data')
    //         )
    //         .subscribe(res => {
    //             this.data = res;
    //             this.initFormsConfig();
    //             this.setFormData();
    //             this.listenForm();
    //         });
    // }


}
