<div class="player-container" [ngStyle]="{'background-image': 'url(' + environment.backendApiHost + config?.thumbnailUrl + ')', 'background-size': 'cover', 'background-position': 'center'}">
    <ng-container *ngFor="let infoLine of config?.infoLines; let i = index">
        <span  class="status-video status-video-{{i + 1}} {{infoLine.cssClass}}" *ngIf="infoLine
         && (infoLine.showOn !== STATE.PLAY || state === STATE.PLAY)
         && (infoLine.showOn !== STATE.STOP || state === STATE.STOP)" (click)="infoLine?.action()">
            <i *ngIf="infoLine.cssIconClass" class="icon icon-{{infoLine.cssIconClass}}"></i> {{ infoLine.text | localize }}
        </span>
    </ng-container>
    <app-videojs *ngIf="config?.mediaSource?.url"
                 [config]="config"
                 [buttonsConfig]="buttonsConfig"
                 [currentLivestreamTime]="currentLivestreamTime"
                 [marketingSocket]="marketingSocket"
                 [playerController]="playerController"
                 (videoEnded)="onVideoEnd()"
                 (videoClicked)="onVideoClick()"
                 (playerSignal)="onPlayerSignal($event)"
                 (overlayClicked)="onOverlayClick($event)"
                 (overlayViewed)="onOverlayViewed($event)"
                 (fullscreenToggle)="onFullscreenChange($event)"
                 (timeUpdate)="onTimeUpdate($event)"
                 (updateOverlays)="updateOverlays()"
    ></app-videojs>
</div>


