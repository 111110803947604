<div class="checkbox-wrap">
    <h4><b>{{(standAlone ? 'remind.title' : 'remind.settings.title') | localize}}</b></h4>
    <p *ngIf="standAlone" class="text-sm">{{'remind.subtitle' | localize}}</p>
    <app-bordered-form class="remind-form"
                       [disabled]="((streamService.stream$ | async)?.media?.status?.stream === STREAM_STATUS.PENDING)"
                       [panel]="true"
                       [defaultForm]="true"
                       [form]="form"
                       [fieldsConfig]="fieldsConfig">
    </app-bordered-form>
</div>
