import {
    Component,
    EventEmitter, Inject,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import {
    Environment,
    IOverlay,
    IPlayerButton,
    IPlayerConfig,
    IPlayerOptions,
    STATE
} from 'ui-elements';
import {IScheduleSignal} from 'ui-elements';
import {BehaviorSubject} from 'rxjs';
import {ENVIRONMENT} from 'ui-elements';

@Component({
    selector: 'app-player',
    templateUrl: './player.component.html',
    styleUrls: ['./player.component.sass']
})
export class PlayerComponent implements OnInit {
    @Input() config: IPlayerConfig;
    @Input() currentLivestreamTime: number;
    @Input() buttonsConfig: IPlayerButton[];
    @Input() marketingSocket: string;
    @Input() playerController: BehaviorSubject<{play: boolean}>;
    @Output() videoEnded: EventEmitter<any> = new EventEmitter<any>();
    @Output() videoClicked: EventEmitter<any> = new EventEmitter<any>();
    @Output() overlayClicked: EventEmitter<IOverlay> = new EventEmitter<IOverlay>();
    @Output() overlayViewed: EventEmitter<IOverlay> = new EventEmitter<IOverlay>();
    @Output() playerSignal: EventEmitter<IScheduleSignal> = new EventEmitter<IScheduleSignal>();
    @Output() fullscreenToggle: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() timeUpdated: EventEmitter<number> = new EventEmitter<number>();
    @Output() updateOverlaysEmitter: EventEmitter<any> = new EventEmitter<any>();

    public options: IPlayerOptions;
    public player: any;
    public STATE = STATE;
    public state: STATE = STATE.PLAY;

    constructor(@Inject(ENVIRONMENT) public environment: Environment) {
    }

    ngOnInit(): void {
        if (!this.config?.thumbnailUrl) {
            this.config.thumbnailUrl = '/cmsImage/fileName/video_default_8.jpg';
        }
        if (!this.config?.fallbackUrl) {
            this.config.fallbackUrl = '/cmsImage/fileName/Fallback_20Image.jpg';
        }
    }

    public onVideoEnd(): void {
        this.videoEnded.emit(this.config.mediaSource.subtype);
    }

    public updateOverlays(): void {
        this.updateOverlaysEmitter.emit();
    }

    public onVideoClick(): void {
        this.videoClicked.emit(this.config.mediaSource.subtype);
    }

    public onOverlayClick(overlay: IOverlay): void {
        this.overlayClicked.emit(overlay);
    }

    public onTimeUpdate(time: number): void {
        this.timeUpdated.emit(time);
    }

    public onOverlayViewed(overlay: IOverlay): void {
        this.overlayViewed.emit(overlay);
    }

    public onPlayerSignal(signal: IScheduleSignal): void {
        this.playerSignal.emit(signal);
    }

    public onFullscreenChange(state: boolean): void {
        this.fullscreenToggle.emit(state);
    }
}
