import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { AuthService } from '@src/app/services/auth/auth.service';
import {
    BehaviorSubject,
    fromEvent,
    merge,
    Observable,
    Subject
} from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { getTokenWithoutPrefix } from '@src/app/utils/token-without-prefix.util';

@Injectable()
export class WebSocketService implements OnDestroy {

    public get webSocket() {
        return this._webSocket?.websocket;
    };

    private _webSocket: IWebSocket;
    public readonly isWebSocketReady$: Observable<boolean>;

    private readonly isWebSocketReadySubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private readonly webSocketCloseSubject: Subject<void> = new Subject<void>();
    private webSocketOpen$: Observable<Event>;
    private webSocketClose$: Observable<Event>;
    private webSocketError$: Observable<Event>;
    private destroy$: Subject<void> = new Subject<void>();
    public wsReconnected: EventEmitter<boolean> = new EventEmitter();
    public username = '';
    private productId = '';
    private token = '';
    private webSocketUrl = '';

    public constructor(private readonly authService: AuthService) {
        this.isWebSocketReady$ = this.isWebSocketReadySubject.asObservable();
        // this.connectWebSocket();
        this.handleAuthorizationStatusChange();
    }

    public init(webSocketUrl: string, token: string, productId: string, username: string = ''): void {
        this.token = token;
        this.productId = productId;
        this.username = username;
        this.webSocketUrl = webSocketUrl;
        // this.connectWebSocket();
    }

    public ngOnDestroy(): void {
        this.closeWebSocketConnection();
        this.destroy$.next();
        this.destroy$.complete();
    }

    public setWebsocketRunning(running: boolean): void {
        this._webSocket.running = false;
    }

    public connectWebSocket(): void {
        if (this._webSocket?.running) {
            return;
        }

        const protocols = [getTokenWithoutPrefix(this.token), this.productId, this.username]
            .filter(protocol => protocol !== '');

        this.closeWebSocketConnection();
        const socket =  new WebSocket(
            this.webSocketUrl,
            // [getTokenWithoutPrefix(this.authService.authToken), 'b0077932-1a1b-4094-ab61-af447fb4d9f9', 'test']
            protocols
        );

        this._webSocket = { websocket: socket, running: true };

        this.handleWebSocketEvents();
    }

    public closeWebSocketConnection(): void {
        this.webSocketCloseSubject.next();
        this.isWebSocketReadySubject.next(false);
        if (this.webSocket && !this.isWebSocketInClosedOrClosingState()) {
            this.webSocket.close();
            this._webSocket.running = false;
        }
    }

    private handleWebSocketEvents(): void {
        this.webSocketOpen$ = fromEvent(this.webSocket, WEB_SOCKET_EVENTS.OPEN)
            .pipe(takeUntil(this.webSocketCloseSubject));
        this.webSocketClose$ = fromEvent(this.webSocket, WEB_SOCKET_EVENTS.CLOSE)
            .pipe(takeUntil(this.webSocketCloseSubject));
        this.webSocketError$ = fromEvent(this.webSocket, WEB_SOCKET_EVENTS.ERROR)
            .pipe(takeUntil((this.webSocketCloseSubject)));

        this.webSocketOpen$.subscribe(() => {
            this.isWebSocketReadySubject.next(true);
        });

        merge(this.webSocketClose$, this.webSocketError$)
            .subscribe(() => {
                this.isWebSocketReadySubject.next(false);
                this.connectWebSocket();
                this.wsReconnected.emit(true);
            });
    }

    private handleAuthorizationStatusChange(): void {
        this.authService.isAuthorized$
            .pipe(takeUntil(this.destroy$))
            .subscribe((isAuthorized: boolean) => {
                if (isAuthorized) {
                    // this.connectWebSocket();
                    return;
                }

                this.closeWebSocketConnection();
            });
    }

    private isWebSocketInClosedOrClosingState(): boolean {
        return this.webSocket.readyState === WebSocket.CLOSED
            || this.webSocket.readyState === WebSocket.CLOSING;
    }
}

interface IWebSocket {
    websocket: WebSocket,
    running: boolean
}

enum WEB_SOCKET_EVENTS {
    OPEN = 'open',
    CLOSE = 'close',
    ERROR = 'error',
    MESSAGE = 'message',
}

export enum WEB_SOCKET_CODE {
    GOING_AWAY = 1001,
    ABNORMAL_CLOSURE = 1006
}
