<app-carousel *ngIf="config"
              [config]="{
                itemsPerList: config.itemsPerList,
                carouselId: config.carouselId,
                scrollStep: config.scrollStep,
                itemsToScrollAtOnce: config.itemsToScrollAtOnce
              }" [itemsCount]="config.selector ? _mediaList?.items?.length + 2 : _mediaList?.items?.length">
    <app-media-list *ngIf="_mediaList" [data]="_mediaList"
                    [selectorIndex]="selectorIndex"
                    [implicitFilters]="implicitFilters"
                    [config]="{
                        carouselLayout: true,
                        elementId: 'row-' + id,
                        selector: config?.selector,
                        moreButton: config?.moreButton
                    }"
                    [id]="'app-list-' + id"
                    (selectorClick$)="onSelectorClick($event)"
    ></app-media-list>
</app-carousel>
