import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import {SubAccountsService} from '@src/app/services/sub-accounts/sub-accounts.service';
import {ITableConfig, TableComponent} from 'ui-elements';
import {ISubAccount} from '@src/app/pages/subaccounts-page/model/sub-account.model';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {AuthService} from '@src/app/services/auth/auth.service';

@Component({
  selector: 'app-connected-publishers-page',
  templateUrl: './connected-publishers-page.component.html',
  styleUrls: ['./connected-publishers-page.component.sass'],
  providers: [AutoDestroyService, SubAccountsService]
})
export class ConnectedPublishersPageComponent implements OnInit {
    @ViewChild('actionsTmpl', {static: true}) public actionsTmpl: TemplateRef<any>;
    @ViewChild('tableComponent', {static: true}) public table: TableComponent;
    public tableConfig: ITableConfig<ISubAccount>;
    public searchQuery: string;
    public loading: boolean;
    constructor(
        private destroy$: AutoDestroyService,
        private subAccountsService: SubAccountsService,
        public dialog: MatDialog,
        private router: Router,
        private authService: AuthService
    ) {
    }

    ngOnInit(): void {
        this.tableConfig = {
            dataField: 'subaccounts',
            searchFn: (sortParams, pagingParams) => {
                return this.subAccountsService.linked(this.searchQuery, pagingParams, sortParams);
            },
            columns: [
                {
                    name: 'subaccounts.firstName',
                    dataField: 'firstName',
                    sortField: 'firstName'
                }, {
                    name: 'subaccounts.lastName',
                    dataField: 'lastName',
                    sortField: 'lastName'
                },
                {
                    name: 'subaccounts.email',
                    sortField: 'email',
                    dataField: 'email',
                    class: 'left'
                },
                {
                    name: 'streams.title.table.column.action',
                    tmpl: this.actionsTmpl,
                    class: 'actions'
                }
            ]

        };
    }

    public search(event: string): void {
        this.searchQuery = event;
        this.table.refreshData({});
    }

    public loginAs(id: string): void {
      // TODO implement login as
        this.loading = true;
        this.subAccountsService.loginAs(id).subscribe(res => {
            this.loading = false;
            if (!res) {
                return;
            }
            this.authService.logout(false);
            this.authService.storeToken(res.authToken);
            this.router.navigateByUrl('/', {skipLocationChange: true})
                .then(() => {
                    this.router.navigate(['streams']);
                });

        }, () => this.loading = false);
    }

}
