import {EventEmitter, Injectable} from '@angular/core';
import {ISideNavConfig} from '@src/app/components/side-nav/side-nav.component';

@Injectable({
  providedIn: 'root'
})
export class NavService {
  public navConfig$: EventEmitter<ISideNavConfig> = new EventEmitter();

  constructor() { }

  public clearNavConfig(): void {
    this.navConfig$.emit(null);
  }
}
