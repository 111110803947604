import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app-stream-marketing-tools-overlays',
    templateUrl: './stream-marketing-tools-overlays.component.html',
    styleUrls: ['./stream-marketing-tools-overlays.component.scss']
})
export class StreamMarketingToolsOverlaysComponent implements OnInit {
    config: IStreamMarketingToolsOverlaysConfig;

    constructor(
        private activatedRoute: ActivatedRoute
    ) {
    }

    ngOnInit(): void {
        this.activatedRoute.data.subscribe(res => {
            this.setConfig(res.overlaysType);
        });
    }

    private setConfig(type) {
        switch (type) {
            case StreamMarketingToolsOverlaysTypes.OVERLAY: {
                this.config = {
                    title: 'overlays.title',
                    subtitle: 'overlays.subtitle',
                    tableTitle: 'overlays.table.title',
                    description: false,
                    link: true,
                    newRoute: 'overlays',
                    dataField: 'overlays',
                    apiField: 'overlay',
                };
            }
                break;
            case StreamMarketingToolsOverlaysTypes.COURSES: {
                this.config = {
                    title: 'courses.title',
                    subtitle: 'courses.subtitle',
                    tableTitle: 'courses.table.title',
                    description: true,
                    link: false,
                    newRoute: 'courses',
                    dataField: 'courses',
                    apiField: 'course',
                };
            }
                break;
        }
    }
}

export enum StreamMarketingToolsOverlaysTypes {
    OVERLAY= 'overlay',
    COURSES = 'course',
    LEADGENERATION = 'leadgeneration',
}

export interface IStreamMarketingToolsOverlaysConfig {
    title: string;
    subtitle: string;
    tableTitle: string;
    description: boolean;
    link: boolean;
    newRoute: string;
    dataField: string;
    apiField: string;
    hideSearch?: boolean;
    hideCreateButton?: boolean;
}
