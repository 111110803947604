import {Injectable} from '@angular/core';
import {ApiService} from 'ui-elements';
import {BehaviorSubject, Observable, ReplaySubject} from 'rxjs';
import {API_URLS_APP} from '@src/app/constants/api-urls.constant';
import {HttpClient, HttpEvent, HttpRequest} from '@angular/common/http';
import * as tus from 'tus-js-client';
import {AuthService} from '@src/app/services/auth/auth.service';
import {getQueryParamsFromUrl} from '@src/app/utils/query-params.util';
import {LoadingService} from '@src/app/services/loading/loading.service';
import {environment} from '@src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class VideoUploadV2Service {
    constructor(
        private apiService: ApiService,
        private http: HttpClient,
        private authService: AuthService,
        private loadingService: LoadingService,
    ) {
    }

    private videoId$: BehaviorSubject<string>  = new BehaviorSubject<string>(null);

    public uploadVideo(file): Observable<HttpEvent<any>> {
        const formData: FormData = new FormData();

        formData.append('file', file);

        const req = new HttpRequest('POST', '/api/v1/' + API_URLS_APP.VIDEO_UPLOAD, formData, {
            reportProgress: true,
            responseType: 'json'
        });

        return this.http.request(req); // idk why reportProgress flag with apiService is not working
    }

    public uploadVideoTUS(file: File): BehaviorSubject<string> {
        const extension = file.name.split('.').pop();
        const token = this.authService.getAuthToken();
        const pLang = getQueryParamsFromUrl(window.location.href).pLang || 'EN';
        const url = environment.production ? 'https://upload.streamdust.tv/files/' : 'https://cms.upload.streamdust.ano-services.com/files/';


        const upload = new tus.Upload(file, {
            endpoint: url,
            retryDelays: [0, 3000, 6000, 12000, 24000],
            chunkSize: 1048576 * 20, // 1024 * 1024 * 20 = 20 MB
            metadata: {
                authToken: token,
                language: pLang,
                extension: extension,
                env: environment.production ? 'prod' : 'test'
            },
            onError: async (error) => {
                console.log(error);
                this.loadingService.loadingEnd();
                this.clearVideoId();
                return false;
            },
            onChunkComplete: (chunkSize, bytesAccepted, bytesTotal) => {
                const progress = Math.floor(bytesAccepted / bytesTotal * 100);
                this.loadingService.updateProgress(progress);
            },
            onSuccess: async () => {
                setTimeout(() => {
                    this.videoId$.next(this.getIdFromTUSUploadUrl(upload.url));
                }, 2000);
            }
        });
        upload.start();
        return this.videoId$;
    }

    private getIdFromTUSUploadUrl(url: string): string {
        return url.split('/').pop();
    }

    public clearVideoId(): void {
        this.videoId$.next(null);
    }
}
