import { Injectable } from '@angular/core';
import { ApiService } from 'ui-elements';
import { Observable } from 'rxjs';
import { IResponse } from '@src/app/models/response.model';
import { API_URLS_APP } from '@src/app/constants/api-urls.constant';

@Injectable({
  providedIn: 'root'
})
export class PasswordForgottenPageService {

  constructor(private apiService: ApiService) { }

  public remindPassword(email: string): Observable<IResponse> {
    return this.apiService.post<IResponse>(API_URLS_APP.PASSWORD_FORGOTTEN, { email });
  }
}
