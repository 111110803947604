import {Component, OnInit} from '@angular/core';
import {IFormConfig, IFormFieldsConfig, StreamService} from '@src/app/services/stream-metadata/stream.service';
import {IStreamRecordModel} from '@src/app/models/stream.model';
import {INPUT_TYPES} from 'ui-elements';
import {FormBuilder, FormGroup} from '@angular/forms';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {RouterEventHandlerService} from '@src/app/services/router-event-handler/router-event-handler.service';
import {LoadingService} from '@src/app/services/loading/loading.service';
import {switchMap, takeUntil, tap} from 'rxjs/operators';
import {FormUtil} from '@src/app/utils/form.util';
import { CmsService, IStreamGenre } from 'ui-elements';

@Component({
    selector: 'app-stream-record',
    templateUrl: './stream-record.component.html',
    styleUrls: ['./stream-record.component.css']
})
export class StreamRecordComponent implements OnInit {

    public formsConfig: IFormConfig[];
    public initialValue: IStreamRecordModel;
    public streamId: string;
    public recordId: string;
    public genres: IStreamGenre[];
    public INPUT_TYPES = INPUT_TYPES;
    public fieldsConfig: IFormFieldsConfig[][];
    public loading = false;
    private valid = true;

    constructor(
        private fb: FormBuilder,
        private streamService: StreamService,
        private readonly destroy$: AutoDestroyService,
        private readonly activatedRoute: ActivatedRoute,
        private router: Router,
        private routerEventService: RouterEventHandlerService,
        private loadingService: LoadingService,
        private cmsService: CmsService
    ) {
    }

    ngOnInit(): void {
        // this.formsConfig = this.streamService.getStreamRecordFormsConfig();

        this.cmsService.getGenres().pipe(takeUntil(this.destroy$)).subscribe(result => {
            this.genres = result;
            const genre = this.formsConfig
                .find(form => form.name === 'recording')
                .formFields.find(formField => formField[0].name === 'genre');
            if (genre) {
                genre[0].config.selectOptions = this.cmsService.mapGenresToKeyValue(this.genres);
            }
            this.activatedRoute.parent.params.pipe(
                takeUntil(this.destroy$),
                tap(({id}: Params) => {
                    this.streamId = id;
                }),
                switchMap(() => {
                    return this.activatedRoute.params
                }),
                switchMap(({id}: Params) => {
                    this.recordId = id;
                    this.loadingService.loadingStart();
                    return this.streamService.getStreamRecord(this.streamId, this.recordId);
                }))
                .subscribe((res) => {
                    this.loadingService.loadingEnd();
                    if (!res) {
                        return;
                    }
                    this.initialValue = res;
                    this.parseStreamRecordData(this.initialValue, true);
                });
        });
    }

    private checkFormValidity(form: FormGroup): void {
        form.markAllAsTouched();
        form.updateValueAndValidity();
        if (form.invalid) {
            this.valid = false;
        }
    }

    private parseStreamRecordData(record, initial = true) {
        if (initial) {
            const price = (record.recording.price as any);
            record.recording.priceAmount = price.amount;
            record.recording.priceCurrency = price.currency;
        } else {
          // this.formsConfig = this.streamService.getStreamRecordFormsConfig();
        }
        this.formsConfig = FormUtil.mapResponseToForms<IStreamRecordModel>(record, this.formsConfig);
    }

    public submit(): void {
        let recording = FormUtil.mapFormsToPayload<IStreamRecordModel>(this.formsConfig);
        this.formsConfig.forEach(formConfig => {
            if (formConfig.form) {
                if (formConfig.isToggle && !formConfig.enabled) {
                    return;
                }
                this.checkFormValidity(formConfig.form);
            }
            if (formConfig.forms) {
                if (formConfig.isToggle && !formConfig.enabled) {
                    return;
                }
                formConfig.forms.forEach(form => this.checkFormValidity(form));
            }
        });
        if (!this.valid) {
            this.valid = true;
            return;
        }
        this.loadingService.loadingStart();

        recording.recording.streamId = this.streamId;
        recording.recording.recordingId = this.recordId;

        this.streamService.updateStreamRecord(recording)
            .pipe(takeUntil(this.destroy$))
            .subscribe((res) => {
                this.loadingService.loadingEnd();
                if (!res.success) {
                    return;
                }
                this.updateStreamRecord();
            });
    }

    cancel(): void {
        this.parseStreamRecordData(this.initialValue, false);
    }

    public toggle(event, childForm: string): void {
        if (!childForm) {
            return;
        }
        if (!event) {
            this.formsConfig.find(form => form.name === childForm).form.get('enabled').patchValue(event);
        }
    }

    public addForm(formConfig: IFormConfig): void {
        formConfig.forms.push(FormUtil.buildForm(formConfig.formsFields));
    }

    public removeForm(formConfig: IFormConfig, indexToRemove: number): void {
        FormUtil.unselectValues(formConfig, indexToRemove);
        formConfig.forms.splice(indexToRemove, 1);
    }

    private updateStreamRecord(): void {
        this.streamService.getStreamRecord(this.streamId, this.recordId)
            .pipe(takeUntil(this.destroy$))
            .subscribe((res) => {
                this.initialValue = res;
                this.parseStreamRecordData(this.initialValue);
            });
    }

}
