<div class="timeline-container {{cssColorClass}} {{cssClass}}">
    <h3 class="title">{{dateTranlate | localize}}</h3>
    <div class="row row-padding-5">
        <div class="col-auto">
            <div class="date-time">
                <span class="date-time-title">{{actualDate && finished ? 'Actual' : 'Scheduled'}}</span>
                <span class="date">{{actualDate && finished ? formattedActualDate : formattedDate}}</span>
                <span class="time">{{actualDate && finished? formattedTimeActual : formattedTime}}</span>
            </div>
        </div>
        <div class="col">
            <div class="date-time"
                 *ngIf="showCountdown">
                <span class="date-time-title"></span>
                <span class="title text-lowercase">{{'stream.timeline.countdown' | localize}}</span>
                <span class="time">{{now | dateUntil: date }}</span>
            </div>
            <div *ngIf="actualDate" class="date-time small-date-time">
                <span class="date-time-title">{{finished ? 'Scheduled' : 'Actual'}}</span>
                <span class="date">{{finished ? formattedDate : formattedActualDate}}</span>
                <span class="time">{{finished ? formattedTime :formattedTimeActual}}</span>
            </div>
        </div>
    </div>
</div>
