import { Pipe, PipeTransform } from '@angular/core';
import { IAccessProps } from '@src/app/components/streamdust-player/streamdust-player.component';

@Pipe({
  name: 'accessRestricted',
  pure: false,
})
export class AccessRestrictedPipe implements PipeTransform {

  transform(accessProps: IAccessProps): boolean {
    if (accessProps.isStreamFinished) {
        return false;
    }
    return !accessProps.fullAccess
        || accessProps.isTeaserOrTrailer
        || accessProps.onlyForRegistered
        || accessProps.showRegistrationOffer
        || accessProps.showLogin;
  }

}
