import {Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild} from '@angular/core';
import {IFormConfig} from '@src/app/services/stream-metadata/stream.service';
import {FormGroup} from '@angular/forms';
import {BehaviorSubject} from 'rxjs';
import {AutoDestroyService, CURRENCY_SYMBOLS, CURRENT_CURRENCY, DateHelper} from 'ui-elements';
import {takeUntil} from 'rxjs/operators';
import {BorderedFormComponent} from '@src/app/components/bordered-form/bordered-form.component';
import {PAYMENT_SYSTEM} from 'ui-elements';

@Component({
  selector: 'app-bordered-form-preview',
  templateUrl: './bordered-form-preview.component.html',
  styleUrls: ['./bordered-form-preview.component.scss'],
  providers: [AutoDestroyService]
})
export class BorderedFormPreviewComponent implements OnInit {

  @Input() public formConfig: IFormConfig;
  @Input() public form: FormGroup;
  @Input() public disabled: boolean;
  @Input() public addLimitReached: boolean;
  @Input() public readonly: boolean;
  @Input() public index: number;
  @Output() public add$: EventEmitter<any> = new EventEmitter();
  @Output() public remove$: EventEmitter<any> = new EventEmitter();
  @ViewChild('borderedForm', {static: true}) public borderedForm: BorderedFormComponent;

  public PAYMENT_SYSTEM = PAYMENT_SYSTEM;
  public previewDisabled = false;
  public previewData: any;
  public currencyProfile: string;
  public backupData: any;

  constructor(
      private destroy$: AutoDestroyService,
      @Inject(CURRENT_CURRENCY) private currentCurrency: BehaviorSubject<string>,
  ) { }

  ngOnInit(): void {
    if (!this.formConfig.preview) {
      return;
    }
    this.previewData = {...this.formConfig.forms[this.index].value};
    this.backupData = this.formConfig.forms[this.index].value;
    this.formatDate();
    this.previewDisabled = !this.formConfig.forms[this.index].value.title;
    if (this.currentCurrency) {
      this.currentCurrency
          .pipe(takeUntil(this.destroy$))
          .subscribe((currency) => {
            if (!currency) {
              return;
            }
            this.currencyProfile = CURRENCY_SYMBOLS[currency];
          });
    }
  }

  public addForm() {
    this.add$.emit();
  }

  public removeForm() {
    this.remove$.emit();
  }

  public hidePreview() {
    this.previewDisabled = true;
  }

  public applyChanges() {
    if (!this.formConfig.forms[this.index].valid) {
      return;
    }
    this.previewData = {...this.formConfig.forms[this.index].value};
    this.formatDate();
    this.backupData = this.formConfig.forms[this.index].value;
    this.previewDisabled = false;
  }

  public close() {
    if (!this.formConfig.forms[this.index].valid) {
      return;
    }
    this.formConfig.forms[this.index].patchValue(this.backupData);
    this.previewDisabled = false;
  }

  public formatDate() {
    if (this.previewData.startTimestamp > 8) {
      this.previewData.startTimestamp = DateHelper.formatDateTime(this.previewData.startTimestamp, 'ddd MMM DD yyyy HH:mm:ss ');
      this.previewData.endTimestamp = DateHelper.formatDateTime(this.previewData.endTimestamp, 'ddd MMM DD yyyy HH:mm:ss ');
      return;
    }
    const language = localStorage.getItem('localLanguage');
    const hrs = language === 'EN' ? ' h' : ' Uhr';
    this.previewData.startTimestamp += hrs;
    this.previewData.endTimestamp += hrs;
  }

}
