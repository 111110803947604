import {Component, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren} from '@angular/core';
import {StreamService} from '@src/app/services/stream-metadata/stream.service';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {IMediaMetadata, ITimerange} from '@src/app/models/stream.model';
import {ITableConfig, ITimeRangeConfig, LocalizationProvider, SnackBarService, TableComponent} from 'ui-elements';
import {takeUntil} from 'rxjs/operators';
import {DateHelper} from '@src/app/utils/date.helper';
import * as moment from 'moment';
import {ITEM_TYPE, SETTING_TYPE} from '@src/app/pipes/item-setting';
import {FILTER_MEDIA_TYPES} from '@src/app/components/media-list/media-list-header/media-list-header.component';
import {UserService} from '@src/app/services/user/user.service';
import {MEDIA_FILTERS_FIELDS, MediaListService} from '@src/app/services/media-list/media-list.service';
import {IListQuery, LOAD_TYPE, MediaListComponent} from '@src/app/components/media-list/media-list.component';
import {ISuggestedMedia} from '@src/app/pages/public-media-page/public-media-page.component';
import {forkJoin} from 'rxjs';
import {IMediaItem} from '@src/app/models/core.model';
import {MEDIA_TYPE} from '@src/app/components/streamdust-player/constants/mediaType';
import {LoadingService} from '@src/app/services/loading/loading.service';
import {STREAM_STATUS} from '@src/app/components/streamdust-player/constants/status';

@Component({
    selector: 'app-publisher-dashboard',
    templateUrl: './publisher-dashboard.component.html',
    styleUrls: ['./publisher-dashboard.component.scss']
})
export class PublisherDashboardComponent implements OnInit {
    public MEDIA_TYPE = MEDIA_TYPE;
    @ViewChild('confirm', {static: false}) confirm: TemplateRef<any>;
    @ViewChild('actionsTmpl', {static: true}) public actionsTmpl: TemplateRef<any>;
    @ViewChild('statusTmpl', {static: true}) public statusTmpl: TemplateRef<any>;
    @ViewChild('titleTmpl', {static: true}) public titleTmpl: TemplateRef<any>;
    @ViewChild('priceTmpl', {static: true}) public priceTmpl: TemplateRef<any>;
    @ViewChild('dateTmpl', {static: true}) public dateTmpl: TemplateRef<any>;
    @ViewChild('tableComponent', {static: true}) public table: TableComponent;
    @ViewChildren('mediaLists') public mediaLists: QueryList<MediaListComponent>;
    public noRecordsMediaTableConfig: ITableConfig<IMediaItem>;
    searchQuery: string = '';
    public streamsYears: number[];
    public searchStreamsTimeRange: ITimerange;
    public confirmTextTpl = 'Möchten Sie löschen {streamName}?';
    public confirmText = '';
    ITEM_TYPE = ITEM_TYPE;
    SETTING_TYPE = SETTING_TYPE;
    timeRangeFilterConfig: ITimeRangeConfig;

    publisherMediaConfigs: ISuggestedMedia[];

    LOAD_TYPE = LOAD_TYPE;

    dataLoaded = false;

    constructor(
        public streamsService: StreamService,
        public destroy$: AutoDestroyService,
        public dialog: MatDialog,
        private router: Router,
        public userService: UserService,
        private mediaListService: MediaListService,
        private localizationProvider: LocalizationProvider,
        private loadingService: LoadingService,
        private snackBarService: SnackBarService,
    ) {
        // this.streamsService.getStreamYears().subscribe(years => {
        //     this.streamsYears = years;
        // });
    }

    ngOnInit(): void {
        this.loadingService.loadingStart();
        this.userService.userProfile$.pipe(takeUntil(this.destroy$)).subscribe(data => {
            if (data && !this.dataLoaded) {
                this.dataLoaded = true;
                this.timeRangeFilterConfig = this.getTimeRangeFilterConfig(data.ownerId);
                this.publisherMediaConfigs = this.getPublisherMediaConfigs(data.ownerId);
                this.setupMediaListsItems();
                this.setNoRecordsMediaTableConfig(data.ownerId);
            }
        });

    }

    getTimeRangeFilterConfig(ownerId): ITimeRangeConfig {
        return {
            fetchMethod: (filters) => this.mediaListService.getMediaListTimeRanges(filters),
            filters: [
                {
                    field: MEDIA_FILTERS_FIELDS.MEDIA_TYPE,
                    value: FILTER_MEDIA_TYPES.STREAM
                },
                {
                    field: MEDIA_FILTERS_FIELDS.STREAM_STATUS,
                    value: STREAM_STATUS.FINISHED
                },
                {
                    field: MEDIA_FILTERS_FIELDS.PUBLISHER,
                    value: ownerId
                },
                {
                    field: MEDIA_FILTERS_FIELDS.VISIBLE,
                    value: false
                }
            ]
        };
    }

    getPublisherMediaConfigs(ownerId): ISuggestedMedia[] {
        return [
            {
                title: 'publisher-dashboard.media-lists.actual-streams',
                implicitFilters: [
                    {
                        field: MEDIA_FILTERS_FIELDS.PUBLISHER,
                        value: ownerId
                    },
                    {
                        field: MEDIA_FILTERS_FIELDS.MEDIA_TYPE,
                        value: FILTER_MEDIA_TYPES.STREAM
                    },
                    {
                        field: MEDIA_FILTERS_FIELDS.STREAM_STATUS,
                        value: [
                            STREAM_STATUS.ACTIVATED, STREAM_STATUS.ACTIVATION,
                            STREAM_STATUS.CONNECTED, STREAM_STATUS.PAUSED, STREAM_STATUS.LIVE]

                    },
                    {
                        field: MEDIA_FILTERS_FIELDS.VISIBLE,
                        value: false
                    }
                ],
                fetchMethod: (filters: IListQuery) => this.mediaListService.getMediaList(filters, true, true),
            },
            {
                title: 'publisher-dashboard.media-lists.future-streams',
                implicitFilters: [
                    {
                        field: MEDIA_FILTERS_FIELDS.PUBLISHER,
                        value: ownerId
                    },
                    {
                        field: MEDIA_FILTERS_FIELDS.MEDIA_TYPE,
                        value: FILTER_MEDIA_TYPES.STREAM
                    },
                    {
                        field: MEDIA_FILTERS_FIELDS.STREAM_STATUS,
                        value: STREAM_STATUS.SCHEDULED

                    },
                    {
                        field: MEDIA_FILTERS_FIELDS.VISIBLE,
                        value: false
                    }
                ],
                showAddStreamButton: true,
                fetchMethod: (filters: IListQuery) => this.mediaListService.getMediaList(filters),
                originalLength: 1
            },
            {
                title: 'publisher-dashboard.media-lists.pending-streams',
                implicitFilters: [
                    {
                        field: MEDIA_FILTERS_FIELDS.PUBLISHER,
                        value: ownerId
                    },
                    {
                        field: MEDIA_FILTERS_FIELDS.MEDIA_TYPE,
                        value: FILTER_MEDIA_TYPES.STREAM
                    },
                    {
                        field: MEDIA_FILTERS_FIELDS.STREAM_STATUS,
                        value: STREAM_STATUS.PENDING
                    },
                    {
                        field: MEDIA_FILTERS_FIELDS.VISIBLE,
                        value: false
                    }
                ],
                fetchMethod: (filters: IListQuery) => this.mediaListService.getMediaList(filters),
            },
            {
                title: 'publisher-dashboard.media-lists.recorded-streams',
                implicitFilters: [
                    {
                        field: MEDIA_FILTERS_FIELDS.PUBLISHER,
                        value: ownerId
                    },
                    {
                        field: MEDIA_FILTERS_FIELDS.MEDIA_TYPE,
                        value: FILTER_MEDIA_TYPES.STREAM_RECORDING
                    },
                    {
                        field: MEDIA_FILTERS_FIELDS.VISIBLE,
                        value: false
                    }
                ],
                fetchMethod: (filters: IListQuery) => this.mediaListService.getMediaList(filters)
            },
            {
                title: 'publisher-dashboard.media-lists.rescheduling-streams',
                implicitFilters: [
                    {
                        field: MEDIA_FILTERS_FIELDS.PUBLISHER,
                        value: ownerId
                    },
                    {
                        field: MEDIA_FILTERS_FIELDS.MEDIA_TYPE,
                        value: FILTER_MEDIA_TYPES.STREAM
                    },
                    {
                        field: MEDIA_FILTERS_FIELDS.STREAM_STATUS,
                        value: STREAM_STATUS.RESCHEDULING
                    },
                    {
                        field: MEDIA_FILTERS_FIELDS.VISIBLE,
                        value: false
                    }
                ],
                fetchMethod: (filters: IListQuery) => this.mediaListService.getMediaList(filters),
            }
        ]
    }

    goToMediaDetails(media): void {
        const route = media.videoType === MEDIA_TYPE.VIDEO_ON_DEMAND ? 'vod' : 'streams';
        this.router.navigate([route, media.id, 'info']);
    }

    setupMediaListsItems(): void {
        forkJoin({
            actualStreams: this.mediaListService.getMediaList({
                filters: this.publisherMediaConfigs[0].implicitFilters
            }, true , true),
            futureStreams: this.mediaListService.getMediaList({
                filters: this.publisherMediaConfigs[1].implicitFilters,
                sort: [
                    {
                        order: 'ASC',
                        field: 'DATE'
                    }
                ],
                // paging: {
                //     page: 0,
                //     itemsOnPage: 5
                // }
            }, true , true),
            recordedStreams: this.mediaListService.getMediaList({
                filters: this.publisherMediaConfigs[2].implicitFilters
            }, true , true),
            rescheduledStreams: this.mediaListService.getMediaList({
                filters: this.publisherMediaConfigs[3].implicitFilters
            }, true , true),
            pendingStreams: this.mediaListService.getMediaList({
                filters: this.publisherMediaConfigs[4].implicitFilters
            }, true , true),
        }).pipe(takeUntil(this.destroy$)).subscribe(result => {
            Object.keys(result).forEach((key, index) => {
                this.publisherMediaConfigs[index].media = result[key].results.data;
                if (index !== 1) {
                    this.publisherMediaConfigs[index].originalLength = result[key].results.data?.items?.length;
                }
            });

            this.publisherMediaConfigs = this.publisherMediaConfigs.filter(suggestedMedia => suggestedMedia.originalLength);

            this.loadingService.loadingEnd();
        });
    }

    setNoRecordsMediaTableConfig(ownerId): void {
        this.noRecordsMediaTableConfig = {
            filtersWidth100: true,
            dataField: 'data',
            searchFn: (sortParams, pagingParams) => {
                const filters = [
                    {
                        field: MEDIA_FILTERS_FIELDS.PUBLISHER,
                        value: ownerId
                    },
                    {
                        field: MEDIA_FILTERS_FIELDS.MEDIA_TYPE,
                        value: FILTER_MEDIA_TYPES.STREAM
                    },
                    {
                        field: MEDIA_FILTERS_FIELDS.STREAM_STATUS,
                        value: [STREAM_STATUS.FINISHED, STREAM_STATUS.DEACTIVATED]
                    },
                    {
                        field: MEDIA_FILTERS_FIELDS.VISIBLE,
                        value: false
                    },
                    {
                        field: MEDIA_FILTERS_FIELDS.DEACTIVATED,
                        value: 'DEACTIVATED_ALLOWED'
                    }
                ];
                const query: IListQuery = {
                    searchTerm: this.searchQuery,
                    paging: pagingParams,
                    filters,
                    sort: sortParams,
                    timeRange: this.searchStreamsTimeRange
                }
                return this.mediaListService.getMediaList(query, true, true);
            },
            columns: [
                {
                    name: 'streams.title.table.column.status',
                    tmpl: this.statusTmpl,
                    class: 'left'
                }, {
                    name: 'streams.title.table.column.livestream',
                    sortField: 'TITLE',
                    tmpl: this.titleTmpl,
                    class: 'left'
                },
                {
                    name: 'streams.title.table.column.price',
                    sortField: 'PRICE',
                    tmpl: this.priceTmpl,
                    class: 'right'
                },
                {
                    name: 'streams.title.table.column.live_date',
                    sortField: 'DATE',
                    tmpl: this.dateTmpl,
                    class: 'left'
                },
                {
                    name: 'streams.title.table.column.action',
                    tmpl: this.actionsTmpl,
                    class: 'center'
                }
            ]

        };
    }

    public formatDate(timestamp: bigint): string {
        return DateHelper.formatDateTime(timestamp);
    }

    navigateToStream(streamId) {
        this.router.navigate(['streams', streamId, 'info']);
    }

    public confirmDeleteStream(stream: IMediaMetadata | IMediaItem): void {
        this.confirmText = stream.title + ' ' + this.localizationProvider.getByKey('publisher-dashboard.media-lists.remove-stream.confirm-body');
        const dialogRef = this.dialog.open(this.confirm);
        dialogRef.afterClosed()
            .pipe(takeUntil(this.destroy$))
            .subscribe((confirm: boolean) => {
                this.confirmText = '';
                if (confirm) {
                    this.deleteStream(stream);
                }
            });
    }

    public deleteStream(stream, mediaListIndex?): void {
        let mediaListToRefresh;
        if (mediaListIndex || mediaListIndex === 0) {
            mediaListToRefresh = this.mediaLists.find((item, index) => index === mediaListIndex);
            mediaListToRefresh.setFiltrationLoading(true);
        }
        this.streamsService.deleteStream(stream.id)
            .pipe(takeUntil(this.destroy$))
            .subscribe(res => {
                if (res.success) {
                    if (mediaListToRefresh) {
                        mediaListToRefresh.refreshList();
                        --this.publisherMediaConfigs[mediaListIndex].originalLength;
                    } else {
                        this.table.resetTableData();
                        this.table.refreshData({});
                    }
                }
                if (res.errorKey === 'STREAM_RUNNING') {
                    if (mediaListToRefresh) {
                        mediaListToRefresh.refreshList();
                        --this.publisherMediaConfigs[mediaListIndex].originalLength;
                    } else {
                        this.table.resetTableData();
                        this.table.refreshData({});
                    }
                    this.snackBarService.openError(this.localizationProvider.getByKey('streams.delete.error'));
                }
            });
    }

    public timeRangeFilter(event): void {
        this.searchStreamsTimeRange = event;
        this.table.refreshData({});
    }

    public search(event: string): void {
        this.searchQuery = event;
        this.table.refreshData({});
    }

    public searchByYear(event: string): void {
        if (event) {
            this.searchStreamsTimeRange = {
                start: moment(event).startOf('year').toDate().getTime(),
                end: moment(event).endOf('year').toDate().getTime(),
            };
        } else {
            this.searchStreamsTimeRange = null;
        }
        this.table.refreshData({});
    }

    public goToNewStream(): void {
        this.router.navigate(['stream/new']);
    }

}
