<div class="linked-streams-body">
    <div *ngIf="data && !data?.hasAccess" class="group-info">
        <p>{{'group.modal.access.description' | localize}}</p>
        <p class="price"><b>{{data.price | currency}}</b></p>
        <ano-button (click)="proceedPayment()"
                    [config]=" { styleType: 'success', sizeType: 'block'}">{{'group.modal.access.payment' | localize}}</ano-button>
    </div>
    <div class="linked-streams">
        <div class="linked-streams-nav" *ngIf="numberOfSlides > 3">
            <div class="linked-streams-prev"
                 *ngIf="sliderArrow.leftArrow"
                 (click)="moveLeft()">
                <i class="icon icon-prev"></i>
            </div>
            <div class="linked-streams-next"
                 *ngIf="sliderArrow.rightArrow"
                 (click)="moveRight()">
                <i class="icon icon-next"></i>
            </div>
        </div>
        <div class="row row-padding-5 flex-nowrap">
            <drag-scroll #sliderComp class="wrapper-slider">
                <div drag-scroll-item class="col-4 item-wrap"
                     *ngFor="let media of data.mediaItems" (click)="goToMedia(media)">
                    <div class="item bg-item" [ngStyle]="{'background-image': getBackground(media)}">
                        <div class="streams-item-header">
                                    <span class="status live" *ngIf="media?.status?.stream === STREAM_STATUS.LIVE">
                                        <i class="icon-dot-5"></i>
                                        Live
                                    </span>
                            <h3 class="title">{{media.title}}</h3>
                            <p>{{media.description | croppedText:120}}</p>
                        </div>
                        <div class="stream-item-footer">
                                    <span class="text">
                                        {{media?.startTimestamp | dateFormat: 'DD MMM YYYY, HH:mm'}} |
                                        {{media?.genre  | keyToValue: genres}} |
                                        {{ getCurrency(media) ? (getCurrency(media) | currency) : 'Free'}}
                                    </span>
                        </div>
                    </div>
                </div>
            </drag-scroll>
        </div>
    </div>
</div>
