import {Component, OnInit, ViewChild, ElementRef, AfterViewInit, AfterContentInit} from '@angular/core';
import {IMediaItem} from '@src/app/models/core.model';
import {LandingService} from '@src/app/services/landing/landing.service';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import {pluck, switchMap, take, takeUntil, tap} from 'rxjs/operators';
import {IListFilterItem, IListQuery} from '@src/app/components/media-list/media-list.component';
import {IUnitKeyValue, LocalizationProvider} from 'ui-elements';
import {environment} from '@src/environments/environment';
import {ITariffPlanConfig, TARIFF_PLAN_TYPES} from '@src/app/components/tariff-plan-cards-list/tariff-plan-cards-list.component';
import {
    ISubscriptionTariffPlan,
    TARIFF_PLAN_PERIOD,
    ISubscriptionTariffPlans
} from '@src/app/components/tariff-plan-cards-list/subscription-plan-card/subscription-plan-card.component';
import {SubscriptionManageService} from '@src/app/services/subscription-manage/subscription-manage.service';
import {ActivatedRoute, Router} from '@angular/router';
import {CmsService} from 'ui-elements';
import {MEDIA_FILTERS_FIELDS, MediaListService} from '@src/app/services/media-list/media-list.service';
import {MEDIA_TYPE} from '@src/app/components/streamdust-player/constants/mediaType';
import {STREAM_STATUS} from '@src/app/components/streamdust-player/constants/status';
import {ISuggestedMedia} from '@src/app/pages/public-media-page/public-media-page.component';
import {FILTER_TYPES} from '@src/app/components/media-list/media-list-header/media-list-header.component';
import {Location, ViewportScroller} from '@angular/common';
import {AuthService} from '@src/app/services/auth/auth.service';
import {ApiService} from 'ui-elements';
import {HeaderPopupType, HeaderService} from '@src/app/services/header/header.service';
import {Observable} from 'rxjs';
import {IResponse} from '@src/app/models/response.model';
import {
    EmailConfirmationPageService
} from '@src/app/pages/email-confirmation-page/services/email-confirmation-page/email-confirmation-page.service';
import {RouterEventHandlerService} from '@src/app/services/router-event-handler/router-event-handler.service';

@Component({
    selector: 'app-landing-data',
    templateUrl: './landing-data.component.html',
    styleUrls: ['./landing-data.component.scss'],
    providers: [AutoDestroyService]
})
export class LandingDataComponent implements OnInit, AfterViewInit, AfterContentInit {
    @ViewChild('streamList') streamList;
    @ViewChild('detailsTable', {static: false}) detailsTable: ElementRef<HTMLDivElement>;

    public partnersList: ReadonlyArray<ImageLinkType> = new Array<ImageLinkType>(
        {
            image: '../../../../assets/img/partner/streamprofis.jpg',
            link: 'https://streamprofis.de'
        },
        {
            image: '../../../../assets/img/partner/filmreif.jpg',
            link: 'https://filmreif.biz/#!portfolio-item/fairtrade/ '
        },
        {
            image: '../../../../assets/img/partner/liveu.jpg',
            link: 'https://www.liveu.tv'
        },
        {
            image: '../../../../assets/img/partner/boinxsoftware.jpg',
            link: 'https://boinx.com'
        },
        {
            image: '../../../../assets/img/partner/medien_netzwerk_nrw.png',
            link: 'https://medien.nrw'
        },
        {
            image: '../../../../assets/img/partner/zoom_entertainment.png',
            link: 'https://vr.zoom-entertainment.de '
        }
    );

    public customersList: ReadonlyArray<ImageLinkType> = new Array<ImageLinkType>(
        {
            image: '../../../../assets/img/customer/alphanet.jpg',
            link: 'https://www.alphanet.de'
        },
        {
            image: '../../../../assets/img/customer/camlog.jpg',
            link: 'https://www.camlog.de'
        },
        {
            image: '../../../../assets/img/customer/nmc.jpg',
            link: 'https://nmc-productions.tv'
        },
        {
            image: '../../../../assets/img/customer/rheinland-pitch.jpg',
            link: 'https://rheinlandpitch.de '
        },
        {
            image: '../../../../assets/img/customer/epson.jpg',
            link: 'https://www.epson.de '
        }
    );
    actualStreamsList: IMediaItem[] = [];
    public genres: IUnitKeyValue[];
    subscriptionTariffPlans: ISubscriptionTariffPlans;
    isAuthorized: boolean;

    public actualStreamFilters: IListQuery = {
        searchTerm: null,
        paging: {
            page: 0,
            itemsOnPage: 3
        },
        sort: [
            {
                order: 'DESC',
                field: 'DATE'
            }
        ]
    };

    tariffPlansConfig: ITariffPlanConfig = {
        planType: TARIFF_PLAN_TYPES.SUBSCRIPTION,
        planPeriod: TARIFF_PLAN_PERIOD.MONTH,
        showPeriodToggle: true
    };
    FILTER_TYPE = FILTER_TYPES;
    actualStreamsListConfig: ISuggestedMedia = {
        title: 'stream.actual-livestreams-page.title',
        thumbnailGrayScale: true,
        fetchMethod: (filters: IListQuery) => this.mediaListService.getMediaList(filters),
        filters: {
            filterTypes: [this.FILTER_TYPE.SORT, this.FILTER_TYPE.GENRE, this.FILTER_TYPE.CONTENT_ACCESS_TYPE, this.FILTER_TYPE.COUNTRY],
            mediaTypes: []
        }
    };
    implicitFilters: IListFilterItem[] = [
        {
            field: MEDIA_FILTERS_FIELDS.MEDIA_TYPE,
            value: MEDIA_TYPE.STREAM
        },
        {
            field: MEDIA_FILTERS_FIELDS.STREAM_STATUS,
            value: [
                STREAM_STATUS.SCHEDULED,
                STREAM_STATUS.ACTIVATION,
                STREAM_STATUS.ACTIVATED,
                STREAM_STATUS.CONNECTED,
                STREAM_STATUS.PAUSED,
                STREAM_STATUS.LIVE,
            ],
        },
    ];
    activeFilters: IListQuery = {
        paging: {
            page: 0,
            itemsOnPage: 6
        },
        sort: [
            {
                order: 'DESC',
                field: 'STREAM_LIVE'
            },
            {
                field: 'DATE',
                order: 'ASC'
            }
        ],
        filters: this.implicitFilters
    };

    ApiService = ApiService;

    constructor(
        private landingService: LandingService,
        private destroy$: AutoDestroyService,
        private cmsService: CmsService,
        private router: Router,
        private subscriptionManageService: SubscriptionManageService,
        public mediaListService: MediaListService,
        public activatedRoute: ActivatedRoute,
        public viewportScroller: ViewportScroller,
        public authService: AuthService,

        private route: ActivatedRoute,
        private emailConfirmationPageService: EmailConfirmationPageService,
        private localizationProvider: LocalizationProvider,
        private routerEventHandlerService: RouterEventHandlerService,
        private headerService: HeaderService,
        private location: Location
    ) {
    }

    ngOnInit(): void {
        this.scrollToFragment();
        this.checkCurrentRoute();

        this.genres = this.cmsService.genres;
        // this.landingService.getActualStreams(this.actualStreamFilters).pipe(takeUntil(this.destroy$)).subscribe(response => {
        //     this.actualStreamsList = response?.results?.data?.items || [];
        // });

        this.mediaListService.getMediaList(this.activeFilters).pipe(takeUntil(this.destroy$)).subscribe(response => {
            this.actualStreamsListConfig.media = response?.results?.data;
            this.actualStreamsListConfig.originalLength = response?.results?.data?.items?.length;
            // this.loadingService.loadingEnd();
        });
        const video = document.getElementById('landing-video') as any;
        video.oncanplaythrough = function () {
            video.muted = true;
            video.play();
        };

        this.authService.isAuthorized$.pipe(takeUntil(this.destroy$))
            .subscribe(() => this.getAllTariffs());
    }

    ngAfterViewInit(): void {
    }

    getImgUrl(url: string): string {
        return environment.backendApiHost + url;
    }

    onShowStream(item: IMediaItem): void {
        this.router.navigate(['watch/stream/' + item.id]);
    }

    onShowGruppe(item: IMediaItem): void {

    }

    getAllTariffs(): void {
        this.subscriptionManageService.getSubscriptionTariffs().pipe(take(1)).subscribe(data => {
            this.subscriptionTariffPlans = Object.keys(data.data.tariffPlans).length ? data.data.tariffPlans : null;
            this.isAuthorized = data.authorized;
            if (this.isAuthorized) {
                return;
            }
            this.subscriptionTariffPlans.EUR = this.subscriptionTariffPlans.EUR.sort(this.sortPlans);
            this.subscriptionTariffPlans.USD = this.subscriptionTariffPlans.USD.sort(this.sortPlans);
        });
    }

    private sortPlans(a: ISubscriptionTariffPlan, b: ISubscriptionTariffPlan): number {
        return (+a.id > +b.id) ? 1 : -1;
    }

    buySubscription(subscription: ISubscriptionTariffPlan): void {
        this.router.navigate(['subscription/upgrade', subscription.id, this.tariffPlansConfig.planPeriod]);
    }

    onScrollToTable(): void {
        const offsetTop = this.detailsTable.nativeElement.offsetTop;
        window.scroll({
            top: offsetTop,
            left: 0,
            behavior: 'smooth'
        });
    }

    ngAfterContentInit(): void {
    }

    private scrollToFragment() {
        this.activatedRoute.fragment.subscribe((fragment: string) => {
            setTimeout(() => {
                if (fragment) {
                    this.viewportScroller.scrollToAnchor(fragment);
                }
            }, 1000);
        });
    }

    private handleQueryParamsRegistration(): void {
        this.route.queryParams.pipe(
            pluck('token'),
            switchMap(this.confirmEmail.bind(this)),
        ).subscribe(
            (authRes) => {
                if (!authRes.success) {
                    return;
                }
                this.headerService.showHeaderPopup(HeaderPopupType.REGISTRATION_SET_PASSWORD);
                this.authService.tempToken = authRes.results.authToken;
                this.authService.registrationComplete$.subscribe(res => {
                    if (!res) {
                        return;
                    }
                    this.headerService.hideHeaderPopup();
                    this.location.replaceState(this.location.path().split('?')[0], '');
                });
            },
            () => {
            },
        );
    }

    private handleQueryParamsUpdateEmail(): void {

        this.route.queryParams.pipe(
            pluck('token'),
            // switchMap(this.confirmEmailUpdate.bind(this)),
        ).subscribe(
            (token) => {
                this.authService.tempToken = token;
                this.authService.registrationComplete$.subscribe(res => {
                    if (!res) {
                        return;
                    }
                    this.authService.storeToken(token);
                    this.location.replaceState(this.location.path().split('?')[0], '');
                });
            },
            () => {
            },
        );
    }

    private confirmEmail(confirmationToken: string): Observable<TEmailConfirmationResponse> {
        return this.emailConfirmationPageService.confirmEmail(confirmationToken)
            .pipe(
                tap(({results: {authToken}, success, errorKey}) => {
                    if (!success) {
                        this.location.replaceState(this.location.path().split('?')[0], '');
                        // this.errorMessage = this.localizationProvider.getByErrorKey(errorKey);
                    }
                }),
            );
    }

    private checkCurrentRoute() {
        if (this.route.snapshot.routeConfig.data.isNewEmail) {
            this.handleQueryParamsUpdateEmail();
        } else {
            this.handleQueryParamsRegistration();
        }
    }
}

export type TEmailConfirmationResponse = IResponse<{ authToken: string }>;

export type ImageLinkType = Readonly<{
    image: string;
    link: string;
}>;

