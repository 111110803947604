import {
    RECORD_STATUS,
    STATUS_CSS_CLASS,
    STATUS_ICON,
    STATUS_LOCALIZATION,
    STREAM_STATUS
} from '@src/app/components/streamdust-player/constants/status';
import {MediaOverviewData} from '@src/app/models/stream.model';
import {PLAYER_MODE} from '@src/app/components/streamdust-player/constants/playerMode';
import {MEDIA_SUBTYPE} from '@src/app/components/streamdust-player/constants/mediaType';
import {DateHelper, IPlayerInfoLine, STATE} from 'ui-elements';

export class InfoLineHelper {
    private readonly waitingStatus: {[key: string]: IPlayerInfoLine} = {
        [STREAM_STATUS.WAITING]: {
            text: STATUS_LOCALIZATION.WAITING,
            cssClass: STATUS_CSS_CLASS.SCHEDULED,
            cssIconClass: STATUS_ICON.DOT
        },
    };
    private readonly publisherStatuses: {[key: string]: IPlayerInfoLine} = {
        [STREAM_STATUS.SCHEDULED]: {
            text: STATUS_LOCALIZATION.SCHEDULED,
            cssClass: STATUS_CSS_CLASS.SCHEDULED,
            cssIconClass: STATUS_ICON.DOT
        },
        [STREAM_STATUS.ACTIVATION]: {
            text: STATUS_LOCALIZATION.ACTIVATION,
            cssClass: STATUS_CSS_CLASS.SCHEDULED,
            cssIconClass: STATUS_ICON.DOT
        },
        [STREAM_STATUS.ACTIVATED]: {
            text: STATUS_LOCALIZATION.ACTIVATED,
            cssClass: STATUS_CSS_CLASS.SCHEDULED,
            cssIconClass: STATUS_ICON.DOT
        },
        [STREAM_STATUS.CONNECTED]: {
            text: STATUS_LOCALIZATION.CONNECTED,
            cssClass: STATUS_CSS_CLASS.SCHEDULED,
            cssIconClass: STATUS_ICON.DOT
        },
        [STREAM_STATUS.PAUSED]: {
            text: STATUS_LOCALIZATION.PAUSED,
            cssClass: STATUS_CSS_CLASS.SCHEDULED,
            cssIconClass: STATUS_ICON.DOT
        },
        [STREAM_STATUS.LIVE]: {
            text: STATUS_LOCALIZATION.LIVE,
            cssClass: STATUS_CSS_CLASS.LIVE,
            cssIconClass: STATUS_ICON.DOT
        },
        [STREAM_STATUS.FINISHED]: {
            text: STATUS_LOCALIZATION.FINISHED,
            cssClass: STATUS_CSS_CLASS.FINISHED,
            cssIconClass: STATUS_ICON.DOT
        },
        [STREAM_STATUS.VIDEO]: {
            text: STATUS_LOCALIZATION.VIDEO,
            cssClass: STATUS_CSS_CLASS.VIDEO
        },
        [STREAM_STATUS.RESCHEDULING]: {
            text: STATUS_LOCALIZATION.RESCHEDULING,
            cssClass: STATUS_CSS_CLASS.FINISHED,
            cssIconClass: STATUS_ICON.DOT
        },
        [STREAM_STATUS.DEACTIVATED]: {
            text: STATUS_LOCALIZATION.DEACTIVATED,
            cssClass: STATUS_CSS_CLASS.FINISHED,
            cssIconClass: STATUS_ICON.DOT
        },

    };

    private readonly viewerStatuses: {[key: string]: IPlayerInfoLine} = {
        [STREAM_STATUS.SCHEDULED]: {
            text: STATUS_LOCALIZATION.SCHEDULED,
            cssClass: STATUS_CSS_CLASS.SCHEDULED,
            cssIconClass: STATUS_ICON.DOT
        },
        [STREAM_STATUS.ACTIVATION]: this.waitingStatus[STREAM_STATUS.WAITING],
        [STREAM_STATUS.ACTIVATED]: this.waitingStatus[STREAM_STATUS.WAITING],
        [STREAM_STATUS.CONNECTED]: this.waitingStatus[STREAM_STATUS.WAITING],
        [STREAM_STATUS.PAUSED]: this.waitingStatus[STREAM_STATUS.WAITING],
        [STREAM_STATUS.LIVE]: {
            text: STATUS_LOCALIZATION.LIVE,
            cssClass: STATUS_CSS_CLASS.LIVE,
            cssIconClass: STATUS_ICON.DOT
        },
        [STREAM_STATUS.FINISHED]: {
            text: STATUS_LOCALIZATION.FINISHED,
            cssClass: STATUS_CSS_CLASS.FINISHED,
            cssIconClass: STATUS_ICON.DOT
        },
        [STREAM_STATUS.VIDEO]: {
            text: STATUS_LOCALIZATION.VIDEO,
            cssClass: STATUS_CSS_CLASS.VIDEO
        },
        [STREAM_STATUS.RESCHEDULING]: {
            text: STATUS_LOCALIZATION.RESCHEDULING,
            cssClass: STATUS_CSS_CLASS.FINISHED,
            cssIconClass: STATUS_ICON.DOT
        },
        [STREAM_STATUS.DEACTIVATED]: {
            text: STATUS_LOCALIZATION.DEACTIVATED,
            cssClass: STATUS_CSS_CLASS.FINISHED,
            cssIconClass: STATUS_ICON.DOT
        },

    };

    private readonly recordStatuses: {[key: string]: IPlayerInfoLine} = {
        [RECORD_STATUS.NONE]: {
            text: STATUS_LOCALIZATION.NONE,
            cssClass: STATUS_CSS_CLASS.INFO,
            cssIconClass: ''
        },
        [RECORD_STATUS.RECORDING]: {
            text: STATUS_LOCALIZATION.RECORDING,
            cssClass: STATUS_CSS_CLASS.INFO,
            cssIconClass: ''
        },
        [RECORD_STATUS.STORING]: {
            text: STATUS_LOCALIZATION.STORING,
            cssClass: STATUS_CSS_CLASS.INFO,
            cssIconClass: ''
        },
        [RECORD_STATUS.RECORDED]: {
            text: STATUS_LOCALIZATION.LIVE,
            cssClass: STATUS_CSS_CLASS.LIVE_FINISHED,
            cssIconClass: STATUS_ICON.GREY_DOT
        }

    };
    public primaryInfoline(mediaInfo: MediaOverviewData, mode: PLAYER_MODE, mediaSubtype: MEDIA_SUBTYPE): IPlayerInfoLine {
        if ([RECORD_STATUS.STORING, RECORD_STATUS.RECORDED].includes(mediaInfo?.video?.status?.record)) {
            return this.recordStatuses[mediaInfo.video?.status?.record];
        }
        if (mode === PLAYER_MODE.PUBLISHER) {
            return this.publisherStatuses[mediaInfo.video?.status?.stream];
        }

        return this.viewerStatuses[mediaInfo.video?.status?.stream];
    }

    public secondaryInfoline(mediaInfo: MediaOverviewData, mode: PLAYER_MODE, mediaSubtype: MEDIA_SUBTYPE, donations: IPlayerInfoLine): IPlayerInfoLine {
        return mode === PLAYER_MODE.PUBLISHER ? this.publisherSecondaryInfoline(mediaInfo, null) : this.viewerSecondaryInfoline(mediaInfo, donations, mediaSubtype);
    }

    public thirdInfoline(mediaInfo: MediaOverviewData, mode: PLAYER_MODE, mediaSubtype: MEDIA_SUBTYPE, donations: IPlayerInfoLine): IPlayerInfoLine {
        // switch (mediaSubtype) {
        //     case MEDIA_SUBTYPE.TEASER:
        //         return {text: 'player.current.teaser', cssClass: STATUS_CSS_CLASS.INFO, showOn: STATE.PLAY};
        //     case MEDIA_SUBTYPE.TRAILER:
        //         return {text: 'player.current.trailer', cssClass: STATUS_CSS_CLASS.INFO, showOn: STATE.PLAY};
        // }
        return donations ? donations : {};
    }


    private publisherSecondaryInfoline(mediaInfo: MediaOverviewData, donations: IPlayerInfoLine): IPlayerInfoLine {
        if ([STREAM_STATUS.SCHEDULED, STREAM_STATUS.RESCHEDULING].includes(mediaInfo.video?.status?.stream)) {
            if (!mediaInfo.advancedSettings.showStreamDate) {
                return {};
            }
            return this.scheduleInfo(mediaInfo);
        }
        if ([STREAM_STATUS.ACTIVATION].includes(mediaInfo.video?.status?.stream)) {
            return {text: 'player.suggestion.activation', cssClass: STATUS_CSS_CLASS.INFO};
        }
        if ([STREAM_STATUS.ACTIVATED].includes(mediaInfo.video?.status?.stream)) {
            return {text: 'player.suggestion.activated', cssClass: STATUS_CSS_CLASS.INFO};
        }
        if ([STREAM_STATUS.CONNECTED].includes(mediaInfo.video?.status?.stream)) {
            return {text: 'player.suggestion.connected', cssClass: STATUS_CSS_CLASS.INFO};
        }
        if ([STREAM_STATUS.PAUSED].includes(mediaInfo.video?.status?.stream)) {
            return {text: 'player.suggestion.paused', cssClass: STATUS_CSS_CLASS.INFO};
        }
        return donations ? donations : {};
    }

    private viewerSecondaryInfoline(mediaInfo: MediaOverviewData, donations: IPlayerInfoLine, mediaSubtype: MEDIA_SUBTYPE): IPlayerInfoLine {
        switch (mediaSubtype) {
            case MEDIA_SUBTYPE.TEASER:
                return {text: 'player.current.teaser', cssClass: STATUS_CSS_CLASS.INFO, showOn: STATE.PLAY};
            case MEDIA_SUBTYPE.TRAILER:
                return {text: 'player.current.trailer', cssClass: STATUS_CSS_CLASS.INFO, showOn: STATE.PLAY};
            case MEDIA_SUBTYPE.MAIN:
                if ([STREAM_STATUS.SCHEDULED, STREAM_STATUS.RESCHEDULING].includes(mediaInfo.video?.status?.stream)) {
                    if (!mediaInfo.advancedSettings.showStreamDate) {
                        return {};
                    }
                    return this.scheduleInfo(mediaInfo);
                }
                // return donations ? donations : {};
        }
    }

    private scheduleInfo(mediaInfo: MediaOverviewData): IPlayerInfoLine {
        return {text: this.formatDate(mediaInfo.video.startTimestamp), cssClass: mediaInfo.video.status.stream === STREAM_STATUS.SCHEDULED ? STATUS_CSS_CLASS.SCHEDULED : STATUS_CSS_CLASS.FINISHED};
    }

    public getStreamStatuses(): {[key: string]: IPlayerInfoLine} {
        return this.publisherStatuses;
    }

    private formatDate(timestamp: number): string {
        const prefix = localStorage.getItem('localLanguage') === 'DE' ? 'Uhr' : '';
        return DateHelper.formatDate(timestamp, 'DD MMMM, HH:mm', true) + prefix;
    }
}


