export interface ISubAccount {
    id?: string;
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    confirmPassword?: string;
    subAccount: {
        type: SUB_ACCOUNT_TYPE;
        accepted: boolean;
    };
}


export enum SUB_ACCOUNT_TYPE {
    DIRECT = 'DIRECT',
    INVITED = 'INVITED'
}

