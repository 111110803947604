import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {Observable, of} from 'rxjs';
import {map, mergeMap, tap} from "rxjs/operators";
import {UserService} from "@src/app/services/user/user.service";
import {IUserProfile} from "@src/app/pages/user-profile/components/profile/services/profile.service";

@Injectable({
    providedIn: 'root'
})
export class PremiumPublisherGuard implements CanActivate {

    userProfile: IUserProfile;

    public constructor(
        private userService: UserService,
        private router: Router
    ) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.userService.userProfile$.pipe(
            tap((res) => {
                this.userProfile = res;
            }),
            mergeMap(() => {
                    if (this.userProfile) {
                        return of(this.checkIfPremiumPublisher(this.userProfile));
                    }
                    return this.userService.getUserDataAsObservable().pipe(
                        map((res) => this.checkIfPremiumPublisher(res.userProfile))
                    )
                }
            ));
    }

    checkIfPremiumPublisher(res): boolean {
        if (res) {
            if (!res?.subscribed) {
                this.router.navigate(['streams']);
                return false;
            }
            return true;
        }
    }

}
