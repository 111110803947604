import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BackdropService {
  public showBackdrop$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() { }

  public show(): void {
    this.showBackdrop$.next(true);
  }

  public hide(): void {
    this.showBackdrop$.next(false);
  }

  public showBackdrop(state: boolean): void {
    this.showBackdrop$.next(state);
  }
}
