<div class="box box-alert box-alert-info">
  <h4 *ngIf="type" class="text-align-center" [ngClass]="{
    'text-success': type === INFO_BOX_TYPES.SUCCESS,
    'text-danger': type === INFO_BOX_TYPES.FAILURE,
    'text-info-green': type === INFO_BOX_TYPES.INFO
  }"><ng-content select="[role=title]"></ng-content></h4>
  <p><ng-content select="[role=message]"></ng-content></p>
  <ng-content></ng-content>
  <div class="info-box-buttons">
    <ng-content select="[role=buttons]"></ng-content>
  </div>
</div>
