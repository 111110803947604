import {EventEmitter, Injectable, Output} from '@angular/core';
import {ApiService} from 'ui-elements';
import {IPagedResponse, IResponse, PagingParams, SortParam} from '@src/app/models/response.model';
import {Observable} from 'rxjs';
import {API_URLS_APP, UrlGenerator} from '@src/app/constants/api-urls.constant';
import {IPresentationActiveRequest, IPresentationList} from '@src/app/services/file/presentation.service';

@Injectable({
    providedIn: 'root'
})
export class RemindService {
    state = {
        active: false
    };

    showViewerAccessModal$: EventEmitter<any> = new EventEmitter<any>();
    changedStateActive$: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        private apiService: ApiService,
    ) {
    }

    getAvailableWithState(mediaId: string,
    ): Observable<IPagedResponse<IReminder[]>> {
        return this.apiService.get(UrlGenerator.generate(API_URLS_APP.GET_REMINDERS_AVALIBLE, {mediaId}));
    }

    update(mediaId, body: any): Observable<IResponse> {
        return this.apiService.put(UrlGenerator.generate(API_URLS_APP.UPDATE_REMINDERS, {mediaId}), body);
    }

    active(mediaId, body: any): Observable<IResponse> {
        return this.apiService.put(UrlGenerator.generate(API_URLS_APP.ACTIVE_REMINDERS, {mediaId}), body);
    }
}

export interface IReminder {
    id: number;
    name: string;
    value: boolean;
}
