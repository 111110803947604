<div class="content profile-page">
    <div class="profile-section login-section">
        <div class="container">
        <span class="main-icon-label">
            <i class="icon-person"></i>
        </span>
        </div>
    </div>

    <div class="content-profile border-radius-50">
        <div class="container">
            <form class="form-signin" [formGroup]="loginForm" (ngSubmit)="login()">
      <span *ngFor="let error of serverErrors"
            class="invalid-feedback text-center d-block mb-2 mt-0">
        {{ error.text }}
      </span>

                <div class="form-group ">
                    <ano-input formControlName="email"
                               [errors]="loginForm.get('email').errors"
                               [isTouched]="loginForm.get('email').touched"
                               [config]="{ inputType: INPUT_TYPES.INPUT, type: 'email', placeholder: 'login.placeholder.email' | localize }"
                               [isCleanable]="true"></ano-input>
                </div>

                <div class="form-group">
                    <ano-input formControlName="password"
                               [errors]="loginForm.get('password').errors"
                               [isTouched]="loginForm.get('password').touched"
                               [config]="{ inputType: INPUT_TYPES.INPUT, type: 'password', placeholder: 'login.placeholder.password' | localize }"
                               [isCleanable]="true"></ano-input>
                </div>

                <div class="form-group">
                    <ano-button
                            [config]="{
            type: 'submit',
            styleType: 'primary',
            sizeType: 'block',
            isDisabled: loading.login
          }">
                        {{ 'login.button.signin' | localize }}
                    </ano-button>
                </div>

                <div class="form-group forgot-password-group">
                    <a class="btn btn-light btn-block" href="#" routerLink="/password-forgotten">
                        {{ 'login.button.forgotpassword' | localize }}
                    </a>
                </div>
                <div class="form-group">
                    <hr>
                </div>
                <div class="form-group forgot-password-group">
                    <a href="#"
                       [routerLink]="['/registration']"
                       class="btn btn-light btn-block">{{ 'login.button.registration' | localize }}</a>
                </div>
            </form>
        </div>
    </div>
</div>
