import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    Inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Optional,
    Self,
    ViewChild
} from '@angular/core';
import {AuthService} from '@src/app/services/auth/auth.service';
import {DOCUMENT, Location} from '@angular/common';
import {RouterEventHandlerService} from '@src/app/services/router-event-handler/router-event-handler.service';
import {Event, Router} from '@angular/router';
import {HeaderBtnTypes, HeaderPopupType, HeaderService} from '@src/app/services/header/header.service';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import {debounceTime, mergeMap, takeUntil} from 'rxjs/operators';
import {UserService} from '@src/app/services/user/user.service';
import {ApiService, CmsService, INPUT_TYPES, LocalizationProvider} from 'ui-elements';
import {IUserProfile} from '@src/app/pages/user-profile/components/profile/services/profile.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {IFormFieldsConfig} from '@src/app/services/stream-metadata/stream.service';
import {PasswordForgottenPageService} from '@src/app/pages/password-forgotten-page/services/password-forgotten-page/password-forgotten-page.service';
import {IServerError} from '@src/app/models/server-error.model';
import {CustomValidators} from '@src/app/utils/custom-validators.util';
import {BackdropService} from '@src/app/services/backdrop/backdrop.service';
import {USER_TYPE} from '@src/app/constants/user-type.constant';
import {obfuscateEmail} from '@src/app/utils/string.util';
import {SubAccountsService} from '@src/app/services/sub-accounts/sub-accounts.service';
import {environment} from '@src/environments/environment';
import {Subject} from 'rxjs';
import { API_URLS_APP } from '@src/app/constants/api-urls.constant';
import { AccountService } from '@src/app/services/language/account.service';

export interface IHeaderConfig {
    rounded: boolean;
    backButton: boolean;
    showLogo: boolean;
    transparent: boolean;
    isProfile: boolean;
    title?: string;
    searchBtn?: boolean;
    addBtn?: boolean;
    avatar?: boolean;
    profileId?: string;
    isForeignProfile: boolean;
    searchBtnRequests?: boolean;
}

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    providers: [AutoDestroyService, SubAccountsService],
})
export class HeaderComponent implements OnInit, OnChanges, OnDestroy {
    @ViewChild('roundedRadius', {static: false}) private roundedRadius: ElementRef<HTMLDivElement>;
    @Input() public config: IHeaderConfig = {
        rounded: true,
        backButton: false,
        showLogo: false,
        transparent: false,
        isProfile: false,
        isForeignProfile: false
    };
    @Input() isEventSite: boolean;
    public isSearchShown = false;
    public title: string;
    public avatar: string;
    public itemsCounter = 0;
    public BTN_TYPES = HeaderBtnTypes;
    public hideOnScroll = false;
    private profileId: string;
    private partnerStatuses: Array<string>;
    public isEditMode: boolean;
    public messagingIconLink = '/messages/conversations';
    public titleKey = '';
    public userInfo: IUserProfile;
    public loading = false;
    public loginForm: FormGroup = this.fb.group({
        email: ['', [Validators.required, CustomValidators.email]],
        password: ['', [Validators.required]],
    });

    public keepLoggedForm: FormGroup = this.fb.group({
        keepLogged: [this.authService.getKeepLogged()]
    });
    public passwordForm: FormGroup = this.fb.group({
        email: ['', [Validators.required, CustomValidators.email]],
    });

    public loginFormFieldsConfig: IFormFieldsConfig[][] = [
        [
            {
                name: 'email',
                formGroup: true,
                config: {
                    inputType: INPUT_TYPES.INPUT,
                    label: 'login.placeholder.email',
                    placeholder: 'login.placeholder.email.field',
                    isRequired: true,
                    size: 9,
                    padding0: true,
                    appendIcon: 'mail'
                }
            },
        ],
        [
            {
                name: 'password',
                formGroup: true,
                config: {
                    inputType: INPUT_TYPES.INPUT,
                    label: 'login.placeholder.password',
                    placeholder: '••••••••',
                    type: 'password',
                    isRequired: true,
                    size: 9,
                    padding0: true,
                    appendIcon: 'lock'
                }
            },
        ]
    ];

    public passwordFormFieldsConfig: IFormFieldsConfig[][] = [
        [
            {
                name: 'email',
                formGroup: true,
                config: {
                    inputType: INPUT_TYPES.INPUT,
                    label: 'forgottenPassword.email',
                    isRequired: true,
                    size: 9,
                    padding0: true,
                    appendIcon: 'mail'
                }
            },
        ],
    ];

    // popup open flags
    public loginOpen = false;
    public passwordForgottenOpen = false;
    public isEmailSentOpen: boolean;
    public changeForgottenPasswordOpen = false;
    public registrationSetPasswordOpen = false;

    public serverErrors: IServerError[] = [];
    public emailSentTextTmpl = this.localizationProvider.getByKey('reset.description');
    public emailSentText = '';
    public username: string;
    public navConfig: INavItemConfig[];
    public navLangConfig: INavItemConfig[];
    public ApiService = ApiService;
    private userProfile: IUserProfile;


    constructor(
        public authService: AuthService,
        private _location: Location,
        private routerEventHandler: RouterEventHandlerService,
        private router: Router,
        private headerService: HeaderService,
        @Self() @Optional() private readonly destroy$: AutoDestroyService,
        private changeDetector: ChangeDetectorRef,
        public userService: UserService,
        public localizationProvider: LocalizationProvider,
        @Inject(DOCUMENT) private document: Document,
        public fb: FormBuilder,
        private backdropService: BackdropService,
        private passwordForgottenPageService: PasswordForgottenPageService,
        private location: Location,
        private subAccountsService: SubAccountsService,
        private cmsService: CmsService,
        public accountService: AccountService
    ) {
        this.router.events.pipe(takeUntil(this.destroy$), debounceTime(50))
            .subscribe((routerEvent: Event) => {
                this.isSearchShown = false;
            });
        this.title = '';
        this.initUserInfo();
    }

    ngOnInit() {
        if (this.config.title) {
            const title = this.localizationProvider.getByKey(this.config.title);
            this.titleKey = this.config.title;
            this.config.title = title || this.config.title;
        }

        this.initActivePopupListener();
        this.resolveUsername();
        this.resolveNavConfig();
        this.resolveLangMenu();
    }

    ngOnChanges(): void {
        if (this.config.title) {
            const title = this.localizationProvider.getByKey(this.config.title);
            this.titleKey = this.config.title;
            this.config.title = title || this.config.title;
        }
    }

    private resolveUsername(): void {
        this.userService.userProfile$.pipe(takeUntil(this.destroy$))
            .subscribe((res: IUserProfile) => {
                if (!res) {
                    this.username = null;
                    return;
                }

                if (!res.firstName && !res.lastName) {
                    this.username = res.email;
                    return;
                }

                this.username = res.firstName + ' ' + res.lastName;
            });
    }

    private resolveNavConfig(): void {
        this.userService.userProfile$.pipe(
            mergeMap((userProfile) => {
                this.userProfile = userProfile;
                return this.userService.userType$;
            })
        )
            .subscribe((res: USER_TYPE) => {
                if (!res) {
                    return;
                }

                switch (res) {
                    case USER_TYPE.VIEWER:
                        this.navConfig = [
                            {
                                text: 'header.dashboard',
                                action: () => this.navigateTo('streams')
                            },
                            {
                                text: 'header.userData',
                                action: () => this.navigateTo('user/edit')
                            },
                            {
                                text: 'header.logout',
                                action: () => this.logout()
                            }
                        ];
                        break;
                    case USER_TYPE.PUBLISHER:
                        this.navConfig = [
                            {
                                text: 'nav.dashboard',
                                action: () => this.navigateTo('dashboard')
                            },
                            {
                                text: 'header.livestreams',
                                action: () => this.navigateTo('streams')
                            },
                            {
                                text: 'header.video',
                                action: () => this.navigateTo('videos-on-demand')
                            },
                            {
                                text: 'header.userData',
                                action: () => this.navigateTo('user/edit')
                            },
                            {
                                text: 'header.logout',
                                action: () => this.logout()
                            }
                        ];
                        break;
                    case USER_TYPE.SUB_ACCOUNT:
                        this.navConfig = [
                            {
                                text: 'header.livestreams',
                                action: () => this.navigateTo('streams')
                            },
                            {
                                text: 'header.video',
                                action: () => this.navigateTo('videos-on-demand')
                            },
                            {
                                text: 'header.userData',
                                action: () => this.navigateTo('user/edit')
                            },
                            {
                                text: 'header.logout',
                                action: () => this.logout()
                            },
                            {
                                hide: this.userProfile?.subAccount?.type !== 'INVITED',
                                text: 'nav.logoutAs',
                                action: () => this.logoutAs()
                            }
                        ];
                        break;
                }
            });
    }

    private navigateTo(url: string): void {
        this.router.navigate([url]);
    }

    public onBtnClicked(btn: HeaderBtnTypes): void {
        if (btn === HeaderBtnTypes.SEARCH) {
            this.isSearchShown = true;
        }
        if (btn === HeaderBtnTypes.SEARCH_REQUESTS) {
            this.router.navigate(['/search']);
        }
        this.headerService.onHeaderBtnClicked(btn);
    }

    public onScrollHandling(event): void {
        if (!this.config.rounded) {
            return;
        }
        this.hideOnScroll = event.target.scrollTop > 5;
    }

    ngOnDestroy(): void {
    }

    public logout(): void {
        this.authService.logout();
    }

    public initUserInfo() {
        this.userInfo = this.userService.userProfile;
    }

    public toggleLogin(): void {
        if (this.loginOpen) {
            this.headerService.hideHeaderPopup();
        } else {
            this.headerService.showHeaderPopup(HeaderPopupType.LOGIN);
        }
    }

    public togglePasswordForgotten(): void {
        if (this.passwordForgottenOpen) {
            this.headerService.hideHeaderPopup();
        } else {
            this.headerService.showHeaderPopup(HeaderPopupType.FORGOT_PASSWORD);
        }
    }

    public login(): void {
        this.authService.storeKeepLogged(this.keepLoggedForm.get('keepLogged').value);
        this.loginForm.markAllAsTouched();
        if (this.loginForm.invalid) {
            return;
        }
        this.loading = true;
        const login$ = new Subject();

        this.authService.login(this.loginForm.value)
            .subscribe(
                (res) => {
                    this.loading = false;
                    if (!res.success) {
                        this.serverErrors = [{
                            key: res.errorKey,
                            text: this.localizationProvider.getByErrorKey(res.errorKey)
                        }];
                        return;
                    }
                    this.userService.userProfile$.pipe(takeUntil(login$))
                        .subscribe((userProfile) => {
                            if (userProfile) {
                                login$.next();
                                login$.complete();
                                if (userProfile.accountType === USER_TYPE.VIEWER) {
                                    this.router.navigate(['streams']);
                                } else {
                                    this.router.navigate(['dashboard']);
                                }
                                setTimeout(() => this.toggleLogin(), 1000);
                            }
                        });
                    this.serverErrors = [];
                },
                () => this.loading = false,
            );
    }

    public remindPassword(): void {
        this.passwordForm.markAllAsTouched();
        if (this.passwordForm.invalid) {
            return;
        }

        this.loading = true;
        const email = this.passwordForm.get('email').value;
        this.passwordForgottenPageService.remindPassword(email)
            .subscribe(
                ({success, errorKey}) => {
                    if (!success) {
                        this.serverErrors = [{key: errorKey, text: this.localizationProvider.getByErrorKey(errorKey)}];
                    }
                    this.emailSentText = this.emailSentTextTmpl.replace('{email}', this.obfuscateEmail(email));
                    this.loading = false;
                    if (success) {
                        this.headerService.showHeaderPopup(HeaderPopupType.EMAIL_SENT);
                    }
                },
                () => this.loading = false,
            );
    }

    public finishRemind(): void {
        this.headerService.hideHeaderPopup();
    }

    public obfuscateEmail(email: string): string {
        return obfuscateEmail(email);
    }

    private initActivePopupListener(): void {
        this.headerService.activePopup$.pipe(takeUntil(this.destroy$))
            .subscribe(modalType => {
                // close all modals
                this.closeAllPopups();

                switch (modalType) {
                    case HeaderPopupType.NONE:
                        // hide all modals
                        break;
                    case HeaderPopupType.LOGIN:
                        this.loginOpen = true;
                        break;
                    case HeaderPopupType.EMAIL_SENT:
                        this.isEmailSentOpen = true;
                        break;
                    case HeaderPopupType.FORGOT_PASSWORD:
                        this.passwordForgottenOpen = true;
                        break;
                    case HeaderPopupType.CHANGE_FORGOTTEN_PASSWORD:
                        this.changeForgottenPasswordOpen = true;
                        break;
                    case HeaderPopupType.REGISTRATION_SET_PASSWORD:
                        this.registrationSetPasswordOpen = true;
                        break;
                    default:
                        console.error('unsupported modal type: ' + modalType);
                        return;
                }
            });
    }

    private logoutAs(): void {
        this.subAccountsService.logoutAs().pipe(takeUntil(this.destroy$)).subscribe((res) => {
            if (!res) {
                return;
            }
            this.authService.logout(false);
            this.authService.storeToken(res.authToken);
            this.router.navigateByUrl('/', {skipLocationChange: true})
                .then(() => {
                    this.router.navigate(['streams']);
                });
        });
    }

    private closeAllPopups(): void {
        this.loginOpen = this.isEmailSentOpen = this.passwordForgottenOpen =
            this.changeForgottenPasswordOpen = this.registrationSetPasswordOpen = false;
        this.serverErrors = [];
    }

    private resolveLangMenu() {
        this.navLangConfig = [
            {
                text: 'header.en',
                action: () => this.changeLang('EN')
            },
            {
                text: 'header.de',
                action: () => this.changeLang('DE')
            }
        ];

        if (environment?.localization) {
            this.navLangConfig.push(
                {
                    text: 'header.key',
                    action: () => this.changeLang('KEY')
                }
            );
        }
    }

    private changeLang(language: string) {
        ApiService.setLanguageToStorage(language);
        this.localizationProvider.load();
        this.cmsService.getCountries();
        this.userService.reloadLand.emit();
        document
          .getElementById('Cookiebot')
          .setAttribute('data-culture', language);
        this.accountService
            .postAccountLanguage(language)
            .subscribe();

        return undefined;
    }

    public goToHome(): void {
        if (this.isEventSite) {
            window.location.href = 'https://streamdust.tv';
            return;
        }

        this.router.navigate(['/']);
    }
}

export interface INavItemConfig {
    hide?: boolean;
    text: string;
    action: () => void;
}
