import {StreamLinksService} from './../../services/stream-links/stream-links.service';
import {LinkedStreamsModalComponent} from './linked-streams-modal/linked-streams-modal.component';
import {IMediaContentRequest, IStreamPreviewTeaserTrailer, PAYMENT_SYSTEM} from './../../models/stream.model';
import {saveAs} from 'file-saver/FileSaver';
import {
    AfterViewInit,
    Component,
    ComponentFactoryResolver,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import {IMediaUrl, IPlayerAccessModal, StreamService} from '@src/app/services/stream-metadata/stream.service';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import {debounceTime, filter, pluck, take, takeUntil, tap} from 'rxjs/operators';
import {MEDIA_FORMAT, MEDIA_SUBTYPE, MEDIA_TYPE} from '@src/app/components/streamdust-player/constants/mediaType';
import {PLAYER_DISPLAY_MODE, PLAYER_MODE} from '@src/app/components/streamdust-player/constants/playerMode';
import {BehaviorSubject, forkJoin, fromEvent, ReplaySubject} from 'rxjs';
import {IPaymentSlot, IVideoStatus, LIVESTREAM_TYPE, MediaOverviewData} from '@src/app/models/stream.model';
import {
    RECORD_STATUS,
    STATUS_CSS_CLASS,
    STREAM_STATUS,
    VideoUrl
} from '@src/app/components/streamdust-player/constants/status';
import {InfoLineHelper} from '@src/app/components/streamdust-player/helper/InfoLineHelper';
import {asBoolean} from '@src/app/utils/string.util';
import {AdvertisementService} from '@src/app/services/advertisement/advertisement.service';
import {PlayerControlsService} from '@src/app/services/player-controls/player-controls.service';
import {ChatState} from '@src/app/state/chat-state';
import {urlWithProtocol} from '@src/app/utils/url.util';
import {CHAT_MODE, ChatComponent} from '@src/app/components/chat/chat.component';
import {Subject} from 'rxjs/internal/Subject';
import {IScheduleSignal, SchedulerService} from '@src/app/services/scheduler-service/scheduler.service';
import {Observable} from 'rxjs/internal/Observable';
import {DonationState} from '@src/app/state/donation-state';
import {DonationsService, IDonation} from '@src/app/services/donations/donations.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {PAYMENT_FAIL_MESSAGES, PaymentService} from '@src/app/services/payment/payment.service';
import {PAYMENT_STATUS} from '@src/app/services/subscription-manage/subscription-manage.service';
import {CONTENT_RESTRICTION} from '@src/app/constants/content-restrictions';
import {ChatService, IChatResponse} from '@src/app/services/chat/chat.service';
import {UserService} from '@src/app/services/user/user.service';
import {AuthService} from '@src/app/services/auth/auth.service';
import {IUserProfile} from '@src/app/pages/user-profile/components/profile/services/profile.service';
import {StreamWorldMapState} from '@src/app/state/stream-world-map';
import {SlotsService} from '@src/app/services/slots/slots.service';

import {
    IMediaSource,
    IOverlay,
    IPlayerButton,
    IPlayerConfig,
    IPlayerInfoLine,
    ITime,
    ITimeType,
    STATE,
} from 'ui-elements';
import {StreamMarketingToolsOverlaysTypes} from '@src/app/pages/stream-page/components/stream-marketing-tools-overlays/stream-marketing-tools-overlays.component';
import {PresentationState} from '@src/app/state/presentation-state';
import {environment} from '@src/environments/environment';
import {API_URLS_APP} from '@src/app/constants/api-urls.constant';
import {RemindService} from '@src/app/services/remind/remind.service';
import {PdfViewerModalComponent} from '@src/app/components/pdf-viewer-modal/pdf-viewer-modal.component';
import {DonationsComponent} from '@src/app/components/donations/donations.component';
import {StreamWorldMapComponent} from '@src/app/components/stream-world-map/stream-world-map.component';
import {ViewerSurveysComponent} from '@src/app/components/viewer-surveys/viewer-surveys.component';
import {InfoLineComponent} from '@src/app/components/info-line/info-line.component';
import {PlayerState} from '@src/app/state/player-state';
import {GroupsModalComponent} from '@src/app/components/streamdust-player/groups-modal/groups-modal.component';
import {GroupModalState} from '@src/app/state/group-modal-state';
import {MediaGroupsService} from '@src/app/services/media-groups/media-groups.service';
import {MediaAccessModalComponent} from '@src/app/components/streamdust-player/media-access-modal/media-access-modal.component';
import {SlotsModalComponent} from '@src/app/components/streamdust-player/slots-modal/slots-modal.component';
import {SlotsModalState} from '@src/app/state/slots-modal-state';
import {IPublicOverlay, IResponse} from '@src/app/models/response.model';
import {Subscription} from 'rxjs/internal/Subscription';
import {ShareModalState} from '@src/app/components/streamdust-player/share-modal/state/share-modal-state';
import {ShareModalComponent} from '@src/app/components/streamdust-player/share-modal/share-modal.component';
import {clearQueryParams} from '@src/app/utils/query-params.util';
import {Location} from '@angular/common';


@Component({
    selector: 'app-streamdust-player',
    templateUrl: './streamdust-player.component.html',
    styleUrls: ['./streamdust-player.component.sass'],
    providers: [AutoDestroyService, DonationsService, ChatService, SchedulerService, SlotsService, ShareModalState]
})
export class StreamdustPlayerComponent implements OnInit, AfterViewInit, OnDestroy {
    public MARKETING_SOCKET = `${environment.webSocketHost}${API_URLS_APP.ROOT_URL}${API_URLS_APP.MARKETING_SOCKET}`;
    @ViewChild('videoWrapper', {static: true}) videoWrapper: ElementRef<HTMLElement>;
    @ViewChild('linkedStreamTmpl', {static: true, read: ViewContainerRef}) linkedStreamTmpl: ViewContainerRef;
    @ViewChild('groupsTmpl', {static: true, read: ViewContainerRef}) groupsTmpl: ViewContainerRef;
    @ViewChild('slotsTmpl', {static: true, read: ViewContainerRef}) slotsTmpl: ViewContainerRef;
    @ViewChild('presentationTmpl', {static: true, read: ViewContainerRef}) presentationTmpl: ViewContainerRef;
    @ViewChild('chatTmpl', {static: true, read: ViewContainerRef}) chatTmpl: ViewContainerRef;
    @ViewChild('donationsTmpl', {static: true, read: ViewContainerRef}) donationsTmpl: ViewContainerRef;
    @ViewChild('mapTmpl', {static: true, read: ViewContainerRef}) mapTmpl: ViewContainerRef;
    @ViewChild('surveysTmpl', {static: true, read: ViewContainerRef}) surveysTmpl: ViewContainerRef;
    @ViewChild('infoTmpl', {static: true, read: ViewContainerRef}) infoTmpl: ViewContainerRef;
    @ViewChild('shareTmpl', {static: true, read: ViewContainerRef}) shareTmpl: ViewContainerRef;
    @ViewChild('accessModal', {static: true, read: ViewContainerRef}) accessModal: MediaAccessModalComponent;

    @Input() playerDisplayMode: PLAYER_DISPLAY_MODE = PLAYER_DISPLAY_MODE.DEFAULT;
    @Input() mediaId: string;
    @Input() mediaType: MEDIA_TYPE;
    @Input() mode: PLAYER_MODE;
    @Input() embeddedChatMode: CHAT_MODE = CHAT_MODE.OVERLAY;
    @Input() embedded = false;
    @Output() grantAccess$: EventEmitter<boolean> = new EventEmitter<boolean>();

    public loading = true;
    public CHAT_MODE = CHAT_MODE;
    public MEDIA_SUBTYPE = MEDIA_SUBTYPE;
    public PLAYER_MODE = PLAYER_MODE;
    public PLAYER_DISPLAY_MODE = PLAYER_DISPLAY_MODE;
    public MEDIA_TYPE = MEDIA_TYPE;

    private infoLineHelper: InfoLineHelper;
    public data: MediaOverviewData;
    public environment = environment;
    public slots: any[];
    public currentSlot: IPaymentSlot;
    public config: IPlayerConfig;
    public buttonsConfig: IPlayerButton[];
    private teaser: IStreamPreviewTeaserTrailer;
    private trailer: IStreamPreviewTeaserTrailer;
    private donationOptions: IDonation[] = [];
    private mediaUrl: IMediaUrl;
    public streamChannel: string;
    private overlays: IPublicOverlay[];
    public currentLivestreamTime: number;
    public hasPartialAccess = false;
    public ageRestricted: boolean;
    private playerSignal: Subject<IScheduleSignal> = new Subject<IScheduleSignal>();
    public playerSignalObservable: Observable<IScheduleSignal> = this.playerSignal.asObservable();
    public restrictedByCountry: boolean;
    public restrictedByDomain: boolean;
    private chatEnabled: boolean;
    private premiumPublisher: boolean;
    public isOwner: boolean;
    public autoplay = false;
    private playFrom: number;
    private slotsIntervalsArr: { start: number, end: number, hasAccess: boolean }[];
    currentBannerIndex = 0;
    componentRefs: IComponentRef[];
    queryParams: any;
    configModalPublisher: any;
    isCourses = false;
    hideModal = false;
    private courses: IPublicOverlay[];
    configPdf;
    presentationEnabled = false;
    private isAuthorized = false;
    showAccessModal = false;
    showScheduleMessage = false;
    private fullScreen$: Subject<boolean> = new Subject<boolean>();
    private refsInitialized: boolean;
    public _refs: { ref: HTMLElement; hide: boolean; }[];
    private mapEnabled: boolean;
    public deactivated: boolean;
    private _refsDestroyer$: Subject<void> = new Subject<void>();

    private donationsSocketConnection$: Subscription;
    private chatSocketConnection$: ReplaySubject<IResponse<IChatResponse>>;
    public accessButtonHover: boolean;
    public showLandscapeMode: boolean;
    private landscapeModeClosed: boolean;
    public playerPaused = true;
    public shareButtonEnabled: boolean;
    public displayVideoTitle: boolean;
    public playerController: BehaviorSubject<{ play: boolean }> = new BehaviorSubject<{ play: boolean }>(null);
    public context = this;
    public accessProps: IAccessProps = {
        isTeaserOrTrailer: false,
        fullAccess: false,
        onlyForRegistered: false,
        showRegistrationOffer: false,
    };

    private streamSlot;
    private isRecord: boolean;
    private hasBeenLive = false;

    constructor(
        public chatState: ChatState,
        public worldMapState: StreamWorldMapState,
        private chatService: ChatService,
        public donationState: DonationState,
        private playerControlsService: PlayerControlsService,
        public streamService: StreamService,
        private readonly destroy$: AutoDestroyService,
        private advertisementService: AdvertisementService,
        private donationsService: DonationsService,
        private activatedRoute: ActivatedRoute,
        private readonly router: Router,
        private paymentService: PaymentService,
        private userService: UserService,
        private authService: AuthService,
        private schedulerService: SchedulerService,
        private slotsService: SlotsService,
        private componentFactoryResolver: ComponentFactoryResolver,
        private streamLinksService: StreamLinksService,
        public presentationState: PresentationState,
        public remindService: RemindService,
        public playerState: PlayerState,
        private groupModalState: GroupModalState,
        private mediaGroupsService: MediaGroupsService,
        private slotsModalState: SlotsModalState,
        public shareModalState: ShareModalState,
        private location: Location
    ) {
        this.infoLineHelper = new InfoLineHelper();
    }

    ngOnInit(): void {
        this.initState();
        this.initListeners();
        this.initTeaser();
        this.initTrailer();
        this.connectToSockets();
        this.router.events.pipe(takeUntil(this.destroy$)).subscribe((e: any) => {
            if (e instanceof NavigationEnd) {
                this.initState();
                this.initTeaser();
                this.initTrailer();
            }
        });
    }

    ngAfterViewInit(): void {
        this.refreshContainerSize();
        fromEvent(window, 'resize')
            .pipe(
                takeUntil(this.destroy$),
                debounceTime(200)
            )
            .subscribe(() => {
                this.refreshContainerSize();
            });
    }

    ngOnDestroy(): void {
        this.streamService.closeStreamStatusWebsocket();
        this.presentationState.activeState$.next(false);
        this.playerState.setState(false);
        // this.schedulerService.stop();
    }

    private initState(): void {
        this.initResizableBounds();
        this.initMediaInfo();
    }

    private initListeners(): void {
        this.listenMediaAccessState();
        this.listenRemind();
        this.listenQueryParams();
        this.listenWindowResize();
        this.listenChatStatus();
        this.listenMapStatus();
        this.listenShareStatus();
        this.listenShareDateStatus();
    }

    initResizableBounds(): void {
        this.playerState.setState(true);
    }

    private listenMediaAccessState() {
        this.streamService.playerAccessModalShow$
            .subscribe((showAccessModal: IPlayerAccessModal) => {
                this.showAccessModal = showAccessModal.state;
            });
    }

    private initTeaser() {
        this.streamService.getStreamTeaserTrailer(this.mediaId, this.mediaType, 'teaser')
            .pipe(takeUntil(this.destroy$))
            .subscribe((res) => {
                this.teaser = res;
                if (this.queryParams?.paymentReturn) {
                    this.teaser.active = false;
                }
            });
    }

    private initTrailer() {
        this.streamService.getStreamTeaserTrailer(this.mediaId, this.mediaType, 'trailer')
            .pipe((takeUntil(this.destroy$)))
            .subscribe((res) => {
                this.trailer = res;
                if (this.queryParams?.paymentReturn) {
                    this.trailer.active = false;
                }
            });
    }

    public initMediaInfo() {
        this.loading = true;
        forkJoin({
            mediaInfo: this.streamService.getStreamLandingInfo(this.mediaType, this.mediaId).pipe(pluck('results', 'data')),
            overlays: this.streamService.getPublicOverlays(this.mediaId, this.mediaType, StreamMarketingToolsOverlaysTypes.OVERLAY),
            courses: this.streamService.getPublicOverlays(this.mediaId, this.mediaType, StreamMarketingToolsOverlaysTypes.COURSES)
        }).pipe(
            takeUntil(this.destroy$),
            tap(({mediaInfo, courses, overlays}) => {
                this.data = mediaInfo;
                this.isRecord = this.data?.video?.hasRecord && this.data?.video?.status?.stream === STREAM_STATUS.FINISHED;
                this.premiumPublisher = mediaInfo?.publisher?.subscribed;
                this.streamService.mediaOverview.next(mediaInfo);
                this.checkIsOwner();

                // this.courses = courses;
                // this.courses.forEach(course => course.isCourses = true);

                this.courses = courses.map((course) => {
                    course.isCourses = true;
                    course.controls = {isClosed: false, isManual: !!!course.startTime, isShowed: false, isShowing: false};
                    return course;
                });

                this.overlays = overlays.map((overlay) => {
                    overlay.controls = {isClosed: false, isManual: !!!overlay.startTime, isShowed: false, isShowing: false};
                    return overlay;
                });
            })
        )
            .subscribe(() => {
                this.listenStreamStatus();
                this.initAdvancedSettings();
                this.initDonationOptions();
                this.initMediaUrl();
            });
    }

    public updateOverlays() {
        const mediaSource: IMediaSource = this.ageRestricted ? {} : this.getMediaSource();

        forkJoin({
            overlays: this.streamService.getPublicOverlays(this.mediaId, this.mediaType, StreamMarketingToolsOverlaysTypes.OVERLAY),
            courses: this.streamService.getPublicOverlays(this.mediaId, this.mediaType, StreamMarketingToolsOverlaysTypes.COURSES)
        }).pipe(
            takeUntil(this.destroy$),
            tap(({ courses, overlays }) => {
                this.courses = courses.map((course) => {
                    course.isCourses = true;
                    course.controls = { isClosed: false, isManual: !!!course.startTime, isShowed: false, isShowing: false };
                    return course;
                });

                this.overlays = overlays.map((overlay) => {
                    overlay.controls = { isClosed: false, isManual: !!!overlay.startTime, isShowed: false, isShowing: false };
                    return overlay;
                });
            })
        )
            .subscribe(() => {
                this.config.overlays = this.resolveOverlays(mediaSource.subtype, this.overlays);
                this.config.courses = this.resolveOverlays(mediaSource.subtype, this.courses);
                this.streamService.overlaysUpdated();
            });
    }


    initAdvancedSettings(): void {
        this.chatEnabled = this.data?.advancedSettings?.chatEnabled;
        this.shareButtonEnabled = this.data?.advancedSettings?.shareButtonEnabled;
        this.displayVideoTitle = this.data?.advancedSettings?.displayVideoTitle;
        this.accessProps.onlyForRegistered = this.data?.advancedSettings?.onlyForRegistered && !this.isAuthorized;
        this.initCountryRestriction();
        this.initAgeRestriction();
        this.initPresentation();
        this.initCourses();
    }

    private listenStreamStatus() {
        this.streamService.streamStatus$
            .pipe(takeUntil(this.destroy$))
            .subscribe((status) => {
                if (!status) {
                    return;
                }
                this.refreshStatus(status);

                if (this.mediaType === MEDIA_TYPE.STREAM && !this.isRecord) {
                    this.streamService.getMediaChannel(this.mediaId)
                        .pipe(takeUntil(this.destroy$))
                        .subscribe((response) => {
                            if (response.success) {
                                this.streamService.setStreamChannel(response.results.data.liveChannel);
                            }
                        });
                }
            });

        this.streamService.streamChannel$
            .pipe(takeUntil(this.destroy$))
            .subscribe(channel => {
                this.streamChannel = channel;
                this.initComponentRefs();
                this.refreshConfig();
            });
    }

    listenWindowResize(): void {
        this.showLandscapeMode = window.innerWidth <= 576 && !this.landscapeModeClosed;
        fromEvent(window, 'resize')
            .pipe(
                takeUntil(this.destroy$),
                debounceTime(50),
                pluck('target')
            )
            .subscribe((window: Window) => {
                this.showLandscapeMode = window.innerWidth <= 576 && !this.landscapeModeClosed;
            });
    }

    onCloseLandscapeMode() {
        this.showLandscapeMode = false;
        this.landscapeModeClosed = true;
    }

    public initComponentRefs(): void {
        this._refsDestroyer$.next();
        const mediaSource: IMediaSource = this.ageRestricted ? {} : this.getMediaSource();
        this.componentRefs = [
            {
                component: LinkedStreamsModalComponent,
                initComponentFn: (instance: LinkedStreamsModalComponent) => {
                    instance.currentMedia = this.data;
                    instance.mode = this.mode;
                    instance.playerDisplayMode = this.playerDisplayMode;
                },
                containerRef: this.linkedStreamTmpl,
                hide: !this.data?.linkedMedia?.length
            },
            {
                component: GroupsModalComponent,
                initComponentFn: (instance: GroupsModalComponent) => {
                    instance.groups = this.data?.video?.groups;
                    instance.mediaId = this.mediaId;
                    instance.mode = this.mode;
                    instance.termsConditions = this.data?.termsConditions;
                    instance.embedded = this.embedded;
                },
                hide: !this.data?.video?.groups?.length,
                containerRef: this.groupsTmpl
            },
            {
                component: SlotsModalComponent,
                initComponentFn: (instance: SlotsModalComponent) => {
                    instance.slots = this.data?.programSettings?.slots;
                    instance.programName = this.data?.programSettings?.name;
                    instance.media = this.data?.video;
                    instance.termsConditions = this.data?.termsConditions;
                    instance.playerMode = this.playerDisplayMode;
                    instance.playSlot$.pipe(takeUntil(this._refsDestroyer$))
                        .subscribe((id) => {
                            this.playSlot(id);
                        });
                },
                containerRef: this.slotsTmpl,
                hide: !this.data?.programSettings?.slots?.length || this.mode === PLAYER_MODE.PUBLISHER
            },
            {
                component: PdfViewerModalComponent,
                initComponentFn: (instance: PdfViewerModalComponent) => {
                    instance.mode = this.playerDisplayMode === PLAYER_DISPLAY_MODE.EMBEDDED ? CHAT_MODE.EMBEDDED : CHAT_MODE.DEFAULT;
                    instance.config = this.configPdf;
                    instance.playerMode = this.mode;
                },
                containerRef: this.presentationTmpl,
                hide: !this.presentationEnabled
            },
            {
                component: ChatComponent,
                initComponentFn: (instance: ChatComponent) => {
                    instance.isOwner = this.mode === PLAYER_MODE.PUBLISHER;
                    instance.mediaId = this.mediaId;
                    instance.title = this.data?.video.title;
                    instance.active = this.chatEnabled;
                    instance.mode = this.embeddedChatMode;
                    instance.playerDisplayMode = this.playerDisplayMode;
                    instance.connection = this.chatSocketConnection$;
                    instance.isAuthorized = this.isAuthorized;
                    instance.fullScreen$ = this.fullScreen$.asObservable();
                    instance.chatPageLink = `watch/stream/${this.mediaId}/chat`;
                    instance.reconnectSocket$.pipe(takeUntil(this._refsDestroyer$))
                        .subscribe((mediaId) => {
                            this.reconnectToChatSockets(mediaId);
                        });
                },
                containerRef: this.chatTmpl
            },
            {
                component: DonationsComponent,
                initComponentFn: (instance: DonationsComponent) => {
                    instance.mediaId = this.mediaId;
                    instance.mediaType = this.mediaType;
                    instance.currency = this.data?.video?.price?.currency;
                    instance.donationsMode = this.embeddedChatMode;
                    instance.playerMode = this.mode;
                    instance.fullScreen$ = this.fullScreen$.asObservable();
                },
                containerRef: this.donationsTmpl
            },
            {
                component: StreamWorldMapComponent,
                initComponentFn: (instance: StreamWorldMapComponent) => {
                    instance.id = 1;
                    instance.streamId = this.mediaId;
                    instance.mode = this.embeddedChatMode;
                    instance.playerDisplayMode = this.playerDisplayMode;
                    instance.fullScreen$ = this.fullScreen$.asObservable();
                },
                containerRef: this.mapTmpl
            },
            {
                component: ViewerSurveysComponent,
                initComponentFn: (instance: ViewerSurveysComponent) => {
                    instance.mediaId = this.mediaId;
                    instance.mediaType = this.mediaType;
                    instance.signalObservable = this.playerSignalObservable;
                    instance.streamScheduledTime = this.resolveStreamScheduledTime();
                    instance.playerSignalObservable = this.playerSignalObservable;
                    instance.isRecord = this.isRecord;
                    instance.startedLiveAt = this.data?.video?.startedLiveAt;
                },
                containerRef: this.surveysTmpl,
                hide: !(mediaSource?.subtype === MEDIA_SUBTYPE.MAIN && mediaSource.url !== VideoUrl.EMPTY)
            },
            {
                component: InfoLineComponent,
                initComponentFn: (instance: InfoLineComponent) => {
                    let donationsInfoLine: IPlayerInfoLine;
                    if (this.donationOptions?.length && this.premiumPublisher) {
                        donationsInfoLine = {
                            text: 'player.button.donate',
                            cssClass: STATUS_CSS_CLASS.DONATE,
                            showOn: STATE.PLAY,
                            cssIconClass: 'hand-heart',
                            action: () => this.donationState.toggleDonations()
                        };
                    }
                    // TODO REFACTOR THIS
                    instance.infoLines = [
                        // tslint:disable-next-line:max-line-length
                        [MEDIA_TYPE.STREAM, MEDIA_TYPE.STREAM_RECORDING].includes(this.mediaType) ? this.infoLineHelper.primaryInfoline(this.data, this.mode, mediaSource.subtype) : null,
                        // tslint:disable-next-line:max-line-length
                        [MEDIA_TYPE.STREAM, MEDIA_TYPE.STREAM_RECORDING, MEDIA_TYPE.VIDEO_ON_DEMAND].includes(this.mediaType) ? this.infoLineHelper.secondaryInfoline(this.data, this.mode, mediaSource.subtype, donationsInfoLine) : null,
                        // tslint:disable-next-line:max-line-length
                        this.infoLineHelper.thirdInfoline(this.data, this.mode, mediaSource.subtype, ![MEDIA_TYPE.VIDEO].includes(this.mediaType) && !this.data?.video?.hasRecord && ![STREAM_STATUS.LIVE, STREAM_STATUS.ACTIVATED, STREAM_STATUS.ACTIVATED, STREAM_STATUS.PAUSED, STREAM_STATUS.DEACTIVATED, STREAM_STATUS.FINISHED].includes(this.data?.video?.status?.stream) ? donationsInfoLine : null)
                    ].filter(infoLine => infoLine?.text);
                },
                containerRef: this.infoTmpl
            },
            {
                component: ShareModalComponent,
                initComponentFn: (instance: ShareModalComponent) => {
                    instance.mediaId = this.mediaId;
                },
                containerRef: this.shareTmpl
            },
        ];
    }


    private connectToSockets(): void {
        this.donationsSocketConnection$ = this.donationsService.connectToSocket(this.mediaId)
            .pipe(takeUntil(this.destroy$))
            .subscribe();
        this.chatSocketConnection$ = this.chatService.connect(this.mediaId);
    }

    public reconnectToChatSockets(mediaId: string): void {
        this.chatSocketConnection$ = this.chatService.reconnect(mediaId);
    }

    private listenShareDateStatus(): void {
        this.streamService.toggleStreamShowDate$
            .pipe(takeUntil(this.destroy$))
            .subscribe((res) => {
                this.data.advancedSettings.showStreamDate = res;
                this.initComponentRefs();
                this.refreshConfig();
            });
    }

    private listenChatStatus(): void {
        this.chatState.chatEnabledState$
            .pipe(takeUntil(this.destroy$))
            .subscribe((res) => {
                this.chatEnabled = res;
                this.refreshButtonsConfig();
            });
    }

    private listenMapStatus(): void {
        this.worldMapState.worldMapEnabled$
            .pipe(takeUntil(this.destroy$))
            .subscribe((res) => {
                if (res !== this.mapEnabled) {
                    this.mapEnabled = res;
                    this.refreshButtonsConfig();
                }
            });
    }

    private listenShareStatus(): void {
        this.worldMapState.shareButtonEnabled$
            .pipe(takeUntil(this.destroy$), filter((res) => res !== null))
            .subscribe((res) => {
                this.shareButtonEnabled = res;
                this.refreshButtonsConfig();
            });
    }


    private initCountryRestriction(): void {
        if (this.data?.restrictions.includes(CONTENT_RESTRICTION.RESTRICTED_BY_COUNTRY)) {
            this.restrictedByCountry = true;
        }
    }

    private refreshConfig(): void {
        const mediaSource: IMediaSource = this.ageRestricted ? {} : this.getMediaSource();
        const backUpMediaSource: IMediaSource = this.ageRestricted ? {} : this.getBackUpMediaSource();
        let currentMediaSource: IMediaSource = mediaSource;
        if (this.streamChannel === 'BACKUP') {
            currentMediaSource = backUpMediaSource;
        }
        if (this.streamChannel === 'DEFAULT') {
            currentMediaSource = mediaSource;
        }
        this.config = {
            isOwner: this.isOwner,
            mediaId: this.mediaId,
            mediaType: this.mediaType,
            isRecord: this.isRecord,
            startedLiveAt: this.data?.video?.startedLiveAt,
            thumbnailUrl: this.data?.video?.previewPicture?.photoUrl,
            fallbackUrl: this.data?.video?.fallbackPicture?.photoUrl,
            showFallback: (this.data?.video?.hasBeenLive || this.hasBeenLive) && this.data?.video?.status?.stream === STREAM_STATUS.ACTIVATED,
            mediaSource: currentMediaSource,
            overlays: this.resolveOverlays(mediaSource.subtype, this.overlays),
            courses: this.resolveOverlays(mediaSource.subtype, this.courses),
            unstoppable: this.unstoppable(mediaSource.subtype),
            disabled: this.disabled(mediaSource.subtype),
            controlsDisabled: mediaSource.url !== VideoUrl.EMPTY,
            reloadOnError: this.reloadOnError(mediaSource.subtype, mediaSource),
            autoplay: this.data?.video?.status?.stream === 'SCHEDULED' ? false : this.autoplay,
            playFrom: this.playFrom,
            infoLines: [],
            vr: this.data?.video?.streamType === LIVESTREAM_TYPE.THREE_SIXTY,
            componentRefs: this.getComponentsRefs(),
            streamScheduledTime: this.resolveStreamScheduledTime()
        };

        this.remindService.state.active = this.data?.reminders?.viewerRemindersEnabled;
        this.refreshButtonsConfig();
    }

    private resolveStreamScheduledTime(): number {
        if (this.mediaType === MEDIA_TYPE.VIDEO_ON_DEMAND) {
            return null;
        }

        if (this.data?.video?.status?.stream === STREAM_STATUS.FINISHED) {
            return null;
        }

        return this.data?.video?.startTimestamp;
    }

    getComponentsRefs(): { ref: HTMLElement; hide: boolean; }[] {
        const res: any[] = [];
        this.componentRefs.filter((component) => !component.hide).forEach(component => {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component.component);
            const viewContainerRef = component.containerRef;
            viewContainerRef.clear();
            const componentRef = viewContainerRef.createComponent(componentFactory);
            const instance = (componentRef.instance);
            component.initComponentFn(instance);
            res.push({ref: componentRef.location.nativeElement, hide: component.hide});
        });
        this.refsInitialized = true;
        this._refs = res;
        return res;
    }

    initSlotAccess() {
        this.data?.programSettings?.slots.map((slot) => {
            slot.hasAccess = this.mediaUrl?.paymentSlots ? !!this.mediaUrl?.paymentSlots?.includes(slot.id) : true;
        });
    }

    private initSlots(): void {
        const isSlots = this.hasSlots();
        const isViewerMode = this.mode === PLAYER_MODE.VIEWER;
        if (!isSlots || !isViewerMode || this.isOwner) {
            return;
        }

        this.initSlotAccess();

        // if (this.data?.video?.hasRecord || this.data.video.videoType === MEDIA_TYPE.STREAM) {
        //     this.recalculateSlotsForRecord();
        // }

        this.initSlotsIntervalsArr();

        this.slotsService.slots = this.data.programSettings.slots;
    }

    private initSlotsIntervalsArr() {
        this.slotsIntervalsArr = [];
        this.data?.programSettings?.slots?.forEach(slot => {
            this.slotsIntervalsArr.push({
                start: slot.startTimestamp,
                end: slot.endTimestamp,
                hasAccess: slot.hasAccess
            });
        });
    }

    private recalculateSlotsForRecord(): void {
        this.data.programSettings.slots.forEach(slot => {
            const start = slot.startTimestamp;
            const end = slot.endTimestamp;

            if (start.toString().length < 13 && end.toString().length < 13) {
                return;
            }
            slot.startTimestamp = start - this.data.video.startTimestamp;
            slot.endTimestamp = end - this.data.video.startTimestamp;
        });
    }

    private getCurrentSlotByMs(time: number) {
        return this.slotsIntervalsArr?.find(interval => (time >= interval.start && time < interval.end));
    }

    private getCurrentSlotByDate(date: Date) {
        return this.slotsIntervalsArr?.find(interval => (date >= new Date(interval.start) && date < new Date(interval.end)));
    }

    private stopPlayingFreeMediaPart() {
        // we need timeout for opening modal while rewind because opening causes wrong values for currentTime() method in videojs player
        setTimeout(() => {
            this.openAccessModal();
        }, 100);
        this.playerController.next({play: false});
    }

    private refreshButtonsConfig(): void {
        const mediaSource: IMediaSource = this.ageRestricted ? {} : this.getMediaSource();
        this.buttonsConfig = [
            {
                cssIconClass: 'icon-users',
                disabled: !this.data?.video?.groups?.length || mediaSource.subtype !== MEDIA_SUBTYPE.MAIN,
                active: false,
                action: () => {
                    this.groupModalState.toggle();
                },
                stateSubject$: this.groupModalState.openSubj
            },
            {
                cssIconClass: 'icon-data-random',
                // tslint:disable-next-line:max-line-length
                disabled: !this.data?.programSettings?.slots?.length || mediaSource.subtype !== MEDIA_SUBTYPE.MAIN || this.mode === PLAYER_MODE.PUBLISHER,
                active: false,
                action: () => {
                    this.slotsModalState.toggle();
                },
                stateSubject$: this.slotsModalState.openSubj
            },
            {
                cssIconClass: 'icon-presentation',
                disabled: !this.presentationEnabled || mediaSource.subtype !== MEDIA_SUBTYPE.MAIN,
                active: false,
                action: () => {
                    this.presentationState.toggle();
                },
                stateSubject$: this.presentationState.activeState$
            },
            {
                cssIconClass: 'location',
                // tslint:disable-next-line:max-line-length
                disabled: (!this.mapEnabled) || (this.mediaType === MEDIA_TYPE.VIDEO_ON_DEMAND || mediaSource.subtype !== MEDIA_SUBTYPE.MAIN),
                active: false,
                action: () => {
                    this.worldMapState.toggleWorldMap();
                },
                stateSubject$: this.worldMapState.worldMapActiveState$
            },
            {
                cssIconClass: 'icon-chat',
                disabled: !this.chatEnabled || mediaSource.subtype !== MEDIA_SUBTYPE.MAIN,
                active: false,
                action: () => {
                    if (this.isAuthorized) {
                        this.chatState.toggleChat();
                    } else {
                        this.accessProps.showLogin = true;
                        this.openAccessModal();
                    }
                },
                stateSubject$: this.chatState.chatActiveState$
            },
            {
                cssIconClass: 'icon-share-alt',
                disabled: !this.shareButtonEnabled || mediaSource.subtype !== MEDIA_SUBTYPE.MAIN,
                active: false,
                action: () => {
                    this.shareModalState.toggle();
                },
                stateSubject$: this.shareModalState.openSubj
            },
            {
                cssIconClass: 'icon-360',
                disabled: this.data?.video?.streamType !== LIVESTREAM_TYPE.THREE_SIXTY || mediaSource.subtype !== MEDIA_SUBTYPE.MAIN,
                active: false,
                standAlone: true
            },
            {
                cssIconClass: 'icon-calendar',
                disabled: this.mediaType === MEDIA_TYPE.VIDEO_ON_DEMAND
                    || this.data?.video.status.stream !== STREAM_STATUS.SCHEDULED || mediaSource.subtype !== MEDIA_SUBTYPE.MAIN,
                active: false,
                action: () => {
                    this.downloadIcs();
                },
                stateSubject$: null
            },
            {
                cssIconClass: 'icon-linked',
                disabled: !this.data?.linkedMedia?.length,
                active: false,
                action: () => {
                    this.streamLinksService.isShowLinkedStreamsComponent = true;
                },
                stateSubject$: null
            },
            {
                cssIconClass: !this.remindService.state.active ? 'icon-bell' : 'icon-bell-active',
                disabled: !this.data?.reminders?.mediaRemindersEnabled || this.mediaType === MEDIA_TYPE.VIDEO_ON_DEMAND
                    || this.data?.video.status.stream !== STREAM_STATUS.SCHEDULED || mediaSource.subtype !== MEDIA_SUBTYPE.MAIN,
                active: false,
                action: () => {
                    this.toggleRemind(!this.remindService.state.active);
                },
                stateSubject$: null
            }
        ];

    }

    downloadIcs(): void {
        this.streamService.getStreamSchedule(this.data?.video.id)
            .subscribe(res => {
                saveAs(
                    res.body,
                    this.data?.video.title.replace(/\s+/g, '_').trim() + '.ics'
                );
            });
    }

    private unstoppable(mediaSourceSubtype: MEDIA_SUBTYPE): boolean {
        return [MEDIA_SUBTYPE.TRAILER, MEDIA_SUBTYPE.TEASER].includes(mediaSourceSubtype);
    }

    private disabled(mediaSourceSubtype: MEDIA_SUBTYPE): boolean {
        if ([MEDIA_TYPE.VIDEO_ON_DEMAND].includes(this.mediaType)) {
            return false;
        }
        if ([MEDIA_SUBTYPE.TEASER, MEDIA_SUBTYPE.TRAILER].includes(mediaSourceSubtype)) {
            return false;
        }

        if (this.data?.video.status.stream === STREAM_STATUS.LIVE || this.data?.video.status.record === RECORD_STATUS.RECORDED) {
            return false;
        }

        return true;
    }

    private reloadOnError(mediaSourceSubtype: MEDIA_SUBTYPE, mediaSource: IMediaSource): boolean {
        // tslint:disable-next-line:max-line-length
        return [MEDIA_SUBTYPE.MAIN].includes(mediaSourceSubtype) && this.data?.video?.status?.stream === STREAM_STATUS.LIVE && mediaSource.url !== VideoUrl.EMPTY;
    }

    private resolveOverlays(mediaSourceSubtype: MEDIA_SUBTYPE, returnObject): IPublicOverlay[] {
        const isVideoOnDemand = this.mediaType === MEDIA_TYPE.VIDEO_ON_DEMAND;
        const isMainSubtype = mediaSourceSubtype === MEDIA_SUBTYPE.MAIN;
        // tslint:disable-next-line:max-line-length
        const isStream = this.data?.video?.status?.stream === STREAM_STATUS.LIVE || this.data?.video?.status?.record === RECORD_STATUS.RECORDED;
        return (isMainSubtype && (isVideoOnDemand || isStream)) ? returnObject : null;
    }

    private initAgeRestriction(): void {
        // tslint:disable-next-line:max-line-length
        this.ageRestricted = this.mode === PLAYER_MODE.VIEWER && this.data?.advancedSettings?.ageRestriction && !asBoolean(this.getStorageItem('isAbove18'));
    }

    private refreshStatus(newStatus: IVideoStatus): void { // TODO LIVESTREAM check
        if ([STREAM_STATUS.LIVE, STREAM_STATUS.PAUSED].includes(newStatus.stream)) {
            this.currentLivestreamTime = newStatus.live?.duration;
        } else {
            this.currentLivestreamTime = null;
        }

        if ([STREAM_STATUS.FINISHED].includes(newStatus.stream) && !this.data?.video?.hasRecord) {
            this.accessProps.isStreamFinished = true;
        }

        if (STREAM_STATUS.LIVE === newStatus.stream) {
            this.hasBeenLive = true;
        }

        if (!this.statusChanged(newStatus)) {
            return;
        }
        this.initComponentRefs();
        if (this.data?.video?.status?.stream === STREAM_STATUS.PAUSED && newStatus.stream === STREAM_STATUS.LIVE) {
            this.autoplay = true;
        } else {
            this.autoplay = false;
        }
        if (this.recordingComplete(newStatus.record)) {
            this.initMediaUrl();
        } else {
            this.data.video.status = newStatus;
            if ([STREAM_STATUS.LIVE, STREAM_STATUS.PAUSED].includes(newStatus.stream)) {
                this.config = null;
                setTimeout(() => this.refreshConfig(), 10);
                return;
            }
            this.refreshConfig();
        }
    }

    private initMediaUrl(newStatus?) {
        const urlBody: IMediaContentRequest = {
            productId: this.mediaId,
            videoType: this.mediaType,
            originReferer: '',
        };
        if (document.location.ancestorOrigins !== undefined && document.location.ancestorOrigins.length) {
            urlBody.originReferer = document.location.ancestorOrigins[document.location.ancestorOrigins.length - 1];
        }

        if (this.streamService.viewerAccessToken) {
            urlBody.accessToken = StreamService.getViewerAccessTokenFromStorage();
        }
        this.streamService.getMediaUrl(urlBody)
            .pipe(takeUntil(this.destroy$))
            .subscribe((response) => {
                if (response.success && response.results.data) {
                    this.mediaUrl = response.results.data;
                    this.hasPartialAccess = true;
                }
                if (!response.success) {
                    if (response.errorKey === 'PRODUCT_RESTRICTED_BY_DOMAIN') {
                        this.restrictedByDomain = true;
                    }
                    this.hasPartialAccess = false;
                }

                this.loading = false;
                this.data.video.status = newStatus ? newStatus : this.data.video.status;

                this.initSlots();
                this.initComponentRefs();
                this.checkFullAccess();
                this.refreshConfig();
        });
    }

    private statusChanged(newStatus: IVideoStatus): boolean {
        return !newStatus || this.data?.video?.status?.stream !== newStatus.stream || this.data?.video?.status?.record !== newStatus.record;
    }

    private liveStreamFinished(newStatus: IVideoStatus): boolean {
        return this.data?.video?.status?.stream !== STREAM_STATUS.FINISHED && newStatus.stream === STREAM_STATUS.FINISHED;
    }

    private recordingComplete(newStatus: RECORD_STATUS): boolean {
        return this.data?.video?.status?.record === RECORD_STATUS.STORING && newStatus === RECORD_STATUS.RECORDED;
    }

    private getMediaSource(): IMediaSource {
        if (this.showTeaser()) {
            return this.teaserMediaSource();
        }

        if (this.showTrailer()) {
            return this.trailerMediaSource();
        }
        this.accessProps.isTeaserOrTrailer = false;
        if (!this.hasPartialAccess) {
            // this.accessProps.checkPayment = this.mode === PLAYER_MODE.VIEWER;
            return {};
        }

        return this.mainMediaSource();
    }

    private getBackUpMediaSource(): IMediaSource {
        if (!this.hasPartialAccess) {
            return {};
        }

        return this.backUpMediaSource();
    }

    private showTeaser(): boolean {
        return this.mode === PLAYER_MODE.VIEWER && this.teaser && this.teaser.active && this.teaser.videoUrl && !this.isTeaserShown();
    }

    private teaserMediaSource(): IMediaSource {
        this.accessProps.isTeaserOrTrailer = true;
        return {
            url: this.teaser.videoUrl,
            type: MEDIA_FORMAT.VIDEO,
            clickUrl: this.teaser.advertisingUrl,
            subtype: MEDIA_SUBTYPE.TEASER
        };
    }

    private showTrailer(): boolean {
        return this.mode === PLAYER_MODE.VIEWER && this.trailer && this.trailer.active && this.trailer.videoUrl && !this.isTrailerShown();
    }

    private trailerMediaSource(): IMediaSource {
        this.accessProps.isTeaserOrTrailer = true;
        return {
            url: this.trailer.videoUrl,
            type: MEDIA_FORMAT.VIDEO,
            clickUrl: this.trailer.advertisingUrl,
            subtype: MEDIA_SUBTYPE.TRAILER
        };
    }

    private mainMediaSource(): IMediaSource {
        return {
            url: this.resolveMediaUrl(),
            type: this.resolveContentType(),
            subtype: MEDIA_SUBTYPE.MAIN
        };
    }

    private backUpMediaSource(): IMediaSource {
        return {
            url: this.resolveBackUpMediaUrl(),
            type: this.resolveContentType(),
            subtype: MEDIA_SUBTYPE.MAIN
        };
    }

    private resolveContentType(): MEDIA_FORMAT {
        return this.mediaType === MEDIA_TYPE.VIDEO_ON_DEMAND ? MEDIA_FORMAT.VIDEO : MEDIA_FORMAT.STREAM;
    }

    private isTeaserShown(): boolean {
        return !!this.getServiceStorageItem('teaserShown');
        // return !!this.getStorageItem('teaserShown');
    }

    private isTrailerShown(): boolean {
        return !!this.getServiceStorageItem('trailerShown');
        // return !!this.getStorageItem('trailerShown');
    }

    private getStorageItem(name: string): string {
        return localStorage.getItem(this.mediaId + '.' + name);
    }

    private setStorageItem(name: string, value: string): void {
        localStorage.setItem(this.mediaId + '.' + name, value);
    }

    private getServiceStorageItem(name: string) {
        return this.userService.getStorageItem(this.mediaId + '.' + name);
    }

    private setServiceStorageItem(name: string, value: string): void {
        this.userService.setStorageItem(this.mediaId + '.' + name, value);
    }

    public onVideoEnd(mediaSubtype: MEDIA_SUBTYPE): void {
        switch (mediaSubtype) {
            case MEDIA_SUBTYPE.TEASER:
                this.setServiceStorageItem('teaserShown', 'true');
                this.advertisementService.teaserView(this.mediaType, this.mediaId)
                    .pipe(takeUntil(this.destroy$))
                    .subscribe(() => {
                    });
                this.autoplay = true;
                this.initComponentRefs();
                this.refreshConfig();
                break;
            case MEDIA_SUBTYPE.TRAILER:
                this.setServiceStorageItem('trailerShown', 'true');
                this.advertisementService.trailerView(this.mediaType, this.mediaId)
                    .pipe(takeUntil(this.destroy$))
                    .subscribe(() => {
                    });
                this.autoplay = true;
                this.initComponentRefs();
                this.refreshConfig();
                break;
            case MEDIA_SUBTYPE.MAIN:
                if (this.data?.linkedMedia?.length) {
                    this.streamLinksService.isShowLinkedStreamsComponent = true;
                }
                break;
        }
    }

    public onVideoClick(mediaSubtype: MEDIA_SUBTYPE): void {
        switch (mediaSubtype) {
            case MEDIA_SUBTYPE.TEASER:
                this.advertisementService.teaserClick(this.mediaType, this.mediaId)
                    .pipe(takeUntil(this.destroy$))
                    .subscribe(() => {
                    });
                break;
            case MEDIA_SUBTYPE.TRAILER:
                this.advertisementService.trailerClick(this.mediaType, this.mediaId)
                    .pipe(takeUntil(this.destroy$))
                    .subscribe(() => {
                    });
                break;
        }
    }

    hasSlots() {
        return !!this.data?.programSettings?.slots?.length;
    }

    private refreshContainerSize(): void {
        if (!this.videoWrapper) {
            return;
        }
        const height = Math.floor(this.videoWrapper.nativeElement.clientWidth * 0.5625);
        this.videoWrapper.nativeElement.style.height = height + 'px';
    }

    public isViewer(): boolean {
        return this.mode === PLAYER_MODE.VIEWER;
    }

    confirmAge(isAbove18) {
        if (!this.ageRestricted) {
            return;
        }
        this.ageRestricted = !isAbove18;
        this.initComponentRefs();
        this.refreshConfig();

    }

    private resolveMediaTypeAccess() {
        const isVideoOnDemand = this.mediaType === MEDIA_TYPE.VIDEO_ON_DEMAND;
        const isLiveStream =  this.data?.video?.status?.stream === STREAM_STATUS.LIVE;
        const isStreamPaused = this.data?.video?.status?.stream === STREAM_STATUS.PAUSED;
        const isRecordedStream = this.data?.video?.status?.record === RECORD_STATUS.RECORDED;
        const isPublisher = this.mode === PLAYER_MODE.PUBLISHER;

        return isVideoOnDemand || isLiveStream || isRecordedStream || (isStreamPaused && isPublisher);
    }

    private resolveMediaUrl(): string {
        return this.mediaUrl?.mediaUrl && this.resolveMediaTypeAccess() ? urlWithProtocol(this.mediaUrl?.mediaUrl) : VideoUrl.EMPTY;
    }

    private resolveBackUpMediaUrl(): string {
        return this.mediaUrl?.backupPlayerUrl && this.resolveMediaTypeAccess() ? urlWithProtocol(this.mediaUrl?.backupPlayerUrl) : null;
    }

    public onPlayerSignal(signal: IScheduleSignal): void {
        if (signal.start || signal.start === 0) {
            if ([MEDIA_TYPE.STREAM].includes(this.mediaType)) {
                const iTime: ITime = {type: ITimeType.CLOCK_TIME, time: new Date().getTime()};
                this.checkSlotInterval(iTime, true);
            }
            this.playerPaused = false;
        }
        if (signal.stop || signal.pause) {
            this.playerPaused = true;
        }
        this.playerSignal.next(signal);
    }

    checkDonation(transactionId: string): void {
        this.donationsService.checkDonation(transactionId).pipe(take(1)).subscribe(result => {
            if (!result?.success) {
                return;
            }
            switch (result?.results?.result?.paymentStatus) {
                case PAYMENT_STATUS.SUCCESS: {
                    localStorage.removeItem('currentDonation');
                    return setTimeout(() => {
                        return this.donationState.toggleDonations();
                    }, 2000);
                }
                case PAYMENT_STATUS.PENDING: {
                    return setTimeout(() => {
                        return this.checkDonation(transactionId);
                    }, 2000);
                }
                case PAYMENT_STATUS.FAILED: {
                    const failed = {
                        failedCardId: result?.results?.result?.externalCardId,
                        transactionFailReason: result?.results?.result?.reason || PAYMENT_FAIL_MESSAGES.FAILED,
                        absoluteError: true
                    };
                    this.paymentService.failedTransactionInfo.next(failed);
                    return setTimeout(() => {
                        this.donationState.toggleDonations();
                        return this.donationState.setFailed(failed);
                    }, 1000);
                }
            }
        });
    }

    listenQueryParams(): void {
        this.activatedRoute.queryParams.pipe(takeUntil(this.destroy$)).subscribe(params => {
            this.queryParams = params;

            if (params.hasOwnProperty('paymentReturn') && !this.isAuthorized) {
                this.accessProps.showRegistrationOffer = true;
            }

            if (params.hasOwnProperty('paymentReturn')
                && this.mediaType === MEDIA_TYPE.STREAM
                && this.data?.video.status.stream !== STREAM_STATUS.SCHEDULED) {
                this.showScheduleMessage = true;
            }

            if (params.hasOwnProperty('donationReturn') && params.hasOwnProperty('transactionId')) {
                this.checkDonation(params['transactionId']);
                clearQueryParams(this.location);
            }
        });
    }

    private checkIsOwner(): void {
        this.authService.isAuthorized$.pipe(takeUntil(this.destroy$))
            .subscribe(authorized => {
                if (!authorized) {
                    this.isAuthorized = false;
                    this.isOwner = false;
                    return;
                }

                this.isAuthorized = true;
                this.userService.userProfile$.pipe(takeUntil(this.destroy$))
                    .subscribe((profile: IUserProfile) => {
                        this.isOwner = profile?.ownerId === this?.data?.video?.owner;
                    });
            });
    }

    public onOverlayClick(overlay: IOverlay): void {
        this.streamService.overlayClick(this.mediaType, this.mediaId, overlay).pipe(takeUntil(this.destroy$)).subscribe();
    }

    public onOverlayViewed(overlay: IOverlay): void {
        this.streamService.overlayView(this.mediaType, this.mediaId, overlay).pipe(takeUntil(this.destroy$)).subscribe();
    }

    private initCourses() {
        this.isCourses = this.data?.video.paymentSystem.paymentSystemGroup === PAYMENT_SYSTEM.COURSE_ACCESS;
    }

    private initPresentation() {
        this.presentationEnabled = !!this.data?.presentation?.slides.length;

        this.configPdf = {
            isDownload: true,
            isLink: true,
            mediaId: this.mediaId,
            presentation: this.data?.presentation,
            embeddedChatMode: this.embeddedChatMode,
        };
    }

    toggleRemind(active) {
        if (!this.isAuthorized) {
            this.showAccessModal = true;
            this.remindService.showViewerAccessModal$.next(true);
            return;
        }

        this.remindService.active(this.mediaId, {active: active})
            .pipe(takeUntil(this.destroy$))
            .subscribe(res => {
                this.remindService.state.active = active;
                this.refreshButtonsConfig();
            });
    }

    private listenRemind() {
        this.remindService.showViewerAccessModal$
            .pipe(takeUntil(this.destroy$))
            .subscribe(res => {
                if (!res) {
                    this.showAccessModal = false;
                }
            });

        this.remindService.changedStateActive$
            .pipe(takeUntil(this.destroy$))
            .subscribe(res => {
                this.data.reminders.viewerRemindersEnabled = this.remindService.state.active;
                this.refreshButtonsConfig();
            });
    }

    public onFullscreenToggle(event: boolean): void {
        this.fullScreen$.next(event);
    }

    public groupPaymentFailure(groupId): void {
        this.groupModalState.setState(true);
        this.groupModalState.paymentStatus$.next({
            groupId: groupId,
            status: false
        });
    }

    public openGroupModal(): void {
        this.groupModalState.setState(true);
    }

    public openSlotModal(): void {
        this.slotsModalState.setState(true);
    }

    public openAccessModal(): void {
        if (this.accessProps.isTeaserOrTrailer) {
            return;
        }
        this.showAccessModal = true;
    }

    public closeRegistrationOffer(): void {
        this.accessProps.showRegistrationOffer = false;
    }

    public closeAccessModal(): void {
        this.showAccessModal = false;
        this.closeRegistrationOffer();
    }

    private playSlot(id: string): void {
        this.currentSlot = this.data.programSettings.slots.find(item => item.id === id);
        this.playFrom = this.currentSlot.startTimestamp / 1000;
        this.refreshConfig();
        this.playFrom = null;
        this.slotsModalState.setState(false);
    }


    private initDonationOptions() {
        this.donationsService.getDonationOptions(this.mediaType, this.mediaId, 'null')
            .pipe(
                takeUntil(this.destroy$),
                filter((res) => !!res)
            )
            .subscribe((res) => {
                if (!this.isArraysEqual(this.donationOptions, res)) {
                     // 11
                    this.donationOptions = res;
                    this.refreshConfig();
                }

            });
    }

    private isArraysEqual(array1: any[], array2: any[]) {
        return  array1.length === array2.length && array1.every((value, index) => value === array2[index]);
    }

    private checkFullAccess() {
        const isNoSlotsToBuy = !this.mediaUrl?.paymentSlots;
        const unpaidSlots = !this.slotsService.accessCheckAll();
        const freeVideo = this.data.video.contentAccessType === 'FREE';
        const psLeadGeneration = this.data.accessOptions.psLeadGeneration;

        this.accessProps.fullAccess = this.isOwner
            || (this.hasPartialAccess && isNoSlotsToBuy)
            || (this.hasPartialAccess && freeVideo && !unpaidSlots && !psLeadGeneration);
    }

    onTimeUpdate(iTime: ITime) {
        this.checkSlotInterval(iTime);
    }

    private checkSlotInterval(iTime: ITime, force?: boolean) {
        if (
            this.accessProps.fullAccess
            || !this.data.accessOptions.psSlot
            || !iTime
            || this.accessProps.isTeaserOrTrailer
            || this.data?.video?.status?.stream === STREAM_STATUS.SCHEDULED
            || this.data?.video?.status?.stream === STREAM_STATUS.WAITING
            || this.slotsModalState.openSubj.value
        ) {
            return;
        }


        const slot = this.resolveCurrentSlot(iTime);
        if (this.mediaType === MEDIA_TYPE.STREAM && !this.isRecord) {
            if (slot && this.streamSlot === slot && !force) {
                return;
            }
            this.streamSlot = slot;
        }
        const isFreeVideo = this.data.video.contentAccessType === 'FREE';

        if (slot?.hasAccess || (!slot && isFreeVideo)) {
            return;
        }
        this.stopPlayingFreeMediaPart();
    }

    private resolveCurrentSlot(iTime: ITime) {
        switch (iTime.type) {
            case ITimeType.CLOCK_TIME:
                const date = new Date(iTime.time);
                return this.getCurrentSlotByDate(date);
            case ITimeType.MEDIA_TIME:
                const ms = iTime.time * 1000;
                return this.getCurrentSlotByMs(ms);
        }
    }

    onScheduleMessageClosed() {
        this.showScheduleMessage = false;
    }
}

interface IComponentRef {
    component: any;
    initComponentFn: (instance: any) => void;
    containerRef: ViewContainerRef;
    hide?: boolean;
}

export interface IAccessProps {
    isTeaserOrTrailer: boolean;
    fullAccess: boolean;
    isStreamFinished?: boolean;
    onlyForRegistered?: boolean;
    showRegistrationOffer?: boolean;
    showLogin?: boolean;
}


