import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-empty-results',
  templateUrl: './empty-results.component.html',
  styleUrls: ['./empty-results.component.css']
})
export class EmptyResultsComponent implements OnInit {
    @Input() text: string;

  constructor() { }

  ngOnInit() {
    if(!this.text) {
      this.text = 'results.loading';
    }
  }

}
