import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler, HttpHeaders,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AuthService } from '@src/app/services/auth/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService,
  ) { }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let token = this.authService.authToken;
    if (req.url.includes('open/api') && !token) {
      token = AuthService.getOpenTokenFromStorage();
    }
    if (token === 'null') {
    }
    const authReq = req.clone({
      headers: req.headers.set('authToken', token).append('Authorization', 'Basic YTM2MDozNjBh')
    });

    return next.handle(authReq).pipe(
      tap({
        error: err => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.authService.logout(false);
            }
          }
        }
      })
    );
  }
}
