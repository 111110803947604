import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import {BILL_STATUS, BillsService, IBill} from '@src/app/services/bills/bills.service';
import {IBillsTableConfig} from '@src/app/pages/bills-page/components/bills-table/bills-table.component';
import {BillsConfig} from '@src/app/pages/bills-page/config/BillsConfig';
import {takeUntil} from 'rxjs/operators';
import {ITableColumn, TEMPLATE_ID} from  'ui-elements';
import {ITimeRangeConfig} from 'ui-elements';
import {MEDIA_FILTERS_FIELDS, MediaListService} from '@src/app/services/media-list/media-list.service';
import {STREAM_STATUS} from '@src/app/components/streamdust-player/constants/status';
import {FILTER_MEDIA_TYPES} from '@src/app/components/media-list/media-list-header/media-list-header.component';
import {getFileNameFromResponseContentDisposition} from '@src/app/utils/file-name-from-content-disposition.util';
import {saveAs} from 'file-saver/FileSaver';
import {USER_TYPE} from '@src/app/constants/user-type.constant';
import {UserService} from '@src/app/services/user/user.service';
import {BehaviorSubject, forkJoin, of} from 'rxjs';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app-bills-page',
    templateUrl: './bills-page.component.html',
    styleUrls: ['./bills-page.component.sass'],
    providers: [AutoDestroyService, BillsService, BillsConfig]
})
export class BillsPageComponent implements OnInit {
    public BILL_STATUS = BILL_STATUS;
    @ViewChild('dateTmpl', {static: true}) public dateTmpl: TemplateRef<any>;
    @ViewChild('amountTmpl', {static: true}) public amountTmpl: TemplateRef<any>;
    @ViewChild('dataSizeTmpl', {static: true}) public dataSizeTmpl: TemplateRef<any>;
    @ViewChild('dateRangeTmpl', {static: true}) public dateRangeTmpl: TemplateRef<any>;
    @ViewChild('actionsTmpl', {static: true}) public actionsTmpl: TemplateRef<any>;
    @ViewChild('statusTmpl', {static: true}) public statusTmpl: TemplateRef<any>;
    @ViewChild('subscriptionTmpl', {static: true}) public subscriptionTmpl: TemplateRef<any>;
    @ViewChild('mediaIdTmpl', {static: true}) public mediaIdTmpl: TemplateRef<any>;
    public billsTableConfigs: IBillsTableConfig<IBill>[];
    public timeRangeConfig: ITimeRangeConfig;
    public isPdfLoading = false;
    public pdfDownloadCurrentId: number;
    public billStatus = {
        [BILL_STATUS.PAID]: 'bills.status.paid',
        [BILL_STATUS.UNPAID]: 'bills.status.unpaid',
        [BILL_STATUS.REFUNDED]: 'bills.status.refunded',
    };

    constructor(
        private billsConfig: BillsConfig,
        private destroy$: AutoDestroyService,
        private mediaListService: MediaListService,
        private billsService: BillsService,
        private userService: UserService,
        private activatedRoute: ActivatedRoute
    ) {
    }

    ngOnInit(): void {
        this.resolveBillsTableConfigs();
    }

    private resolveBillsTableConfigs(): void {
        this.billsConfig.getConfig((this.activatedRoute.data as BehaviorSubject<any>)?.value?.isViewer).pipe(takeUntil(this.destroy$))
            .subscribe((res) => {
                this.billsTableConfigs = res;
                this.addTemplatesToConfigs();
                this.timeRangeConfig = {
                    fetchMethod: (filters) => this.mediaListService.getMediaListTimeRanges(filters),
                    withoutFilters: true,
                    filters: [
                        {
                            field: MEDIA_FILTERS_FIELDS.MEDIA_TYPE,
                            value: FILTER_MEDIA_TYPES.STREAM
                        },
                        {
                            field: MEDIA_FILTERS_FIELDS.STREAM_STATUS,
                            value: STREAM_STATUS.FINISHED
                        },
                        {
                            field: MEDIA_FILTERS_FIELDS.VISIBLE,
                            value: false
                        }
                    ]
                };
            });
    }

    private addTemplatesToConfigs(): void {
        this.billsTableConfigs.forEach((config: IBillsTableConfig<IBill>) => {
            config.tableConfig.columns.forEach((column: ITableColumn) => {
                if (column?.tmplId === TEMPLATE_ID.DATE) {
                    column.tmpl = this.dateTmpl;
                }

                if (column?.tmplId === TEMPLATE_ID.PRICE) {
                    column.tmpl = this.amountTmpl;
                }

                if (column?.tmplId === TEMPLATE_ID.VALUE_UNIT) {
                    column.tmpl = this.dataSizeTmpl;
                }

                if (column?.tmplId === TEMPLATE_ID.DATE_RANGE) {
                    column.tmpl = this.dateRangeTmpl;
                }

                if (column?.tmplId === TEMPLATE_ID.ACTIONS) {
                    column.tmpl = this.actionsTmpl;
                }

                if (column?.tmplId === TEMPLATE_ID.STATUS) {
                    column.tmpl = this.statusTmpl;
                }
                if (column?.tmplId === TEMPLATE_ID.SUBSCRIPTION_TYPE) {
                    column.tmpl = this.subscriptionTmpl;
                }

                if (column?.tmplId === TEMPLATE_ID.MEDIA_ID) {
                    column.tmpl = this.mediaIdTmpl;
                }
            });
        });
    }

    public downloadPdf(bill: IBill): void {
        if (this.isPdfLoading) {
            return;
        }
        this.pdfDownloadCurrentId = bill.purchaseId;

        this.isPdfLoading = true;
        this.userService.userType$.pipe(takeUntil(this.destroy$)).subscribe(userType => {
            if (userType === USER_TYPE.PUBLISHER) {
                const downloadPDFPublisher$ = this.billsService.downloadPDFPublisher(bill.purchaseId);
                const downloadPDFPublisherChargeBack$ = bill.chargeBack ? this.billsService.downloadPDFPublisherChargeBack(bill.purchaseId) : of(null);
                forkJoin([downloadPDFPublisher$, downloadPDFPublisherChargeBack$])
                    .pipe(takeUntil(this.destroy$))
                    .subscribe(([publisherRes, chargeBackRes]) => {
                        if (publisherRes) {
                            saveAs(
                                publisherRes.body,
                                getFileNameFromResponseContentDisposition(publisherRes)
                            );
                        }
                        if (chargeBackRes) {
                            saveAs(
                                chargeBackRes.body,
                                getFileNameFromResponseContentDisposition(chargeBackRes)
                            );
                        }
                        this.pdfLoadEnd();
                    }, () => this.pdfLoadEnd());
                return;
            }
            this.billsService.downloadPDFViewer(bill.purchaseId)
                .pipe(takeUntil(this.destroy$))
                .subscribe(res => {
                    this.pdfLoadEnd();
                    saveAs(
                        res.body,
                        getFileNameFromResponseContentDisposition(res)
                    );
                }, () => this.pdfLoadEnd());
        });
    }

    private pdfLoadEnd(): void {
        this.isPdfLoading = false;
        this.pdfDownloadCurrentId = null;
    }

}
