import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from '@src/app/services/auth/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {EmailConfirmationPageService} from '@src/app/pages/email-confirmation-page/services/email-confirmation-page/email-confirmation-page.service';
import {pluck, switchMap, tap} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {INFO_BOX_TYPES} from '@src/app/components/info-box/info-box.component';
import {IResponse} from '@src/app/models/response.model';
import {RouterEventHandlerService} from '@src/app/services/router-event-handler/router-event-handler.service';
import {HeaderPopupType, HeaderService} from '@src/app/services/header/header.service';
import {LocalizationProvider} from 'ui-elements';

@Component({
    selector: 'app-email-confirmation-page',
    templateUrl: './email-confirmation-page.component.html',
    styleUrls: ['./email-confirmation-page.component.css']
})
export class EmailConfirmationPageComponent implements OnDestroy {

    public localizationList = this.localizationProvider.localizationList;

    public loading = true;
    public isConfirmed: boolean;
    public errorMessage: string;
    public failed = false;

    public INFO_BOX_TYPES = INFO_BOX_TYPES;

    constructor(
        private authService: AuthService,
        private route: ActivatedRoute,
        private emailConfirmationPageService: EmailConfirmationPageService,
        private localizationProvider: LocalizationProvider,
        private routerEventHandlerService: RouterEventHandlerService,
        private headerService: HeaderService,
        private router: Router
    ) {
        this.checkCurrentRoute();
    }

    private handleQueryParamsRegistration(): void {

        this.route.queryParams.pipe(
            pluck('token'),
            switchMap(this.confirmEmail.bind(this)),
        ).subscribe(
            (authRes) => {
                this.loading = false;
                if (!authRes.success) {
                    this.failed = true;
                    this.errorMessage = authRes.errorKey;
                    return;
                }
                this.headerService.showHeaderPopup(HeaderPopupType.REGISTRATION_SET_PASSWORD);
                this.authService.tempToken = authRes.results.authToken;
                this.authService.registrationComplete$.subscribe(res => {
                    if (!res) {
                        return;
                    }
                    this.isConfirmed = true;
                    this.headerService.hideHeaderPopup();
                    this.router.navigate(['home']);
                });
            },
            () => {
                this.loading = false;
                this.errorMessage = 'Unhandled error occurred';
            },
        );
    }

    private handleQueryParamsUpdateEmail(): void {

        this.route.queryParams.pipe(
            pluck('token'),
            // switchMap(this.confirmEmailUpdate.bind(this)),
        ).subscribe(
            (token) => {
                this.loading = false;
                this.authService.tempToken = token;
                this.authService.registrationComplete$.subscribe(res => {
                    if (!res) {
                        return;
                    }
                    this.authService.storeToken(token);
                    this.isConfirmed = true;
                    this.router.navigate(['home']);
                });
            },
            () => {
                this.loading = false;
                this.errorMessage = 'Unhandled error occurred';
            },
        );
    }

    private confirmEmailUpdate(confirmationToken: string): Observable<TEmailConfirmationResponse> {
        return this.emailConfirmationPageService.confirmEmailUpdate(confirmationToken)
            .pipe(
                tap(({results: {authToken}, success, errorKey}) => {
                    if (!success) {
                        this.errorMessage = this.localizationProvider.getByErrorKey(errorKey);
                    }

                    if (authToken) {
                        this.authService.storeToken(authToken);
                        this.isConfirmed = true;
                    }
                }),
            );
    }

    private confirmEmail(confirmationToken: string): Observable<TEmailConfirmationResponse> {
        return this.emailConfirmationPageService.confirmEmail(confirmationToken)
            .pipe(
                tap(({results: {authToken}, success, errorKey}) => {
                    if (!success) {
                        this.errorMessage = this.localizationProvider.getByErrorKey(errorKey);
                    }

                    // if (authToken) {
                    //     this.authService.registrationComplete$.subscribe(res => {
                    //         if (!res) {
                    //             return;
                    //         }
                    //         this.authService.storeToken(authToken);
                    //         this.isConfirmed = true;
                    //         // this.router.navigate(['home']);
                    //     });
                    //
                    // }
                }),
            );
    }

    private checkCurrentRoute() {
        if (this.route.snapshot.routeConfig.data.isNewEmail) {
            this.handleQueryParamsUpdateEmail();
        } else {
            this.handleQueryParamsRegistration();
        }
    }

    ngOnDestroy(): void {
    }
}

type TEmailConfirmationResponse = IResponse<{ authToken: string }>;
