import { Component, OnInit } from '@angular/core';
import {INPUT_TYPES} from 'ui-elements';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-iframe-test',
  templateUrl: './iframe-test.component.html',
  styleUrls: ['./iframe-test.component.sass']
})
export class IframeTestComponent implements OnInit {
  INPUT_TYPES = INPUT_TYPES;
  mediaType: string;
  mediaId: string;
  url: SafeUrl;

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
  }

  public onLoad(): void {
    this.url = null;
    setTimeout(() => {
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(window.location.protocol + '//' + window.location.host + '/embedded/' + this.mediaType + '/' + this.mediaId);
    }, 500);
  }

}
