import {Injectable} from '@angular/core';
import {ApiService} from 'ui-elements';
import {Observable} from 'rxjs';
import {API_URLS_APP, UrlGenerator} from '@src/app/constants/api-urls.constant';
import {IResponse} from '@src/app/models/response.model';

@Injectable({
    providedIn: 'root'
})
export class VideoUploadService {

    constructor(
        private apiService: ApiService
    ) {
    }

    public uploadVideo(file: File): Observable<IResponse<IVideoUploadInfo>> {
        const formData = new FormData();
        formData.append('file', file);
        return this.apiService.post(API_URLS_APP.VIDEO_UPLOAD, formData);
    }

    public getVideo(videoId: string): Observable<IResponse<{ data: IVideoInfo }>> {
        return this.apiService.get(UrlGenerator.generate(API_URLS_APP.VIDEO_GET, {videoId}));
    }
}

export interface IVideoUploadInfo {
    id: string;
    url: string;
}

export interface IVideoInfo {
    id: string;
    provider: string;
    url: string;
    created: any;
    updated: any;
}
