<div class="row row-padding-10 align-items-center">
    <div class="col">
        <div class="table-responsive">
            <table class="table table-material table-border">
                <tbody>
                <tr>
<!--                    <td>{{'stream.overview.info.title.bandwidth' | localize}}</td>-->
                    <td>{{'stream.overview.info.title.audience' | localize}}</td>
<!--                    <td>{{'stream.overview.info.title.bitrate' | localize}}</td>-->
                    <td>{{'stream.overview.info.title.recording' | localize}} </td>
                </tr>
                <tr>
<!--                    <td>{{data?.bandwidth?.used?.value}} {{data?.bandwidth?.used?.unit}} (von {{data?.bandwidth?.limit?.value}} {{data?.bandwidth?.limit?.unit}})</td>-->
                    <td>{{data.audience || 0}}</td>
<!--                    <td>{{data?.bitrate?.value}} {{data?.bitrate?.unit}}</td>-->
                    <td><i class="icon {{data?.isRecording ? 'icon-check text-success' : 'icon-cross text-danger'}}"></i></td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="col-auto" *ngIf="status && status !== STREAM_STATUS.FINISHED">
        <button class="btn btn-secondary btn-icon" (click)="openModal()">
            <i class="icon icon-file-code"></i>
            {{'stream.encoderData.title' | localize}}
        </button>
    </div>
</div>
