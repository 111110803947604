import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {BehaviorSubject, fromEvent, interval, Observable, Subject} from 'rxjs';
import {AutoDestroyService} from 'ui-elements';
import {
    Environment,
    EXTERNAL_PLAYER_SIGNAL,
    IOverlay,
    IPlayerButton,
    IPlayerConfig,
    IPlayerOptions,
    IPlayerOverlay,
    ITime,
    ITimeType,
    MARKETING_ENTITY_TYPE,
    MEDIA_SUBTYPE,
    MEDIA_TYPE,
    OVERLAY_TYPE
} from 'ui-elements';
import {filter, takeUntil} from 'rxjs/operators';
import {IScheduleSignal} from 'ui-elements';
import {ENVIRONMENT} from 'ui-elements';
import {LocalizationProvider} from 'ui-elements';
import {StreamMarketingToolsOverlaysTypes} from '@src/app/pages/stream-page/components/stream-marketing-tools-overlays/stream-marketing-tools-overlays.component';
import {IPublicOverlay} from '@src/app/models/response.model';
import {MarketingService} from '@src/app/services/marketing/marketing.service';
import {MARKETING_ACTION, SURVEY_STATUS} from '@src/app/services/survey/survey.service';
import {StreamService} from '@src/app/services/stream-metadata/stream.service';

declare const videojs: any;

@Component({
    selector: 'app-videojs',
    templateUrl: './videojs.component.html',
    styleUrls: ['./videojs.component.sass'],
    providers: [AutoDestroyService]
})
export class VideojsComponent implements OnInit, AfterViewInit, OnDestroy {
    MEDIA_SUBTYPE = MEDIA_SUBTYPE;
    @ViewChild('videoWrapper', {static: true}) videoWrapper: ElementRef<HTMLElement>;
    @ViewChild('target', {static: true}) target: ElementRef;
    @Input('playerController') set _playerController(controller: BehaviorSubject<{play: boolean}>) {
        if (!controller) {
            return;
        }
        this.playerController = controller;
        this.playerController.pipe(takeUntil(this.destroy$), filter(res => !!res)).subscribe((signal) => {
            this.stopped = signal.play;
            if (signal.play) {
                this.player.play();
            } else {
                this.player.pause();
            }
        });
    }
    @Input('config')
    public set _config(config: IPlayerConfig) {
        this.config = config;
        this.overlays = this.resolveOverlaysTime(config.overlays) || [];
        this.courses = this.resolveOverlaysTime(config.courses, StreamMarketingToolsOverlaysTypes.COURSES) || [];
        this.retryCounter = 0;
        this.initOptions();
        this.initPlayer();
        this.handlePlayerErrors();
        this.resetOverlays();
    }

    @Input() set buttonsConfig(config: IPlayerButton[]) {
        this._buttonsConfig = config;
        this.initButtons();
    }

    get buttonsConfig(): IPlayerButton[] {
        return this._buttonsConfig;
    }

    private _buttonsConfig: IPlayerButton[];

    @Input('currentLivestreamTime')
    public set _currentLivestreamTime(time: number) {
        if (!time) {
            this.currentLivestreamTime = null;
            return;
        }
        this.currentLivestreamTime = Math.floor(time / 1000);

        this.initTimeGenerator();
    }

    @Input() externalPlayerSignal: Observable<EXTERNAL_PLAYER_SIGNAL>;

    @Output() videoEnded: EventEmitter<any> = new EventEmitter<any>();
    @Output() videoClicked: EventEmitter<any> = new EventEmitter<any>();
    @Output() playerSignal: EventEmitter<IScheduleSignal> = new EventEmitter<IScheduleSignal>();
    @Output() overlayClicked: EventEmitter<IPublicOverlay> = new EventEmitter<IPublicOverlay>();
    @Output() overlayViewed: EventEmitter<IPublicOverlay> = new EventEmitter<IPublicOverlay>();
    @Output() fullscreenToggle: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() timeUpdate: EventEmitter<ITime> = new EventEmitter<ITime>();
    @Output() updateOverlays: EventEmitter<any> = new EventEmitter<any>();

    public config: IPlayerConfig;
    private overlays: IPublicOverlay[]; // TODO CHANGE THIS TO IOVERLAY
    private courses: IPublicOverlay[];
    private overlaysSettings: IPlayerOverlay[] = [];
    private stopped = false;
    private previousTime: number;
    private overlayClickCanceler$: Subject<IPublicOverlay> = new Subject<IPublicOverlay>();

    private isFullscreen = false;

    private player: any;
    private playerController: BehaviorSubject<{play: boolean}>;
    private options: IPlayerOptions;
    private livestreamTimeInterval: Observable<number>;
    private currentLivestreamTime: number;
    private paused = true;
    private started = false;
    public startedMain = false;
    public playButton = true;
    private eventsSubscribed: boolean;
    private retryCounter: number;

    private timeGenerator$: BehaviorSubject<ITime> = new BehaviorSubject<ITime>(null);
    private marketingService: MarketingService;

    constructor(
        @Inject(AutoDestroyService) private destroy$: AutoDestroyService,
        // @Inject(SchedulerService) private schedulerService: SchedulerService,
        @Inject(LocalizationProvider) private localize: LocalizationProvider,
        @Inject(ENVIRONMENT) private environment: Environment,
        private streamService: StreamService,
    ) {
        this.marketingService = MarketingService.getInstance();
    }

    ngOnInit(): void {
        // if (this.config.mediaSource.type === MEDIA_FORMAT.STREAM) {
        this.player.controlBar.liveDisplay.hide();
        const timeControl = this.player.controlBar.addChild('button', {});
        timeControl.addClass('livestream-time');
        // }
        this.listenOverlaysActions();
        this.listenOverlaysUpdated();
    }


    private listenOverlaysUpdated(): void {
        this.streamService.overlaysUpdated$.subscribe((data) => {
            this.overlays = this.resolveOverlaysTime(this.config.overlays) || [];
            this.courses = this.resolveOverlaysTime(this.config.courses, StreamMarketingToolsOverlaysTypes.COURSES) || [];
        });
    }

    ngAfterViewInit(): void {
        this.refreshContainerSize();
        fromEvent(window, 'resize')
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                setTimeout(() => this.refreshContainerSize(), 200);
            });
    }

    ngOnDestroy() {
        this.destroyPlayer();
    }

    initTimeGenerator() {
        if (this.config.mediaType === MEDIA_TYPE.STREAM && !this.config.isRecord) {
            if (this.livestreamTimeInterval) {
                return;
            }
            this.livestreamTimeInterval = interval(1000);
            this.livestreamTimeInterval.pipe(takeUntil(this.destroy$)).subscribe(() => {
                this.currentLivestreamTime++;
                const time = {type: ITimeType.CLOCK_TIME, time: Math.floor(new Date().getTime() / 1000)};
                this.timeGenerator$.next(time);
            });
        } else {
            this.player.on('timeupdate', () => {
                const time = {type: ITimeType.MEDIA_TIME, time: this.player.currentTime()};
                this.timeGenerator$.next(time);
            });
        }
    }

    destroyPlayer() {
        if (!this.player) {
            return;
        }
        this.player.dispose();
    }

    private listenEvents(): void {
        if (this.eventsSubscribed) {
            return;
        }
        this.listenPlayerSignals();
        this.listenClick();
        this.listenPlayerErrors();
        this.listenQualityChange();
        this.listenTimeGenerator();
        this.listenTimeUpdate();

        if (!this.player) {
            return;
        }
        this.eventsSubscribed = true;
    }

    private listenTimeUpdate() {
        this.timeUpdate.pipe(takeUntil(this.destroy$)).subscribe((time: ITime) => {
            this.checkOverlays(time);
            this.updateVideoTimer(time);
        });
    }

    private listenOverlaysActions(): void {
        this.marketingService.connectToSocket(this.config.mediaId, this.config.mediaType)
            .pipe(
                takeUntil(this.destroy$),
                filter(res => !!res),
            )
            .subscribe((res) => {
                if (res.results.data.type !== MARKETING_ENTITY_TYPE.OVERLAY) {
                    return;
                }

                const overlay = [...this.overlays, ...this.courses].find(ovr => ovr.id === res.results.data.id);

                if (res.results.data.action === MARKETING_ACTION.UPDATE_STATUS) {
                    if (res.results.data.status === SURVEY_STATUS.INACTIVE) {
                        if (!overlay) {
                            return;
                        }
                        setTimeout(() => {
                            this.removeOverlay(overlay);
                        }, 100);
                    }
                    this.updateOverlays.emit();
                }

                if (res.results.data.action === MARKETING_ACTION.SHOW || res.results.data.action === MARKETING_ACTION.HIDE) {
                    if (!overlay) {
                        return;
                    }
                    this.adjustOverlaySchedule(overlay, res.results.data.action, res.results.data.status);
                }

            });
    }

    private adjustOverlaySchedule(overlay: IPublicOverlay, action: MARKETING_ACTION, status: SURVEY_STATUS): void {
        const timezoneOffset = new Date().getTimezoneOffset() * 60;
        const startedLiveAtDate = new Date();
        const startedLiveAtSec = (startedLiveAtDate.getHours() * 60 * 60)
            + (startedLiveAtDate.getMinutes() * 60)
            + (startedLiveAtDate.getSeconds());
        const newEventTime = startedLiveAtSec + timezoneOffset;

        if (action === MARKETING_ACTION.SHOW) {
            overlay.startTime = newEventTime;
            overlay.endTime = overlay.startTime + overlay.duration;
        }
        if (action === MARKETING_ACTION.HIDE) {
            overlay.endTime = newEventTime;
        }

        overlay.status = action;
        overlay.isManualStart = true;
    }

    private initOptions(): void {
        this.options = {
            techOrder: ['html5'],
            preload: false,
            poster: this.environment.backendApiHost + this.config.thumbnailUrl,
            autoplay: false,
            controls: this.config?.controlsDisabled,
            errorDisplay: false,
            controlBar: {
                volumePanel: {inline: true},
                fullscreenToggle: true
            },
            html5: {
                vhs: {
                    overrideNative: true,
                    smoothQualityChange: true,
                    limitRenditionByPlayerDimensions: false,
                    useDevicePixelRatio: true
                }
            },
            sources: [this.source()],
            bigPlayButton: this.config.mediaSource.url !== 'no-video',
            inactivityTimeout: 3000
        };
    }

    private source(): { src: string; type: string; } {
        return {
            src: this.config.mediaSource.url,
            type: this.config.mediaSource.type
        };
    }

    private initPlayer(): void {
        if (this.player) {
            this.setupPlayer(true);

            this.initTimeGenerator();
            return;
        }
        this.player = videojs(this.target.nativeElement, this.options);
        this.setupPlayer();

        this.listenEvents();
        this.initTimeGenerator();
    }

    private setupPlayer(reinitPlayer = false) {
        if (reinitPlayer) {
            this.player.src(this.source());
            this.player.controls(this.config?.controlsDisabled);
            this.player.load();
        }

        if (this.config.vr && this.config.mediaSource.subtype === MEDIA_SUBTYPE.MAIN) {
            this.player.vr({projection: '360'});
        }
        if (this.config.playFrom || this.config.playFrom === 0) {
            this.player.currentTime(this.config.playFrom);
            this.player.play();
        }

        if (reinitPlayer) {
            if (this.config.autoplay) {
                this.player.play();
            }
        } else {
            if (this.config.mediaSource.subtype === MEDIA_SUBTYPE.MAIN) {
                this.addGradient();
            }
        }

    }

    private listenQualityChange() {
        const qualityLevels = this.player.qualityLevels();
        console.log(qualityLevels);
        qualityLevels.on('change', function() {
            console.log('Quality Level changed!');
            console.log('Quality level list: ', qualityLevels);
            console.log('New level:', qualityLevels[qualityLevels.selectedIndex]);
        });

    }

    private listenPlayerErrors() {
        this.player.on('error', (e) => {
            if (this.config.showFallback) {
                this.player.poster(this.environment.backendApiHost + this.config.fallbackUrl);
            } else {
                this.player.poster(this.environment.backendApiHost + this.config.thumbnailUrl);
            }
            switch (this.retryCounter) {
                case 0:
                    this.retryCounter++;
                    setTimeout(() => {
                        this.initPlayer();
                    }, 2000);
                    break;
                case 1:
                    this.retryCounter++;
                    setTimeout(() => {
                        this.initPlayer();
                    }, 6000);
                    break;
                case 2:
                    this.retryCounter++;
                    setTimeout(() => {
                        this.initPlayer();
                    }, 10000);
                    break;
            }
        });
    }

    private addGradient(): void {
        const gradient = this.player.addChild('button', {});
        gradient.addClass('player-gradient');
        gradient.on('click', () => {
            if (this.config.disabled) {
                return;
            }
            if (this.paused) {
                this.player.play();
            } else {
                this.player.pause();
            }

            this.paused = !this.paused;
        });
    }

    private handlePlayerErrors(): void {
        this.player.on('error', () => {
            if (!this.config.reloadOnError) {
                return;
            }
            this.player.reset();
            this.player.src(this.source());
            this.player.load();
            if (this.config.showFallback) {
                this.player.poster(this.environment.backendApiHost + this.config.fallbackUrl);
            } else {
                this.player.poster(this.environment.backendApiHost + this.config.thumbnailUrl);
            }
        });
    }

    // private applyUnstopable(): void {
        // const rewindPanel = document.querySelector('.vjs-control-bar');
        // if (!rewindPanel) {
        //     return;
        // }
        // if (this.config.unstoppable) {
        //     rewindPanel.classList.add('disabled-controls');
        // } else {
        //     rewindPanel.classList.remove('disabled-controls');
        // }
    // }

    private initButtons(): void {
        if (!this.buttonsConfig) {
            return;
        }

        const container = this.addComponentButtons();

        this.buttonsConfig.forEach((button: IPlayerButton) => {
            this.removeButtonIfExists(button);
            if (button.disabled) {
                return;
            }

            let playerButton;
            if (!button.standAlone) {
                playerButton = container.addChild('button', {buttonId: button.cssIconClass});
            } else  {
                playerButton = this.player.addChild('button', {buttonId: button.cssIconClass});
            }
            playerButton.addClass(button.cssIconClass);
            playerButton.on('click', (e) => {
                e.preventDefault();
                e.stopPropagation();
                button.action();
            });
            playerButton.on('touchstart', (e) => {
                e.preventDefault();
                e.stopPropagation();
                button.action();
            });
            button.stateSubject$?.pipe(takeUntil(this.destroy$))
                .subscribe((state: boolean) => {
                    this.toggleButtonState(playerButton, state);
                });

        });
    }

    private removeButtonIfExists(button: IPlayerButton): void {
        const existingButton = this.player.children().find(item => item?.el_?.classList.contains(button.cssIconClass));
        if (existingButton) {
            this.player.removeChild(existingButton);
        }
    }

    private toggleButtonState(button: any, state: boolean): void {
        if (state) {
            button.addClass('active-button');
            return;
        }

        button.removeClass('active-button');
    }

    resetOverlays() {
        ['left-overlay', 'right-overlay', 'bottom-overlay'].forEach((clazz) => {
            const overlay = this.player.children().find(item => item?.el_?.classList.contains(clazz));
            if (overlay) {
                this.player.removeChild(overlay);
            }
        });

        const leftOverlay = this.player.addChild('button', {});
        leftOverlay.addClass('left-overlay');
        const rightOverlay = this.player.addChild('button', {});
        rightOverlay.addClass('right-overlay');
        const bottomOverlay = this.player.addChild('button', {});
        bottomOverlay.addClass('bottom-overlay');

        this.overlaysSettings = [
            {
                type: OVERLAY_TYPE.LEFT,
                width: 140,
                class: 'left-overlay',
                button: leftOverlay
            },
            {
                type: OVERLAY_TYPE.RIGHT,
                width: 140,
                class: 'right-overlay',
                button: rightOverlay
            },
            {
                type: OVERLAY_TYPE.BOTTOM,
                width: 480,
                class: 'bottom-overlay',
                button: bottomOverlay
            }
        ];
    }

    private listenPlayerSignals(): void {
        if (!this.player) {
            return;
        }
        // this.player.off('ended');
        // this.player.off('play');
        // this.player.off('fullscreenchange');
        this.player.on('canplay', () => {
            this.playButton = true;
        });
        this.player.on('ended', () => {
            this.videoEnded.emit();
            this.playerSignal.emit({stop: true});
            if (this.config.mediaSource.subtype === MEDIA_SUBTYPE.TEASER || this.config.mediaSource.subtype === MEDIA_SUBTYPE.TRAILER) {
                this.playButton = false;
            }
        });

        this.player.on('play', () => {
            this.paused = false;
            const time = this.currentLivestreamTime || this.config.playFrom || this.player.currentTime();
            this.config.playFrom = null;
            this.startedMain = this.config?.mediaSource?.subtype === MEDIA_SUBTYPE.MAIN;
            this.playerSignal.emit({start: Math.floor(time) * 1000});

        });
        this.player.on('pause', () => {
            this.paused = true;
            if (this.player.currentTime() >= this.player.duration()) {
                return;
            }
            this.playerSignal.emit({pause: true});
        });
        this.player.on('fullscreenchange', () => {
            this.isFullscreen = !this.isFullscreen;
            this.fullscreenToggle.emit(this.isFullscreen);
        });
    }

    private listenClick(): void {
        if (!this.player) {
            return;
        }
        // this.player.off('click');
        this.player.on('click', (e: any) => {
            if (!['VIDEO'].includes(e.target.tagName)) {
                return;
            }
            this.clickCallback();
        });
        this.player.on('touchstart', (e: any) => {
            if (!['VIDEO', 'BUTTON'].includes(e.target.tagName)) {
                return;
            }
            this.clickCallback(true);
        });
    }

    private clickCallback(touched?: boolean): void {
        if (this.config.unstoppable && !this.stopped) {
            this.player.play();
            this.openLink();
            this.videoClicked.emit();
            return;
        }

        this.stopped = !this.stopped;
        if (!touched) {
            return;
        }
        if (this.stopped) {
            this.player.pause();
        } else {
            this.player.play();
        }
    }

    private openLink(): void {
        if (this.config.mediaSource.clickUrl) {
            window.open(this.config.mediaSource.clickUrl, '_blank');
        }
    }

    private listenTimeGenerator(): void {
        this.timeGenerator$.pipe(takeUntil(this.destroy$)).subscribe((time) => {
            this.timeUpdate.emit(time);
        });
    }

    checkOverlays(time: ITime) {
        switch (time.type) {
            case ITimeType.MEDIA_TIME:
                this.processMediaTimeOverlay(time.time);
                break;
            case ITimeType.CLOCK_TIME:
                this.processClockTimeOverlay(time.time);
                break;
        }
    }

    processClockTimeOverlay(currentTime: number) {
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);
        const startDayTime = Math.floor(currentDate.getTime() / 1000);
        const timezoneOffset = currentDate.getTimezoneOffset() * 60;

        [...this.overlays, ...this.courses].forEach(over => {
            if (!over.active || over.controls?.isManual) {
                return;
            }

            if ((currentTime + timezoneOffset) >= (startDayTime + over.startTime) && (currentTime + timezoneOffset) <= (startDayTime + over.startTime + 1)) {
                over.controls.isClosed = false;
            }

            if (over.controls.isClosed) {
                return;
            }

            if ((currentTime + timezoneOffset) >= (startDayTime + over.startTime) && (currentTime + timezoneOffset) <= (startDayTime + over.endTime)) {
                if (!over.controls.isShowing) {
                    this.renderOverlay(over);
                }
                return;
            }

            if (over.controls.isShowing) {
                this.removeOverlay(over);
            }
        });
    }

    processMediaTimeOverlay(seconds: number) {
        [...this.overlays, ...this.courses].forEach(over => {
            if (!over.active || over.controls?.isManual) {
                return;
            }

            if (seconds >= over.startTime && seconds <= (over.startTime + 1)) {
                over.controls.isClosed = false;
            }

            if (over.controls.isClosed) {
                return;
            }

            if (seconds >= over.startTime && seconds <= over.endTime) {
                if (!over.controls.isShowing) {
                    this.renderOverlay(over);
                }
                return;
            }

            if (over.controls.isShowing) {
                this.removeOverlay(over);
            }
        });
    }


    private updateVideoTimer(iTime: ITime): void {
        switch (iTime.type) {
            case ITimeType.CLOCK_TIME:
                document.querySelector('.livestream-time').innerHTML = this.convertToTime(this.currentLivestreamTime);
                break;
            case ITimeType.MEDIA_TIME:
                document.querySelector('.livestream-time').innerHTML = this.convertToTime(iTime.time);
                break;
        }
    }

    private convertToTime(time) {
        const seconds = Math.floor(time) % 60;
        const minutes = Math.floor(time / 60) % 60;
        const hours = Math.floor(time / 3600);
        if (hours) {
            return `${this.timeFormatter(hours)}:${this.timeFormatter(minutes)}:${this.timeFormatter(seconds)}`;
        }
        return `${this.timeFormatter(minutes)}:${this.timeFormatter(seconds)}`;
    }

    private timeFormatter(numb: number): string {
        return numb >= 10 ? '' + numb : '0' + numb;
    }

    private renderOverlay(overlay: IPublicOverlay): void {
        const el = this.getOverlayElement(overlay);
        if (el) {
            return;
        }

        const settings = this.getOverlaySettings(overlay.position);
        const container = this.getOverlayContainer(settings);

        container.appendChild(this.buildOverlayLayout(overlay));
        overlay.controls.isShowing = true;

        this.emitOverlayView(overlay);
        this.initOverlaySubscribers(overlay);
    }

    private getOverlayContainer(settings: IPlayerOverlay): HTMLElement {
        return document.querySelector('.' + settings.class);
    }

    private getOverlaySettings(position: OVERLAY_TYPE): IPlayerOverlay {
        return this.overlaysSettings.find(item => item.type === position);
    }

    private getOverlayElement(overlay: IPublicOverlay, childClass?: string): HTMLElement {
        if (!childClass) {
            return document.getElementById(`overlay-${overlay.id}`);
        }
        return document.querySelector(`#overlay-${overlay.id} .${childClass}`);
    }


    private removeOverlay(overlay: IPublicOverlay, closedByBtn = false): void {
        overlay.controls.isShowing = false;
        overlay.controls.isClosed = closedByBtn;

        const el = this.getOverlayElement(overlay);
        if (!el) {
            return;
        }
        console.log('removeOverlay', el);
        el.remove();
    }

    private buildOverlayLayout(overlay: any): HTMLElement {
        const picture = overlay?.picture ? this.environment.backendApiHost + overlay.picture.photoUrl : null;
        const el = document.createElement('div');
        el.id = `overlay-${overlay.id}`;
        el.classList.add('overlay');

        el.innerHTML = overlay.isCourses ? this.buildCourseLayout(overlay, picture) : this.buildBannerLayout(overlay, picture);
        return el;
    }

    private buildCourseLayout(overlay: IPublicOverlay, pictureUrl: string): string {
        return `
                <div style="${this.getCourseBackground(overlay, pictureUrl)}"
                class="overlay-box course-box">
                    <p class="text course-${overlay.color.toLowerCase()}">
                    ${overlay.textDescription}</p>
                    <button class="btn btn-primary btn-still">
                        ${this.localize.getByKey('course.button.stillHere')}
                    </button>
                </div>`;
    }

    private getCourseBackground(overlay: IPublicOverlay, pictureUrl: string): string {
        if (pictureUrl) {
            return `background-image: url(${pictureUrl});`;
        }

        const color = overlay.color.toLowerCase() === 'black' ? 'black' : 'white';

        return `background-color: ${color}`;
    }

    private buildBannerLayout(overlay: IPublicOverlay, pictureUrl: string): string {
        return `<span class="icon icon-close close-overlay"></span>
                <a href="${overlay?.url ? this.buildOverlayUrl(overlay.url) : 'javascript:void(0)'}" target="${overlay.url ? '_blank' : '_self'}">
                <div style="
                    background-image: url(${pictureUrl});"
                    class="overlay-box clickable">
                    </div>
                </a>`;
    }

    private buildOverlayUrl(url: string): string {
        let res = url;
        if (!url.startsWith('http://') && !url.startsWith('https://')) {
            res = 'https://' + url;
        }

        return res;
    }

    private refreshContainerSize(): void {
        const height = Math.floor(this.videoWrapper.nativeElement.clientWidth * 0.5625);
        this.videoWrapper.nativeElement.style.height = height + 'px';
    }

    private addComponentButtons() {
        this.player.removeChild('component');
        const container = this.player.addChild('component');
        container.addClass('buttons-player-box');
        this.setComponents();
        return container;
    }

    setComponents(): void {
        if (this.config.componentRef) {
            this.player.el().appendChild(this.config.componentRef);
        }

        if (this.config.componentRefs?.length) {
            this.config.componentRefs.filter((ref) => !ref?.hide).forEach((ref) => {
                this.player.el().appendChild(ref.ref);
            });
        }
    }

    private emitOverlayView(overlay: IPublicOverlay) {
        if (overlay.controls.isShowed) {
            return;
        }
        overlay.controls.isShowed = true;
        this.overlayViewed.emit(overlay);
    }

    private initOverlaySubscribers(overlay: IPublicOverlay) {
        this.overlayClickSubscribe(overlay, 'close-overlay', () => {
            this.removeOverlay(overlay, true);
        });
        this.overlayClickSubscribe(overlay, 'clickable', () => {
            this.overlayClicked.emit(overlay);
            this.onClickCources(overlay);
        });
        this.overlayClickSubscribe(overlay, 'btn-still', () => {
            this.onClickCources(overlay);
            this.overlayClicked.emit(overlay);
            this.removeOverlay(overlay, true);
        });
    }

    private onClickCources(overlay: IPublicOverlay) {
        if (overlay.isCourses) {
            this.courses = this.overlays.filter((item) => item.id !== overlay.id);
        }
    }

    private overlayClickSubscribe(overlay: IPublicOverlay, clazz: string, cb: () => void): void {
        const el = this.getOverlayElement(overlay, clazz);
        if (!el) {
            return;
        }
        fromEvent(this.getOverlayElement(overlay, clazz), 'click')
            .subscribe((event) => {
                cb();
            });
    }

    private resolveOverlaysTime(overlays: IPublicOverlay[], overlayType?: StreamMarketingToolsOverlaysTypes): IPublicOverlay[] {
        if (this.config.isRecord && this.config.startedLiveAt) {
            const timezoneOffset = new Date().getTimezoneOffset() * 60;
            const startedLiveAtDate = new Date(this.config.startedLiveAt);
            const startedLiveAtSec = (startedLiveAtDate.getHours() * 60 * 60)
                                   + (startedLiveAtDate.getMinutes() * 60)
                                   + (startedLiveAtDate.getSeconds());

            overlays = overlays?.map((overlay) => {
                overlay.startTime = overlay.startTime - startedLiveAtSec - timezoneOffset;
                overlay.endTime = overlay.startTime + overlay.duration;
                return overlay;
            });
        }

        if (overlayType === StreamMarketingToolsOverlaysTypes.COURSES) {
            overlays = overlays?.filter((overlay) => {
                return !overlay.overlayUserActions?.clickAmount;
            });
        }

        return overlays;
    }
}


