<div class="panel panel-md">
    <div class="subscription-tariffs-comparison-modal-panel-header panel-header text-center">
        <h2 class="modal-main-title mb-40">
            {{'public-group-page.title' | localize}}
            <br/><b>{{group?.name}}</b>
        </h2>
        <p class="group-purchase-info">
<!--            <ng-container *ngIf="userHasAccess">-->
<!--                {{'public-group-page.user-has-access' | localize}}-->
<!--            </ng-container>-->
            <ng-container *ngIf="!userHasAccess">
                {{'public-group-page.group-purchase-info-price' | localize}} <b>{{group?.price | currency}}.</b> {{'public-group-page.group-purchase-info' | localize}}
            </ng-container>
        </p>
        <button *ngIf="!userHasAccess && !(loadingService.loading | async)" (click)="purchaseGroup()" class="btn btn-success mb-40 mt-40">{{'public-group-page.buy-media-group-button' | localize}}</button>
    </div>
</div>
<span class="public-group-media-lists">
    <app-media-lists [mediaLists]="groupMediaListSettings" [config]="suggestedMediaListsConfig"></app-media-lists>
</span>
