import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ITableConfig, TableComponent} from 'ui-elements';
import {MediaGroupsService} from '@src/app/services/media-groups/media-groups.service';
import {IMediaGroup} from '@src/app/models/stream-groups.model';
import {DateHelper} from '@src/app/utils/date.helper';
import {MatDialog} from '@angular/material/dialog';
import {takeUntil} from 'rxjs/operators';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import {Router} from "@angular/router";
import {LocalizationProvider} from 'ui-elements';

@Component({
    selector: 'app-groups-list',
    templateUrl: './groups-list.component.html',
    styleUrls: ['./groups-list.component.sass'],
    providers: [AutoDestroyService]
})
export class GroupsListComponent implements OnInit {
    public tableConfig: ITableConfig<IMediaGroup>;
    @ViewChild('tableComponent', {static: true}) public table: TableComponent;
    @ViewChild('nameTmpl', {static: true}) public nameTmpl: TemplateRef<any>;
    @ViewChild('priceTmpl', {static: true}) public priceTmpl: TemplateRef<any>;
    @ViewChild('confirm', {static: false}) confirm: TemplateRef<any>;
    @ViewChild('actionsTmpl', {static: true}) public actionsTmpl: TemplateRef<any>;
    @ViewChild('paymentTypeTmpl', {static: true}) public paymentTypeTmpl: TemplateRef<any>;
    public searchQuery = '';
    // private confirmTextTpl = 'Möchten Sie löschen {groupName}?';
    private confirmTextTpl = this.localizationProvider.getByKey('groups.deleteText');
    public confirmText = '';

    constructor(
        private streamGroupsService: MediaGroupsService,
        public dialog: MatDialog,
        private destroy$: AutoDestroyService,
        private router: Router,
        private localizationProvider: LocalizationProvider
    ) {
    }

    ngOnInit(): void {
        this.tableConfig = {
            dataField: 'data',
            searchFn: (sortParams, pagingParams) => {
                return this.streamGroupsService.getMyGroups(this.searchQuery, pagingParams, sortParams);
            },
            columns: [
                {
                    name: 'groups.name',
                    sortField: 'NAME',
                    tmpl: this.nameTmpl,
                    class: 'left'
                },
                {
                    name: 'groups.price',
                    sortField: 'PRICE',
                    tmpl: this.priceTmpl,
                    class: 'right'
                },
                {
                    name: 'groups.actions',
                    tmpl: this.actionsTmpl,
                    class: 'center'
                }
            ]

        };
    }

    public search(event: string): void {
        this.searchQuery = event;
        this.table.refreshData({});
    }

    public formatDate(timestamp: bigint): string {
        return DateHelper.formatDateTime(timestamp);
    }

    goToGroupEdit(groupId) {
        this.router.navigate(['groups', groupId]);
    }

    goToGroupRevenue(groupId) {
        this.router.navigate(['groups', 'revenue', groupId]);
    }

    public deleteGroup(group: IMediaGroup): void {
        this.confirmText = this.confirmTextTpl.replace('{groupName}', group.name);
        const dialogRef = this.dialog.open(this.confirm);
        dialogRef.afterClosed()
            .pipe(takeUntil(this.destroy$))
            .subscribe((confirm: boolean) => {
                this.confirmText = '';
                if (confirm) {
                    this.streamGroupsService.deleteGroup(group.id)
                        .pipe(takeUntil(this.destroy$))
                        .subscribe(res => {
                            if (res.success) {
                                this.table.resetTableData();
                                this.table.refreshData({});
                            }
                        });
                }
            });
    }

    public newGroup(): void {
        this.router.navigate(['groups/new']);
    }

    public getPaymentType(paymentType: string): string {
        let res = '';
        const found = this.streamGroupsService.getPaymentTypes().find(item => item.key === paymentType);
        if (found) {
            res = (found.value as string);
        }

        return res;
    }
}
