import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@src/app/modules/shared/shared.module';
import {LandingPageComponent} from '@src/app/pages/landing-page/landing-page.component';
import { LandingDataComponent } from './landing-data/landing-data.component';
import { ActualLivestreamsComponent } from './actual-livestreams/actual-livestreams.component';
import {VideoComponent} from '@src/app/pages/landing-page/video/video.component';
import { MyVideoComponent } from './my-video/my-video.component';


@NgModule({
    declarations: [
        LandingPageComponent,
        LandingDataComponent,
        ActualLivestreamsComponent,
        VideoComponent,
        MyVideoComponent
    ],
    imports: [
        CommonModule,
        SharedModule
    ]
})
export class LandingPageModule {
}
