import {Injectable} from '@angular/core';
import {IListQuery} from "@src/app/components/media-list/media-list.component";
import {Observable} from "rxjs";
import {IPagedResponse} from "@src/app/models/response.model";
import {IMediaItem} from "@src/app/models/core.model";
import {API_URLS_APP, UrlGenerator} from "@src/app/constants/api-urls.constant";
import {ApiService} from "ui-elements";

@Injectable({
    providedIn: 'root'
})
export class PublicStreamPageService {

    constructor(
        private apiService: ApiService
    ) {
    }

    public getSuggestedVideos(productType, productId: string, videoType: string, filter?: IListQuery): Observable<IPagedResponse<IMediaItem>> {
        return this.apiService.post(UrlGenerator.generate(API_URLS_APP.SUGGESTED_VIDEOS, {
            productId,
            productType,
            videoType
        }), filter, {
            openApi: true,
            isWithoutRootUrl: true
        });
    }
}

export enum SUGGESTED_VIDEO_TYPE {
    FUTURE = 'futureVideos',
    ACTUAL = 'actualVideos',
    PAST = 'pastVideos'
}
