import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PublicMediaPageComponent} from "@src/app/pages/public-media-page/public-media-page.component";
import {SharedModule} from "@src/app/modules/shared/shared.module";
import {PublisherInfoComponent} from './components/publisher-info/publisher-info.component';
import {StreamWorldMapState} from '@src/app/state/stream-world-map';

@NgModule({
    declarations: [PublicMediaPageComponent],
    imports: [
        CommonModule,
        SharedModule
    ],
    exports: [
        PublicMediaPageComponent
    ],
    providers: [
        StreamWorldMapState
    ],

    entryComponents: [PublicMediaPageComponent]
})
export class PublicMediaPageModule {
}
