<div class="modal-backdrop fade in"></div>
<div class="modal show">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <app-group [isModal]="true" #groupComponent (groupCreated$)="save($event)"></app-group>
                <div class="modal-footer">
                    <button (click)="cancel()" type="button" class="btn btn-secondary" data-dismiss="modal">{{ dismissButtonText | localize}}</button>
                    <button (click)="submit()" type="button" class="btn btn-success">{{ submitButtonText | localize }}</button>
                </div>
            </div>
        </div>
    </div>
</div>