import {
    Component, ComponentFactory,
    ComponentFactoryResolver,
    ComponentRef,
    OnInit,
    Type,
    ViewChild,
    ViewContainerRef
} from '@angular/core';

@Component({
    selector: 'app-modal-container',
    templateUrl: './modal-container.component.html',
    styleUrls: ['./modal-container.component.sass']
})
export class ModalContainerComponent {
    @ViewChild('modalContainer', {read: ViewContainerRef}) private modalContainer: ViewContainerRef;

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver
    ) {
    }

    createModal<T>(component: Type<T>): ComponentRef<T> {
        this.modalContainer.clear();

        const factory: ComponentFactory<T> = this.componentFactoryResolver.resolveComponentFactory(component);

        return this.modalContainer.createComponent(factory, 0);
    }
}
