import {EventEmitter, Inject, Injectable} from '@angular/core';
import {ApiService} from 'ui-elements';
import {API_URLS_APP} from '@src/app/constants/api-urls.constant';
import {map, pluck, take, takeUntil, tap} from 'rxjs/operators';
import {BehaviorSubject, forkJoin, Observable, of} from 'rxjs';
import {IResponse} from '@src/app/models/response.model';
import {AuthService} from '@src/app/services/auth/auth.service';
import {
    ProfileService, IContactInfo,
    IUserProfile
} from '@src/app/pages/user-profile/components/profile/services/profile.service';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import {USER_TYPE} from '@src/app/constants/user-type.constant';
import {IPicture, IRegistration} from '@src/app/models/core.model';
import {environment} from '@src/environments/environment';
import {SubAccountsService} from '@src/app/services/sub-accounts/sub-accounts.service';
import {currentCurrency} from '@src/app/providers/currentCurrency';

export interface ISettingItem {
    settingState: boolean;
    settingName: string;
}


@Injectable({
    providedIn: 'root'
})
export class UserService {

    private storageItem = {};
    private isAccountHiddenSubject = new BehaviorSubject(false);
    public isAccountHidden: Observable<boolean>;
    public isUserSelectTheme: boolean;
    public photoUrl: string;
    public homeDates: { [index: number]: string } = {};
    public userProfile: IUserProfile;
    public userProfile$: BehaviorSubject<IUserProfile> = new BehaviorSubject<IUserProfile>(null);
    public publisherHeaderPicture: BehaviorSubject<IPicture> = new BehaviorSubject<IPicture>(null);
    public headerPictureLoaded = false;
    public mediaOwnerPicture = false;
    public userContactInfo$: BehaviorSubject<IContactInfo> = new BehaviorSubject<IContactInfo>(null);
    public userName: string;
    public userType$: BehaviorSubject<USER_TYPE> = new BehaviorSubject<USER_TYPE>(null);
    public reloadLand = new EventEmitter();
    public environment = environment;

    public get publisherHeaderPicture$(): Observable<IPicture> {
        return this.publisherHeaderPicture.asObservable();
    }

    public get publisherHeaderPictureURL$(): Observable<string> {
        return this.publisherHeaderPicture.asObservable()
            .pipe(map((picture) => {
                if (!this.headerPictureLoaded) {
                    return null;
                }
                if (!picture) {
                    return 'assets/img/background/nasa-bg-header-current-streams.png';
                }
                return environment.backendApiHost + picture.photoUrl;
            }));
    }


    constructor(private apiService: ApiService,
                private authService: AuthService,
                private destroy$: AutoDestroyService,
                private editUserDataService: ProfileService,
                private subAccountsService: SubAccountsService) {
        this.homeDates[0] = 'DD-MMM-YYYY';
        this.homeDates[1] = 'MMM. DD, YYYY';
        this.homeDates[2] = 'YYYY-MMM-DD';
        this.authService.logoutEmitter.pipe(takeUntil(this.destroy$))
            .subscribe((value) => {
                if (value) {
                    this.clearUserProfile();
                }
            });
        // this.getUserProfile();
        // this.authService.isAuthorized$.pipe(takeUntil(this.destroy$)).subscribe(authorized => {
        //     if (!authorized) {
        //         this.headerPictureLoaded = true;
        //         return;
        //     }
        //     this.refreshUserProfile();
        // });

    }

    public getStorageItem(key): any {
        return this.storageItem[key];
    }

    public setStorageItem(key, value): void {
        return this.storageItem[key] = value;
    }

    public clearStorageItem(): void {
        this.storageItem = {};
    }

    public get localLanguage$(): Observable<string> {
        return of(ApiService.localLanguage);
    }

    public getNotificationSettings(): Observable<ISettingItem[]> {
        return this.apiService.get(API_URLS_APP.NOTIFICATION_SETTINGS).pipe(pluck('results', 'setting'));
    }

    public setNotificationSettings<T extends ISettingItem>({settingState, settingName}: T): Observable<ISettingItem[]> {
        return this.apiService.post(API_URLS_APP.NOTIFICATION_SETTINGS, {
            settingState,
            settingName
        });
    }

    public getAccountStatus(): Observable<boolean> {
        return this.apiService.get(API_URLS_APP.ACCOUNT_HIDDEN_STATUS).pipe(
            pluck('results', 'hidden')
        );
    }

    public hideAccount(): Observable<IResponse> {
        return this.apiService.post(API_URLS_APP.ACCOUNT_HIDE, {})
            .pipe(
                tap(({success}) => {
                    if (success) {
                        this.isAccountHiddenSubject.next(true);
                    }
                }),
            );
    }

    public showAccount(): Observable<IResponse> {
        return this.apiService.post(API_URLS_APP.ACCOUNT_UNHIDE, {})
            .pipe(
                tap(({success}) => {
                    if (success) {
                        this.isAccountHiddenSubject.next(false);
                    }
                }),
            );
    }

    public upgradeToPublisher(body: IRegistration): Observable<IResponse> {
        return this.apiService.post(API_URLS_APP.UPGRADE_TO_PUBLISHER, body);
    }

    changeEmail(body) {
        return this.apiService.post(API_URLS_APP.CHANGE_EMAIL, body);
    }

    public setTheme(body) {
        return this.apiService.post(API_URLS_APP.SET_THEME, body);
    }

    public refreshUserProfile(): void {
        this.getUserDataAsObservable().subscribe();
        this.refreshHeaderPicture();
    }

    public refreshHeaderPicture(): void {
        if (this.mediaOwnerPicture) {
            return;
        }
        this.editUserDataService.getPublisherHeader()
            .pipe(take(1))
            .subscribe((res) => {
                this.headerPictureLoaded = true;
                this.publisherHeaderPicture.next(res);
            }, () => {
                this.headerPictureLoaded = true;
                this.publisherHeaderPicture.next(null);
            });
    }

    public clearUserProfile(): void {
        this.userType$.next(null);
        this.userContactInfo$.next(null);
        this.userProfile$.next(null);
        currentCurrency.next(null);
        this.clearPublisherHeader();
    }

    public clearPublisherHeader(): void {
        this.publisherHeaderPicture.next(null);
        this.mediaOwnerPicture = false;
    }

    public setPublisherHeaderPicture(picture: IPicture, mediaOwnerPicture: boolean = false): void {
        this.headerPictureLoaded = true;
        this.publisherHeaderPicture.next(picture);
        this.mediaOwnerPicture = mediaOwnerPicture;
    }

    public getUserDataAsObservable() {
        return forkJoin({
            userProfile: this.editUserDataService.getUserProfile(),
            contactInfo: this.editUserDataService.getContacts(),
            hasConnectedPublishers: this.subAccountsService.hasConnectedPublishers()
            // headerPicture: this.editUserDataService.getPublisherHeader()
        }).pipe(
            tap((res) => {
                this.userProfile = res.userProfile;
                this.userProfile.hasConnectedPublishers = res.hasConnectedPublishers;
                this.userType$.next(res.userProfile.accountType);
                this.userContactInfo$.next(res.contactInfo);
                this.userProfile$.next(this.userProfile);
                currentCurrency.next(this.userProfile.currency);
            }),
            takeUntil(this.destroy$));
    }

    // public getUserPhoto(): string {
    //     return (this.userProfile && this.userProfile.profilePhoto && this.environment.backendApiHost + this.userProfile.profilePhoto.photoUrl) || '';
    // }
    //
    // public getFirstName(): string {
    //     return (this.userProfile && this.userProfile.firstName) || '';
    // }
    //
    // public getLastName(): string {
    //     return (this.userProfile && this.userProfile.lastName) || '';
    // }
    //
    // public getEmail(): string {
    //     return (this.userProfile && this.userProfile.email) || '';
    // }
    //
    // public isPremiumUser() {
    //     return this.userProfile.subscribed;
    // }
}


