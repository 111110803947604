<div class="panel panel-md">
    <div class="panel-header">
        <h2 class="title-lg"><b>{{'streams.title' | localize}}</b></h2>
        <p class="page-description min-width-100">{{'streams.text.explanation' | localize}}</p>
    </div>

    <div class="publisher-dashboard-media-lists box-streams">
        <ng-container *ngFor="let publisherMediaConfig of publisherMediaConfigs; let i = index">
            <div class="small-media-list">
                <app-media-list
                    #mediaLists
                    (removeMediaItem$)="deleteStream($event, i)"
                    [customClick]="true"
                    (customClick$)="goToMediaDetails($event)"
                    [implicitFilters]="publisherMediaConfig.implicitFilters"
                    [config]="{
                                fetchMethod: publisherMediaConfig.fetchMethod,
                                borderTop: i !== 0,
                                addMediaButton: {
                                    show: publisherMediaConfig?.showAddStreamButton,
                                    text: 'media-list.create-new-stream',
                                    navigateLink: (userService.userProfile$ | async).dataLimits.storageSpace.limitReached || (userService.userProfile$ | async).dataLimits.bandwidth.limitReached ? 'user/space-storage-and-bandwidth' :'stream/new'
                                },
                                loadMore: LOAD_TYPE.BUTTON,
                                cardConfig: {
                                    publisherView: true,
                                    streamHeaderCssClass: 'video-on-demand-card-header',
                                    actions: {
                                        remove: true,
                                        removeConfirmTitle: 'Livestream löschen',
                                        removeConfirmBody: 'publisher-dashboard.media-lists.remove-stream.confirm-body'
                                    },
                                    infoLineCssClass: 'left-infoline',
                                    elements: {
                                        group: true
                                    },
                                    skeleton: {
                                        headerCssClass: 'header-sm'
                                    }
                                }
                            }"
                    [title]="publisherMediaConfig?.title"
                    [data]="publisherMediaConfig?.media">
                </app-media-list>
            </div>
        </ng-container>
    </div>

    <div class="panel-body bg-white padding-top-20">
        <div class="section-panel">
            <div class="section-panel-header">
                <h4><b>{{'streams.finishedStreams.title' | localize}}</b></h4>
                <p class="text-sm">{{'streams.finishedStreams.description' | localize}}</p>
            </div>
            <div class="section-panel-body streams-table">
                <ano-table #tableComponent [tableConfig]="noRecordsMediaTableConfig" class="finished-livestreams"
                           [withSearch]="true"
                           (search$)="search($event)"
                           [defaultSortField]="'DATE'"
                           [sortOrder]="-1"
                           [showDatepicker]="true"
                           [startFromAll]="true"
                           (timeRangeFilter$)="timeRangeFilter($event)"
                ></ano-table>
                <ng-template #actionsTmpl let-data="rowData">
                    <div class="table-actions-list">
                        <b class="text-primary">
                            <i (click)="navigateToStream(data.id)"
                               class="icon icon-edit cursor-pointer"></i> |
                        </b>
                        <b class="text-primary">
                            <i (click)="confirmDeleteStream(data)"
                            class="icon icon-trash text-danger cursor-pointer"></i>
                        </b>
                    </div>
                </ng-template>
                <ng-template #statusTmpl let-data="rowData">
                                            <span [ngStyle]="{color: data?.status | itemSetting: {
                                                itemType: ITEM_TYPE.STREAM,
                                                settingType: SETTING_TYPE.COLOR,
                                                skipRecord: false,
                                                ignoreNoneRecord: false
                                            } }">
                                                {{(data?.status | itemSetting: {
                                                itemType: ITEM_TYPE.STREAM,
                                                settingType: SETTING_TYPE.TRANSLATE,
                                                skipRecord: false,
                                                ignoreNoneRecord: false
                                            }) | localize}}
                                            </span>
                </ng-template>
                <ng-template #titleTmpl let-data="rowData">
                    <div class="long-text">
                                                <span>
                                                    {{data.title}}
                                                </span>
                    </div>
                </ng-template>
                <ng-template #priceTmpl let-data="rowData">
                    {{data.price?.amount ? (data.price | currency) : 'stream.payment-types.free' | localize}}
                </ng-template>
                <ng-template #dateTmpl let-data="rowData">
                    {{(data.startedAt || data.startTimestamp) | dateTimeFormat: 'DD MMM YYYY, HH:mm'}}
                </ng-template>
            </div>
        </div>
    </div>
</div>
<ng-template #confirm let-dialogRef="dialogRef">
    <ano-modal [dialogRef]="dialogRef" [title]="'Livestream löschen'" [isConfirmation]="true">
        {{confirmText}}
    </ano-modal>
</ng-template>
