import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainDashboardPageComponent } from './main-dashboard-page.component';
import {SharedModule} from '@src/app/modules/shared/shared.module';



@NgModule({
  declarations: [MainDashboardPageComponent],
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class MainDashboardPageModule { }
