import {AfterViewInit, Component, ComponentRef, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {IFormConfig, StreamService} from '@src/app/services/stream-metadata/stream.service';
import {filter, map, take, takeUntil, tap} from 'rxjs/operators';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {NEW_ID_PARAM} from '@src/app/utils/custom-validators.util';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import {LoadingService} from '@src/app/services/loading/loading.service';
import {IStreamModel, IVideoStatus} from '@src/app/models/stream.model';
import {IStreamBroadcast} from '@src/app/models/response.model';
import {PLAYER_MODE} from '@src/app/components/streamdust-player/constants/playerMode';
import {RECORD_STATUS, STREAM_STATUS} from '@src/app/components/streamdust-player/constants/status';
import {MatDialog} from '@angular/material/dialog';
import {DateHelper, LocalizationProvider} from 'ui-elements';
import {OverviewPageService} from '@src/app/services/overview-page/overview-page.service';
import {MEDIA_TYPE} from '@src/app/components/streamdust-player/constants/mediaType';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {IInfoPanel, InfoPanelsConfig} from '@src/app/components/info-panel/info-panel.component';
import {DonationState} from '@src/app/state/donation-state';
import {StreamWorldMapState} from '@src/app/state/stream-world-map';
import {UserService} from '@src/app/services/user/user.service';
import {ChatState} from '@src/app/state/chat-state';
import {Location} from '@angular/common';
import {ModalService} from '@src/app/services/modal/modal.service';
import {PaymentModalComponent} from '@src/app/components/payment-modal/payment-modal.component';
import {PaymentService} from '@src/app/services/payment/payment.service';
import {IMultiBitratePaymentRequest, MultiBitrateService} from '@src/app/services/multi-bitrate/multi-bitrate.service';
import {PAYMENT_STATUS} from '@src/app/services/subscription-manage/subscription-manage.service';
import {IHeidelpayPaymentDetails} from '@src/app/components/payment-cards-list/heidelpay-payment/heidelpay-payment.component';
import {IPaymentCard} from '@src/app/components/payment-cards-list/payment-cards-list.component';
import {clearQueryParams} from '@src/app/utils/query-params.util';
import {VIDEO_VISIBLE_SETTINGS} from '@src/app/models/video-on-demand.model';

@Component({
    selector: 'app-media-overview',
    templateUrl: './media-overview.component.html',
    styleUrls: ['./media-overview.component.sass'],
    providers: [AutoDestroyService, MultiBitrateService]

})
export class MediaOverviewComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('confirm', {static: false}) confirm: TemplateRef<any>;
    public MEDIA_TYPE = MEDIA_TYPE;
    public mediaType: MEDIA_TYPE;
    public RECORD_STATUS = RECORD_STATUS;
    public stream: IStreamModel;
    public mediaId: string;
    public preview = '';
    public streamBroadcastSettings: IStreamBroadcast;
    public PLAYER_MODE = PLAYER_MODE;
    public streamStatus: IVideoStatus;
    public STREAM_STATUS = STREAM_STATUS;
    public streamStartAllowed = false;
    public infoPanel: IInfoPanel;
    public playPausePending = false;
    public localizationPrefix: string;

    public actualStartAt: number;
    public scheduledStartAt: number;
    public actualEndAt: number;
    public scheduledEndAt: number;
    loaderButton = false;
    public premiumPublisher: boolean;
    public paymentError: boolean;
    public paymentLoading = false;

    public streamViewers$: Observable<number>;

    constructor(
        private fb: FormBuilder,
        public streamService: StreamService,
        private readonly destroy$: AutoDestroyService,
        private readonly activatedRoute: ActivatedRoute,
        public loadingService: LoadingService,
        private router: Router,
        public dialog: MatDialog,
        private localizationProvider: LocalizationProvider,
        private overviewPageService: OverviewPageService,
        public donationState: DonationState,
        public worldMapState: StreamWorldMapState,
        public userService: UserService,
        private chatState: ChatState,
        private location: Location,
        private modalService: ModalService,
        private paymentService: PaymentService,
        private multiBitrateService: MultiBitrateService
    ) {
    }

    ngOnInit(): void {
        this.activatedRoute.queryParams.pipe(takeUntil(this.destroy$)).subscribe(params => {
            if (params.hasOwnProperty('multiBitratePaymentReturn')) {
                this.loadingService.loadingStart();
                if (params.hasOwnProperty('transactionId')) {
                    this.paymentLoading = true;
                    this.checkPaymentResult(params['transactionId']);
                }
                clearQueryParams(this.location);
            }
        });
        this.init();

        this.listenStreamViewers();
        // this.router.events
        //     .pipe(takeUntil(this.destroy$))
        //     .subscribe((e: any) => {
        //         if (e instanceof NavigationEnd && e.url.includes('info')) {
        //             this.init();
        //         }
        //     });
    }

    private listenStreamViewers(): void {
        this.streamViewers$ = this.streamService.streamViewers$.pipe(takeUntil(this.destroy$), map(viewers => {
            if (!viewers?.length) {
                return 0;
            }
            return viewers.reduce((prev, current) => prev + current.viewers, 0);
        }));
    }

    private checkPaymentResult(transactionId: string): void {
        this.multiBitrateService.checkPayment(
            {transactionId: transactionId}).pipe(take(1)).subscribe(results => {
            switch (results?.results?.result?.paymentStatus) {
                case PAYMENT_STATUS.FAILED: {
                    this.paymentError = true;
                    this.paymentLoading = false;
                    break;
                }
                case PAYMENT_STATUS.PENDING: {
                    setTimeout(() => {
                        return this.checkPaymentResult(transactionId);
                    }, 3000);
                    break;
                }
                case PAYMENT_STATUS.SUCCESS: {
                    setTimeout(() => {
                        this.streamService.streamStatus$.pipe(takeUntil(this.destroy$))
                            .subscribe((res) => {
                                if (res.stream !== STREAM_STATUS.PENDING) {
                                    this.paymentLoading = false;
                                }
                            });
                    }, 1000);
                    break;
                }
            }
        }, () => this.paymentLoading = false);
    }

    init() {
        this.loadingService.loadingStart();
        this.activatedRoute.parent.params.pipe(
            takeUntil(this.destroy$),
            tap(({id}: Params) => {
                this.mediaId = id;
                this.mediaType = this.resolveMediaType();
                this.localizationPrefix = this.resolveLocalizationPrefix() + '.';
                this.streamService.stream$
                .pipe(takeUntil(this.destroy$))
                .subscribe((res) => {
                    this.loadingService.loadingEnd();
                    if (!res) {
                        return;
                    }
                    this.stream = res;
                    this.userService.userProfile$.subscribe(_res => {
                        if (!_res) {
                            return;
                        }
                        this.premiumPublisher = _res?.subscribed;
                        this.resolveTimeline();
                        this.checkStreamStartAllowed();
                        this.refreshInfoPanel();
                    });
                }, () => this.loadingService.loadingEnd());
            }),
            filter(({id}: Params) => id !== NEW_ID_PARAM))
            .subscribe(() => {
                // this.streamService.connectStreamStatusWebsocket(this.mediaId);
                this.streamService.streamStatus$
                    .pipe(takeUntil(this.destroy$))
                    .subscribe((status) => {
                        if (!status) {
                            return;
                        }
                        this.streamStatus = status;
                        this.refreshInfoPanel();
                        this.playPausePending = false;
                        this.refreshTimeLine();
                    });

                // if (this.mediaType === MEDIA_TYPE.STREAM) {
                //     this.streamService.getStreamBroadcast(this.mediaId)
                //         .pipe(takeUntil(this.destroy$))
                //         .subscribe((_res) => this.streamBroadcastSettings = _res);
                // }
            });
    }

    resolveTimeline() {
        const media = this.stream?.media;
        this.actualStartAt = media?.startedAt;
        this.scheduledStartAt = media?.startTimestamp;
        this.actualEndAt = media?.stoppedAt;
        this.scheduledEndAt = media?.endTimestamp;
    }

    ngAfterViewInit(): void {
    }

    ngOnDestroy(): void {
        if (this.mediaType === MEDIA_TYPE.STREAM) {
            this.streamService.closeStreamStatusWebsocket();
        }
    }

    private resolveMediaType(): MEDIA_TYPE {
        return (this.activatedRoute.data as BehaviorSubject<any>)?.value?.mediaType;
    }

    private resolveLocalizationPrefix(): string {
        switch (this.mediaType) {
            case MEDIA_TYPE.STREAM:
                return 'stream';
            case MEDIA_TYPE.VIDEO_ON_DEMAND:
                return 'vod';
        }
    }

    private checkStreamStartAllowed(): void {
        if (this.stream?.media?.status?.stream !== STREAM_STATUS.SCHEDULED) {
            this.streamStartAllowed = true;
            return;
        }
        this.isStartTime();
        const timerId = setInterval(() => {
            this.isStartTime();
            this.refreshInfoPanel();

        }, 30000);
        if (this.streamStartAllowed) {
            clearInterval(timerId);
        }
    }

    private isStartTime(): void {
        if (new Date().getTime() > this.stream.media.startTimestamp - 30 * 60 * 1000) {
            this.streamStartAllowed = true;
        }
    }

    private refreshInfoPanel(): void {
        if (this.streamStatus?.stream === STREAM_STATUS.SCHEDULED || this.streamStatus?.stream === STREAM_STATUS.ACTIVATION) {
            this.infoPanel = InfoPanelsConfig.streamScheduledInfo;
            return;
        }
        if (this.streamStatus?.stream === STREAM_STATUS.RESCHEDULING) {
            this.infoPanel = InfoPanelsConfig.streamReschedulingInfo;
            return;
        }

        if (this.streamStatus?.stream === STREAM_STATUS.ACTIVATED) {
            this.infoPanel = {textKey: 'player.suggestion.activated', info: true};
            return;
        }
        if (this.streamStatus?.stream === STREAM_STATUS.CONNECTED) {
            this.infoPanel = {textKey: 'player.suggestion.connected', info: true};
            return;
        }
        if ([STREAM_STATUS.PAUSED, STREAM_STATUS.LIVE].includes(this.streamStatus?.stream)
            && (this.stream.media.endTimestamp - new Date().getTime()) <= 30 * 60 * 1000) {
            const endDate = DateHelper.formatDateTime(this.stream.media.endTimestamp + (25 * 60 * 1000));
            const infoText = this.localizationProvider.getByKey('player.suggestion.autoStop').replace('{{date}}', endDate);
            this.infoPanel = {textKey: infoText};
            return;
        }

        this.infoPanel = null;
    }

    private refreshTimeLine(): void {
        if (this.streamStatus.stream === STREAM_STATUS.LIVE && !this.stream.media.startedAt) {
            this.actualStartAt = new Date().getTime();
        }

        if (this.streamStatus.stream === STREAM_STATUS.FINISHED && !this.stream.media.stoppedAt) {
            this.actualEndAt = new Date().getTime();
        }
    }


    public startStream(): void {
        if (this.streamStatus.stream === STREAM_STATUS.SCHEDULED) {
            // this.streamService.openModal$.emit('encoderModal');
            this.loaderButton = true;
            this.streamService.startStream(this.mediaId)
                .pipe(takeUntil(this.destroy$))
                .subscribe((res) => {
                    if (!res.success) {
                        return;
                    }
                    // this.streamService.getMedia(this.mediaType, this.mediaId).pipe(take(1)).subscribe(() => {
                    //     }
                    // );
                });
        }
        if ([STREAM_STATUS.LIVE, STREAM_STATUS.PAUSED, STREAM_STATUS.ACTIVATED, STREAM_STATUS.CONNECTED]
            .includes(this.streamStatus.stream)) {
            this.stopStream();
        }
    }

    public stopStream(): void {
        const dialogRef = this.dialog.open(this.confirm);
        dialogRef.afterClosed()
            .pipe(takeUntil(this.destroy$))
            .subscribe((confirm: boolean) => {
                if (confirm) {
                    this.loaderButton = true;
                    this.streamService.stopStream(this.mediaType, this.mediaId)
                        .pipe(takeUntil(this.destroy$))
                        .subscribe((res) => {
                            this.loaderButton = false;
                            if (!res.success) {
                                return;
                            }
                            // this.streamService.getMedia(this.mediaType, this.mediaId).pipe(take(1)).subscribe();
                        }, () => {
                            this.loaderButton = false;
                        });
                }
            });
    }

    public playStream(): void {
        this.playPausePending = true;
        this.overviewPageService.playStream(this.mediaId)
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
            });
    }

    public pauseStream(): void {
        this.playPausePending = true;
        this.overviewPageService.pauseStream(this.mediaId)
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
            });
    }

    public selectCard(): void {
        const modalRef = this.modalService
            .open<PaymentModalComponent>(PaymentModalComponent, (component: ComponentRef<PaymentModalComponent>) => {
            component.instance.returnUrl = 'streams/' + this.mediaId + '/info';
            component.instance.onlyCard = true;
            component.instance.paymentCardsListControls = {
                payButton: true,
                addNewCard: {
                    show: true,
                    text: 'payment-cards-list.use-another-card',
                    customHandler: (paymentDetails: IHeidelpayPaymentDetails) => this.multiBitratePay(this.mediaId, paymentDetails, true)
                }
            };
            component.instance.proceedPayment$.pipe(takeUntil(this.destroy$))
                .subscribe((paymentDetails) => {
                    this.multiBitratePay(this.mediaId, paymentDetails);
                    modalRef.close({});
                });
            component.instance.cancel$.pipe(takeUntil(this.destroy$))
                .subscribe(() => {
                    this.router.navigate(['/streams/' + this.mediaId + '/info']);
                });
        });
    }

    private multiBitratePay(mediaId: string, cardDetails: IHeidelpayPaymentDetails | IPaymentCard, isNewCardPayment = false): void {
        const payload: IMultiBitratePaymentRequest = {
            mediaId: mediaId,
        };
        const card = (cardDetails as IHeidelpayPaymentDetails).card;
        const url = window.location.protocol + '//' + window.location.host + '/streams/' + mediaId + '/info';

        if (isNewCardPayment) {
            payload.returnUrl = url;
            payload.successUrl = url;
            payload.failUrl = url;
            payload.email = (cardDetails as IHeidelpayPaymentDetails)?.email;
            payload.firstName = (cardDetails as IHeidelpayPaymentDetails)?.firstName;
            payload.lastName = (cardDetails as IHeidelpayPaymentDetails)?.lastName;
            payload.paymentMethod = {
                resourceId: card.id,
                method: card.method
            };
            if (card.method === 'card') {
                payload.paymentMethod.saveCard = true;
                payload.paymentMethod.paymentCard = {
                    externalCardId: card.id,
                    expiryDate: card.expiryDate,
                    type: card.brand,
                    number: card.number.slice(-4),
                    cardDetails: {
                        id: card.id,
                        brand: card.brand,
                        cvc: card.cvc,
                        expiryDate: card.expiryDate,
                        number: card.number.slice(-4),
                        method: card.method,
                        account: card.cardDetails.account,
                        cardType: card.cardDetails.cardType,
                        countryIsoA2: card.cardDetails.countryIsoA2,
                        countryName: card.cardDetails.countryName,
                        issuerName: card.cardDetails.issuerName,
                        issuerPhoneNumber: card.cardDetails.issuerPhoneNumber,
                        issuerUrl: card.cardDetails.issuerUrl,
                        '3ds': card['3ds']
                    }
                };
            }
        } else {
            payload.paymentMethod = {
                resourceId: (cardDetails as IPaymentCard).externalCardId,
                method: 'card',
                userCardId: (cardDetails as IPaymentCard).id};
            this.paymentLoading = true;
        }

        this.multiBitrateService.proceedPayment(payload)
            .pipe(takeUntil(this.destroy$))
            .subscribe((result) => {
                switch (result?.results?.result?.paymentStatus) {
                    case PAYMENT_STATUS.FAILED: {
                        this.paymentService.failedTransactionInfo.next({
                            failedCardId: result.results.result.externalCardId,
                            transactionFailReason: result.results.result.reason
                        });
                        this.paymentLoading = false;
                        break;
                    }
                    case PAYMENT_STATUS.PENDING: {
                        if (result?.results?.result?.redirectUrl) {
                            window.location.href = result.results.result.redirectUrl;
                        }
                        return;
                    }
                    case PAYMENT_STATUS.SUCCESS: {
                        setTimeout(() => {
                            this.streamService.streamStatus$.pipe(takeUntil(this.destroy$))
                                .subscribe((res) => {
                                    if (res.stream !== STREAM_STATUS.PENDING) {
                                        this.paymentLoading = false;
                                    }
                                });
                        }, 1000);
                    }
                }
            });
    }

}
