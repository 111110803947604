import {Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, ChangeDetectorRef} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IFieldsConfig } from '@src/app/pages/user-profile/interfaces/interfaces-common';
import {ACTIONS, AutoDestroyService, INPUT_TYPES} from 'ui-elements';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-publisher',
  templateUrl: './publisher.component.html',
  styleUrls: ['./publisher.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [AutoDestroyService]
})
export class PublisherComponent implements OnInit {
  @Input() public upgradeToPublisher = false;

  @Input() public form: FormGroup;
  @Input() public phoneForm: FormGroup;
  @Input() public firmForm: FormGroup;
  @Input() public currencyForm: FormGroup;
  @Input() public termsForm: FormGroup;
  @Input() public isPhoneConfirmed: boolean;
  @Input() public loading: { [key: string]: boolean } = {};

  @Input() public formConfig: IFieldsConfig[][];
  @Input() public phoneFieldsConfig: IFieldsConfig[][];
  @Input() public firmFieldsConfig: IFieldsConfig[][];
  @Input() public currencyFieldsConfig: IFieldsConfig[][];
  @Input() public termsFieldsConfig: IFieldsConfig[][];

  @Output() action = new EventEmitter<PublisherAction>();

  constructor(
      private destroy$: AutoDestroyService,
      private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    if (!this.upgradeToPublisher) {
      this.resetForms();
    }
    this.initPhoneConfig();
    this.phoneForm.get('privatePhone').valueChanges.pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          if (/^0/.test(data)) {
            this.phoneForm.get('privatePhone').patchValue(data.replace(/^0/, ''));
          }
        });
  }

  submit() {
    this.action.emit({ type: ACTIONS.SUBMIT, payload: null });
  }

  cancel() {
    this.action.emit({ type: ACTIONS.CANCEL, payload: null });
  }

  confirmedPhone(event: boolean) {
    this.isPhoneConfirmed = event;
    this.initPhoneConfig();
  }

  private resetForms(): void {
    this.resetForm(this.form);
    this.resetForm(this.phoneForm);
    this.resetForm(this.firmForm);
  }

  private resetForm(form: FormGroup): void {
    form.reset();
    form.markAsUntouched();
    form.markAsPristine();
    form.updateValueAndValidity();
  }

  private initPhoneConfig(): void {
    // if (!this.upgradeToPublisher) {
    this.phoneFieldsConfig = [
      [
        {
          name: 'privatePhone',
          config: {
            inputType: INPUT_TYPES.PHONE,
            placeholder: 'registration.phone',
            size: 6,
            buttonText: 'send',
            isConfirmCode: true,
            isRequired: true,
            disabled: this.isPhoneConfirmed,
          },
        },
        {
          name: 'smsVerification',
          config: {
            inputType: INPUT_TYPES.INPUT,
            placeholder: 'registration.sms',
            size: 6,
            isConfirmCode: true,
            mask: '000000',
            isRequired: true,
            disabled: this.isPhoneConfirmed,
          },
        },
      ],
    ];
    // }
  }
}

export type PublisherAction = PublisherCancel | PublisherSubmit;

export interface PublisherCancel {
  type: ACTIONS.CANCEL;
  payload?: null;
}

export interface PublisherSubmit {
  type: ACTIONS.SUBMIT;
  payload?: any;
}
