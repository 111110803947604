<div class="content">
    <div class="panel panel-md">
        <div class="panel-header pb-0">
            <h2 class="title-lg"><b>{{'profile.passwordChange.title'| localize}}</b></h2>
        </div>
    </div>
</div>
<ano-form class="change-password"
        [form]="form"
        [fieldsConfig]="fieldsConfig"
        [showErrorsForViewMode]="true"
        [passwordValidation]="true"
        [success$]="success$.asObservable()"
        (submit$)="changePassword()"
        [cancelButtonClass]="'btn-danger'"
        [submitButtonText]="'common.button.apply'">
</ano-form>
<div class="card bg-white mb-40 side-padding-0" *ngIf="serverErrors.length">
    <div class="row justify-content-center align-items-center">
        <div class="col-xl-6">
            <div class="card-body pt-10">
                <ng-container *ngIf="serverErrors.length">
                    <app-info-box *ngFor="let error of serverErrors" class="d-block mb-3"
                                  [type]="INFO_BOX_TYPES.FAILURE">
                        <ng-container role="message">
                            <span *ngFor="let error of serverErrors"
                                  class="text-danger text-align-center">
                                {{ error.text }}
                            </span>
                        </ng-container>
                    </app-info-box>
                </ng-container>
            </div>
        </div>
    </div>
</div>
