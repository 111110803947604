<ano-table #tableComponent [tableConfig]="tableConfig"
           [withSearch]="!hideSearch"
           (search$)="search($event)"
           [addButton]="!hideAddButton && !((streamService?.stream$ | async)?.media?.status?.stream === STREAM_STATUS.FINISHED && !(streamService?.stream$ | async)?.media?.hasRecording) && !(streamService.stream$ | async)?.media?.deactivated"
           [addButtonText]="'common.button.create'"
           (addItem$)="goToNewSurvey()"
           (navigate$)="goToNoDataUrl($event)"
></ano-table>

<ng-template #actionsTmpl let-data="rowData">
    <div class="table-actions-list">
        <b class="text-primary">
            <i (click)="navigateToSurveyInfo(data.id)"
               class="icon icon-info cursor-pointer"></i> |
        </b>
        <b class="text-primary">
            <i (click)="navigateToSurvey(data.id)"
               class="icon icon-edit cursor-pointer"></i> |
        </b>
        <b class="text-primary">
            <i (click)="download($event)"
               class="icon icon-download cursor-pointer"></i> <ng-container *ngIf="!((streamService?.stream$ | async)?.media?.status?.stream === STREAM_STATUS.FINISHED && !(streamService?.stream$ | async)?.media?.hasRecording) && !(streamService.stream$ | async)?.media?.deactivated && !((streamService.stream$ | async)?.media?.status?.stream === STREAM_STATUS.PENDING)">|</ng-container>
        </b>
        <b class="text-primary">
            <i (click)="deleteSurvey(data, $event)" *ngIf="!((streamService?.stream$ | async)?.media?.status?.stream === STREAM_STATUS.FINISHED && !(streamService?.stream$ | async)?.media?.hasRecording) && !(streamService.stream$ | async)?.media?.deactivated && !((streamService.stream$ | async)?.media?.status?.stream === STREAM_STATUS.PENDING)"
            class="icon icon-trash text-danger cursor-pointer"></i>
        </b>
    </div>
</ng-template>
<!--            <ng-template #statusTmpl let-data="rowData">-->
<!--                <span>{{ (data.status === SURVEY_STATUS.ACTIVE ? 'survey.status.active' : 'survey.status.inactive') | localize}}</span>-->
<!--            </ng-template>-->
<ng-template #durationTmpl let-data="rowData">
    <span>{{ data.duration | durationConvert : 'seconds' }}</span>
</ng-template>

<ng-template #startStopTmpl let-data="rowData">
    <div class="button-container">
        <ano-button *ngIf="data.isManual && !data.manualStarted"
                    [config]=" { styleType: 'success', sizeType: 'block'}"
                    (click)="start(data)"
                    [isDisabled]="!([STREAM_STATUS.LIVE].includes((streamService.streamStatus$ | async)?.stream))"
        >{{'common.button.start' | localize}}</ano-button>
        <ano-button *ngIf="data.isManual && data.manualStarted"
                    [config]=" { styleType: 'danger', sizeType: 'block'}"
                    (click)="stop(data)"
                    [isDisabled]="!([STREAM_STATUS.LIVE].includes((streamService.streamStatus$ | async)?.stream))"
        >{{'common.button.stop' | localize}}</ano-button>
    </div>
</ng-template>
<ng-template #publishTmpl let-data="rowData">
<!--    <div class="button-container">-->
    <div class="button-container">
        <ano-button *ngIf="!data.resultsPublished"
                    [config]=" { styleType: 'success', sizeType: 'block'}"
                    [isDisabled]="mediaType === MEDIA_TYPE.STREAM && (data.notComplete || data.inProgress || data.manualStarted || !([STREAM_STATUS.LIVE].includes((streamService.streamStatus$ | async)?.stream)))"
        (click)="publishResult(data)">{{'survey.table.publish.button.show' | localize}}</ano-button>
        <ano-button *ngIf="data.resultsPublished"
                    [config]=" { styleType: 'danger', sizeType: 'block'}"
                    [isDisabled]="mediaType === MEDIA_TYPE.STREAM && (!([STREAM_STATUS.LIVE].includes((streamService.streamStatus$ | async)?.stream)))"
                    (click)="publishResult(data)">{{'survey.table.publish.button.hide' | localize}}</ano-button>
    </div>
</ng-template>

<ng-template #startTimeTmpl let-data="rowData">
    <span>{{ data.startTime === null ? ('survey.field.manualStart' | localize) : data.startTime | durationConvert : 'seconds' }}</span>
</ng-template>
<ng-template #statusTmpl let-data="rowData" class="ss-test">
    <div class="long-text">
        <app-toggle
                [disabled]="(streamService.stream$ | async)?.media?.deactivated || ((streamService.stream$ | async)?.media?.status?.stream === STREAM_STATUS.PENDING)"
                [value]="data.active"
                [config]="formConfig"
                (toggleEmit)="onToggle($event, data)"
        ></app-toggle>
    </div>
</ng-template>
<ng-template #confirm let-dialogRef="dialogRef">
    <ano-modal [dialogRef]="dialogRef" [title]="'survey.delete.title' | localize" [isConfirmation]="true">
        {{'survey.delete.confirmText' | localize}}
    </ano-modal>
</ng-template>
