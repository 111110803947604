import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {NavService} from '@src/app/services/nav/nav.service';
import {UserService} from '@src/app/services/user/user.service';
import {IUserProfile} from '@src/app/pages/user-profile/components/profile/services/profile.service';
import {StreamService} from '@src/app/services/stream-metadata/stream.service';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import {take, takeUntil} from 'rxjs/operators';
import {environment} from '@src/environments/environment';
import {Router} from '@angular/router';
import {BUTTON_POSITION} from '@src/app/models/side-nav-config.model';
import {USER_TYPE} from '@src/app/constants/user-type.constant';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.sass'],
  providers: [AutoDestroyService]
})
export class SideNavComponent implements OnInit, OnChanges {
  public USER_TYPE = USER_TYPE;
  public BUTTON_POSITION = BUTTON_POSITION;
  @Input() public config: ISideNavConfig;
  public advancedConfig: ISideNavConfig;
  public userInfo: IUserProfile;
  @Input() public rightNav = false;
  public environment = environment;

  public userProfile: IUserProfile;
  public userPhoto = '';
  public userFirstName = '';
  public userLastName = '';
  public userEmail = '';

  constructor(
      public navService: NavService,
      public userService: UserService,
      public streamService: StreamService,
      public destroy$: AutoDestroyService,
      private router: Router
  ) {
  }

  ngOnInit() {
    this.initUserInfo();
    this.streamService.closeModal$
        .pipe(takeUntil(this.destroy$))
        .subscribe((modalName) => {
          const found = this.getConfig().items.find(item => item.modalName === modalName);
          if (!found) {
            return;
          }

          found.opened = false;
        });

    this.listenUserProfile();
  }

  ngOnChanges(): void {
    this.navService.navConfig$.subscribe(res => {
      this.advancedConfig = res;
      this.rightNav = true;
    });
  }

  private listenUserProfile() {
    this.userService.userProfile$.pipe(takeUntil(this.destroy$)).subscribe((userProfile) => {
      this.userProfile = userProfile;
      this.userPhoto = this.userProfile?.profilePhoto?.photoUrl ? environment?.backendApiHost + this.userProfile?.profilePhoto?.photoUrl : '';
      this.userFirstName = this.userProfile?.firstName || '';
      this.userLastName = this.userProfile?.lastName || '';
      this.userEmail = this.userProfile?.email || '';
    });
  }

  private getConfig(): ISideNavConfig {
    return this.advancedConfig || this.config;
  }
  public initUserInfo() {
    this.userInfo = this.userService.userProfile;
  }

  // public getFirstName(): string {
  //    return this.userService.getFirstName();
  // }
  //
  // public getLastName(): string {
  //   return this.userService.getLastName();
  // }
  //
  // public getEmail(): string {
  //   return this.userService.getEmail();
  // }
  //
  // public getUserPhoto(): string {
  //   return this.userService.getUserPhoto();
  // }

  public openModal(item: ISideNavConfigItem): void {
    item.opened = true;
    this.streamService.openModal$.emit(item.modalName);
  }

  public goToMyVideos(): void {
    this.userService.userType$.pipe(take(1))
        .subscribe(userType => {
          if (userType === USER_TYPE.VIEWER) {
            return;
          }
          this.router.navigate(['video', 'my']);
        });
  }
}

export interface ISideNavConfig {
  title?: string;
  items: ISideNavConfigItem[];
  backButtonConfig?: ISideNavBackButtonConfig;
  buttons?: ISideNavButton[];
  infoBox?: boolean;
}

export interface ISideNavButton {
  cssClass?: string;
  icon?: string;
  text?: string;
  callback?: () => void;
  show?: boolean;
  position?: BUTTON_POSITION;
}

export interface ISideNavConfigItem {
  hide?: boolean;
  text: string;
  icon?: string;
  link?: string;
  opened?: boolean;
  subItems?: ISideNavConfigItem[];
  modalName?: string;
}

export interface ISideNavBackButtonConfig {
  show: boolean;
  buttonText: string;
  navigateUrl: string;
}


