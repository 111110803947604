<div class="chat video-overlay donation survey" [ngStyle]="{'display': state ? 'block' : 'none'}" *ngIf="!answered || data.resultsPublished">
    <div class="chat-header">
        <div class="chat-header-user">
            <h3 class="title">{{'survey.modal.title' | localize}}</h3>
        </div>
        <div class="chat-header-action">
            <ul class="action-list">
                <li><button class="icon-close btn navigation-button" (click)="close()"></button></li>
            </ul>
        </div>
    </div>
    <div class="video-overlay-body">

        <h3><b>{{data.question}}</b></h3>

        <app-answers *ngIf="!answered || !data.resultsPublished" [data]="answersConfig" (answer$)="sendAnswer()"></app-answers>
        <div class="chart-loading" *ngIf="answered && !answersChartItems">
            <ano-loader [isOpen]="true"></ano-loader>
        </div>

        <app-simple-chart *ngIf="answered && data.resultsPublished && answersChartItems" [data]="answersChartItems"></app-simple-chart>

    </div>
</div>
