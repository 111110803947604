import {Component, OnInit} from '@angular/core';
import {PhotoService} from '@src/app/services/photo/photo.service';
import {PhotoCropperService} from '@src/app/services/photo-cropper/photo-cropper.service';
import {IFormFieldsConfig, StreamService} from '@src/app/services/stream-metadata/stream.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {takeUntil, tap} from 'rxjs/operators';
import {INPUT_TYPES} from 'ui-elements';
import {ILandingRequest, IOverlayRequest, StreamMetadataSelectValues} from '@src/app/models/stream.model';
import {LoadingService} from '@src/app/services/loading/loading.service';
import * as _ from 'lodash';
import {environment} from '@src/environments/environment';
import {FormService} from '@src/app/services/form/form.service';
import {MEDIA_TYPE} from '@src/app/components/streamdust-player/constants/mediaType';
import {BehaviorSubject} from 'rxjs';
import {STREAM_STATUS} from '@src/app/components/streamdust-player/constants/status';
import {CustomValidators} from '@src/app/utils/custom-validators.util';

@Component({
    selector: 'app-stream-landing',
    templateUrl: './stream-landing.component.html',
    styleUrls: ['./stream-landing.component.scss']
})
export class StreamLandingComponent implements OnInit {
    public STREAM_STATUS = STREAM_STATUS;
    public mediaType: MEDIA_TYPE;
    public fieldsConfig: IFormFieldsConfig[][];
    public wallpaperConfig: IFormFieldsConfig[][];
    public websiteConfig: IFormFieldsConfig[][];
    public websiteToggleConfig: IFormFieldsConfig[][];
    public domainConfig: IFormFieldsConfig[][];
    public form: FormGroup = this.fb.group({
        wallpaper: [''],
        websiteUrl: ['', [CustomValidators.domain, CustomValidators.noWhitespaceValidator]],
        domain: [''],
        active: [''],
    });

    public wallpaperForm: FormGroup = this.fb.group({
        wallpaper: [''],
    });
    public websiteForm: FormGroup = this.fb.group({
        websiteUrl: ['', [Validators.required, CustomValidators.domain]],
        active: [false],
    });
    public domainForm: FormGroup = this.fb.group({
        domain: [''],
    });

    public payload: ILandingRequest = {
        productId: null,
        productType: null,
        wallpaper: {
            workbenchId: '',
            previewSettings: {
                x: 0,
                y: 0,
                width: 0,
                height: 0,
                rotation: 0,
            }
        },
        websiteUrl: '',
        domain: '',
        active: false
    };
    public photoUrl = '';
    public environment = environment;

    constructor(
        private photoService: PhotoService,
        private readonly photoCropperService: PhotoCropperService,
        public streamService: StreamService,
        private readonly activatedRoute: ActivatedRoute,
        private readonly destroy$: AutoDestroyService,
        private fb: FormBuilder,
        private router: Router,
        public loadingService: LoadingService,
        public formService: FormService
    ) {
    }

    ngOnInit(): void {
        this.activatedRoute.parent.params.pipe(
            takeUntil(this.destroy$),
            tap(({id}: Params) => {
                if (!id) {
                    return;
                }
                this.mediaType = this.resolveMediaType();
                this.payload.productId = id;
                this.payload.productType = this.mediaType;
                this.wallpaperConfig = [
                    [
                        {
                            name: 'wallpaper',
                            config: {
                                inputType: INPUT_TYPES.IMAGE,
                                accept: 'image/*',
                                size: 12,
                                // label: 'Wallpaper',
                                // isRequired: true
                            }
                        },
                    ],
                ];
                this.websiteConfig = [
                    [
                        {
                            name: 'websiteUrl',
                            config: {
                                inputType: INPUT_TYPES.INPUT,
                                // label: 'webseitenname',
                                placeholder: 'stream.website.field.website',
                                isRequired: true,
                                url: true,
                                append: '.streamdust.tv',
                                formGroup: true,
                                removeSpaces: true
                            }
                        },
                    ],
                    [
                        {
                            name: 'active',
                            config: {
                                inputType: INPUT_TYPES.CHECKBOX,
                                // label: 'Website aktivieren',
                                placeholder: 'stream.website.field.websiteActive',
                                // isRequired: true,
                                simplePanelToggle: true
                            }
                        }
                    ],
                ];

                this.websiteToggleConfig = [
                    [
                        {
                            name: 'active',
                            config: {
                                inputType: INPUT_TYPES.CHECKBOX,
                                // label: 'Website aktivieren',
                                placeholder: 'stream.website.field.websiteActive',
                                simplePanelToggle: true
                            }
                        }
                    ],
                ];

                this.domainConfig = [
                    [
                        {
                            name: 'domain',
                            config: {
                                inputType: INPUT_TYPES.INPUT,
                                // label: 'Eigene Domain',
                                placeholder: 'stream.website.field.domain'
                            }
                        },
                    ],
                ];
            }))
            .subscribe(() => {
                this.loadingService.loadingStart();
                this.streamService.getLandingPage(this.mediaType, this.payload.productId)
                    .pipe(takeUntil(this.destroy$))
                    .subscribe((res) => {
                        this.loadingService.loadingEnd();
                        if (!res) {
                            return;
                        }
                        this.websiteForm.get('websiteUrl').patchValue(res.websiteUrl);
                        this.websiteForm.get('active').patchValue(res.active);
                        // this.domainForm.get('domain').patchValue(res.domain);
                        this.photoUrl = res.wallpaper.photoUrl;
                        this.wallpaperForm.get('wallpaper').clearValidators();

                    }, () => {
                        this.loadingService.loadingEnd();
                    });
            });
    }

    private resolveMediaType(): MEDIA_TYPE {
        return (this.activatedRoute.data as BehaviorSubject<any>)?.value?.mediaType;
    }

    public uploadPreviewPhoto({files}): void {
        const file = files;
        this.photoCropperService.completeWorkbenchReadySubject();

        if (!file) {
            return;
        }

        const aspectRatio = 16 / 9;
        this.photoService.generateUploader({file, aspectRatio: aspectRatio})
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                ({croppingParams, workbenchId, croppedImage}) => {
                    if (croppedImage) {
                        this.photoUrl = croppedImage;
                    } else {
                        const reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = () => {
                            this.photoUrl = reader.result.toString();
                        };
                    }
                    this.photoCropperService.completeWorkbenchReadySubject();
                    this.payload.wallpaper.workbenchId = workbenchId;
                    this.payload.wallpaper.previewSettings = croppingParams;
                    this.wallpaperForm.get('wallpaper').setValidators(Validators.required);
                },
            );
    }

    public submit(): void {
        this.wallpaperForm.markAllAsTouched();
        this.websiteForm.markAllAsTouched();
        this.wallpaperForm.get('wallpaper').patchValue(this.payload?.wallpaper?.workbenchId || '');

        if (this.photoUrl) {
            this.wallpaperForm.get('wallpaper').clearValidators();
            this.wallpaperForm.updateValueAndValidity();
        }
        if (this.wallpaperForm.invalid || this.websiteForm.invalid) {
            this.formService.submitInvalid$.emit();
            return;
        }

        this.payload.websiteUrl = this.websiteForm.get('websiteUrl').value;
        this.payload.active = this.websiteForm.get('active').value;
        // this.payload.domain = this.domainForm.get('domain').value;
        const _payload = _.cloneDeep(this.payload);
        this.loadingService.loadingStart();
        this.streamService.createLandingPage(_payload)
            .pipe(takeUntil(this.destroy$))
            .subscribe((res) => {
                this.loadingService.loadingEnd();
                if (!res.success) {
                    this.websiteForm.get('websiteUrl').setErrors({ENTITY_EXISTS: true});
                    return;
                }
            });
    }

    public clearImage(): void {
        this.photoUrl = '';
        this.wallpaperForm.get('wallpaper').patchValue('');
        // this.wallpaperForm.get('wallpaper').setValidators([Validators.required]);
        this.payload.wallpaper = {
            workbenchId: '',
        };
    }

    public getForms(): FormGroup[] {
        return [this.wallpaperForm, this.websiteForm];
    }

}
