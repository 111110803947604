import {Component, Input, OnInit} from '@angular/core';
import {IPlayerInfoLine, STATE} from 'ui-elements';

@Component({
  selector: 'app-info-line',
  templateUrl: './info-line.component.html',
  styleUrls: ['./info-line.component.sass']
})
export class InfoLineComponent implements OnInit {
  @Input() infoLines: IPlayerInfoLine[];
  public STATE = STATE;
  public state: STATE = STATE.PLAY;

  constructor() { }

  ngOnInit(): void {
  }

}
