import {Component, Input, OnInit} from '@angular/core';
import {LoadingService} from '@src/app/services/loading/loading.service';
import {StreamService} from '@src/app/services/stream-metadata/stream.service';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import {mergeMap, takeUntil} from 'rxjs/operators';
import {Router} from '@angular/router';
import {MEDIA_TYPE} from '@src/app/components/streamdust-player/constants/mediaType';
import {MediaOverviewData} from '@src/app/models/stream.model';
import {ERRORS_KEYS} from '@src/app/constants/errors-keys';
import {UserService} from '@src/app/services/user/user.service';
import {CONTENT_RESTRICTION} from '@src/app/constants/content-restrictions';
import {PLAYER_MODE} from '@src/app/components/streamdust-player/constants/playerMode';
import {ChatState} from '@src/app/state/chat-state';
import {DonationState} from '@src/app/state/donation-state';
import {ChatService} from '@src/app/services/chat/chat.service';
import {StreamWorldMapState} from '@src/app/state/stream-world-map';
import {environment} from '@src/environments/environment';

@Component({
    selector: 'app-event-site-page',
    templateUrl: './event-site-page.component.html',
    styleUrls: ['./event-site-page.component.sass'],
    providers: [AutoDestroyService, ChatState, DonationState, ChatService, StreamWorldMapState]
})
export class EventSitePageComponent implements OnInit {
    @Input() public mediaId: string;
    @Input() public mediaType: MEDIA_TYPE;
    public PLAYER_MODE = PLAYER_MODE;
    public data: MediaOverviewData;
    public contentIsOnReview: boolean;
    public restrictedByCountry: boolean;
    public hasAccess: boolean;
    public coverImage: string;
    public environment = environment;
    public loading: boolean;

    constructor(
        public loadingService: LoadingService,
        public streamService: StreamService,
        public router: Router,
        private destroy$: AutoDestroyService,
        private userService: UserService,
        public worldMapState: StreamWorldMapState
    ) {
    }

    ngOnInit(): void {
        this.loading = true;
        this.streamService.getStreamLandingInfo(this.mediaType, this.mediaId).pipe(takeUntil(this.destroy$))
        // this.streamService.getVideo()
        //     .pipe(
        //         takeUntil(this.destroy$),
        //         mergeMap((mediaInfo) => {
        //             if (!mediaInfo) {
        //                 this.loading = false;
        //                 this.router.navigate(['']);
        //                 return;
        //             }
        //             this.mediaId = mediaInfo.productId;
        //             this.mediaType = mediaInfo.productType;
        //             return this.streamService.getStreamLandingInfo(this.mediaType, this.mediaId).pipe(takeUntil(this.destroy$));
        //         }))
            .subscribe((response) => {
                if (!response.success) {
                    if (response.errorKey === ERRORS_KEYS.CONTENT_IS_ON_REVIEW) {
                        this.contentIsOnReview = true;
                    }
                    return;
                }

                this.streamService.getPublicLandingPageInfo(this.mediaType, this.mediaId)
                    .pipe(takeUntil(this.destroy$))
                    .subscribe((res) => {
                        this.coverImage = this.environment.backendApiHost + res;
                        this.loading = false;
                    }, () => this.loading = false);
                this.data = response?.results?.data;
                this.userService.setPublisherHeaderPicture(this.data?.publisher?.headerPicture, true);
                this.checkForRestriction();
            }, () => this.loading = false);
    }

    private checkForRestriction(): void {
        this.restrictedByCountry = this?.data?.restrictions.includes(CONTENT_RESTRICTION.RESTRICTED_BY_COUNTRY);
    }

    public grantAccess(): void {
        this.hasAccess = true;
    }
}
