import {Component, OnDestroy, OnInit} from '@angular/core';
import {IFieldsConfig} from '@src/app/pages/user-profile/interfaces/interfaces-common';
import {INPUT_TYPES, LocalizationProvider} from 'ui-elements';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ISurvey, MARKETING_ACTION, MARKETING_ENTITY_TYPE, SURVEY_STATUS, SurveyService} from '@src/app/services/survey/survey.service';
import {take, takeUntil, tap} from 'rxjs/operators';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {NEW_ID_PARAM} from '@src/app/utils/custom-validators.util';
import {MEDIA_TYPE} from '@src/app/components/streamdust-player/constants/mediaType';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import {BehaviorSubject} from 'rxjs';
import {DurationPipe} from '@src/app/pipes/duration.pipe';
import {IChartItem} from '@src/app/components/simple-chart/simple-chart.component';
import {Subscription} from 'rxjs/internal/Subscription';
import {StreamService} from '@src/app/services/stream-metadata/stream.service';
import {STREAM_STATUS} from '@src/app/components/streamdust-player/constants/status';
import {MarketingService} from '@src/app/services/marketing/marketing.service';
import {IStreamModel} from '@src/app/models/stream.model';
import {ISchedule, SchedulerService, TIME_POINT} from '@src/app/services/scheduler-service/scheduler.service';
import {DurationConvertPipe} from '@src/app/pipes/duration-convert.pipe';

@Component({
    selector: 'app-info',
    templateUrl: './info.component.html',
    styleUrls: ['./info.component.sass'],
    providers: [AutoDestroyService, SchedulerService]
})
export class InfoComponent implements OnInit, OnDestroy {
    public MEDIA_TYPE = MEDIA_TYPE;
    public SURVEY_STATUS = SURVEY_STATUS;
    public STREAM_STATUS = STREAM_STATUS;
    public data: ISurvey;
    private mediaId: string;
    public mediaType: MEDIA_TYPE;
    private surveyId: string;
    private surveysUrl: string;
    public form: FormGroup;
    public config: IFieldsConfig[];
    activateForm = this.fb.group({
        active: [false]
    });
    private activateFormSubscription: Subscription;

    public answersChartItems: IChartItem[];
    private stream: IStreamModel;
    public streamScheduledTime: number;


    constructor(
        public streamService: StreamService,
        private surveyService: SurveyService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private destroy$: AutoDestroyService,
        private localizationProvider: LocalizationProvider,
        private marketingService: MarketingService,
        private fb: FormBuilder,
        private schedulerService: SchedulerService,) {
    }

    ngOnInit(): void {
        this.activatedRoute.parent.params.pipe(
            takeUntil(this.destroy$),
            tap(({id}: Params) => {
                this.mediaId = id;
                this.mediaType = this.resolveMediaType();
                this.surveysUrl = this.resolveSurveysUrl();
                this.initConfig();
                this.listenStatusChange();
            }))
            .subscribe(() => {
                this.activatedRoute.params.pipe(
                    takeUntil(this.destroy$)).subscribe(({id}: Params) => {
                    if (!id || id === NEW_ID_PARAM) {
                        return;
                    }
                    this.surveyId = id;
                    this.streamService.stream$.pipe(takeUntil(this.destroy$))
                        .subscribe((res) => {
                            if (!res) {
                                return;
                            }
                            if (this.stream) {
                                return;
                            }
                            this.stream = res;
                            this.streamScheduledTime = this.resolveStreamScheduledTime();
                            this.loadData();
                        });
                });
            });

        this.streamService.streamStatus$.pipe(takeUntil(this.destroy$))
            .subscribe((status) => {
                if (!this.stream) {
                    return;
                }
                if (!status?.stream) {
                    return;
                }

                if (this.stream?.media?.status?.stream !== status?.stream) {
                    this.surveyService.clearPublishedSurveys(this.mediaId);
                }
                this.stream.media.status = status;
            });
    }

    private resolveStreamScheduledTime(): number {
        if (this.mediaType === MEDIA_TYPE.VIDEO_ON_DEMAND) {
            return null;
        }

        if ([STREAM_STATUS.FINISHED].includes(this.stream?.media?.status?.stream)) {
            return null;
        }

        return this.stream?.media?.startTimestamp;
    }

    private schedule(): void {
        if (!this.streamScheduledTime) {
            return;
        }

        this.schedulerService.schedule(this.toSchedule(), true);
        this.listenSchedulersEvents();
        this.schedulerService.start(new Date().getTime());
    }

    private toSchedule(): ISchedule[] {
        return [{
            entityId: this.data.id,
            entity: this.data,
            eventType: 'SURVEY',
            timePointStart: this.resolveSurveyStartTime(this.data),
            timePointStop: this.resolveSurveyEndTime(this.data)
        }];
    }

    private resolveSurveyStartTime(survey: ISurvey): number {
        if (this.streamScheduledTime) {
            return this.streamScheduledTime + (survey.startTime * 1000);
        }

        return survey.startTime;
    }

    private resolveSurveyEndTime(survey: ISurvey): number {
        if (this?.streamScheduledTime) {
            return this?.streamScheduledTime + (+survey.endTime * 1000);
        }
        return survey.endTime;
    }

    private listenSchedulersEvents(): void {
        this.schedulerService.events
            .pipe(takeUntil(this.destroy$))
            .subscribe((ev) => {
                if (!ev) {
                    return;
                }
                switch (ev.timePoint) {
                    case TIME_POINT.START:
                        (ev.entity as ISurvey).inProgress = true;
                        break;
                    case TIME_POINT.STOP:
                        (ev.entity as ISurvey).inProgress = false;
                        (ev.entity as ISurvey).notComplete = false;
                        if (!(ev.entity as ISurvey).isManual && this.resolveSurveyEndTime((ev.entity as ISurvey)) > new Date().getTime()) {
                            (ev.entity as ISurvey).notComplete = true;
                        }
                        if ((ev.entity as ISurvey).isManual && !(ev.entity as ISurvey).startedAt) {
                            (ev.entity as ISurvey).notComplete = true;
                        }
                        break;
                }
            });
    }

    private resolveMediaType(): MEDIA_TYPE {
        return (this.activatedRoute.data as BehaviorSubject<any>)?.value?.mediaType
    }

    private resolveSurveysUrl(): string {
        const mediaIdRelatedUrlSegment = this.mediaId + '/surveys';
        switch (this.mediaType) {
            case MEDIA_TYPE.STREAM:
            case MEDIA_TYPE.STREAM_RECORDING:
                return 'streams/' + mediaIdRelatedUrlSegment;
            case MEDIA_TYPE.VIDEO_ON_DEMAND:
                return 'vod/' + mediaIdRelatedUrlSegment;

        }
    }

    private initConfig(): void {
        this.form = this.fb.group({
            name: [''],
            active: [''],
            duration: [''],
            views: [''],
            votes: ['']
        });

        this.config = [
            {
                name: 'name',
                config: {
                    inputType: INPUT_TYPES.INPUT,
                    label: 'survey.table.field.name',
                    placeholder: '',
                }
            },
            {
                name: 'active',
                config: {
                    inputType: INPUT_TYPES.INPUT,
                    label: 'survey.table.field.status',
                    placeholder: '',
                }
            },
            {
                name: 'duration',
                config: {
                    inputType: INPUT_TYPES.INPUT,
                    label: 'survey.table.field.duration',
                    placeholder: '',
                }
            },
            {
                name: 'views',
                config: {
                    inputType: INPUT_TYPES.INPUT,
                    label: 'survey.table.field.views',
                    placeholder: '',
                }
            },
            {
                name: 'votes',
                config: {
                    inputType: INPUT_TYPES.INPUT,
                    label: 'survey.table.field.votes',
                    placeholder: '',
                }
            }
        ];
    }

    private loadData(updateState: boolean = true): void {
        this.surveyService.get(this.surveyId).pipe(takeUntil(this.destroy$))
            .subscribe((res) => {
                res.endTime = res.duration;
                this.data = res;
                if (!this.data.isManual && this.resolveSurveyEndTime(this.data) > new Date().getTime()) {
                    this.data.notComplete = true;
                }

                if (this.data.isManual && !this.data.startedAt) {
                    this.data.notComplete = true;
                }
                this.data.resultsPublished = this.surveyService.publishedSurveys(this.mediaId)
                    .findIndex(_item => _item.id === this.data.id) > -1 && this.stream?.media?.status?.stream !== STREAM_STATUS.FINISHED;
                this.fillForm(updateState);
                if (this.activateFormSubscription) {
                    this.activateFormSubscription.unsubscribe();
                }
                this.activateFormSubscription = this.activateForm.get('active')
                    .valueChanges.pipe(takeUntil(this.destroy$)).subscribe(active => {
                        this.toggleOverlay(active);
                });
                this.mapAnswersToChartItems();
                this.schedule();
            });
    }

    private listenStatusChange(): void {
        this.marketingService.connectToSocket(this.mediaId, this.mediaType)
        // this.marketingService.events()
            .pipe(takeUntil(this.destroy$))
            .subscribe(res => {
                if (!res) {
                    return;
                }

                if (res.results.data.type !== MARKETING_ENTITY_TYPE.SURVEY) {
                    return;
                }
                const surveyStatus = res.results.data;
                if (surveyStatus.id !== this.data.id) {
                    return;
                }

                this.data.status = surveyStatus.status;
                if (res.results.data.action === MARKETING_ACTION.SHOW) {
                    this.data.manualStarted = true;
                }
                if (res.results.data.action === MARKETING_ACTION.HIDE) {
                    this.data.manualStarted = false;
                }
            });
    }

    private mapAnswersToChartItems(): void {
        const votesCount = this.data.answers.reduce((all, answer) => all + answer.replies, 0);
        this.answersChartItems = this.data.answers.map(answer => ({
            text: answer.answer,
            value: votesCount ? +(((answer.replies / votesCount) * 100).toFixed(2)) : 0
        }));
    }

    private fillForm(updateState: boolean = true): void {
        Object.keys(this.form.controls).forEach(control => {
            let value = this.data[control];
            if (control === 'active') {
                value = this.localizeStatus(value);
            }
            if (control === 'duration') {
                value = new DurationConvertPipe().transform(value, 'seconds');
            }
            this.form.get(control).patchValue(value);
        });
        if (updateState) {
            this.activateForm.get('active').setValue(this.data.active);
        }
    }

    private localizeStatus(status: boolean): string {
        return this.localizationProvider.getByKey('survey.status.' + (status ? 'active' : 'inactive'));
    }

    public start(): void {
        this.surveyService.start(this.data.id)
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {this.data.manualStarted = true; });
    }

    public stop(): void {
        this.surveyService.stop(this.data.id)
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {this.data.manualStarted = false; });
    }

    public toggleOverlay(state: boolean): void {
        this.surveyService.toggle(this.data.id, state)
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {});
    }

    public publishResult(): void {
        this.surveyService.publishResults(this.surveyId, this.mediaId)
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                this.data.resultsPublished = this.surveyService.publishedSurveys(this.mediaId)
                    .findIndex(item => item.id === this.data.id) > -1;
            });
    }

    public goToSurveyPage() {
        let qp = {};
        let url = '../../';

        if ((this.activatedRoute.data as BehaviorSubject<any>)?.value?.standAlone) {
            url = '../../../';
            qp = { surveys: true};
        }
        this.router.navigate([url], {relativeTo: this.activatedRoute, queryParams: qp});
    }

    ngOnDestroy() {
        this.schedulerService.stop();
    }

}
