/**
 * Created by Тима on 07.04.2020.
 */
import {Injectable} from '@angular/core';
import {ApiService} from 'ui-elements';
import {AuthService} from '@src/app/services/auth/auth.service';
import {Observable} from 'rxjs';
import {IResponse} from '@src/app/models/response.model';
import {API_URLS_APP} from '@src/app/constants/api-urls.constant';
import {tap} from 'rxjs/operators';
import {IInputConfig, INPUT_TYPES} from 'ui-elements';

@Injectable()
export class ChangePasswordService {

    constructor(
        private apiService: ApiService,
        private readonly authService: AuthService
    ) {
    }
    public getFieldsConfig(): IChangePassMetadataFieldsConfig[] {
        return [
            {name: 'oldPassword',
                config: {
                    inputType: INPUT_TYPES.INPUT,
                    label: 'profile.passwordChange.oldPassword',
                    placeholder: '',
                    isRequired: true,
                    type: 'password'
                }
            },
            {name: 'newPassword',
                config: {
                    inputType: INPUT_TYPES.INPUT,
                    label: 'profile.passwordChange.newPassword',
                    placeholder: '',
                    isRequired: true,
                    type: 'password'
                }
            },
            {name: 'newPasswordConfirmation',
                config: {
                    inputType: INPUT_TYPES.INPUT,
                    label: 'profile.passwordChange.newPasswordConfirm',
                    placeholder: '',
                    isRequired: true,
                    type: 'password'
                }
            }
        ];
    }
    public changePassword(oldPassword: string, newPassword: string): Observable<IResponse<{ authToken: string }>> {
        return this.apiService.post(API_URLS_APP.CHANGE_PASSWORD, {oldPassword, newPassword})
            .pipe(
                // filter((response) => response.success),

                tap((response) => {
                    response.success && this.authService.storeToken(response.results.authToken);
                })
            );
    }
}
export interface IChangePassMetadataFieldsConfig {
    name: string;
    config: IInputConfig;
}
