import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {ITariffPlan} from "@src/app/services/storage-and-bandwidth/storage-and-bandwidth.service";
import {TARIFF_PLAN_PERIOD} from "@src/app/components/tariff-plan-cards-list/subscription-plan-card/subscription-plan-card.component";
import {IPrice} from "@src/app/models/stream.model";
import {TARIFF_PLAN_TYPES} from "@src/app/components/tariff-plan-cards-list/tariff-plan-cards-list.component";

@Component({
    selector: 'app-plan-card',
    templateUrl: './plan-card.component.html',
    styleUrls: ['./plan-card.component.sass']
})
export class PlanCardComponent implements OnChanges {

    @Input() plan: ITariffPlan;
    @Input() config: IPlanConfig;

    @Output() actionButtonPressed$ = new EventEmitter<ITariffPlan>();

    planPriceMonth: IPrice;
    planPriceYear: IPrice;
    planPriceWeek: IPrice;

    PLAN_PERIOD = TARIFF_PLAN_PERIOD;
    PLAN_TYPES = TARIFF_PLAN_TYPES;

    constructor() {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.config?.currentValue) {
            this.calculatePlanPrice();
        }
    }

    ngOnInit(): void {
        this.calculatePlanPrice();
    }

    calculatePlanPrice(): void {
        this.planPriceMonth = this.getTariffPlanPrice(TARIFF_PLAN_PERIOD.MONTH);
        this.planPriceYear = this.getTariffPlanPrice(TARIFF_PLAN_PERIOD.YEAR);
        this.planPriceWeek = this.getTariffPlanPrice(TARIFF_PLAN_PERIOD.WEEK);
    }

    getTariffPlanPrice(period: TARIFF_PLAN_PERIOD): IPrice {
        const tariffPlanPrice = this.plan.prices.find(price => price.period === this.config?.planPeriod);
        const periodPrice = tariffPlanPrice?.prices.find(periodPrice => periodPrice.period === period);
        return periodPrice?.price;
    }

    activatePlan(): void {
        this.actionButtonPressed$.emit(this.plan);
    }
}

export interface IPlanConfig {
    iconName: string;
    iconIndex: number;
    hideActionButton: boolean;
    planPeriod: TARIFF_PLAN_PERIOD;
    planType: TARIFF_PLAN_TYPES;
}
