import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-feed-section',
  templateUrl: './feed-section.component.html',
  styleUrls: ['./feed-section.component.css']
})
export class FeedSectionComponent implements OnInit {
  @Input() public title: string;
  @Input() public length: number;
  @Input() public isSection = true;
  @Input() isNew: boolean;
  @Input() isShowCounter = true;
  constructor() { }

  ngOnInit() {
  }
}
