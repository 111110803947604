<div class="content">
    <div class="title-wrap">
        <h2>{{'dashboard.welcomeBack' | localize}}, <b>{{(userService.userProfile$ | async)?.firstName || (userService.userProfile$ | async)?.email}}</b></h2>
        <ano-datepicker [showAllOption]="false" (changeDate)="timeRangeFilter($event)"></ano-datepicker>
    </div>
    <div class="row row-padding-10">
        <div class="col-lg-6" *ngFor="let config of widgetsConfigs">
            <div class="widget-spinner-container">
                <ano-loader *ngIf="loading" [isOpen]="loading"></ano-loader>
            </div>
            <ano-widget [config]="config"></ano-widget>
        </div>
    </div>

    <div class="card">
        <div class="card-header header-element-inline">
            <h2 class="title">{{'dashboard.viewersChart.title' | localize}}</h2>
        </div>
        <div class="card-body bg-white chart-container">
            <ano-loader *ngIf="loading" [isOpen]="loading"></ano-loader>
            <div class="row row-padding-10 mb-30">
                <div class="col-lg-12 col-md-12">
                    <ano-scatter-chart #chartComponent *ngIf="!loading" [data]="chartData"
                                       [width]="700"
                                       [categories]="data?.statistic?.category" [legend]="data?.legend"
                                       [colorScheme]="colorScheme"></ano-scatter-chart>
                </div>
            </div>
        </div>
    </div>

    <div class="card">
        <div class="card-header header-element-inline">
            <h2 class="title">{{'dashboard.pieChart.title' | localize}}</h2>
        </div>
        <div class="card-body bg-white chart-container">
            <ano-loader *ngIf="loading" [isOpen]="loading"></ano-loader>
            <div class="row row-padding-10 mb-30">
                <div class="col-lg-8 col-md-8">
                    <ano-pie-chart *ngIf="pieChartData" [id]="2" [data]="pieChartData" [colorScheme]="pieChartLength ? pieChartColorScheme : pieChartNoDataColorScheme"></ano-pie-chart>
                </div>
                <div class="col-lg-4 col-md-4">
                    <ano-legend-box [legend]="pieChartLegend" [colorScheme]="pieChartColorScheme" [readOnly]="true" [suffix]="'%'"></ano-legend-box>
                </div>
            </div>
        </div>
    </div>
    <div class="card">
        <div class="card-header header-element-inline">
            <h2 class="title">{{'dashboard.worldwide.title' | localize}}</h2>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-xl-8 col-lg-6">
                    <p style="margin-bottom: 24px;">{{'dashboard.worldwide.description' | localize}}</p>
                </div>
            </div>
            <div class="table-wrapper-border">
                <ano-loader *ngIf="loading" [isOpen]="loading"></ano-loader>
                <div class="table-responsive" *ngIf="data">
                    <table class="table table-sm table-border layout-fixed without-bg-header table-hover">
                        <thead>
                        <tr class="bg-white">
                            <th width="85%" class="text-align-left bg-white">
                                {{'dashboard.worldwide.table.Impressionen' | localize}}
                                {{'dashboard.worldwide.table.total' | localize}}
                            </th>
                            <th class="bg-white"><div class="number-container">{{'dashboard.worldwide.table.count' | localize}}</div><br/></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let item of summary">
                            <td width="85%">{{('dashboard.pieChart.legend.' + item.title) | localize}}</td>
                            <td><ano-number class="dashboard-table-number" [data]="item.count"></ano-number></td>
                        </tr>
                        </tbody>
                        <tfoot>
                        <tr>
                            <td>{{'dashboard.worldwide.table.total' | localize}}</td>
                            <td><ano-number class="dashboard-table-number" [data]="data?.summary?.total"></ano-number></td>
                        </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <div class="card">
        <div class="card-header header-element-inline">
            <h2 class="title">{{'dashboard.barChart.title' | localize}} ({{(currentCurrency | async) || CURRENCY_SYMBOLS.EUR}})</h2>
        </div>
        <div class="card-body bg-white bar-chart-container">
            <ano-loader *ngIf="loading" [isOpen]="loading"></ano-loader>
            <div class="row row-padding-10 mb-30">
                <div class="col-lg-12 col-md-12">
                    <ano-bar-chart *ngIf="barChartData" [data]="barChartData" [colorScheme]="colorScheme" [width]="770" [id]="5" [currency]="(currentCurrency | async)"></ano-bar-chart>
                </div>
            </div>
        </div>
    </div>
    <div class="card">
        <div class="card-header header-element-inline">
            <h2 class="title">{{'dashboard.ads.title' | localize}}</h2>
        </div>
        <div class="card-body ads-container">
            <ano-loader *ngIf="loading" [isOpen]="loading"></ano-loader>
            <div class="row justify-content-center row-padding-55">
                <div class="col-auto">
                    <div class="box-210 text-center">
                        <h3 class="title">{{data?.ads.trailer | number}}</h3>
                        <p>{{'dashboard.promoClickOnTrailer' | localize}}</p>
                    </div>
                </div>
                <div class="col-auto">
                    <div class="box-210 text-center">
                        <h3 class="title">{{data?.ads.overlay | number}}</h3>
                        <p>{{'dashboard.promoClickOnBanner' | localize}}</p>
                    </div>
                </div>
                <div class="w-100"></div>
                <div class="col-auto">
                    <div class="box-210 text-center">
                        <h3 class="title">{{data?.ads.teaser | number}}</h3>
                        <p>{{'dashboard.promoClickOnTeaser' | localize}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card">
        <div class="card-header header-element-inline">
            <h2 class="title">{{'dashboard.worldmap.title' | localize}}</h2>
        </div>
        <div class="card-body map-container">
            <ano-loader *ngIf="loading" [isOpen]="loading"></ano-loader>
            <app-bubble-map [id]="1" [dataArray]="map" (onBubbleHover$)="onMapHover($event)"
            ></app-bubble-map>
            <p class="total-viewers">
                <span class="break-after">{{totalViewers | numberWithSeparator}}</span>
                <span class="text-xs break-after">{{'stream-world-map.viewers' | localize}}</span>
                <span class="text-xs">{{'stream-world-map.worldwide' | localize}}</span>
            </p>
            <p *ngIf="viewersInACountry && currentCountry" class="country-viewers">
                <span class="break-after">{{viewersInACountry | numberWithSeparator}}</span>
                <span class="text-xs break-after">{{'stream-world-map.viewers' | localize}}</span>
                <span class="text-xs">{{'stream-world-map.from' | localize}} {{currentCountry}}</span>
            </p>
        </div>
    </div>
</div>
