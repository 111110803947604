import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-info-box',
  templateUrl: './info-box.component.html',
  styleUrls: ['./info-box.component.sass']
})
export class InfoBoxComponent {

  public INFO_BOX_TYPES = INFO_BOX_TYPES;

  @Input() public type: INFO_BOX_TYPES;

  constructor() { }

}

export enum INFO_BOX_TYPES {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  INFO = 'INFO',
}
