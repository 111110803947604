<section class="section section-live-streams">
    <div class="container">
        <div class="box-live-streams">
            <app-media-list
                    [activeQuery]="activeFilters"
                    [implicitFilters]="implicitFilters"
                    [config]="{
                                fetchMethod: actualStreamsListConfig?.fetchMethod,
                                loadMore: LOAD_TYPE.SCROLL,
                                filters: actualStreamsListConfig?.filters,
                                headerConfig: {
                                    cssClass: 'title-xlg',
                                    filtersCssClass: 'blue-theme-filter',
                                    withSearch: true,
                                    filtration: true
                                },
                                cardConfig: {
                                    isActual: true,
                                    elements: {
                                        country: true,
                                        publisher: true,
                                        group: true
                                    }
                                }
                            }"
                    [title]="actualStreamsListConfig?.title"
                    [data]="actualStreamsListConfig?.media">
            </app-media-list>
        </div>
    </div>
</section>
