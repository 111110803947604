import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {

  transform(time: number, ...args: unknown[]): unknown {
    if (time === 0) {
      return '00:00';
    }

    // return this.parseTime(time);
    const minutes = Math.floor((time) / 60);
    const seconds = Math.floor((time) - (minutes * 60));
    return  this.getTime(minutes, '0', 2) + ' : ' + this.getTime(seconds, '0', 2);
  }

  private getTime(string, pad, length): string {
    return (new Array(length + 1).join(pad) + string).slice(-length);
  }

  private parseTime(time, type?: TIME_TYPE): number {
    const duration = time.split(':');

    if (!duration.length) {
      return time;
    }

    if (type === TIME_TYPE.MINUTES) {
      return (+duration[0]);
    }

    if (type === TIME_TYPE.MILLISECONDS) {
      return ((+duration[0] * 60) + (+duration[1] || 0)) * 1000;
    }

    return (+duration[0] * 60) + (+duration[1] || 0);
  }

}


enum TIME_TYPE {
  MINUTES, MILLISECONDS
}
