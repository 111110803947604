<section *ngIf="loading && (eventSiteState.isEventSite$ | async)" class="section loading-container">
    <ano-loader [isOpen]="(loadingService.loading | async) && (eventSiteState.isEventSite$ | async)"></ano-loader>
</section>

<div [class.event-site]="loadedFromSubdomain || (eventSiteState.isEventSite$ | async)" [ngStyle]="(loadedFromSubdomain || (eventSiteState.isEventSite$ | async))? {'background-image': 'url(' + coverImage + ')', 'background-size': 'cover', 'background-position': 'center top', 'background-repeat': 'no-repeat'} : {}">
    <section class="section section-live-streams mt-0">
        <div class="box-live-streams">
            <div class="container media-player-container" [class.content-center]="restrictedByCountry">
                <ng-container *ngIf="!loading">
                    <h1 *ngIf="contentIsOnReview">{{'public-media-page.content-is-on-review-text' | localize}}</h1>
                    <h1 *ngIf="restrictedByCountry">{{'public-media-page.content-is-on-restricted_by_country' | localize}}</h1>

                    <div class="box-streams" [class.mb-0]="data?.video?.groups?.length > 0"
                         *ngIf="!contentIsOnReview && !restrictedByCountry">
                        <div class="row">
                            <div class="col-lg-12">
                                <app-streamdust-player *ngIf="!loading"
                                                       [mediaType]="mediaType"
                                                       [mediaId]="mediaId"
                                                       [mode]="PLAYER_MODE.VIEWER"
                                                       (grantAccess$)="grantAccess()"
                                ></app-streamdust-player>
                                <div class="player-footer">
                                    <div class="row">
                                        <div class="col-lg-8">
                                            <h2 class="title stream-title">{{data?.video?.title}}</h2>
                                            <div class="stream-description">
                                                <ng-container
                                                        *ngFor="let descriptionLine of data?.video?.description.split('\n')">
                                                    <p class="description-line">{{descriptionLine}}</p>
                                                </ng-container>
                                            </div>
                                        </div>
                                        <div class="col-lg-4">
                                            <app-publisher-info [publisher]="data?.publisher"
                                                                [contacts]="data?.contacts"
                                                                [data]="data"
                                            ></app-publisher-info>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <!--            <div *ngIf="data?.video?.groups?.length > 1 && !restrictedByCountry" class="media-groups-list mb-40">-->
                <!--                <div class="panel-header text-center">-->
                <!--                    <h2><b>{{'public-media-page.media-groups-media.title' | localize}}</b></h2>-->
                <!--                    <div class="btn-group btn-group-toggle" data-toggle="buttons">-->
                <!--                        <ng-container *ngFor="let group of data?.video?.groups; let i = index">-->
                <!--                            <label [class.active]="i === activeGroupIndex" class="btn btn-primary"-->
                <!--                                   (click)="changeActiveGroup(i)">-->
                <!--                                <input type="radio" name="options" id="option1"-->
                <!--                                       checked> {{group?.name}}-->
                <!--                            </label>-->
                <!--                        </ng-container>-->
                <!--                    </div>-->
                <!--                </div>-->
                <!--            </div>-->

                <!--            <div *ngIf="data?.video?.groups?.length > 0 && !restrictedByCountry" class="box-live-streams">-->
                <!--                <div class="box-streams">-->
                <!--                    <app-media-list-->
                <!--                            [implicitFilters]="mediaGroupsMedia[activeGroupIndex]?.implicitFilters"-->
                <!--                            [config]="{-->
                <!--                                fetchMethod: mediaGroupsMedia[activeGroupIndex]?.fetchMethod,-->
                <!--                                headerConfig: {-->
                <!--                                    cssClass: 'card-profile title-level-1'-->
                <!--                                },-->
                <!--                                loadMore: LOAD_TYPE.BUTTON,-->
                <!--                                loadMoreCustomClick: mediaGroupsMedia[activeGroupIndex]?.loadMoreCustomClick,-->
                <!--                                filters: mediaGroupsMedia[activeGroupIndex]?.filters-->
                <!--                            }"-->
                <!--                            [title]="data?.video?.groups?.length > 1 ? '' : mediaGroupsMedia[activeGroupIndex]?.title"-->
                <!--                            [data]="mediaGroupsMedia[activeGroupIndex]?.media">-->
                <!--                    </app-media-list>-->
                <!--                </div>-->
                <!--            </div>-->
            </div>
        </div>
    </section>

<!--    <div class="box-live-streams groups-container" *ngIf="data?.video?.groups?.length && !restrictedByCountry">-->
<!--        <app-media-list-slider-->
<!--                [selectorIndex]="activeGroupIndex"-->
<!--                [implicitFilters]="mediaGroupsMedia[activeGroupIndex]?.implicitFilters"-->
<!--                [mediaList]="mediaGroupsMedia[activeGroupIndex]?.media" [id]="'1'"-->
<!--                [config]="{-->
<!--            itemsPerList: 3,-->
<!--            carouselId: '1',-->
<!--            itemsToScrollAtOnce: 1,-->
<!--            groups: data?.video?.groups,-->
<!--            selectorTitle: 'public-media-page.media-groups-media.title',-->
<!--            moreButton: {-->
<!--                show: true,-->
<!--                text: 'common.load-more',-->
<!--                navigationClick: mediaGroupsMedia[activeGroupIndex]?.loadMoreCustomClick-->
<!--            }-->
<!--    }"-->
<!--                (selectorClick)="changeActiveGroup($event)"-->
<!--        ></app-media-list-slider>-->
<!--    </div>-->

    <div class="container" [class.event-site-devider]="loadedFromSubdomain || (eventSiteState.isEventSite$ | async)">
        <div class="horizontal-divider mt-35"></div>
    </div>

    <div *ngIf="data" class="container public-page-suggested-media mb-40">
        <div class="box-live-streams">
            <app-media-lists [mediaLists]="suggestedMedia" [config]="suggestedMediaListsConfig" [isPlaceholder]="true"></app-media-lists>
        </div>
    </div>
<!--    <app-donations *ngIf="data" [mediaId]="mediaId" [mediaType]="mediaType"-->
<!--                   [currency]="data?.video?.price?.currency" [playerMode]="PLAYER_MODE.VIEWER"></app-donations>-->
    <app-stream-world-map *ngIf="true" [id]="2"
                          [streamId]="mediaId"></app-stream-world-map>
</div>
