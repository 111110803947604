import {Component, ElementRef, Inject, NgZone, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '@src/app/services/auth/auth.service';
import {IServerError} from '@src/app/models/server-error.model';
import {LocalizationProvider} from 'ui-elements';
import {DOCUMENT} from '@angular/common';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import {INPUT_TYPES} from 'ui-elements';
import {UserService} from '@src/app/services/user/user.service';

@Component({
    selector: 'app-login-page',
    templateUrl: './login-page.component.html',
    styleUrls: ['./login-page.component.css']
})
export class LoginPageComponent implements OnInit {
    @ViewChild('loginInput', {static: false}) loginInput: ElementRef<HTMLElement>;
    public localizationList = this.localizationProvider.localizationList;
    public loginForm: FormGroup;
    public serverErrors: IServerError[] = [];
    private readonly topColor = '#007bf6';
    public INPUT_TYPES = INPUT_TYPES;

    public loading: { [key: string]: boolean } = {};

    constructor(
        private router: Router,
        private fb: FormBuilder,
        private authService: AuthService,
        private localizationProvider: LocalizationProvider,
        @Inject(DOCUMENT) private _document: any,
        private autoDestroy$: AutoDestroyService,
        private _ngZone: NgZone,
        private userService: UserService
    ) {
    }

    ngOnInit() {
        this.setLoginForm();
        // if (this.nativeMobileService.isMobile()) {
        //   this.nativeMobileService.changeTopColor(this.topColor);
        // }
    }

    private setLoginForm(): void {
        this.loginForm = this.fb.group({
            email: ['', [Validators.email]],
            password: ['', [Validators.required]],
        });
    }

    public login(): void {
        this.loginForm.markAllAsTouched();
        if (this.loginForm.invalid) {
            return;
        }

        this.loading.login = true;
        this.authService.login(this.loginForm.value)
            .subscribe(
                (res) => {
                    this.loading.login = false;
                    if (!res.success) {
                        this.serverErrors = [{key: res.errorKey, text: this.localizationProvider.getByErrorKey(res.errorKey)}];
                        return;
                    }
                    this.userService.publisherHeaderPicture.next(null);

                    this._ngZone.run(() => {
                        this.router.navigate(['home']);
                    });
                },
                () => this.loading.login = false,
            );
    }
}
