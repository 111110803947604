import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'printArray'
})
export class PrintArrayPipe implements PipeTransform {

    transform(value: string[]): any {
        if (!value) {
            return value;
        }
        return value.filter(i => i).join(', ');
    }

}
