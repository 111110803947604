<div class="input-group input-group-edit material-form-group">
  <div class="btn btn-edit btn-block form-control form-control-multiselect">
      <span class="multi-select-list w-100">
          <ng-container *ngIf="isMulti">
            <span *ngFor="let file of value" class="select-item">
              {{ file.name }}
            </span>
          </ng-container>
          <span *ngIf="!isMulti && value">
            {{ value.name }}
          </span>
          <span (click)="nativeFileInput.click()"
                *ngIf="isEmpty"
                class="w-100">{{ 'default.filesAdd' | localize }}</span>
      </span>
    <span (click)="nativeFileInput.click()" class="plus-icon">+</span>
  </div>
  <label class="form-control-placeholder floated"
  >{{ label | localize }}</label>
</div>


<input #nativeFileInput
       (change)="addFile($event)"
       [multiple]="isMulti"
       [accept]="acceptTypes"
       class="native-file-input"
       type="file">
