<div class="modal modal-absolute show" *ngIf="mediaId" (click)="closeModal()">
    <div class="modal-dialog modal-xl" role="document" (click)="stopImmediatePropagation($event)">
        <div mat-dialog-content class="modal-content">
            <div class="modal-header">
                <h4><b>{{modalTitle | localize}}</b></h4>
            </div>
            <div class="explanations-container">
                <p class="first">{{'linkedStreams.linkName.description' | localize}}</p>
                <p>{{'linkedStreams.originLink.description' | localize}}</p>
            </div>
            <div class="modal-top">
                <div class="input-wrap">
                    <app-bordered-form
                            [defaultForm]="true"
                            [form]="form"
                            [fieldsConfig]="formConfig"
                    ></app-bordered-form>
                </div>
                <button *ngIf="isEditMode"
                        class="btn btn-primary"
                        (click)="onChangeMode()">{{'groupsOfLinks.modal.changeLinkBtn' | localize}}</button>
            </div>
            <div class="teble-wrap">
                <div class="teble" [class.without-filter]="isEditMode">
                    <ano-table #tableComponent
                               [tableConfig]="tableConfig"
                               [defaultSortField]="'TITLE'"
                               [withSearch]="!isEditMode"
                               [isOpportunityToSelect]="true"
                               [selectedRowId]="data?.linkedMediaId"
                               [isLinkedStreamsFilters]="!isEditMode"
                               [linkedFilters]="filters"
                               (filterByLinkedStreams$)="filterByLinkedStreams($event)"
                               (select$)="onSelectLinks($event)"
                               (search$)="search($event)"
                    ></ano-table>
                </div>
            </div>
            <div class="footer-container">
                <div class="error-message" *ngIf="isShowError">
                    {{'groupsOfLinks.modal.errorMessage' | localize}}
                </div>
                <div class="buttons-wrap">
                    <button class="btn btn-danger cancel-button"
                            (click)="closeModal()">{{'common.button.close' | localize}}</button>
                    <button class="btn btn-success"
                            (click)="onAddLinks()">{{ 'groupsOfLinks.modal.add' | localize }}</button>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #statusTmpl let-data="rowData">
    <div class="text-left" [ngStyle]="{'color': '#0e54a9'}">
        {{(data?.status | itemSetting: {
        itemType: ITEM_TYPE.STREAM,
        settingType: SETTING_TYPE.TRANSLATE,
        skipRecord: false,
        ignoreNoneRecord: false
    }) | localize}}
    </div>
</ng-template>
<ng-template #nameTmpl let-data="rowData">
    <div class="text-left">
        {{data.title}}
    </div>
</ng-template>
<ng-template #startDateTmpl let-data="rowData">
    <div class="text-left">
        {{data.startTimestamp || data.created | dateFormat: 'DD MMM YYYY'}}
    </div>
</ng-template>
<ng-template #endDateTmpl let-data="rowData">
    <div class="text-left">
        {{data.endTimestamp | dateFormat: 'DD MMM YYYY'}}
    </div>
</ng-template>
<ng-template #recordTmpl let-data="rowData">
    <div class="text-left">
        {{((data.recordingEnabled || data.hasRecording) ? 'groupsOfLinks.modal.record-true' : 'groupsOfLinks.modal.record-false') | localize}}
    </div>
</ng-template>
