import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SharedModule} from '@src/app/modules/shared/shared.module';
import {GroupsPageComponent} from '@src/app/pages/groups-page/groups-page.component';
import { GroupsListComponent } from './components/groups-list/groups-list.component';
import { GroupComponent } from './components/group/group.component';
import { GroupModalContainerComponent } from './components/group-modal-container/group-modal-container.component';
import {GroupRevenueComponent} from '@src/app/pages/groups-page/components/group-revenue/group-revenue.component';
import {
  CountryRevenuesComponent
} from '@src/app/pages/groups-page/components/group-revenue/components/country-revenues/country-revenues.component';



@NgModule({
  declarations: [GroupsPageComponent, GroupsListComponent, GroupComponent, GroupModalContainerComponent, GroupRevenueComponent, CountryRevenuesComponent],
  imports: [
    CommonModule,
      SharedModule
  ]
})
export class GroupsPageModule { }
