import {STREAM_STATUS} from '@src/app/components/streamdust-player/constants/status';
import {StreamLinksModalComponent} from './stream-links-modal/stream-links-modal.component';
import {IListOfStreamsLinks} from './../../../../models/stream-links.model';
import {StreamLinksService} from './../../../../services/stream-links/stream-links.service';
import {MEDIA_TYPE} from './../../../../components/streamdust-player/constants/mediaType';
import {StreamService} from '@src/app/services/stream-metadata/stream.service';
import {IFormConfig} from './../../../../services/stream-metadata/stream.service';
import {AutoDestroyService} from './../../../../services/auto-destroy-service/auto-destroy.service';
import {takeUntil} from 'rxjs/operators';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {ITableConfig, TableComponent, INPUT_TYPES} from 'ui-elements';
import {Component, OnInit, ViewChild, TemplateRef} from '@angular/core';
import {LocalizationProvider} from 'ui-elements';
import {UserService} from '@src/app/services/user/user.service';
import {IUserProfile} from '@src/app/pages/user-profile/components/profile/services/profile.service';

@Component({
    selector: 'app-stream-links',
    templateUrl: './stream-links.component.html',
    styleUrls: ['./stream-links.component.scss'],
    providers: [AutoDestroyService]
})
export class StreamLinksComponent implements OnInit {
    STREAM_STATUS = STREAM_STATUS;
    public tableConfig: ITableConfig<IListOfStreamsLinks>;
    public searchQuery = '';
    private confirmTextTpl = this.localizationProvider.getByKey('groupsOfLinks.deleteText');
    public confirmText = '';
    public INPUT_TYPES = INPUT_TYPES;
    public formConfig: IFormConfig;
    public mediaId: string;
    private table: TableComponent;
    public isDisable: boolean;
    private user: IUserProfile;

    @ViewChild('tableComponent', {static: false}) set tableComponent(content: TableComponent) {
        if (content) {
            this.table = content;
        }
    }

    @ViewChild('confirm', {static: false}) confirm: TemplateRef<any>;
    @ViewChild('statusTmpl', {static: true}) public statusTmpl: TemplateRef<any>;
    @ViewChild('nameTmpl', {static: true}) public nameTmpl: TemplateRef<any>;
    @ViewChild('linksNameTmpl', {static: true}) public linksNameTmpl: TemplateRef<any>;
    @ViewChild('dateTmpl', {static: true}) public dateTmpl: TemplateRef<any>;
    @ViewChild('callTmpl', {static: true}) public callTmpl: TemplateRef<any>;
    @ViewChild('actionsTmpl', {static: true}) public actionsTmpl: TemplateRef<any>;
    @ViewChild('titleTmpl', {static: true}) public titleTmpl: TemplateRef<any>;

    constructor(
        private localizationProvider: LocalizationProvider,
        public dialog: MatDialog,
        private destroy$: AutoDestroyService,
        private streamService: StreamService,
        private streamLinksService: StreamLinksService,
        private userService: UserService
    ) {
    }

    ngOnInit(): void {
        this.streamService.stream$.subscribe(stream => {
            if (!stream) {
                return;
            }
            this.isDisable = stream.media?.status?.stream === STREAM_STATUS.FINISHED && !stream.media?.hasRecording;
            this.mediaId = stream.media?.id;
            this.setConfigs();
        });

        this.userService.userProfile$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
            this.user = user;
        });
    }

    private setConfigs(): void {
        this.formConfig = this.streamService.getStreamFormConfig(false, false, MEDIA_TYPE.STREAM, true, this.isDisable);
        this.tableConfig = {
            dataField: 'data',
            matPaginator: true,
            customScroll: true,
            searchFn: (sortParams, pagingParams) => {
                return this.streamLinksService.getListOfLinks(this.mediaId, this.searchQuery, pagingParams, sortParams);
            },
            columns: [
                {
                    name: 'groupsOfLinks.status',
                    sortField: 'STATUS',
                    tmpl: this.statusTmpl
                },
                {
                    name: 'groupsOfLinks.linkTitle',
                    sortField: 'LINK_TITLE',
                    tmpl: this.titleTmpl
                },
                {
                    name: 'groupsOfLinks.name',
                    sortField: 'NAME',
                    tmpl: this.nameTmpl
                },
                {
                    name: 'groupsOfLinks.linksName',
                    sortField: 'LINKED_MEDIA_NAME',
                    tmpl: this.linksNameTmpl
                },
                {
                    name: 'groupsOfLinks.date',
                    sortField: 'CREATED',
                    tmpl: this.dateTmpl
                },
                {
                    name: 'groupsOfLinks.clickAmount',
                    sortField: 'CLICK_AMOUNT',
                    tmpl: this.callTmpl
                },
                {
                    name: 'groups.actions',
                    tmpl: this.actionsTmpl,
                    class: 'actions'
                }
            ]
        };
    }

    public search(event: string): void {
        this.searchQuery = event;
        this.table.refreshData({});
    }

    public deleteLink(item: IListOfStreamsLinks): void {
        if (this.isDisable) {
            return;
        }

        this.confirmText = this.confirmTextTpl.replace('{linkName}', item.name);
        const dialogRef = this.dialog.open(this.confirm);
        dialogRef.afterClosed()
            .pipe(takeUntil(this.destroy$))
            .subscribe((confirm: boolean) => {
                this.confirmText = '';
                if (confirm) {
                    this.streamLinksService.deleteStreamLink(item.mediaId, item.linkedMediaId)
                        .pipe(takeUntil(this.destroy$))
                        .subscribe(res => {
                            if (res.success) {
                                this.table.resetTableData();
                                this.table.refreshData({});
                            }
                        });
                }
            });
    }

    onToggle(event, data: IListOfStreamsLinks) {
        this.streamLinksService.saveStreamLink({
            name: data.name,
            otherMediaName: data.linkedMediaName,
            mediaId: data.mediaId,
            newMediaId: data.linkedMediaId,
            otherMediaId: data.linkedMediaId,
            active: event.value
        }).subscribe();
    }

    openModal(data?: any): void {
        if (this.isDisable) {
            return;
        }

        if (!data) {
            data = {
                user: this.user,
                hasOtherData: false
            };
        } else {
            data.user = this.user;
            data.hasOtherData = true;
        }
        const dialogRef = this.dialog.open(StreamLinksModalComponent, {
            panelClass: 'stream-links-modal',
            hasBackdrop: true,
            data: data,
        } as MatDialogConfig);
        dialogRef.afterClosed()
            .pipe(takeUntil(this.destroy$))
            .subscribe((isSave: boolean) => {
                if (isSave) {
                    this.table.resetTableData();
                    this.table.refreshData({});
                }
            });
    }

}
