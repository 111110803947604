<app-resizable-element #resizableElement
                       [title]="title"
                       [cssClass]="'donations'"
                       [hiddenClass]="((donationState.donationActiveState$ | async) && (worldMapState.worldMapOverlayState$ | async) && (worldMapState.worldMapActiveState$ | async)) || !(donationState.donationActiveState$ | async) ? 'donations-hidden' : ''"
                       [embeddedClass]="(donationState.donationOverlayState$ | async) || donationsMode === CHAT_MODE.EMBEDDED ? 'donations-embedded' : ''"
                       [dragPosition]="dragPosition"
                       [fullScreenClass]="(fullScreen$ | async) ? 'fullscreen-donations' : ''"
                       [draggable]="!(donationState.donationOverlayState$ | async)"
                       [resizable]="!(donationState.donationOverlayState$ | async)"
                       [footerHidden]="playerMode === PLAYER_MODE.PUBLISHER || mode === MODE.PAYMENT_CARD_SELECTION"
                       [embedded]="embeddedButton"
                       [close]="closeButton"
                       [bodyHidden]="mode === MODE.PAYMENT_CARD_SELECTION"
                       [minHeight]="320"
                       [minWidth]="320"
                       [bodyOffset]="115"
>
    <ng-container role="body">
        <ano-loader [isOpen]="pending"></ano-loader>
        <div class="messages">
            <div class="message-item" *ngIf="!paidDonations?.length && mode === MODE.PAID">
                <div class="message-content system-message">
                    {{ 'donations.infoMessage' | localize }}
                </div>
            </div>
        </div>
        <ul class="donation-list" #messagesContainer>
            <app-paid-donations *ngIf="mode === MODE.PAID" [data]="paidDonations"></app-paid-donations>
            <app-donation-options *ngIf="mode === MODE.PAY" [data]="donations" [currency]="currency"
                                  (donate$)="initiateDonationPayload($event)"></app-donation-options>
        </ul>
    </ng-container>
    <ng-container role="footer">
        <div class="row row-padding-10">
            <ng-container *ngIf="mode === MODE.PAID">
                <div class="col-auto">
                    <a class="btn btn-success text-white" (click)="mode = MODE.PAY">{{ 'donations.donate' | localize}}</a>
                </div>
                <div class="col">
                    <p class="mb-0">{{ 'donations.support' | localize}}</p>
                </div>
            </ng-container>
            <ng-container *ngIf="mode === MODE.PAY">
                <div class="col-auto">
                    <a class="btn btn-secondary text-white"
                       (click)="mode = MODE.PAID; scrollToBottom(false)">back</a>
                </div>
            </ng-container>
        </div>
    </ng-container>
    <ng-container role="outer">
        <ng-container *ngIf="mode === MODE.PAYMENT_CARD_SELECTION">
            <div class="donations-payment-cards-list">
                <app-payment-cards-list #cardList [addNewCardModalSize]="12"
                                        [isModal]="true"
                                        [useActiveCard]="useActiveCard"
                                        [controls]="paymentCardsListControls"
                                        [donationsMode]="true"
                                        (proceedPayment$)="donate($event)">
                </app-payment-cards-list>
            </div>
        </ng-container>
    </ng-container>

</app-resizable-element>
