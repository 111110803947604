import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IPaymentCard, IPaymentCardsListControls} from '@src/app/components/payment-cards-list/payment-cards-list.component';
import {IHeidelpayPaymentDetails} from '@src/app/components/payment-cards-list/heidelpay-payment/heidelpay-payment.component';
import {PaymentService} from '@src/app/services/payment/payment.service';
import {LoadingService} from 'ui-elements';
import {Router} from '@angular/router';
import {IUserAGB} from '@src/app/pages/user-profile/components/withdrawal-and-conditions/services/withdrawal-and-conditions.service';

@Component({
  selector: 'app-group-payment',
  templateUrl: './group-payment.component.html',
  styleUrls: ['./group-payment.component.scss']
})
export class GroupPaymentComponent implements OnInit {
  @Input() termsConditions: IUserAGB;
  @Input() groupId: string;
  @Input() mediaId: string;
  paymentCardsListControls: IPaymentCardsListControls = {
    payButton: true,
    addNewCard: {
      show: true,
      text: 'payment-cards-list.use-another-card',
      customHandler: (paymentDetails) => this.purchaseGroup(paymentDetails, true)
    }
  };
  @Output() paymentSuccess$: EventEmitter<void> = new EventEmitter<void>();
  @Output() close$: EventEmitter<void> = new EventEmitter<void>();

  constructor(
      public loadingService: LoadingService,
      private router: Router,
      private paymentService: PaymentService,
  ) { }

  ngOnInit(): void {
  }

  purchaseGroup(paymentCard: IHeidelpayPaymentDetails | IPaymentCard, isNewCardPayment = false): void {
    this.paymentService.groupPayment$.emit({paymentCard, isNewCardPayment, groupId: this.groupId});
  }

  close() {
    this.close$.emit();
  }

  // purchaseGroup(paymentCard: IPaymentCard | IHeidelpayCard, heidelpayCard = false): void {
  //   this.loadingService.loadingStart();
  //
  //   const externalCardId = (paymentCard as IPaymentCard)?.id;
  //
  //   const req: IStartPaymentReq = {
  //     groupId: this.groupId,
  //     returnUrl: `https://${window.location.hostname}${this.router.url}`,
  //     successUrl: `https://${window.location.hostname}${this.router.url}`,
  //     failUrl: `https://${window.location.hostname}${this.router.url}`,
  //   };
  //
  //   if (heidelpayCard) {
  //     Object.assign(req, { paymentMethod: {
  //       method: (paymentCard as IHeidelpayCard).method,
  //       resourceId: (paymentCard as IHeidelpayCard).id}
  //     });
  //
  //     if (req.paymentMethod.method === 'card') {
  //       req.paymentMethod.paymentCard = this.paymentService.getNewPaymentCardData(paymentCard as IHeidelpayCard);
  //       req.paymentMethod.saveCard = true;
  //     }
  //     // Object.assign(req, {saveCard: true, paymentCard: this.paymentService.getNewPaymentCardData(paymentCard as IHeidelpayCard)});
  //   } else {
  //     Object.assign(req, { paymentMethod: {
  //         method: (paymentCard as IHeidelpayCard).method,
  //         resourceId: (paymentCard as IPaymentCard)?.externalCardId,
  //         userCardId: externalCardId}
  //     });
  //   }
  //
  //   if (this.streamService.viewerAccessToken) {
  //     req.accessToken = this.streamService.viewerAccessToken;
  //   }
  //
  //   // this.mediaGroupsService.storeGroupPaymentFlag(this.mediaId, this.groupId);
  //
  //   const test = req;
  //   debugger
  //   this.paymentService.getAccessToGroup(req).pipe(take(1)).subscribe(response => {
  //     switch (response?.results?.result?.paymentStatus) {
  //       case PAYMENT_STATUS.FAILED: {
  //         this.paymentService.failedTransactionInfo.next({
  //           failedCardId: response?.results?.result?.externalCardId,
  //           transactionFailReason: response?.results?.result?.reason || PAYMENT_FAIL_MESSAGES.FAILED
  //         });
  //         this.mediaGroupsService.removeGroupPaymentFlag(this.mediaId);
  //         this.loadingService.loadingEnd();
  //         break;
  //       }
  //       case PAYMENT_STATUS.PENDING: {
  //         if (response?.results?.result?.redirectUrl) {
  //           window.location.href = response.results.result.redirectUrl;
  //         }
  //         break;
  //       }
  //       case PAYMENT_STATUS.SUCCESS: {
  //         this.paymentSuccess$.emit();
  //         this.loadingService.loadingEnd();
  //         this.mediaGroupsService.removeGroupPaymentFlag(this.mediaId);
  //         break;
  //       }
  //     }
  //   });
  // }

}
