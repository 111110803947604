import {Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {IFormConfig, StreamService} from '@src/app/services/stream-metadata/stream.service';
import {takeUntil, tap} from 'rxjs/operators';
import {ITableConfig, TableComponent} from 'ui-elements';
import {IOverlay} from '@src/app/models/response.model';
import {MatDialog} from "@angular/material/dialog";
import {LocalizationProvider} from 'ui-elements';
import {environment} from '@src/environments/environment';
import {MEDIA_TYPE} from '@src/app/components/streamdust-player/constants/mediaType';
import {BehaviorSubject} from 'rxjs';
import {STREAM_STATUS} from '@src/app/components/streamdust-player/constants/status';
import {
    IStreamMarketingToolsOverlaysConfig,
    StreamMarketingToolsOverlaysTypes
} from '@src/app/pages/stream-page/components/stream-marketing-tools-overlays/stream-marketing-tools-overlays.component';

@Component({
    selector: 'app-stream-overlays',
    templateUrl: './stream-overlays.component.html',
    styleUrls: ['./stream-overlays.component.scss']
})
export class StreamOverlaysComponent implements OnInit {
    @Input() config: IStreamMarketingToolsOverlaysConfig;

    public STREAM_STATUS = STREAM_STATUS;
    public MEDIA_TYPE = MEDIA_TYPE;
    public mediaType: MEDIA_TYPE;
    public environment = environment;
    public mediaId: string;
    public productType: string;
    public searchQuery: string = '';

    public position = {
        LEFT: 'overlay.position.left',
        RIGHT: 'overlay.position.right',
        BOTTOM: 'overlay.position.bottom'
    };

    public configLoaded = false;
    private overlaysType: StreamMarketingToolsOverlaysTypes;
    private formConfig: IFormConfig;

    constructor(
        private readonly destroy$: AutoDestroyService,
        private readonly activatedRoute: ActivatedRoute,
        public streamService: StreamService,
        public dialog: MatDialog,
        private router: Router,
        private localizationProvider: LocalizationProvider
    ) {
    }

    ngOnInit(): void {
        this.formConfig = this.streamService.getStreamFormConfig(false, false, MEDIA_TYPE.STREAM, true);
        this.overlaysType = (this.activatedRoute.data as BehaviorSubject<any>)?.value?.overlaysType;

        this.activatedRoute.parent.params.pipe(
            takeUntil(this.destroy$),
            tap(({id}: Params) => {
                this.mediaId = id;
                this.mediaType = this.resolveMediaType();
            }))
            .subscribe(() => {
            });
    }

    private resolveMediaType(): MEDIA_TYPE {
        return (this.activatedRoute.data as BehaviorSubject<any>)?.value?.mediaType;
    }

    public click(ev): void {
        ev.preventDefault();
    }
}
