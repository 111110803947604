import {Component, Input, OnInit} from '@angular/core';
import {IStreamMarketingToolsOverlaysConfig} from '../../../stream-marketing-tools-overlays/stream-marketing-tools-overlays.component';
import {MEDIA_TYPE} from '@src/app/components/streamdust-player/constants/mediaType';
import {IStreamModel} from '@src/app/models/stream.model';
import {ActivatedRoute} from '@angular/router';
import {takeUntil} from 'rxjs/operators';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import {TAB} from '@src/app/pages/stream-page/components/media-overview/components/quick-access/tabs';
import {Location} from '@angular/common';

@Component({
  selector: 'app-quick-access',
  templateUrl: './quick-access.component.html',
  styleUrls: ['./quick-access.component.sass'],
  providers: [AutoDestroyService]
})
export class QuickAccessComponent implements OnInit {
  public overlayConfig: IStreamMarketingToolsOverlaysConfig;
  @Input() mediaType: MEDIA_TYPE;
  @Input() mediaId: string;
  @Input() media: IStreamModel;
  public quickLinkTmpl: string;
  public activeTab: TABS = TABS.DESCRIPTION;
  public TABS: typeof TABS = TABS;

  constructor(
      private activatedRoute: ActivatedRoute,
      private destroy$: AutoDestroyService,
      private location: Location,
  ) { }

  ngOnInit(): void {
    this.overlayConfig = {
      title: 'overlays.title',
      subtitle: 'overlays.subtitle',
      tableTitle: 'overlays.table.title',
      description: false,
      link: true,
      newRoute: 'overlays',
      dataField: 'overlays',
      apiField: 'overlay',
      hideSearch: true,
      hideCreateButton: true
    };
    this.quickLinkTmpl = `${this.mediaType === MEDIA_TYPE.STREAM ? 'streams' : 'vod'}/${this.mediaId}/`;
    this.activatedRoute.queryParams.pipe(takeUntil(this.destroy$)).subscribe(params => {
      if (!params) {
        return;
      }

      if (params.hasOwnProperty(TAB.OVERLAYS)) {
        document.getElementById('overlays-tab').click();
      }

      if (params.hasOwnProperty(TAB.SURVEYS)) {
        document.getElementById('survey-tab').click();
      }

      this.location.replaceState(this.location.path().split('?')[0], '');
    });
  }

  public switchTab(tab: TABS): void {
    this.activeTab = tab;
  }

}


enum TABS {
  DESCRIPTION,
  OVERLAYS,
  SURVEYS,
  PRESENTATIONS,
  PRE_MOD,
  VIDEO_IN_CALLS,
  VISIBILITY
}
