<section class="section section-dashboard">
    <div class="container">
        <div class="wrapper-content bg-light">
            <div class="row row-padding-10">
                <div class="col-4">
                    <div class="form-control">
                        <ano-dropdown
                                [values]="[{key: 'stream', value: 'Livestream'}, {key: 'vod', value: 'VoD'}]"
                                [placeholder]="'Select media type'"
                                [isEditMode]="true"
                                (change)="mediaType = $event"
                        ></ano-dropdown>
                    </div>
                    <div class="form-control">
                        <ano-input #input
                                   [isEditMode]="true"
                                   [config]="{ inputType: INPUT_TYPES.INPUT, placeholder: 'Media ID'}"
                                   [isCleanable]="true" (input$)="mediaId = $event">
                        </ano-input>
                    </div>
                    <div class="form-control">
                        <ano-button
                                    [config]="{
                                        type: 'submit',
                                        styleType: 'success'
                                        }" (click)="onLoad()">
                            {{ 'Load' }}
                        </ano-button>
                    </div>
                </div>
                <div class="col-8">
                    <div class="content section-panel">
                        <div class="iframe-container bg-white">
                            <iframe *ngIf="url" id="streamdust-58014f79" [src]="url" width="640" height="360" frameborder=0></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
