import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {BillsPageComponent} from "@src/app/pages/bills-page/bills-page.component";
import {SharedModule} from "@src/app/modules/shared/shared.module";
import { BillsTableComponent } from './components/bills-table/bills-table.component';



@NgModule({
  declarations: [BillsPageComponent, BillsTableComponent],
  imports: [
    CommonModule,
      SharedModule
  ]
})
export class BillsPageModule { }
