import { Pipe, PipeTransform } from '@angular/core';
import {cutString} from '@src/app/utils/string.util';


@Pipe({
    name: 'transaction'
})
export class TransactionPipe implements PipeTransform {

    transform(value: string, ...args: unknown[]): string {
        if (!value) {
            return;
        }
        return value.split('_')[0].slice(-8);
    }

}
