import {
    APP_INITIALIZER,
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule, NO_ERRORS_SCHEMA
} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from '@src/app/modules/app-routing/app-routing.module';
import {AppComponent} from '@src/app/app.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {SharedModule} from '@src/app/modules/shared/shared.module';
import {LoginPageModule} from '@src/app/pages/login-page/login-page.module';
import {AuthInterceptor} from '@src/app/interceptors/auth.interceptor';
import {DEFAULT_TIMEOUT, ErrorInterceptor} from '@src/app/interceptors/error.interceptor';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RegistrationPageModule} from '@src/app/pages/registration-page/registration-page.module';
import {EmailConfirmationPageModule} from '@src/app/pages/email-confirmation-page/email-confirmation-page.module';
import {PasswordForgottenPageModule} from '@src/app/pages/password-forgotten-page/password-forgotten-page.module';
import {ChangePasswordPageModule} from '@src/app/pages/change-password-page/change-password-page.module';
import {TimeagoModule} from 'ngx-timeago';
import {HeaderRoundComponent} from '@src/app/components/header-round/header-round.component';
import {HeaderService} from '@src/app/services/header/header.service';
import {NgxMaskModule} from 'ngx-mask';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import {AccessDeniedPageModule} from './pages/access-denied-page/access-denied-page.module';
import {IMaskModule} from 'angular-imask';
import {DashboardPageModule} from '@src/app/pages/dashboard-page/dashboard-page.module';
import {StreamPageModule} from '@src/app/pages/stream-page/stream-page.module';
import {CouponsModalComponent} from '@src/app/pages/stream-page/components/stream-coupons/coupons-modal/coupons-modal.component';
import {UserProfileModule} from '@src/app/pages/user-profile/user-profile.module';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {RecordPageModule} from '@src/app/pages/record-page/record-page.module';
import {AngularIbanModule} from 'angular-iban';
import {ContentWrapperComponent} from './pages/content-wrapper/content-wrapper.component';
import {PublicMediaPageModule} from '@src/app/pages/public-media-page/public-media-page.module';
import {EmbeddedStreamPageModule} from '@src/app/pages/embedded-stream-page/embedded-stream-page.module';
import {MockedPageComponent} from './components/mocked-page/mocked-page.component';
import {NewStreamPageComponent} from './pages/new-stream-page/new-stream-page.component';
import {GroupsPageModule} from '@src/app/pages/groups-page/groups-page.module';
import {VideoOnDemandPageModule} from '@src/app/pages/video-on-demand-page/video-on-demand-page.module';
import {LandingPageModule} from '@src/app/pages/landing-page/landing-page.module';
import {ModalContainerComponent} from './components/modal-container/modal-container.component';
import {GroupModalContainerComponent} from '@src/app/pages/groups-page/components/group-modal-container/group-modal-container.component';
import {WebSocketService} from '@src/app/services/web-socket/web-socket.service';
import {BillsPageModule} from '@src/app/pages/bills-page/bills-page.module';
import {StreamsWrapperComponent} from './pages/streams-wrapper/streams-wrapper.component';
import {PaymentStartModule} from '@src/app/pages/payment-start-page/payment-start.module';
import {PaymentDetailsPageModule} from '@src/app/pages/payment-details-page/payment-details-page.module';
import {PublicGroupPageModule} from '@src/app/pages/public-group-page/public-group-page.module';
import {RevenuesPageModule} from '@src/app/pages/revenues-page/revenues-page.module';
import {SubaccountsPageModule} from '@src/app/pages/subaccounts-page/subaccounts-page.module';
import {EventSitePageModule} from '@src/app/pages/event-site-page/event-site-page.module';
import {ConnectedPublishersPageModule} from '@src/app/pages/connected-publishers-page/connected-publishers-page.module';
import {TestPageModule} from '@src/app/pages/test-page/test-page.module';
import {AgmCoreModule, GoogleMapsAPIWrapper} from '@agm/core';
import {environment} from '@src/environments/environment';
import {AgmOverlays} from 'agm-overlays';
import {
    localizationProviderFactory,
    API_URLS,
    ENVIRONMENT,
    LocalizationProvider,
    CmsService,
    CURRENT_CURRENCY
} from 'ui-elements';
import {API_URLS_APP} from '@src/app/constants/api-urls.constant';
import {MainDashboardPageModule} from '@src/app/pages/main-dashboard-page/main-dashboard-page.module';
import {currentCurrency} from '@src/app/providers/currentCurrency';
import '@angular/common/locales/global/de';
import '@angular/common/locales/global/en';
import {MatIconModule} from '@angular/material/icon';
import {ForbiddenPageModule} from '@src/app/pages/forbidden-page/forbidden-page.module';
import {NotFoundPageModule} from '@src/app/pages/not-found-page/not-found-page.module';
import {IframeTestModule} from '@src/app/pages/iframe-test/iframe-test.module';
import {SlotsModalComponent} from '@src/app/components/streamdust-player/slots-modal/slots-modal.component';
import {ChatComponent} from '@src/app/components/chat/chat.component';
import {LinkedStreamsModalComponent} from '@src/app/components/streamdust-player/linked-streams-modal/linked-streams-modal.component';
import {GroupsModalComponent} from '@src/app/components/streamdust-player/groups-modal/groups-modal.component';
import {PdfViewerModalComponent} from '@src/app/components/pdf-viewer-modal/pdf-viewer-modal.component';
import {DonationsComponent} from '@src/app/components/donations/donations.component';
import {StreamWorldMapComponent} from '@src/app/components/stream-world-map/stream-world-map.component';
import {ViewerSurveysComponent} from '@src/app/components/viewer-surveys/viewer-surveys.component';
import {InfoLineComponent} from '@src/app/components/info-line/info-line.component';
import {MediaPreviewPageModule} from '@src/app/pages/media-preview-page/media-preview-page.module';
import { HeaderComponent } from './components/header/header.component';
import {ChatPageModule} from '@src/app/pages/chat-page/chat-page.module';

@NgModule({
    entryComponents: [
        CouponsModalComponent,
        ModalContainerComponent,
        GroupModalContainerComponent,
        SlotsModalComponent,
        ChatComponent,
        LinkedStreamsModalComponent,
        GroupsModalComponent,
        PdfViewerModalComponent,
        DonationsComponent,
        StreamWorldMapComponent,
        ViewerSurveysComponent,
        InfoLineComponent
    ],
    declarations: [
        AppComponent,
        HeaderRoundComponent,
        ContentWrapperComponent,
        MockedPageComponent,
        NewStreamPageComponent,
        ModalContainerComponent,
        StreamsWrapperComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        SharedModule,
        LoginPageModule,
        RegistrationPageModule,
        // MaterialModule,
        EmailConfirmationPageModule,
        PasswordForgottenPageModule,
        ChangePasswordPageModule,
        TimeagoModule.forRoot(),
        MatDialogModule,
        MatIconModule,
        MatFormFieldModule,
        MatInputModule,
        NgxMaskModule.forRoot(),
        AccessDeniedPageModule,
        IMaskModule,
        DashboardPageModule,
        StreamPageModule,
        UserProfileModule,
        MatSnackBarModule,
        RecordPageModule,
        AngularIbanModule,
        PublicMediaPageModule,
        ChatPageModule,
        EmbeddedStreamPageModule,
        GroupsPageModule,
        VideoOnDemandPageModule,
        LandingPageModule,
        BillsPageModule,
        PaymentStartModule,
        PaymentDetailsPageModule,
        PublicGroupPageModule,
        RevenuesPageModule,
        SubaccountsPageModule,
        EventSitePageModule,
        ConnectedPublishersPageModule,
        TestPageModule,
        AgmOverlays,
        AgmCoreModule.forRoot({
            apiKey: environment.googleApiKey
        }),
        MainDashboardPageModule,
        ForbiddenPageModule,
        NotFoundPageModule,
        IframeTestModule,
        MediaPreviewPageModule
    ],
    providers: [
        {
            provide: API_URLS,
            useValue: API_URLS_APP
        },
        {
            provide: CURRENT_CURRENCY,
            useValue: currentCurrency
        },
        {
            provide: ENVIRONMENT,
            useValue: environment
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true
        },
        {
            provide: MatDialogRef,
            useValue: {}
        },
        {
            provide: MAT_DIALOG_DATA,
            useValue: {}
        },
        LocalizationProvider,
        {
            provide: APP_INITIALIZER,
            useFactory: localizationProviderFactory,
            deps: [LocalizationProvider, CmsService],
            multi: true
        },
        {provide: DEFAULT_TIMEOUT, useValue: 20000},
        AutoDestroyService,
        HeaderService,
        WebSocketService,
        GoogleMapsAPIWrapper
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class AppModule {
}

