import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConnectedPublishersPageComponent } from './connected-publishers-page.component';
import {SharedModule} from '@src/app/modules/shared/shared.module';



@NgModule({
  declarations: [ConnectedPublishersPageComponent],
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class ConnectedPublishersPageModule { }
