import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ITableConfig, ITimeRangeConfig, IUnitKeyValue, TableComponent, INPUT_TYPES} from 'ui-elements';
import {IRevenue} from '@src/app/models/response.model';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import {ActivatedRoute, Params} from '@angular/router';
import {StreamService} from '@src/app/services/stream-metadata/stream.service';
import {MatDialog} from '@angular/material/dialog';
import {switchMap, take, takeUntil, tap} from 'rxjs/operators';
import {LoadingService} from '@src/app/services/loading/loading.service';
import {ISlotModel, IStreamModel, IStreamRevenuesTotal, ITimerange} from '@src/app/models/stream.model';
import * as moment from 'moment';
import * as jspdf from 'jspdf';
import {environment} from '@src/environments/environment';
import {LocalizationProvider} from 'ui-elements';
import {MEDIA_TYPE} from '@src/app/components/streamdust-player/constants/mediaType';
import {BehaviorSubject} from 'rxjs';
import {CURRENCY_SYMBOLS} from '@src/app/components/streamdust-player/media-access-modal/media-access-modal.component';
import {getFileNameFromResponseContentDisposition} from '@src/app/utils/file-name-from-content-disposition.util';
import {saveAs} from 'file-saver/FileSaver';
import {CountryRevenuesComponent} from '@src/app/pages/stream-page/components/stream-revenue/components/country-revenues/country-revenues.component';
import {IPaymentsModel} from '@src/app/pages/stream-page/components/stream-metadata/stream-metadata.component';
import {FormBuilder} from '@angular/forms';
import {IListFilterItem} from 'ui-elements/lib/types/types';

@Component({
    selector: 'app-stream-revenue',
    templateUrl: './stream-revenue.component.html',
    styleUrls: ['./stream-revenue.component.scss']
})
export class StreamRevenueComponent implements OnInit {
    @ViewChild('countryRevenuesComponent', {static: true}) public countryRevenuesComponent: CountryRevenuesComponent;
    @ViewChild('tableComponent', {static: true}) public table: TableComponent;
    @ViewChild('actionsTemplate', {static: true}) public actionsTemplate: TemplateRef<any>;
    @ViewChild('transactionTmpl', {static: true}) public transactionTmpl: TemplateRef<any>;
    @ViewChild('billTmpl', {static: true}) public billTmpl: TemplateRef<any>;
    @ViewChild('invoiceTmpl', {static: true}) public invoiceTmpl: TemplateRef<any>;
    @ViewChild('nameTmpl', {static: true}) public nameTmpl: TemplateRef<any>;
    @ViewChild('totalCostTmpl', {static: true}) public totalCostTmpl: TemplateRef<any>;
    public mediaType: MEDIA_TYPE;

    searchQuery = '';
    mediaId: string;
    revenueCurrency: 'EUR' | 'USD';
    downloadButtonText = 'stream.revenue.individual-transactions-table.download-pdf';
    language: string;

    isPDFLoading = false;
    isZIPLoading = false;
    isTransactionPDFLoading = false;
    public pdfDownloadCurrentId: string;

    tableConfig: ITableConfig<IRevenue>;
    streamMetadata: IStreamModel;
    streamRevenuesTotal: IStreamRevenuesTotal;
    public environment = environment;
    streamInfoTable: IInfoTable[];
    streamRevenuesTotalTable: IInfoTable[];
    public timeRange: ITimerange;
    public timeRangeConfig: ITimeRangeConfig;
    public isCurrentMonth = true;
    overviewForm = this.formBuilder.group({selectOverview: [0]});
    overviewSelectOptions: IUnitKeyValue[] = [{key: 0, value: this.localize('stream.revenue.overview.general')}];
    INPUT_TYPES = INPUT_TYPES;
    public filters: IListFilterItem[];
    public slotMetadata: ISlotModel;

    constructor(
        private readonly destroy$: AutoDestroyService,
        private readonly activatedRoute: ActivatedRoute,
        private streamService: StreamService,
        public loadingService: LoadingService,
        public dialog: MatDialog,
        private localizationProvider: LocalizationProvider,
        private formBuilder: FormBuilder,
    ) {
        this.overviewForm.get('selectOverview').valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((overview) => this.slotsFilter(overview));
    }

    ngOnInit(): void {
        this.language = localStorage.getItem('localLanguage');

        this.resolveDefaultTimeRange();
        this.setIsCurrentMonth();
        this.activatedRoute.parent.params.pipe(
            takeUntil(this.destroy$),
            tap(({id}: Params) => {
                this.mediaId = id;
                this.mediaType = this.resolveMediaType();
                this.setupTransactionsTable();
            }),
            switchMap(({id}: Params) => {
                this.loadingService.loadingStart();
                return this.streamService.getRevenuesTotal(this.timeRange, this.mediaType, this.mediaId);
            }),
            tap((res) => {
                if (!res) {
                    return;
                }
                this.streamRevenuesTotal = res;
                this.streamRevenuesTotalTable = this.getStreamTotalRevenueTable();
            }),
            switchMap(() => this.streamService.stream$))
            .subscribe((res) => {
                if (!res) {
                    this.loadingService.loadingEnd();
                    return;
                }
                this.formatOverviewOptions(res.programSettings.slots);
                this.parseStreamData(res);
                this.streamMetadata = res;
                this.streamInfoTable = this.getStreamInfoTable();
                this.loadingService.loadingEnd();
            });
    }

    private resolveDefaultTimeRange(): void {
        this.timeRange = {
            start: +moment().utc().startOf('month').format('x'),
            end: +moment().utc().endOf('month').format('x')
        };

    }

    private setIsCurrentMonth(): void {
        this.isCurrentMonth = new Date(this.timeRange.start).getMonth() === new Date().getMonth();
    }

    private resolveMediaType(): MEDIA_TYPE {
        return (this.activatedRoute.data as BehaviorSubject<any>)?.value?.mediaType;
    }

    localize(key) {
        return this.localizationProvider.getByKey(key);
    }

    formatStreamTime(startTime, endTime) {
        return `${moment(startTime).format('HH:mm')} ${this.localize('stream.revenue.date-hours')} ${this.localize('stream.revenue.date-to')} ${moment(endTime).format('HH:mm')} ${this.localize('stream.revenue.date-hours')}`;
    }

    formatStreamDate(startTime, endTime) {
        return `${moment(startTime).format('DD.MM.yyyy')} ${this.localize('stream.revenue.date-to')} ${moment(endTime).format('DD.MM.yyyy')}`;
    }

    wrapPictureUrl(pictureRoute) {
        return environment.backendApiHost + pictureRoute;
    }

    parseStreamData(streamMetadata: any): void {
        if (this.slotMetadata) {
            streamMetadata.media.time = moment('2000-01-01 00:00:00').add(moment.duration(streamMetadata.media.endTimestamp - streamMetadata.media.startTimestamp)).format('HH:mm:ss');
            streamMetadata.media.formattedStartDate = moment('2000-01-01 00:00:00').add(moment.duration(streamMetadata.media.startTimestamp)).format('HH:mm:ss');
            streamMetadata.media.streamAccess = this.resolveAccessTypesSlots(streamMetadata.media.slotPayment);
        } else {
            streamMetadata.media.time = streamMetadata?.media?.productType === MEDIA_TYPE.VIDEO_ON_DEMAND ? moment(streamMetadata.media.video.updated).format('HH:mm').toString() : this.formatStreamTime(streamMetadata.media.startTimestamp, streamMetadata.media.endTimestamp);
            streamMetadata.media.formattedStartDate = moment(streamMetadata?.media?.productType === MEDIA_TYPE.VIDEO_ON_DEMAND ? streamMetadata.media.video.updated : streamMetadata.media.startTimestamp).format('DD.MM.yyyy');
            streamMetadata.media.streamAccess = this.resolveAccessTypes(streamMetadata.media.paymentSystem);
        }
        streamMetadata.media.date = moment(this.timeRange.start).format('MMMM YYYY');
        streamMetadata.media.currency = this.revenueCurrency = CURRENCY_SYMBOLS[streamMetadata.media.price.currency];
        streamMetadata.media.singleTicketPrice = `${streamMetadata.media.price.amount} ${CURRENCY_SYMBOLS[streamMetadata.media.price.currency]}`;
        streamMetadata.media.totalTicketsSold = this.streamRevenuesTotal.purchasesAmount;
        streamMetadata.previewPicture = streamMetadata.previewPicture && streamMetadata.previewPicture.photoUrl ?
            this.wrapPictureUrl(streamMetadata.previewPicture.photoUrl) : '';
    }

    private resolveAccessTypes(paymentSystem: IPaymentsModel): string {
        let res = '';
        if (paymentSystem.psPayPerView) {
            res += this.localizationProvider.getByKey('stream.payments.payView.name');
        }

        if (paymentSystem.psSlot) {
            if (res) {
                res += ', ';
            }
            res += this.localizationProvider.getByKey('stream.payments.slots.name');
        }

        if (paymentSystem.psVoucher) {
            if (res) {
                res += ', ';
            }
            res += this.localizationProvider.getByKey('stream.payments.voucher.name');
        }
        if (paymentSystem.psLeadGeneration) {
            if (res) {
                res += ', ';
            }
            res += this.localizationProvider.getByKey('stream.payments.leadGeneration.name');
        }

        if (paymentSystem.paymentSystemGroup === 'FREE_ACCESS') {
            res = this.localizationProvider.getByKey('stream.payment-types.free');
        }

        return res;

    }

    getStreamInfoTable(): IInfoTable[] {
        return [
            {
                title: this.localize('stream.recording.info.livestream-title'),
                property: 'title'
            },
            {
                title: this.localize('stream.revenue.date-of-broadcast'),
                property: 'formattedStartDate'
            },
            {
                title: this.localize('stream.revenue.duration'),
                property: 'time'
            },
            {
                title: this.localize('stream.revenue.billiing-cycle'),
                property: 'date'
            },
            {
                title: this.localize('stream.revenue.access'),
                property: 'streamAccess'
            },
            {
                title: this.localize('stream.revenue.price'),
                property: 'singleTicketPrice'
            },
            {
                title: this.localize('stream.revenue.number-of-tickets'),
                property: 'totalTicketsSold'
            }
        ];
    }

    getSlotInfoTable(): IInfoTable[] {
        return [
            {
                title: this.localize('stream.revenue.slot-title'),
                property: 'title'
            },
            {
                title: this.localize('stream.revenue.time-start'),
                property: 'formattedStartDate'
            },
            {
                title: this.localize('stream.revenue.duration'),
                property: 'time'
            },
            {
                title: this.localize('stream.revenue.billiing-cycle'),
                property: 'date'
            },
            {
                title: this.localize('stream.revenue.access-slot'),
                property: 'streamAccess'
            },
            {
                title: this.localize('stream.revenue.price'),
                property: 'singleTicketPrice'
            },
            {
                title: this.localize('stream.revenue.number-of-tickets'),
                property: 'totalTicketsSold'
            }
        ];
    }

    getStreamTotalRevenueTable(): IInfoTable[] {
        return [
            {
                title: this.localize('stream.revenue.streamdust-fee'),
                property: 'transactionFeeSumNet'
            },
            {
                title: this.localize('stream.revenue.streamdust-comission'),
                property: 'streamdustFeeSumNet'
            },
            {
                title: `${this.localize('stream.revenue.streamdust-vat')} ${this.streamRevenuesTotal.feeVatPercent || 0}%`,
                property: 'totalFeesVat'
            },
            {
                title: this.localize('stream.revenue.streamdust-total-gross'),
                property: 'totalFeesGross'
            },
            {
                title: this.localize('stream.revenue.streamdust-withdrawal'),
                property: 'withdrawalAmount'
            }
        ];
    }

    setupTransactionsTable(): void {
        this.tableConfig = {
            filtersWidth100: true,
            dataField: 'data',
            matPaginator: true,
            searchFn: (sortParams, pagingParams) => {
                return this.streamService.getRevenuesList(this.timeRange, this.mediaType, this.mediaId, this.searchQuery, pagingParams, sortParams, this.filters);
            },
            columns: [
                {
                    name: this.localize('stream.revenue.individual-transactions-table.date'),
                    sortField: 'DATE',
                    dataField: 'created',
                    date: true,
                    class: 'left'
                },
                {
                    name: this.localize('stream.revenue.individual-transactions-table.name'),
                    sortField: 'FIRST_NAME',
                    tmpl: this.nameTmpl,
                    class: 'left'
                },
                // {
                //     name: 'LAND',
                //     dataField: 'country'
                // },
                {
                    name: this.localize('stream.revenue.individual-transactions-table.email'),
                    dataField: 'email',
                    // tmpl: this.customFieldNameTmlp,
                    class: 'left'
                },
                {
                    name: this.localize('stream.revenue.individual-transactions-table.custom-field-name'),
                    dataField: 'customFieldName',
                    // tmpl: this.customFieldNameTmlp,
                    class: 'left'
                },
                {
                    name: this.localize('stream.revenue.individual-transactions-table.custom-field-value'),
                    dataField: 'customFieldValue',
                    // tmpl: this.customFieldValueTmlp,
                    class: 'left'
                },
                // {
                //     name: this.localize('stream.revenue.individual-transactions-table.transaction-number'),
                //     // dataField: 'transactionId',
                //     tmpl: this.transactionTmpl,
                //     class: 'left'
                // },
                // {
                //     name: this.localize('stream.revenue.individual-transactions-table.bill-number'),
                //     // dataField: 'billNumber'
                //     tmpl: this.billTmpl,
                //     class: 'left'
                // },
                {
                    name: this.localize('stream.revenue.individual-transactions-table.invoice-number'),
                    tmpl: this.invoiceTmpl,
                    class: 'left'
                },
                {
                    name: this.localize('stream.revenue.individual-transactions-table.invoice-amount'),
                    tmpl: this.totalCostTmpl,
                    class: 'right'
                },
                {
                    name: this.localize('stream.revenue.individual-transactions-table.action'),
                    tmpl: this.actionsTemplate,
                    class: 'center'
                },
            ]
        };
    }

    formatRevenue(value) {
        return `${this.revenueCurrency} ${value ? value?.toFixed(2) : '0.00'}`;
    }

    downloadPaymentTable() {
        if (this.isZIPLoading) {
            return;
        }
        this.isZIPLoading = true;

        this.streamService.downloadRevenuesZIP(this.timeRange, this.mediaType, this.mediaId)
            .pipe(takeUntil(this.destroy$))
            .subscribe(res => {
                this.isZIPLoading = false;
                saveAs(
                    res.body,
                    getFileNameFromResponseContentDisposition(res)
                );
            });
    }

    downloadPaymentData(id: string) {
        if (this.isTransactionPDFLoading) {
            return;
        }
        this.isTransactionPDFLoading = true;
        this.pdfDownloadCurrentId = id;

        this.streamService.downloadTransactionPDF(id)
            .pipe(takeUntil(this.destroy$))
            .subscribe(res => {
                this.pdfLoadEnd();
                saveAs(
                    res.body,
                    getFileNameFromResponseContentDisposition(res)
                );
            }, () => this.pdfLoadEnd());
    }

    downloadRevenuesTotalData() {
        if (this.isPDFLoading) {
            return;
        }

        this.isPDFLoading = true;

        this.streamService.downloadRevenuesPDF(this.timeRange, this.mediaType, this.mediaId)
            .pipe(takeUntil(this.destroy$))
            .subscribe(res => {
                this.isPDFLoading = false;
                saveAs(
                    res.body,
                    getFileNameFromResponseContentDisposition(res)
                );
            });


        // this.isPDFLoading = true;
        //
        // const revenuesTotalDataTable = document.getElementById('revenuesTotalDataTable');
        //
        // htmlToImage.toPng(revenuesTotalDataTable).then((dataUrl) => {
        //     const imgWidth = 190;
        //     const revenuesImage = new Image();
        //     revenuesImage.src = dataUrl;
        //     revenuesImage.onload = () => {
        //         const imgHeight = revenuesImage.height * imgWidth / revenuesImage.width;
        //         let pdf = new jspdf('p', 'mm', 'a4', false); // A4 size page of PDF
        //         const position = 10;
        //         pdf.addImage(dataUrl, 'PNG', 10, position, imgWidth, imgHeight)
        //         pdf.save(`stream-${this.streamMetadata.media.title}-einnahmen-abzüglich.pdf`);
        //         this.isPDFLoading = false;
        //     };
        // }).catch((error) => {
        //     this.isPDFLoading = false;
        // });
    }

    public search(event: string): void {
        this.searchQuery = event;
        this.table.refreshData({});
    }

    private pdfLoadEnd(): void {
        this.isTransactionPDFLoading = false;
        this.pdfDownloadCurrentId = null;
    }

    public timeRangeFilter(event): void {
        this.timeRange = event;
        console.log('timeRange', this.timeRange);
        this.setIsCurrentMonth();
        this.updateData();
        this.table.refreshData({});
        this.countryRevenuesComponent.refreshTable({});
    }

    public slotsFilter(overview): void {
        this.filters = overview ? [{field: 'SLOT_ID', value: overview}] : [];
        this.streamInfoTable = overview ? this.getSlotInfoTable() : this.getStreamInfoTable();
        this.slotMetadata = overview ? {media: this.streamMetadata.programSettings.slots.find(slot => slot.id === overview)} : null;
        this.countryRevenuesComponent.refreshTable(this.filters);
        this.table.refreshData({});
        this.updateData(this.filters);
    }

    public resolveAccessTypesSlots(accessType) {
        switch (accessType) {
            case 'FREE':
                return this.localizationProvider.getByKey('stream.payment-types.free');
            case 'PAY_PER_VIEW':
                return this.localizationProvider.getByKey('stream.payments.payView.name');
        }
    }

    public updateData(filters?): void {
        this.loadingService.loadingStart();
        this.streamService.getRevenuesTotal(this.timeRange, this.mediaType, this.mediaId, filters)
            .pipe(take(1))
            .subscribe((res) => {
                if (!res) {
                    this.loadingService.loadingEnd();
                }
                this.streamRevenuesTotal = res;
                this.parseStreamData(this.slotMetadata ? this.slotMetadata : this.streamMetadata);
                this.loadingService.loadingEnd();
            });
    }

    public formatOverviewOptions(options) {
        options.forEach(option => {
            this.overviewSelectOptions.push({key: option.id, value: option.title});
        });
    }
}

export interface IInfoTable {
    title: string;
    property: string;
    isLink?: boolean;
}
