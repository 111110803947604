<div #resizableContainer class="chat {{cssClass}} {{hiddenClass}} {{embeddedClass}} {{fullScreenClass}} {{fullScreenDisabledClass}} {{chatOpenedClass}}"
     ngResizable [rzHandles]="resizable ? resizeDirection : null"
     [rzMinWidth]="minWidth"
     [rzMinHeight]="minHeight"
     (rzResizing)="onResize($event)"
     (rzStop)="onResizeEnd($event)"
     ngDraggable="draggable" [handle]="dragElement" [position]="{x: dragPosition.x, y: dragPosition.y, rotate: 0, scaleX: 1, scaleY: 1}" [bounds]="bounds" [inBounds]="true"
     (stopped)="onDragEnd()"
>
    <div class="chat-header" #dragElement>
        <div class="chat-header-user">
            <h3 class="title" [style.maxWidth.px]="width - 100" >
                <a href="/" target="_blank" class="icon-main">
                    <img src="/assets/img/ico/ico-streamdust.png" alt=""/>
                </a>
                {{title}}
            </h3>
        </div>
        <div *ngIf="!isChatPage" class="chat-header-action">
            <ul class="action-list">
                <li *ngIf="chatPageLink"><a [href]="chatPageLink" target="_blank" class="icon-new-window btn navigation-button"></a></li>
                <li *ngIf="fullscreen?.show"><button class="icon-resize btn navigation-button" (click)="fullscreen.callback()"></button></li>
                <li *ngIf="embedded?.show"><button class="icon-resize-min btn navigation-button" (click)="embedded.callback()"></button></li>
                <li><button class="icon-close btn navigation-button" (click)="close?.callback()"></button></li>
            </ul>
        </div>
    </div>
    <div *ngIf="!bodyHidden" class="chat-body {{fullscreenBodyClass}}" [class.footer-hidden]="footerHidden" #scrollingContainer [id]="id" [style.height.px]="height - (footerHidden ? 38 : bodyOffset)" [style.width.px]="width">
        <ng-content select="[role=body]"></ng-content>
    </div>
    <div *ngIf="!footerHidden" class="chat-footer">
        <ng-content select="[role=footer]"></ng-content>
    </div>
    <ng-content role="outer"></ng-content>
</div>
