import { Injectable } from "@angular/core";

const myScripts = [
  {
    name: "CookieDeclaration",
    src: "https://consent.cookiebot.com/uc.js",
    dataCbid: "ceec2797-325d-49aa-a307-534056df5cb8",
    dataBlockingmode: "auto",
  },
];
  
@Injectable()
export class ScriptService {
  
    private scripts: any = {};
  
    constructor() {
      myScripts.forEach((script: any) => {
        this.scripts[script.name] = {
          loaded: false,
          src: script.src,
          dataCbid: script.dataCbid,
          dataBlockingmode: script.dataBlockingmode,
        };
      });
    }
  
    // load a single or multiple scripts
    load(...scripts: string[]) {
      const promises: any[] = [];
      // push the returned promise of each loadScript call 
      scripts.forEach((script) => promises.push(this.loadScript(script)));
      // return promise.all that resolves when all promises are resolved
      return Promise.all(promises);
    }
    
    // load the script
    loadScript(name: string) {
      return new Promise((resolve, reject) => {
        // resolve if already loaded
        if (this.scripts[name].loaded) {
          resolve({script: name, loaded: true, status: 'Already Loaded'});
        } else {
          // load script
          const script = document.createElement('script');
          script.type = 'text/javascript';
          script.async = true;
          script.id = 'CookieDeclaration';
          script.src = this.scripts[name].src;
          script.setAttribute("data-cbid", this.scripts[name].dataCbid);
          script.setAttribute(
            "data-blockingmode",
            this.scripts[name].dataBlockingmode
          );
          // cross browser handling of onLoaded event
          if ((script as any).readyState) {  // IE
            (script as any).onreadystatechange = () => {
              if ((script as any).readyState === 'loaded' || (script as any).readyState === 'complete') {
                (script as any).onreadystatechange = null;
                this.scripts[name].loaded = true;
                resolve({script: name, loaded: true, status: 'Loaded'});
              }
            };
          } else {  // Others
            script.onload = () => {
              this.scripts[name].loaded = true;
              resolve({script: name, loaded: true, status: 'Loaded'});
            };
          }
          script.onerror = (error: any) => resolve({script: name, loaded: false, status: 'Loaded'});
          // finally append the script tag in the DOM
          document.getElementsByTagName('head')[0].prepend(script);
        }
      });
    }
  
  }