import {Component, Input, OnInit} from '@angular/core';
import {StreamService} from '@src/app/services/stream-metadata/stream.service';
import {IValueUnit} from 'ui-elements';
import { STREAM_STATUS } from '@src/app/components/streamdust-player/constants/status';

@Component({
  selector: 'app-stream-info',
  templateUrl: './stream-info.component.html',
  styleUrls: ['./stream-info.component.scss']
})
export class StreamInfoComponent implements OnInit {
  @Input() data: IStreamData;

  STREAM_STATUS = STREAM_STATUS;
  @Input() status: STREAM_STATUS;

  constructor(
      private streamService: StreamService
  ) { }

  ngOnInit(): void {
  }

  public openModal(): void {
    this.streamService.openModal$.emit('encoderModal');
  }

}

export interface IStreamData {
  bandwidth?: {
    limit: IValueUnit;
    used: IValueUnit;
  };
  audience?: number;
  bitrate?: IValueUnit;
  isRecording?: boolean;
}
