import {
    AfterContentInit,
    AfterViewInit,
    Compiler,
    Component,
    Injector,
    Input,
    NgModule,
    NgModuleRef,
    OnInit,
    ViewChild, ViewContainerRef
} from '@angular/core';
import {TARIFF_PLAN_PERIOD} from "@src/app/components/tariff-plan-cards-list/subscription-plan-card/subscription-plan-card.component";
import {FormBuilder} from "@angular/forms";
import {INPUT_TYPES, IUnitKeyValue} from "ui-elements";
import {takeUntil} from "rxjs/operators";
import {AutoDestroyService} from "@src/app/services/auto-destroy-service/auto-destroy.service";
import {UserService} from '@src/app/services/user/user.service';
import {AuthService} from '@src/app/services/auth/auth.service';
import {CURRENCY_SYMBOLS} from '@src/app/components/streamdust-player/media-access-modal/media-access-modal.component';
import {IUserProfile} from '@src/app/pages/user-profile/components/profile/services/profile.service';
import {ApiService} from 'ui-elements';

@Component({
    selector: 'app-subscription-tariffs-table',
    templateUrl: './subscription-tariffs-table.component.html',
    styleUrls: ['./subscription-tariffs-table.component.scss'],
})
export class SubscriptionTariffsTableComponent implements OnInit {
    public ApiService = ApiService;
    @Input() isShowFirstSection: boolean;
    @Input() userProfile: IUserProfile;

    displayOurOffers: boolean;
    displayTopPossibleCombinations: boolean;
    displayMarketingTools: boolean;
    displayEmbeddings: boolean;
    displayOtherFeatures: boolean;

    monthlyChargesPrices = {
        MONTH: {
            monthly: {
                EUR: ['0,00', '90,00', '250,00', '450,00'],
                USD: ['0,00', '100,00', '290,00', '490,00']
            },
            yearly: {
                EUR: ['0,00', '81,00', '225,00', '405,00'],
                USD: ['0,00', '90,00', '261,00', '441,00']
            }
        },
        YEAR: {
            monthly: {
                EUR: ['0,00', '81,00', '225,00', '405,00'],
                USD: ['0,00', '90,00', '261,00', '441,00']
            },
            yearly: {
                EUR: ['0,00', '972', '2.700', '4.860'],
                USD: ['0,00', '1080', '3.132', '5.292']
            }
        }
    };
    monthlyChargesPricesToShow = this.monthlyChargesPrices.MONTH;
    streamProcessingPrices = {
        EUR: ['4,15'],
        USD: ['3,85']
    };
    payPerViewPrices = {
        EUR: ['0,80', '0,70', '0,60', '0,50'],
        USD: ['0,97', '0,85', '0,73', '0,61']
    };
    transactionPrices = {
        EUR: ['0,35'],
        USD: ['0,42']
    };
    feeAndTransactionPrices = {
        EUR: [['0,70', '0,35'], ['0,60', '0,35'], ['0,50', '0,35']],
        USD: [['0,85', '0,42'], ['0,73', '0,42'], ['0,61', '0,42']],
    };

    currentTariffPeriod = TARIFF_PLAN_PERIOD.MONTH;
    TARIFF_PLAN_PERIOD = TARIFF_PLAN_PERIOD;

    currencyForm = this.formBuilder.group({
        currency: ['EUR']
    });

    currencySelectOptions: IUnitKeyValue[] = [
        {
            key: 'EUR',
            value: 'EUR'
        },
        {
            key: 'USD',
            value: 'USD'
        }
    ];

    INPUT_TYPES = INPUT_TYPES;

    selectedCurrency = 'EUR';

    constructor(
        private formBuilder: FormBuilder,
        private destroy$: AutoDestroyService,
        private userService: UserService,
        public authService: AuthService,
        private _compiler: Compiler,
        private _injector: Injector,
        private _m: NgModuleRef<any>
    ) {
    }

    ngOnInit(): void {
        if (this.authService.isAuthorized) {
            if (this.userProfile) {
                this.initCurrency(this.userProfile);
            } else {
                this.userService.userProfile$.subscribe(profile => {
                    if (!profile) {
                        return;
                    }
                    this.initCurrency(profile);
                });
            }
        }

        this.currencyForm.get('currency').valueChanges.pipe(takeUntil(this.destroy$)).subscribe(currencyFormValue => {
            this.selectedCurrency = currencyFormValue;
        });
        this.displayOurOffers = this.isShowFirstSection;
    }

    initCurrency(userProfile) {
        if (!userProfile.currency) {
            return;
        }
        this.selectedCurrency = userProfile.currency;
        this.currencyForm.get('currency').setValue(userProfile.currency);
    }

    changeTariffPeriod(tariffPlanPeriod): void {
        this.currentTariffPeriod = tariffPlanPeriod;
        this.monthlyChargesPricesToShow = this.monthlyChargesPrices[tariffPlanPeriod];
    }

}
