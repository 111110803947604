import {Pipe, PipeTransform} from '@angular/core';
import {IUnitKeyValue} from "ui-elements";

@Pipe({
    name: 'keyToValue'
})
export class KeyToValuePipe implements PipeTransform {

    transform(key: string, optionsList: IUnitKeyValue[]): string | number {
        return optionsList.find(optionItem => optionItem.key === key)?.value;
    }

}
