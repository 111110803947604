<section *ngIf="loading" class="section loading-container">
    <ano-loader [isOpen]="loading"></ano-loader>
</section>
<ng-container *ngIf="!loading">
    <section class="section mh-480">
        <div class="container">
            <div class="bg-light registration-complete">
                <app-info-box>
                    <ng-container role="message" class="mb-0">
                        {{message}}
                    </ng-container>
                </app-info-box>
            </div>
        </div>
    </section>
</ng-container>
