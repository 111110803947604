import {Injectable} from '@angular/core';
import {ApiService} from "ui-elements";
import {Observable} from "rxjs";
import {IVideoOnDemandModel} from "@src/app/models/video-on-demand.model";
import {API_URLS_APP, UrlGenerator} from "@src/app/constants/api-urls.constant";
import {pluck} from "rxjs/operators";
import {IPagedResponse, PagingParams, SortParam} from "@src/app/models/response.model";
import {IGroup} from "@src/app/services/groups/group.service";
import {IMediaGroup} from "@src/app/models/stream-groups.model";
import {ITimerange} from "@src/app/models/stream.model";

@Injectable({
    providedIn: 'root'
})
export class VideoOnDemandService {

    constructor(private apiService: ApiService) {
    }

    public createVideo(body: IVideoOnDemandModel): Observable<IVideoOnDemandModel> {
        return this.apiService.post(API_URLS_APP.VIDEO_ON_DEMAND_SAVE, body)
            .pipe(pluck('results', 'data'));
    }

    public updateVideo(body: IVideoOnDemandModel): Observable<IVideoOnDemandModel> {
        return this.apiService.put(API_URLS_APP.VIDEO_ON_DEMAND_SAVE, body);
    }

    public getVideosList(searchQuery: string, paging: PagingParams = DEFAULT_PAGING, filters?: IFiltersQuery[], timerange?: ITimerange, sort?: SortParam[]): Observable<IPagedResponse<IVideoOnDemandModel>> {
        return this.apiService.post(API_URLS_APP.VIDEO_ON_DEMAND_LIST, {
            paging,
            sort,
            timerange,
            searchTerm: searchQuery,
            filters
        });
    }

    public getVideo(id: string): Observable<IVideoOnDemandModel> {
        return this.apiService.get(UrlGenerator.generate(API_URLS_APP.VIDEO_ON_DEMAND_GET, {id}))
            .pipe(pluck('results', 'data'));
    }

    public deleteVideo(id: string): Observable<IVideoOnDemandModel> {
        return this.apiService.delete(UrlGenerator.generate(API_URLS_APP.VIDEO_ON_DEMAND_GET, {id}));
    }
}

export const DEFAULT_PAGING = {
    page: 0,
    itemsOnPage: 8
} as PagingParams;

export interface IFiltersQuery {
    field: string;
    value: string;
}

export enum VOD_FILTER_FIELDS {
    TITLE = 'TITLE',
    GROUP_ID = 'GROUP_ID'
}
