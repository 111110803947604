import {Component, Input, OnInit} from '@angular/core';
import {
    ITariffPlanPrice,
    TARIFF_PLAN_PERIOD
} from "@src/app/components/tariff-plan-cards-list/subscription-plan-card/subscription-plan-card.component";
import {IPrice} from "@src/app/models/stream.model";
import * as _ from 'lodash';

@Component({
    selector: 'app-payment-check',
    templateUrl: './payment-check.component.html',
    styleUrls: ['./payment-check.component.css']
})
export class PaymentCheckComponent implements OnInit {

    @Input() orderTitle: string;

    @Input() set orderPrice(orderPrice: ITariffPlanPrice[] | IPrice) {
        if (!orderPrice) {
            return;
        }

        this._orderPrice = orderPrice;
        if (orderPrice.constructor.name === 'Array') {
            this.calculateSubscriptionOrderPrice();
        } else {
            this.calculateOrderGroupPrice();
        }
    };

    @Input() planPeriod: TARIFF_PLAN_PERIOD;


    _orderPrice: ITariffPlanPrice[] | IPrice;
    itemsOrderedAmount: number;
    orderPriceTaxFreePerItem: IPrice;
    orderPriceTaxFree: IPrice;
    orderPriceVat: IPrice;
    orderPriceWithVat: IPrice;
    vatPercentage

    taxFee = 5;

    constructor() {
    }

    ngOnInit(): void {
    }

    calculateOrderGroupPrice(): void {
        this.itemsOrderedAmount = 1;
        this.orderPriceTaxFree = this.orderPriceTaxFreePerItem = this._orderPrice as IPrice;
    }

    calculateSubscriptionOrderPrice(): void {
        this.itemsOrderedAmount = this.getItemsOrderedAmount();
        const orderPricesByOrderPeriod = (this._orderPrice as ITariffPlanPrice[]).find(price => price.period === this.planPeriod);
        if (!orderPricesByOrderPeriod) {
            throw Error("no order price for period: " + this.planPeriod);
        }
        this.orderPriceTaxFreePerItem = this.getPeriodPrice(orderPricesByOrderPeriod, this.planPeriod);
        this.orderPriceVat = this.getPeriodVat(orderPricesByOrderPeriod, this.planPeriod, 'total');
        this.orderPriceWithVat = this.getPeriodVat(orderPricesByOrderPeriod, this.planPeriod, 'vat');
        this.vatPercentage = `${this.getPeriodVat(orderPricesByOrderPeriod, this.planPeriod, 'vatPercent')}%`;
        // this.orderPriceTaxFree = this.planPeriod === TARIFF_PLAN_PERIOD.YEAR ? orderPricesByOrderPeriod?.yearPrice : orderPricesByOrderPeriod?.monthPrice;
        this.orderPriceTaxFree = _.cloneDeep(this.orderPriceTaxFreePerItem);
        this.orderPriceTaxFree.amount = this.orderPriceTaxFree.amount * this.itemsOrderedAmount;
        // this.orderPriceWithTax = {
        //     // amount: this.orderPriceTaxFree?.amount + this.taxFee,
        //     amount: this.orderPriceTaxFreePerItem?.amount * this.itemsOrderedAmount,
        //     currency: this.orderPriceTaxFreePerItem?.currency
        // };
    }

    getPeriodPrice(tariffPlanPrice: ITariffPlanPrice, planPeriod: TARIFF_PLAN_PERIOD): IPrice {
        if (planPeriod == TARIFF_PLAN_PERIOD.YEAR) {
            // for year period we show 12 x monthPrice
            planPeriod = TARIFF_PLAN_PERIOD.MONTH;
        }
        const periodPrice = tariffPlanPrice.prices.find(periodPrice => periodPrice.period === planPeriod);
        return periodPrice.price;
    }

    getPeriodVat(tariffPlanPrice: ITariffPlanPrice, planPeriod: TARIFF_PLAN_PERIOD, data: string): IPrice {
        const periodPriceWithVat = tariffPlanPrice.prices.find(periodPrice => periodPrice.period === planPeriod);
        return periodPriceWithVat[data];
    }

    getItemsOrderedAmount(): number {
        return this.planPeriod === TARIFF_PLAN_PERIOD.YEAR ? 12 : 1;
    }

}
