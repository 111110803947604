import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {StreamWorldMapState} from '@src/app/state/stream-world-map';
import {DEFAULT_WORLD_MAP_POSITION} from '@src/app/models/core.model';
import {IBubbleMapData} from '@src/app/components/stream-world-map/bubble-map/bubble-map.component';
import {take, takeUntil} from 'rxjs/operators';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import {CHAT_MODE} from '@src/app/components/chat/chat.component';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {StreamService} from '@src/app/services/stream-metadata/stream.service';
import {IResizeEvent} from 'ngx-draggable-resize/lib/models/resize-event';
import {IResizableElementButton, ResizableElementComponent} from '@src/app/components/resizable-element/resizable-element.component';
import {PLAYER_DISPLAY_MODE} from '@src/app/components/streamdust-player/constants/playerMode';

@Component({
    selector: 'app-stream-world-map',
    templateUrl: './stream-world-map.component.html',
    styleUrls: ['./stream-world-map.component.sass']
})
export class StreamWorldMapComponent implements OnInit {
    @ViewChild('resizableContainer', {static: false}) public resizableContainer: ResizableElementComponent;
    PLAYER_DISPLAY_MODE = PLAYER_DISPLAY_MODE;
    public width = 806;
    public height = 453;

    @Input() streamId: string;
    @Input() mode: CHAT_MODE = CHAT_MODE.DEFAULT;
    @Input() playerDisplayMode: PLAYER_DISPLAY_MODE = PLAYER_DISPLAY_MODE.DEFAULT;
    @Input() id: number;
    @Input() fullScreen$: Observable<boolean>;

    fullscreen$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    dragPosition: { x: number; y: number } = DEFAULT_WORLD_MAP_POSITION;

    dataArray: IBubbleMapData[];

    totalViewers: number;
    viewersInACountry: number;
    currentCountry: string;

    loading = true;
    fullscreen = false;

    CHAT_MODE = CHAT_MODE;

    mapHeight = 410;
    public resizeSubject: Subject<any[]> = new Subject<any[]>();
    public resize$: Observable<any[]> = this.resizeSubject.asObservable();
    public closeButton: IResizableElementButton;
    public fullscreenButton: IResizableElementButton;
    public embeddedButton: IResizableElementButton;

    constructor(
        public worldMapState: StreamWorldMapState,
        private destroy$: AutoDestroyService,
        private streamService: StreamService
    ) {
    }

    ngOnInit(): void {
        // this.fullScreen$.pipe(takeUntil(this.destroy$))
        //     .subscribe((res) => {
        //         setTimeout(() => this.refreshMap());
        //     });
        if (this.playerDisplayMode === PLAYER_DISPLAY_MODE.EMBEDDED) {
            this.width = 630;
            this.height = 340;
            this.worldMapState.setOverlayState(true);
        }
        this.worldMapState.worldMapOverlayState$.pipe(takeUntil(this.destroy$))
            .subscribe((res) => {
                setTimeout(() => {
                    this.worldMapState.worldMapActiveState$.pipe(takeUntil(this.destroy$))
                        .subscribe((state) => {
                            if (state) {
                                this.refreshMap();
                            }
                        });
                });
            });
        this.initButtons();
        this.streamService.streamViewers$
            .pipe(takeUntil(this.destroy$))
            .subscribe((viewers) => {
                this.loading = false;
                // if (!viewers?.length) {
                //     return;
                // }
                this.dataArray = viewers;
                if (!viewers?.length) {
                    this.totalViewers = 0;
                } else {
                    this.totalViewers = viewers.reduce((prev, current) => prev + current.viewers, 0);
                }
                this.removeEmpty();
                this.loading = false;
            });
    }

    private refreshMap(): void {
        this.width = 0;
        this.height = 0;
        setTimeout(() => {
            const container = this.resizableContainer.resizableContainer.nativeElement.getBoundingClientRect();
            this.width = container.width;
            this.height = container.height;
            this.resizeEnd();
        }, 1);
    }

    public initButtons(): void {
        this.fullscreenButton = {
            show: this.mode === CHAT_MODE.DEFAULT,
            callback: () => this.toggleFullscreen()
        };
        this.embeddedButton = {
            show: this.mode !== CHAT_MODE.EMBEDDED,
            callback: () => this.toggleOverlay()
        };
        this.closeButton = {
            show: true,
            callback: () => this.worldMapState.closeWorldMap()
        };
    }

    updateViewersData(viewersByCountry: IBubbleMapData): void {
        this.viewersInACountry = viewersByCountry?.viewers;
        this.currentCountry = viewersByCountry?.country;
    }

    toggleFullscreen(): void {
        this.fullscreen = !this.fullscreen;
        this.fullscreen$.next(this.fullscreen);
        if (this.fullscreen) {
            setTimeout(() => {
                this.mapHeight = document.getElementById(`bubbleMapContainer-${this.id}`).offsetHeight;
            });
            document.body.classList.add('chat-full-screen');
        } else {
            this.mapHeight = 410;
            document.body.classList.remove('chat-full-screen');
        }
    }

    toggleOverlay(): void {
        this.fullscreen = false;
        this.fullscreen$.next(this.fullscreen);
        document.body.classList.remove('chat-full-screen');
        this.worldMapState.toggleOverlay();
        this.fullscreenButton = {
            show: this.mode === CHAT_MODE.DEFAULT,
            callback: () => this.toggleFullscreen()
        };
    }

    public onResize(evt: IResizeEvent): void {
        this.height = evt.size.height;
        this.width = evt.size.width;
    }

    public resizeEnd(): void {
        this.resizeSubject.next(this.dataArray);
    }

    private removeEmpty(): void {
        if (!this.dataArray?.length) {
            return;
        }
        this.dataArray = this.dataArray.filter(item => {
            if (item.viewers === 0 && this.currentCountry === item.country) {
                this.currentCountry = null;
                this.viewersInACountry = null;
            }
            return item.viewers > 0;
        });
    }
}
