<div class="panel" *ngFor="let config of billsTableConfigs">
    <div class="panel-header pb-0" *ngIf="config.groupTitle">
        <h2 class="title-lg"><b>{{config.groupTitle | localize}}</b></h2>
    </div>
    <div class="panel-body bg-white padding-top-20 padding-bottom-20">
        <app-bills-table [config]="config" [timeRangeConfig]="timeRangeConfig"></app-bills-table>
    </div>
</div>


<ng-template #subscriptionTmpl let-data="rowData">
    <div class="long-text">
        <span>
            {{('admin.publisher.type.' + data.subscriptionType).toLowerCase() | localize}}
        </span>
    </div>
</ng-template>
<ng-template #dateTmpl let-data="rowData">
    <div class="long-text">
        <span>
            {{data.date | dateFormat: 'DD MMM YYYY'}}
        </span>
    </div>
</ng-template>
<ng-template #mediaIdTmpl let-data="rowData">
    <div class="long-text">
        <span>
            {{data.productId | stringCut}}
        </span>
    </div>
</ng-template>
<ng-template #amountTmpl let-data="rowData">
    <div class="long-text">
        <span>
            {{data.amount| currency}}
        </span>
    </div>
</ng-template>

<ng-template #dataSizeTmpl let-data="rowData">
    <div class="long-text">
        <span>
            {{data?.dataSize?.value}} {{data?.dataSize?.unit}}
        </span>
    </div>
</ng-template>

<ng-template #statusTmpl let-data="rowData">
    <div class="long-text">
        <span *ngIf="data?.type == 'MEDIA' || data?.type == 'MULTIBITRATE'">
            {{((data.status === BILL_STATUS.PAID ? 'bills.status.paid' : 'bills.status.unpaid') | localize) }}
        </span>
        <span *ngIf="data?.type == 'SUBSCRIPTION' || data?.type == 'BANDWIDTH' || data?.type == 'STORAGE_SPACE'">
            <span>{{ billStatus[data.status] | localize }}</span>
        </span>
    </div>
</ng-template>

<ng-template #dateRangeTmpl let-data="rowData">
    <div class="range-col flex flex-column">
        <span>
            {{data.validity.startDate | dateFormat: 'DD.MM.YYYY'}} -
        </span>
        <span>
            {{data.validity.endDate | dateFormat: 'DD.MM.YYYY'}}
        </span>
    </div>
</ng-template>

<ng-template #actionsTmpl let-data="rowData">
    <div class="table-actions-list" *ngIf="data.status !== BILL_STATUS.UNPAID">
        <div class="spinner-container" *ngIf="isPdfLoading && data.purchaseId === pdfDownloadCurrentId"><ano-loader [isOpen]="isPdfLoading && data.purchaseId === pdfDownloadCurrentId"></ano-loader></div>
        <b class="text-primary">
            <i (click)="downloadPdf(data)"
               class="icon icon-download cursor-pointer"></i>
        </b>
    </div>
</ng-template>
