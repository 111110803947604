import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventSitePageComponent } from './event-site-page.component';
import {SharedModule} from '@src/app/modules/shared/shared.module';



@NgModule({
  declarations: [EventSitePageComponent],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [EventSitePageComponent],
  entryComponents: [EventSitePageComponent]
})
export class EventSitePageModule { }
