<div class="linked-streams-body">
    <div class="slots-info" *ngIf="media?.paymentSystem?.psPayPerView">
        <ng-container *ngIf="!isPayedAll; else Payed">
            <p>{{'slots.modal.access.description' | localize}}:</p>
            <p class="price"><b>{{media?.price | currency}}</b></p>
            <ano-button (click)="proceedPayment(null)"
                        [config]=" { styleType: 'success', sizeType: 'block'}">{{'slots.modal.access.payment.all' | localize}}</ano-button>
        </ng-container>
        <ng-template #Payed>
            <p class="info-text">{{'slots.modal.access.description.bought' | localize}}</p>
        </ng-template>
    </div>
    <div class="linked-streams">
        <div class="linked-streams-nav" *ngIf="numberOfSlides > 2">
            <div class="linked-streams-prev"
                 *ngIf="sliderArrow.leftArrow"
                 (click)="moveLeft()">
                <i class="icon icon-prev"></i>
            </div>
            <div class="linked-streams-next"
                 *ngIf="sliderArrow.rightArrow"
                 (click)="moveRight()">
                <i class="icon icon-next"></i>
            </div>
        </div>
        <div class="row row-padding-5 flex-nowrap">
            <div  class="col-4 item-wrap-media">
                <div class="item bg-item" [ngStyle]="{'background-image': getBackground(media)}">
                    <div class="streams-item-header">
                                        <span class="status live" *ngIf="media?.status?.stream === STREAM_STATUS.LIVE">
                                            <i class="icon-dot-5"></i>
                                            Live
                                        </span>
                        <h3 class="title">{{media.title}}</h3>
                        <p>{{media.description | croppedText:120}}</p>
                    </div>
                </div>
            </div>
            <div class="drag-controls-wrapper">
                <drag-scroll #sliderComp class="wrapper-slider">
                    <div drag-scroll-item class="col-4 item-wrap"
                         *ngFor="let item of data">
                        <div class="item bg-item">
                            <div class="streams-item-header">
                                <ng-container *ngIf="!isVideo">
                                    <div class="header-info-text info-date">
                                        {{item.startTimestamp | dateFormat: 'DD MMM YYYY': true}}
                                    </div>
                                    <div class="header-info-text info-time">
                                        {{item.startTimestamp | dateFormat: 'HH:mm': true}} - {{item.endTimestamp | dateTimeFormat: 'HH:mm'}}
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="isVideo">
                                    <div class="header-info-text info-time">
                                        {{item.startTimestamp | durationConvert}} - {{item.endTimestamp | durationConvert}}
                                    </div>
                                </ng-container>
                                <h3 class="title">{{item.title}}</h3>
                                <span class="item-access-info">
                                {{'slots.modal.slot.access' | localize}}:
                                <ng-container *ngIf="item.slotPayment === PAYMENT_SYSTEM.FREE">{{'slots.modal.free' | localize}}</ng-container>
                                <ng-container *ngIf="item.slotPayment === PAYMENT_SYSTEM.PAY_PER_VIEW && !item.hasAccess" >
                                    {{item.price | currency}}
                                </ng-container>
                                <ng-container *ngIf="item.hasAccess && item.slotPayment !== PAYMENT_SYSTEM.FREE && item.slotPayment !== PAYMENT_SYSTEM.VOUCHER" >
                                    {{'slots.modal.slot.access.item.bought' | localize}}
                                </ng-container>
                                <ng-container *ngIf="item.slotPayment === PAYMENT_SYSTEM.VOUCHER">{{'slots.modal.voucher' | localize}}</ng-container>
                            </span>
                            </div>
                            <div class="stream-item-footer">
                                <button *ngIf="!isVideo" (click)="downloadIcs(item)" class="btn button-primary remind-button">
                                    <i class="icon icon-calendar"></i>
                                </button>
                                <button *ngIf="isVideo && item.hasAccess"
                                        class="btn btn-primary btn-icon-play"
                                        [class.video-slot-button]="isVideo"
                                        (click)="playSlot(item.id)"
                                >
                                    <i class="icon icon-play"></i>
                                    Play
                                </button>
                                <button *ngIf="!item.hasAccess" (click)="proceedPayment(item.id)" class="btn button-primary buy-button" [class.video-slot-button]="isVideo">
                                    {{'slots.modal.slot.access.item' | localize}}
                                </button>
                                <!--                                    <span class="text">-->
                                <!--                                        {{ getCurrency(item) ? (getCurrency(item) | currency) : 'Free'}}-->
                                <!--                                    </span>-->
                            </div>
                        </div>
                    </div>
                </drag-scroll>
            </div>
        </div>
    </div>
</div>
