import {Component, OnInit} from '@angular/core';
import {UserService} from "@src/app/services/user/user.service";
import {pluck, switchMap, takeUntil, tap} from "rxjs/operators";
import {LoadingService} from "@src/app/services/loading/loading.service";
import {MEDIA_FILTERS_FIELDS, MediaListService} from "@src/app/services/media-list/media-list.service";
import {IListFilterItem, IListQuery, LOAD_TYPE} from "@src/app/components/media-list/media-list.component";
import {ISuggestedMedia} from "@src/app/pages/public-media-page/public-media-page.component";
import {
    FILTER_MEDIA_TYPES,
    FILTER_TYPES, IMediaListsConfig
} from "@src/app/components/media-list/media-list-header/media-list-header.component";
import {forkJoin, of} from "rxjs";
import {IMediaItem} from "@src/app/models/core.model";
import {IPagedResponseResults} from "@src/app/models/response.model";
import {AutoDestroyService} from "@src/app/services/auto-destroy-service/auto-destroy.service";
import {IUserProfile} from "@src/app/pages/user-profile/components/profile/services/profile.service";
import {USER_TYPE} from '@src/app/constants/user-type.constant';

@Component({
    selector: 'app-viewer-dashboard',
    templateUrl: './viewer-dashboard.component.html',
    styleUrls: ['./viewer-dashboard.component.sass']
})
export class ViewerDashboardComponent implements OnInit {
    USER_TYPE = USER_TYPE;

    FILTER_TYPES = FILTER_TYPES;

    userName: string;
    ownerId: string;

    ownerFilter: IListFilterItem;

    implicitStreamFilters: IListFilterItem[];

    query: IListQuery = {
        paging: {
            page: 0,
            itemsOnPage: 9
        },
        filters: [],
        groupFilters: [{field: 'VISIBLE', value: ['everywhere', 'except_streamdust', 'streamdust_only']}],
    };

    videoImplicitFilters: IListFilterItem[];
    streamsImplicitFilters: IListFilterItem[];

    streamsList: IPagedResponseResults<IMediaItem>;
    videosList: IPagedResponseResults<IMediaItem>;
    viewerMediaListSettings: ISuggestedMedia[];

    LOAD_TYPE = LOAD_TYPE;

    viewerMediaListsConfig: IMediaListsConfig = {
        filters: {
            filterTypes: [
                this.FILTER_TYPES.SORT,
                this.FILTER_TYPES.HAS_ACCESS_MEDIA_GROUP
            ],
            mediaTypes: []
        },
        withSearch: true,
        filtration: true,
        filtersCssClass: 'blue-theme-filter',
        smallLists: true,
        mediaCardElements: {
            group: true
        },
        hideMediaPrice: true,
        cardConfig: {
            streamHeaderCssClass: 'video-on-demand-card-header',
            skeleton: {
                headerCssClass: 'header-sm'
            }
        },
    };

    constructor(
        public userService: UserService,
        public loadingService: LoadingService,
        private mediaListService: MediaListService,
        private destroy$: AutoDestroyService
    ) {
    }

    ngOnInit(): void {
        this.loadingService.loadingStart();
        this.getUserName();

        this.userService.userProfile$.pipe(
            switchMap((userProfileData: IUserProfile) => {
                if (!userProfileData) {
                    return of(null);
                }
                this.ownerId = userProfileData.ownerId;
                this.ownerFilter = {
                    field: MEDIA_FILTERS_FIELDS.HAS_ACCESS,
                    value: this.ownerId
                };
                this.setViewerMediaQuery();
                this.setViewerMediaTimeRange();
                return forkJoin({
                    videosList: this.mediaListService.getMediaList({
                        ...this.query,
                        filters: this.videoImplicitFilters,
                    }, false).pipe(pluck('results', 'data')),
                    streamsList: this.mediaListService.getMediaList({
                        ...this.query,
                        filters: this.streamsImplicitFilters,
                    }, false).pipe(pluck('results', 'data'))
                });
            }),
            tap((mediaListData) => {
                this.videosList = mediaListData?.videosList;
                this.streamsList = mediaListData?.streamsList;
                this.viewerMediaListSettings = this.getViewerMediaSettings().filter(mediaSetting => mediaSetting.originalLength);
                this.loadingService.loadingEnd();
            }),
            takeUntil(this.destroy$)).subscribe();
    }

    setViewerMediaTimeRange(): void {
        this.viewerMediaListsConfig.timeRangeConfig = {
            fetchMethod: (filters) => this.mediaListService.getMediaListTimeRanges(filters),
            filters: [
                {
                    field: MEDIA_FILTERS_FIELDS.MEDIA_TYPE,
                    value: [FILTER_MEDIA_TYPES.STREAM, FILTER_MEDIA_TYPES.VIDEO_ON_DEMAND, FILTER_MEDIA_TYPES.STREAM_RECORDING]
                },
                this.ownerFilter
            ],
            customIndex: 1
        };
    }

    setViewerMediaQuery(): void {
        this.videoImplicitFilters = [
            this.ownerFilter,
            {
                field: MEDIA_FILTERS_FIELDS.MEDIA_TYPE,
                value: [FILTER_MEDIA_TYPES.STREAM_RECORDING, FILTER_MEDIA_TYPES.VIDEO_ON_DEMAND]
            }
        ];
        this.streamsImplicitFilters = [
            this.ownerFilter,
            {
                field: MEDIA_FILTERS_FIELDS.MEDIA_TYPE,
                value: [FILTER_MEDIA_TYPES.STREAM]
            }
        ];
    }

    getViewerMediaSettings(): ISuggestedMedia[] {
        return [
            {
                title: 'media-list.streams.title',
                media: this.streamsList,
                implicitFilters: this.streamsImplicitFilters,
                fetchMethod: (filters: IListQuery) => this.mediaListService.getMediaList(filters),
                originalLength: this.streamsList?.items?.length
            },
            {
                title: 'media-list.videos.title',
                media: this.videosList,
                implicitFilters: this.videoImplicitFilters,
                fetchMethod: (filters: IListQuery) => this.mediaListService.getMediaList(filters),
                originalLength: this.videosList?.items?.length
            }
        ];
    }

    getUserName(): void {
        this.userService.userProfile$.pipe(takeUntil(this.destroy$))
            .subscribe((res: IUserProfile) => {
                if (!res || (!res.firstName && !res.lastName)) {
                    this.userName = this.userService.userProfile?.email;
                    return;
                }

                this.userName = res.firstName + ' ' + res.lastName;
            });
    }

}
