import {Component, EventEmitter, HostListener, Input, Output} from '@angular/core';

@Component({
  selector: 'app-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.sass']
})
export class InfoModalComponent {
  @Input() title: string;
  @Input() description: string;
  @Input() closeButtonText = 'OK';
  @Output() closed = new EventEmitter<void>();

  closeModel() {
    this.closed.emit();
  }

  stopPropagation(event: Event) {
    event.stopPropagation();
  }

  @HostListener('document:keydown.escape')
  onEscapeKeyDown() {
    this.closeModel();
  }
}
