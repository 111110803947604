import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {AutoDestroyService} from '../../services/auto-destroy-service/auto-destroy.service';
import {SubAccountsService} from '../../services/sub-accounts/sub-accounts.service';
import {ITableConfig, TableComponent} from  'ui-elements';
import {ISubAccount} from './model/sub-account.model';
import {takeUntil} from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';
import {ISideNavConfig} from '@src/app/components/side-nav/side-nav.component';
import {SideNavConfigModel} from '@src/app/models/side-nav-config.model';
import {Router} from '@angular/router';
import {ITableButtonConfig} from 'ui-elements';

@Component({
    selector: 'app-subaccounts-page',
    templateUrl: './subaccounts-page.component.html',
    styleUrls: ['./subaccounts-page.component.sass'],
    providers: [AutoDestroyService, SubAccountsService]
})
export class SubaccountsPageComponent implements OnInit {
    @ViewChild('confirm', {static: false}) confirm: TemplateRef<any>;
    @ViewChild('actionsTmpl', {static: true}) public actionsTmpl: TemplateRef<any>;
    @ViewChild('statusTmpl', {static: true}) public statusTmpl: TemplateRef<any>;
    @ViewChild('typeTmpl', {static: true}) public typeTmpl: TemplateRef<any>;
    @ViewChild('emailTmpl', {static: true}) public emailTmpl: TemplateRef<any>;
    @ViewChild('tableComponent', {static: true}) public table: TableComponent;
    navConfig: ISideNavConfig = SideNavConfigModel.getLeftMenuConfig();
    public tableConfig: ITableConfig<ISubAccount>;
    public searchQuery: string;
    public tableButtonConfig: ITableButtonConfig = {
        buttonConfig: { styleType: 'primary', sizeType: 'block'},
        icon: 'icon-send',
        text: 'Invite',
        action: () => { this.router.navigate(['subaccounts/invite']); }
    };

    constructor(
        private destroy$: AutoDestroyService,
        private subAccountsService: SubAccountsService,
        public dialog: MatDialog,
        private router: Router
    ) {
    }

    ngOnInit(): void {
        this.tableConfig = {
            dataField: 'subaccounts',
            searchFn: (sortParams, pagingParams) => {
                return this.subAccountsService.list(this.searchQuery, pagingParams, sortParams);
            },
            columns: [
                {
                    name: 'subaccounts.status',
                    tmpl: this.statusTmpl
                }, {
                    name: 'subaccounts.type',
                    tmpl: this.typeTmpl
                }, {
                    name: 'subaccounts.firstName',
                    dataField: 'firstName',
                    sortField: 'firstName',
                    class: 'left'
                }, {
                    name: 'subaccounts.lastName',
                    dataField: 'lastName',
                    sortField: 'lastName',
                    class: 'left'
                },
                {
                    name: 'subaccounts.email',
                    sortField: 'email',
                    tmpl: this.emailTmpl,
                    class: 'left'
                },
                {
                    name: 'streams.title.table.column.action',
                    tmpl: this.actionsTmpl,
                    class: 'middle'
                }
            ]

        };
    }

    public search(event: string): void {
        this.searchQuery = event;
        this.table.refreshData({});
    }

    public delete(id: string): void {
        const dialogRef = this.dialog.open(this.confirm);
        dialogRef.afterClosed()
            .pipe(takeUntil(this.destroy$))
            .subscribe((confirm: boolean) => {
                if (confirm) {
                    this.subAccountsService.delete(id)
                        .pipe(takeUntil(this.destroy$))
                        .subscribe((res) => {
                            if (res.success) {
                                this.table.resetTableData();
                                this.table.refreshData({});
                            }
                        });
                }
            });
    }

    public addItem(): void {
        this.router.navigate(['subaccounts/new']);
    }

    public navigateToAccount(id: string): void {
        this.router.navigate(['subaccounts/' + id]);
    }
}
