import {Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren} from '@angular/core';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {filter, pluck, switchMap, takeUntil, tap} from 'rxjs/operators';
import {NEW_ID_PARAM} from '@src/app/utils/custom-validators.util';
import {LoadingService} from '@src/app/services/loading/loading.service';
import {MediaGroupsService} from '@src/app/services/media-groups/media-groups.service';
import {IFormConfig} from '@src/app/services/stream-metadata/stream.service';
import {FormBuilder} from '@angular/forms';
import {IMediaGroup, IMediaGroupPayload} from '@src/app/models/stream-groups.model';
import {IMediaItem, VIDEO_TYPE} from '@src/app/models/core.model';
import {FormService} from '@src/app/services/form/form.service';
import {FormUtil} from '@src/app/utils/form.util';
import {forkJoin} from 'rxjs';
import {
    IListFilterItem,
    IListQuery,
    LOAD_TYPE,
    MediaListComponent
} from '@src/app/components/media-list/media-list.component';
import {FILTER_MEDIA_TYPES} from '@src/app/components/media-list/media-list-header/media-list-header.component';
import {ISuggestedMedia} from '@src/app/pages/public-media-page/public-media-page.component';
import {MEDIA_FILTERS_FIELDS, MediaListService} from '@src/app/services/media-list/media-list.service';
import {IPagedResponseResults} from '@src/app/models/response.model';
import {MEDIA_TYPE} from '@src/app/components/streamdust-player/constants/mediaType';
import {UserService} from '@src/app/services/user/user.service';

@Component({
    selector: 'app-group',
    templateUrl: './group.component.html',
    styleUrls: ['./group.component.sass']
})
export class GroupComponent implements OnInit {
    @Input() isModal = false;
    @Output() groupCreated$: EventEmitter<string> = new EventEmitter<string>();
    @ViewChildren('mediaLists') public mediaLists: QueryList<MediaListComponent>;

    public ownerId: string;
    public group: IMediaGroup;
    public streams: IMediaItem[];
    public groupId: string;
    public formConfig: IFormConfig;
    public VIDEO_TYPE = VIDEO_TYPE;
    isCreateMode = false;
    loading = false;

    LOAD_TYPE = LOAD_TYPE;

    groupMediaListSettings: ISuggestedMedia[];

    groupVideoImplicitFilters: IListFilterItem[];
    groupStreamsImplicitFilters: IListFilterItem[];

    query: IListQuery = {
        paging: {
            page: 0,
            itemsOnPage: 6
        },
        sort: [{
            field: 'DATE',
            order: 'ASC'
        }]
    };

    groupStreamsList: IPagedResponseResults<IMediaItem>;
    groupVideosList: IPagedResponseResults<IMediaItem>;
    public showPlaceholder = false;

    constructor(
        private readonly destroy$: AutoDestroyService,
        private readonly activatedRoute: ActivatedRoute,
        private router: Router,
        public loadingService: LoadingService,
        private streamGroupsService: MediaGroupsService,
        private fb: FormBuilder,
        private formService: FormService,
        private mediaListService: MediaListService,
        private userService: UserService
    ) {
    }

    ngOnInit(): void {
        this.formConfig = this.streamGroupsService.getGroupFormConfig(this.isModal);

        this.activatedRoute.params.pipe(
            takeUntil(this.destroy$),
            tap(({id}: Params) => {
                if (id === NEW_ID_PARAM) {
                    this.isCreateMode = true;
                    return;
                }
                this.groupId = id;
            }),
            filter(({id}: Params) => id && id !== NEW_ID_PARAM),
            switchMap(({id}: Params) => {
                this.loadingService.loadingStart();
                this.loading = true;
                this.userService.userProfile$.pipe(takeUntil(this.destroy$))
                    .subscribe((res) => {
                        this.ownerId = res.ownerId;
                        this.setGroupMediaQuery();
                    });
                return forkJoin({
                    groupData: this.streamGroupsService.getGroup(id),
                    groupVideosList: this.mediaListService.getMediaList({
                        ...this.query,
                        filters: this.groupVideoImplicitFilters
                    }, true, true).pipe(pluck('results', 'data')),
                    groupStreamsList: this.mediaListService.getMediaList({
                        ...this.query,
                        filters: this.groupStreamsImplicitFilters
                    }, true, true).pipe(pluck('results', 'data'))
                });
            }))
            .subscribe((_res) => {
                this.loadingService.loadingEnd();
                this.loading = false;
                if (!_res) {
                    return;
                }
                this.groupStreamsList = _res.groupStreamsList;
                this.groupVideosList = _res.groupVideosList;
                this.groupMediaListSettings = this.getGroupMediaSettings().filter(mediaSetting => mediaSetting.originalLength);
                this.checkPlaceholder();
                this.group = (_res.groupData as IMediaGroup);
                this.streams = this.group.mediaItems as IMediaItem[];
                this.formConfig.form.get('name').patchValue(this.group.name);
                // this.formConfig.form.get('paymentType').patchValue(_res.groupData.paymentType);
                this.formConfig.form.get('priceAmount').patchValue(this.group.price.amount);
                this.formConfig.form.get('priceCurrency').patchValue(this.group.price.currency);
            });
    }

    goToMediaDetails(media): void {
        const route = media.videoType === MEDIA_TYPE.VIDEO_ON_DEMAND ? 'vod' : 'streams';
        this.router.navigate([route, media.id, 'info']);
    }

    setGroupMediaQuery(): void {
        this.groupStreamsImplicitFilters = [
            {
                field: MEDIA_FILTERS_FIELDS.PUBLISHER,
                value: this.ownerId
            },
            {
                field: MEDIA_FILTERS_FIELDS.GROUP_ID,
                value: this.groupId
            },
            {
                field: MEDIA_FILTERS_FIELDS.MEDIA_TYPE,
                value: FILTER_MEDIA_TYPES.STREAM
            },
            {
                field: MEDIA_FILTERS_FIELDS.VISIBLE,
                value: false
            }
        ];
        this.groupVideoImplicitFilters = [
            {
                field: MEDIA_FILTERS_FIELDS.PUBLISHER,
                value: this.ownerId
            },
            {
                field: MEDIA_FILTERS_FIELDS.GROUP_ID,
                value: this.groupId
            },
            {
                field: MEDIA_FILTERS_FIELDS.MEDIA_TYPE,
                value: [FILTER_MEDIA_TYPES.VIDEO_ON_DEMAND, FILTER_MEDIA_TYPES.STREAM_RECORDING]
            },
            {
                field: MEDIA_FILTERS_FIELDS.VISIBLE,
                value: false
            }
        ];
    }

    getGroupMediaSettings(): ISuggestedMedia[] {
        return [
            {
                title: 'group.media-list.streams',
                media: this.groupStreamsList,
                implicitFilters: this.groupStreamsImplicitFilters,
                fetchMethod: (filters: IListQuery) => this.mediaListService.getMediaList(filters, true, true),
                originalLength: this.groupStreamsList?.items?.length
            },
            {
                title: 'group.media-list.videos',
                media: this.groupVideosList,
                implicitFilters: this.groupVideoImplicitFilters,
                fetchMethod: (filters: IListQuery) => this.mediaListService.getMediaList(filters, true, true),
                originalLength: this.groupVideosList?.items?.length
            }
        ];
    }

    public submit(): void {
        let payload: IMediaGroupPayload;
        if (this.isModal) {
            payload = this.formConfig.form.value;
        } else {
            payload = FormUtil.mapFormsToPayload<IMediaGroupPayload>([this.formConfig])['group'];
        }

        this.formConfig.form.markAllAsTouched();
        if (this.formConfig.form.invalid) {
            if (this.isModal) {
                return;
            }
            this.formService.submitInvalid$.emit();
            return;
        }

        if (!this.isCreateMode) {
            payload.id = this.groupId;
        }

        this.loading = true;
        this.streamGroupsService.saveGroup(payload)
            .pipe(takeUntil(this.destroy$))
            .subscribe((res) => {
                this.loading = false;
                if (!res) {
                    return;
                }
                this.groupCreated$.emit(res.results.id);
                if (this.isCreateMode) {
                    this.goToGroups();
                }
            }, () => this.loading = false);
    }

    public goToGroups(): void {
        this.router.navigate(['groups']);
    }

    public removeMediaFromGroup(media: IMediaItem, mediaListIndex): void {
        let mediaListToRefresh;
        if (mediaListIndex || mediaListIndex === 0) {
            mediaListToRefresh = this.mediaLists.find((item, index) => index === mediaListIndex);
            mediaListToRefresh.setFiltrationLoading(true);
        }
        this.streamGroupsService.removeStreamFromGroup(this.groupId, media.id, media.videoType)
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                if (mediaListToRefresh) {
                    mediaListToRefresh.refreshList();
                    --this.groupMediaListSettings[mediaListIndex].originalLength;
                }
            }, () => mediaListToRefresh.setFiltrationLoading(false));
    }

    private checkPlaceholder() {
        if (!this.groupMediaListSettings.length) {
            this.showPlaceholder = true;
        }
    }
}
