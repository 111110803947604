import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'whiteSpace'
})
export class WhiteSpacePipe implements PipeTransform {

  transform(value: string): string {
    if (!value) {
      return null;
    }
    return value.replace('-', '- ');
  }

}
