import {Component, Input, OnInit} from '@angular/core';
import {IFormConfig, StreamService} from '@src/app/services/stream-metadata/stream.service';
import {MEDIA_TYPE} from '@src/app/components/streamdust-player/constants/mediaType';
import {Router} from '@angular/router';
import {takeUntil} from 'rxjs/operators';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import {LoadingService} from 'ui-elements';
import {ChatState} from '@src/app/state/chat-state';
import {IStreamModel, IStreamPreviewTeaserTrailer} from '@src/app/models/stream.model';
import {Subscription} from 'rxjs';
import {WorldmapService} from '@src/app/services/worldmap/worldmap.service';
import {StreamWorldMapState} from '@src/app/state/stream-world-map';

@Component({
  selector: 'app-visibility-settings',
  templateUrl: './visibility-settings.component.html',
  styleUrls: ['./visibility-settings.component.scss'],
  providers: [AutoDestroyService]
})
export class VisibilitySettingsComponent implements OnInit {
  @Input() stream: IStreamModel;
  @Input() mediaType: MEDIA_TYPE;
  @Input() mediaId: string;
  @Input() premiumPublisher: boolean;
  public landingForms: IFormConfig[];
  public landingSubscription: Subscription;
  public worldMapAndShareButtonSubscription: Subscription;
  public teaserSubscription: Subscription;
  public trailerSubscription: Subscription;
  public websiteSettingsAvailable = false;
  public trailerAvailable = false;
  public teaserAvailable = false;
  public trailer: IStreamPreviewTeaserTrailer;
  public teaser: IStreamPreviewTeaserTrailer;
  public pending: boolean;

  constructor(
      private streamService: StreamService,
      private router: Router,
      private destroy$: AutoDestroyService,
      public loadingService: LoadingService,
      public chatState: ChatState,
      private wmService: WorldmapService,
      private mapState: StreamWorldMapState
  ) { }

  ngOnInit(): void {
    this.landingForms = this.streamService.getLandingSettingsForms(this.mediaType, this.premiumPublisher);
    this.getVisibilitySettings();
    this.setChatState();
    this.setShowStreamDateState();
    this.listenChatToggle();
    this.listenShowStreamDateToggle();
    this.pending = true;
  }

  private getVisibilitySettings(): void {
      if (!this.worldMapAndShareButtonSubscription) {
          this.worldMapAndShareButtonSubscription = this.wmService.getState(this.mediaType, this.mediaId)
              .pipe(takeUntil(this.destroy$))
              .subscribe((res) => {
                  this.loadingService.loadingEnd();
                  if (!res) {
                      return;
                  }
                  this.landingForms.find(form => form.name === 'worldmapVisibility')
                      .form.get('active').patchValue(res.advancedSettings.worldMapEnabled);

                  this.landingForms.find(form => form.name === 'shareMedia')
                      .form.get('active').patchValue(res.advancedSettings.shareButtonEnabled);

                  this.landingForms.find(form => form.name === 'videoTitle')
                      .form.get('active').patchValue(res.advancedSettings.displayVideoTitle);

                  this.listenWorldMapSettings();
                  this.listenShareButtonSettings();
                  this.listenVideoTitleSettings();
              }, () => {
                  this.loadingService.loadingEnd();
                  // this.landingForms.find(form => form.name === 'worldmapVisibility').forcedDisable = true;
                  this.listenWorldMapSettings();
                  this.listenShareButtonSettings();
                  this.listenVideoTitleSettings();
              });
      }
      if (!this.landingSubscription) {
          this.landingSubscription = this.streamService.getLandingPage(this.mediaType, this.mediaId)
              .pipe(takeUntil(this.destroy$))
              .subscribe((res) => {
                  this.loadingService.loadingEnd();
                  if (!res) {
                      const formConfig = this.landingForms.find(form => form.name === 'siteVisibility');
                      formConfig.formFields[0][0].config.hiddenToggle = true;
                      this.websiteSettingsAvailable = false;

                      return;
                  }
                  this.websiteSettingsAvailable = true;
                  this.landingForms.find(form => form.name === 'siteVisibility')
                      .form.get('active').patchValue(res.active);
                  this.listenLandingSettings();
              }, () => {
                  this.loadingService.loadingEnd();
                  this.landingForms.find(form => form.name === 'siteVisibility').forcedDisable = true;
                  this.listenLandingSettings();
              });
      }

      if (!this.premiumPublisher) {
          return;
      }
      if (!this.teaserSubscription) {
          this.teaserSubscription = this.streamService.getStreamTeaserTrailer(this.mediaId, this.mediaType, 'teaser')
              .pipe(takeUntil(this.destroy$))
              .subscribe(res => {
                  if (!res) {
                      return;
                  }
                  setTimeout(() => {
                    this.pending = false;
                  }, 1500);
                  this.teaser = res;
                  const videoSettings = this.landingForms.find(form => form.name === 'teaserVisibility');
                  const videoActive = videoSettings.formFields.find(frmCfg => frmCfg.find(fldCfg => fldCfg.name === 'teaserActive'));
                  if (!res.videoUrl && videoActive) {
                      videoActive[0].config.hiddenToggle = true;
                      this.teaserAvailable = false;
                      return;
                  }
                  this.teaserAvailable = true;
                  if (res?.active) {
                      videoSettings.form.get('teaserActive').setValue(true);
                  }
                  this.listenTeaserSetting();
              });
      }

      if (!this.trailerSubscription) {
          this.trailerSubscription = this.streamService.getStreamTeaserTrailer(this.mediaId, this.mediaType, 'trailer')
              .pipe(takeUntil(this.destroy$))
              .subscribe(res => {
                  if (!res) {
                      return;
                  }
                  setTimeout(() => {
                    this.pending = false;
                  }, 1500);
                  if (res?.videoUrl && !res.videoUrl.startsWith('http://') && !res.videoUrl.startsWith('https://')) {
                      res.videoUrl = 'https://' + res.videoUrl;
                  }
                  if (res.advertisingUrl && !res.advertisingUrl.startsWith('http://') && !res.advertisingUrl.startsWith('https://')) {
                      res.advertisingUrl = 'https://' + res.advertisingUrl;
                  }
                  this.trailer = res;
                  const videoSettings = this.landingForms.find(form => form.name === 'trailerVisibility');
                  const videoActive = videoSettings.formFields.find(frmCfg => frmCfg.find(fldCfg => fldCfg.name === 'trailerActive'));
                  if (!res.videoUrl && videoActive) {
                      videoActive[0].config.hiddenToggle = true;
                      this.trailerAvailable = false;
                      return;
                  }
                  this.trailerAvailable = true;
                  if (res?.active) {
                      videoSettings.form.get('trailerActive').setValue(true);
                  }
                  this.listenTrailerSetting();
              });
      }
  }

    public listenWorldMapSettings(): void {
        this.landingForms.find(form => form.name === 'worldmapVisibility')
            .form.valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((res) => {
                this.loadingService.loadingStart();
                this.wmService.setState(
                    this.mediaType,
                    this.mediaId,
                    {
                        ...res
                    }
                ).pipe(takeUntil(this.destroy$))
                    .subscribe(() => {
                        // this.mapState.worldMapEnabled$.next(true);
                        this.loadingService.loadingEnd();
                    }, () => this.loadingService.loadingEnd());
            });
    }

    public listenShareButtonSettings(): void {
        this.landingForms.find(form => form.name === 'shareMedia')
            .form.valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((res) => {
                this.loadingService.loadingStart();
                this.wmService.setShareState(
                    this.mediaType,
                    this.mediaId,
                    {
                        ...res
                    }
                ).pipe(takeUntil(this.destroy$))
                    .subscribe(() => {
                        // this.mapState.worldMapEnabled$.next(true);
                        this.loadingService.loadingEnd();
                    }, () => this.loadingService.loadingEnd());
            });
    }

  public listenVideoTitleSettings(): void {
    this.landingForms.find(form => form.name === 'videoTitle')
        .form.valueChanges.pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          this.loadingService.loadingStart();
          this.wmService.setVideoTitle(
              this.mediaType,
              this.mediaId,
              {
                ...res
              }
          ).pipe(takeUntil(this.destroy$))
              .subscribe(() => {
                // this.mapState.worldMapEnabled$.next(true);
                this.loadingService.loadingEnd();
              }, () => this.loadingService.loadingEnd());
        });
  }

  public listenLandingSettings(): void {
    this.landingForms.find(form => form.name === 'siteVisibility')
        .form.valueChanges.pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          this.loadingService.loadingStart();
          this.streamService.updateSiteVisibility(
              this.mediaType,
              this.mediaId,
              {
                ...res
              }
          ).pipe(takeUntil(this.destroy$))
              .subscribe(() => {
                this.loadingService.loadingEnd();
              }, () => this.loadingService.loadingEnd());
        });
  }

  private setChatState(): void {
    this.landingForms.find(form => form.name === 'chatState')
        ?.form.get('chatEnabled').patchValue(this.stream?.advancedSettings?.chatEnabled);
  }

    private setShowStreamDateState(): void {
        this.landingForms.find(form => form.name === 'showStreamDateState')
            ?.form.get('showStreamDateEnabled').patchValue(this.stream?.advancedSettings?.showStreamDate);
    }

  public listenChatToggle(): void {
    const chat = this.landingForms.find(form => form.name === 'chatState');
    if (!chat) {
      return;
    }
    chat?.form.get('chatEnabled').valueChanges.pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          this.streamService.toggleChat({productId: this.mediaId, productType: this.mediaType, active: res})
              .pipe(takeUntil(this.destroy$))
              .subscribe(() => {
                this.chatState.closeChat();
                this.loadingService.loadingEnd();
              });
        });
  }

    public listenShowStreamDateToggle(): void {
        const showStreamDate = this.landingForms.find(form => form.name === 'showStreamDateState');
        if (!showStreamDate) {
            return;
        }
        showStreamDate?.form.get('showStreamDateEnabled').valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((res) => {
                this.streamService.toggleShowStreamDate({productId: this.mediaId, productType: this.mediaType, showStreamDate: res})
                    .pipe(takeUntil(this.destroy$))
                    .subscribe(() => {
                        this.streamService.toggleStreamShowDate$.emit(res);
                    });
            });
    }

  public goToSetup(linkTmpl: string, e: Event): void {
    e.preventDefault();
    const link = linkTmpl.replace('{id}', this.mediaId);
    this.router.navigate([link]);
  }

  public listenTeaserSetting(): void {
    this.landingForms.find(form => form.name === 'teaserVisibility')
        .form.get('teaserActive').valueChanges.pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          if (!this.teaser.videoUrl) {
            this.router.navigate(['streams', this.mediaId, 'teaser']);
          } else {
            this.loadingService.loadingStart();
            this.streamService.toggleTeaser({productId: this.mediaId, videoType: this.mediaType, active: res})
                .pipe(takeUntil(this.destroy$))
                .subscribe((response) => {
                  this.loadingService.loadingEnd();
                  if (response?.errorKey === 'NOT_FOUND') {
                    this.router.navigate(['streams', this.mediaId, 'teaser']);
                  }
                });
          }
        });
  }

  public listenTrailerSetting(): void {
    this.landingForms.find(form => form.name === 'trailerVisibility')
        .form.get('trailerActive').valueChanges.pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          if (!this.trailer.videoUrl) {
            this.router.navigate(['streams', this.mediaId, 'trailer']);
          } else {
            this.loadingService.loadingStart();
            this.streamService.toggleTrailer({productId: this.mediaId, videoType: this.mediaType, active: res})
                .pipe(takeUntil(this.destroy$))
                .subscribe((response) => {
                  this.loadingService.loadingEnd();
                  if (response?.errorKey === 'NOT_FOUND') {
                    this.router.navigate(['streams', this.mediaId, 'trailer']);
                  }
                });
          }
        });
  }


}
