import {Component, Inject, OnInit} from '@angular/core';
import {StreamService} from '@src/app/services/stream-metadata/stream.service';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import {ActivatedRoute, NavigationEnd, Params, Router} from '@angular/router';
import {catchError, take, takeUntil, tap} from 'rxjs/operators';
import {MEDIA_TYPE} from '@src/app/components/streamdust-player/constants/mediaType';
import {PLAYER_DISPLAY_MODE, PLAYER_MODE} from '@src/app/components/streamdust-player/constants/playerMode';
import {BehaviorSubject, of} from 'rxjs';
import {CHAT_MODE} from '@src/app/components/chat/chat.component';
import {ChatService} from '@src/app/services/chat/chat.service';
import {DOCUMENT} from '@angular/common';
import {AuthService} from '@src/app/services/auth/auth.service';

@Component({
    selector: 'app-embedded-stream-page',
    templateUrl: './embedded-stream-page.component.html',
    styleUrls: ['./embedded-stream-page.component.sass'],
    providers: [ChatService]
})
export class EmbeddedStreamPageComponent implements OnInit {
    public streamId: string;
    public MEDIA_TYPE = MEDIA_TYPE;
    public CHAT_MODE = CHAT_MODE;
    public PLAYER_MODE = PLAYER_MODE;
    public PLAYER_DISPLAY_MODE = PLAYER_DISPLAY_MODE;
    public mediaType: MEDIA_TYPE;
    public loading: boolean;

    constructor(public streamService: StreamService,
                private readonly destroy$: AutoDestroyService,
                private readonly activatedRoute: ActivatedRoute,
                @Inject(DOCUMENT) private document: any,
                private router: Router,
                private route: ActivatedRoute,
                public authService: AuthService,
    ) {
    }

    ngOnInit(): void {
        this.document.body.classList.add('embedded-mode');
        this.loadData();
        this.router.events.pipe(takeUntil(this.destroy$)).subscribe((e: any) => {
            if (e instanceof NavigationEnd) {
                // window.scrollTo({top: 0, behavior: 'smooth'});
                this.loadData();
            }
        });

        this.route.queryParams.subscribe(params => {
            const fid = encodeURIComponent(params['fid']);
            const hash = encodeURIComponent(params['hash']);
            const token = this.authService.getAuthToken();
            if (fid && hash && this.streamId && !token) {
                this.authService.loginByForeignId(this.streamId, fid, hash)
                    .pipe(
                        takeUntil(this.destroy$),
                        catchError((error) => of(error))
                    ).subscribe(response => {
                    if (response && response.success) {
                        window.location.reload();
                    }
                });
            }
        });
    }

    private loadData(): void {
        this.loading = true;
        this.activatedRoute.params.pipe(
            take(1),
            tap(({id}: Params) => {
                this.streamId = id;
                this.mediaType = this.resolveMediaType();
            })).subscribe(() => {
                this.streamService.connectStreamStatusWebsocket(this.streamId);
                setTimeout(() => this.loading = false, 500);
        });
    }

    private resolveMediaType(): MEDIA_TYPE {
        return (this.activatedRoute.data as BehaviorSubject<any>)?.value?.mediaType;
    }

}
