import {Injectable} from '@angular/core';
import {ApiService} from "ui-elements";
import {Observable} from "rxjs";
import {ITimerange} from "@src/app/models/stream.model";
import {API_URLS_APP, UrlGenerator} from "@src/app/constants/api-urls.constant";
import {pluck, tap} from 'rxjs/operators';
import {IPagedResponse, IResponse, PagingParams, SortParam} from "@src/app/models/response.model";
import {DEFAULT_PAGING} from "@src/app/services/video-on-demand/video-on-demand.service";
import {
    ISubscriptionTariffPlan, ISubscriptionTariffPlans,
    TARIFF_PLAN_PERIOD
} from '@src/app/components/tariff-plan-cards-list/subscription-plan-card/subscription-plan-card.component';
import {TARIFF_PLAN_TYPES} from "@src/app/components/tariff-plan-cards-list/tariff-plan-cards-list.component";
import {IPaymentReturnResponse, IStartPaymentReq} from "@src/app/services/payment/payment.service";

@Injectable({
    providedIn: 'root'
})
export class SubscriptionManageService {

    constructor(
        private apiService: ApiService
    ) {
    }

    public getCurrentSubscription(searchQuery = null, paging: PagingParams = DEFAULT_TABLE_PAGING, sort: SortParam[] = [], timeRange?: ITimerange): Observable<IPagedResponse<any>> {
        return this.apiService.post(API_URLS_APP.GET_SUBSCRIPTIONS_LIST, {
            paging,
            sort,
            timeRange,
            filters: [{
                field: 'ACTIVE',
                value: true
            }]
        });
    }

    public getSubscriptionTariffs(): Observable<{authorized: boolean, data: { tariffPlans: ISubscriptionTariffPlans}}> {
        return this.apiService.get(API_URLS_APP.GET_SUBSCRIPTION_TARIFFS)
        // return this.apiService.get('assets/mock-json/tariffs.json', {isWithoutRootUrl: true})
            .pipe(pluck('results'));
    }

    public getSubscriptionTariff(tariffId, tariffType: TARIFF_PLAN_TYPES): Observable<ISubscriptionTariffPlan> {
        return this.apiService.get(UrlGenerator.generate(API_URLS_APP.GET_TARIFF_PLAN, {tariffId, tariffType}))
            .pipe(pluck('results', 'data'));
    }

    public subscriptionStart(req: IStartPaymentReq): Observable<IResponse<{ result: IPaymentReturnResponse }>> {
        return this.apiService.post(UrlGenerator.generate(API_URLS_APP.START_SUBSCRIPTION, {}, {testFail: false}), req);
            // .pipe(tap((res) => { res.results.result.paymentStatus = PAYMENT_STATUS.FAILED; }));
    }

    public subscriptionActivate(transactionId): Observable<IResponse<{ result: IPaymentReturnResponse }>> {
        return this.apiService.post(UrlGenerator.generate(API_URLS_APP.SUBSCRIPTION_ACTIVATE, {}, {testFail: false}), {transactionId})
    }

    public subscriptionCancel(subscriptionId: string): Observable<boolean> {
        return this.apiService.put(UrlGenerator.generate(API_URLS_APP.SUBSCRIPTION_CANCEL, {subscriptionId}), {})
            .pipe(pluck('success'));
    }

    public subscriptionRenew(subscriptionId: string): Observable<boolean> {
        return this.apiService.put(UrlGenerator.generate(API_URLS_APP.SUBSCRIPTION_RENEW, {subscriptionId}), {})
            .pipe(pluck('success'));
    }

    public getSubscriptionPlanTypeTranslate(subscriptionType: SUBSCRIPTION_PLAN_TYPES): string {
        switch (subscriptionType) {
            case SUBSCRIPTION_PLAN_TYPES.FREE: {
                return 'subscription-manage.table.subscription-plan-type.free';
            }
            case SUBSCRIPTION_PLAN_TYPES.BEGINNER: {
                return 'subscription-manage.table.subscription-plan-type.beginner';
            }
            case SUBSCRIPTION_PLAN_TYPES.ADVANCED: {
                return 'subscription-manage.table.subscription-plan-type.advanced';
            }
            case SUBSCRIPTION_PLAN_TYPES.PROFESSIONAL: {
                return 'subscription-manage.table.subscription-plan-type.professional';
            }
        }
    }

    public getSubscriptionTypeTranslate(subscriptionType: TARIFF_PLAN_TYPES): string {
        switch (subscriptionType) {
            case TARIFF_PLAN_TYPES.SUBSCRIPTION: {
                return 'subscription-manage.table.subscription-type.subscription';
            }
            case TARIFF_PLAN_TYPES.BANDWIDTH: {
                return 'subscription-manage.table.subscription-type.bandwidth';
            }
            case TARIFF_PLAN_TYPES.STORAGE_SPACE: {
                return 'subscription-manage.table.subscription-type.storage-space';
            }
        }
    }

    public getSubscriptionPeriodTranslate(subscriptionPeriod: TARIFF_PLAN_PERIOD): string {
        switch (subscriptionPeriod) {
            case TARIFF_PLAN_PERIOD.WEEK : {
                return 'subscription-manage.table.subscription-period.week';
            }
            case TARIFF_PLAN_PERIOD.MONTH : {
                return 'subscription-manage.table.subscription-type.month';
            }
            case TARIFF_PLAN_PERIOD.YEAR : {
                return 'subscription-manage.table.subscription-type.year';
            }
        }
    }

}

export enum SUBSCRIPTION_PLAN_TYPES {
    FREE = 'FREE',
    BEGINNER = 'BEGINNER',
    ADVANCED = 'ADVANCED',
    PROFESSIONAL = 'PROFESSIONAL'
}

export enum PAYMENT_STATUS {
    PENDING = 'PENDING',
    FAILED = 'FAILED',
    SUCCESS = 'SUCCESS'
}

export const DEFAULT_TABLE_PAGING = {
    page: 0,
    itemsOnPage: 10
} as PagingParams;

// export interface IPaymentResult {
//     externalCardId?: string;
//     message: string;
//     paymentStatus: PAYMENT_STATUS
//     redirectUrl: string;
// }
