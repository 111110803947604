<!--<ul class="donation-list">-->
    <li *ngFor="let item of data">
        <div class="row row-padding-10 align-items-top">
            <ng-container *ngIf="!item.sum">
                <div class="col">
                    <form [formGroup]="forms[item.id]" class="border-form donations">
<!--                        <input #input-->
<!--                            formControlName="donation"-->
<!--                            cleaveMask-->
<!--                            [cleaveValue]="input.value"-->
<!--                            class="form-control" type="text" placeholder="Eingabe Summe">-->
                        <div class="form-group mb-0">
                            <ano-input formControlName="donation" #input
                                       [isEditMode]="true"
                                       [errors]="forms[item.id].get('donation').errors"
                                       [isTouched]="forms[item.id].get('donation').touched"
                                       [invalid]="forms[item.id].get('donation').invalid"
                                       [config]="{ inputType: INPUT_TYPES.CURRENCY_AMOUNT, placeholder: 'Eingabe Summe' | localize }"
                                       [isCleanable]="true">
                            </ano-input>
                        </div>
                        <p>{{item.name}}</p>
                    </form>
                </div>
                <div class="col-auto" (click)="donate(item, input.value, forms[item.id])">
                    <button class="btn btn-success btn-sm">spenden</button>
                </div>
            </ng-container>
            <ng-container *ngIf="item.sum">
                <div class="col mr-auto">
                    <h5 class="name">{{item.currency}} {{{amount: item.sum, currency: currency} | currency: true}}</h5>
                    <p>{{item.name}}</p>
                </div>
                <div class="col-auto" (click)="donate(item, item.sum)">
                    <button class="btn btn-success btn-sm">spenden</button>
                </div>
            </ng-container>
        </div>
    </li>
<!--</ul>-->
