<div class="panel panel-md">
    <div class="panel-header">
        <h2><b>{{config.title | localize}}</b></h2>
        <p class="text-sm">{{config.subtitle | localize}}</p>
    </div>
    <div class="panel-body bg-white">
        <div class="section-panel">
            <div class="section-panel-header">
                <h4><b>{{config.tableTitle | localize}}</b></h4>
            </div>
            <div class="section-panel-body">
                <app-overlays-table [mediaId]="mediaId" [mediaType]="mediaType" [config]="config"></app-overlays-table>
            </div>
        </div>
    </div>
</div>

