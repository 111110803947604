import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {VideoOnDemandPageComponent} from './video-on-demand-page.component';
import {SharedModule} from '@src/app/modules/shared/shared.module';
import {VideoOnDemandListComponent} from './components/video-on-demand-list/video-on-demand-list.component';
import {StreamPageModule} from "@src/app/pages/stream-page/stream-page.module";


@NgModule({
    declarations: [VideoOnDemandPageComponent, VideoOnDemandListComponent],
    imports: [
        CommonModule,
        SharedModule,
        StreamPageModule
    ]
})
export class VideoOnDemandPageModule {
}
