<div class="modal-wrapper bg-transparent">
    <div *ngIf="!isGroup || (authService.isAuthorized$ | async)" class="left-side">
        <ng-container *ngIf="isPayment">
            <ano-loader [class.transparent-loader]="true" [isOpen]="loading"></ano-loader>
            <app-payment-cards-list *ngIf="!loading" [controls]="paymentCardsListControls"
                                    [isTransparent]="true"
                                    [chargeImmediately]="false"
                                    [addNewCardModalSize]="12"
                                    (proceedPayment$)="proceedPayment($event)">
            </app-payment-cards-list>
        </ng-container>
        <ng-container *ngIf="!isPayment">
            <div
                 class="required-box second-info-box">
                <div class="form-check">
                    <input [(ngModel)]="checkModel.firstTerms"
                           [ngModelOptions]="{standalone: true}"
                           (change)="changeCheck('firstTerms')"
                           type="checkbox" class="form-check-input" id="privacyPolicy">
                    <label for="privacyPolicy"
                           class="form-check-label">{{'media-access-modal.leadgeneration.desc' | localize}}</label>
                </div>
            </div>

            <div class="required-box">
                <div class="form-check">
                    <input [(ngModel)]="checkModel.secondTerms"
                           [ngModelOptions]="{standalone: true}"
                           (change)="changeCheck('firstTerms')"
                           type="checkbox" class="form-check-input" id="privacyPolicyCheckbox1"
                    >
                    <label class="form-check-label" for="privacyPolicyCheckbox1">
                        {{'media-access-modal.privacy-policy.i-agree-with' | localize}} <a
                            href="javascript:void(0)"
                            (click)="showPopUp($event)">{{'media-access-modal.privacy-policy.right-of-withdrawal' | localize}}</a> {{'media-access-modal.privacy-policy.and-the' | localize}}
                        <a [href]="termsConditions.privacyPolicyUrl"
                           target="_blank">{{'media-access-modal.privacy-policy.privacy-policy' | localize}}</a> {{'media-access-modal.privacy-policy.privacy-policy-too' | localize}}
                        {{'media-access-modal.right-of-withdrawal.notes-on' | localize}}
                        <a
                                (click)="showPopUp($event)"
                                href="javascript:void(0)">{{'media-access-modal.right-of-withdrawal.right-of-withdrawal' | localize}}</a> {{'media-access-modal.right-of-withdrawal.of-the-organizer' | localize}}
                    </label>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="right-side" *ngIf="!isPayment">
        <ng-container>
            <ng-container *ngIf="authService.isAuthorized$ | async">
                <div class="user-box">
                    <div class="user-pic">
                        <img alt="mock user photo" *ngIf="!userPhoto" class="avatar" src="/assets/img/mock/no-user-photo.png">
                        <img alt="user photo" *ngIf="userPhoto" class="avatar" [src]="userPhoto">
                    </div>

                    <span class="name text-white">{{'media-access-modal.welcom' | localize}},
                <ng-container *ngIf="userFirstName || userLastName">
                    <span class="name text-white">{{userFirstName}} </span>
                    <span class="last-name text-white">{{userLastName}}!</span>
                </ng-container>
                <ng-container *ngIf="!(userFirstName || userLastName)">
                    <span class="name text-white">{{userEmail}}</span>
                </ng-container>
            </span>
                    <p>{{'media-access-modal.youAreNot' | localize}}
                        <ng-container *ngIf="userFirstName || userLastName">
                            <span class="text-white">{{userFirstName}} </span>
                            <span class="text-white">{{userLastName}}!</span>
                        </ng-container>
                        <ng-container *ngIf="!(userFirstName || userLastName)">
                            <span class="text-white">{{userEmail}}</span>
                        </ng-container>
                        ? <a href="javascript:void(0)" (click)="logout()" localize="media-access-modal.logoutLink"></a></p>
                </div>

                <ul class="list-mark-check">
                    <li class="text-white">{{'media-access-modal.loginInfo1' | localize}}</li>
                    <li class="text-white">{{'media-access-modal.loginInfo2' | localize}}</li>
                </ul>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="!(authService.isAuthorized$ | async)">
            <div class="area-login-header">
                <h3 class="title text-white">{{'media-access-modal.loginTitle' | localize}}</h3>
            </div>
            <div class="area-login-body">
                <ul class="list-mark-check">
                    <li class="text-white">{{'media-access-modal.loginInfo1' | localize}}</li>
                    <li class="text-white">{{'media-access-modal.loginInfo2' | localize}}</li>
                </ul>
            </div>
            <div class="user-login access-form">
                <form [formGroup]="userLoginForm" (ngSubmit)="loginUser()" class="login-form border-form col-12">
                    <div class="form-inputs">
                        <div class="form-group">
                            <ano-input
                                    formControlName="email"
                                    [invalid]="userLoginForm.get('email').invalid"
                                    [errors]="userLoginForm.get('email').errors"
                                    [isTouched]="userLoginForm.get('email').touched"
                                    [isEditMode]="true"
                                    [config]="{ inputType: INPUT_TYPES.INPUT, type: 'text', placeholder: 'payment-cards.heidelpay.email-input.placeholder', isRequired: true }"></ano-input>
                        </div>

                        <div class="form-group">
                            <ano-input
                                    formControlName="password"
                                    [invalid]="userLoginForm.get('password').invalid"
                                    [errors]="userLoginForm.get('password').errors"
                                    [isTouched]="userLoginForm.get('password').touched"
                                    [isEditMode]="true"
                                    [config]="{ inputType: INPUT_TYPES.INPUT, type: 'password', placeholder: 'media-access-modal.viewer-register-modal.password-placeholder', isRequired: true }"></ano-input>
                        </div>
                    </div>

                    <div class="action-buttons login-buttons">
<!--                        <ng-container>-->
<!--                            <a href="javascript:void(0);" (click)="cancel()"-->
<!--                               class="back-link">{{'media-access-modal.cancel-button-text.back' | localize}}</a>-->
<!--                        </ng-container>-->

                        <div class="col-auto" type="submit">
                            <button localize="media-access-modal.login-form.login-button"
                                    class="btn btn-primary btn-sm"></button>
                        </div>
                        <div class="col forgot-password">
                            <a #b2 class="link-form text-xs link" href="javascript:void(0);"
                               (click)="goForgotPassword()">{{'login.button.forgot_password' | localize: b2}}</a>
                        </div>
                    </div>
                    <p class="text-xs">
                        <span class="text-white">{{'media-access-modal.registrationDesc' | localize}}</span><br>
                        <a href="javascript:void(0);"
                           (click)="goRegistration()">{{'media-access-modal.registrationLink' | localize}}</a>
                    </p>
                </form>
            </div>
        </ng-container>
    </div>
</div>
