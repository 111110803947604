<section class="section mh-480">
    <div class="container">
        <app-token-expired-page [title]="'error.EMAIL_CONFIRMATION_TOKEN_IS_INVALID'"></app-token-expired-page>
        <!-- <div class="bg-light registration-complete">
            <app-info-box>
                <ng-container role="message" class="mb-0">
                    {{'error.EMAIL_CONFIRMATION_TOKEN_IS_INVALID' | localize}}
                </ng-container>
            </app-info-box>
        </div> -->
    </div>
</section>
