import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ITableConfig, TableComponent} from  'ui-elements';
import {IFilterListItem} from "@src/app/components/media-list/media-list.component";
import {BILL_TYPE} from "@src/app/services/bills/bills.service";
import {ITimeRangeConfig} from 'ui-elements';

@Component({
    selector: 'app-bills-table',
    templateUrl: './bills-table.component.html',
    styleUrls: ['./bills-table.component.css']
})
export class BillsTableComponent implements OnInit {
    @ViewChild('tableComponent', {static: true}) public table: TableComponent;
    @Input() public config: IBillsTableConfig<any>;
    @Input() public timeRangeConfig: ITimeRangeConfig;

    constructor() {
    }

    ngOnInit(): void {
    }

}

export interface IBillsTableConfig<T> {
    id: BILL_TYPE;
    groupTitle?: string;
    withSearch?: boolean;
    withFilters?: boolean;
    title?: string;
    tableConfig: ITableConfig<T>;
    filtersConfig?: IFilterListItem[];
    withTimeRange?: boolean;
}
