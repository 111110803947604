import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {IPDFConfig} from '@src/app/components/pdf-viewer/pdf-viewer.component';
import {IResizableElementButton, ResizableElementComponent} from '@src/app/components/resizable-element/resizable-element.component';
import {DEFAULT_CHAT_POSITION} from '@src/app/models/core.model';
import {PresentationState} from '@src/app/state/presentation-state';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {PresentationService} from '@src/app/services/file/presentation.service';
import {CHAT_MODE} from '@src/app/components/chat/chat.component';
import {IResizeEvent} from 'ngx-draggable-resize/lib/models/resize-event';
import {PLAYER_MODE} from '@src/app/components/streamdust-player/constants/playerMode';
import {takeUntil} from 'rxjs/internal/operators';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';


@Component({
    selector: 'app-pdf-viewer-modal',
    templateUrl: './pdf-viewer-modal.component.html',
    styleUrls: ['./pdf-viewer-modal.component.scss'],
    providers: [AutoDestroyService]
})
export class PdfViewerModalComponent implements OnInit {
    @Input() config: IPDFConfig;
    @ViewChild('resizableElement', {static: false}) private resizableElement: ResizableElementComponent;
    public fullscreenChat$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    closeButton: any;
    embeddedButton: IResizableElementButton;
    fullscreenButton: any;
    allowSending: any;
    embeddedChatMode: any;
    public dragPosition: { x: number; y: number } = DEFAULT_CHAT_POSITION;
    fullscreenChat: any;
    presentation = {
        fileId: null,
        slides: [],
    };
    showViewer = false;
    CHAT_MODE = CHAT_MODE;
    @Input() public mode: CHAT_MODE = CHAT_MODE.DEFAULT;
    @Input() public playerMode: PLAYER_MODE = PLAYER_MODE.PUBLISHER;
    private resizeSubject: Subject<any> = new Subject<any>();
    public resize$: Observable<any> = this.resizeSubject.asObservable();

    constructor(
        public presentationState: PresentationState,
        public presentationService: PresentationService,
        private destroy$: AutoDestroyService
    ) {
    }

    ngOnInit(): void {
        this.initButtons();

        this.presentation = this.config.presentation;
        this.showViewer = true;
        this.presentationState.activeState$.pipe(takeUntil(this.destroy$))
            .subscribe((res) => {
                if (res) {
                    this.clickShow();
                }
            });
    }

    public initButtons(): void {
        this.fullscreenButton = {
            show: this.mode !== CHAT_MODE.EMBEDDED,
            callback: () => this.toggleFullscreen()
        };
        this.embeddedButton = {
            show: this.mode !== CHAT_MODE.EMBEDDED,
            callback: () => this.toggleOverlay()
        };
        this.closeButton = {
            show: true,
            callback: () => this.presentationState.close()
        };
    }

    public toggleFullscreen(): void {
        this.mode = CHAT_MODE.DEFAULT;

        this.fullscreenChat = !this.fullscreenChat;
        this.fullscreenChat$.next(this.fullscreenChat);
        if (this.fullscreenChat) {
            document.body.classList.add('chat-full-screen');
        } else {
            document.body.classList.remove('chat-full-screen');
        }
    }

    clickShow() {
        this.presentationService.clickShow(this.config.presentation.id).pipe(takeUntil(this.destroy$)).subscribe((res) => {
        });
    }

    toggleOverlay(): void {
        if (this.mode === CHAT_MODE.EMBEDDED) {
            this.mode = CHAT_MODE.DEFAULT;
        } else {
            this.mode = CHAT_MODE.EMBEDDED;
        }

        this.fullscreenChat = false;
        this.fullscreenChat$.next(this.fullscreenChat);
        if (this.fullscreenChat) {
            document.body.classList.add('chat-full-screen');
        } else {
            document.body.classList.remove('chat-full-screen');
        }


        this.presentationState.toggleOverlay();
        this.showViewer = false;
        setTimeout(() => {
            this.showViewer = true;
        }, 0);
    }

    resize($event: IResizeEvent) {
        this.showViewer = false;
        setTimeout(() => {
            this.resizeSubject.next();
            this.showViewer = true;
        }, 0);
    }

    indexChanged(currentPage: any) {
        this.config.currentPage = currentPage;
    }
}
