import {IMediaTimerange} from '@src/app/models/stream.model';

export interface IRevenue {
    creditNotId?: string;
    currency: string;
    id: number;
    income: number;
    owner: {internalId: string;};
    internalId: string;
    payedOut: number;
    payoutDate: number;
    payoutDone: boolean;
    payoutLeft: number;
    price: number;
    productId: string;
    productType: string;
    publisherId: string;
    soldAmount: number;
    title: string;
}

export interface IRevenueTimerange {
    firstUnpaidMonth: IMediaTimerange;
    firstUnpaidYear: IMediaTimerange;
    options: {
        monthTimeRanges: IMediaTimerange[];
        yearTimeRanges: IMediaTimerange[];
    };
}

export enum REVENUE_STATUS {
    CREATED = 'CREATED',
    SUCCESS = 'SUCCESS',
    PAYMENT_FAILURE = 'PAYMENT_FAILURE',
    DECLINED_BY_USER = 'DECLINED_BY_USER',
    TIMEOUT = 'TIMEOUT'
}

export enum PURCHASE_TYPE {
    DONATION = 'DONATION',
    STREAM = 'STREAM',
    STREAM_GROUP = 'STREAM_GROUP',
    STREAM_RECORDING = 'STREAM_RECORDING',
    VIDEO_ON_DEMAND = 'VIDEO_ON_DEMAND',
    SUBSCRIPTION = 'SUBSCRIPTION',
    STORAGE_SPACE = 'STORAGE_SPACE',
    BANDWIDTH = 'BANDWIDTH',
    MULTIBITRATE = 'MULTIBITRATE',
    SLOT = 'PAYMENT_SLOT'
}
