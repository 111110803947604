import { SUBSCRIPTION_PLAN_TYPES } from './../subscription-manage/subscription-manage.service';
import { API_URLS_APP } from './../../constants/api-urls.constant';
import { Injectable } from '@angular/core';
import {ApiService} from 'ui-elements';
import {Observable} from 'rxjs';
import {pluck} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CalculateTariffsService {

  constructor(private apiService: ApiService) { }

  public getFees(): Observable<ICalculatorFees[]> {
    return this.apiService.get(API_URLS_APP.PAYMENT_FEES).pipe(pluck('results', 'data'));
  }

  public getVatZones(): Observable<ICalculatorVats[]> {
    return this.apiService.get(API_URLS_APP.PAYMENT_VATS).pipe(pluck('results', 'data'));
  }
}

export interface ICalculatorFees {
  streamdustFees : IFee[],
  transactionFees: IFee[],
  subscriptionPlan: SUBSCRIPTION_PLAN_TYPES
}

export interface IFee {
  currency: string,
    initialValue: number,
    percent: number
}

export interface ICalculatorVats {
  value: number,
  country: string
}
