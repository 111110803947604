import {Component, Inject, OnInit} from '@angular/core';
import {INPUT_TYPES} from 'ui-elements';
import {FormBuilder, Validators} from '@angular/forms';
import {StreamService} from '@src/app/services/stream-metadata/stream.service';
import {IVoucherRequest} from '@src/app/models/stream.model';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-coupons-modal',
  templateUrl: './coupons-modal.component.html',
  styleUrls: ['./coupons-modal.component.sass']
})
export class CouponsModalComponent implements OnInit {
  public INPUT_TYPES = INPUT_TYPES;
  public form = this.fb.group({
    amount: ['', [Validators.required, Validators.max(50000), Validators.min(1)]]
  });

  constructor(
      public dialogRef: MatDialogRef<CouponsModalComponent>,
      private fb: FormBuilder,
      private streamService: StreamService,
      @Inject(MAT_DIALOG_DATA) public data: {streamId: string},
      private destroy$: AutoDestroyService
      ) { }

  ngOnInit() {
  }

  public close(): void {
    this.dialogRef.close();
  }

  public submit(): void {
    if (this.form.invalid) {
      return;
    }
    const body: IVoucherRequest = {
      streamId: this.data.streamId,
      amount: this.form.get('amount').value
    };
    this.streamService.createVoucher(body)
        .pipe()
        .subscribe(res => {
          if (res.success) {
            this.dialogRef.close();
          }
        });
  }
}
