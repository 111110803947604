import {EventEmitter, Injectable} from '@angular/core';
import {ActivatedRoute, ActivationEnd, NavigationEnd, Router, RouterEvent, RoutesRecognized} from '@angular/router';
import {filter, pairwise, takeUntil} from 'rxjs/operators';
import {HeaderService} from '@src/app/services/header/header.service';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import {NavService} from '@src/app/services/nav/nav.service';
import {MenuItem} from 'primeng/api/menuitem';
import {AuthService} from '@src/app/services/auth/auth.service';
import {scrollToTop} from '@src/app/utils/scroll.util';

@Injectable({
    providedIn: 'root'
})
export class RouterEventHandlerService {
    static readonly ROUTE_DATA_BREADCRUMB = 'breadcrumb';
    public activatedRouteData: { [key: string]: any } = {};
    public urlStack: string[] = [];
    public currentRoute: EventEmitter<string> = new EventEmitter();
    public previousUrl: string;
    public currentUrl: string;
    public backPressed = false;
    public urlRemoveEmitter: EventEmitter<string> = new EventEmitter();
    private urlToRemove: string;
    public menuItems: MenuItem[];
    public rightMenu = false;
    public _rightMenu = false;

    constructor(
        public router: Router,
        private headerService: HeaderService,
        private autoDestroy$: AutoDestroyService,
        private navService: NavService,
        private activatedRoute: ActivatedRoute,
        private authService: AuthService
    ) {
        this.router.events
            .pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
            .subscribe((events: RoutesRecognized[]) => {
            });
        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe(() => {
                this.authService.setExpirationDate();
                this.menuItems = this.createBreadcrumbs(this.activatedRoute.root);
                this.rightMenu = this._rightMenu;
                this._rightMenu = false;
            });
        this.router.events.subscribe((event: RouterEvent) => {
            if (event instanceof ActivationEnd) {
                if (event?.snapshot?.routeConfig?.children?.length) {
                    return;
                }
                this.activatedRouteData = event.snapshot.data;
                scrollToTop(this.activatedRouteData.scrollTop);
            }
        });
    }

    goToPreviousUrl() {
        this.router.navigate(
            [this.urlStack ?
                this.urlStack[this.urlStack.length - 1]
                : 'home']);
    }

    private createBreadcrumbs(route: ActivatedRoute, url: string = '', breadcrumbs: MenuItem[] = []): MenuItem[] {
        this._rightMenu = route.routeConfig && route.routeConfig.data && route.routeConfig.data[ 'rightMenu' ];
        const label = route.routeConfig && route.routeConfig.data && route.routeConfig.data[ 'breadcrumb' ];
        // const label = route.routeConfig && route.routeConfig.data[ 'breadcrumb' ];
        const path = route.routeConfig && route.routeConfig.path;
        let breadcrumb;
        const nextUrl = `${url}${path || ''}/`;
        if (label && path) {
            breadcrumb = {
                label: label,
                url: nextUrl
            };
        }
        const newBreadcrumbs = label && path ? [ ...breadcrumbs, breadcrumb ] : breadcrumbs;

        if (route.firstChild) {
            return this.createBreadcrumbs(route.firstChild, nextUrl, newBreadcrumbs);
        }
        // if (newBreadcrumbs.length === 2 && newBreadcrumbs[0].label === newBreadcrumbs[1].label) {
        //     newBreadcrumbs.splice(1, 0);
        // }
        return newBreadcrumbs;
    }
}
