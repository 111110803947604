import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@src/app/modules/shared/shared.module';
import { commonDeclarations, commonImports, commonExports } from '@src/app/pages/registration-page/registration-page.common';
import { ReactiveFormsModule } from '@angular/forms';
import { ViewerComponent } from './components/viewer/viewer.component';
import { PublisherComponent } from './components/publisher/publisher.component';
import { PublisherViewerCardsComponent } from './components/publisher-viewer-cards/publisher-viewer-cards.component';

@NgModule({
  declarations: [
    ...commonDeclarations,
    ViewerComponent,
    PublisherComponent,
    PublisherViewerCardsComponent,
  ],
  imports: [
    ...commonImports,
    CommonModule,
    SharedModule,
    ReactiveFormsModule
  ],
  exports: [
    ...commonExports,
  ],
})
export class RegistrationPageModule { }
