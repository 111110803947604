import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FileChangeEvent } from '@angular/compiler-cli/src/perform_watch';
import { SnackBarService } from 'ui-elements';
import bytesToMegabytes from '@src/app/utils/bytes-to-megabytes.util';

@Component({
  selector: 'app-file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.sass'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FileInputComponent),
    multi: true,
  }],
})
export class FileInputComponent implements ControlValueAccessor {

  @Input() public label = '';
  @Input() public isMulti: boolean = false;
  @Input() private maxFileSize = Infinity;
  @Input() public acceptTypes = '';

  public value: File | File[] = this.isMulti ? [] : undefined;

  public get isEmpty(): boolean {
    return (this.value instanceof Array && !this.value.length) || !this.value;
  }

  public constructor(private snackBarService: SnackBarService) { }

  public writeValue(value: File | File[]): void {
    this.value = value;
    this.onChange(this.value);
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public registerOnChange(fn: (value: File | File[]) => void): void {
    this.onChange = fn;
  }

  public addFile({ target: { files } }: any): void {
    const filesToUpload: File[] = <File[]>Array.from(files)
      .filter((file: File) => file.size < this.maxFileSize);

    const lengthDifference = files.length - filesToUpload.length;

    if (lengthDifference) {
      this.snackBarService
        .open(
          `Maximum file size is ${bytesToMegabytes(this.maxFileSize)} MB.
          ${lengthDifference} file${lengthDifference > 1 ? 's' : ''} removed.`,
        );
    }

    if (this.value instanceof Array) {
      this.writeValue(this.value.concat(filesToUpload));
      return;
    }

    this.writeValue(filesToUpload[0]);
  }

  private onTouched(): void { }

  private onChange(value: File | File[]): void { }
}
