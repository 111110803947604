<div class="wrapper-content bg-light">
    <div class="payment-details">
        <h2 (click)="cancelPayment()" class="body-title mb-25 cursor-pointer"><i
                class="icon icon-back-arrow"></i><b>{{'subscription-upgrade.title' | localize}}</b></h2>
        <p>{{'subscription-upgrade.body' | localize}}</p>

        <div class="wrapper-payment-overview">
            <div class="row row-padding-10">
                <div class="col">
                    <div class="payment-overview-box">
                        <app-checkout-details [tariffPlan]="subscriptionToBuy"
                                              [title]="tariffPlanTitle"
                                              [details]="tariffPlanDetails"
                                              [planPeriod]="tariffPlansConfig?.planPeriod"></app-checkout-details>
                    </div>
                </div>

                <div class="col-auto">
                    <app-payment-check [orderTitle]="tariffPlanTitle"
                                       [orderPrice]="subscriptionToBuy?.prices"
                                       [planPeriod]="tariffPlansConfig?.planPeriod"></app-payment-check>
                </div>
            </div>
        </div>

        <div class="row row-padding-10">
            <div class="col">
                <div class="payment-cards-list-wrapper">
                    <app-payment-cards-list #paymentCardsList
                                            id="paymentCardsList"
                                            *ngIf="subscriptionToBuy"
                                            [onlyCard]="true"
                                            [addNewCardModalSize]="6"
                                            [controls]="paymentCardsListControls"
                                            [agreementText]="!subscriptionToBuy?.boughtInPast && agreementText"
                                            (proceedPayment$)="startSubscription($event)">
                    </app-payment-cards-list>
                </div>
            </div>
            <div class="col-auto">
                <div class="payment-overview-box summary"></div>
            </div>
        </div>
    </div>
</div>
