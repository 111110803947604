import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {API_URLS_APP, UrlGenerator} from '@src/app/constants/api-urls.constant';
import {IPagedResponse, IResponse, PagingParams, SortParam} from '@src/app/models/response.model';
import {
    IFileUploadingResponse,
    IPresentationActiveRequest,
    IPresentationList,
    IPresentationRequest
} from '@src/app/services/file/presentation.service';
import {ApiService} from 'ui-elements';
import {HttpClient} from '@angular/common/http';
import {map, pluck} from 'rxjs/operators';
import {MEDIA_TYPE} from '@src/app/components/streamdust-player/constants/mediaType';
import {CmsService, IUnitKeyValue} from 'ui-elements';

@Injectable({
    providedIn: 'root'
})
export class OverlayLocationService {
    private cities: IUnitKeyValue[];

    public constructor(
        private apiService: ApiService,
        private http: HttpClient,
        private cmsService: CmsService,
    ) {
    }

    public create(body: IOverlayLocationRequest, isMocked: boolean = false): Observable<IResponse> {
        return this.apiService.post(API_URLS_APP.OVERLAY_LOCATION_CREATE, body);
    }

    public update(body: IOverlayLocationRequest, isMocked: boolean = false): Observable<IResponse> {
        return this.apiService.put(API_URLS_APP.OVERLAY_LOCATION_UPDATE, body);
    }

    public active(body: IOverlayLocationActiveRequest): Observable<IResponse> {
        return this.apiService.put(API_URLS_APP.OVERLAY_LOCATION_ACTIVE, body);
    }

    public delete(locationId: string): Observable<IResponse> {
        return this.apiService.delete(UrlGenerator.generate(API_URLS_APP.OVERLAY_LOCATION_DELETE, {locationId}));
    }

    getCities(countryCode, isMocked) {
        // if (isMocked) {
        //     let res;
        //     if (+countryId > 50) {
        //         res = [
        //             {
        //                 id: '14',
        //                 name: 'Kiev',
        //                 latitude: 50.4021702,
        //                 longitude: 30.392609
        //             },
        //             {
        //                 id: '2',
        //                 name: 'Dnepr',
        //                 latitude: 48.4623911,
        //                 longitude: 34.9303171
        //             },
        //         ];
        //     } else {
        //         res = [
        //             {
        //                 id: '77',
        //                 name: 'London',
        //                 latitude: 51.509865,
        //                 longitude: -0.118092
        //             },
        //             {
        //                 id: '22',
        //                 name: 'Liverpool',
        //                 latitude: 53.41058,
        //                 longitude: -2.97794
        //             },
        //             {
        //                 id: '11',
        //                 name: 'Manchester',
        //                 latitude: 53.478062,
        //                 longitude: -2.244666
        //             },
        //         ];
        //     }
        //
        //     return this.apiService.post('mock', {
        //         request: {},
        //         // response: null
        //         response: res
        //     }).pipe(pluck('results', 'data'));
        // }
        // return this.apiService.get(API_URLS_APP.MEDIA_INFO_FROM_SUBDOMAIN)
        //     .pipe(pluck('results', 'data'));

        return this.apiService.get(UrlGenerator.generate(API_URLS_APP.GET_CITY_BY_COUNTRY, {countryId: countryCode}));
    }

    getCitiesSelectOptions(city) {
        this.cities = this.cmsService.mapCountriesToKeyValue(city).sort((a, b) => a.value < b.value ? -1 : 1);
        return this.cities;
        // this.cmsService.mapCitiesToKeyValue();
    }

    public getList(overlayId: string,
                   searchQuery = '',
                   paging: PagingParams = DEFAULT_PAGING,
                   sort: SortParam[] = DEFAULT_SORTING,
                   isMocked: boolean = false
    ): Observable<IPagedResponse<IPresentationList>> {
        if (!overlayId) {
            return of({
                success: true,
                errorKey: null,
                results: {data: {items: [], paging: {items: 0, itemsOnPage: 0, page: 0}, sort: null}}
            });
        }
        return this.apiService.post(UrlGenerator.generate(API_URLS_APP.GET_OVERLAY_LIST, {overlayId}), {
            paging,
            sort,
            searchTerm: searchQuery
        });
    }

    getRadius(): IUnitKeyValue[] {
        const arr = [];
        for (let i = 10; i <= 100; i += 10) {
            arr.push(
                {
                    value: `+${i} km`,
                    key: i,
                }
            );
        }

        return arr;
    }
}

const DEFAULT_PAGING = {
    page: 0,
    itemsOnPage: 10
} as PagingParams;

const DEFAULT_SORTING = [{
    field: 'COUNTRY',
    order: 'ASC'
}] as SortParam[];

export interface IOverlayLocationConfig {
    overlayId?: string;
}

export declare interface InitConfig {
    initForms(): void;

    initFormsConfig(): void;

    initTableConfig(): void;
}

export interface IOverlayLocationRequest {
    id?: string;
    country: string;
    city: string;
    radius: string;
    overlayId: string;
}

export interface IOverlayLocationActiveRequest {
    locationId: string;
    active: boolean;
}

export const GOOGLE_MAPS = {
    minZoom: 0,
    maxZoom: 19,
};
