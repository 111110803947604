<div class="content">
    <div class="panel">
        <div class="panel-header pb-0">
            <h2 class="title-lg"><b>{{(userType === USER_TYPE.PUBLISHER ? 'bank.title' : 'bank.title.viewer') | localize}}</b></h2>
        </div>
    </div>
</div>
<div class="payment-cards-list-wrapper">
    <app-payment-cards-list [controls]="paymentCardsListControls" [onlyCard]="true"></app-payment-cards-list>
</div>
<!--<ano-form-->
<!--        [form]="form"-->
<!--        [title]="'bank.cardTitle'"-->
<!--        [description]="'bank.description'"-->
<!--        [fieldsConfig]="fieldsConfig"-->
<!--        (submit$)="saveChanges()"-->
<!--        [submitButtonText]="'Änderungen Speichern'">-->
<!--</ano-form>-->
<ano-form class="back-details" *ngIf="userType === USER_TYPE.PUBLISHER"
          [form]="bankForm"
          [title]="'bank.bankAccount'"
          [description]="'bank.bankAccount.description'"
          [fieldsConfig]="bankFieldsConfig"
          [mainColMd7]="true"
          [buttonsCol5]="true"
          (submit$)="saveChanges()"
          [submitButtonText]="'Änderungen Speichern'">
</ano-form>
