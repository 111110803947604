import {Injectable} from '@angular/core';
import {ApiService} from 'ui-elements';
import {IPagedResponse, IResponse} from '@src/app/models/response.model';
import {Observable} from 'rxjs';
import {API_URLS_APP, UrlGenerator} from '@src/app/constants/api-urls.constant';
import {ISubAccount} from '@src/app/pages/subaccounts-page/model/sub-account.model';
import {pluck, tap} from 'rxjs/operators';
import {PagingParams, SortParam} from '../../models/response.model';
import {CustomValidators} from '@src/app/utils/custom-validators.util';
import {IFormConfig, IFormFieldsConfig} from '@src/app/services/stream-metadata/stream.service';
import {FormUtil} from '@src/app/utils/form.util';
import {INPUT_TYPES} from 'ui-elements';
import {FormControl, Validators} from '@angular/forms';
import {StreamMetadataSelectValues} from '@src/app/models/stream.model';
import {asBoolean} from '@src/app/utils/string.util';
import {IUserProfile} from '@src/app/pages/user-profile/components/profile/services/profile.service';

@Injectable({providedIn: 'root'})
export class SubAccountsService {

    constructor(private apiService: ApiService) {
    }

    public create(body: ISubAccount): Observable<IResponse<any>> {
        return this.apiService.post(API_URLS_APP.SUBACCOUNT_SAVE, body);
    }

    public update(body: ISubAccount): Observable<IResponse<any>> {
        return this.apiService.put(API_URLS_APP.SUBACCOUNT_SAVE, body);
    }

    public get(id: any): Observable<ISubAccount> {
        return this.apiService.get(UrlGenerator.generate(API_URLS_APP.SUBACCOUNT, {id}))
            .pipe(pluck('results', 'results'));
    }

    public list(searchQuery = '', paging: PagingParams = DEFAULT_PAGING, sort: SortParam[] = DEFAULT_SORTING): Observable<IPagedResponse<ISubAccount>> {
        return this.apiService.post(API_URLS_APP.SUBACCOUNTS_LIST, {
            searchTerm: searchQuery,
            paging: paging,
            sort: sort
        });
    }

    public linked(searchQuery = '', paging: PagingParams = DEFAULT_PAGING, sort: SortParam[] = DEFAULT_SORTING): Observable<IPagedResponse<ISubAccount>> {
        return this.apiService.post(API_URLS_APP.SUBACCOUNTS_LINKED_LIST, {
            searchTerm: searchQuery,
            paging: paging,
            sort: sort
        });
    }

    public delete(id: any): Observable<IResponse<any>> {
        return this.apiService.delete(UrlGenerator.generate(API_URLS_APP.SUBACCOUNT_DELETE, {id}));
    }

    public sendInvite(email: string): Observable<IResponse<any>> {
        return this.apiService.post(API_URLS_APP.SUBACCOUNT_INVITE, {email: email});
    }

    public hasConnectedPublishers(): Observable<boolean> {
        return this.apiService.get(API_URLS_APP.SUBACCOUNT_HAS_CONNECTED).pipe(pluck('results', 'has'));
    }

    public acceptInvite(token: string): Observable<IUserProfile> {
        return this.apiService.post(API_URLS_APP.SUBACCOUNT_ACCEPT, {token: token})
            .pipe(pluck('results', 'result'));
    }

    public logoutAs(): Observable<ISubAccountAuth> {
        return this.apiService.post(API_URLS_APP.LOGOUT_AS, {})
            .pipe(pluck('results'));
    }

    public loginAs(accountId: string): Observable<ISubAccountAuth> {
        return this.apiService.post(API_URLS_APP.LOGIN_AS, {accountId: accountId})
            .pipe(pluck('results'));
    }

    public logoutIfSubaccountOpenedInNewTab(): Observable<ISubAccountAuth>  {
        const perfNavigation = performance.getEntriesByType('navigation');
        if ((perfNavigation[0] as PerformanceNavigationTiming).type === 'reload') {
            return;
        }


        return this.logoutAs();
    }

    public getFormConfig(): IFormConfig {
        return {
            name: 'subaccount',
            formFields: this.getFieldsConfig(),
            form: FormUtil.buildForm(this.getFieldsConfig()),
            isDefault: true
        };
    }

    public getFieldsConfig(): IFormFieldsConfig[][] {
        return [
            [
                {
                    name: 'firstName',
                    control: ['', [Validators.required]],
                    config: {
                        inputType: INPUT_TYPES.INPUT,
                        placeholder: 'subaccounts.firstName',
                        isRequired: true
                    }
                },
                {
                    name: 'lastName',
                    control: ['', [Validators.required]],
                    config: {
                        inputType: INPUT_TYPES.INPUT,
                        placeholder: 'subaccounts.lastName',
                        isRequired: true
                    }
                },
            ],
            [
                {
                    name: 'email',
                    control: ['', Validators.compose([Validators.required, CustomValidators.email])],
                    config: {
                        inputType: INPUT_TYPES.INPUT,
                        placeholder: 'subaccounts.email',
                        isRequired: true
                    }
                }
            ]
        ];
    }

    public getPasswordFormConfig(): IFormConfig {
        return {
            name: 'password',
            formFields: this.getPasswordFieldsConfig(),
            form: FormUtil.buildForm(this.getPasswordFieldsConfig()),
            isDefault: true
        };
    }

    public getPasswordFieldsConfig(): IFormFieldsConfig[][] {
        const passwordControl = new FormControl('',
            [Validators.required, CustomValidators.password]);
        return [
            [

                {
                    name: 'password',
                    control: passwordControl,
                    config: {
                        inputType: INPUT_TYPES.INPUT,
                        type: 'password',
                        placeholder: 'subaccounts.password',
                        isRequired: true
                    }
                }
            ],
            [
                {
                    name: 'confirmPassword',
                    control: ['', [Validators.required, CustomValidators.passwordsMatch(passwordControl)]],
                    config: {
                        inputType: INPUT_TYPES.INPUT,
                        type: 'password',
                        placeholder: 'subaccounts.confirmPassword',
                        isRequired: true
                    }
                }
            ]
        ];
    }
}


const DEFAULT_PAGING = {
    page: 0,
    itemsOnPage: 10
} as PagingParams;

const DEFAULT_SORTING = [{
    field: 'firstName',
    order: 'ASC'
}] as SortParam[];

export interface ISubAccountAuth {
    authToken: string;
    email: string;
}
