import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  ElementRef, EventEmitter,
  Input,
  OnDestroy,
  OnInit, Output,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {StreamService} from '@src/app/services/stream-metadata/stream.service';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import {debounceTime, pluck, takeUntil, tap} from 'rxjs/operators';
import {MEDIA_TYPE} from '@src/app/components/streamdust-player/constants/mediaType';
import {PLAYER_DISPLAY_MODE, PLAYER_MODE} from '@src/app/components/streamdust-player/constants/playerMode';
import {forkJoin, fromEvent, ReplaySubject} from 'rxjs';
import {
  IMediaContentRequest,
  MediaOverviewData
} from '@src/app/models/stream.model';
import {ChatState} from '@src/app/state/chat-state';
import {CHAT_MODE, ChatComponent} from '@src/app/components/chat/chat.component';
import {Subject} from 'rxjs/internal/Subject';
import {NavigationEnd, Router} from '@angular/router';
import {ChatService, IChatResponse} from '@src/app/services/chat/chat.service';
import {UserService} from '@src/app/services/user/user.service';
import {AuthService} from '@src/app/services/auth/auth.service';

import {IPlayerConfig} from 'ui-elements';
import {IResponse} from '@src/app/models/response.model';


@Component({
  selector: 'app-chat-wrapper',
  templateUrl: './chat-wrapper.component.html',
  styleUrls: ['./chat-wrapper.component.sass'],
  providers: [AutoDestroyService, ChatService]
})
export class ChatWrapperComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('videoWrapper', {static: true}) videoWrapper: ElementRef<HTMLElement>;
  @ViewChild('chatTmpl', {static: true, read: ViewContainerRef}) chatTmpl: ViewContainerRef;

  @Input() playerDisplayMode: PLAYER_DISPLAY_MODE = PLAYER_DISPLAY_MODE.DEFAULT;
  @Input() mediaId: string;
  @Input() mediaType: MEDIA_TYPE;
  @Input() mode: PLAYER_MODE;
  @Input() embeddedChatMode: CHAT_MODE = CHAT_MODE.OVERLAY;
  @Input() embedded = false;
  @Output() grantAccess$: EventEmitter<boolean> = new EventEmitter<boolean>();

  public loading = true;
  public PLAYER_DISPLAY_MODE = PLAYER_DISPLAY_MODE;

  public data: MediaOverviewData;
  public config: Partial<IPlayerConfig>;
  private chatEnabled: boolean;
  componentRefs: IComponentRef[];
  private fullScreen$: Subject<boolean> = new Subject<boolean>();
  private refsInitialized: boolean;
  public _refs: { ref: HTMLElement; hide: boolean; }[];
  private _refsDestroyer$: Subject<void> = new Subject<void>();

  private chatSocketConnection$: ReplaySubject<IResponse<IChatResponse>>;

  constructor(
      public chatState: ChatState,
      private chatService: ChatService,
      public streamService: StreamService,
      private readonly destroy$: AutoDestroyService,
      private readonly router: Router,
      private userService: UserService,
      private authService: AuthService,
      private componentFactoryResolver: ComponentFactoryResolver,
  ) {
  }

  ngOnInit(): void {
    this.chatState.toggleChat();
    this.initState();
    this.initListeners();
    this.connectToSockets();
    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.initState();
      }
    });
  }

  ngAfterViewInit(): void {
    this.refreshContainerSize();
    fromEvent(window, 'resize')
        .pipe(
            takeUntil(this.destroy$),
            debounceTime(200)
        )
        .subscribe(() => {
          this.refreshContainerSize();
        });
  }

  ngOnDestroy(): void {
    this.streamService.closeStreamStatusWebsocket();
  }

  private initState(): void {
    this.initMediaInfo();
  }

  private initListeners(): void {
    this.listenChatStatus();
  }

  public initMediaInfo() {
    this.loading = true;
    forkJoin({
      mediaInfo: this.streamService.getStreamLandingInfo(this.mediaType, this.mediaId).pipe(pluck('results', 'data')),
    }).pipe(
        takeUntil(this.destroy$),
        tap(({mediaInfo}) => {
          this.data = mediaInfo;
          this.streamService.mediaOverview.next(mediaInfo);
        })
    )
        .subscribe(() => {
          this.initMediaUrl();
        });
  }

  public initComponentRefs(): void {
    this._refsDestroyer$.next();
    this.componentRefs = [
      {
        component: ChatComponent,
        initComponentFn: (instance: ChatComponent) => {
          instance.isOwner = this.mode === PLAYER_MODE.PUBLISHER;
          instance.mediaId = this.mediaId;
          instance.title = this.data?.video.title;
          instance.active = this.chatEnabled;
          instance.mode = this.embeddedChatMode;
          instance.playerDisplayMode = this.playerDisplayMode;
          instance.connection = this.chatSocketConnection$;
          instance.fullScreen$ = this.fullScreen$.asObservable();
          instance.isChatPage = true;
        },
        containerRef: this.chatTmpl
      },
    ];
  }

  private connectToSockets(): void {
    this.chatSocketConnection$ = this.chatService.connect(this.mediaId);
  }

  private listenChatStatus(): void {
    this.chatState.chatEnabledState$
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          this.chatEnabled = res;
        });
  }

  private refreshConfig(): void {
    this.config = {
      componentRefs: this.getComponentsRefs(),
    };
  }

  getComponentsRefs(): { ref: HTMLElement; hide: boolean; }[] {
    const res: any[] = [];
    this.componentRefs.filter((component) => !component.hide).forEach(component => {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component.component);
      const viewContainerRef = component.containerRef;
      viewContainerRef.clear();
      const componentRef = viewContainerRef.createComponent(componentFactory);
      const instance = (componentRef.instance);
      component.initComponentFn(instance);
      res.push({ref: componentRef.location.nativeElement, hide: component.hide});
    });
    this.refsInitialized = true;
    this._refs = res;
    return res;
  }

  private initMediaUrl(newStatus?) {
    const urlBody: IMediaContentRequest = {
      productId: this.mediaId,
      videoType: this.mediaType,
      originReferer: '',
    };
    if (document.location.ancestorOrigins !== undefined && document.location.ancestorOrigins.length) {
      urlBody.originReferer = document.location.ancestorOrigins[document.location.ancestorOrigins.length - 1];
    }

    if (this.streamService.viewerAccessToken) {
      urlBody.accessToken = StreamService.getViewerAccessTokenFromStorage();
    }
    this.streamService.getMediaUrl(urlBody)
        .pipe(takeUntil(this.destroy$))
        .subscribe((response) => {
          this.loading = false;
          this.data.video.status = newStatus ? newStatus : this.data.video.status;

          this.initComponentRefs();
          this.refreshConfig();
        });
  }

  private refreshContainerSize(): void {
    if (!this.videoWrapper) {
      return;
    }
    const height = Math.floor(this.videoWrapper.nativeElement.clientWidth * 0.5625);
    this.videoWrapper.nativeElement.style.height = height + 'px';
  }
}

interface IComponentRef {
  component: any;
  initComponentFn: (instance: any) => void;
  containerRef: ViewContainerRef;
  hide?: boolean;
}
