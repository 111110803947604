import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {IResizeEvent} from 'ngx-draggable-resize/lib/models/resize-event';
import {AngularResizableDirective} from 'ngx-draggable-resize';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-resizable-element',
  templateUrl: './resizable-element.component.html',
  styleUrls: ['./resizable-element.component.sass']
})
export class ResizableElementComponent implements OnInit {
  @ViewChild('resizableContainer', {static: false}) public resizableContainer: ElementRef<HTMLElement>;
  @ViewChild('scrollingContainer', {static: false}) public scrollingContainer: ElementRef<HTMLDivElement>;
  @Input() title: string;
  @Input() id: string;
  @Input() cssClass: string;
  @Input() hiddenClass: string;
  @Input() embeddedClass: string;
  @Input() fullScreenClass: string;
  @Input() fullscreenBodyClass: string;
  @Input() fullScreenDisabledClass: string;
  @Input() chatOpenedClass: string;
  @Input() fullscreen: IResizableElementButton;
  @Input() embedded: IResizableElementButton;
  @Input() close: IResizableElementButton;
  @Input() resizable: boolean;
  @Input() resizeDirection: string = RESIZABLE_DIRECTION.ALL;
  @Input() draggable: boolean;
  @Input() dragPosition: {x: number; y: number};
  @Input() bodyHidden: boolean;
  @Input() footerHidden: boolean;
  @Input() minHeight = 225;
  @Input() minWidth = 225;
  @Input() bodyOffset = 142;
  @Input() isTransparent: boolean;
  @Input() isChatPage = false;
  @Input() chatPageLink: string;
  public width: number;
  public height: number;

  @Output() dragEnd: EventEmitter<any> = new EventEmitter<any>();
  @Output() resize: EventEmitter<IResizeEvent> = new EventEmitter<IResizeEvent>();
  @Output() resizeEnd: EventEmitter<IResizeEvent> = new EventEmitter<IResizeEvent>();

  constructor() { }

  ngOnInit(): void {
  }

  public get bounds(): HTMLElement {
    return document.getElementById('resizable-bounds');
  }

  public onResize(evt: IResizeEvent): void {
    this.height = evt.size.height;
    this.width = evt.size.width;
    this.resize.emit(evt);
  }

  public onResizeEnd(evt: IResizeEvent): void {
    this.resizeEnd.emit(evt);
  }

  public onDragEnd(): void {
    this.dragEnd.emit();
  }

}

export interface IResizableElementButton {
  show: boolean;
  callback: () => void;
}

export enum RESIZABLE_DIRECTION {
  ALL = 'all',
  NORTH = 'n',
  EAST = 'e',
  SOUTH = 's',
  WEST = 'w',
  SOUTH_EAST = 'se',
  NORTH_EAST = 'ne',
  SOUTH_WEST = 'sw',
  NORTH_WEST = 'nw'
}

//n,e,s,w,se,ne,sw,nw
