<div class="panel panel-md">
    <div class="panel-header">
        <h2><b>{{'groupsOfLinks.title' | localize}}</b></h2>
        <p class="text-sm">{{'groupsOfLinks.subtitle' | localize}}</p>
    </div>
    <div class="panel-body bg-white padding-bottom-30" *ngIf="mediaId">
        <div class="section-panel">
            <div class="section-panel-header">
                <h4><b>{{'groupsOfLinks.table.title' | localize}}</b></h4>
            </div>
            <ano-table #tableComponent
                        [tableConfig]="tableConfig" 
                        [defaultSortField]="'LINKED_MEDIA_NAME'"
                        [sortOrder]="1"
                        [withSearch]="true"
                        [addButton]="(!((streamService?.stream$ | async)?.media?.status?.stream === STREAM_STATUS.FINISHED && !(streamService?.stream$ | async)?.media?.hasRecording) && !(streamService.stream$ | async)?.media?.deactivated) && ((streamService.stream$ | async)?.media?.status?.stream !== STREAM_STATUS.PENDING)"
                        [addButtonText]="'common.button.create'"
                        (addItem$)="openModal()"
                        (search$)="search($event)"
            ></ano-table>
        </div>
    </div>
</div>

<ng-template #statusTmpl let-data="rowData" class="ss-test">
    <div class="long-text">
        <app-toggle
                [value]="data.active"
                [config]="formConfig"
                (toggleEmit)="onToggle($event, data)"
        ></app-toggle>
    </div>
</ng-template>
<ng-template #titleTmpl let-data="rowData" class="ss-test">
    <div class="long-text text-left">
        <span>
            {{data.linkedMedia.title}}
        </span>
    </div>
</ng-template>
<ng-template #linksNameTmpl let-data="rowData" class="ss-test">
    <div class="long-text text-left">
        <span>
            {{data.linkedMediaName}}
        </span>
    </div>
</ng-template>
<ng-template #nameTmpl let-data="rowData" class="ss-test">
    <div class="long-text text-left">
        <span>
            {{data.name}}
        </span>
    </div>
</ng-template>
<ng-template #dateTmpl let-data="rowData">
    <div class="long-text text-left">
        {{data.created | dateFormat: 'DD MMM YYYY'}}
    </div>
</ng-template>
<ng-template #callTmpl let-data="rowData">
    <div class="text-right">
        {{data?.clickAmount | number}}
    </div>
</ng-template>
<ng-template #actionsTmpl let-data="rowData">
    <div class="table-actions-list" [class.disabled]="isDisable">
        <b class="text-primary" *ngIf="!((streamService.stream$ | async)?.media?.status?.stream === STREAM_STATUS.PENDING)">
            <i (click)="openModal(data)"
                class="icon icon-edit cursor-pointer"></i> |
        </b>
        <i *ngIf="!((streamService.stream$ | async)?.media?.status?.stream === STREAM_STATUS.PENDING)" (click)="deleteLink(data)"
            class="icon icon-trash text-danger cursor-pointer"></i>
    </div>
</ng-template>
<ng-template #confirm let-dialogRef="dialogRef">
    <ano-modal [dialogRef]="dialogRef" [title]="'groupsOfLinks.deleteModalTitle' | localize" [isConfirmation]="true">
        {{confirmText}}
    </ano-modal>
</ng-template>
