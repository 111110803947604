<div class="panel panel-md">
    <div class="panel-header">
        <h2><b>{{'survey.title' | localize}}</b></h2>
        <p>{{'survey.subtitle' | localize}}</p>
    </div>
    <div class="panel-body bg-white" *ngIf="data">
        <div class="section-panel">
            <div class="section-panel-header">
                <h2 class="title-level-3">
                    <b>{{'survey.activate.label' | localize}}</b>
                </h2>

                <form [formGroup]="activateForm">
                    <mat-slide-toggle [formControlName]="'active'" class="video-activation-toggle"></mat-slide-toggle>
                </form>
            </div>
            <div class="section-panel-body">
                <div class="row">
                    <div class="col-lg-8">
                        <ano-form [form]="form" [forcedViewMode]="true" [borderForm]="true" [fieldsConfig]="config" [withoutSave]="true" [materialForm]="true" [isLightForm]="true"></ano-form>
                        <ng-container *ngIf="data.startTime === null && mediaType === MEDIA_TYPE.STREAM && (streamService?.streamStatus$ | async)?.stream === STREAM_STATUS.LIVE">
                            <div class="form-group autostop-info-message">
                                <span class="error-message" *ngIf="data.manualStarted">Umfrage wurde gestartet und wird automatisch in {{data.duration | durationConvert}} hh:mm:ss beendet.</span>
                            </div>
                            <div class="btn-group">
                                <div class="row">
                                    <div class="col-auto">
                                        <button class="btn btn-success" (click)="start()" [disabled]="data.manualStarted">{{'common.button.start' | localize}}</button>
                                    </div>
                                    <div class="col-auto">
                                        <button class="btn btn-danger" (click)="stop()" [disabled]="!data.manualStarted">{{'common.button.stop' | localize}}</button>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-panel" [class.border-top]="data.startTime === null && mediaType === MEDIA_TYPE.STREAM && (streamService?.streamStatus$ | async)?.stream === STREAM_STATUS.LIVE">
            <div class="section-panel-header">
                <h4><b>{{data.question}}</b></h4>
            </div>
            <div class="section-panel-body">
                <app-simple-chart *ngIf="answersChartItems" [data]="answersChartItems"></app-simple-chart>
            </div>
            <div class="section-panel-footer">
                <div class="form-group row justify-content-between">
                    <div class="col">
                        <ng-container *ngIf="!([STREAM_STATUS.FINISHED].includes((streamService.streamStatus$ | async)?.stream))">
                        <button *ngIf="!data.resultsPublished"
                                (click)="publishResult()" class="btn btn-success" [disabled]="data.notComplete || data.manualStarted || data.inProgress">{{'survey.table.publish.button.show' | localize}}</button>
                            <button *ngIf="data.resultsPublished"
                                (click)="publishResult()" class="btn btn-danger">{{'survey.table.publish.button.hide' | localize}}</button>
                        </ng-container>
                    </div>
                    <div class="col text-right">
                        <button (click)="goToSurveyPage()" class="btn btn-secondary">{{'common.button.back' | localize}}</button>
                    </div>
                </div>
                <p class="mb-0">{{'survey.publish.description' | localize}}</p>
            </div>
        </div>
    </div>
</div>
