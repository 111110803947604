import {Component, OnInit} from '@angular/core';
import {IListFilterItem, IListQuery} from "@src/app/components/media-list/media-list.component";
import {ISuggestedMedia} from "@src/app/pages/public-media-page/public-media-page.component";
import {IPagedResponseResults} from "@src/app/models/response.model";
import {IMediaItem} from "@src/app/models/core.model";
import {
    FILTER_MEDIA_TYPES,
    IMediaListsConfig
} from "@src/app/components/media-list/media-list-header/media-list-header.component";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {AutoDestroyService} from "@src/app/services/auto-destroy-service/auto-destroy.service";
import {MediaGroupsService} from "@src/app/services/media-groups/media-groups.service";
import {MEDIA_FILTERS_FIELDS, MediaListService} from "@src/app/services/media-list/media-list.service";
import {mergeMap, pluck, takeUntil, tap} from "rxjs/operators";
import {forkJoin} from "rxjs";
import {LoadingService} from "@src/app/services/loading/loading.service";
import {IMediaGroup} from "@src/app/models/stream-groups.model";
import {UserService} from "@src/app/services/user/user.service";
import {IUserPublisher} from "@src/app/pages/user-profile/components/profile/services/profile.service";
import {StreamService} from "@src/app/services/stream-metadata/stream.service";

@Component({
    selector: 'app-public-group',
    templateUrl: './public-group.component.html',
    styleUrls: ['./public-group.component.scss']
})
export class PublicGroupComponent implements OnInit {


    groupId: string;
    group: IMediaGroup;

    groupStreamsImplicitFilters: IListFilterItem[];
    groupVideoImplicitFilters: IListFilterItem[];
    query: IListQuery = {
        paging: {
            page: 0,
            itemsOnPage: 6
        }
    };
    groupMediaListSettings: ISuggestedMedia[];
    groupStreamsList: IPagedResponseResults<IMediaItem>;
    groupVideosList: IPagedResponseResults<IMediaItem>;

    suggestedMediaListsConfig: IMediaListsConfig = {
        filtersCssClass: 'blue-theme-filter',
        cardBackgroundCssClass: 'bg-light',
        hideMediaPrice: true,
        basicSkeleton: true
    };

    userHasAccess: boolean;

    constructor(
        private activatedRoute: ActivatedRoute,
        private destroy$: AutoDestroyService,
        private mediaGroupsService: MediaGroupsService,
        private mediaListService: MediaListService,
        public loadingService: LoadingService,
        private router: Router,
        private userService: UserService,
        private streamService: StreamService
    ) {
    }

    ngOnInit(): void {
        this.loadingService.loadingStart();
        this.activatedRoute.params.pipe(
            tap(({id}: Params) => {
                if (!id) {
                    this.loadingService.loadingEnd();
                    return;
                }
                this.groupId = id;
                this.setGroupMediaQuery();
            }),
            mergeMap(({id}: Params) => {
                const accessToken = this.streamService.viewerAccessToken;
                return forkJoin({
                    groupData: this.mediaGroupsService.getGroup(id, true),
                    groupVideosList: this.mediaListService.getMediaList({
                        ...this.query,
                        filters: this.groupVideoImplicitFilters
                    }).pipe(pluck('results', 'data')),
                    groupStreamsList: this.mediaListService.getMediaList({
                        ...this.query,
                        filters: this.groupStreamsImplicitFilters
                    }).pipe(pluck('results', 'data')),
                    userHasAccess: this.mediaGroupsService.checkAccessToGroup(this.groupId, accessToken || '')
                })
            }),
            takeUntil(this.destroy$))
            .subscribe((_res) => {
                const groupData = (_res.groupData as { group: IMediaGroup, publisherInfo: IUserPublisher });
                this.userService.setPublisherHeaderPicture(groupData?.publisherInfo?.headerPicture, true);
                this.userHasAccess = _res.userHasAccess;
                this.group = groupData?.group;
                this.groupStreamsList = _res.groupStreamsList;
                this.groupVideosList = _res.groupVideosList;
                this.groupMediaListSettings = this.getGroupMediaSettings().filter(mediaSetting => mediaSetting.originalLength);
                this.loadingService.loadingEnd();
            });
    }

    setGroupMediaQuery(): void {
        this.groupStreamsImplicitFilters = [
            {
                field: MEDIA_FILTERS_FIELDS.GROUP_ID,
                value: this.groupId
            },
            {
                field: MEDIA_FILTERS_FIELDS.MEDIA_TYPE,
                value: FILTER_MEDIA_TYPES.STREAM
            }
        ];
        this.groupVideoImplicitFilters = [
            {
                field: MEDIA_FILTERS_FIELDS.GROUP_ID,
                value: this.groupId
            },
            {
                field: MEDIA_FILTERS_FIELDS.MEDIA_TYPE,
                value: [FILTER_MEDIA_TYPES.VIDEO_ON_DEMAND, FILTER_MEDIA_TYPES.STREAM_RECORDING]
            }
        ];
    }

    getGroupMediaSettings(): ISuggestedMedia[] {
        return [
            {
                title: 'group.media-list.streams',
                media: this.groupStreamsList,
                implicitFilters: this.groupStreamsImplicitFilters,
                fetchMethod: (filters: IListQuery) => this.mediaListService.getMediaList(filters),
                originalLength: this.groupStreamsList?.items?.length
            },
            {
                title: 'group.media-list.videos',
                media: this.groupVideosList,
                implicitFilters: this.groupVideoImplicitFilters,
                fetchMethod: (filters: IListQuery) => this.mediaListService.getMediaList(filters),
                originalLength: this.groupVideosList?.items?.length
            }
        ];
    }

    purchaseGroup(): void {
        this.router.navigate(['groups', this.groupId, 'get-access']);
    }

}
