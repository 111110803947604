<app-streamdust-player *ngIf="(streamService?.streamStatus$ | async)?.stream !== STREAM_STATUS.PENDING" [mediaId]="mediaId"
                       [mediaType]="mediaType"
                       [mode]="PLAYER_MODE.PUBLISHER"
></app-streamdust-player>
<div class="pending-container bg-white" *ngIf="streamStatus?.stream === STREAM_STATUS.PENDING || paymentLoading">
    <section class="section section-live-streams" *ngIf="!paymentLoading">
        <div class="container">
            <div class="wrapper-content">
                <div class="box-registration-success">
                    <h2 class="title">{{'stream.multiBitrate.paymentRequired.title' | localize}}</h2>
                    <p>{{'stream.multiBitrate.paymentRequired.text' | localize}}</p>
                    <p *ngIf="paymentError" class="text-danger">{{'payment.failure' | localize}}</p>
                    <div class="add-card">
                        <button class="btn btn-success mb-40 mt-40" (click)="selectCard()">{{'stream.multiBitrate.addCard' | localize}}</button>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <ano-loader *ngIf="paymentLoading" [isOpen]="true"></ano-loader>
</div>
<div class="box-streams" *ngIf="stream && ((mediaType === MEDIA_TYPE.STREAM && streamStatus) || mediaType !== MEDIA_TYPE.STREAM)">
    <div class="bg-white mb-20 card">
        <app-timeline *ngIf="mediaType !== MEDIA_TYPE.VIDEO_ON_DEMAND && scheduledStartAt && scheduledEndAt && streamStatus && !stream.media.deactivated"
                      [status]="streamStatus"
                      [scheduledStart]="scheduledStartAt"
                      [scheduledEnd]="scheduledEndAt"
                      [startAt]="actualStartAt"
                      [endAt]="actualEndAt"
                      class="mb-20"
        ></app-timeline>
        <div class="card-body" *ngIf="streamStatus && !stream.media.deactivated && mediaType !== MEDIA_TYPE.VIDEO_ON_DEMAND">
            <div class="row row-padding-10 info-box">
                <div class="col">
                    <app-info-panel *ngIf="infoPanel" [infoPanel]="infoPanel"></app-info-panel>
                </div>
                <div class="col-auto ml-auto">
                    <div class="row row-padding-10">
                        <div class="col-auto ml-auto">
                                <button *ngIf="streamStatus?.stream === STREAM_STATUS.LIVE"
                                        class="btn btn-icon btn-lg btn-secondary play-pause"
                                        [disabled]="this.playPausePending"
                                        (click)="pauseStream()">
                                    <div *ngIf="playPausePending" class="loader-container">
                                        <ano-loader [isOpen]="this.playPausePending"></ano-loader>
                                    </div>
                                    <div><i class="icon icon-pause"></i></div>
                                    <div>{{'player.button.status.pause' | localize}}</div>
                                </button>
                                <button *ngIf="!(streamStatus?.stream | includes : [STREAM_STATUS.LIVE, STREAM_STATUS.FINISHED])"
                                        class="btn btn-icon btn-success btn-lg btn-secondary play-pause"
                                        [disabled]="streamStatus?.stream !== STREAM_STATUS.PAUSED || playPausePending" (click)="playStream()">
                                    <div *ngIf="this.playPausePending" class="loader-container">
                                        <ano-loader [isOpen]="this.playPausePending"></ano-loader>
                                    </div>
                                    <div><i class="icon icon-stream"></i></div>
                                    <div>{{'player.button.status.live' | localize}}</div>
                                </button>
                        </div>
                        <div class="col-auto" *ngIf="!(streamStatus?.stream | includes : [STREAM_STATUS.FINISHED])">
                            <button style="position: relative"
                                    class="btn btn-danger btn-lg btn-icon"
                                    [disabled]="!(streamStatus?.stream | includes : [STREAM_STATUS.LIVE, STREAM_STATUS.PAUSED, STREAM_STATUS.ACTIVATED, STREAM_STATUS.ACTIVATION, STREAM_STATUS.CONNECTED]) || loaderButton"
                                    [class.btn-secondary]="true"
                                    (click)="startStream()">
                                <div *ngIf="streamStatus?.stream === STREAM_STATUS.ACTIVATION
                                || (streamStatus?.stream === STREAM_STATUS.SCHEDULED && loaderButton)
                                || (streamStatus?.stream === STREAM_STATUS.ACTIVATED && loaderButton)" class="loader-container">
                                    <ano-loader
                                            [isOpen]="loaderButton"></ano-loader>
                                </div>
                                <ng-container
                                        *ngIf="!(streamStatus?.stream | includes : [STREAM_STATUS.FINISHED])">
                                    <div><i class="icon icon-stop"></i></div>
                                    <div>{{'player.stopStream' | localize}}</div>
                                </ng-container>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-body" *ngIf="mediaType === MEDIA_TYPE.STREAM">
            <app-stream-info
                [status]="streamStatus?.stream"
                [data]="{
                    audience: (streamViewers$ | async),
                    isRecording: ((streamService.stream$ | async).media?.status?.record | includes :[RECORD_STATUS.RECORDED, RECORD_STATUS.RECORDING, RECORD_STATUS.STORING]) || (streamService.stream$ | async).media?.recordingEnabled}"
            ></app-stream-info>
        </div>
    </div>
    <app-visibility-settings
            *ngIf="premiumPublisher && (mediaType === MEDIA_TYPE.VIDEO_ON_DEMAND || (!stream.media.deactivated && streamStatus && (streamStatus?.stream !== STREAM_STATUS.FINISHED || (streamService?.stream$ | async)?.media?.hasRecording) && streamStatus?.stream !== STREAM_STATUS.PENDING))"
            [mediaType]="mediaType"
            [premiumPublisher]="premiumPublisher"
            [mediaId]="mediaId"
            [stream]="stream"
    ></app-visibility-settings>

    <app-quick-access [media]="(streamService?.stream$ | async)" [mediaType]="mediaType" [mediaId]="mediaId"></app-quick-access>

    <div class="row row-padding-10">
        <div class="col-lg-6" *ngIf="premiumPublisher && mediaType === MEDIA_TYPE.STREAM && streamStatus?.stream !== STREAM_STATUS.FINISHED">
            <div class="card" style="min-height: 380px; background-color: #fff;">
                <div class="card-body">
                    <app-reminder [standAlone]="true" [mediaId]="mediaId"></app-reminder>
                </div>
            </div>

        </div>
        <div class="col">
                    <app-stream-viewer-link style="max-height: 200px; background-color: #fff;"
                            *ngIf="!stream.media.deactivated && ((streamService?.stream$ | async)?.media?.hasRecording || streamStatus?.stream !== STREAM_STATUS.FINISHED) && streamStatus?.stream !== STREAM_STATUS.PENDING"
                            [showEmbeddedLink]="false"></app-stream-viewer-link>
        </div>
    </div>

    <div *ngIf="(streamService?.stream$ | async)?.media?.hasRecording && streamStatus?.stream !== STREAM_STATUS.PENDING" class="mt-30">
        <app-stream-record-info [recordData]="(streamService?.stream$ | async)?.media?.recordingData"
                                [startStreamTimestamp]="(streamService?.stream$ | async)?.media?.startedAt"
                                [endStreamTimestamp]="(streamService?.stream$ | async)?.media?.stoppedAt"
                                [streamId]="mediaId"
                                [isEmbeded]="true"></app-stream-record-info>
    </div>

    <ng-template #confirm let-dialogRef="dialogRef">
        <ano-modal [dialogRef]="dialogRef" [title]="'player.delete-stream.title' | localize" [isConfirmation]="true">
            {{'player.delete-stream.body' | localize}}
        </ano-modal>
    </ng-template>
</div>
