import {Component, OnInit} from '@angular/core';
import {DashboardService} from '@src/app/services/dashboard/dashboard.service';
import {
    CmsService,
    CURRENCY_SYMBOLS,
    IChartData,
    IChartLegend,
    ISimpleChartData,
    ITimerange,
    IWidgetConfig,
    LocalizationProvider,
    WIDGET_TYPE
} from 'ui-elements';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import {takeUntil} from 'rxjs/operators';
import {IBubbleMapData} from '@src/app/components/stream-world-map/bubble-map/bubble-map.component';
import {UserService} from '@src/app/services/user/user.service';
import * as moment from 'moment';
import {currentCurrency} from '@src/app/providers/currentCurrency';
import {BehaviorSubject} from 'rxjs';


@Component({
    selector: 'app-main-dashboard-page',
    templateUrl: './main-dashboard-page.component.html',
    styleUrls: ['./main-dashboard-page.component.sass'],
    providers: [AutoDestroyService]
})
export class MainDashboardPageComponent implements OnInit {
    public CURRENCY_SYMBOLS = CURRENCY_SYMBOLS;
    public currentCurrency: BehaviorSubject<string> = currentCurrency;
    public data: any;
    public map: any[];
    public widgetsConfigs: IWidgetConfig[];
    public chartData: IChartData[];
    public pieChartData: ISimpleChartData;
    public barChartData: ISimpleChartData[];
    public pieChartLegend: IChartLegend[];
    public readonly pieChartNoDataColorScheme: any = {
        noData: '#b1b1b1'
    };

    public summary: any[] = [
        {
            count: 0,
            title: 'VOUCHER'
        },
        {
            count: 0,
            title: 'PAY_PER_VIEW'
        },
        {
            count: 0,
            title: 'LEAD'
        },
        {
            count: 0,
            title: 'FREE'
        },
        {
            count: 0,
            title: 'VISIT'
        }

    ];
    public readonly pieChartColorScheme: any = {
        VOUCHER: '#0e54a9',
        PAY_PER_VIEW: '#0c1d3f',
        LEAD: '#007e8c',
        FREE: '#ffbe00',
    };
    public readonly colorScheme: any = {
        label1: '#0e54a9',
        label2: '#0c1d3f',
        label3: '#007e8c',
        label4: '#ffbe00',
        label5: '#bad668',
        line1: '#0e54a9',
    };

    public get pieChartLength(): number {
        return (this.data.chart.data as any[]).filter((item) =>  item.percentage !== 'NaN').length;
    }
    public totalViewers = 0;
    public viewersInACountry = 0;
    public currentCountry: string;
    public loading: boolean;

    constructor(
        private dashboardService: DashboardService,
        private destroy$: AutoDestroyService,
        private localizationProvider: LocalizationProvider,
        private cmsService: CmsService,
        public userService: UserService
    ) {
    }

    ngOnInit(): void {
        this.resolveWidgetsConfigs();
        this.getDashboardSettings(null);
    }

    private getDashboardSettings(timeRange?: ITimerange): void {
        this.loading = true;
        this.resetChartData();
        const from = (!!timeRange) ? timeRange.start : +moment().utc().startOf('month').format('x');
        const to = (!!timeRange) ? timeRange.end : +moment().utc().endOf('month').format('x');
        this.dashboardService.getDashboard(from, to).pipe(takeUntil(this.destroy$))
            .subscribe((res) => {
                this.data = res;
                this.resolvePieChartData();
                this.resolveChartData();
                this.resolveBarChartData();
                this.resolveWidgetsConfigs();
                this.data?.summary?.category.forEach(item => this.summary.find(_item => _item.title === item?.title).count = item.count);
                this.map = res.map.stats;
                if (!this.map?.length) {
                    this.totalViewers = 0;
                } else {
                    this.totalViewers = this.map.reduce((prev, current) => prev + current.viewers, 0);
                }

                if (!this.data.summary.category.length) {
                    this.data.summary = this.resolveDefaultSummary();
                }
                this.loading = false;
            }, () => this.loading = false);
    }

    private resetChartData(): void {
        this.pieChartData = null;
        this.chartData = null;
        this.barChartData = null;
    }

    public timeRangeFilter(event: ITimerange) {
        this.data = null;
        this.getDashboardSettings(event);
    }

    private resolvePieChartData(): void {
        this.pieChartData = {
            VOUCHER: 0,
            PAY_PER_VIEW: 0,
            LEAD: 0,
            FREE: 0
        };
        this.pieChartLegend = [
            {
                count: 0,
                label: 'VOUCHER',
                title: 'dashboard.pieChart.legend.VOUCHER',
            },
            {
                count: 0,
                label: 'PAY_PER_VIEW',
                title: 'dashboard.pieChart.legend.PAY_PER_VIEW',
            },
            {
                count: 0,
                label: 'LEAD',
                title: 'dashboard.pieChart.legend.LEAD',
            },
            {
                count: 0,
                label: 'FREE',
                title: 'dashboard.pieChart.legend.FREE',
            }
        ];
        let total = 100;
        (this.data.chart.data as any[]).filter((item) => item.percentage !== 'NaN').forEach((item, index) => {
            if (!item) {
                return;
            }
            const value = item.percentage.toFixed(2);
            const res = index === (this.data.chart.data as any[]).length - 1 ? total.toFixed(2) : value;
            this.pieChartData[item.label] = res;
            this.pieChartLegend.find(legendItem => legendItem.label === item.label).count = res;
            if (index === (this.data.chart.data as any[]).length - 1) {
                return;
            }
            total -= value;
        });

        if (!this.pieChartLength) {
            this.fillEmptyPieChart();
        }
    }

    private fillEmptyPieChart(): void {
        this.pieChartData.noData = 100;
        this.pieChartLegend = [
            {
                count: 0,
                label: 'VOUCHER',
                title: 'dashboard.pieChart.legend.VOUCHER',
            },
            {
                count: 0,
                label: 'PAY_PER_VIEW',
                title: 'dashboard.pieChart.legend.PAY_PER_VIEW',
            },
            {
                count: 0,
                label: 'LEAD',
                title: 'dashboard.pieChart.legend.LEAD',
            },
            {
                count: 0,
                label: 'FREE',
                title: 'dashboard.pieChart.legend.FREE',
            }
        ];

    }

    private resolveBarChartData(): void {
        this.barChartData = Object.entries(this.data.media).map(([key, value]) => ({group: this.localizationProvider.getByKey('dashboard.barChart.' + key), value: value as any})) as any;
    }

    private resolveWidgetsConfigs(): void {
        currentCurrency.pipe(takeUntil(this.destroy$))
            .subscribe((currency) => {
                this.widgetsConfigs = [
                    {
                        type: WIDGET_TYPE.PRIMARY,
                        title: this.localizationProvider.getByKey('dashboard.widget.income'),
                        amount: this.data?.widget?.totalIncome || 0,
                        currency: CURRENCY_SYMBOLS[currency],
                        icon: 'coins'
                    },
                    {
                        type: WIDGET_TYPE.SUCCESS,
                        title: this.localizationProvider.getByKey('dashboard.widget.viewers'),
                        amount: this.data?.widget?.viewerCount || 0,
                        icon: 'users'
                    },
                ];
            });
    }

    private resolveChartData(): void {
        this.data.legend = [{
            count: 0,
            label: 'line1',
            title: 'Zuschauer gesamt'
        }];
        this.chartData = [];
        this.data.statistic.category.forEach((cat, index) => {
            this.chartData.push({
                date: new Date(cat).getDate()
            });
            this.chartData[index].values = {};
            Object.entries(this.data.statistic.values).forEach(([key, values], index1) => {
                this.chartData[index].values[key] = values[index];
            });
            this.data.statistic.category[index] = new Date(cat).getDate() - 1;
        });
    }

    public onMapHover(viewersByCountry: IBubbleMapData): void {
        this.viewersInACountry = viewersByCountry?.viewers;
        this.currentCountry = this.cmsService.getCountryByKey(viewersByCountry?.country)?.value;
    }

    private resolveDefaultSummary(): any {
        return {
            total: 0,
                category: [
                {
                    title: 'LEAD',
                    count: 0
                },
                {
                    title: 'PAY_PER_VIEW',
                    count: 0
                },
                {
                    title: 'FREE',
                    count: 0
                },
                {
                    title: 'VOUCHER',
                    count: 0
                }
            ]
        };
    }

}
