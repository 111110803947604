import {Injectable} from '@angular/core';
import {ApiService, asBoolean} from 'ui-elements';
import {IPagedResponse, IResponse, PagingParams, SortParam} from '@src/app/models/response.model';
import {Observable, of} from 'rxjs';
import {GROUP_PAYMENT_SYSTEM, IMediaGroup, IMediaGroupPayload} from '@src/app/models/stream-groups.model';
import {API_URLS_APP, UrlGenerator} from '@src/app/constants/api-urls.constant';
import {pluck} from 'rxjs/operators';
import {
    IFormConfig,
    IFormFieldsConfig,
} from '@src/app/services/stream-metadata/stream.service';
import {INPUT_TYPES, IUnitKeyValue} from 'ui-elements';
import {StreamMetadataSelectValues} from '@src/app/models/stream.model';
import {INameId} from '@src/app/models/core.model';
import {FormUtil} from '@src/app/utils/form.util';
import {Validators} from '@angular/forms';
import {CustomValidators} from '@src/app/utils/custom-validators.util';
import {MEDIA_TYPE} from '@src/app/components/streamdust-player/constants/mediaType';
import {IUserPublisher} from '@src/app/pages/user-profile/components/profile/services/profile.service';
import {IListQuery} from '@src/app/components/media-list/media-list.component';
import {Subject} from 'rxjs/internal/Subject';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {MEDIA_ACCESS_MODAL_STATE} from '@src/app/components/streamdust-player/media-access-modal/media-access-modal.component';

@Injectable({
    providedIn: 'root'
})
export class MediaGroupsService {
    private groupPaymentSuccess: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    public groupPaymentSuccess$: Observable<string> = this.groupPaymentSuccess.asObservable();
    private activeAccessModalSubject: Subject<MEDIA_ACCESS_MODAL_STATE> = new Subject();
    public activeAccessModal$ = this.activeAccessModalSubject.asObservable();

    constructor(
        private apiService: ApiService
    ) {
    }

    public getMyGroups(searchQuery = '', paging: PagingParams = DEFAULT_PAGING, sort: SortParam[] = DEFAULT_SORTING): Observable<IPagedResponse<IMediaGroup>> {
        return this.apiService.post(API_URLS_APP.MY_GROUPS, {
            paging,
            sort,
            searchTerm: searchQuery
        });
    }

    public deleteGroup(groupId: string): Observable<IResponse> {
        return this.apiService.delete(UrlGenerator.generate(API_URLS_APP.GROUP, {groupId}));
    }

    public getGroup(groupId: string, usePublicEndpoint = false): Observable<IMediaGroup | { group: IMediaGroup, publisherInfo: IUserPublisher }> {
        // const pluckToUse = usePublicEndpoint ? pluck('results', 'data', 'group') : pluck('results', 'data');
        const endpointRoute = usePublicEndpoint ? API_URLS_APP.GROUP_PUBLIC : API_URLS_APP.GROUP;
        return this.apiService.get(UrlGenerator.generate(endpointRoute, {groupId}), {
            openApi: usePublicEndpoint,
            isWithoutRootUrl: usePublicEndpoint
        })
            .pipe(pluck('results', 'data'));
    }

    public checkAccessToGroup(groupId: string, accessToken: string): Observable<boolean> {
        return this.apiService.post(UrlGenerator.generate(API_URLS_APP.GROUP_CHECK_ACCESS, {groupId}), {accessToken}, {
            openApi: true,
            isWithoutRootUrl: true
        })
            .pipe(pluck('results', 'hasAccess'));
    }

    public saveGroup(body: IMediaGroupPayload): Observable<IResponse<{ id: string }>> {
        return this.apiService.post(API_URLS_APP.SAVE_GROUP, body);
    }

    public mapGroupsToKeyValue(groups: IMediaGroup[]): IUnitKeyValue[] {
        return groups.map(item => ({key: item.id, value: item.name}));
    }

    public mapGenresToKeyValue(genres: INameId[]): IUnitKeyValue[] {
        return genres.map(item => ({key: item.id, value: item.name}));
    }

    public removeStreamFromGroup(groupId: string, mediaId: string, mediaType: MEDIA_TYPE): Observable<IResponse> {
        const mediaTypeRoute = mediaType === MEDIA_TYPE.STREAM ? 'stream' : 'vod';
        return this.apiService.delete(UrlGenerator.generate(API_URLS_APP.TOGGLE_STREAM_GROUP, {
            groupId,
            mediaId,
            mediaType: mediaTypeRoute
        }));
    }

    public addStreamInGroup(groupId: string, streamId: string): Observable<IResponse> {
        return this.apiService.put(UrlGenerator.generate(API_URLS_APP.TOGGLE_STREAM_GROUP, {groupId, streamId}), {});
    }

    public getGroupFormConfig(isModal: boolean): IFormConfig {
        return {
            name: 'group',
            formFields: isModal ? this.getGroupFieldsConfigModal() : this.getGroupFieldsConfig(),
            form: FormUtil.buildForm(isModal ? this.getGroupFieldsConfigModal() : this.getGroupFieldsConfig()),
            isDefault: true
        };
    }

    public getGroupFieldsConfig(): IFormFieldsConfig[][] {
        return [
            [
                {
                    name: 'name',
                    control: ['', [Validators.required]],
                    config: {
                        inputType: INPUT_TYPES.INPUT,
                        placeholder: 'groups.field.name',
                        isRequired: true
                    }
                },
            ],
            // [
            //     {
            //         name: 'paymentType',
            //         control: ['', [Validators.required]],
            //         config: {
            //             inputType: INPUT_TYPES.SELECT,
            //             placeholder: 'groups.paymentType',
            //             isRequired: true,
            //             selectOptions: this.getPaymentTypes(),
            //         }
            //     },
            // ],
            [
                {
                    name: 'priceAmount',
                    control: ['', Validators.compose([Validators.required, CustomValidators.priceAmount(2)])],
                    config: {
                        inputType: INPUT_TYPES.CURRENCY_AMOUNT,
                        placeholder: 'groups.field.price',
                        isRequired: true
                    }
                },
            ],
        ];
    }

    public getGroupFieldsConfigModal(): IFormFieldsConfig[][] {
        return [
            [
                {
                    name: 'name',
                    control: ['', [Validators.required]],
                    config: {
                        inputType: INPUT_TYPES.INPUT,
                        placeholder: 'groups.field.name',
                        isRequired: true
                    }
                },
            ],
            // [
            //     {
            //         name: 'paymentType',
            //         control: ['', [Validators.required]],
            //         config: {
            //             inputType: INPUT_TYPES.SELECT,
            //             placeholder: 'groups.paymentType',
            //             isRequired: true,
            //             selectOptions: this.getPaymentTypes(),
            //         }
            //     },
            // ],
            [
                {
                    name: 'priceAmount',
                    control: ['', Validators.compose([Validators.required, CustomValidators.priceAmount(0.01)])],
                    config: {
                        inputType: INPUT_TYPES.CURRENCY_AMOUNT,
                        placeholder: 'groups.field.price',
                        isRequired: true
                    }
                },
            ]
        ];
    }

    public getPaymentTypes(): IUnitKeyValue[] {
        return [
            {
                key: GROUP_PAYMENT_SYSTEM.PPV,
                value: 'PPV'
            },
            {
                key: GROUP_PAYMENT_SYSTEM.PAY_FOR_THE_EVENT,
                value: 'Pay for the event'
            }
        ];
    }

    public successfulPayment(id: string): void {
        this.groupPaymentSuccess.next(id);
    }

    public goRegistration() {
        this.activeAccessModalSubject.next(MEDIA_ACCESS_MODAL_STATE.REGISTRATION_STEP_1);
    }
}

const DEFAULT_PAGING = {
    page: 0,
    itemsOnPage: 10
} as PagingParams;

const DEFAULT_SORTING = [{
    field: 'NAME',
    order: 'ASC'
}] as SortParam[];
