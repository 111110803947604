import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Modal} from "@src/app/components/modal-container/models/modal.model";
import {GroupComponent} from "@src/app/pages/groups-page/components/group/group.component";
import {StreamService} from "@src/app/services/stream-metadata/stream.service";

@Component({
    selector: 'app-group-modal-container',
    templateUrl: './group-modal-container.component.html',
    styleUrls: ['./group-modal-container.component.sass']
})
export class GroupModalContainerComponent extends Modal {
    @ViewChild('groupComponent', {static: false}) public groupComponent: GroupComponent;
    dismissButtonText = 'common.button.close';
    submitButtonText = 'common.button.apply';

    public submit(): void {
        this.groupComponent.submit();
        if (this.groupComponent.formConfig.form.invalid) {
            return;
        }
    }

    public save(id: string): void {
        // this.streamService.update$.emit({groupCreated$: true});
        this.close({groupCreatedId: id});
    }

    public cancel(output?: any): void {
        this.dismiss(output);
    }



    // public close(output?: any): void {
    //     this.groupComponent.submit();
    //     this.close(output);
    // }
    //
    // public dismiss(output?: any): void {
    //     this.dismiss(output);
    // }
}
