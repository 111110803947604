import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RecordPageComponent} from '@src/app/pages/record-page/record-page.component';



@NgModule({
  declarations: [RecordPageComponent],
  imports: [
    CommonModule
  ]
})
export class RecordPageModule { }
