<header class="header" id="main-header">
    <div class="container">
        <nav class="navbar navbar-expand-lg">
            <a (click)="goToHome()" class="logo navbar-brand">
                <img src="/assets/img/logo.png" alt="logo">
            </a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
                    aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="navbar-collapse collapse" id="navbarCollapse">
                <ul class="navbar-nav mr-auto">
                    <li class="nav-item">
                        <a class="nav-link" [routerLink]="['/']" fragment="section-calculate-ticket">{{'header.price' | localize}}</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [routerLink]="['/']" fragment="section-features">{{'header.features' | localize}}</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="https://beta.streamdust.tv/info/faq/" target="_blank">{{'header.faq' | localize}}</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link nav-link-live" routerLink="streams/actual" routerLinkActive="active"
                           #rla="routerLinkActive"><span class="live-circle">&#9679;</span> {{'header.live' | localize}}</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLink="video/actual" routerLinkActive="active">{{'header.videos' | localize}}</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [routerLink]="['/']" fragment="section-tariff">{{'header.prices' | localize}}</a>
                    </li>
                </ul>
                <ng-container *ngIf="authService.isAuthorized$ | async">
                    <ul class="navbar-nav">

                        <li class="nav-item">
                            <!--                            <ul class="nav-ico">-->
                            <!--                                <li><span class="icon icon-search"></span></li>-->
                            <!--                                <li><span class="icon icon-bell badges"></span></li>-->
                            <!--                                <li><span class="icon icon-mail badges"></span></li>-->
                            <!--                                <li><span class="icon icon-settings"></span></li>-->
                            <!--                            </ul>-->
                        </li>
                        <li class="nav-item">
                            <div class="dropdown">
                                <button class="btn btn-sm dropdown-toggle username" type="button" id="dropdownMenu1"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {{ (username| localize)}}
                                </button>
                                <div class="dropdown-menu header-nav">
                                    <!--<a class="dropdown-item" routerLink="user/edit">Benutzerdaten</a>-->
                                    <!--<a class="dropdown-item" routerLink="streams">Livestreams</a>-->
                                    <!--<a class="dropdown-item" (click)="logout()">Logout</a>-->
                                    <a *ngFor="let nav of navConfig" class="dropdown-item cursor-pointer"
                                       (click)="nav.action()">{{nav.text | localize}}</a>
                                </div>
                            </div>
                        </li>
                    </ul>
                </ng-container>

                <ng-container *ngIf="!(authService.isAuthorized$ | async)">
                    <ul class="navbar-nav navbar-btn">
                        <li class="nav-item"><a class="btn btn-outline-secondary btn-login" [routerLink]="'/registration'">Registration</a></li>
                        <li class="nav-item">
                            <a class="btn btn-outline-secondary btn-login" (click)="toggleLogin()">Login</a>
                            <app-header-popup
                                    [opened]="loginOpen"
                                    [title]="'login.title' | localize"
                                    [subTitle]="'login.description' | localize"
                                    [loading]="loading">
                                <ng-container role="body">
                                    <app-bordered-form
                                            [form]="loginForm"
                                            [fieldsConfig]="loginFormFieldsConfig"
                                            [defaultForm]="true">
                                    </app-bordered-form>
                                    <ng-container *ngIf="serverErrors.length">
                                        <div class="row row-padding-0">
                                            <div class="col-lg-9 offset-lg-3">
                                                <span class="error-message"
                                                      *ngFor="let error of serverErrors">{{error.text}}</span>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <div class="row row-padding-0">
                                        <div class="col-md-3"></div>
                                        <div class="col-md-9">
                                            <div class="row row-padding-0 align-items-end">
                                                <div class="col col-lg-auto mr-auto">
                                                    <form [formGroup]="keepLoggedForm">
                                                        <div class="form-check">
                                                            <input type="checkbox" class="form-check-input"
                                                                   id="exampleCheck1" formControlName="keepLogged">
                                                            <label class="form-check-label"
                                                                   for="exampleCheck1">{{'login.keepLogged' | localize}}</label>
                                                        </div>
                                                    </form>
                                                </div>
                                                <div class="col col-lg-auto">
                                                    <a class="text-xs link" (click)="togglePasswordForgotten()"><i
                                                            class="icon icon-lock"></i>{{'login.button.forgot_password' | localize}}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container role="footer">
                                    <div class="row row-padding-0 align-items-end">
                                        <div class="col">
                                            <!--                                            <div class="form-switcher row row-padding-5 align-items-center">-->
                                            <!--                                                <div class="col-auto">-->
                                            <!--&lt;!&ndash;                                                    <div class="switcher active-success"></div>&ndash;&gt;-->
                                            <!--                                                </div>-->
                                            <!--                                                <div class="col">-->
                                            <!--&lt;!&ndash;                                                    <label class="switcher-label">{{'login.keepLogged' | localize}}</label>&ndash;&gt;-->
                                            <!--                                                </div>-->
                                            <!--                                            </div>-->
                                            <span class="text text-xs">{{'login.newUser' | localize}}  <b><a
                                                    [routerLink]="'/registration'"
                                                    (click)="toggleLogin()">{{'login.createAccount' | localize}}</a></b></span>
                                        </div>
                                        <div class="col-auto">
                                            <div class="btn-box">
                                                <div class="row row-padding-10 align-content-end">
                                                    <div class="col-auto">
                                                        <button class="btn btn-secondary"
                                                                (click)="toggleLogin()">{{ 'login.button.cancel' | localize}}</button>
                                                    </div>
                                                    <div class="col-auto">
                                                        <button class="btn btn-primary"
                                                                (click)="login()">{{'login.button.signin' | localize}}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </app-header-popup>
                            <app-header-popup *ngIf="isEmailSentOpen"
                                              [opened]="isEmailSentOpen"
                                              [withBackdrop]="true"
                                              [titleSuccess]="true"
                                              [title]="'reset.title'"
                                              [description]="'reset.emailSent'"
                                              [successText]="emailSentText"
                                              [withoutBody]="true"
                            >
                                <ng-container role="footer">
                                    <div class="row row-padding-0 align-items-end">
                                        <div class="col">
                                            <span class="text text-xs"><b><a
                                                    href="#">{{'reset.help' | localize}}</a></b></span>
                                        </div>
                                        <div class="col-auto">
                                            <div class="btn-box">
                                                <div class="row row-padding-10 align-content-end">
                                                    <div class="col-auto">
                                                        <button class="btn btn-primary"
                                                                (click)="finishRemind()">{{'reset.done' | localize}}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </app-header-popup>
                            <app-header-popup *ngIf="passwordForgottenOpen"
                                              [opened]="passwordForgottenOpen"
                                              [withBackdrop]="true"
                                              [title]="'forgottenPassword.title'"
                                              [description]="'forgottenPassword.description'">
                                <ng-container role="body">
                                    <app-bordered-form class="form-forgottenPassword"
                                                       [form]="passwordForm"
                                                       [fieldsConfig]="passwordFormFieldsConfig"
                                                       [defaultForm]="true">
                                    </app-bordered-form>
                                    <div class="row row-padding-0 error-box">
                                        <ng-container *ngIf="serverErrors.length">
                                            <div class="col-lg-9 offset-lg-3">
                                                <span class="error-message"
                                                      *ngFor="let error of serverErrors">{{error.text}}</span>
                                            </div>
                                        </ng-container>
                                    </div>
                                </ng-container>
                                <ng-container role="footer">
                                    <div class="row row-padding-0 align-items-end">
                                        <div class="col-sm col-auto">
                                            <span class="text text-xs"><a href="https://wp.streamdust.tv/faq/" target="_blank">{{'forgottenPassword.help' | localize}}</a></span>
                                        </div>
                                        <div class="col-auto">
                                            <div class="btn-box">
                                                <div class="row row-padding-10 align-content-end">
                                                    <div class="col-auto">
                                                        <button class="btn btn-secondary"
                                                                (click)="togglePasswordForgotten()">{{'forgottenPassword.button.cancel' | localize}}
                                                        </button>
                                                    </div>
                                                    <div class="col-auto">
                                                        <button class="btn btn-primary" (click)="remindPassword()">
                                                            {{'forgottenPassword.button.resetPassword' | localize}}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </app-header-popup>
                            <app-new-password [showPopup]="registrationSetPasswordOpen"></app-new-password>
                            <app-change-forgotten-password
                                    [showPopup]="changeForgottenPasswordOpen"></app-change-forgotten-password>
                        </li>
                    </ul>
                </ng-container>
                <!--=======================-->
                <!--===LANGUAGE-SWITCHER===-->
                <!--=======================-->
                <ng-container>
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <div class="dropdown language-switcher">
                                <button class="btn btn-sm dropdown-toggle username" type="button"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {{ ApiService.localLanguage }}
                                </button>
                                <div class="dropdown-menu">
                                    <a *ngFor="let nav of navLangConfig" href="javascript: Cookiebot.renew()" class="dropdown-item cursor-pointer"
                                       (click)="nav.action()">{{nav.text | localize}}</a>
                                </div>
                            </div>
                        </li>
                    </ul>
                </ng-container>
            </div>
        </nav>
    </div>
</header>
