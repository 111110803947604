import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output, QueryList,
    ViewChild, ViewChildren
} from '@angular/core';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import {take, takeUntil, tap} from 'rxjs/operators';
import {BehaviorSubject, Observable, ReplaySubject} from 'rxjs';
import {IResponse} from '@src/app/models/response.model';
import {StreamService} from '@src/app/services/stream-metadata/stream.service';
import {AuthService} from '@src/app/services/auth/auth.service';
import {UserService} from '@src/app/services/user/user.service';
import {FormBuilder, Validators} from '@angular/forms';
import {
  CHAT_STATUS,
  ChatService,
  CONTENT_TYPE,
  IChatResponse,
  IMessage,
  MESSAGE_TYPE
} from '@src/app/services/chat/chat.service';
import {ChatState} from '@src/app/state/chat-state';
import {ExcludedEmojies} from '@src/app/components/chat/config/excludedEmojies';
import {DEFAULT_CHAT_POSITION} from '@src/app/models/core.model';
import {StreamWorldMapState} from '@src/app/state/stream-world-map';
import {ResizeEvent} from 'angular-resizable-element';
import {IResizeEvent} from 'ngx-draggable-resize/lib/models/resize-event';
import {
  IResizableElementButton,
  RESIZABLE_DIRECTION,
  ResizableElementComponent
} from '@src/app/components/resizable-element/resizable-element.component';
import {PLAYER_DISPLAY_MODE} from '../streamdust-player/constants/playerMode';
import {DonationState} from '@src/app/state/donation-state';
import {GenerateUtil} from '@src/app/utils/generate.util';
import {HttpEventType, HttpResponse} from '@angular/common/http';
import {LocalizationProvider, SnackBarService} from 'ui-elements';

@Component({
    selector: 'app-chat',
    templateUrl: './chat.component.html',
    styleUrls: ['./chat.component.sass'],
    providers: [AutoDestroyService]
})
export class ChatComponent implements OnInit {
    RESIZABLE_DIRECTION = RESIZABLE_DIRECTION;

    constructor(
        public elementRef: ElementRef,
        public chatService: ChatService,
        public chatState: ChatState,
        public donationState: DonationState,
        public destroy$: AutoDestroyService,
        public streamService: StreamService,
        public authService: AuthService,
        public userService: UserService,
        private fb: FormBuilder,
        public worldMapState: StreamWorldMapState,
        private snackBarService: SnackBarService,
        private localizationProvider: LocalizationProvider,
    ) {

    }
    PLAYER_DISPLAY_MODE = PLAYER_DISPLAY_MODE;
    public CHAT_MODE = CHAT_MODE;
    public width: number;
    public height: number;
    @ViewChild('scrollingContainer', {static: false}) private scrollingContainer: ElementRef<HTMLDivElement>;
    @ViewChild('messagesContainer', {static: false}) private messagesContainer: ElementRef<HTMLDivElement>;
    @ViewChild('chat', {static: false}) private chat: ElementRef<HTMLDivElement>;
    @ViewChild('resizableElement', {static: false}) private resizableElement: ResizableElementComponent;
    @ViewChild('emojiRef', {static: false}) private emojiRef: ElementRef<HTMLElement>;
    @ViewChild('pinnedBox', { static: false }) pinnedBoxRef: ElementRef;
    @ViewChildren('messagesRef') messageElements: QueryList<ElementRef>;
    public dragPosition: {x: number; y: number} = DEFAULT_CHAT_POSITION;
    public messageInputValue: string;
    public conversation: IMessage[] = [];
    @Input() public mediaId: string;
    @Input() public title: string;
    @Input() public mode: CHAT_MODE = CHAT_MODE.DEFAULT;
    @Input() public isOwner: boolean;
    @Input() public active: boolean;
    @Input() public connection: ReplaySubject<IResponse<IChatResponse>>;
    @Input() playerDisplayMode: PLAYER_DISPLAY_MODE = PLAYER_DISPLAY_MODE.DEFAULT;
    @Input() fullScreen$: Observable<boolean>;
    @Input() isChatPage = false;
    @Input() chatPageLink: string;
    @Input() isAuthorized: boolean;
    @Output() public reconnectSocket$: EventEmitter<string> = new EventEmitter<string>();

    public nickname: string;
    public nicknameError: string;
    public usernameForm = this.fb.group({
        username: ['', [Validators.required]]
    });
    public fullscreenChat$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public fullscreenChat = false;
    public allowSending = false;
    public askNickname = false;
    public emojiOpened = false;
    public emojiRightPosition = '360px';
    public emojiTopPosition = '-3px';
    public emojiBottomPosition = 'auto';
    private readonly excludedEmojies = ExcludedEmojies.list;
    public closeButton: IResizableElementButton;
    public fullscreenButton: IResizableElementButton;
    public embeddedButton: IResizableElementButton;
    public instanceId = GenerateUtil.uuidv4();
    public activeSlideIndex = 0;
    public showPinLimitInfo = false;
    private pinLimitTimeout: any;
    public pinnedMessages: IMessage[] = [];
    public emojisToShowFilter: (emoji: any) => boolean = (emoji: any) => !this.excludedEmojies.includes(emoji);

    ngOnInit(): void {
        this.messageInputValue = '';
        this.connection?.pipe(takeUntil(this.destroy$), tap((res) => {
            if (res?.results?.status &&
                res?.results?.type !== CONTENT_TYPE.MESSAGE_PINNED &&
                res?.results?.type !== CONTENT_TYPE.CMD) {
                this.chatState.chatEnabledState$.next(res.results.status !== CHAT_STATUS.DISABLED);
            }
        })).subscribe(msg => {
            this.handleWsResponse(msg);
        });
        this.chatState.chatActiveState$
            .pipe(takeUntil(this.destroy$))
            .subscribe((state) => {
                this.renderWindow(state);
            });

        this.chatState.currentPosition$.next(this.dragPosition);
        this.initButtons();
        if (this.isChatPage) {
            this.toggleFullscreen();
        }
    }

    public reconnectSocket(id: string): void {
        this.reconnectSocket$.emit(id);
    }

    public initButtons(): void {
        this.fullscreenButton = {
            show: this.playerDisplayMode === PLAYER_DISPLAY_MODE.DEFAULT,
            callback: () => this.toggleFullscreen()
        };
        this.embeddedButton = {
            show: this.mode !== CHAT_MODE.EMBEDDED,
            callback: () => this.toggleOverlay()
        };
        this.closeButton = {
            show: true,
            callback: () => this.chatState.closeChat()
        };
    }
    public toggleFullscreen(): void {
        this.fullscreenChat = !this.fullscreenChat;
        this.fullscreenChat$.next(this.fullscreenChat);
        this.scrollToBottom(false, false);
        if (this.fullscreenChat) {
            document.body.classList.add('chat-full-screen');
        } else {
            document.body.classList.remove('chat-full-screen');
        }
        if (this.emojiOpened) {
            this.emojiOpened = false;
            setTimeout(() => {
                this.emojiOpened = true;
                this.calculateEmojisPosition();
            }, 1);
        }
    }

    toggleOverlay(): void {
        this.fullscreenChat = false;
        this.fullscreenChat$.next(this.fullscreenChat);
        if (this.fullscreenChat) {
            document.body.classList.add('chat-full-screen');
        } else {
            document.body.classList.remove('chat-full-screen');
        }

        this.chatState.toggleOverlay();
        this.chatState.chatOverlayState$.pipe(take(1))
            .subscribe((state) =>
                this.fullscreenButton = {
                    show: this.playerDisplayMode === PLAYER_DISPLAY_MODE.DEFAULT && !state,
                    callback: () => this.toggleFullscreen()
                });
    }

    private renderWindow(state: boolean): void {
        if (!state) {
            document.body.classList.remove('chat-full-screen');
            return;
        }

        if (this.mode === CHAT_MODE.EMBEDDED) {
            this.chatState.setOverlayState(true);
        }
        if (this.mode !== CHAT_MODE.DEFAULT) {
            this.scrollToBottom(false, false);
            return;
        }

        // this.dragPosition = {
        //     x: window.innerWidth - 500,
        //     y: window.innerHeight / 1.5
        // };
        this.scrollToBottom(false, false);
    }

    public keyPress(e: KeyboardEvent): void {
        if (e.keyCode === 13 && e.altKey) {
            this.messageInputValue += '\n';
        }
        if (e.keyCode === 13 && !e.shiftKey && !e.altKey) {
            e.preventDefault();
            this.sendMessage();
        }
    }

    public sendMessage(): void {
        if (!this.messageInputValue) {
            return;
        }
        this.send(this.messageInputValue);
        this.messageInputValue = '';
        this.scrollToBottom(true, false);
    }

    private scrollToBottom(isSent: boolean, withAnimation: boolean): void {
        if (!this.messagesContainer) {
            return;
        }

        const messagesHeight = this.messagesContainer.nativeElement.clientHeight;
        const scrollPosition = this.resizableElement.scrollingContainer.nativeElement.scrollTop + this.resizableElement.scrollingContainer.nativeElement.clientHeight;
        if (!isSent && scrollPosition < messagesHeight - 50 ) {
            return;
        }
        setTimeout(() => {
            const scrollConfig: ScrollToOptions = {top: this.messagesContainer.nativeElement.clientHeight};
            if (withAnimation) {
                scrollConfig.behavior = 'smooth';
            }
            this.resizableElement.scrollingContainer.nativeElement.scroll(scrollConfig);
        });
    }

    private filterPinnedMessages() {
        this.pinnedMessages = this.conversation.filter((message) => message.isPinned) || [];
    }

    public handleWsResponse(response: IResponse<IChatResponse>): void {
        switch (response.results.type) {
            case CONTENT_TYPE.OPEN:
                this.handleOpenResponse(response);
                break;
            case CONTENT_TYPE.NICKNAME:
                this.handleNicknameResponse(response);
                break;
            case CONTENT_TYPE.CONVERSATION:
                this.handleConversationResponse(response);
                break;
            case CONTENT_TYPE.STATUS:
                this.handleStatusResponse(response);
                break;
            case CONTENT_TYPE.SEND:
                this.handleSendResponse(response);
                break;
            case CONTENT_TYPE.MESSAGE_DELETED:
                this.handleDeleteResponse(response);
                break;
            case CONTENT_TYPE.MESSAGE_PINNED:
                this.handlePinResponse(response);
                break;
        }
        if (response.errorKey === 'NOT_LOGGED_IN' && this.isAuthorized) {
            this.reconnectSocket(this.mediaId);
        }
    }

    private handleOpenResponse(response: IResponse<IChatResponse>): void {
        // if (!response.results.nickname) {
        //     this.askNickname = true;
        //     return;
        // }

        this.chatService.send({content: null, type: CONTENT_TYPE.CONVERSATION});
        this.setInputState(response.results.status);
    }

    private handleNicknameResponse(response: IResponse<IChatResponse>): void {
        if (!response.success) {
            // return this.askNickname(response.errorKey);
            this.askNickname = true;
            this.nicknameError = response.errorKey;
            return;
        }

        this.askNickname = false;
        this.chatService.send({content: null, type: CONTENT_TYPE.CONVERSATION});
    }

    private handleConversationResponse(response: IResponse<IChatResponse>): void {
        if (!response.success) {
            return;
        }

        this.conversation = response.results.messages;
        this.filterPinnedMessages();
        this.setInputState(response.results.status);
        this.checkDisabledStatus(response.results.status);
        this.scrollToBottom(false, true);
    }

    private handleStatusResponse(response: IResponse<IChatResponse>): void {
        this.setInputState(response.results.status);
        this.chatService.send({content: null, type: CONTENT_TYPE.CONVERSATION});
        this.checkDisabledStatus(response.results.status);
    }

    private handleDeleteResponse(response: IResponse<IChatResponse>): void {
        response.results.messages.forEach(message => {
            const targetMessage = this.conversation.find(target => target.id === message.id);
            if (!targetMessage) {
                return;
            }

            targetMessage.content = message.content;
            targetMessage.type = MESSAGE_TYPE.SYSTEM;
        });
    }

    private handlePinLimit() {
        this.showPinLimitInfo = true;

        if (this.pinLimitTimeout) {
            clearTimeout(this.pinLimitTimeout);
        }

        this.pinLimitTimeout = setTimeout(() => {
            this.showPinLimitInfo = false;
        }, 10000);
    }

    private handlePinResponse(response: IResponse<IChatResponse>): void {
        if (response.results.limitReached) {
            this.handlePinLimit();
        }

        const targetMessage = this.conversation.find(target => target.id === response.results.messageId);
        if (!targetMessage) {
            return;
        }

        targetMessage.isPinned = response.results.isPinned;
        this.filterPinnedMessages();
    }

    private checkDisabledStatus(status: CHAT_STATUS): void {
        if (status === CHAT_STATUS.DISABLED) {
            this.conversation = [
                {
                    content: 'Chat was disabled by publisher',
                    conversationId: 'c5f21e9b-35be-4f8f-af2c-e15a6f3955f4',
                    created: 1598531970910,
                    id: '5c23b79b-9e5e-48be-87b1-8a15305e7cd2',
                    type: MESSAGE_TYPE.SYSTEM
                }
            ];
        }
    }

    private handleSendResponse(response: IResponse<IChatResponse>): void {
        this.scrollToBottom(false, false);
        if (!response.success) {
            return;
        }
        this.conversation = this.conversation.concat(response.results.messages);
    }

    private setInputState(status: CHAT_STATUS): void {
        this.allowSending = status === CHAT_STATUS.ACTIVE;
    }

    public send(message: string, messageType = MESSAGE_TYPE.PLAIN): void {
        this.chatService.send({content: message, type: CONTENT_TYPE.SEND, messageType});
    }

    public setNickname(): void {
        this.usernameForm.markAllAsTouched();
        if (this.usernameForm.invalid) {
            return;
        }

        this.nickname = this.usernameForm.get('username').value;
        this.chatService.send({content: this.nickname, type: CONTENT_TYPE.NICKNAME});
    }

    public toggleEmojies(): void {
        if (!this.emojiOpened) {
            this.calculateEmojisPosition();
        }
        this.emojiOpened = !this.emojiOpened;
    }

    public calculateEmojisPosition(): void {
        const rect = this.resizableElement.resizableContainer.nativeElement.getBoundingClientRect();
        if (this.fullscreenChat) {
            this.emojiTopPosition = 'auto';
            this.emojiBottomPosition = '100px';
            this.emojiRightPosition = '0';
        } else {
            this.emojiTopPosition = '-3px';
            this.emojiBottomPosition = 'auto';
            if (rect.left > (window.innerWidth / 2) - (rect.width / 2)) {
                this.emojiRightPosition = '360px';
            } else {
                this.emojiRightPosition = '-345px';
            }
        }


        const emojiMart: HTMLElement = document.querySelector('.emoji-mart') as HTMLElement;
        if (emojiMart) {
            // emojiMart.style.right = this.emojiRightPosition;
            // emojiMart.style.top = this.emojiTopPosition;
            // emojiMart.style.bottom = this.emojiBottomPosition;
        }
    }

    public deleteMessage(id: string): void {
        this.chatService.send({
            content: `/delete ${id}`,
            type: CONTENT_TYPE.CMD
        });
    }

    public pinMessage(id: string): void {
        this.chatService.send({
            content: `/pin ${id}`,
            type: CONTENT_TYPE.CMD
        });
    }

    public addEmoji(emoji: any): void {
        this.messageInputValue += emoji.emoji.native + ' ';
    }

    public onResizeEnd(evt: ResizeEvent): void {
    }

    public onResize(evt: IResizeEvent): void {
        this.height = evt.size.height;
        this.width = evt.size.width;
    }

    onFileSelected(event: any) {
        const file: File = event.target.files[0];
        if (file) {
            this.chatService.uploadAttachment(file, this.mediaId)
                .pipe(takeUntil(this.destroy$))
                .subscribe((response) => {
                  if (response.type === HttpEventType.UploadProgress) {
                    // console.log('event.type === HttpEventType.UploadProgress', response);
                  } else if (response instanceof HttpResponse) {
                    const res: any = response.body;
                    if (res.success) {
                      this.send(res.results.data.filePath, MESSAGE_TYPE.FILE);
                    }
                    if (!res.success && res.errorKey === 'FILE_SIZE_EXCEEDS_LIMITS') {
                        this.snackBarService.openError(this.localizationProvider.getByKey('chat.fileSize.error'));
                    }
                  }
                });
        }
    }

    scrollToMessage(message: IMessage): void {
        const index = this.conversation.findIndex(msg => msg.id === message.id);
        if (index !== -1) {
            const messageElement = this.messageElements.toArray()[index]?.nativeElement;
            const containerElement = this.resizableElement?.scrollingContainer?.nativeElement;

            if (messageElement && containerElement) {
                const messageOffset = messageElement.offsetTop;
                const containerOffset = containerElement.offsetTop;
                const pinnedBoxHeight = this.pinnedBoxRef.nativeElement.offsetHeight;
                const scrollToPosition = messageOffset - containerOffset - pinnedBoxHeight - 10;

                containerElement.scrollTo({
                    top: scrollToPosition,
                    behavior: 'smooth'
                });
            }
        }
    }
}

export enum CHAT_MODE {
    DEFAULT, OVERLAY, EMBEDDED
}
