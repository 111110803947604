import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class PlayerState {
    private _state$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public state$: Observable<boolean> = this._state$.asObservable();

    public setState(state: boolean): void {
        this._state$.next(state);
    }
}
