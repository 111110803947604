import { Pipe, PipeTransform } from '@angular/core';
import {DateHelper} from "@src/app/utils/date.helper";

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {

  transform(timestamp: number, format: string, localTime = false, ...args: unknown[]): string {
    return DateHelper.formatDate(timestamp, format, localTime);
  }

}
