import { ElementRef, Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from '@src/app/utils/custom-validators.util';
import {CmsService, INPUT_TYPES} from 'ui-elements';
import { IFieldsConfig } from '../../user-profile/interfaces/interfaces-common';
import { StreamMetadataSelectValues } from '@src/app/models/stream.model';
import countriesList from '@src/assets/json/countires.json';
import { ICredentials } from '@src/app/services/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class RegistrationService {
  private passwordControl = new FormControl('', [
    Validators.required,
    CustomValidators.password,
  ]);

  constructor(
      private fb: FormBuilder,
      private cmsService: CmsService
  ) {}

  public getProfileInfoPublisherForm(): FormGroup {
    return this.fb.group({
      firstName: [
        '',
        [Validators.required, CustomValidators.noWhitespaceValidator],
      ],
      lastName: [
        '',
        [Validators.required, CustomValidators.noWhitespaceValidator],
      ],
      address: [
        '',
        [Validators.required, CustomValidators.noWhitespaceValidator],
      ],
      postalCode: [
        '',
        [Validators.required, CustomValidators.noWhitespaceValidator],
      ],
      city: ['', [Validators.required, CustomValidators.noWhitespaceValidator]],
      country: [
        '',
        [Validators.required],
      ],
      email: ['', [Validators.required, CustomValidators.email]],
      website: ['', [Validators.required]],
    });
  }

  public getProfileFormViewerForm(): FormGroup {
    return this.fb.group({
      firstName: [
        '',
        [Validators.required, CustomValidators.noWhitespaceValidator],
      ],
      lastName: [
        '',
        [Validators.required, CustomValidators.noWhitespaceValidator],
      ],
      postalCode: [
        '',
        [Validators.required, CustomValidators.noWhitespaceValidator],
      ],
      city: ['', [Validators.required, CustomValidators.noWhitespaceValidator]],
      country: [
        '',
        [Validators.required],
      ],
      privatePhone: [''],
      email: ['', [Validators.required, CustomValidators.email]],
      password: this.passwordControl,
      passwordConfirmation: [
        '',
        Validators.compose([
          Validators.required,
          CustomValidators.passwordsMatch(this.passwordControl),
        ]),
      ],
      redirectLink: [
        `https://${window.location.hostname}/streams`,
      ],
    });
  }

  public getPhoneForm(): FormGroup {
    return this.fb.group({
      privatePhone: [
        '',
        [
          Validators.required,
          CustomValidators.minLengthPhone,
          CustomValidators.noWhitespaceValidator,
        ],
      ],
      smsVerification: [
        '',
        [Validators.required, CustomValidators.smsforConfirmPhone],
      ],
    });
  }

  public getFirmForm(): FormGroup {
    return this.fb.group({
      company: [''],
      vatId: [''],
    });
  }

  public getCurrencyForm(): FormGroup {
    return this.fb.group({
      currency: [
        '',
        [Validators.required, CustomValidators.noWhitespaceValidator],
      ],
    });
  }

  public getTermForm(): FormGroup {
    return this.fb.group({
      termsOfService: [false, Validators.requiredTrue],
    });
  }

  public getCurrencyFieldsConfig(): IFieldsConfig[][] {
    return [
      [
        {
          name: 'currency',
          config: {
            inputType: INPUT_TYPES.SELECT,
            placeholder: 'registration.currency',
            selectOptions: StreamMetadataSelectValues.getCurrencies(),
            isRequired: true,
            size: 6,
          },
        },
      ],
    ];
  }

  public getFirmConfig(): IFieldsConfig[][] {
    return [
      [
        {
          name: 'company',
          config: {
            inputType: INPUT_TYPES.INPUT,
            placeholder: 'registration.firmName',
            isRequired: false,
          },
        },
      ],
      [
        {
          name: 'vatId',
          config: {
            inputType: INPUT_TYPES.INPUT,
            placeholder: 'registration.vat',
            isRequired: false,
          },
        },
      ],
    ];
  }

  public getPublisherFormConfig(
    upgradeToPublisher: boolean
  ): IFieldsConfig[][] {
    return [
      [
        {
          name: 'firstName',
          config: {
            inputType: INPUT_TYPES.INPUT,
            placeholder: 'registration.firstName',
            isRequired: true,
          },
        },
        {
          name: 'lastName',
          config: {
            inputType: INPUT_TYPES.INPUT,
            placeholder: 'registration.lastName',
            isRequired: true,
          },
        },
      ],
      [
        {
          name: 'address',
          config: {
            inputType: INPUT_TYPES.INPUT,
            placeholder: 'registration.street',
            size: 6,
            isRequired: true,
          },
        },
        {
          name: 'city',
          config: {
            inputType: INPUT_TYPES.INPUT,
            placeholder: 'registration.city',
            size: 6,
            isRequired: true,
          },
        },
      ],
      [
        {
          name: 'postalCode',
          config: {
            inputType: INPUT_TYPES.INPUT,
            placeholder: 'registration.zip',
            size: 6,
            isRequired: true,
          },
        },
        {
          name: 'country',
          config: {
            inputType: INPUT_TYPES.SELECT,
            placeholder: 'registration.country',
            selectOptions: this.cmsService.countriesList,
            size: 6,
            isRequired: true,
            isAutocomplete: true,
          },
        },
      ],
      [
        {
          name: 'website',
          config: {
            inputType: INPUT_TYPES.TEXTAREA,
            placeholder: 'profile.publisher.imprint',
            isRequired: true,
          },
        },
      ],
      [
        {
          name: 'email',
          config: {
            inputType: INPUT_TYPES.INPUT,
            placeholder: 'registration.email',
            isRequired: true,
            disabled: upgradeToPublisher,
          },
        },
      ],
    ];
  }

  public getViewerFormConfig(): IFieldsConfig[][] {
    return [
      [
        {
          name: 'firstName',
          config: {
            inputType: INPUT_TYPES.INPUT,
            placeholder: 'registration.firstName',
            isRequired: true,
          },
        },
        {
          name: 'lastName',
          config: {
            inputType: INPUT_TYPES.INPUT,
            placeholder: 'registration.lastName',
            isRequired: true,
          },
        },
      ],
      [
        {
          name: 'city',
          config: {
            inputType: INPUT_TYPES.INPUT,
            placeholder: 'registration.city',
            size: 6,
            isRequired: true,
          },
        },
        {
          name: 'country',
          config: {
            inputType: INPUT_TYPES.SELECT,
            placeholder: 'registration.country',
            selectOptions: this.cmsService.countriesList,
            size: 6,
            isRequired: true,
            isAutocomplete: true,
          },
        },
      ],
      [
        {
          name: 'postalCode',
          config: {
            inputType: INPUT_TYPES.INPUT,
            placeholder: 'registration.zip',
            size: 6,
            isRequired: true,
          },
        },
        {
          name: 'privatePhone',
          config: {
            inputType: INPUT_TYPES.INPUT,
            placeholder: 'registration.phone',
            size: 6,
            isRequired: false,
          },
        },
      ],
    ];
  }

  public getTermsFieldsConfig(
    terms: ElementRef<HTMLElement>
  ): IFieldsConfig[][] {
    return [
      [
        {
          name: 'termsOfService',
          config: {
            inputType: INPUT_TYPES.SIMPLE_CHECKBOX,
            placeholder: terms,
            isRequired: true,
            id: 'labelTermsOfService',
          },
        },
      ],
    ];
  }
}

export interface IRegistrationResult {
  isRegistered: boolean;
  credentials?: ICredentials;
}

export interface ICountriesList {
  key: string;
  value: string;
}

export enum StateRegistration {
  initial = 'initial',
  publisher = 'publisher',
  viewer = 'viewer'
}
