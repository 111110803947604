import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'croppedText'
})
export class CroppedTextPipe implements PipeTransform {

    transform(text: string, numberOfLetters: number): string {
        if (text && text.length >= numberOfLetters) {
            return text.substring(
                0,
                Math.max(
                    text.lastIndexOf(' ', numberOfLetters),
                    text.indexOf(' ', numberOfLetters),
                    0
                )
            ) + '... '
        }
        return text
    }
}