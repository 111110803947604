import {Component, OnInit} from '@angular/core';
import {INPUT_TYPES} from "ui-elements";
import {FormBuilder, FormGroup} from "@angular/forms";
import {IFieldsConfig} from "@src/app/pages/user-profile/interfaces/interfaces-common";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {AutoDestroyService} from "@src/app/services/auto-destroy-service/auto-destroy.service";
import {takeUntil, tap} from "rxjs/operators";
import {RevenuesService} from "@src/app/services/revenues/revenues.service";
import {cutString} from '@src/app/utils/string.util';
import {DateHelper} from '@src/app/utils/date.helper';
import {CURRENCY_SYMBOLS} from '@src/app/components/streamdust-player/media-access-modal/media-access-modal.component';
import {getWithDecimals} from "@src/app/utils/currency.util";
import {StreamService} from '@src/app/services/stream-metadata/stream.service';
import {getFileNameFromResponseContentDisposition} from "@src/app/utils/file-name-from-content-disposition.util";
import {saveAs} from 'file-saver/FileSaver';
import {LocalizationProvider, IFormFieldsConfig} from 'ui-elements';
import {IRevenue} from '@src/app/pages/revenues-page/model/revenue.model';

@Component({
    selector: 'app-revenue-details',
    templateUrl: './revenue-details.component.html',
    styleUrls: ['./revenue-details.component.sass'],
  providers: [RevenuesService]
})
export class RevenueDetailsComponent implements OnInit {
    public viewLoading: boolean;
    public viewDone: boolean;
    public download: boolean;
    public downloadDone: boolean;
    public sendLoading: boolean;
    public sendDone: boolean;
    public form: FormGroup;
    public config: IFormFieldsConfig[];
    private id: number;
    public pdfLoading = false;
    public data: IRevenue;
    public currentMonth = false;

    constructor(
        private fb: FormBuilder,
        private activatedRoute: ActivatedRoute,
        private destroy$: AutoDestroyService,
        private revenueService: RevenuesService,
        private router: Router,
        private streamService: StreamService,
        private localizationProvider: LocalizationProvider
    ) {
    }

    ngOnInit(): void {
        this.initConfig();
        this.activatedRoute.params.pipe(
            takeUntil(this.destroy$),
            tap(({id}: Params) => {
                this.revenueService.getRevenue(id)
                    .pipe(takeUntil(this.destroy$))
                    .subscribe((res) => {
                        this.id = id;
                        this.data = res;
                        this.form.get('purchaseType').setValue(this.localizationProvider.getByKey('stream.revenue.' + this.data.productType));
                        this.form.get('transactionId').setValue(this.data.creditNotId);
                        this.form.get('title').setValue(this.data.title);
                        this.form.get('created').setValue(DateHelper.formatDate(this.data.payoutDate, 'DD MMM YYYY'));
                        this.form.get('payoutAmount').setValue(CURRENCY_SYMBOLS[this.data.currency] + ' ' + this.data.income);
                        this.currentMonth = new Date().getMonth() === new Date(this.data.payoutDate).getMonth();
                        // this.form.get('created').setValue(this.data.);
                        // Object.keys(this.form.controls).forEach(control => {
                        //     let value = res[control];
                        //     if (control === 'accountId' || control === 'transactionId') {
                        //         value = cutString(value, 0, 8);
                        //     }
                        //     if (control === 'created') {
                        //         value = DateHelper.formatDate(value, 'DD MMM YYYY');
                        //     }
                        //
                        //     if (control === 'payoutAmount') {
                        //         let price = CURRENCY_SYMBOLS[res.currency] + getWithDecimals(Math.abs(+value));
                        //         if (Math.sign(value) === -1) {
                        //             price = '-' + price;
                        //         }
                        //         value = price;
                        //     }
                        //
                        //     if (control === 'purchaseType') {
                        //         value = this.localizationProvider.getByKey('revenues.type.' + this.localizationProvider.getByKey(value.toLowerCase()));
                        //     }
                        //     this.form.get(control).patchValue(value);
                        // });
                    });
            })).subscribe();
    }

    private initConfig(): void {
        this.form = this.fb.group({
            transactionId: [''],
            purchaseType: [''],
            created: [''],
            payoutAmount: [''],
            title: ['']
        });

        this.config = [
            {
                name: 'transactionId',
                config: {
                    inputType: INPUT_TYPES.INPUT,
                    label: 'revenues.publisher.creditNumber',
                    placeholder: '',
                }
            },
            {
                name: 'title',
                config: {
                    inputType: INPUT_TYPES.INPUT,
                    label: 'revenues.publisher.title',
                    placeholder: '',
                }
            },
            {
                name: 'purchaseType',
                config: {
                    inputType: INPUT_TYPES.INPUT,
                    label: 'revenues.publisher.type',
                    placeholder: '',
                }
            },
            {
                name: 'created',
                config: {
                    inputType: INPUT_TYPES.INPUT,
                    label: 'revenues.publisher.date',
                    placeholder: '',
                }
            },
            {
                name: 'payoutAmount',
                config: {
                    inputType: INPUT_TYPES.INPUT,
                    label: 'revenues.publisher.sum',
                    placeholder: '',
                }
            }
        ];
    }

    public goBack(): void {
        this.router.navigate(['revenue']);
    }

    public downloadPdf(): void {
        if (this.download) {
            return;
        }
        this.download = true;

        this.streamService.downloadPayoutPDF(this.id.toString())
            .pipe(takeUntil(this.destroy$))
            .subscribe(res => {
                this.download = false;
                this.downloadDone = true;
                setTimeout(() => this.downloadDone = false, 5000);
                saveAs(
                    res.body,
                    getFileNameFromResponseContentDisposition(res)
                );
            }, () => this.download = false);
    }


    public viewPdf(): void {
        this.viewLoading = true;
        this.streamService.downloadPayoutPDF(this.id.toString())
            .pipe(takeUntil(this.destroy$))
            .subscribe((res) => {
                this.viewLoading = false;
                this.viewDone = true;
                setTimeout(() => this.viewDone = false, 5000);
                const fileURL = URL.createObjectURL(new Blob([res.body], {type: 'application/pdf'}));
                window.open(fileURL);
            }, () => this.viewLoading = false);
    }

    public sendPdf(): void {
        this.sendLoading = true;
        this.revenueService.sendPdf(this.id.toString())
            .pipe(takeUntil(this.destroy$))
            .subscribe((res) => {
                this.sendLoading = false;
                this.sendDone = true;
                setTimeout(() => this.sendDone = false, 5000);
            }, () => this.sendLoading = false);
    }

}
