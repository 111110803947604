import {Component} from '@angular/core';

@Component({
    selector: 'app-public-group-page',
    templateUrl: './public-group-page.component.html',
    styleUrls: ['./public-group-page.component.css']
})
export class PublicGroupPageComponent {

    constructor() {
    }
}
