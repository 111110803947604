/**
 * Created by Тима on 03.04.2020.
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {UserProfileComponent} from "@src/app/pages/user-profile/user-profile.component";
import {ProfileComponent} from "@src/app/pages/user-profile/components/profile/profile.component";
import {ChangePasswordComponent} from "@src/app/pages/user-profile/components/change-password/change-password.component";
import {CreditsComponent} from "@src/app/pages/user-profile/components/credits/credits.component";
import {WithdrawalAndConditionsComponent} from "@src/app/pages/user-profile/components/withdrawal-and-conditions/withdrawal-and-conditions.component";
import {BankDetailsComponent} from "@src/app/pages/user-profile/components/bank-details/bank-details.component";
import {DeleteAccountComponent} from "@src/app/pages/user-profile/components/delete-account/delete-account.component";
import {RouterModule} from "@angular/router";
import {ReactiveFormsModule} from "@angular/forms";
import {SharedModule} from "@src/app/modules/shared/shared.module";
import { SubscriptionManageComponent } from './components/subscription-manage/subscription-manage.component';
import { StorageAndBandwidthComponent } from './components/storage-and-bandwidth/storage-and-bandwidth.component';
import { BitrateCalculatorComponent } from './components/storage-and-bandwidth/components/bitrate-calculator/bitrate-calculator.component';
import { SubscriptionTariffsComparisonComponent } from './components/subscription-manage/subscription-tariffs-comparison/subscription-tariffs-comparison.component';


@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        SharedModule
    ],
    declarations: [
        UserProfileComponent,
        ProfileComponent,
        ChangePasswordComponent,
        CreditsComponent,
        WithdrawalAndConditionsComponent,
        BankDetailsComponent,
        DeleteAccountComponent,
        SubscriptionManageComponent,
        StorageAndBandwidthComponent,
        BitrateCalculatorComponent,
        SubscriptionTariffsComparisonComponent
    ],
    providers: []
    // bootstrap: [UserDataComponent],
    // schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class UserProfileModule { }
