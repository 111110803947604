import {Pipe, PipeTransform} from '@angular/core';
import {IVideoStatus} from "@src/app/models/stream.model";
import {RECORD_STATUS} from "@src/app/components/streamdust-player/constants/status";

@Pipe({
    name: 'itemSetting'
})
export class ItemSettingPipe implements PipeTransform {

    private voucherStatusesSettings = {
        REDEEMED: {
            translate: 'coupons.status.redeemed',
            color: '#0e54a9'
        },
        EXPIRED: {
            translate: 'coupons.status.expired',
            color: '#fc4349'
        },
        ACTIVE: {
            translate: 'coupons.status.active',
            color: 'green'
        },
        INACTIVE: {
            translate: 'coupons.status.disabled',
            color: '#fc4349'
        }
    };

    private streamStatusesSettings = {
        SCHEDULED: {
            translate: 'player.status.scheduled',
            color: '#0e54a9'
        },
        ACTIVATION: {
            translate: 'player.status.activation',
            color: '#0e54a9'
        },
        ACTIVATED: {
            translate: 'player.status.activated',
            color: '#0e54a9'
        },
        CONNECTED: {
            translate: 'player.status.connected',
            color: '#0e54a9'
        },
        PAUSED: {
            translate: 'player.status.paused',
            color: '#0e54a9'
        },
        LIVE: {
            translate: 'player.status.live',
            color: '#fc4349'
        },
        FINISHED: {
            translate: 'player.status.finished',
            color: '#fc4349'
        },
        RESCHEDULING: {
            translate: 'player.status.rescheduling',
            color: '#fc4349'
        },
        DEACTIVATED: {
            translate: 'player.status.deactivated',
            color: '#fc4349'
        },
        PENDING: {
            translate: 'player.status.pending',
            color: '#fc4349'
        },
    };

    private recordStatusesTranslates = {
        NONE: 'player.record-status.none',
        RECORDING: 'player.record-status.recording',
        STORING: 'player.record-status.storing',
        RECORDED: 'player.record-status.recorded'
    };

    transform(item: string | IVideoStatus, settingConfig: IItemSettingConfig): string {
        switch (settingConfig.itemType) {
            case ITEM_TYPE.VOUCHER: {
                return this.voucherStatusesSettings[item as string][settingConfig.settingType];
            }
            case ITEM_TYPE.STREAM: {
                if ((![RECORD_STATUS.NONE, RECORD_STATUS.RECORDING].includes((item as IVideoStatus)?.record) && !settingConfig.skipRecord) || settingConfig.ignoreNoneRecord) {
                    return settingConfig.settingType === SETTING_TYPE.TRANSLATE ? this.recordStatusesTranslates[(item as IVideoStatus)?.record] : 'orange';
                } else {
                    const statusSettings = this.streamStatusesSettings[(item as IVideoStatus)?.stream];
                    return settingConfig.settingType === SETTING_TYPE.TRANSLATE ? statusSettings?.translate : statusSettings?.color;
                }
            }
        }
        return '';
    }

}

export interface IItemSettingConfig {
    itemType: ITEM_TYPE;
    settingType: SETTING_TYPE;
    skipRecord?: boolean;
    ignoreNoneRecord?: boolean;
}

export enum SETTING_TYPE {
    TRANSLATE = 'translate',
    COLOR = 'color'
}

export enum ITEM_TYPE {
    VOUCHER = 'VOUCHER',
    STREAM = 'STREAM'
}

