<app-resizable-element #resizableElement
                       [title]="title"
                       [id]="'chat'"
                       [hiddenClass]="((chatState.chatActiveState$ | async) && (chatState.chatOverlayState$ | async) && (worldMapState.worldMapOverlayState$ | async) && (worldMapState.worldMapActiveState$ | async)) || !(chatState.chatActiveState$ | async) ? 'chat-hidden' : ''"
                       [fullScreenClass]="fullscreenChat ? 'fullscreen-chat' : ''"
                       [fullScreenDisabledClass]="fullscreenChat ? 'full-screen-disabled' : ''"
                       [chatOpenedClass]="(donationState.donationActiveState$ | async) && (donationState.donationOverlayState$ | async) && (chatState.chatOverlayState$ | async) ? 'chat-opened' : ''"
                       [embeddedClass]="(chatState.chatOverlayState$ | async) || mode === CHAT_MODE.EMBEDDED ? 'chat-embedded' : ''"
                       [dragPosition]="dragPosition"
                       [draggable]="!(chatState.chatOverlayState$ | async) && !fullscreenChat"
                       [resizable]="true"
                       [resizeDirection]="(chatState.chatOverlayState$ | async) ? RESIZABLE_DIRECTION.EAST : RESIZABLE_DIRECTION.ALL"
                       [footerHidden]="!allowSending || askNickname"
                       [fullscreen]="fullscreenButton"
                       [embedded]="embeddedButton"
                       [fullscreenBodyClass]="(fullScreen$ | async) && (chatState.chatOverlayState$ | async) ? 'fullscreen-chat-body' : ''"
                       [close]="closeButton"
                       [isChatPage]="isChatPage"
                       [chatPageLink]="chatPageLink"
                       (dragEnd)="calculateEmojisPosition()"
>
    <ng-container role="body">
        <div class="messages" #messagesContainer *ngIf="conversation">
<!--            TODO revert after release-->
            <ng-container *ngFor="let message of conversation">
                <app-message [isOwner]="isOwner" [message]="message" (deleteMessage)="deleteMessage($event)" [isEmbedded]="(chatState.chatOverlayState$ | async) || mode === CHAT_MODE.EMBEDDED"></app-message>
            </ng-container>
<!--            <div class="pinned-box" #pinnedBox>-->
<!--                <div *ngFor="let pinnedMessage of pinnedMessages"-->
<!--                     class="pinned-box__message"-->
<!--                     (click)="scrollToMessage(pinnedMessage)"-->
<!--                >-->
<!--                    <app-pinned-message-->
<!--                        [isOwner]="isOwner"-->
<!--                        [message]="pinnedMessage"-->
<!--                        (deleteMessage)="deleteMessage($event)"-->
<!--                        (pinMessage$)="pinMessage($event)"-->
<!--                        [isEmbedded]="(chatState.chatOverlayState$ | async) || mode === CHAT_MODE.EMBEDDED"-->
<!--                    ></app-pinned-message>-->
<!--                </div>-->

<!--                <div *ngIf="showPinLimitInfo" class="limit-info" (click)="showPinLimitInfo = false">{{'chat.pin.limit.info' | localize}} <i class="icon icon-close"></i></div>-->
<!--            </div>-->

<!--            <div *ngFor="let message of conversation"-->
<!--                 #messagesRef-->
<!--            >-->
<!--                <app-message-->
<!--                    [isOwner]="isOwner"-->
<!--                    [message]="message"-->
<!--                    (deleteMessage)="deleteMessage($event)"-->
<!--                    (pinMessage$)="pinMessage($event)"-->
<!--                    [isEmbedded]="(chatState.chatOverlayState$ | async) || mode === CHAT_MODE.EMBEDDED"-->
<!--                ></app-message>-->
<!--            </div>-->
        </div>

        <div *ngIf="askNickname">
            <form class="username-form-form" [formGroup]="usernameForm" (ngSubmit)="setNickname()">
                <span>{{'chat.selectUsername' | localize}}</span>
                <input [formControlName]="'username'" type="text" class="form-control" placeholder="{{'chat.username' | localize}}">
                <span *ngIf="nicknameError" class="text-danger name-taken">{{'chat.nickname.error.' + nicknameError | localize}}</span>
                <button class="btn btn-success" type="submit">{{'common.button.apply' | localize}}</button>
            </form>
        </div>
    </ng-container>
    <ng-container role="footer">
        <form (ngSubmit)="sendMessage()">
            <textarea [class.embedded-text-area]="(chatState.chatOverlayState$ | async) || mode === CHAT_MODE.EMBEDDED"
                    (keypress)="keyPress($event);"
                    [(ngModel)]="messageInputValue"
                    [ngModelOptions]="{ standalone: true}"
                    style="resize: none;" rows="2" class="form-control" placeholder="{{'chat.writeHere' | localize}}"></textarea>
            <div class="form-buttons" [class.embedded-form-buttons]="(chatState.chatOverlayState$ | async) || mode === CHAT_MODE.EMBEDDED">
<!--                TODO revert after release-->
                <button class="btn btn-smile" (click)="toggleEmojies()" type="button">
                    <i class="icon icon-smile"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span></i>
                </button>
<!--                <div class="form-buttons-row">-->
<!--                    <div class="custom-file-upload">-->
<!--                        <button class="btn btn-upload" (click)="fileInput.click()">-->
<!--                            <i class="icon icon-download"></i>-->
<!--                        </button>-->
<!--                        <input #fileInput type="file" (change)="onFileSelected($event)" class="hidden-element">-->
<!--                    </div>-->
<!--                    <button class="btn btn-smile" (click)="toggleEmojies()" type="button">-->
<!--                        <i class="icon icon-smile"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span></i>-->
<!--                    </button>-->
<!--                </div>-->
                <button class="btn btn-submit" type="submit"><i class="icon icon-send"></i></button>
            </div>
        </form>
    </ng-container>
    <emoji-mart #emojiRef
                [ngStyle]="{'display': emojiOpened ? 'block' : 'none'}"
                [sheetSize]="32"
                set="apple"
                [isNative]="true"
                [hideObsolete]="true"
                [emojisToShowFilter]="emojisToShowFilter"
                (emojiClick)="addEmoji($event)" [style]="{ position: 'absolute', right: emojiRightPosition, top: emojiTopPosition, bottom: emojiBottomPosition }"></emoji-mart>
</app-resizable-element>
