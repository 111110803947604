import {IShowStreamDateActive} from '@src/app/models/stream.model';

export enum API_URLS_APP {

  OPEN_API = 'open/api/',
  ROOT_URL = 'api/v1/',
  LOGIN = 'login',
  LOGIN_BY_FOREIGN_ID = 'login/foreignId/{mediaId}?fId={fId}&hash={hash}',
  LOGOUT = 'login/logout',
  REGISTRATION = 'registration',
  REGISTRATION_VIEWER = 'registration/viewer',
  REGISTRATION_SMS_SEND = 'registration/sms/send',
  REGISTRATION_PHONE_CONFIRM = 'registration/phone/confirm',
  GET_OVERVIEW = 'home/overview',
  EMAIL_CONFIRMATION = 'login/confirmation',
  EMAIL_CONFIRMATION_UPDATE = 'account/confirmNewEmail',
  PASSWORD_FORGOTTEN = 'login/passwordForgotten',
  PASSWORD_FORGOTTEN_CONFIRMATION = 'login/passwordForgotten/confirmation',
  CHANGE_PASSWORD_FORGET = 'login/password',
  CHANGE_PASSWORD = 'account/password',
  GET_NOTIFICATIONS = 'notifications',
  GET_MY_PROFILE = 'profile/myprofile',
  GET_MY_PROFILE_DICTIONARY = 'assets/mock-json/new-dictionary.json',
  GET_LOCALIZATION_MESSAGES = 'localization/messages',
  GET_CONVERSATION = 'messaging/conversation/{id}/{previousMessageTimestamp}/{pageSize}',
  GET_CONVERSATIONS = 'messaging/conversations',
  SEND_MESSAGE = 'messaging/socket/message',
  SEARCH = 'search/{criteria}/{page}',
  IMPORT_CONTACTS = 'contactSuggestion/addSuggestions',
  POSSIBLE_CONTACTS = 'contactSuggestion/possibleContacts',
  PUSH_NOTIFICATIONS_REGISTRATION = 'settings/notificationToken',
  READ_MESSAGE = 'messaging/conversation/read',
  FAVORITES = 'contacts/favorites',
  TOGGLE_FAVORITE = 'contacts/favorite',
  GET_REQUESTS = 'contact/requests/incoming/pending',
  MESSAGE = 'messaging/message/{id}',
  GET_INCOMING_REQUESTS = 'contact/requests/incoming/pending',
  GET_OUTGOING_REQUESTS = 'contact/requests/outgoing/pending',
  CONTACT_STATUS = 'contact/requests/',
  GET_CONTACTS = 'contacts',
  GET_CONTACTS_BY_CATEGORY = 'contacts/{categoryId}',
  UPDATE_CONTACT_GROUP = 'contacts/groups/updateContact',
  SAVE_PROFILE_ELEMENT = 'profile/element',
  SAVE_PROFILE_LIST = 'profile/elements',
  SAVE_PROFILE_OBJECTS = 'profile/objects',
  SAVE_PROFILE_NOTE = 'profile/note',
  NOTIFICATION_SETTINGS = 'notifications/settings',
  ACCOUNT_HIDDEN_STATUS = 'account/hidden',
  ACCOUNT_HIDE = 'account/hide',
  ACCOUNT_UNHIDE = 'account/unhide',
  ACCOUNT_DELETE = 'account/delete',
  REMOVE_PROFILE_OBJECTS_ROW = 'profile/objects/{fieldId}/{position}',
  REMOVE_PROFILE_OBJECTS_ROW_FOREIGN = 'profile/objects/{fieldId}/{position}?foreignId={foreignId}',
  GET_PROFILE = 'profile/profile/{profileId}',
  // WEB_SOCKET_CHAT = 'socket/chat',
  WEB_SOCKET_CHAT = 'socket/chat/v2',
  WEB_SOCKET_STREAM_STATUS = 'socket/stream/status',
  GET_CONTACTS_CATEGORIES = 'contacts/groups',
  REMOVE_CONTACTS_GROUP = 'contacts/groups/{categoryId}',
  ADD_GROUP = 'contacts/groups',
  GET_CONTACTS_GROUP = 'contacts/{categoryId}',
  CONTACT_CREATE = 'contact/requests/create',
  UPLOAD_PHOTO = 'photos/upload?&id={uploaderId}',
  UPLOAD_PHOTO_V2 = 'photos/upload',
  UPLOAD_PRESENTATION = 'presentation/file/upload',
  UPLOAD_MESSAGE_ATTACHMENT = 'messaging/{streamId}/attachment',
  WORKBENCH = 'photos/workbench?uploadid={uploaderId}',
  ADD_PHOTO = 'photo/add',
  ADD_ELEMENT_PHOTO = 'photo/add/profile/element',
  DELETE_PHOTO = 'photo/delete',
  ADD_COVER_PHOTO = 'photo/add/cover',
  GET_LAST_UPLOADED_PHOTO = 'photo/my',
  GET_LAST_UPLOADED_COVER_PHOTO = 'photo/my/cover',
  GET_PHOTO = 'photos/d/{id}/{quality}?preview={isPreview}',
  GET_WORKBENCH = 'photos/workbench?id={workbenchId}',
  GET_PROFILE_STRUCTURE = 'profile/structure',
  GET_LANGUAGE_AND_SIZES = 'settings/languagesAndSizes',
  SET_LANGUAGE = 'settings/language',
  HOME_SIZE_SYSTEM = 'settings/homeSizeSystem',
  TRAVEL_SIZE_SYSTEM = 'settings/travelSizeSystem',
  REGISTRATION_FIRST_STEP = 'registration/stage/first',
  REGISTRATION_SECOND_STEP = 'registration/stage/second',
  CHANGE_EMAIL = 'account/email',
  GET_UPDATES = 'profileUpdates/',
  GET_GROUP_DISTRIBUTION = '/analysis/groupDistribution',
  GET_MEDICAL_CONSULTATIONS_LIST = 'health/consultations/{doctor_id}',
  GET_MEDICAL_CONSULTATIONS = 'health/consultation/{id}',
  UPDATE_MEDICAL_CONSULTATION = 'health/consultation',
  CREATE_MEDICAL_NOTE = 'health/consultationNote',
  REMOVE_MEDICAL_CONSULTATION = 'health/consultation/{id}',
  GET_FAVORITE_BEVERAGES_DISTRIBUTION = '/analysis/favoriteBeveragesDistribution',
  GET_PERMISSIONS = '/permission/groupsPermissions',
  SET_PERMISSIONS = '/permission/group',
  DELETE_CONVERSATION = 'messaging/conversation/{id}',
  CONTACT_US = 'contactus/email',
  CREATE_PERMISSION_REQUEST = 'group/permission/request/create',
  GET_INCOMING_PERMISSIONS_REQUESTS = 'group/permission/request/incoming',
  GET_OUTGOING_PERMISSIONS_REQUESTS = 'group/permission/request/outgoing',
  GET_PERMITTED_GROUPS = 'group/permission/request/permittedGroups/{accountId}',
  ACCEPT_PERMISSION_REQUEST = 'group/permission/request/accept',
  REJECT_PERMISSION_REQUEST = 'group/permission/request/reject',
  THEME_COLORS = 'settings/theme/colors',
  SET_THEME = 'settings/theme',
  DISCONNECT_CONTACT = 'contacts/',
  GET_USER_THEME = 'settings/theme',
  GET_SUBSCROPTION_STATUS = 'subscription/status',
  MANAGED_PROFILE_REQUEST = 'managed/profile/request',
  GET_MANAGED_PROFILES = 'managed/profile/my',
  FIND_ACCOUNT_FOR_MANAGED = 'managed/profile/find/',
  SEND_REQUEST_FOR_MANAGE = 'managed/profile/request',
  REVOKE_ACCESS_MANAGED_PROFILE_MANAGED_SIDE = 'managed/profile/revokeAccess',
  REVOKE_ACCESS_MANAGED_PROFILE_MANAGER_SIDE = 'managed/profile/removeManagement',
  DELETE_MANAGED_PROFILE = 'managed/profile/',
  CREATE_MANAGED_ACCOUNT = 'managed/profile/create',
  LOGIN_AS_MANAGED_PROFILE = 'managed/profile/login',
  MANAGED_PROFILES_INCOMING = 'managed/profile/request/incoming',
  MANAGED_PROFILES_OUTGOING = 'managed/profile/request/outgoing',
  BACK_TO_MANAGER_ACCOUNT = 'managed/profile/logout',
  SET_MANAGING_REQUEST_STATUS = 'managed/profile/request/',
  UPLOAD_HEALTH_FILES = 'health/file/upload/{consultationId}',
  DOWNLOAD_HEALTH_FILE = 'health/file/download/{fileId}',
  SET_SUBSCRIPTION = 'subscription',
  CANCEL_SUBSCRIPTION = 'subscription/cancelation',
  PROLONG_SUBSCRIPTION = 'subscription/prolongation',
  DEFAULT_PERMISSION_TEMPLATE = 'permission/defaultGroupPermissionTemplate',
  GET_SUBSCRIPTION_FEATURES = 'subscription/features',
  GET_CONSULTATIONS = 'health/consultations/section',
  GET_CONTENT_INFO = 'localization/box/content/',
  GET_EXPLANATIONS = 'profile/explanation',
  CREATE_EVENT = 'event/create',
  GET_EVENT_TYPES = 'event/types',
  EVENTS_LIST = 'event/my',
  UPDATE_EVENT = 'event/update',
  REMIND_REQUEST = 'contact/requests/resend/notification/',

  STREAM_EDIT = 'stream',
  STREAM_RECORD_EDIT = 'stream/recording',
  GET_MEDIA = 'media/{mediaType}/{mediaId}',
  STREAM = 'stream/{id}',
  OVERLAY_LOCATION_DELETE = 'overlay/location/delete/{locationId}',
  STREAM_RECORD = 'stream/recording/{streamId}/{recordingId}',
  STREAM_RECORD_DOWNLOAD = 'stream/recording/download/{streamId}',
  STREAM_RECORD_INFO = 'stream/recording/stream/{streamId}',
  // STREAM = 'assets/mock-json/media.json',
  STREAMS = 'stream/list',
  STREAMS_PAST = 'open/api/video/{productType}/{productId}/pastVideos',
  SUGGESTED_VIDEOS = 'open/api/video/{productType}/{productId}/{videoType}',
  // STREAMS_PAST = 'open/api/media/{streamId}/pastStreams',
  // STREAMS = 'assets/mock-json/streams.json',
  VOUCHER_CREATE = 'stream/voucher/create',
  VOUCHER_REDEEM = 'payment/voucher',
  // VOUCHER_CHECK = 'media/player/voucher',
  VOUCHERS = 'voucher/{groupId}',
  // VOUCHERS = 'assets/mock-json/vouchers.json',
  VOUCHERS_EXPORT = 'voucher/export/csv/{mediaType}/{mediaId}',
  PAYMENT_RETURN = 'payment/return',
  GET_PAYMENT_CONFIG = 'payment/config',
  PAYMENT_CARD = 'payment/card',
  DELETE_PAYMENT_CARD = 'payment/card/{cardId}',
  ACTIVATE_PAYMENT_CARD = 'payment/card/return',
  SET_PAYMENT_CARD_AS_ACTIVE = 'payment/card/{cardId}/activate',
  PROCEED_PAYMENT = 'payment',
  PROCEED_SLOT_PAYMENT = 'payment/paymentSlot',
  GET_STREAM_PREVIEW = 'preview/picture/{productType}/{productId}',
  GET_STREAM_PREVIEW_PUBLIC = 'open/api/video/preview/{videoType}/{videoId}',
  UPLOAD_STREAM_PREVIEW = 'preview/picture/{productType}/{productId}',
  PREVIEW_TEASER = 'teaser/{videoType}/{productId}',
  PREVIEW_TRAILER = 'trailer/{videoType}/{productId}',
  UPLOAD_STREAM_PREVIEW_TRAILER = 'stream/trailer/{streamId}',
  // OVERLAYS = 'media/overlay/{streamId}',
  OVERLAYS = '{apiField}/{productType}/{productId}',
  OVERLAY = 'overlay/{overlayId}',
  COURSE = 'course/{overlayId}',
  // OVERLAYS = 'assets/mock-json/overlays.json',
  PRESENTATION_CREATE = 'presentation',
  OVERLAY_LOCATION_CREATE = 'overlay/location/create',
  OVERLAY_LOCATION_UPDATE = 'overlay/location/update',
  PRESENTATION_ACTIVE = 'presentation/active',
  UPDATE_REMINDERS = 'media/reminders/{mediaId}',
  ACTIVE_REMINDERS = 'media/reminders/{mediaId}/active',
  OVERLAY_LOCATION_ACTIVE = 'overlay/location/active',
  PRESENTATION_BY_MEDIAID = 'presentation/{mediaId}',
  GET_CITY_BY_COUNTRY = 'overlay/location/{countryId}/cities',
  PRESENTATION_CLICK_LINK = 'open/api/presentation/{presentationId}/click/link',
  PRESENTATION_CLICK_SHOW = 'open/api/presentation/{presentationId}/click/show',
  OVERLAY_CREATE = '{overlaysType}',
  OVERLAY_ACTIVE = '{overlaysType}/active',
  OVERLAY_START = '{overlaysType}/start',
  OVERLAY_DELETE = '{overlaysType}/{overlayId}',
  COURSES_DELETE = 'course/{overlayId}',
  USER_PROFILE = 'user/data/profile',
  USER_COMPANY = 'user/data/company',
  USER_BANK = 'user/data/bank',
  USER_AGB = 'user/data/agb',
  USER_PUBLISHER = 'user/data/publisher',
  LANDING_CREATE = 'landingpage',
  LANDING_GET = 'landingpage/{mediaType}/{mediaId}',
  // RECORDS = 'assets/mock-json/records.json',
  CONTACTS = 'user/data/contactInfo',
  BROADCAST = 'stream/broadcast/{streamId}',
  DELETE_ACCOUNT = 'account/delete',
  RECORDS = 'stream/recording/list/{streamId}',
  START_STREAM = 'stream/{streamId}/start',
  STOP_STREAM = 'stream/{streamId}/stop',
  STREAM_STATUS = 'stream/{streamId}/status',
  PUBLIC_STREAM_STATUS = 'open/api/stream/{streamId}/status',
  STREAM_VISIBILITY_GET = 'stream/settings/{streamId}',
  MEDIA_VISIBILITY_GET = 'mediasettings/{mediaType}/{mediaId}',
  STREAM_VISIBILITY_UPDATE = 'stream/settings/visibility',
  MEDIA_VISIBILITY_UPDATE = 'mediasettings/{mediaType}/{mediaId}/visibility',
  STREAM_LANDING_VISIBILITY = 'landingpage/{mediaType}/{mediaId}/active',
  STREAM_BROADCAST_STATUS = 'stream/{streamId}/broadcast/stats',
  STREAM_BROADCAST_STATUS_PUBLIC = 'open/api/stream/{streamId}/broadcast/stats',
  STREAM_TOKEN = 'open/api/stream/token/{streamId}',
  STREAM_PLAYER = 'open/api/stream/player',
  GET_STREAM_PLAYER_LINK = 'video/url',
  GET_STREAM_CHANNEL = 'stream/channel/{streamId}',
  REVENUES_LIST = 'purchase/media/list/{mediaType}/{mediaId}',
  REVENUES_TOTAL = 'purchase/media/total/{mediaType}/{mediaId}',
  REVENUES_PDF = 'purchase/media/pdf/{mediaType}/{mediaId}',
  REVENUES_ZIP = 'purchase/media/zip/{mediaType}/{mediaId}',
  PURCHASE_PDF = 'purchase/media/pdf/{purchaseId}',
  PAYOUT_PDF = 'payouts/payout/monthly/{purchaseId}/pdf',
  STREAM_YEARS = 'stream/years',
  STREAM_ACTUAL_STREAMS = 'open/api/video/actualStreams',
  STREAM_LIMITATION = 'stream/limitation',
  // STREAM_LANDING_INFO = '/api/v1/open/media/landing/{streamId}'
  // STREAM_LANDING_INFO = 'open/api/media/{streamId}',
  STREAM_LANDING_INFO = 'open/api/video/details/{productType}/{productId}',
  STREAM_IS_OWNER = 'stream/{streamId}/isOwner',
  MY_GROUPS = 'media/group/list/my',
  GET_STREAM_LINKS = 'media/link/{streamId}',
  GET_PRESENTATION_LIST = 'presentation/list/{mediaId}',
  GET_REMINDERS_AVALIBLE = 'media/reminders/{mediaId}',
  GET_OVERLAY_LIST = 'overlay/{overlayId}/location/list',
  GET_STREAM_ALLOWED_DOMAINS = 'media/allowed-domains/{mediaId}',
  STREAM_ALLOWED_DOMAINS = 'media/allowed-domains',
  STREAM_ALLOWED_DOMAINS_DELETE = 'media/allowed-domains/{domainId}/',
  STREAM_LINK = 'media/link',
  STREAM_LINK_CLICK = 'open/api/media/{mediaId}/{otherMediaId}/click',
  GET_LINKABLE_STREAMS = 'media/linkableMedia/{streamId}',
  GET_MEDIA_ONLY_BY_ID = 'media/{productId}',
  STREAM_UNLINK = 'media/unlink',
  VIEWER_GROUPS = 'media/group/list/my',
  GROUP = 'media/group/{groupId}',
  GROUP_PUBLIC = 'open/api/media/group/{groupId}',
  GROUP_GET_ACCESS = 'payment/group',
  GROUP_CHECK_ACCESS = 'open/api/media/group/access/{groupId}',
  TOGGLE_STREAM_GROUP = 'media/group/{mediaType}/{groupId}/{mediaId}',
  SAVE_GROUP = 'media/group',
  GENRES = 'cms/stream/genres',
  COUNTRIES = 'cms/countries',
  VIDEO_ON_DEMAND_SAVE = 'videoondemand',
  VIDEO_ON_DEMAND_GET = 'videoondemand/{id}',
  VIDEO_ON_DEMAND_LIST = 'videoondemand/list',
  VIDEO_UPLOAD = 'video/upload',
  VIDEO_GET = 'video/upload/{videoId}',
  VOUCHER_GROUP_GET = 'voucher/group/{productType}/{productId}',
  VOUCHER_GROUP_LIMITATIONS = 'voucher/limitations',
  VOUCHER_GROUP_CREATE = 'voucher/group/create',
  VOUCHER_GROUP_UPDATE = 'voucher/group/update',
  VOUCHER_GROUP_DEACTIVATE = 'voucher/deactivate/group/{id}',
  VOUCHER_DEACTIVATE = 'voucher/deactivate/{productType}/{streamId}/{code}',
  GET_STREAM_TEASER_PUBLIC = 'open/api/teaser/{videoType}/{videoId}',
  GET_STREAM_TRAILER_PUBLIC = 'open/api/trailer/{videoType}/{videoId}',
  GET_STREAM_OVERLAYS_PUBLIC = 'open/api/video/{videoType}/{videoId}/{overlaysTypes}',
  TOGGLE_TEASER = 'teaser/active',
  TOGGLE_TRAILER = 'trailer/active',
  TEASER_CLICK = 'open/api/teaser/{videoType}/{productId}/click',
  TEASER_VIEW = 'open/api/teaser/{videoType}/{productId}/view',
  TRAILER_CLICK = 'open/api/trailer/{videoType}/{productId}/click',
  TRAILER_VIEW = 'open/api/trailer/{videoType}/{productId}/view',
  GET_MESSAGES = 'messaging/{streamId}',
  GET_PUBLIC_MESSAGES = 'messaging/get',
  SET_CHAT_USERNAME = 'messaging/checkUsername',
  PAUSE_STREAM = 'stream/{mediaId}/pause',
  UPGRADE_TO_PUBLISHER = 'account/grant/role/publisher',
  GET_MEDIA_LIST = 'open/api/video/list',
  GET_MEDIA_LIST_FILTERS = 'open/api/video/filters',
  GET_MEDIA_LIST_TIMELINE = 'open/api/video/options/timerange',
  GET_SUBSCRIPTIONS_LIST = 'subscription/list',
  GET_SUBSCRIPTION_TARIFFS = 'subscription/tariffs',
  GET_SUBSCRIPTION_TARIFF = 'subscription/tariffs/{tariffId}',
  GET_TARIFF_PLAN = 'tariffplan/{tariffType}/{tariffId}',
  SUBSCRIPTION_ACTIVATE = 'payment/subscription/return',
  SUBSCRIPTION_CANCEL = 'subscription/cancel/{subscriptionId}',
  SUBSCRIPTION_RENEW = 'subscription/renew/{subscriptionId}',
  START_SUBSCRIPTION = 'payment/subscription',
  BILLS = 'assets/mock-json/bills.mock.json',
  BILLS_LIST = 'billing/list',
  BILLS_PDF_PUBLISHER = 'purchase/subscription/pdf/{id}',
  BILLS_PDF_PUBLISHER_CHARGEBACK = 'purchase/subscription/chargeback/{id}',
  PRESENTATION_DOWNLOAD = 'open/api/presentation/{presentationId}/file/download',
  BILLS_PDF_VIEWER = 'purchase/media/pdf/{id}',
  GET_DATA_LIMIT = 'datalimit',
  GET_TARIFFS = 'subscription/tariffs',
  SURVEY_SAVE = 'survey',
  SURVEY = 'survey/{id}',
  // SURVEY_START = 'survey/{id}/start',
  SURVEY_START = 'survey/start',
  SURVEY_ACTIVATE = 'survey/active',
  SURVEY_STOP = 'survey/{id}/stop',
  SURVEYS_LIST = 'survey/{mediaType}/{mediaId}/list',
  SURVEYS_LIST_OPEN = 'open/api/survey/{mediaType}/{mediaId}',
  SURVEY_ANSWER = 'open/api/survey/answer/{surveyId}/{answerId}',
  SURVEY_INCREMENT_VIEW = 'open/api/survey/view/{surveyId}',
  SURVEY_INCREMENT_VOTE = 'open/api/survey/vote/{surveyId}',
  SURVEY_SOCKET = 'socket/survey/status',
  SURVEY_CSV = 'survey/{mediaType}/{mediaId}/export/csv',
  MARKETING_SOCKET = 'socket/marketing/status',
  DONATION_OPTIONS = 'open/api/donations/{mediaType}/{mediaId}',
  DONATE = 'payment/donation',
  DONATION_SOCKET = 'socket/donation',
  CHAT_ACTIVATE = 'chat/active',
  SHOW_STREAM_DATE = 'stream/settings/showDate',
  PUBLISHER_HEADER = 'user/data/publisherHeader',
  PURCHASE_BY_COUNTRY = 'purchase/media/total/country/{mediaType}/{mediaId}',
  REVENUES = 'purchase/media/list/publisher',
  REVENUE = 'purchase/media/publisher/{id}',
  REVENUES_TIMERANGE = 'purchase/media/list/publisher/timerange',
  STREAM_WORLD_MAP = 'media/worldMap/{streamId}',
  OVERLAY_CLICK = 'open/api/{overlayType}/{overlayId}/click',
  OVERLAY_VIEW = 'open/api/{overlayType}/{overlayId}/view',
  REGISTER_LEAD_GENERATION = 'registration/lead',
  PAYMENT_LEAD = 'payment/lead',
  REGISTER_VIEWER_CONFIRM_EMAIL = 'registration/viewer/confirm/email',
  GET_LEADS = 'leadgeneration/{mediaType}/{mediaId}',
  GET_COURSE_PARTICIPANTS = 'course/participants/{mediaId}',
  LEADS_CSV = 'leadgeneration/{mediaType}/{mediaId}/export/csv',
  COURSES_PARTICIPANTS_CSV = 'course/participants/{mediaId}/csv',
  LEAD_DELETE = 'leadgeneration/{mediaType}/{mediaId}/{accountId}',
  COURSES_VIEW_DELETE = 'courses-overview/{mediaType}/{mediaId}/{accountId}',
  SUBACCOUNT_SAVE = 'subaccount',
  SUBACCOUNTS_LIST = 'subaccount/account/list',
  SUBACCOUNTS_LINKED_LIST = 'subaccount/linked/list',
  SUBACCOUNT_INVITE= 'subaccount/invite',
  SUBACCOUNT_ACCEPT= 'subaccount/confirm',
  SUBACCOUNT = 'subaccount/account/{id}',
  SUBACCOUNT_DELETE = 'subaccount/account/delete/{id}',
  SUBACCOUNT_HAS_CONNECTED= 'subaccount/has',
  LOGIN_AS = 'login/loginas',
  LOGOUT_AS = 'login/logoutas',
  MEDIA_INFO_FROM_SUBDOMAIN = 'landingpage/media',
  PUBLIC_LANDING_PAGE = 'open/api/landingpage/{mediaType}/{mediaId}',
  GET_STREAM_SCHEDULE = 'open/api/stream/{streamId}/calendar',
  GET_SLOT_SCHEDULE = 'open/api/stream/{streamId}/{slotId}/calendar',
  PUBLISH_SURVEY_RESULT = 'survey/publish',
  PAYMENT_FEES = 'cms/payment/fees',
  PAYMENT_VATS = 'cms/payment/vats',
  PUBLISHER_PAYOUTS = 'payouts/product/monthly',
  PUBLISHER_PAYOUT = 'payouts/product/monthly/{id}',
  PUBLISHER_PAYOUTS_TIMERANGE = 'payouts/product/monthly/timerange',
  SEND_PAYOUT_PDF = 'payouts/payout/monthly/{id}/send/creditNotes',
  DASHBOARD = 'statistic/publisher/data',
  STREAM_STATISTIC = 'statistic/media/data',
  STREAM_ONLINE_VIEWERS = 'statistic/media/online-viewers-statistic',
  MULTI_BITRATE_PAYMENT = 'payment/multibitrate/media',
  DESCRIPTION_SAVE = 'media/{mediaId}/description',
  GET_MAP_STATE = 'worldmap/active/{mediaId}',
  SET_MAP_STATE = 'mediasettings/{mediaType}/{mediaId}/worldmap',
  SET_SHARE_STATE = 'mediasettings/{mediaType}/{mediaId}/share-button',
  SET_VIDEO_TITLE = 'mediasettings/{mediaType}/{mediaId}/display-video-title',
  ACCOUNT_LANGUAGE = 'account/language'
}

export class UrlGenerator {
  /**
   * @url: url should be in 'someString/{someOption}/anotherString{anotherOption}' format
   * key in {} with braces will be replaced with value from object which stored for this key
   **/
  public static generate(url: string, options: { [key: string]: any }, queryParams: { [key: string]: any } = {}): string {
    let modifiedUrl = url;

    Object.entries(options).forEach(([key, value]: any[]) => {
      const regexp = new RegExp(`{${key}}`, 'g');

      let stringValue: string;

      try {
        stringValue = value.toString();
      } catch {
        throw new Error(`Cannot stringify value ${value}`);
      }

      modifiedUrl = modifiedUrl.replace(regexp, stringValue);
    });

    if (Object.keys(queryParams).length) {
      modifiedUrl += '?';

      Object.keys(queryParams).forEach((key, index) => {
        if (index > 0) {
          modifiedUrl += '&';
        }
        if (queryParams[key] && queryParams[key] !== 'any') {
          modifiedUrl += `${key}=${queryParams[key]}`
        }
      });
    }

    return modifiedUrl;
  }
}
