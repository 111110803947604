import {Component, Input, OnChanges} from '@angular/core';
import {DateHelper} from "@src/app/utils/date.helper";

@Component({
    selector: 'app-timeline-panel',
    templateUrl: './panel.component.html',
    styleUrls: ['./panel.component.sass']
})
export class PanelComponent implements OnChanges {

    @Input() date: number;
    @Input() actualDate: number;
    @Input() now: number;
    @Input() showCountdown: boolean;
    @Input() finished: boolean;
    @Input() cssClass: string;
    @Input() dateTranlate: string;

    formattedDate: string;
    formattedActualDate: string;
    formattedTime: string;
    formattedTimeActual: string;
    cssColorClass: string;

    constructor() {
    }

    ngOnChanges(): void {
        this.formatDateAndTime();
        this.refreshCssClass();
    }

    formatDateAndTime(): void {
        this.formattedDate = DateHelper.formatDate(this.date, 'DD MMM YYYY');
        this.formattedActualDate = this.actualDate ? DateHelper.formatDate(this.actualDate, 'DD MMM YYYY') : null;
        this.formattedTime = DateHelper.formatTime(this.date);
        this.formattedTimeActual = this.actualDate ? DateHelper.formatTime(this.actualDate) : null;
    }

    refreshCssClass(): void {
        this.cssColorClass = this.resolveCssClass();
    }

    resolveCssClass(): string {
        if (this.finished) {
            return 'timeline-finished';
        }
        if (DateHelper.timeInRange(this.date, 10)) {
            return 'timeline-can-take-action';
        }
        if (DateHelper.timePassed(this.date, 0)) {
            return 'timeline-action-taken';
        }
        return 'timeline-no-action';
    }


}
