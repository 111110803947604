import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {asBoolean} from "@src/app/utils/string.util";

@Component({
    selector: 'app-stream-age-restrictions-modal',
    templateUrl: './media-age-restrictions-modal.component.html',
    styleUrls: ['./media-age-restrictions-modal.component.sass']
})
export class MediaAgeRestrictionsModalComponent implements OnInit {
    @Input() mediaId: string;
    @Output() answer$: EventEmitter<boolean> = new EventEmitter<boolean>();
    isAnswered = false;
    isAbove18 = false;

    constructor() {
    }

    ngOnInit() {
        const above18Item = localStorage.getItem(this.mediaId + '.isAbove18');
        if (above18Item) {
            this.isAnswered = true;
            this.isAbove18 = asBoolean(localStorage.getItem(this.mediaId + '.isAbove18'));
            this.answer$.emit(this.isAbove18);
        }
    }

    answer(isAbove18): void {
        this.isAnswered = true;
        this.isAbove18 = isAbove18;
        localStorage.setItem(this.mediaId + '.isAbove18', isAbove18.toString());
        this.answer$.emit(isAbove18);
    }
}
