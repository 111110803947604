<ano-loader [class.transparent-loader]="isTransparent" [isOpen]="loadingService.loading | async"></ano-loader>

<div class="form-wrapper card" [class.chat-body]="donationsMode" [class.transparent]="isTransparent">
    <div class="card-body">
        <a *ngIf="showBackButton" href="javascript:void(0);" (click)="paymentCancelled()" class="back-link"><i
                class="icon icon-back-arrow"></i></a>
        <span class="back-text">{{(hasCards ? 'payment-cards.heidelpay.add-new-card' : 'common.button.back') | localize}}</span>
<!--        <ng-container *ngIf="!paymentType">-->
<!--            <span class="text d-block text-xs mb-20">{{'payment-cards.heidelpay.payment-type-selection' | localize}}</span>-->
<!--            <div class="payment-type-list">-->
<!--                <div class="payment-type" (click)="selectPaymentType(PAYMENT_TYPE.UNZER)">Unzer</div>-->
<!--                <div class="payment-type" (click)="selectPaymentType(PAYMENT_TYPE.PAYPAL)">Paypal</div>-->
<!--                <div class="payment-type" (click)="selectPaymentType(PAYMENT_TYPE.SOFORT)">Sofort</div>-->
<!--            </div>-->

<!--        </ng-container>-->
<!--        <ng-container *ngIf="paymentType">-->
            <span *ngIf="paymentType === PAYMENT_TYPE.UNZER" class="text d-block text-xs mb-20">{{'payment-cards.heidelpay.enter-card-info' | localize}}</span>
            <span *ngIf="paymentType !== PAYMENT_TYPE.UNZER" class="text d-block text-xs mb-20">{{'payment-cards.heidelpay.enter-user-info' | localize}}</span>
            <p *ngIf="(transactionFailInfo$ | async)?.transactionFailReason" class="invalid-feedback" [class.absolute-error-feedback]="(transactionFailInfo$ | async)?.absoluteError"
               style="display: block">{{('payment-error.' + (transactionFailInfo$ | async)?.transactionFailReason) | localize}}</p>
            <div class="row" *ngIf="paymentType === PAYMENT_TYPE.UNZER || !(authService.isAuthorized$ | async)">
                <div class="col-md-{{addNewCardModalSize}}">
                    <form id="payment-form" class="heidelpayUI form" [class.not-authorized-payment]="!isAuthorized()">
                        <div class="heidelpay-form-group" [class.payment-modal]="isModal">
                            <div id="card-element-id-number" class="form-group"></div>

                            <div id="card-element-id-expiry" class="form-group"></div>

                            <div id="card-element-id-cvc" class="form-group"></div>

                                <form [formGroup]="emailForm" *ngIf="!isAuthorized()">
                                    <div class="form-group name-input first-name-input">
                                    <ano-input formControlName="firstName"
                                               [invalid]="emailForm.get('firstName').invalid"
                                               [errors]="emailForm.get('firstName').errors"
                                               [isTouched]="emailForm.get('firstName').touched"
                                               [isEditMode]="true"
                                               [config]="{ inputType: INPUT_TYPES.INPUT, type: 'text', placeholder: 'profile.firstName', isRequired: true, classList: 'heidelpayInput' }"></ano-input>
                                    </div>
                                    <div class="form-group name-input">
                                    <ano-input formControlName="lastName"
                                               [invalid]="emailForm.get('lastName').invalid"
                                               [errors]="emailForm.get('lastName').errors"
                                               [isTouched]="emailForm.get('lastName').touched"
                                               [isEditMode]="true"
                                               [config]="{ inputType: INPUT_TYPES.INPUT, type: 'text', placeholder: 'profile.lastName', isRequired: true, classList: 'heidelpayInput' }"></ano-input>
                                    </div>
                                    <div class="form-group no-margin">
                                    <ano-input formControlName="email"
                                               [invalid]="emailForm.get('email').invalid"
                                               [errors]="emailForm.get('email').errors"
                                               [isTouched]="emailForm.get('email').touched"
                                               [isEditMode]="true"
                                               [config]="{ inputType: INPUT_TYPES.INPUT, type: 'text', placeholder: 'payment-cards.heidelpay.email-input.placeholder', isRequired: true, classList: 'heidelpayInput' }"></ano-input>
                                    </div>
                                </form>
                            <!--</div>-->
                        </div>
                        <ng-container *ngIf="agreementText && !termsAgreed">
                            <app-agreement-box [colSize]="12" [text]="agreementText" [error]="termsRequiredError" (change$)="toggleTermsAgreed($event)"></app-agreement-box>
                        </ng-container>

                        <ng-container *ngIf="!donationsMode">
                            <ng-container *ngTemplateOutlet="submitButton"></ng-container>
                        </ng-container>
                    </form>
                </div>
            </div>
<!--        </ng-container>-->
    </div>
</div>

<ng-container *ngIf="donationsMode">
    <ng-container *ngTemplateOutlet="submitButton"></ng-container>
</ng-container>

<ng-template #submitButton>
    <div class="buttons-group" [class.chat-footer]="donationsMode">
        <button (click)="submit($event)"
                class="btn btn-primary btn-block">{{'payment-cards.heidelpay.submit-button' | localize}}</button>
    </div>
</ng-template>
