import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from "@src/app/modules/shared/shared.module";
import {StreamWorldMapState} from '@src/app/state/stream-world-map';
import {ChatPageComponent} from '@src/app/pages/chat-page/chat-page.component';

@NgModule({
  declarations: [ChatPageComponent],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [
    ChatPageComponent
  ],
  providers: [
    StreamWorldMapState
  ],

  entryComponents: [ChatPageComponent]
})
export class ChatPageModule {
}
