import {Component, OnInit} from '@angular/core';
import {IFormFieldsConfig} from '@src/app/services/stream-metadata/stream.service';
import {FormBuilder, Validators} from '@angular/forms';
import {INPUT_TYPES} from 'ui-elements';
import {CustomValidators} from '@src/app/utils/custom-validators.util';
import {SubAccountsService} from '@src/app/services/sub-accounts/sub-accounts.service';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import {takeUntil} from 'rxjs/operators';
import {Router} from '@angular/router';
import {InfoPanelsConfig} from '@src/app/components/info-panel/info-panel.component';

@Component({
    selector: 'app-sub-account-invitation',
    templateUrl: './sub-account-invitation.component.html',
    styleUrls: ['./sub-account-invitation.component.sass'],
    providers: [AutoDestroyService, SubAccountsService]
})
export class SubAccountInvitationComponent implements OnInit {
    public InfoPanelsConfig = InfoPanelsConfig;
    public inviteSent: boolean;
    public fieldsConfig: IFormFieldsConfig[][] = [[
        {
            name: 'email',
            control: ['', [Validators.required, CustomValidators.email]],
            config: {
                inputType: INPUT_TYPES.INPUT,
                placeholder: 'subaccounts.email',
                isRequired: true,
                size: 8
            }
        },
    ]];

    public form = this.fb.group({
        email: ['', [Validators.required, CustomValidators.email]]
    });

    public loading = false;

    constructor(
        private fb: FormBuilder,
        private subAccountsService: SubAccountsService,
        private destroy$: AutoDestroyService,
        private router: Router
    ) {
    }

    ngOnInit(): void {
    }

    public submit(): void {
        this.form.markAllAsTouched();
        if (this.form.invalid) {
            return;
        }
        this.loading = true;
        this.subAccountsService.sendInvite(this.form.get('email').value)
            .pipe(takeUntil(this.destroy$))
            .subscribe((res) => {
                this.loading = false;
                if (!res.success) {
                    this.form.get('email').setErrors({[res.errorKey]: true});
                    return;
                }
                this.inviteSent = true;
            }, () => {
                this.loading = false;
            });

    }

    public goBack(): void {
        this.router.navigate(['subaccounts']);
    }

}
