import {IUnitKeyValue, LocalizationProvider} from 'ui-elements';
import {IPreviewPicture, } from '@src/app/services/stream-metadata/stream.service';
import {ICroppingParams} from '@src/app/components/photo-cropper/photo-cropper.component';
import {IContactInfo, IUserPublisher} from '@src/app/pages/user-profile/components/profile/services/profile.service';
import {IMediaItem} from '@src/app/models/core.model';
import {RECORD_STATUS, STOP_REASON, STREAM_STATUS} from '@src/app/components/streamdust-player/constants/status';
import {IPagedResponseResults} from '@src/app/models/response.model';
import {MEDIA_TYPE} from '@src/app/components/streamdust-player/constants/mediaType';
import {CONTENT_RESTRICTION} from '@src/app/constants/content-restrictions';
import {IPaymentUrlsReq, PAYMENT_CARD_TYPE} from '@src/app/components/payment-cards-list/payment-cards-list.component';
import {IHeidelpayCard, ICommonPaymentCard} from '@src/app/components/payment-cards-list/heidelpay-payment/heidelpay-payment.component';
import {IMediaGroup} from '@src/app/models/stream-groups.model';
import {COURSE_COLOR, OVERLAY_TYPE} from 'ui-elements';
import {IVideoInfo} from '@src/app/services/video-upload/video-upload.service';
import {IUserAGB} from '@src/app/pages/user-profile/components/withdrawal-and-conditions/services/withdrawal-and-conditions.service';
import {PaymentsStream, NewPaymentsNoPremiun, PaymentsVideo} from '@src/app/models/payments-stream';
import {IPaymentsModel} from '@src/app/pages/stream-page/components/stream-metadata/stream-metadata.component';
import * as _ from 'lodash';
import {IPresentationList} from '@src/app/services/file/presentation.service';
import {IPresentationData} from '@src/app/components/pdf-viewer-img/pdf-viewer-img.component';
import {IInfoTable} from '@src/app/pages/stream-page/components/stream-revenue/stream-revenue.component';
import {VIDEO_VISIBLE_SETTINGS} from '@src/app/models/video-on-demand.model';

export interface IMediaMetadata {
    id?: string;
    owner?: any;
    title: string;
    description: string;
    startTimestamp: any;
    endTimestamp: any;
    recordingEnabled: boolean;
    backupChannelEnabled: boolean;
    chatEnabled: boolean;
    visible: boolean;
    regionRestriction: string;
    price: IPrice | number;
    paymentSystem: PAYMENT_SYSTEM;
    streamType: LIVESTREAM_TYPE;
    online?: boolean;
    previewPicture?: any;
}

export interface IPublicStreamInfo {
    id: string;
    title: string;
    description: string;
    price: IPrice;
    contentAccessType: string;
    contentType: string;
    created: number;
    endTimestamp: number;
    genre: string;
    owner: string;
    previewPicture: IPreviewPicture;
    fallbackPicture: IPreviewPicture;
    startTimestamp: number;
    hasRecord: boolean;
    status: IVideoStatus;
    videoType: string;
    stoppedAt?: number;
    startedLiveAt?: number;
    groups: IMediaGroup[];
    paymentSystem: IPaymentsModel;
    streamType: LIVESTREAM_TYPE;
    hasBeenLive: boolean;
}

export interface IVoucher {
    streamId: string;
    code: string;
    article: string;
    active: boolean;
    discount: number;
    expiryDate: any;
    disposable: boolean;
}

export interface IVideoStatus {
    stream: STREAM_STATUS;
    record: RECORD_STATUS;
    live: { duration: number };
    url?: string;
}

export interface IPrice {
    amount: number;
    currency: string;
}

export interface IVoucherRequest {
    amount: number;
    streamId: string;
}

export interface IVoucherRedeemRequest {
    code: string;
    productId: string;
    videoType?: string;
    discount?: {
        discount: number;
        newPrice: IPrice;
        oldPrice: IPrice;
    };
    mediaId?: string;
    paymentSlotId?: string;
}

export interface IProceedMediaPaymentRequest extends IPaymentUrlsReq {
    productId?: string;
    videoType?: string;
    externalCardId: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    voucherCode?: string;
    // type?: PAYMENT_CARD_TYPE;
    // number?: string;
    // expiryDate?: string;
    // heidelpayCard?: IHeidelpayCard;
    // newCard?: boolean;
    accessToken?: string;
    mediaId?: string;
    paymentSlotIds?: string[];
    paymentSlotId?: string;
    paymentCard?: ICommonPaymentCard;
    saveCard?: boolean;
}

export interface IMediaPaymentRequest extends IPaymentUrlsReq {
    userCardId?: string;
    paymentCard?: ICommonPaymentCard;
    saveCard?: boolean;
    productId?: string;
    videoType?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    voucherCode?: string;
    accessToken?: string;
    mediaId?: string;
    paymentSlotIds?: string[];
    paymentSlotId?: string;
    paymentMethod?: {
        resourceId?: string;
        method?: string;
        userCardId?: string;
        saveCard?: boolean;
        paymentCard?: ICommonPaymentCard;
    };
    groupId?: string;
}

export interface IOverlayRequest {
    productId: string;
    productType: MEDIA_TYPE;
    id?: string;
    name: string;
    link?: string;
    color?: string;
    textDescription?: string;
    showDuration: number;
    startTime: number;
    position: string;
    picture: {
        workbenchId: string;
        previewSettings: ICroppingParams;
    };
    noPhoto?: boolean;
}

export interface IPublisherPayload {
    name: string;
    description: string;
    website: string;
    pictureId?: string;
    newPicture?: {
        workbenchId: string;
        previewSettings: ICroppingParams;
    };
    leadTextField1: string;
    leadTextField2: string;
    customFieldOn: boolean;
    customFieldName: string;
}

export interface ILandingRequest {
    productId: string;
    productType: MEDIA_TYPE;
    wallpaper: {
        workbenchId: string;
        previewSettings?: ICroppingParams;
    };
    websiteUrl: string;
    domain: string;
    active: boolean;
}

export interface ILanding {
    streamId: string;
    websiteUrl: string;
    domain: string;
    active: true;
    wallpaper: {
        photoId: string;
        photoUrl: string;
    };
}

export interface IOverlayActive {
    overlayId?: string;
    courseId?: string;
    active?: boolean;
    start?: boolean;
}

export interface ICroppingSettings {
    workbenchId: string;
    previewSettings: ICroppingParams;
}

export interface IStreamPreviewPayload {
    streamId: string;
    workbenchId: string;
    previewSettings: ICroppingParams;
}

export interface IStreamPreview {
    photoId: string;
    photoUrl: string;
}

export interface IStreamPreviewVideoMetadata {
    video?: {
        accountId?: { internalId: string; }
        created?: number
        fileName?: string;
        fileSize?: number
        id?: string;
        provider?: string;
        updated?: number
        url?: string;
    };
    clicks?: number;
    views?: number;
    videoId?: string;
    videoUrl?: string;
    advertisingUrl?: string;
    active?: boolean;
}

export interface IPresentationMetadata {
    lastModified?: number;
    lastModifiedDate?: Date;
    name?: string;
    size?: number;
    type?: string;

    accountId?: { internalId: string; }
    created?: number
    fileName?: string;
    fileSize?: number
    id?: string;
    provider?: string;
    updated?: number
    url?: string;
    clicks?: number;
    views?: number;
}

export interface IStreamPreviewTeaserTrailer extends IStreamPreviewVideoMetadata {
    streamId: string;
}

export interface IStreamRecord {
    id: {
        recordingId: string;
        streamId: string;
    };
    name: string;
    fileName: string;
    downloadUrl: string;
    fileSize: 0;
    duration: 0;
    created: Date;
}


export class StreamMetadataSelectValues {
    constructor(
        private localizationProvider: LocalizationProvider,
    ) {
    }

    static getPicturePositions(): IUnitKeyValue[] {
        return [
            {
                key: OVERLAY_TYPE.LEFT,
                value: 'overlay.position.left'
            },
            {
                key: OVERLAY_TYPE.RIGHT,
                value: 'overlay.position.right'
            },
            {
                key: OVERLAY_TYPE.BOTTOM,
                value: 'overlay.position.bottom'
            },
        ];
    }

    static getColorsTextDescription(): IUnitKeyValue[] {
        return [
            {
                key: COURSE_COLOR.WHITE,
                value: 'course.color.white'
            },
            {
                key: COURSE_COLOR.BLACK,
                value: 'course.color.black'
            }
        ];
    }

    static getLivestreamTypes(): IUnitKeyValue[] {
        return [
            {
                key: LIVESTREAM_TYPE.DEFAULT,
                value: 'stream.type.default'
            },
            {
                key: LIVESTREAM_TYPE.THREE_SIXTY,
                value: 'stream.type.vr'
            }
        ];
    }

    static getPaymentSystem(premiumPublisher, mediaType?): IUnitKeyValue[] {
        if (!premiumPublisher) {
            return NewPaymentsNoPremiun;
        }

        return PaymentsVideo;
    }

    static getNames(): IUnitKeyValue[] {
        return [
            {
                key: 'No group',
                value: 'No group'
            },
        ];
    }

    static getCountries(): IUnitKeyValue[] {
        return [
            {
                key: '1',
                value: 'Andorra'
            },
            {
                key: '2',
                value: 'United Arab Emirates'
            },
            {
                key: '3',
                value: 'Afghanistan'
            }
        ];
    }

    static getCurrencies(): IUnitKeyValue[] {
        return [
            {
                key: 'EUR',
                value: '€'
            },
            {
                key: 'USD',
                value: '$'
            }
        ];
    }

    static getPrices(): IUnitKeyValue[] {
        return [
            {
                value: 1.99,
                key: 1.99,
                unit: '€'
            },
            {
                value: 2.49,
                key: 2.49,
                unit: '€'
            },
            {
                value: 2.99,
                key: 2.99,
                unit: '€'
            },
            {
                value: 3.49,
                key: 3.49,
                unit: '€'
            },
            {
                value: 3.99,
                key: 3.99,
                unit: '€'
            },
            {
                value: 4.49,
                key: 4.49,
                unit: '€'
            },
            {
                value: 4.99,
                key: 4.99,
                unit: '€'
            },
            {
                value: 5.49,
                key: 5.49,
                unit: '€'
            },
            {
                value: 5.99,
                key: 5.99,
                unit: '€'
            },
            {
                value: 6.49,
                key: 6.49,
                unit: '€'
            },
            {
                value: 6.99,
                key: 6.99,
                unit: '€'
            }
        ];
    }

    getConfirmation(): IUnitKeyValue[] {
        return [
            {
                key: true,
                value: this.localize('stream.selector.enable_recording.selector.yes'),
            },
            {
                key: false,
                value: this.localize('stream.selector.enable_recording.selector.no'),
            }
        ];
    }

    localize(key) {
        return this.localizationProvider.getByKey(key);
    }
}

export enum PAYMENT_SYSTEM {
    AVAILABLE_FOR_EVERYONE = 'AVAILABLE_FOR_EVERYONE',
    ONLY_WITH_VOUCHER = 'ONLY_WITH_VOUCHER',
    FREE = 'FREE',
    LEAD_GENERATION = 'LEAD_GENERATION',
    FREE_ACCESS = 'FREE_ACCESS',
    LIMIT_ACCESS = 'LIMIT_ACCESS',
    VOUCHER = 'VOUCHER',
    PAY_PER_VIEW = 'PAY_PER_VIEW',
    ENTERPRISE_LOGIN = 'ENTERPRISE_LOGIN',
    COURSE_ACCESS = 'COURSE_ACCESS',
}

export enum LIVESTREAM_TYPE {
    CREATE = 'CREATE',
    DEFAULT = 'DEFAULT',
    APPEND = 'APPEND',
    THREE_SIXTY = 'THREE_SIXTY'
}

export enum DATETIME_INPUT_TYPE {
    START, END
}

export const STREAM_SORTING_PROPERTY = [
    {
        key: 'title',
        value: 'TITLE'
    },
    {
        key: 'startTimestamp',
        value: 'START_TIMESTAMP'
    },
    {
        key: 'price',
        value: 'PRICE'
    }
];

export const VOUCHER_SORTING_PROPERTY = [
    {
        key: 'code',
        value: 'CODE'
    },
    {
        key: 'activated',
        value: 'ACTIVATED'
    }
];

export interface IStreamRecordingData {
    duration: number;
    fileName: string;
    fileSize: number;
    fileUrl: string;
    recordInfoTable?: IInfoTable[];
    isRecordDownloading?: boolean;
}

// TODO: REMOVE THIS
export interface IStreamStatus {
    stream: STREAM_STATUS;
    record: RECORD_STATUS;
    live: {
        duration: number;
    };
    stopReason?: STOP_REASON;
}

export interface IStreamModelMetadata {
    id?: string;
    title: string;
    genre: string;
    description: string;
    startTimestamp: any;
    endTimestamp: any;
    recordingEnabled: boolean;
    price: IPrice;
    startedAt: number;
    stoppedAt: number;
    currency: string;
    paymentSystem: any;
    streamType: string;
    isStarted?: boolean;
    isStopped?: boolean;
    hasRecording?: boolean;
    status?: IStreamStatus;
    duration?: number;
    recordingData?: IStreamRecordingData[];
    productType?: string;
    formattedStartDate?: string;
    visible?: VIDEO_VISIBLE_SETTINGS;
    video?: IVideoInfo;
    videoId?: string;
    linkedMedia?: IStreamModelMetadata[];
    videoType?: MEDIA_TYPE;
    deactivated?: boolean;
    owner?: string;
}

export interface IStreamStatuses {
    isStarted: boolean;
    isStopped: boolean;
}

export interface IStreamModel {
    media: IStreamModelMetadata;
    groupsSettings: {
        enabled: boolean;
        groups: string[];
        // groups: [
        //     {
        //         name: string;
        //         paymentSystem: string;
        //         price: number;
        //     }
        // ]
    };
    programSettings: {
        enabled: true;
        name: string;
        slots: IPaymentSlot[];
        // slots: [
        //     {
        //         title: string;
        //         startTimestamp: number;
        //         endTimestamp: number;
        //         paymentSystem: string;
        //         price: number;
        //     }
        // ]
    };
    preview?: {
        workbenchId?: string;
        previewSettings?: ICroppingParams;
        photoId?: string;
        newPicture?: ICroppingSettings;
    };
    previewPicture?: IStreamPreview;
    donationSettings: {
        enabled: boolean;
        donations: [
            {
                name: string;
                sum: number;
            }
        ];
        freeSums: {
            enabled: boolean;
            names: string[];
        }
    };
    advancedSettings: {
        chatEnabled: boolean;
        ageRestriction: boolean;
        regionRestriction: string;
        showStreamDate: boolean;
    };
    showStreamDate?: boolean;
}

export interface ISlotModel {
    media: IPaymentSlot;
    previewPicture?: IStreamPreview;
}

export interface IStreamRecordInfoModel {
    description: string;
    endStreamTimestamp: number;
    fileSize: string;
    fileUrl: string;
    fileName: string;
    genre: string;
    id: {
        recordingId: string;
        streamId: string;
    };
    paymentSystem: string;
    price: {
        amount: number,
        currency: 'string'
    };
    startStreamTimestamp: number;
    title: number;
}

export interface IStreamRecordModel {
    recording: {
        streamId?: string,
        recordingId?: string,
        fileUrl?: string;
        title: string;
        description: string;
        paymentSystem: string;
        priceAmount: number,
        priceCurrency: string;
    };
    groupsSettings: {
        enabled: boolean;
        groups: string[]
    };
    donationSettings: {
        enabled: boolean;
        donations: [
            {
                name: string;
                sum: number;
            }
        ];
    };
    freeSumSettings: {
        enabled: boolean;
        freeSumNames: string[]
    };
    advancedSettings: {
        chatEnabled: boolean;
        ageRestriction: boolean;
        restrictedRegions: string;
    };
    visibilitySettings: {
        visibleOnSite: boolean;
        visibleOnProfilePage: boolean;
        websiteEnabled: boolean;
    };
}

export interface IMediaVisibility {
    advancedSettings: {
        worldMapEnabled: boolean;
        shareButtonEnabled: boolean;
        displayVideoTitle: boolean;
    };
}

export interface ILandingVisibility {
    active: string;
}

export interface IChatActive {
    active: boolean;
    productId?: string;
    productType?: MEDIA_TYPE;
}

export interface IShowStreamDateActive {
    showStreamDate: boolean;
    productId?: string;
    productType?: MEDIA_TYPE;
}


export interface MediaOverviewData {
    termsConditions: IUserAGB;
    video: IPublicStreamInfo;
    publisher: IUserPublisher;
    contacts: IContactInfo;
    actualStreams: IPagedResponseResults<IStreamModel>;
    pastVideos: IPagedResponseResults<IMediaItem>;
    futureStreams: IPagedResponseResults<IStreamModel>;
    restrictions: CONTENT_RESTRICTION[];
    advancedSettings: {
        ageRestriction: boolean;
        chatEnabled: boolean;
        restrictedRegions: string[];
        shareButtonEnabled: boolean;
        displayVideoTitle: boolean;
        showStreamDate: boolean;
        onlyForRegistered: boolean;
        domainRestrictionEnabled: boolean;
    };
    paymentSlots?: IPaymentSlot[];
    programSettings?: {
        name: string;
        slots: IPaymentSlot[];
    };
    presentation?: IPresentationData;
    reminders?: IRemindersData;
    linkedMedia: IStreamModelMetadata[];
    accessOptions?: IAccessOptions;
}

export interface IAccessOptions {
    paymentSystemGroup: PAYMENT_SYSTEM;
    psDonation: boolean;
    psEntrepriseLogin: boolean;
    psLeadGeneration: boolean;
    psPayPerView: boolean;
    psSlot: boolean;
    psVoucher: boolean;
}

export interface IMediaContentRequest {
    productId: string;
    videoType: string;
    slotId?: string;
    accessToken?: string;
    originReferer?: string;
}

export interface IStreamRevenuesTotal {
    couponsAmount: number;
    feeVatPercent: number;
    totalFeesVat: number;
    payoutAmount: number;
    priceVat: number;
    purchasesAmount: number;
    streamId: string;
    streamdustFeeSumNet: number;
    totalCost: number;
    transactionFeeSumNet: number;
    withdrawalAmount: number;
    totalFeesGross: number;
}

export interface IMediaTimerange {
    year: number;
    month?: any;
    timeRange: {
        start: number;
        end: number;
    };
}

export interface ITimerange {
    start: number;
    end: number;
}

export interface IStreamLimitation {
    minimalPriceAmount: number;
    paymentSystems: string[];
}

export interface IPaymentSlot {
    id?: string;
    title: string;
    startTimestamp: number;
    endTimestamp: number;
    slotPayment: PAYMENT_SYSTEM;
    price: IPrice;
    active?: boolean;
    hasAccess?: boolean;
}


export interface IRemindersData {
    mediaRemindersEnabled: boolean;
    viewerRemindersEnabled: boolean;
}
