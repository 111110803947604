<div class="profile-data" *ngIf="publisher">
    <div class="profile-data-header">
        <div class="row row-padding-10 align-items-end">
            <div class="col-auto">
                <img class="img-responsive" [src]="publisher?.picture?.photoUrl ? (environment.backendApiHost + publisher?.picture?.photoUrl) : '/assets/img/mock/no-user-photo.png'" alt="image">
            </div>
            <div class="col">
                <h3><b>{{publisher?.name}}</b></h3>
                <ul class="list-link mb-10">
                    <li>{{publisher?.videoAmount || 0}} Videos</li>
                    <li *ngIf="publisher?.country">| {{publisher?.country | keyToValue: countries}}</li>
                </ul>
                <ul class="list-social" [class.mt-30]="shouldBeCollapsed">
                    <!-- <li *ngIf="publisher?.telegram"><a href="#" target="_blank"><i class="icon icon-telegram-round"></i></a></li> -->
                    <li *ngIf="contacts?.website"><a [href]="contacts?.website | website" target="_blank"><i class="icon icon-home-round"></i></a></li>
                    <li *ngIf="contacts?.phone"><a [href]="'tel:' + contacts.phone"><i class="icon icon-phone-round"></i></a></li>
                    <li *ngIf="contacts?.email"><a [href]="'mailto:' + contacts.email"><i class="icon icon-telegram-round"></i></a></li>
                    <!-- <li *ngIf="contacts?.instagram"><a href="#" target="_blank"><i class="icon icon-instagram-round"></i></a></li> -->
                </ul>
            </div>
        </div>
    </div>
    <div class="profile-data-body">
        <div #publisherDescription>
            <ng-container *ngIf="publisher?.description">
                <p class="description-line" *ngFor="let descriptionLine of publisher?.description.split('\n')">
                    {{descriptionLine}}
                </p>
            </ng-container>
        </div>

        <!--        <div class="show-more-button-wrapper">-->
        <!--            <a *ngIf="shouldBeCollapsed" class="show-more-button" href="#"-->
        <!--               (click)="toggleDescription($event)">{{isDescriptionCollapsed ? 'Show more...' : 'Show less...'}}</a>-->
        <!--        </div>-->

    </div>
    <ng-container *ngIf="showShareMedia">
        <span class="share-title">{{'public-media-page.shareTitle' | localize}}</span>
        <ul class="list-social" [class.mt-30]="shouldBeCollapsed">
            <!--        <li><a href="https://www.instagram.com/" target="_blank"><i class="icon-instagram icon-round"></i></a></li>-->
            <li><a [href]="'https://twitter.com/share?url=' + shareUrl" target="_blank"><i class="icon-twitter icon-round"></i></a></li>
            <li><a [href]="'https://www.facebook.com/sharer/sharer.php?u=' + shareUrl" target="_blank"><i class="icon-facebook icon-round"></i></a></li>
            <li><a [href]="'https://www.linkedin.com/sharing/share-offsite/?url=' + shareUrl" target="_blank"><i class="icon-linkedin icon-round"></i></a></li>
            <li><a [href]="'https://www.xing.com/spi/shares/new?url=' + shareUrl" target="_blank"><i class="icon-xing icon-round"></i></a></li>
            <li><a [href]="'https://api.whatsapp.com/send?text=' + shareUrl" target="_blank"><i class="icon-whatsapp icon-round"></i></a></li>
        </ul>
    </ng-container>
    <ul class="list-link">
        <li>
            <a *ngIf="isOpenModal" href="javascript:void(0)" (click)="openModal()">{{'public-media-page.imprint' | localize}}</a>
            <a *ngIf="!isOpenModal" [href]="getWebsiteContent()" target="_blank">{{'public-media-page.imprint' | localize}}</a>
        </li>
        <li *ngIf="data.termsConditions?.privacyPolicyUrl" class="list-link-separator">|</li>
        <li *ngIf="data.termsConditions?.privacyPolicyUrl">
            <a [href]="data.termsConditions?.privacyPolicyUrl | website" target="_blank">{{'public-media-page.privacy' | localize}}</a>
        </li>
        <li *ngIf="data.termsConditions?.termsConditionUrl" class="list-link-separator">|</li>
        <li *ngIf="data.termsConditions?.termsConditionUrl">
            <a [href]="data.termsConditions?.termsConditionUrl | website" target="_blank">{{'public-media-page.conditions' | localize}}</a>
        </li>
    </ul>
</div>
