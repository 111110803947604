<div class="plan price-plan {{config?.coverClass || ''}}" [class.best-plan]="plan?.mostPopular">
    <div class="panel-header-text">
        {{'landing-page.streamdust-subscription.' + plan?.title | localize}}
    </div>
    <div *ngIf="plan?.mostPopular" class="plan-badge">
        {{'payment-cards-list.subscription.most-popular' | localize}}
    </div>
    <div class="item-body">
        <span class="rate">{{planPriceMonth?.amount || 0}} {{planPriceMonth?.currency || 'EUR'}}</span>
        <span class="plan-sub-title">
            {{'payment-cards-list.subscription.per-month' | localize}}
            <ng-container *ngIf="planPriceMonth?.amount">,</ng-container>
            <ng-container
                    *ngIf="planPriceMonth?.amount && config?.planPeriod === TARIFF_PLAN_PERIOD.MONTH"><br/> {{'payment-cards-list.subscription.can-be-cancelled' | localize}}</ng-container>
            <span *ngIf="config?.planPeriod === TARIFF_PLAN_PERIOD.YEAR">
                <br/>{{'payment-cards-list.subscription.one-time' | localize}}
                <br/>{{'payment-cards-list.subscription.payment-of' | localize}}
                <br/>{{planPriceYear?.amount || 0}} {{planPriceYear?.currency || 'EUR'}} {{'payment-cards-list.subscription.yearly' | localize}}
            </span>
        </span>

        <ul class="plan-list mb-30">
            <li *ngFor="let planBenefit of plan?.benefits" [innerHTML]="planBenefit">
            </li>
            <li class="no-icon pt-0">
                <span class="text-mutted">{{'landing-page.alle'|localize}}</span>
            </li>
        </ul>

        <div *ngIf="(planPriceMonth?.amount && !config?.hideActionButton) || !config?.isAuthorized"
             class="row row-padding-0 justify-content-center">
            <div class="col-auto">
                <button class="btn btn-success btn"
                        (click)="actionButtonPressed()">{{'payment-cards-list.subscription.buttons.upgrade' | localize}}</button>
            </div>
        </div>
    </div>
</div>
