export interface IWebSocketResponse {
  data: string;
}

export interface IWebSocketDataResponse {
  data: any;
  type?: WEBSOCKET_STATUS_TYPE;
  success: boolean;
}

export enum MESSAGES_ACTIONS {
  READ = 'READ',
  SEND = 'SEND',
  CMD = 'CMD',
  INITIAL = 'INITIAL',
  DELETE = 'DELETE',
  UPDATE_BADGE = 'UPDATE_BADGE',
}

export interface IWebSocketMessage {
  action: MESSAGES_ACTIONS;
  [key: string]: any;
}

export enum WEBSOCKET_STATUS_TYPE {
  STATUS = 'STATUS',
  VIEWERS = 'VIEWERS',
  WORLD_MAP = 'WORLD_MAP',
  SHARE_BUTTON = 'SHARE_BUTTON',
  CHANNEL = 'CHANNEL',
  BANDWIDTH_WARNING = 'BANDWIDTH_WARNING',
}
