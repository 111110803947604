import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {API_URLS_APP, UrlGenerator} from "@src/app/constants/api-urls.constant";
import {ApiService} from "ui-elements";

@Injectable({
    providedIn: 'root'
})
export class StreamRecordService {

    constructor(
        private apiService: ApiService
    ) {
    }

    public downloadStreamRecord(streamId: string): Observable<any> {
        return this.apiService.get(UrlGenerator.generate(API_URLS_APP.STREAM_RECORD_DOWNLOAD, {
            streamId: streamId
        }), {responseType: 'blob', observe: 'response'});
    }
}
