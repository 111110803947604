<ano-loader [isOpen]="!eventSiteLoaded"></ano-loader>
<div *ngIf="playerState" class="resizable-bounds" id="resizable-bounds"></div>
<ng-container *ngIf="!embedded">
    <ng-container *ngIf="eventSiteLoaded && !eventSite && !eventSiteNavigate">
        <app-header [isEventSite]="!!eventSite"></app-header>
        <section class="section section-header-area" id="publisher-banner"
                 *ngIf="!hideHeaderImage && !isTokenExpiredPage || (eventSiteLoaded && eventSite) && !isTokenExpiredPage">
<!--            <ng-container *ngIf="!loaderPic">-->
                <div *ngIf="!userService.headerPictureLoaded"></div>
                <img class="img-responsive img-stream-header" *ngIf="customHeaderImage || userService.headerPictureLoaded"
                     [src]="customHeaderImage || headerImage">
<!--            </ng-container>-->
        </section>

        <div class="container">
            <app-info-panel
                    *ngIf="userProfile?.accountType === USER_TYPE.PUBLISHER && userProfile?.waitingApproval && (authService.isAuthorized$ | async)"
                    [infoPanel]="infoPanel"></app-info-panel>
            <app-info-panel
                    *ngIf="(streamService?.stream$ | async)?.media?.status?.stream === STREAM_STATUS.FINISHED && (streamService?.stream$ | async)?.media?.status?.record === RECORD_STATUS.NONE  && !(streamService?.stream$ | async)?.media?.hasRecording"
                    [infoPanel]="InfoPanelsConfig.streamFinishedInfo"></app-info-panel>
            <app-info-panel
                    *ngIf="(streamService?.stream$ | async)?.media?.status?.stopReason === STOP_REASON.BANDWIDTH_LIMIT"
                    [infoPanel]="InfoPanelsConfig.streamBandwidthLimitInfo"></app-info-panel>
            <app-info-panel
                    *ngIf="(streamService?.stream$ | async)?.media?.deactivated"
                    [infoPanel]="InfoPanelsConfig.mediaDeactivated"></app-info-panel>
            <app-info-panel *ngIf="!hideSubAccountPanel && (userService?.userType$ | async) === USER_TYPE.SUB_ACCOUNT"
                            [infoPanel]="InfoPanelsConfig.loggedInAsSubAccount"></app-info-panel>
            <app-info-panel *ngIf="(userService.userProfile$ | async)?.dataLimits?.storageSpace?.limitReached"
                            [infoPanel]="InfoPanelsConfig.storageOrBandwidthLimitReached">
                <ng-container role="body"><a [routerLink]="'user/space-storage-and-bandwidth'" class="upgrade-link">{{'account.infoPanel.storageOrBandwidthLimitReached.upgradeLink' | localize}}</a></ng-container>
            </app-info-panel>

        </div>
        <router-outlet></router-outlet>
        <app-footer></app-footer>
    </ng-container>
    <ng-container *ngIf="eventSiteLoaded && eventSite">
        <router-outlet *ngIf="eventSiteNavigate"></router-outlet>
        <app-public-stream-page *ngIf="!eventSiteNavigate" [loadedFromSubdomain]="true" [mediaId]="eventSite.productId"
                                [mediaType]="eventSite.productType"></app-public-stream-page>
    </ng-container>
</ng-container>
<ng-container *ngIf="embedded && eventSiteLoaded && !eventSite">
    <router-outlet></router-outlet>
</ng-container>


<ng-template #bandwidthDialog let-dialogRef="dialogRef">
  <ano-modal
      [dialogRef]="dialogRef"
      [title]="'bandwidthWarning.title' | localize"
      [submitButtonText]="'bandwidthWarning.submitButton' | localize"
      [dismissButtonText]="'bandwidthWarning.dismissButton' | localize"
  >
    <p>
      {{ 'bandwidthWarning.text1' | localize }}
    </p>
    <p>
      {{ 'bandwidthWarning.text2' | localize }} <a routerLink="user/space-storage-and-bandwidth" (click)="dialogRef.close()"><b>{{'bandwidthWarning.text2.link' | localize}}</b></a>
    </p>
  </ano-modal>
</ng-template>


<div *ngIf="backdropService.showBackdrop$ | async" class="modal-backdrop show"></div>
