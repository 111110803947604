import { Injectable } from '@angular/core';
import {Observable, of, Subject, throwError} from 'rxjs';
import { IResponse } from '@src/app/models/response.model';
import { ApiService } from 'ui-elements';
import { API_URLS_APP, UrlGenerator } from '@src/app/constants/api-urls.constant';
import {
  ICroppingParams, IPhotoCroppingPopupData,
  PhotoCropperComponent
} from '@src/app/components/photo-cropper/photo-cropper.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FULL_SCREEN_POPUP_STYLES } from '@src/app/constants/full-screen-popup-styles.constant';
import {filter, map, mergeMap, pluck, switchMap, tap} from 'rxjs/operators';
import { PhotoCropperService } from '@src/app/services/photo-cropper/photo-cropper.service';
import { SnackBarService } from 'ui-elements';
import bytesToMegabytes from '@src/app/utils/bytes-to-megabytes.util';
import {
  HttpClient,
  HttpEvent,
  HttpEventType,
  HttpParams,
  HttpRequest, HttpResponse,
  HttpUploadProgressEvent
} from '@angular/common/http';
import {LoadingService} from '@src/app/services/loading/loading.service';

@Injectable({
  providedIn: 'root'
})
export class PhotoService {

  public constructor(
    private apiService: ApiService,
    private photoCropperService: PhotoCropperService,
    private dialog: MatDialog,
    private snackBarService: SnackBarService,
    private http: HttpClient,
    public loadingService: LoadingService,
  ) { }

  public createUploader(): Observable<IUploaderCreatingResponse> {
    return this.apiService.get(UrlGenerator.generate(
      API_URLS_APP.UPLOAD_PHOTO,
      { uploaderId: '' }),
      { isWithoutRootUrl: true }
    );
  }

  public uploadPhoto(photo: File, uploaderId: string): Observable<IPhotoUploadingResponse> {
    const formData = new FormData();
    formData.append('file', photo);
    return this.apiService.post(
      UrlGenerator.generate(API_URLS_APP.UPLOAD_PHOTO, { uploaderId }),
      formData,
      { isWithoutRootUrl: true }
    );
  }

  public uploadPhotoV2(photo: File, uploaderId: string): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();
    formData.append('file', photo);

    const params: HttpParams = new HttpParams().set('id', uploaderId);
    const req = new HttpRequest('POST', API_URLS_APP.UPLOAD_PHOTO_V2, formData, {
      reportProgress: true,
      responseType: 'json',
      params: params,
    });

    return this.http.request(req);
  }

  public getUploadingStatus = (uploaderId: string): Observable<IUploadingStatusGettingResponse> => {
    return this.apiService.get(
      UrlGenerator.generate(API_URLS_APP.UPLOAD_PHOTO, { uploaderId }),
      { isWithoutRootUrl: true }
    );
  }

  public createWorkbench(uploaderId: string): Observable<IWorkbenchCreatingResponse> {
    return this.apiService.get(
      UrlGenerator.generate(API_URLS_APP.WORKBENCH, { uploaderId }),
      { isWithoutRootUrl: true }
    );
  }

  public addPhoto(workbenchId: string, croppingParams: ICroppingParams, isWishList?: boolean): Observable<IResponse> {
    // tslint:disable-next-line:max-line-length
    return !isWishList ? this.apiService.post(API_URLS_APP.ADD_PHOTO, { workbenchId, previewSettings: croppingParams }) : this.apiService.post(API_URLS_APP.ADD_ELEMENT_PHOTO, { workbenchId, previewSettings: croppingParams });
  }
  public removePhoto(workbenchId: string, url: string): Observable<IResponse> {
    let body;
    if (workbenchId) {
      body = { 'id': workbenchId };
    } else {
      body = { 'url': url };
    }
    return this.apiService.post(API_URLS_APP.DELETE_PHOTO, body);
  }

  public getPhoto = (): Observable<TPhotoGettingResponse> => {
    return this.apiService.get(API_URLS_APP.GET_LAST_UPLOADED_PHOTO);
  }

  public addCoverPhoto(workbenchId: string, croppingParams: ICroppingParams): Observable<IResponse> {
    return this.apiService.post(API_URLS_APP.ADD_COVER_PHOTO, { workbenchId, previewSettings: croppingParams });
  }

  public getCoverPhoto = (): Observable<TPhotoGettingResponse> => {
    return this.apiService.get(API_URLS_APP.GET_LAST_UPLOADED_COVER_PHOTO);
  }

  // tslint:disable-next-line:max-line-length
  public generateUploader(photoCroppingPopupData: IPhotoCroppingPopupData): Observable<{ croppingParams: ICroppingParams, workbenchId: string, croppedImage?: string}> {
    if (!this.isFileValid(photoCroppingPopupData.file)) {
      this.snackBarService.open(`Maximum file size is ${bytesToMegabytes(MAX_FILE_SIZE)} MB.`);
      return throwError('File exceeds maximum value');
    }
    const dialogRef: MatDialogRef<PhotoCropperComponent> = this.openPhotoCropperPopup(photoCroppingPopupData);
    return this.createUploader()
      .pipe(
        pluck('id'),
        // switchMap((uploaderId: string) => (
        //   this.uploadPhoto(photoCroppingPopupData.file, uploaderId)
        //     .pipe(map(() => uploaderId))
        // )),
          switchMap((uploaderId: string) => (
              this.uploadPhotoV2(photoCroppingPopupData.file, uploaderId)
                  .pipe(
                      filter((event) => {
                        if (event.type === HttpEventType.UploadProgress) {
                          this.updateProgress(event as HttpUploadProgressEvent);
                          return false;
                        } else {
                          if (event instanceof HttpResponse) {
                            return true;
                          }
                        }
                      }),
                      map(() => uploaderId))
          )),
        switchMap(this.getUploadingStatus),
        switchMap(({ status, id }) =>
          this.createWorkbench(id)
        ),
        switchMap(({ id: workbenchId }) => {
          this.photoCropperService.workbenchId$.next(workbenchId);

          return dialogRef.afterClosed()
            .pipe(
              switchMap(dialogResult => {
                if (!dialogResult) {
                  return throwError({ errorKey: 'UPLOADING_CANCELLED', });
                }
                this.loadingService.loadingEnd();
                return of({ ...dialogResult, workbenchId });
              }),
            );
        })
      );
  }

  private updateProgress(event: HttpUploadProgressEvent) {
    const progress = Math.floor(100 * event.loaded / event.total);
    this.loadingService.updateProgress(progress);
  }

  private openPhotoCropperPopup(photoCroppingPopupData: IPhotoCroppingPopupData): MatDialogRef<PhotoCropperComponent> {
    return this.dialog.open(
      PhotoCropperComponent,
      {
        disableClose: true,
        data: photoCroppingPopupData,
        ...FULL_SCREEN_POPUP_STYLES,
      }
    );
  }

  private isFileValid(file: File): boolean {
    return file.size < MAX_FILE_SIZE;
  }
}

const MAX_FILE_SIZE = 11 * 1024 * 1024;

interface IUploaderCreatingResponse {
  id: string;
}

interface IPhotoUploadingResponse {
  id: string;
}

interface IUploadingStatusGettingResponse {
  progress: number;
  status: number;
  id: string;
}

interface IWorkbenchCreatingResponse {
  id: string;
}

export type TPhotoGettingResponse = IResponse<{ photoId: string, photoUrl: string }>;
