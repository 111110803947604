import {MEDIA_TYPE} from '@src/app/components/streamdust-player/constants/mediaType';

export interface IPurchase {
    country?: string;
    originalPrice: number;
    grossTotalCost: number;
    netTotalCost: number;
    grossTotalCostSum: number;
    netTotalCostSum: number;
    vatPercent: number;
    priceVat: number;
    priceVatSum: number;
    purchasesAmount: number;
    priceDiscount?: IPriceDiscount;
    productId?: string;
    productType?: MEDIA_TYPE;
    type?: PURCHASE_TYPE;
    paymentSlotId?: string;
    paymentSlotName?: string;
}

export interface IPriceDiscount {
    type: DISCOUNT_TYPE;
    value: number;
}

export interface IPurchaseByCountry {
    country: string;
    generalPurchases: IPurchase;
    discountPurchases: IPurchase[];
    paymentSlotPurchases: IPurchase[];
    donationPurchases: IPurchase;
    grossTotalCostSum: number;
    netTotalCostSum: number;
}

export interface IAllCountryPurchases {
    purchasesByCountry: IPurchaseByCountry[];
    purchasesAmount: number;
    grossTotalCostSum: number;
    netTotalCostSum: number;
}

export enum DISCOUNT_TYPE {
    PERCENT = 'PERCENT'
}

export enum PURCHASE_TYPE {
    DISCOUNT = 'DISCOUNT',
    GENERAL = 'GENERAL',
    DONATIONS = 'DONATIONS',
    SLOT = 'SLOT',
}

