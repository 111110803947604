import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from '@src/app/app.module';
import { environment } from '@src/environments/environment';


if (environment.production) {

  setTimeout(() => {
    const ga_id = 'G-SEBVPJ31FY';

    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${ga_id}`;
    script.async = true;
    document.head.appendChild(script);

    const script1 = document.createElement('script');
    script1.innerHTML = `// Google Analytics
          window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${ga_id}');
      `;
    document.head.appendChild(script1);
  });
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
