import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {takeUntil} from 'rxjs/operators';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import {SubAccountsService} from '@src/app/services/sub-accounts/sub-accounts.service';
import {LocalizationProvider} from 'ui-elements';

@Component({
    selector: 'app-accept-invitation',
    templateUrl: './accept-invitation.component.html',
    styleUrls: ['./accept-invitation.component.sass'],
    providers: [AutoDestroyService]
})
export class AcceptInvitationComponent implements OnInit {
    public loading: boolean;
    public message: string;

    constructor(
        private activatedRoute: ActivatedRoute,
        private destroy$: AutoDestroyService,
        private subAccountsService: SubAccountsService,
        private router: Router,
        private localizationProvider: LocalizationProvider
    ) {
    }

    ngOnInit(): void {
        this.loading = true;
        this.activatedRoute.queryParams.pipe(takeUntil(this.destroy$)).subscribe(params => {
            if (!params.hasOwnProperty('token')) {
                this.loading = false;
                this.router.navigate(['']);
            }

            this.subAccountsService.acceptInvite(params['token'])
                .pipe(takeUntil(this.destroy$))
                .subscribe((res) => {
                    this.loading = false;
                    if (!res) {
                        this.message = this.localizationProvider.getByKey('error.EMAIL_CONFIRMATION_TOKEN_IS_INVALID');
                        return;
                    }
                    this.message = `${this.localizationProvider.getByKey('acceptInvitation.haveConnected')} ${res.firstName} ${res.lastName} (${res.email}) ${this.localizationProvider.getByKey('acceptInvitation.toYourAccount')}`;
                }, () => {
                    this.loading = false;
                    this.message = this.localizationProvider.getByKey('error.EMAIL_CONFIRMATION_TOKEN_IS_INVALID');
                });
        });

    }

    private goToInvalidTokenPage(): void {
        this.router.navigate(['invalid-token']);
    }
}
