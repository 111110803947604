<div class="panel" >
    <div class="panel-header pb-0">
        <h2><b>Test page</b></h2>
    </div>
    <div class="panel-body bg-white padding-top-20 padding-bottom-20">
        <ano-table #tableComponent [tableConfig]="config"
        ></ano-table>
    </div>
</div>
<ng-template #priceTmpl let-data="rowData">
    <span>{{ {amount: data.amount, currency: data.currency} | currency }}</span>
</ng-template>

<ng-template #dateTmpl let-data="rowData">
    <span>{{ data?.date | dateFormat: 'DD MMM YYYY' }}</span>
</ng-template>


<ng-template #statusTmpl let-data="rowData">
    <i class="icon-{{ data?.status === 'OPEN' ? 'check' : 'cross'}} text-primary"></i>
</ng-template>
<ng-template #actionsTmpl let-data="rowData">
    <div class="table-actions-list">
        <b class="text-primary">
            <i class="icon icon-login cursor-pointer"></i>
            <i class="icon icon-eye cursor-pointer"></i>
            <i class="icon icon-trash text-danger cursor-pointer"></i>
        </b>
    </div>
</ng-template>
