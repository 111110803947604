import {Component, Input, OnInit} from '@angular/core';
import {PDFDocumentProxy} from 'ng2-pdf-viewer';
import {CHAT_MODE} from '@src/app/components/chat/chat.component';

export interface IPDFConfig {
    src?: string;
    currentPage?: number;
    totalPages?: number;
    originalSize?: boolean;
    showAll?: boolean;
    isDownload?: boolean;
    isLink?: boolean;
    isRemove?: boolean;
    mediaId?: string;
    presentation?: any;
    embeddedChatMode?: CHAT_MODE;
}

@Component({
    selector: 'app-pdf-viewer',
    templateUrl: './pdf-viewer.component.html',
    styleUrls: ['./pdf-viewer.component.scss']
})
export class PdfViewerComponent implements OnInit {
    @Input() config: IPDFConfig;
    private defaultConfig: IPDFConfig;

    constructor() {
    }

    x;

    ngOnInit(): void {
        this.defaultConfig = {
            currentPage: 1,
            totalPages: 0,
            originalSize: false,
            showAll: false,
        };

        this.config = {...this.defaultConfig, ...this.config};
    }

    afterLoadComplete(pdfData: PDFDocumentProxy) {
        this.config.totalPages = pdfData.numPages;
    }

    nextPage() {
        if (this.config.currentPage === this.config.totalPages) {
            return;
        }
        this.config.currentPage++;
    }

    prevPage() {
        if (this.config.currentPage === 1) {
            return;
        }
        this.config.currentPage--;
    }
}
