<div class="panel">
    <div class="panel-header pb-0">
        <h2 class="title-lg"><b>{{'subaccounts.title' | localize}}</b></h2>
    </div>
</div>

<div class="card bg-white">
    <div class="card-body pb-0">
        <div class="row justify-content-between align-items-end">
            <div class="col-xl-7">
                <div class="title-box">
                    <h2 class="title-level-3">Send invitation</h2>
                </div>
                <app-bordered-form *ngIf="!inviteSent"
                        [form]="form"
                        [fieldsConfig]="fieldsConfig"
                        [defaultForm]="true">
                </app-bordered-form>
                <app-info-panel *ngIf="inviteSent"
                                [infoPanel]="InfoPanelsConfig.subAccountInviteSent">
                </app-info-panel>
            </div>
            <div class="col-auto">
                <div class="buttons-container mr-20 mb-20">
                    <button *ngIf="!inviteSent" class="btn btn-success" [class.untouched]="form.untouched" [class.invalid-button]="form.invalid && form.touched" (click)="submit()">{{'common.button.apply' | localize}}</button>
                    <button *ngIf="inviteSent" class="btn btn-secondary" [class.untouched]="form.untouched" [class.invalid-button]="form.invalid && form.touched" (click)="goBack()">{{'common.button.back' | localize}}</button>
                </div>
            </div>
        </div>
    </div>
    <ano-loader [isOpen]="loading"></ano-loader>
</div>
