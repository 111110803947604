<table class="table table-striped layout-fixed">
    <thead class="align-middle without-background text-lg">
    <tr>
        <th class="table-col-2">
            <div class="subscription-tariffs-comparison-currency blue-theme-filter">
                <form [formGroup]="currencyForm" *ngIf="!this.authService.isAuthorized">
                    <ano-input formControlName="currency"
                               [isEditMode]="true"
                               [config]="{
                                                    inputType: INPUT_TYPES.SELECT,
                                                    placeholder: '',
                                                    selectOptions: currencySelectOptions
                                                }"
                    ></ano-input>
                </form>
            </div>
        </th>
        <th class="table-col-2">{{'landing-page.streamdust-subscription.Free' | localize}}</th>
        <th class="table-col-2">{{'landing-page.streamdust-subscription.Beginner' | localize}}</th>
        <th class="table-col-2">{{'landing-page.streamdust-subscription.Advanced' | localize}}</th>
        <th class="table-col-2">{{'landing-page.streamdust-subscription.Professional' | localize}}</th>
        <th class="table-col-2">{{'landing-page.streamdust-subscription.Enterprise' | localize}}</th>
    </tr>
    </thead>

    <tbody class="our-offers-title">
    <tr class="table-title-tr">
        <td colspan="6">
            <div class="table-title-box" [class.active]="displayOurOffers"
                 (click)="displayOurOffers = !displayOurOffers">
                <a href="javascript:void(0)">
                                            <span class="flex-block">
                                                <i class="icon-table_arrow"></i>
                                                <span class="text-block">
                                                    Our
                                                    <br/>Offers
                                                </span>
                                            </span>
                </a>
            </div>
        </td>
    </tr>
    </tbody>
    <tbody class="our-offers data-table-body"
           [ngStyle]="{'display' : displayOurOffers ? 'table-row-group' : 'none'}">
    <tr>
        <th>Monthly cost</th>
        <td>
            <b>{{monthlyChargesPricesToShow?.monthly[selectedCurrency][0]}} {{selectedCurrency}}</b>
            <br/>per month
        </td>
        <td>
            <b>{{monthlyChargesPricesToShow?.monthly[selectedCurrency][1]}} {{selectedCurrency}}</b>
            <br/>per month,
            <ng-container *ngIf="currentTariffPeriod === TARIFF_PLAN_PERIOD.MONTH">monthly
                <br/>cancelable annually*
            </ng-container>
            <ng-container *ngIf="currentTariffPeriod === TARIFF_PLAN_PERIOD.YEAR">
                <br/>one time payment
                <br/><b>per {{monthlyChargesPricesToShow?.yearly[selectedCurrency][1]}} {{selectedCurrency}}year</b>
            </ng-container>
        </td>
        <td>
            <b>{{monthlyChargesPricesToShow?.monthly[selectedCurrency][2]}} {{selectedCurrency}}</b>
            <br/>per month,
            <ng-container *ngIf="currentTariffPeriod === TARIFF_PLAN_PERIOD.MONTH">monthly
                <br/>cancelable annually*
            </ng-container>
            <ng-container *ngIf="currentTariffPeriod === TARIFF_PLAN_PERIOD.YEAR">
                <br/>one time payment
                <br/><b>per {{monthlyChargesPricesToShow?.yearly[selectedCurrency][2]}} {{selectedCurrency}}
                year</b>
            </ng-container>
        </td>
        <td>
            <b>{{monthlyChargesPricesToShow?.monthly[selectedCurrency][3]}} {{selectedCurrency}}</b>
            <br/>per month,
            <ng-container *ngIf="currentTariffPeriod === TARIFF_PLAN_PERIOD.MONTH">monthly
                <br/>cancelable annually*
            </ng-container>
            <ng-container *ngIf="currentTariffPeriod === TARIFF_PLAN_PERIOD.YEAR">
                <br/>one time payment
                <br/><b>per {{monthlyChargesPricesToShow?.yearly[selectedCurrency][3]}} {{selectedCurrency}}
                year</b>
            </ng-container>
        </td>
        <td>
            Are you looking for
            <br/>a special
            <br/>Business solution?
        </td>
    </tr>
    <tr>
        <th>Bandwidth</th>
        <td>
            <b>Up to 8 Mbps</b>
        </td>
        <td>
            <b>Up to 8 Mbps</b>
        </td>
        <td>
            <b>Up to 8 Mbps</b>
        </td>
        <td>
            <b>Up to 8 Mbps</b>
        </td>
        <td>
            <b>You need
                <br/>more bandwidth?</b>
        </td>
    </tr>
    <tr>
        <th>
            Multibitrate +
            <br/>Redundant Pipeline
            <br/>Encoding
        </th>
        <td>
            <b>{{streamProcessingPrices[selectedCurrency][0]}} {{selectedCurrency}} per hour</b>
        </td>
        <td>
            <b>{{streamProcessingPrices[selectedCurrency][0]}} {{selectedCurrency}} per hour</b>
        </td>
        <td>
            <b>{{streamProcessingPrices[selectedCurrency][0]}} {{selectedCurrency}} per hour</b>
        </td>
        <td>
            <b>{{streamProcessingPrices[selectedCurrency][0]}} {{selectedCurrency}} per hour</b>
        </td>
        <td>

        </td>
    </tr>
    <tr>
        <th>
            Bandwidth usage
            <br/>per month for
            <br/>free livestreams
        </th>
        <td>
            <b>0 TB per month</b>
        </td>
        <td>
            <b>1 TB per month</b>
        </td>
        <td>
            <b>3 TB per month</b>
        </td>
        <td>
            <b>5 TB per month</b>
        </td>
        <td>
            <b>
                Unlimited
                <br/> streaming
                <br/> bandwidth?
            </b>
        </td>
    </tr>
    <tr>
        <th>
            Storage space
        </th>
        <td>
            <b>1 GB free per year</b>
        </td>
        <td>
            <b>150 GB free per year</b>
        </td>
        <td>
            <b>250 GB free per year</b>
        </td>
        <td>
            <b>500 GB free per year</b>
        </td>
        <td>

        </td>
    </tr>
    <tr>
        <th>
            Livestreams free of
            <br/>charge for viewer
        </th>
        <td>
            <i class="icon-cross status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
    </tr>
    <tr>
        <th>
            Pay-per-View-
            <br/>livestreaming
        </th>
        <td>
            Unlimited number
            <br/>of viewers
        </td>
        <td>
            Unlimited number
            <br/>of viewers
        </td>
        <td>
            Unlimited number
            <br/>of viewers
        </td>
        <td>
            Unlimited number
            <br/>of viewers
        </td>
        <td>
            Unlimited number
            <br/>of viewers
        </td>
    </tr>
    <tr>
        <th>
            Service fee
            <br/>pay-per-View
            <br/>livestreaming
        </th>
        <td>
            <b>
                {{payPerViewPrices[selectedCurrency][0]}} {{selectedCurrency}} plus 10 %
                <br/>from gross ticket price
            </b>
        </td>
        <td>
            <b>
                {{payPerViewPrices[selectedCurrency][1]}} {{selectedCurrency}} plus 9 %
                <br/>from gross ticket price
            </b>
        </td>
        <td>
            <b>
                {{payPerViewPrices[selectedCurrency][2]}} {{selectedCurrency}} plus 8 %
                <br/>from gross ticket price
            </b>
        </td>
        <td>
            <b>
                {{payPerViewPrices[selectedCurrency][3]}} {{selectedCurrency}} plus 7,5 %
                <br/>from gross ticket price
            </b>
        </td>
        <td>
            <b>Minimal Fee</b>
        </td>
    </tr>
    <tr>
        <th>
            Video free of
            <br/>charge for viewers
        </th>
        <td>
            <i class="icon-cross status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
    </tr>
    <tr>
        <th>
            Service fee
            <br/> pay-per-view video
        </th>
        <td>
            <b>
                {{payPerViewPrices[selectedCurrency][0]}} {{selectedCurrency}} plus 10 %
                <br/>from gross ticket price
            </b>
        </td>
        <td>
            <b>
                {{payPerViewPrices[selectedCurrency][1]}} {{selectedCurrency}} plus 9 %
                <br/>from gross ticket price
            </b>
        </td>
        <td>
            <b>
                {{payPerViewPrices[selectedCurrency][2]}} {{selectedCurrency}} plus 8 %
                <br/>from gross ticket price
            </b>
        </td>
        <td>
            <b>
                {{payPerViewPrices[selectedCurrency][3]}} {{selectedCurrency}} plus 7,5 %
                <br/>from gross ticket price
            </b>
        </td>
        <td>
            <b>Minimal Fee</b>
        </td>
    </tr>
    <tr>
        <th>
            Transaction cost
            <br/><span class="info-block">(Costs for payment processing of livestreams and videos: MasterCard, VisaCard)</span>
        </th>
        <td>
            {{transactionPrices[selectedCurrency][0]}} {{selectedCurrency}} plus 2,6% from gross ticket price (MasterCard,
            VisaCard)
        </td>
        <td>
            {{transactionPrices[selectedCurrency][0]}} {{selectedCurrency}} plus 2,6% from gross ticket price (MasterCard,
            VisaCard)
        </td>
        <td>
            {{transactionPrices[selectedCurrency][0]}} {{selectedCurrency}} plus 2,6% from gross ticket price (MasterCard,
            VisaCard)
        </td>
        <td>
            {{transactionPrices[selectedCurrency][0]}} {{selectedCurrency}} plus 2,6% from gross ticket price (MasterCard,
            VisaCard)
        </td>
        <td>
            Further payment
            <br/>options?
        </td>
    </tr>
    <tr>
        <th>
            <a href="">PayPal only for
                <br/>extra charge</a>
        </th>
        <th>
            <a href="">Please contact
                <br/>us</a>
        </th>
        <th>
            <a href="">Please contact
                <br/>us</a>
        </th>
        <th>
            <a href="">Please contact
                <br/>us</a>
        </th>
        <th>
            <a href="">Please contact
                <br/>us</a>
        </th>
        <th>
            <a href="">Please contact
                <br/>us</a>
        </th>
    </tr>
    </tbody>
    <tbody class="top-possible-combinations-title">
    <tr class="table-title-tr">
        <td colspan="6">
            <div class="table-title-box" [class.active]="displayTopPossibleCombinations"
                 (click)="displayTopPossibleCombinations = !displayTopPossibleCombinations">
                <a href="javascript:void(0)">
                                        <span class="flex-block">
                                            <i class="icon-table_arrow"></i>
                                            <span class="text-block">
                                            Top
                                            <br/>Combination
                                            <br/>possibilities
                                        </span>
                                        </span>
                </a>
            </div>
        </td>
    </tr>
    </tbody>
    <tbody class="top-possible-combinations data-table-body"
           [ngStyle]="{'display' : displayTopPossibleCombinations ? 'table-row-group' : 'none'}">
    <tr>
        <th>
            Livestreams/slots
        </th>
        <td>
            <i class="icon-cross status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
    </tr>
    <tr>
        <th>
            Grouping of
            <br/>livestreams
        </th>
        <td>
            <i class="icon-cross status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
    </tr>
    <tr>
        <th>
            Grouping of
            <br/>videos
        </th>
        <td>
            <i class="icon-cross status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
    </tr>
    <tr>
        <th>
            Online courses
        </th>
        <td>
            <i class="icon-cross status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
    </tr>
    <tr>
        <th>
            Donate button
        </th>
        <td>
            <i class="icon-cross status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
    </tr>
    <tr>
        <th>
            Service fee
            <br/>+ transaction costs
        </th>
        <td>
            <i class="icon-cross status-element text-primary"></i>
        </td>
        <td>
            {{feeAndTransactionPrices[selectedCurrency][0][0]}} {{selectedCurrency}} plus 9%
            <br/> of the gross ticket price
            <br/> as well as {{feeAndTransactionPrices[selectedCurrency][0][1]}} {{selectedCurrency}}
            <br/> plus 2.6% of the gross
            <br/> ticket price (Master-
            <br/> Card, VisaCard)*
<!--            Transaction cost-->
<!--            <br/>plus 9 % comission for-->
<!--            <br/>gross ticket price-->
        </td>
        <td>
            {{feeAndTransactionPrices[selectedCurrency][1][0]}} {{selectedCurrency}} plus 8%
            <br/> of the gross ticket price
            <br/> as well as {{feeAndTransactionPrices[selectedCurrency][1][1]}} {{selectedCurrency}}
            <br/> plus 2.6% of the gross
            <br/> ticket price (Master-
            <br/> Card, VisaCard)*
<!--            Transaction cost-->
<!--            <br/>plus 8 % comission for-->
<!--            <br/>gross ticket price-->
        </td>
        <td>
            {{feeAndTransactionPrices[selectedCurrency][2][0]}} {{selectedCurrency}} plus 7.5%
            <br/> of the gross ticket price
            <br/> as well as {{feeAndTransactionPrices[selectedCurrency][2][1]}} {{selectedCurrency}}
            <br/> plus 2.6% of the gross
            <br/> ticket price (Master-
            <br/> Card, VisaCard)*
<!--            Transaction cost-->
<!--            <br/>plus 6 % comission for-->
<!--            <br/>gross ticket price-->
        </td>
        <td>
            Minimal comission
            <br/>fees
        </td>
    </tr>
    </tbody>
    <tbody class="marketing-tools-title">
    <tr class="table-title-tr">
        <td colspan="6">
            <div class="table-title-box" [class.active]="displayMarketingTools"
                 (click)="displayMarketingTools = !displayMarketingTools">
                <a href="javascript:void(0)">
                                    <span class="flex-block">
                                        <i class="icon-table_arrow"></i>
                                        <span class="text-block">
                                        Marketing
                                        <br/>tools
                                    </span>
                                    </span>
                </a>
            </div>
        </td>
    </tr>
    </tbody>
    <tbody class="marketing-tools data-table-body"
           [ngStyle]="{'display' : displayMarketingTools ? 'table-row-group' : 'none'}">
    <tr>
        <th>
            Discount codes
            <br/>livestreaming
        </th>
        <td>
            <i class="icon-cross status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
    </tr>
    <tr>
        <th>
            Discount codes
            <br/>videos
        </th>
        <td>
            <i class="icon-cross status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
    </tr>
    <tr>
        <th>
            Preview teasers
        </th>
        <td>
            <i class="icon-cross status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
    </tr>
    <tr>
        <th>
            Promotional trailers
        </th>
        <td>
            <i class="icon-cross status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
    </tr>
    <tr>
        <th>
            Linkable banner ads
        </th>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
    </tr>
    <tr>
        <th>
            Lead generation
            <br/>livestreaming
        </th>
        <td>
            <i class="icon-cross status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
    </tr>
    <tr>
        <th>
            Lead generation
            <br/>videos
        </th>
        <td>
            <i class="icon-cross status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
    </tr>
    <tr>
        <th>
            Calendar +
            <br/>Reminder Email
        </th>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
    </tr>
    <tr>
        <th>
            Interactive surveys
        </th>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
    </tr>
    <tr>
        <th>
            Preview
            <br/>picture
        </th>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
    </tr>
    <tr>
        <th>
            IP-based web
            <br/>banner playout
        </th>
        <td>
            <i class="icon-cross status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
    </tr>
    <tr>
        <th>
            Fade in viewer images
            <br/><span class="info-block">(coming soon)</span>
        </th>
        <td>
            <i class="icon-cross status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
    </tr>
<!--    <tr>-->
<!--        <th>-->
<!--            Display of-->
<!--            <br/>linkable-->
<!--            <br/>advertising images-->
<!--            <br/>on he event side-->
<!--            <br/><span class="info-block">(coming soon)</span>-->
<!--        </th>-->
<!--        <td>-->
<!--            <i class="icon-cross status-element text-primary"></i>-->
<!--        </td>-->
<!--        <td>-->
<!--            <i class="icon-check status-element text-primary"></i>-->
<!--        </td>-->
<!--        <td>-->
<!--            <i class="icon-check status-element text-primary"></i>-->
<!--        </td>-->
<!--        <td>-->
<!--            <i class="icon-check status-element text-primary"></i>-->
<!--        </td>-->
<!--        <td>-->
<!--            <i class="icon-check status-element text-primary"></i>-->
<!--        </td>-->
<!--    </tr>-->
    </tbody>
    <tbody class="embeddings-title">
    <tr class="table-title-tr">
        <td colspan="6">
            <div class="table-title-box" [class.active]="displayEmbeddings"
                 (click)="displayEmbeddings = !displayEmbeddings">
                <a href="javascript:void(0)">
                                    <span class="flex-block">
                                        <i class="icon-table_arrow"></i>
                                        <span class="text-block">
                                        Embeddings
                                    </span>
                                    </span>
                </a>
            </div>
        </td>
    </tr>
    </tbody>
    <tbody class="embeddings data-table-body"
           [ngStyle]="{'display' : displayEmbeddings ? 'table-row-group' : 'none'}">
    <tr>
        <th>
            Set up your own
            <br/>customizable event
            <br/>page
        </th>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
    </tr>
    <tr>
        <th>
            Embedding of the
            <br/>player incl. the entire
            <br/>system via iFrame
        </th>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
    </tr>
    <tr>
        <th>
            API for your own customer
            <br/> login to your customer
            <br/>database
        </th>
        <td>
            <i class="icon-cross status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-cross status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-cross status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-cross status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
    </tr>
    <tr>
        <th>
            Connect video
            <br/>graphic software
        </th>
        <td>
            <i class="icon-cross status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
    </tr>
    </tbody>
    <tbody class="other-features-title">
    <tr class="table-title-tr">
        <td colspan="6">
            <div class="table-title-box" [class.active]="displayOtherFeatures"
                 (click)="displayOtherFeatures = !displayOtherFeatures">
                <a href="javascript:void(0)">
                                    <span class="flex-block">
                                        <i class="icon-table_arrow"></i>
                                        <span class="text-block">
                                        Interactive
                                        <br/>tools and
                                        <br/>other
                                        <br/>features
                                    </span>
                                    </span>
                </a>
            </div>
        </td>
    </tr>
    </tbody>
    <tbody class="other-features data-table-body"
           [ngStyle]="{'display' : displayOtherFeatures ? 'table-row-group' : 'none'}">
    <tr>
        <th>
            Chat
        </th>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
    </tr>
    <tr>
        <th>
            Statistics
        </th>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
    </tr>
    <tr>
        <th>
            Geoblocking
        </th>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
    </tr>
    <tr>
        <th>
            Age restriction
        </th>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
    </tr>
    <tr>
        <th>
            Real-time display
            <br/>of current viewers
            <br/>worldwide
        </th>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
    </tr>
    <tr>
        <th>
            Extremely robust CDN
        </th>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
    </tr>
    <tr>
        <th>
            Worldwide accurate
            <br/>billing
        </th>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
    </tr>
    <tr>
        <th>

            Ad-free from
            <br/>Streamdust
        </th>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
    </tr>
    <tr>
        <th>
            Cloud recording
        </th>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
    </tr>
    <tr>
        <th>
            Adaptive bitrate
        </th>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
    </tr>
    <tr>
        <th>
            VoD archive
        </th>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
    </tr>
    <tr>
        <th>
            Simultaneous
            <br/>streaming
        </th>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
    </tr>
    <tr>
        <th>
            Streaming in Full HD
        </th>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
    </tr>
    <tr>
        <th>
            360 degree streaming
        </th>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
        <td>
            <i class="icon-check status-element text-primary"></i>
        </td>
    </tr>
    </tbody>
</table>
