import {Component, ComponentRef, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormGroup, Validators} from '@angular/forms';
import {CustomValidators, NEW_ID_PARAM} from '@src/app/utils/custom-validators.util';
import {CmsService, INPUT_TYPES, IStreamGenre, StreamEventsService} from 'ui-elements';
import {
    IFormConfig,
    IFormFieldsConfig,
    IPreviewPicture,
    MIN_VALUE_PRICE,
    StreamService
} from '@src/app/services/stream-metadata/stream.service';
import {
    IStreamLimitation,
    IStreamModel,
    IStreamPreviewVideoMetadata,
    PAYMENT_SYSTEM,
} from '@src/app/models/stream.model';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import {filter, mergeMap, pluck, switchMap, takeUntil, tap} from 'rxjs/operators';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {LoadingService} from '@src/app/services/loading/loading.service';
import {FormUtil} from '@src/app/utils/form.util';
import {PhotoService} from '@src/app/services/photo/photo.service';
import {PhotoCropperService} from '@src/app/services/photo-cropper/photo-cropper.service';
import {IMediaGroup} from '@src/app/models/stream-groups.model';
import {MediaGroupsService} from '@src/app/services/media-groups/media-groups.service';
import {BehaviorSubject, forkJoin, Subject, Subscription} from 'rxjs';
import {FormService} from '@src/app/services/form/form.service';
import {STREAM_STATUS} from '@src/app/components/streamdust-player/constants/status';
import {MEDIA_TYPE} from '@src/app/components/streamdust-player/constants/mediaType';
import {IInfoPanel, InfoPanelsConfig} from '@src/app/components/info-panel/info-panel.component';
import * as _ from 'lodash';
import {UserService} from '@src/app/services/user/user.service';
import {IVideoInfo} from '@src/app/services/video-upload/video-upload.service';
import {VideoOnDemandService} from '@src/app/services/video-on-demand/video-on-demand.service';
import {ModalService} from '@src/app/services/modal/modal.service';
import {PaymentModalComponent} from '@src/app/components/payment-modal/payment-modal.component';
import {PAYMENT_STATUS} from '@src/app/services/subscription-manage/subscription-manage.service';
import {IPaymentCard} from '@src/app/components/payment-cards-list/payment-cards-list.component';
import {IHeidelpayPaymentDetails} from '@src/app/components/payment-cards-list/heidelpay-payment/heidelpay-payment.component';
import {IMultiBitratePaymentRequest, MultiBitrateService} from '@src/app/services/multi-bitrate/multi-bitrate.service';
import {PaymentService} from '@src/app/services/payment/payment.service';
import {DateHelper} from '@src/app/utils/date.helper';
import {VIDEO_VISIBLE_SETTINGS} from '@src/app/models/video-on-demand.model';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {
    IStorageAndBandwidthTariffs,
    StorageAndBandwidthService
} from '@src/app/services/storage-and-bandwidth/storage-and-bandwidth.service';

export interface IPaymentsModel {
    paymentSystemGroup?: string;
    psDonation: boolean;
    psEntrepriseLogin: boolean;
    psLeadGeneration: boolean;
    psPayPerView: boolean;
    psSlot: boolean;
    psVoucher: boolean;
    psCustomIntegration: boolean;
}

@Component({
    selector: 'app-stream-metadata',
    templateUrl: './stream-metadata.component.html',
    styleUrls: ['./stream-metadata.component.sass'],
    providers: [AutoDestroyService, MultiBitrateService]
})
export class StreamMetadataComponent implements OnInit {
    @ViewChild('bandwidthDialog', {static: false}) bandwidthDialog: TemplateRef<any>;
    public MEDIA_TYPE = MEDIA_TYPE;
    @Input() public isCreateMode = false;
    private media: IStreamModel;
    public mediaType: MEDIA_TYPE;
    public formsConfig: IFormConfig[];
    public myGroups: IMediaGroup[];
    public initialValue: IStreamModel;
    public mediaId: string;
    public INPUT_TYPES = INPUT_TYPES;
    public STREAM_STATUS = STREAM_STATUS;
    public fieldsConfig: IFormFieldsConfig[][];
    public loading = false;
    public photoUrl = '';
    private valid = true;
    public agreementText = 'storage-and-bandwidth.privacyPolicy';
    public localTermsAgreed = false;
    public termsRequiredError = false;
    public visibilitySubscription: Subscription;
    private currentVisibleSettings = VIDEO_VISIBLE_SETTINGS.EVERYWHERE;
    private picture = {
        workbenchId: '',
        previewSettings: {
            x: 0,
            y: 0,
            width: 0,
            height: 0,
            rotation: 0,
        }
    };
    genres: IStreamGenre[];
    limitation: IStreamLimitation;
    showInfoPanel = false;
    infoPanel: IInfoPanel;
    private premiumPublisher: boolean;
    private currentPaymentSystem: PAYMENT_SYSTEM;
    public videoFormConfig: IFormConfig[];
    public videoId = '';
    advertisementVideo: IStreamPreviewVideoMetadata = {
        views: null,
        clicks: null,
        videoUrl: null,
        advertisingUrl: null
    };

    private slotsPaymentSystemSubject$: Subject<any> = new Subject<any>();
    private checkModel: IPaymentsModel = {
        psVoucher: false,
        psSlot: false,
        psLeadGeneration: false,
        psPayPerView: false,
        psDonation: false,
        psEntrepriseLogin: false,
        psCustomIntegration: false,
    };
    public showAccesError = false;
    private MIN_VALUE_PRICE = MIN_VALUE_PRICE;
    private startDate: Date;
    private endDate: Date;
    private slotsFormsNotifier$: Subject<void> = new Subject<void>();
    public backupChannelEnabled = false;
    private dialogRef: MatDialogRef<any> | null = null;

    constructor(
        public streamService: StreamService,
        private readonly destroy$: AutoDestroyService,
        private readonly activatedRoute: ActivatedRoute,
        private router: Router,
        public loadingService: LoadingService,
        private photoService: PhotoService,
        private readonly photoCropperService: PhotoCropperService,
        private streamGroupsService: MediaGroupsService,
        private cmsService: CmsService,
        private formService: FormService,
        private userService: UserService,
        private vodService: VideoOnDemandService,
        private streamEventsService: StreamEventsService,
        private modalService: ModalService,
        private multiBitrateService: MultiBitrateService,
        private paymentService: PaymentService,
        public dialog: MatDialog,
        private storageAndBandwidthService: StorageAndBandwidthService,
    ) {
    }

    ngOnInit() {
        this.loadingService.loadingStart();
        this.videoFormConfig = this.streamService.getVideoOnDemandUploadFormConfig();
        forkJoin({
            myGroups: this.streamGroupsService.getMyGroups().pipe(pluck('results', 'data', 'items')),
            genres: this.cmsService.getGenres(),
            streamLimitation: this.streamService.getStreamLimitation(),
            storageAndBandwidthLimit: this.storageAndBandwidthService.getDataLimit(),
        }).pipe(takeUntil(this.destroy$),
            tap((results) => {
                this.myGroups = results?.myGroups;
                this.genres = results?.genres;
                this.limitation = results?.streamLimitation;
                this.checkStorageAndBandwidthLimit(results?.storageAndBandwidthLimit);
            }),
            mergeMap(() => this.userService.userProfile$.pipe(takeUntil(this.destroy$))
            )).subscribe(results => {
            if (results) {
                this.premiumPublisher = results?.subscribed;
            }
            if (this.isCreateMode) {
                this.mediaType = this.resolveMediaType();
                this.formsConfig = this.streamService.getStreamFormsConfig(false, false, this.premiumPublisher, this.mediaType);
                this.listenVisibility();
                this.listenBackupChannel();
                this.parseAdditionalStreamData();
                this.loadingService.loadingEnd();
                if (this.premiumPublisher) {
                    this.hidePrice();
                }

                this.hideSlotsControl();

                window.scrollTo({top: 391, behavior: 'smooth'});
                return;
            }
            this.activatedRoute.parent.params.pipe(
                takeUntil(this.destroy$),
                tap(({id}: Params) => {
                    this.mediaType = this.resolveMediaType();
                    if (!id) {
                        this.isCreateMode = true;
                        this.formsConfig = this.streamService.getStreamFormsConfig(false, false, this.premiumPublisher, this.mediaType);
                        this.listenVisibility();
                        this.hideSlotsControl();

                        return;
                    }
                    this.mediaId = id;
                }),
                filter(({id}: Params) => id && id !== NEW_ID_PARAM),
                switchMap(({id}: Params) => this.streamService.stream$))
                .subscribe((_res) => {
                    this.media = _.cloneDeep(_res);
                    this.loadingService.loadingEnd();
                    if (!this.media) {
                        return;
                    }
                    const price = (this.media.media.price as any);
                    this.media.media.price = price.amount;
                    this.initialValue = this.media;
                    this.showInfoPanel = this.checkIfInfoPanelNeeded();
                    this.infoPanel = this.resolveInfoPanel();
                    this.formsConfig = this.streamService.getStreamFormsConfig(
                        this.media.media?.status?.stream === STREAM_STATUS.FINISHED && !this.media?.media?.hasRecording
                        || this.media.media?.status?.stream === STREAM_STATUS.ACTIVATED || this.media?.media?.status?.stream === STREAM_STATUS.ACTIVATION
                        || this.media.media?.status?.stream === STREAM_STATUS.LIVE,
                        this.media.media.hasRecording,
                        this.premiumPublisher,
                        this.mediaType
                    );
                    if (this.media.media.visible) {
                        this.currentVisibleSettings = this.media.media.visible;
                    }
                    this.listenVisibility();
                    if (this.media.media.video && this.media.media.video.id) {
                        this.videoId = this.media.media.video.id;
                        this.patchVideoFormValue(this.videoId);
                        if (!this.media.media.video.url.startsWith('http://') || !(this.media.media.video.url.startsWith('https://'))) {
                            this.media.media.video.url = 'https://' + this.media.media.video.url;
                        }
                        this.advertisementVideo.videoUrl = this.media.media.video.url;
                    }
                    this.formsConfig = FormUtil.mapResponseToForms<IStreamModel>(this.media, this.formsConfig);
                    this.photoUrl = this.media.previewPicture && this.media.previewPicture.photoUrl;
                    const preview = this.formsConfig.find(form => form.name === 'preview');
                    if (preview) {
                        preview.payload.photoId = this.media.previewPicture && this.media.previewPicture.photoId;
                    }
                    this.currentPaymentSystem = this.media.media.paymentSystem;
                    this.parseAdditionalStreamData();
                });
        });

        this.streamService.update$
            .pipe(takeUntil(this.destroy$))
            .subscribe((res) => {
                if (!res) {
                    return;
                }
                if (!res.groupCreatedId$) {
                    return;
                }

                this.streamGroupsService.getMyGroups().pipe(pluck('results', 'data', 'items'))
                    .subscribe((_groups) => {
                        this.myGroups = _groups;
                        this.attachGroups(res.groupCreatedId$);
                    });
            });
    }

    private listenBackupChannel(): void {
        const backupChannelEnabledField = this.formsConfig.find(form => form.name === 'media').form.get('backupChannelEnabled');
        backupChannelEnabledField.valueChanges
            .pipe(takeUntil(this.destroy$))
            .subscribe(newValue => {
                this.backupChannelEnabled = newValue;
            });
    }

    private listenVisibility(): void {
        if (!this.visibilitySubscription) {
            const visibleSettings = this.formsConfig.find(form => form.name === 'visible').form;

            switch (this.currentVisibleSettings) {
                case VIDEO_VISIBLE_SETTINGS.NOWHERE:
                    visibleSettings.get('visibleNone').patchValue(true);
                    break;
                case VIDEO_VISIBLE_SETTINGS.EVERYWHERE:
                    visibleSettings.get('visibleEverywhere').patchValue(true);
                    break;
                case VIDEO_VISIBLE_SETTINGS.EXCEPT_STREAMDUST:
                    visibleSettings.get('visibleNotStreamdust').patchValue(true);
                    break;
                case VIDEO_VISIBLE_SETTINGS.STREAMDUST_ONLY:
                    visibleSettings.get('visibleStreamdust').patchValue(true);
                    break;
            }
            this.listenVisibilitySetting();
        }
    }

    public listenVisibilitySetting(): void {
        const formConfig = this.formsConfig.find(form => form.name === 'visible');

        const visibleNone = formConfig.form.get('visibleNone');
        const visibleEverywhere = formConfig.form.get('visibleEverywhere');
        const visibleNotStreamdust = formConfig.form.get('visibleNotStreamdust');
        const visibleStreamdust = formConfig.form.get('visibleStreamdust');

        visibleNone.valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((bool) => {
                if (bool) {
                    visibleEverywhere.patchValue(false);
                    visibleNotStreamdust.patchValue(false);
                    visibleStreamdust.patchValue(false);
                    this.currentVisibleSettings = VIDEO_VISIBLE_SETTINGS.NOWHERE;
                } else if (!visibleEverywhere.value && !visibleNotStreamdust.value && !visibleStreamdust.value) {
                    setTimeout(() => {
                        visibleNone.patchValue(true);
                    }, 100);
                }
            });

        visibleEverywhere.valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((bool) => {
                if (bool) {
                    visibleNone.patchValue(false);
                    visibleNotStreamdust.patchValue(false);
                    visibleStreamdust.patchValue(false);
                    this.currentVisibleSettings = VIDEO_VISIBLE_SETTINGS.EVERYWHERE;
                } else if (!visibleNone.value && !visibleNotStreamdust.value && !visibleStreamdust.value) {
                    setTimeout(() => {
                        visibleEverywhere.patchValue(true);
                    }, 100);
                }
            });

        visibleNotStreamdust.valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((bool) => {
                if (bool) {
                    visibleEverywhere.patchValue(false);
                    visibleNone.patchValue(false);
                    visibleStreamdust.patchValue(false);
                    this.currentVisibleSettings = VIDEO_VISIBLE_SETTINGS.EXCEPT_STREAMDUST;
                } else if (!visibleNone.value && !visibleEverywhere.value && !visibleStreamdust.value) {
                    setTimeout(() => {
                        visibleNotStreamdust.patchValue(true);
                    }, 100);
                }
            });

        visibleStreamdust.valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((bool) => {
                if (bool) {
                    visibleNotStreamdust.patchValue(false);
                    visibleEverywhere.patchValue(false);
                    visibleNone.patchValue(false);
                    this.currentVisibleSettings = VIDEO_VISIBLE_SETTINGS.STREAMDUST_ONLY;
                } else if (!visibleNone.value && !visibleEverywhere.value && !visibleNotStreamdust.value) {
                    setTimeout(() => {
                        visibleStreamdust.patchValue(true);
                    }, 100);
                }
            });
    }

    public parseAdditionalStreamData(): void {
        if (this.mediaType === MEDIA_TYPE.STREAM && !this.isCreateMode && this.media?.media?.status?.stream !== STREAM_STATUS.FINISHED) {
            this.startDate = new Date(this.media.media.startTimestamp);
            this.endDate = new Date(this.media.media.endTimestamp);
        }
        this.listenPaymentSystemChange();


        this.checkPremium();
        this.attachGroups();
        this.attachGenres();
        this.attachPaymentTypes();
        this.setPriceLimitation();
        this.setSlotPriceVisibility();
        this.listenSlotsPaymentSystemChange();
        this.listenStreamDateChanges();
        this.listenSlotsTimeChange();
        this.recalculateSlotsForRecord();
        this.refreshSlotsFrontiers();
        if (this.mediaType === MEDIA_TYPE.VIDEO_ON_DEMAND) {
            return;
        }
        this.listenDateChange();
        setTimeout(() => {
            this.validateStartDate();
        });
    }

    private resolveInfoPanel(): IInfoPanel {
        if (this.initialValue?.media?.status?.stream === STREAM_STATUS.RESCHEDULING) {
            return InfoPanelsConfig.streamReschedulingInfo;
        }

        if (this.initialValue?.media?.isStarted && !this.initialValue?.media?.isStopped) {
            return InfoPanelsConfig.streamStartedInfo;
        }

        return null;
    }

    private resolveMediaType(): MEDIA_TYPE {
        return (this.activatedRoute.data as BehaviorSubject<any>)?.value?.mediaType;
    }

    public uploadPreview({files}): void {
        const file = files;
        this.photoCropperService.completeWorkbenchReadySubject();

        if (!file) {
            return;
        }

        this.photoService.generateUploader({file, aspectRatio: 91 / 51})
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                ({croppingParams, workbenchId, croppedImage}) => {
                    const preview = this.formsConfig.find(form => form.name === 'preview');
                    if (!preview) {
                        return;
                    }
                    delete preview.payload.photoId;
                    if (croppedImage) {
                        this.photoUrl = croppedImage;
                    } else {
                        const reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = () => {
                            this.photoUrl = reader.result.toString();
                        };
                    }
                    this.photoCropperService.completeWorkbenchReadySubject();
                    this.picture.workbenchId = workbenchId;
                    this.picture.previewSettings = croppingParams;
                    if (this.isCreateMode) {
                        preview.payload.workbenchId = workbenchId;
                        preview.payload.previewSettings = croppingParams;
                    } else {
                        preview.payload.newPicture = this.picture;
                    }
                },
            );
    }

    private checkFormValidity(form: FormGroup): void {
        form.markAllAsTouched();
        form.updateValueAndValidity();
        this.checkStreamValidity(form);
        if (form.invalid) {
            this.valid = false;
        }
    }

    private checkStreamValidity(form: FormGroup): void {
        if (this.mediaType === MEDIA_TYPE.STREAM) {
            if (form.value.startTimestamp < new Date()) {
                const startDateControl = form.get('startTimestamp');
                startDateControl.setErrors({reschedulingRequired: true});
                this.valid = false;
            }
        }
    }

    public submit(): void {
        this.formsConfig.forEach(formConfig => {
            if (formConfig.form) {
                if (formConfig.isToggle && !formConfig.enabled) {
                    // formConfig.form.clearValidators();
                    return;
                }
                this.checkFormValidity(formConfig.form);
            }
            if (formConfig.forms) {
                if (formConfig.isToggle && !formConfig.enabled) {
                    // formConfig.forms.forEach(form => form.clearValidators());
                    return;
                }
                formConfig.forms.forEach(form => this.checkFormValidity(form));
            }
        });


        if (this.mediaType === MEDIA_TYPE.STREAM) {
            this.saveStream();
        }

        if (this.mediaType === MEDIA_TYPE.VIDEO_ON_DEMAND) {
            this.saveVoD();
        }
    }

    public saveStream(): void {
        const payload = this.getPayload();

        if (this.isCreateMode && !this.localTermsAgreed) {
            this.termsRequiredError = true;
            return;
        }

        if (!this.valid) {
            this.valid = true;
            this.formService.submitInvalid$.emit();
            return;
        }

        this.moveVisibilityToMedia(payload);
        this.loadingService.loadingStart();
        if (this.isCreateMode) {
            this.streamService.createStream(payload)
                .pipe(takeUntil(this.destroy$))
                .subscribe((res) => {
                    this.loadingService.loadingEnd();
                    if (!res.success) {
                        if (res.errorKey === 'BANDWIDTH_CAPACITY_LIMIT_REACHED') {
                            this.showBandwidthDialog();
                        }
                        return;
                    }
                    if (res.results.paymentResult.paymentStatus === PAYMENT_STATUS.FAILED) {
                        this.openPaymentModal(res.results.id);
                        return;
                    }
                    this.router.navigate(['/streams/' + res.results.id + '/info']);
                    this.streamService.saveStream$.emit(this.media);
                });
        } else {
            payload.media.id = this.mediaId;
            this.streamService.updateStream(payload)
                .pipe(takeUntil(this.destroy$))
                .subscribe((res) => {
                    this.loadingService.loadingEnd();
                    if (!res.success) {
                        return;
                    }
                    if (res.results.paymentResult.paymentStatus === PAYMENT_STATUS.FAILED) {
                        this.openPaymentModal(res.results.id);
                        return;
                    }
                    this.updateStream();
                    this.streamService.saveStream$.emit(this.media);
                });
        }
    }

    private saveVoD(): void {
        let payload = this.getPayload();

        this.videoFormConfig.forEach(formConfig => {
            if (formConfig.form) {
                if (formConfig.isToggle && !formConfig.enabled) {
                    return;
                }
                this.checkFormValidity(formConfig.form);
            }
            if (formConfig.forms) {
                if (formConfig.isToggle && !formConfig.enabled) {
                    return;
                }
                formConfig.forms.forEach(form => this.checkFormValidity(form));
            }
        });
        if (!this.valid) {
            this.valid = true;
            this.formService.submitInvalid$.emit();
            return;
        }
        payload.videoId = this.videoId;
        payload = {...payload, ...(payload as any).media};
        payload.priceAmount = (payload as any).price;
        payload.visible = this.currentVisibleSettings;
        delete payload.advancedSettings.visible;
        delete (payload as any).startTimestamp;
        delete (payload as any).endTimestamp;
        delete (payload as any).price;
        // TODO remove visible deletion
        // delete (payload as any).visible;
        // delete (payload as any).programSettings;
        delete (payload as any).media;
        delete (payload as any).recordingEnabled;
        // delete (payload as any).streamType;
        delete (payload as any).regionRestriction;
        delete (payload as any).chatEnabled;
        delete (payload as any).backupChannelEnabled;
        this.loadingService.loadingStart();
        if (this.isCreateMode) {
            delete (payload as any).media;
            this.vodService.createVideo(payload)
                .pipe(takeUntil(this.destroy$))
                .subscribe((res) => {
                    this.loadingService.loadingEnd();
                    this.loading = false;
                    if (!res) {
                        return;
                    }
                    this.streamService.saveStream$.emit(this.media);
                    this.router.navigate(['vod/' + res.id + '/info']);
                });
        } else {
            payload.id = this.mediaId;
            this.vodService.updateVideo(payload)
                .pipe(takeUntil(this.destroy$))
                .subscribe((res) => {
                    this.loadingService.loadingEnd();
                    if (!res) {
                        return;
                    }
                    this.streamService.saveStream$.emit(this.media);
                });
        }
    }

    getPayload() {
        const payload = FormUtil.mapFormsToPayload<any>(this.formsConfig);
        payload.media.paymentSystem = {
            ...this.checkModel,
            paymentSystemGroup: !this.premiumPublisher ? PAYMENT_SYSTEM.LIMIT_ACCESS : payload.media.paymentSystem,
        };
        if (!this.premiumPublisher) {
            payload.media.paymentSystem.psPayPerView = true;
        }
        if (this.premiumPublisher && this.isInvalidPaymentSystem(payload.media.paymentSystem.paymentSystemGroup, payload.media.paymentSystem)) {
            this.valid = false;
        }

        if (!payload.programSettings.enabled) {
            delete payload.programSettings.name;
            delete payload.programSettings.slots;
        }

        return payload;
    }

    private openPaymentModal(id: string): void {
        const modalRef = this.modalService.open<PaymentModalComponent>(PaymentModalComponent, (component: ComponentRef<PaymentModalComponent>) => {
            component.instance.returnUrl = 'streams/' + id + '/info';
            component.instance.onlyCard = true;
            component.instance.paymentCardsListControls = {
                payButton: true,
                addNewCard: {
                    show: true,
                    text: 'payment-cards-list.use-another-card',
                    customHandler: (paymentDetails: IHeidelpayPaymentDetails) => this.multiBitratePay(id, paymentDetails, true)
                }
            };
            component.instance.proceedPayment$.pipe(takeUntil(this.destroy$))
                .subscribe((paymentDetails) => {
                    this.multiBitratePay(id, paymentDetails);
                    modalRef.close({});
                });
            component.instance.cancel$.pipe(takeUntil(this.destroy$))
                .subscribe(() => {
                    this.router.navigate(['/streams/' + id + '/info']);
                });
        });
    }

    private multiBitratePay(mediaId: string, cardDetails: IHeidelpayPaymentDetails | IPaymentCard, isNewCardPayment = false): void {
        const payload: IMultiBitratePaymentRequest = {
            mediaId: mediaId,
        };
        const card = (cardDetails as IHeidelpayPaymentDetails).card;
        const url = window.location.protocol + '//' + window.location.host + '/streams/' + mediaId + '/info';

        if (isNewCardPayment) {
            payload.returnUrl = url;
            payload.successUrl = url;
            payload.failUrl = url;
            payload.email = (cardDetails as IHeidelpayPaymentDetails)?.email;
            payload.firstName = (cardDetails as IHeidelpayPaymentDetails)?.firstName;
            payload.lastName = (cardDetails as IHeidelpayPaymentDetails)?.lastName;
            payload.paymentMethod = {
                resourceId: card.id,
                method: card.method
            };
            if (card.method === 'card') {
                payload.paymentMethod.saveCard = true;
                payload.paymentMethod.paymentCard = {
                    externalCardId: card.id,
                    expiryDate: card.expiryDate,
                    type: card.brand,
                    number: card.number.slice(-4),
                    cardDetails: {
                        id: card.id,
                        brand: card.brand,
                        cvc: card.cvc,
                        expiryDate: card.expiryDate,
                        number: card.number.slice(-4),
                        method: card.method,
                        account: card.cardDetails.account,
                        cardType: card.cardDetails.cardType,
                        countryIsoA2: card.cardDetails.countryIsoA2,
                        countryName: card.cardDetails.countryName,
                        issuerName: card.cardDetails.issuerName,
                        issuerPhoneNumber: card.cardDetails.issuerPhoneNumber,
                        issuerUrl: card.cardDetails.issuerUrl,
                        '3ds': card['3ds']
                    }
                };
            }
        } else {
            payload.paymentMethod = {
                resourceId: (cardDetails as IPaymentCard).externalCardId,
                method: 'card',
                userCardId: (cardDetails as IPaymentCard).id};
        }

        this.loadingService.loadingStart();

        this.multiBitrateService.proceedPayment(payload)
            .pipe(takeUntil(this.destroy$))
            .subscribe((result) => {
                this.loadingService.loadingEnd();
                switch (result?.results?.result?.paymentStatus) {
                    case PAYMENT_STATUS.FAILED: {
                        this.paymentService.failedTransactionInfo.next({
                            failedCardId: result.results.result.externalCardId,
                            transactionFailReason: result.results.result.reason
                        });
                        break;
                    }
                    case PAYMENT_STATUS.PENDING: {
                        if (result?.results?.result?.redirectUrl) {
                            window.location.href = result.results.result.redirectUrl;
                        }
                        break;
                    }
                    case PAYMENT_STATUS.SUCCESS: {
                        let qp = {};
                        if (result?.results?.result?.redirectUrl) {
                            qp = this.getQueryParams(result.results.result.redirectUrl);
                        }

                        this.router.navigate(['/streams/' + mediaId + '/info'], {queryParams: qp});
                        return;
                    }
                }
            });
    }

    private getQueryParams(url: string): {[key: string]: string} {
        const paramsString = url.split('?')[1];
        if (!paramsString) {
            return null;
        }
        const params = paramsString.split('&');
        if (!params?.length) {
            return null;
        }

        const reqParams = params.filter(item => item.includes('multiBitratePaymentReturn') || item.includes('transactionId'));
        if (!reqParams?.length) {
            return null;
        }

        const res = {};
        reqParams.forEach(item => {
            const _item = item.split('=');
            res[_item[0]] = _item[1];
        });
        return res;
    }

    public clearImage(): void {
        this.photoUrl = '';
        const preview = this.formsConfig.find(form => form.name === 'preview');
        if (!preview) {
            return;
        }
        preview.payload.photoId = '';
        this.picture = {
            workbenchId: '',
            previewSettings: {
                x: 0,
                y: 0,
                width: 0,
                height: 0,
                rotation: 0
            }
        };
    }

    public toggle(event, childForm: string): void {
        if (!childForm) {
            return;
        }
        if (!event) {
            this.formsConfig.find(form => form.name === childForm).form.get('enabled').patchValue(event);
        }
    }

    public getToggleFormState(toggleFormName: string, toggleFormField: string): boolean {
        if (!toggleFormName) {
            return true;
        }
        return this.formsConfig.find(form => form.name === toggleFormName).form.get(toggleFormField).value;
    }

    public addForm(formConfig: IFormConfig): void {
        formConfig.forms.push(FormUtil.buildForm(formConfig.formsFields));
        this.addDateFrontier(formConfig);
        this.refreshSlotsFrontiers();
        this.listenSlotsTimeChange();
        this.listenSlotsPaymentSystemChange(formConfig.forms.length - 1);
    }

    private addDateFrontier(formConfig: IFormConfig): void {
        if (formConfig.name !== 'programSettings') {
            return;
        }
        const dates = formConfig.formsFields[1][0].config.dateFrontiers;
        if (!dates?.length) {
            return;
        }
        dates.push({start: formConfig.forms[formConfig.forms.length - 2].get('endTimestamp').value, end: this.endDate});
    }

    private listenSlotsTimeChange(): void {
        this.slotsFormsNotifier$.next();
        const formConfig = this.formsConfig.find(item => item.name === 'programSettings');
        if (!formConfig) {
            return;
        }

        const lastItemIndex = formConfig.forms.length - 1;

        formConfig.forms.forEach((form, index) => {
            let start = form.get('startTimestamp').value;
            let end = form.get('endTimestamp').value;

            // if (index === lastItemIndex) {
            //     formConfig.hideAdd = !(!!start && !!end);
            // }

            form.get('startTimestamp').valueChanges.pipe(takeUntil(this.slotsFormsNotifier$))
                .subscribe((res) => {
                    form.get('startTimestamp').markAsTouched();
                    start = res;
                    // if (index === lastItemIndex && start && end) {
                    //     formConfig.hideAdd = false;
                    // }
                    this.refreshSlotsFrontiers();
                });
            form.get('endTimestamp').valueChanges.pipe(takeUntil(this.slotsFormsNotifier$))
                .subscribe((res) => {
                    form.get('endTimestamp').markAsTouched();
                    end = res;
                    // if (index === lastItemIndex && start && end) {
                    //     formConfig.hideAdd = false;
                    // }
                    this.refreshSlotsFrontiers();
                });
        });
    }

    public removeForm(formConfig: IFormConfig, indexToRemove: number): void {
        FormUtil.unselectValues(formConfig, indexToRemove);
        formConfig.forms.splice(indexToRemove, 1);
        this.removeDateFrontier(formConfig, indexToRemove);
        this.refreshSlotsFrontiers();
        if (formConfig.name === 'programSettings') {
            formConfig.formsFields[2][1].hideInForms.splice(formConfig.formsFields[2][1].hideInForms.find((item => item === indexToRemove)), 1);
        }
    }

    private removeDateFrontier(formConfig: IFormConfig, indexToRemove: number): void {
        if (formConfig.name !== 'programSettings') {
            return;
        }
        const dates = formConfig.formsFields[1][0].config.dateFrontiers;
        if (!dates?.length) {
            return;
        }

        dates.splice(indexToRemove, 1);
    }

    private recalculateSlotsForRecord(): void {
        if (!this.media?.media?.hasRecording || !this.media?.programSettings?.slots?.length) {
            return;
        }
        const formConfig = this.formsConfig.find(item => item.name === 'programSettings');
        if (!formConfig) {
            return;
        }

        formConfig.forms.forEach((form, index) => {
            const start = this.media?.programSettings?.slots[index].startTimestamp;
            const end = this.media?.programSettings?.slots[index].endTimestamp;

            if (start.toString().length < 13 && end.toString().length < 13) {
                return;
            }

            form.get('startTimestamp').patchValue(DateHelper.getStringFromMilliseconds(start - this.media?.media?.startTimestamp));
            form.get('endTimestamp').patchValue(DateHelper.getStringFromMilliseconds(end - this.media?.media?.startTimestamp));
        });
    }
    private refreshSlotsFrontiers(): void {
        const formConfig = this.formsConfig.find(item => item.name === 'programSettings');
        if (!formConfig) {
            return;
        }

        const dates = formConfig.formsFields[1][0].config.dateFrontiers;
        if (!dates) {
            return;
        }

        formConfig.forms.forEach((form, index) => {
            const nextForm = formConfig.forms[index + 1];
            const prevForm = formConfig.forms[index - 1];
            dates[index] = {start: null, end: null};
            if (index === 0) {
                dates[index].start = this.startDate;
                dates[index].end = this.endDate;
                this.validateDates(form, prevForm, nextForm);
                return;
            }
            dates[index].start = prevForm ? this.getDateFromInput(prevForm.get('endTimestamp').value) : this.startDate;
            dates[index].end = this.endDate;
            this.validateDates(form, prevForm, nextForm);
        });
    }

    private getDateFromInput(value: any): any {
        return this.mediaType === MEDIA_TYPE.STREAM ? value : DateHelper.getMillisecondsFromString(value);
    }

    private validateDates(form: FormGroup, prevForm: FormGroup, nextForm: FormGroup): void {
        if (form.get('startTimestamp').value &&
            ((form.get('endTimestamp').value < this.startDate) ||
            (this.getDateFromInput(form.get('endTimestamp').value) < this.getDateFromInput(form.get('startTimestamp').value) ||
            (nextForm && nextForm.get('startTimestamp').value && (this.getDateFromInput(form.get('endTimestamp').value)) > (this.getDateFromInput(nextForm.get('startTimestamp').value)))))) {
            form.get('endTimestamp').setErrors({invalidEndDate: true});
        } else {
            const errors = form.get('endTimestamp').errors;
            if (errors?.invalidEndDate) {
                delete errors.invalidEndDate;
            }
            setTimeout(() => {
                form.get('endTimestamp').setErrors(errors ? (Object.entries(errors).length ? errors : null) : null );
            });
        }

        if (form.get('endTimestamp').value && (
            (form.get('startTimestamp').value < this.startDate) ||
            (this.getDateFromInput(form.get('startTimestamp').value) > this.getDateFromInput(form.get('endTimestamp').value)) ||
            (prevForm && form.get('startTimestamp').value && (this.getDateFromInput(form.get('startTimestamp').value) < this.getDateFromInput(prevForm.get('endTimestamp').value))))) {
            form.get('startTimestamp').setErrors({invalidStartDate: true});
        } else {
            const errors = form.get('startTimestamp').errors;
            if (errors?.invalidStartDate) {
                delete errors.invalidStartDate;
            }
            setTimeout(() => {
                form.get('startTimestamp').setErrors(errors ? (Object.entries(errors).length ? errors : null) : null);
            });
        }
        form.markAllAsTouched();
    }

    private updateStream(): void {
        this.streamService.getMedia(this.mediaType, this.mediaId)
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
            });
    }

    public getForms(): FormGroup[] {
        const forms: FormGroup[] = [];
        if (this.mediaType === MEDIA_TYPE.VIDEO_ON_DEMAND) {
            this.videoFormConfig.forEach(formConfig => {
                if (formConfig.form) {
                    forms.push(formConfig.form);
                }

                if (formConfig.forms) {
                    formConfig.forms.forEach(form => forms.push(form));
                }
            });
        }
        if (this.formsConfig?.length) {
            this.formsConfig.forEach(formConfig => {
                if (formConfig.form) {
                    forms.push(formConfig.form);
                }

                if (formConfig.forms) {
                    formConfig.forms.forEach(form => forms.push(form));
                }
            });
        }
        return forms;
    }

    public attachGenres(): void {
        const genre = this.formsConfig
            .find(form => form.name === 'media')
            .formFields.find(formField => formField[1]?.name === 'genre');

        if (genre) {
            genre[1].config.selectOptions = this.cmsService.mapGenresToKeyValue(this.genres);
        }
    }

    public attachGroups(idToSelect?: string): void {
        const groups = this.formsConfig.find(form => form.name === 'groupsSettings');
        if (groups) {
            groups.formsFields[0][0].config.selectOptions = this.streamGroupsService.mapGroupsToKeyValue(this.myGroups);
            groups.formsFields[0][0].config.selectOptions.forEach(group => {
                if (this.media?.groupsSettings?.groups.includes(group.key as string)) {
                    group.selected = true;
                }
            });
            const selectedOptions = groups.formsFields[0][0].config.selectOptions;
            if (idToSelect) {
                const index = selectedOptions.findIndex(item => item.key === idToSelect);
                selectedOptions[index].selected = true;
                groups.forms[index].get('groups').patchValue(idToSelect);
            }
        }
    }

    public attachPaymentTypes(): void {
        const paymentForm = this.formsConfig
            .find(form => form.name === 'media').form.get('paymentSystem');

        const paymentSystem = this.formsConfig
            .find(form => form.name === 'media')
            .formFields.find(formField => formField[0].name === 'paymentSystem');

        if (this.media?.media.paymentSystem) {
            paymentForm.setValue(this.media.media.paymentSystem.paymentSystemGroup);
            paymentSystem[0]['model'] = this.media.media.paymentSystem;
        }
    }

    public setPriceLimitation(): void {
        this.formsConfig
            .find(form => form.name === 'media').form
            .get('price')
            .setValidators([Validators.required, CustomValidators.priceAmount(this.MIN_VALUE_PRICE)]);
    }

    hideSlotsControl() {
        const slots: IFormConfig = this.formsConfig.find(formConfig => formConfig.name === 'programSettings');
        slots.hidden = true;
        this.checkModel.psSlot = false;
        this.formsConfig.find(form => form.name === 'programSettings').enabled = false;
    }

    showSlotsControl() {
        const slots: IFormConfig = this.formsConfig.find(formConfig => formConfig.name === 'programSettings');
        slots.hidden = false;
        if (this.mediaType === MEDIA_TYPE.VIDEO_ON_DEMAND) {
            return;
        }
        if ((!this.startDate || !this.endDate) && !this.media?.media?.hasRecording) {
            slots.formInfoPanel.show = true;
            slots.forcedDisable = true;
            slots.enabled = false;
        }
    }

    private listenPaymentSystemChange(): void {
        this.formsConfig
            .find(form => form.name === 'media').form
            .get('paymentSystem').valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((paymentSystem) => {
                // @ts-ignore
                this.media?.media?.paymentSystem.paymentSystemGroup = paymentSystem;

                if (paymentSystem === PAYMENT_SYSTEM.COURSE_ACCESS) {
                    this.hideSlotsControl();
                } else if (paymentSystem === PAYMENT_SYSTEM.FREE_ACCESS) {
                    this.checkModel.psLeadGeneration = false;
                    this.checkModel.psPayPerView = false;
                    this.checkModel.psVoucher = false;
                    this.logicTypesPayments();
                    this.hideSlotsControl();
                } else {
                    this.showSlotsControl();
                    this.addValidationForSlots();
                }

                // if (this.mediaType === MEDIA_TYPE.VIDEO_ON_DEMAND) {
                //     this.hideSlotsControl();
                // }

                this.validatePayments(paymentSystem);
            });
    }

    private validatePayments(paymentSystem) {

        // Promise is using to detect changes on next microtask
        // removing console.error NG0100: ExpressionChangedAfterItHasBeenCheckedError
        // https://angular.io/errors/NG0100
        // DO NOT USE cd.detectChanges() !!!
        // causes bug STREAM-2277

        Promise.resolve().then(() => {
            if (this.isInvalidPaymentSystem(paymentSystem, this.checkModel)) {
                this.valid = true;
                this.showAccesError = true;
                // this.formService.submitInvalid$.emit();
            } else {
                this.showAccesError = false;
            }
        });
    }

    private addValidationForSlots(): void {
        const formConfig = this.formsConfig.find(item => item.name === 'programSettings');
        if (!formConfig) {
            return;
        }

        formConfig.formsFields[1][0].config.dateFrontiers = [{
            start: this.startDate,
            end: this.endDate
        }];
    }

    private setSlotPriceVisibility(): void {
        const config: IFormConfig = this.formsConfig.find(formConfig => formConfig.name === 'programSettings');
        config.forms.forEach((_form, index) => {
            if (!_form.get('slotPayment').value || _form.get('slotPayment').value === PAYMENT_SYSTEM.FREE || _form.get('slotPayment').value === PAYMENT_SYSTEM.VOUCHER) {
                config.formsFields[2][1].hideInForms.push(index);
            }
        });
    }

    private listenSlotsPaymentSystemChange(formIndex?: number): void {
        this.slotsPaymentSystemSubject$.next();
        const config: IFormConfig = this.formsConfig.find(formConfig => formConfig.name === 'programSettings');
        if (formIndex) {
            config.formsFields[2][1].hideInForms.push(formIndex);
        }
        config.forms.forEach((_form, index) => {
            _form.get('slotPayment').valueChanges.pipe(takeUntil(this.slotsPaymentSystemSubject$))
                .subscribe((value) => {
                    const _index = config.formsFields[2][1].hideInForms.findIndex(item => item === index);
                    if (value === PAYMENT_SYSTEM.FREE || value === PAYMENT_SYSTEM.VOUCHER) {
                        if (_index >= 0) {
                            config.formsFields[2][1].hideInForms[_index] = index;
                        } else {
                            config.formsFields[2][1].hideInForms.push(index);
                            _form.get('price').patchValue(0);
                        }
                        _form.get('price').clearValidators();
                        _form.get('price').markAsTouched();
                        _form.get('price').updateValueAndValidity();
                    } else {
                        if (_index >= 0) {
                            config.formsFields[2][1]?.hideInForms
                                .splice(_index, 1);
                            _form.get('price').patchValue(null);
                            _form.get('price').markAsTouched();
                            _form.get('price').setValidators([Validators.required, CustomValidators.priceAmount(this.MIN_VALUE_PRICE)]);
                        }
                    }
                });
        });
    }

    private listenStreamDateChanges(): void {
        if (this.mediaType === MEDIA_TYPE.VIDEO_ON_DEMAND || (!this.isCreateMode && this.media?.media?.status?.stream === STREAM_STATUS.FINISHED)) {
            return;
        }
        const formConfig = this.formsConfig.find(item => item.name === 'media');
        if (!formConfig) {
            return;
        }

        const slotsFormConfig = this.formsConfig.find(item => item.name === 'programSettings');

        formConfig.form.get('startTimestamp').valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((res) => {
                this.startDate = res;
                if (this.endDate && this.startDate) {
                    slotsFormConfig.forcedDisable = false;
                    slotsFormConfig.formInfoPanel.show = false;
                    slotsFormConfig.enabled = this.checkModel.psSlot;
                    this.refreshSlotsFrontiers();
                    this.listenSlotsTimeChange();
                }
            });

        formConfig.form.get('endTimestamp').valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((res) => {
                this.endDate = res;
                if (this.endDate && this.startDate) {
                    slotsFormConfig.forcedDisable = false;
                    slotsFormConfig.formInfoPanel.show = false;
                    slotsFormConfig.enabled = this.checkModel.psSlot;
                    this.refreshSlotsFrontiers();
                    this.listenSlotsTimeChange();
                }
            });
    }

    private hidePriceForms(priceForms, price): void {
        priceForms[0].hidden = true;
        price.setValue(0);
    }

    checkIfInfoPanelNeeded(): boolean {
        return this.initialValue?.media?.status?.stream === STREAM_STATUS.ACTIVATED
            || this.initialValue?.media?.status?.stream === STREAM_STATUS.LIVE
            || this.initialValue?.media?.status?.stream === STREAM_STATUS.ACTIVATION
            || this.initialValue?.media?.status?.stream === STREAM_STATUS.RESCHEDULING;
    }

    private checkPremium(): void {
        this.userService.userProfile$.pipe(takeUntil(this.destroy$))
            .subscribe((res) => {
                this.premiumPublisher = res?.subscribed;
            });
    }

    uploadVideo(data: IVideoInfo) {
        this.advertisementVideo.videoUrl = data.url;
        this.videoId = data.id;
        this.patchVideoFormValue(this.videoId);
    }

    clearVideo($event: IPreviewPicture | IStreamPreviewVideoMetadata) {
        this.patchVideoFormValue(null);
    }

    private patchVideoFormValue(value: string): void {
        const found = this.videoFormConfig.find(formConfig => formConfig.name === 'video');

        if (!found) {
            return;
        }

        found.form.get('video').patchValue(value);
    }

    logicTypesPayments(data?: { key, checkModel }) {
        const priceForms = this.formsConfig
            .find(form => form.name === 'media')
            .formFields.find(formField => formField[0].name === 'price');
        const form = this.formsConfig
            .find(form => form.name === 'media').form;
        // const currency = form.get('currency');
        const price = form.get('price');

        if (!this.checkModel) {
            return;
        }

        if (!this.checkModel.psPayPerView) {
            this.hidePriceForms(priceForms, price);
        } else if (this.checkModel.psPayPerView) {
            this.showPrice(data.key);
        }
    }

    showPrice(key?) {
        const form = this.formsConfig
            .find(form => form.name === 'media').form;
        const priceForms = this.formsConfig
            .find(form => form.name === 'media')
            .formFields.find(formField => formField[0].name === 'price');
        priceForms[0].hidden = false;
        const price = form.get('price');
        price.setValidators([Validators.required, CustomValidators.priceAmount(this.MIN_VALUE_PRICE)]);
        if (!price.value) {
            price.setValue(null);
        }
    }

    hidePrice() {
        const priceForms = this.formsConfig
            .find(form => form.name === 'media')
            .formFields.find(formField => formField[0].name === 'price');
        const form = this.formsConfig
            .find(form => form.name === 'media').form;
        const price = form.get('price');
        this.hidePriceForms(priceForms, price);
    }

    checkedPayments$(data: { key, checkModel }) {
        this.checkModel = data.checkModel;
        this.logicTypesPayments(data);

        const paymentSystem = this.formsConfig
            .find(form => form.name === 'media').form
            .get('paymentSystem').value;

        this.validatePayments(paymentSystem);

        this.formsConfig.find(form => form.name === 'programSettings').enabled = this.checkModel.psSlot && ((this.mediaType === MEDIA_TYPE.STREAM && !this.media?.media?.hasRecording && !!this.startDate && !!this.endDate) || (this.mediaType === MEDIA_TYPE.VIDEO_ON_DEMAND || this.media?.media?.hasRecording));
        this.formsConfig.find(form => form.name === 'donationSettings').enabled = this.checkModel.psDonation;
    }

    private listenDateChange() {
        this.formsConfig
            .find(form => form.name === 'media').form
            .get('startTimestamp')?.valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((startDate) => {
                if (!startDate) {
                    return;
                }
                const endDateControl = this.formsConfig.find(form => form.name === 'media').form
                    .get('endTimestamp');
                const maxDate = new Date((startDate.getTime() + 57600000));
                if (startDate > endDateControl.value || endDateControl.value > maxDate) {
                    endDateControl.setErrors({'endDateBeforeStart': true});
                    endDateControl.markAsTouched();
                } else {
                    endDateControl.updateValueAndValidity();
                }
            });
    }

    private validateStartDate(): void {
        const form = this.formsConfig
            .find(_form => _form.name === 'media').form;
        const dateField = form.get('startTimestamp');
        if (dateField?.value && dateField?.value?.getTime() < new Date().getTime() && !([STREAM_STATUS.FINISHED, STREAM_STATUS.DEACTIVATED].includes(this.media.media?.status?.stream))) {
            dateField.setErrors({reschedulingRequired: true});
            if (![STREAM_STATUS.LIVE, STREAM_STATUS.ACTIVATED].includes(this.media.media?.status?.stream)) {
                form.markAllAsTouched();
            }
        }
    }

    toggleEmit(event) {
        this.streamEventsService.toggleStream.emit(event);
    }

    private isInvalidPaymentSystem(paymentSystemGroup, paymentSystem) {
        return (paymentSystemGroup === PAYMENT_SYSTEM.LIMIT_ACCESS
            && !paymentSystem.psVoucher
            && !paymentSystem.psLeadGeneration
            && !paymentSystem.psPayPerView
            && !paymentSystem.psSlot)
            || (paymentSystemGroup === PAYMENT_SYSTEM.COURSE_ACCESS
                && !paymentSystem.psVoucher
                && !paymentSystem.psLeadGeneration
                && !paymentSystem.psPayPerView
                && !paymentSystem.psCustomIntegration);
    }

    private moveVisibilityToMedia(payload: any): void {
        payload.media.visible = this.currentVisibleSettings;
        delete payload.visible;
        delete payload.advancedSettings.visible;
    }

    public toggleTermsAgreed(event: boolean): void {
        this.localTermsAgreed = event;
        this.termsRequiredError = false;
    }


    public checkStorageAndBandwidthLimit(storageAndBandwidthLimit: IStorageAndBandwidthTariffs) {
        const bandwidthLimit = storageAndBandwidthLimit.dataLimits.bandwidth;
        if (this.resolveMediaType() === MEDIA_TYPE.STREAM &&
            bandwidthLimit?.limitReached) {
            this.showBandwidthDialog();
        }
    }

    public showBandwidthDialog(): void {
        if (!this.dialogRef) {
            this.dialogRef = this.dialog.open(this.bandwidthDialog);

            this.dialogRef.afterClosed()
                .pipe(takeUntil(this.destroy$))
                .subscribe((confirm: boolean) => {
                    if (confirm) {
                        this.router.navigate(['/bandwidth/upgrade/4/WEEK']);
                    }
                    this.dialogRef = null;
                });
        }
    }
}


enum MEDIA_SAVE_ERROR {
    CARD_REQUIRED = 'CARD_REQUIRED',
    PAYMENT_FAILURE = 'PAYMENT_FAILURE'
}
