<div [class.hidden]="currentModalState === MEDIA_ACCESS_MODAL_STATE.LEAD_GENERATION_REGISTERED"
     [class.mh-auto]="currentModalState === MEDIA_ACCESS_MODAL_STATE.LEAD_GENERATION_REGISTERED"
     [class.modal-wrapper--landscape]="showLandscapeMode"
     [class.modal-wrapper--landscape-closed]="landscapeModeClosed"
     class="modal-wrapper bg-white">
    <div *ngIf="!configModalPublisher.isOpened" class="left-side" [class.left-side--reg-offer]="showRegistrationOffer">
        <div [class.payment]="currentModalState !== MEDIA_ACCESS_MODAL_STATE.LEAD_GENERATION_REGISTERED"
             class="center">
            <ng-container *ngIf="currentModalState === MEDIA_ACCESS_MODAL_STATE.REGISTRATION_STEP_1
                          || currentModalState === MEDIA_ACCESS_MODAL_STATE.REGISTRATION_STEP_2"
            >
                <div *ngIf="!showLandscapeMode && showRegistrationOffer" class="reg-offer-header">
                    <div class="reg-offer-title">
                        {{'media-access-modal.reg-offer.title' | localize}}
                    </div>

                    <button
                            (click)="closeAccessModal()"
                            class="modal-close-btn btn btn-sm">
                        {{'media-access-modal.register-later' | localize}}
                        <i class="modal-close__icon icon icon-arrow-right"></i>
                    </button>
                </div>

                <div class="top-wrapper">

                    <div *ngIf="userHasAccess &&
                         (currentModalState === MEDIA_ACCESS_MODAL_STATE.REGISTRATION_STEP_2 || currentModalState === MEDIA_ACCESS_MODAL_STATE.REGISTRATION_STEP_1);
                         else RegularRegister"
                    >
                        <div class="voucher-top" *ngIf="isUsedVoucher">
                            <div class="voucher-top-first-text">
                                <div class="title-wrapper">
                                    <h2 class="title-level-2">
                                        <b class="voucher-active-text">{{'media-access-modal.used-voucher' | localize}}
                                            !</b>
                                    </h2>
                                </div>
                                <div (click)="goBack()" class="register-later-button">
                                    <span>
                                        {{('media-access-modal.cancel-button-text.not-now') | localize}}
                                    </span>
                                    <i class="icon icon-arrow-right"></i>
                                </div>
                            </div>
                        </div>
                        <div class="no-voucher-top jc-end" *ngIf="!isUsedVoucher">
                            <div (click)="goBack()" class="register-later-button">
                                    <span>
                                        {{('media-access-modal.cancel-button-text.not-now') | localize}}
                                    </span>
                                <i class="icon icon-arrow-right"></i>
                            </div>
                        </div>
                    </div>

                    <ng-template #RegularRegister>
                        <div class="title-wrapper">
                            <h2 class="title-level-2">
                                <b>{{'media-access-modal.register-account' | localize}}</b>
                            </h2>
                        </div>
                    </ng-template>
                </div>
            </ng-container>

            <ng-container *ngIf="currentModalState === MEDIA_ACCESS_MODAL_STATE.REMIND_ME_NO_AUTHORIZED">
                <div class="top-wrapper">
                    <div class="title-wrapper">
                        <h2 class="title-level-2">
                            {{'media-access-modal.remind.title' | localize}}
                        </h2>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="currentModalState === MEDIA_ACCESS_MODAL_STATE.ONLY_FOR_REGISTERED">
                <div class="top-wrapper">
                    <div class="title-wrapper">
                        <h2 class="title-level-2">
                            {{'media-access-modal.onlyForRegistered.title' | localize}}
                        </h2>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="currentModalState === MEDIA_ACCESS_MODAL_STATE.COURSES_STEP_1">
                <div class="top-wrapper">
                    <div class="title-wrapper">
                        <h2 class="title-level-2">
                            {{'media-access-modal.title.courses' | localize}}
                        </h2>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="currentModalState === MEDIA_ACCESS_MODAL_STATE.IMPRESSUM">
                <div class="top-wrapper">
                    <div class="title-wrapper">
                        <h2 class="title-level-2">
                            {{'profile.publisher.imprint' | localize}}
                        </h2>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="currentModalState === MEDIA_ACCESS_MODAL_STATE.SLOT_VOUCHER_ACTIVATION
                                    || currentModalState === MEDIA_ACCESS_MODAL_STATE.VOUCHER_ACTIVATION">
                <div class="top-wrapper">
                    <div class="title-wrapper">
                        <h2 class="title-level-2">
                            {{'media-access-modal.title.voucher' | localize}}
                        </h2>
                    </div>
                </div>
            </ng-container>


            <ng-container
                    *ngIf="currentModalState === MEDIA_ACCESS_MODAL_STATE.ACCESS_SELECT
                            || currentModalState === MEDIA_ACCESS_MODAL_STATE.INFO">
                <div class="top-wrapper">
                    <div class="title-wrapper">
                        <ng-container *ngIf="!userHasAccess || isAuthorized">
                            <h2 class="title-level-2">{{'media-access-modal.title' | localize}}</h2>
                            <h2 class="title-level-2">{{'media-access-modal.subtitle' | localize}}
                                <b>{{'media-access-modal.media' | localize}}</b></h2>
                        </ng-container>

                        <h2 *ngIf="userHasAccess && !isAuthorized" class="title-level-2">
                            {{'media-access-modal.register-account' | localize}}
                        </h2>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="currentModalState === MEDIA_ACCESS_MODAL_STATE.LEAD_GENERATION_AUTHORIZED
                    || currentModalState === MEDIA_ACCESS_MODAL_STATE.LEAD_GENERATION_REGISTERED">
                <div class="top-wrapper">
                    <div class="title-wrapper">
                        <h2 class="title-level-2">
                            <b>{{'media-access-modal.lead-generation.title' | localize}}</b>
                        </h2>
                    </div>
                </div>
            </ng-container>

            <div class="center-wrapper">
                <ng-container
                        *ngIf="!userHasAccess">
                    <ng-container *ngIf="currentModalState === MEDIA_ACCESS_MODAL_STATE.ACCESS_SELECT">
                        <div>
                            <div (click)="showInfo(MEDIA_ACCESS_MODAL_STATE.ONLINE_PAYMENT)"
                                 *ngIf="paymentSystem?.psPayPerView"
                                 class="form-check">
                                <input class="form-check-input" id="radios1" name="Radios" type="radio" value="option1">
                                <ng-container *ngIf="!activeVoucher; else Voucher">
                                    <label class="form-check-label" for="radios1">
                                        {{'media-access-modal.online-payment-for' | localize}}
                                    </label><br>
                                    <label>
                                        <span>{{'media-access-modal.regular-price' | localize}}: </span>
                                        <b class="blue-text">{{mediaPrice | currency}}</b>
                                    </label>
                                </ng-container>
                                <ng-template #Voucher>
                                    <label class="form-check-label" for="radios1">
                                        {{'media-access-modal.online-payment-for' | localize}}
                                        <span class="voucher-active-text">({{'media-access-modal.used-voucher' | localize}}
                                            )</span>
                                    </label><br>
                                    <label class="blue-text">
                                        <b>{{'media-access-modal.new-price-voucher' | localize}}: </b>
                                        <b>{{mediaPrice | currency}}</b>
                                    </label>
                                </ng-template>
                            </div>

                            <div (click)="showInfo(MEDIA_ACCESS_MODAL_STATE.VOUCHER_ACTIVATION)"
                                 *ngIf="paymentSystem?.psVoucher"
                                 class="form-check">
                                <input class="form-check-input" id="radios2" name="Radios" type="radio" value="option1">
                                <label class="form-check-label" for="radios2">
                                    {{'media-access-modal.use-a-voucher' | localize}}
                                </label>
                            </div>
                            <div (click)="showInfo(MEDIA_ACCESS_MODAL_STATE.LEAD_GENERATION)"
                                 *ngIf="leadGenerationMedia || paymentSystem?.psLeadGeneration"
                                 class="form-check">
                                <input class="form-check-input" id="radios3" name="Radios" type="radio" value="option1">
                                <label class="form-check-label" for="radios3">
                                    {{'media-access-modal.register-lead-generation' | localize}}
                                    <small>({{'media-access-modal.registrationRadio'|localize}})</small>
                                </label>
                            </div>
                            <div (click)="openGroupModal()"
                                 *ngIf="data?.video?.groups?.length"
                                 class="form-check">
                                <input #groupSelector (click)="groupSelector.checked = false" class="form-check-input"
                                       id="radios7" name="Radios" type="radio" value="option1">
                                <label class="form-check-label blue-text" for="radios7">
                                    <b class="text-position-with-bold-icon">{{'media-access-modal.buy-group' | localize}}
                                        <i class="icon icon-arrow-right bold-icon"></i></b>
                                </label>
                            </div>
                            <div (click)="openSlotModal()"
                                 *ngIf="data?.programSettings?.slots?.length"
                                 class="form-check">
                                <input (click)="closeAccessModal()" #groupSelector (click)="groupSelector.checked = false" class="form-check-input"
                                       id="radios8" name="Radios" type="radio" value="option1">
                                <label class="form-check-label blue-text" for="radios8">
                                    <b class="text-position-with-bold-icon">{{'media-access-modal.buy-slot' | localize}}
                                        <i class="icon icon-arrow-right bold-icon"></i></b>
                                </label>
                            </div>
                        </div>

                    </ng-container>

                    <ng-container *ngIf="currentModalState === MEDIA_ACCESS_MODAL_STATE.IMPRESSUM">
                        <div class="modal-body">
                            <div [innerText]="configModalPublisher.htmlText"></div>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="currentModalState === MEDIA_ACCESS_MODAL_STATE.REMIND_ME_NO_AUTHORIZED">
                        {{(!remindService.state.active ? 'media-access-modal.remind.message.alert' : 'media-access-modal.remind.message.success') | localize}}
                    </ng-container>

                    <ng-container *ngIf="currentModalState === MEDIA_ACCESS_MODAL_STATE.ONLY_FOR_REGISTERED">
                        {{'media-access-modal.onlyForRegistered.message' | localize}}
                    </ng-container>

                    <ng-container *ngIf="currentModalState === MEDIA_ACCESS_MODAL_STATE.INFO">
                        <div *ngIf="!isAuthorized"
                             class="required-box second-info-box">
                            <h4 class="title">{{'media-access-modal.wichtigeInfo' | localize}}</h4>
                            <p>{{'media-access-modal.wichtigeInfoText' | localize}}</p>
                            <div class="form-check">
                                <input (change)="changeCheck('firstTerms')"
                                       [(ngModel)]="checkModel.firstTerms"
                                       [ngModelOptions]="{standalone: true}"
                                       class="form-check-input" id="privacyPolicy" type="checkbox">
                                <label class="form-check-label"
                                       for="privacyPolicy">{{'media-access-modal.verstanden' | localize}}</label>
                            </div>
                        </div>

                        <div class="required-box second-info-box">
                            <div class="form-check">
                                <input (change)="changeCheck('secondTerms')"
                                       [(ngModel)]="checkModel.secondTerms"
                                       [ngModelOptions]="{standalone: true}"
                                       class="form-check-input" id="privacyPolicyCheckbox1" type="checkbox"
                                >
                                <label class="form-check-label" for="privacyPolicyCheckbox1">
                                    {{'media-access-modal.privacy-policy.i-agree-with' | localize}} <a
                                        (click)="showPopUp($event)"
                                        href="javascript:void(0)">{{'media-access-modal.privacy-policy.right-of-withdrawal' | localize}}</a> {{'media-access-modal.privacy-policy.and-the' | localize}}
                                    <a [href]="termsConditions.privacyPolicyUrl"
                                       target="_blank">{{'media-access-modal.privacy-policy.privacy-policy' | localize}}</a> {{'media-access-modal.privacy-policy.privacy-policy-too' | localize}}
                                    {{'media-access-modal.right-of-withdrawal.notes-on' | localize}}
                                    <a
                                            (click)="showPopUp($event)"
                                            href="javascript:void(0)">{{'media-access-modal.right-of-withdrawal.right-of-withdrawal' | localize}}</a> {{'media-access-modal.right-of-withdrawal.of-the-organizer' | localize}}
                                </label>
                            </div>
                        </div>

                        <div *ngIf="selectedPayment === PAYMENT_SYSTEM.LEAD_GENERATION && leadTextField1"
                             class="required-box second-info-box">
                            <div class="form-check">
                                <input (change)="changeCheck('leadTextField1')"
                                       [(ngModel)]="checkModel.leadTextField1"
                                       [ngModelOptions]="{standalone: true}"
                                       class="form-check-input" id="leadTextField1" type="checkbox">
                                <label class="form-check-label" for="leadTextField1">
                                    {{
                                        leadTextField1 ? leadTextField1 : ('media-access-modal.leadgeneration.desc' | localize)
                                    }}
                                </label>
                            </div>
                        </div>

                        <div *ngIf="selectedPayment === PAYMENT_SYSTEM.LEAD_GENERATION && leadTextField2"
                             class="required-box">
                            <div class="form-check">
                                <input (change)="changeCheck('leadTextField2')"
                                       [(ngModel)]="checkModel.leadTextField2"
                                       [ngModelOptions]="{standalone: true}"
                                       class="form-check-input" id="leadTextField2" type="checkbox"
                                >
                                <label class="form-check-label" for="leadTextField2">
                                    {{ leadTextField2 }}
                                </label>
                            </div>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="currentModalState === MEDIA_ACCESS_MODAL_STATE.COURSES_STEP_1">
                        <div class="required-box second-info-box">
                            <p class="pr-3">
                                {{'media-access-modal.coursesState1.text1' | localize}}
                            </p>
                            <p>
                                {{'media-access-modal.coursesState1.text2' | localize}}
                            </p>
                            <div class="form-check">
                                <input (change)="changeCheck('firstTerms')"
                                       [(ngModel)]="checkModel.firstTerms"
                                       [ngModelOptions]="{standalone: true}"
                                       class="form-check-input" id="privacyPolicy" type="checkbox">
                                <label [class.text-danger]="checkModel.firstTermsError"
                                       class="form-check-label"
                                       for="privacyPolicy">{{'media-access-modal.coursesState1.checkBox1' | localize}}</label>
                                <span *ngIf="checkModel.firstTermsError"
                                      class="invalid-feedback text-danger">{{'error.required' | localize}}</span>
                            </div>
                            <div class="form-check">
                                <input (change)="changeCheck('secondTerms')"
                                       [(ngModel)]="checkModel.secondTerms"
                                       [ngModelOptions]="{standalone: true}"
                                       class="form-check-input" id="privacyPolicyCheckbox2" type="checkbox">
                                <label [class.text-danger]="checkModel.secondTermsError"
                                       class="form-check-label"
                                       for="privacyPolicyCheckbox2">{{'media-access-modal.coursesState1.checkBox2' | localize}}</label>
                                <span *ngIf="checkModel.secondTermsError"
                                      class="invalid-feedback text-danger">{{'error.required' | localize}}</span>
                            </div>
                        </div>
                        <button (click)="coursesRegister()"
                                *ngIf="!(authService.isAuthorized$ | async)"
                                class="btn btn-primary btn-sm"
                                localize="media-access-modal.viewer-register-modal.send-button"></button>
                    </ng-container>

                    <app-payment-cards-list (proceedPayment$)="payForMedia($event)"
                                            *ngIf="currentModalState === MEDIA_ACCESS_MODAL_STATE.ONLINE_PAYMENT"
                                            [addNewCardModalSize]="12"
                                            [chargeImmediately]="true"
                                            [controls]="paymentCardsListControls"
                                            class="media-payment-cards-list">
                    </app-payment-cards-list>

<!--                    <app-payment-cards-list (proceedPayment$)="payForSlot($event)"-->
<!--                                            *ngIf="currentModalState === MEDIA_ACCESS_MODAL_STATE.SLOT_PAYMENT"-->
<!--                                            [addNewCardModalSize]="12"-->
<!--                                            [chargeImmediately]="true"-->
<!--                                            [controls]="slotCardsListControls"-->
<!--                                            class="media-payment-cards-list">-->
<!--                    </app-payment-cards-list>-->

                    <ng-container *ngIf="currentModalState === MEDIA_ACCESS_MODAL_STATE.VOUCHER_ACTIVATION">
                        <ng-container *ngTemplateOutlet="voucherTemplate"></ng-container>
                    </ng-container>

                    <ng-container *ngIf="currentModalState === MEDIA_ACCESS_MODAL_STATE.SLOT_VOUCHER_ACTIVATION">
                        <ng-container *ngTemplateOutlet="voucherSlotTemplate"></ng-container>
                    </ng-container>

                    <ng-container
                            *ngIf="currentModalState === MEDIA_ACCESS_MODAL_STATE.REGISTRATION_STEP_1">
                        <ng-container *ngTemplateOutlet="leadGenerationTemplate"></ng-container>
                    </ng-container>

                    <ng-container *ngIf="currentModalState === MEDIA_ACCESS_MODAL_STATE.LEAD_GENERATION_AUTHORIZED">
                        <ng-container *ngTemplateOutlet="leadGenerationTemplateAuthorized"></ng-container>
                    </ng-container>

                    <ng-container
                            *ngIf="currentModalState === MEDIA_ACCESS_MODAL_STATE.REGISTRATION_STEP_2">
                        <ng-container *ngTemplateOutlet="viewerRegisterTemplate"></ng-container>
                    </ng-container>
                </ng-container>

                <ng-container
                        *ngIf="(slot?.hasAccess || voucherRedeemed || paymentSuccess) && !registrationCanceled && userHasAccess && !isAuthorized && currentModalState !== MEDIA_ACCESS_MODAL_STATE.REGISTRATION_STEP_2">
                    <ng-container *ngTemplateOutlet="leadGenerationTemplate"></ng-container>
                </ng-container>
            </div>

            <div *ngIf="(currentModalState === MEDIA_ACCESS_MODAL_STATE.ACCESS_SELECT
                 || currentModalState === MEDIA_ACCESS_MODAL_STATE.INFO
                 || currentModalState === MEDIA_ACCESS_MODAL_STATE.COURSES_STEP_1
                 || currentModalState === MEDIA_ACCESS_MODAL_STATE.REGISTRATION_STEP_2
                 || currentModalState === MEDIA_ACCESS_MODAL_STATE.REGISTRATION_STEP_1
                 || currentModalState === MEDIA_ACCESS_MODAL_STATE.LEAD_GENERATION_AUTHORIZED
                 || currentModalState === MEDIA_ACCESS_MODAL_STATE.VOUCHER_ACTIVATION
                 || currentModalState === MEDIA_ACCESS_MODAL_STATE.IMPRESSUM
                 || currentModalState === MEDIA_ACCESS_MODAL_STATE.REMIND_ME_NO_AUTHORIZED
                 || currentModalState === MEDIA_ACCESS_MODAL_STATE.ONLY_FOR_REGISTERED
                 )"
                 class="bottom-wrapper">
                <div>
                    <div class="actions-bottom">
                        <a (click)="onDownloadIcs()"
                           *ngIf="currentModalState === MEDIA_ACCESS_MODAL_STATE.ACCESS_SELECT"
                           class="calendar-link" href="javascript:void(0);"><i
                                class="icon icon-calendar"></i>
                            {{'media-access-modal.calendarLink'| localize}}
                        </a>

                        <a (click)="goRemindMe()"
                           *ngIf="currentModalState === MEDIA_ACCESS_MODAL_STATE.ACCESS_SELECT && data?.reminders.mediaRemindersEnabled"
                           class="calendar-link" href="javascript:void(0);">
                            <i [ngClass]="!remindService.state.active ? 'icon-bell' : 'icon-bell-active'"
                               class="icon"
                            ></i>
                            {{(remindService.state.active ? 'media-access-modal.remindLinkOff' : 'media-access-modal.remindLinkOn') | localize}}
                        </a>
                    </div>

                    <a (click)="goBack()"
                       *ngIf="(currentModalState === MEDIA_ACCESS_MODAL_STATE.INFO
                    || currentModalState === MEDIA_ACCESS_MODAL_STATE.LEAD_GENERATION_AUTHORIZED
                    || currentModalState === MEDIA_ACCESS_MODAL_STATE.VOUCHER_ACTIVATION
                    || currentModalState === MEDIA_ACCESS_MODAL_STATE.REMIND_ME_NO_AUTHORIZED
                    || currentModalState === MEDIA_ACCESS_MODAL_STATE.IMPRESSUM)"
                       class="back-link back-info link-color" href="javascript:void(0);">
                        <i class="icon icon-back-arrow"></i>{{'media-access-modal.cancel-button-text.back' | localize}}
                    </a>
                </div>

            </div>
        </div>

        <div class="center"></div>

    </div>

    <div *ngIf="!configModalPublisher.isOpened" [class.hide-login]="(
         currentModalState !== MEDIA_ACCESS_MODAL_STATE.ACCESS_SELECT
         && currentModalState !== MEDIA_ACCESS_MODAL_STATE.INFO
         && currentModalState !== MEDIA_ACCESS_MODAL_STATE.COURSES_STEP_1
         && currentModalState !== MEDIA_ACCESS_MODAL_STATE.REMIND_ME_NO_AUTHORIZED
         && currentModalState !== MEDIA_ACCESS_MODAL_STATE.ONLY_FOR_REGISTERED
         )
         || userHasAccess"
         class="right-side">
        <ng-container>
            <ng-container *ngTemplateOutlet="loginTemplate"></ng-container>
        </ng-container>
    </div>
    <div class="provided">A service provided by
        <b class="link-color"><a [href]="platformUrl" target="_blank">Streamdust</a></b>
    </div>


    <div *ngIf="configModalPublisher.isOpened" class="modal-desc">
        <div class="title-wrapper">
        </div>
        <div class="modal-body">
            <h2 class="title-level-2">{{configModalPublisher.title | localize}}</h2>
            <div [innerText]="configModalPublisher.htmlText"></div>
        </div>
        <div class="bottom-wrapper">
            <div class="absolute-bottom">
                <a (click)="closePopUp()" class="back-link back-info link-color"
                   href="javascript:void(0);">
                    <ng-container
                            *ngIf="currentModalState === MEDIA_ACCESS_MODAL_STATE.LEAD_GENERATION_REGISTERED || !userHasAccess; else LaterRegister">
                        <i class="icon icon-back-arrow"></i>
                        {{(currentModalState === MEDIA_ACCESS_MODAL_STATE.LEAD_GENERATION_REGISTERED ? 'media-access-modal.select-another-access-button' : userHasAccess ? 'media-access-modal.cancel-button-text.not-now' : 'media-access-modal.cancel-button-text.back') | localize}}
                    </ng-container>
                    <ng-template #LaterRegister>
                        {{(currentModalState === MEDIA_ACCESS_MODAL_STATE.LEAD_GENERATION_REGISTERED ? 'media-access-modal.select-another-access-button' : userHasAccess ? 'media-access-modal.cancel-button-text.not-now' : 'media-access-modal.cancel-button-text.back') | localize}}
                        <i class="icon icon-arrow-right"></i>
                    </ng-template>
                </a>
            </div>
        </div>
    </div>
    <button *ngIf="!showLandscapeMode && !showRegistrationOffer"
            (click)="closeAccessModal()"
            class="modal-close">
        <i class="icon icon-close"></i>
    </button>
    <button *ngIf="showLandscapeMode"
            (click)="onCloseLandscapeMode()"
            class="modal-close">
        <i class="icon icon-close"></i>
    </button>
</div>

<ng-template #loginTemplate>
    <ng-container *ngIf="isAuthorized">
        <div class="user-box">
            <div class="user-pic">
                <img *ngIf="!userPhoto" class="avatar" src="/assets/img/mock/no-user-photo.png">
                <img *ngIf="userPhoto" [src]="userPhoto" class="avatar">
            </div>

            <span class="name">{{'media-access-modal.welcom' | localize}},
                <ng-container *ngIf="userFirstName || userLastName">
                    <span class="name">{{userFirstName}} </span>
                    <span class="last-name">{{userLastName}}!</span>
                </ng-container>
                <ng-container *ngIf="!(userFirstName || userLastName)">
                    <span class="name">{{userEmail}}</span>
                </ng-container>
            </span>
            <p>{{'media-access-modal.youAreNot' | localize}}
                <ng-container *ngIf="userFirstName || userLastName">
                    <span class="">{{userFirstName}} </span>
                    <span class="">{{userLastName}}!</span>
                </ng-container>
                <ng-container *ngIf="!(userFirstName || userLastName)">
                    <span class="">{{userEmail}}</span>
                </ng-container>
                ? <a (click)="logout()" href="javascript:void(0)" localize="media-access-modal.logoutLink"></a></p>
        </div>

        <ul class="list-mark-check">
            <li>{{'media-access-modal.loginInfo1' | localize}}</li>
            <li>{{'media-access-modal.loginInfo2' | localize}}</li>
        </ul>
    </ng-container>
    <ng-container *ngIf="!isAuthorized">
        <div class="area-login-header">
            <h3 class="title">{{'media-access-modal.loginTitle' | localize}}</h3>
        </div>
        <div class="area-login-body">
            <ul class="list-mark-check">
                <li>{{'media-access-modal.loginInfo1' | localize}}</li>
                <li>{{'media-access-modal.loginInfo2' | localize}}</li>
            </ul>
        </div>
        <div class="user-login access-form">
            <form (ngSubmit)="loginUser()" [formGroup]="userLoginForm" class="login-form border-form col-12">
                <div class="form-inputs">
                    <div class="form-group">
                        <ano-input
                                [config]="{ inputType: INPUT_TYPES.INPUT, type: 'text', placeholder: 'payment-cards.heidelpay.email-input.placeholder', isRequired: true }"
                                [errors]="userLoginForm.get('email').errors"
                                [invalid]="userLoginForm.get('email').invalid"
                                [isEditMode]="true"
                                [isTouched]="userLoginForm.get('email').touched"
                                formControlName="email"></ano-input>
                    </div>

                    <div class="form-group">
                        <ano-input
                                [config]="{ inputType: INPUT_TYPES.INPUT, type: 'password', placeholder: 'media-access-modal.viewer-register-modal.password-placeholder', isRequired: true }"
                                [errors]="userLoginForm.get('password').errors"
                                [invalid]="userLoginForm.get('password').invalid"
                                [isEditMode]="true"
                                [isTouched]="userLoginForm.get('password').touched"
                                formControlName="password"></ano-input>
                    </div>
                </div>

                <div class="action-buttons login-buttons">
                    <ng-container
                            *ngIf="currentModalState === MEDIA_ACCESS_MODAL_STATE.ONLINE_PAYMENT || currentModalState === MEDIA_ACCESS_MODAL_STATE.VOUCHER_ACTIVATION">
                        <ng-container *ngTemplateOutlet="cancelButton"></ng-container>
                    </ng-container>

                    <div class="col-auto" type="submit">
                        <button class="btn btn-primary btn-sm"
                                localize="media-access-modal.login-form.login-button"></button>
                    </div>
                    <div class="col forgot-password">
                        <a #b2 (click)="goForgotPassword()" class="link-form text-xs link"
                           href="javascript:void(0);">{{'login.button.forgot_password' | localize: b2}}</a>
                    </div>
                </div>
                <p #p1 *ngIf="currentModalState !== MEDIA_ACCESS_MODAL_STATE.COURSES_STEP_1"
                   class="text-xs">
                    {{'media-access-modal.registrationDesc' | localize: p1}}<br>
                    <a #b3 (click)="goRegistration()"
                       href="javascript:void(0);">{{'media-access-modal.registrationLink' | localize: b3}}</a>
                </p>
            </form>
        </div>
    </ng-container>
</ng-template>

<ng-template #voucherTemplate>
    <p>{{'media-access-modal.voucher-form-description' | localize}}</p>

    <div class="voucher-activation access-form">
        <form (ngSubmit)="redeemVoucher()" [formGroup]="voucherForm" class="voucher-form">
            <div class="form-group">
                <ano-input
                        [config]="{ inputType: INPUT_TYPES.INPUT, type: 'text', placeholder: 'media-access-modal.voucher-form.voucher-input-placeholder' }"
                        [errors]="voucherForm.get('code').errors"
                        [invalid]="voucherForm.get('code').invalid"
                        [isEditMode]="true"
                        [isTouched]="voucherForm.get('code').touched"
                        formControlName="code"></ano-input>
            </div>

            <div class="action-buttons">
                <button class="btn btn-success btn-sm voucher-activation-button"
                        type="submit">{{'media-access-modal.voucher-form.redeem-button' | localize}}</button>
            </div>
        </form>
    </div>
</ng-template>
<ng-template #voucherSlotTemplate>
    <p>{{'media-access-modal.voucher-form-description' | localize}}</p>

    <div class="voucher-activation access-form">
        <form (ngSubmit)="redeemVoucher(true)" [formGroup]="voucherForm" class="voucher-form">
            <div class="form-group">
                <ano-input
                        [config]="{ inputType: INPUT_TYPES.INPUT, type: 'text', placeholder: 'media-access-modal.voucher-form.voucher-input-placeholder' }"
                        [errors]="voucherForm.get('code').errors"
                        [invalid]="voucherForm.get('code').invalid"
                        [isEditMode]="true"
                        [isTouched]="voucherForm.get('code').touched"
                        formControlName="code"></ano-input>
            </div>

            <div class="action-buttons">
                <ng-container *ngTemplateOutlet="cancelButton"></ng-container>

                <button class="btn btn-success btn-sm voucher-activation-button"
                        type="submit">{{'media-access-modal.voucher-form.redeem-button' | localize}}</button>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #viewerRegisterTemplate>
    <app-info-panel
            *ngIf="!viewerRegisterForm.dirty && currentModalState !== MEDIA_ACCESS_MODAL_STATE.REGISTRATION_STEP_2"
            [infoPanel]="noRegistrationInfoPanel"></app-info-panel>

    <div class="user-address access-form">
        <ano-loader [isOpen]="loadingService.loading | async"></ano-loader>
        <form (ngSubmit)="registerStep2()" [formGroup]="viewerRegisterForm" class="user-address-form">
            <div class="form-inputs border-form">
                <ng-container
                        *ngIf="voucherRedeemed
                        || currentModalState === MEDIA_ACCESS_MODAL_STATE.REGISTRATION_STEP_2
                        || this.paymentSuccess">
                    <div class="form-group multi-fields">
                        <ano-input
                                [config]="{ inputType: INPUT_TYPES.INPUT, type: 'text', placeholder: 'payment-cards.heidelpay.email-input.placeholder', isRequired: true }"
                                [errors]="viewerRegisterForm.get('email').errors"
                                [invalid]="viewerRegisterForm.get('email').invalid"
                                [isEditMode]="true"
                                [isTouched]="viewerRegisterForm.get('email').touched"
                                class="input-form-field"
                                formControlName="email"></ano-input>
                        <ano-password-validator *ngIf="viewerRegisterForm.dirty"
                                                [password]="viewerRegisterForm.get('password').value"
                                                class="validator-password"></ano-password-validator>
                    </div>
                </ng-container>

                <div class="form-group multi-fields">
                    <ano-input
                            [config]="{ inputType: INPUT_TYPES.INPUT, type: 'password', placeholder: 'media-access-modal.viewer-register-modal.password-placeholder', isRequired: true }"
                            [errors]="viewerRegisterForm.get('password').errors"
                            [invalid]="viewerRegisterForm.get('password').invalid"
                            [isEditMode]="true"
                            [isTouched]="viewerRegisterForm.get('password').touched"
                            class="input-form-field"
                            formControlName="password"></ano-input>
                    <ano-input
                            [config]="{ inputType: INPUT_TYPES.INPUT, type: 'password', placeholder: 'media-access-modal.viewer-register-modal.repeat-password-placeholder', isRequired: true }"
                            [errors]="viewerRegisterForm.get('passwordConfirmation').errors"
                            [invalid]="viewerRegisterForm.get('passwordConfirmation').invalid"
                            [isEditMode]="true"
                            [isTouched]="viewerRegisterForm.get('passwordConfirmation').touched"
                            class="input-form-field"
                            formControlName="passwordConfirmation"></ano-input>
                </div>
                <p *ngIf="userAlreadyExistsError"
                   class="error-message">{{'media-access-modal.viewer-register-modal.user-already-exists-error' | localize}}
                </p>

            </div>

            <div class="privacy-container">
                <div class="privacy-item">
                    <form [formGroup]="privacyPolicyForm" class="privacy-policy-form">
                        <div class="form-check">
                            <input class="form-check-input" formControlName="privacyPolicy" id="privacyPolicyCheckbox"
                                   type="checkbox">
                            <label class="form-check-label" for="privacyPolicyCheckbox">
                                {{'media-access-modal.privacy-policy.i-agree-with' | localize}} <a
                                    (click)="showPopUp($event)"
                                    href="javascript:void(0)">{{'media-access-modal.privacy-policy.right-of-withdrawal' | localize}}</a> {{'media-access-modal.privacy-policy.and-the' | localize}}
                                <a [href]="termsConditions.privacyPolicyUrl"
                                   target="_blank">{{'media-access-modal.privacy-policy.privacy-policy' | localize}}</a> {{'media-access-modal.privacy-policy.privacy-policy-too' | localize}}
                            </label>
                        </div>
                        <p class="form-check-label mb-0 pl-20">
                            {{'media-access-modal.right-of-withdrawal.notes-on' | localize}}
                            <a (click)="showPopUp($event)"
                               href="javascript:void(0)"
                            >
                                {{'media-access-modal.right-of-withdrawal.right-of-withdrawal' | localize}}
                            </a>
                            {{'media-access-modal.right-of-withdrawal.of-the-organizer' | localize}}
                            <ng-container
                                    *ngIf="privacyPolicyForm?.get('privacyPolicy').errors?.required"
                            >
                        <div *ngIf="privacyPolicyForm?.get('privacyPolicy')?.touched"
                             [ngStyle]="{display: privacyPolicyForm?.get('privacyPolicy')?.touched ? 'inline-block' : 'none'}"
                             class="invalid-feedback text-center"
                        >
                            {{'media-access-modal.privacy-policy.accept-error' | localize}}
                        </div>
                        </ng-container>
                        </p>
                    </form>
                </div>

                <div class="privacy-item">
                    <div class="area-login-body registration">
                        <ul class="list-mark-check">
                            <li>{{'media-access-modal.loginInfo1' | localize}}</li>
                            <li>{{'media-access-modal.loginInfo2' | localize}}</li>
                        </ul>
                    </div>
                </div>
            </div>


            <div class="action-buttons reg-offer-footer">
                <a *ngIf="(currentModalState === MEDIA_ACCESS_MODAL_STATE.REGISTRATION_STEP_2 && !userHasAccess)"
                   (click)="goBack()"
                   class="back-link back-info link-color" href="javascript:void(0);">
                    <i class="icon icon-back-arrow"></i>{{'media-access-modal.cancel-button-text.back' | localize}}
                </a>
                <button class="btn btn-success btn-sm" type="submit">
                    {{'media-access-modal.viewer-register-modal.send-button' | localize}}
                </button>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #leadGenerationTemplateAuthorized>
    <app-info-panel [infoPanel]="leadGenerationInfoPanel"></app-info-panel>

    <p>{{'media-access-modal.lead-generation-modal.authorized-details-info' | localize}}</p>

    <div class="lead-generation access-form">
        <form (ngSubmit)="registrationStep1()"
              class="lead-generation-form">
            <div class="action-buttons text-align-center">
                <button class="btn btn-success btn-sm" type="submit">
                    {{'media-access-modal.lead-generation-modal.continue-button' | localize}}
                </button>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #leadGenerationTemplate>
    <!--    <app-info-panel [infoPanel]="leadGenerationInfoPanel"></app-info-panel>-->
    <div *ngIf="userHasAccess && (
            currentModalState === MEDIA_ACCESS_MODAL_STATE.REGISTRATION_STEP_2 ||
            currentModalState === MEDIA_ACCESS_MODAL_STATE.REGISTRATION_STEP_1
        )" class="title-wrapper">
        <h2 class="title-level-3">
            <b>{{'media-access-modal.register-account' | localize}}</b>
        </h2>
    </div>
    <p class="lead-warning-text"><i
            class="icon icon-check voucher-active-text">&nbsp;</i>{{'media-access-modal.lead-generation-warning' | localize}}
    </p>
    <div class="title-wrapper">
        <h2 class="title-level-3">
            <b>{{'media-access-modal.user-info' | localize}}</b>
        </h2>
    </div>
    <div class="lead-generation access-form">
        <form (ngSubmit)="registrationStep1()"
              [formGroup]="leadGenerationForm"
              class="lead-generation-form">
            <div class="form-inputs border-form">
                <div class="form-group multi-fields">
                    <ano-input
                            [config]="{ inputType: INPUT_TYPES.INPUT, type: 'text', placeholder: 'media-access-modal.lead-generation-modal.firstname.placeholder', isRequired: true }"
                            [errors]="leadGenerationForm.get('firstName').errors"
                            [invalid]="leadGenerationForm.get('firstName').invalid"
                            [isEditMode]="true"
                            [isTouched]="leadGenerationForm.get('firstName').touched"
                            class="input-form-field"
                            formControlName="firstName"></ano-input>

                    <ano-input
                            [config]="{ inputType: INPUT_TYPES.INPUT, type: 'text', placeholder: 'media-access-modal.lead-generation-modal.lastname.placeholder', isRequired: true }"
                            [errors]="leadGenerationForm.get('lastName').errors"
                            [invalid]="leadGenerationForm.get('lastName').invalid"
                            [isEditMode]="true"
                            [isTouched]="leadGenerationForm.get('lastName').touched"
                            class="input-form-field"
                            formControlName="lastName"></ano-input>
                </div>

                <div class="form-group multi-fields">
                    <ano-input
                            [config]="{ inputType: INPUT_TYPES.INPUT, type: 'text', placeholder: 'media-access-modal.lead-generation-modal.city.placeholder', isRequired: true }"
                            [errors]="leadGenerationForm.get('city').errors"
                            [invalid]="leadGenerationForm.get('city').invalid"
                            [isEditMode]="true"
                            [isTouched]="leadGenerationForm.get('city').touched"
                            class="input-form-field"
                            formControlName="city"></ano-input>

                    <ano-input
                            [config]="{ inputType: INPUT_TYPES.SELECT,
                                        placeholder: 'media-access-modal.lead-generation-modal.country.placeholder',
                                        selectOptions: countriesList,
                                        size: 6,
                                        isRequired: true,
                                        isAutocomplete: true }"
                            [errors]="leadGenerationForm.get('country').errors"
                            [invalid]="leadGenerationForm.get('country').invalid"
                            [isEditMode]="true"
                            [isTouched]="leadGenerationForm.get('country').touched"
                            class="input-form-field"
                            formControlName="country"></ano-input>
                </div>

                <div class="form-group multi-fields">
                    <ano-input
                            [config]="{ inputType: INPUT_TYPES.INPUT, type: 'text', placeholder: 'media-access-modal.lead-generation-modal.zip.placeholder', isRequired: true }"
                            [errors]="leadGenerationForm.get('postalCode').errors"
                            [invalid]="leadGenerationForm.get('postalCode').invalid"
                            [isEditMode]="true"
                            [isTouched]="leadGenerationForm.get('postalCode').touched"
                            class="input-form-field"
                            formControlName="postalCode"></ano-input>

                    <ano-input
                            [config]="{ inputType: INPUT_TYPES.INPUT,
                             type: 'text',
                                        placeholder: 'media-access-modal.lead-generation-modal.phone.placeholder',
                                        isRequired: false}"
                            [errors]="leadGenerationForm.get('privatePhone').errors"
                            [invalid]="leadGenerationForm.get('privatePhone').invalid"
                            [isEditMode]="true"
                            [isTouched]="leadGenerationForm.get('privatePhone').touched"
                            class="input-form-field"
                            formControlName="privatePhone"></ano-input>
                </div>
                <div class="form-group" *ngIf="customFieldOn && customFieldName">
                    <ano-input
                            [config]="{ inputType: INPUT_TYPES.INPUT, type: 'text', placeholder: customFieldName, isRequired: customFieldOn }"
                            [errors]="leadGenerationForm.get('customFieldValue').errors"
                            [invalid]="leadGenerationForm.get('customFieldValue').invalid"
                            [isEditMode]="true"
                            [isTouched]="leadGenerationForm.get('customFieldValue').touched"
                            class="input-form-field"
                            formControlName="customFieldValue"></ano-input>
                </div>
            </div>

            <div class="action-buttons reg-offer-footer text-align-center">
<!--                                <ng-container *ngTemplateOutlet="cancelButton"></ng-container>-->
                <a *ngIf="(currentModalState === MEDIA_ACCESS_MODAL_STATE.REGISTRATION_STEP_1 && !userHasAccess)"
                   (click)="goBack()"
                   class="back-link back-info link-color" href="javascript:void(0);">
                    <i class="icon icon-back-arrow"></i>{{'media-access-modal.cancel-button-text.back' | localize}}
                </a>
                <button class="btn btn-primary btn-sm" type="submit">
                    {{'media-access-modal.lead-generation-modal.continue-button' | localize}}
                </button>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #cancelButton>
    <a (click)="cancelAccessMethodSelection()" class="back-link text-secondary"
       href="javascript:void(0);">{{(currentModalState === MEDIA_ACCESS_MODAL_STATE.LEAD_GENERATION_REGISTERED ? 'media-access-modal.select-another-access-button' : userHasAccess ? 'media-access-modal.cancel-button-text.not-now' : 'media-access-modal.cancel-button-text.back') | localize}}</a>
</ng-template>


