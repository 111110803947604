import {STREAM_STATUS} from '@src/app/components/streamdust-player/constants/status';
import {StreamDomainRestrictionsModalComponent} from './stream-domain-restrictions-modal/stream-domain-restrictions-modal.component';
import {
    IListOfDomainRestriction,
    IDomainRestrictionData
} from './../../../../models/stream-domain-restrictions.model';
import {StreamDomainRestrictionsService} from './../../../../services/stream-domain-restrictions/stream-domain-restrictions.service';
import {MEDIA_TYPE} from './../../../../components/streamdust-player/constants/mediaType';
import {StreamService} from '@src/app/services/stream-metadata/stream.service';
import {IFormConfig} from './../../../../services/stream-metadata/stream.service';
import {AutoDestroyService} from './../../../../services/auto-destroy-service/auto-destroy.service';
import {takeUntil} from 'rxjs/operators';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {INPUT_TYPES} from 'ui-elements';
import {Component, OnInit, ViewChild, TemplateRef} from '@angular/core';
import {LocalizationProvider} from 'ui-elements';
import {UserService} from '@src/app/services/user/user.service';
import {IUserProfile} from '@src/app/pages/user-profile/components/profile/services/profile.service';

@Component({
    selector: 'app-stream-domain-restrictions',
    templateUrl: './stream-domain-restrictions.component.html',
    styleUrls: ['./stream-domain-restrictions.component.scss'],
    providers: [AutoDestroyService]
})
export class StreamDomainRestrictionsComponent implements OnInit {
    STREAM_STATUS = STREAM_STATUS;
    public domainRestrictionData: IDomainRestrictionData[];
    private confirmTextTpl = this.localizationProvider.getByKey('groupsOfLinks.deleteText');
    public confirmText = '';
    public INPUT_TYPES = INPUT_TYPES;
    public formConfig: IFormConfig;
    public mediaId: string;
    public isDisable: boolean;
    private user: IUserProfile;

    @ViewChild('confirm', {static: false}) confirm: TemplateRef<any>;

    constructor(
        private localizationProvider: LocalizationProvider,
        public dialog: MatDialog,
        private destroy$: AutoDestroyService,
        private streamService: StreamService,
        private streamDomainRestrictionsService: StreamDomainRestrictionsService,
        private userService: UserService
    ) {
    }

    ngOnInit(): void {
        this.streamService.stream$.subscribe(stream => {
            if (!stream) {
                return;
            }
            this.isDisable = stream.media?.status?.stream === STREAM_STATUS.FINISHED && !stream.media?.hasRecording;
            this.mediaId = stream.media?.id;
            this.setConfigs();
        });

        this.userService.userProfile$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
            this.user = user;
        });
    }

    private setConfigs(): void {
        this.formConfig = this.streamService.getStreamFormConfig(false, false, MEDIA_TYPE.STREAM, true, this.isDisable);
        this.streamDomainRestrictionsService.getListOfAllowedDomains(this.mediaId)
            .pipe(takeUntil(this.destroy$))
            .subscribe((res) => {
                if (res) {
                    this.domainRestrictionData = res;
                }
            });
    }

    public deleteLink(item: IListOfDomainRestriction): void {
        if (this.isDisable) {
            return;
        }

        this.confirmText = this.confirmTextTpl.replace('{linkName}', item.mediaId);
        const dialogRef = this.dialog.open(this.confirm);
        dialogRef.afterClosed()
            .pipe(takeUntil(this.destroy$))
            .subscribe((confirm: boolean) => {
                this.confirmText = '';
                if (confirm) {
                    this.streamDomainRestrictionsService.deleteStreamAllowedDomains(item.domainId)
                        .pipe(takeUntil(this.destroy$))
                        .subscribe(res => {
                            if (res.success) {
                                this.domainRestrictionData = this.domainRestrictionData.filter((domainItem) => {
                                    return domainItem.domainId !== item.domainId;
                                });
                            }
                        });
                }
            });
    }

    onToggle(event, data: IListOfDomainRestriction) {
        this.streamDomainRestrictionsService.saveStreamAllowedDomains({
            mediaId: data.mediaId,
            active: event.value
        }).subscribe();
    }

    openModal(data?: any): void {
        if (this.isDisable) {
            return;
        }

        if (!data) {
            data = {
                user: this.user,
                hasOtherData: false
            };
        } else {
            data.user = this.user;
            data.hasOtherData = true;
        }
        const dialogRef = this.dialog.open(StreamDomainRestrictionsModalComponent, {
            panelClass: 'stream-links-modal',
            hasBackdrop: true,
            data: data,
        } as MatDialogConfig);
        dialogRef.afterClosed()
            .pipe(takeUntil(this.destroy$))
            .subscribe((dialogResults) => {
                if (dialogResults?.domainItem?.length) {
                    this.domainRestrictionData.push(dialogResults.domainItem[0]);
                }
                if (dialogResults?.newDomain && dialogResults.domainId) {
                    const currentItem = this.domainRestrictionData.find((item) => {
                        return item.domainId === dialogResults.domainId;
                    });
                    if (currentItem) {
                        currentItem.allowedDomain = dialogResults.newDomain;
                    }
                }
            });
    }

}
