import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class ChatState implements OnDestroy {
    public chatEnabledState$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public chatActiveState$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private chatActive = false;
    public chatOverlayState$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private chatOverlay = false;
    public currentPosition$: BehaviorSubject<{ x: number, y: number }> = new BehaviorSubject<{ x: number, y: number }>(null);

    constructor() {
    }

    public closeChat(): void {
        this.chatActive = false;
        this.chatActiveState$.next(this.chatActive);
    }

    public toggleChat(): void {
        this.chatActive = !this.chatActive;
        this.chatActiveState$.next(this.chatActive);
    }

    public toggleOverlay(): void {
        this.chatOverlay = !this.chatOverlay;
        this.chatOverlayState$.next(this.chatOverlay);
    }

    public setOverlayState(state: boolean): void {
        this.chatOverlay = state;
        this.chatOverlayState$.next(this.chatOverlay);
    }

    ngOnDestroy(): void {
    }

}
