import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs/internal/BehaviorSubject";

@Injectable({
    providedIn: 'root'
})
export class PlayerControlsService {

    public resizeState$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private resizeState = false;


    constructor() {
    }

    public toggleResize(): void {
        this.resizeState = !this.resizeState;
        this.resizeState$.next(this.resizeState);
    }
}
