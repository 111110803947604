import {Inject, Injectable, InjectionToken} from '@angular/core';
import {HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {catchError, tap, timeout} from 'rxjs/operators';
import {Router} from '@angular/router';
import {SnackBarService} from 'ui-elements';

export const DEFAULT_TIMEOUT = new InjectionToken<number>('defaultTimeout');

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    private readonly urlsWithoutToasts: string[] = [
        '/api/v1/video/url',
        '/api/v1/payment/donation', //TODO remove, when payment with NON-EUR currency will be done
        '/open/api/teaser/',
        '/open/api/trailer/',
        '/open/api/video/filters',
        '/open/api/video/list',
        '/open/api/video/options/timerange',
        '/api/v1/purchase/media/list/publisher',
        '/api/v1/purchase/media/list/publisher/timerange',
        '/open/api/media/group/access/',
        '/api/v1/login',
        '/api/v1/mock',
        '/open/api/overlay',
        '/open/api/course',
        '/open/api/presentation',
        '/open/api/media/',
        '/api/v1/media/reminders',
        '/api/v1/statistic/publisher/data',
        '/api/v1/payment',
        '/api/v1/purchase/media/list',
        '/api/v1/purchase/media/total/country',
        '/api/v1/purchase/media/total',
        '/open/api/survey',
        '/api/v1/survey/start',
        '/api/v1/survey/publish'
    ];

    private readonly notNavigateTo404 = [
        '/api/v1/video/url'
    ];


    constructor(
        private snackBarService: SnackBarService,
        private router: Router,
    ) {
    }


    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const selectOneUrl = (element) => req.url.includes(element);
        return next.handle(req).pipe(
            tap(
                (response) => {
                    if ((response instanceof HttpResponse) && (req.method === 'POST')) {
                        if (response.body) {
                            if (response.body.success === true
                                // && !Object.keys(response.body.results).length
                                && !req.body.hasOwnProperty('paging') && !this.urlsWithoutToasts.some(selectOneUrl)) {
                                this.snackBarService.showInfo('Success');
                            }
                            if (response.body.success === false && response.status !== 200 && !this.urlsWithoutToasts.some(selectOneUrl)) {
                                this.snackBarService.openError('Server Error');
                            }
                        }
                        // else {
                        //     this.snackBarService.openError('Service is temporary unavailable. Please try again later');
                        // }
                    }
                    if ((response instanceof HttpResponse) && (req.method === 'PUT' || req.method === 'DELETE')) {
                        if (response.body) {
                            if (response.body.success === true && !this.urlsWithoutToasts.some(selectOneUrl)) {
                                this.snackBarService.showInfo('Success');
                            }
                            if (response.body.success === false
                                && !this.urlsWithoutToasts.some(selectOneUrl)
                                && response.body.errorKey !== 'STREAM_RUNNING'
                            ) {
                                this.snackBarService.openError('Server Error');
                            }
                        }
                        // else {
                        //     this.snackBarService.openError('Service is temporary unavailable. Please try again later');
                        // }
                    }
                    return;
                }
            ),
            catchError((err) => {
                if (err.status === 500 && req.method !== 'GET' && !this.urlsWithoutToasts.some(selectOneUrl)) {
                    this.snackBarService.openError('Failure');
                }

                if (err.status === 403) {
                    this.router.navigate(['/403']);
                }

                if (err.status === 404 && !this.notNavigateTo404.some(selectOneUrl)) {
                    this.router.navigate(['/404']);
                }
                return throwError(err);
            })
        );
    }

    private goToMaintanancePage(): void {
        this.router.navigate(['/maintanance']);
    }
}
