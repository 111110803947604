import { Pipe, PipeTransform } from '@angular/core';
import {cutString} from '@src/app/utils/string.util';


@Pipe({
  name: 'stringCut'
})
export class StringCutPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    return cutString(value, 0, 8);
  }

}
