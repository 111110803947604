<section class="section">
	<div class="container">
		<div class="panel panel-md mb-30">
			<div class="card-body pl-0 pr-0">
				<div class="plan-box registration">
					<div class="row justify-content-center">
						<div class="col-md-6 col-lg-5 col-xl-4" (click)="changeState(stateRegistartion.viewer)">
							<div class="plan price-plan">
								<div class="panel-header-text bg-cyan">
									<i class="icon icon-user"></i>
									{{ 'registration.viewer.cards.title' | localize }}
								</div>
								<div class="item-body">
									<ul class="plan-list mb-30">
										<li>{{ 'registration.viewer.cards.firstItem' | localize }}</li>
										<li>{{ 'registration.viewer.cards.secondItem' | localize }}</li>
										<li>{{ 'registration.viewer.cards.thirdItem' | localize }}</li>
									</ul>
									<button class="btn btn-primary btn-sm">{{ 'registration.viewer.cards.button' | localize }}</button>
								</div>
							</div>
						</div>
						<div class="col-md-6 col-lg-5 col-xl-4" (click)="changeState(stateRegistartion.publisher)">
							<div class="plan price-plan">
								<div class="panel-header-text bg-warning">
									<i class="icon icon-camera"></i>
									{{ 'registration.publisher.cards.title' | localize }}
								</div>
								<div class="item-body">
									<ul class="plan-list mb-30">
										<li>{{ 'registration.publisher.cards.firstItem' | localize }}</li>
										<li>{{ 'registration.publisher.cards.secondItem' | localize }}</li>
										<li>{{ 'registration.publisher.cards.thirdItem' | localize }}</li>
										<li>{{ 'registration.publisher.cards.fourthItem' | localize }}</li>
										<li>{{ 'registration.publisher.cards.fifthItem' | localize }}</li>
									</ul>
									<button class="btn btn-primary btn-sm">{{ 'registration.publisher.cards.button' | localize }}</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
