<section class="section section-dashboard">
    <div class="container">
        <app-subscription-upgrade *ngIf="subscriptionUpgrade"
                                  [agreementText]="(routerData | async)?.agreementText"
                                  [paymentCardsListControls]="paymentCardsListControls"
                                  (cancelPayment$)="cancelPayment($event)"></app-subscription-upgrade>
        <app-group-get-access *ngIf="groupAccess"
                              [paymentCardsListControls]="paymentCardsListControls"
                              (cancelPayment$)="cancelPayment($event)"
        >
        </app-group-get-access>
    </div>
</section>
