import {Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ITableConfig, TableComponent} from 'ui-elements';
import {IMediaMetadata, IStreamRecord} from '@src/app/models/stream.model';
import {Pagination} from '@src/app/models/sorting.model';
import {INPUT_TYPES} from 'ui-elements';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {StreamService} from '@src/app/services/stream-metadata/stream.service';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import {FormBuilder} from '@angular/forms';
import {DateHelper} from '@src/app/utils/date.helper';
import {filter, takeUntil, tap} from 'rxjs/operators';
import {IRecord} from '@src/app/models/response.model';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {NEW_ID_PARAM} from '@src/app/utils/custom-validators.util';
import {saveAs} from 'file-saver/FileSaver';
import {ApiService} from "ui-elements";
import {HttpClient} from "@angular/common/http";
import {getFileNameFromResponseContentDisposition} from "@src/app/utils/file-name-from-content-disposition.util";
import {MatDialog} from "@angular/material/dialog";

@Component({
    selector: 'app-stream-records',
    templateUrl: './stream-records.component.html',
    styleUrls: ['./stream-records.component.css']
})
export class StreamRecordsComponent implements OnInit {
    public tableConfig: ITableConfig<IStreamRecord>;
    @ViewChild('actionsTmpl', {static: true}) public actionsTmpl: TemplateRef<any>;
    @ViewChild('statusTmpl', {static: true}) public statusTmpl: TemplateRef<any>;
    @ViewChild('linkTmpl', {static: true}) public linkTmpl: TemplateRef<any>;
    @ViewChild('dateTmpl', {static: true}) public dateTmpl: TemplateRef<any>;
    @ViewChild('sizeTpl', {static: true}) public sizeTpl: TemplateRef<any>;
    @ViewChild('tableComponent', {static: true}) public table: TableComponent;
    @ViewChild('modalRecord', {static: true}) public modalRecord: TemplateRef<any>;
    @Input() searchQuery: string = '';
    @Input() searchFilters: IStreamRecord;
    public pagination: Pagination = new Pagination();
    public data: IRecord[];
    public page = 0;
    public INPUT_TYPES = INPUT_TYPES;
    public searchForm = this.fb.group({
        search: [''],
    });
    public streamId: string;
    public recordName = '';
    public recordUrl = '';

    @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: false}) sort: MatSort;

    public config = {
        'license': 'PTRL1-mkACG-K3ttT-v74XR-6djew-DAJMR',
        'sourceURL': '',
        'autoPlay': false,
        'volume': '75',
        'mute': false,
        'loop': false,
        'audioOnly': false,
        'uiShowQuickRewind': true,
        'uiShowDurationVsTimeRemaining': true,
        'uiShowBitrateSelector': true,
        'uiQuickRewindSeconds': '30',
        'width': '640',
        'responsive': 'false',
        'posterFrameURL': '',
        'uiPosterFrameFillMode': 'stretch',
        'uiShowChannelBug': 'false',
        'uiChannelBugURL': '',
        'uiChannelBugLocation': 'topleft',
        'uiDVRMinDuration': 180000,
        'uiEnableDVR': false,
        'liveStartTimestamp': ''
    };

    constructor(
        public streamsService: StreamService,
        public destroy$: AutoDestroyService,
        private fb: FormBuilder,
        private activatedRoute: ActivatedRoute,
        private apiService: ApiService,
        private http: HttpClient,
        public dialog: MatDialog,
        private router: Router
    ) {
    }

    ngOnInit() {
        this.activatedRoute.parent.params.pipe(
            takeUntil(this.destroy$),
            tap(({id}: Params) => {
                this.streamId = id;
            }),
            filter(({id}: Params) => id !== NEW_ID_PARAM))
            .subscribe(() => {
                this.tableConfig = {
                    dataField: 'data',
                    searchFn: (sortParams, pagingParams) => {
                        return this.streamsService.getRecords(this.streamId, this.searchQuery, pagingParams, sortParams);
                    },
                    columns: [
                        {
                            name: 'Datum',
                            sortField: 'DATE',
                            tmpl: this.dateTmpl
                        }, {
                            name: 'Aufzeichnung',
                            sortField: 'URL',
                            tmpl: this.linkTmpl
                        }, {
                            name: 'Größe',
                            // sortField: 'SIZE',
                            tmpl: this.sizeTpl
                        },
                        {
                            name: 'Aktionen',
                            tmpl: this.actionsTmpl
                        }
                    ]

                };
            });
    }

    public saveFile(url: string): void {
        this.http.get(url).pipe(takeUntil(this.destroy$))
            .subscribe(res => {
                // saveAs(
                //     res.body,
                //     getFileNameFromResponseContentDisposition(res),
                // );
            });
    }

    public formatDate(timestamp: bigint): string {
        return DateHelper.formatDateTime(timestamp);
    }

    public lessThanOneDay(timestamp: bigint): boolean {
        return timestamp < new Date().getTime() - (86400000);
    }

    public search(event: string): void {
        this.searchQuery = event;
        this.table.resetTable();
        this.table.refreshData({});
    }

    public openRecord(record: IStreamRecord): void {
        this.router.navigate([`${record.id.recordingId}/edit`], {relativeTo: this.activatedRoute});
    }

}
