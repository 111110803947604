import {Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {StreamService} from '@src/app/services/stream-metadata/stream.service';
import {MEDIA_TYPE} from '@src/app/components/streamdust-player/constants/mediaType';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import {map, switchMap, takeUntil} from 'rxjs/operators';
import {CmsService} from 'ui-elements';
import {
    IAllCountryPurchases,
    IPurchase, IPurchaseByCountry, PURCHASE_TYPE
} from '@src/app/pages/stream-page/components/stream-revenue/components/country-revenues/model/revenues';
import {of} from 'rxjs/internal/observable/of';
import {IPagedResponse, IPagedResponseResults, PagingParams, SortParam} from '@src/app/models/response.model';
import {ITableConfig, TableComponent} from 'ui-elements';
import {INameId} from '@src/app/models/core.model';
import {UserService} from '@src/app/services/user/user.service';
import {ITimerange} from '@src/app/models/stream.model';
import {IListFilterItem} from 'ui-elements/lib/types/types';

@Component({
    selector: 'app-country-revenues',
    templateUrl: './country-revenues.component.html',
    styleUrls: ['./country-revenues.component.sass'],
    providers: [AutoDestroyService]
})
export class CountryRevenuesComponent implements OnInit {
    public PURCHASE_TYPE = PURCHASE_TYPE;
    @ViewChild('tableComponent', {static: false}) public table: TableComponent;
    @ViewChild('typeTmpl', {static: true}) public typeTmpl: TemplateRef<any>;
    @ViewChild('countryTmpl', {static: true}) public countryTmpl: TemplateRef<any>;
    @ViewChild('purchasesAmountTmpl', {static: true}) public purchasesAmountTmpl: TemplateRef<any>;
    @ViewChild('priceVatTmpl', {static: true}) public priceVatTmpl: TemplateRef<any>;
    @ViewChild('netTotalCostTmpl', {static: true}) public netTotalCostTmpl: TemplateRef<any>;
    @ViewChild('netTotalCostSumTmpl', {static: true}) public netTotalCostSumTmpl: TemplateRef<any>;
    @ViewChild('priceVatSumTmpl', {static: true}) public priceVatSumTmpl: TemplateRef<any>;
    @ViewChild('grossTotalCostSumTmpl', {static: true}) public grossTotalCostSumTmpl: TemplateRef<any>;
    @ViewChild('tableSummary', {static: true}) public tableSummary: TemplateRef<any>;
    @Input() mediaType: MEDIA_TYPE;
    @Input() mediaId: string;
    @Input() timeRange: ITimerange;
    public allPurchases: IAllCountryPurchases;
    public data: ICountryPurchases[] = [];
    public countriesList: INameId[];
    public tableConfig: ITableConfig<IPurchase>;
    public dropdownOpened = false;
    public selectedCountry: INameId;
    public selectedCountryPurchase: ICountryPurchases;
    public configLoaded = false;
    public filters: IListFilterItem[];

    constructor(
        private streamService: StreamService,
        private destroy$: AutoDestroyService,
        private cmsService: CmsService,
        public userService: UserService
    ) {
    }

    ngOnInit(): void {
        this.initTable();
    }

    private initTable(): void {
        this.tableConfig = {
            dataField: 'data',
            withoutPaging: true,
            tableSummary: this.tableSummary,
            searchFn: (sortParams, pagingParams) => {
                return this.streamService.getPurchasesByCountry(this.timeRange, this.mediaType, this.mediaId, this.filters)
                    .pipe(takeUntil(this.destroy$), switchMap((res) => {
                        if (!res) {
                            return;
                        }
                        this.allPurchases = res;
                        this.countriesList = [];
                        this.toCountriesList(res);
                        this.toCountryPurchases(res);
                        return of<IPagedResponse<IPurchase>>({
                            success: true,
                            errorKey: null,
                            message: '',
                            results: {
                                data: this.toPagedResponseResults(sortParams)
                            }
                        });
                    }));
            },
            columns: [
                {
                    name: 'stream.revenue.country.country',
                    sortField: 'country',
                    tmpl: this.countryTmpl,
                    width: 123,
                    class: 'left no-white-space'
                },
                {
                    name: 'stream.revenue.country.type',
                    tmpl: this.typeTmpl,
                    width: 100,
                    class: 'left no-white-space'
                },
                {
                    name: 'stream.revenue.country.soldTickets',
                    sortField: 'purchasesAmount',
                    tmpl: this.purchasesAmountTmpl,
                    width: 111,
                    class: 'right no-white-space'
                },
                {
                    name: 'stream.revenue.country.vatPerTicket',
                    // dataField: 'priceVat',
                    tmpl: this.priceVatTmpl,
                    class: 'right no-white-space',
                    width: 93,
                },
                {
                    name: 'stream.revenue.country.netPerNicket',
                    tmpl: this.netTotalCostTmpl,
                    class: 'right no-white-space',
                    width: 93
                },
                {
                    name: 'stream.revenue.country.totalNet',
                    tmpl: this.netTotalCostSumTmpl,
                    class: 'right no-white-space',
                    width: 93
                },
                {
                    name: 'stream.revenue.country.plusVat',
                    tmpl: this.priceVatSumTmpl,
                    class: 'right no-white-space',
                    width: 98
                },
                {
                    name: 'stream.revenue.country.totalGross',
                    tmpl: this.grossTotalCostSumTmpl,
                    class: 'right no-white-space',
                    width: 99
                },
            ]
        };
    }

    private toPagedResponseResults(sortParams: SortParam[]): IPagedResponseResults<IPurchase> {
        let purchases: IPurchase[] = [];
        if (this.selectedCountry.id === 'all') {
            this.data.forEach(countryPurchase => countryPurchase.purchases.forEach(purchase => purchases.push(purchase)));
        } else {
            purchases = this.data.find(purchase => purchase.country === this.selectedCountry.id).purchases;
        }

        if (sortParams && sortParams.length) {
            purchases = purchases.sort(((a, b) => sortParams[0].order === 'ASC' ? (a[sortParams[0].field] > b[sortParams[0].field] ? 1 : -1) : (a[sortParams[0].field] > b[sortParams[0].field] ? -1 : 1)));
        }

        return {
            sort: null,
            paging: {
                page: 0,
                itemsOnPage: purchases.length,
                items: purchases.length
            },
            items: purchases
        };
    }

    private toCountryPurchases(allPurchases: IAllCountryPurchases): void {
        this.data = [];
        if (!allPurchases?.purchasesByCountry?.length) {
            return;
        }
        allPurchases.purchasesByCountry.forEach((purchase: IPurchaseByCountry) => {
            const data: ICountryPurchases = {
                country: purchase.country,
                purchases: [],
                purchasesAmount: 0,
                grossTotalCostSum: 0,
                netTotalCostSum: 0

            };
            const country = this.cmsService.countriesList.find(_country => _country.key === purchase.country)?.value;
            if (!country) {
                return;
            }

            if (purchase.generalPurchases) {
                purchase.generalPurchases.type = PURCHASE_TYPE.GENERAL;
                purchase.generalPurchases.country = country as string;
                data.purchasesAmount += purchase.generalPurchases.purchasesAmount;
                data.purchases.push(purchase.generalPurchases);
            }

            if (purchase.discountPurchases) {
                purchase.discountPurchases.forEach(discountPurchase => {
                    discountPurchase.type = PURCHASE_TYPE.DISCOUNT;
                    discountPurchase.country = country as string;
                    data.purchasesAmount += discountPurchase.purchasesAmount;
                    data.purchases.push(discountPurchase);
                });
            }

            if (purchase.paymentSlotPurchases) {
                purchase.paymentSlotPurchases.forEach(paymentSlotPurchases => {
                    paymentSlotPurchases.type = PURCHASE_TYPE.SLOT;
                    paymentSlotPurchases.country = country as string;
                    data.purchasesAmount += paymentSlotPurchases.purchasesAmount;
                    data.purchases.push(paymentSlotPurchases);
                });
            }

            if (purchase.donationPurchases) {
                purchase.donationPurchases.type = PURCHASE_TYPE.DONATIONS;
                purchase.donationPurchases.country = country as string;
                data.purchasesAmount += purchase.donationPurchases.purchasesAmount;
                data.purchases.push(purchase.donationPurchases);
            }

            // data.purchasesAmount = data.purchases.length;
            data.grossTotalCostSum = purchase.grossTotalCostSum;
            data.netTotalCostSum = purchase.netTotalCostSum;
            this.data.push(data);
        });
    }

    private toCountriesList(data: IAllCountryPurchases): void {
        this.countriesList = [{id: 'all', name: 'ALL', selected: true}];
        this.countriesList = this.countriesList.concat(data.purchasesByCountry.map((purchase) => (
            {
                id: purchase.country,
                name: this.cmsService.countriesList.find(country => country.key === purchase.country)?.value as string
            })));
        this.selectedCountry = this.selectedCountry || this.countriesList[0];
    }

    public toggleFilter(): void {
        this.dropdownOpened = !this.dropdownOpened;
    }

    public filter(country: INameId): void {
        this.selectedCountry = country;
        this.countriesList.forEach(_country => _country.selected = false);
        this.selectedCountryPurchase = this.data.find(purchase => purchase.country === country.id);
        country.selected = true;
        this.toggleFilter();
        setTimeout(() => {
            this.table.resetTableData();
            this.table.refreshData({});
        });
    }

    public refreshTable(filters): void {
        this.filters = filters;
        this.table.refreshData({});
    }
}

interface ICountryPurchases {
    country: string;
    purchases: IPurchase[];
    purchasesAmount?: number;
    grossTotalCostSum?: number;
    netTotalCostSum?: number;

}
