import {StreamLinksModalComponent} from './components/stream-links/stream-links-modal/stream-links-modal.component';
import {StreamLinksComponent} from './components/stream-links/stream-links.component';
import {StreamDomainRestrictionsModalComponent} from './components/stream-domain-restrictions/stream-domain-restrictions-modal/stream-domain-restrictions-modal.component';
import {StreamDomainRestrictionsComponent} from './components/stream-domain-restrictions/stream-domain-restrictions.component';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StreamPageComponent} from '@src/app/pages/stream-page/stream-page.component';
import {StreamMetadataComponent} from '@src/app/pages/stream-page/components/stream-metadata/stream-metadata.component';
import {SharedModule} from '@src/app/modules/shared/shared.module';
import {StreamCouponsComponent} from './components/stream-coupons/stream-coupons.component';
import {CouponsModalComponent} from './components/stream-coupons/coupons-modal/coupons-modal.component';
import {StreamPreviewComponent} from './components/stream-preview/stream-preview.component';
import {StreamOverlaysComponent} from './components/stream-overlays/stream-overlays.component';
import {StreamOverlayComponent} from './components/stream-overlay/stream-overlay.component';
import {StreamLandingComponent} from './components/stream-landing/stream-landing.component';
import {StreamRecordsComponent} from './components/stream-records/stream-records.component';
import {StreamRecordComponent} from './components/stream-record/stream-record.component';
import {MediaOverviewComponent} from './components/media-overview/media-overview.component';
import {StreamTrailerComponent} from './components/stream-trailer/stream-trailer.component';
import {StreamTeaserComponent} from './components/stream-teaser/stream-teaser.component';
import {StreamSurveyComponent} from './components/stream-survey/stream-survey.component';
import {StreamRevenueComponent} from './components/stream-revenue/stream-revenue.component';
import {StreamStatisticComponent} from './components/stream-statistic/stream-statistic.component';
import {StreamEncoderDataComponent} from './components/stream-encoder-data/stream-encoder-data.component';
import {StreamViewerLinkComponent} from './components/stream-viewer-link/stream-viewer-link.component';
import {StreamThumbnailComponent} from './components/stream-thumbnail/stream-thumbnail.component';
import {StreamRecordInfoComponent} from './components/stream-record-info/stream-record-info.component';
import {ChatState} from '@src/app/state/chat-state';
import {TimelineComponent} from './components/media-overview/components/timeline/timeline.component';
import {PanelComponent} from './components/media-overview/components/timeline/panel/panel.component';
import {SurveyComponent} from './components/stream-survey/components/survey/survey.component';
import {InfoComponent} from './components/stream-survey/components/info/info.component';
import {DonationState} from '@src/app/state/donation-state';
import {ChatService} from '@src/app/services/chat/chat.service';
import {CountryRevenuesComponent} from './components/stream-revenue/components/country-revenues/country-revenues.component';
import {OverlayInfoComponent} from './components/stream-overlays/components/overlay-info/overlay-info.component';
import {StreamWorldMapState} from '@src/app/state/stream-world-map';
import {MediaLeadsComponent} from './components/media-leads/media-leads.component';
import {StreamMarketingToolsOverlaysComponent} from './components/stream-marketing-tools-overlays/stream-marketing-tools-overlays.component';
import {StreamPresentationComponent} from './components/stream-presentation/stream-presentation.component';
import {PresentationState} from '@src/app/state/presentation-state';
import {StreamOverlayLocationComponent} from '@src/app/pages/stream-page/components/stream-overlay/stream-overlay-location/stream-overlay-location.component';
import { StreamRemindComponent } from './components/stream-remind/stream-remind.component';
import { StreamInfoComponent } from './components/media-overview/components/stream-info/stream-info.component';
import { QuickAccessComponent } from './components/media-overview/components/quick-access/quick-access.component';
import { OverlaysTableComponent } from './components/stream-overlays/components/overlays-table/overlays-table.component';
import { SurveyTableComponent } from './components/stream-survey/components/survey-table/survey-table.component';
import { PresentationsTableComponent } from './components/stream-presentation/components/presentations-table/presentations-table.component';
import { ReminderComponent } from './components/stream-remind/components/reminder/reminder.component';
import { VisibilitySettingsComponent } from './components/media-overview/components/visibility-settings/visibility-settings.component';
import { MediaDescriptionComponent } from './components/media-overview/components/media-description/media-description.component';
import { MediaVisibilityComponent } from './components/media-overview/components/media-visibility/media-visibility.component';
import { StreamThumbnailPreviewComponent } from './components/stream-thumbnail/components/stream-thumbnail-preview/stream-thumbnail-preview.component';
import { StreamThumbnailFallbackComponent } from './components/stream-thumbnail/components/stream-thumbnail-fallback/stream-thumbnail-fallback.component';

@NgModule({
    declarations: [
        StreamPageComponent,
        StreamMetadataComponent,
        StreamCouponsComponent,
        CouponsModalComponent,
        StreamPreviewComponent,
        StreamOverlaysComponent,
        StreamOverlayComponent,
        StreamLandingComponent,
        StreamRecordsComponent,
        StreamRecordComponent,
        MediaOverviewComponent,
        StreamTrailerComponent,
        StreamTeaserComponent,
        StreamSurveyComponent,
        StreamLinksComponent,
        StreamLinksModalComponent,
        StreamDomainRestrictionsComponent,
        StreamDomainRestrictionsModalComponent,
        StreamRevenueComponent,
        StreamStatisticComponent,
        StreamEncoderDataComponent,
        StreamViewerLinkComponent,
        StreamThumbnailComponent,
        StreamRecordInfoComponent,
        TimelineComponent,
        PanelComponent,
        SurveyComponent,
        InfoComponent,
        CountryRevenuesComponent,
        OverlayInfoComponent,
        MediaLeadsComponent,
        StreamMarketingToolsOverlaysComponent,
        StreamPresentationComponent,
        StreamOverlayLocationComponent,
        StreamRemindComponent,
        StreamInfoComponent,
        QuickAccessComponent,
        OverlaysTableComponent,
        SurveyTableComponent,
        PresentationsTableComponent,
        ReminderComponent,
        VisibilitySettingsComponent,
        MediaDescriptionComponent,
        MediaVisibilityComponent,
        StreamThumbnailPreviewComponent,
        StreamThumbnailFallbackComponent,
    ],
    exports: [
        StreamMetadataComponent,
        StreamTeaserComponent,
        StreamTrailerComponent
    ],
    imports: [
        CommonModule,
        SharedModule
    ],
    providers: [
        ChatState,
        PresentationState,
        DonationState,
        ChatService,
        StreamWorldMapState
    ]
})
export class StreamPageModule {
}
