import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-agreement-box',
  templateUrl: './agreement-box.component.html',
  styleUrls: ['./agreement-box.component.scss']
})
export class AgreementBoxComponent implements OnInit {
  @Input() text: string;
  @Input() error = false;
  @Input() colSize = 6;
  agreed = false;
  @Output() change$: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  public toggleTermsAgreed(): void {
    this.agreed = !this.agreed;
    this.error = false;
    this.change$.emit(this.agreed);
  }


}
