<ng-container *ngIf="!isInvitation">
    <div class="panel">
        <div class="panel-header pb-0">
            <h2 class="title-lg"><b>{{'subaccounts.title' | localize}}</b></h2>
        </div>
    </div>

    <div class="card bg-white">
        <div class="card-body pb-0">
            <div class="row justify-content-between align-items-end">
                <div class="col-xl-7">
                    <div class="title-box">
                        <h2 class="title-level-3">
                            {{ (isCreateMode ? 'subaccounts.create' : 'subaccounts.edit') | localize}}
                        </h2>
                    </div>
                    <app-info-panel
                        *ngIf="subaccount?.subAccount?.type === SUB_ACCOUNT_TYPE.INVITED"
                        [infoPanel]="InfoPanelConfig.invitedSubAccount">
                    </app-info-panel>
                    <app-bordered-form
                        [form]="formConfig.form"
                        [fieldsConfig]="formConfig.formFields"
                        [defaultForm]="true"
                        [disabledForm]="subaccount?.subAccount?.type === SUB_ACCOUNT_TYPE.INVITED">
                    </app-bordered-form>
                    <app-bordered-form
                        [form]="passwordFormConfig.form"
                        [fieldsConfig]="passwordFormConfig.formFields"
                        [defaultForm]="true"
                        [disabledForm]="subaccount?.subAccount?.type === SUB_ACCOUNT_TYPE.INVITED">
                    </app-bordered-form>
                    <div class="validator-container">
                        <ano-password-validator
                                *ngIf="isCreateMode || passwordFormConfig.form.get('password').value"
                                [hideTitle]="true"
                                [password]="passwordFormConfig.form.get('password').value">
                        </ano-password-validator>
                    </div>
                </div>
                <div class="col-auto">
                    <div class="buttons-container mr-20 mb-20">
                        <button class="btn btn-secondary back-button" (click)="goToSubaccounts()">
                            {{'common.button.back' | localize}}
                        </button>
                        <button class="btn btn-success" (click)="submit()" *ngIf="isCreateMode || subaccount?.subAccount?.type === SUB_ACCOUNT_TYPE.DIRECT">
                            {{'common.button.apply' | localize}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <ano-loader [isOpen]="loading"></ano-loader>
    </div>
</ng-container>
<app-sub-account-invitation *ngIf="isInvitation"></app-sub-account-invitation>
