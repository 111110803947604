import { Directive, ElementRef, Input, OnInit} from '@angular/core';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import {LocalizationProvider} from 'ui-elements';

@Directive({
  selector: '[localize]',
  providers: [AutoDestroyService]
})

export class LocalizeDirective implements OnInit {
  @Input('localize') localizeKey: string;

  constructor(
      private autoDestroyService: AutoDestroyService,
      private elem: ElementRef,
      private localizationProvider: LocalizationProvider
  ) { }

  ngOnInit() {
    this.elem.nativeElement.innerText += this.localizationProvider.getByKey(this.localizeKey);
  }

}
