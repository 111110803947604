// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.a
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  domain: 'https://cms.streamdust.ano-services.com/',
  backendApiHost: '',
  // backendApiHost: 'https://api.cms.streamdust.ano-services.com',
  webSocketHost: 'wss://a360:360a@api.cms.streamdust.ano-services.com/',
  localization: true,
  googleApiKey: 'AIzaSyAuGBpt8--2zAZnJC1zmbowA-TdNM8TeIA',
  cmsHost: 'api/v1'
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
