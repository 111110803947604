<div class="card bg-white bg-transparent">
    <div  *ngIf="showEmbeddedLink" class="card-header pl-0 pr-0 pb-15">
        <h3 class="mb-0"><b>{{'stream.viewerLink' | localize}}</b></h3>
    </div>
    <div *ngIf="!showEmbeddedLink" class="card-header bg-white pb-0">
        <h3 class="title-level-3 mb-0"><b>{{'stream.viewerLink' | localize}}</b></h3>
    </div>
    <div class="card-body bg-white pt-25">
        <form class="border-form">
            <div class="input-group">
                <input class="form-control form-control-lg iframe-padding text-primary" type="text" readonly
                       [value]="linkValue">
                <div class="input-group-append text-primary">
                    <span class="input-group-text open-in-new-link" (click)="openLink(linkValue)">
                        <i class="icon icon-new-window text-primary"></i>
                    </span>
                    <span class="input-group-text copy" (click)="copyValue(linkValue, false)">
                        <i class="icon icon-copy text-primary"></i>
                    </span>
                </div>
            </div>
        </form>
    </div>
</div>

<div class="card bg-white bg-transparent" *ngIf="showEmbeddedLink">
    <div class="card-header pl-0 pr-0 pt-0 pb-15">
        <h3 class="mb-0"><b>{{'stream.embeddedLink' | localize}}</b></h3>
    </div>
    <div class="card-body bg-white pt-25">
        <p>{{'stream.embeddedLink.description' | localize}}</p>
        <form class="border-form">
            <div class="input-group">
                <input readonly [value]="iframeValue" class="form-control form-control-lg iframe-padding text-primary">
                <div class="input-group-append text-primary">
                    <span class="input-group-text open-in-new-link" (click)="mailTo(iframeValue)">
                        <i class="icon icon-mail text-primary"></i>
                    </span>
                    <span class="input-group-text copy" (click)="copyValue(iframeValue, true)">
                        <i class="icon icon-copy text-primary"></i>
                    </span>
                </div>
            </div>
        </form>
    </div>
</div>

<!--TODO revert after release-->
<!--<div class="card bg-white bg-transparent" *ngIf="showEmbeddedLink">-->
<!--    <div class="card-header pl-0 pr-0 pt-0 pb-15">-->
<!--        <h3 class="mb-0"><b>{{'stream.chatIframeLink' | localize}}</b></h3>-->
<!--    </div>-->
<!--    <div class="card-body bg-white pt-25">-->
<!--        <p>{{'stream.chatIframeLink.description' | localize}}</p>-->
<!--        <form class="border-form">-->
<!--            <div class="input-group">-->
<!--                <input readonly [value]="chatIframeValue" class="form-control form-control-lg iframe-padding text-primary">-->
<!--                <div class="input-group-append text-primary">-->
<!--                    <span class="input-group-text open-in-new-link" (click)="mailTo(chatIframeValue)">-->
<!--                        <i class="icon icon-mail text-primary"></i>-->
<!--                    </span>-->
<!--                    <span class="input-group-text copy" (click)="copyValue(chatIframeValue, true)">-->
<!--                        <i class="icon icon-copy text-primary"></i>-->
<!--                    </span>-->
<!--                </div>-->
<!--            </div>-->
<!--        </form>-->
<!--    </div>-->
<!--</div>-->
