import { Injectable } from '@angular/core';
import {ApiService, IResponse} from 'ui-elements';
import {Observable} from 'rxjs';
import {API_URLS_APP, UrlGenerator} from '@src/app/constants/api-urls.constant';

@Injectable({
  providedIn: 'root'
})
export class MediaDescriptionService {

  constructor(
      private apiService: ApiService
  ) { }

  public save(mediaId: string, description: string): Observable<IResponse<any>> {
    return this.apiService.put(UrlGenerator.generate(API_URLS_APP.DESCRIPTION_SAVE, {mediaId}), {description});
  }
}
