<div class="panel panel-md" appScrollToFirstError>
    <div class="panel-body bg-white padding-bottom-30">
        <ng-container *ngIf="showForm">
            <div class="col-md-9">
                <div class="section-panel">
                    <div class="section-panel-header">
                        <h4>
                            <b>{{form.get('id').value ? ( 'overlay.location.edit' | localize ) : ( 'overlay.location.create' | localize )}}</b>
                        </h4>
                    </div>
                    <div class="section-panel-body">
                        <div class="section-panel-body">
                            <app-bordered-form
                                    [defaultForm]="true"
                                    [form]="form"
                                    [isClearButton]="true"
                                    [fieldsConfig]="fieldsConfig"
                                    [disabled]="(streamService.stream$ | async)?.media?.status?.stream === STREAM_STATUS.FINISHED && !(streamService.stream$ | async)?.media?.hasRecording"
                            ></app-bordered-form>

                            <div>
                                <agm-map class="agm-map"
                                         *ngIf="form.get('country').value"
                                         [latitude]="googleMap.latitude"
                                         [longitude]="googleMap.longitude"
                                         [disableDefaultUI]="true"
                                         [zoom]="googleMap.zoom">

                                    <div class="row row-padding-3">
                                        <div class="col-auto">
                                            <i class="icon icon-zoom-plus" (click)="zoomPlus()"></i>
                                        </div>
                                        <div class="col-auto">
                                            <i class="icon icon-zoom-minus" (click)="zoomMinus()"></i>
                                        </div>
                                    </div>

                                    <agm-circle [latitude]="googleMap.latitude"
                                                [longitude]="googleMap.longitude"
                                                [radius]="form.get('radius').value ? form.get('radius').value * 1000 : 1"
                                                [fillColor]="'#0d54a9'"
                                    ></agm-circle>
                                    <agm-overlay
                                            [latitude]="googleMap.latitude"
                                            [longitude]="googleMap.longitude"
                                    >
                                        <i class="icon icon-location2 streamdust-marker"></i>
                                    </agm-overlay>

                                </agm-map>

                                <div *ngIf="!form.get('country').value" class="placeholder-location">
                                    <span class="text-xlg">{{'overlay.location.map.placeholder' | localize}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div>
                    <div class="row row-padding-10 action-buttons">
                        <div class="col-auto">
                            <div class="form-group">
                                <button class="btn btn-secondary"
                                        (click)="cancel()">{{'common.button.close' | localize}}</button>
                            </div>
                        </div>
                        <div class="col-auto">
                            <div class="form-group">
                                <button class="btn btn-success"
                                        (click)="save()">{{'common.button.apply' | localize}}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>

        <div class="section-panel">
            <div class="section-panel-header">
                <h4><b>{{'stream.overlay.location.table.title' | localize}}</b></h4>
            </div>
            <ano-table class="" #tableComponent
                       [tableConfig]="tableConfig"
                       [addButton]="true"
                       [disabledAddButton]="disabledAddButton"
                       [disabledInputs]="!id"
                       [systemMessage]="!id && 'stream.overlay.destination.table.noDestination'"
                       [sortOrder]="1"
                       [addButtonText]="'common.button.create'"
                       [defaultSortField]="'COUNTRY'"
                       [withSearch]="true"
                       [searchInputType]="'text'"
                       (search$)="search($event)"
                       (addItem$)="addNewItem()"
            >
            </ano-table>
        </div>
    </div>

</div>


<ng-template #actionsTmpl let-data="rowData">
    <b class="text-primary">
        <i (click)="goToEdit(data)"
           class="icon icon-edit cursor-pointer"></i>
        <ng-container
                *ngIf="!((streamService?.stream$ | async)?.media?.status?.stream === STREAM_STATUS.FINISHED && !(streamService?.stream$ | async)?.media?.hasRecording)">
            |
        </ng-container>
    </b>
    <b class="text-primary">
        <i *ngIf="!((streamService.stream$ | async)?.media?.status?.stream === STREAM_STATUS.FINISHED && !(streamService?.stream$ | async)?.media?.hasRecording)"
        (click)="delete(data, $event)" class="icon icon-trash text-danger cursor-pointer"></i>
    </b>
</ng-template>
<ng-template #statusTmpl let-data="rowData" class="ss-test">
    <div class="long-text">
        <app-toggle
                [value]="data.active"
                (toggleEmit)="onToggle($event, data)"
        ></app-toggle>
    </div>
</ng-template>
<ng-template #radiusTmpl let-data="rowData">
    {{data.radius}} km
</ng-template>
<ng-template #countryTmpl let-data="rowData">
    {{ data.country.name }}
</ng-template>

<ng-template #confirm let-dialogRef="dialogRef">
    <ano-modal [dialogRef]="dialogRef" [title]="'overlay.location.modal.delete.title' | localize"
               [isConfirmation]="true">
        <div [innerHTML]="confirmText"></div>
    </ano-modal>
</ng-template>

<ng-template #latitudeTmpl let-data="rowData">
    <a class="g-icon" (click)="navigateToMap(data, $event)" target="_blank">
        <i class="icon icon-location2"></i>
    </a>
</ng-template>
