import { Routes } from '@angular/router';
import { HeaderComponent } from '@src/app/components/header/header.component';
import { ReactiveFormsModule } from '@angular/forms';
import {FeedSectionComponent} from '@src/app/components/feed-section/feed-section.component';
import {TimeagoModule} from 'ngx-timeago';
import { InfoBoxComponent } from '@src/app/components/info-box/info-box.component';
import {BubbleCounterComponent} from '@src/app/components/bubble-counter/bubble-counter.component';

export const commonDeclarations: any[] = [
  HeaderComponent,
  FeedSectionComponent,
  InfoBoxComponent,
  BubbleCounterComponent
];

export const commonImports: any[] = [
  ReactiveFormsModule.withConfig({warnOnNgModelWithFormControl: 'never'}),
  TimeagoModule.forRoot(),
];

export const commonExports: any[] = [
  HeaderComponent,
  ReactiveFormsModule,
  FeedSectionComponent,
  TimeagoModule,
  InfoBoxComponent,
  BubbleCounterComponent
];

export const routes: Routes = [
];
