<div class="box-streams">
    <app-media-list-header
            [config]="config"
            [filters]="config?.filters"
            [activeQuery]="activeQuery"
            (filter$)="doFilter($event)">
    </app-media-list-header>

    <div class="empty-stream-list">
        <p *ngIf="!(loadingService.loading | async) && showNoFilterResultsBlock && !showPlaceholder">
            {{'media-list.media-lists.no-data-by-filter-media' | localize}}
        </p>
        <p *ngIf="showPlaceholder">
            {{'media-list.media-lists.no-data-media' | localize}}
        </p>
        <ano-loader class="list-loader" *ngIf="loadingService.loading | async"
                    [isOpen]="loadingService.loading | async" [theme]="2"></ano-loader>
    </div>

    <ng-container *ngFor="let mediaList of mediaLists">
        <ng-container *ngIf="mediaList?.media?.items?.length">
            <span [class.small-media-list]="config?.smallLists">
                    <app-media-list #mediaLists
                                    [implicitFilters]="mediaList.implicitFilters"
                                    [customClick]="mediaList?.groupView"
                                    [config]="{
                                        hideEmptyList: true,
                                        fetchMethod: mediaList.fetchMethod,
                                        basicSkeleton: config.basicSkeleton,
                                        headerConfig: {
                                            filtration: false,
                                            cssClass: config.cssClass
                                        },
                                        loadMore: LOAD_TYPE.BUTTON,
                                        cardConfig: {
                                            backgroundClass: config.cardBackgroundCssClass,
                                            skeletonBackgroundClass: config.skeletonBackgroundClass,
                                            greyscale: mediaList.thumbnailGrayScale,
                                            elements: config?.mediaCardElements,
                                            hidePrice: config?.hideMediaPrice,
                                            groupView: mediaList?.groupView,
                                            streamHeaderCssClass: config?.cardConfig?.streamHeaderCssClass,
                                            skeleton: {
                                                headerCssClass: config?.cardConfig?.skeleton?.headerCssClass
                                            }
                                        },
                                        queryParams: config?.queryParams
                                    }"
                                    (customClick$)="customClick($event, mediaList)"
                                    [title]="mediaList?.title"
                                    [data]="mediaList?.media">
                    </app-media-list>
            </span>
        </ng-container>
    </ng-container>
</div>
