<div class="modal-wrapper bg-white" *ngIf="!isAnswered || !isAbove18">
    <ng-container *ngIf="!isAnswered">
        <p>{{'media-age-restriction.no-access' | localize}}</p>

        <div class="buttons-group">
            <button class="btn btn-secondary btn-sm"
                    (click)="answer(false)">{{'media-age-restriction.no-answer' | localize}}</button>
            <button class="btn btn-primary btn-sm"
                    (click)="answer(true)">{{'media-age-restriction.yes-answer' | localize}}</button>
        </div>
    </ng-container>

    <ng-container *ngIf="isAnswered && !isAbove18">
        <p class="no-margin">{{'media-age-restriction.no-access' | localize}}</p>
    </ng-container>
</div>
