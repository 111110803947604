export function urlWithProtocol(url: string): string {
    if (!url) {
        return;
    }
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
        url = 'https://' + url;
    }

    return url;
}

export function windowOpen(url, name?, specs?) {
    if (!url.match(/^https?:\/\//i)) {
        url = 'http://' + url;
    }
    return window.open(url, name, specs);
}
