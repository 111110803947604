import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
    ISubscriptionTariffPlan,
    TARIFF_PLAN_PERIOD
} from '@src/app/components/tariff-plan-cards-list/subscription-plan-card/subscription-plan-card.component';
import {IPaymentCard, IPaymentCardsListControls} from '@src/app/components/payment-cards-list/payment-cards-list.component';
import {
    IHeidelpayCard,
    IHeidelpayPaymentDetails
} from '@src/app/components/payment-cards-list/heidelpay-payment/heidelpay-payment.component';
import {ITariffPlanConfig, TARIFF_PLAN_TYPES} from '@src/app/components/tariff-plan-cards-list/tariff-plan-cards-list.component';
import {IStartPaymentReq, PAYMENT_FAIL_MESSAGES, PaymentService} from '@src/app/services/payment/payment.service';
import {ActivatedRoute, Router} from '@angular/router';
import {LoadingService} from '@src/app/services/loading/loading.service';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import {PAYMENT_STATUS, SubscriptionManageService} from '@src/app/services/subscription-manage/subscription-manage.service';
import {LocalizationProvider} from 'ui-elements';
import {mergeMap, take, takeUntil} from 'rxjs/operators';
import {UserService} from '@src/app/services/user/user.service';

@Component({
    selector: 'app-subscription-upgrade',
    templateUrl: './subscription-upgrade.component.html',
    styleUrls: ['./subscription-upgrade.component.css']
})
export class SubscriptionUpgradeComponent implements OnInit {
    @Input() agreementText: string;

    @Input() paymentCardsListControls: IPaymentCardsListControls;

    subscriptionToBuyId: string;
    groupToBuyId: string;

    subscriptionToBuy: ISubscriptionTariffPlan;
    tariffPlansConfig: ITariffPlanConfig = {
        planType: TARIFF_PLAN_TYPES.SUBSCRIPTION,
        planPeriod: TARIFF_PLAN_PERIOD.MONTH,
        hideActionButton: true
    };

    failedCardId: string;
    transactionFailReason: PAYMENT_FAIL_MESSAGES;
    returnUrl: string;
    tariffPlanDetails: string;
    tariffPlanTitle: string;

    @Output() cancelPayment$ = new EventEmitter<string>();

    constructor(
        private paymentService: PaymentService,
        private router: Router,
        private loadingService: LoadingService,
        private activatedRoute: ActivatedRoute,
        private destroy$: AutoDestroyService,
        private subscriptionManageService: SubscriptionManageService,
        private localizationProvider: LocalizationProvider,
        private userService: UserService,
    ) {
    }

    ngOnInit(): void {
        this.loadingService.loadingStart();

        this.paymentCardsListControls.addNewCard.customHandler = (paymentDetails: IHeidelpayPaymentDetails) => this.startSubscription(paymentDetails.card, true);

        this.activatedRoute.params
            .pipe(
                mergeMap(params => {
                    this.updateSubscriptionConfig(params.period);
                    this.subscriptionToBuyId = params.id;
                    return this.subscriptionManageService.getSubscriptionTariff(this.subscriptionToBuyId, this.tariffPlansConfig.planType)
                        .pipe(takeUntil(this.destroy$));
                }),
                takeUntil(this.destroy$)).subscribe(tariff => {
            this.subscriptionToBuy = tariff;
            this.tariffPlanTitle = this.getTariffPlanTitle();
            this.loadingService.loadingEnd();
        });
    }

    updateSubscriptionConfig(period: TARIFF_PLAN_PERIOD): void {
        this.tariffPlansConfig.planType = this.getTariffPlanType();
        this.tariffPlansConfig.iconName = this.getTariffPlanIconName();
        this.tariffPlansConfig.planPeriod = period;
        this.returnUrl = this.getTariffPlanReturnUrl();
        this.tariffPlanDetails = this.getTariffPlanDetails();
    }

    getTariffPlanType(): TARIFF_PLAN_TYPES {
        return this.router.url.includes('subscription') ? TARIFF_PLAN_TYPES.SUBSCRIPTION :
            this.router.url.includes('bandwidth') ? TARIFF_PLAN_TYPES.BANDWIDTH : TARIFF_PLAN_TYPES.STORAGE_SPACE;
    }

    getTariffPlanIconName(): string {
        return this.tariffPlansConfig.planType === TARIFF_PLAN_TYPES.BANDWIDTH ? 'bandwidth' : 'memoryspace';
    }

    getTariffPlanReturnUrl(): string {
        return this.tariffPlansConfig.planType === TARIFF_PLAN_TYPES.SUBSCRIPTION ? 'user/subscription-manage' : 'user/space-storage-and-bandwidth';
    }

    getTariffPlanDetails(): string {
        return this.tariffPlansConfig.planType === TARIFF_PLAN_TYPES.BANDWIDTH ? 'subscription-upgrade.increase-bandwidth.description' :
            this.tariffPlansConfig.planType === TARIFF_PLAN_TYPES.STORAGE_SPACE ? 'subscription-upgrade.increase-storage.description' : '';
    }

    getTariffPlanTitle(): string {
        return this.localize(this.subscriptionManageService.getSubscriptionTypeTranslate(this.subscriptionToBuy.type)) + ' '
            + this.localize(this.subscriptionManageService.getSubscriptionPlanTypeTranslate(this.subscriptionToBuy.subscriptionPlanType));
    }

    startSubscription(paymentCard: IPaymentCard | IHeidelpayCard, heidelpayCard = false): void {
        if (!heidelpayCard) {
            this.loadingService.loadingStart();
        }

        const externalCardId = paymentCard?.id;

        const req: IStartPaymentReq = {
            subscriptionTariffId: this.subscriptionToBuyId,
            returnUrl: `https://${window.location.hostname}/payment-details`,
            successUrl: `https://${window.location.hostname}/user/subscription-manage`,
            failUrl: `https://${window.location.hostname}${this.router.url}`,
            subscriptionPeriod: this.tariffPlansConfig.planPeriod,
            subscriptionTariffType: this.tariffPlansConfig.planType
        };
        //
        // if (!heidelpayCard) {
        //     req.userCardId = externalCardId;
        // }

        if (heidelpayCard) {
            Object.assign(req, { paymentMethod: {
                    saveCard: true,
                    method: (paymentCard as IHeidelpayCard).method,
                    resourceId: (paymentCard as IHeidelpayCard).id}
            });

            if (req.paymentMethod.method === 'card') {
                req.paymentMethod.paymentCard = this.paymentService.getNewPaymentCardData(paymentCard as IHeidelpayCard);
            }
            // Object.assign(req, {saveCard: true, paymentCard: this.paymentService.getNewPaymentCardData(paymentCard as IHeidelpayCard)});
        } else {
            Object.assign(req, { paymentMethod: {
                    method: 'card',
                    resourceId: (paymentCard as IPaymentCard)?.externalCardId,
                    userCardId: externalCardId}
            });
        }

        this.subscriptionManageService.subscriptionStart(req).pipe(take(1)).subscribe(response => {
            switch (response?.results?.result?.paymentStatus) {
                case PAYMENT_STATUS.FAILED: {
                    this.paymentService.failedTransactionInfo.next({
                        failedCardId: response?.results?.result?.externalCardId,
                        transactionFailReason: response?.results?.result?.reason || PAYMENT_FAIL_MESSAGES.FAILED
                    });
                    break;
                }
                case PAYMENT_STATUS.PENDING: {
                    if (response?.results?.result?.redirectUrl) {
                        window.location.href = response.results.result.redirectUrl;
                    }
                    break;
                }
                case PAYMENT_STATUS.SUCCESS: {
                    this.router.navigate(['user/subscription-manage']);
                    this.userService.refreshUserProfile();
                    break;
                }
            }
            this.loadingService.loadingEnd();
        });
    }

    cancelPayment(): void {
        this.cancelPayment$.emit(this.returnUrl);
    }

    localize(key: string): string {
        if (!key) {
            return '';
        }
        return this.localizationProvider.getByKey(key);
    }

}
