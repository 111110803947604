import { Injectable } from '@angular/core';
import {API_URLS_APP} from '@src/app/constants/api-urls.constant';
import {environment} from '@src/environments/environment';
import {WebSocketConnector} from '@src/app/services/web-socket-connector/web-socket-connector.service';
import {filter, map} from 'rxjs/operators';
import {IResponse} from '@src/app/models/response.model';
import {ISurveyStatus} from '@src/app/services/survey/survey.service';
import {Subject} from 'rxjs/internal/Subject';
import {MEDIA_TYPE} from '@src/app/components/streamdust-player/constants/mediaType';
import {GenerateUtil} from "@src/app/utils/generate.util";
import {BehaviorSubject} from 'rxjs';
import {Observable} from 'rxjs/internal/Observable';

const MARKETING_SOCKET = `${environment.webSocketHost}${API_URLS_APP.ROOT_URL}${API_URLS_APP.MARKETING_SOCKET}`;
@Injectable({
  providedIn: 'root'
})
export class MarketingService {
  private static instance: MarketingService;
  public connectionSocket: Subject<any>;
  private id = GenerateUtil.uuidv4();
  private connection: BehaviorSubject<IResponse<{data: ISurveyStatus}>> = new BehaviorSubject<IResponse<{data: ISurveyStatus}>>(null);

  constructor() { }

    public static getInstance(): MarketingService {
      if (!MarketingService.instance) {
        MarketingService.instance = new MarketingService();
      }
      return MarketingService.instance;
    }

    public connectToSocket(mediaId: string, mediaType: MEDIA_TYPE): Observable<IResponse<{data: ISurveyStatus}>> {
        if (!this.connectionSocket) {
            this.connectionSocket = new WebSocketConnector().connect(MARKETING_SOCKET, null, [mediaId, mediaType], true);
            this.connectionSocket.pipe(map(
                (response: MessageEvent): IResponse<{data: ISurveyStatus}> => {
                    return JSON.parse(response.data);
                }
            )).subscribe((response) => {
                this.connection.next(response);
            });
        }
        return this.connection.asObservable();
    }

    public events(): Subject<IResponse<{data: ISurveyStatus}>> {
        return <Subject<IResponse<{data: ISurveyStatus}>>>this.connectionSocket.pipe(map(
            (response: MessageEvent): IResponse<{data: ISurveyStatus}> => {
                return JSON.parse(response.data);
            }
        ));
    }
}
