<div class="content">
    <div class="panel panel-md">
        <div class="panel-header">
            <h2 class="title-lg"><b>{{'vod.list.title' | localize}}</b></h2>
            <p class="page-description">{{'vod.list.description' | localize}}</p>
        </div>
        <div class="panel-body padding-0">
            <div class="section-panel">
                <div class="section-panel-body">
                    <div class="vod-actions align-items-center">
                        <div class="flex flex-row justify-content-between groups-dropdown-filter blue-theme-filter">
<!--                            <ano-time-range-filter [config]="timeRangeFilterConfig" (filterByTimeRange$)="filterByTimeRange($event)"></ano-time-range-filter>-->
                            <ano-datepicker [showAllOption]="true" [startFromAll]="true" (changeDate)="filterByTimeRange($event)"></ano-datepicker>
                            <ano-search class="border-form input-form" (search$)="search($event)"></ano-search>
                        </div>
                    </div>
                    <span class="small-media-list">
                        <app-media-list
                                [activeQuery]="vodQuery"
                                [implicitFilters]="implicitFilters"
                                [config]="{
                                    fetchMethod: videoOnDemandListConfig?.fetchMethod,
                                    addMediaButton: {
                                        show: true,
                                        text: 'media-list.create-new-vod',
                                        navigateLink: (userService.userProfile$ | async)?.dataLimits?.storageSpace?.limitReached || (userService.userProfile$ | async)?.dataLimits?.bandwidth?.limitReached ? 'user/space-storage-and-bandwidth' :'video-on-demand/new'
                                    },
                                    loadMore: LOAD_TYPE.BUTTON,
                                    cardConfig: {
                                        publisherView: true,
                                        streamHeaderCssClass: 'video-on-demand-card-header',
                                        actions: {
                                            remove: true,
                                            removeConfirmTitle: 'vod.remove-media.confirm-title',
                                            removeConfirmBody: 'vod.remove-media.confirm-body'
                                        },
                                        elements: {
                                            group: true
                                        },
                                        skeleton: {
                                            headerCssClass: 'header-sm'
                                        }
                                    }
                                }"
                                (removeMediaItem$)="removeVideo($event.id)"
                                [customClick]="true"
                                (customClick$)="gotToVodDashboard($event)"
                                [data]="videos">
                    </app-media-list>
                    </span>
                </div>
            </div>
        </div>

        <div class="panel-body bg-white padding-top-20">
            <div class="section-panel">
                <div class="section-panel-header">
                    <h4><b>{{'vod.deactivatedVideos.title' | localize}}</b></h4>
                    <p class="text-sm">{{'vod.deactivatedVideos.description' | localize}}</p>
                </div>
                <div class="section-panel-body streams-table">
                    <ano-table #tableComponent [tableConfig]="disabledVideosConfig" class="finished-livestreams"
                               [withSearch]="true"
                               (search$)="tableSearch($event)"
                               (searchByYear$)="searchByYear($event)"
                               [defaultSortField]="'DATE'"
                               [sortOrder]="-1"
                               [showDatepicker]="true"
                               [startFromAll]="true"
                               (timeRangeFilter$)="timeRangeFilter($event)"
                               [yearsFilters]="streamsYears"
                    ></ano-table>
                    <ng-template #actionsTmpl let-data="rowData">
                        <div class="table-actions-list">
                            <b class="text-primary">
                                <i (click)="navigateToVideo(data.id)"
                                   class="icon icon-edit cursor-pointer"></i> |
                            </b>
                            <b class="text-primary">
                                <i (click)="confirmDeleteVideo(data)"
                                class="icon icon-trash text-danger cursor-pointer"></i>
                            </b>
                        </div>
                    </ng-template>
                    <ng-template #statusTmpl let-data="rowData">
                                            <span [ngStyle]="{color: 'red' }">
                                                {{ 'player.status.deactivated' | localize}}
                                            </span>
                    </ng-template>
                    <ng-template #titleTmpl let-data="rowData">
                        <div class="long-text">
                                                <span>
                                                    {{data.title}}
                                                </span>
                        </div>
                    </ng-template>
                    <ng-template #priceTmpl let-data="rowData">
                        {{data.price?.amount ? (data.price | currency) : 'stream.payment-types.free' | localize}}
                    </ng-template>
                    <ng-template #dateTmpl let-data="rowData">
                        {{(data.startedAt || data.startTimestamp) | dateTimeFormat: 'DD MMM YYYY, HH:mm'}}
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #confirm let-dialogRef="dialogRef">
    <ano-modal [dialogRef]="dialogRef" [title]="'Livestream löschen'" [isConfirmation]="true">
        {{confirmText}}
    </ano-modal>
</ng-template>
