<div class="row row-padding-0">
    <div class="required-box second-info-box col-{{colSize}}">
        <div class="invalid-feedback"><ng-container *ngIf="error">{{'error.requiredAgreement' | localize}}</ng-container></div>
        <div class="form-check">
            <input [ngModel]="agreed"
                   [ngModelOptions]="{standalone: true}"
                   (ngModelChange)="toggleTermsAgreed()"
                   type="checkbox" class="form-check-input" id="privacyPolicy">
            <label for="privacyPolicy"
                   class="form-check-label">{{text | localize}}</label>
        </div>
    </div>
</div>
