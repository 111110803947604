<div class="panel-header text-center">
    <h2 *ngIf="!hideHeader"><b>{{'subscription-manage.plan-period.title' | localize}}</b></h2>
    <div class="btn-group btn-group-toggle" data-toggle="buttons">
        <label class="btn btn-primary"
               [class.active]="currentPeriod === TARIFF_PLAN_PERIOD.MONTH"
               (click)="setPlanPeriod(TARIFF_PLAN_PERIOD.MONTH)">
            <input type="radio" name="options" id="option1"
                   checked> {{'subscription-manage.plan-period.per-month' | localize}}
        </label>
        <label class="btn btn-primary"
               [class.active]="currentPeriod === TARIFF_PLAN_PERIOD.YEAR"
               (click)="setPlanPeriod(TARIFF_PLAN_PERIOD.YEAR)">
            <input type="radio" name="options"
                   id="option2"> {{'subscription-manage.plan-period.per-year' | localize}}
        </label>
    </div>
</div>
