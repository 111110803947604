import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  commonDeclarations,
  commonExports,
  commonImports
} from '@src/app/pages/login-page/login-page.common';
import { SharedModule } from '@src/app/modules/shared/shared.module';

@NgModule({
  declarations: [
    ...commonDeclarations,
  ],
  imports: [
    ...commonImports,
    CommonModule,
    SharedModule
  ],
  exports: [
    ...commonExports,
  ],
})
export class LoginPageModule { }
