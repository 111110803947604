import { Injectable } from '@angular/core';
import { API_URLS_APP } from '@src/app/constants/api-urls.constant';
import { ApiService } from 'ui-elements';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(
    private apiService: ApiService
  ) { }

  public postAccountLanguage(language: string) {
    if (language === 'KEY'){
        return;
    }
    return this.apiService.post(API_URLS_APP.ACCOUNT_LANGUAGE, { language })
  }
}
