import {Component, OnInit} from '@angular/core';
declare var $: any;
@Component({
    selector: 'app-landing-page',
    templateUrl: './landing-page.component.html',
    styleUrls: ['./landing-page.component.sass']
})
export class LandingPageComponent implements OnInit {

    constructor(
    ) {
    }

    ngOnInit(): void {
        $(document).ready(function() {
            $('.slider-document').bxSlider({
                nextText: '',
                prevText: ''
            });
        });
    }

}
