<section class="section section-dashboard">
    <div class="container">
        <div class="wrapper-content bg-light">
            <div class="box-streams">
                <div class="box-live-streams-header">
                    <h1><b>{{'common.controlCenter' | localize}}&nbsp;&gt;</b></h1>
                    <h2 class="stream-title"><b>{{ stream?.media?.title }}</b></h2>
                </div>
            </div>
            <div class="row row-padding-10 flex-md-row flex-column-reverse">
                <div class="col">
                    <div class="wrap" *ngIf="!isLoading">
                        <router-outlet></router-outlet>
                    </div>
                </div>
                <div class="col-md-auto col-lg-auto col-sm-12">

                    <app-side-nav [config]="sideNavConfig" [rightNav]="true"></app-side-nav>
                </div>
            </div>
        </div>
    </div>
<!--    <app-chat *ngIf="chatEnabled || (!chatEnabled && (chatState.chatActiveState$ | async))"-->
<!--              [isOwner]="true"-->
<!--              [title]="(streamService?.stream$ | async)?.media?.title"-->
<!--              [mediaId]="(streamService?.stream$ | async)?.media?.id"></app-chat>-->
</section>
<ng-template #encoderModal let-dialogRef="dialogRef">
    <ano-modal [dismissButtonText]="'common.button.ok'"
               [title]="('encoder.data.title' | localize)+ ' <span class=light>'+ ('encoder.data.subtitle' | localize) +'</span>'"
               [dialogRef]="dialogRef"
               [withoutSubmit]="true"
               dismissButtonClass="btn-primary" loadingText="encoder.data.loading">
        <p>{{'encoder.data.description' | localize}}</p>
        <app-stream-encoder-data [stream]="stream"></app-stream-encoder-data>
    </ano-modal>
</ng-template>
