<div class="container">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <!--<li *ngIf="!items || (items && !items.lenght)" class="breadcrumb-item active">-->
                <!--&lt;!&ndash;<ng-container *ngIf="!items || !items.lenght">&ndash;&gt;-->
                    <!--<a routerLink="streams">Streams</a>-->
                <!--&lt;!&ndash;</ng-container>&ndash;&gt;-->
                <!--&lt;!&ndash;<ng-container *ngIf="items && items.lenght">&ndash;&gt;-->
                    <!--&lt;!&ndash;<a>Startseit</a>&ndash;&gt;-->
                <!--&lt;!&ndash;</ng-container>&ndash;&gt;-->
            <!--</li>-->
            <li class="breadcrumb-item" *ngFor="let item of items; let last=last;" [class.active]="last || items.length === 1">
                <a *ngIf="!last" [routerLink]="item.url">{{ item.label }}</a>
                <ng-container *ngIf="last">
                    {{item.label}}
                </ng-container>
            </li>
            <!--<li class="breadcrumb-item"><a href="#">Aktuelle Streams</a></li>-->
            <!--<li class="breadcrumb-item active" aria-current="page">Kontrollzentrum Streamtitel</li>-->
        </ol>
    </nav>
</div>