import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {UserService} from '@src/app/services/user/user.service';
import {DateHelper} from '@src/app/utils/date.helper';
import { environment } from '@src/environments/environment';
import {IMessage, MESSAGE_TYPE} from '@src/app/services/chat/chat.service';
import {take, takeUntil} from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.sass']
})
export class MessageComponent implements OnInit {
  @ViewChild('confirm', {static: false}) confirm: TemplateRef<any>;
  @Input() message: IMessage;
  @Input() serviceMessage: string;
  @Input() serviceMessageDate: any;
  @Input() isOwner: boolean;
  @Input() isEmbedded: boolean;
  public serviceUsername = 'chat.serviceMessage';
  public environment = environment;
  public readonly defaultAvatar = 'assets/img/mock/avatar.png';
  public readonly serviceAvatar = 'assets/img/avatars/system_avatar_2.png';
  public MESSAGE_TYPE = MESSAGE_TYPE;
  public messageContent: string[];
  public isImageFile = false;
  public fileName = '';
  @Output() deleteMessage: EventEmitter<string> = new EventEmitter<string>();
  @Output() pinMessage$: EventEmitter<string> = new EventEmitter<string>();

  constructor(
      public userService: UserService,
      public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.messageContent = this.message.content.split('\n');
    this.parseURL();
  }

  parseURL(): void {
    if (this.message.type === MESSAGE_TYPE.FILE && this.isValidURL(this.message.content)) {
      const urlObject = new URL(this.message.content);
      const pathSegments = urlObject.pathname.split('/');
      this.fileName = decodeURIComponent(pathSegments[pathSegments.length - 1]);
      const fileExtension = this.fileName.split('.').pop().toLowerCase();
      this.isImageFile = this.checkImageExtension(fileExtension);
    }
  }

  checkImageExtension(extension: string): boolean {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'svg', 'bmp'];
    return imageExtensions.includes(extension);
  }

  isValidURL(url: string): boolean {
    const urlPattern = /^(http|https):\/\/[\w.-]+\.[a-zA-Z]{2,4}\/?[\w-./?%&=]*$/;
    return urlPattern.test(url);
  }

  public trim(value): string {
      return value.replace(/^\s+|\s+$/g, '');
  }

  public formatDayAndTime(timestamp: any): string {
    return DateHelper.formatDayAndTime(timestamp);
  }

  public getAvatar(): string {
    if (!this.message.owner.profilePhoto) {
      return this.defaultAvatar;
    }

    if (!this.message.owner.profilePhoto.photoUrl) {
      return this.defaultAvatar;
    }

    this.message.owner.profilePhoto.photoUrl = this.message.owner.profilePhoto.photoUrl.replace('/1024?', '/64?');

    return this.environment.backendApiHost + this.message.owner.profilePhoto.photoUrl;
  }

  public toggleRemove(event): void {
    event.stopImmediatePropagation();
    const dialogRef = this.dialog.open(this.confirm);
    dialogRef.afterClosed()
        .pipe(take(1))
        .subscribe((confirm: boolean) => {
          if (confirm) {
            this.deleteMessage.emit(this.message.id);
          }
        });
  }

  public pinMessage(event): void {
    event.stopImmediatePropagation();
    this.pinMessage$.emit(this.message.id);
  }

}
