import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IDonation} from "@src/app/services/donations/donations.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {GenerateUtil} from "@src/app/utils/generate.util";
import {CustomValidators} from "@src/app/utils/custom-validators.util";
import {DONATION_MIN_VALUE} from "@src/app/models/core.model";
import { INPUT_TYPES } from 'ui-elements';

@Component({
  selector: 'app-donation-options',
  templateUrl: './donation-options.component.html',
  styleUrls: ['./donations-options.component.sass']
})
export class DonationOptionsComponent implements OnInit {
  public INPUT_TYPES = INPUT_TYPES;
  @Input() set data(data: IDonation[]) {
    data.forEach(donation => {
      donation.id = GenerateUtil.uuidv4();
      if(!donation.sum) {
        this.forms[donation.id] = this.fb.group({
          donation: ['', [Validators.required, CustomValidators.priceAmount(DONATION_MIN_VALUE)]]
        });
      }
    });
    this._data = data;
  }

  @Input() currency: string;

  forms: {[key: string]: FormGroup} = {};

  get data(): IDonation [] {
    return this._data;
  }
  @Output() donate$: EventEmitter<IDonation> = new EventEmitter<IDonation>();
  private _data: IDonation[];

  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
  }

  public donate(donation: IDonation, donationValue: any, form?: FormGroup): void {
    if (form) {
      form.markAllAsTouched();
      if (form.invalid) {
        return;
      }
    }
    this.donate$.emit({
      sum: donationValue,
      name: donation.name,
      currency: donation.currency
    });
  }
}
