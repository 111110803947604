<section class="section section-live-streams">
    <div class="container">
        <div class="wrapper-content bg-light" style="padding: 35px 20px 35px 20px; margin-bottom: 30px; min-height: auto;">
            <div class="box-registration-success">
                <img src="/assets/img/ico/cancel-circle.svg" class="mb-3">
                <h2 class="title">{{ title | localize }}</h2>
                <button class = "btn btn-primary mt-20" (click) = "resetClicked()">{{"reset.password" | localize}}</button>
                <p>{{ desc | localize }}</p>
                <span class="title-social-list">{{ "forgottenPassword.followUs" | localize}}</span>
                <ul class="social-list">
                    <li><a href="https://www.facebook.com/Stageyourbusiness" target="_blank"><i class="icon icon-facebook"></i></a></li>
                    <li><a href="https://www.instagram.com/streamdust/" target="_blank"><i class="icon icon-instagram"></i></a></li>
                    <li><a href="https://www.linkedin.com/company/streamdust-tv/" target="_blank"><i class="icon icon-linkedin"></i></a></li>
                    <li><a href="https://www.xing.com/companies/streamdust.tvug" target="_blank"><i class="icon icon-xing"></i></a></li>
                </ul>
            </div>
        </div>
    </div>
</section>