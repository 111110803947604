import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {MenuItem} from "primeng/api/menuitem";

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.css']
})
export class BreadcrumbComponent implements OnInit {
    @Input() items: MenuItem[];
    public activeHomeLink = false;

    constructor() {
    }

    ngOnInit(): void {
    }

}
