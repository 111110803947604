<div class="modal modal-absolute show" (click)="closeModal()">
    <div class="modal-dialog modal-xl" role="document" (click)="stopImmediatePropagation($event)">
        <div mat-dialog-content class="modal-content">
            <div class="modal-header">
                <button (click)="closeModal()" type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <app-subscription-tariffs-table [userProfile]="data.userProfile"></app-subscription-tariffs-table>
<!--            <app-subscription-tariffs-table-static [userProfile]="data.userProfile"></app-subscription-tariffs-table-static>-->
        </div>
    </div>
</div>
