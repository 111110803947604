import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {mergeMap, take, takeUntil, tap} from 'rxjs/operators';
import {ActivatedRoute, Params} from '@angular/router';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import {INPUT_TYPES} from 'ui-elements';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '@src/app/services/auth/auth.service';
import {Observable} from 'rxjs';
import {CustomValidators} from '@src/app/utils/custom-validators.util';
import {IPaymentConfig} from '@src/app/models/response.model';
import {PaymentService} from '@src/app/services/payment/payment.service';
import {PAYMENT_CARD_TYPE} from '@src/app/components/payment-cards-list/payment-cards-list.component';
import {UserService} from '@src/app/services/user/user.service';
import {Subject} from 'rxjs/internal/Subject';
import {LoadingService} from '@src/app/services/loading/loading.service';

declare const heidelpay: any;

@Component({
    selector: 'app-heidelpay-payment-page',
    templateUrl: './heidelpay-payment.component.html',
    styleUrls: ['./heidelpay-payment.component.sass']
})
export class HeidelpayPaymentComponent implements OnInit, OnDestroy {
    PAYMENT_TYPE = PAYMENT_TYPE;

    @Input() addNewCardModalSize = 4;
    @Input() donationsMode = false;
    @Input() showBackButton = false;
    @Input() termsAgreed = false;
    @Input() agreementText: string;
    @Input() isModal: boolean;
    @Input() isTransparent: boolean;
    @Input() hasCards: boolean;
    @Input() set paymentType(type: PAYMENT_TYPE) {
        this._paymentType = type;
        this.paymentTypeSelected$.next();
    }
    private _paymentType: PAYMENT_TYPE;
    get paymentType(): PAYMENT_TYPE {
        return this._paymentType;
    }
    termsRequiredError = false;
    localTermsAgreed = false;

    heidelpayPublicKey: string;
    streamId: string;

    INPUT_TYPES = INPUT_TYPES;

    card: any;

    emailForm: FormGroup;

    transactionFailInfo$ = this.paymentService.failedTransactionInfo$;

    @ViewChild('cardNumber') cardNumber;

    @Output() paymentCancelled$ = new EventEmitter<void>();
    @Output() paymentConfirmed$ = new EventEmitter<IHeidelpayPaymentDetails>();
    private initialized: boolean;
    private paymentTypeSelected$: Subject<void> = new Subject<void>();

    constructor(
        private activatedRoute: ActivatedRoute,
        private destroy$: AutoDestroyService,
        public paymentService: PaymentService,
        private fb: FormBuilder,
        public authService: AuthService,
        private userService: UserService,
        public loadingService: LoadingService,
    ) {
    }

    ngOnInit(): void {
        this.loadingService.loadingStart();
        this.emailForm = this.fb.group({
            email: ['', this.isAuthorized() ? [] : [Validators.required, CustomValidators.email]],
            firstName: ['', this.isAuthorized() ? [] : [Validators.required]],
            lastName: ['', this.isAuthorized() ? [] : [Validators.required]],
        });

        if (this.isAuthorized()) {
            this.userService.userProfile$.pipe(takeUntil(this.destroy$))
                .subscribe((profile) => {
                    this.emailForm.get('email').patchValue(profile?.email);
                    this.emailForm.get('firstName').patchValue(profile?.firstName);
                    this.emailForm.get('lastName').patchValue(profile?.lastName);
                });
        }

        this.activatedRoute.params.pipe(takeUntil(this.destroy$),
            tap(({id}: Params) => {
                this.streamId = id;
            }),
            mergeMap(() => this.getPaymentConfig()))
            .subscribe(() => {
                this.loadingService.loadingEnd();
                // this.paymentTypeSelected$.pipe(takeUntil(this.destroy$))
                //     .subscribe(() => {
                        this.initHeidelpayElements();
                //     });
            });
    }

    ngOnDestroy(): void {
        this.paymentService.failedTransactionInfo.next(null);
    }

    getPaymentConfig(): Observable<IPaymentConfig> {
        return this.paymentService.getPaymentConfig().pipe(takeUntil(this.destroy$),
            tap((response => {
                if (response && response.publicKey) {
                    this.heidelpayPublicKey = response.publicKey;
                }
            })));
    }

    isAuthorized(): boolean {
        return this.authService.isAuthorized;
    }

    initHeidelpayElements(): void {
        if (this.initialized) {
            return;
        }
        // console.log('payment type', this.paymentType);

        this.initialized = true;
        const heidelpayInstance = new heidelpay(this.heidelpayPublicKey);

        switch (this.paymentType) {
            case PAYMENT_TYPE.UNZER:
                this.card = heidelpayInstance.Card();
                Promise.all([
                    this.card.create('number', {
                        containerId: 'card-element-id-number',
                        onlyIframe: false
                    }),
                    this.card.create('expiry', {
                        containerId: 'card-element-id-expiry',
                        onlyIframe: false
                    }),
                    this.card.create('cvc', {
                        containerId: 'card-element-id-cvc',
                        onlyIframe: false
                    }),
                ]).then(() => {
                }).catch(() => {
                });
                break;
            case PAYMENT_TYPE.PAYPAL:
                this.card = heidelpayInstance.Paypal();
                this.proceedIfAuthorized();
                break;
            case PAYMENT_TYPE.SOFORT:
                this.card = heidelpayInstance.Sofort();
                this.proceedIfAuthorized();
        }
        // this.card = heidelpayInstance.Card();
        // this.card = heidelpayInstance.Paypal();
        //
        // Promise.all([
        //     this.card.create('number', {
        //         containerId: 'card-element-id-number',
        //         onlyIframe: false
        //     }),
        //     this.card.create('expiry', {
        //         containerId: 'card-element-id-expiry',
        //         onlyIframe: false
        //     }),
        //     this.card.create('cvc', {
        //         containerId: 'card-element-id-cvc',
        //         onlyIframe: false
        //     }),
        // ]).then(() => {
        //     // this.removeRedundantInputs();
        //     this.loading = false;
        // }).catch(() => {
        //     this.loading = false;
        // });

        // Promise.all([
        //     this.card.create('email', {
        //         containerId: 'card-element-id-number',
        //         onlyIframe: false
        //     }),
        //     this.card.create('password', {
        //         containerId: 'card-element-id-expiry',
        //         onlyIframe: false
        //     })
        // ]).then(() => {
        //     // this.removeRedundantInputs();
        //     this.loading = false;
        // }).catch(() => {
        //     this.loading = false;
        // });
    }

    submit(event): void {
        if (this.agreementText && !this.termsAgreed && !this.localTermsAgreed) {
            this.termsRequiredError = true;
            return;
        }
        event?.preventDefault();
        this.card.createResource().then((o, s) => {
        });
        this.emailForm.markAllAsTouched();

        if (this.emailForm.invalid) {
            return;
        }

        this.loadingService.loadingStart();

        this.card.createResource().then((card: IHeidelpayCard) => {
            // console.log('card', card);
            this.paymentConfirmed$.emit({
                card,
                email: this.emailForm.get('email').value,
                firstName: this.emailForm.get('firstName').value,
                lastName: this.emailForm.get('lastName').value
            });
        }).catch(() => {
            this.loadingService.loadingEnd();
        });
    }

    private proceedIfAuthorized(): void {
        this.loadingService.loadingStart();
        this.authService.isAuthorized$.pipe(take(1))
            .subscribe((isAuthorized) => {
                if (!isAuthorized) {
                    this.loadingService.loadingEnd();
                    return;
                }
                this.submit(null);
            });
    }

    paymentCancelled(): void {
        this.paymentService.failedTransactionInfo.next(null);
        this.paymentCancelled$.emit();
    }

    public toggleTermsAgreed(event: boolean): void {
        this.localTermsAgreed = event;
        this.termsRequiredError = false;
    }

    public selectPaymentType(type: PAYMENT_TYPE): void {
        this.paymentType = type;
        this.paymentTypeSelected$.next();
        this.initialized = false;
    }
}

export interface IHeidelpayPaymentDetails {
    card: IHeidelpayCard;
    email: string;
    firstName?: string;
    lastName?: string;
}

export interface IHeidelpayCard {
    '3ds': string;
    brand: PAYMENT_CARD_TYPE;
    cardDetails: {
        account: string;
        cardType: string;
        countryIsoA2: string;
        countryName: string;
        issuerName: string;
        issuerPhoneNumber: string;
        issuerUrl: string;
    };
    cvc: string;
    expiryDate: string;
    id: string;
    method: string;
    number: string;
}

export interface ICommonPaymentCard {
    externalCardId: string;
    type: PAYMENT_CARD_TYPE;
    number: string;
    expiryDate: string;
    cardDetails: {
        id: string;
        brand: string;
        cvc: string;
        expiryDate: string;
        method: string;
        number: string;
        account: string;
        cardType: string;
        countryIsoA2: string;
        countryName: string;
        issuerName: string;
        issuerPhoneNumber: string;
        issuerUrl: string;
        '3ds': string;
    };
}

enum PAYMENT_TYPE {
    UNZER = 'UNZER', PAYPAL = 'PAYPAL', SOFORT = 'SOFORT'
}
