<form class="border-form validation-absolute"
      *ngIf="!config?.hidden"
      autocomplete="off"
      [class.disabled]="disabledForm"
      [formGroup]="form"
      [class.border-bottom]="!toggleForm && !defaultForm && !showAdd && !addLimitReached && !withoutBorder"
      [class.margin-bottom-20]="!toggleForm && !defaultForm && !showAdd && !withoutMargin"
>
    <div class="info-panel" *ngIf="infoPanel">
        <app-info-panel [infoPanel]="infoPanel"></app-info-panel>
    </div>

    <div class="form-group" *ngIf="formCountTitle">
        <span class="text">{{ formCountTitle | localize }} {{ itemIndex + 1 }}</span>
    </div>
    <ng-container *ngFor="let row of fieldsConfig; let i=index">
        <!--        <ng-container *ngIf="!isHidden(row)">-->
        <ng-container *ngIf="row.length === 1">
            <ng-container *ngFor="let field of row">
                <div
                        [ngClass]="{ 'row-padding-0': !panel && (field.config.simpleToggle || field.config.padding0),
                        'row-padding-10': !panel && !field.config.simpleToggle && !field.config.padding0 && !rowPaddingImage30,
                        'form-group': field.formGroup
                        }"
                        class="row align-items-center"
                        *ngIf="!field.hidden && !field?.hideInForms?.includes(itemIndex)"
                >
                    <label *ngIf="field.config.label && !withoutLables"
                           class="col-md-3 form-control-label">{{field.config.label | localize}}</label>
                    <div [ngClass]="getColSize(row, field)" [class.withBtn]="field.config?.buttonText"
                         [id]="field.name + (itemIndex ? '_' + itemIndex : '')">
                        <p *ngIf="field.config?.explanationField && !field.hidden" class="explanation-field">{{field.config?.explanationField | localize}}</p>
                        <div [ngClass]="{'form-group': !field.formGroup && (!panel || field.config.formGroup)}"
                             [class.mb-30]="field.config?.errorConfig && form.get(field.name)?.errors && form.get(field.name)?.errors[field.config?.errorConfig.error]"
                             [class.pl-15]="field.config.simpleToggle"
                             [class.invalid]="form.get(field.name).invalid && form.get(field.name).touched"
                             [class.form-group-select]="field.config.inputType === INPUT_TYPES.DATETIME"
                             [class.readonly]="readonly"
                             [class.mb-5]="field.config?.isVisibilitySettings"
                             [class.mt-5]="!field.config.simpleToggle && field.config.inputType === INPUT_TYPES.CHECKBOX">
                            <h4 *ngIf="field.title"><b>{{field.title | localize}}</b></h4>
                            <div [ngClass]="field.config.append ? 'row row-padding-0 align-items-center' : ''">
                                <div [ngClass]="field.config.append ? 'col' : ''">
                                    <ano-input [formControlName]="field.name"
                                               [formMain]="form"
                                               [formIndex]="itemIndex"
                                               [errors]="form.get(field.name).errors"
                                               [infoMessage]="field.config?.infoMessage"
                                               [isTouched]="form.get(field.name).touched"
                                               [isDirty]="form.get(field.name).dirty"
                                               [invalid]="form.get(field.name).invalid"
                                               [readonly]="readonly"
                                               [disabled]="disabled || disabledForm"
                                               [disabledToggle]="disabledToggle"
                                               [columnImageUploader]="true"
                                               [isEditMode]="true"
                                               [multimediaUrl]="multimediaUrl"
                                               [multimediaType]="multimediaType"
                                               [playerConfig]="playerConfig"
                                               [accept]="field.config.accept"
                                               [clearImageButton]="true"
                                               [formConfig]="config"
                                               [field]="field"
                                               [coursePreview]="coursePreview"
                                               [config]="{
                                                    isAutocomplete: field.config.isAutocomplete,
                                                    isCourse: field.config.isCourse,
                                                    isInlineEditing: field.config.isInlineEditing,
                                                    inputType: field.config.inputType,
                                                    type: field.config.type,
                                                    label: field.config.label,
                                                    placeholder: field.config.placeholder,
                                                    isRequired: field.config.isRequired,
                                                    id: field.config.id,
                                                    selectOptions: field.config.selectOptions,
                                                    datetimeType: field.config.datetimeType,
                                                    startDate: getStartDate(field.config, itemIndex),
                                                    endDate: getEndDate(field.config, itemIndex),
                                                    classList: 'form-control-lg',
                                                    append: field.config.append,
                                                    simpleToggle: field.config.simpleToggle,
                                                    appendText: field.config.appendText,
                                                    mask: field.config.mask,
                                                    appendIcon: field.config.appendIcon,
                                                    actionLink: field.config.actionLink,
                                                    actionCallback: field.config.actionCallback,
                                                    min: field.config.min,
                                                    max: field.config.max,
                                                    disabled: field.config.disabled,
                                                    datetimePickerType: field.config.datetimePickerType,
                                                    hiddenToggle: field.config.hiddenToggle,
                                                    videoDurationLimit: field.config.videoDurationLimit,
                                                    excludeSelected: field.config.excludeSelected,
                                                    maxFileSize: field.config.maxFileSize,
                                                    removeSpaces: field.config.removeSpaces,
                                                    url: field.config.url,
                                                    dateFrontiers: field.config.dateFrontiers
                                                }"
                                               (blur)="onBlur($event)"
                                               (input$)="onInput($event, field)"
                                               (toggle$)="toggle($event)"
                                               (onFileUpload$)="uploadPreviewPhoto($event)"
                                               (clearImage$)="clearImage($event)"
                                               (checkedPayments$)="checkedPayments$.emit($event)"
                                               (checked$)="form.get(field.name).patchValue($event)"
                                    ></ano-input>
                                </div>
                                <div *ngIf="field.config.append" class="col-auto">
                                    <span class="text-form">{{ field.config.append }}</span>
                                </div>
                                <div *ngIf="field.config.bottomText">
                                    <span>{{ field.config.bottomText | localize }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="info-panel-container col-{{field.config.infoPanel.size}} {{field.config.infoPanel.cssClass}}" *ngIf="field?.config?.infoPanel?.show">
                        <app-info-panel [infoPanel]="field?.config?.infoPanel?.config"></app-info-panel>
                    </div>
                    <div class="form-group"
                         [class.remove-row]="(!field?.config?.size && !field?.config?.halfSize) || field?.config?.size === 12"
                         *ngIf="!toggleForm && !defaultForm && showRemove && !readonly && i === 0"
                         [class.ml-20]="panel || field.config.simpleToggle || field.config.padding0">
                            <span class="add-group" [class.text-danger]="!disabledForm" (click)="remove()">
                                <i class="icon icon-trash"></i>
                            </span>
                    </div>
                    <button *ngIf="field.config?.buttonText"
                            [disabled]="isDisabledBtn || form.get(field.name).errors"
                            (click)="onClickButton(field)"
                            class="btn btn-primary">{{field.config?.buttonText}}</button>
                </div>
                <div class="form-group" *ngIf="field.subTitle">
                    <span class="text">{{field.subTitle | localize}}</span>
                </div>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="row.length > 1">
            <div class="row row-padding-10">
                <ng-container *ngFor="let field of row">
                    <label *ngIf="field.config.label && !withoutLables"
                           class="col-md-3 form-control-label">{{field.config.label | localize}}</label>
                    <div class="{{getColSize(row, field)}}" [class.withBtn]="field.config?.buttonText"
                         [id]="field.name + (itemIndex ? '_' + itemIndex : '')">
                        <p *ngIf="field.config?.explanationField && !field.hidden" class="explanation-field">{{field.config?.explanationField | localize}}</p>
                        <div class="form-group" *ngIf="!field.hidden && !field?.hideInForms?.includes(itemIndex)"
                             [class.mb-30]="field.config?.errorConfig && form.get(field.name)?.errors && form.get(field.name)?.errors[field.config?.errorConfig.error]"
                             [class.invalid]="form.get(field.name).invalid && form.get(field.name).touched"
                             [class.form-group-select]="field.config.inputType === INPUT_TYPES.DATETIME"
                             [class.readonly]="readonly" [class.disabled]="disabled"
                             [class.mt-5]="field.config.inputType === INPUT_TYPES.CHECKBOX">
                            <h4 *ngIf="field.title"><b>{{field.title | localize}}</b></h4>
                            <ano-input [formControlName]="field.name"
                                       [formIndex]="itemIndex"
                                       [errors]="form.get(field.name).errors"
                                       [infoMessage]="field.config?.infoMessage"
                                       [isTouched]="form.get(field.name).touched"
                                       [isDirty]="form.get(field.name).dirty"
                                       [invalid]="form.get(field.name).invalid"
                                       [disabled]="disabled || disabledForm"
                                       [disabledToggle]="disabledToggle"
                                       [playerConfig]="playerConfig"
                                       [columnImageUploader]="true"
                                       [multimediaUrl]="multimediaUrl"
                                       [multimediaType]="multimediaType"
                                       [isEditMode]="true"
                                       [accept]="field.config.accept"
                                       [clearImageButton]="true"
                                       [readonly]="readonly"
                                       (checked$)="form.get(field.name).patchValue($event)"
                                       (blur)="onBlur($event)"
                                       (input$)="onInput($event, field)"
                                       [field]="field"
                                       [config]="{
                                            isAutocomplete: field.config.isAutocomplete,
                                            isCourse: field.config.isCourse,
                                            isInlineEditing: field.config.isInlineEditing,
                                            inputType: field.config.inputType,
                                            type: field.config.type,
                                            label: field.config.label,
                                            isClearButton: isClearButton,
                                            placeholder: field.config.placeholder,
                                            isRequired: field.config.isRequired,
                                            id: field.config.id,
                                            selectOptions: field.config.selectOptions,
                                            datetimeType: field.config.datetimeType,
                                            mask: field.config.mask,
                                            startDate: getStartDate(field.config, itemIndex),
                                            endDate: getEndDate(field.config, itemIndex),
                                            classList: 'form-control-lg',
                                            append: field.config.append,
                                            appendText: field.config.appendText,
                                            appendIcon: field.config.appendIcon,
                                            actionLink: field.config.actionLink,
                                            actionCallback: field.config.actionCallback,
                                            min: field.config.min,
                                            max: field.config.max,
                                            disabled: field.config.disabled,
                                            datetimePickerType: field.config.datetimePickerType,
                                            hiddenToggle: field.config.hiddenToggle,
                                            videoDurationLimit: field.config.videoDurationLimit,
                                            excludeSelected: field.config.excludeSelected,
                                            maxFileSize: field.config.maxFileSize,
                                            removeSpaces: field.config.removeSpaces,
                                            url: field.config.url,
                                            dateFrontiers: field.config.dateFrontiers
                                                    }"
                                       (onFileUpload$)="uploadPreviewPhoto($event)"
                                       (clearImage$)="clearImage($event)"
                            ></ano-input>
                        </div>
                        <div *ngIf="field.config.bottomText">
                            <span>{{ field.config.bottomText | localize }}</span>
                        </div>
                        <button *ngIf="field.config?.buttonText"
                                [disabled]="isDisabledBtn || form.get(field.name).errors"
                                (click)="onClickButton(field)"
                                class="btn btn-primary">{{field.config?.buttonText}}</button>
                    </div>
                </ng-container>
                <div class="form-group remove-row"
                     *ngIf="!toggleForm && !defaultForm && showRemove && !readonly && i === 0">
                            <span class="add-group" [class.text-danger]="!disabledForm" (click)="remove()">
                                <i class="icon icon-trash"></i>
                            </span>
                </div>
            </div>
            <div class="form-group" *ngIf="getSubTitle(row)">
                <span class="text">{{ getSubTitle(row) }}</span>
            </div>

        </ng-container>
        <!--        </ng-container>-->
    </ng-container>
    <!--    <div class="form-group" *ngIf="!toggleForm && !defaultForm && showRemove && !readonly">-->
    <!--        <span class="add-group" [class.text-danger]="!disabledForm" (click)="remove()">-->
    <!--            <i class="icon icon-trash"></i>-->
    <!--            {{ removeButtonText | localize }}-->
    <!--        </span>-->
    <!--    </div>-->
    <div class="form-group" *ngIf="!toggleForm && !defaultForm && showAdd && !readonly && !addLimitReached && !disabled"
         [class.add-button-position-right]="addButtonPositionRight">
        <span class="add-group" (click)="add()">
            <i class="icon icon-plus-circle"></i>
            <span>{{ addButtonText | localize }}</span>
        </span>
    </div>
</form>
