import {Component, OnInit} from '@angular/core';
import {take, takeUntil} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import {
    PAYMENT_STATUS,
    SubscriptionManageService
} from '@src/app/services/subscription-manage/subscription-manage.service';
import {Location} from '@angular/common';
import {PAYMENT_FAIL_MESSAGES, PaymentService} from '@src/app/services/payment/payment.service';
import {MediaGroupsService} from '@src/app/services/media-groups/media-groups.service';
import {UserService} from '@src/app/services/user/user.service';
import {AuthService} from '@src/app/services/auth/auth.service';
import {clearQueryParams} from '@src/app/utils/query-params.util';

@Component({
    selector: 'app-payment-details-page',
    templateUrl: './payment-details-page.component.html',
    styleUrls: ['./payment-details-page.component.css']
})
export class PaymentDetailsPageComponent implements OnInit {

    queryParams: any;
    transactionId: string;
    successActivationRedirectUrl: string;
    failActivationRedirectUrl: string;

    loading: boolean;

    constructor(
        private activatedRoute: ActivatedRoute,
        private destroy$: AutoDestroyService,
        private subscriptionManageService: SubscriptionManageService,
        private location: Location,
        private router: Router,
        private paymentService: PaymentService,
        private groupService: MediaGroupsService,
        private userService: UserService,
        private authService: AuthService
    ) {
    }

    ngOnInit(): void {
        this.activatedRoute.queryParams.pipe(takeUntil(this.destroy$)).subscribe(params => {
            this.queryParams = params;

            if (params.hasOwnProperty('successUrl') && params.hasOwnProperty('failUrl')) {
                this.successActivationRedirectUrl = params['successUrl'];
                this.failActivationRedirectUrl = params['failUrl'];
            }

            if (params.hasOwnProperty('transactionId')) {
                this.loading = true;
                this.transactionId = params['transactionId'];
                if (params.hasOwnProperty('subscriptionReturn')) {
                    this.checkPaymentResult();
                }
                if (params.hasOwnProperty('paymentReturn')) {
                    this.checkPaymentResult(false);
                }
                clearQueryParams(this.location);
            }
        });
    }

    checkPaymentResult(subscriptionPayment = true): void {
        const paymentCheckMethod = subscriptionPayment ? this.subscriptionManageService.subscriptionActivate(this.transactionId) :
            this.paymentService.checkTransactionStatus(this.transactionId);

        paymentCheckMethod.pipe(take(1)).subscribe(results => {
            switch (results?.results?.result?.paymentStatus) {
                case PAYMENT_STATUS.FAILED: {
                    setTimeout(() => {
                        this.loading = false;
                        const failedInfo = {
                            failedCardId: results?.results?.result?.externalCardId,
                            transactionFailReason: results?.results?.result?.reason || PAYMENT_FAIL_MESSAGES.FAILED,
                        };
                        this.paymentService.failedTransactionInfo.next(failedInfo);
                        window.location.href = `${this.failActivationRedirectUrl}?failedCardId=${results.results.result.externalCardId}&transactionFailReason=${results.results.result.reason}`;
                    }, 1000);
                    break;
                }
                case PAYMENT_STATUS.PENDING: {
                    setTimeout(() => {
                        return this.checkPaymentResult();
                    }, 3000);
                    break;
                }
                case PAYMENT_STATUS.SUCCESS: {
                    setTimeout(() => {
                        this.loading = false;
                        window.location.href = this.successActivationRedirectUrl;
                        this.authService.isAuthorized$.pipe(take(1))
                            .subscribe((res) => {
                                if (res) {
                                    this.userService.refreshUserProfile();
                                }
                            });
                    }, 1000);
                    break;
                }
            }
        });
    }

}
