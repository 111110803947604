<section class="section section-dashboard">
    <div class="container">
        <div class="wrapper-content bg-light">
            <app-dashboard-header></app-dashboard-header>
            <div class="row row-padding-10">
                <div class="col-md-auto col-lg-auto col-sm-12">
                    <app-side-nav [config]="navConfig"></app-side-nav>
                </div>
                <div class="col">
                    <div class="box-dashboard">
                        <router-outlet></router-outlet>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
