/**
 * Created by Тима on 03.04.2020.
 */
import {Component} from '@angular/core';
import {ISideNavConfig} from '@src/app/components/side-nav/side-nav.component';
import {UserService} from "@src/app/services/user/user.service";
import {SideNavConfigModel} from "@src/app/models/side-nav-config.model";
import {mergeMap, takeUntil} from "rxjs/operators";
import {AutoDestroyService} from "@src/app/services/auto-destroy-service/auto-destroy.service";
import {Router} from "@angular/router";
import {IUserProfile} from "@src/app/pages/user-profile/components/profile/services/profile.service";
import {USER_TYPE} from "@src/app/constants/user-type.constant";
import {SubAccountsService} from '@src/app/services/sub-accounts/sub-accounts.service';
import {AuthService} from '@src/app/services/auth/auth.service';
import {SUB_ACCOUNT_TYPE} from '@src/app/pages/subaccounts-page/model/sub-account.model';

@Component({
    selector: 'app-user-profile',
    templateUrl: './user-profile.component.html',
    styleUrls: ['./user-profile.component.css'],
    providers: [AutoDestroyService]
})
export class UserProfileComponent {
    navConfig: ISideNavConfig = SideNavConfigModel.getLeftMenuConfig();
    USER_TYPE = USER_TYPE;
    userType: USER_TYPE;
    userProfile: IUserProfile;

    constructor(
        public userService: UserService,
        private destroy$: AutoDestroyService,
        private router: Router,
        private subAccountsService: SubAccountsService,
        private authService: AuthService
    ) {
        this.userService.userProfile$.pipe(
            mergeMap((userProfile) => {
                this.userProfile = userProfile;
                return this.userService.userType$;
            }),
            takeUntil(this.destroy$))
            .subscribe((type) => {
                this.userType = type;
                this.navConfig = SideNavConfigModel.getLeftMenuConfig(this.userType, this.userProfile?.hasConnectedPublishers, () => {
                    this.router.navigate(["upgrade-to-publisher"], {
                      queryParams: { step: "publisher" },
                    });
                }, this.userProfile?.subscribed, () => {
                    this.subAccountsService.logoutAs().pipe(takeUntil(this.destroy$)).subscribe((res) => {
                        if (!res) {
                            return;
                        }
                        this.authService.logout(false);
                        this.authService.storeToken(res.authToken);
                        this.router.navigateByUrl('/', {skipLocationChange: true})
                            .then(() => {
                                this.router.navigate(['streams']);
                            });
                    });
                }, this.userProfile?.subAccount?.type === SUB_ACCOUNT_TYPE.INVITED);
            });

    }
}
