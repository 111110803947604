<div class="panel mb-30">
    <div class="panel-header">
        <h2><b>{{'nav.revenue' | localize}}</b></h2>
    </div>
    <div class="panel-body bg-white padding-top-20 padding-bottom-20">
        <div class="title-box">
            <div class="head-container">
                <h2 class="title-level-3">
                    {{ 'revenues.publisher.details' | localize }}
                </h2>
                <ano-button class="cancel-button"
                            (click)="goBack()"
                            [config]="{
                                        type: 'button',
                                        styleType: 'primary',
                                        classList: 'width110px'
                                        }">
                    <ng-container><i class="icon icon-back-arrow"></i> {{ 'zuruck' | localize }}</ng-container>

                </ano-button>
            </div>
        </div>
        <div class="row">
            <div class="col-6 revenue-details">
                <ano-form [form]="form" [forcedViewMode]="true" [fieldsConfig]="config" [withoutSave]="true"
                          [materialForm]="true" [inlineForm]="false" [isLightForm]="false" [borderForm]="false" [labelWidth]="6" [fieldWidth]="6"></ano-form>
<!--                <div class="buttons-container">-->
<!--                    <ano-button class="action-button" (click)="downloadPdf()"-->
<!--                                [config]="{-->
<!--                                                type: 'button',-->
<!--                                                styleType: 'success',-->
<!--                                                classList: 'width100perc'-->
<!--                                                }"-->
<!--                    ><ng-container>-->
<!--                        <ano-loader [isOpen]="pdfLoading"></ano-loader>-->
<!--                        <i class="icon icon-download"></i> {{ 'download pdf' | localize }}</ng-container>-->
<!--                    </ano-button>-->
<!--                </div>-->
            </div>
<!--            <div class="col-4"></div>-->

<!--            <div class="col-5">-->
<!--                <app-form-->
<!--                        id="leftForm"-->
<!--                        [form]="form"-->
<!--                        [forcedViewMode]="true"-->
<!--                        [fieldsConfig]="config"-->
<!--                        [withoutSave]="true"-->
<!--                        [materialForm]="true"-->
<!--                        [isLightForm]="true"-->
<!--                        [borderForm]="true"-->
<!--                        [fieldWidth]="6"-->
<!--                        [labelWidth]="6"-->
<!--                ></app-form>-->
<!--            </div>-->
            <div class="col-6">
                <div class="row">
                    <div class="col-6">
                        <label class="form-control-label"><b>Gutschrift wurde überwiesen</b></label>
                    </div>
                    <div class="col-6">
                        <i class="ico icon-{{ data?.payoutDone ? 'check' : 'waiting'}} text-primary"></i>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="details-actions" *ngIf="data && !currentMonth">
                            <ano-button (click)="viewPdf()" [config]="{
                type: 'button',
                classList: 'cursor-pointer full-width',
                styleType: 'primary'}">
                                <i *ngIf="viewDone" class="icon icon-check text-success cursor-pointer"></i>
                                <i class="icon icon-admin-eye text-white cursor-pointer"></i>
                                <ano-loader [isOpen]="viewLoading"></ano-loader>
                                Show PDF
                            </ano-button>
                            <ano-button (click)="downloadPdf()" [config]="{
                type: 'button',
                classList: 'cursor-pointer full-width',
                styleType: 'primary'}">
                                <i *ngIf="downloadDone" class="icon icon-check text-success cursor-pointer"></i>
                                <i class="icon icon-download text-white cursor-pointer">
                                </i>
                                <ano-loader [isOpen]="download"></ano-loader>
                                Download PDF
                            </ano-button>
                            <ano-button (click)="sendPdf()" [config]="{
                type: 'button',
                classList: 'cursor-pointer full-width',
                styleType: 'success'}">
                                <i *ngIf="sendDone" class="icon icon-check text-white cursor-pointer"></i>
                                <i class="icon icon-telegram-round text-white cursor-pointer"></i>
                                <ano-loader [isOpen]="sendLoading"></ano-loader>
                                Send PDF
                            </ano-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
