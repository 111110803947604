import { Routes } from '@angular/router';
import { RegistrationPageComponent } from '@src/app/pages/registration-page/registration-page.component';

export const commonDeclarations: any[] = [
  RegistrationPageComponent,
];

export const commonImports: any[] = [
];

export const commonExports: any[] = [
  RegistrationPageComponent,
];

export const routes: Routes = [
];
