import {environment} from '@src/environments/environment';

export enum STREAM_STATUS {
    SCHEDULED = 'SCHEDULED',
    RESCHEDULING = 'RESCHEDULING',
    ACTIVATION = 'ACTIVATION',
    ACTIVATED = 'ACTIVATED',
    CONNECTED = 'CONNECTED',
    PAUSED = 'PAUSED',
    LIVE = 'LIVE',
    FINISHED = 'FINISHED',
    VIDEO = 'VIDEO',
    WAITING = 'WAITING',
    CANCELED = 'CANCELED',
    DEACTIVATED = 'DEACTIVATED',
    PENDING = 'PENDING'
}

export enum RECORD_STATUS {
    NONE = 'NONE',
    RECORDING = 'RECORDING',
    STORING = 'STORING',
    RECORDED = 'RECORDED'
}

export enum STOP_REASON {
    DEFAULT = 'DEFAULT',
    BANDWIDTH_LIMIT = 'BANDWIDTH_LIMIT',
}

export enum STATUS_ICON {
    DOT = 'dot-12',
    GREY_DOT = 'dot-12 grey-icon'
}

export enum STATUS_CSS_CLASS {
    SCHEDULED = 'scheduled',
    LIVE = 'live',
    FINISHED = 'finished',
    VIDEO = 'video',
    INFO = 'info',
    DONATE = 'donate',
    LIVE_FINISHED = 'live-finished'
}

export enum STATUS_LOCALIZATION {
    SCHEDULED= 'player.status.scheduled',
    ACTIVATION = 'player.status.activation',
    ACTIVATED = 'player.status.activated',
    CONNECTED = 'player.status.connected',
    PAUSED = 'player.status.paused',
    LIVE = 'player.status.live',
    FINISHED = 'player.status.finished',
    NONE= 'player.record-status.none',
    RECORDING = 'player.record-status.recording',
    STORING = 'player.record-status.storing',
    RECORDED = 'player.record-status.recorded',
    VIDEO = 'player.status.video',
    WAITING = 'player.status.waiting',
    RESCHEDULING = 'player.status.rescheduling',
    DEACTIVATED = 'player.status.deactivated',
}


// export enum VideoUrl {
//     // @ts-ignore
//     EMPTY = `${environment.backendApiHost}no-video`
// }

export class VideoUrl {
    public static EMPTY = `${environment.backendApiHost}/no-video`;
}
