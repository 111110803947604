import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {animate, style, transition, trigger} from '@angular/animations';

export const backdropAnimation = trigger(
    'backdropEnterAnimation', [
        transition(':enter', [
            style({opacity: 0}),
            animate('200ms', style({opacity: 0.2})),
        ]),
        transition(':leave', [
            animate('200ms', style({opacity: 0})),
        ])
    ]
);

export const appearAnimation = trigger(
    'smoothAppearing', [
        transition(':enter', [
            style({transform: 'translate(0, 100px)', opacity: 0,}),
            animate('200ms', style({transform: 'translate(0, 0)', opacity: 1,})),
        ]),
        transition(':leave', [
            animate('200ms', style({transform: 'translate(0, 100px)', opacity: 0,})),
        ])
    ]
);

export interface IBottomModalBtnsConfig {
    icon: string;
    text: string;
    id: any;
    model?: string;
    action?: (param?: any) => void;
}

@Component({
    selector: 'app-bottom-modal',
    templateUrl: './bottom-modal.component.html',
    styleUrls: ['./bottom-modal.component.css'],
    animations: [backdropAnimation, appearAnimation]

})
export class BottomModalComponent implements OnInit {

    public isShown: boolean;
    @Input() btnsConfig: IBottomModalBtnsConfig[];
    @Input() isInputShow = false;
    @Input() inputModel = '';
    @Output() btnClicked = new EventEmitter<IBottomModalBtnsConfig>();


    constructor() {
    }

    ngOnInit() {
    }

    public onBtnClicked(btn: IBottomModalBtnsConfig) {
        if(this.isInputShow) {
            btn.model = this.inputModel;
        }
        this.btnClicked.emit(btn);
    }

    public show() {
        this.isShown = true;
    }

    public hide() {
        this.isShown = false;
    }

}
