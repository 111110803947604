<div class="content">
    <div class="panel panel-md">
        <div class="panel-header">
            <h2 class="title-lg"><b>{{'profile.deleteAccount.title' | localize}}</b></h2>
            <p class="page-description">{{'profile.deleteAccount.description' | localize}}</p>
        </div>
    </div>
</div>
<ano-form class="pt-20 "
        [form]="form"
        [fieldsConfig]="fieldsConfig"
          [mainColMd7]="true"
        (submit$)="saveChanges()"
        [submitButtonText]="'profile.deleteAccount.title'">
</ano-form>

<ng-template #confirm let-dialogRef="dialogRef">
    <ano-modal [dialogRef]="dialogRef" [title]="'profile.deleteAccount.confirmation.title' | localize" [isConfirmation]="true">
        {{'profile.deleteAccount.confirmation.text' | localize}}
    </ano-modal>
</ng-template>
