<div class="panel mb-30">
    <div class="panel-header">
        <h2 class="title-lg"><b>{{'subaccounts.connectedPublishers.title' | localize}}</b></h2>
        <p class="page-description min-width-100">{{'subaccounts.connectedPublishers.description' | localize}}</p>
    </div>
    <div class="panel-body bg-white padding-top-20 padding-bottom-20">
        <ano-table class="connected-publishers-table" #tableComponent [tableConfig]="tableConfig"
                   [withSearch]="true"
                   (search$)="search($event)"
        ></ano-table>
    </div>
</div>

<ng-template #actionsTmpl let-data="rowData">
    <div class="table-actions-list">
        <b class="text-primary">
            <i (click)="loginAs(data.ownerId)"
               class="icon icon-login cursor-pointer"></i>
        </b>
    </div>
</ng-template>

<ano-loader [isOpen]="loading"></ano-loader>
