import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccessDeniedPageComponent } from './access-denied-page.component';
import { SharedModule } from '@src/app/modules/shared/shared.module';

@NgModule({
  declarations: [AccessDeniedPageComponent],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [AccessDeniedPageComponent]
})
export class AccessDeniedPageModule { }
