import {Component, OnInit} from '@angular/core';
import {StreamService} from '@src/app/services/stream-metadata/stream.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {takeUntil} from 'rxjs/operators';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import {IOverlay} from '@src/app/models/response.model';
import {INPUT_TYPES} from 'ui-elements';
import {FormBuilder, FormGroup} from '@angular/forms';
import {IFieldsConfig} from '@src/app/pages/user-profile/interfaces/interfaces-common';
import {environment} from '@src/environments/environment';
import {LocalizationProvider} from 'ui-elements';
import {STREAM_STATUS} from '@src/app/components/streamdust-player/constants/status';
import {MEDIA_TYPE} from '@src/app/components/streamdust-player/constants/mediaType';
import {BehaviorSubject, Subscription} from 'rxjs';
import {MARKETING_ACTION, MARKETING_ENTITY_TYPE, SURVEY_STATUS} from '@src/app/services/survey/survey.service';
import {MarketingService} from '@src/app/services/marketing/marketing.service';
import {UserService} from '@src/app/services/user/user.service';
import {StreamMarketingToolsOverlaysTypes} from '@src/app/pages/stream-page/components/stream-marketing-tools-overlays/stream-marketing-tools-overlays.component';
import {IInfoPanel, InfoPanelsConfig} from '@src/app/components/info-panel/info-panel.component';

@Component({
    selector: 'app-overlay-info',
    templateUrl: './overlay-info.component.html',
    styleUrls: ['./overlay-info.component.sass'],
    providers: [AutoDestroyService, MarketingService]
})
export class OverlayInfoComponent implements OnInit {
    public STREAM_STATUS = STREAM_STATUS;
    public MEDIA_TYPE = MEDIA_TYPE;
    private overlayId: string;
    public data: IOverlay;
    public mediaId: string;
    public mediaType: MEDIA_TYPE;
    public form: FormGroup;
    public config: IFieldsConfig[];
    public picture: string;
    public pictureClass: string;
    activateForm = this.fb.group({
        active: [false]
    });
    private activateFormSubscription: Subscription;
    loader = false;
    private overlaysType: StreamMarketingToolsOverlaysTypes;
    public configPage: any;
    public disabledInfoPanel: IInfoPanel = InfoPanelsConfig.overlayDisabled;

    constructor(
        public streamService: StreamService,
        private readonly activatedRoute: ActivatedRoute,
        private destroy$: AutoDestroyService,
        private fb: FormBuilder,
        private localizationProvider: LocalizationProvider,
        private marketingService: MarketingService,
        public userService: UserService,
        public router: Router
    ) {
    }

    ngOnInit(): void {
        this.overlaysType = (this.activatedRoute.data as BehaviorSubject<any>)?.value?.overlaysType;

        if (this.overlaysType === StreamMarketingToolsOverlaysTypes.OVERLAY) {
            this.configPage = {
                title: 'overlays.title',
                subtitle: 'overlays.subtitle'
            };
        } else {
            this.configPage = {
                title: 'courses.title',
                subtitle: 'courses.subtitle'
            };
        }


        this.loader = true;
        this.activatedRoute.params.pipe(
            takeUntil(this.destroy$))
            .subscribe(({id}: Params) => {
                this.activatedRoute.parent.params.subscribe(parentParams => {
                    this.mediaId = parentParams.id;
                    this.mediaType = this.resolveMediaType();
                    this.listenStatusChange();
                });
                if (!id) {
                    return;
                }
                this.overlayId = id;
                this.streamService.getOverlay(this.overlayId, this.overlaysType)
                    .pipe(takeUntil(this.destroy$))
                    .subscribe((res) => {
                        this.mediaType = this.resolveMediaType();
                        this.data = res;
                        this.initConfig();
                        this.picture = this.data?.picture ? environment.backendApiHost + this.data.picture.photoUrl : null;
                        this.pictureClass = (this.data.position as string).toLowerCase() + '-overlay';
                        this.data.showDuration = this.parseSecondsToTime(this.data.showDuration) + ' mm:ss';
                        this.data.status = this.data.active ? this.localizationProvider.getByKey('common.status.active') : this.localizationProvider.getByKey('common.status.inactive');
                        this.fillForm();
                        this.activateForm.get('active').patchValue(this.data.active);
                        this.activateFormSubscription = this.activateForm.get('active').valueChanges.pipe(takeUntil(this.destroy$)).subscribe(active => {
                            if (active) {
                                this.activateOverlay();
                                return;
                            }

                            this.deactivateOverlay();
                        });
                        this.loader = false;
                    });

            });
    }

    private resolveMediaType(): MEDIA_TYPE {
        return (this.activatedRoute.parent.data as BehaviorSubject<any>)?.value?.mediaType;
    }

    private initConfig(): void {
        this.form = this.fb.group({
            status: [''],
            name: [''],
            position: [''],
            showDuration: [''],
            showAmount: [''],
            clickAmount: [''],
            url: [''],
            textDescription: [''],
        });


        if (this.overlaysType === StreamMarketingToolsOverlaysTypes.COURSES) {
            this.form.removeControl('link');
        } else if (this.overlaysType === StreamMarketingToolsOverlaysTypes.OVERLAY) {
            this.form.removeControl('textDescription');
        }

        this.config = [
            {
                name: 'status',
                config: {
                    inputType: INPUT_TYPES.INPUT,
                    label: 'overlays.table.status',
                    placeholder: '',
                    size: 6
                }
            },
            {
                name: 'name',
                config: {
                    inputType: INPUT_TYPES.INPUT,
                    label: 'overlays.table.name',
                    placeholder: '',
                    size: 6
                }
            },
            {
                name: 'textDescription',
                hidden: this.overlaysType !== StreamMarketingToolsOverlaysTypes.COURSES,
                config: {
                    inputType: INPUT_TYPES.TEXTAREA,
                    label: 'overlay.textDescription',
                    placeholder: '',
                    size: 6
                }
            },
            {
                name: 'position',
                config: {
                    inputType: INPUT_TYPES.INPUT,
                    label: 'overlays.table.position',
                    placeholder: '',
                    size: 6
                }
            },
            {
                name: 'showDuration',
                config: {
                    inputType: INPUT_TYPES.INPUT,
                    label: 'overlays.table.duration',
                    placeholder: '',
                    size: 6
                }
            },
            {
                name: 'showAmount',
                config: {
                    inputType: INPUT_TYPES.INPUT,
                    label: 'overlays.table.show',
                    placeholder: '',
                    size: 6
                }
            },
            {
                name: 'clickAmount',
                config: {
                    inputType: INPUT_TYPES.INPUT,
                    label: 'overlays.table.clicks',
                    placeholder: '',
                    size: 6
                }
            },
            {
                name: 'url',
                hidden: this.overlaysType !== StreamMarketingToolsOverlaysTypes.OVERLAY,
                config: {
                    inputType: INPUT_TYPES.INPUT,
                    label: 'overlay.link',
                    placeholder: '',
                    size: 6
                }
            }
        ];
    }

    private fillForm(): void {
        Object.keys(this.form.controls).forEach(control => {
            this.form.get(control).patchValue(this.data[control]);
        });
    }

    public start(): void {
        this.streamService.startOverlay({
            overlayId: this.overlayId,
            start: true
        }, this.overlaysType).pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.data.manualStarted = true;
            this.form.get('status').patchValue(this.localizeStatus(SURVEY_STATUS.ACTIVE));
        });
    }

    public stop(): void {
        this.streamService.startOverlay({
            overlayId: this.overlayId,
            start: false
        }, this.overlaysType).pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.data.manualStarted = false;
            this.form.get('status').patchValue(this.localizeStatus(SURVEY_STATUS.INACTIVE));
        });
    }

    public activateOverlay(): void {
        this.data.active = true;
        this.streamService.overlayActive({
            overlayId: this.overlayId,
            active: true
        }, this.overlaysType).pipe(takeUntil(this.destroy$)).subscribe(() => {
        });
    }

    public deactivateOverlay(): void {
        this.data.active = false;
        this.streamService.overlayActive({
            overlayId: this.overlayId,
            active: false
        }, this.overlaysType).pipe(takeUntil(this.destroy$)).subscribe(() => {
        });
    }

    private localizeStatus(status: SURVEY_STATUS): string {
        return this.localizationProvider.getByKey('survey.status.' + status.toLowerCase());
    }

    private parseSecondsToTime(seconds): string {
        let min: number | string = Math.floor(seconds / 60);
        let sec: number | string = seconds % 60;

        if (min < 10) {
            min = '0' + min;
        }

        if (sec < 10) {
            sec = '0' + sec;
        }

        return `${min}:${sec}`;
    }

    private listenStatusChange(): void {
        this.marketingService.connectToSocket(this.mediaId, this.mediaType)
            // this.marketingService.events()
            .pipe(takeUntil(this.destroy$))
            .subscribe(res => {
                if (!res) {
                    return;
                }

                if (res.results.data.type !== MARKETING_ENTITY_TYPE.OVERLAY) {
                    return;
                }
                const overlayStatus = res.results.data;
                if (overlayStatus.id !== this.data?.id) {
                    return;
                }

                if (overlayStatus.status === this.data.status) {
                    return;
                }

                this.data.status = overlayStatus.status;
                // this.data.active = overlayStatus.action === MARKETING_ACTION.SHOW;
                if (overlayStatus.action === MARKETING_ACTION.HIDE) {
                    this.data.manualStarted = false;
                }
                this.form.get('status').patchValue(this.localizeStatus(overlayStatus.status));
            });
    }

    public goToOverlaysPage() {
        let qp = {};
        let url = '../../';

        if ((this.activatedRoute.data as BehaviorSubject<any>)?.value?.standAlone) {
            url = '../../../';
            qp = { overlays: true};
        }
        this.router.navigate([url], {relativeTo: this.activatedRoute, queryParams: qp});
    }
}
