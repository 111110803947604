import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IFieldsConfig } from '@src/app/pages/user-profile/interfaces/interfaces-common';
import { ACTIONS, INPUT_TYPES } from 'ui-elements';
import { PublisherAction } from '../publisher/publisher.component';

@Component({
  selector: "app-viewer",
  templateUrl: "./viewer.component.html",
  styleUrls: ["./viewer.component.scss"],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewerComponent implements OnInit {
  INPUT_TYPES = INPUT_TYPES;
  @Input() public viewerForm: FormGroup;
  @Input() public firmForm: FormGroup;
  @Input() public termsForm: FormGroup;

  @Input() public viewerFieldsConfig: IFieldsConfig[][];
  @Input() public firmFieldsConfig: IFieldsConfig[][];
  @Input() public termsFieldsConfig: IFieldsConfig[][];
  @Input() public loading: { [key: string]: boolean } = {};
  @Input() public userAlreadyExistsError = false;

  @Output() action = new EventEmitter<PublisherAction>();

  constructor() {}

  ngOnInit(): void {
    this.resetForms();
  }

  submit() {
    this.action.emit({
      type: ACTIONS.SUBMIT,
      payload: null,
    });
  }

  private resetForms(): void {
    this.resetForm(this.viewerForm);
    this.resetForm(this.firmForm);
  }

  private resetForm(form: FormGroup): void {
    form.reset();
    form.markAsUntouched();
    form.markAsPristine();
    form.updateValueAndValidity();
  }
}

export type ViewerAction = ViewerCancel | ViewerSubmit;

export interface ViewerCancel {
  type: ACTIONS.CANCEL;
  payload?: null;
}

export interface ViewerSubmit {
  type: ACTIONS.SUBMIT;
  payload?: any;
}

