import {Component, Input, OnInit} from '@angular/core';
import {IStreamModel} from '@src/app/models/stream.model';
import {takeUntil} from 'rxjs/operators';
import {MediaDescriptionService} from '@src/app/pages/stream-page/components/media-overview/components/media-description/service/media-description.service';
import {AutoDestroyService, INPUT_TYPES} from 'ui-elements';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-media-description',
  templateUrl: './media-description.component.html',
  styleUrls: ['./media-description.component.scss'],
  providers: [AutoDestroyService]
})
export class MediaDescriptionComponent implements OnInit {
  FORM_MODE = FORM_MODE;
  INPUT_TYPES = INPUT_TYPES;
  @Input() media: IStreamModel;
  form: FormGroup = this.fb.group({
    description: ['', [Validators.required]]
  });

  public mode: FORM_MODE = FORM_MODE.VIEW;

  constructor(
      private destroy$: AutoDestroyService,
      private mediaDescriptionService: MediaDescriptionService,
      private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.form.get('description').setValue(this.media?.media?.description);
  }

  goToEditMode(): void {
    this.mode = FORM_MODE.EDIT;
  }

  save(): void {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }
    this.mediaDescriptionService.save(this.media.media.id, this.form.get('description').value).pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          if (!res.success) {
            return;
          }
          this.mode = FORM_MODE.VIEW;
          this.media.media.description = this.form.get('description').value;
          this.form.get('description').setValue(this.media?.media?.description);
        });
  }

  cancel(): void {
    this.mode = FORM_MODE.VIEW;
    this.form.get('description').setValue(this.media?.media?.description);
  }
}

enum FORM_MODE {
  VIEW, EDIT
}
