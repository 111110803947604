<h3 *ngIf="textHeaders?.mainHeader"><b>{{textHeaders.mainHeader | localize}}</b></h3>
<p *ngIf="textHeaders?.description">{{textHeaders.description | localize}}</p>
<p *ngIf="textHeaders?.subDescription">{{textHeaders.subDescription | localize}}</p>

<div class="card bg-white">
    <div class="col-xl-{{columnSize}}">
        <ano-loader [isOpen]="loadingService.regularLoading$ | async"></ano-loader>
        <div class="card-body media-upload-body">
            <ng-container *ngFor="let formConfig of formsConfig; let i = index">
                <ng-container>
                    <div *ngIf="formConfig.title" class="title-box" [class.border-top]="!formConfig.withoutTopPadding">
                        <h2 class="title-level-3" [class.title-margin-left-15]="marginLeft15">
                            <form [formGroup]="activateVideoForm"
                                  *ngIf="i === 0 && multimediaUrl && multimediaType === 'video' && isDataUploaded">
                                <mat-slide-toggle formControlName="active"
                                                  class="video-activation-toggle"></mat-slide-toggle>
                            </form>
                            {{formConfig.title | localize}}
                            <span class="light">&nbsp;&nbsp;{{formConfig.subtitle | localize}}</span>
                        </h2>
                        <p *ngIf="formConfig.teaserText">{{formConfig.teaserText | localize}}</p>
                    </div>

                    <div class="img-box" [class.edit-mode]="editMode">
                        <div class="image-wrapper img-edit" *ngIf="editMode && !isEditMode">
                            <div class="image-container">
                                <img *ngIf="multimediaUrl" class="img-preview" [src]="environment.backendApiHost + multimediaUrl" alt="">
                                <div *ngIf="!multimediaUrl" class="box-no-img">
                                    <span class="text-xlg">
                                            {{'profile.noImage.placeholder' | localize}}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <ng-container *ngIf="(!editMode && formConfig.form) || (editMode && isEditMode)">
                            <app-bordered-form class="image-wrapper"
                                               [multimediaType]="multimediaType"
                                               (clearImage$)="clearMultimedia()"
                                               [multimediaUrl]="multimediaUrl"
                                               [playerConfig]="playerConfig"
                                               [withoutLables]="true"
                                               [class.drag-and-drop]="i === 0"
                                               [config]="formConfig"
                                               [defaultForm]="formConfig.isDefault"
                                               [toggleForm]="formConfig.isToggle"
                                               [childForm]="formConfig.isChild"
                                               [form]="formConfig.form"
                                               [fieldsConfig]="formConfig.formFields"
                                               [title]="formConfig.title"
                                               [teaserText]="formConfig.teaserText"
                                               [withoutTopPadding]="formConfig.withoutTopPadding"
                                               [disabledForm]="(formConfig.isToggle && !formConfig.enabled)"
                                               (onFileUpload$)="uploadPreview($event)"
                                               [rowPaddingImage30]="true"
                                               [disabled]="disabled"
                            >
                            </app-bordered-form>
                            <app-progress-spinner *ngIf="i === 0" [isOpen]="loadingService.loading | async" [progress]="loadingService.progress | async"></app-progress-spinner>
                            <app-info-panel *ngIf="mediaLoaded && i === 0" [infoPanel]="afterUploadWarning"></app-info-panel>
                        </ng-container>
                    </div>

                </ng-container>
            </ng-container>

            <div *ngIf="isSinglePage && !disabled && !editMode"
                 class="button-group row align-items-center justify-content-end"
                 [class.border-top]="buttonsGroupBorderTop" [class.pt-0]="multimediaType !== 'photo'">
                <div class="form-group">
                    <button class="btn btn-success"
                            (click)="uploadMultimedia()">{{(thumbnail ? 'common.button.savePicture' : 'common.button.apply') | localize}}
                    </button>
                </div>
            </div>

            <form *ngIf="multimediaType === 'video' && withTable"
                  class="material-form last-no-border mb-30">
                <div class="row row-padding-0">
                    <label class="col-md-6 form-control-label">{{'trailer.show' | localize}}</label>
                    <div class="col-md-6">
                        <input type="text" class="form-control" disabled
                               [placeholder]="metadata?.views ? metadata?.views : 0">
                    </div>
                </div>
                <div class="row row-padding-0">
                    <label class="col-md-6 form-control-label">{{'trailer.clicks' | localize}}</label>
                    <div class="col-md-6">
                        <input type="text" class="form-control" disabled
                               [placeholder]="metadata?.clicks ? metadata?.clicks : 0">
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="align-self-end col-auto p-0" *ngIf="editMode && !disabled">
        <div class="card-body">
            <div class="buttons-container">
                <button class="btn cancel-button {{ 'btn-danger' }}" *ngIf="isEditMode"
                        (click)="cancel()">{{'common.button.close' | localize}}
                </button>
                <button class="btn"
                        [class.btn-primary]="!isEditMode"
                        [class.btn-success]="isEditMode"
                        (click)="changeEditMode()">{{ (editButtonText()) | localize }}</button>
            </div>

        </div>
    </div>
</div>
