<div
    class="area-stream"
    [class.landing-card]="config?.isLanding"
    [class.actual-card]="config?.isActual"
    [class.disabled-card]="visibilityOnlyIframe"
    (click)="click($event)">
    <div class="area-stream-header {{config?.streamHeaderCssClass}}">
        <div *ngIf="previewPicture?.photoUrl" class="area-stream-header-image-container" [id]="'picture-' + id">
        </div>
        <div *ngIf="visibilityOnlyIframe" class="only-iframe">
            <b>{{'media-list.media-card.only-iframe' | localize}}</b>
        </div>
        <div *ngIf="config?.actions?.remove && !data?.live" class="close remove-vod" (click)="toggleRemove($event)">
            <i class="icon icon-trash2"></i>
        </div>
        <div *ngIf="!previewPicture?.photoUrl" class="overlay-default"></div>
        <ng-container *ngIf="data?.videoType === MEDIA_TYPE.STREAM && !config?.hideInfoLines">
            <span class="status-video status-video-1 {{config?.infoLineCssClass}}"
                  [ngStyle]="{'background-color': (data.status | itemSetting: {
                itemType: ITEM_TYPE.STREAM,
                settingType: SETTING_TYPE.COLOR,
                skipRecord: true,
                ignoreNoneRecord: false
            })}">
                <i class="icon icon-dot-12"></i> {{ (data.status | itemSetting: {
                itemType: ITEM_TYPE.STREAM,
                settingType: SETTING_TYPE.TRANSLATE,
                skipRecord: true,
                ignoreNoneRecord: false
            }) | localize }}
            </span>
            <span class="status-video status-video-2 bg-color-orange {{config?.infoLineCssClass}}"
                  *ngIf="(data?.status?.record !== RECORD_STATUS.NONE || data?.status?.stream === STREAM_STATUS.FINISHED)">
                {{(data.status | itemSetting: {
                itemType: ITEM_TYPE.STREAM,
                settingType: SETTING_TYPE.TRANSLATE,
                skipRecord: false,
                ignoreNoneRecord: true
            }) | localize}}
            </span>
            <span [ngStyle]="{'background-color': (data.status | itemSetting: {
                itemType: ITEM_TYPE.STREAM,
                settingType: SETTING_TYPE.COLOR,
                skipRecord: true,
                ignoreNoneRecord: false
            })}" class="status-video status-video-2 {{config?.infoLineCssClass}}"
                  *ngIf="(data?.status?.stream === STREAM_STATUS.SCHEDULED || data?.status?.stream === STREAM_STATUS.RESCHEDULING) && data.showStreamDate !== false">{{ data?.startTimestamp | dateTimeFormat: 'DD MMMM, HH:mm' }}
            </span>
        </ng-container>
    </div>
    <div class="area-stream-body" [class.bg-light]="config?.backgroundClass">
        <h3 class="media-title">{{config?.groupView ? data?.name : data?.title}}</h3>
        <div class="media-desc">{{data?.description}}</div>
        <div *ngIf="!config?.groupView && !config?.isLanding && data.showStreamDate !== false" class="stream-info">
            <span class="block-with-right-separator">
                {{(data?.startedAt || data?.startTimestamp) | dateTimeFormat: 'DD MMM YYYY, HH:mm'}}
            </span>
            <span *ngIf="config?.elements?.country" class="block-with-right-separator">
                {{data?.publisherInfo?.country | keyToValue: countries}}
            </span>
            <span class="stream-genre">
                {{data?.genre | keyToValue: genres}}
            </span>
        </div>
        <div *ngIf="config?.groupView && !config?.isLanding" class="stream-info">
            {{data?.mediaItems?.length}} {{(data?.mediaItems?.length > 1 ? 'media-list.media-card.items' : 'media-list.media-card.item') | localize}}
        </div>
        <div class="article-footer" *ngIf="config?.isLanding">
            <ul class="livestream-data">
                <li>{{'stream.title.start' | localize}}: {{(data?.startedAt || data?.startTimestamp) | dateTimeFormat: 'DD MMM YYYY, HH:mm'}}</li>
                <li>{{'vod.field.genre'| localize}}: {{data?.genre | keyToValue: genres}}</li>
                <li *ngIf="data?.groups?.length && config?.elements?.group">{{'nav.groups' | localize}}: {{data?.groups[0].name}}</li>
                <li><span class="price">{{'media-list.media-card.price' | localize}}: {{data?.price.amount ? (data?.price | currency) : 'Free'}}</span></li>
            </ul>

            <div class="row row-padding-10">
                <div class="col-auto">
                    <button class="btn btn-sm btn-primary">{{'landing-page.goToStream' | localize}}</button>
                </div>
                <div class="col-auto" *ngIf="data?.groups?.length && config?.elements?.group">
                    <button class="btn btn-sm btn-success" (click)="goToGroupPage($event, data?.groups[0].id)">{{'landing-page.goToGroup' | localize}}</button>
                </div>
            </div>
        </div>
        <div class="stream-info publisher-info" *ngIf="!config?.isLanding">
            <span *ngIf="config?.elements?.publisher && (data?.publisherInfo?.name || data?.publisherInfo?.picture?.photoUrl)"
                  class="block-with-right-separator">
                <img *ngIf="data?.publisherInfo?.picture?.photoUrl" class="publisher-photo"
                     [src]="environment.backendApiHost + data?.publisherInfo?.picture?.photoUrl || '/assets/img/mock/no-user-photo.png'">
                <span>
                    {{data?.publisherInfo?.name}}
                </span>
            </span>
            <span *ngIf="!config?.hidePrice"
                  class="card-word"
                  [class.block-with-right-separator]="data?.groups?.length && config?.elements?.group">
                <b>{{data?.price.amount ? (data?.price | currency) : 'Free'}}</b>
            </span>
            <span *ngIf="data?.groups?.length && config?.elements?.group">
                <b [class.block-with-right-separator]="data?.groups?.length > 1"
                   class="card-word"
                   (click)="goToGroupPage($event, data?.groups[0].id)">{{data?.groups[0].name}}</b>
                <ng-container *ngIf="data?.groups?.length > 1">
                    <b class="card-word">{{data?.groups?.length - 1}} {{((data?.groups?.length - 1) > 1 ? 'media-list.media-card.more-groups' : 'media-list.media-card.more-group') | localize}}</b>
                </ng-container>
            </span>
        </div>
    </div>
</div>
<ng-template #confirm let-dialogRef="dialogRef">
    <ano-modal [dialogRef]="dialogRef" [title]="config?.actions?.removeConfirmTitle | localize" [isConfirmation]="true">
        {{data.title + ' ' + (config?.actions?.removeConfirmBody | localize)}}
    </ano-modal>
</ng-template>
