import {AfterContentInit, Directive, Input} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {FormService} from "@src/app/services/form/form.service";
import {AutoDestroyService} from "@src/app/services/auto-destroy-service/auto-destroy.service";
import {takeUntil} from "rxjs/operators";

@Directive({
  selector: '[appScrollToFirstError]',
  providers: [AutoDestroyService]
})
export class ScrollToFirstErrorDirective implements AfterContentInit {
  @Input() forms: FormGroup[];
  constructor(
      private formService: FormService,
      private autoDestroyService: AutoDestroyService
  ) { }

  ngAfterContentInit(): void {
    this.formService.submitInvalid$
        .pipe(takeUntil(this.autoDestroyService))
        .subscribe(() => {
      this.scrollToFirstErrorField();
    });
  }

  public scrollToFirstErrorField(): void {
    let firstErrorFieldName = '';
    if (!this.forms) { return; }
    this.forms.forEach(form => {
      if (firstErrorFieldName) {
        return;
      }

      Object.keys(form.controls).forEach(control => {
        if (firstErrorFieldName) {
          return;
        }
        if (form.get(control).invalid) {
          firstErrorFieldName = control;
          return;
        }
      });
    });
    const errorField = document.getElementById(firstErrorFieldName);
    if (!errorField) {
      return;
    }

    errorField.scrollIntoView({
      block: 'center',
      behavior: 'smooth'
    });
  }

}
