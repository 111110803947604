<div class="profile-section login-section gray-section">
  <div class="container">
        <span class="main-icon-label">
            <i class="icon-email"></i>
        </span>
  </div>
</div>

<div class="content content-profile">
  <div class="container">
    <app-info-box class="d-block mb-3"
                  *ngIf="isEmailSent"
                  [type]="INFO_BOX_TYPES.INFO">
      <ng-container role="title">{{ 'passwordForgotten.label.done' | localize }}</ng-container>
      <ng-container role="message">
        {{ 'passwordForgotten.label.passwordResettingLinkWasSentTo' | localize }} {{ passwordForgottenForm.get('email').value }}
      </ng-container>
      <ng-container role="buttons">
        <ano-button
          [config]="{
          styleType: 'secondary',
          sizeType: 'block'
        }"
          routerLink="login">
          {{ 'passwordForgotten.button.backToLogin' | localize }}
        </ano-button>
      </ng-container>
    </app-info-box>
    <form [formGroup]="passwordForgottenForm" class="form-signin" (ngSubmit)="remindPassword()" *ngIf="!isEmailSent">
      <span *ngFor="let error of serverErrors"
            class="invalid-feedback text-center d-block mb-2 mt-0">
        {{ error.text }}
      </span>

      <div class="form-group">
        <ano-input
          formControlName="email"
          [isTouched]="passwordForgottenForm.get('email').touched"
          [errors]="passwordForgottenForm.get('email').errors"
          [config]="{
            type: 'email',
            placeholder: 'passwordForgotten.placeholder.email' | localize
          }"></ano-input>
      </div>

      <div class="form-group">
        <ano-button
          [config]="{
            type: 'submit',
            styleType: 'secondary',
            sizeType: 'block',
            isDisabled: loading.passwordReminding
          }">
          {{ 'passwordForgotten.button.sendPasswordResettingLink' | localize }}
        </ano-button>
      </div>
      <div class="form-group forgot-password-group">
        <a class="btn btn-light btn-block" href="#" routerLink="login">
          {{ 'passwordForgotten.button.backToLogin' | localize }}
        </a>
      </div>
    </form>
  </div>
</div>
