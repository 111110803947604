import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {takeUntil, tap} from 'rxjs/operators';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import {SnackBarService} from 'ui-elements';
import {MEDIA_TYPE} from '@src/app/components/streamdust-player/constants/mediaType';
import {BehaviorSubject} from 'rxjs';
import {DateHelper} from '@src/app/utils/date.helper';
import {IStreamModel} from '@src/app/models/stream.model';
import {StreamService} from '@src/app/services/stream-metadata/stream.service';
import {LocalizationProvider} from 'ui-elements';

@Component({
    selector: 'app-stream-viewer-link',
    templateUrl: './stream-viewer-link.component.html',
    styleUrls: ['./stream-viewer-link.component.sass'],
    providers: [AutoDestroyService]
})
export class StreamViewerLinkComponent implements OnInit {
    @Input() public showEmbeddedLink = true;
    stream: IStreamModel;
    public mediaId: string;
    public mediaType: MEDIA_TYPE;
    public linkValue = '';
    public iframeValue = '';
    public chatIframeValue = '';

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private destroy$: AutoDestroyService,
        private snackBarService: SnackBarService,
        private streamService: StreamService,
        private localizationProvider: LocalizationProvider,
    ) {
    }

    ngOnInit(): void {
        this.activatedRoute.parent.params.pipe(
            takeUntil(this.destroy$),
            tap(({id}: Params) => {
                this.mediaId = id;
                this.mediaType = this.resolveMediaType();

            })).subscribe(() => {
            this.linkValue = this.resolveVideoLink();
            this.iframeValue = '<div style="display: block!important; position: relative!important;padding-bottom: 56.25%!important; height: 0!important; overflow: hidden!important"><' +
                'iframe id="streamdust-' + this.mediaId.substring(0, 8) + '" style="position: absolute!important;top: 0!important; left: 0!important;width: 100%!important;height: 100%!important;" src="' + this.resolveVideoLink(true)
                + '" width="640" height="360" frameborder=0 allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true" oallowfullscreen="true" msallowfullscreen="true"></iframe></div>';

            this.chatIframeValue = '<div style="display: block!important; position: relative!important;padding-bottom: 56.25%!important; height: 0!important; overflow: hidden!important"><' +
                'iframe id="streamdust-' + this.mediaId.substring(0, 8) + '-chat" style="position: absolute!important;top: 0!important; left: 0!important;width: 100%!important;height: 100%!important;" src="' + this.resolveVideoLink() + '/chat'
                + '" width="640" height="360" frameborder=0 allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true" oallowfullscreen="true" msallowfullscreen="true"></iframe></div>';

        });

        this.streamService.stream$.pipe(takeUntil(this.destroy$)).subscribe(res => {
            this.stream = res;
        });
    }

    private resolveMediaType(): MEDIA_TYPE {
        return (this.activatedRoute.data as BehaviorSubject<any>)?.value?.mediaType;
    }

    private resolveVideoLink(embedded: boolean = false): string {
        const mainUrlSegment = window.location.protocol + '//' + window.location.host
            + (embedded ? '/embedded' : '/watch');
        switch (this.mediaType) {
            case MEDIA_TYPE.STREAM:
            case MEDIA_TYPE.STREAM_RECORDING:
                return mainUrlSegment + '/stream/' + this.mediaId;
            case MEDIA_TYPE.VIDEO_ON_DEMAND:
                return mainUrlSegment + '/vod/' + this.mediaId;

        }
    }

    public copyValue(value: string, isIFrameLink: boolean): void {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = value;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        const toastText = isIFrameLink ? 'Copied "STREAM AUF EIGENER WEBSEITE EINBINDEN"' : 'Copied "ZUSCHAUERLINK"';
        this.snackBarService.showInfo(toastText);
    }

    mailTo(body) {
        const res =
            this.stream.media.title + '\r\n\r\n' +
            this.stream.media.description + '\r\n' +
            DateHelper.formatDateTime(this.stream.media.startTimestamp, null, true) +
            ' - ' + DateHelper.formatDateTime(this.stream.media.endTimestamp, null, true) + '\r\n\r\n\r\n' +
            body;

        const subject = this.localizationProvider.getByKey('stream.data.embedded.title');

        window.open('mailto:?subject=' + subject + `&body=${encodeURIComponent(res)}`);
    }

    openLink(link) {
        window.open(link, '_blank');
    }

}
