/**
 * Created by Тима on 03.04.2020.
 */
import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {
    ProfileService,
    IContactInfo,
    IUserProfile,
    IUserPublisher, ICompanyInfo, IHeaderPictureMetadata
} from '@src/app/pages/user-profile/components/profile/services/profile.service';
import {IFieldsConfig} from '@src/app/pages/user-profile/interfaces/interfaces-common';
import {take, takeUntil} from 'rxjs/operators';
import {PhotoService} from '@src/app/services/photo/photo.service';
import {PhotoCropperService} from '@src/app/services/photo-cropper/photo-cropper.service';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import {IPublisherPayload} from '@src/app/models/stream.model';
import {UserService} from '@src/app/services/user/user.service';
import {environment} from '@src/environments/environment';
import {CustomValidators} from '@src/app/utils/custom-validators.util';
import {LoadingService} from '@src/app/services/loading/loading.service';
import {IFormConfig, IPreviewPicture} from '@src/app/services/stream-metadata/stream.service';
import {USER_TYPE} from '@src/app/constants/user-type.constant';
import {forkJoin} from 'rxjs';
import {IPicture} from '@src/app/models/core.model';

@Component({
    selector: 'app-edit-user-data',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.sass'],
    providers: [ProfileService, AutoDestroyService]
})
export class ProfileComponent implements OnInit {
    public USER_TYPE = USER_TYPE;
    public userType: USER_TYPE;

    userProfile: IUserProfile;
    publisherInfo: IUserPublisher;
    contactInfo: IContactInfo;
    form: FormGroup;
    publisherForm: FormGroup;
    companyForm: FormGroup;
    contactsForm: FormGroup;
    public fieldsConfig: IFieldsConfig[];
    public publisherFieldsConfig: IFieldsConfig[];
    public firmFieldsConfig: IFieldsConfig[];
    public companyFieldsConfig: IFieldsConfig[];
    public contactsFieldsConfig: IFieldsConfig[];
    public publisherPhotoUrl: string;
    public avatar: string;
    public avatarRequest = {
        workbenchId: '',
        previewSettings: {
            x: 0,
            y: 0,
            width: 0,
            height: 0,
            rotation: 0
        }
    };
    public publisherPayload: IPublisherPayload = {
        name: '',
        description: '',
        website: '',
        newPicture: {
            workbenchId: '',
            previewSettings: {
                x: 0,
                y: 0,
                width: 0,
                height: 0,
                rotation: 0
            }
        },
        leadTextField1: '',
        leadTextField2: '',
        customFieldOn: false,
        customFieldName: '',
    };
    public environment = environment;

    public publisherHeader: IPicture;

    streamHeader: IPreviewPicture = {
        workbenchId: '',
        previewSettings: {
            x: 0,
            y: 0,
            width: 0,
            height: 0,
            rotation: 0,
        }
    };

    streamHeaderFormsConfig: IFormConfig[];
    public userName: string;
    private headerPictureMetadata: IHeaderPictureMetadata;

    constructor(
        private profileService: ProfileService,
        private fb: FormBuilder,
        private photoService: PhotoService,
        private readonly photoCropperService: PhotoCropperService,
        private destroy$: AutoDestroyService,
        private userService: UserService,
        public loadingService: LoadingService,
    ) {
    }

    ngOnInit() {
        this.userService.userProfile$.pipe(takeUntil(this.destroy$))
            .subscribe((userProfile) => {
                if (userProfile) {
                    this.resolveUserName();
                }
            });

        this.loadPublisherHeaderPicture();

        this.userService.userType$.pipe(takeUntil(this.destroy$), take(2))
            .subscribe((userType) => {
                this.userType = userType;
                this.buildForms();
                this.loadFieldsConfigs();
                this.loadProfileInfo();
                this.streamHeaderFormsConfig = this.profileService.getStreamHeaderFormsConfig();
            });
    }

    private buildForms(): void {
        this.form = this.createEmptyProfileForm();
        this.companyForm = this.createFirmForm();
        this.publisherForm = this.createPublisherForm();
        this.contactsForm = this.createContactsForm();
    }

    private loadFieldsConfigs(): void {
        this.fieldsConfig = this.profileService.getFieldsConfig();
        this.publisherFieldsConfig = this.profileService.getPublisherFieldsConfig();
        this.firmFieldsConfig = this.profileService.getFirmFieldsConfig();
        // this.companyFieldsConfig = this.profileService.getCompanyFieldsConfig();
        this.contactsFieldsConfig = this.profileService.getContactsFieldsConfig(this.userType);
    }

    private loadProfileInfo(): void {
        forkJoin({
            userProfile: this.profileService.getUserProfile(),
            publisherInfo: this.profileService.getUserPublisher(),
            contactsInfo: this.profileService.getContacts(),
            // publisherHeader: this.profileService.getPublisherHeader()
        }).pipe(takeUntil(this.destroy$))
            .subscribe((res) => {
                if (res.contactsInfo) {
                    this.contactInfo = res.contactsInfo;
                    this.fillContactForm();
                }
                this.userProfile = res.userProfile;
                this.avatar = (this.userProfile.profilePhoto && this.environment.backendApiHost + this.userProfile.profilePhoto.photoUrl) || '';
                this.fillProfileForm();
                if (res.publisherInfo) {
                    this.publisherInfo = res.publisherInfo;
                    this.fillPublisherForm();
                }

                if (res.userProfile.companyInfo) {
                    this.fillCompanyForm();
                }
            });
    }


    private loadPublisherHeaderPicture(): void {
        this.userService.publisherHeaderPicture$.pipe(takeUntil(this.destroy$))
            .subscribe(res => {
                this.publisherHeader = res;
                if (this.publisherHeader && this.streamHeaderFormsConfig) {
                    this.streamHeaderFormsConfig[0].title = 'edit-user-data.stream-header.title.uploaded';
                    this.streamHeaderFormsConfig[0].subtitle = null;
                }
            });
    }
    public saveChanges(): void {
        if (this.form.invalid) {
            return;
        }
        this.loadingService.loadingStart();
        const reqObj: IUserProfile = {
            firstName: this.form.value.firstName,
            lastName: this.form.value.lastName,
            chatName: this.form.value.chatName,
            street: this.form.value.street,
            postalCode: this.form.value.postalCode,
            city: this.form.value.city,
        };

        if (this.avatarRequest.workbenchId) {
            reqObj.newPicture = this.avatarRequest;
            this.avatarRequest = {
                workbenchId: '',
                previewSettings: {
                    x: 0,
                    y: 0,
                    width: 0,
                    height: 0,
                    rotation: 0
                }
            };
        } else {
            reqObj.pictureId = (this.userProfile.profilePhoto && this.userProfile.profilePhoto.photoId) || '';
        }

        this.profileService.saveProfile(reqObj).pipe(takeUntil(this.destroy$)).subscribe(
            (res) => {
                if (this.userType === USER_TYPE.VIEWER) {
                    this.profileService.saveContactInfo({
                        email: this.form.value.email,
                        phone: this.form.value.phone,
                        website: null
                    }).pipe(takeUntil(this.destroy$)).subscribe(
                        () => {
                            this.loadProfile();
                            this.loadingService.loadingEnd();
                        }, () => this.loadingService.loadingEnd());
                } else {
                    this.loadProfile();
                    this.loadingService.loadingEnd();
                }
            },
            (error) => {
                this.loadingService.loadingEnd();
            }
        );
    }

    public savePublisherChanges(): void {
        if (!this.publisherPayload.newPicture?.workbenchId) {
            delete this.publisherPayload.newPicture;
            this.publisherPayload.pictureId = (this.publisherInfo.picture && this.publisherInfo.picture.photoId) || '';
        }
        if (this.publisherForm.invalid) {
            return;
        }
        this.loadingService.loadingStart();
        this.publisherPayload.name = this.publisherForm.get('name').value;
        this.publisherPayload.description = this.publisherForm.get('description').value;
        this.publisherPayload.website = this.publisherForm.get('website').value;
        this.publisherPayload.leadTextField1 = this.publisherForm.get('leadTextField1').value;
        this.publisherPayload.leadTextField2 = this.publisherForm.get('leadTextField2').value;
        this.publisherPayload.customFieldOn = this.publisherForm.get('customFieldOn').value;
        this.publisherPayload.customFieldName = this.publisherForm.get('customFieldName').value;
        this.profileService.saveUserPublisher(this.publisherPayload).subscribe(
            (res) => {
                this.loadingService.loadingEnd();
                if (!res.success) {
                    return;
                }
            },
            (error) => {
            }
        );
    }

    public saveContactsChanges(): void {
        this.contactsForm.markAllAsTouched();
        if (this.contactsForm.invalid) {
            return;
        }
        this.loadingService.loadingStart();
        this.profileService.saveContactInfo(this.contactsForm.getRawValue())
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => this.loadingService.loadingEnd(), () => this.loadingService.loadingEnd());
    }

    public saveCompanyChanges(): void {
        this.companyForm.markAllAsTouched();
        if (this.companyForm.invalid) {
            return;
        }
        this.loadingService.loadingStart();
        this.profileService.saveCompany(this.companyForm.getRawValue())
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => this.loadingService.loadingEnd(), () => this.loadingService.loadingEnd());
    }

    public createEmptyProfileForm(): FormGroup {
        return this.fb.group({
            'firstName': ['', [Validators.required]],
            'lastName': ['', [Validators.required]],
            'chatName': [''],
            'street': ['', [Validators.required]],
            'postalCode': ['', [Validators.required]],
            'city': ['', [Validators.required]],
            'country': ['', [Validators.required]],
            'phone': ['', [Validators.required]],
            'email': ['', [Validators.required]],
        });
    }

    public fillProfileForm(): void {
        const controls = this.form.controls;
        controls.firstName.setValue(this.userProfile?.firstName);
        controls.lastName.setValue(this.userProfile?.lastName);
        controls.chatName.setValue(this.userProfile?.chatName);
        controls.street.setValue(this.userProfile?.address?.street);
        controls.postalCode.setValue(this.userProfile?.address?.postalCode);
        controls.city.setValue(this.userProfile?.address?.city);
        controls.country.setValue(this.userProfile?.address?.country);
        controls.phone.setValue(this.userProfile?.address?.phone);
        controls.email.setValue(this.userProfile?.email);
    }

    public createPublisherForm(): FormGroup {
        return this.fb.group({
            'name': ['', [Validators.required]],
            'description': ['', [Validators.required]],
            'website': ['', [Validators.required]],
            'newPicture': ['', [Validators.required]],
            'leadTextField1': [''],
            'leadTextField2': [''],
            'customFieldOn': [''],
            'customFieldName': [''],
        });
    }

    public createFirmForm(): FormGroup {
        return this.fb.group({
            'company': [''],
            'vatId': ['', Validators.required],
        });
    }

    public fillPublisherForm(): void {
        const controls = this.publisherForm.controls;
        controls.name.setValue(this.publisherInfo?.name);
        controls.description.setValue(this.publisherInfo?.description);
        controls.website.setValue(this.publisherInfo?.website);
        controls.leadTextField1.setValue(this.publisherInfo?.leadTextField1);
        controls.leadTextField2.setValue(this.publisherInfo?.leadTextField2);
        controls.customFieldOn.setValue(this.publisherInfo?.customFieldOn);
        controls.customFieldName.setValue(this.publisherInfo?.customFieldName);
        controls.newPicture.setValue((this.publisherInfo?.picture && this.publisherInfo?.picture?.photoUrl) || '');
        this.publisherPhotoUrl = (this.publisherInfo?.picture && this.publisherInfo?.picture?.photoUrl) || '';
    }

    private fillContactForm(): void {
        const controls = this.contactsForm.controls;
        controls.phone.setValue(this.contactInfo?.phone);
        controls.email.setValue(this.contactInfo?.email);
        controls.website.setValue(this.contactInfo?.website);
    }

    private fillCompanyForm(): void {
        const controls = this.companyForm.controls;
        controls.company.setValue(this.userProfile?.companyInfo?.company);
        controls.vatId.setValue(this.userProfile?.companyInfo?.vatId);
    }

    public createContactsForm(): FormGroup {
        return this.fb.group({
            'phone': [''],
            'email': ['', [CustomValidators.email]],
            'website': ['', CustomValidators.URL]
        });
    }

    public uploadPublisherPhoto(file): void {
        this.photoCropperService.completeWorkbenchReadySubject();

        if (!file) {
            return;
        }

        this.photoService.generateUploader({file: file.files, aspectRatio: 1})
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                ({croppingParams, workbenchId, croppedImage}) => {
                    if (croppedImage) {
                        this.publisherPhotoUrl = croppedImage;
                    } else {
                        const reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = () => {
                            this.publisherPhotoUrl = reader.result.toString();
                        };
                    }
                    this.photoCropperService.completeWorkbenchReadySubject();
                    this.publisherPayload.newPicture.workbenchId = workbenchId;
                    this.publisherPayload.newPicture.previewSettings = croppingParams;
                    this.publisherForm.get('newPicture').patchValue(workbenchId);
                    this.publisherForm.get('newPicture').markAsTouched();
                },
            );
    }

    public uploadAvatar([file]): void {
        this.photoCropperService.completeWorkbenchReadySubject();

        if (!file) {
            return;
        }

        this.photoService.generateUploader({file, aspectRatio: 1})
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                ({croppingParams, workbenchId, croppedImage}) => {
                    if (croppedImage) {
                        this.avatar = croppedImage;
                    } else {
                        const reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = () => {
                            this.avatar = reader.result.toString();
                        };
                    }
                    this.photoCropperService.completeWorkbenchReadySubject();
                    this.avatarRequest.workbenchId = workbenchId;
                    this.avatarRequest.previewSettings = croppingParams;
                },
            );
    }

    public clearPublisherImage(): void {
        this.publisherPhotoUrl = '';
        this.publisherForm.get('newPicture').patchValue('');
        this.publisherPayload.newPicture = {
            workbenchId: '',
            previewSettings: {
                x: 0,
                y: 0,
                width: 0,
                height: 0,
                rotation: 0
            }
        };
    }

    public loadProfile(): void {
        this.userService.refreshUserProfile();
        this.userService.userProfile$.pipe(takeUntil(this.destroy$))
            .subscribe((res) => {
                this.userProfile = res;
                this.avatar = (this.userProfile.profilePhoto && this.environment.backendApiHost + this.userProfile.profilePhoto.photoUrl) || '';
                this.fillProfileForm();
                this.loadingService.loadingEnd();
            });
    }

    public clearAvatar(): void {
        this.avatar = (this.userProfile.profilePhoto && this.environment.backendApiHost + this.userProfile.profilePhoto.photoUrl) || '';
    }

    public deleteAvatar(): void {
        this.userProfile.profilePhoto.photoId = null;
        this.avatar = null;
    }


    resolveUserName(): void {
        this.userService.userProfile$.pipe(takeUntil(this.destroy$))
            .subscribe((res: IUserProfile) => {
                if (!res) {
                    this.userName = null;
                    return;
                }

                if (!res.firstName && !res.lastName) {
                    this.userName = res.email;
                    return;
                }

                this.userName = res.firstName + ' ' + res.lastName;
            });
    }

    public uploadHeaderPicture(metadata: any): void {
        this.headerPictureMetadata = {newPicture: metadata};
        this.streamHeaderFormsConfig[0].title = 'edit-user-data.stream-header.title.uploaded';
        this.streamHeaderFormsConfig[0].subtitle = null;
    }

    public saveHeaderPicture(): void {
        let payload: IHeaderPictureMetadata;
        if (!this.publisherHeader && !this.headerPictureMetadata) {
            payload = {pictureId: null}
        }
        if (!this.publisherHeader && this.headerPictureMetadata) {
            payload = this.headerPictureMetadata;
        }
        if (this.publisherHeader && !this.publisherHeader.photoId) {
            payload = this.headerPictureMetadata ? this.headerPictureMetadata :   {pictureId: null}
        }
        if (this.publisherHeader && this.publisherHeader.photoId) {
            payload = {pictureId: this.publisherHeader.photoId}
        }
        this.profileService.savePublisherHeader(payload)
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                this.headerPictureMetadata = null;
                this.userService.refreshHeaderPicture();
            });
    }

    public clearPublisherHeaderPicture(): void {
        this.streamHeaderFormsConfig[0].title = 'edit-user-data.stream-header.title';
        this.streamHeaderFormsConfig[0].subtitle = 'edit-user-data.stream-header.subtitle';
        this.publisherHeader.photoId = null;
    }
}
