<div class="vo-chart-group">
    <div class="vo-chart-list">
        <div class="vo-chart-item" *ngFor="let item of data; let i=index;">
            <div class="vo-chart-visual">
                <div class="vo-chart-inside" [ngStyle]="{'height': item.value + '%', 'background': COLORS[i]}"></div>
            </div>
            <span class="vo-chart-name">{{item.text}}</span>
            <span class="vo-chart-percent">{{item.value}}<span>%</span></span>
        </div>
    </div>
</div>