import {BehaviorSubject, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {Subject} from 'rxjs/internal/Subject';

@Injectable({providedIn: 'root'})
export class SlotsModalState {
    private isOpened = false;
    public openSubj: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public isOpened$: Observable<boolean> = this.openSubj.asObservable();
    public paymentStatus$: BehaviorSubject<{ groupId: string, status: boolean }> = new BehaviorSubject<{ groupId: string, status: boolean }>(null);
    public paymentForAllMedia$: Subject<any> = new Subject<any>();
    public currentSlot$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    public isPayedAll$ = new BehaviorSubject<boolean>(false);

    private paymentSuccess: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    public paymentSuccess$ = this.paymentSuccess.asObservable();

    constructor() {
    }

    public successfulPayment(id: string): void {
        this.paymentSuccess.next(id);
    }

    public setState(state: boolean) {
        this.isOpened = state;
        this.openSubj.next(this.isOpened);
    }

    public toggle(): void {
        this.isOpened = !this.isOpened;
        this.openSubj.next(this.isOpened);
    }

    public paymentForAllMedia(): void {
        this.paymentForAllMedia$.next();
    }

    public resetCurrentSlot(): void {
        this.currentSlot$.next(null);
    }
}
