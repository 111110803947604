import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PasswordForgottenPageComponent } from '@src/app/pages/password-forgotten-page/password-forgotten-page.component';
import { SharedModule } from '@src/app/modules/shared/shared.module';

@NgModule({
  declarations: [PasswordForgottenPageComponent],
  imports: [
    CommonModule,
    SharedModule,
  ],
  exports: [PasswordForgottenPageComponent]
})
export class PasswordForgottenPageModule { }
