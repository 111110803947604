<div class="box-dashboard" *ngIf="mediaType">
    <div class="content">
        <div class="panel panel-md">
            <div class="panel-header page-title-panel">
                <h2 class="title-lg">
                    <b>{{ (mediaType === MEDIA_TYPE.STREAM ? ((streamService?.stream$ | async)?.media?.hasRecording ?
                        'stream.recording.edit' : isCreateMode ?
                            'stream.create.title' : 'stream.create.edit') : ((isCreateMode ? 'vod.create.title' : 'vod.edit.title'))) | localize }}</b>
                </h2>
                <p class="page-description" *ngIf="mediaType === MEDIA_TYPE.STREAM">{{(isCreateMode ? 'stream.page.description' : 'stream.page.description.edit') | localize}}</p>
                <p class="page-description" *ngIf="mediaType === MEDIA_TYPE.VIDEO_ON_DEMAND">{{'vod.description' | localize}}</p>
            </div>
        </div>
    </div>
</div>

<div class="panel panel-md" appScrollToFirstError [forms]="getForms()">
    <div class="panel-body bg-white">
        <ano-loader [isOpen]="loadingService.loading | async" ></ano-loader>
        <div class="row justify-content-between align-items-end">
            <div class="col-xl-8 mr-auto">
                <app-stream-multimedia-upload *ngIf="mediaType === MEDIA_TYPE.VIDEO_ON_DEMAND"
                                              class="video-on-demand-upload"
                                              [isSinglePage]="false"
                                              [columnSize]="12"
                                              [multimediaType]="'video'"
                                              [metadata]="advertisementVideo"
                                              [multimediaUrl]="advertisementVideo?.videoUrl"
                                              [formsConfig]="videoFormConfig"
                                              [withTable]="false"
                                              [actualResultText]="'vod.actualVideo'"
                                              [buttonsGroupBorderTop]="false"
                                              [disabled]="(streamService.stream$ | async)?.media?.deactivated"
                                              (uploadMultimedia$)="uploadVideo($event)"
                                              (clearMultimedia$)="clearVideo($event)"
                >
                </app-stream-multimedia-upload>
                <ng-container *ngFor="let formConfig of formsConfig; let i = index">
                    <ng-container *ngIf="!formConfig.hidden">
                        <div class="section-panel" [class.border-top]="!formConfig.withoutTopPadding">
                            <div *ngIf="formConfig?.title" class="section-panel-header"
                                 [class.border-top]="!formConfig?.withoutTopPadding">
                                <h2 #h2_1 class="title-level-3">{{formConfig.title | localize: h2_1 }}</h2>
                                <app-info-panel *ngIf="showInfoPanel && i === 0"
                                                [infoPanel]="infoPanel"></app-info-panel>
                                <p *ngIf="formConfig.teaserText">{{formConfig.teaserText | localize}}</p>
                            </div>
                            <app-info-panel *ngIf="formConfig?.formInfoPanel?.show"
                                            [infoPanel]="formConfig?.formInfoPanel?.config"></app-info-panel>
                            <ng-container *ngIf="formConfig.isToggle">
                                <app-toggle
                                        [value]="formConfig.enabled"
                                        [config]="formConfig"
                                        [disabled]="(streamService.stream$ | async)?.media?.deactivated || (streamService.stream$ | async)?.media?.status?.stream === STREAM_STATUS.PENDING"
                                        (toggleEmit)="toggleEmit($event)"
                                ></app-toggle>
                            </ng-container>
                            <ng-container *ngIf="formConfig.form">
                                <div class="section-panel-body" [class.error-payments]="showAccesError"
                                     [class.padding-0]="formConfig.withoutBottomPadding">
                                    <app-bordered-form
                                            [readonly]="formConfig.readonly || ((streamService.stream$ | async)?.media?.status?.stream === STREAM_STATUS.FINISHED && !(streamService.stream$ | async)?.media?.hasRecording) || (streamService.stream$ | async)?.media?.deactivated || ([STREAM_STATUS.PENDING, STREAM_STATUS.CONNECTED, STREAM_STATUS.ACTIVATION, STREAM_STATUS.ACTIVATED, STREAM_STATUS.WAITING, STREAM_STATUS.LIVE, STREAM_STATUS.PAUSED].includes((streamService.stream$ | async)?.media?.status?.stream))"
                                            [config]="formConfig"
                                            [defaultForm]="formConfig.isDefault"
                                            [toggleForm]="formConfig.isToggle"
                                            [childForm]="formConfig.isChild"
                                            [form]="formConfig.form"
                                            [fieldsConfig]="formConfig.formFields"
                                            [title]="formConfig.title"
                                            [multimediaUrl]="photoUrl"
                                            [teaserText]="formConfig.teaserText"
                                            [withoutTopPadding]="formConfig.withoutTopPadding"
                                            (toggle$)="toggle($event, formConfig.childForm)"
                                            [disabledForm]="formConfig.isToggle && !formConfig.enabled || ((streamService.stream$ | async)?.media?.status?.stream === STREAM_STATUS.FINISHED && !(streamService.stream$ | async)?.media?.hasRecording) || (streamService.stream$ | async)?.media?.deactivated || ([STREAM_STATUS.PENDING, STREAM_STATUS.CONNECTED, STREAM_STATUS.ACTIVATION, STREAM_STATUS.ACTIVATED, STREAM_STATUS.WAITING, STREAM_STATUS.LIVE, STREAM_STATUS.PAUSED].includes((streamService.stream$ | async)?.media?.status?.stream))"
                                            (onFileUpload$)="uploadPreview($event)"
                                            (clearImage$)="clearImage()"
                                            (checkedPayments$)="checkedPayments$($event)"
                                    >
                                    </app-bordered-form>
                                    <p class="invalid-text" *ngIf="showAccesError && formConfig.name === 'media'">
                                        {{'streams.payment.error' | localize}}
                                    </p>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="formConfig.forms &&
                     (!formConfig.simpleToggle || (formConfig.simpleToggle && formConfig.enabled))">
                                <ng-container *ngFor="let settingsForm of formConfig.forms; let i = index;">
                                    <div class="section-panel-body" [class.padding-0]="formConfig.withoutBottomPadding">
                                        <app-bordered-form-preview
                                                [disabled]="(streamService.stream$ | async)?.media?.deactivated || ((streamService.stream$ | async)?.media?.status?.stream === STREAM_STATUS.FINISHED && !(streamService.stream$ | async)?.media?.hasRecording) || (streamService.stream$ | async)?.media?.status?.stream === STREAM_STATUS.PENDING"
                                                [readonly]="formConfig.readonly || ((streamService.stream$ | async)?.media?.status?.stream === STREAM_STATUS.FINISHED && !(streamService.stream$ | async)?.media?.hasRecording) || (streamService.stream$ | async)?.media?.deactivated || ([STREAM_STATUS.PENDING, STREAM_STATUS.CONNECTED, STREAM_STATUS.ACTIVATION, STREAM_STATUS.ACTIVATED, STREAM_STATUS.WAITING, STREAM_STATUS.LIVE, STREAM_STATUS.PAUSED].includes((streamService.stream$ | async)?.media?.status?.stream))"
                                                [index]="i"
                                                [form]="settingsForm"
                                                [formConfig]="formConfig"
                                                [addLimitReached]="i === myGroups?.length - 1 && formConfig.name === 'groupsSettings'"
                                                (add$)="addForm(formConfig)"
                                                (remove$)="removeForm(formConfig, i)"
                                        >
                                        </app-bordered-form-preview>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="isCreateMode && mediaType === MEDIA_TYPE.STREAM">
                    <div class="section-panel border-top">
                        <div class="section-panel-header">
                            <h2 class="title-level-3">{{'stream.multiBitrateAgreement.title' | localize}}</h2>
                            <p>{{(backupChannelEnabled ? 'stream.multiBitrateAgreement.description.x2' : 'stream.multiBitrateAgreement.description') | localize}}</p>
                        </div>
                        <div class="section-panel-body">
                            <form class="border-form">
                                <div class="row row-padding-0">
                                    <div class="col-lg-12">
                                        <app-agreement-box
                                                [colSize]="12"
                                                [text]="agreementText"
                                                [error]="termsRequiredError"
                                                (change$)="toggleTermsAgreed($event)"></app-agreement-box>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </ng-container>
            </div>
            <div class="col-auto"
                 *ngIf="!(streamService.stream$ | async)?.media?.deactivated && (isCreateMode || !initialValue || initialValue?.media?.status?.stream === STREAM_STATUS.SCHEDULED || initialValue?.media?.status?.stream === STREAM_STATUS.RESCHEDULING || initialValue?.media?.hasRecording || mediaType === MEDIA_TYPE.VIDEO_ON_DEMAND)">
                <div class="button-group text-right mb-20">
                    <!--<div class="form-group">-->
                    <!--<button class="btn btn-primary">KOntrollzentrum</button>-->
                    <!--</div>-->
                    <div class="form-group">
                        <button class="btn btn-success" (click)="submit()">{{'common.button.apply' | localize}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--<ng-container *ngIf="isCreateMode">-->
<!--    <ano-loader [isOpen]="(loadingService.loading | async)"></ano-loader>-->

<!--</ng-container>-->

<ng-template #bandwidthDialog let-dialogRef="dialogRef">
    <ano-modal
        [dialogRef]="dialogRef"
        [title]="'stream.create.bandwidthWarning.title' | localize"
        [submitButtonText]="'stream.create.bandwidthWarning.submitButton' | localize"
        [dismissButtonText]="'stream.create.bandwidthWarning.dismissButton' | localize"
    >
        <p>
            {{ 'stream.create.bandwidthWarning.text1' | localize }}
            <a routerLink="/user/space-storage-and-bandwidth" (click)="dialogRef.close()">
                <b>{{'stream.create.bandwidthWarning.text2.link' | localize}}</b>
            </a>
            {{ 'stream.create.bandwidthWarning.text2' | localize }}
        </p>
    </ano-modal>
</ng-template>
