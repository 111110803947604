<h2><b>aufzeichnungen</b></h2>
<p class="mb-40">Bitte beachte, dass nicht gesicherte Aufzeichnungen nach drei Tagen automatich gelöscht werden.</p>
<div class="card bg-white">
    <div class="row justify-content-between align-items-end">
        <div class="col-xl-8">
            <div class="card-body">
                <div class="title-box">
                    <h2 class="title-level-3">video als video on demand speichern</h2>
                </div>

<!--                <app-player *ngIf="initialValue?.recording?.fileUrl" [videoSrc]="initialValue?.recording?.fileUrl"></app-player>-->

                <ng-container *ngFor="let formConfig of formsConfig">
                    <div *ngIf="formConfig.title" class="title-box" [class.border-top]="!formConfig.withoutTopPadding">
                        <h2 class="title-level-3">{{formConfig.title}}</h2>
                        <p *ngIf="formConfig.teaserText">{{formConfig.teaserText}}</p>
                    </div>
                    <ng-container *ngIf="formConfig.isToggle">
                        <app-toggle
                                [value]="formConfig.enabled"
                                [config]="formConfig"
                        ></app-toggle>
                    </ng-container>
                    <ng-container *ngIf="formConfig.form">
                        <app-bordered-form
                                [config]="formConfig"
                                [defaultForm]="formConfig.isDefault"
                                [toggleForm]="formConfig.isToggle"
                                [childForm]="formConfig.isChild"
                                [form]="formConfig.form"
                                [fieldsConfig]="formConfig.formFields"
                                [title]="formConfig.title"
                                [teaserText]="formConfig.teaserText"
                                [withoutTopPadding]="formConfig.withoutTopPadding"
                                (toggle$)="toggle($event, formConfig.childForm)"
                                [disabledForm]="formConfig.isToggle && !formConfig.enabled"
                        >
                        </app-bordered-form>
                    </ng-container>
                    <ng-container *ngIf="formConfig.forms &&
                     (!formConfig.simpleToggle || (formConfig.simpleToggle && formConfig.enabled))">
                        <ng-container *ngFor="let settingsForm of formConfig.forms; let i = index;">
                            <app-bordered-form
                                    [itemIndex]="i"
                                    [formCountTitle]="formConfig.formCountTitle"
                                    [form]="settingsForm"
                                    [fieldsConfig]="formConfig.formsFields"
                                    [disabledForm]="formConfig.isToggle && !formConfig.enabled"
                                    [addButtonText]="formConfig.addButtonText"
                                    [showAdd]="i === formConfig.forms.length - 1"
                                    [showRemove]="i > 0"
                                    (remove$)="removeForm(formConfig, i)"
                                    (add$)="addForm(formConfig)">
                            </app-bordered-form>
                        </ng-container>
                    </ng-container>
                </ng-container>

                <p class="border-bottom padding-bottom-20">Die Webseite kannst du unter dem rechten Menüpunkt <a href="#"><i class="icon-code"></i> <b>Stream
                    einbinden</b></a> erstellen.</p>

                <div class="button-group text-left mb-20 mt-25">
                    <!--<div class="form-group">-->
                    <!--<button class="btn btn-primary">KOntrollzentrum</button>-->
                    <!--</div>-->
                    <div class="form-group">
                        <button class="btn btn-success mr-20" (click)="submit()">speichern</button>
                        <button class="btn btn-secondary" (click)="cancel()">abbrechen</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
