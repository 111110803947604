import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'website'
})
export class WebsitePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
      if (!value) {
          return null;
      }
      if (!value.startsWith('http://') && !value.startsWith('https://')) {
          value = 'https://' + value;
      }
      return value;
  }

}
