<div class="sticky-box">
    <ng-container *ngFor="let button of config?.buttons">
        <div *ngIf="button.show && button.position === BUTTON_POSITION.TOP" class="sidebar-button-wrapper" [class.top-button]="button.position === BUTTON_POSITION.TOP">
            <button [class]="button.cssClass" (click)="button.callback()"><i class="icon icon-{{button.icon}}"></i> {{button.text | localize}}</button>
        </div>
    </ng-container>
    <div class="sidebar">
        <div class="sidebar-header" *ngIf="!rightNav">
            <div class="user-pic">
                <img *ngIf="!userPhoto" class="img-responsive" src="/assets/img/mock/no-user-photo.png">
                <img *ngIf="userPhoto" class="img-responsive" [src]="userPhoto">
            </div>
            <div class="user-name" [class.cursor-pointer]="(userService.userType$ | async) !== USER_TYPE.VIEWER" (click)="goToMyVideos()">
                <ng-container *ngIf="userFirstName || userLastName">
                    <span class="name">{{userFirstName}}</span>
                    <span class="last-name">{{userLastName}}</span>
                </ng-container>
                <ng-container *ngIf="!(userFirstName || userLastName)">
                    <span class="name">{{userEmail}}</span>
                </ng-container>
            </div>
        </div>
        <div class="sidebar-body">
            <ul class="sidebar-nav">
                <ng-container *ngFor="let item of (advancedConfig || config)?.items">
                    <ng-container *ngIf="!item.hide">
                        <ng-container *ngIf="item.subItems">
                            <li class="dpopdown-menu" routerLinkActive="active" #rla="routerLinkActive"
                                [class.submenu-open]="item.opened || rla.isActive" [class.active]="rla.isActive" (click)="item.opened = !item.opened">
                                <i class="icon {{ item.icon }}"></i>
                                <span [class.active]="rla.isActive && !item?.subItems?.length">{{ item.text | localize}}</span>
                                <i class="icon-down"></i>
                                <ul class="submenu">
                                    <ng-container *ngFor="let subItem of item.subItems">
                                        <ng-container *ngIf="!subItem.hide">
                                            <li *ngIf="!subItem.subItems" routerLinkActive="active"
                                                [routerLinkActiveOptions]="{
                                    exact: true}">
                                                <a [routerLink]="subItem.link" routerLinkActive="active">{{subItem.text | localize}}</a>
                                            </li>
                                            <li *ngIf="subItem.subItems" class="dpopdown-menu"
                                                [class.submenu-open]="item.opened || rla.isActive">
                                                <span class="text-span">{{subItem.text | localize}}</span>
                                                <ul class="submenu inner-sub">
                                                    <li *ngFor="let editItem of subItem.subItems" routerLinkActive="active" [routerLinkActiveOptions]="{
                                        exact: true
                                        }">
                                                        <a [routerLink]="editItem.link">{{ editItem.text | localize }}</a>
                                                    </li>
                                                </ul>
                                                <i class="icon-down inner-sub-icon"></i>
                                            </li>
                                        </ng-container>
                                    </ng-container>
                                </ul>
                            </li>
                        </ng-container>
                        <ng-container *ngIf="!item.subItems">
                            <ng-container *ngIf="item.link">
                                <li routerLinkActive="active" class="pr-0">
                                    <a routerLink="{{ item.link }}" routerLinkActive="active">
                                        {{ item.text | localize}}
                                    </a>
                                    <i class="icon {{ item.icon }}"></i>
                                </li>
                            </ng-container>
                            <ng-container *ngIf="item.modalName">
                                <li [class.active]="item.opened">
                                    <a (click)="openModal(item)" [class.active]="item.opened">
                                        {{ item.text | localize}}
                                    </a>
                                    <i class="icon {{ item.icon }}"></i>
                                </li>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </ul>
        </div>
    </div>
    <ng-container *ngFor="let button of config?.buttons">
        <div *ngIf="button.show && button.position === BUTTON_POSITION.BOTTOM" class="sidebar-button-wrapper" [class.bottom-button]="button.position === BUTTON_POSITION.BOTTOM">
            <button [class]="button.cssClass" (click)="button.callback()"><i class="icon icon-{{button.icon}}"></i> {{button.text | localize}}</button>
        </div>
    </ng-container>
    <div class="sidebar-box info" *ngIf="config?.infoBox">
        <h3 class="title">{{'nav.infoBox.info.title' | localize}}</h3>
        <p>{{'nav.infoBox.info.firstP' | localize}}</p>
        <p>{{'nav.infoBox.info.secondP' | localize}}</p>
    </div>
</div>

