import {Injectable, OnDestroy} from '@angular/core';
import {ApiService, IPagedResponse} from 'ui-elements';
import {Observable} from 'rxjs';
import {IResponse, PagingParams, SortParam} from '@src/app/models/response.model';
import {MEDIA_TYPE} from '@src/app/components/streamdust-player/constants/mediaType';
import {API_URLS_APP, UrlGenerator} from '@src/app/constants/api-urls.constant';
import {map, pluck, take} from 'rxjs/operators';
import {Subject} from 'rxjs/internal/Subject';
import {environment} from '@src/environments/environment';

const SURVEY_SOCKET = `${environment.webSocketHost}${API_URLS_APP.ROOT_URL}${API_URLS_APP.MARKETING_SOCKET}`;
@Injectable({
  providedIn: 'root'
})
export class SurveyService implements OnDestroy {
  public connection: Subject<any>;
  private manualSurvey$: Subject<ISurvey> = new Subject<ISurvey>();
  private _publishedSurveys: IPublishedSurvey[] = [];

  constructor(
      private apiService: ApiService
  ) {
    this._publishedSurveys = JSON.parse(localStorage.getItem('_publishedSurveys')) || [];
  }

  public create(body: ISurveyRequest): Observable<ISurvey> {
    return this.apiService.post(API_URLS_APP.SURVEY_SAVE, body)
        .pipe(pluck('results', 'data'));
  }

  public update(body: ISurveyRequest): Observable<IResponse> {
    return this.apiService.put(API_URLS_APP.SURVEY_SAVE, body);
  }

  public get(id: string): Observable<ISurvey> {
    return this.apiService.get(UrlGenerator.generate(API_URLS_APP.SURVEY, {id}))
        .pipe(pluck('results', 'data'));
  }

  public getAll(mediaId: string, mediaType: MEDIA_TYPE, searchTerm: string = '', sort: SortParam[] = DEFAULT_SORT, paging: PagingParams = DEFAULT_PAGING): Observable<IPagedResponse<ISurvey>> {
    return this.apiService.post(UrlGenerator.generate(API_URLS_APP.SURVEYS_LIST, {mediaType, mediaId}), {
      paging,
      sort,
      searchTerm
    });
  }

  public getAllPublic(mediaType: MEDIA_TYPE, mediaId: string): Observable<ISurvey[]> {
    return this.apiService.get(UrlGenerator.generate(API_URLS_APP.SURVEYS_LIST_OPEN, {mediaType, mediaId}), {isWithoutRootUrl: true, openApi: true})
        .pipe(pluck('results', 'data'));
  }

  public sendAnswer(surveyId: string, answerId: string): Observable<IResponse> {
      return this.apiService.put(UrlGenerator.generate(API_URLS_APP.SURVEY_ANSWER, {surveyId, answerId}), {},  {isWithoutRootUrl: true, openApi: true});
  }

  public incrementVote(surveyId: string): Observable<IResponse> {
      return this.apiService.put(UrlGenerator.generate(API_URLS_APP.SURVEY_INCREMENT_VOTE, {surveyId}), {}, {isWithoutRootUrl: true, openApi: true});
  }

  public incrementView(surveyId: string): Observable<IResponse> {
      return this.apiService.put(UrlGenerator.generate(API_URLS_APP.SURVEY_INCREMENT_VIEW, {surveyId}), {}, {isWithoutRootUrl: true, openApi: true});
  }

  public delete(id: string): Observable<IResponse> {
    return this.apiService.delete(UrlGenerator.generate(API_URLS_APP.SURVEY, {id}));
  }

  public start(surveyId: string): Observable<IResponse> {
    return this.apiService.put(API_URLS_APP.SURVEY_START, {surveyId, start: true});
  }

  public stop(surveyId: string): Observable<IResponse> {
    return this.apiService.put(API_URLS_APP.SURVEY_START, {surveyId, start: false});
  }

  public toggle(surveyId: string, active: boolean): Observable<IResponse> {
    return this.apiService.put(API_URLS_APP.SURVEY_ACTIVATE, {surveyId, active: active});
  }

  public downloadCsv(mediaType: MEDIA_TYPE, mediaId: string): Observable<Response> {
    return this.apiService.get(
        UrlGenerator.generate(API_URLS_APP.SURVEY_CSV, {mediaType, mediaId}),
        {responseType: 'blob', observe: 'response'},
    );
  }

  public publishResults(surveyId: string, streamId: string,): Observable<IResponse<any>> {
    let publish: boolean;
    const surveyIndex = this._publishedSurveys.findIndex(item => item.id === surveyId);
    if (surveyIndex > -1) {
      publish = false;
      this._publishedSurveys.splice(surveyIndex, 1);
    } else {
      publish = true;
      this._publishedSurveys.filter((item => item.streamId === streamId))
          .forEach(surv => this.apiService.put(API_URLS_APP.PUBLISH_SURVEY_RESULT, {
        surveyId: surv.id,
        publish: false
      }).subscribe());
      this._publishedSurveys = this._publishedSurveys.filter((item => item.streamId !== streamId));
      this._publishedSurveys.push({id: surveyId, streamId: streamId});
    }
    localStorage.setItem('_publishedSurveys', JSON.stringify(this._publishedSurveys));
    return this.apiService.put(API_URLS_APP.PUBLISH_SURVEY_RESULT, {
      surveyId,
      publish
    });
  }

  public publishedSurveys(streamId: string): IPublishedSurvey[] {
    return this._publishedSurveys.filter((item => item.streamId === streamId));;
  }

  public clearPublishedSurveys(streamId: string): void {
    this._publishedSurveys.forEach(item => {
      this.publishResults(item.id, streamId).pipe(take(1)).subscribe();
    });

    this._publishedSurveys = this._publishedSurveys.filter((item => item.streamId !== streamId));
  }

  ngOnDestroy(): void {
    if (this.connection) {
      this.connection.complete();
    }
  }
}

const DEFAULT_SORT = [{
  field: 'NAME',
  order: 'ASC'
}] as SortParam[];

const DEFAULT_PAGING = {
  page: 0,
  itemsOnPage: 10
} as PagingParams;

export interface ISurvey {
  id: string;
  productInfo: {
    productId: string,
    productType: MEDIA_TYPE
  };
  startTime?: number;
  name: string;
  question: string;
  answers: IAnswer[];
  status: SURVEY_STATUS;
  duration: number;
  views: number;
  votes: number;
  created: Date;
  updated: Date;
  active: boolean;
  hide?: boolean;
  resultsPublished?: boolean;
  manualStarted: boolean;
  isManual?: boolean;
  endTime?: number;
  inProgress?: boolean;
  notComplete?: boolean;
  startedAt?: number;
}

export interface IAnswer {
  id: string;
  answer: string;
  replies: number;
}

export interface ISurveyRequest {
  id?: string;
  productId?: string;
  productType?: MEDIA_TYPE;
  name: string;
  question: string;
  answers: string[];
  startTime: number;
  // durationInMinutes: number;
  duration: number;
  active?: boolean;
}

export enum SURVEY_STATUS {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  PUBLISH_RESULTS = 'PUBLISH_RESULTS',
  HIDE_RESULTS = 'HIDE_RESULTS'
}

export enum MARKETING_ACTION {
  HIDE = 'HIDE',
  SHOW = 'SHOW',
  UPDATE_STATUS = 'UPDATE_STATUS',
  PUBLISH_RESULTS = 'PUBLISH_RESULTS',
  HIDE_RESULTS = 'HIDE_RESULTS'
}

export enum MARKETING_ENTITY_TYPE {
  OVERLAY = 'OVERLAY',
  SURVEY = 'SURVEY'
}

export interface ISurveyStatus {
  id: string;
  status: SURVEY_STATUS;
  type: MARKETING_ENTITY_TYPE;
  action: MARKETING_ACTION;
}

export interface IPublishedSurvey {
  id: string;
  streamId: string;
}
