import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IMediaItem, IMediaSliderConfig} from "@src/app/models/core.model";
import {IPagedResponseResults} from "@src/app/models/response.model";
import {IStreamModel} from "@src/app/models/stream.model";
import {IListFilterItem} from "@src/app/components/media-list/media-list.component";

@Component({
    selector: 'app-media-list-slider',
    templateUrl: './media-list-slider.component.html',
    styleUrls: ['./media-list-slider.component.css']
})
export class MediaListSliderComponent implements OnInit{
    @Input() id: string;
    @Input() selectorIndex: number;
    @Input() set config(config: IMediaSliderConfig) {
        if (!config) {
            this.setDefaultConfig();
            return;
        }
        this._config = {
            itemsPerList: config.itemsPerList || 3,
            carouselId: config.carouselId || this.id,
            scrollStep: 398,
            itemsToScrollAtOnce: config.itemsToScrollAtOnce || 3,
            selector: {title: config.selectorTitle, items: config.groups.map(group => group.name)} || null,
            moreButton: config?.moreButton
        };
    }
    @Input() implicitFilters: IListFilterItem[] = [];

    get config(): IMediaSliderConfig {
        return this._config;
    }

    private _config: IMediaSliderConfig;
    @Input() set mediaList(mediaList: IPagedResponseResults<IMediaItem | IStreamModel>) {
        this._mediaList = mediaList;
    };

    @Output() selectorClick: EventEmitter<number> = new EventEmitter<number>();



    _mediaList: IPagedResponseResults<IMediaItem | IStreamModel>;

    constructor() {
    }

    ngOnInit(): void {
        if (!this._config) {
            this.setDefaultConfig();
        }
    }

    private setDefaultConfig(): void {
        this._config = {
            itemsPerList: 3,
            carouselId: this.id,
            scrollStep: 398,
            itemsToScrollAtOnce: 3,
        }
    }

    private onSelectorClick(index: number): void {
        this.selectorClick.emit(index);
    }

}
