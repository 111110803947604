import {Component, OnInit} from '@angular/core';
import {PasswordForgottenPageService} from '@src/app/pages/password-forgotten-page/services/password-forgotten-page/password-forgotten-page.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {INFO_BOX_TYPES} from '@src/app/components/info-box/info-box.component';
import {IServerError} from '@src/app/models/server-error.model';
import {LocalizationProvider} from 'ui-elements';
import {CustomValidators} from '@src/app/utils/custom-validators.util';

@Component({
    selector: 'app-password-forgotten-page',
    templateUrl: './password-forgotten-page.component.html',
    styleUrls: ['./password-forgotten-page.component.css']
})
export class PasswordForgottenPageComponent implements OnInit {
    public passwordForgottenForm: FormGroup;

    public isEmailSent: boolean;
    public loading: { [key: string]: boolean } = {};

    public INFO_BOX_TYPES = INFO_BOX_TYPES;

    public serverErrors: IServerError[] = [];
    private readonly topColor = '#6c757d';

    constructor(
        private passwordForgottenPageService: PasswordForgottenPageService,
        private fb: FormBuilder,
        private localizationProvider: LocalizationProvider,
    ) {
    }

    ngOnInit() {
        this.initForm();
    }

    public initForm(): void {
        this.passwordForgottenForm = this.fb.group({
            email: ['', [Validators.required, CustomValidators.email]],
        });
    }

    public remindPassword(): void {
        this.passwordForgottenForm.markAllAsTouched();
        if (this.passwordForgottenForm.invalid) {
            return;
        }

        this.loading.passwordReminding = true;
        this.passwordForgottenPageService.remindPassword(this.passwordForgottenForm.get('email').value)
            .subscribe(
                ({success, errorKey}) => {
                    this.serverErrors = [{key: errorKey, text: this.localizationProvider.getByErrorKey(errorKey)}];
                    this.isEmailSent = success;
                    this.loading.passwordReminding = false;
                },
                () => this.loading.registration = false,
            );
    }

}
