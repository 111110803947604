import {Component, OnInit} from '@angular/core';
import {IListFilterItem, IListQuery, LOAD_TYPE} from '@src/app/components/media-list/media-list.component';
import {LandingService} from '@src/app/services/landing/landing.service';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import {LoadingService} from '@src/app/services/loading/loading.service';
import {takeUntil} from 'rxjs/operators';
import {ISuggestedMedia} from '@src/app/pages/public-media-page/public-media-page.component';
import {FILTER_TYPES} from '@src/app/components/media-list/media-list-header/media-list-header.component';
import {MEDIA_FILTERS_FIELDS, MediaListService} from '@src/app/services/media-list/media-list.service';
import {MEDIA_TYPE} from '@src/app/components/streamdust-player/constants/mediaType';
import {STREAM_STATUS} from '@src/app/components/streamdust-player/constants/status';

@Component({
    selector: 'app-actual-livestreams',
    templateUrl: './actual-livestreams.component.html',
    styleUrls: ['./actual-livestreams.component.css']
})
export class ActualLivestreamsComponent implements OnInit {

    FILTER_TYPE = FILTER_TYPES;

    actualStreamsListConfig: ISuggestedMedia = {
        title: 'stream.actual-livestreams-page.title',
        thumbnailGrayScale: true,
        fetchMethod: (filters: IListQuery) => this.mediaListService.getMediaList(filters),
        filters: {
            filterTypes: [this.FILTER_TYPE.SORT, this.FILTER_TYPE.GENRE, this.FILTER_TYPE.CONTENT_ACCESS_TYPE, this.FILTER_TYPE.COUNTRY],
            mediaTypes: []
        }
    };

    LOAD_TYPE = LOAD_TYPE;

    implicitFilters: IListFilterItem[] = [
        {
            field: MEDIA_FILTERS_FIELDS.MEDIA_TYPE,
            value: MEDIA_TYPE.STREAM
        },
        {
            field: MEDIA_FILTERS_FIELDS.STREAM_STATUS,
            value: [
                STREAM_STATUS.SCHEDULED,
                STREAM_STATUS.ACTIVATION,
                STREAM_STATUS.ACTIVATED,
                STREAM_STATUS.CONNECTED,
                STREAM_STATUS.PAUSED,
                STREAM_STATUS.LIVE,
                // STREAM_STATUS.FINISHED,
                // STREAM_STATUS.VIDEO,
                // STREAM_STATUS.WAITING,
            ],
        },
    ];

    activeFilters: IListQuery = {
        paging: {
            page: 0,
            itemsOnPage: 9
        },
        sort: [
            {
                order: 'DESC',
                field: 'STREAM_LIVE'
            },
            {
                field: 'DATE',
                order: 'ASC'
            }
        ],
        filters: this.implicitFilters
    };

    constructor(
        private landingService: LandingService,
        private destroy$: AutoDestroyService,
        public loadingService: LoadingService,
        private mediaListService: MediaListService
    ) {
    }

    ngOnInit(): void {
        this.loadingService.loadingStart();
        this.mediaListService.getMediaList(this.activeFilters).pipe(takeUntil(this.destroy$)).subscribe(response => {
            this.actualStreamsListConfig.media = response?.results?.data;
            this.actualStreamsListConfig.originalLength = response?.results?.data?.items?.length;
            this.loadingService.loadingEnd();
        });
    }
}
