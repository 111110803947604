<div class="plan" [class.best-plan]="plan?.mostPopular">
    <div class="item-header">
            <span *ngIf="plan?.mostPopular" class="best-plan-text">
			    <i class="icon icon-star"></i>
                {{'storage-and-bandwidth.plans.most-popular' | localize}}
			</span>
        <img [src]="'assets/img/graphics/' + config?.iconName + '-' + config?.iconIndex + '.svg'" alt="">
    </div>
    <div class="item-body">
        <div class="plan-details">
            <span class="rate">{{plan?.limit?.value}} {{plan?.limit?.unit}}
                <span *ngIf="!plan?.prices[0]?.monthPrice?.amount && plan.id === '5'">+</span>
            </span>
            <span *ngIf="plan.type === PLAN_TYPES.BANDWIDTH && plan?.prices[0]?.prices[0]?.price.amount" class="price">
                {{plan?.prices[0]?.prices[0]?.price.amount || 0}} {{plan?.prices[0]?.prices[0]?.price.currency || 'EUR'}}
                <span class="text-mutted mt-5" *ngIf="!config?.hideActionButton">Alle Preise zzgl. Mwst.</span>
            </span>
            <span *ngIf="plan.type !== PLAN_TYPES.BANDWIDTH" [class.hidden]="!planPriceMonth?.amount"
                  class="price">{{planPriceMonth?.amount || 0}} {{planPriceMonth?.currency || 'EUR'}}
                <ng-container
                        *ngIf="config?.planType === PLAN_TYPES.STORAGE_SPACE">/{{'subscription-manage.table.subscription-type.month' | localize}}</ng-container>
                <span *ngIf="config?.planType === PLAN_TYPES.STORAGE_SPACE && config?.planPeriod === PLAN_PERIOD.YEAR"
                      class="plan-sub-title">
                    {{'payment-cards-list.subscription.one-time' | localize}}
                    <br/>{{'payment-cards-list.subscription.payment-of' | localize}}
                    <br/>{{planPriceYear?.amount || 0}} {{planPriceYear?.currency || 'EUR'}} {{'payment-cards-list.subscription.yearly' | localize}}
                </span>
                <span class="text-mutted" *ngIf="!config?.hideActionButton">Alle Preise zzgl. Mwst.</span>
            </span>
        </div>
        <div class="row row-padding-0 justify-content-center">
            <div class="col-auto">
                <button *ngIf="!config?.hideActionButton" class="btn btn-success btn-sm" (click)="activatePlan()">
                    {{ (plan.id !== '5' ? 'storage-and-bandwidth.plans.buy-now' : 'storage-and-bandwidth.plans.contact-us') | localize}}
                </button>
            </div>
        </div>
    </div>
</div>
