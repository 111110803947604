import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {PaymentService} from '@src/app/services/payment/payment.service';
import {ActivatedRoute, Data, Router} from '@angular/router';
import {IPaymentCardsListControls} from '@src/app/components/payment-cards-list/payment-cards-list.component';
import {LoadingService} from '@src/app/services/loading/loading.service';
import {takeUntil} from 'rxjs/operators';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import {Observable} from 'rxjs';
import {clearQueryParams} from '@src/app/utils/query-params.util';
import {Location} from '@angular/common';

@Component({
    selector: 'app-buy-subscription',
    templateUrl: './payment-start.component.html',
    styleUrls: ['./payment-start.component.scss']
})
export class PaymentStartPageComponent implements OnInit {

    @ViewChild('paymentCardsList', {static: false}) set content(content: ElementRef) {
        const paymentCardsList = document.getElementById('paymentCardsList');
        setTimeout(() => {
            this.paymentService.failedTransactionInfo$.pipe(takeUntil(this.destroy$)).subscribe(res => {
                if (res?.failedCardId || res?.transactionFailReason) {
                    paymentCardsList.scrollIntoView({block: 'center', behavior: 'smooth'});
                }
            });
        }, 500);
    }

    subscriptionToBuyId: string;
    groupToBuyId: string;

    paymentCardsListControls: IPaymentCardsListControls = {
        payButton: true,
        addNewCard: {
            show: true,
            text: 'payment-cards-list.use-another-card',
        }
    };

    subscriptionUpgrade = false;
    groupAccess = false;

    public get routerData(): Observable<Data> {
        return this.activatedRoute.data;
    }

    constructor(
        private paymentService: PaymentService,
        private router: Router,
        private loadingService: LoadingService,
        private activatedRoute: ActivatedRoute,
        private destroy$: AutoDestroyService,
        private location: Location
    ) {
    }

    ngOnInit() {
        this.activatedRoute.queryParams.pipe(takeUntil(this.destroy$)).subscribe(params => {
            if (params.hasOwnProperty('failedCardId') && params.hasOwnProperty('transactionFailReason')) {
                this.handlePaymentFail(params);
            }
        });

        this.activatedRoute.params
            .pipe(
                takeUntil(this.destroy$)
            ).subscribe(params => {
            if (params.period) {
                this.subscriptionUpgrade = true;
            } else {
                this.groupAccess = true;
            }
        });
    }

    handlePaymentFail(params): void {
        this.paymentService.failedTransactionInfo.next({
            failedCardId: params['failedCardId'],
            transactionFailReason: params['transactionFailReason']
        });
        clearQueryParams(this.location);
    }

    cancelPayment(returnUrl): void {
        this.router.navigate([returnUrl]);
    }

}
