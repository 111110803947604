import {Observable} from 'rxjs';
import {tap, takeUntil} from 'rxjs/operators';
import {SETTING_TYPE} from '@src/app/pipes/item-setting';
import {ITEM_TYPE} from '@src/app/pipes/item-setting';
import {StreamService} from '@src/app/services/stream-metadata/stream.service';
import {TableComponent, ITableConfig, AutoDestroyService, MEDIA_TYPE, ITableColumn} from 'ui-elements';
import {IFieldsConfig} from './../../../../user-profile/interfaces/interfaces-common';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {OnInit, Component, ViewChild, TemplateRef, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {INPUT_TYPES} from 'ui-elements';
import {IMediaItem} from '@src/app/models/core.model';
import {IListFilterItem} from '@src/app/components/media-list/media-list.component';
import {StreamLinksService} from '@src/app/services/stream-links/stream-links.service';
import {FILTER_TYPES} from '@src/app/components/media-list/media-list-header/media-list-header.component';
import {IResponse} from '@src/app/models/response.model';

@Component({
    selector: 'app-stream-links-modal',
    templateUrl: './stream-links-modal.component.html',
    styleUrls: ['./stream-links-modal.component.scss'],
    providers: [AutoDestroyService]
})
export class StreamLinksModalComponent implements OnInit {
    form: FormGroup;
    formConfig: IFieldsConfig[][];
    tableConfig: ITableConfig<IMediaItem>;
    selectedStreams: IMediaItem;
    mediaId: string;
    searchQuery = '';
    ITEM_TYPE = ITEM_TYPE;
    SETTING_TYPE = SETTING_TYPE;
    table: TableComponent;
    isShowError: boolean;
    filters: IListFilterItem[];
    modalTitle: string = titles[0];
    isEditMode: boolean;

    @ViewChild('tableComponent', {static: false}) set tableComponent(content: TableComponent) {
        if (content) {
            this.table = content;
        }
    }

    @ViewChild('statusTmpl', {static: true}) public statusTmpl: TemplateRef<any>;
    @ViewChild('nameTmpl', {static: true}) public nameTmpl: TemplateRef<any>;
    @ViewChild('startDateTmpl', {static: true}) public startDateTmpl: TemplateRef<any>;
    @ViewChild('endDateTmpl', {static: true}) public endDateTmpl: TemplateRef<any>;
    @ViewChild('recordTmpl', {static: true}) public recordTmpl: TemplateRef<any>;

    constructor(
        private streamService: StreamService,
        private streamLinksService: StreamLinksService,
        private fb: FormBuilder,
        public dialogRef: MatDialogRef<StreamLinksModalComponent>,
        private destroy$: AutoDestroyService,
        @Inject(MAT_DIALOG_DATA) public data
    ) {
    }

    ngOnInit(): void {
        if (this.data.hasOtherData) {
            this.isEditMode = true;
            this.selectedStreams = {id: this.data.linkedMediaId} as IMediaItem;
            this.streamLinksService.getMediaById(this.selectedStreams.id)
                .pipe(takeUntil(this.destroy$))
                .subscribe(media => {
                    const type = media.results.data.productType;
                    this.filters = [{
                        field: FILTER_TYPES.MEDIA_TYPE,
                        value: type === MEDIA_TYPE.VIDEO_ON_DEMAND ? MEDIA_TYPE.VIDEO : type
                    }];
                    this.modalTitle = titles[1];
                    this.init();
                });
        } else {
            this.filters = [{field: FILTER_TYPES.MEDIA_TYPE, value: MEDIA_TYPE.STREAM}];
            this.init();
        }
    }

    init(): void {
        this.initConfig();
        this.initForms();
        this.initTableConf();
        this.streamService.stream$.subscribe(s => this.mediaId = s.media.id);
    }

    getMediaList(paging?, sort?): Observable<IResponse<any>> {
        if (this.isEditMode) {
            return this.streamLinksService.getMediaById(this.selectedStreams.id)
                .pipe(
                    tap(data => {
                        data.results.data = {
                            items: [data.results.data],
                            paging: {page: 0, itemsOnPage: 10, items: 1},
                            sort: [{order: 'ASC', field: 'TITLE'}]
                        };
                    })
                );
        } else {
            const filters = [
                ...this.filters,
                {field: this.data.user.accountType, value: this.data.user.ownerId},
            ];
            return this.streamLinksService.linkableStreams(this.mediaId, this.searchQuery, paging, sort, filters);
        }
    }

    private initForms(): void {
        this.form = this.fb.group({
            name: [this.data?.name || '', [Validators.required]],
            otherMediaName: [this.data?.linkedMediaName || '', [Validators.required]]
        });
    }

    private initConfig(): void {
        this.formConfig = [
            [
                {
                    name: 'name',
                    config: {
                        inputType: INPUT_TYPES.INPUT,
                        placeholder: 'groupsOfLinks.name',
                        isRequired: true
                    }
                }, {
                name: 'otherMediaName',
                config: {
                    inputType: INPUT_TYPES.INPUT,
                    placeholder: 'groupsOfLinks.otherStreamName',
                    isRequired: true
                }
            }
            ]
        ];
    }

    private initTableConf(): void {
        this.tableConfig = {
            dataField: 'data',
            matPaginator: true,
            filtersWidth100: true,
            customScroll: true,
            searchFn: (sort, paging) => {
                return this.getMediaList(paging, sort);
            },
            columns: this.getColumns()
        };
    }

    getColumns(): ITableColumn[] {
        return this.filters[0].value === MEDIA_TYPE.STREAM ? [
            {
                name: 'groupsOfLinks.status',
                sortField: 'STREAM_STATUS',
                tmpl: this.statusTmpl
            },
            {
                name: 'groupsOfLinks.streamTitle',
                sortField: 'TITLE',
                tmpl: this.nameTmpl
            },
            {
                name: 'groupsOfLinks.startTimestamp',
                sortField: 'STREAM_START',
                tmpl: this.startDateTmpl,
                class: 'left'
            },
            {
                name: 'groupsOfLinks.endTimestamp',
                sortField: 'STREAM_END',
                tmpl: this.endDateTmpl,
                class: 'left'
            },
            {
                name: 'groups.record',
                tmpl: this.recordTmpl,
                class: 'left'
            }
        ] : [
            {
                name: 'groupsOfLinks.videoTitle',
                sortField: 'TITLE',
                tmpl: this.nameTmpl
            },
            {
                name: 'groupsOfLinks.date',
                sortField: 'VOD_UPLOADED_VIDEO',
                tmpl: this.startDateTmpl,
                class: 'left'
            }
        ];
    }

    public search(event: string): void {
        this.searchQuery = event;
        this.table.refreshData({});
    }

    onChangeMode(): void {
        this.isEditMode = !this.isEditMode;
        this.clearSearch();
        this.initTableConf();
        this.table.refreshData({});
    }

    clearSearch(): void {
        this.searchQuery = '';
        this.table?.clearSearch();
    }

    onAddLinks(): void {
        this.form.markAllAsTouched();
        if (this.form.invalid || !this.selectedStreams?.id) {
            if (!this.selectedStreams?.id) {
                this.isShowError = true;
            }
            return;
        }
        this.streamLinksService.saveStreamLink({
            ...this.form.value,
            mediaId: this.mediaId,
            otherMediaId: this.data.hasOtherData ? this.data.linkedMediaId : null,
            newMediaId: this.selectedStreams.id,
            active: this.data.hasOtherData ? this.data?.active : true
        }).subscribe(() => {
            this.closeModal(true);
        });
    }

    closeModal(isSave?: boolean): void {
        this.dialogRef.close(isSave);
    }

    stopImmediatePropagation(event): void {
        event.stopImmediatePropagation();
    }

    filterByLinkedStreams(event: IListFilterItem[]): void {
        this.filters = event;
        this.initTableConf();
        this.table.refreshData({});
    }

    onSelectLinks(item: IMediaItem) {
        if (this.isEditMode && this.selectedStreams?.id === item.id) {
            return;
        }

        this.selectedStreams = item;
        this.onChangeMode();
        this.isShowError = false;
    }
}

export const titles: string[] = [
    'groupsOfLinks.modal.titleOnCreation',
    'groupsOfLinks.modal.titleOnEdit'
];
