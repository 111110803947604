import {AbstractControl, FormControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export const NEW_ID_PARAM = 'new';

export function isAlphanumeric(str): boolean {
    return /^[a-z0-9]+$/i.test(str);
}

export class CustomValidators {
    public static passwordsShouldNotMatch(equalControl: AbstractControl): ValidatorFn {
        let subscribe = false;

        return (control: AbstractControl): ValidationErrors => {
            if (!subscribe) {
                subscribe = true;

                equalControl.valueChanges.subscribe(() => {
                    control.updateValueAndValidity();
                });
            }

            return equalControl.value === control.value ? {'passwordsShouldNotMatch': true} : null;
        };
    }

    public static passwordsMatch(equalControl: AbstractControl): ValidatorFn {
        let subscribe = false;

        return (control: AbstractControl): ValidationErrors => {
            if (!subscribe) {
                subscribe = true;

                equalControl.valueChanges.subscribe(() => {
                    control.updateValueAndValidity();
                });
            }

            return (equalControl.value !== control.value && control.value) || !control?.value ? {'passwordsMatch': true} : null;
        };
    }

    public static password(formControl: AbstractControl): ValidationErrors {
        const password: string = formControl.value;

        const isPasswordValid = () => {
            return password.length >= 12 && password.toLowerCase() !== password && !isAlphanumeric(password);
        };

        return !password || isPasswordValid() ? null : {password: true};
    }

    public static noWhitespaceValidator(control: FormControl) {
        if (control && !control.value) {
            return null;
        }

        const isWhitespace = (control && control.value || '').trim().length === 0;
        const isValid = !isWhitespace;
        return isValid ? null : {'whitespace': true};
    }

    public static URL(formControl: AbstractControl): ValidationErrors {
        const URL: string = formControl.value;

        const isValidURL = (URL: string) => /^.+\..{2,}$/.test(URL);

        return !URL || isValidURL(URL) ? null : {url: true};
    }

    public static domain(formControl: AbstractControl): ValidationErrors {
        const domain: string = formControl.value;
        const isValidDomain = (value: string) => /^([a-zA-Z0-9\_]+)$/.test(value);

        return !domain || isValidDomain(domain) ? null : {domain: true};
    }

    public static email(formControl: AbstractControl): ValidationErrors {
        const email: string = formControl.value;

        const isValidEmail = (_email: string) => /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])/.test(_email ? _email.toLowerCase() : _email);
        return !email || isValidEmail(email) ? null : {email: true};
    }

    public static bic(formControl: AbstractControl): ValidationErrors {
        const bic: string = formControl.value;
        const isValidBic = (_bic: string) => /^([A-Z]{6}[A-Z2-9]{1}[A-NP-Z1-2]{1})(X{3}|[A-WY-Z0-9]{1}[A-Z0-9]{2})?$/.test(_bic);
        return !bic || isValidBic(bic) ? null : {bic: true};
    }

    public static priceAmount(targetAmount: number, type: PRICE_VALIDATION_TYPE = PRICE_VALIDATION_TYPE.MIN): ValidatorFn {
        return (formControl: AbstractControl): ValidationErrors => {
            let val: any = formControl.value;

            const processFormattedValue = function process(str) {
                // Remove unnecessary symbols from formatted value
                str = str.replace(/[^\d.]/g, '');

                return str.replace(/^([^.]*\.)(.*)$/, function (a, b, c) {
                    return b + c.replace(/\./g, '');
                });
            };

            if (typeof val === 'string') {
                val = processFormattedValue(val);
            }

            // Convert value to number
            // TODO: Math.abs is quick fix for mask which doesn't let you enter "-" but on validation we obtain value before mask with "-"
            const valNum: number = Math.abs(+val);

            const isValidPriceAmountMin = (_amount: number) => type === PRICE_VALIDATION_TYPE.MIN ? (_amount >= PRICE_VALIDATION_VALUE.MIN && _amount >= targetAmount) : _amount > PRICE_VALIDATION_VALUE.MIN;
            const isValidPriceAmountMax = (_amount: number) => {
                if (!_amount) {
                    return true;
                }
                if (type === PRICE_VALIDATION_TYPE.MIN) {
                    return _amount > PRICE_VALIDATION_VALUE.MIN && _amount <= PRICE_VALIDATION_VALUE.MAX;
                }
                return _amount > PRICE_VALIDATION_VALUE.MIN && _amount <= targetAmount;
            };
            return !val || isValidPriceAmountMin(valNum) ? isValidPriceAmountMax(valNum) ? null :
                (type === PRICE_VALIDATION_TYPE.MIN ? {priceMaxAmount: true} : {priceCustomMaxAmount: true}) :
                {min: {min: type === PRICE_VALIDATION_TYPE.MIN ? targetAmount : 0.01}};
        };
    }

    public static smsforConfirmPhone(control: FormControl) {
        if (!control?.errors?.lengthSms && control?.value?.length === 6) {
            return control.errors;
        }
        if (control?.value === '') {
            return null;
        }

        return control?.value?.length < 6 ? {'lengthSms': true} : null;
    }

    public static minLengthPhone(control: FormControl) {
        if (control?.value === '') {
            return null;
        }

        return control?.value?.length < PHONE_VALIDATION_VALUE.MIN ? {'lengthPhone': true} : null;
    }

    public static timer(control: AbstractControl): ValidationErrors {
        if (control.value === '') {
            return null;
        }

        return control?.value?.length < 8 ? {'timerFormat': true} : null;
    }
}

export enum PRICE_VALIDATION_TYPE {
    MAX = 'MAX', MIN = 'MIN'
}

export enum PRICE_VALIDATION_VALUE {
    MIN = 0,
    MAX = 1000000
}

export enum PHONE_VALIDATION_VALUE {
    MIN = 11
}
