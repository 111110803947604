<ng-container *ngIf="message.type === MESSAGE_TYPE.PLAIN">
    <div class="pinned-message-item" [class.embedded-pin]="isEmbedded">
        <div class="message-content">
            <i *ngIf="isOwner"
               class="icon-close cursor-pointer message-pin-button"
               [class.message-pin-button--active]="message.isPinned"
               (click)="pinMessage($event)"
            ></i>
            <p class="message-line" *ngFor="let messageLine of messageContent">{{ messageLine }}</p>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="message.type === MESSAGE_TYPE.FILE">
    <div class="pinned-message-item" [class.embedded-pin]="isEmbedded">
        <div class="message-content">
            <i *ngIf="isOwner"
               class="icon-close cursor-pointer message-pin-button"
               [class.message-pin-button--active]="message.isPinned"
               (click)="pinMessage($event)"
            ></i>
            <p class="message-line" *ngFor="let messageLine of messageContent">{{ fileName }}</p>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="message.type === MESSAGE_TYPE.SYSTEM">
    <div class="pinned-message-item">
        <div class="message-content system-message">
            {{ ('chat.systemMessage.' + message.content) | localize }} {{message.date ? (message.date | dateTimeFormat) : ''}}
        </div>
    </div>
</ng-container>
