import {MediaOverviewData} from '@src/app/models/stream.model';
import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {IUserPublisher} from '@src/app/pages/user-profile/components/profile/services/profile.service';
import {environment} from '@src/environments/environment';
import {CmsService} from 'ui-elements';
import {IUnitKeyValue} from '@src/app/pages/user-profile/interfaces/interfaces-common';
import {StreamService} from '@src/app/services/stream-metadata/stream.service';
import {filter, takeUntil} from 'rxjs/operators';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import {StreamWorldMapState} from '@src/app/state/stream-world-map';

@Component({
    selector: 'app-publisher-info',
    templateUrl: './publisher-info.component.html',
    styleUrls: ['./publisher-info.component.sass']
})
export class PublisherInfoComponent implements OnInit {

    // @HostListener('window:resize', ['$event']) onResize() {
    //     this.calculateIfCollapseIsNeeded();
    // }

    public environment = environment;
    @Input() public publisher: IUserPublisher;
    @Input() public data: MediaOverviewData;
    @Input() public contacts: any;
    @ViewChild('publisherDescription') publisherDescription;
    isDescriptionCollapsed = true;
    shouldBeCollapsed = true;
    isCollapseApplied = false;
    currentUrl: string;
    shareUrl: string;
    public countries: IUnitKeyValue[] = [];
    public isOpenModal = false;
    public showShareMedia = false;

    constructor(
        private cmsService: CmsService,
        public streamService: StreamService,
        private destroy$: AutoDestroyService,
        public worldMapState: StreamWorldMapState,
    ) {
    }

    ngOnInit(): void {
        this.countries = this.cmsService.countriesList;
        this.currentUrl = window.location.href;
        this.shareUrl = window.location.protocol + '//' + window.location.host + '/media-preview/' + this.data.video.id;
        this.getWebsiteContent();
        this.listenMediaOverview();
        // this.calculateIfCollapseIsNeeded();

        // TODO: remove after done https://jira.anotheria.net/browse/STREAM-969
        // this.publisher.privacyPolicyUrl = 'http://beispiel.com/agb2.htm';
        // this.publisher.termsConditionUrl = 'http://beispiel.com/agb.htm';
    }

    // ngAfterViewInit() {
    //     this.calculateIfCollapseIsNeeded();
    // }
    //
    // ngAfterContentInit() {
    //     this.calculateIfCollapseIsNeeded();
    // }
    //
    // calculateIfCollapseIsNeeded() {
    //     this.shouldBeCollapsed = this.publisher?.description
    //         && (this.publisherDescription?.nativeElement?.offsetHeight > 95 || this.publisherDescription?.nativeElement?.offsetHeight === 0)
    //     || (this.isCollapseApplied && this.publisherDescription?.nativeElement?.offsetHeight === 95);
    //     this.isCollapseApplied = this.shouldBeCollapsed;
    // }
    //
    // toggleDescription(event) {
    //     event.preventDefault();
    //     this.isDescriptionCollapsed = !this.isDescriptionCollapsed;
    // }

    getWebsiteContent() {
        if (!this.publisher?.website) {
            return 'https://www.streamdust.tv/imprint.php';
        }
        const res = this.isLink(this.publisher?.website);
        if (res && res.length) {
            return res[0];
        } else {
            this.isOpenModal = true;
        }
    }

    isLink(text) {
        const urlRegex = /^(https?:\/\/[^\s]+)$/g;
        return text.match(urlRegex);
    }

    openModal() {
        if (this.isOpenModal) {
            this.streamService.playerAccessModalShow$.emit({data: this.publisher?.website, state: true});
        }
    }

    private listenMediaOverview(): void {
        this.streamService.mediaOverview
            .pipe(
                takeUntil(this.destroy$),
                filter(res => !!res)
            )
            .subscribe((res) => {
                this.showShareMedia = res.advancedSettings.shareButtonEnabled;
            });

        this.worldMapState.shareButtonEnabled$
            .pipe(takeUntil(this.destroy$), filter((res) => res !== null))
            .subscribe((res) => {
                this.showShareMedia = res;
            });
    }
}
