<div *ngIf="isShown"
     @smoothAppearing
     class="modal modal-sidebar-bottom d-block">
    <div *ngIf="isInputShow" class="input-wrapper">
        <input [(ngModel)]="inputModel" class="form-control form-control-shadow"/>
    </div>
    <div @smoothAppearing class="modal-dialog sidebar-nav-bottom" role="document" (click)="isShown = false">
        <ul class="nav flex-column">
            <li *ngFor="let btn of btnsConfig" (click)="onBtnClicked(btn)" class="nav-item">
                <a class="nav-link nav-link-with-icon">
                    <i class="{{btn.icon}} nav-icon"></i>
                    <span class="nav-label">{{btn.text | localize}}</span>
                </a>
            </li>
        </ul>
    </div>
</div>

<div *ngIf="isShown" @backdropEnterAnimation class="modal-backdrop" (click)="hide()"></div>
