import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {IFormConfig} from '@src/app/services/stream-metadata/stream.service';
import {ISubAccount, SUB_ACCOUNT_TYPE} from '@src/app/pages/subaccounts-page/model/sub-account.model';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import {SubAccountsService} from '@src/app/services/sub-accounts/sub-accounts.service';
import {filter, takeUntil, tap} from 'rxjs/operators';
import {CustomValidators, NEW_ID_PARAM} from '@src/app/utils/custom-validators.util';
import {LoadingService} from '@src/app/services/loading/loading.service';
import {FormUtil} from '@src/app/utils/form.util';
import {Validators} from '@angular/forms';
import {InfoPanelsConfig} from '@src/app/components/info-panel/info-panel.component';

@Component({
    selector: 'app-sub-account',
    templateUrl: './sub-account.component.html',
    styleUrls: ['./sub-account.component.sass'],
    providers: [AutoDestroyService, SubAccountsService]
})
export class SubAccountComponent implements OnInit {
    public SUB_ACCOUNT_TYPE = SUB_ACCOUNT_TYPE;
    public InfoPanelConfig = InfoPanelsConfig;
    public subaccount: ISubAccount;
    public formConfig: IFormConfig;
    public passwordFormConfig: IFormConfig;
    private accountId: string;
    isCreateMode = false;
    loading = false;
    isInvitation = false;

    constructor(
        private router: Router,
        private subAccountsService: SubAccountsService,
        private readonly destroy$: AutoDestroyService,
        private readonly activatedRoute: ActivatedRoute,
        public loadingService: LoadingService,
    ) {
    }

    ngOnInit(): void {
        this.formConfig = this.subAccountsService.getFormConfig();
        this.passwordFormConfig = this.subAccountsService.getPasswordFormConfig();

        this.activatedRoute.params.pipe(
            takeUntil(this.destroy$),
            tap(({id}: Params) => {
                if (id === NEW_ID_PARAM) {
                    this.isCreateMode = true;
                    return;
                }
                if (id === 'invite') {
                    this.isInvitation = true;
                    return;
                }
                this.accountId = id;
            }),
            filter(({id}: Params) => id && id !== NEW_ID_PARAM && id !== 'invite'))
            .subscribe(() => {
                this.loadingService.loadingStart();
                this.loading = true;
                this.subAccountsService.get(this.accountId).pipe(takeUntil(this.destroy$))
                    .subscribe((res) => {
                        this.loadingService.loadingEnd();
                        this.loading = false;
                        this.subaccount = res;
                        this.formConfig.form.get('firstName').patchValue(this.subaccount.firstName);
                        this.formConfig.form.get('lastName').patchValue(this.subaccount.lastName);
                        this.formConfig.form.get('email').patchValue(this.subaccount.email);
                        this.clearPasswordValidators(true);
                        this.listenPasswordChange();
                    });
            });
    }

    private listenPasswordChange(): void {
        this.passwordFormConfig.form.get('password').valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((value => {
                if (!value) {
                    this.clearPasswordValidators(false);
                    return;
                }
                this.addPasswordValidators();
            }));
    }

    private clearPasswordValidators(update: boolean): void {
        this.passwordFormConfig.form.get('password').clearValidators();
        if (update) {
            this.passwordFormConfig.form.get('password').updateValueAndValidity();
        }
        this.passwordFormConfig.form.get('password').markAsUntouched();
        this.passwordFormConfig.form.get('confirmPassword').clearValidators();
        if (update) {
            this.passwordFormConfig.form.get('confirmPassword').updateValueAndValidity();
        }
        this.passwordFormConfig.form.get('confirmPassword').markAsUntouched();
        this.passwordFormConfig.formFields[0][0].config.isRequired = false;
        this.passwordFormConfig.formFields[1][0].config.isRequired = false;
    }

    private addPasswordValidators(): void {
        this.passwordFormConfig.form.get('password').setValidators(Validators.compose([Validators.required, CustomValidators.password]));
        // this.passwordFormConfig.form.get('password').updateValueAndValidity();
        this.passwordFormConfig.form.get('password').markAsTouched();
        this.passwordFormConfig.form.get('confirmPassword').setValidators(Validators.compose([Validators.required, CustomValidators.passwordsMatch(this.passwordFormConfig.form.get('password'))]));
        // this.passwordFormConfig.form.get('confirmPassword').updateValueAndValidity();
        this.passwordFormConfig.form.get('confirmPassword').markAsTouched();
        this.passwordFormConfig.formFields[0][0].config.isRequired = true;
        this.passwordFormConfig.formFields[1][0].config.isRequired = true;
    }

    public goToSubaccounts(): void {
        this.router.navigate(['subaccounts']);
    }

    public submit(): void {
        const payload: ISubAccount = FormUtil.mapFormsToPayload<ISubAccount>([this.formConfig])['subaccount'];

        this.formConfig.form.markAllAsTouched();
        this.passwordFormConfig.form.markAllAsTouched();

        if (this.formConfig.form.invalid || this.passwordFormConfig.form.invalid) {
            return;
        }

        if (!this.isCreateMode) {
            payload.id = this.accountId;
            if (!this.passwordFormConfig.form.get('password').value) {
                delete payload.password;
            }
        }
        delete payload.confirmPassword;

        this.loading = true;
        if (this.isCreateMode) {
            this.subAccountsService.create({
                ...payload,
                password: this.passwordFormConfig.form.get('password').value
            })
                .pipe(takeUntil(this.destroy$))
                .subscribe((res) => {
                    this.loading = false;
                    if (!res.success) {
                        this.formConfig.form.get('email').setErrors({'emailExists': true});
                        return;
                    }
                    this.goToSubaccounts();
                }, () => {
                    this.loading = false;
                });
        } else {
            this.subAccountsService.update({
                ...payload,
                password: this.passwordFormConfig.form.get('password').value
            })
                .pipe(takeUntil(this.destroy$))
                .subscribe(() => this.loading = false);
        }
    }

}
