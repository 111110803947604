<div class="pdf-container wrapper-slider">
    <div *ngIf="resizeStarted" class="resize-started-container">
        <ano-loader [isOpen]="true"></ano-loader>
    </div>
    <drag-scroll #nav class="wrapper-slider"
                 (indexChanged)="indexChanged($event)"
                 [drag-scroll-disabled]="false"
                 [snap-duration]="500"
                 (snapAnimationFinished)="initialized($event)"
    >
        <div class="right-border"></div>
        <div class="left-border"></div>
        <div class="bottom-border"></div>
        <div drag-scroll-item *ngFor="let item of data?.slides" class="col-12 frame-container {{playerMode.toLowerCase()}}" [class.frame-container-embedded]="mode === CHAT_MODE.EMBEDDED">
            <img [src]="environment.backendApiHost + item.url">
        </div>
    </drag-scroll>

    <div class="presentation-controls">
        <div class="page-counter">
            Pages {{config.currentPage}}/{{config.totalPages}}
        </div>
        <div class="top-gradient"></div>
        <div class="button-box">
            <a *ngIf="config.isLink && data.link" (click)="clickLink(data.link)"
               target="_blank">
                <i class="icon icon-linked"></i>
            </a>
            <a href="javascript:void(0)">
                <i *ngIf="config.isDownload && data.viewerDownloadAllowed" (click)="download()" class="icon icon-download"></i>
            </a>
            <a href="javascript:void(0)">
                <i *ngIf="config.isRemove" (click)="remove()" class="icon icon-trash2"></i>
            </a>
        </div>

        <div class="slider-nav pdf-nav">
            <div class="slider-prev" (click)="prevPage()">
                <i class="icon icon-prev"></i>
            </div>
            <div class="slider-next" (click)="nextPage()">
                <i class="icon icon-next"></i>
            </div>
        </div>
    </div>
</div>
