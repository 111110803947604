<div class="box-profile">
    <div class="content">
        <div class="panel panel-md">
            <div class="panel-header">
                <h2 class="title-lg">{{'profile.greetings' | localize}}, <b>{{userName}}</b></h2>
                <p class="page-description">{{'profile.page-description-1' | localize}}</p>
                <p class="page-description">{{'profile.page-description-2' | localize}}</p>
                <span class="required">{{'profile.required' | localize}}</span>
            </div>
        </div>
    </div>

    <div class="edit-user-data-form">
        <ano-form *ngIf="form"
                  [forcedViewMode]="userType === USER_TYPE.SUB_ACCOUNT"
                  [form]="form"
                  [title]="'profile.profileDetails'"
                  [fieldsConfig]="fieldsConfig"
                  [withAvatar]="true"
                  [avatar]="avatar"
                  (avatar$)="uploadAvatar($event)"
                  (cancel$)="clearAvatar()"
                  (deleteAvatar$)="deleteAvatar()"
                  (submit$)="saveChanges()">
        </ano-form>
    </div>
    <ano-form *ngIf="companyForm && userType !== USER_TYPE.VIEWER"
              [forcedViewMode]="userType === USER_TYPE.SUB_ACCOUNT"
              [form]="companyForm"
              [title]="'profile.company.information'"
              [fieldsConfig]="firmFieldsConfig"
              (submit$)="saveCompanyChanges()">
    </ano-form>
    <ng-container *ngIf="userType !== USER_TYPE.VIEWER">
        <ano-form class="publisher"
                  *ngIf="publisherForm"
                  [forcedViewMode]="userType === USER_TYPE.SUB_ACCOUNT"
                  [form]="publisherForm"
                  [title]="'profile.publisher.title'"
                  [subTitle]="'profile.publisher.subTitle'"
                  [fieldsConfig]="publisherFieldsConfig"
                  [columnImageUploader]="true"
                  [clearImageButton]="true"
                  [photoUrl]="publisherPhotoUrl"
                  [mainColMd8]="userType === USER_TYPE.SUB_ACCOUNT"
                  (onFileUpload$)="uploadPublisherPhoto($event)"
                  (clearImage$)="clearPublisherImage()"
                  (submit$)="savePublisherChanges()">
        </ano-form>
        <app-stream-multimedia-upload
                class="mb-20"
                [disabled]="userType === USER_TYPE.SUB_ACCOUNT"
                [multimediaType]="'photo'"
                [buttonsGroupBorderTop]="false"
                [aspectRatio]="6.83"
                [multimediaUrl]="publisherHeader?.photoUrl"
                [metadata]="streamHeader"
                [formsConfig]="streamHeaderFormsConfig"
                [marginLeft15]="true"
                [withTable]="false"
                [editMode]="true"
                (updateMultimedia$)="uploadHeaderPicture($event)"
                (clearMultimedia$)="clearPublisherHeaderPicture()"
                (save$)="saveHeaderPicture()"
        >
        </app-stream-multimedia-upload>
    </ng-container>
    <ano-form
            *ngIf="contactsForm && userType !== USER_TYPE.VIEWER"
            [forcedViewMode]="userType === USER_TYPE.SUB_ACCOUNT"
            [form]="contactsForm"
            [title]="'profile.contact.title'"
            [subTitle]="'profile.contact.subTitle'"
            [fieldsConfig]="contactsFieldsConfig"
            (submit$)="saveContactsChanges()">
    </ano-form>
</div>
