import {Component, EventEmitter, Input, Output} from '@angular/core';
import * as d3 from 'd3v4';
import {Observable} from 'rxjs';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import {takeUntil} from 'rxjs/operators';

@Component({
    selector: 'app-bubble-map',
    templateUrl: './bubble-map.component.html',
    styleUrls: ['./bubble-map.component.css']
})
export class BubbleMapComponent {

    @Input() set dataArray(dataArray: any[]) {
        if (!dataArray) {
            dataArray = [];
        }
        // if (!dataArray?.length) {
            this.loading = false;
        //     return;
        // }
        d3.selectAll(`svg#bubble-map-${this.id} > circle`).remove();
        setTimeout(() => {
            this.setupBubbleMap(dataArray);
        }, 100);
    }

    @Input() set width(width: number) {
        // const bubbleMapEl = document.getElementById(`bubbleMapContainer-${this.id}`);
        // bubbleMapEl.style.zoom = (width / this.rzHeight).toString();
    }

    @Input() id: number;
    @Input() rzWidth = 630;
    @Input() rzHeight = 410;
    @Input() set resize(resize: Observable<any[]>) {
        resize.subscribe((data) => {
            if (!data) {
                data = [];
            }
            d3.selectAll(`svg#bubble-map-${this.id} > *`).remove();
            if (this.rzWidth / 1.5365 > this.rzHeight) {
                this.rzHeight = this.rzWidth / 1.5365;
            }
            setTimeout(() => {
                this.setupBubbleMap(data);
            }, 100);
        });
    }

    @Output() onBubbleHover$ = new EventEmitter();

    loading = true;
    mapHeight = 410;
    zoom = 1;

    constructor() {
    }

    setupBubbleMap(data: any[]): void {
        const vm = this;

        const svg = d3.select(`svg#bubble-map-${this.id}`);

        if (!svg) {
            return;
        }

        const width = +svg.attr('width'),
            height = +svg.attr('height');

        // Map and projection
        const projection = d3.geoMercator()
            .fitSize([width, height], {type: 'Sphere'})
            .center([0, 45])                // GPS of location to zoom on
            .scale((width / 630) * 100)                       // This is like the zoom
            .translate([width / 2, height / 2])

        d3.queue()
            .defer(d3.json, 'https://raw.githubusercontent.com/holtzy/D3-graph-gallery/master/DATA/world.geojson')  // World shape
            .defer((callback) => {
                callback(null, data)
            }) // Position of circles
            // .defer(d3.csv, 'https://raw.githubusercontent.com/holtzy/D3-graph-gallery/master/DATA/data_gpsLocSurfer.csv') // Position of circles
            .await(ready);

        function ready(error, dataGeo, data: IBubbleMapData[]) {
            // Create a color scale
            // const allContinent = d3.map(data, function (d) {
            //     return (d.homecontinent);
            // }).keys();
            // const color = d3.scaleOrdinal(d3.schemeCategory10);
            // .domain(allContinent)
            // .range(d3.schemePaired);

            // Add a scale for bubble size
            const valueExtent = data?.length ? d3.extent(data, function (d) {
                return +d.viewers;
            }) : [];
            const size = d3.scaleSqrt()
                .domain(valueExtent)  // What's in the data
                .range([7, 50]);  // Size in pixel

            // Draw the map
            svg.append('g')
                .selectAll('path')
                .data(dataGeo.features)
                .enter()
                .append('path')
                .attr('fill', '#bad668')
                .attr('d', d3.geoPath()
                    .projection(projection)
                )
                .style('stroke', 'none');
            // .style('opacity', .3);

            // Add circles:
            if (data?.length) {
                svg
                    .selectAll('myCircles')
                    .data(data.sort(function (a, b) {
                        return +b.viewers - +a.viewers
                    }).filter(function (d, i) {
                        return i < 1000
                    }))
                    .enter()
                    .append('circle')
                    .attr('cx', function (d) {
                        return projection([+d.lng, +d.lat])[0]
                    })
                    .attr('cy', function (d) {
                        return projection([+d.lng, +d.lat])[1]
                    })
                    .attr('r', function (d) {
                        return size(+d.viewers)
                    })
                    .attr('class', 'cursor-pointer')
                    // .style('fill', function (d) {
                    //     return color(d.homecontinent)
                    // })
                    .style('fill', '#0e54a9')
                    .attr('stroke', function (d) {
                        if (d.viewers > 2000) {
                            return 'black'
                        } else {
                            return 'none'
                        }
                    })
                    .attr('stroke-width', 1)
                    .attr('fill-opacity', .7)
                    .on('mouseover', (data) => {
                        vm.onBubbleHover$.emit(data);
                    }).on('mouseout', (data) => {
                    vm.onBubbleHover$.emit(null);
                });

             }

            vm.loading = false;
            // Add title and explanation
            // svg
            //     .append('text')
            //     .attr('text-anchor', 'end')
            //     .style('fill', 'black')
            //     .attr('x', width - 10)
            //     .attr('y', height - 30)
            //     .attr('width', 90)
            //     .html('WHERE SURFERS LIVE')
            //     .style('font-size', 14);


            // --------------- //
            // ADD LEGEND //
            // --------------- //

            // Add legend: circles
            // const valuesToShow = [100, 4000, 15000];
            // const xCircle = 40;
            // const xLabel = 90;
            // svg
            //     .selectAll('legend')
            //     .data(valuesToShow)
            //     .enter()
            //     .append('circle')
            //     .attr('cx', xCircle)
            //     .attr('cy', function (d) {
            //         return height - size(d)
            //     })
            //     .attr('r', function (d) {
            //         return size(d)
            //     })
            //     .style('fill', 'none')
            //     .attr('stroke', 'black');

            // Add legend: segments
            // svg
            //     .selectAll('legend')
            //     .data(valuesToShow)
            //     .enter()
            //     .append('line')
            //     .attr('x1', function (d) {
            //         return xCircle + size(d)
            //     })
            //     .attr('x2', xLabel)
            //     .attr('y1', function (d) {
            //         return height - size(d)
            //     })
            //     .attr('y2', function (d) {
            //         return height - size(d)
            //     })
            //     .attr('stroke', 'black')
            //     .style('stroke-dasharray', ('2,2'));

            // Add legend: labels
            // svg
            //     .selectAll('legend')
            //     .data(valuesToShow)
            //     .enter()
            //     .append('text')
            //     .attr('x', xLabel)
            //     .attr('y', function (d) {
            //         return height - size(d)
            //     })
            //     .text(function (d) {
            //         return d
            //     })
            //     .style('font-size', 10)
            //     .attr('alignment-baseline', 'middle');
        }
    }

}

export interface IBubbleMapData {
    country: string;
    lat: number;
    lng: number;
    viewers: number;
}
