<h2 *ngIf="title" class="media-list-title {{config?.cssClass}}" [class.border-top]="borderTop">
    <b>{{title | localize}}</b>
</h2>
<div *ngIf="config?.filtration || filtersList?.length || config?.withSearch" class="records-filter {{config?.filtersCssClass || ''}}" [class.border-top]="borderTop && !title">
    <form class="form-filter" *ngIf="config?.filtration || filtersList?.length" [formGroup]="filtersForm">
        <ng-container *ngFor="let streamFilter of filtersList; let i = index">
            <!--            <ano-time-range-filter *ngIf="config?.timeRangeConfig && i === config?.timeRangeConfig?.customIndex" [config]="config?.timeRangeConfig"-->
            <!--                                   (filterByTimeRange$)="doFilterByTimeRange($event)"></ano-time-range-filter>-->
            <ano-input [formControlName]="streamFilter.name"
                       [isEditMode]="true"
                       [noBorder]="false"
                       [config]="{
            inputType: INPUT_TYPES.SELECT,
            placeholder: streamFilter.placeholder,
            selectOptions: streamFilter.selectOptions,
            isAutocomplete: streamFilter.isAutocomplete
       }"
            ></ano-input>
            <ano-datepicker *ngIf="config?.timeRangeConfig && i === config?.timeRangeConfig?.customIndex"
                            [startFromAll]="true"
                            (changeDate)="doFilterByTimeRange($event)"></ano-datepicker>
        </ng-container>
        <ano-time-range-filter *ngIf="config?.timeRangeConfig && !config?.timeRangeConfig?.customIndex" [config]="config?.timeRangeConfig"
                               (filterByTimeRange$)="doFilterByTimeRange($event)"></ano-time-range-filter>
    </form>
    <ano-search *ngIf="config?.withSearch" class="media-list-search" (search$)="doSearch($event)"></ano-search>
</div>
