<div (click)="closeModel()" class="info-modal-overlay" >
    <div class="info-modal-container" (click)="stopPropagation($event)">
        <div class="info-modal-header">
            <i class="info-modal-icon icon icon-info"></i>
            <h2 class="info-modal-title">
                {{title | localize}}
            </h2>
        </div>
        <div class="info-modal-text">{{description | localize}}</div>
        <button (click)="closeModel()" class="info-modal-btn btn btn-primary">
            {{closeButtonText | localize}}
        </button>
    </div>
</div>
