export function getWithDecimals(val) {
    if (val === '') {
        return '';
    }

    // Format value with thousands separator
    const formatWithThousandsSeparator = (val) => val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    val = formatWithThousandsSeparator(val);

    const config = {
        decimalScale: 2,
        prefix: '$',
    };
    const [integer, decimal] = val.split('.');
    if (decimal) {
        const difference = config.decimalScale - decimal.length;
        return integer + '.' + decimal.split('').concat(Array(difference).fill(0)).join('');
    } else {
        return val + `${val.includes('.') ? '' : '.'}00`;
    }
}
