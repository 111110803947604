import {API_URLS_APP, UrlGenerator} from './../../constants/api-urls.constant';
import { IListOfStreamsLinks } from './../../models/stream-links.model';
import { Observable } from 'rxjs';
import { PagingParams, SortParam, IPagedResponse, IResponse } from './../../models/response.model';
import { Injectable } from '@angular/core';
import {IListFilterItem} from '@src/app/components/media-list/media-list.component';
import { ApiService } from 'ui-elements';
import {IMediaItem} from '@src/app/models/core.model';
import {Router} from '@angular/router';

@Injectable({providedIn: 'root'})
export class StreamLinksService {
    public isShowLinkedStreamsComponent: boolean;

    constructor(
        private apiService: ApiService,
        private router: Router,
    ) {
    }

    saveStreamLink(data): Observable<IResponse<any>> {
        return this.apiService.post(API_URLS_APP.STREAM_LINK, {...data});
    }

    public getListOfLinks(streamId: string,
                            searchQuery = '',
                            paging: PagingParams = DEFAULT_PAGING,
                            sort: SortParam[] = DEFAULT_LINKS_SORTING): Observable<IPagedResponse<IListOfStreamsLinks>> {
        return this.apiService.post(UrlGenerator.generate(API_URLS_APP.GET_STREAM_LINKS, {streamId}), {
            paging,
            sort,
            searchTerm: searchQuery
        });
    }

    public linkableStreams(streamId: string,
                            searchQuery = '',
                            paging: PagingParams = DEFAULT_PAGING,
                            sort: SortParam[],
                            filters: IListFilterItem[]): Observable<IPagedResponse<IMediaItem>> {

        const isEmbedded = this.router.url.includes('/embedded/');

        return this.apiService.post(UrlGenerator.generate(API_URLS_APP.GET_LINKABLE_STREAMS, {streamId}), {
            paging,
            sort,
            searchTerm: searchQuery,
            filters,
            groupFilters: [{field: 'VISIBLE', value: ['everywhere', isEmbedded ? 'except_streamdust' : 'streamdust_only']}]
        });
    }

    public deleteStreamLink(mediaId: string, otherMediaId: string): Observable<IResponse> {
        return this.apiService.post(API_URLS_APP.STREAM_UNLINK, {mediaId, otherMediaId});
    }

    public onClick(mediaId: string, otherMediaId: string): Observable<IResponse> {
        return this.apiService.post(UrlGenerator.generate(API_URLS_APP.STREAM_LINK_CLICK, {mediaId, otherMediaId}), {}, {
            openApi: true,
            isWithoutRootUrl: true
        });
    }

    public getMediaById(productId: string): Observable<IResponse<any>> {
        return this.apiService.get(UrlGenerator.generate(API_URLS_APP.GET_MEDIA_ONLY_BY_ID, {productId}));
    }
}

const DEFAULT_PAGING = {
    page: 0,
    itemsOnPage: 10
} as PagingParams;

const DEFAULT_LINKS_SORTING = [{
    field: 'LINKED_MEDIA_NAME',
    order: 'ASC'
}] as SortParam[];
