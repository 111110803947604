import {Observable, Observer, Subject} from 'rxjs';
import {getTokenWithoutPrefix} from '@src/app/utils/token-without-prefix.util';

export class WebSocketConnector {
    private subject: Subject<any>;
    private ws: WebSocket;
    private keepAliveTimeInterval: ReturnType<typeof setInterval>;
    private readonly KEEP_ALIVE_INTERVAL = 240000;
  constructor() {
  }

  public connect<T>(url, token: string = null, protocols: string[], keepAlive: boolean): Subject<T> {
    if (!this.subject) {
      protocols = [getTokenWithoutPrefix(token), ...protocols]
          .filter(protocol => protocol !== '');

      this.subject = this.create(url, protocols);
      if(keepAlive) {
        this.keepAliveTimeInterval = setInterval(() => {
          this.subject.next({type: 'KEEP_ALIVE'});
        }, this.KEEP_ALIVE_INTERVAL);
      }
    }
    return this.subject;
  }

  private create<T>(url, protocols): Subject<T> {
    // const options = {
    //   WebSocket: WebSocket, // custom WebSocket constructor
    //   connectionTimeout: 1000,
    //   maxRetries: 10,
    // };
    // this.ws = new WebSocket(url, protocols);
    this.ws = new WebSocket(url, protocols);

    const observable = new Observable((obs: Observer<T>) => {
      this.ws.onmessage = obs.next.bind(obs);
      this.ws.onerror = obs.error.bind(obs);
      this.ws.onclose = obs.complete.bind(obs);
      return this.ws.close.bind(this.ws);
    });
    const observer = {
      next: (data: Object) => {
        if (this.ws.readyState === WebSocket.OPEN) {
          this.ws.send(JSON.stringify(data));
        }
      },
      complete: () => {
        this.close();
      },
      error: (e) => {
        console.error('WebSocketConnector_ERROR', e);
      }
    };

    return Subject.create(observer, observable);
  }

  private close(): void {
    if (!this.closed()) {
      this.ws.close();
      this.subject = null;
      clearInterval(this.keepAliveTimeInterval);
    }
  }

  private closed(): boolean {
    return !this.ws || [WebSocket.CLOSED, WebSocket.CLOSING].includes(this.ws.readyState);
  }

}
