<div class="sticky-box">
    <div class="payment-overview-box summary">
        <div class="payment-overview-header">
            <h3 class="title">{{'subscription-upgrade.payment-check.title' | localize}}</h3>
        </div>
        <div class="payment-overview-body">
            <p class="text-dark mb-10">{{(planPeriod ? 'subscription-upgrade.payment-check.body' : 'subscription-upgrade.payment-check.body') | localize}}</p>
            <div class="summary-box">
                <div class="row row-padding-3">
                    <div class="col">
                        <p>{{orderTitle}}</p>
<!--                        <p class="text-secondary-transparent">-->
<!--                            {{'subscription-upgrade.payment-check.subscription-plan-upgrade' | localize}}-->
<!--                        </p>-->
                    </div>
                    <div class="col-auto ml-auto">
                        <span class="price">{{itemsOrderedAmount}}x{{orderPriceTaxFreePerItem | currency}}</span>
                    </div>
                </div>

                <div class="horizontal-divider"></div>

                <div class="row">
                    <div class="col">
                        <p>{{'subscription-upgrade.payment-check.subtotal' | localize}}</p>
                    </div>
                    <div class="col-auto ml-auto">
                        <span class="price">{{orderPriceTaxFree | currency}}</span>
                    </div>
                </div>

                <div class="horizontal-divider"></div>

                <div class="row">
                    <div class="col">
                        <p>{{"subscription-upgrade.vat" | localize}} {{ vatPercentage }}</p>
                    </div>
                    <div class="col-auto ml-auto">
                        <span class="price">{{orderPriceWithVat | currency}}</span>
                    </div>
                </div>

                <div class="horizontal-divider"></div>

                <div class="row justify-content-between">
                    <div class="col-auto">
                        <p class="mb-5">{{'subscription-upgrade.payment-check.summary' | localize}}</p>
                    </div>
                    <div class="col-auto ml-auto">
                        <span class="summary-price">{{orderPriceVat | currency}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
