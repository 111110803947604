<div class="container">
    <span class="page-sub-title ng-star-inserted">{{title | localize}}
        <app-bubble-counter [badgeCounter]="totalProfiles"></app-bubble-counter>
    </span>
</div>
<div class="bar-wrapper">
    <ngx-chartjs *ngIf="!pieChartStatus.isEmpty" [options]="options" [data]="data" [type]="type"></ngx-chartjs>
    <app-empty-results *ngIf="pieChartStatus.isEmpty" [text]="'default.noData'"></app-empty-results>
</div>


