<div class="pdf-container wrapper-slider">
    <div class="page-counter">
        Pages {{config.currentPage}}/{{config.totalPages}}
    </div>

    <pdf-viewer [src]="config.src"
                [original-size]="config.originalSize"
                [show-all]="config.showAll"
                [page]="config.currentPage"
                [fit-to-page]="true"
                (after-load-complete)="afterLoadComplete($event)"
    ></pdf-viewer>

    <div class="slider-nav" *ngIf="config?.totalPages > 1">
        <div class="slider-prev" (click)="prevPage()">
            <img src="assets/img/ico/icon-prev-primary.png" alt="">
        </div>
        <div class="slider-next" (click)="nextPage()">
            <img src="assets/img/ico/icon-next-primary.png" alt="">
        </div>
    </div>



    <div class="top-gradient"></div>

    <div class="button-box">
        <i *ngIf=config.isDownload class="icon icon-download"></i>
        <i *ngIf=config.isRemove class="icon icon-trash2"></i>
    </div>
</div>
