/**
 * Created by Тима on 03.04.2020.
 */
import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { CustomValidators } from '@src/app/utils/custom-validators.util';
import {IFieldsConfig} from '@src/app/pages/user-profile/interfaces/interfaces-common';
import {ChangePasswordService} from '@src/app/pages/user-profile/components/change-password/services/change-password.service';
import {LoadingService} from '@src/app/services/loading/loading.service';
import {IServerError} from '@src/app/models/server-error.model';
import {DEFAULT_ERROR_MESSAGE} from '@src/app/constants/server-errors.constant';
import {LocalizationProvider} from 'ui-elements';
import { INFO_BOX_TYPES } from '@src/app/components/info-box/info-box.component';
import {tap} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';
import {IResponse} from '@src/app/models/response.model';

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.css'],
    providers: [ChangePasswordService]
})
export class ChangePasswordComponent implements OnInit  {

    public fieldsConfig: IFieldsConfig[];
    public form: FormGroup;
    public serverErrors: IServerError[] = [];
    public INFO_BOX_TYPES = INFO_BOX_TYPES;
    public success$ = new Subject();

    constructor(
        private changePasswordService: ChangePasswordService,
        private fb: FormBuilder,
        public loadingService: LoadingService,
        private readonly localization: LocalizationProvider,
    ) {
        this.form = this.generateChangePasswordForm();
    }

    ngOnInit() {
        this.fieldsConfig = this.changePasswordService.getFieldsConfig();
    }
    public changePassword(): Observable<IResponse<any>> {
        this.form.markAllAsTouched();
        // this.form.get('oldPassword').setErrors({});
        if (this.form.invalid) { return; }

        this.loadingService.loadingStart();

        this.changePasswordService.changePassword(this.form.value.oldPassword, this.form.value.newPassword)
            .subscribe(
                (response) => {
                    this.loadingService.loadingEnd();
                    if (!response.success) {
                        if (response.errorKey === 'OLD_PASSWORD_MISMATCH') {
                            this.form.get('oldPassword').setErrors({[response.errorKey]: true});
                            this.form.markAllAsTouched();
                            this.form.updateValueAndValidity();

                            return;
                        }
                        return this.serverErrors = [{
                            key: response.errorKey,
                            text: this.localization.getByErrorKey(response.errorKey) || DEFAULT_ERROR_MESSAGE
                        }];
                    }
                    this.serverErrors = [];
                    this.success$.next();
                    //
                    // this.isPasswordChanged = success;
                    // this.loading.passwordChanging = false;
                },
                () => {
                    // this.loading.passwordChanging = false
                    this.loadingService.loadingEnd();
                }
            );
    }
    private generateChangePasswordForm(): FormGroup {
        const passwordControl = new FormControl('', [Validators.required, CustomValidators.password]);
        return this.fb.group({
            oldPassword: ['', [Validators.required]],
            newPassword: passwordControl,
            newPasswordConfirmation: ['', [CustomValidators.passwordsMatch(passwordControl)]]
        });
    }
}
