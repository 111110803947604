<div class="card" [class.margin-bottom-0]="withoutPadding">
    <div class="row row-align-stretch">
        <div class="mr-auto col-md-8" [class.col-md-8]="!fullWidth" [class.col-md-12]="fullWidth">
            <form class="default-form" [formGroup]="form"
                  [class.padding-bottom-0]="withoutPadding"
                  [class.padding-left-0]="removeDefaultPadding"
            >
                <ng-container *ngFor="let row of fieldsConfig">
                    <ng-container *ngIf="row.length === 1">
                        <ng-container *ngFor="let field of row">
                            <div style="padding: 0"
                                 [class.col-md-6]="field.config.inputType === INPUT_TYPES.CHECKBOX || field.config.halfSize">
                                <div class="form-group">
                                    <!--<label *ngIf="field.config.label">{{field.config.label}}</label>-->
                                    <ano-input [formControlName]="field.name"
                                               [errors]="form.get(field.name).errors"
                                               [isTouched]="form.get(field.name).touched"
                                               [invalid]="form.get(field.name).invalid"
                                               [multimediaUrl]="photoUrl"
                                               [disabled]="disabled"
                                               [clearImageButton]="clearImageButton"
                                               [disabledToggle]="disabledToggle"
                                               [config]="{
                                                    inputType: field.config.inputType,
                                                    type: field.config.type,
                                                    label: field.config.label,
                                                    placeholder: field.config.placeholder,
                                                    isRequired: field.config.isRequired,
                                                    selectOptions: field.config.selectOptions,
                                                    datetimeType: field.config.datetimeType,
                                                    startDate: getStartDate(),
                                                    endDate: getEndDate()
                                                    }"
                                               (onFileUpload$)="uploadPreviewPhoto($event)"
                                               (clearImage$)="clearImage($event)"
                                               (toggle$)="toggle($event)"
                                    ></ano-input>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="row.length > 1">
                        <div class="row row-padding-10">
                            <ng-container *ngFor="let field of row">
                                <div class="col-md-{{row.length > 1 ? '6' : '12'}}">
                                    <div class="form-group">
                                        <!--<label *ngIf="field.config.label">{{field.config.label}}</label>-->
                                        <ano-input [formControlName]="field.name"
                                                   [name]="field.name"
                                                   [disabled]="disabled"
                                                   [errors]="form.get(field.name).errors"
                                                   [isTouched]="form.get(field.name).touched"
                                                   [invalid]="form.get(field.name).invalid"
                                                   [multimediaUrl]="photoUrl"
                                                   [clearImageButton]="clearImageButton"
                                                   [config]="{
                                                    inputType: field.config.inputType,
                                                    type: field.config.type,
                                                    label: field.config.label,
                                                    placeholder: field.config.placeholder,
                                                    isRequired: field.config.isRequired,
                                                    selectOptions: field.config.selectOptions,
                                                    datetimeType: field.config.datetimeType,
                                                    startDate: getStartDate(),
                                                    endDate: getEndDate()
                                                    }"
                                                   (onFileUpload$)="uploadPreviewPhoto($event)"
                                                   (clearImage$)="clearImage($event)"
                                        ></ano-input>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                </ng-container>
                <div class="form-group" *ngIf="!withoutSubmit">
                    <ano-button (click)="submit()"
                                [config]="{type: 'button', styleType: 'success', sizeType: 'sm'}">{{submitButtonText}}</ano-button>
                </div>
            </form>
        </div>
        <div class="col-auto" [class.align-self-end]="withSideSubmit">
            <div class="card-body" *ngIf="withSideSubmit">
                <button class="btn button-primary">{{'common.controlCenter' | localize}}</button>
                <button class="btn btn-success"
                        (click)="submit()">Speichern</button>
            </div>
        </div>
    </div>
</div>


