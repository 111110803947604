<div class="content">
    <div class="panel">
        <div class="panel-header pb-0">
            <h2 class="title-lg"><b>{{'groups.title' | localize}}</b></h2>
        </div>
    </div>
</div>

<div class="card bg-white card-livestreams">
    <div class="card-body groups-list">
        <ano-table #tableComponent [tableConfig]="tableConfig" [defaultSortField]="'CREATED'" [sortOrder]="1"
                   [withSearch]="true" [addButton]="true" [addButtonText]="'common.button.create'"
                   (addItem$)="newGroup()"
                   (search$)="search($event)"
        ></ano-table>
        <ng-template #nameTmpl let-data="rowData" class="ss-test">
            <div class="long-text">
                <span>
                    {{data.name}}
                </span>
            </div>
        </ng-template>
        <ng-template #paymentTypeTmpl let-data="rowData">
            {{getPaymentType(data.paymentType)}}
        </ng-template>
        <ng-template #priceTmpl let-data="rowData">
            {{data.price | currency }}
        </ng-template>
        <ng-template #actionsTmpl let-data="rowData">
            <div class="table-actions-list">
                <b class="text-primary">
                    <i (click)="goToGroupEdit(data.id)"
                       class="icon icon-edit cursor-pointer"></i> |
                </b>
                <b class="text-primary">
                <i (click)="deleteGroup(data)"
                   class="icon icon-trash text-danger cursor-pointer"></i> |
                </b>

                <div (click)="goToGroupRevenue(data.id)" class="ticket-btn btn-primary">{{'revenue' | localize}}</div>
            </div>
        </ng-template>
        <ng-template #confirm let-dialogRef="dialogRef">
            <ano-modal [dialogRef]="dialogRef" [title]="'groups.deleteModalTitle' | localize" [isConfirmation]="true">
                {{confirmText}}
            </ano-modal>
        </ng-template>
    </div>
</div>
