import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IframeTestComponent } from './iframe-test.component';
import {SharedModule} from '@src/app/modules/shared/shared.module';



@NgModule({
  declarations: [IframeTestComponent],
    imports: [
        CommonModule,
        SharedModule
    ]
})
export class IframeTestModule { }
