import {Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {IPresentationMetadata} from '@src/app/models/stream.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {IUnitKeyValue} from '@src/app/pages/user-profile/interfaces/interfaces-common';
import {IFormConfig, StreamService} from '@src/app/services/stream-metadata/stream.service';
import {IPresentationList} from '@src/app/services/file/presentation.service';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import {LoadingService} from '@src/app/services/loading/loading.service';
import {ActivatedRoute} from '@angular/router';
import {debounceTime, pluck, takeUntil} from 'rxjs/operators';
import {STREAM_STATUS} from '@src/app/components/streamdust-player/constants/status';
import {
    GOOGLE_MAPS,
    InitConfig,
    IOverlayLocationConfig,
    OverlayLocationService
} from '@src/app/services/overlay/overlay-location.service';
import {MatDialog} from '@angular/material/dialog';
import {Observable} from 'rxjs';
import {IResponse} from '@src/app/models/response.model';
import {MapsAPILoader} from '@agm/core';
import {CmsService, ICountry, INPUT_TYPES, ITableConfig, LocalizationProvider, TableComponent} from 'ui-elements';

@Component({
    selector: 'app-stream-overlay-location',
    templateUrl: './stream-overlay-location.component.html',
    styleUrls: ['./stream-overlay-location.component.scss'],
})
export class StreamOverlayLocationComponent implements OnInit, InitConfig {
    @ViewChild('tableComponent', {static: false}) public table: TableComponent;
    @ViewChild('statusTmpl', {static: true})
    public statusTmpl: TemplateRef<any>;
    @ViewChild('actionsTmpl', {static: true})
    public actionsTmpl: TemplateRef<any>;
    @ViewChild('radiusTmpl', {static: true})
    public radiusTmpl: TemplateRef<any>;
    @ViewChild('countryTmpl', {static: true})
    public countryTmpl: TemplateRef<any>;
    @ViewChild('latitudeTmpl', {static: true}) latitudeTmpl: TemplateRef<any>;

    @ViewChild('confirm', {static: false}) confirm: TemplateRef<any>;

    @Input() config: IOverlayLocationConfig;
    @Input() id: string;

    private searchQuery: string;
    private confirmTextTpl = this.localizationProvider.getByKey(
        'overlays.deleteOverlayText'
    );

    presentation: IPresentationMetadata;
    formsConfig: IFormConfig[];
    STREAM_STATUS = STREAM_STATUS;
    payload = {
        id: null,
        country: '',
        city: '',
        radius: '',
        overlayId: '',
    };
    tableConfig: ITableConfig<IPresentationList>;
    form: FormGroup;
    fieldsConfig: any;
    showForm = false;
    private confirmText: string;
    private cities = [];

    googleMap = {
        latitude: 0,
        longitude: 0,
        zoom: 8,
    };

    constructor(
        private fb: FormBuilder,
        public streamService: StreamService,
        public localizationProvider: LocalizationProvider,
        private readonly destroy$: AutoDestroyService,
        public loadingService: LoadingService,
        public activatedRoute: ActivatedRoute,
        public overlayLocationService: OverlayLocationService,
        public cmsService: CmsService,
        public dialog: MatDialog,
        public mapsApiLoader: MapsAPILoader
    ) {
    }

    get disabledAddButton(): boolean {
        return this.showForm;
    }

    ngOnInit(): void {
        this.payload.overlayId = this.config.overlayId;
        this.formsConfig = this.streamService.getStreamPresentationFormsConfig();
        this.initForms();
        this.initFormsConfig();
        this.initTableConfig();
        this.listenForm();
    }

    initForms(): void {
        this.form = this.fb.group({
            id: [''],
            country: ['', [Validators.required]],
            city: [''],
            radius: [''],
        });
    }

    initTableConfig() {
        this.tableConfig = {
            dataField: 'data',
            searchFn: (sortParams, pagingParams) => {
                return this.overlayLocationService.getList(
                    this.config.overlayId,
                    this.searchQuery,
                    pagingParams,
                    sortParams,
                    true
                );
            },
            columns: [
                {
                    name: 'overlays.table.status',
                    sortField: 'ACTIVE',
                    tmpl: this.statusTmpl,
                    width: 70,
                },
                {
                    name: 'overlay.location.table.field.location',
                    tmpl: this.latitudeTmpl,
                    width: 70,
                    class: 'center',
                },
                {
                    name: 'overlay.location.table.field.country',
                    sortField: 'COUNTRY',
                    dataField: 'country',
                    width: 100,
                    tmpl: this.countryTmpl,
                    textAlign: 'left',
                },
                {
                    name: 'overlay.location.table.field.city',
                    sortField: 'CITY',
                    dataField: 'city',
                    width: 100,
                    textAlign: 'left',
                },
                {
                    name: 'overlay.location.table.field.radius',
                    sortField: 'RADIUS',
                    tmpl: this.radiusTmpl,
                    width: 100,
                    class: 'right',
                },
                {
                    name: 'overlays.table.actions',
                    tmpl: this.actionsTmpl,
                    class: 'center actions',
                },
            ],
        };
    }

    initFormsConfig() {
        this.fieldsConfig = [
            [
                {
                    name: 'country',
                    config: {
                        inputType: INPUT_TYPES.SELECT,
                        isAutocomplete: true,
                        isClearButton: true,
                        placeholder: 'overlay.location.country',
                        selectOptions: this.getCountries(),
                        isRequired: true,
                    },
                },
                {
                    name: 'city',
                    config: {
                        inputType: INPUT_TYPES.SELECT,
                        isAutocomplete: true,
                        isClearButton: true,
                        placeholder: 'overlay.location.city',
                        selectOptions: [],
                    },
                },
                {
                    name: 'radius',
                    config: {
                        inputType: INPUT_TYPES.SELECT,
                        placeholder: 'overlay.location.radius',
                        isClearButton: true,
                        selectOptions: this.getRadius(),
                    },
                },
            ],
        ];
    }

    private toggleActive(active, model) {
        const payload = {
            locationId: model.id,
            active: active,
        };
        this.loadingService.loadingStart();
        this.overlayLocationService
            .active(payload)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                (res) => {
                    this.loadingService.loadingEnd();
                },
                () => this.loadingService.loadingEnd()
            );
    }

    public search(event: string): void {
        this.searchQuery = event;
        this.table.refreshData({});
    }

    onToggle($event: any, data) {
        this.toggleActive($event.value, data);
    }

    addNewItem() {
        this.showForm = true;
        this.form.reset();
        this.form.markAsPristine();
    }

    getCountries(): IUnitKeyValue[] {
        return this.cmsService.countriesList;
    }

    cancel() {
        this.showForm = false;
        this.form.reset();
        this.form.markAsPristine();
    }

    save() {
        this.form.markAllAsTouched();

        if (this.form.invalid) {
            return;
        }

        this.payload = {
            ...this.payload,
            ...this.form.getRawValue(),
        };

        if (!this.payload.id) {
            delete this.payload.id;
        }

        this.loadingService.loadingStart();

        let overlayObserver: Observable<IResponse>;

        if (this.payload.id) {
            overlayObserver = this.overlayLocationService.update(this.payload, true);
        } else {
            overlayObserver = this.overlayLocationService.create(this.payload, true);
        }

        overlayObserver.pipe(takeUntil(this.destroy$)).subscribe(
            (res) => {
                this.loadingService.loadingEnd();
                if (res.success) {
                    this.showForm = false;
                    this.form.reset();
                    this.form.markAsPristine();
                    this.table.refreshData({});
                }
            },
            () => this.loadingService.loadingEnd()
        );
    }

    private getRadius() {
        return this.overlayLocationService.getRadius();
    }

    goToEdit(data: any) {
        this.form.patchValue(data);
        this.form.get('country').patchValue(data.country.code);
        this.showForm = true;
        if (!this.cities.length) {
            setTimeout(() => {
                this.setCityLocationByName(data.city);
            }, 500);
            return;
        }
        this.setCityLocationByName(data.city);
    }

    getShortNameLocation(data) {
        const name = [data.country.name];
        if (data.city) {
            name.push(data.city);
        }
        if (data.radius) {
            name.push(data.radius + ' km');
        }

        return '<b>' + name.join(', ') + '</b>';
    }

    delete(data, $event: MouseEvent) {
        $event.stopPropagation();
        this.confirmText = this.confirmTextTpl.replace(
            '{overlayName}',
            this.getShortNameLocation(data)
        );
        const dialogRef = this.dialog.open(this.confirm);

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.destroy$))
            .subscribe((confirm: boolean) => {
                this.confirmText = '';
                if (confirm) {
                    this.overlayLocationService
                        .delete(data.id)
                        .pipe(takeUntil(this.destroy$))
                        .subscribe((res) => {
                            if (res.success) {
                                this.table.resetTableData();
                                this.table.refreshData({});
                            }
                        });
                }
            });
    }

    private listenForm() {
        this.form
            .get('country')
            .valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((countryCode) => {
                this.setCountryLocationByCode(countryCode);
                const fieldCity = this.fieldsConfig[0].find(
                    (item) => item.name === 'city'
                );

                if (!countryCode) {
                    this.form
                        .get('city')
                        .setValue(null, {onlySelf: false, emitEvent: false});
                    fieldCity.config.selectOptions = [];
                    return;
                }

                this.setCitiesOptions(countryCode);
            });

        this.form
            .get('city')
            .valueChanges.pipe(takeUntil(this.destroy$), debounceTime(500))
            .subscribe((cityName) => {
                if (!cityName || !this.cities.length) {
                    return;
                }

                this.setCityLocationByCode(cityName);
            });
    }

    setGoogleMapLocation(obj: any) {
        if (!obj || !obj.latitude || !obj.longitude) {
            return;
        }
        this.googleMap.latitude = +obj.latitude;
        this.googleMap.longitude = +obj.longitude;
    }

    getCity(countryId) {
        return this.overlayLocationService.getCities(countryId, true);
    }

    zoomPlus() {
        if (this.googleMap.zoom <= GOOGLE_MAPS.maxZoom) {
            this.googleMap.zoom++;
        }
    }

    zoomMinus() {
        if (this.googleMap.zoom > GOOGLE_MAPS.minZoom) {
            this.googleMap.zoom--;
        }
    }

    navigateToMap(data, event) {
        event.stopImmediatePropagation();
        const url = `http://maps.google.com/maps?q=${data.latitude},${data.longitude}`;
        window.open(url, event);
    }

    private setCitiesOptions(countryCode: string) {
        if (typeof countryCode !== 'string') {
            return;
        }

        const field = this.fieldsConfig[0].find((item) => item.name === 'city');
        this.getCity(countryCode)
            .pipe(takeUntil(this.destroy$), pluck('results', 'data'))
            .subscribe((cities) => {
                this.cities = cities;
                field.config.selectOptions = this.cmsService.mapCitiesToKeyValue(
                    this.cities
                );
                field.hidden = true;
                setTimeout(() => (field.hidden = false), 1);
            });
    }

    private setCountryLocationByCode(countryCode) {
        const country = this.cmsService.getCountyByCode(countryCode);
        this.setGoogleMapLocation(country);
    }

    private setCityLocationByName(cityName) {
        const city: ICountry = this.cmsService.getCityByName(this.cities, cityName);
        if (!city) {
            return;
        }
        this.setGoogleMapLocation(city);
    }

    private setCityLocationByCode(cityCode) {
        const city: ICountry = this.cmsService.getCityByCode(this.cities, cityCode);
        if (!city) {
            return;
        }
        this.setGoogleMapLocation(city);
    }
}
