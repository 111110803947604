import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class EventSiteState {
    private isEventSite: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public isEventSite$: Observable<boolean> = this.isEventSite.asObservable();

    public constructor() {}

    public setIsEventSite(state: boolean): void {
        this.isEventSite.next(state);
    }
}
