import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {StreamPageModule} from '@src/app/pages/stream-page/stream-page.module';
import {PublicMediaPageModule} from '@src/app/pages/public-media-page/public-media-page.module';

@Injectable()
export class PresentationState implements OnDestroy {
    public activeState$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private active = false;
    public chatOverlayState$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private chatOverlay = false;
    public currentPosition$: BehaviorSubject<{ x: number, y: number }> = new BehaviorSubject<{ x: number, y: number }>(null);

    constructor() {
    }

    public close(): void {
        this.active = false;
        this.activeState$.next(this.active);
    }

    public toggle(): void {
        this.active = !this.active;
        this.activeState$.next(this.active);
    }

    public toggleOverlay(): void {
        this.chatOverlay = !this.chatOverlay;
        this.chatOverlayState$.next(this.chatOverlay);
    }

    public setOverlayState(state: boolean): void {
        this.chatOverlay = state;
        this.chatOverlayState$.next(this.chatOverlay);
    }

    ngOnDestroy(): void {
    }

}
