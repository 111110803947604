import {BehaviorSubject, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {IFailedTransactionInfo} from '@src/app/services/payment/payment.service';

@Injectable()
export class DonationState {
    public donationActiveState$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private donationActive = false;
    public donationOverlayState$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public currentPosition$: BehaviorSubject<{x: number, y: number}> = new BehaviorSubject<{x: number, y: number}>(null);
    private donationOverlay = false;
    private donationFailed$: BehaviorSubject<IFailedTransactionInfo> = new BehaviorSubject<IFailedTransactionInfo>(null);

    constructor() {
    }

    public closeDonations(): void {
        this.donationActive = false;
        this.donationActiveState$.next(this.donationActive);
    }

    public toggleDonations(): void {
        this.donationActive = !this.donationActive;
        this.donationActiveState$.next(this.donationActive);
    }

    public toggleOverlay(): void {
        this.donationOverlay = !this.donationOverlay;
        this.donationOverlayState$.next(this.donationOverlay);
    }

    public setOverlayState(state: boolean): void {
        this.donationOverlay = state;
        this.donationOverlayState$.next(this.donationOverlay);
    }

    public setFailed(failedInfo: IFailedTransactionInfo): void {
        this.donationFailed$.next(failedInfo);
    }

    public get failed$(): Observable<IFailedTransactionInfo> {
        return this.donationFailed$.asObservable();
    }
}
