import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { StateRegistration } from '../../services/registration.service';

@Component({
  selector: "app-publisher-viewer-cards",
  templateUrl: "./publisher-viewer-cards.component.html",
  styleUrls: ["./publisher-viewer-cards.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PublisherViewerCardsComponent implements OnInit {
  @Output() change: EventEmitter<string> = new EventEmitter<string>();

  constructor() {}

  public get stateRegistartion(): (typeof StateRegistration) {
    return StateRegistration;
  }

  ngOnInit(): void {}

  public changeState(state: string): void {
    this.change.emit(state);
  }
}
