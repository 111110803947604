<div class="panel">
    <div class="panel-header">
        <h2><b>{{'remind.title' | localize}}</b></h2>
        <p class="text-sm">{{'remind.subtitle' | localize}}</p>
    </div>
    <div class="card bg-white padding-bottom-30">
        <div class="col-4">
            <div class="card-body settings-block section-panel">
                <app-reminder [mediaId]="mediaId"></app-reminder>
            </div>
        </div>
    </div>
</div>
