import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {IMediaGroup} from '@src/app/models/stream-groups.model';
import {DragScrollComponent} from 'ngx-drag-scroll';
import {takeUntil} from 'rxjs/operators';
import {GroupModalState} from '@src/app/state/group-modal-state';
import {AutoDestroyService} from 'ui-elements';

@Component({
  selector: 'app-groups-list',
  templateUrl: './groups-list.component.html',
  styleUrls: ['./groups-list.component.scss'],
  providers: [AutoDestroyService]
})
export class GroupsListComponent implements OnInit {
  @ViewChild('sliderComp', {read: DragScrollComponent}) set sliderComp(component: DragScrollComponent) {
    if (component) {
      this.slider = component;
      this.slider.reachesLeftBound.subscribe(res => this.sliderArrow.leftArrow = !res);
      this.slider.reachesRightBound.subscribe(res => this.sliderArrow.rightArrow = !res);
    }
  }
  slider: DragScrollComponent;
  numberOfSlides: number;
  sliderArrow = {leftArrow: false, rightArrow: true};
  @Input() set data(data: IMediaGroup[]) {
    this._data = data;
    this.numberOfSlides = this._data.length;
    if (this._data?.length === 1) {
      this.groupSelected$.emit(this._data[0]);
    }
  }
  @Output() groupSelected$: EventEmitter<IMediaGroup> = new EventEmitter<IMediaGroup>();
  private _data: IMediaGroup[];
  get data(): IMediaGroup[] {
    return this._data;
  }

  constructor(
      private groupModalState: GroupModalState,
      private destroy$: AutoDestroyService
  ) { }

  ngOnInit(): void {
    this.groupModalState.paymentStatus$.pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          if (!res) {
            return;
          }
          const currentGroup = this.data.find(group => group.id === res.groupId);
          if (currentGroup) {
            this.selectGroup(currentGroup);
          }
        });
  }

  moveLeft(): void {
    this.slider.moveLeft();
  }

  moveRight(): void {
    this.slider.moveRight();
  }

  public selectGroup(group: IMediaGroup): void {
    this.groupSelected$.emit(group);
  }

}
