<div class="panel">
    <div class="panel-header">
        <h2><b>{{'presentations.title' | localize}}</b></h2>
        <p class="text-sm">{{'presentations.subtitle' | localize}}</p>
    </div>
    <div class="bg-white padding-bottom-30">
        <div class="upload-block section-panel">
            <div class="title-box">
                <h2 class="title-level-3 title-upload-presentation">
                    Upload
                </h2>
                <form [formGroup]="activateForm" *ngIf="payload.id">
                    <mat-slide-toggle [formControlName]="'active'" class="video-activation-toggle"></mat-slide-toggle>
                </form>
            </div>

            <app-stream-multimedia-upload
                    *ngIf="!file.fileId"
                    [multimediaType]="'pdf'"
                    [buttonsGroupBorderTop]="false"
                    [multimediaUrl]="presentationUrl"
                    [metadata]="presentation"
                    [formsConfig]="formsConfig"
                    [withTable]="false"
                    [disabled]="(streamService.stream$ | async)?.media?.deactivated
                    || ((streamService.stream$ | async)?.media?.status?.stream === STREAM_STATUS.FINISHED
                    && !(streamService.stream$ | async)?.media?.hasRecording) || ((streamService.stream$ | async)?.media?.status?.stream === STREAM_STATUS.PENDING)"
                    [isSinglePage]="false"
                    (uploadMultimedia$)="uploadPresentation($event)"
                    (clearMultimedia$)="clearPresentation($event)">
            </app-stream-multimedia-upload>

            <div class="settings-block" *ngIf="file.fileId">
                <div class="pdf-viewer-box">
                    <div class="pdf-viewer-section">
                        <app-pdf-viewer-img [data]="file"
                                            [config]="pdf"
                                            [playerMode]="playerMode"
                                            (remove$)="removePresentation($event)"
                        >
                        </app-pdf-viewer-img>
                    </div>
                </div>
                <p class="file-info" *ngIf="file.fileName">
                    {{file.fileName}} ({{file.fileUploadDate | dateTimeFormat: 'DD MMM YYYY, HH:mm'}})
                </p>
            </div>
        </div>
        <div class="settings-block col-lg-8 section-panel">
            <div class="input-wrap border-bootom">
                <app-bordered-form
                        [defaultForm]="true"
                        [form]="pdfForm"
                        [disabled]="(streamService.stream$ | async)?.media?.deactivated || ((streamService.stream$ | async)?.media?.status?.stream === STREAM_STATUS.PENDING)"
                        [fieldsConfig]="pdfFormConfig"
                ></app-bordered-form>
            </div>
            <div class="checkbox-wrap">
                <h4><b>{{'presentations.settings.title' | localize}}</b></h4>
                <app-bordered-form
                        [disabled]="(streamService.stream$ | async)?.media?.deactivated || ((streamService.stream$ | async)?.media?.status?.stream === STREAM_STATUS.FINISHED && !(streamService.stream$ | async)?.media?.hasRecording) || ((streamService.stream$ | async)?.media?.status?.stream === STREAM_STATUS.PENDING)"
                        [panel]="true"
                        [defaultForm]="true"
                        [form]="settingsForm"
                        [fieldsConfig]="settingsFormConfig">
                </app-bordered-form>
            </div>
        </div>
        <div class="button-block">
            <button *ngIf="!(streamService.stream$ | async)?.media?.deactivated && !((streamService.stream$ | async)?.media?.status?.stream === STREAM_STATUS.FINISHED && !(streamService.stream$ | async)?.media?.hasRecording) && !((streamService.stream$ | async)?.media?.status?.stream === STREAM_STATUS.PENDING)"
                    class="btn btn-success" (click)="onSave()">{{'common.button.apply' | localize}}</button>
        </div>
    </div>

    <div class="panel panel-md">
        <div class="panel-body bg-white padding-bottom-30 bg-white-margin">
            <div class="section-panel">
                <div class="">
                    <h2 class="title-level-3 title-upload-presentation">
                        {{'presentation.table.title'|localize}}
                    </h2>
                </div>
                <app-presentations-table #tableComponent [mediaId]="payload.mediaId"></app-presentations-table>
<!--                <ano-table class="presentation-table" #tableComponent-->
<!--                           [tableConfig]="tableConfig"-->
<!--                           [defaultSortField]="'FILE_NAME'">-->
<!--                </ano-table>-->
            </div>
        </div>
    </div>

</div>
