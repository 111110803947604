import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PaymentStartPageComponent} from "@src/app/pages/payment-start-page/payment-start-page.component";
import {SharedModule} from "@src/app/modules/shared/shared.module";
import { CheckoutDetailsComponent } from './checkout-details/checkout-details.component';
import { PaymentCheckComponent } from './payment-check/payment-check.component';
import { SubscriptionUpgradeComponent } from './subscription-upgrade/subscription-upgrade.component';
import { GroupGetAccessComponent } from './group-get-access/group-get-access.component';


@NgModule({
    declarations: [PaymentStartPageComponent, CheckoutDetailsComponent, PaymentCheckComponent, SubscriptionUpgradeComponent, GroupGetAccessComponent],
    imports: [
        CommonModule,
        SharedModule
    ]
})
export class PaymentStartModule {
}
