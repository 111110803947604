<app-stream-multimedia-upload
    [multimediaType]="'photo'"
    [buttonsGroupBorderTop]="false"
    [multimediaUrl]="photoUrl"
    [metadata]="picture"
    [textHeaders]="textHeaders"
    [formsConfig]="formsConfig"
    [withTable]="false"
    [disabled]="((streamService.stream$ | async)?.media?.status?.stream === STREAM_STATUS.FINISHED && !(streamService.stream$ | async)?.media?.hasRecording) || (streamService.stream$ | async)?.media?.deactivated || (streamService.stream$ | async)?.media?.status?.stream === STREAM_STATUS.PENDING"
    [thumbnail]="true"
    (updateMultimedia$)="updatePicture($event)"
    (save$)="uploadPicture()"
    (clearMultimedia$)="clearPicture($event)">
</app-stream-multimedia-upload>
