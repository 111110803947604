/**
 * Created by Тима on 03.04.2020.
 */
import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {
    WithdrAndConditionsService,
    IUserAGB
} from '@src/app/pages/user-profile/components/withdrawal-and-conditions/services/withdrawal-and-conditions.service';
import {IFieldsConfig} from '@src/app/pages/user-profile/interfaces/interfaces-common';
import {LoadingService} from '@src/app/services/loading/loading.service';
import {CustomValidators} from '@src/app/utils/custom-validators.util';
import {takeUntil} from 'rxjs/operators';
import {UserService} from '@src/app/services/user/user.service';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import {IUserProfile} from '@src/app/pages/user-profile/components/profile/services/profile.service';
import {LocalizationProvider} from 'ui-elements';

@Component({
    selector: 'app-withdrawal-and-conditions',
    templateUrl: './withdrawal-and-conditions.component.html',
    styleUrls: ['./withdrawal-and-conditions.component.css'],
    providers: [WithdrAndConditionsService, AutoDestroyService]
})
export class WithdrawalAndConditionsComponent implements OnInit {

    public fieldsConfig: IFieldsConfig[];
    public form: FormGroup;
    public userAGB: IUserAGB;
    public userName: string;

    constructor(
        private withdrAndConditionsService: WithdrAndConditionsService,
        private fb: FormBuilder,
        private loadingService: LoadingService,
        private userService: UserService,
        private destroy$: AutoDestroyService,
        private localizationProvider: LocalizationProvider
    ) {
        this.form = this.generateForm();
        this.fieldsConfig = this.withdrAndConditionsService.getFieldsConfig();
        this.withdrAndConditionsService.getUserAGB().subscribe(
            res => {
                this.userAGB = res;
                this.fillForm();
            }
        );
    }

    ngOnInit(): void {
        this.resolveUserName();
    }

    private generateForm(): FormGroup {
        return this.fb.group({
            withdrawal: ['', [Validators.required]],
            termsConditionUrl: ['', CustomValidators.URL],
            privacyPolicyUrl: ['', CustomValidators.URL]
        });
    }

    public fillForm(): void {
        const controls = this.form.controls;
        controls.withdrawal.setValue(this.userAGB.withdrawal || this.localizationProvider.getByKey('profile.termsAndConditions.withdrawalText'));
        controls.termsConditionUrl.setValue(this.userAGB.termsConditionUrl);
        controls.privacyPolicyUrl.setValue(this.userAGB.privacyPolicyUrl);
    }

    public saveChanges(): void {
        this.form.markAllAsTouched();
        if (this.form.invalid) {
            return;
        }

        ['termsConditionUrl', 'privacyPolicyUrl'].map(control => this.patchUrlValue(control));

        this.loadingService.loadingStart();

        this.withdrAndConditionsService.saveUserAGB(this.form.value).subscribe(
            (res) => {
                this.loadingService.loadingEnd();
            },
            (error) => {
            }
        );
    }

    resolveUserName(): void {
        this.userService.userProfile$.pipe(takeUntil(this.destroy$))
            .subscribe((res: IUserProfile) => {
                if (!res) {
                    this.userName = null;
                    return;
                }

                if (!res.firstName && !res.lastName) {
                    this.userName = res.email;
                    return;
                }

                this.userName = res.firstName + ' ' + res.lastName;
            });
    }

    private patchUrlValue(controlName: string) {
        const urlField = this.form.get(controlName);
        if (urlField.value && !urlField.value.startsWith('http://') && !urlField.value.startsWith('https://')) {
            urlField.patchValue('https://' + urlField.value);
        }
    }
}
