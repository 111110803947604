<div class="calculate-box">
    <div class="row justify-content-between">
        <div class="col-auto">
            <form class="border-form" [formGroup]="bitrateForm">
                <div class="form-group">
                    <label class="form-control-label">{{'storage-and-bandwidth.bandwidth-calculator.bitrate' | localize}}</label>
                    <div class="row">
                        <div class="col-lg-7">
                            <ano-input formControlName="bitrate"
                                       [isEditMode]="true"
                                       [errors]="bitrate.errors"
                                       [isTouched]="bitrate.touched"
                                       [invalid]="bitrate.invalid"
                                       [config]="bitrateSelectInputConfig"
                            ></ano-input>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="form-control-label">{{'storage-and-bandwidth.bandwidth-calculator.number-of-viewers' | localize}}</label>
                    <div class="row">
                        <div class="col-lg-7">
                            <ano-input formControlName="numberOfViews" [isEditMode]="true"
                                       [errors]="numberOfViews.errors"
                                       [isTouched]="numberOfViews.touched"
                                       [invalid]="numberOfViews.invalid"
                                       [config]="bitrateNumberInputConfig">
                            </ano-input>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="form-control-label">{{'storage-and-bandwidth.bandwidth-calculator.stream-length' | localize}}
                        <span class="light">
                                    {{'storage-and-bandwidth.bandwidth-calculator.in-minutes' | localize}}<b>?</b>
                        </span>
                    </label>
                    <div class="row">
                        <div class="col-lg-7">
                            <ano-input formControlName="mediaLength" [isEditMode]="true"
                                       [errors]="mediaLength.errors"
                                       [isTouched]="mediaLength.touched"
                                       [invalid]="mediaLength.invalid"
                                       [config]="bitrateNumberInputConfig">
                            </ano-input>
                        </div>
                    </div>
                </div>
                <button class="btn btn-primary btn-sm"
                        (click)="calculateBandwidth()">{{'storage-and-bandwidth.buttons.calculate' | localize}}</button>
            </form>
        </div>
        <div class="col-auto">
            <div class="calculate-result">
                <div class="box sm bg-cyan">
                    <span class="result">{{estimatedBandwidthInGB}} GB</span>
                    <span class="text">{{'storage-and-bandwidth.bandwidth-calculator.estimated-bitrate' | localize}}</span>
                    <img class="icon" src="assets/img/graphics/bandwidth-speedometer-4_WHITE-4.svg">
                </div>
                <span class="horizontal-divider">=</span>
                <div class="box sm bg-cyan">
                    <span class="result">{{estimatedBandwidthInTB}} TB</span>
                    <span class="text">{{'storage-and-bandwidth.bandwidth-calculator.estimated-bitrate' | localize}}</span>
                    <img class="icon" src="assets/img/graphics/bandwidth-speedometer-4_WHITE-4.svg">
                </div>
            </div>
        </div>
    </div>
</div>
