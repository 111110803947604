import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ISurvey, SurveyService} from '@src/app/services/survey/survey.service';
import {IInputConfig, IUnitKeyValue, INPUT_TYPES, AutoDestroyService, LocalizationProvider} from 'ui-elements';
import {takeUntil} from 'rxjs/operators';
import {IChartItem} from '@src/app/components/simple-chart/simple-chart.component';
import {asBoolean} from '@src/app/utils/string.util';

@Component({
  selector: 'app-viewer-survey',
  templateUrl: './viewer-survey.component.html',
  styleUrls: ['./viewer-survey.component.sass'],
  providers: [SurveyService, AutoDestroyService]
})
export class ViewerSurveyComponent implements OnInit {
  private _data: ISurvey;
  @Input() set data(survey: ISurvey) {
      this._data = survey;
      const answered = localStorage.getItem(this.data.id + '.answered');
      if (answered) {
          this.answered = asBoolean(answered);
      }
      if (!this.answered && !this.data.resultsPublished) {
          this.incrementView();
          this.answers = this.mapAnswers();
          this.answersConfig = {
              inputType: INPUT_TYPES.SELECT,
              placeholder: this.localizationProvider.getByKey('survey.choose'),
              selectOptions: this.answers,
          };
      } else {
          this.answered = true;
          this.mapAnswersToChartItems();
      }
  }

  get data(): ISurvey {
      return this._data;
  }
  @Output() answered$: EventEmitter<any> = new EventEmitter<any>();
  @Output() close$: EventEmitter<any> = new EventEmitter<any>();
  private answers: IUnitKeyValue[];
  public answersConfig: IInputConfig;
  public answered = false;
  public answersChartItems: IChartItem[];
  public state = true;

  constructor(
      private surveyService: SurveyService,
      private destroy$: AutoDestroyService,
      public localizationProvider: LocalizationProvider
  ) { }

  ngOnInit(): void {
      console.log(this.data)
  }

  private isAnswered(): boolean {
      this.answered = asBoolean(localStorage.getItem(this.data.id + '.answered'));
      return this.answered;
  }

  private incrementView(): void {
      this.surveyService.incrementView(this.data.id)
          .pipe(takeUntil(this.destroy$)).subscribe(() => {});
  }

  private mapAnswers(): IUnitKeyValue[] {
   return this.data.answers.map(answer => ({
     key: answer.id,
     value: answer.answer
   }));
  }

  private mapAnswersToChartItems(): void {
      const votesCount = this._data.answers.reduce((all, answer) => all + answer.replies, 0);
      this.answersChartItems = this._data.answers.map(answer => ({
          text: answer.answer,
          value: votesCount ? +(((answer.replies / votesCount) * 100).toFixed(2)) : 0
      }));
      this.state = true;
  }

  public sendAnswer(): void {
      const answer = this.answers.find(_answer => _answer.selected);
      if (!answer) {
          return;
      }
      this.answered = true;
      localStorage.setItem(this.data.id + '.answered', this.answered.toString());



      this.surveyService.sendAnswer(this.data.id, (answer.key as string))
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => {
              this.incrementVote();
              this.answered$.emit();
          });
  }

  private incrementVote(): void {
      this.surveyService.incrementVote(this.data.id)
          .pipe(takeUntil(this.destroy$)).subscribe(() => {});
  }

  public close(): void {
      this.state = false;
      this.close$.emit();
  }
}
