import { Injectable } from '@angular/core';
import {ApiService} from 'ui-elements';
import {Observable} from 'rxjs';
import {pluck} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {API_URLS_APP} from '@src/app/constants/api-urls.constant';
import * as moment from 'moment';


@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(
      private http: HttpClient,
      private apiService: ApiService
  ) { }

  // public getDashboard(): Observable<any> {
  //   return this.http.get('assets/mock-json/dashboard.json')
  //       .pipe(pluck('results', 'data'));
  // }
  //
  public getDashboard(
      from = +moment().utc().startOf('month').format('x'),
      to = +moment().utc().endOf('month').format('x'),
      period = 'DAY'
  ): Observable<any> {
    return this.apiService.post(API_URLS_APP.DASHBOARD, { from, to, period })
        .pipe(pluck('results', 'data'));
  }

  public getStreamStatistic(
      mediaId: string,
      from = +moment().utc().startOf('month').format('x'),
      to = +moment().utc().endOf('month').format('x'),
      period = 'DAY'
  ): Observable<any> {
    return this.apiService.post(API_URLS_APP.STREAM_STATISTIC, { mediaId, from, to, period })
        .pipe(pluck('results', 'data'));
  }

  public getStreamOnlineViewers(mediaId: string): Observable<any> {
    return this.apiService.post(API_URLS_APP.STREAM_ONLINE_VIEWERS, { mediaId })
        .pipe(pluck('results', 'onlineViewers'));
  }

  public getMap(): Observable<any[]> {
    return this.http.get('assets/mock-json/map.json')
        .pipe(pluck('results', 'data'));
  }
}
