import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ITableColumn, ITableConfig} from 'ui-elements';
import {Subject} from 'rxjs';
import {SortParam} from '@src/app/models/response.model';
import {takeUntil} from 'rxjs/operators';
import {IVoucherGroupModel} from '@src/app/models/voucher.model';
import {PageEvent} from '@angular/material/paginator';
import {StreamService} from '@src/app/services/stream-metadata/stream.service';
import {STREAM_STATUS} from '@src/app/components/streamdust-player/constants/status';

@Component({
  selector: 'app-group-table',
  templateUrl: './group-table.component.html',
  styleUrls: ['./group-table.component.sass']
})
export class GroupTableComponent implements OnInit {
  public STREAM_STATUS = STREAM_STATUS;
  @Input() set tableConfig(config: ITableConfig<any>) {
    this.config = config;
  }
  @Input() public title = '';
  // @Input() public data: ITableGroupEntry[];
  @Input() public columns: ITableColumn[];
  @Output() public edit$: EventEmitter<IVoucherGroupModel> = new EventEmitter<IVoucherGroupModel>();
  @Output() public deactivateGroup$: EventEmitter<string> = new EventEmitter<string>();
  private callCanceler = new Subject();
  itemsOnPage = 3;
  data: any[];
  page = 0;
  config: ITableConfig<any>;
  loading = false;
  cachedSort: any = {};
  totalPages = 0;
  itemsLength = 0;

  constructor(
      public streamService: StreamService
  ) { }

  ngOnInit(): void {
    this.getData();
  }

  public getData(): void {
    this.callCanceler.next();
    this.loading = true;
    if (!this.config) {
      return;
    }
    this.config.searchFn(
        undefined,
        {
          itemsOnPage: this.itemsOnPage,
          page: this.page
        })
        .pipe(takeUntil(this.callCanceler))
        .subscribe(({results}) => {
            this.data = results[this.config.dataField].items;
            this.itemsLength = results[this.config.dataField].paging.items;
            this.totalPages = Math.ceil(this.itemsLength / this.itemsOnPage);
            this.loading = false;
        }, () => this.loading = false);
  }

  public nextPage(): void {
    this.page++;
    this.getData();
  }

  public prevPage(): void {
    if (this.page === 0) {
      return;
    }

    this.page--;
    this.getData();
  }

  public goToPage(page: PageEvent): void {
    this.page = page.pageIndex;
    this.getData();
  }
  private buildSortBody(event: any): SortParam[] {
    const sort = event.sortField && [{field: event.sortField, order: event.sortOrder === 1 ? 'ASC' : 'DESC'} as SortParam];
    if (event.sortField === this.cachedSort.sortField && event.sortOrder === this.cachedSort.sortOrder) {
      this.page = Math.floor(event.first / this.itemsOnPage);
    } else {
      this.page = 0;
      this.cachedSort = {sortField: event.sortField, sortOrder: event.sortOrder};
    }
    return sort || undefined;
  }

  public refreshTable(): void {
    this.page = 0;
    this.getData();
  }

  public edit(group: IVoucherGroupModel): void {
    this.edit$.emit(group);
  }

  public deactivateGroup(groupId: string): void {
    this.deactivateGroup$.emit(groupId);
  }

}

export interface ITableGroupEntry {
  name: string;
  type: string;
  date: any;
  numberOfVouchers: number;
  numberOfRedeems: number;
  deactivate: boolean;
  discount: number;
}
