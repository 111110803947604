import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {IFormFieldsConfig} from '@src/app/services/stream-metadata/stream.service';
import {takeUntil} from 'rxjs/operators';
import {INPUT_TYPES} from 'ui-elements';

@Component({
    selector: 'app-default-form',
    templateUrl: './default-form.component.html',
    styleUrls: ['./default-form.component.css']
})
export class DefaultFormComponent implements OnInit {
    @Input() public withoutPadding = false;
    @Input() public fullWidth = false;
    @Input() public removeDefaultPadding = false;
    @Input() public title: string;
    @Input() public subTitle: string;
    @Input() public form: FormGroup;
    @Input() public fieldsConfig: IFormFieldsConfig[][];
    @Input() public withSideColumn: boolean;
    @Input() public withAvatar: boolean;
    @Input() public submitButtonText: string;
    @Input() public withoutSubmit = false;
    @Input() public withSideSubmit = false;
    @Input() public photoUrl = '';
    @Input() public clearImageButton = false;
    @Input() public disabled = false;
    @Input() public disabledToggle = false;
    @Output() public submit$: EventEmitter<any> = new EventEmitter();
    @Output() public onFileUpload$: EventEmitter<any> = new EventEmitter();
    @Output() public clearImage$: EventEmitter<any> = new EventEmitter();
    @Output() public toggle$: EventEmitter<any> = new EventEmitter();
    public INPUT_TYPES = INPUT_TYPES;

    constructor() {
    }

    ngOnInit(): void {
    }

    public submit(): void {
        this.submit$.emit(true);
    }

    public getStartDate(): any {
        if (this.form.controls.hasOwnProperty('startTimestamp')) {
            return this.form.get('startTimestamp').value;
        }

        return null;
    }

    public getEndDate(): any {
        if (this.form.controls.hasOwnProperty('endTimestamp')) {
            return this.form.get('endTimestamp').value;
        }

        return null;
    }

    public uploadPreviewPhoto(event: any): void {
        this.onFileUpload$.emit(event);
    }

    public clearImage(event: any): void {
        this.clearImage$.emit(event);
    }

    public toggle(event: any): void {
        this.toggle$.emit(event);
    }

}
