import {Component, Input, OnInit} from '@angular/core';
import {StreamService} from '@src/app/services/stream-metadata/stream.service';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import {ActivatedRoute, NavigationEnd, Params, Router} from '@angular/router';
import {mergeMap, takeUntil, tap} from 'rxjs/operators';
import {MediaOverviewData} from '@src/app/models/stream.model';
import {MEDIA_TYPE} from '@src/app/components/streamdust-player/constants/mediaType';
import {PLAYER_MODE} from '@src/app/components/streamdust-player/constants/playerMode';
import {BehaviorSubject} from 'rxjs';
import {ChatState} from '@src/app/state/chat-state';
import {ChatService} from '@src/app/services/chat/chat.service';
import {scrollToTop} from '@src/app/utils/scroll.util';

@Component({
  selector: 'app-public-stream-page',
  templateUrl: './chat-page.component.html',
  styleUrls: ['./chat-page.component.sass'],
  providers: [AutoDestroyService, ChatState, ChatService],
})
export class ChatPageComponent implements OnInit {
  public PLAYER_MODE = PLAYER_MODE;
  @Input() public loadedFromSubdomain: boolean;
  @Input() public mediaId: string;
  @Input() public mediaType: MEDIA_TYPE;
  public data: MediaOverviewData;
  public loading = true;

  constructor(
      public streamService: StreamService,
      private readonly destroy$: AutoDestroyService,
      private readonly activatedRoute: ActivatedRoute,
      private readonly router: Router,
  ) {
  }

  ngOnInit(): void {
    this.loadData();
    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.loadData();
      }
    });
  }

  loadData() {
    if (!this.loadedFromSubdomain) {
      this.mediaType = this.resolveMediaType();
    }
    this.activatedRoute.params.pipe(
        tap(({id}: Params) => {
          if (!this.loadedFromSubdomain || id) {
            this.mediaId = id;
          }
        }),
        mergeMap(() => this.streamService.getStreamLandingInfo(this.mediaType, this.mediaId)),
        tap((response) => {
          if (!response.success) {
            this.loading = false;
            return;
          }
          this.streamService.getPublicLandingPageInfo(this.mediaType, this.mediaId)
              .pipe(takeUntil(this.destroy$))
              .subscribe((res) => {
                scrollToTop();
              }, () => this.loading = false);
          this.streamService.connectStreamStatusWebsocket(this.mediaId);
        }),
        takeUntil(this.destroy$)
    ).subscribe(() => {
      this.loading = false;
    });
  }

  private resolveMediaType(): MEDIA_TYPE {
    return (this.activatedRoute.data as BehaviorSubject<any>)?.value?.mediaType;
  }
}
