import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService, IResponse} from 'ui-elements';
import {API_URLS_APP, UrlGenerator} from '@src/app/constants/api-urls.constant';
import {ICommonPaymentCard} from '@src/app/components/payment-cards-list/heidelpay-payment/heidelpay-payment.component';
import {tap} from 'rxjs/operators';
import {IPaymentReturnResponse} from '@src/app/services/payment/payment.service';

@Injectable()
export class MultiBitrateService {

  constructor(private apiService: ApiService) { }

  public proceedPayment(body: IMultiBitratePaymentRequest): Observable<IResponse<any>> {
    return this.apiService.post(API_URLS_APP.MULTI_BITRATE_PAYMENT, body);
  }

  public checkPayment(body: any): Observable<IResponse<{ result: IPaymentReturnResponse }>> {
    return this.apiService.post(UrlGenerator.generate(API_URLS_APP.PAYMENT_RETURN, {}, {testFail: false}), body);
  }
}


export interface IMultiBitratePaymentRequest {
  returnUrl?: string;
  successUrl?: string;
  failUrl?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  accessToken?: string;
  userCardId?: string;
  paymentCard?: ICommonPaymentCard;
  paymentMethod?: {
    resourceId?: string;
    method?: string;
    userCardId?: string;
    saveCard?: boolean;
    paymentCard?: ICommonPaymentCard;
  };
  saveCard?: true;
  mediaId: string;
}
