import {Injectable} from '@angular/core';
import {ApiService} from 'ui-elements';
import {Observable} from 'rxjs';
import {pluck} from 'rxjs/operators';
import {IPaymentSlot} from '@src/app/models/stream.model';

@Injectable()
export class SlotsService {
    public slots: IPaymentSlot[];

    constructor(private apiService: ApiService) {
    }

    public accessCheck(slotId: string): Observable<{hasAccess: boolean}> {
        return this.apiService.post('mock', {
            request: {
                slotId: slotId
            },
            response: {
                hasAccess: this.slots.find(slot => slot.id === slotId).hasAccess
            }
        }).pipe(pluck('results', 'data'));
    }

    public accessCheckAll(): boolean {
        const slots = this.slots;
        return !this.slots?.find(slot => !!slot.hasAccess === false);
    }
}
