<div class="panel panel-md">
    <div class="panel-header">
        <h2><b>{{'survey.title' | localize}}</b></h2>
        <p class="text-sm">{{'survey.subtitle' | localize}}</p>
    </div>
    <div class="panel-body bg-white padding-bottom-30">
        <div class="section-panel">
            <div class="section-panel-header">
                <h4><b>{{'survey.table.title' | localize}}</b></h4>
            </div>
            <app-survey-table></app-survey-table>
        </div>
    </div>
</div>


