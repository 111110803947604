import {Injectable} from '@angular/core';
import {ITableConfig, TEMPLATE_ID} from 'ui-elements';
import {IPagedResponse, PagingParams, SortParam} from '@src/app/models/response.model';
import {Observable} from 'rxjs';
import {ILead} from '@src/app/pages/stream-page/components/media-leads/config/lead-model';

@Injectable()
export class CoursesOverviewConfig {
    private config: ITableConfig<ILead>;

    constructor() {
        this.initConfig();
    }

    private initConfig(): void {
        this.config = {
            dataField: 'data',
            searchFn: null,
            columns: [
                {
                    name: 'lead.table.field.name',
                    sortField: 'PARTICIPANT_F_NAME',
                    dataField: 'firstName',
                    width: 70
                }, {
                    name: 'lead.table.field.lastName',
                    sortField: 'PARTICIPANT_L_NAME',
                    dataField: 'lastName',
                    width: 100
                },
                {
                    name: 'lead.table.field.email',
                    sortField: 'PARTICIPANT_EMAIL',
                    dataField: 'email',
                    width: 100
                },
                {
                    name: 'coursesOverview.table.field.date',
                    sortField: 'VIEW_DATE',
                    dataField: 'viewDate',
                    width: 100,
                    tmplId: TEMPLATE_ID.DATE
                }, {
                    name: 'coursesOverview.table.field.amountOverlays',
                    dataField: 'showAmount',
                    width: 100
                },
                {
                    name: 'coursesOverview.table.field.amountClicks',
                    dataField: 'clickAmount',
                    width: 100
                }
            ]
        };
    }

    public resolveConfig(searchFn: (sortParams: SortParam[], pagingParams: PagingParams) => Observable<IPagedResponse<ILead>>): ITableConfig<ILead> {
        this.config.searchFn = searchFn;
        return this.config;
    }
}
