import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {IPrice} from "@src/app/models/stream.model";
import {TARIFF_PLAN_TYPES} from "@src/app/components/tariff-plan-cards-list/tariff-plan-cards-list.component";
import {SUBSCRIPTION_PLAN_TYPES} from "@src/app/services/subscription-manage/subscription-manage.service";

@Component({
    selector: 'app-subscription-plan-card',
    templateUrl: './subscription-plan-card.component.html',
    styleUrls: ['./subscription-plan-card.component.sass']
})
export class SubscriptionPlanCardComponent implements OnInit, OnChanges {

    @Input() plan: ISubscriptionTariffPlan;
    @Input() config: ISubscriptionTariffPlanConfig;

    @Output() actionButtonPressed$ = new EventEmitter<ISubscriptionTariffPlan>();

    planPriceMonth: IPrice;
    planPriceYear: IPrice;
    planPricePerText: string;

    TARIFF_PLAN_PERIOD = TARIFF_PLAN_PERIOD;

    constructor() {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.config?.currentValue) {
            this.calculatePlanPrice();
        }
    }

    ngOnInit(): void {
        this.calculatePlanPrice();
    }

    calculatePlanPrice(): void {
        this.planPriceMonth = this.getTariffPlanPrice(TARIFF_PLAN_PERIOD.MONTH);
        this.planPriceYear = this.getTariffPlanPrice(TARIFF_PLAN_PERIOD.YEAR);
    }

    actionButtonPressed(): void {
        this.actionButtonPressed$.emit(this.plan);
    }

    getTariffPlanPrice(period: TARIFF_PLAN_PERIOD): IPrice {
        const tariffPlanPrice = this.plan.prices.find(price => price.period === this.config?.planPeriod)
        const periodPrice = tariffPlanPrice?.prices.find(periodPrice => periodPrice.period === period);
        return periodPrice?.price
    }

}

export interface ISubscriptionTariffPlanConfig {
    planPeriod: TARIFF_PLAN_PERIOD;
    hideActionButton: boolean;
    coverClass?: number;
    isAuthorized?: boolean;
}

export interface ISubscriptionTariffPlans {
    EUR: ISubscriptionTariffPlan[];
    USD: ISubscriptionTariffPlan[];
}

export interface ISubscriptionTariffPlan {
    id?: string;
    title: string;
    benefits: string[];
    boughtInPast: boolean;
    mostPopular: boolean;
    prices: ITariffPlanPrice[];
    subscriptionPlanType: SUBSCRIPTION_PLAN_TYPES;
    subscriptionType: SUBSCRIPTION_PLAN_TYPES;
    type: TARIFF_PLAN_TYPES;
}

export interface ITariffPlanPrice {
    period: TARIFF_PLAN_PERIOD;
    prices: ITariffPlanPeriodPrice[];
    monthPrice: IPrice;
}

export interface ITariffPlanPeriodPrice {
    period: TARIFF_PLAN_PERIOD;
    price: IPrice;
    total: IPrice;
    vat: IPrice;
}

export enum TARIFF_PLAN_PERIOD {
    WEEK = 'WEEK',
    MONTH = 'MONTH',
    YEAR = 'YEAR'
}


