import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '@src/app/services/auth/auth.service';
import {takeUntil} from 'rxjs/operators';
import {IServerError} from '@src/app/models/server-error.model';
import {CustomValidators} from '@src/app/utils/custom-validators.util';
import {INFO_BOX_TYPES} from '@src/app/components/info-box/info-box.component';
import {ACTIONS, LocalizationProvider} from 'ui-elements';
import {INPUT_TYPES} from 'ui-elements';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import {IFieldsConfig} from '@src/app/pages/user-profile/interfaces/interfaces-common';
import {FormService} from '@src/app/services/form/form.service';
import {ActivatedRoute, Router} from '@angular/router';
import {BehaviorSubject} from 'rxjs';
import {UserService} from '@src/app/services/user/user.service';
import {IUserProfile} from '@src/app/pages/user-profile/components/profile/services/profile.service';
import {PublisherAction, PublisherComponent} from './components/publisher/publisher.component';
import { ViewerAction } from './components/viewer/viewer.component';
import {
  RegistrationService,
  IRegistrationResult,
  StateRegistration,
} from './services/registration.service';
import * as _ from 'lodash';
import {PublicMediaPageComponent} from '@src/app/pages/public-media-page/public-media-page.component';

@Component({
  selector: 'app-registration-page',
  templateUrl: './registration-page.component.html',
  styleUrls: ['./registration-page.component.scss'],
  providers: [AutoDestroyService],
})
export class RegistrationPageComponent implements OnInit, AfterViewInit {
  @ViewChild('terms', { static: false }) private terms: ElementRef<HTMLElement>;
  private EMAIL_ALREADY_EXIST_ERR_KEY = 'REGISTRATION_EMAIL_ALREADY_EXISTS';
  public INFO_BOX_TYPES = INFO_BOX_TYPES;
  public form: FormGroup;
  public firmForm: FormGroup;
  public currencyForm: FormGroup;
  public phoneForm: FormGroup;
  public termsForm: FormGroup;
  public serverErrors: IServerError[] = [];
  public isRegistered = false;
  public isRegisteredViewer = false;
  public registrationResult: IRegistrationResult = {} as any;
  public loading: { [key: string]: boolean } = {};
  public INPUT_TYPES = INPUT_TYPES;
  public formConfig: IFieldsConfig[][];
  public firmFieldsConfig: IFieldsConfig[][];
  public phoneFieldsConfig: IFieldsConfig[][];
  public termsFieldsConfig: IFieldsConfig[][];
  public upgradeToPublisher: boolean;
  public isPhoneConfirmed: boolean;
  public currencyFieldsConfig: IFieldsConfig[][];
  public stepRegistration: string = StateRegistration.initial;

  public viewerForm: FormGroup;
  public viewerFieldsConfig: IFieldsConfig[][];

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private localizationProvider: LocalizationProvider,
    private destroy$: AutoDestroyService,
    public elementRef: ElementRef<HTMLElement>,
    public formService: FormService,
    private readonly activatedRoute: ActivatedRoute,
    private userService: UserService,
    private router: Router,
    private registrationService: RegistrationService
  ) {}

  ngOnInit() {
    this.upgradeToPublisher = (this.activatedRoute
      .data as BehaviorSubject<any>)?.value?.upgradeToPublisher;
    this.initConfigs();
    this.initForms();
    if (this.upgradeToPublisher) {
      this.fillForms();
    }

    this.activatedRoute.queryParams.subscribe((params) => {
      if (!!params["step"]) {
        this.stepRegistration = params["step"];
      } else {
        this.stepRegistration = this.stateRegistartion.initial;
      }
    });

    // this.firmForm.get('company').valueChanges.pipe(takeUntil(this.destroy$)).subscribe((firmName) => {
    //   if (firmName) {
    //     this.firmForm
    //       .get('vatId')
    //       .setValidators([
    //         Validators.required,
    //         CustomValidators.noWhitespaceValidator,
    //       ]);
    //     this.firmForm.get('vatId').updateValueAndValidity();
    //     this.firmForm.get('vatId').markAsTouched();
    //   } else {
    //     this.firmForm.get('vatId').setValidators(null);
    //     this.firmForm.get('vatId').updateValueAndValidity();
    //   }
    // });
  }

  ngAfterViewInit(): void {
    this.termsFieldsConfig = this.registrationService.getTermsFieldsConfig(this.terms);
  }

  public get stateRegistartion(): typeof StateRegistration {
    return StateRegistration;
  }

  public get isInitialStep(): boolean {
    return this.stepRegistration === this.stateRegistartion.initial;
  }

  public get isPublisherStep(): boolean {
    return this.stepRegistration === this.stateRegistartion.publisher;
  }

  public get isViewerStep(): boolean {
    return this.stepRegistration === this.stateRegistartion.viewer;
  }

  private initConfigs(): void {
    this.initMainFormConfig();
    this.initFirmConfig();
    this.initCurrencyConfig();
    this.initViewerFormConfig();
  }

  private initForms(): void {
    this.form = this.registrationService.getProfileInfoPublisherForm();

    this.viewerForm = this.registrationService.getProfileFormViewerForm();

    this.phoneForm = this.registrationService.getPhoneForm();
    // } else {
    //     this.form.addControl('privatePhone', new FormControl('', [Validators.required, CustomValidators.noWhitespaceValidator]));
    // }
    this.firmForm = this.registrationService.getFirmForm();
    this.currencyForm = this.registrationService.getCurrencyForm();
    this.termsForm = this.registrationService.getTermForm();
  }

  private initCurrencyConfig() {
    this.currencyFieldsConfig = this.registrationService.getCurrencyFieldsConfig();
  }

  private initFirmConfig() {
    this.firmFieldsConfig = this.registrationService.getFirmConfig();
  }

  private initMainFormConfig() {
    this.formConfig = this.registrationService.getPublisherFormConfig(this.upgradeToPublisher);
  }

  private initViewerFormConfig() {
    this.viewerFieldsConfig = this.registrationService.getViewerFormConfig();
  }

  private fillForms(): void {
    this.userService.userProfile$
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: IUserProfile) => {
        if (!res) {
          return;
        }

        const formControls = this.form.controls;
        const firmControls = this.firmForm.controls;
        formControls.firstName.setValue(res?.firstName);
        formControls.lastName.setValue(res?.lastName);
        formControls.address.setValue(res?.address?.street);
        formControls.postalCode.setValue(res?.address?.postalCode);
        formControls.city.setValue(res?.address?.city);
        formControls.country.setValue(res?.address?.country);
        formControls.email.setValue(res?.email);
        firmControls.company.setValue(res?.companyInfo?.company);
        firmControls.vatId.setValue(res?.companyInfo?.vatId);
      });

    this.userService.userContactInfo$
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        const phoneControls = this.phoneForm.controls;
        phoneControls.privatePhone.setValue(res?.phone);
        // formControls.email.setValue(res.email);
      });
  }

  public submit(): void {
    this.form.markAllAsTouched();
    this.firmForm.markAllAsTouched();
    this.currencyForm.markAllAsTouched();
    this.phoneForm.markAllAsTouched();
    this.termsForm.markAllAsTouched();
    if (
      this.form.invalid ||
      this.termsForm.invalid ||
      this.phoneForm.invalid ||
      this.currencyForm.invalid ||
      this.firmForm.invalid
    ) {
      this.formService.submitInvalid$.emit();
      return;
    }

    const registrationData = {
      ...this.form.value,
      ...this.phoneForm.value,
      ...this.firmForm.value,
      ...this.currencyForm.value,
    };

    registrationData.confirmationCode = registrationData.smsVerification;
    delete registrationData.smsVerification;

    this.loading.registration = true;
    if (this.upgradeToPublisher) {
      delete registrationData.confirmationCode;
      delete registrationData.email;
      this.userService
        .upgradeToPublisher(registrationData)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (res) => {
            if (!res.success) {
              return;
            }
            this.userService.refreshUserProfile();
            this.goToDashboard();
          },
          () => (this.loading.registration = false)
        );
      return;
    }

    this.authService
      .registrationFirstStep(registrationData)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.loading.registration = false;
          this.registrationResult = {} as any;

          if (!res.success) {
            this.handleRegistrationBackendError(res);
            return;
          }
          this.isRegistered = true;
        },
        () => (this.loading.registration = false)
      );
  }

  private submitViewer(): void {
      this.viewerForm.markAllAsTouched();
      this.firmForm.markAllAsTouched();
      this.termsForm.markAllAsTouched();
      if (
        this.viewerForm.invalid ||
        this.firmForm.invalid ||
        this.termsForm.invalid
      ) {
        this.formService.submitInvalid$.emit();
        return;
      }

      const registrationData = {
        ...this.viewerForm.value,
        ...this.firmForm.value,
      };

      delete registrationData.passwordConfirmation;

      this.loading.registration = true;
      registrationData.redirectLink = `https://${window.location.hostname}/streams`;

      this.authService
        .registerViewer(registrationData)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (res) => {
            this.loading.registration = false;
            this.registrationResult = {} as any;

            if (!res.success) {
              this.handleRegistrationBackendError(res, true);
              return;
            }
            this.isRegisteredViewer = true;
          },
          () => (this.loading.registration = false)
        );
  }

  public cancel(): void {
    this.goToDashboard();
  }

  private goToDashboard(): void {
    this.router.navigate(['streams']);
  }

  public getForms(): FormGroup[] {
    return [this.form, this.phoneForm, this.firmForm, this.termsForm];
  }

  private handleRegistrationBackendError(response, viewer = false): void {
    if (this.EMAIL_ALREADY_EXIST_ERR_KEY === response.errorKey) {
      if (viewer) {
        this.viewerEmail.setErrors({ [response.errorKey]: true });
      } else {
        this.email.setErrors({[response.errorKey]: true});
      }
      this.form.markAllAsTouched();
      this.form.updateValueAndValidity();
    } else {
      this.serverErrors = [
        {
          key: response.errorKey,
          text: this.localizationProvider.getByErrorKey(response.errorKey),
        },
      ];
    }

    window.scrollTo({
      top: this.elementRef.nativeElement.offsetTop,
      behavior: 'smooth',
    });
  }

  get email(): AbstractControl {
    return this.form.get('email');
  }

  get viewerEmail(): AbstractControl {
    return this.viewerForm.get('email');
  }

  public handlePublisherAction(action: PublisherAction): void {
    switch (action.type) {
      case ACTIONS.CANCEL:
        this.cancel();
        break;
      case ACTIONS.SUBMIT:
        this.submit();
        break;
    }
  }

  public handleViewerAction(action: ViewerAction): void {
    switch (action.type) {
      case ACTIONS.SUBMIT:
        this.submitViewer();
        break;
    }
  }

  public changeState(state: string): void {
    this.stepRegistration = state;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {'step': state}
    });
  }
}
