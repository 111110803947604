import {Component, Input, OnInit} from '@angular/core';
import {ShareModalState} from '@src/app/components/streamdust-player/share-modal/state/share-modal-state';

@Component({
  selector: 'app-share-modal',
  templateUrl: './share-modal.component.html',
  styleUrls: ['./share-modal.component.sass']
})
export class ShareModalComponent implements OnInit {
  @Input() mediaId: string;
  shareUrl: string;

  constructor(public state: ShareModalState) { }

  ngOnInit(): void {
    this.shareUrl = window.location.protocol + '//' + window.location.host + '/media-preview/' + this.mediaId;
  }

  close(): void {
    this.state.setState(false);
  }

}
