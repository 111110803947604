<div class="panel panel-md">
    <div class="panel-header" *ngIf="!isEmbeded">
        <h2><b>{{'stream.recording.title' | localize}}</b></h2>
        <p>{{'stream.recording.subtitle' | localize}}</p>
    </div>
    <div class="card bg-white">
        <div class="card-header">
            <h4 class="mb-0"><b>{{'stream.recording.card.title' | localize}}</b></h4>
        </div>

        <div class="card-body">
            <div class="row row-padding-10">
                <div class="col-lg-12">
                    <p class="mt-25"
                       *ngIf="!recordData?.length">{{'stream.recording.info.no-recording' | localize}}</p>
                    <form class="material-form" *ngIf="recordData?.length">
                        <ng-container *ngFor="let data of recordData">
                            <div class="row row-padding-0 position-relative" *ngFor="let info of data.recordInfoTable">
                                <label class="col-lg-4 form-control-label">{{info?.title}}</label>
                                <div class="col-lg-8" [class.record-link]="info?.isLink">
                                    <input *ngIf="!info?.isLink" disabled type="text" class="form-control"
                                           [placeholder]="data[info?.property] ? data[info?.property] : ''">
                                    <div class="link-block">
                                        <a *ngIf="info.isLink" target="_blank"
                                           [href]="data[info?.property]">{{data[info?.property]}}</a>
                                        <div *ngIf="info.isLink" class="input-group-append cursor-pointer">
                                        <span class="input-group-text copy-icon-wrapper"
                                              (click)="copyValue(data[info?.property])">
                                            <i class="icon icon-copy"></i>
                                        </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="btn-box">
                                    <div class="row row-padding-10">
                                        <div class="col-auto">
                                            <a class="btn btn-primary btn-record-download" [href]="data.fileUrl">
                                                <ano-loader [isOpen]="data.isRecordDownloading"></ano-loader>
                                                <span *ngIf="!data.isRecordDownloading">{{'common.button.download' | localize}}</span>
                                            </a>
                                            <span class="file-size-text-wrapper">
                                            <p class="file-size-text">
                                                {{'stream.recording.info.file-size' | localize}}:
                                                <span class="size-mb">
                                                    {{data.fileSize}}
                                                </span>
                                            </p>
                                        </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
