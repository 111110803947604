import {Component, EventEmitter, Input, Output} from '@angular/core';
import {IPaymentCard, PAYMENT_CARD_TYPE} from '@src/app/components/payment-cards-list/payment-cards-list.component';

@Component({
    selector: 'app-payment-card',
    templateUrl: './payment-card.component.html',
    styleUrls: ['./payment-card.component.scss']
})
export class PaymentCardComponent {

    @Input() set card (paymentCard: IPaymentCard) {
        this._card = paymentCard;
        this.iconSrc = this.resolvePaymentCardSrc(this._card.type);
    }
    @Input() showRemoveButton: boolean;
    @Input() failedCardId: string;

    @Output() deleteCard$ = new EventEmitter<string>();
    @Output() setCardAsActive$ = new EventEmitter<IPaymentCard>();

    _card: IPaymentCard;
    iconSrc: string;
    PAYMENT_CARD_TYPE = PAYMENT_CARD_TYPE;

    constructor() {
    }

    resolvePaymentCardSrc(cardType): string {
        switch (cardType) {
            case PAYMENT_CARD_TYPE.MASTER_CARD: {
                return 'assets/img/ico/mastercard.svg';
            }
            case PAYMENT_CARD_TYPE.VISA: {
                return this._card.active ? 'assets/img/ico/visa-inverse.svg' : 'assets/img/ico/visa.svg';
            }
            default: {
                return 'assets/img/ico/mastercard.svg';
            }
        }
    }

    setCardAsActive(event): void {
        event.stopImmediatePropagation();
        if (!this._card.active) {
            this.setCardAsActive$.emit(this._card);
        }
    }

    deleteCard(event): void {
        event.stopImmediatePropagation();
        this.deleteCard$.emit(this._card.id);
    }
}
