<!--<div class="container photo-cropper-container fixedheight">-->
<!--  <button (click)="rotateLeft()">Rotate left</button>-->
<!--  <button (click)="rotateRight()">Rotate right</button>-->

<!--  <image-cropper-->
<!--    #imageCropperComponent-->
<!--    [aspectRatio]="data.aspectRatio"-->
<!--    [roundCropper]="data.isRoundCropper"-->
<!--    [imageFile]="image"-->
<!--    [maintainAspectRatio]="true"-->
<!--    format="jpeg"-->
<!--  ></image-cropper>-->
<!--  <span *ngIf="!image">Loading...</span>-->
<!--  <button (click)="confirm()" *ngIf="dialogRef && image">Confirm</button>-->
<!--</div>-->

<!--<header class="header fixed-top">-->
<!--<div class="container">-->
<!--<div class="row align-items-center">-->
<!--<div (click)="reject()" class="col-3">-->
<!--<a class="header-nav header-nav-text">-->
<!--Cancel-->
<!--</a>-->
<!--</div>-->
<!--<div class="col-6">-->
<!--<div class="header-title">-->
<!--<div class="header-title">-->
<!--Upload photo-->
<!--</div>-->
<!--</div>-->
<!--</div>-->
<!--<div (click)="confirm()" *ngIf="image" class="col-3">-->
<!--<a class="header-nav header-nav-text header-nav-right">-->
<!--Done-->
<!--</a>-->
<!--</div>-->
<!--</div>-->
<!--</div>-->
<!--</header>-->

<div class="modal">
    <div class="modal-dialog">
        <div class="modal-content" [class.loading]="!image">
            <!--<div class="modal-header">-->
            <!--<div class="title-box">-->
            <!--<h4 class="title">Gutscheine erstellen</h4>-->
            <!--</div>-->
            <!--<a aria-hidden="true" (click)="close()" class="close"><span>×</span></a>-->

            <!--</div>-->
            <div class="modal-body">
                <div class="photo-upload-box" [class.loading]="!image">
                    <image-cropper
                        #imageCropperComponent
                        [aspectRatio]="data.aspectRatio"
                        [imageFile]="image"
                        [maintainAspectRatio]="true"
                        [roundCropper]="data.isRoundCropper"
                        (imageCropped)="cropped($event)"
                        format="jpeg"
                    ></image-cropper>
                    <!--<span *ngIf="!image">Loading...</span>-->
<!--                    <ano-loader [isOpen]="!image"></ano-loader>-->
                    <app-progress-spinner [isOpen]="!image" [progress]="loadingService.progress | async"></app-progress-spinner>
                    <div *ngIf="image" class="overlay-icon-nav">
                        <span (click)="rotateLeft()" class="overlay-nav-icon"><i class="icon-rotate-left"></i></span>
                        <span (click)="rotateRight()" class="overlay-nav-icon"><i class="icon-rotate-right"></i></span>
                    </div>

                </div>
                <div class="modal-footer" *ngIf="image">
                    <button (click)="reject()" type="button" class="btn btn-secondary" data-dismiss="modal">{{ 'overlay.cancel' | localize}}</button>
                    <button (click)="confirm()" type="button" class="btn btn-success">{{  'save.picture' | localize}}</button>
                    <!--<div class="row">-->
                        <!--<div class="col">-->
                            <!--<ano-button [config]="{styleType: 'primary', sizeType: 'sm'}" (click)="reject()">Cancel-->
                            <!--</ano-button>-->
                        <!--</div>-->
                        <!--<div class="col">-->
                            <!--<ano-button [config]="{styleType: 'primary', sizeType: 'sm'}" (click)="confirm()">Done-->
                            <!--</ano-button>-->
                        <!--</div>-->
                    <!--</div>-->
                </div>
            </div>
        </div>
    </div>
</div>
<!--<div class="full-content">-->
<!--<div class="photo-upload-box">-->
<!--<image-cropper-->
<!--#imageCropperComponent-->
<!--[aspectRatio]="data.aspectRatio"-->
<!--[imageFile]="image"-->
<!--[maintainAspectRatio]="true"-->
<!--[roundCropper]="data.isRoundCropper"-->
<!--format="jpeg"-->
<!--&gt;</image-cropper>-->
<!--</div>-->
<!--<div *ngIf="image" class="overlay-icon-nav">-->
<!--<span (click)="rotateLeft()" class="overlay-nav-icon">-->
<!--<i class="icon-rotate-left"></i>-->
<!--</span>-->
<!--<span (click)="rotateRight()" class="overlay-nav-icon">-->
<!--<i class="icon-rotate-right"></i>-->
<!--</span>-->
<!--</div>-->
<!--<div class="row">-->
<!--<div class="col">-->
<!--<app-button [config]="{styleType: 'primary', sizeType: 'sm'}" (click)="reject()">Cancel</app-button>-->
<!--</div>-->
<!--<div class="col">-->
<!--<ano-button [config]="{styleType: 'primary', sizeType: 'sm'}" (click)="confirm()">Done</ano-button>-->
<!--</div>-->
<!--</div>-->
<!--</div>-->

<!--<ano-loader [isOpen]="!image"></ano-loader>-->
