import {Component, OnDestroy, OnInit} from '@angular/core';
import {SurveyFieldsConfig} from "@src/app/pages/stream-page/components/stream-survey/components/survey/config/SurveyFieldsConfig";
import {IFormConfig, IFormFieldsConfig, StreamService} from "@src/app/services/stream-metadata/stream.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {FormUtil} from "@src/app/utils/form.util";
import {MEDIA_TYPE} from "@src/app/components/streamdust-player/constants/mediaType";
import {take, takeUntil, tap} from 'rxjs/operators';
import {ActivatedRoute, Params, Router} from "@angular/router";
import {BehaviorSubject, Subject} from 'rxjs';
import {AutoDestroyService} from "@src/app/services/auto-destroy-service/auto-destroy.service";
import {IAnswer, ISurvey, ISurveyRequest, SurveyService} from "@src/app/services/survey/survey.service";
import {CustomValidators, NEW_ID_PARAM} from '@src/app/utils/custom-validators.util';
import {STREAM_STATUS} from "@src/app/components/streamdust-player/constants/status";
import {IInfoPanel, InfoPanelsConfig} from "@src/app/components/info-panel/info-panel.component";
import {Observable} from 'rxjs/internal/Observable';
import {DateHelper} from '@src/app/utils/date.helper';
import {DurationConvertPipe} from '@src/app/pipes/duration-convert.pipe';

@Component({
    selector: 'app-survey',
    templateUrl: './survey.component.html',
    styleUrls: ['./survey.component.sass'],
    providers: [SurveyFieldsConfig, AutoDestroyService]
})
export class SurveyComponent implements OnInit, OnDestroy {
    public MEDIA_TYPE = MEDIA_TYPE;
    public STREAM_STATUS = STREAM_STATUS;
    public data: ISurvey;
    public createMode = true;
    public mediaType: MEDIA_TYPE;
    public mediaId: string;
    public nameConfig: IFormFieldsConfig[][];
    public nameForm: FormGroup = this.fb.group({
        name: ['', Validators.required]
    });

    public questionConfig: IFormFieldsConfig[][];
    public questionForm: FormGroup = this.fb.group({
        question: ['', Validators.required]
    });

    public answerConfig: IFormConfig;

    public durationConfig: IFormFieldsConfig[][];
    public durationForm: FormGroup = this.fb.group({
        // startTime: ['', Validators.required],
        startTime: [''],
        duration: ['', [Validators.required, CustomValidators.timer]],
        manualStart: [false]
    });

    private surveysUrl: string;
    public disableManualInfoPanel: IInfoPanel;
    public deactivateToEdit: IInfoPanel = InfoPanelsConfig.deactivateSurveyToEdit;
    private valid = true;
    private notifier$: Subject<any>;

    constructor(
        private config: SurveyFieldsConfig,
        private fb: FormBuilder,
        private activatedRoute: ActivatedRoute,
        private destroy$: AutoDestroyService,
        private surveyService: SurveyService,
        private router: Router,
        public streamService: StreamService
    ) {
    }

    ngOnInit(): void {
        this.activatedRoute.parent.params.pipe(
            takeUntil(this.destroy$),
            tap(({id}: Params) => {
                this.mediaId = id;
                this.mediaType = this.resolveMediaType();
                this.surveysUrl = this.resolveSurveysUrl();
                this.initConfigs();
            }))
            .subscribe(() => {
                this.activatedRoute.params.pipe(
                    takeUntil(this.destroy$)).subscribe(({id}: Params) => {
                    if (!id || id === NEW_ID_PARAM) {
                        this.refreshStreamStatus();
                        this.switchToAutomaticMode(true);
                        this.durationForm.get('startTime').setValidators([Validators.required, CustomValidators.timer]);
                        if (this.mediaType === MEDIA_TYPE.VIDEO_ON_DEMAND) {
                            this.durationConfig[0][0].hidden = true;
                        }
                        return;
                    }

                    this.createMode = false;
                    this.surveyService.get(id).pipe(takeUntil(this.destroy$))
                        .subscribe((res) => {
                            this.data = res;
                            if (this.mediaType === MEDIA_TYPE.STREAM) {
                                this.data.startTime = this.data.startTime - (new Date().getTimezoneOffset() * 60);
                            }
                            this.fillForms();
                            this.refreshStreamStatus();
                            this.switchToAutomaticMode();
                            if (this.mediaType === MEDIA_TYPE.VIDEO_ON_DEMAND) {
                                this.durationConfig[0][0].hidden = true;
                            }
                        });
                });
            });

    }

    private resolveMediaType(): MEDIA_TYPE {
        return (this.activatedRoute.data as BehaviorSubject<any>)?.value?.mediaType
    }

    private resolveSurveysUrl(): string {
        const mediaIdRelatedUrlSegment = this.mediaId + ((this.activatedRoute.data as BehaviorSubject<any>)?.value?.standAlone ? '/info' : '/surveys');
        switch (this.mediaType) {
            case MEDIA_TYPE.STREAM:
            case MEDIA_TYPE.STREAM_RECORDING:
                return 'streams/' + mediaIdRelatedUrlSegment;
            case MEDIA_TYPE.VIDEO_ON_DEMAND:
                return 'vod/' + mediaIdRelatedUrlSegment;

        }
    }


    private initConfigs(): void {
        this.nameConfig = this.config.name;
        this.questionConfig = this.config.question;
        this.answerConfig = this.config.answerForm;
        this.durationConfig = this.config.duration;
        this.listenManualStartChange();
    }

    private fillForms(): void {
        this.nameForm.get('name').patchValue(this.data.name);
        this.questionForm.get('question').patchValue(this.data.question);
        this.fillAnswers(this.data.answers);
        this.fillDuration(this.data.startTime, this.data.duration);
    }

    private refreshStreamStatus(): void {
        this.streamService.streamStatus$
            .pipe(takeUntil(this.destroy$))
            .subscribe((res) => {
                if (!res) {
                    return;
                }

                if (res.stream === STREAM_STATUS.FINISHED) {
                    this.disableManualSurvey();
                }
            });
    }

    private disableManualSurvey(): void {
        if (this.createMode) {
            this.durationConfig[0][0].hidden = true;
            return;
        }

        if (this.data.startTime) {
            return;
        }

        this.durationConfig[0][0].hidden = true;
        this.durationForm.get('manualStart').patchValue(false);
        this.disableManualInfoPanel = InfoPanelsConfig.manualSurveyDisabled;
    }

    private fillAnswers(answers: IAnswer[]): void {
        if (!answers.length) {
            return;
        }

        const answersCountToAdd = answers.length - 2;
        if (answersCountToAdd > 0) {
            for (let i = 0; i < answersCountToAdd; i++) {
                this.addForm(this.answerConfig);
            }
        }
        for (let i = 0; i < answers.length; i++) {
            this.answerConfig.forms[i].get('answer').patchValue(answers[i].answer);
        }
    }

    private fillDuration(startTime: number, duration: number): void {
        const durationConvertPipe = new DurationConvertPipe();
        this.durationForm.get('duration').patchValue(durationConvertPipe.transform(duration, 'seconds'));
        if (startTime !== null) {
            this.durationForm.get('startTime').patchValue(durationConvertPipe.transform(startTime, 'seconds'));
            this.durationForm.get('startTime').setValidators([Validators.required, CustomValidators.timer]);
            return;
        }
        this.durationForm.get('manualStart').patchValue(true);
        this.durationConfig[1][0].config.disabled = true;
        this.durationForm.get('startTime').clearValidators();
        this.durationForm.get('startTime').updateValueAndValidity();
        this.durationForm.get('startTime').markAsUntouched();
    }

    private listenManualStartChange(): void {
        this.durationForm.get('manualStart').valueChanges.subscribe((res) => {
            this.durationConfig[1][0].config.disabled = !!res;
            if (res) {
                this.durationForm.get('startTime').clearValidators();
                this.durationForm.get('startTime').updateValueAndValidity();
            } else {
                this.durationForm.get('startTime').setValidators([Validators.required, CustomValidators.timer]);
                this.durationForm.get('startTime').updateValueAndValidity();
                this.durationForm.get('startTime').markAsUntouched();
            }
        });
    }

    public addForm(formConfig: IFormConfig): void {
        formConfig.forms.push(FormUtil.buildForm(formConfig.formsFields));
    }

    public removeForm(formConfig: IFormConfig, indexToRemove: number): void {
        formConfig.forms.splice(indexToRemove, 1);
    }

    public submit(): void {
        let qp = {};

        if ((this.activatedRoute.data as BehaviorSubject<any>)?.value?.standAlone) {
            qp = { surveys: true};
        }
        [this.nameForm, this.questionForm, this.durationForm, ...this.answerConfig.forms].forEach(form => {
            form.markAllAsTouched();
            form.updateValueAndValidity();
            if (form.invalid) {
                this.valid = false;
            }
        });
        if (!this.valid) {
            this.valid = true;
            return;
        }
        const payload: ISurveyRequest = {
            ...this.nameForm.getRawValue(),
            ...this.questionForm.getRawValue(),
            answers: this.answerConfig.forms.map(form => form.get('answer').value),
            startTime: this.durationForm.get('manualStart').value ? null : DateHelper.getSecondsFromString(this.durationForm.get('startTime').value),
            duration: DateHelper.getSecondsFromString(this.durationForm.get('duration').value),
        };

        if (this.mediaType === MEDIA_TYPE.STREAM) {
            payload.startTime = payload.startTime + (new Date().getTimezoneOffset() * 60);
        }

        if (this.createMode) {
            payload.productId = this.mediaId;
            payload.productType = this.mediaType;
            payload.active = true;
            this.surveyService.create(payload)
                .pipe(takeUntil(this.destroy$))
                .subscribe((res) => {
                    this.data = res;
                    this.toggleOverlay(false)
                        .subscribe(() => {
                            this.toggleOverlay(true)
                                .subscribe(() => {
                                    this.router.navigate([this.surveysUrl], {queryParams: qp});
                                });
                        });

                });
            return;
        }

        payload.id = this.data.id;
        this.surveyService.update(payload)
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                this.router.navigate([this.surveysUrl]);
            });

    }

    public cancel(): void {
        let qp = {};

        if ((this.activatedRoute.data as BehaviorSubject<any>)?.value?.standAlone) {
            qp = { surveys: true};
        }
        this.router.navigate([this.surveysUrl], {queryParams: qp});
    }

    private parseTime(time, type?: TIME_TYPE): number {
        const duration = time.split(':');

        if (!duration.length) {
            return time;
        }

        if (type === TIME_TYPE.MINUTES) {
            return (+duration[0]);
        }

        if (type === TIME_TYPE.MILLISECONDS) {
            return ((+duration[0] * 60) + (+duration[1] || 0)) * 1000;
        }

        return (+duration[0] * 60) + (+duration[1] || 0);
    }

    ngOnDestroy(): void {
        if (this.mediaType === MEDIA_TYPE.STREAM) {
            this.streamService.closeStreamStatusWebsocket();
        }

        if (this.notifier$) {
            this.notifier$.next();
            this.notifier$.complete();
        }
    }


    private switchToAutomaticMode(isNew: boolean = false): void {
        if (this.notifier$) {
            this.notifier$.next();
            this.notifier$.complete();
        }
        this.notifier$ = new Subject<any>();
        this.streamService.stream$.pipe(takeUntil(this.notifier$)).subscribe((stream) => {
            if (!stream) {
                return;
            }

            if (stream?.media?.status?.stream === STREAM_STATUS.FINISHED && stream?.media?.hasRecording) {
                this.durationForm.get('manualStart').patchValue(false);
                this.durationConfig[1][0].config.disabled = false;
                this.durationConfig[0][0].hidden = true;
                this.durationForm.get('startTime').setValidators([Validators.required, CustomValidators.timer]);
                this.durationForm.get('startTime').updateValueAndValidity();
                if (!isNew) {
                    this.durationForm.get('startTime').markAsTouched();
                }
            }

            this.notifier$.next();
            this.notifier$.complete();
        });

    }

    public toggleOverlay(state: boolean): Observable<any> {
        return this.surveyService.toggle(this.data.id, state)
            .pipe(take(1));
    }

}

enum TIME_TYPE {
    MINUTES, MILLISECONDS
}
