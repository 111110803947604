import {Component, EventEmitter, HostListener, Input, Output, ViewChild} from '@angular/core';
import {IMediaItem} from '@src/app/models/core.model';
import {INPUT_TYPES, IUnitKeyValue} from 'ui-elements';
import {LoadingService} from '@src/app/services/loading/loading.service';
import {IPagedResponse, IPagedResponseResults, SortParam} from '@src/app/models/response.model';
import {IMediaCardConfig} from '@src/app/components/media-card/media-card.component';
import {Router} from '@angular/router';
import {IMediaHeaderConfig} from '@src/app/components/media-list/media-list-header/media-list-header.component';
import {Observable} from 'rxjs';
import {take} from 'rxjs/operators';
import {IStreamModel, ITimerange} from '@src/app/models/stream.model';
import {MEDIA_TYPE} from '@src/app/components/streamdust-player/constants/mediaType';
import {ISuggestedMediaFilters} from '@src/app/pages/public-media-page/public-media-page.component';
import {IMediaGroup} from '@src/app/models/stream-groups.model';
import {DragScrollComponent} from 'ngx-drag-scroll';
import {VIDEO_VISIBLE_SETTINGS} from '@src/app/models/video-on-demand.model';

@Component({
    selector: 'app-media-list',
    templateUrl: './media-list.component.html',
    styleUrls: ['./media-list.component.sass']
})
export class MediaListComponent {
    @ViewChild('nav', {read: DragScrollComponent}) ds: DragScrollComponent;

    @Input() set data(data: IPagedResponseResults<IMediaItem | IStreamModel>) {
        this._data = data;
        if (this.config?.loadMore && this._data?.items?.length) {
            this._hasMore = this._data.items.length < this._data.paging.items;
            this.calculateSkeletonItemsAmount();
        }
    }

    constructor(
        public loadingService: LoadingService,
        private router: Router
    ) {
    }

    @Input() title: string;
    @Input() selectorIndex: number;

    @Input() activeQuery: IListQuery = {
        paging: {
            page: 0,
            itemsOnPage: 6
        },
        sort: [
            {
                order: 'DESC',
                field: 'DATE'
            }
        ],
        filters: []
    };

    @Input() implicitFilters: IListFilterItem[] = [];

    @Input() config: IMediaListConfig;
    @Input() customClick: boolean;
    @Output() public removeMediaItem$ = new EventEmitter<IMediaItem>();
    @Output() public updateFilter$ = new EventEmitter<IListQuery>();
    @Output() public loadNextPage$ = new EventEmitter<IListQuery>();
    @Output() public customClick$ = new EventEmitter<IMediaItem>();
    @Output() public selectorClick$ = new EventEmitter<number>();
    _hasMore = false;
    loading = false;
    filtrationLoading = false;
    _data: IPagedResponseResults<IMediaItem | IStreamModel>;
    timeout: ReturnType<typeof setTimeout>;
    skeletonItemsAmount = [];

    LOAD_TYPE = LOAD_TYPE;
    INPUT_TYPES = INPUT_TYPES;

    identify(index, item) {
        return item.id;
    }

    @HostListener('window: scroll', ['$event']) infiniteScroll() {
        if (this.config?.loadMore === LOAD_TYPE.SCROLL && !this.loading) {
            const streamsList = document.getElementById('streams-list');
            if ((window.innerHeight + window.pageYOffset) >= streamsList.offsetHeight + streamsList.offsetTop) {
                this.loadNextPage();
            }
        }
    }

    public removeMediaItem(mediaItem: IMediaItem): void {
        this.removeMediaItem$.emit(mediaItem);
    }

    doFilter(filtersQuery: IListQuery): void {
        this.filtrationLoading = true;
        this.activeQuery.paging.page = 0;
        Object.assign(this.activeQuery, filtersQuery);
        this.mergeFilters();
        this.config.fetchMethod(this.activeQuery).pipe(take(1)).subscribe(data => {
            this.data = data.results.data;
            this.filtrationLoading = false;
        });
    }

    doFilterAsPromise(filtersQuery: IListQuery): Promise<IPagedResponseResults<IMediaItem>> {
        return new Promise(resolve => {
            this.filtrationLoading = true;
            this.activeQuery.paging.page = 0;
            Object.assign(this.activeQuery, filtersQuery);
            this.mergeFilters();
            this.config.fetchMethod(this.activeQuery).pipe(take(1)).subscribe(data => {
                this.data = data.results.data;
                this.filtrationLoading = false;
                resolve(data.results.data);
            });
        });
    }

    loadNextPage(): void {
        if (this.config?.loadMoreCustomClick) {
            this.config.loadMoreCustomClick();
        }

        if (this._hasMore) {
            this.activeQuery.paging.page += 1;
            this.loading = true;
            this._hasMore = this.hasMore(this._data?.paging?.itemsOnPage);
            this.updateList((data) => {
                this._data.items = this._data.items.concat(data.results.data.items);
                this.calculateSkeletonItemsAmount();
                this.loading = false;
            });
        }
    }

    refreshList(): void {
        this.activeQuery.paging.page = 0;
        this.filtrationLoading = true;
        this.updateList((data) => {
            this.data = data.results.data;
            this.filtrationLoading = false;
        });
    }

    updateList(dataUpdateFunction): void {
        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = null;
        }
        this.mergeFilters();
        this.config.fetchMethod(this.activeQuery).pipe(take(1)).subscribe(data => {
            this.timeout = setTimeout(() => {
                dataUpdateFunction(data);
            }, 500);
        });
    }

    setFiltrationLoading(value: boolean) {
        this.filtrationLoading = value;
    }

    private hasMore(increaseBy = 0): boolean {
        return this._data?.items?.length + increaseBy < this._data?.paging?.items;
    }

    resolveMediaType(mediaItemType): string {
        return mediaItemType === MEDIA_TYPE.VIDEO_ON_DEMAND ? 'vod' : 'stream';
    }

    click(mediaItem: IMediaItem): void {
        if (this.customClick) {
            this.customClick$.emit(mediaItem);
            return;
        }
        this.router.navigate(['watch', this.resolveMediaType(mediaItem.videoType), mediaItem.id], {queryParams: this.config.queryParams});
    }

    mergeFilters(): void {
        this.implicitFilters.forEach(mediaFilter => {
            if (!this.activeQuery.filters?.some(queryFilter => queryFilter.field === mediaFilter.field)) {
                this.activeQuery?.filters.push(mediaFilter);
            }
        });
    }

    navigateToNewMediaPage(): void {
        this.router.navigate([this.config?.addMediaButton?.navigateLink]);
    }

    calculateSkeletonItemsAmount(): void {
        let mediaItemsAmount = this._data?.items?.length;
        if (this.config?.addMediaButton?.show) {
            mediaItemsAmount++;
        }

        const itemsAtLastRow = mediaItemsAmount % 3;
        if (itemsAtLastRow) {
            this.skeletonItemsAmount = new Array(3 - itemsAtLastRow);
        } else {
            this.skeletonItemsAmount = [];
        }
    }

    onSelectorClick(index: number): void {
        this.selectorClick$.emit(index);
    }

    moreClick(): void {
        this.config?.moreButton?.navigationClick();
    }

    moveLeft() {
        this.ds.moveLeft();
    }

    moveRight() {
        this.ds.moveRight();
    }
}

export interface IMediaListConfig {
    isLanding?: boolean;
    hideEmptyList?: boolean;
    headerConfig?: IMediaHeaderConfig;
    cardConfig?: IMediaCardConfig;
    loadMore?: LOAD_TYPE;
    loadMoreCustomClick?: () => void;
    borderTop?: boolean;
    elementId?: string;
    fetchMethod?: (filters: IListQuery) => Observable<IPagedResponse<IMediaItem>>;
    cssClass?: string;
    filters?: ISuggestedMediaFilters;
    addMediaButton?: {
        show: boolean;
        text: string;
        navigateLink: string;
    };
    selector?: {
        title: string;
        items: string[];
    };
    carouselLayout?: boolean;
    basicSkeleton?: boolean;
    moreButton?: {
        show: boolean;
        text: string;
        navigationClick: () => void;
    };
    queryParams: { [key: string]: any };
}

export interface IFilterListItem {
    name: string;
    placeholder: string;
    selectOptions: IUnitKeyValue[];
    isAutocomplete?: boolean;
}

export interface IListFilterItem {
    field: string;
    value: string | string[] | boolean;
}

export interface IListQuery {
    searchTerm?: string;
    paging?: {
        page: number;
        itemsOnPage: number;
    };
    sort?: SortParam[];
    timeRange?: ITimerange;
    filters?: IListFilterItem[];
    groupFilters?: IListFilterItem[];
    implicitFilters?: IListFilterItem[];
}

export enum LOAD_TYPE {
    BUTTON = 'BUTTON',
    SCROLL = 'SCROLL'
}
