<div class="landing">
    <section class="section section-film">
        <div class="wrapper-video">
            <div class="overlay-video"></div>
            <video #video autoplay muted loop width="100%" id="landing-video" (canplay)="video.muted = true">
                <source src="../../../../assets/video/header_mainpage.mp4" type="video/mp4">
                <source src="../../../../assets/video/header_mainpage.webm" type="video/webm">
                <source src="../../../../assets/video/header_mainpage.ogv" type="video/ogg">
                <object data="../../../../assets/video/header_mainpage.swf" type="application/x-shockwave-flash">
                    <param name="movie" value="video.swf">
                </object>
            </video>
        </div>
        <h1>{{'landing-page.title' | localize}}</h1>
    </section>

    <section *ngIf="actualStreamsListConfig?.media?.items?.length" class="section section-article-carousel section-livestream section-p-70">
        <div class="container">
            <div class="box-article">
                <div class="header-article">
                    <h2 class="inverse text-center"><b>{{'landing-page.latest-livestreams' | localize}}</b></h2>
                </div>
                <div class="wrapper-slider">
                    <div class="box-live-streams">
                        <app-media-list
                                [config]="{
                                isLanding: true,
                                headerConfig: {
                                    cssClass: 'title-level-1',
                                    filtersCssClass: 'blue-theme-filter',
                                    filtration: true
                                },
                                cardConfig: {
                                isLanding: true,
                                    elements: {
                                        country: true,
                                        publisher: true,
                                        group: true
                                    }
                                }
                            }"
                                [data]="actualStreamsListConfig?.media">
                        </app-media-list>
                    </div>
                </div>
                <div class="button-group">
                    <div class="row justify-content-center">
                        <div class="col-auto">
                            <button routerLink="./streams/actual"
                                    class="btn btn-primary">{{'landing-page.all-livestreams' | localize}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="section section-bg section-lg section-marketing"
             style="background-image: url('../../../../assets/img/background/bg-marketing.jpg')">
        <div class="container">
            <div class="row align-items-center h-100">
                <div class="col-lg-6">
                    <div class="heading-block">
                        <h2>{{'landing-page.product.name' | localize}} <b>{{'landing-page.marketing' | localize}}</b>
                        </h2>
                        <ul class="list-mark-triangle">
                            <li>{{'landing-page.marketing.option1' | localize}}</li>
                            <li>{{'landing-page.marketing.option2' | localize}}</li>
                            <li>{{'landing-page.marketing.option3' | localize}}</li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="gif-container h-350">
                        <img class="img-responsive"
                             src="{{'../../../../assets/img/gifs/Streamdust_Marketing_' + ApiService.localLanguage +'-min.gif'}}"
                             alt=""/>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="section section-bg section-lg section-interactive"
             style="background-image: url('../../../../assets/img/background/bg-interactive.jpg')">
        <div class="container">
            <div class="row align-items-center h-100">
                <div class="col-lg-6">
                    <div class="gif-container h-350">
                        <img class="img-responsive"
                             src="{{'../../../../assets/img/gifs/Streamdust_Interaktiv_' + ApiService.localLanguage +'-min.gif'}}"
                             alt=""/>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="heading-block">
                        <h2>{{'landing-page.product.name' | localize}} <b>{{'landing-page.interaktiv' | localize}}</b>
                        </h2>
                        <ul class="list-mark-triangle">
                            <li>{{'landing-page.interaktiv.option1'|localize}}</li>
                            <li>{{'landing-page.interaktiv.option2'|localize}}</li>
                            <li>{{'landing-page.interaktiv.option3'|localize}}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="section section-bg section-lg section-monetize"
             style="background-image: url('../../../../assets/img/background/bg-monetarisieren.jpg')">
        <div class="container">
            <div class="row align-items-center h-100">
                <div class="col-lg-6">
                    <div class="heading-block">
                        <h2>{{'landing-page.product.name' | localize}}
                            <b>{{'landing-page.monetarisieren' | localize}}</b></h2>
                        <ul class="list-mark-triangle">
                            <li>
                                {{'landing-page.monetarisieren.option1' | localize}}
                            </li>
                            <li>
                                {{'landing-page.monetarisieren.option2' | localize}}
                            </li>
                            <li>
                                {{'landing-page.monetarisieren.option3' | localize}}
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="gif-container h-350">
                        <img class="img-responsive"
                             src="{{'../../../../assets/img/gifs/Streamdust_Previewteaser_' + ApiService.localLanguage +'-min.gif'}}"
                             alt=""/>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="section section-bg section-lg section-setting"
             style="background-image: url('../../../../assets/img/background/bg-individualisieren.jpg')">
        <div class="container">
            <div class="row align-items-center h-100">
                <div class="col-lg-6">
                    <div class="gif-container h-350">
                        <img class="img-responsive"
                             src="{{'../../../../assets/img/gifs/Streamdust_WhiteLabel_' + ApiService.localLanguage +'-min.gif'}}"
                             alt=""/>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="heading-block">
                        <h2>{{'landing-page.product.name' | localize}}
                            <b>{{'landing-page.individualisieren1' | localize}}</b><b>{{'landing-page.individualisieren2' | localize}}</b>
                        </h2>
                        <ul class="list-mark-triangle">
                            <li>{{'landing-page.individualisieren.option1' | localize}}</li>
                            <li>{{'landing-page.individualisieren.option2' | localize}}</li>
                            <li>{{'landing-page.individualisieren.option3' | localize}}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="section section-features section-p-70" id="section-features">
        <div class="container">
            <div class="heading-block">
                <h2 class="text-center inverse">
                    <b>{{'landing-page.our-top-features1' | localize}}</b> {{'landing-page.our-top-features2' | localize}}
                </h2>
            </div>
            <div class="row">
                <div class="col-6 col-lg-4">
                    <div class="feature-box">
                        <div class="features-header">
                            <img src="../../../../assets/img/ico/programm.svg" alt="programm">
                        </div>
                        <div class="features-body">
                            <h3><b>{{'landing-page.our-top-features.pr1.title' | localize}}</b></h3>
                            <p>{{'landing-page.our-top-features.pr1.desc' | localize}}</p>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-lg-4">
                    <div class="feature-box">
                        <div class="features-header">
                            <img src="../../../../assets/img/ico/login.svg" alt="programm">
                        </div>
                        <div class="features-body">
                            <h3><b>{{'landing-page.our-top-features.pr2.title' | localize}}</b></h3>
                            <p>{{'landing-page.our-top-features.pr2.desc' | localize}}</p>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-lg-4">
                    <div class="feature-box">
                        <div class="features-header">
                            <img src="../../../../assets/img/ico/spenden.svg" alt="programm">
                        </div>
                        <div class="features-body">
                            <h3><b>{{'landing-page.our-top-features.pr3.title' | localize}}</b></h3>
                            <p>{{'landing-page.our-top-features.pr3.desc' | localize}}</p>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-lg-4">
                    <div class="feature-box xl-mt-60">
                        <div class="features-header">
                            <img src="../../../../assets/img/ico/content.svg" alt="programm">
                        </div>
                        <div class="features-body">
                            <h3><b>{{'landing-page.our-top-features.pr4.title' | localize}}</b></h3>
                            <p>{{'landing-page.our-top-features.pr4.desc' | localize}}</p>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-lg-4">
                    <div class="feature-box xl-mt-60">
                        <div class="features-header">
                            <img src="../../../../assets/img/ico/gruppien.svg" alt="programm">
                        </div>
                        <div class="features-body">
                            <h3><b>{{'landing-page.our-top-features.pr5.title' | localize}}</b></h3>
                            <p>{{'landing-page.our-top-features.pr5.desc' | localize}}</p>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-lg-4">
                    <div class="feature-box xl-mt-60">
                        <div class="features-header">
                            <img src="../../../../assets/img/ico/video.svg" alt="programm">
                        </div>
                        <div class="features-body">
                            <h3><b>{{'landing-page.our-top-features.pr6.title' | localize}}</b></h3>
                            <p>{{'landing-page.our-top-features.pr6.desc' | localize}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="section section-bg section-desc"
             style="background-image: url('../../../../assets/img/background/bg-osman-rana.jpg')">
        <div class="container">
            <h2 class="text-center"><b>{{'landing-page.your-livestream.title' | localize}}</b></h2>
            <div class="row justify-content-center mt-50">
                <div class="col-auto">
                    <button routerLink="./registration"
                            class="btn btn-xlg btn-inverse">{{'landing-page.your-livestream.button' | localize}}</button>
                </div>
            </div>
        </div>
    </section>

    <section id="section-calculate-ticket" class="section section-calculate-ticket section-p-70">
        <div class="container">
            <app-calculate-ticket></app-calculate-ticket>
        </div>
    </section>

    <section class="section bg-primary-dark section-p-lg section-advantages">
        <div class="container">
            <div class="row align-items-center h-100 row-padding-55">
                <div class="col-lg-6">
                    <div class="heading-block">
                        <h2>
                            <b>{{'landing-page.summarized.title1' | localize}}</b><b>{{'landing-page.summarized.title2' | localize}}</b>
                        </h2>
                        <div class="align-content-end">
                            <ul class="list-mark-check list-sm">
                                <li *ngFor="let a of [1,2,3,4,5,6,7,8,9,10,11,12,13,14]">{{'landing-page.summarized.option' + a | localize}}</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="gif-container h-350">
                        <img class="absolute-top-right img-responsive"
                             src="../../../../assets/img/graphics/vorteile.jpg" alt=""/>

                        <img class="img-responsive gif"
                             src="{{'../../../../assets/img/gifs/Streamdust_Dashboard_' + ApiService.localLanguage +'-min.gif'}}"
                             alt=""/>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="section section-tariff section-p-70" id="section-tariff" *ngIf="subscriptionTariffPlans" >
        <div class="container">
            <div class="panel panel-md mb-30 plan-period-select-wrapper">
            <div class="card-body pl-0 pr-0 subscription-plans-wrapper">
                <h2 class="inverse text-center"><b>{{'landing-page.choose-your-plan.title' | localize}}</b></h2>
                <app-plan-cards-list [tariffPlans]="subscriptionTariffPlans"
                                     [config]="tariffPlansConfig"
                                     [isAuthorized]="isAuthorized"
                                     (actionButtonPressed$)="buySubscription($event)">
                </app-plan-cards-list>
                <div class="button-group">
                    <div class="row justify-content-center">
                        <div class="col-auto">
                            <button (click)="onScrollToTable()"
                                    class="btn btn-primary">{{'landing-page.choose-your-plan.button' | localize}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </section>

    <section class="section section-p-70 section-tariffs" #detailsTable>
        <div class="container">
            <app-subscription-tariffs-table [isShowFirstSection]="true"></app-subscription-tariffs-table>
        </div>
    </section>

    <section class="section section-bg section-desc"
             style="background-image: url('../../../../assets/img/background/bg-mark-basarab.jpg')">
        <div class="container">
            <h2 class="text-center">{{'landing-page.streamdust-subscription.präsentieren'|localize}} –
                <b>{{'landing-page.streamdust-subscription.weltweit'|localize}}</b></h2>
            <div class="row justify-content-center">
                <div class="col-auto">
                    <button routerLink="./registration" class="btn btn-xlg btn-inverse">
                        {{'landing-page.streamdust-subscription.registrieren'|localize}}
                    </button>
                </div>
            </div>
        </div>
    </section>

    <section class="section section-customer section-p-70">
        <div class="container">
            <h2 class="inverse text-center"><b>{{'landing-page.unsere'|localize}}</b></h2>
            <div class="wrapper-slider">
                <div class="slider-title">{{'landing-page.Partner'|localize}}</div>
                <div class="slider-partner custom-nav">
                    <div class="item" *ngFor="let partner of partnersList">
                        <a [href]="partner.link" target="_blank">
                            <img class="img-responsive" [src]="partner.image" alt=""/>
                        </a>
                    </div>
                </div>
            </div>
            <div class="wrapper-slider">
                <div class="slider-title">{{'landing-page.Kunden'|localize}}</div>
                <div class="slider-customer custom-nav">
                    <div class="item" *ngFor="let customer of customersList">
                        <a [href]="customer.link" target="_blank">
                            <img class="img-responsive" [src]="customer.image" alt=""/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
