import {AfterViewInit, Directive, ElementRef, EventEmitter, Output} from '@angular/core';
import {fromEvent} from 'rxjs';
import {debounceTime} from 'rxjs/operators';

@Directive({
    selector: '[appInfinityScroll]'
})
export class InfinityScrollDirective implements AfterViewInit {


    @Output() private scrolled: EventEmitter<void> = new EventEmitter<void>();
    public scrollEndDelay = 150;
​

    constructor(private elementRef: ElementRef<HTMLElement>) {
    }

​

    public ngAfterViewInit(): void {
        const el = this.elementRef.nativeElement;
        fromEvent(el, 'scroll')
            .pipe(debounceTime(this.scrollEndDelay))
            .subscribe(() => {
                if (window.innerHeight - el.clientHeight - 200 <= el.scrollTop) {
                    this.scrolled.emit();
                }
            });
    }

}
