<div class="wrapper-linked-streams left-side" *ngIf="modalState.isOpened$ | async">
    <ano-loader [class.transparent-loader]="true" [isOpen]="loading"></ano-loader>
    <div class="linked-streams-box">
        <div class="linked-streams-header">
            <h2 class="title" *ngIf="!isPayment">{{programName}}</h2>
            <i class="icon icon-close" (click)="close()"></i>
        </div>
        <app-slots-info *ngIf="!isPayment"
                        [data]="slots"
                        [media]="media"
                        [isPayedAll]="isPayedAll"
                        (proceedPayment$)="proceedPayment($event)"
                        (playSlot$)="playSlot($event)"
        ></app-slots-info>
        <app-slots-payment *ngIf="isPayment"
                           [termsConditions]="termsConditions"
                           [mediaId]="media?.id"
                           [productType]="media?.videoType"
                           [playerMode]="playerMode"
                           [slotId]="slotId"
                           (slotPaymentSuccess$)="paymentSuccess()"
                           (paymentCanceled$)="paymentCanceled()"
        ></app-slots-payment>
<!--        <app-group-info *ngIf="currentGroup && !isPayment"-->
<!--                        [mediaId]="mediaId"-->
<!--                        [data]="currentGroup"-->
<!--                        (navigate$)="close()"-->
<!--                        (proceedPayment$)="onPaymentProceed()"></app-group-info>-->
<!--        <app-group-payment *ngIf="currentGroup && isPayment"-->
<!--                           [mediaId]="mediaId"-->
<!--                           [groupId]="currentGroup.id"-->
<!--                           (paymentSuccess$)="paymentSuccess()"-->
<!--        ></app-group-payment>-->
    </div>
</div>
