import {Component, Input, OnInit} from '@angular/core';
import {IFieldsConfig} from '@src/app/pages/user-profile/interfaces/interfaces-common';
import {FormBuilder, FormGroup} from '@angular/forms';
import {pluck, takeUntil} from 'rxjs/operators';
import {RemindService} from '@src/app/services/remind/remind.service';
import {StreamService} from '@src/app/services/stream-metadata/stream.service';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import { STREAM_STATUS } from '@src/app/components/streamdust-player/constants/status';
import {INPUT_TYPES} from 'ui-elements';

@Component({
  selector: 'app-reminder',
  templateUrl: './reminder.component.html',
  styleUrls: ['./reminder.component.scss'],
  providers: [AutoDestroyService]
})
export class ReminderComponent implements OnInit {
  STREAM_STATUS = STREAM_STATUS;
  @Input() mediaId: string;
  @Input() standAlone: boolean;
  fieldsConfig: IFieldsConfig[][];
  form: FormGroup;
  private data: any;

  constructor(
      private readonly remindService: RemindService,
      private fb: FormBuilder,
      public streamService: StreamService,
      private readonly destroy$: AutoDestroyService,
  ) { }

  ngOnInit(): void {
    this.getAvailableWithState();
    this.initForms();
  }

  getAvailableWithState() {
    this.remindService.getAvailableWithState(this.mediaId)
        .pipe(
            takeUntil(this.destroy$),
            pluck('results', 'data')
        )
        .subscribe(res => {
          this.data = res;
          this.initFormsConfig();
          this.setFormData();
          this.listenForm();
        });
  }

  private initForms() {
    this.form = this.fb.group({
      min15: [false],
      hour1: [false],
      hour15: [false],
      day1: [false],
      day2: [false],
    });
  }

  private initFormsConfig() {
    this.fieldsConfig = [
      [
        {
          name: 'min15',
          config: {
            inputType: INPUT_TYPES.CHECKBOX,
            placeholder: this.data.find(item => item.id === '1').name,
            size: this.standAlone ? 8 : 12
          }
        }
      ],
      [
        {
          name: 'hour1',
          config: {
            inputType: INPUT_TYPES.CHECKBOX,
            placeholder: this.data.find(item => item.id === '2').name,
            size: this.standAlone ? 8 : 12
          }
        }
      ],
      [
        {
          name: 'hour15',
          config: {
            inputType: INPUT_TYPES.CHECKBOX,
            placeholder: this.data.find(item => item.id === '3').name,
            size: this.standAlone ? 8 : 12
          }
        }
      ],
      [
        {
          name: 'day1',
          config: {
            inputType: INPUT_TYPES.CHECKBOX,
            placeholder: this.data.find(item => item.id === '4').name,
            size: this.standAlone ? 8 : 12
          }
        }
      ],
      [
        {
          name: 'day2',
          config: {
            inputType: INPUT_TYPES.CHECKBOX,
            placeholder: this.data.find(item => item.id === '5').name,
            size: this.standAlone ? 8 : 12
          }
        }
      ],
    ];
  }

  private setFormData() {
    this.data.map(() => {
      this.form.get('min15').setValue(this.data.find(item => item.id === '1').value);
      this.form.get('hour1').setValue(this.data.find(item => item.id === '2').value);
      this.form.get('hour15').setValue(this.data.find(item => item.id === '3').value);
      this.form.get('day1').setValue(this.data.find(item => item.id === '4').value);
      this.form.get('day2').setValue(this.data.find(item => item.id === '5').value);
    });
  }

  private listenForm() {
    this.form.valueChanges
        .pipe(takeUntil(this.destroy$))
        .subscribe(res => {
          this.updateRemindState();
        });
  }

  private updateRemindState() {
    const dataForm = this.form.getRawValue();
    const body = [
      {
        id: 1,
        value: this.form.get('min15').value
      },
      {
        id: 2,
        value: this.form.get('hour1').value
      },
      {
        id: 3,
        value: this.form.get('hour15').value
      },
      {
        id: 4,
        value: this.form.get('day1').value
      },
      {
        id: 5,
        value: this.form.get('day2').value
      }
    ];

    this.remindService.update(this.mediaId, {reminders: body})
        .pipe(takeUntil(this.destroy$))
        .subscribe(res => {
        });
  }

}
