import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'filter'})
export class FilterPipe implements PipeTransform {
    transform(items: any[], searchValue: string, fieldName?: string) {
        if (!searchValue) {
            return items;
        }
        const defaultKey = fieldName || 'categoryName'; // for groups
        return items
            .filter(item => item[defaultKey] && typeof item[defaultKey] === 'string')
            .filter(item => (item[defaultKey] as string).toLowerCase().includes(searchValue.toLowerCase()));
    }
}
