import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SlotsModalState} from '@src/app/state/slots-modal-state';
import {IPaymentSlot, IPublicStreamInfo} from '@src/app/models/stream.model';
import {IUserAGB} from '@src/app/pages/user-profile/components/withdrawal-and-conditions/services/withdrawal-and-conditions.service';
import {PLAYER_DISPLAY_MODE} from '@src/app/components/streamdust-player/constants/playerMode';
import {takeUntil} from 'rxjs/operators';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import {OpenCurrentSlotPayment, FailurePaymentService} from '@src/app/services/slot-failure/failure-payment.service';

@Component({
  selector: 'app-slots-modal',
  templateUrl: './slots-modal.component.html',
  styleUrls: ['./slots-modal.component.scss']
})
export class SlotsModalComponent implements OnInit {
  public loading: boolean;
  @Input() public slots: IPaymentSlot[];
  @Input() public media: IPublicStreamInfo;
  @Input() public programName: string;
  @Input() termsConditions: IUserAGB;
  @Input() public playerMode: PLAYER_DISPLAY_MODE;
  @Output() public playSlot$: EventEmitter<string> = new EventEmitter<string>();
  public isPayment: boolean;
  public slotId: string;
  public isPayedAll: boolean;

  constructor(
      public modalState: SlotsModalState,
      private destroy$: AutoDestroyService,
      private failurePaymentService: FailurePaymentService
  ) { }

  ngOnInit(): void {
    this.modalState.isPayedAll$.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      this.isPayedAll = res;
    });

    this.failurePaymentService.slotsModalAction
        .pipe(takeUntil(this.destroy$))
        .subscribe((data: OpenCurrentSlotPayment) => {
          this.proceedPayment(data.slotId);
          this.failurePaymentService.accessWindowAction.next(data);
        });

    this.failurePaymentService.slotModalInitialized.emit();
  }

  public close(): void {
    this.modalState.setState(false);
    this.isPayment = false;
    this.modalState.resetCurrentSlot();
  }

  public proceedPayment(id: string): void {
    if (!id) {
      this.modalState.paymentForAllMedia();
      // this.isPayment = true;
      this.close();
      return;
    }

    this.slotId = id;
    this.isPayment = true;
  }

  public paymentSuccess(): void {
    this.modalState.successfulPayment(this.slotId);
    this.close();
  }

  public playSlot(id: string): void {
    this.playSlot$.emit(id);
  }

  public paymentCanceled(): void {
    this.isPayment = false;
  }

}
