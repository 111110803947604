import { Component, OnInit } from '@angular/core';
import {mergeMap, takeUntil} from "rxjs/operators";
import {SideNavConfigModel} from "@src/app/models/side-nav-config.model";
import {UserService} from "@src/app/services/user/user.service";
import {AutoDestroyService} from "@src/app/services/auto-destroy-service/auto-destroy.service";
import {Router} from "@angular/router";
import {ISideNavConfig} from "@src/app/components/side-nav/side-nav.component";
import {USER_TYPE} from "@src/app/constants/user-type.constant";
import {IUserProfile} from "@src/app/pages/user-profile/components/profile/services/profile.service";
import {SubAccountsService} from '@src/app/services/sub-accounts/sub-accounts.service';
import {AuthService} from '@src/app/services/auth/auth.service';
import {SUB_ACCOUNT_TYPE} from '@src/app/pages/subaccounts-page/model/sub-account.model';

@Component({
  selector: 'app-content-wrapper',
  templateUrl: './content-wrapper.component.html',
  styleUrls: ['./content-wrapper.component.sass']
})
export class ContentWrapperComponent implements OnInit {
    public USER_TYPE = USER_TYPE;
    public navConfig: ISideNavConfig;
    public userType: USER_TYPE;
    userProfile: IUserProfile;

    constructor(
      private router: Router,
      private userService: UserService,
      private destroy$: AutoDestroyService,
      private subAccountsService: SubAccountsService,
      private authService: AuthService
  ) {
      this.userService.userProfile$.pipe(
          mergeMap((userProfile) => {
              this.userProfile = userProfile;
              return this.userService.userType$;
          }),
          takeUntil(this.destroy$))
          .subscribe((type) => {
              this.userType = type;
              this.navConfig = SideNavConfigModel.getLeftMenuConfig(this.userType, this.userProfile?.hasConnectedPublishers, () => {
                  this.router.navigate(["upgrade-to-publisher"], {
                    queryParams: { 'step': 'publisher' },
                  });
              }, this.userProfile?.subscribed, () => {
                  this.subAccountsService.logoutAs().pipe(takeUntil(this.destroy$)).subscribe((res) => {
                      if (!res) {
                          return;
                      }
                      this.authService.logout(false);
                      this.authService.storeToken(res.authToken);
                      this.router.navigateByUrl('/', {skipLocationChange: true})
                          .then(() => {
                              this.router.navigate(['streams']);
                          });
                  });
              }, this.userProfile?.subAccount?.type === SUB_ACCOUNT_TYPE.INVITED);
          });
  }

  ngOnInit(): void {
  }

}
