<div class="panel panel-md">
    <div class="panel-header">
        <h2><b>{{'survey.title' | localize}}</b></h2>
        <p>{{'survey.subtitle' | localize}}</p>
    </div>
    <div class="panel-body bg-white padding-bottom-30">
        <div class="row align-items-end">
            <div class="col-md-12 mr-auto">
            <div class="section-panel">
<!--            <div class="section-panel-header">-->
<!--                <h4><b>{{(createMode ? 'survey.create' : 'survey.edit') | localize}}</b></h4>-->
<!--            </div>-->
            <div class="section-panel">
                <div class="section-panel-header padding-bottom-10">
                    <h4><b>{{'survey.field.name' | localize}}</b></h4>
                </div>
                <app-info-panel *ngIf="disableManualInfoPanel" [infoPanel]="disableManualInfoPanel"></app-info-panel>
                <app-info-panel *ngIf="this.data && this.data?.active" [infoPanel]="deactivateToEdit"></app-info-panel>
                <app-bordered-form
                        [disabled]="this.data?.active || (((streamService?.streamStatus$ | async)?.stream === STREAM_STATUS.FINISHED && !(streamService?.stream$ | async)?.media?.hasRecording)
                        || (![STREAM_STATUS.SCHEDULED, STREAM_STATUS.FINISHED].includes((streamService?.streamStatus$ | async)?.stream) && !createMode) || (streamService.stream$ | async)?.media?.deactivated || data?.manualStarted) && this.mediaType === MEDIA_TYPE.STREAM"
                        [defaultForm]="true"
                        [form]="nameForm"
                        [fieldsConfig]="nameConfig"></app-bordered-form>
                <div class="section-panel-header padding-bottom-10">
                    <h4><b>{{'survey.field.question' | localize}}</b></h4>
                </div>
                <app-bordered-form
                        [disabled]="this.data?.active || (((streamService?.streamStatus$ | async)?.stream === STREAM_STATUS.FINISHED && !(streamService?.stream$ | async)?.media?.hasRecording)
                        || (![STREAM_STATUS.SCHEDULED, STREAM_STATUS.FINISHED].includes((streamService?.streamStatus$ | async)?.stream) && !createMode) || (streamService.stream$ | async)?.media?.deactivated || data?.manualStarted) && this.mediaType === MEDIA_TYPE.STREAM"
                        [defaultForm]="true"
                        [form]="questionForm"
                        [fieldsConfig]="questionConfig"></app-bordered-form>
                <div class="section-panel-header">
                    <h4><b>{{'survey.field.answers' | localize}}</b></h4>
                </div>
                <ng-container *ngFor="let form of answerConfig.forms; let i=index">
                    <app-bordered-form
                            [disabled]="this.data?.active || (((streamService?.streamStatus$ | async)?.stream === STREAM_STATUS.FINISHED && !(streamService?.stream$ | async)?.media?.hasRecording)
                        || (![STREAM_STATUS.SCHEDULED, STREAM_STATUS.FINISHED].includes((streamService?.streamStatus$ | async)?.stream) && !createMode) || (streamService.stream$ | async)?.media?.deactivated || data?.manualStarted) && this.mediaType === MEDIA_TYPE.STREAM"
                            [itemIndex]="i"
                            [addButtonText]="answerConfig.addButtonText"
                            [showAdd]="i === answerConfig.forms.length - 1"
                            [showRemove]="answerConfig.forms.length > 2"
                            [addLimitReached]="answerConfig.forms.length === 20"
                            [form]="form"
                            [fieldsConfig]="answerConfig.formsFields"
                            [withoutBorder]="true"
                            [withoutMargin]="true"
                            [addButtonPositionRight]="true"
                            (add$)="addForm(answerConfig)"
                            (remove$)="removeForm(answerConfig, i)"
                    ></app-bordered-form>
                </ng-container>
                <app-bordered-form
                        [disabled]="this.data?.active || (((streamService?.streamStatus$ | async)?.stream === STREAM_STATUS.FINISHED && !(streamService?.stream$ | async)?.media?.hasRecording)
                        || (![STREAM_STATUS.SCHEDULED, STREAM_STATUS.FINISHED].includes((streamService?.streamStatus$ | async)?.stream) && !createMode) || (streamService.stream$ | async)?.media?.deactivated || data?.manualStarted) && this.mediaType === MEDIA_TYPE.STREAM"
                        [defaultForm]="true"
                        [form]="durationForm"
                        [fieldsConfig]="durationConfig"></app-bordered-form>

                <div class="form-group" *ngIf="this.createMode || (!this.data?.active && !(streamService.stream$ | async)?.media?.deactivated && !((streamService?.stream$ | async)?.media?.status?.stream === STREAM_STATUS.FINISHED && !(streamService?.stream$ | async)?.media?.hasRecording && mediaType === MEDIA_TYPE.STREAM))">
                    <div class="row row-padding-10 action-buttons">
                        <div class="col-auto">
                            <button class="btn btn-secondary" (click)="cancel()">{{'common.button.close' | localize}}</button>
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-success" (click)="submit()">{{'common.button.apply' | localize}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            </div>
        </div>
    </div>
</div>
