import { Injectable } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { ICroppingParams } from '@src/app/components/photo-cropper/photo-cropper.component';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PhotoCropperService {

  public workbenchId$: ReplaySubject<string> = new ReplaySubject<string>(1);

  public constructor() { }

  public static mapImageCroppedEventData({
    width,
    height,
    imagePosition: { x1: x, y1: y },
    transform: { rotation },
  }: ImageCroppedEvent): ICroppingParams {
    return {
      width,
      height,
      x,
      y,
      rotation,
    };
  }

  public completeWorkbenchReadySubject(): void {
    this.workbenchId$.complete();
    this.workbenchId$ = new ReplaySubject<string>(1);
  }
}
