/**
 * Created by Тима on 03.04.2020.
 */
import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {
    BankDetailsService,
    IBankDetails
} from '@src/app/pages/user-profile/components/bank-details/services/bank-details.service';
import {IFieldsConfig} from '@src/app/pages/user-profile/interfaces/interfaces-common';
import {ValidatorService} from 'angular-iban';
import {LoadingService} from '@src/app/services/loading/loading.service';
import {CustomValidators} from "@src/app/utils/custom-validators.util";
import {USER_TYPE} from "@src/app/constants/user-type.constant";
import {UserService} from "@src/app/services/user/user.service";
import {AutoDestroyService} from "@src/app/services/auto-destroy-service/auto-destroy.service";
import {take, takeUntil} from 'rxjs/operators';
import {PaymentService} from "@src/app/services/payment/payment.service";
import {
    IPaymentCard,
    IPaymentCardsListControls
} from "@src/app/components/payment-cards-list/payment-cards-list.component";
import {CmsService} from 'ui-elements';

@Component({
    selector: 'app-bank-details',
    templateUrl: './bank-details.component.html',
    styleUrls: ['./bank-details.component.scss'],
    providers: [AutoDestroyService, BankDetailsService]
})
export class BankDetailsComponent implements OnInit {
    public USER_TYPE = USER_TYPE;
    public userType: USER_TYPE;
    public fieldsConfig: IFieldsConfig[];
    public bankFieldsConfig: IFieldsConfig[];
    public form: FormGroup;
    public bankForm: FormGroup;
    public bankDetails: IBankDetails;
    public paymentCardsList: IPaymentCard[];
    public paymentCardsListControls: IPaymentCardsListControls = {
        removeCard: true,
        addNewCard: {
            show: true,
            text: 'payment-cards-list.empty-list-message'
        },
        canSetCardAsActive: true
    };


    constructor(
        private bankDetailsService: BankDetailsService,
        private fb: FormBuilder,
        private loadingService: LoadingService,
        private userService: UserService,
        private destroy$: AutoDestroyService,
        public paymentService: PaymentService
    ) {
    }

    ngOnInit(): void {
        this.userService.userType$.pipe(takeUntil(this.destroy$))
            .subscribe((userType) => {
                this.userType = userType;
                this.form = this.generateForm();
                this.fieldsConfig = this.bankDetailsService.getFieldsConfig();
                if (this.userType === USER_TYPE.PUBLISHER) {
                    this.bankForm = this.generateBankForm();
                    this.bankFieldsConfig = this.bankDetailsService.getBankFieldsConfig();
                    this.bankDetailsService.getBankDetails().pipe(take(1))
                        .subscribe((res) => {
                            if (!res) {
                                return;
                            }

                            this.bankDetails = res;

                            this.fillForm();
                        });
                }
            });
    }

    private generateForm(): FormGroup {
        return this.fb.group({
            // cardType: [''],
            accountId: ['', [Validators.required]],
            accountRecipient: ['', [Validators.required]],
            iBan: ['', [Validators.required]],
            bic: ['', [Validators.required]],
            street: ['', [Validators.required]],
            city: [''],
            postalCode: ['', [Validators.required]],
            country: ['', [Validators.required]],
            bankCountry: ['', [Validators.required]],
            bankName: ['', [Validators.required]],
        });
    }

    private generateBankForm(): FormGroup {
        return this.fb.group({
            accountRecipient: ['', [Validators.required]],
            street: ['', [Validators.required]],
            city: ['', [Validators.required]],
            postalCode: ['', [Validators.required]],
            country: ['', [Validators.required]],
            bankCountry: ['', [Validators.required]],
            iBan: ['', [Validators.required, ValidatorService.validateIban]],
            bic: ['', [Validators.required, CustomValidators.bic]],
            bankName: ['', [Validators.required]],
        });
    }
    public fillForm(): void {
        const controls = this.bankForm.controls;
        controls.accountRecipient.setValue(this.bankDetails.accountRecipient);
        controls.street.setValue(this.bankDetails.street);
        controls.city.setValue(this.bankDetails.city);
        controls.postalCode.setValue(this.bankDetails.postalCode);
        controls.country.setValue(this.bankDetails.country);
        controls.bankCountry.setValue(this.bankDetails.bankCountry);
        controls.iBan.setValue(this.bankDetails.iBan);
        controls.bic.setValue(this.bankDetails.bic);
        controls.bankName.setValue(this.bankDetails.bankName);
    }
    public saveChanges(): void {
        this.bankForm.markAllAsTouched();
        if (this.bankForm.invalid) { return; }
        this.loadingService.loadingStart();
        this.bankDetailsService.saveBankDetails(this.bankForm.value).subscribe(
            (res) => {
                this.loadingService.loadingEnd();
            },
            (error) => {
            }
        );
    }
}
