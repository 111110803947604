<ano-loader [isOpen]="loader"></ano-loader>
<div class="panel panel-md" *ngIf="!loader">
    <div class="panel-header">
        <h2><b>{{configPage.title | localize}}</b></h2>
        <p>{{configPage.subtitle | localize}}</p>
    </div>
    <div class="panel-body bg-white">
        <div class="section-panel">
            <div class="section-panel-header">
                <h4><b>Einblendung Aktivieren</b></h4>
                <form [formGroup]="activateForm">
                    <mat-slide-toggle [formControlName]="'active'" class="video-activation-toggle"></mat-slide-toggle>
                </form>
            </div>
            <div class="section-panel-body" *ngIf="data">
                <div class="row" *ngIf="!data.active">
                    <div class="col-6">
                        <app-info-panel [infoPanel]="disabledInfoPanel"></app-info-panel>
                    </div>
                </div>
                <div class="user-image" *ngIf="picture">
                    <img class="img-responsive {{pictureClass || ''}}" [src]="picture" alt="image">
                </div>
                <div class="row">
                    <div class="col-md-9">
                        <ano-form [form]="form" [classList]="'group-mb-0'" [forcedViewMode]="true" [borderForm]="true" [fieldsConfig]="config" [withoutSave]="true" [materialForm]="true" [isLightForm]="true"></ano-form>
                        <ng-container *ngIf="data && data.active && data.startTime === null && mediaType === MEDIA_TYPE.STREAM && (streamService?.streamStatus$ | async)?.stream === STREAM_STATUS.LIVE">
                            <div class="form-group autostop-info-message">
                                <span class="error-message" *ngIf="data.manualStarted ">Einblendung wurde gestartet und wird automatisch in {{data.showDuration}} beendet.</span>
                            </div>
                            <div class="btn-group">
                                <div class="row">
                                    <div class="col-auto">
                                        <button class="btn btn-success" (click)="start()" [disabled]="data.manualStarted">{{'common.button.start' | localize}}</button>
                                    </div>
                                    <div class="col-auto">
                                        <button class="btn btn-danger" (click)="stop()" [disabled]="!data.manualStarted">{{'common.button.stop' | localize}}</button>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <div class="col-md-3">
                        <div class="button-group text-right mr-20 mb-20 flex flex-row justify-content-sm-between">
                            <div class="form-group">
                                <button class="btn btn-secondary back-button" (click)="goToOverlaysPage()">
                                    {{'common.button.back' | localize}}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
