import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.css']
})
export class PaginatorComponent implements OnInit {
  @Input() totalPages: number;
  @Input() currentPage: number;
  @Output() goToPage$: EventEmitter<number> = new EventEmitter<number>();
  constructor() { }

  ngOnInit(): void {
  }

  public getCounters(): any {
    return new Array(this.totalPages);
  }

  public goToPage(page: number): void {
    this.goToPage$.emit(page);
  }
}
