<ano-table *ngIf="configLoaded"
           #tableComponent
           [tableConfig]="tableConfig"
           [height]="'80px'"
           [addButton]="!config?.hideCreateButton && (!((streamService?.stream$ | async)?.media?.status?.stream === STREAM_STATUS.FINISHED && !(streamService?.stream$ | async)?.media?.hasRecording) && !(streamService.stream$ | async)?.media?.deactivated) && ((streamService.stream$ | async)?.media?.status?.stream !== STREAM_STATUS.PENDING)"
           [defaultSortField]="'NAME'"
           [sortOrder]="1"
           [addButtonText]="'common.button.create'"
           [withSearch]="!config?.hideSearch"
           (navigate$)="goToNoDataUrl($event)"
           (search$)="search($event)"
           (addItem$)="addNewItem()">
    <ng-container role="bottomButtons">
        <button class="btn btn-success margin-left-20">{{'overlays.downloadCsv' | localize}}</button>
    </ng-container>
</ano-table>
<ng-template #nameTmpl let-data="rowData">
    <div class="long-text">
        <span>
            {{data.name}}
        </span>
    </div>
</ng-template>
<ng-template #posTmpl let-data="rowData">
    {{ (position[data.position] || '') | localize }}
</ng-template>
<ng-template #actionsTmpl let-data="rowData">
    <b class="text-primary">
        <i (click)="goToOverlayInfo(data.id)"
           class="icon icon-info cursor-pointer"></i> |
    </b>
    <b class="text-primary">
        <i (click)="goToOverlayEdit(data.id)"
           class="icon icon-edit cursor-pointer"></i> <ng-container *ngIf="!((streamService?.stream$ | async)?.media?.status?.stream === STREAM_STATUS.FINISHED && !(streamService?.stream$ | async)?.media?.hasRecording) && !(streamService.stream$ | async)?.media?.deactivated">|</ng-container>
    </b>
    <b class="text-primary">
        <i *ngIf="(!((streamService.stream$ | async)?.media?.status?.stream === STREAM_STATUS.FINISHED && !(streamService?.stream$ | async)?.media?.hasRecording) && !(streamService.stream$ | async)?.media?.deactivated) || !((streamService.stream$ | async)?.media?.status?.stream === STREAM_STATUS.PENDING)"
        (click)="delete(data, $event)" class="icon icon-trash text-danger cursor-pointer"></i>
    </b>
</ng-template>
<ng-template #imageTmpl let-data="rowData">
    <img *ngIf="data.picture" style="max-height: 50px; max-width: 80px"
         [src]="data.picture && (environment.backendApiHost + data.picture.photoUrl)"/>
</ng-template>
<ng-template #durationTmpl let-data="rowData">
    <span>{{ data.showDuration | durationConvert: 'seconds' }}</span>
</ng-template>
<ng-template #startTimeTmpl let-data="rowData">
    <span>{{ data.startTime === null ? ('survey.field.manualStart' | localize) : data.startTime | durationConvert: 'seconds' }}</span>
</ng-template>
<ng-template #statusTmpl let-data="rowData" class="ss-test">
    <div class="long-text">
        <app-toggle
                [disabled]="(streamService.stream$ | async)?.media?.deactivated"
                [value]="data.active"
                [config]="formConfig"
                (toggleEmit)="onToggle($event, data)"
        ></app-toggle>
    </div>
</ng-template>

<ng-template #confirm let-dialogRef="dialogRef">
    <ano-modal [dialogRef]="dialogRef" [title]="overlaysType + 's.deleteModalTitle' | localize" [isConfirmation]="true">
        {{confirmText}}
    </ano-modal>
</ng-template>
