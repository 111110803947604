import {Component, OnInit} from '@angular/core';
import {
  IFormConfig,
  IMultimediaHeaders,
  IPreviewPicture,
  StreamService
} from '@src/app/services/stream-metadata/stream.service';
import {filter, switchMap, takeUntil, tap} from 'rxjs/operators';
import {ActivatedRoute, Params} from '@angular/router';
import {NEW_ID_PARAM} from '@src/app/utils/custom-validators.util';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import {LoadingService} from '@src/app/services/loading/loading.service';
import {environment} from '@src/environments/environment';
import {MEDIA_TYPE} from '@src/app/components/streamdust-player/constants/mediaType';
import {BehaviorSubject} from 'rxjs';
import {STREAM_STATUS} from '@src/app/components/streamdust-player/constants/status';

@Component({
  selector: 'app-stream-thumbnail-fallback',
  templateUrl: './stream-thumbnail-fallback.component.html',
  styleUrls: ['./stream-thumbnail-fallback.component.scss']
})
export class StreamThumbnailFallbackComponent implements OnInit {
  public STREAM_STATUS = STREAM_STATUS;
  public environment = environment;
  public mediaType: MEDIA_TYPE;
  textHeaders: IMultimediaHeaders = {
    mainHeader: 'stream.thumbnail.fallback.title',
    description: 'stream.thumbnail.fallback.description'
  };

  pictureWasUpdated = false;

  streamId: string;
  photoUrl = '';

  formsConfig: IFormConfig[];

  picture: IPreviewPicture = {
    workbenchId: '',
    previewSettings: {
      x: 0,
      y: 0,
      width: 0,
      height: 0,
      rotation: 0,
    }
  };

  constructor(
      public streamService: StreamService,
      private readonly activatedRoute: ActivatedRoute,
      private readonly destroy$: AutoDestroyService,
      private loadingService: LoadingService,
  ) {
  }

  ngOnInit(): void {
    this.formsConfig = this.streamService.getStreamThumbnailFormsConfig();

    this.activatedRoute.parent.params.pipe(
        takeUntil(this.destroy$),
        tap(({id}: Params) => {
          this.streamId = id;
          this.mediaType = this.resolveMediaType();
        }),
        filter(({id}: Params) => id && id !== NEW_ID_PARAM),
        switchMap(({id}: Params) => {
          this.loadingService.loadingStart();
          return this.streamService.getPreviewPublisher(id, 'STREAM_FALLBACK');
        }))
        .subscribe((res) => {
          this.loadingService.loadingEnd();
          if (!res) {
            return;
          }
          this.photoUrl = res.photoUrl;
          const preview = this.formsConfig.find(form => form.name === 'preview');
          if (!preview) {
            return;
          }
          preview.payload.photoId = res.photoId;
        });
  }

  private resolveMediaType(): MEDIA_TYPE {
    return (this.activatedRoute.data as BehaviorSubject<any>)?.value?.mediaType;
  }


  updatePicture(newPicture): void {
    this.pictureWasUpdated = true;
    this.picture = newPicture;
  }

  uploadPicture(): void {
    if (!this.pictureWasUpdated) {
      return;
    }

    let body;
    if (this.picture && this.picture.workbenchId && this.picture.previewSettings) {
      body = {
        newPicture: {
          workbenchId: this.picture.workbenchId,
          previewSettings: this.picture.previewSettings
        }
      };
    } else {
      body = {
        photoId: ''
      };
    }

    this.loadingService.loadingStart();

    this.streamService.uploadPreview(this.streamId, body, 'STREAM_FALLBACK')
        .pipe(takeUntil(this.destroy$))
        .subscribe((res: any) => {
          if (res && res.results && res.results.preview && res.results.preview.photoUrl) {
            this.photoUrl = res.results.preview.photoUrl;
          } else {
            this.photoUrl = '';
          }
          this.pictureWasUpdated = false;
          this.loadingService.loadingEnd();
        });
  }

  clearPicture(clearPicture): void {
    this.pictureWasUpdated = true;
    this.picture = clearPicture;
  }

  wrapMediaUrl(mediaUrl): string {
    return this.environment.backendApiHost + mediaUrl;
  }
}
