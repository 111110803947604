import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {IMediaItem} from '@src/app/models/core.model';
import {API_URLS_APP} from '@src/app/constants/api-urls.constant';
import {ApiService} from 'ui-elements';
import {IPagedResponse} from "@src/app/models/response.model";

@Injectable({
    providedIn: 'root'
})
export class LandingService {

    constructor(
        private apiService: ApiService
    ) {
    }

    public getActualStreams(filters): Observable<IPagedResponse<IMediaItem>> {
        return this.apiService.post(API_URLS_APP.STREAM_ACTUAL_STREAMS, filters, {
            openApi: true,
            isWithoutRootUrl: true
        });
    }
}
