import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {INPUT_TYPES} from 'ui-elements';
import {IFormConfig} from '@src/app/services/stream-metadata/stream.service';
import {PresentationService} from '@src/app/services/file/presentation.service';
import {takeUntil} from 'rxjs/operators';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';

@Component({
    selector: 'app-toggle',
    templateUrl: './toggle.component.html',
    styleUrls: ['./toggle.component.css'],
    providers: [AutoDestroyService]
})
export class ToggleComponent implements OnInit, OnChanges {
    public form: FormGroup = this.fb.group({
        toggle: [false]
    });
    public INPUT_TYPES = INPUT_TYPES;
    @Input() value: boolean;
    @Input() config: IFormConfig;
    @Input() disabled: boolean;
    @Output() toggleEmit = new EventEmitter();

    constructor(
        private fb: FormBuilder,
        private presentationService: PresentationService,
        private destroy$: AutoDestroyService
    ) {
    }

    ngOnInit(): void {
        this.presentationService.toggleTableStatus$
            .pipe(takeUntil(this.destroy$))
            .subscribe(res => {
                this.toggle(res.active, false);
            });
    }

    ngOnChanges(): void {
        this.form.get('toggle').patchValue(this.value);
        if (this.config?.form) {
            this.config.form.markAsUntouched();
            this.config.form.updateValueAndValidity();
        }
        if (this.config?.forms) {
            this.config.forms.forEach(form => {
                form.markAsUntouched();
                form.updateValueAndValidity();
            });
        }
    }

    public toggle(val: any, isEmit = true): void {
        this.form.get('toggle').patchValue(val);

        if (this.config) {
            this.config.enabled = val;

        }
        if (isEmit) {
            this.toggleEmit.emit({form: this.config?.name, value: val});
        }
    }

}
