<div class="modal-body">
    <div class="panel panel-md mb-30">
        <div class="subscription-tariffs-comparison-modal-panel-header panel-header text-center">
            <h2 class="modal-main-title"><b>{{'landing-page.streamdust-subscription.title' | localize}}</b></h2>
            <app-period-toggle [hideHeader]="true"
                               (planPeriodChange)="changeTariffPeriod($event)"></app-period-toggle>
        </div>
        <div class="card-body pl-0 pr-0">
            <div class="table-responsive table-fixed-header tariff-table">

                <ng-container [ngSwitch]="ApiService.localLanguage">
                    <app-subscription-tariffs-table-en *ngSwitchCase="'EN'"
                                                       [isShowFirstSection]="isShowFirstSection"
                                                       [userProfile]="userProfile"
                                                       [displayOurOffers]="displayOurOffers"
                                                       [displayTopPossibleCombinations]="displayTopPossibleCombinations"
                                                       [displayMarketingTools]="displayMarketingTools"
                                                       [displayEmbeddings]="displayEmbeddings"
                                                       [displayOtherFeatures]="displayOtherFeatures"
                                                       [monthlyChargesPrices]="monthlyChargesPrices"
                                                       [monthlyChargesPricesToShow]="monthlyChargesPricesToShow"
                                                       [streamProcessingPrices]="streamProcessingPrices"
                                                       [feeAndTransactionPrices]="feeAndTransactionPrices"
                                                       [payPerViewPrices]="payPerViewPrices"
                                                       [transactionPrices]="transactionPrices"
                                                       [currentTariffPeriod]="currentTariffPeriod"
                                                       [TARIFF_PLAN_PERIOD]="TARIFF_PLAN_PERIOD"
                                                       [currencyForm]="currencyForm"
                                                       [currencySelectOptions]="currencySelectOptions"
                                                       [INPUT_TYPES]="INPUT_TYPES"
                                                       [selectedCurrency]="selectedCurrency">
                    </app-subscription-tariffs-table-en>
                    <app-subscription-tariffs-table-de *ngSwitchCase="'DE'"
                                                       [isShowFirstSection]="isShowFirstSection"
                                                       [userProfile]="userProfile"
                                                       [displayOurOffers]="displayOurOffers"
                                                       [displayTopPossibleCombinations]="displayTopPossibleCombinations"
                                                       [displayMarketingTools]="displayMarketingTools"
                                                       [displayEmbeddings]="displayEmbeddings"
                                                       [displayOtherFeatures]="displayOtherFeatures"
                                                       [monthlyChargesPrices]="monthlyChargesPrices"
                                                       [monthlyChargesPricesToShow]="monthlyChargesPricesToShow"
                                                       [streamProcessingPrices]="streamProcessingPrices"
                                                       [feeAndTransactionPrices]="feeAndTransactionPrices"
                                                       [payPerViewPrices]="payPerViewPrices"
                                                       [transactionPrices]="transactionPrices"
                                                       [currentTariffPeriod]="currentTariffPeriod"
                                                       [TARIFF_PLAN_PERIOD]="TARIFF_PLAN_PERIOD"
                                                       [currencyForm]="currencyForm"
                                                       [currencySelectOptions]="currencySelectOptions"
                                                       [INPUT_TYPES]="INPUT_TYPES"
                                                       [selectedCurrency]="selectedCurrency"
                    ></app-subscription-tariffs-table-de>
                </ng-container>
            </div>
        </div>
    </div>

</div>
