<div class="payment-overview-box">
    <div class="payment-overview-header">
        <h3 class="title">{{title}}</h3>
    </div>
    <div class="payment-overview-body">
        <div class="row">
            <div class="col-4">
                <span>{{'payment-details.checkout-details.description' | localize}}</span>
            </div>
            <div class="col-8">
                <p *ngIf="details" class="text-dark mb-25">{{details | localize}}</p>
                <ul *ngIf="_tariffPlan?.benefits?.length" class="plan-list mb-30">
                    <li *ngFor="let planBenefit of _tariffPlan?.benefits" [innerHTML]="planBenefit"></li>
                </ul>
            </div>
        </div>

        <div *ngIf="expiryDate" class="row">
            <div class="col-4">
                <span>{{'payment-details.checkout-details.expiration-date' | localize}}</span>
            </div>
            <div class="col-8">
                <p>{{expiryDate}}</p>
            </div>
        </div>
    </div>
</div>
