import {Injectable} from '@angular/core';
import {ApiService} from 'ui-elements';
import {Observable} from 'rxjs';
import {IResponse} from '@src/app/models/response.model';
import {API_URLS_APP} from '@src/app/constants/api-urls.constant';
import {AuthService} from '@src/app/services/auth/auth.service';
import {tap} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ChangePasswordPageService {

    constructor(private apiService: ApiService,
                private readonly authService: AuthService) {
    }

    public forgotPassword(password: string): Observable<IResponse> {
        return this.apiService.post<IResponse>(API_URLS_APP.CHANGE_PASSWORD_FORGET, {password});
    }

    public changePassword(oldPassword: string, newPassword: string): Observable<IResponse<{ authToken: string }>> {
        return this.apiService.post(API_URLS_APP.CHANGE_PASSWORD, {oldPassword, newPassword})
            .pipe(
                // filter((response) => response.success),

                tap((response) => response.success && this.authService.storeToken(response.results.authToken))
            );
    }

    public confirmPasswordForgotten(token: string): Observable<IResponse<{ authToken: string }>> {
        return this.apiService.post<IResponse>(API_URLS_APP.PASSWORD_FORGOTTEN_CONFIRMATION, {token});
    }
}
