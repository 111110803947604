import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'includes'
})
export class IncludesPipe implements PipeTransform {

    transform(value: string, arr: string[]): boolean {
        return arr.includes(value);
    }

}
