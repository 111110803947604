<div class="card bg-white">
    <app-group-table *ngIf="groupsList.length" #groupTableComponent
                     [title]="'coupons.groups.title'"
                     [tableConfig]="couponsGroupsTableConfig"
                     (edit$)="openEditGroupModal($event)"
                     (deactivateGroup$)="deactivateGroup($event)"
    ></app-group-table>
</div>

<div class="card bg-white">
    <ano-loader [isOpen]="loadingService.loading | async"></ano-loader>
    <div class="row justify-content-between align-items-end">
        <div class="col-xl-8">
            <div class="card-body">
                <div class="title-box">
                    <h2 class="title-level-3">{{'coupons.groups' | localize}}</h2>
                </div>
                <app-bordered-form
                        [form]="form"
                        [fieldsConfig]="formConfig"
                        [defaultForm]="true"
                        [disabled]="(streamService.stream$ | async)?.media?.status?.stream === STREAM_STATUS.FINISHED && !(streamService.stream$ | async)?.media?.hasRecording || (streamService.stream$ | async)?.media?.status?.stream === STREAM_STATUS.PENDING || (streamService.stream$ | async)?.media?.deactivated"
                ></app-bordered-form>
                <ano-button *ngIf="!((streamService.stream$ | async)?.media?.status?.stream === STREAM_STATUS.FINISHED && !(streamService.stream$ | async)?.media?.hasRecording) && (streamService.stream$ | async)?.media?.status?.stream !== STREAM_STATUS.PENDING && !(streamService.stream$ | async)?.media?.deactivated"
                            class="app-button-start" (click)="createGroup()"
                            [config]="{
         					 styleType: 'success',
          					sizeType: 'block'
        					}">{{'coupons.create.button' | localize}}</ano-button>
            </div>
        </div>
    </div>
</div>
<div class="card bg-white card-livestreams" *ngIf="groupsList.length">
    <div class="card-header no-padding-bottom">
        <h3 class="page-title title-level-3"><b>{{'coupons.manage.title' | localize}}</b></h3>
    </div>

    <div class="card-body">
        <ano-table #tableComponent [tableConfig]="tableConfig"
                   [withSearch]="true" [defaultSortField]="'CODE'" [sortOrder]="1"
                   (search$)="search($event)"
                   [buttonsConfig]="[tableButtonConfig]"
        >
            <ng-container role="table-header">
                <tr>
                    <th>
                        <div class="row row-padding-0 align-items-center">
                            <ng-container *ngIf="groupsList.length">
                                <div class="col mr-auto text-left">
                                    <span>{{'coupons.group.title' | localize}} {{getCurrentFilter()}}</span>
                                </div>
                                <div class="col-auto" *ngIf="groupsList?.length > 1">
                                    <div class="dropdown">
                                        <button class="btn btn-primary btn-dropdown-rectangle btn-sm"
                                                (click)="toggleFilter()">
                                            {{getCurrentFilter() || ('coupons.group.filter' | localize)}}
                                        </button>
                                        <div class="dropdown-menu" [class.open]="dropdownOpened">
                                            <!--<div class="dropdown-menu open">-->
                                            <ng-container *ngFor="let group of groupsList">
                                                <a class="dropdown-item" [class.active]="group.selected"
                                                   (click)="filter(group)">{{group.name}}</a>
                                            </ng-container>
                                            <!--										<a class="dropdown-item" href="#">Sortieren nach3</a>-->
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </th>
                </tr>
            </ng-container>
        </ano-table>

        <ng-template #modalAddNew let-dialogRef="dialogRef">
            <ano-modal [form]="addCouponsForm" [dialogRef]="dialogRef" [title]="'Gutscheine erstellen'">
                <app-default-form [form]="addCouponsForm" [fieldsConfig]="couponsFieldsConfig" [fullWidth]="true"
                                  [withoutSubmit]="true"></app-default-form>
            </ano-modal>
        </ng-template>
    </div>
</div>

<ng-template #vouchersAmountTmpl let-data="rowData">
    {{data.vouchersAmount }} / {{ data.usedVouchersAmount }}
</ng-template>
<ng-template #dateTmpl let-data="rowData">
    {{getDate(data.expiryDate) }}
</ng-template>
<ng-template #disposableTmpl let-data="rowData">
    {{getDisposable(data.disposable) }}
</ng-template>
<ng-template #discountTmpl let-data="rowData">
    {{ data.type === DISCOUNT_TYPE.FREE ? 'Free' : (data.type === DISCOUNT_TYPE.FIXED_PRICE ? currency + data.value : data.value + ' %') }}
</ng-template>

<ng-template #modalEdit let-dialogRef="dialogRef">
    <ano-modal [form]="editForm" [dialogRef]="dialogRef" [title]="title">
        <app-bordered-form
                [defaultForm]="true" [form]="editForm" [fieldsConfig]="editGroupFormConfig"></app-bordered-form>
    </ano-modal>
</ng-template>

<ng-template #actionsTmpl let-data="rowData">
    <span *ngIf="data.status === VOUCHER_STATUS.ACTIVE && !((streamService.stream$ | async)?.media?.status?.stream === STREAM_STATUS.FINISHED && !(streamService.stream$ | async)?.media?.hasRecording) && !(streamService.stream$ | async)?.media?.deactivated" class="text-danger cursor-pointer"
          (click)="deactivateCoupon(data.code)">{{'common.button.deactivate' | localize}}</span>
</ng-template>

<ng-template #statusTmpl let-data="rowData">
		<span [ngStyle]="{color: (data.status | itemSetting: {
            settingType: SETTING_TYPE.COLOR,
            itemType: ITEM_TYPE.VOUCHER
        })}">
            {{(data.status | itemSetting: {
            settingType: SETTING_TYPE.TRANSLATE,
            itemType: ITEM_TYPE.VOUCHER
        }) | localize }}
		</span>
</ng-template>

<ng-template #voucherGroupStatusTmpl let-data="rowData">
		<span [ngStyle]="{color: data.active ? 'green' : '#FC4349'}">
			{{(data.active ? 'coupons.status.active' : 'coupons.status.inactive') | localize}}
		</span>
</ng-template>

<ng-template #confirm let-dialogRef="dialogRef">
    <ano-modal [dialogRef]="dialogRef" [title]="deactivateConfirmTitle | localize" [isConfirmation]="true">
        {{deactivateConfirmBody | localize}}
    </ano-modal>
</ng-template>
