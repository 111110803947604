/**
 * Created by Тима on 07.04.2020.
 */
import {Injectable} from '@angular/core';
import {ApiService, IFormConfig, IFormFieldsConfig, IUnitKeyValue} from 'ui-elements';
import {API_URLS_APP} from '@src/app/constants/api-urls.constant';
import {Observable} from 'rxjs';
import {IResponse} from '@src/app/models/response.model';
import {map, pluck} from 'rxjs/internal/operators';
import {ICroppingSettings, IPublisherPayload} from '@src/app/models/stream.model';
import {ICroppingParams} from '@src/app/components/photo-cropper/photo-cropper.component';
import {CmsService, INPUT_TYPES} from 'ui-elements';
import {FormUtil} from '@src/app/utils/form.util';
import {USER_TYPE} from '@src/app/constants/user-type.constant';
import {IPicture} from '@src/app/models/core.model';
import {SUB_ACCOUNT_TYPE} from '@src/app/pages/subaccounts-page/model/sub-account.model';
import {IFieldsConfig} from '@src/app/pages/user-profile/interfaces/interfaces-common';
import {IStorageAndBandwidth} from '@src/app/services/storage-and-bandwidth/storage-and-bandwidth.service';

@Injectable({providedIn: 'root'})
export class ProfileService {
    constructor(
        private apiService: ApiService,
        private cmsService: CmsService
    ) {

    }

    public getUserProfile(): Observable<IUserProfile> {
        return this.apiService.get(API_URLS_APP.USER_PROFILE).pipe(
            pluck('results', 'profile'),
            map(profile => {
                return profile;
            })
        );
    }

    public saveProfile(newProfile: IUserProfile): Observable<IResponse> {
        return this.apiService.post(API_URLS_APP.USER_PROFILE, newProfile)
            .pipe(
                pluck('results')
            );
    }

    // public getCompany(): Observable<ICompanyInfo> {
    //     return this.apiService.get(API_URLS_APP.USER_COMPANY)
    //         .pipe(
    //             pluck('results', 'data')
    //         );
    // }

    public saveCompany(company: ICompanyInfo): Observable<IResponse> {
        return this.apiService.post(API_URLS_APP.USER_COMPANY, company)
            .pipe(
                pluck('results')
            );
    }

    public getUserPublisher(): Observable<IUserPublisher> {
        return this.apiService.get(API_URLS_APP.USER_PUBLISHER).pipe(
            pluck('results', 'data')
        );
    }

    public saveUserPublisher(newPublisher: IPublisherPayload): Observable<IResponse> {
        return this.apiService.post(API_URLS_APP.USER_PUBLISHER, newPublisher)
            .pipe(
                pluck('results')
            );
    }

    public saveContactInfo(body: IContactInfo): Observable<IResponse> {
        return this.apiService.post(API_URLS_APP.CONTACTS, body);
    }

    public getContacts(): Observable<IContactInfo> {
        return this.apiService.get(API_URLS_APP.CONTACTS)
            .pipe(pluck('results', 'data'));
    }

    public savePublisherHeader(body: IHeaderPictureMetadata): Observable<IResponse> {
        return this.apiService.post(API_URLS_APP.PUBLISHER_HEADER, body);
    }

    public getPublisherHeader(): Observable<IPicture> {
        return this.apiService.get(API_URLS_APP.PUBLISHER_HEADER)
            .pipe(pluck('results', 'data'));
    }

    public getFieldsConfig(): IFieldsConfig[] {
        return [
            {
                name: 'firstName',
                config: {
                    inputType: INPUT_TYPES.INPUT,
                    label: 'profile.firstName',
                    placeholder: '',
                    isRequired: true
                }
            },
            {
                name: 'lastName',
                config: {
                    inputType: INPUT_TYPES.INPUT,
                    label: 'profile.lastName',
                    placeholder: '',
                    isRequired: true
                }
            },
            {
                name: 'chatName',
                config: {
                    inputType: INPUT_TYPES.INPUT,
                    label: 'profile.chatName',
                    placeholder: '',
                }
            },
            {
                name: 'street',
                config: {
                    inputType: INPUT_TYPES.INPUT,
                    label: 'profile.streetAndHouse',
                    placeholder: '',
                    isRequired: true
                }
            },
            {
                name: 'postalCode',
                config: {
                    inputType: INPUT_TYPES.INPUT,
                    label: 'profile.zip',
                    placeholder: '',
                    isRequired: true
                }
            },
            {
                name: 'city',
                config: {
                    inputType: INPUT_TYPES.INPUT,
                    label: 'profile.city',
                    placeholder: '',
                    isRequired: true
                }
            },
            {
                name: 'country',
                config: {
                    inputType: INPUT_TYPES.SELECT,
                    label: 'profile.country',
                    isAutocomplete: true,
                    // placeholder: '',
                    selectOptions: this.getCountries(),
                    isRequired: true,
                    disabled: true,
                }
            },
            {
                name: 'phone',
                config: {
                    inputType: INPUT_TYPES.PHONE,
                    type: 'tel',
                    label: 'profile.contact.phone',
                    placeholder: '',
                    isRequired: true,
                    disabled: true,
                }
            },
            {
                name: 'email',
                config: {
                    inputType: INPUT_TYPES.INPUT,
                    type: 'tel',
                    label: 'profile.contact.email',
                    placeholder: '',
                    isRequired: true,
                    disabled: true,
                }
            },
        ];
    }

    public getPublisherFieldsConfig(): IFieldsConfig[] {
        return [
            {
                name: 'name',
                config: {
                    inputType: INPUT_TYPES.INPUT,
                    label: 'profile.publisher.name',
                    placeholder: '',
                    isRequired: true
                }
            },
            {
                name: 'description',
                config: {
                    inputType: INPUT_TYPES.TEXTAREA,
                    label: 'profile.publisher.description',
                    placeholder: '',
                    isRequired: true
                }
            },
            {
                name: 'website',
                config: {
                    inputType: INPUT_TYPES.TEXTAREA,
                    label: 'profile.publisher.imprint',
                    placeholder: '',
                    isRequired: true
                }
            },
            {
                name: 'leadTextField1',
                config: {
                    inputType: INPUT_TYPES.TEXTAREA,
                    label: 'profile.publisher.leadTextField1',
                    placeholder: '',
                    isRequired: true
                }
            },
            {
                name: 'leadTextField2',
                config: {
                    inputType: INPUT_TYPES.TEXTAREA,
                    label: 'profile.publisher.leadTextField2',
                    placeholder: '',
                    isRequired: true
                }
            },
            {
                name: 'customFieldOn',
                config: {
                    inputType: INPUT_TYPES.CHECKBOX,
                    label: 'profile.publisher.customFieldOn',
                    placeholder: '',
                    isRequired: false
                }
            },
            {
                name: 'customFieldName',
                config: {
                    inputType: INPUT_TYPES.TEXTAREA,
                    label: 'profile.publisher.customFieldName',
                    placeholder: '',
                    isRequired: false
                }
            },
            {
                name: 'newPicture',
                config: {
                    inputType: INPUT_TYPES.IMAGE,
                    accept: 'image/*',
                    label: 'profile.publisher.image',
                    placeholder: '',
                    isRequired: true
                }
            }
        ];
    }

    public getFirmFieldsConfig(): IFieldsConfig[] {
        return [
            {
                name: 'company',
                config: {
                    inputType: INPUT_TYPES.INPUT,
                    label: 'profile.company.name',
                    placeholder: '',
                    isRequired: false,
                    optional: true
                }
            },
            {
                name: 'vatId',
                config: {
                    inputType: INPUT_TYPES.INPUT,
                    label: 'profile.company.vatId',
                    placeholder: '',
                    isRequired: true
                }
            },
        ];
    }

    public getCompanyFieldsConfig(): IFieldsConfig[] {
        return [
            {
                name: 'company',
                config: {
                    inputType: INPUT_TYPES.INPUT,
                    label: 'Firmenname (optional)',
                    placeholder: '',
                    isRequired: false
                }
            },
            {
                name: 'vatId',
                config: {
                    inputType: INPUT_TYPES.INPUT,
                    label: 'Umsatzsteuer Nr. (optional)',
                    placeholder: '',
                    isRequired: false
                }
            }
        ];
    }

    public getContactsFieldsConfig(userType: USER_TYPE): IFieldsConfig[] {
        const config: IFieldsConfig[] = [
            {
                name: 'phone',
                config: {
                    inputType: INPUT_TYPES.PHONE,
                    type: 'tel',
                    label: 'profile.contact.phone',
                    placeholder: '',
                    isRequired: false
                }
            },
            {
                name: 'email',
                config: {
                    inputType: INPUT_TYPES.INPUT,
                    label: 'profile.contact.email',
                    placeholder: '',
                    isRequired: false
                }
            }
        ];
        if (userType === USER_TYPE.PUBLISHER || userType === USER_TYPE.SUB_ACCOUNT) {
            config.push({
                name: 'website',
                config: {
                    inputType: INPUT_TYPES.INPUT,
                    label: 'profile.contact.website',
                    placeholder: '',
                    isRequired: false
                }
            });
        }
        return config;
    }

    public getStreamHeaderForm(): IFormFieldsConfig[][] {
        return [
            [
                {
                    name: 'streamHeader',
                    control: [''],
                    config: {
                        inputType: INPUT_TYPES.IMAGE,
                        isRequired: true,
                        accept: 'image/*'
                    }
                },
            ]
        ];
    }

    public getStreamHeaderFormConfig(): IFormConfig {
        return {
            name: 'streamHeader',
            formFields: this.getStreamHeaderForm(),
            title: 'edit-user-data.stream-header.title',
            subtitle: 'edit-user-data.stream-header.subtitle',
            isDefault: true,
            form: FormUtil.buildForm(this.getStreamHeaderForm()),
            withoutTopPadding: true,
            payload: {}
        };
    }

    public getStreamHeaderFormsConfig(): IFormConfig[] {
        return [
            this.getStreamHeaderFormConfig()
        ];
    }

    public getCountries(): IUnitKeyValue[] {
        return this.cmsService.countriesList;
    }
}

export interface IUserProfile {
    accountType?: USER_TYPE;
    firstName: string;
    lastName: string;
    chatName?: string;
    street?: string;
    postalCode?: number;
    city?: string;
    country?: string;
    pictureId?: string;
    email?: string;
    ownerId?: string;
    userType?: USER_TYPE;
    newPicture?: {
        workbenchId: string;
        previewSettings: ICroppingParams;
    };
    profilePhoto?: {
        photoId: string;
        photoUrl: string;
    };
    address?: {
        street: string;
        postalCode: number;
        city: number;
        country: string;
        phone: string;
    };
    companyInfo?: ICompanyInfo;
    subscribed?: boolean;
    waitingApproval?: boolean;
    phone?: string;
    privatePhone?: string;
    hasConnectedPublishers?: boolean;
    subAccount?: {
        accepted: boolean;
        type: SUB_ACCOUNT_TYPE;
    };
    currency?: string;
    dataLimits?: IStorageAndBandwidth;
}

export interface IUserPublisher {
    name: string;
    description: string;
    website: string;
    leadTextField1?: string;
    leadTextField2?: string;
    customFieldOn?: boolean;
    customFieldName?: string;
    email?: string;
    phone?: string;
    telegram?: string;
    instagram: string;
    videoAmount?: number;
    picture: {
        photoId: string;
        photoUrl: string;
    };
    country?: string;
    headerPicture?: {
        photoId: string;
        photoUrl: string;
    };
    privacy?: string;
    impressum?: string;
    termsConditionUrl?: string;
    privacyPolicyUrl?: string;
    conditions?: string;
    subscribed: boolean;
}

export interface IContactInfo {
    phone: string;
    email: string;
    website?: string;
}

export interface ICompanyInfo {
    company: string;
    vatId: string;
}

export interface IHeaderPictureMetadata {
    pictureId?: string;
    newPicture?: ICroppingSettings;
}
