import {Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ITableConfig, ITableNoDataLink, TableComponent} from 'ui-elements';
import {MEDIA_TYPE} from '@src/app/components/streamdust-player/constants/mediaType';
import {
  ISurvey,
  MARKETING_ACTION,
  MARKETING_ENTITY_TYPE,
  SURVEY_STATUS,
  SurveyService
} from '@src/app/services/survey/survey.service';
import {IFormConfig, StreamService} from '@src/app/services/stream-metadata/stream.service';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {filter, take, takeUntil, tap} from 'rxjs/operators';
import {BehaviorSubject} from 'rxjs';
import {getFileNameFromResponseContentDisposition} from '@src/app/utils/file-name-from-content-disposition.util';
import {STREAM_STATUS} from '@src/app/components/streamdust-player/constants/status';
import {saveAs} from 'file-saver/FileSaver';
import {IStreamModel} from '@src/app/models/stream.model';
import {MarketingService} from '@src/app/services/marketing/marketing.service';
import {ISchedule, SchedulerService, TIME_POINT} from '@src/app/services/scheduler-service/scheduler.service';

@Component({
  selector: 'app-survey-table',
  templateUrl: './survey-table.component.html',
  styleUrls: ['./survey-table.component.scss'],
  providers: [AutoDestroyService, SchedulerService]
})
export class SurveyTableComponent implements OnInit, OnDestroy {
  @Input() hideSearch: boolean;
  @Input() hideAddButton: boolean;
  @Input() standAlone: boolean;
  @Input() noDataLink: ITableNoDataLink;
  @Input() itemsOnPage = 10;
  @ViewChild('tableComponent', {static: true}) public table: TableComponent;
  @ViewChild('confirm', {static: false}) confirm: TemplateRef<any>;
  @ViewChild('startTimeTmpl', {static: true}) public startTimeTmpl: TemplateRef<any>;
  @ViewChild('startStopTmpl', {static: true}) public startStopTmpl: TemplateRef<any>;
  @ViewChild('publishTmpl', {static: true}) public publishTmpl: TemplateRef<any>;
  @ViewChild('durationTmpl', {static: true}) public durationTmpl: TemplateRef<any>;
  @ViewChild('statusTmpl', {static: true}) public statusTmpl: TemplateRef<any>;
  @ViewChild('downloadTmpl', {static: true}) public downloadTmpl: TemplateRef<any>;
  @ViewChild('actionsTmpl', {static: true}) public actionsTmpl: TemplateRef<any>;
  public STREAM_STATUS = STREAM_STATUS;
  public SURVEY_STATUS = SURVEY_STATUS;
  public MEDIA_TYPE = MEDIA_TYPE;
  private mediaId: string;
  public mediaType: MEDIA_TYPE;
  public tableConfig: ITableConfig<ISurvey>;
  private searchQuery: string;
  private newSurveyUrl: string;
  public formConfig: IFormConfig;
  public data: ISurvey[];
  public stream: IStreamModel;
  public streamScheduledTime: number;

  constructor(
      private surveyService: SurveyService,
      private readonly destroy$: AutoDestroyService,
      private readonly activatedRoute: ActivatedRoute,
      public dialog: MatDialog,
      private router: Router,
      public streamService: StreamService,
      private marketingService: MarketingService,
      private schedulerService: SchedulerService,
  ) { }

  ngOnInit(): void {
    this.formConfig = this.streamService.getStreamFormConfig(false, false, MEDIA_TYPE.STREAM, true);
    this.activatedRoute.parent.params.pipe(
        takeUntil(this.destroy$),
        tap(({id}: Params) => {
          this.mediaId = id;
          this.mediaType = this.resolveMediaType();
          this.newSurveyUrl = this.resolveNewOverlayUrl();
          this.streamService.stream$.pipe(takeUntil(this.destroy$))
              .subscribe((stream) => {
                this.stream = stream;
                this.streamScheduledTime = this.resolveStreamScheduledTime();
                this.initConfig();
                setTimeout(() => this.listenStatusChange(), 1000);
              });

        }))
        .subscribe(() => {
        });
    this.streamService.streamStatus$.pipe(takeUntil(this.destroy$))
        .subscribe((status) => {
          if (!this.stream) {
            return;
          }
          if (!status?.stream) {
            return;
          }
          if (this.stream?.media?.status?.stream !== status?.stream) {
            this.surveyService.clearPublishedSurveys(this.mediaId);
          }
        });
  }

  private resolveStreamScheduledTime(): number {
    if (this.mediaType === MEDIA_TYPE.VIDEO_ON_DEMAND) {
      return null;
    }

    if ([STREAM_STATUS.FINISHED].includes(this.stream?.media?.status?.stream)) {
      return null;
    }

    return this.stream?.media?.startTimestamp;
  }

  private listenStatusChange(): void {
    this.marketingService.connectToSocket(this.mediaId, this.mediaType)
        .pipe(takeUntil(this.destroy$), filter(res => !!res))
        .subscribe(res => {
          if (!res) {
            return;
          }

          if (res.results.data.type !== MARKETING_ENTITY_TYPE.SURVEY) {
            return;
          }

          const surveyStatus = res.results.data;
          const survey = this.data.find((item) => item.id === surveyStatus.id);
          if (!survey) {
            return;
          }

          survey.status = surveyStatus.status;
          if (res.results.data.action === MARKETING_ACTION.SHOW) {
            survey.manualStarted = true;
            this.table.updateData(survey, (tableData => {
              return tableData.findIndex((item => item.id === survey.id))
            }));
          }
          if (res.results.data.action === MARKETING_ACTION.HIDE) {
            survey.manualStarted = false;
            this.table.updateData(survey, (tableData => {
              return tableData.findIndex((item => item.id === survey.id))
            }));
          }
        });
  }

  private resolveMediaType(): MEDIA_TYPE {
    return (this.activatedRoute.data as BehaviorSubject<any>)?.value?.mediaType;
  }

  private resolveNewOverlayUrl(): string {
    const mediaIdRelatedUrlSegment = this.mediaId + '/surveys/new';
    switch (this.mediaType) {
      case MEDIA_TYPE.STREAM:
      case MEDIA_TYPE.STREAM_RECORDING:
        return 'streams/' + mediaIdRelatedUrlSegment;
      case MEDIA_TYPE.VIDEO_ON_DEMAND:
        return 'vod/' + mediaIdRelatedUrlSegment;

    }
  }

  private resolveSurveyUrl(id: string): string {
    const mediaIdRelatedUrlSegment = this.mediaId + (this.standAlone ? '/info' : '') + '/surveys/' + id;
    switch (this.mediaType) {
      case MEDIA_TYPE.STREAM:
      case MEDIA_TYPE.STREAM_RECORDING:
        return 'streams/' + mediaIdRelatedUrlSegment;
      case MEDIA_TYPE.VIDEO_ON_DEMAND:
        return 'vod/' + mediaIdRelatedUrlSegment;

    }
  }

  private resolveSurveyInfoUrl(id: string): string {
    const mediaIdRelatedUrlSegment = this.mediaId + (this.standAlone ? '/info' : '') + '/surveys/' + id + '/info';
    switch (this.mediaType) {
      case MEDIA_TYPE.STREAM:
      case MEDIA_TYPE.STREAM_RECORDING:
        return 'streams/' + mediaIdRelatedUrlSegment;
      case MEDIA_TYPE.VIDEO_ON_DEMAND:
        return 'vod/' + mediaIdRelatedUrlSegment;

    }
  }

  private initConfig(): void {
    // TODO: fix navigation on row click
    this.tableConfig = {
      dataField: 'data',
      // navigationUrl: this.resolveNavigationUrl(),
      itemsOnPage: this.itemsOnPage,
      noDataLink: this.noDataLink,
      searchFn: (sortParams, pagingParams) => {
        return this.surveyService.getAll(this.mediaId, this.mediaType, this.searchQuery, sortParams, pagingParams)
            .pipe(tap((res) => {
              res.results.data.items.forEach((item) => {
                item.endTime = item.duration;
              });
              this.data = res.results.data.items;
              this.data.forEach((item) => {
                item.isManual = item.startTime === null;
                item.resultsPublished = this.surveyService.publishedSurveys(this.mediaId).findIndex(_item => _item.id === item.id) > -1 && this.stream?.media?.status?.stream !== STREAM_STATUS.FINISHED;
                if (item.isManual && !item.startedAt) {
                  item.notComplete = true;
                }

                if (this.mediaType === MEDIA_TYPE.STREAM) {
                  item.startTime = item.startTime - (new Date().getTimezoneOffset() * 60);
                }
              });
              this.schedule();
            }));
      },
      columns: [
        {
          name: 'survey.table.field.status',
          sortField: 'STATUS',
          tmpl: this.statusTmpl,
          width: 70
        },
        {
          name: 'survey.table.field.start',
          tmpl: this.startStopTmpl,
          width: 70,
          hide: this.stream?.media?.status?.stream === STREAM_STATUS.FINISHED || this.mediaType !== MEDIA_TYPE.STREAM
        },
        {
          name: 'survey.table.field.publish',
          tmpl: this.publishTmpl,
          width: 70,
          hide: this.stream?.media?.status?.stream === STREAM_STATUS.FINISHED
        }, {
          name: 'survey.table.field.name',
          sortField: 'NAME',
          dataField: 'name',
          width: 100
        }, {
          name: 'survey.field.startTime',
          sortField: 'START_TIME',
          tmpl: this.startTimeTmpl,
          class: 'left',
          width: 100
        }, {
          name: 'survey.table.field.duration',
          sortField: 'DURATION',
          tmpl: this.durationTmpl,
          class: 'left',
          width: 100
        }, {
          name: 'survey.table.field.views',
          sortField: 'VIEWS',
          dataField: 'views',
          class: 'right',
          width: 100
        },
        {
          name: 'survey.table.field.votes',
          sortField: 'VOTES',
          dataField: 'votes',
          class: 'right',
          width: 100
        },
        {
          name: 'survey.table.field.actions',
          tmpl: this.actionsTmpl,
          class: 'center',
          width: 100
        }
      ]
    };
  }

  private schedule(): void {
    if (!this.streamScheduledTime) {
      return;
    }

    this.schedulerService.stop();
    this.schedulerService.schedule(this.resolveSchedules(), true);
    this.listenSchedulersEvents();
    this.schedulerService.start(new Date().getTime());
  }

  public resolveSchedules(): ISchedule[] {
    return this.data.filter(survey => survey.startTime !== null && new Date().getTime() <= this.resolveSurveyEndTime(survey)).map(survey => {
      if (!survey.isManual && this.resolveSurveyEndTime(survey) > new Date().getTime()) {
        survey.notComplete = true;
      }
      return {
        entityId: survey.id,
        entity: survey,
        eventType: 'SURVEY',
        timePointStart: this.resolveSurveyStartTime(survey),
        timePointStop: this.resolveSurveyEndTime(survey)
      };
    });
  }

  private resolveSurveyStartTime(survey: ISurvey): number {
    if (this.streamScheduledTime) {
      return survey.isManual || survey.resultsPublished ? new Date().getTime() : this.streamScheduledTime + (survey.startTime * 1000);
    }

    return survey.startTime;
  }

  private resolveSurveyEndTime(survey: ISurvey): number {
    if (this?.streamScheduledTime) {
      return this.streamScheduledTime + ((survey.startTime + survey.endTime) * 1000);
    }
    return survey.endTime;
  }

  private listenSchedulersEvents(): void {
    this.schedulerService.events
        .pipe(takeUntil(this.destroy$))
        .subscribe((ev) => {
          if (!ev) {
            return;
          }
          switch (ev.timePoint) {
            case TIME_POINT.START:
              (ev.entity as ISurvey).inProgress = true;
              break;
            case TIME_POINT.STOP:
              (ev.entity as ISurvey).inProgress = false;
              (ev.entity as ISurvey).notComplete = false;
              if (!(ev.entity as ISurvey).isManual && this.resolveSurveyEndTime((ev.entity as ISurvey)) > new Date().getTime()) {
                (ev.entity as ISurvey).notComplete = true;
              }
              if ((ev.entity as ISurvey).isManual && !(ev.entity as ISurvey).startedAt) {
                (ev.entity as ISurvey).notComplete = true;
              }
              break;
          }
        });
  }

  private resolveNavigationUrl(): string {
    const mediaIdRelatedUrlSegment = this.mediaId + '/surveys/{id}/info';
    switch (this.mediaType) {
      case MEDIA_TYPE.STREAM:
      case MEDIA_TYPE.STREAM_RECORDING:
        return 'streams/' + mediaIdRelatedUrlSegment;
      case MEDIA_TYPE.VIDEO_ON_DEMAND:
        return 'vod/' + mediaIdRelatedUrlSegment;

    }
  }

  public search(searchTerm: string): void {

  }

  public goToNewSurvey(): void {
    this.router.navigate([this.newSurveyUrl]);

  }

  public navigateToSurvey(id: string): void {
    this.router.navigate([this.resolveSurveyUrl(id)]);
  }

  public navigateToSurveyInfo(id: string): void {
    this.router.navigate([this.resolveSurveyInfoUrl(id)]);
  }

  public deleteSurvey(survey: ISurvey, event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    const dialogRef = this.dialog.open(this.confirm);
    dialogRef.afterClosed()
        .pipe(takeUntil(this.destroy$))
        .subscribe((confirm: boolean) => {
          if (confirm) {
            this.surveyService.toggle(survey.id, !survey.active)
                .pipe(take(1))
                .subscribe(() => {
                  this.surveyService.delete(survey.id)
                      .pipe(takeUntil(this.destroy$)).subscribe((res) => {
                    if (res.success) {
                      this.table.resetTableData();
                      this.table.refreshData({});
                    }
                  });
                })
          }
        });
  }

  public download(event): void {
    event.stopPropagation();
    this.surveyService.downloadCsv(this.mediaType, this.mediaId)
        .pipe(takeUntil(this.destroy$))
        .subscribe(res => {
          saveAs(
              res.body,
              getFileNameFromResponseContentDisposition(res),
          );
        });
  }

  onToggle(event: any, object: ISurvey) {
    this.surveyService.toggle(object.id, event.value).pipe(takeUntil(this.destroy$)).subscribe(() => {
      // this.form.get('status').patchValue(this.localizeStatus(SURVEY_STATUS.ACTIVE));
    });
  }

  public goToNoDataUrl(url: string): void {
    this.router.navigate([url]);
  }

  public start(survey: ISurvey): void {
    this.surveyService.start(survey.id)
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.setSurveyManualStarted(survey, true);
        });
  }

  public stop(survey: ISurvey): void {
    this.surveyService.stop(survey.id)
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.setSurveyManualStarted(survey, false);
        });
  }

  private setSurveyManualStarted(survey: ISurvey, state: boolean): void {
    survey.manualStarted = state;
  }

  public publishResult(survey: ISurvey): void {
    this.surveyService.publishResults(survey.id, this.mediaId)
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          survey.resultsPublished = this.surveyService.publishedSurveys(this.mediaId).findIndex(item => item.id === survey.id) > -1;
          this.data.filter(item => item.id !== survey.id).forEach(item => item.resultsPublished = false);
        });
  }

  public ngOnDestroy() {
    this.schedulerService.stop();
  }

}
