<div class="content">
    <div class="panel panel-md">
        <div class="panel-header">
            <h2><b>{{'stream.statistic.title' | localize}}</b></h2>
        </div>
    </div>

    <div class="row row-padding-10">
        <div [class]="'col-lg-' + 12 / staticWidgetsConfigs.length" *ngFor="let config of staticWidgetsConfigs">
            <div class="widget-spinner-container">
                <ano-loader *ngIf="loading" [isOpen]="loading"></ano-loader>
            </div>
            <ano-widget [config]="config"></ano-widget>
        </div>
    </div>
    <div class="title-wrap">
        <span> </span>
        <ano-datepicker [showAllOption]="false" (changeDate)="timeRangeFilter($event)"></ano-datepicker>
    </div>

    <div class="row row-padding-10">
        <div class="col-lg-12" *ngFor="let config of widgetsConfigs">
            <div class="widget-spinner-container">
                <ano-loader *ngIf="loading" [isOpen]="loading"></ano-loader>
            </div>
            <ano-widget [config]="config"></ano-widget>
        </div>
    </div>

    <div class="card">
        <div class="card-header header-element-inline">
            <h2 class="title">{{'dashboard.viewersChart.title' | localize}}</h2>
        </div>
        <div class="card-body bg-white chart-container">
            <ano-loader *ngIf="loading" [isOpen]="loading"></ano-loader>
            <div class="row row-padding-10 mb-30">
                <div class="col-lg-12 col-md-12">
                    <ano-scatter-chart #chartComponent *ngIf="!loading && chartData" [data]="chartData"
                                       [width]="700"
                                       [categories]="data?.statistic?.category"
                                       [legend]="data?.legend"
                                       [colorScheme]="colorScheme"></ano-scatter-chart>
                </div>
            </div>
        </div>
    </div>

    <div class="card">
        <div class="card-header header-element-inline">
            <h2 class="title">{{'dashboard.worldmap.title' | localize}}</h2>
        </div>
        <div class="card-body map-container">
            <ano-loader *ngIf="loading" [isOpen]="loading"></ano-loader>
            <app-bubble-map [id]="1" [dataArray]="map" (onBubbleHover$)="onMapHover($event)"
            ></app-bubble-map>
            <p class="total-viewers">
                <span class="break-after">{{totalViewers | numberWithSeparator}}</span>
                <span class="text-xs break-after">{{'stream-world-map.viewers' | localize}}</span>
                <span class="text-xs">{{'stream-world-map.worldwide' | localize}}</span>
            </p>
            <p *ngIf="viewersInACountry && currentCountry" class="country-viewers">
                <span class="break-after">{{viewersInACountry | numberWithSeparator}}</span>
                <span class="text-xs break-after">{{'stream-world-map.viewers' | localize}}</span>
                <span class="text-xs">{{'stream-world-map.from' | localize}} {{currentCountry}}</span>
            </p>
        </div>
    </div>
</div>
