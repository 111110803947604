import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IUnitKeyValue} from 'ui-elements';

@Component({
    selector: 'app-answers',
    templateUrl: './answers.component.html',
    styleUrls: ['./answers.component.sass']
})
export class AnswersComponent implements OnInit {
    @Input() data: any;
    @Output() answer$: EventEmitter<any> = new EventEmitter<any>();
    public error: boolean;

    constructor() {
    }

    ngOnInit(): void {
    }

    public answer(): void {
        if (!this.data.selectOptions.find(item => item.selected)) {
          this.error = true;
          return;
        }
        this.error = false;
        this.answer$.emit();
    }

}
