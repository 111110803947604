import {
  AfterViewInit,
  Component,
  Inject,
  Input,
  OnInit,
  Optional, Self,
  ViewChild
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {ImageCroppedEvent, ImageCropperComponent} from 'ngx-image-cropper';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { PhotoCropperService } from '@src/app/services/photo-cropper/photo-cropper.service';
import { ApiService } from 'ui-elements';
import { API_URLS_APP, UrlGenerator } from '@src/app/constants/api-urls.constant';
import { AutoDestroyService } from '@src/app/services/auto-destroy-service/auto-destroy.service';
import { RouterEventHandlerService } from '@src/app/services/router-event-handler/router-event-handler.service';
import {LoadingService} from '@src/app/services/loading/loading.service';

@Component({
  selector: 'app-photo-cropper',
  templateUrl: './photo-cropper.component.html',
  styleUrls: ['./photo-cropper.component.sass'],
  providers: [
    AutoDestroyService,
  ],
})
export class PhotoCropperComponent implements AfterViewInit {

  @ViewChild('imageCropperComponent', { static: false }) private imageCropperComponent: ImageCropperComponent;
  private croppingParams: ICroppingParams;
  private croppedImage = '';

  public image: File | Blob;

  public constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public readonly data: IPhotoCroppingPopupData,
    @Optional() public readonly dialogRef: MatDialogRef<PhotoCropperComponent>,
    public readonly photoCropperService: PhotoCropperService,
    private readonly apiService: ApiService,
    @Self() private readonly destroy$: AutoDestroyService,
    private readonly routerEventHandlerService: RouterEventHandlerService,
    public loadingService: LoadingService,
  ) { }

  public ngAfterViewInit(): void {
    this.dialogRef.afterClosed()
      .subscribe(() => this.routerEventHandlerService.activatedRouteData.hiddenElements = {});
    this.routerEventHandlerService.activatedRouteData.hiddenElements = { footer: true };
    this.imageCropperComponent.imageCropped
      .pipe(
        map(PhotoCropperService.mapImageCroppedEventData),
        takeUntil(this.destroy$),
      )
      .subscribe((croppingParams: ICroppingParams) => {
        this.croppingParams = croppingParams;
      });

    this.photoCropperService.workbenchId$
      .pipe(
        switchMap(workbenchId =>
          this.apiService.get(
            UrlGenerator.generate(API_URLS_APP.GET_WORKBENCH, { workbenchId }),
            { isWithoutRootUrl: true, responseType: 'blob' },
          )
        ),
        takeUntil(this.destroy$),
      )
      .subscribe((res: Blob) => this.image = res);
  }

  public confirm(): void {
    this.dialogRef.close({ croppingParams: this.croppingParams, croppedImage: this.croppedImage });
  }

  public reject(): void {
    this.dialogRef.close();
  }

  public rotateLeft(): Promise<void> {
    return this.imageCropperComponent.rotateLeft();
  }

  public rotateRight(): Promise<void> {
    return this.imageCropperComponent.rotateRight();
  }

  public cropped(event: ImageCroppedEvent): void {
    this.croppedImage = event.base64;
  }
}

export interface ICroppingParams {
  x: number;
  y: number;
  width: number;
  height: number;
  rotation: number;
}

export interface IPhotoCroppingPopupResult {
  croppingParams: ICroppingParams;
}

export interface IPhotoCroppingPopupData {
  file: File;
  isRoundCropper?: boolean;
  aspectRatio?: number;
}
