import {Pipe, PipeTransform} from '@angular/core';
import {LocalizationProvider} from 'ui-elements';

@Pipe({
    name: 'localize',
})

export class LocalizationPipe implements PipeTransform {

    public constructor(
        private readonly localizationProvider: LocalizationProvider) {
    }

    public transform(key: string, element?: any): string {
        if (element) {
            if (element.parentElement) {
                element = element.parentElement;
            }
            element.setAttribute('local-key', key);
        }
        return this.localizationProvider.getByKey(key);
    }
}
