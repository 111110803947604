<ng-container *ngIf="message.type === MESSAGE_TYPE.PLAIN">
    <div class="message-item" [class.outgoing-message]="message.owner.current">
        <div class="message-avatar embedded-avatar" *ngIf="!message.owner.current || isEmbedded">
            <figure class="avatar">
                <img [src]="getAvatar()" class="rounded-circle" alt="image">
            </figure>
            <div>
                <h5>{{message.owner.name}}</h5>
<!--                TODO revert after release-->
<!--                <h5 class="message-name-box">-->
<!--                    <span *ngIf="message.owner.conversationOwner" class="message-admin">{{'chat.message.admin.title' | localize}}</span>-->
<!--                    <span class="message-name">{{message.owner.name}}</span>-->
<!--                </h5>-->
                <div class="time">{{formatDayAndTime(message.created)}}</div>
            </div>
            <i *ngIf="isOwner && isEmbedded" (click)="toggleRemove($event)" class="icon-trash text-danger cursor-pointer message-remove-button embedded-remove-button"></i>
            <!--                TODO revert after release-->
<!--            <i *ngIf="isOwner && isEmbedded"-->
<!--               class="icon-pin cursor-pointer message-pin-button message-pin-button&#45;&#45;embedded"-->
<!--               [class.message-pin-button&#45;&#45;active]="message.isPinned"-->
<!--               (click)="pinMessage($event)"-->
<!--            ></i>-->
        </div>
        <div class="message-content">
            <i *ngIf="isOwner && !isEmbedded" (click)="toggleRemove($event)" class="icon-trash text-danger cursor-pointer message-remove-button"></i>
            <!--                TODO revert after release-->
<!--            <i *ngIf="isOwner && !isEmbedded"-->
<!--               class="icon-pin cursor-pointer message-pin-button"-->
<!--               [class.message-pin-button&#45;&#45;active]="message.isPinned"-->
<!--               (click)="pinMessage($event)"-->
<!--            ></i>-->
            <p class="message-line" *ngFor="let messageLine of messageContent">{{ messageLine }}</p>
        </div>
        <div *ngIf="message.owner.current && !isEmbedded" class="outgoing-time">{{formatDayAndTime(message.created)}}</div>
    </div>
</ng-container>
<!--TODO revert after release-->
<!--<ng-container *ngIf="message.type === MESSAGE_TYPE.FILE">-->
<!--    <div class="message-item" [class.outgoing-message]="message.owner.current">-->
<!--        <div class="message-avatar embedded-avatar" *ngIf="!message.owner.current || isEmbedded">-->
<!--            <figure class="avatar">-->
<!--                <img [src]="getAvatar()" class="rounded-circle" alt="image">-->
<!--            </figure>-->
<!--            <div>-->
<!--                <h5 class="message-name-box">-->
<!--                    <span *ngIf="message.owner.conversationOwner" class="message-admin">{{'chat.message.admin.title' | localize}}</span>-->
<!--                    <span class="message-name">{{message.owner.name}}</span>-->
<!--                </h5>-->
<!--                <div class="time">{{formatDayAndTime(message.created)}}</div>-->
<!--            </div>-->
<!--            <i *ngIf="isOwner && isEmbedded" (click)="toggleRemove($event)" class="icon-trash text-danger cursor-pointer message-remove-button embedded-remove-button"></i>-->
<!--            <i *ngIf="isOwner && isEmbedded"-->
<!--               class="icon-pin cursor-pointer message-pin-button message-pin-button&#45;&#45;embedded"-->
<!--               [class.message-pin-button&#45;&#45;active]="message.isPinned"-->
<!--               (click)="pinMessage($event)"-->
<!--            ></i>-->
<!--        </div>-->
<!--        <div *ngIf="!isImageFile" class="message-content">-->
<!--            <i *ngIf="isOwner && !isEmbedded" (click)="toggleRemove($event)" class="icon-trash text-danger cursor-pointer message-remove-button"></i>-->
<!--            <i *ngIf="isOwner && !isEmbedded"-->
<!--               class="icon-pin cursor-pointer message-pin-button"-->
<!--               [class.message-pin-button&#45;&#45;active]="message.isPinned"-->
<!--               (click)="pinMessage($event)"-->
<!--            ></i>-->
<!--            <a [href]="messageLine" download target="_blank" class="message-line" *ngFor="let messageLine of messageContent">{{ fileName }} <i class="icon icon-download"></i></a>-->
<!--        </div>-->

<!--        <div *ngIf="isImageFile" class="message-image">-->
<!--            <i *ngIf="isOwner && !isEmbedded" (click)="toggleRemove($event)" class="icon-trash text-danger cursor-pointer message-remove-button"></i>-->
<!--            <i *ngIf="isOwner && !isEmbedded"-->
<!--               class="icon-pin cursor-pointer message-pin-button"-->
<!--               [class.message-pin-button&#45;&#45;active]="message.isPinned"-->
<!--               (click)="pinMessage($event)"-->
<!--            ></i>-->
<!--            <img [src]="messageLine" *ngFor="let messageLine of messageContent" [alt]="fileName">-->
<!--            <a [href]="messageLine" download target="_blank" class="message-line" *ngFor="let messageLine of messageContent">{{ fileName }} <i class="icon icon-download"></i></a>-->
<!--        </div>-->
<!--        <div *ngIf="message.owner.current && !isEmbedded" class="outgoing-time">{{formatDayAndTime(message.created)}}</div>-->
<!--    </div>-->
<!--</ng-container>-->
<ng-container *ngIf="message.type === MESSAGE_TYPE.SYSTEM">
    <div class="message-item">
        <div class="message-avatar">
            <figure class="avatar system-avatar">
                <img [src]="serviceAvatar" class="rounded-circle" alt="image">
            </figure>
            <div>
                <h5>{{serviceUsername | localize}}</h5>
                <div class="time">{{formatDayAndTime(message.created)}}</div>
            </div>
        </div>
        <div class="message-content system-message">
            {{ ('chat.systemMessage.' + message.content) | localize }} {{message.date ? (message.date | dateTimeFormat) : ''}}
        </div>
    </div>
</ng-container>

<ng-template #confirm let-dialogRef="dialogRef">
    <ano-modal [dialogRef]="dialogRef" [title]="'chat.message.delete.title' | localize" [isConfirmation]="true">
        {{'chat.message.delete.confirm' | localize}}
    </ano-modal>
</ng-template>
