import {Component, OnInit} from '@angular/core';
import {ISchedule, SchedulerService} from "@src/app/services/scheduler-service/scheduler.service";

@Component({
    selector: 'app-scheduler-events-subscriber',
    templateUrl: './scheduler-events-subscriber.component.html',
    styleUrls: ['./scheduler-events-subscriber.component.css'],
    providers: [SchedulerService]
})
export class SchedulerEventsSubscriberComponent implements OnInit {
    constructor(
        private schedulerService: SchedulerService
    ) {
    }

    ngOnInit(): void {
        this.schedulerService.events.subscribe((ev) => {
            if (!ev) {
                return;
            }
            //
        });
    }

    public pause(): void {
        this.schedulerService.pause();
    }

    public stop(): void {
        this.schedulerService.stop();
    }

    public play(): void {
        this.schedulerService.start(11000);
    }

}
