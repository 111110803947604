import { Pipe, PipeTransform } from '@angular/core';
import {obfuscateEmail} from "@src/app/utils/string.util";

@Pipe({
  name: 'email'
})
export class EmailPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    const regExp = /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
    if (regExp.test(value)) {
      return obfuscateEmail(value);
    }
    return value;
  }

}
