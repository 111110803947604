<div class="content">
  <div class="container">
    <div class="box box-alert box-alert-info">
      <div class="ava ava-md ava-center ava-with-space ava-icon ava-icon-white">
        <i class="icon-person"></i>
      </div>
      <h4>{{ config.title | localize }}</h4>
      <p *ngFor="let description of config.descriptions" class="description">
        {{ description | localize }}
      </p>
      <div class="row">
        <div *ngFor="let button of config.buttons" class="col">
          <ano-button
            (click)="$event.preventDefault(); button.action.apply(this);"
            [config]="button.config"
          >
            {{ button.text | localize }}
          </ano-button>
        </div>
      </div>
    </div>
  </div>
</div>
