import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-stream-access-button',
  templateUrl: './media-access-button.component.html',
  styleUrls: ['./media-access-button.component.scss']
})
export class MediaAccessButtonComponent implements OnInit {

  @Input() isTeaserOrTrailer: boolean;
  @Input() displayVideoTitle: boolean;
  teaserOrTrailerClicked = false;

  constructor() { }

  ngOnInit(): void {
  }

  onClickButton() {
    if (this.isTeaserOrTrailer) {
      this.teaserOrTrailerClicked = true;
    }
  }
}
