<div class="profile-header">
    <p>{{(upgradeToPublisher ? 'publisher.upgrade.description' : 'registration.description') |
        localize}}</p>
    <span class="required">{{'registration.required' | localize}}</span>
</div>

<div class="card bg-white">
    <div class="card-header">
        <h2 class="title">{{'registration.profileInfo' | localize}}</h2>
    </div>

    <div class="row row-align-stretch">
        <div class="col-lg-8 mr-auto">
            <div class="card-body">
                <app-info-panel
                        [infoPanel]="{textKey: 'registration.country.info'}">
                </app-info-panel>
                <app-bordered-form
                        [defaultForm]="true"
                        [form]="form"
                        [fieldsConfig]="formConfig"
                >
                </app-bordered-form>
            </div>
        </div>
    </div>
</div>
<div class="card bg-white">
    <div class="card-header">
        <h2 class="title">{{'registration.PhoneInfo' | localize }}</h2>
    </div>

    <div class="row row-align-stretch">
        <div class="col-lg-8 mr-auto">
            <div class="card-body">
                <app-bordered-form
                        [defaultForm]="true"
                        [form]="phoneForm"
                        [fieldsConfig]="phoneFieldsConfig"
                        [config]="{withConfirmationPhone: true}"
                        (confirmed$)="confirmedPhone($event)"
                        >
                    </app-bordered-form>
                </div>
            </div>
        </div>
    </div>
    <div class="card bg-white">
        <div class="card-header">
            <h2 class="title">{{'registration.firmInfo' | localize }}</h2>
        </div>
        
        <div class="row row-align-stretch">
            <div class="col-lg-8 mr-auto">
                <div class="card-body">
                    <app-info-panel
                        [infoPanel]="{textKey: 'registration.needToInputCompanyInformation'}">
                    </app-info-panel>
                    <app-bordered-form
                        [defaultForm]="true"
                        [form]="firmForm"
                        [fieldsConfig]="firmFieldsConfig">
                    </app-bordered-form>
                </div>
            </div>
        </div>
    </div>

<div class="card bg-white">
    <div class="card-header">
        <h2 class="title">{{'registration.currency.title' | localize }}</h2>
    </div>
    <div class="row row-align-stretch">
        <div class="col-lg-8 mr-auto">
            <div class="card-body">
                <p>{{'registration.currency.info'|localize}}</p>
                <app-bordered-form
                        [defaultForm]="true"
                        [form]="currencyForm"
                        [fieldsConfig]="currencyFieldsConfig"
                >
                </app-bordered-form>
            </div>
        </div>
    </div>
</div>

<div class="card bg-white">
    <div class="card-header">
            <p class="mb-0">{{'registration.termsOfUse'|localize}}</p>
    </div>

    <div class="row row-align-stretch">
        <div class="col-lg-8 mr-auto">
            <div class="card-body">
                <app-bordered-form
                        [defaultForm]="true"
                        [form]="termsForm"
                        [fieldsConfig]="termsFieldsConfig"
                >
                </app-bordered-form>
            </div>
        </div>
    </div>
</div>

<div class="row row-align-stretch">
    <div class="col-lg-8 mr-auto">
        <div class="m-button align-self-start">
            <!-- <ano-button *ngIf="upgradeToPublisher" class="cancel-button" (click)="cancel()"
                        [config]="{
                type: 'submit',
                styleType: 'secondary',
                isDisabled: loading.registration
                }">
                {{ 'publisher.upgrade.button.cancel' | localize }}
            </ano-button> -->
            <ano-button (click)="submit()"
                        style="justify-content: flex-start !important;"
                        class="registration-button"
                        [config]="{
                type: 'submit',
                styleType: 'success',
                isDisabled: loading.registration
                }">
                {{ (upgradeToPublisher ? 'publisher.upgrade.button.finish' : 'registration.button.register') | localize }}
            </ano-button>
        </div>
    </div>
</div>

