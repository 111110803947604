import {Injectable} from '@angular/core';
import {ApiService} from 'ui-elements';
import {Observable} from 'rxjs';
import {IPagedResponse} from '@src/app/models/response.model';
import {IMediaItem} from '@src/app/models/core.model';
import {API_URLS_APP} from '@src/app/constants/api-urls.constant';
import {IListFilterItem, IListQuery} from '@src/app/components/media-list/media-list.component';
import {pluck} from 'rxjs/operators';
import {ISuggestedMediaFilters} from '@src/app/pages/public-media-page/public-media-page.component';
import {IMediaTimerange} from '@src/app/models/stream.model';
import {DEFAULT_PAGING} from '@src/app/services/video-on-demand/video-on-demand.service';
import {Router} from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class MediaListService {

    constructor(
        private apiService: ApiService,
        private router: Router,
    ) {
    }

    public getMediaList(filters: IListQuery, autoDetectEmbedded = true, showNowhere = false): Observable<IPagedResponse<IMediaItem>> {
        if (!filters.paging) {
            filters.paging = DEFAULT_PAGING;
        }

        if (autoDetectEmbedded) {
            const isEmbedded = this.router.url.includes('/embedded/');
            if (filters.filters) {
                filters.filters = filters.filters.filter((item) => item.field !== 'VISIBLE');
            }
            if (filters.groupFilters) {
                filters.groupFilters = filters.groupFilters.filter((item) => item.field !== 'VISIBLE');
                filters.groupFilters.push({field: 'VISIBLE', value: ['everywhere', isEmbedded ? 'except_streamdust' : 'streamdust_only']});
            } else {
                filters.groupFilters = [{field: 'VISIBLE', value: ['everywhere', isEmbedded ? 'except_streamdust' : 'streamdust_only']}];
            }

            if (showNowhere) {
                filters.groupFilters = [{field: 'VISIBLE', value: ['everywhere', 'except_streamdust', 'streamdust_only', 'nowhere']}];
            }
        }

        return this.apiService.post(API_URLS_APP.GET_MEDIA_LIST, filters, {
            openApi: true,
            isWithoutRootUrl: true
        });
    }

    public getMediaListFilters(filterTypes: ISuggestedMediaFilters): Observable<{
        [key: string]: any[];
    }> {
        return this.apiService.post(API_URLS_APP.GET_MEDIA_LIST_FILTERS, filterTypes, {
            openApi: true,
            isWithoutRootUrl: true
        }).pipe(pluck('results', 'data'));
    }

    public getMediaListTimeRanges(filters: IListFilterItem[]): Observable<IMediaTimerange[]> {
        return this.apiService.post(API_URLS_APP.GET_MEDIA_LIST_TIMELINE, {filters}, {
            openApi: true,
            isWithoutRootUrl: true
        }).pipe(pluck('results', 'data'));
    }
}

export enum MEDIA_FILTERS_FIELDS {
    MEDIA_TYPE = 'MEDIA_TYPE',
    STREAM_STATUS = 'STREAM_STATUS',
    EXCLUDE_ID = 'EXCLUDE_ID',
    PUBLISHER = 'PUBLISHER',
    PURCHASED_BY = 'PURCHASED_BY',
    GROUP_ID = 'GROUP_ID',
    VISIBLE = 'VISIBLE',
    HAS_ACCESS = 'HAS_ACCESS',
    DEACTIVATED = 'DEACTIVATED',
    DEACTIVATED_ALLOWED = 'DEACTIVATED_ALLOWED',
    DEACTIVATED_ONLY = 'DEACTIVATED_ONLY',
}
