export function scrollToTop(scrollTo?: number) {
    const banner = document.getElementById('publisher-banner');
    const header = document.getElementById('main-header');
    if (!header && !banner) {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        return;
    }
    let scrollTop = header.clientHeight;
    if (banner) {
        scrollTop += banner.clientHeight + 10;
    }


    if (typeof scrollTo !== 'undefined') {
        scrollTop = scrollTo;
    }

    window.scrollTo({
        top: scrollTop,
        behavior: 'smooth'
    });
}
