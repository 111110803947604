import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ChangePasswordPageService} from "@src/app/pages/change-password-page/services/change-password-page/change-password-page.service";
import {takeUntil} from "rxjs/internal/operators";
import {AutoDestroyService} from "@src/app/services/auto-destroy-service/auto-destroy.service";
import {AuthService} from "@src/app/services/auth/auth.service";
import {HeaderPopupType, HeaderService} from "@src/app/services/header/header.service";
import {LocalizationProvider} from 'ui-elements';

@Component({
    selector: 'app-change-password-page',
    templateUrl: './change-password-page.component.html',
    styleUrls: ['./change-password-page.component.sass'],
    providers: [AutoDestroyService]
})
export class ChangePasswordPageComponent implements OnInit {

    public TOKEN_INVALID_ERR_KEY = 'PASSWORD_LOST_TOKEN_IS_INVALID';
    public TOKEN_EXPIRED_ERR_KEY = "PASSWORD_LOST_TOKEN_IS_EXPIRED";

    public passwordForgottenConfirmationResult: { isTokenInvalid: boolean, errorMessage: string } = {} as any;
    public loading: boolean;

    public confirmForgotPassToken: string;

    constructor(private changePasswordForgottenService: ChangePasswordPageService,
                private authService: AuthService,
                private headerService: HeaderService,
                private localizationProvider: LocalizationProvider,
                private route: ActivatedRoute,
                private destroy$: AutoDestroyService) {

    }

    ngOnInit() {
        this.loading = true;

        // get token param
        this.confirmForgotPassToken = this.route.snapshot.queryParams['token'];
        if (!(this.confirmForgotPassToken && this.confirmForgotPassToken.length > 0)) {
            this.handlePasswordForgottenConfirmationError(null);
            return;
        }

        this.verifyToken(this.confirmForgotPassToken);
    }

    private verifyToken(confirmForgotPassToken: string) {
        this.changePasswordForgottenService.confirmPasswordForgotten(confirmForgotPassToken)
            .pipe(takeUntil(this.destroy$))
            .subscribe((res) => {
                this.loading = false;

                if (!res.success) {
                    this.handlePasswordForgottenConfirmationError(res);
                    return
                }

                this.authService.tempToken = res.results.authToken;
                this.headerService.showHeaderPopup(HeaderPopupType.CHANGE_FORGOTTEN_PASSWORD);
            }, (err) => {
                this.handlePasswordForgottenConfirmationError(err);
            })
    }

    private handlePasswordForgottenConfirmationError(response): void {
        this.loading = false;
        this.passwordForgottenConfirmationResult.isTokenInvalid = true;
        const errorKey = response ? response.errorKey : this.TOKEN_INVALID_ERR_KEY || this.TOKEN_EXPIRED_ERR_KEY;
        this.passwordForgottenConfirmationResult.errorMessage = this.localizationProvider.getByErrorKey(errorKey);
    }
}
