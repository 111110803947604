<div class="row">
    <div class="col">
        <div *ngIf="loading" class="loading-container">
            <ano-loader [isOpen]="true"></ano-loader>
        </div>
        <app-streamdust-player *ngIf="!loading"
                [playerDisplayMode]="PLAYER_DISPLAY_MODE.EMBEDDED"
                [mediaId]="streamId"
                [mediaType]="mediaType"
                [mode]="PLAYER_MODE.VIEWER"
                [embeddedChatMode]="CHAT_MODE.EMBEDDED"
                [embedded]="true"
        ></app-streamdust-player>
    </div>
</div>
