<section *ngIf="loading" class="section loading-container">
    <ano-loader [isOpen]="loading"></ano-loader>
</section>
<div class="bg-fixed" [ngStyle]=" {'background-image': 'url(' + coverImage + ')', 'background-size': 'cover', 'background-position': 'center top', 'background-repeat': 'no-repeat'}"></div>
<section *ngIf="!loading" class="section section-live-streams mt-0">
    <div class="box-live-streams">
        <div class="container media-player-container event-site" [class.content-center]="restrictedByCountry">
            <ng-container *ngIf="!(loadingService.loading | async)">
                <h1 *ngIf="contentIsOnReview">{{'public-media-page.content-is-on-review-text' | localize}}</h1>
                <h1 *ngIf="restrictedByCountry">{{'public-media-page.content-is-on-restricted_by_country' | localize}}</h1>

                <div class="box-streams" [class.mb-0]="data?.video?.groups?.length > 0"
                     *ngIf="!contentIsOnReview && !restrictedByCountry">
                    <div class="row">
                        <div class="col-lg-8">
                            <app-streamdust-player *ngIf="data"
                                                   [mediaType]="mediaType"
                                                   [mediaId]="mediaId"
                                                   [mode]="PLAYER_MODE.VIEWER"
                                                   (grantAccess$)="grantAccess()"
                            ></app-streamdust-player>
                            <div class="player-footer">
                                <div class="row">
                                    <div class="col">
                                        <h2 class="title stream-title">{{data?.video?.title}}</h2>
                                        <div class="stream-description">
                                            <ng-container *ngFor="let descriptionLine of data?.video?.description.split('\n')">
                                                <p class="description-line">{{descriptionLine}}</p>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <app-publisher-info [publisher]="data?.publisher"
                                                [contacts]="data?.contacts"
                                                [data]="data"
                            ></app-publisher-info>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</section>
<!--<app-donations *ngIf="data" [mediaId]="mediaId" [mediaType]="mediaType"-->
<!--               [currency]="data?.video?.price?.currency" [playerMode]="PLAYER_MODE.VIEWER"></app-donations>-->
<!--<app-stream-world-map *ngIf="(worldMapState.worldMapActiveState$ | async)" [id]="2"-->
<!--                      [streamId]="mediaId"></app-stream-world-map>-->
