<app-period-toggle *ngIf="config.showPeriodToggle" (planPeriodChange)="changeTariffPeriod($event)"></app-period-toggle>

<div class="plan-box" [class.month]="config.planPeriod === TARIFF_PLAN_PERIOD.MONTH"
     [class.year]="config.planPeriod === TARIFF_PLAN_PERIOD.YEAR">
    <div class="tariff-currency-selector blue-theme-filter" *ngIf="!isAuthorized">
        <form [formGroup]="currencyForm">
            <ano-input formControlName="tariffCurrency"
                       [isEditMode]="true"
                       [config]="{
                                                        inputType: INPUT_TYPES.SELECT,
                                                        placeholder: '',
                                                        selectOptions: currencySelectOptions
                                                    }"
            ></ano-input>
        </form>
    </div>
    <div class="row row-padding-3 justify-content-center">
        <ng-container
                *ngIf="config?.planType === PLAN_LIST_TYPES.BANDWIDTH || config?.planType === PLAN_LIST_TYPES.STORAGE_SPACE">
            <ng-container *ngFor="let tariffPlan of tariffPlans; let i = index">
                <div class="col-md-6 col-lg">
                    <app-plan-card [plan]="tariffPlan"
                               [config]="{
                                        iconName: config?.iconName,
                                        iconIndex: i + 1,
                                        hideActionButton: config?.hideActionButton,
                                        planPeriod: config?.planPeriod,
                                        planType: config?.planType
                                    }"
                               (actionButtonPressed$)="activatePlan($event)"></app-plan-card>
                </div>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="config?.planType === PLAN_LIST_TYPES.SUBSCRIPTION">
            <ng-container *ngFor="let tariffPlan of tariffPlans[isAuthorized && (userCurrency | async) ? (userCurrency | async) : currentCurrency]; let i = index">
                <div class="col-md-6 col-lg">
                <app-subscription-plan-card [plan]="tariffPlan"
                                            [config]="{
                                                    hideActionButton: config?.hideActionButton,
                                                    isAuthorized: isAuthorized,
                                                    planPeriod: config?.planPeriod,
                                                    coverClass: tariffPlans.length === 2 ? 'plan-col-6' : (tariffPlans.length === 1 ? 'plan-col-12' : null)
                                                }"
                                            (actionButtonPressed$)="activatePlan($event)">
                </app-subscription-plan-card>
                </div>
            </ng-container>
        </ng-container>
    </div>
</div>
