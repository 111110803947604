import {IUnitKeyValue} from './../../../pages/user-profile/interfaces/interfaces-common';
import {IStreamModelMetadata, MediaOverviewData} from './../../../models/stream.model';
import {PLAYER_DISPLAY_MODE, PLAYER_MODE} from './../constants/playerMode';
import {STREAM_STATUS} from './../constants/status';
import {Router} from '@angular/router';
import {StreamLinksService} from './../../../services/stream-links/stream-links.service';
import {environment} from './../../../../environments/environment';
import {DragScrollComponent} from 'ngx-drag-scroll';
import {Component, HostListener, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MEDIA_TYPE} from '../constants/mediaType';
import {CmsService} from 'ui-elements';

@Component({
    selector: 'app-linked-streams-modal',
    templateUrl: './linked-streams-modal.component.html',
    styleUrls: ['./linked-streams-modal.component.scss']
})
export class LinkedStreamsModalComponent implements OnInit, OnDestroy {
    @ViewChild('sliderComp', {read: DragScrollComponent}) set sliderComp(component: DragScrollComponent) {
        if(component) { 
            this.slider = component;
            this.slider.reachesLeftBound.subscribe(res => this.sliderArrow.leftArrow = !res);
            this.slider.reachesRightBound.subscribe(res => this.sliderArrow.rightArrow = !res);
        }
    }

    @Input() currentMedia: MediaOverviewData;
    @Input() mode: PLAYER_MODE;
    @Input() public playerDisplayMode: PLAYER_DISPLAY_MODE;
    slider: DragScrollComponent;
    sliderArrow = {leftArrow: false, rightArrow: true};
    numbeOfSlides: number;
    genres: IUnitKeyValue[];
    linkedMedia: IStreamModelMetadata[];
    environment = environment;
    STREAM_STATUS = STREAM_STATUS;

    constructor(private cmsService: CmsService,
                private router: Router,
                public streamLinksService: StreamLinksService) {
    }

    ngOnInit(): void {
        this.genres = this.cmsService.genres;
        this.linkedMedia = this.currentMedia?.linkedMedia;
        this.numbeOfSlides = this.linkedMedia.length
    }

    ngOnDestroy(): void {
        this.onClose();
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        event.target.innerWidth;
    }

    onClose(): void {
        this.streamLinksService.isShowLinkedStreamsComponent = false;
    }

    moveLeft(): void {
        this.slider.moveLeft();
    }

    moveRight(): void {
        this.slider.moveRight();
    }

    getBackground(media): string {
        return media?.previewPicture ? 'url(' + environment.backendApiHost + media?.previewPicture?.photoUrl + ')' : '';
    }

    getCurrency(media): {amount: string, currency: string} {
        const price = media.linkedMedia?.video.price
        return price.amount ? {amount: price.amount, currency: price.currency || 'EUR'} : null;
    }

    goToLinks(media: IStreamModelMetadata) {
        this.streamLinksService.onClick(this.currentMedia.video.id, media.id).subscribe(() => {
            const mode = this.playerDisplayMode === PLAYER_DISPLAY_MODE.EMBEDDED ? 'embedded' : 'watch';
            const typeForPublisher = media.videoType === MEDIA_TYPE.STREAM ? 'streams' : 'vod';
            const typeForViewer = media.videoType === MEDIA_TYPE.STREAM ? 'stream' : 'vod';
            const url = this.mode === PLAYER_MODE.PUBLISHER
                ? `${typeForPublisher}/${media.id}/info`
                : `${mode}/${typeForViewer}/${media.id}`;

            this.onClose();
            this.router.navigate([url]);
        });
    }
}
