import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {UserService} from '@src/app/services/user/user.service';
import {IMessage, MESSAGE_TYPE} from '@src/app/services/chat/chat.service';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-pinned-message',
  templateUrl: './pinned-message.component.html',
  styleUrls: ['./pinned-message.component.sass']
})
export class PinnedMessageComponent implements OnInit {
  @Input() message: IMessage;
  @Input() serviceMessage: string;
  @Input() serviceMessageDate: any;
  @Input() isOwner: boolean;
  @Input() isEmbedded: boolean;
  public MESSAGE_TYPE = MESSAGE_TYPE;
  public messageContent: string[];
  public isImageFile = false;
  public fileName = '';
  @Output() deleteMessage: EventEmitter<string> = new EventEmitter<string>();
  @Output() pinMessage$: EventEmitter<string> = new EventEmitter<string>();

  constructor(
      public userService: UserService,
      public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.messageContent = this.message.content.split('\n');
    this.parseURL();
  }

  parseURL(): void {
    if (this.message.type === MESSAGE_TYPE.FILE && this.isValidURL(this.message.content)) {
      const urlObject = new URL(this.message.content);
      const pathSegments = urlObject.pathname.split('/');
      this.fileName = decodeURIComponent(pathSegments[pathSegments.length - 1]);
      const fileExtension = this.fileName.split('.').pop().toLowerCase();
      this.isImageFile = this.checkImageExtension(fileExtension);
    }
  }

  checkImageExtension(extension: string): boolean {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'svg', 'bmp'];
    return imageExtensions.includes(extension);
  }

  isValidURL(url: string): boolean {
    const urlPattern = /^(http|https):\/\/[\w.-]+\.[a-zA-Z]{2,4}\/?[\w-./?%&=]*$/;
    return urlPattern.test(url);
  }

  public pinMessage(event): void {
    event.stopImmediatePropagation();
    this.pinMessage$.emit(this.message.id);
  }

}
