export enum MEDIA_TYPE {
    STREAM = 'STREAM',
    VIDEO_ON_DEMAND = 'VIDEO_ON_DEMAND',
    STREAM_RECORDING = 'STREAM_RECORDING',
    VIDEO = 'VIDEO',
}

export enum MEDIA_FORMAT {
    VIDEO = 'video/mp4',
    STREAM = 'application/x-mpegURL'
}

export enum MEDIA_SUBTYPE {
    TEASER = 'TEASER',
    TRAILER = 'TRAILER',
    MAIN = 'MAIN'
}
