import {Component, Input, OnInit} from '@angular/core';
import {IFormFieldsConfig, StreamService} from '@src/app/services/stream-metadata/stream.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {AutoDestroyService, INPUT_TYPES} from 'ui-elements';
import {IStreamBroadcast} from '@src/app/models/response.model';
import {STREAM_STATUS} from '@src/app/components/streamdust-player/constants/status';
import {LocalizationProvider} from 'ui-elements';
import {IStreamModel} from '@src/app/models/stream.model';
import {DateHelper} from '@src/app/utils/date.helper';
import {takeUntil} from 'rxjs/internal/operators';

@Component({
    selector: 'app-stream-encoder-data',
    templateUrl: './stream-encoder-data.component.html',
    styleUrls: ['./stream-encoder-data.component.sass'],
    providers: [AutoDestroyService]
})
export class StreamEncoderDataComponent implements OnInit {
    @Input() data: IStreamBroadcast;
    @Input() stream: IStreamModel;
    loading: boolean;
    public fieldsConfig: IFormFieldsConfig[] = [
        {
            name: 'server',
            config: {
                inputType: INPUT_TYPES.INPUT,
                label: 'encoder.data.url',
                copy: true
            }
        },
        {
            name: 'streamKey',
            config: {
                inputType: INPUT_TYPES.INPUT,
                label: 'encoder.data.streamname',
                copy: true
            }
        },
        {
            name: 'backupServer',
            config: {
                inputType: INPUT_TYPES.INPUT,
                label: 'encoder.data.backup.url',
                copy: true
            }
        },
        {
            name: 'backupStreamKey',
            config: {
                inputType: INPUT_TYPES.INPUT,
                label: 'encoder.data.backup.streamname',
                copy: true
            }
        },
        {
            name: 'userName',
            hidden: true,
            config: {
                inputType: INPUT_TYPES.INPUT,
                label: 'encoder.data.username',
                copy: true
            }
        },
        {
            name: 'password',
            hidden: true,
            config: {
                inputType: INPUT_TYPES.INPUT,
                label: 'encoder.data.password',
                copy: true,
                show: true
            }
        }
    ];

    public STREAM_STATUS = STREAM_STATUS;
    public sendEmailLink: string;

    public form: FormGroup = this.fb.group({
        server: [''],
        streamKey: [''],
        backupServer: [''],
        backupStreamKey: [''],
        userName: [''],
        password: ['']
    });

    constructor(
        private fb: FormBuilder,
        private localizationProvider: LocalizationProvider,
        private streamService: StreamService,
        private destroy$: AutoDestroyService
    ) {
    }

    ngOnInit(): void {
        this.loading = true;
        this.streamService.getStreamBroadcast(this.stream.media.id)
            .pipe(takeUntil(this.destroy$))
            .subscribe((_res) => {
                this.data = _res;
                this.form.get('server').patchValue(this.data?.serverUrl);
                this.form.get('streamKey').patchValue(this.data?.streamKey);
                if (this.data?.backupServerUrl) {
                    this.form.get('backupServer').patchValue(this.data?.backupServerUrl);
                    this.form.get('backupStreamKey').patchValue(this.data?.backupStreamKey);
                } else {
                    this.fieldsConfig.find(field => field.name === 'backupServer').hidden = true;
                    this.fieldsConfig.find(field => field.name === 'backupStreamKey').hidden = true;
                }
                if (this.data?.streamAuthentication?.enabled) {
                    this.form.get('userName').patchValue(this.data?.streamAuthentication?.username);
                    this.form.get('password').patchValue(this.data?.streamAuthentication?.password);
                    this.fieldsConfig.find(field => field.name === 'userName').hidden = false;
                    this.fieldsConfig.find(field => field.name === 'password').hidden = false;
                } else {
                    this.fieldsConfig.find(field => field.name === 'userName').hidden = true;
                    this.fieldsConfig.find(field => field.name === 'password').hidden = true;
                }
                this.loading = false;

                this.prepareSendEmailLink();
            }, () => this.loading = false);
    }

    public prepareSendEmailLink(): void {
        const subject = this.localizationProvider.getByKey('encoder.data.title');
        const emailBody = this.buildEmailBody();
        this.sendEmailLink = 'mailto:?subject=' + subject + '&body=' + encodeURIComponent(emailBody);
    }

    private buildEmailBody(): string {
        let res =
            this.stream.media.title + '\r\n\r\n' +
            this.stream.media.description + '\r\n' +
            DateHelper.formatDateTime(this.stream.media.startTimestamp, null, true) + ' - ' + DateHelper.formatDateTime(this.stream.media.endTimestamp, null, true) + '\r\n\r\n\r\n' +
            this.localizationProvider.getByKey('encoder.data.description') + '\r\n\r\n' +
            this.localizationProvider.getByKey('encoder.data.url') + ': ' + this.data?.serverUrl + '\r\n' +
            this.localizationProvider.getByKey('encoder.data.streamname') + ': ' + this.data?.streamKey + '\r\n';

        if (this.data?.backupServerUrl) {
            res += this.localizationProvider.getByKey('encoder.data.backup.url') + ': ' + this.data?.backupServerUrl + '\r\n' +
                this.localizationProvider.getByKey('encoder.data.backup.streamname') + ': ' + this.data?.backupStreamKey + '\r\n';
        }

        if (this.data?.streamAuthentication?.enabled) {
            res += this.localizationProvider.getByKey('encoder.data.username') + ': ' + this.data?.streamAuthentication?.username + '\r\n' +
                this.localizationProvider.getByKey('encoder.data.password') + ': ' + this.data?.streamAuthentication?.password + '\r\n';
        }

        return res;
    }

}
