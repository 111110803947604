<form class="border-form" [formGroup]="form">
    <div class="row row-padding-30" [class.row-padding-30]="!config?.padding0" [class.row-padding-0]="config?.padding0">
        <div class="col-lg-6">
            <div class="form-group mt-5">
                <ano-input [formControlName]="'toggle'"
                           [isEditMode]="true"
                           [disabled]="disabled || config?.forcedDisable"
                           [config]="{
                                inputType: INPUT_TYPES.CHECKBOX,
                                placeholder: config?.togglePlaceholder,
                                classList: 'form-control-lg'}"
                           (toggle$)="toggle($event)"
                ></ano-input>
            </div>
        </div>
        <div class="col-lg-12" *ngIf="config?.toggleText">
            <div class="form-group">
                <span class="text" [class.disabled-text]="!form.get('toggle').value">{{config?.toggleText | localize}}</span>
            </div>
        </div>
    </div>
</form>
