<div class="panel panel-md">
    <div class="panel-header">
        <h2><b>{{'stream.revenue.title' | localize}}</b></h2>
        <p class="text-sm">{{'stream.revenue.description' | localize}}</p>
        <div class="datepicker-container">
            <ano-datepicker [showAllOption]="false" (changeDate)="timeRangeFilter($event)"></ano-datepicker>
        </div>
    </div>

    <div class="card">
        <ano-loader [isOpen]="loadingService.loading | async"></ano-loader>
        <div class="card-header">
            <h4 class="mb-0"><b>{{'stream.revenue.title' | localize}}</b> <span class="light"> ({{timeRange.start | dateFormat: 'MMMM YYYY'}})</span></h4>
            <div class="blue-theme-filter" *ngIf="overviewSelectOptions.length > 1">
                <form [formGroup]="overviewForm">
                    <ano-input formControlName="selectOverview"
                               [isEditMode]="true"
                               [config]="{
                                    inputType: INPUT_TYPES.SELECT,
                                    placeholder: 'stream.revenue.overview.select' | localize,
                                    selectOptions: overviewSelectOptions
                               }"
                    ></ano-input>
                </form>
            </div>
        </div>

        <div class="card-body">
            <div class="row row-padding-10">
                <div class="col">
                    <form class="light-form full-20">
                        <div class="row row-padding-0" *ngFor="let streamInfo of streamInfoTable">
                            <label class="col-lg-6 form-control-label">{{streamInfo?.title}}</label>
                            <div class="col-lg-6">
                                <input disabled type="text" class="form-control"
                                       [hidden]="loadingService.loading | async"
                                       [placeholder]="slotMetadata ? slotMetadata?.media[streamInfo?.property] : streamMetadata?.media[streamInfo?.property]">
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <div class="card bg-white">
        <app-country-revenues #countryRevenuesComponent
                              [mediaType]="mediaType"
                              [mediaId]="mediaId"
                              [timeRange]="timeRange"
        ></app-country-revenues>
    </div>

    <div class="card">
        <ano-loader [isOpen]="loadingService.loading | async"></ano-loader>
        <div class="card-header">
            <h4 class="mb-0"><b>{{'stream.revenue.less' | localize}}</b> <span class="light"> ({{timeRange.start | dateFormat: 'MMMM YYYY'}})</span></h4>
        </div>

        <div class="card-body">
            <div class="row">
                <div class="col">
                    <form class="light-form full-20 mb-20" id="revenuesTotalDataTable">
                        <div class="row row-padding-0" *ngFor="let streamRevenue of streamRevenuesTotalTable">
                            <label class="col-lg-6 form-control-label">{{streamRevenue.title}}</label>
                            <div class="col-lg-6" style="text-align: right">
                                <input disabled type="text" class="form-control"
                                       [placeholder]="formatRevenue(streamRevenuesTotal[streamRevenue.property])">
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="row justify-content-end">
                <div class="col-auto">
                    <button class="btn btn-success" [disabled]="!streamRevenuesTotal?.withdrawalAmount || isCurrentMonth" (click)="downloadRevenuesTotalData()">
                        {{'stream.revenue.download-credit' | localize}}
                        <ano-loader [isOpen]="isPDFLoading"></ano-loader>
                    </button>
                    <div class="invalid-feedback" *ngIf="isCurrentMonth && streamRevenuesTotal?.withdrawalAmount">
                        {{'error.media.revenue.pdfDownload.currentMonth' | localize}}
                    </div>
                    <div class="invalid-feedback" *ngIf="!streamRevenuesTotal?.withdrawalAmount">
                        {{'error.media.revenue.pdfDownload.noData' | localize}}
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card">
        <ano-loader [isOpen]="loadingService.loading | async"></ano-loader>
        <div class="card-header">
            <h4 class="mb-0"><b>{{'stream.revenue.invividual-transactions' | localize}}</b> <span class="light"> ({{timeRange.start | dateFormat: 'MMMM YYYY'}})</span></h4>
        </div>

        <div class="card-body bg-white">
            <ano-table #tableComponent class="bills"
                       [tableConfig]="tableConfig"
                       [defaultSortField]="'DATE'"
                       [sortOrder]="1"
                       [height]="'80px'"
                       [withSearch]="true"
                       [downloadButton]="false"
                       [downloadButtonText]="downloadButtonText"
                       (download$)="downloadPaymentTable($event)"
                       (search$)="search($event)"></ano-table>
        </div>
    </div>
</div>

<ng-template #actionsTemplate let-rowData="rowData">
    <div class="spinner-container" *ngIf="isTransactionPDFLoading && rowData.id === pdfDownloadCurrentId"><ano-loader [isOpen]="isTransactionPDFLoading && rowData.id === pdfDownloadCurrentId"></ano-loader></div>
    <ul class="action-list">
        <li><a href="javascript:void(0)" (click)="downloadPaymentData(rowData.id)"><i class="icon icon-download"></i></a>
        </li>
        <!--        <li><a href="javascript:void(0)"><i class="icon icon-paper-plane"></i></a></li>-->
    </ul>
</ng-template>

<ng-template #transactionTmpl let-data="rowData">
    {{data.transactionId | transaction}}
</ng-template>

<ng-template #totalCostTmpl let-data="rowData">
    {{{amount: data.totalCost, currency: revenueCurrency} | currency}}
</ng-template>
<ng-template #billTmpl let-data="rowData">
    {{data.billNumber | stringCut}}
</ng-template>
<ng-template #invoiceTmpl let-data="rowData">
    {{data.invoiceId | slice : -5 }}
</ng-template>
<ng-template #nameTmpl let-data="rowData">
    <span>{{data.firstName}} {{data.lastName}}</span>
</ng-template>
