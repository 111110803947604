import {saveAs} from 'file-saver/FileSaver';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
    IMediaUrl,
    IPlayerAccessModal,
    StreamService,
    VOUCHER_ACTIVATION_REJECT_REASONS,
    VOUCHER_ACTIVATION_STATUSES
} from '@src/app/services/stream-metadata/stream.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {CustomValidators} from '@src/app/utils/custom-validators.util';
import {
    asBoolean,
    CmsService,
    INPUT_TYPES,
    IUnitKeyValue,
    LoadingService as UILoadingService,
    LocalizationProvider,
    SnackBarService
} from 'ui-elements';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import {catchError, take, takeUntil} from 'rxjs/operators';
import {of, Subscription} from 'rxjs';
import {AuthService, IViewerCredentials} from '@src/app/services/auth/auth.service';
import {
    IMediaPaymentRequest,
    IPaymentSlot,
    IPrice,
    IVoucherRedeemRequest,
    MediaOverviewData,
    PAYMENT_SYSTEM
} from '@src/app/models/stream.model';
import {LoadingService} from '@src/app/services/loading/loading.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MEDIA_TYPE} from '@src/app/components/streamdust-player/constants/mediaType';
import {
    IHeidelpayCard,
    IHeidelpayPaymentDetails
} from '@src/app/components/payment-cards-list/heidelpay-payment/heidelpay-payment.component';
import {
    IFailedTransactionInfo,
    IGroupPayment,
    ISlotPayment,
    PAYMENT_FAIL_MESSAGES,
    PaymentService
} from '@src/app/services/payment/payment.service';
import {
    IPaymentCard,
    IPaymentCardsListControls
} from '@src/app/components/payment-cards-list/payment-cards-list.component';
import {PAYMENT_STATUS} from '@src/app/services/subscription-manage/subscription-manage.service';
import {IInfoPanel, InfoPanelsConfig} from '@src/app/components/info-panel/info-panel.component';
import {UserService} from '@src/app/services/user/user.service';
import * as _ from 'lodash';
import {IUserProfile} from '@src/app/pages/user-profile/components/profile/services/profile.service';
import {IUserAGB} from '@src/app/pages/user-profile/components/withdrawal-and-conditions/services/withdrawal-and-conditions.service';
import {IPaymentsModel} from '@src/app/pages/stream-page/components/stream-metadata/stream-metadata.component';
import {STREAM_STATUS} from '../constants/status';
import {HeaderPopupType, HeaderService} from '@src/app/services/header/header.service';
import {RemindService} from '@src/app/services/remind/remind.service';
import {PLAYER_DISPLAY_MODE} from '../constants/playerMode';
import {MediaGroupsService} from '@src/app/services/media-groups/media-groups.service';
import {SlotsModalState} from '@src/app/state/slots-modal-state';
import {FailurePaymentService} from '@src/app/services/slot-failure/failure-payment.service';
import {environment} from '@src/environments/environment';
import {clearQueryParams} from '@src/app/utils/query-params.util';
import {Location} from '@angular/common';
import {PURCHASE_TYPE} from '@src/app/pages/revenues-page/model/revenue.model';

@Component({
    selector: 'app-stream-access-modal',
    templateUrl: './media-access-modal.component.html',
    styleUrls: ['./media-access-modal.component.sass'],
    providers: [LoadingService]
})
export class MediaAccessModalComponent implements OnInit {
    public PAYMENT_SYSTEM = PAYMENT_SYSTEM;
    @Input() mediaId = '';
    @Input() productType: MEDIA_TYPE = MEDIA_TYPE.STREAM;
    @Input() paymentSystem: IPaymentsModel;
    @Input() termsConditions: IUserAGB;
    @Input() showLandscapeMode: boolean;
    @Input() playerMode: PLAYER_DISPLAY_MODE;
    @Input() hasGroups: boolean;
    @Input() parentLoader = true;
    @Input() leadTextField1 = '';
    @Input() leadTextField2 = '';
    @Input() customFieldOn = false;
    @Input() customFieldName = '';
    @Input() showRegistrationOffer: boolean;
    @Input() showLogin: boolean;
    @Input() landscapeModeClosed = false;

    @Output() groupPaymentFailure$: EventEmitter<string> = new EventEmitter<string>();
    @Output() openGroupModal$: EventEmitter<void> = new EventEmitter<void>();
    @Output() openSlotModal$: EventEmitter<void> = new EventEmitter<void>();
    @Output() closeAccessModal$: EventEmitter<void> = new EventEmitter<void>();
    @Output() closeRegistrationOffer$: EventEmitter<void> = new EventEmitter<void>();
    @Output() openAccessModal$: EventEmitter<void> = new EventEmitter<void>();
    @Output() getMedia$: EventEmitter<void> = new EventEmitter<void>();
    @Output() closeLandscapeMode$: EventEmitter<void> = new EventEmitter<void>();
    private componentsInitSub: Subscription;

    @Input('slot')
    public set _slot(slot: IPaymentSlot) {
        this.slot = slot;
        if (!this.slot && this.userHasAccess) {
            return;
        }
        if (!this.slot?.hasAccess) {
            this.userHasAccess = false;
        }
    }

    @Input('isPayed') set _isPayed(payed) {
        this.slotsState.isPayedAll$.next(payed);
    }

    @Input('data') set _data(data: MediaOverviewData) {
        this.data = data;
        this.mediaPrice = data?.video.price;
        this.initRemindState();
        this.checkOnlyForRegistered();
    }

    private slot: IPaymentSlot;

    data: MediaOverviewData;
    videoTypeURL = this.productType === MEDIA_TYPE.STREAM ? MEDIA_TYPE.STREAM.toLowerCase() : 'vod';
    returnUrl = `https://${window.location.hostname}/watch/${this.videoTypeURL}/${this.mediaId}`;

    currentModalState: MEDIA_ACCESS_MODAL_STATE = MEDIA_ACCESS_MODAL_STATE.ACCESS_SELECT;

    userAlreadyExistsError = false;
    userHasAccess = false;
    voucherRedeemed = false;
    paymentSuccess = false;
    canActivateVoucher = false;
    leadGenerationMedia = false;

    INPUT_TYPES = INPUT_TYPES;

    mediaPrice: IPrice;
    mediaUrl: IMediaUrl;

    activeVoucher: IVoucherRedeemRequest;

    voucherForm: FormGroup;
    passwordForm: FormGroup;
    userLoginForm: FormGroup;
    viewerRegisterForm: FormGroup;
    privacyPolicyForm: FormGroup;
    leadGenerationForm: FormGroup;
    registrationCanceled = false;

    private userProfile: IUserProfile;
    public userPhoto = '';
    public userFirstName = '';
    public userLastName = '';
    public userEmail = '';

    public isAuthorized: boolean;

    paymentCardsListControls: IPaymentCardsListControls = {
        payButton: true,
        cancelButton: {
            show: true,
            action: () => {
                this.setModalState(MEDIA_ACCESS_MODAL_STATE.ACCESS_SELECT);
                this.paymentService.resetPaymentCardError();
            }
        },
        addNewCard: {
            show: true,
            text: 'payment-cards-list.use-another-card',
            customHandler: (paymentDetails: IHeidelpayPaymentDetails) => this.payForMedia(paymentDetails, true)
        }
    };

    queryParams: unknown;
    noRegistrationInfoPanel: IInfoPanel = InfoPanelsConfig.noRegistrationDuringPurchase;
    accountAlreadyRegistered: IInfoPanel = InfoPanelsConfig.accountAlreadyRegistered;
    leadGenerationInfoPanel: IInfoPanel = InfoPanelsConfig.leadGenerationWarning;
    leadGenerationRegisteredInfoPanel: IInfoPanel = InfoPanelsConfig.leadGenerationAlreadyRegistered;
    MEDIA_ACCESS_MODAL_STATE = MEDIA_ACCESS_MODAL_STATE;
    countriesList: IUnitKeyValue[] = [];

    configModalPublisher = {
        isOpened: false,
        title: '',
        htmlText: '',
    };
    private nextState: MEDIA_ACCESS_MODAL_STATE;
    private prevState: MEDIA_ACCESS_MODAL_STATE[] = [];
    checkModel = {
        firstTerms: false,
        secondTerms: false,
        leadTextField1: false,
        leadTextField2: false,
        firstTermsError: false,
        secondTermsError: false,
        leadTextField1Error: false,
        leadTextField2Error: false,
    };
    private registerSystem: PAYMENT_SYSTEM;
    public selectedPayment: MEDIA_ACCESS_MODAL_STATE;
    remindState = {
        active: false,
        isButtonClick: false
    };
    private backAction = false;
    public platformUrl = window.location.protocol + '//' + window.location.host;
    public isUsedVoucher: boolean;

    constructor(
        private streamService: StreamService,
        public authService: AuthService,
        private router: Router,
        private fb: FormBuilder,
        private destroy$: AutoDestroyService,
        public loadingService: LoadingService,
        private activatedRoute: ActivatedRoute,
        private paymentService: PaymentService,
        private cmsService: CmsService,
        public userService: UserService,
        public headerService: HeaderService,
        public remindService: RemindService,
        private localizationProvider: LocalizationProvider,
        private mediaGroupsService: MediaGroupsService,
        private slotsState: SlotsModalState,
        private failurePaymentService: FailurePaymentService,
        private uiLoadingService: UILoadingService,
        private location: Location,
        private snackBarService: SnackBarService
    ) {
        this.countriesList = this.cmsService.countriesList;
    }

    ngOnInit(): void {
        this.initForms();
        this.initListeners();
    }

    isSendLeadGeneration() {
        return localStorage.getItem(`leadGeneration${this.mediaId}`);
    }

    checkIfLeadGenerationMedia(): void {
        this.activatedRoute.queryParams.pipe(takeUntil(this.destroy$)).subscribe(params => {
            if (this.paymentSystem?.psLeadGeneration) {
                this.setFormLead();
                this.leadGenerationMedia = true;
            }
        });
    }

    private failureSlotPayment(method: ('CARD' | 'PAYPAL' | 'SOFORT'), slotId: string, withoutRedirect = false): void {
        if (withoutRedirect) {
            this.openSlotModal();
            this.failurePaymentService.slotsModalAction.emit({slotId, method});
            return;
        }
        this.componentsInitSub = this.failurePaymentService.slotModalInitialized
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                this.openSlotModal();
                this.failurePaymentService.slotsModalAction.emit({slotId, method});
            });
    }

    private failureGroupPayment(method: ('CARD' | 'PAYPAL' | 'SOFORT'), groupId: string): void {
        this.failurePaymentService.accessWindowAction.next({slotId: null, method});
        this.groupPaymentFailure$.emit(groupId);
    }

    private failureMediaPayment(method: ('CARD' | 'PAYPAL' | 'SOFORT')): void {
        this.showInfo(MEDIA_ACCESS_MODAL_STATE.ONLINE_PAYMENT);
        this.setModalState(this.nextState);
        this.openAccessModal$.emit();
        this.failurePaymentService.cardListAction.next(method);
    }

    checkTransactionStatus(method: ('CARD' | 'PAYPAL' | 'SOFORT'), transactionId): void {
        this.loadingService.loadingStart();
        this.paymentService.checkTransactionStatus(transactionId)
            .pipe(
                takeUntil(this.destroy$),
                catchError((error) => of(error))
            )
            .subscribe(response => {
                const paymentStatus = response?.results?.result?.paymentStatus;
                const purchaseInfo = response?.results?.result?.purchaseInfo;
                switch (paymentStatus) {
                    case PAYMENT_STATUS.FAILED: {
                        const failedInfo: IFailedTransactionInfo = {
                            failedCardId: response?.results?.result?.externalCardId,
                            transactionFailReason: response?.results?.result?.reason || PAYMENT_FAIL_MESSAGES.FAILED
                        };

                        this.onFailedTransaction(method, failedInfo, purchaseInfo);
                        break;
                    }
                    case PAYMENT_STATUS.PENDING: {
                        setTimeout(() => {
                            return this.checkTransactionStatus(method, transactionId);
                        }, 3000);
                        break;
                    }
                    case PAYMENT_STATUS.SUCCESS: {
                        this.snackBarService.showInfo('Success');
                        this.onSuccessPayment(true);
                        this.loadingService.loadingEnd();
                        break;
                    }
                }
            });
    }

    onFailedTransaction(failedInfo, method, purchaseInfo) {
        switch (purchaseInfo.purchaseType) {
            case PURCHASE_TYPE.STREAM_GROUP:
                this.failureGroupPayment(method, purchaseInfo.productId);
                failedInfo.absoluteError = true;
                break;
            case PURCHASE_TYPE.SLOT:
                this.failureSlotPayment(method, purchaseInfo.productId);
                break;
            default:
                this.failureMediaPayment(method);
                break;
        }

        this.paymentService.failedTransactionInfo.next(failedInfo);
    }

    payForMedia(result: IHeidelpayPaymentDetails | IPaymentCard, isNewCardPayment = false): void {
        this.loadingService.loadingStart();
        const body: IMediaPaymentRequest = this.getPaymentBody(result, isNewCardPayment, PAYMENT_BODY_TYPES.MEDIA);
        this.saveViewerInfo(body.email, body.firstName, body.lastName);

        this.paymentService.proceedMediaPayment(body).pipe(
            takeUntil(this.destroy$),
            catchError(error => of(error))
        ).subscribe(response => {
            console.log('trigger');
            switch (response?.results?.result?.paymentStatus) {
                case PAYMENT_STATUS.FAILED: {
                    this.snackBarService.openError('Error');
                    this.failureMediaPayment(this.resolvePaymentMethod(body.paymentMethod.method));

                    this.paymentService.failedTransactionInfo.next({
                        failedCardId: response.results.result.externalCardId,
                        transactionFailReason: response.results.result.reason || PAYMENT_FAIL_MESSAGES.FAILED
                    });
                    this.loadingService.loadingEnd();
                    break;
                }
                case PAYMENT_STATUS.PENDING: {
                    if (response?.results?.result?.redirectUrl) {
                        window.location.href = response.results.result.redirectUrl;
                    }
                    break;
                }
                case PAYMENT_STATUS.SUCCESS: {
                    this.snackBarService.showInfo('Success');
                    this.loadingService.loadingEnd();
                    this.onSuccessPayment(true);
                    this.getMedia$.emit();
                    break;
                }
            }
        });
    }

    private resolvePaymentMethod(method: string): 'CARD' | 'SOFORT' | 'PAYPAL' {
        switch (method) {
            case 'card':
                return 'CARD';
            case 'sofort':
                return 'SOFORT';
            case 'paypal':
                return 'PAYPAL';
        }
    }

    private hasVouchers(): boolean {
        return this.streamService?.viewerVouchers.filter((voucher) => voucher.productId === this.mediaId)?.length > 0;
    }

    private getVoucher(): string {
        const vouchers = this.streamService?.viewerVouchers.filter((voucher) => voucher.productId === this.mediaId);
        return vouchers[vouchers.length - 1]?.code;
    }


    checkForVoucherNew(voucher): void {
        this.canActivateVoucher = false;
        this.mediaPrice = voucher.discount.newPrice;
        this.activeVoucher = voucher;
        // this.privacyPolicyForm.get('privacyPolicy').setValue(true);
        this.setModalState(MEDIA_ACCESS_MODAL_STATE.ACCESS_SELECT);
    }

    checkForVoucher(): void {
        if (this.streamService?.viewerVouchers?.length) {
            // tslint:disable-next-line:no-shadowed-variable
            const voucher = this.streamService.viewerVouchers.find(voucher => voucher.productId === this.mediaId);
            if (voucher) {
                this.canActivateVoucher = false;
                this.mediaPrice = voucher.discount.newPrice;
                this.activeVoucher = voucher;
                // this.privacyPolicyForm.get('privacyPolicy').setValue(true);
                this.setModalState(MEDIA_ACCESS_MODAL_STATE.ONLINE_PAYMENT);

            }
        }
    }

    onSuccessPayment(openRegisterModal?: boolean): void {
        if (!this.isAuthorized && openRegisterModal) {
            this.setModalState(MEDIA_ACCESS_MODAL_STATE.REGISTRATION_STEP_1);
            this.openAccessModal$.emit();
        }
    }

    onCloseLandscapeMode(): void {
        this.closeLandscapeMode$.emit();
    }

    initForms(): void {
        this.voucherForm = this.fb.group({
            mediaId: [this.mediaId, Validators.required],
            code: ['', Validators.required]
        });

        this.passwordForm = this.fb.group({
            password: ['', Validators.required]
        });

        this.userLoginForm = this.fb.group({
            email: ['', [CustomValidators.email, Validators.required]],
            password: ['', [Validators.required]]
        });

        const passwordControl = new FormControl('',
            [Validators.required, CustomValidators.password]);

        this.viewerRegisterForm = this.fb.group({
            email: ['', [CustomValidators.email, Validators.required]],
            firstName: ['', [Validators.required]],
            lastName: ['', [Validators.required]],
            password: passwordControl,
            passwordConfirmation: ['', Validators.compose([CustomValidators.passwordsMatch(passwordControl)])]
        });

        const videoTypeUrl = this.productType === MEDIA_TYPE.STREAM ? MEDIA_TYPE.STREAM.toLowerCase() : 'vod';

        this.leadGenerationForm = this.fb.group({
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            city: ['', Validators.required],
            country: ['', Validators.required],
            postalCode: ['', Validators.required],
            privatePhone: [''],
            redirectLink: [`https://${window.location.hostname}/watch/${videoTypeUrl}/${this.mediaId}`],
            customFieldValue: [''],
            customFieldName: [''],
            customFieldOn: [''],
            publisherId: [''],
            mediaId: [''],
        });

        this.privacyPolicyForm = this.fb.group({
            privacyPolicy: ['', Validators.requiredTrue]
        });
    }

    loginUser(): void {
        this.userLoginForm.markAllAsTouched();
        if (this.currentModalState === MEDIA_ACCESS_MODAL_STATE.COURSES_STEP_1 && !this.termsAgreed()) {
            this.checkModel.firstTermsError = !this.checkModel.firstTerms;
            this.checkModel.secondTermsError = !this.checkModel.secondTerms;
            return;
        }

        if (this.userLoginForm.invalid) {
            return;
        }

        this.loadingService.loadingStart();

        this.authService.login(this.userLoginForm.value)
            .pipe(
                takeUntil(this.destroy$),
                catchError((error) => of(error))
            ).subscribe(response => {
            if (response && response.success) {
                this.userLoginForm.get('password').setErrors(null);
                this.setFormLead();

                this.checkRemindAfterLogin();
                this.checkOnlyForRegistered();
                if (this.currentModalState === MEDIA_ACCESS_MODAL_STATE.COURSES_STEP_1) {
                    this.currentModalState = MEDIA_ACCESS_MODAL_STATE.ACCESS_SELECT;
                    this.checkModel.firstTerms = false;
                    this.checkModel.secondTerms = false;
                }
                this.getMedia$.emit();
            } else {
                if (response.errorKey === 'NAME_OR_PASSWORD_MISMATCH') {
                    this.userLoginForm.get('password').setErrors({nameOrPasswordMismatch: true});
                }
                this.loadingService.loadingEnd();
            }
        });
    }

    /**
     * Returns true if all required checkboxes are checked, or false otherwise.
     */
    private termsAgreed(): boolean {
        const requiredCheckboxes = [];
        if (this.currentModalState === MEDIA_ACCESS_MODAL_STATE.INFO && this.isAuthorized) {
            requiredCheckboxes.push(this.checkModel.secondTerms);
        } else {
            requiredCheckboxes.push(this.checkModel.firstTerms);
            requiredCheckboxes.push(this.checkModel.secondTerms);
        }
        if (this.selectedPayment === MEDIA_ACCESS_MODAL_STATE.LEAD_GENERATION) {
            if (this.leadTextField1) {
                requiredCheckboxes.push(this.checkModel.leadTextField1);
            }
            if (this.leadTextField2) {
                requiredCheckboxes.push(this.checkModel.leadTextField2);
            }
        }
        return requiredCheckboxes.every((check) => check);
    }

    cancelAccessMethodSelection(): void {
        this.registrationCanceled = true;
        if (this.userHasAccess) {
            this.setModalState(MEDIA_ACCESS_MODAL_STATE.ACCESS_SELECT);
            return;
        }

        this.goPrevState();
    }

    setModalState(mediaAccessType: MEDIA_ACCESS_MODAL_STATE): void {
        this.prevState.push(this.currentModalState);
        this.currentModalState = mediaAccessType;

        if (this.currentModalState === MEDIA_ACCESS_MODAL_STATE.LEAD_GENERATION_REGISTERED) {
            this.payLeadGeneration();
        }

        if (this.currentModalState === MEDIA_ACCESS_MODAL_STATE.ACCESS_SELECT
            || this.currentModalState === MEDIA_ACCESS_MODAL_STATE.COURSES_STEP_1
        ) {
            this.prevState = [];
        }

        if (this.currentModalState === MEDIA_ACCESS_MODAL_STATE.COURSES_STEP_1) {
            if (this.isAuthorized) {
                this.nextState = MEDIA_ACCESS_MODAL_STATE.ONLINE_PAYMENT;
            } else {
                this.nextState = MEDIA_ACCESS_MODAL_STATE.REGISTRATION_STEP_1;
            }
        }

        if (this.prevState[this.prevState.length - 1] === MEDIA_ACCESS_MODAL_STATE.REMIND_ME_NO_AUTHORIZED) {
            this.remindState.isButtonClick = true;
        }

    }

    // tslint:disable-next-line:max-line-length
    private getPaymentBody(result: IHeidelpayPaymentDetails | IPaymentCard, isNewCardPayment = false, type: PAYMENT_BODY_TYPES, id?: string): IMediaPaymentRequest {
        const videoTypeUrl = this.productType === MEDIA_TYPE.STREAM ? MEDIA_TYPE.STREAM.toLowerCase() : 'vod';
        const pageType = this.playerMode === PLAYER_DISPLAY_MODE.EMBEDDED ? 'embedded' : 'watch';
        const email = (result as IPaymentRequest).email || '';
        const firstName = (result as IPaymentRequest)?.firstName || (this.isAuthorized && this.userProfile?.firstName) || '';
        const lastName = (result as IPaymentRequest)?.lastName || (this.isAuthorized && this.userProfile?.lastName)  || '';
        const returnUrl = `https://${window.location.hostname}/${pageType}/${videoTypeUrl}/${this.mediaId}`;
        // const returnUrl = `http://${window.location.hostname}:4200/${pageType}/${videoTypeUrl}/${this.mediaId}`;

        const body: IMediaPaymentRequest = {
            returnUrl: returnUrl,
            successUrl: returnUrl,
            failUrl: returnUrl,
            email,
            firstName,
            lastName,
        };

        switch (type) {
            case PAYMENT_BODY_TYPES.GROUP:
                body.groupId = id;
                break;
            case PAYMENT_BODY_TYPES.MEDIA:
                body.productId = this.mediaId;
                body.videoType = this.productType;
                break;
            case PAYMENT_BODY_TYPES.SLOT:
                body.mediaId = this.mediaId;
                body.paymentSlotId = id;
                break;
        }

        if (isNewCardPayment) {
            const card = (result as IHeidelpayPaymentDetails).card;
            if (this.isAuthorized && type !== PAYMENT_BODY_TYPES.GROUP && type !== PAYMENT_BODY_TYPES.SLOT) {
                body.saveCard = true;
            }
            if (card.method === 'card') {
                body.paymentMethod = {
                    method: card.method,
                    resourceId: card.id,
                    paymentCard: {
                        externalCardId: card.id,
                        expiryDate: card.expiryDate,
                        type: card.brand,
                        number: card.number.slice(-4),
                        cardDetails: {
                            id: card.id,
                            brand: card.brand,
                            cvc: card.cvc,
                            expiryDate: card.expiryDate,
                            number: card.number.slice(-4),
                            method: card.method,
                            account: card.cardDetails.account,
                            cardType: card.cardDetails.cardType,
                            countryIsoA2: card.cardDetails.countryIsoA2,
                            countryName: card.cardDetails.countryName,
                            issuerName: card.cardDetails.issuerName,
                            issuerPhoneNumber: card.cardDetails.issuerPhoneNumber,
                            issuerUrl: card.cardDetails.issuerUrl,
                            '3ds': card['3ds']
                        }
                    }
                };
            } else {
                body.paymentMethod = {
                    resourceId: card.id,
                    method: card.method
                };
            }

        } else {
            body.paymentMethod = {
                userCardId: (result as IPaymentCard).id,
                resourceId: (result as IPaymentCard).externalCardId,
                method: 'card'
            };
        }

        if (this.activeVoucher?.code) {
            body.voucherCode = this.activeVoucher.code;
        }

        if (this.streamService.viewerAccessToken) {
            body.accessToken = this.streamService.viewerAccessToken;
        }

        return body;
    }

    payForSlot(paymentCard: IHeidelpayPaymentDetails | IPaymentCard, isNewCardPayment = false, slotId: string): void {
        this.uiLoadingService.loadingStart();
        const body: IMediaPaymentRequest = this.getPaymentBody(paymentCard, isNewCardPayment, PAYMENT_BODY_TYPES.SLOT, slotId);

        this.paymentService.proceedSlotPayment(body).pipe(
            takeUntil(this.destroy$),
            catchError(error => of(error))
        ).subscribe(response => {
            switch (response?.results?.result?.paymentStatus) {
                case PAYMENT_STATUS.FAILED: {
                    this.paymentService.failedTransactionInfo.next({
                        failedCardId: response.results.result.externalCardId,
                        transactionFailReason: response.results.result.reason || PAYMENT_FAIL_MESSAGES.FAILED
                    });

                    this.failureSlotPayment(this.resolvePaymentMethod(body.paymentMethod.method), slotId);
                    this.uiLoadingService.loadingEnd();
                    break;
                }
                case PAYMENT_STATUS.PENDING: {
                    if (response?.results?.result?.redirectUrl) {
                        window.location.href = response.results.result.redirectUrl;
                    }
                    break;
                }
                case PAYMENT_STATUS.SUCCESS: {
                    this.uiLoadingService.loadingEnd();
                    this.onSuccessPayment(true);
                    this.getMedia$.emit();
                    break;
                }
            }
        });

    }

    payForGroup(paymentCard: IHeidelpayPaymentDetails | IPaymentCard, isHeidelpayPayment = false, groupId: string) {
        this.uiLoadingService.loadingStart();
        const body = this.getPaymentBody(paymentCard, isHeidelpayPayment, PAYMENT_BODY_TYPES.GROUP, groupId);

        this.paymentService.getAccessToGroup(body).pipe(take(1)).subscribe(response => {
            switch (response?.results?.result?.paymentStatus) {
                case PAYMENT_STATUS.FAILED: {
                    const failedInfo = {
                        failedCardId: response?.results?.result?.externalCardId,
                        transactionFailReason: response?.results?.result?.reason || PAYMENT_FAIL_MESSAGES.FAILED,
                        absoluteError: true
                    };
                    this.paymentService.failedTransactionInfo.next(failedInfo);
                    this.failureGroupPayment(this.resolvePaymentMethod(body.paymentMethod.method), groupId);
                    this.uiLoadingService.loadingEnd();
                    break;
                }
                case PAYMENT_STATUS.PENDING: {
                    if (response?.results?.result?.redirectUrl) {
                        window.location.href = response.results.result.redirectUrl;
                    }
                    break;
                }
                case PAYMENT_STATUS.SUCCESS: {
                    this.uiLoadingService.loadingEnd();
                    this.onSuccessPayment(true);
                    this.getMedia$.emit();
                    break;
                }
            }
        });
    }

    getVoucherError(reason: VOUCHER_ACTIVATION_REJECT_REASONS): string {
        switch (reason) {
            case VOUCHER_ACTIVATION_REJECT_REASONS.EXPIRED: {
                return 'voucherExpired';
            }
            case VOUCHER_ACTIVATION_REJECT_REASONS.INACTIVE: {
                return 'voucherInactive';
            }
            case VOUCHER_ACTIVATION_REJECT_REASONS.NOT_FOUND: {
                return 'voucherNotFound';
            }
            case VOUCHER_ACTIVATION_REJECT_REASONS.REDEEMED: {
                return 'voucherRedeemed';
            }
            default: {
                return 'voucherNotFound';
            }
        }
    }

    redeemVoucher(withVoucher: boolean = false): void {
        this.voucherForm.get('code').setValue(this.voucherForm.get('code').value.trim());
        this.voucherForm.markAllAsTouched();

        if (this.voucherForm.invalid) {
            return;
        }

        this.loadingService.loadingStart();

        const payload = this.voucherForm.value;
        if (withVoucher && this.slot && !this.slot.hasAccess && this.slot.slotPayment === PAYMENT_SYSTEM.PAY_PER_VIEW) {
            payload.paymentSlotId = this.slot.id;
        }
        this.streamService.redeemVoucher(payload)
            .pipe(takeUntil(this.destroy$), catchError(error => of({...error, success: false})))
            .subscribe(response => {
                if (response?.success) {
                    if (response?.results?.result?.status === VOUCHER_ACTIVATION_STATUSES.SUCCESS_ACCESS) {
                        this.voucherRedeemed = true;
                        if (this.slot) {
                            this.slot.hasAccess = true;
                        }
                        this.onSuccessPayment(true);
                        this.isUsedVoucher = true;
                        this.voucherForm.get('code').reset();
                    }
                    if (response?.results?.result?.status === VOUCHER_ACTIVATION_STATUSES.SUCCESS_DISCOUNT) {
                        this.cancelAccessMethodSelection();
                        this.checkForVoucherNew(response?.results?.result);
                        this.voucherForm.get('code').reset();
                    }
                    if (response?.results?.result?.status === VOUCHER_ACTIVATION_STATUSES.VOUCHER_INVALID) {
                        this.voucherForm.get('code').setErrors({[this.getVoucherError(response?.results?.result?.reason)]: true});
                    }
                }
                this.loadingService.loadingEnd();
            });
    }

    registerStep2(): void {
        if (this.currentModalState === MEDIA_ACCESS_MODAL_STATE.REGISTRATION_STEP_2) {
            this.clearViewerFromValidators();
        } else {
            this.addViewerFromValidators();
        }
        this.viewerRegisterForm.markAllAsTouched();
        this.privacyPolicyForm.markAllAsTouched();

        if (this.viewerRegisterForm.invalid || this.privacyPolicyForm.invalid) {
            return;
        }

        const viewerRegisterFormValue: IViewerCredentials = this.viewerRegisterForm.value;

        delete viewerRegisterFormValue.passwordConfirmation;

        if (this.streamService.viewerAccessToken) {
            viewerRegisterFormValue.accessToken = this.streamService.viewerAccessToken;
        }

        const _viewerRegisterFormValue: IViewerCredentials = _.cloneDeep(viewerRegisterFormValue);
        delete _viewerRegisterFormValue?.accessToken;
        delete _viewerRegisterFormValue?.passwordConfirmation;
        const leadGenerationFormValue: ILeadGenerationForm = Object.assign(_viewerRegisterFormValue, this.leadGenerationForm.value);
        leadGenerationFormValue.email = _viewerRegisterFormValue.email;
        leadGenerationFormValue.customFieldName = this.customFieldName;
        leadGenerationFormValue.customFieldOn = this.customFieldOn;
        leadGenerationFormValue.publisherId = this.data?.video?.owner;
        leadGenerationFormValue.mediaId = this.data?.video?.id;

        // if (this.registerSystem === PAYMENT_SYSTEM.LEAD_GENERATION) {
        //     if (this.leadGenerationMedia && this.currentModalState === MEDIA_ACCESS_MODAL_STATE.REGISTRATION_STEP_2) {
        //         this.registerLeadGenerationViewer(leadGenerationFormValue);
        //         return;
        //     }
        // }

        this.loadingService.loadingStart();

        this.authService.registerViewer(leadGenerationFormValue).pipe(
            takeUntil(this.destroy$),
            catchError(error => of(error))
        ).subscribe(response => {
            if (response && response.success) {
                localStorage.removeItem('viewerTempInfo');
                this.userAlreadyExistsError = false;
                this.checkOnlyForRegistered();
                if (this.registerSystem === PAYMENT_SYSTEM.LEAD_GENERATION) {
                    this.setModalState(MEDIA_ACCESS_MODAL_STATE.LEAD_GENERATION_REGISTERED);
                } else {
                    this.getMedia$.emit();
                    this.setModalState(MEDIA_ACCESS_MODAL_STATE.ACCESS_SELECT);
                }

                if (this.remindState.isButtonClick) {
                    this.toggleRemind(true);
                }

            } else if (response.errorKey === 'REGISTRATION_EMAIL_ALREADY_EXISTS') {
                this.setModalState(MEDIA_ACCESS_MODAL_STATE.REGISTRATION_STEP_2);
                this.viewerRegisterForm.get('email').setErrors({REGISTRATION_EMAIL_ALREADY_EXISTS: true});
            }
            this.loadingService.loadingEnd();
        });
    }

    private clearViewerFromValidators(): void {
        this.viewerRegisterForm.get('firstName').clearValidators();
        this.viewerRegisterForm.get('firstName').updateValueAndValidity();
        this.viewerRegisterForm.get('lastName').clearValidators();
        this.viewerRegisterForm.get('lastName').updateValueAndValidity();
    }

    private addViewerFromValidators(): void {
        this.viewerRegisterForm.get('firstName').setValidators([Validators.required]);
        this.viewerRegisterForm.get('firstName').updateValueAndValidity();
        this.viewerRegisterForm.get('lastName').setValidators([Validators.required]);
        this.viewerRegisterForm.get('lastName').updateValueAndValidity();
    }

    registerLeadGenerationViewer(formToSend: ILeadGenerationForm): void {
        this.loadingService.loadingStart();
        this.streamService.registerLeadGeneration(formToSend).pipe(takeUntil(this.destroy$)).subscribe(res => {
            if (res.success) {
                localStorage.setItem(`leadGeneration${this.mediaId}`, 'true');
                this.setModalState(MEDIA_ACCESS_MODAL_STATE.LEAD_GENERATION_REGISTERED);
            }
            if (!res.success && res.errorKey === 'REGISTRATION_EMAIL_ALREADY_EXISTS') {
                this.viewerRegisterForm.get('email').setErrors({[res.errorKey]: true});
            }
            this.loadingService.loadingEnd();
        });
    }

    registrationStep1(): void {
        // if (this.isAuthorized) {
        //     const leadGenerationFormValue: ILeadGenerationForm = this.leadGenerationForm.value;
        //     leadGenerationFormValue.firstName = this.userProfile.firstName;
        //     leadGenerationFormValue.lastName = this.userProfile.lastName;
        //     leadGenerationFormValue.city = this.userProfile.city;
        //     leadGenerationFormValue.country = this.userProfile.country;
        //     leadGenerationFormValue.email = this.userProfile.email;
        //     leadGenerationFormValue.postalCode = this.userProfile.postalCode;
        //     leadGenerationFormValue.privatePhone = this.userProfile.privatePhone;
        //
        //     this.registerLeadGenerationViewer(leadGenerationFormValue);
        //     return;
        // }

        this.leadGenerationForm.markAllAsTouched();
        if (this.leadGenerationForm.invalid) {
            return;
        }
        this.setModalState(MEDIA_ACCESS_MODAL_STATE.REGISTRATION_STEP_2);
    }

    private saveViewerInfo(email: string, firstName: string, lastName: string): void {
        localStorage.setItem('viewerTempInfo', JSON.stringify({email, firstName, lastName}));
    }

    private getViewerInfoFromLS(): { email: string, firstName: string, lastName: string } {
        const res = JSON.parse(localStorage.getItem('viewerTempInfo'));
        return res;
    }

    showPopUp(event) {
        event.stopPropagation();
        this.configModalPublisher.title = 'media-access-modal.privacy-policy.right-of-withdrawal';
        this.configModalPublisher.htmlText = this.termsConditions?.withdrawal || this.localizationProvider.getByKey('profile.termsAndConditions.withdrawalText');
        this.configModalPublisher.isOpened = true;
    }

    closePopUp() {
        // this.streamService.playerAccessModalShow$.next({state: false});
        this.configModalPublisher.isOpened = false;
    }

    private listenModalPublisher() {
        this.streamService.playerAccessModalShow$.subscribe((res: IPlayerAccessModal) => {
            if (!res) {
                return;
            }
            this.configModalPublisher.htmlText = res.data;
            this.setModalState(MEDIA_ACCESS_MODAL_STATE.IMPRESSUM);
        });
    }

    onDownloadIcs(): void {
        this.streamService.getStreamSchedule(this.data.video.id)
            .subscribe(res => {
                saveAs(
                    res.body,
                    this.data.video.title.replace(/\s+/g, '_').trim() + '.ics'
                );
            });
    }

    get isShowIcs(): boolean {
        return this.data.video.status.stream === STREAM_STATUS.SCHEDULED;
    }

    goForgotPassword() {
        this.headerService.showHeaderPopup(HeaderPopupType.FORGOT_PASSWORD);
    }

    showInfo(ONLINE_PAYMENT: MEDIA_ACCESS_MODAL_STATE) {
        this.selectedPayment = ONLINE_PAYMENT;

        if (ONLINE_PAYMENT === MEDIA_ACCESS_MODAL_STATE.LEAD_GENERATION) {
            ONLINE_PAYMENT = MEDIA_ACCESS_MODAL_STATE.REGISTRATION_STEP_1;
            this.registerSystem = PAYMENT_SYSTEM.LEAD_GENERATION;
        }

        this.prevState.push(this.currentModalState);
        this.currentModalState = MEDIA_ACCESS_MODAL_STATE.INFO;
        if (ONLINE_PAYMENT === MEDIA_ACCESS_MODAL_STATE.ONLINE_PAYMENT && this.data?.video.paymentSystem.paymentSystemGroup === PAYMENT_SYSTEM.COURSE_ACCESS) {
            this.nextState = MEDIA_ACCESS_MODAL_STATE.COURSES_STEP_1;
        } else {
            this.nextState = ONLINE_PAYMENT;
        }
        // this.showAccessModal$.emit(true);
    }

    goNextStep() {
        switch (this.nextState) {
            case MEDIA_ACCESS_MODAL_STATE.COURSES_STEP_1: {
                if (!this.isAuthorized) {
                    this.nextState = MEDIA_ACCESS_MODAL_STATE.REGISTRATION_STEP_1;
                } else {
                    this.nextState = MEDIA_ACCESS_MODAL_STATE.COURSES_STEP_1;
                }
            }
                break;
            case MEDIA_ACCESS_MODAL_STATE.REGISTRATION_STEP_1: {
                if (this.isAuthorized) {
                    this.nextState = MEDIA_ACCESS_MODAL_STATE.LEAD_GENERATION_REGISTERED;
                }
            }
                break;
            default:
                break;
        }

        if (this.currentModalState === MEDIA_ACCESS_MODAL_STATE.REGISTRATION_STEP_1 && this.isUsedVoucher) {
            this.isUsedVoucher = false;
        }


        this.setModalState(this.nextState);
    }

    changeCheck(key: string) {
        this.checkModel[`${key}Error`] = !this.checkModel[key];
        // tslint:disable-next-line:max-line-length
        if (this.termsAgreed() || (this.isAuthorized && this.termsAgreed() && this.selectedPayment !== MEDIA_ACCESS_MODAL_STATE.LEAD_GENERATION)) {
            this.checkModel.firstTerms = false;
            this.checkModel.secondTerms = false;
            this.checkModel.leadTextField1 = false;
            this.checkModel.leadTextField2 = false;
            if (this.currentModalState === MEDIA_ACCESS_MODAL_STATE.COURSES_STEP_1 && !this.isAuthorized) {
                this.checkModel.firstTerms = true;
                this.checkModel.secondTerms = true;
                this.setCoursesTermsAgreed();
                return;
            }
            this.goNextStep();
        }
    }

    private setCoursesTermsAgreed(): void {
        localStorage.setItem(`courseAgreed_${this.mediaId}`, 'true');
    }

    private isCoursesTermsAgreed(): boolean {
        return asBoolean(localStorage.getItem(`courseAgreed_${this.mediaId}`));
    }

    coursesRegister(): void {
        if (!this.termsAgreed()) {
            this.checkModel.firstTermsError = !this.checkModel.firstTerms;
            this.checkModel.secondTermsError = !this.checkModel.secondTerms;
            return;
        }
        this.checkModel.firstTerms = false;
        this.checkModel.secondTerms = false;
        this.goNextStep();
    }

    // getUserPhoto() {
    //     return this.userService.getUserPhoto();
    // }

    logout() {
        this.authService.logout(true, false);
        this.checkForCourses();
        this.checkRemindAfterLogout();
    }

    goRegistration() {
        this.setModalState(MEDIA_ACCESS_MODAL_STATE.REGISTRATION_STEP_1);
    }

    goBack() {
        if (this.backAction) {
            this.remindService.showViewerAccessModal$.next(false);
            this.setModalState(MEDIA_ACCESS_MODAL_STATE.ACCESS_SELECT);
            return;
        }

        if (this.currentModalState === MEDIA_ACCESS_MODAL_STATE.IMPRESSUM && this.userHasAccess) {
            this.setModalState(MEDIA_ACCESS_MODAL_STATE.ACCESS_SELECT);
            this.closePopUp();
            return;
        }

        // tslint:disable-next-line:max-line-length
        if (this.userHasAccess && (this.currentModalState === MEDIA_ACCESS_MODAL_STATE.REGISTRATION_STEP_1 || this.currentModalState === MEDIA_ACCESS_MODAL_STATE.REGISTRATION_STEP_2)) {
            this.setModalState(MEDIA_ACCESS_MODAL_STATE.ACCESS_SELECT);
            this.userHasAccess = false;
            this.registrationCanceled = true;
            this.closeAccessModal$.emit();
            return;
        }

        if (this.userHasAccess && this.currentModalState !== MEDIA_ACCESS_MODAL_STATE.REGISTRATION_STEP_2) {
            this.setModalState(MEDIA_ACCESS_MODAL_STATE.ACCESS_SELECT);
            return;
        }
        this.closeRegistrationOffer$.emit();
        this.goPrevState();
    }

    private setFormLead() {
        if (this.isAuthorized) {
            this.userService.userProfile$.pipe(takeUntil(this.destroy$)).subscribe(userProfile => {
                if (userProfile) {
                    const user = Object.assign(userProfile, userProfile.address);
                    this.leadGenerationForm.patchValue(user);
                }
            });
        }
    }

    private checkForCourses() {
        if (this.data?.video.paymentSystem.paymentSystemGroup === PAYMENT_SYSTEM.COURSE_ACCESS && !this.isCoursesTermsAgreed()) {
            this.setModalState(MEDIA_ACCESS_MODAL_STATE.COURSES_STEP_1);
        }
    }

    private checkOnlyForRegistered() {
        if (!this.isAuthorized && this.data?.advancedSettings?.onlyForRegistered) {
            if (this.data?.publisher?.customFieldOn) {
                this.leadGenerationForm.get('customFieldValue').setValidators([Validators.required]);
            }
            this.setModalState(MEDIA_ACCESS_MODAL_STATE.ONLY_FOR_REGISTERED);
        }
    }

    private goPrevState() {
        this.currentModalState = this.prevState[this.prevState.length - 1];
        this.prevState.pop();
    }

    private payLeadGeneration() {
        console.log('pay lead');
        this.loadingService.loadingStart();
        const body = {
            mediaId: this.mediaId,
            marketingPurposes: true,
            termsConditions: true
        };

        this.streamService.payLeadGeneration(body).pipe(takeUntil(this.destroy$)).subscribe(res => {
            if (res.success) {
                this.getMedia$.emit();
                // localStorage.setItem(`leadGeneration${this.mediaId}`, 'true');
                // this.setModalState(MEDIA_ACCESS_MODAL_STATE.LEAD_GENERATION_REGISTERED);
                // this.onSuccessPayment();
            }
            if (!res.success && res.errorKey === 'REGISTRATION_EMAIL_ALREADY_EXISTS') {
                this.viewerRegisterForm.get('email').setErrors({[res.errorKey]: true});
            }
            this.loadingService.loadingEnd();
        });
    }

    goRemindMe() {
        if (!this.isAuthorized) {
            this.setModalState(MEDIA_ACCESS_MODAL_STATE.REMIND_ME_NO_AUTHORIZED);
            return;
        }
        this.toggleRemind(!this.remindService.state.active);
    }

    checkRemindAfterLogin() {
        if (this.currentModalState !== MEDIA_ACCESS_MODAL_STATE.REMIND_ME_NO_AUTHORIZED) {
            return;
        }

        this.toggleRemind(true);
    }

    toggleRemind(active) {
        this.remindService.active(this.mediaId, {active: active})
            .pipe(takeUntil(this.destroy$))
            .subscribe(res => {
                this.remindService.state.active = active;
                this.remindService.changedStateActive$.next(active);
            });
    }

    initRemindState() {
        this.remindService.state.active = this.data?.reminders.viewerRemindersEnabled;
    }

    private checkRemindAfterLogout() {
        this.remindService.state.active = false;
    }

    public openGroupModal(): void {
        this.closeAccessModal();
        this.openGroupModal$.emit();
    }

    public openSlotModal(): void {
        this.openSlotModal$.emit();
        this.componentsInitSub?.unsubscribe();
    }

    public closeAccessModal(): void {
        if (this.currentModalState === MEDIA_ACCESS_MODAL_STATE.IMPRESSUM) {
            this.setModalState(MEDIA_ACCESS_MODAL_STATE.ACCESS_SELECT);
            this.closePopUp();
        }
        this.closeRegistrationOffer$.emit();
        this.closeAccessModal$.emit();
    }

    private initListeners() {
        this.listenModalPublisher();
        this.listenModalRemind();
        this.listenAuth();
        this.listenGroupPayment();
        this.listenSlotPayment();
        this.listenAllMediaPayment();
        this.listenRedirectParams();
        this.listenGroupsRedirect();
    }

    private listenGroupsRedirect(): void {
        this.mediaGroupsService.activeAccessModal$.pipe(takeUntil(this.destroy$))
          .subscribe(modalType => {
              if (modalType === MEDIA_ACCESS_MODAL_STATE.REGISTRATION_STEP_1) {
                  this.openAccessModal$.emit();
                  this.goRegistration();
              }
          });
    }

    private listenModalRemind() {
        this.remindService.showViewerAccessModal$
            .pipe(takeUntil(this.destroy$))
            .subscribe(res => {
                if (!res) {
                    return;
                }
                this.goRemindMe();
                this.backAction = true;
            });
    }

    private listenAuth() {
        this.authService.isAuthorized$.subscribe((res) => {
            this.isAuthorized = res;
            if (this.isAuthorized) {
                this.setModalState(MEDIA_ACCESS_MODAL_STATE.ACCESS_SELECT);
            }
            this.initUserProfile();
            this.checkForCourses();
        });
    }

    private listenRedirectParams() {
        this.activatedRoute.queryParams
            .pipe(takeUntil(this.destroy$))
            .subscribe(params => {
                if (_.isEmpty(params)) {
                    return;
                }

                this.queryParams = params;
                this.isUsedVoucher = params.hasOwnProperty('voucherId');

                if (params.hasOwnProperty('viewer-reg-token')) {
                    this.confirmEmail(params);
                    return;
                }

                if (params.hasOwnProperty('email')) {
                    this.viewerRegisterForm.get('email').setValue(params['email']);
                }

                if (params.hasOwnProperty('transactionId')) {
                    this.checkTransactionStatus(params['paymentMethodType'], params['transactionId']);
                }

                this.checkIfLeadGenerationMedia();
                this.restoreViewerInfoFromLS();
                clearQueryParams(this.location);
        });
    }

    private confirmEmail(params) {
        this.streamService.registerViewerConfirmEmail({token: params['viewer-reg-token']})
            .pipe(takeUntil(this.destroy$))
            .subscribe((res) => {
                if (!res.success) {
                    this.router.navigate(['invalid-token']);
                    return;
                }
                this.authService.storeToken(res.results.authToken);
                clearQueryParams(this.location);
            },
            () => this.router.navigate(['invalid-token'])
        );
    }

    private restoreViewerInfoFromLS() {
        const viewerInfo = this.getViewerInfoFromLS();
        if (viewerInfo) {
            this.viewerRegisterForm.get('email').setValue(viewerInfo.email);
            this.viewerRegisterForm.get('firstName').setValue(viewerInfo.firstName);
            this.viewerRegisterForm.get('lastName').setValue(viewerInfo.lastName);
        }
    }

    private initUserProfile() {
        if (this.isAuthorized) {
            this.userProfile =  this.userService.userProfile;
            this.userPhoto = this.userProfile?.profilePhoto?.photoUrl ? environment?.backendApiHost + this.userProfile?.profilePhoto?.photoUrl : '';
            this.userFirstName = this.userProfile?.firstName || '';
            this.userLastName = this.userProfile?.lastName || '';
            this.userEmail = this.userProfile?.email || '';
        }
    }


    private listenGroupPayment() {
        this.paymentService.groupPayment$.pipe(takeUntil(this.destroy$)).subscribe((event: IGroupPayment) => {
            this.payForGroup(event.paymentCard, event.isNewCardPayment, event.groupId);
        });

        this.mediaGroupsService.groupPaymentSuccess$
            .pipe(takeUntil(this.destroy$))
            .subscribe((id: string) => {
                if (!id) {
                    return;
                }
                this.onSuccessPayment();
                this.mediaGroupsService.successfulPayment(null);
            });
    }

    private listenSlotPayment() {
        this.paymentService.slotPayment$.pipe(takeUntil(this.destroy$)).subscribe((event: ISlotPayment) => {
            this.payForSlot(event.paymentCard, event.isNewCardPayment, event.slotId);
        });

        this.slotsState.paymentSuccess$.pipe(takeUntil(this.destroy$))
            .subscribe((id: string) => {
                if (!id) {
                    return;
                }
                this.onSuccessPayment();
                this.slotsState.successfulPayment(null);
            });
    }

    private listenAllMediaPayment() {
        this.slotsState.paymentForAllMedia$.pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                this.openAccessModal$.emit();
                this.showInfo(MEDIA_ACCESS_MODAL_STATE.ONLINE_PAYMENT);
            });
    }
}

export interface IPaymentRequest {
    card: IHeidelpayCard;
    email: string;
    firstName?: string;
    lastName?: string;
}

export enum CURRENCY_SYMBOLS {
    EUR = 'EUR',
    USD = 'USD'
}

export const CURRENCY_VALUES = {
    '€': 'EUR',
    '$': 'USD'
};

export enum MEDIA_ACCESS_MODAL_STATE {
    LEAD_GENERATION = 'LEAD_GENERATION',
    ACCESS_SELECT = 'ACCESS_SELECT',
    ONLINE_PAYMENT = 'ONLINE_PAYMENT',
    SLOT_PAYMENT = 'SLOT_PAYMENT',
    VOUCHER_ACTIVATION = 'VOUCHER_ACTIVATION',
    SLOT_VOUCHER_ACTIVATION = 'SLOT_VOUCHER_ACTIVATION',
    REGISTRATION_STEP_1 = 'REGISTRATION_STEP_1',
    REGISTRATION_STEP_2 = 'REGISTRATION_STEP_2',
    COURSES_STEP_1 = 'COURSES_STEP_1',
    LEAD_GENERATION_AUTHORIZED = 'LEAD_GENERATION_AUTHORIZED',
    LEAD_GENERATION_REGISTERED = 'LEAD_GENERATION_REGISTERED',
    REMIND_ME_NO_AUTHORIZED = 'REMIND_ME_NO_AUTHORIZED',
    IMPRESSUM = 'IMPRESSUM',
    INFO = 'INFO',
    ONLY_FOR_REGISTERED = 'ONLY_FOR_REGISTERED',
}

export const ACCESS_MODAL_STATE = {
    REGISTRATION_COURSES_STEP_1: {
        title: 'media-access-modal.title.courses',
        buttons: {
            back: true
        },
        panel: {
            right: {
                show: true
            }
        }
    }
};

export interface ILeadGenerationForm {
    city: string;
    country: string;
    firstName: string;
    lastName: string;
    email?: string;
    password?: string;
    privatePhone: string;
    postalCode: number;
    customFieldName?: string;
    customFieldOn?: boolean;
    customFieldValue?: string;
    publisherId?: string;
    mediaLink: string;
    mediaId?: string;
}

export enum PAYMENT_BODY_TYPES {
    MEDIA,
    SLOT,
    GROUP
}
