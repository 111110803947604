<app-stream-multimedia-upload
        multimediaType="video"
        [metadata]="video"
        [isDataUploaded]="isDataUploaded"
        [isVideoActive]="isVideoActive"
        [multimediaUrl]="video?.videoUrl"
        [textHeaders]="textHeaders"
        [formsConfig]="formsConfig"
        (updateMultimedia$)="updateVideo($event)"
        (uploadMultimedia$)="uploadVideo($event)"
        (clearMultimedia$)="clearVideo($event)"
        (toggleVideoActive$)="toggleVideoActive($event)"
        (save$)="save()"
        [disabled]="(streamService.stream$ | async)?.media?.deactivated || ((streamService.stream$ | async)?.media?.status?.stream === STREAM_STATUS.FINISHED && !(streamService.stream$ | async)?.media?.hasRecording) || ((streamService.stream$ | async)?.media?.status?.stream === STREAM_STATUS.PENDING)"
>
</app-stream-multimedia-upload>
