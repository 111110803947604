<div class="panel mb-30">
    <div class="panel-header">
        <h2 class="title-lg"><b>{{'subaccounts.title' | localize}}</b></h2>
        <p class="page-description min-width-100">{{'subaccounts.description' | localize}}</p>
    </div>
    <div class="panel-body bg-white padding-top-20 padding-bottom-20">
        <ano-table class="subaccounts-table" #tableComponent [tableConfig]="tableConfig"
                   [withSearch]="true"
                   [buttonsConfig]="[tableButtonConfig]"
                   [defaultSortField]="'firstName'"
                   (search$)="search($event)"
                   (addItem$)="addItem()"
        ></ano-table>
    </div>
</div>

<ng-template #actionsTmpl let-data="rowData">
    <div class="table-actions-list">
<!--        <b class="text-primary">-->
<!--            <ng-container>-->
<!--            <i (click)="navigateToAccount(data.ownerId)"-->
<!--               class="icon icon-edit cursor-pointer"></i>-->
<!--            </ng-container> |-->
<!--        </b>-->
        <b class="text-primary">
            <i (click)="delete(data.ownerId)"
               class="text-danger icon icon-trash cursor-pointer"></i>
        </b>
    </div>
</ng-template>

<ng-template #statusTmpl let-data="rowData">
    <i *ngIf="!data.subAccount.accepted " class="icon icon-waiting text-primary"></i>
    <i *ngIf="data.subAccount.accepted " class="icon icon-check text-primary"></i>

</ng-template>
<ng-template #emailTmpl let-data="rowData">
    <span class="email-field">{{data.email}}</span>
</ng-template>
<ng-template #typeTmpl let-data="rowData">
    <span>{{data.subAccount.type}}</span>
</ng-template>

<ng-template #confirm let-dialogRef="dialogRef">
    <ano-modal [dialogRef]="dialogRef" [title]="'Subaccount löschen'" [isConfirmation]="true">
        {{'subaccounts.confirmDelete' | localize}}
    </ano-modal>
</ng-template>
