<div class="container">
    <div class="box-article bg-light">
        <div class="wrapper-slider">
            <div class="custom-nav slider-article">
                <div id="row">
                    <ng-content></ng-content>
                </div>
                <div class="slider-nav">
                    <div class="slider-prev">
                        <i class="icon icon-prev" *ngIf="canScrollLeft" (click)="scrollLeft()"></i>
                        <!--<img *ngIf="canScrollLeft" src="assets/img/ico/icon-prev-primary.png" alt="" (click)="scrollLeft()">-->
                    </div>
                    <div class="slider-next">
                        <i class="icon icon-next" *ngIf="canScrollRight" (click)="scrollRight()"></i>
                        <!--<img *ngIf="canScrollRight" (click)="scrollRight()" src="assets/img/ico/icon-next-primary.png" alt="">-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
