import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  commonDeclarations,
  commonExports,
  commonImports
} from '@src/app/modules/material/material.common';

@NgModule({
  declarations: [
    ...commonDeclarations,
  ],
  imports: [
    ...commonImports,
    CommonModule,
  ],
  exports: [
    ...commonExports
  ],
})
export class MaterialModule { }
