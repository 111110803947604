import {Subject} from "rxjs/internal/Subject";
import {ComponentRef} from "@angular/core";
import {ModalContainerComponent} from "@src/app/components/modal-container/modal-container.component";
import {Observable} from "rxjs/internal/Observable";
import {Modal} from "@src/app/components/modal-container/models/modal.model";

export class ModalRef {

    private result$ = new Subject<any>();

    constructor(
        private modalContainer: ComponentRef<ModalContainerComponent>,
        private modal: ComponentRef<Modal>,
    ) {
        this.modal.instance.modalInstance = this;
    }

    close(output: any): void {
        this.result$.next(output);
        this.destroy$();
    }

    dismiss(output: any): void {
        this.result$.error(output);
        this.destroy$();
    }

    onResult(): Observable<any> {
        return this.result$.asObservable();
    }

    private destroy$(): void {
        this.modal.destroy();
        this.modalContainer.destroy();
        this.result$.complete();
    }

}