import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable()
export class ShareModalState {
    private isOpened: boolean = false;
    public openSubj: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public isOpened$: Observable<boolean> = this.openSubj.asObservable();

    constructor() {
    }

    public setState(state: boolean) {
        this.isOpened = state;
        this.openSubj.next(this.isOpened);
    }

    public toggle(): void {
        this.isOpened = !this.isOpened;
        this.openSubj.next(this.isOpened);
    }
}
