import { StreamPresentationComponent } from './../../pages/stream-page/components/stream-presentation/stream-presentation.component';
import {StreamLinksComponent} from './../../pages/stream-page/components/stream-links/stream-links.component';
import {Routes} from '@angular/router';
import {AuthGuard} from '@src/app/guards/auth/auth.guard';
import {ShouldNotBeAuthorizedGuard} from '@src/app/guards/should-not-be-authorized/should-not-be-authorized.guard';
import {RegistrationPageComponent} from '@src/app/pages/registration-page/registration-page.component';
import {EmailConfirmationPageComponent} from '@src/app/pages/email-confirmation-page/email-confirmation-page.component';
import {PasswordForgottenPageComponent} from '@src/app/pages/password-forgotten-page/password-forgotten-page.component';
import {ChangePasswordPageComponent} from '@src/app/pages/change-password-page/change-password-page.component';
import {AccessDeniedPageComponent} from '@src/app/pages/access-denied-page/access-denied-page.component';
import {DashboardPageComponent} from '@src/app/pages/dashboard-page/dashboard-page.component';
import {StreamMetadataComponent} from '@src/app/pages/stream-page/components/stream-metadata/stream-metadata.component';
import {StreamPageComponent} from '@src/app/pages/stream-page/stream-page.component';
import {StreamCouponsComponent} from '@src/app/pages/stream-page/components/stream-coupons/stream-coupons.component';
import {StreamPreviewComponent} from '@src/app/pages/stream-page/components/stream-preview/stream-preview.component';
import {UserProfileComponent} from '@src/app/pages/user-profile/user-profile.component';
import {ProfileComponent} from '@src/app/pages/user-profile/components/profile/profile.component';
import {ChangePasswordComponent} from '@src/app/pages/user-profile/components/change-password/change-password.component';
import {CreditsComponent} from '@src/app/pages/user-profile/components/credits/credits.component';
import {WithdrawalAndConditionsComponent} from '@src/app/pages/user-profile/components/withdrawal-and-conditions/withdrawal-and-conditions.component';
import {BankDetailsComponent} from '@src/app/pages/user-profile/components/bank-details/bank-details.component';
import {DeleteAccountComponent} from '@src/app/pages/user-profile/components/delete-account/delete-account.component';
import {StreamOverlaysComponent} from '@src/app/pages/stream-page/components/stream-overlays/stream-overlays.component';
import {StreamOverlayComponent} from '@src/app/pages/stream-page/components/stream-overlay/stream-overlay.component';
import {StreamLandingComponent} from '@src/app/pages/stream-page/components/stream-landing/stream-landing.component';
import {RecordPageComponent} from '@src/app/pages/record-page/record-page.component';
import {StreamRecordsComponent} from '@src/app/pages/stream-page/components/stream-records/stream-records.component';
import {StreamRecordComponent} from '@src/app/pages/stream-page/components/stream-record/stream-record.component';
import {MediaOverviewComponent} from '@src/app/pages/stream-page/components/media-overview/media-overview.component';
import {ContentWrapperComponent} from '@src/app/pages/content-wrapper/content-wrapper.component';
import {StreamTrailerComponent} from '@src/app/pages/stream-page/components/stream-trailer/stream-trailer.component';
import {StreamTeaserComponent} from '@src/app/pages/stream-page/components/stream-teaser/stream-teaser.component';
import {StreamSurveyComponent} from '@src/app/pages/stream-page/components/stream-survey/stream-survey.component';
import {StreamRevenueComponent} from '@src/app/pages/stream-page/components/stream-revenue/stream-revenue.component';
import {StreamEncoderDataComponent} from '@src/app/pages/stream-page/components/stream-encoder-data/stream-encoder-data.component';
import {StreamStatisticComponent} from '@src/app/pages/stream-page/components/stream-statistic/stream-statistic.component';
import {StreamViewerLinkComponent} from '@src/app/pages/stream-page/components/stream-viewer-link/stream-viewer-link.component';
import {PublicMediaPageComponent} from '@src/app/pages/public-media-page/public-media-page.component';
import {EmbeddedStreamPageComponent} from '@src/app/pages/embedded-stream-page/embedded-stream-page.component';
import {MockedPageComponent} from '@src/app/components/mocked-page/mocked-page.component';
import {StreamThumbnailComponent} from '@src/app/pages/stream-page/components/stream-thumbnail/stream-thumbnail.component';
import {NewStreamPageComponent} from '@src/app/pages/new-stream-page/new-stream-page.component';
import {HeidelpayPaymentComponent} from '@src/app/components/payment-cards-list/heidelpay-payment/heidelpay-payment.component';
import {GroupsPageComponent} from '@src/app/pages/groups-page/groups-page.component';
import {GroupsListComponent} from '@src/app/pages/groups-page/components/groups-list/groups-list.component';
import {GroupComponent} from '@src/app/pages/groups-page/components/group/group.component';
import {VideoOnDemandPageComponent} from '@src/app/pages/video-on-demand-page/video-on-demand-page.component';
import {VideoOnDemandListComponent} from '@src/app/pages/video-on-demand-page/components/video-on-demand-list/video-on-demand-list.component';
import {StreamRecordInfoComponent} from '@src/app/pages/stream-page/components/stream-record-info/stream-record-info.component';
import {LandingPageComponent} from '@src/app/pages/landing-page/landing-page.component';
import {LandingDataComponent} from '@src/app/pages/landing-page/landing-data/landing-data.component';
import {ActualLivestreamsComponent} from '@src/app/pages/landing-page/actual-livestreams/actual-livestreams.component';
import {MEDIA_TYPE} from '@src/app/components/streamdust-player/constants/mediaType';
import {SubscriptionManageComponent} from '@src/app/pages/user-profile/components/subscription-manage/subscription-manage.component';
import {BillsPageComponent} from '@src/app/pages/bills-page/bills-page.component';
import {StreamsWrapperComponent} from '@src/app/pages/streams-wrapper/streams-wrapper.component';
import {StorageAndBandwidthComponent} from '@src/app/pages/user-profile/components/storage-and-bandwidth/storage-and-bandwidth.component';
import {SurveyComponent} from '@src/app/pages/stream-page/components/stream-survey/components/survey/survey.component';
import {InfoComponent} from '@src/app/pages/stream-page/components/stream-survey/components/info/info.component';
import {PaymentStartPageComponent} from '@src/app/pages/payment-start-page/payment-start-page.component';
import {PaymentDetailsPageComponent} from '@src/app/pages/payment-details-page/payment-details-page.component';
import {PremiumPublisherGuard} from '@src/app/guards/premium-publisher/premium-publisher.guard';
import {PublicGroupPageComponent} from '@src/app/pages/public-group-page/public-group-page.component';
import {RevenuesPageComponent} from '@src/app/pages/revenues-page/revenues-page.component';
import {RevenueDetailsComponent} from '@src/app/pages/revenues-page/components/revenue-details/revenue-details.component';
import {GroupRevenueComponent} from '@src/app/pages/groups-page/components/group-revenue/group-revenue.component';
import {OverlayInfoComponent} from '@src/app/pages/stream-page/components/stream-overlays/components/overlay-info/overlay-info.component';
import {MediaLeadsComponent} from '@src/app/pages/stream-page/components/media-leads/media-leads.component';
import {InvalidTokenComponent} from '@src/app/components/invalid-token/invalid-token.component';
import {SubaccountsPageComponent} from '@src/app/pages/subaccounts-page/subaccounts-page.component';
import {SubAccountComponent} from '@src/app/pages/subaccounts-page/components/sub-account/sub-account.component';
import {ConnectedPublishersPageComponent} from '@src/app/pages/connected-publishers-page/connected-publishers-page.component';
import {AcceptInvitationComponent} from '@src/app/pages/subaccounts-page/components/accept-invitation/accept-invitation.component';
import {VideoComponent} from '@src/app/pages/landing-page/video/video.component';
import {EventSitePageComponent} from '@src/app/pages/event-site-page/event-site-page.component';
import {MainDashboardPageComponent} from '@src/app/pages/main-dashboard-page/main-dashboard-page.component';
import {
    StreamMarketingToolsOverlaysComponent,
    StreamMarketingToolsOverlaysTypes
} from '@src/app/pages/stream-page/components/stream-marketing-tools-overlays/stream-marketing-tools-overlays.component';
import {TestPageComponent} from '@src/app/pages/test-page/test-page.component';
import {StreamRemindComponent} from '@src/app/pages/stream-page/components/stream-remind/stream-remind.component';
import {NotFoundPageComponent} from '@src/app/pages/not-found-page/not-found-page.component';
import {ForbiddenPageComponent} from '@src/app/pages/forbidden-page/forbidden-page.component';
import {IframeTestComponent} from '@src/app/pages/iframe-test/iframe-test.component';
import {NotViewerGuard} from '@src/app/guards/not-viewer/not-viewer.guard';
import {ViewerDashboardComponent} from '@src/app/pages/dashboard-page/components/viewer-dashboard/viewer-dashboard.component';
import {MyVideoComponent} from '@src/app/pages/landing-page/my-video/my-video.component';
import {MediaPreviewPageComponent} from '@src/app/pages/media-preview-page/media-preview-page.component';
import { TokenExpiredPageComponent } from '@src/app/components/token-expired-page/token-expired-page.component';
import {StreamDomainRestrictionsComponent} from '@src/app/pages/stream-page/components/stream-domain-restrictions/stream-domain-restrictions.component';
import {ChatPageComponent} from '@src/app/pages/chat-page/chat-page.component';

export const UserDataRoutes: Routes = [
    {
        path: 'edit',
        component: ProfileComponent,
        data: {
            hideHeaderImage: true,
        },
    },
    {
        path: 'change-password',
        component: ChangePasswordComponent,
        data: {
            hideHeaderImage: true,
        },
    },
    {
        path: 'credits',
        component: CreditsComponent
    },
    {
        path: 'withdrawal-and-conditions',
        component: WithdrawalAndConditionsComponent,
        data: {
            hideHeaderImage: true,
        },
    },
    {
        path: 'bank-details',
        component: BankDetailsComponent,
        data: {
            hideHeaderImage: true,
        },
    },
    {
        path: 'payment-cards',
        component: BankDetailsComponent
    },
    {
        path: 'delete-account',
        component: DeleteAccountComponent,
        data: {
            hideHeaderImage: true,
        },
    },
    {
        path: 'subscription-manage',
        component: SubscriptionManageComponent,
        data: {
            hideHeaderImage: true,
        },
    },
    {
        path: 'space-storage-and-bandwidth',
        component: StorageAndBandwidthComponent,
        data: {
            hideHeaderImage: true,
        },
    }
];

export const routes: Routes = [
    {
        path: '',
        component: LandingPageComponent,
        children: [
            {
                path: '',
                data: {
                    breadcrumb: 'Startseite',
                    hideHeaderImage: true,
                    hideSubAccountPanel: true,
                    scrollTop: 0,
                },
                component: LandingDataComponent
            },
            {
                path: 'streams/actual',
                data: {
                    breadcrumb: 'Aktuelle Livestreams',
                    customHeaderImage: 'assets/img/background/bg-streams.jpg'
                },
                component: ActualLivestreamsComponent
            },
            {
                path: 'video/actual',
                data: {
                    breadcrumb: 'Aktuelle Livestreams',
                    customHeaderImage: 'assets/img/background/bg-streams.jpg'
                },
                component: VideoComponent
            },
            {
                path: 'video/my',
                data: {
                    breadcrumb: 'Aktuelle Livestreams',
                    customHeaderImage: 'assets/img/background/bg-streams.jpg'
                },
                component: MyVideoComponent
            }
        ]
    },
    {
        path: 'payment-details',
        component: PaymentDetailsPageComponent
    },
    {
        path: 'stream/new',
        canActivate: [AuthGuard],
        component: NewStreamPageComponent,
        data: {
            breadcrumb: 'Stream Erstellen',
            mediaType: MEDIA_TYPE.STREAM,
            hideHeaderImage: true,
        },
    },
    {
        path: 'bills',
        canActivate: [AuthGuard],
        component: ContentWrapperComponent,
        data: {
            breadcrumb: 'Streams',
            hideHeaderImage: true,
        },
        children: [
            {
                path: '',
                canActivate: [AuthGuard],
                component: BillsPageComponent,
            },
        ]
    },
    {
        path: 'revenue',
        canActivate: [AuthGuard],
        component: ContentWrapperComponent,
        data: {
            breadcrumb: 'Streams',
            hideHeaderImage: true,
        },
        children: [
            {
                path: '',
                canActivate: [AuthGuard],
                component: RevenuesPageComponent,
            },
            {
                path: ':id',
                canActivate: [AuthGuard],
                component: RevenueDetailsComponent,
            },
        ]
    },
    {
        path: 'subscription/upgrade/:id/:period',
        canActivate: [AuthGuard],
        component: PaymentStartPageComponent,
        data: {
            hideHeaderImage: true,
            scrollTop: 106,
            agreementText: 'storage-and-bandwidth.privacyPolicy'
        },
    },
    {
        path: 'bandwidth/upgrade/:id/:period',
        canActivate: [AuthGuard],
        component: PaymentStartPageComponent,
        data: {
            hideHeaderImage: true,
            scrollTop: 106,
            agreementText: 'storage-and-bandwidth.privacyPolicy'
        },
    },
    {
        path: 'storage/upgrade/:id/:period',
        canActivate: [AuthGuard],
        component: PaymentStartPageComponent,
        data: {
            hideHeaderImage: true,
            scrollTop: 106,
            agreementText: 'storage-and-bandwidth.privacyPolicy'
        },
    },
    {
        path: 'groups/:id/get-access',
        component: PaymentStartPageComponent,
        data: {
            hideHeaderImage: true,
            scrollTop: 106
        },
    },
    {
        path: 'dashboard',
        canActivate: [AuthGuard],
        component: ContentWrapperComponent,
        children: [
            {
                path: '',
                canActivate: [AuthGuard],
                component: MainDashboardPageComponent,
            },
        ],
        data: {
            hideHeaderImage: true,
        },
    },
    {
        path: 'viewer',
        canActivate: [AuthGuard],
        component: ContentWrapperComponent,
        children: [
            {
                path: 'media',
                canActivate: [AuthGuard],
                component: ViewerDashboardComponent,
                data: {
                    hideHeaderImage: true,
                },
            },
            {
                path: 'bills',
                canActivate: [AuthGuard],
                component: BillsPageComponent,
                data: {
                    isViewer: true,
                    hideHeaderImage: true,
                }
            },
        ]
    },
    {
        path: 'streams',
        canActivate: [AuthGuard],
        component: StreamsWrapperComponent,
        data: {
            breadcrumb: 'Streams',
            hideHeaderImage: true,
        },
        children: [
            {
                path: '',
                canActivate: [AuthGuard],
                component: DashboardPageComponent,
            },
            {
                path: ':id',
                component: StreamPageComponent,
                canActivate: [AuthGuard],
                data: {
                    rightMenu: true,
                    mediaType: MEDIA_TYPE.STREAM
                },
                children: [
                    {
                        path: 'info',
                        component: MediaOverviewComponent,
                        data: {
                            breadcrumb: 'Vorchauvideo',
                            rightMenu: true,
                            mediaType: MEDIA_TYPE.STREAM,
                            hideHeaderImage: true,
                        },
                    },
                    {
                        path: 'edit',
                        component: StreamMetadataComponent,
                        data: {
                            breadcrumb: 'Stream Bearbeiten',
                            rightMenu: true,
                            mediaType: MEDIA_TYPE.STREAM,
                            hideHeaderImage: true,
                        },
                    },
                    {
                        path: 'coupons',
                        component: StreamCouponsComponent,
                        canActivate: [PremiumPublisherGuard],
                        data: {
                            breadcrumb: 'Gutscheine',
                            rightMenu: true,
                            mediaType: MEDIA_TYPE.STREAM,
                            hideHeaderImage: true,
                        },
                    },
                    {
                        path: 'preview',
                        component: StreamPreviewComponent,
                        data: {
                            mediaType: MEDIA_TYPE.STREAM,
                        }
                    },
                    {
                        path: 'overlays',
                        component: StreamMarketingToolsOverlaysComponent,
                        data: {
                            breadcrumb: 'Overlays',
                            rightMenu: true,
                            mediaType: MEDIA_TYPE.STREAM,
                            overlaysType: StreamMarketingToolsOverlaysTypes.OVERLAY,
                            hideHeaderImage: true,
                        },
                    },
                    {
                        path: 'overlays/new',
                        component: StreamOverlayComponent,
                        data: {
                            breadcrumb: 'New Overlay',
                            rightMenu: true,
                            mediaType: MEDIA_TYPE.STREAM,
                            overlaysType: StreamMarketingToolsOverlaysTypes.OVERLAY,
                            hideHeaderImage: true,
                        },
                    },
                    {
                        path: 'info/overlays/new',
                        component: StreamOverlayComponent,
                        data: {
                            breadcrumb: 'New Overlay',
                            rightMenu: true,
                            mediaType: MEDIA_TYPE.STREAM,
                            overlaysType: StreamMarketingToolsOverlaysTypes.OVERLAY,
                            standAlone: true,
                            hideHeaderImage: true,
                        },
                    },
                    {
                        path: 'info/overlays/:id/edit',
                        component: StreamOverlayComponent,
                        data: {
                            breadcrumb: 'Overlay',
                            rightMenu: true,
                            mediaType: MEDIA_TYPE.STREAM,
                            overlaysType: StreamMarketingToolsOverlaysTypes.OVERLAY,
                            standAlone: true,
                            hideHeaderImage: true,
                        },
                    },
                    {
                        path: 'overlays/:id/edit',
                        component: StreamOverlayComponent,
                        data: {
                            breadcrumb: 'Overlay',
                            rightMenu: true,
                            mediaType: MEDIA_TYPE.STREAM,
                            overlaysType: StreamMarketingToolsOverlaysTypes.OVERLAY,
                            hideHeaderImage: true,
                        },
                    },
                    {
                        path: 'info/overlays/:id/info',
                        component: OverlayInfoComponent,
                        data: {
                            breadcrumb: 'Overlay',
                            rightMenu: true,
                            mediaType: MEDIA_TYPE.STREAM,
                            overlaysType: StreamMarketingToolsOverlaysTypes.OVERLAY,
                            standAlone: true,
                            hideHeaderImage: true,
                        },
                    },
                    {
                        path: 'overlays/:id/info',
                        component: OverlayInfoComponent,
                        data: {
                            breadcrumb: 'Overlay',
                            rightMenu: true,
                            mediaType: MEDIA_TYPE.STREAM,
                            overlaysType: StreamMarketingToolsOverlaysTypes.OVERLAY,
                            hideHeaderImage: true,
                        },
                    },
                    {
                        path: 'courses',
                        component: StreamMarketingToolsOverlaysComponent,
                        data: {
                            breadcrumb: 'Online Courses',
                            rightMenu: true,
                            mediaType: MEDIA_TYPE.STREAM,
                            overlaysType: StreamMarketingToolsOverlaysTypes.COURSES,
                            hideHeaderImage: true,
                        },
                    },
                    {
                        path: 'courses/new',
                        component: StreamOverlayComponent,
                        data: {
                            breadcrumb: 'New Overlay',
                            rightMenu: true,
                            mediaType: MEDIA_TYPE.STREAM,
                            overlaysType: StreamMarketingToolsOverlaysTypes.COURSES,
                            hideHeaderImage: true,
                        },
                    },
                    {
                        path: 'courses/:id/edit',
                        component: StreamOverlayComponent,
                        data: {
                            breadcrumb: 'Overlay',
                            rightMenu: true,
                            mediaType: MEDIA_TYPE.STREAM,
                            overlaysType: StreamMarketingToolsOverlaysTypes.COURSES,
                            hideHeaderImage: true,
                        },
                    },
                    {
                        path: 'courses/:id/info',
                        component: OverlayInfoComponent,
                        data: {
                            breadcrumb: 'Overlay',
                            rightMenu: true,
                            mediaType: MEDIA_TYPE.STREAM,
                            overlaysType: StreamMarketingToolsOverlaysTypes.COURSES,
                            hideHeaderImage: true,
                        },
                    },
                    {
                        path: 'courses-overview',
                        component: MediaLeadsComponent,
                        data: {
                            breadcrumb: 'Overview Online students',
                            rightMenu: true,
                            mediaType: MEDIA_TYPE.STREAM,
                            overlaysType: StreamMarketingToolsOverlaysTypes.COURSES,
                            hideHeaderImage: true,
                        },
                    },
                    {
                        path: 'landing',
                        component: StreamLandingComponent,
                        data: {
                            breadcrumb: 'Landing',
                            rightMenu: true,
                            mediaType: MEDIA_TYPE.STREAM,
                            hideHeaderImage: true,
                        },
                    },
                    {
                        path: 'records',
                        component: StreamRecordsComponent,
                        data: {
                            breadcrumb: 'Records',
                            rightMenu: true,
                            mediaType: MEDIA_TYPE.STREAM,
                            hideHeaderImage: true,
                        }
                    },
                    {
                        path: 'records/:id/edit',
                        component: StreamRecordComponent,
                        data: {
                            breadcrumb: 'Record',
                            rightMenu: true,
                            mediaType: MEDIA_TYPE.STREAM,
                            hideHeaderImage: true,
                        }
                    },
                    {
                        path: 'record/info',
                        component: StreamRecordInfoComponent,
                        data: {
                            breadcrumb: 'Record',
                            rightMenu: true,
                            mediaType: MEDIA_TYPE.STREAM,
                            hideHeaderImage: true,
                        }
                    },
                    {
                        path: 'trailer',
                        component: StreamTrailerComponent,
                        data: {
                            breadcrumb: 'Werbetrailer',
                            rightMenu: true,
                            mediaType: MEDIA_TYPE.STREAM,
                            hideHeaderImage: true,
                        },
                    },
                    {
                        path: 'teaser',
                        component: StreamTeaserComponent,
                        canActivate: [PremiumPublisherGuard],
                        data: {
                            breadcrumb: 'Preview Teaser',
                            rightMenu: true,
                            mediaType: MEDIA_TYPE.STREAM,
                            hideHeaderImage: true,
                        },
                    },
                    {
                        path: 'surveys',
                        component: StreamSurveyComponent,
                        data: {
                            breadcrumb: 'Umfrage-Tool',
                            rightMenu: true,
                            mediaType: MEDIA_TYPE.STREAM,
                            hideHeaderImage: true,
                        },
                    },
                    {
                        path: 'surveys/new',
                        component: SurveyComponent,
                        data: {
                            breadcrumb: 'Umfrage-Tool',
                            rightMenu: true,
                            mediaType: MEDIA_TYPE.STREAM,
                            hideHeaderImage: true,
                        },
                    },
                    {
                        path: 'info/surveys/new',
                        component: SurveyComponent,
                        data: {
                            breadcrumb: 'Umfrage-Tool',
                            rightMenu: true,
                            mediaType: MEDIA_TYPE.STREAM,
                            standAlone: true,
                            hideHeaderImage: true,
                        },
                    },
                    {
                        path: 'surveys/:id',
                        component: SurveyComponent,
                        data: {
                            breadcrumb: 'Umfrage-Tool',
                            rightMenu: true,
                            mediaType: MEDIA_TYPE.STREAM,
                            hideHeaderImage: true,
                        },
                    },
                    {
                        path: 'info/surveys/:id',
                        component: SurveyComponent,
                        data: {
                            breadcrumb: 'Umfrage-Tool',
                            rightMenu: true,
                            mediaType: MEDIA_TYPE.STREAM,
                            standAlone: true,
                            hideHeaderImage: true,
                        },
                    },
                    {
                        path: 'surveys/:id/info',
                        component: InfoComponent,
                        data: {
                            breadcrumb: 'Umfrage-Tool',
                            rightMenu: true,
                            mediaType: MEDIA_TYPE.STREAM,
                            hideHeaderImage: true,
                        },
                    },
                    {
                        path: 'info/surveys/:id/info',
                        component: InfoComponent,
                        data: {
                            breadcrumb: 'Umfrage-Tool',
                            rightMenu: true,
                            mediaType: MEDIA_TYPE.STREAM,
                            standAlone: true,
                            hideHeaderImage: true,
                        },
                    },
                    {
                        path: 'thumbnail',
                        component: StreamThumbnailComponent,
                        data: {
                            breadcrumb: 'Vorschaubild hochladen',
                            rightMenu: true,
                            mediaType: MEDIA_TYPE.STREAM,
                            hideHeaderImage: true,
                        },
                    },
                    {
                        path: 'revenue',
                        component: StreamRevenueComponent,
                        data: {
                            breadcrumb: 'Einnahmen und Kostenübersicht',
                            rightMenu: true,
                            mediaType: MEDIA_TYPE.STREAM,
                            hideHeaderImage: true,
                        },
                    },
                    {
                        path: 'encoder',
                        component: StreamEncoderDataComponent,
                        data: {
                            breadcrumb: 'Encoder Daten',
                            rightMenu: true,
                            mediaType: MEDIA_TYPE.STREAM,
                            hideHeaderImage: true,
                        },
                    },
                    {
                        path: 'statistic',
                        component: StreamStatisticComponent,
                        data: {
                            breadcrumb: 'Statistiken',
                            rightMenu: true,
                            mediaType: MEDIA_TYPE.STREAM,
                            hideHeaderImage: true,
                        },
                    },
                    {
                        path: 'iframe',
                        component: StreamViewerLinkComponent,
                        data: {
                            breadcrumb: 'Zuschauerlink und iframe',
                            rightMenu: true,
                            mediaType: MEDIA_TYPE.STREAM,
                            hideHeaderImage: true,
                        },
                    },
                    {
                        path: 'leads',
                        component: MediaLeadsComponent,
                        data: {
                            breadcrumb: 'Leads',
                            rightMenu: true,
                            mediaType: MEDIA_TYPE.STREAM,
                            overlaysType: StreamMarketingToolsOverlaysTypes.LEADGENERATION,
                            hideHeaderImage: true,
                        },
                    },
                    {
                        path: 'links',
                        component: StreamLinksComponent,
                        data: {
                            breadcrumb: 'Links',
                            rightMenu: true,
                            mediaType: MEDIA_TYPE.STREAM,
                            hideHeaderImage: true,
                        },
                    },
                    {
                        path: 'domain-restrictions',
                        component: StreamDomainRestrictionsComponent,
                        data: {
                            breadcrumb: 'Domain Restrictions',
                            rightMenu: true,
                            mediaType: MEDIA_TYPE.STREAM,
                            hideHeaderImage: true,
                        },
                    },
                    {
                        path: 'presentations',
                        component: StreamPresentationComponent,
                        canActivate: [PremiumPublisherGuard],
                        data: {
                            breadcrumb: 'Presentations',
                            rightMenu: true,
                            mediaType: MEDIA_TYPE.STREAM,
                            hideHeaderImage: true,
                        },
                    },
                    {
                        path: 'info/presentations',
                        component: StreamPresentationComponent,
                        data: {
                            breadcrumb: 'Presentations',
                            rightMenu: true,
                            mediaType: MEDIA_TYPE.STREAM,
                            hideHeaderImage: true,
                        },
                    },
                    {
                        path: 'remind',
                        component: StreamRemindComponent,
                        data: {
                            // breadcrumb: 'Presentations',
                            rightMenu: true,
                            mediaType: MEDIA_TYPE.STREAM,
                            hideHeaderImage: true,
                        },
                    }
                ],
            },
        ]

    },
    {
        path: 'record',
        canActivate:
            [AuthGuard],
        component:
        RecordPageComponent,
    },
    {
        path: 'groups/:id/details',
        component: PublicGroupPageComponent,
    },
    {
        path: 'watch/:id',
        component: PublicMediaPageComponent,
        data: {
            withoutMenu: true
        },
    },
    {
        path: 'watch/stream/:id',
        runGuardsAndResolvers: 'always',
        component: PublicMediaPageComponent,
        data: {
            withoutMenu: true,
            mediaType: MEDIA_TYPE.STREAM
        },
    },
    {
        path: 'watch/stream/:id/chat',
        runGuardsAndResolvers: 'always',
        component: ChatPageComponent,
        data: {
            withoutMenu: true,
            mediaType: MEDIA_TYPE.STREAM
        },
    },
    {
        path: 'watch/vod/:id',
        component: PublicMediaPageComponent,
        runGuardsAndResolvers: 'always',
        data: {
            withoutMenu: true,
            mediaType: MEDIA_TYPE.VIDEO_ON_DEMAND
        },
    },
    {
        path: 'embedded/stream/:id',
        component: EmbeddedStreamPageComponent,
        data: {
            embedded: true,
            mediaType: MEDIA_TYPE.STREAM
        },
    },
    {
        path: 'embedded/vod/:id',
        component: EmbeddedStreamPageComponent,
        data: {
            embedded: true,
            mediaType: MEDIA_TYPE.VIDEO_ON_DEMAND
        },
    },
    {
        path: 'media-preview/:id',
        component: MediaPreviewPageComponent,
        data: {
            embedded: true,
            mediaType: MEDIA_TYPE.STREAM
        },
    },
    {
        path: 'event-site',
        component: EventSitePageComponent,
        data: {
            embedded: true,
        },
    },
    {
        path: 'heidelpay/:id',
        // loadChildren:
        //     () => import('../../pages/heidelpay-payment/heidelpay-payment.module').then(mod => mod.HeidelpayPaymentPageModule),
        component: HeidelpayPaymentComponent,
        data: {
            embedded: true
        },
    },
    {
        path: 'groups',
        canActivate: [PremiumPublisherGuard],
        component: GroupsPageComponent,
        data: {
            breadcrumb: 'Groups',
            hideHeaderImage: true,
        },
        children: [
            {
                path: '',
                component: GroupsListComponent,
            },
            {
                path: ':id',
                component: GroupComponent,
            },

            {
                path: 'revenue/:id',
                canActivate: [AuthGuard],
                component: GroupRevenueComponent,
                data: {
                    breadcrumb: 'Groups'
                },
            },
        ]
    },
    {
        path: 'videos-on-demand',
        component: VideoOnDemandPageComponent,
        data: {
            breadcrumb: 'Video on demand',
            hideHeaderImage: true,
        },
        children: [
            {
                path: '',
                component: VideoOnDemandListComponent,
            }

        ]
    },
    {
        path: 'video-on-demand/new',
        component: NewStreamPageComponent,
        data: {
            breadcrumb: 'Video on demand',
            mediaType: MEDIA_TYPE.VIDEO_ON_DEMAND,
            hideHeaderImage: true,
        },
    },

    {
        path: 'subaccounts',
        canActivate: [AuthGuard],
        component: ContentWrapperComponent,
        data: {
            breadcrumb: 'Streams',
            hideHeaderImage: true,
        },
        children: [
            {
                path: '',
                canActivate: [AuthGuard],
                component: SubaccountsPageComponent,
            },
            {
                path: ':id',
                canActivate: [AuthGuard],
                component: SubAccountComponent,
            },
        ]
    },
    {
        path: 'connected-publishers',
        canActivate: [AuthGuard],
        component: ContentWrapperComponent,
        data: {
            breadcrumb: 'Streams'
        },
        children: [
            {
                path: '',
                canActivate: [AuthGuard],
                component: ConnectedPublishersPageComponent,
            }
        ]
    },
    {
        path: 'connect-invitation',
        canActivate: [AuthGuard],
        component: AcceptInvitationComponent,
    },
    {
        path: 'vod/:id',
        component: StreamPageComponent,
        data: {
            breadcrumb: 'Video on demand',
            mediaType: MEDIA_TYPE.VIDEO_ON_DEMAND
        },
        children: [
            {
                path: '',
                redirectTo: '/videos-on-demand',
                pathMatch: 'full',
            },
            {
                path: 'info',
                component: MediaOverviewComponent,
                data: {
                    breadcrumb: 'Vorchauvideo',
                    rightMenu: true,
                    mediaType: MEDIA_TYPE.VIDEO_ON_DEMAND,
                    hideHeaderImage: true,
                },
            },
            {
                path: 'edit',
                component: StreamMetadataComponent,
                data: {
                    breadcrumb: 'VOD Bearbeiten',
                    rightMenu: true,
                    mediaType: MEDIA_TYPE.VIDEO_ON_DEMAND,
                    hideHeaderImage: true,
                },
            },
            {
                path: 'coupons',
                component: StreamCouponsComponent,
                data: {
                    breadcrumb: 'Gutscheine',
                    rightMenu: true,
                    mediaType: MEDIA_TYPE.VIDEO_ON_DEMAND,
                    hideHeaderImage: true,
                },
            },
            {
                path: 'preview',
                component: MockedPageComponent,
                data: {
                    mediaType: MEDIA_TYPE.VIDEO_ON_DEMAND,
                }
            },
            {
                path: 'overlays',
                component: StreamMarketingToolsOverlaysComponent,
                data: {
                    breadcrumb: 'Overlays',
                    rightMenu: true,
                    mediaType: MEDIA_TYPE.VIDEO_ON_DEMAND,
                    overlaysType: StreamMarketingToolsOverlaysTypes.OVERLAY,
                    hideHeaderImage: true,
                },
            },
            {
                path: 'overlays/new',
                component: StreamOverlayComponent,
                data: {
                    breadcrumb: 'New Overlay',
                    rightMenu: true,
                    mediaType: MEDIA_TYPE.VIDEO_ON_DEMAND,
                    overlaysType: StreamMarketingToolsOverlaysTypes.OVERLAY,
                    hideHeaderImage: true,
                },
            },
            {
                path: 'overlays/:id/edit',
                component: StreamOverlayComponent,
                data: {
                    breadcrumb: 'Overlay',
                    rightMenu: true,
                    mediaType: MEDIA_TYPE.VIDEO_ON_DEMAND,
                    overlaysType: StreamMarketingToolsOverlaysTypes.OVERLAY,
                    hideHeaderImage: true,
                },
            },
            {
                path: 'overlays/:id/info',
                component: OverlayInfoComponent,
                data: {
                    breadcrumb: 'Overlay',
                    rightMenu: true,
                    mediaType: MEDIA_TYPE.VIDEO_ON_DEMAND,
                    overlaysType: StreamMarketingToolsOverlaysTypes.OVERLAY,
                    hideHeaderImage: true,
                },
            },
            {
                path: 'courses',
                component: StreamMarketingToolsOverlaysComponent,
                data: {
                    breadcrumb: 'Online Courses',
                    rightMenu: true,
                    mediaType: MEDIA_TYPE.VIDEO_ON_DEMAND,
                    overlaysType: StreamMarketingToolsOverlaysTypes.COURSES,
                    hideHeaderImage: true,
                },
            },
            {
                path: 'courses/new',
                component: StreamOverlayComponent,
                data: {
                    breadcrumb: 'New Overlay',
                    rightMenu: true,
                    mediaType: MEDIA_TYPE.VIDEO_ON_DEMAND,
                    overlaysType: StreamMarketingToolsOverlaysTypes.COURSES,
                    hideHeaderImage: true,
                },
            },
            {
                path: 'courses/:id/edit',
                component: StreamOverlayComponent,
                data: {
                    breadcrumb: 'Overlay',
                    rightMenu: true,
                    mediaType: MEDIA_TYPE.VIDEO_ON_DEMAND,
                    overlaysType: StreamMarketingToolsOverlaysTypes.COURSES,
                    hideHeaderImage: true,
                },
            },
            {
                path: 'courses/:id/info',
                component: OverlayInfoComponent,
                data: {
                    breadcrumb: 'Overlay',
                    rightMenu: true,
                    mediaType: MEDIA_TYPE.VIDEO_ON_DEMAND,
                    overlaysType: StreamMarketingToolsOverlaysTypes.COURSES,
                    hideHeaderImage: true,
                },
            },
            {
                path: 'courses-overview',
                component: MediaLeadsComponent,
                data: {
                    breadcrumb: 'Overview Online students',
                    rightMenu: true,
                    mediaType: MEDIA_TYPE.VIDEO_ON_DEMAND,
                    overlaysType: StreamMarketingToolsOverlaysTypes.COURSES,
                    hideHeaderImage: true,
                },
            },
            {
                path: 'links',
                component: StreamLinksComponent,
                data: {
                    breadcrumb: 'Links',
                    rightMenu: true,
                    mediaType: MEDIA_TYPE.VIDEO_ON_DEMAND,
                    hideHeaderImage: true,
                },
            },
            {
                path: 'domain-restrictions',
                component: StreamDomainRestrictionsComponent,
                data: {
                    breadcrumb: 'Domain Restrictions',
                    rightMenu: true,
                    mediaType: MEDIA_TYPE.VIDEO_ON_DEMAND,
                    hideHeaderImage: true,
                },
            },
            {
                path: 'presentations',
                component: StreamPresentationComponent,
                canActivate: [PremiumPublisherGuard],
                data: {
                    breadcrumb: 'Presentations',
                    rightMenu: true,
                    mediaType: MEDIA_TYPE.VIDEO_ON_DEMAND,
                    hideHeaderImage: true,
                },
            },
            {
                path: 'landing',
                component: StreamLandingComponent,
                data: {
                    breadcrumb: 'Landing',
                    rightMenu: true,
                    mediaType: MEDIA_TYPE.VIDEO_ON_DEMAND,
                    hideHeaderImage: true,
                },
            },
            {
                path: 'trailer',
                component: StreamTrailerComponent,
                canActivate: [PremiumPublisherGuard],
                data: {
                    breadcrumb: 'Werbetrailer',
                    rightMenu: true,
                    mediaType: MEDIA_TYPE.VIDEO_ON_DEMAND,
                    hideHeaderImage: true,
                },
            },
            {
                path: 'teaser',
                component: StreamTeaserComponent,
                data: {
                    breadcrumb: 'Preview Teaser',
                    rightMenu: true,
                    mediaType: MEDIA_TYPE.VIDEO_ON_DEMAND,
                    hideHeaderImage: true,
                },
            },
            {
                path: 'surveys',
                component: StreamSurveyComponent,
                data: {
                    breadcrumb: 'Umfrage-Tool',
                    rightMenu: true,
                    mediaType: MEDIA_TYPE.VIDEO_ON_DEMAND,
                    hideHeaderImage: true,
                },
            },
            {
                path: 'info/surveys/new',
                component: SurveyComponent,
                data: {
                    breadcrumb: 'Umfrage-Tool',
                    rightMenu: true,
                    mediaType: MEDIA_TYPE.VIDEO_ON_DEMAND,
                    standAlone: true,
                    hideHeaderImage: true,
                },
            },
            {
                path: 'surveys/new',
                component: SurveyComponent,
                data: {
                    breadcrumb: 'Umfrage-Tool',
                    rightMenu: true,
                    mediaType: MEDIA_TYPE.VIDEO_ON_DEMAND,
                    hideHeaderImage: true,
                },
            },
            {
                path: 'surveys/:id',
                component: SurveyComponent,
                data: {
                    breadcrumb: 'Umfrage-Tool',
                    rightMenu: true,
                    mediaType: MEDIA_TYPE.VIDEO_ON_DEMAND,
                    hideHeaderImage: true,
                },
            },
            {
                path: 'surveys/:id/info',
                component: InfoComponent,
                data: {
                    breadcrumb: 'Umfrage-Tool',
                    rightMenu: true,
                    mediaType: MEDIA_TYPE.VIDEO_ON_DEMAND,
                    hideHeaderImage: true,
                },
            },
            {
                path: 'info/surveys/:id',
                component: SurveyComponent,
                data: {
                    breadcrumb: 'Umfrage-Tool',
                    rightMenu: true,
                    mediaType: MEDIA_TYPE.VIDEO_ON_DEMAND,
                    standAlone: true,
                    hideHeaderImage: true,
                },
            },
            {
                path: 'info/surveys/:id/info',
                component: InfoComponent,
                data: {
                    breadcrumb: 'Umfrage-Tool',
                    rightMenu: true,
                    mediaType: MEDIA_TYPE.VIDEO_ON_DEMAND,
                    standAlone: true,
                    hideHeaderImage: true,
                },
            },
            {
                path: 'thumbnail',
                component: StreamThumbnailComponent,
                data: {
                    breadcrumb: 'Vorschaubild hochladen',
                    rightMenu: true,
                    mediaType: MEDIA_TYPE.VIDEO_ON_DEMAND,
                    hideHeaderImage: true,
                },
            },
            {
                path: 'revenue',
                component: StreamRevenueComponent,
                data: {
                    breadcrumb: 'Einnahmen und Kostenübersicht',
                    rightMenu: true,
                    mediaType: MEDIA_TYPE.VIDEO_ON_DEMAND,
                    hideHeaderImage: true,
                },
            },
            {
                path: 'statistic',
                component: StreamStatisticComponent,
                data: {
                    breadcrumb: 'Statistiken',
                    rightMenu: true,
                    mediaType: MEDIA_TYPE.VIDEO_ON_DEMAND,
                    hideHeaderImage: true,
                },
            },
            {
                path: 'iframe',
                component: StreamViewerLinkComponent,
                data: {
                    breadcrumb: 'Zuschauerlink und iframe',
                    rightMenu: true,
                    mediaType: MEDIA_TYPE.VIDEO_ON_DEMAND,
                    hideHeaderImage: true,
                },
            },
            {
                path: 'leads',
                component: MediaLeadsComponent,
                data: {
                    breadcrumb: 'Leads',
                    rightMenu: true,
                    mediaType: MEDIA_TYPE.VIDEO_ON_DEMAND,
                    overlaysType: StreamMarketingToolsOverlaysTypes.LEADGENERATION,
                    hideHeaderImage: true,
                },
            },
            {
                path: 'info/overlays/new',
                component: StreamOverlayComponent,
                data: {
                    breadcrumb: 'New Overlay',
                    rightMenu: true,
                    mediaType: MEDIA_TYPE.VIDEO_ON_DEMAND,
                    overlaysType: StreamMarketingToolsOverlaysTypes.OVERLAY,
                    standAlone: true,
                    hideHeaderImage: true,
                },
            },
            {
                path: 'info/overlays/:id/edit',
                component: StreamOverlayComponent,
                data: {
                    breadcrumb: 'Overlay',
                    rightMenu: true,
                    mediaType: MEDIA_TYPE.VIDEO_ON_DEMAND,
                    overlaysType: StreamMarketingToolsOverlaysTypes.OVERLAY,
                    standAlone: true,
                    hideHeaderImage: true,
                },
            },
            {
                path: 'info/overlays/:id/info',
                component: OverlayInfoComponent,
                data: {
                    breadcrumb: 'Overlay',
                    rightMenu: true,
                    mediaType: MEDIA_TYPE.VIDEO_ON_DEMAND,
                    overlaysType: StreamMarketingToolsOverlaysTypes.OVERLAY,
                    standAlone: true,
                    hideHeaderImage: true,
                },
            },
            {
                path: 'info/presentations',
                component: StreamPresentationComponent,
                data: {
                    breadcrumb: 'Presentations',
                    rightMenu: true,
                    mediaType: MEDIA_TYPE.VIDEO_ON_DEMAND,
                    hideHeaderImage: true,
                },
            },
        ]
    },
    {
        path: 'user',
        component:
        UserProfileComponent,
        canActivate:
            [AuthGuard],
        children:
        UserDataRoutes
    },
    {
        path: 'login',
        component: MockedPageComponent,
        canActivate: [ShouldNotBeAuthorizedGuard],
    },
    {
        path: 'registration',
        component:
        RegistrationPageComponent,
        canActivate: [ShouldNotBeAuthorizedGuard],
        data: {
            hideHeaderImage: true,
            scrollTop: 106
        }
    },
    {
        path: 'upgrade-to-publisher',
        component:
        RegistrationPageComponent,
        data:
            {
                upgradeToPublisher: true,
                hideHeaderImage: true
            }
        ,
    },
    {
        path: 'confirm-registration',
        component:
        LandingPageComponent,
        children: [
            {
                path: '',
                data: {
                    breadcrumb: 'Startseite',
                    hideHeaderImage: true,
                    hideSubAccountPanel: true,
                    scrollTop: 106
                },
                component: LandingDataComponent
            }
        ]
    }
    ,
    {
        path: 'new-email-confirmation',
        component:
        EmailConfirmationPageComponent,
        data:
            {
                hiddenElements: {
                    header: true,
                    footer:
                        true
                }
                ,
                isNewEmail: true
            }
        ,
    }
    ,
    {
        path: 'password-forgotten',
        component:
        PasswordForgottenPageComponent,
        data:
            {
                hiddenElements: {
                    header: true, footer:
                        true
                }
            }
        ,
        canActivate: [ShouldNotBeAuthorizedGuard],
    }
    ,
    {
        path: 'change-password',
        component:
        ChangePasswordPageComponent,
        data:
            {
                hiddenElements: {
                    header: true, footer:
                        true
                }
            }
        ,
        canActivate: [ShouldNotBeAuthorizedGuard]
    },
    {
        path: 'access-denied',
        component:
        AccessDeniedPageComponent,
        data:
            {
                headerConfig: {
                    rounded: true,
                    backButton:
                        true,
                    showLogo:
                        false,
                    transparent:
                        false,
                    isProfile:
                        false,
                    // title: 'navigation.accessDenied',
                }
            }
    },
    {
        path: 'invalid-token',
        component:
        InvalidTokenComponent,
        data: {
            hideHeaderImage: true,
            scrollTop: 106
        },
    },
    {
        path: 'test-page',
        component: ContentWrapperComponent,
        data: {
            scrollTop: 106
        },
        children: [
            {
                path: '',
                component: TestPageComponent,
                data: {
                    scrollTop: 106
                },
            }
        ]
    },
    {
        path: 'iframe-test',
        component:
        IframeTestComponent,
        data: {
            hideHeaderImage: true,
            scrollTop: 106
        },
    },
    {
        path: '403',
        component:
        ForbiddenPageComponent,
        data: {
            hideHeaderImage: true,
            scrollTop: 106
        },
    },
    {
        path: '404',
        component:
        NotFoundPageComponent,
        data: {
            hideHeaderImage: true,
            scrollTop: 106
        },
    },
    {
        path: '**',
        redirectTo:
            '/streams',
    }
];
