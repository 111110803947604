import {AfterViewInit, Directive, ElementRef, EventEmitter, Output} from '@angular/core';
import {fromEvent} from 'rxjs';
import {HeaderService} from '@src/app/services/header/header.service';

@Directive({
  selector: '[appHeaderScrollHandler]'
})
export class HeaderScrollHandlerDirective implements AfterViewInit {

  @Output() private headerHandlerScrolled: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
      private elementRef: ElementRef<HTMLElement>,
      private headerService: HeaderService) { }

  public ngAfterViewInit(): void {
  //   const el = this.elementRef.nativeElement;
  //   this.headerService.hideRoundingEmitter.emit(el.scrollTop > 5);
  //   fromEvent(el, 'scroll')
  //       .subscribe(() => {
  //         this.headerService.hideRoundingEmitter.emit(el.scrollTop > 5);
  //         if (el.scrollTop > 5) {
  //             el.style.borderRadius = '1px';
  //         } else {
  //             el.style.borderRadius = '50px 50px 0 0';
  //         }
  //       });
  }

}
