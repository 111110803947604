/**
 * Created by Тима on 09.04.2020.
 */
import {Injectable} from '@angular/core';
import {ApiService} from 'ui-elements';
import {INPUT_TYPES} from 'ui-elements';
import {API_URLS_APP} from '@src/app/constants/api-urls.constant';
import {Observable} from 'rxjs';
import {IResponse} from '@src/app/models/response.model';
import {pluck} from 'rxjs/internal/operators';
import {IFieldsConfig} from '@src/app/pages/user-profile/interfaces/interfaces-common';
import {CmsService} from 'ui-elements';

@Injectable()
export class BankDetailsService {

    constructor(
        private apiService: ApiService,
        private cmsService: CmsService
    ) {}
    public getFieldsConfig(): IFieldsConfig[] {
        return [
            {
                name: 'cardType',
                hidden: true,
                config: {
                    inputType: INPUT_TYPES.INPUT,
                    label: 'bank.cardType',
                    placeholder: ''
                }
            },
            {
                name: 'cardHolder',
                config: {
                    inputType: INPUT_TYPES.INPUT,
                    label: 'bank.cardHolder',
                    placeholder: '',
                    isRequired: true
                }
            },
            {
                name: 'cardNumber',
                config: {
                    inputType: INPUT_TYPES.CREDIT_CARD,
                    label: 'bank.cardNumber',
                    placeholder: '',
                    isRequired: true
                }
            },
            {
                name: 'expiryDate',
                config: {
                    inputType: INPUT_TYPES.INPUT,
                    label: 'bank.expiryDate',
                    placeholder: '',
                    isRequired: true,
                    mask: '00/00'
                }
            },
            {
                name: 'cvc',
                config: {
                    inputType: INPUT_TYPES.PASSWORD,
                    label: 'bank.cvc',
                    placeholder: '',
                    isRequired: true,
                    mask: '0000',
                    type: 'password'
                }
            },
            {
                name: 'primaryAddress',
                config: {
                    inputType: INPUT_TYPES.INPUT,
                    label: 'bank.primaryAddress',
                    placeholder: '',
                    isRequired: true
                }
            },
            {
                name: 'secondaryAddress',
                config: {
                    inputType: INPUT_TYPES.INPUT,
                    label: 'bank.secondaryAddress',
                    placeholder: '',
                }
            },
            {
                name: 'postCode',
                config: {
                    inputType: INPUT_TYPES.INPUT,
                    label: 'bank.postCode',
                    placeholder: '',
                    isRequired: true
                }
            },
            {
                name: 'residence',
                config: {
                    inputType: INPUT_TYPES.INPUT,
                    label: 'bank.residence',
                    placeholder: '',
                    isRequired: true
                }
            },
            {
                name: 'state',
                config: {
                    inputType: INPUT_TYPES.INPUT,
                    label: 'bank.state',
                    placeholder: '',
                    isRequired: true
                }
            },
            {
                name: 'country',
                config: {
                    inputType: INPUT_TYPES.SELECT,
                    isAutocomplete: true,
                    label: 'bank.country',
                    placeholder: '',
                    isRequired: true,
                    selectOptions: this.cmsService.countriesList
                }
            }
        ];
    }
    public getBankFieldsConfig(): IFieldsConfig[] {
        return [
            {
                name: 'accountRecipient',
                config: {
                    inputType: INPUT_TYPES.INPUT,
                    label: 'bank.bankAccount.owner',
                    placeholder: '',
                    isRequired: true
                }
            },
            {
                name: 'street',
                config: {
                    inputType: INPUT_TYPES.TEXTAREA,
                    label: 'bank.bankAccount.street',
                    placeholder: '',
                    isRequired: true,
                    autosize: true
                }
            },
            {
                name: 'city',
                config: {
                    inputType: INPUT_TYPES.INPUT,
                    label: 'bank.bankAccount.city',
                    placeholder: '',
                    isRequired: true
                }
            },
            {
                name: 'postalCode',
                config: {
                    inputType: INPUT_TYPES.INPUT,
                    label: 'bank.bankAccount.postalCode',
                    placeholder: '',
                    isRequired: true
                }
            },
            {
                name: 'country',
                config: {
                    inputType: INPUT_TYPES.SELECT,
                    label: 'bank.bankAccount.country',
                    placeholder: '',
                    selectOptions: this.cmsService.countriesList,
                    isRequired: true,
                    isAutocomplete: true
                }
            },
            {
                name: 'bankCountry',
                config: {
                    inputType: INPUT_TYPES.SELECT,
                    label: 'bank.bankAccount.bankCountry',
                    placeholder: '',
                    selectOptions: this.cmsService.countriesList,
                    isRequired: true,
                    isAutocomplete: true
                }
            },
            {
                name: 'bankName',
                config: {
                    inputType: INPUT_TYPES.TEXTAREA,
                    label: 'bank.bankAccount.bankName',
                    placeholder: '',
                    isRequired: true,
                    autosize: true
                }
            },
            {
                name: 'iBan',
                config: {
                    inputType: INPUT_TYPES.IBAN,
                    label: 'bank.bankAccount.iban',
                    placeholder: '',
                    isRequired: true,
                }
            },
            {
                name: 'bic',
                config: {
                    inputType: INPUT_TYPES.INPUT,
                    label: 'bank.bankAccount.bic',
                    placeholder: '',
                    isRequired: true
                }
            }
        ];
    }
    public getBankDetails(): Observable<IBankDetails> {
        return this.apiService.get(API_URLS_APP.USER_BANK).pipe(
            pluck('results', 'data')
        );
    }
    public saveBankDetails(newDetails: IBankDetails): Observable<IResponse> {
        return this.apiService.post(API_URLS_APP.USER_BANK, newDetails)
            .pipe(
                pluck('results')
            );
    }
}
export interface IBankDetails {
    accountId: string;
    accountRecipient: string;
    iBan: string;
    bic: string;
    street: string;
    city: string;
    postalCode: string;
    country: string;
    bankCountry: string;
    bankName: string;
    // accountRecipient: string;
    // accountInstitution: string;
    // iBan: string;
    // bic: string;
}
