import { Pipe, PipeTransform } from '@angular/core';
import {DateHelper} from '@src/app/utils/date.helper';

@Pipe({
  name: 'durationConvert'
})
export class DurationConvertPipe implements PipeTransform {

  transform(value: any, timeType: 'seconds' | 'milliseconds' = 'milliseconds', convertType: CONVERT_TYPE = CONVERT_TYPE.TO_STRING, ...args: unknown[]): unknown {
    if (value === null || typeof value === 'undefined') {
      return null;
    }
    return this.getValue(value, convertType, timeType);
  }

  private getValue(value: any, convertType: CONVERT_TYPE, timeType: 'seconds' | 'milliseconds'): any {
    if (timeType === 'milliseconds') {
      return convertType === CONVERT_TYPE.TO_STRING ? DateHelper.getStringFromMilliseconds(value) : DateHelper.getMillisecondsFromString(value);
    }

    return convertType === CONVERT_TYPE.TO_STRING ? DateHelper.getStringFromSeconds(value) : DateHelper.getSecondsFromString(value);
  }

}

export enum CONVERT_TYPE {
  TO_STRING,
  TO_NUMBER
}
