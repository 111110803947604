import {Component, Input, OnInit} from '@angular/core';
import {IFormConfig, StreamService} from '@src/app/services/stream-metadata/stream.service';
import {IStreamModel} from '@src/app/models/stream.model';
import {Subscription} from 'rxjs';
import {LoadingService} from '@src/app/services/loading/loading.service';
import {VIDEO_VISIBLE_SETTINGS} from '@src/app/models/video-on-demand.model';
import {takeUntil} from 'rxjs/operators';
import {MEDIA_TYPE} from '@src/app/components/streamdust-player/constants/mediaType';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';

@Component({
  selector: 'app-media-visibility',
  templateUrl: './media-visibility.component.html',
  styleUrls: ['./media-visibility.component.sass'],
  providers: [AutoDestroyService],
})
export class MediaVisibilityComponent implements OnInit {
  @Input() stream: IStreamModel;
  @Input() mediaType: MEDIA_TYPE;
  @Input() mediaId: string;
  public landingForms: IFormConfig[];
  public visibilitySubscription: Subscription;

  constructor(
    public streamService: StreamService,
    public loadingService: LoadingService,
    private destroy$: AutoDestroyService,
  ) {
  }

  ngOnInit(): void {
    this.landingForms = this.streamService.getVisibilitySettingsForms();
    this.listenVisibility();
  }


  private listenVisibility(): void {
    console.log('test1');
    if (!this.visibilitySubscription) {
      console.log('test2');
      const landingSettings = this.landingForms.find(form => form.name === 'landingSettings').form;
      switch (this.stream.media.visible) {
        case VIDEO_VISIBLE_SETTINGS.NOWHERE:
          landingSettings.get('visibleNone').patchValue(true);
          break;
        case VIDEO_VISIBLE_SETTINGS.EVERYWHERE:
          console.log('pathc val everywhere');
          landingSettings.get('visibleEverywhere').patchValue(true);
          break;
        case VIDEO_VISIBLE_SETTINGS.EXCEPT_STREAMDUST:
          landingSettings.get('visibleNotStreamdust').patchValue(true);
          break;
        case VIDEO_VISIBLE_SETTINGS.STREAMDUST_ONLY:
          landingSettings.get('visibleStreamdust').patchValue(true);
          break;
      }
      this.listenVisibilitySetting();
    }
  }

  public listenVisibilitySetting(): void {
    const formConfig = this.landingForms.find(form => form.name === 'landingSettings');

    const visibleNone = formConfig.form.get('visibleNone');
    const visibleEverywhere = formConfig.form.get('visibleEverywhere');
    const visibleNotStreamdust = formConfig.form.get('visibleNotStreamdust');
    const visibleStreamdust = formConfig.form.get('visibleStreamdust');

    const sendData = (visible: VIDEO_VISIBLE_SETTINGS) => {
      this.loadingService.loadingStart();
      this.streamService.updateVisibilitySettings(
        this.mediaType,
        this.mediaId,
        {
          visible
        }).pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.loadingService.loadingEnd();
          this.stream.media.visible = visible;
        }, () => this.loadingService.loadingEnd());
    };

    visibleNone.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((bool) => {
        if (bool) {
          visibleEverywhere.patchValue(false);
          visibleNotStreamdust.patchValue(false);
          visibleStreamdust.patchValue(false);
          sendData(VIDEO_VISIBLE_SETTINGS.NOWHERE);

        } else if (!visibleEverywhere.value && !visibleNotStreamdust.value && !visibleStreamdust.value) {
          setTimeout(() => {
            visibleNone.patchValue(true);
          }, 100);
        }
      });

    visibleEverywhere.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((bool) => {
        if (bool) {
          visibleNone.patchValue(false);
          visibleNotStreamdust.patchValue(false);
          visibleStreamdust.patchValue(false);
          sendData(VIDEO_VISIBLE_SETTINGS.EVERYWHERE);
        } else if (!visibleNone.value && !visibleNotStreamdust.value && !visibleStreamdust.value) {
          setTimeout(() => {
            visibleEverywhere.patchValue(true);
          }, 100);
        }
      });

    visibleNotStreamdust.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((bool) => {
        if (bool) {
          visibleEverywhere.patchValue(false);
          visibleNone.patchValue(false);
          visibleStreamdust.patchValue(false);
          sendData(VIDEO_VISIBLE_SETTINGS.EXCEPT_STREAMDUST);
        } else if (!visibleNone.value && !visibleEverywhere.value && !visibleStreamdust.value) {
          setTimeout(() => {
            visibleNotStreamdust.patchValue(true);
          }, 100);
        }
      });

    visibleStreamdust.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((bool) => {
        if (bool) {
          visibleNotStreamdust.patchValue(false);
          visibleEverywhere.patchValue(false);
          visibleNone.patchValue(false);
          sendData(VIDEO_VISIBLE_SETTINGS.STREAMDUST_ONLY);
        } else if (!visibleNone.value && !visibleEverywhere.value && !visibleNotStreamdust.value) {
          setTimeout(() => {
            visibleStreamdust.patchValue(true);
          }, 100);
        }
      });
  }
}
