<div class="card bg-white">
	<div class="card-body">
		<div class="section-panel">
			<div class="section-panel-header">
				<h4><b>AUFZEICHNUNGEN</b></h4>
			</div>
			<div class="section-panel-body">
				<ano-table #tableComponent [tableConfig]="tableConfig" [withSearch]="true" (search$)="search($event)"></ano-table>
				<ng-template #actionsTmpl let-data="rowData">
					<div class="table-actions-list">
						<b>
							<a [href]="data.downloadUrl" download class="text-danger">Download </a>
							<a href="javascript:void(0)" (click)="openRecord(data)" class="text-primary">| Speichern</a>
						</b>
					</div>
				</ng-template>
				<ng-template #statusTmpl let-data="rowData">
				    <span [ngStyle]="{color: data.online ? 'green' : 'red'}">
				        {{data.online ? 'ONLINE' : 'OFFLINE'}}
				    </span>
				</ng-template>
				<ng-template #linkTmpl let-data="rowData">
					<a [href]="data.downloadUrl" class="video-link">{{data.downloadUrl}}</a>
				</ng-template>
				<ng-template #dateTmpl let-data="rowData">
				    <span>
				        {{formatDate(data.created)}}
				    </span>
				</ng-template>
				<ng-template #sizeTpl let-data="rowData">
					{{(data.fileSize / 1024 / 1024).toFixed(2)}} MB
				</ng-template>
			</div>
		</div>
	</div>
</div>

<ng-template #modalRecord let-dialogRef="dialogRef">
	<ano-modal [dialogRef]="dialogRef" [title]="recordName" [withoutSubmit]="true">
		<!--    <div id="playerElement" style="width:100%; height:0; padding:0 0 56.25% 0"></div>-->
		<div style="display: flex; justify-content: center; align-items: center; width: 100%">
			<video style="width: 100%;" controls="controls">
				<source [src]="recordUrl" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'>
			</video>
		</div>
	</ano-modal>
</ng-template>
