import {Component, Input, OnInit} from '@angular/core';
import {COLORS} from "@src/app/utils/COLORS";

@Component({
  selector: 'app-simple-chart',
  templateUrl: './simple-chart.component.html',
  styleUrls: ['./simple-chart.component.sass']
})
export class SimpleChartComponent implements OnInit {
  public COLORS = COLORS;
  @Input() data: IChartItem[];

  constructor() { }

  ngOnInit(): void {
  }

}


export interface IChartItem {
    text: string;
    value: number;
}