import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DragAndDropComponent, INPUT_TYPES, LocalizationProvider, InfoPanelsConfig, OVERLAY_TYPE} from 'ui-elements';
import {PhotoService} from '@src/app/services/photo/photo.service';
import {PhotoCropperService} from '@src/app/services/photo-cropper/photo-cropper.service';
import {IFormFieldsConfig, StreamService} from '@src/app/services/stream-metadata/stream.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import {mergeMap, takeUntil, tap} from 'rxjs/operators';
import {IOverlayRequest, StreamMetadataSelectValues} from '@src/app/models/stream.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FormService} from '@src/app/services/form/form.service';
import {CustomValidators} from '@src/app/utils/custom-validators.util';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {IResponse} from '@src/app/models/response.model';
import {LoadingService} from '@src/app/services/loading/loading.service';
import {MEDIA_TYPE} from '@src/app/components/streamdust-player/constants/mediaType';
import {STREAM_STATUS} from '@src/app/components/streamdust-player/constants/status';
import {StreamMarketingToolsOverlaysTypes} from '@src/app/pages/stream-page/components/stream-marketing-tools-overlays/stream-marketing-tools-overlays.component';
import {SURVEY_STATUS} from '@src/app/services/survey/survey.service';
import {IOverlayLocationConfig} from '@src/app/services/overlay/overlay-location.service';
import {UserService} from '@src/app/services/user/user.service';
import {DateHelper} from '@src/app/utils/date.helper';


@Component({
    selector: 'app-stream-overlay',
    templateUrl: './stream-overlay.component.html',
    styleUrls: ['./stream-overlay.component.sass']
})
export class StreamOverlayComponent implements OnInit, OnDestroy {
    public InfoPanelsConfig = InfoPanelsConfig;
    public STREAM_STATUS = STREAM_STATUS;
    public MEDIA_TYPE = MEDIA_TYPE;
    public mediaType: MEDIA_TYPE;
    @ViewChild('photoUploader', {static: false}) private photoUploader: DragAndDropComponent;
    @ViewChild('pictureContainer', {static: false}) private pictureContainer: ElementRef<HTMLElement>;
    public payload: IOverlayRequest = {
        productId: '',
        productType: null,
        name: '',
        showDuration: 0,
        startTime: 0,
        position: '',
        color: '',
        picture: {
            workbenchId: '',
            previewSettings: {
                x: 0,
                y: 0,
                width: 0,
                height: 0,
                rotation: 0
            }
        },
    };
    public photoUrl: string;
    public form: FormGroup = this.fb.group({
        name: ['', [Validators.required]],
        link: ['', [CustomValidators.URL]],
        textDescription: ['', [Validators.required, Validators.maxLength(80)]],
        color: ['', [Validators.required]],
        position: [OVERLAY_TYPE.BOTTOM, [Validators.required]],
        manualStart: [false],
        showDuration: ['', [Validators.required, CustomValidators.timer]],
        startTime: ['', [Validators.required, CustomValidators.timer]]
    });
    public readonly imageSizes = {
        LEFT: {
            width: 140,
            height: 200
        },
        RIGHT: {
            width: 140,
            height: 200
        },
        BOTTOM: {
            width: 480,
            height: 70
        }
    };
    public pictureDisabled = false;
    public selectedPictureSize: any = this.imageSizes.BOTTOM;
    public previousPictureSize: any;
    public incorrectPictureSize = false;

    public pictureForm: FormGroup;
    activateForm = this.fb.group({
        active: [true]
    });
    public fieldsConfig: IFormFieldsConfig[][];
    public pictureConfig: IFormFieldsConfig[][];

    public readonly supportedPhotoFormats = ['jpeg', 'img', 'png', 'jpg'];
    public overlaysType: StreamMarketingToolsOverlaysTypes.OVERLAY | StreamMarketingToolsOverlaysTypes.COURSES;
    public configPage: any;
    private notifier$: Subject<any>;
    StreamMarketingToolsOverlaysTypes = StreamMarketingToolsOverlaysTypes;
    overlayLocationConfig: IOverlayLocationConfig = {};
    premiumPublisher: boolean;

    constructor(
        private photoService: PhotoService,
        private readonly photoCropperService: PhotoCropperService,
        public streamService: StreamService,
        private readonly activatedRoute: ActivatedRoute,
        private readonly destroy$: AutoDestroyService,
        private fb: FormBuilder,
        private router: Router,
        public formService: FormService,
        public loadingService: LoadingService,
        public localizationProvider: LocalizationProvider,
        public userService: UserService,
    ) {
    }

    ngOnInit() {
        this.checkPremium();

        this.overlaysType = (this.activatedRoute.data as BehaviorSubject<any>)?.value?.overlaysType;
        this.pictureForm = this.fb.group({
            picture: ['', this.overlaysType === StreamMarketingToolsOverlaysTypes.OVERLAY ? [Validators.required] : []]
        });

        if (this.overlaysType === StreamMarketingToolsOverlaysTypes.COURSES) {
            this.configPage = {
                title: 'courses.title',
                subtitle: 'courses.subtitle',
                titleCreate: 'courses.create'
            };
            this.form.removeControl('link');
            this.pictureDisabled = false;
        } else if (this.overlaysType === StreamMarketingToolsOverlaysTypes.OVERLAY) {
            this.configPage = {
                title: 'overlays.title',
                subtitle: 'overlays.subtitle',
                titleCreate: 'overlays.create'
            };
            this.form.removeControl('textDescription');
            this.form.removeControl('color');
        }

        this.form.get('position').valueChanges
            .pipe(takeUntil(this.destroy$))
            .subscribe((res) => {
                if (!res) {
                    return;
                }

                this.pictureDisabled = false;
                this.incorrectPictureSize = false;
                this.selectedPictureSize = this.imageSizes[res];
                if (this.previousPictureSize && this.previousPictureSize.width !== this.selectedPictureSize.width && (this.payload.picture.workbenchId || this.photoUrl)) {
                    this.incorrectPictureSize = true;
                    this.selectedPictureSize = this.imageSizes[res];
                    return;
                }
                this.previousPictureSize = this.selectedPictureSize;
            });


        this.activatedRoute.parent.params.pipe(
            takeUntil(this.destroy$),
            tap(({id}: Params) => {
                if (!id) {
                    return;
                }
                this.payload.productId = id;
                this.mediaType = this.resolveMediaType();
                this.fieldsConfig = [
                    [
                        {
                            name: 'name',
                            config: {
                                inputType: INPUT_TYPES.INPUT,
                                placeholder: this.overlaysType + '.name',
                                isRequired: true
                            }
                        },
                    ],
                    [
                        {
                            name: 'link',
                            hidden: this.overlaysType !== StreamMarketingToolsOverlaysTypes.OVERLAY,
                            config: {
                                inputType: INPUT_TYPES.INPUT,
                                placeholder: 'overlay.link',
                                url: true
                            }
                        },
                    ],
                    [
                        {
                            name: 'textDescription',
                            hidden: this.overlaysType !== StreamMarketingToolsOverlaysTypes.COURSES,
                            config: {
                                inputType: INPUT_TYPES.INPUT,
                                placeholder: 'overlay.textDescription',
                                isRequired: true
                            }
                        },

                    ],
                    [
                        {
                            name: 'position',
                            config: {
                                inputType: INPUT_TYPES.SELECT,
                                placeholder: 'Position',
                                selectOptions: StreamMetadataSelectValues.getPicturePositions(),
                                halfSize: true,
                                isRequired: true
                            }
                        },
                        {
                            name: 'color',
                            hidden: this.overlaysType !== StreamMarketingToolsOverlaysTypes.COURSES,
                            config: {
                                inputType: INPUT_TYPES.SELECT,
                                placeholder: 'course.color',
                                selectOptions: StreamMetadataSelectValues.getColorsTextDescription(),
                                halfSize: true,
                                isRequired: true
                            }
                        },
                    ],
                    [
                        {
                            name: 'manualStart',
                            hidden: this.mediaType === MEDIA_TYPE.VIDEO_ON_DEMAND,
                            config: {
                                inputType: INPUT_TYPES.CHECKBOX,
                                placeholder: 'survey.field.manualStart',
                                size: 6,
                            }
                        },
                    ],
                    [
                        {
                            name: 'startTime',
                            title: this.overlaysType + '.start-time',
                            config: {
                                inputType: INPUT_TYPES.INPUT,
                                bottomText: this.overlaysType + '.start-time.explanation.' + this.mediaType,
                                placeholder: this.overlaysType + '.start-time',
                                halfSize: true,
                                appendText: 'hh:mm:ss',
                                mask: 'h0:m0:s0',
                                isRequired: true
                            }
                        },
                        {
                            name: 'showDuration',
                            title: this.overlaysType + '.duration',
                            config: {
                                inputType: INPUT_TYPES.INPUT,
                                bottomText: this.overlaysType + '.duration.explanation.' + this.mediaType,
                                placeholder: this.overlaysType + '.duration',
                                halfSize: true,
                                appendText: 'hh:mm:ss',
                                mask: 'h0:m0:s0',
                                isRequired: true
                            }
                        },
                    ]
                ];
                this.payload.productType = this.mediaType;
                this.pictureConfig = [
                    [
                        {
                            name: 'picture',
                            config: {
                                inputType: INPUT_TYPES.IMAGE,
                                placeholder: 'overlay.image',
                                accept: 'image/*',
                                isRequired: this.overlaysType === StreamMarketingToolsOverlaysTypes.OVERLAY,
                                size: 0,
                                isCourse: this.overlaysType === StreamMarketingToolsOverlaysTypes.COURSES
                            }
                        }
                    ]
                ];
            }),
            mergeMap(() => this.activatedRoute.params))
            .subscribe(({id}: Params) => {
                if (!id) {
                    this.switchToAutomaticMode(true);
                    this.listenManualStartChange();
                    return;
                }
                this.loadingService.loadingStart();
                this.payload.id = id;
                this.overlayLocationConfig.overlayId = this.payload.id;
                this.pictureForm.get('picture').clearValidators();
                this.streamService.getOverlay(this.payload.id, this.overlaysType).pipe(takeUntil(this.destroy$)).subscribe(streamOverlay => {
                    this.loadingService.loadingEnd();
                    streamOverlay.link = streamOverlay.url;
                    streamOverlay.showDuration = DateHelper.getStringFromSeconds(streamOverlay.showDuration as number);
                    if (this.mediaType === MEDIA_TYPE.STREAM) {
                        streamOverlay.startTime = streamOverlay.startTime !== null ? DateHelper.getStringFromSeconds((streamOverlay.startTime as number) - new Date().getTimezoneOffset() * 60) : null;
                    } else {
                        streamOverlay.startTime = streamOverlay.startTime !== null ? DateHelper.getStringFromSeconds(streamOverlay.startTime as number) : null;
                    }
                    if (!streamOverlay.startTime) {
                        this.form.get('manualStart').patchValue(true);
                        this.fieldsConfig[5][0].config.disabled = true;
                        this.form.get('startTime').clearValidators();
                        this.form.get('startTime').updateValueAndValidity();
                        this.form.get('startTime').markAsUntouched();
                    }
                    this.switchToAutomaticMode();
                    this.selectedPictureSize = this.imageSizes[streamOverlay.position];
                    this.pictureContainer.nativeElement.style.width = streamOverlay.picture ? this.selectedPictureSize.width + 'px' : 'auto';
                    this.form.patchValue(streamOverlay);
                    this.photoUrl = streamOverlay?.picture?.photoUrl;
                    this.listenManualStartChange();
                    this.activateForm.get('active').patchValue(streamOverlay.active);
                    this.listenActiveForm();
                }, () => this.loadingService.loadingEnd());
            });
    }

    private resolveMediaType(): MEDIA_TYPE {
        return (this.activatedRoute.data as BehaviorSubject<any>)?.value?.mediaType;
    }

    private resolveOverlaysUrl(): string {
        const mediaIdRelatedUrlSegment = this.payload.productId + '/' + this.overlaysType;
        switch (this.mediaType) {
            case MEDIA_TYPE.STREAM:
            case MEDIA_TYPE.STREAM_RECORDING:
                return 'streams/' + mediaIdRelatedUrlSegment;
            case MEDIA_TYPE.VIDEO_ON_DEMAND:
                return 'vod/' + mediaIdRelatedUrlSegment;

        }
    }

    wrapUrl(url) {
        return url;
    }

    public uploadPreviewPhoto({files}): void {
        const file = files;
        this.photoCropperService.completeWorkbenchReadySubject();

        if (!file) {
            return;
        }

        if (!this.form.get('position').value) {
            return;
        }

        if (!this.resolveFileName(file.name)) {
            this.setImageUploadErrors();
            return;
        }

        this.photoService.generateUploader({
            file: file,
            aspectRatio: this.selectedPictureSize.width / this.selectedPictureSize.height
        }).pipe(takeUntil(this.destroy$))
            .subscribe(
                ({croppingParams, workbenchId, croppedImage}) => {
                    if (croppedImage) {
                        this.photoUrl = croppedImage;
                    } else {
                        const reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = () => {
                            this.photoUrl = reader.result.toString();
                        };
                    }
                    this.pictureContainer.nativeElement.style.width = this.selectedPictureSize.width + 'px';
                    this.photoCropperService.completeWorkbenchReadySubject();
                    this.payload.picture.workbenchId = workbenchId;
                    this.payload.picture.previewSettings = croppingParams;
                    this.incorrectPictureSize = false;
                    this.previousPictureSize = this.selectedPictureSize;
                    this.pictureForm.get('picture').patchValue(this.payload.picture.workbenchId);
                },
            );
    }

    private resolveFileName(fileName: string): boolean {
        return !!this.supportedPhotoFormats.find(format => format === fileName.split('.').pop());
    }

    private setImageUploadErrors(): void {
        this.pictureForm.get('picture').setErrors({unsupportedImageFormat: true});
        this.pictureForm.get('picture').markAsTouched();
    }

    parseSecondsToTime(seconds): string {
        let min: number | string = Math.floor(seconds / 60);
        let sec: number | string = seconds % 60;

        if (min < 10) {
            min = '0' + min;
        }

        if (sec < 10) {
            sec = '0' + sec;
        }

        return `${min}:${sec}`;
    }

    parseTimeToSeconds(time): number {
        const duration = time.split(':');

        if (!duration.length) {
            return time;
        }

        return (+duration[0] * 60) + (+duration[1] || 0);
    }

    public submit(): void {
        this.form.markAllAsTouched();
        this.pictureForm.markAllAsTouched();
        if (this.incorrectPictureSize) {
            return;
        }
        if (!this.payload.picture?.workbenchId && !this.payload.id && this.overlaysType === StreamMarketingToolsOverlaysTypes.OVERLAY) {
            this.formService.submitInvalid$.emit();
            return;
        }

        if (this.payload.picture?.workbenchId) {
            this.pictureForm.get('picture').patchValue(this.payload.picture.workbenchId);
        } else {
            delete this.payload.picture;
        }

        if (this.form.invalid) {
            this.formService.submitInvalid$.emit();
            return;
        }

        this.payload.name = this.form.get('name').value;
        if (this.overlaysType === StreamMarketingToolsOverlaysTypes.OVERLAY) {
            this.payload.link = this.form.get('link').value;
            delete this.payload.color;
        }
        if (this.overlaysType === StreamMarketingToolsOverlaysTypes.COURSES) {
            this.payload.textDescription = this.form.get('textDescription').value;
            this.payload.color = this.form.get('color').value;
            this.payload.noPhoto = !this.photoUrl;
        }

        this.payload.position = this.form.get('position').value;
        this.payload.showDuration = DateHelper.getSecondsFromString(this.form.get('showDuration').value);
        this.payload.startTime = this.form.get('manualStart').value ? null : DateHelper.getSecondsFromString(this.form.get('startTime').value);

        if (this.mediaType === MEDIA_TYPE.STREAM && !this.form.get('manualStart').value) {
            this.payload.startTime = this.payload.startTime + (new Date().getTimezoneOffset() * 60);
        }

        let overlayObserver: Observable<IResponse>;

        if (this.payload.id) {
            overlayObserver = this.streamService.updateOverlay(this.payload, this.overlaysType);
        } else {
            overlayObserver = this.streamService.createOverlay(this.payload, this.overlaysType);
        }
        this.loadingService.loadingStart();

        overlayObserver.pipe(takeUntil(this.destroy$))
            .subscribe((res) => {
                this.loadingService.loadingEnd();
                if (res.success) {
                    // @ts-ignore
                    const newId = res.results?.data;
                    if (newId) {
                        this.router.navigate([this.payload.id ? '../../' : '../' + newId + '/edit'], {relativeTo: this.activatedRoute});
                    }
                }
            }, () => this.loadingService.loadingEnd());
    }

    public clearImage(): void {
        // this.pictureForm.get('picture').setValidators([Validators.required]);
        this.pictureForm.get('picture').setValue('');
        this.pictureContainer.nativeElement.style.width = '100%';
        this.photoUrl = '';
        this.payload.picture = {
            workbenchId: '',
            previewSettings: {
                x: 0,
                y: 0,
                width: 0,
                height: 0,
                rotation: 0
            }
        };
    }

    public cancel(): void {
        let qp = {};
        let url = '../../';
        if (!this.payload.id) {
            url = '../';
        }

        if ((this.activatedRoute.data as BehaviorSubject<any>)?.value?.standAlone) {
            url = '../../../';
            qp = { overlays: true};
        }
        this.router.navigate([url], {relativeTo: this.activatedRoute, queryParams: qp});
    }

    public getForms(): FormGroup[] {
        return [this.form, this.pictureForm];
    }

    private listenManualStartChange(): void {
        this.form.get('manualStart').valueChanges.subscribe((res) => {
            const startTime = this.fieldsConfig.find(item => item[0].name === 'startTime')[0];
            startTime.config.disabled = !!res;

            if (res) {
                this.form.get('startTime').clearValidators();
                this.form.get('startTime').updateValueAndValidity();
                this.form.get('startTime').markAsUntouched();
            } else {
                this.form.get('startTime').setValidators([Validators.required]);
                // this.form.get('startTime').updateValueAndValidity();
                // this.form.get('startTime').markAsTouched();
            }
        });
    }

    private listenActiveForm() {
        this.activateForm.get('active').valueChanges.pipe(takeUntil(this.destroy$)).subscribe(active => {
            if (active) {
                this.start();
                return;
            }

            this.stop();
        });
    }

    public start(): void {
        this.streamService.overlayActive({
            overlayId: this.payload.id,
            active: true
        }, this.overlaysType).pipe(takeUntil(this.destroy$)).subscribe(() => {
        });
    }

    public stop(): void {
        this.streamService.overlayActive({
            overlayId: this.payload.id,
            active: false
        }, this.overlaysType).pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.form.get('status')?.patchValue(this.localizeStatus(SURVEY_STATUS.INACTIVE));
        });
    }

    private localizeStatus(status: SURVEY_STATUS): string {
        return this.localizationProvider.getByKey('survey.status.' + status.toLowerCase());
    }

    private switchToAutomaticMode(isNew: boolean = false): void {
        if (this.notifier$) {
            this.notifier$.next();
            this.notifier$.complete();
        }
        this.notifier$ = new Subject<any>();
        this.streamService.stream$.pipe(takeUntil(this.notifier$)).subscribe((stream) => {
            if (!stream) {
                return;
            }

            if (stream.media?.status?.stream === STREAM_STATUS.FINISHED && stream.media?.hasRecording) {
                this.form.get('manualStart').patchValue(false);
                this.fieldsConfig[5][0].config.disabled = false;
                this.fieldsConfig[4][0].hidden = true;
                this.form.get('startTime').setValidators([Validators.required]);
                if (!isNew) {
                    this.form.get('startTime').updateValueAndValidity();
                    this.form.get('startTime').markAsTouched();
                }
            }

            this.notifier$.next();
            this.notifier$.complete();
        });

    }

    ngOnDestroy(): void {
        if (this.notifier$) {
            this.notifier$.next();
            this.notifier$.complete();
        }
    }

    private checkPremium() {
        this.userService.userProfile$.pipe(takeUntil(this.destroy$))
            .subscribe((res) => {
                if (!res) {
                    return;
                }
                this.premiumPublisher = res.subscribed;
            });
    }
}
