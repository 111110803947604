import {Component, Input, OnInit} from '@angular/core';
import {IPaidDonation} from "@src/app/services/donations/donations.service";

@Component({
  selector: 'app-paid-donations',
  templateUrl: './paid-donations.component.html',
  styleUrls: ['./paid-donations.component.sass']
})
export class PaidDonationsComponent implements OnInit {
  @Input() data: IPaidDonation[];

  constructor() { }

  ngOnInit(): void {
  }

}
