<div class="card-header no-padding-bottom">
    <h3 class="page-title title-level-3">
        <b>{{'stream.revenue.country.title' | localize}}</b> <span class="light"> ({{timeRange.start | dateFormat: 'MMMM YYYY'}})</span></h3>
</div>

<div class="card-body">
    <ano-table *ngIf="tableConfig" class="country-revenues" #tableComponent [tableConfig]="tableConfig">
        <ng-container role="table-header">
            <tr>
                <th>
                    <div class="row row-padding-0">
                        <ng-container>
                            <div class="col mr-auto text-left">
                                <span>{{ selectedCountry?.id === 'all' ? ('stream.revenue.country.worldwide' | localize) : 'In ' + selectedCountry?.name}}</span>
                            </div>
                            <div class="col-auto" *ngIf="countriesList?.length > 1">
                                <div class="dropdown">
                                    <button class="btn btn-primary btn-dropdown-rectangle btn-sm"
                                            (click)="toggleFilter()">
                                        {{selectedCountry.name || ''}}
                                    </button>
                                    <div class="dropdown-menu" [class.open]="dropdownOpened">
                                        <ng-container *ngFor="let country of countriesList">
                                            <a class="dropdown-item" [class.active]="country?.selected"
                                               (click)="filter(country)">{{country?.name}}</a>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </th>
            </tr>
        </ng-container>
    </ano-table>
</div>

<ng-template #typeTmpl let-data="rowData">
    <div class="type-tmpl">
        <ng-container *ngIf="data.type === PURCHASE_TYPE.DISCOUNT">
            <span>{{'stream.revenue.accesstype.discountvauchers' | localize}} </span> <span class="text-primary">{{data.priceDiscount.value}}%</span>
        </ng-container>
        <ng-container *ngIf="data.type === PURCHASE_TYPE.GENERAL">
            <span>{{'stream.revenue.accesstype.payments' | localize}}</span>
        </ng-container>
        <ng-container *ngIf="data.type === PURCHASE_TYPE.DONATIONS">
            <span>{{'stream.revenue.accesstype.donations' | localize}}</span>
        </ng-container>
        <ng-container *ngIf="data.type === PURCHASE_TYPE.SLOT">
            <span>{{'stream.revenue.accesstype.slot' | localize}}
                <span>"{{data.paymentSlotName}}"</span>
                <span> {{data.deleted ? ('stream.revenue.deleted' | localize) : ''}}</span>
            </span>
        </ng-container>
    </div>
</ng-template>

<ng-template #tableSummary>
    <tr class="summary" *ngIf="allPurchases?.purchasesByCountry?.length || selectedCountryPurchase">
        <td width="127" class="text-left min-width-127">{{ selectedCountry?.id === 'all' ? ('stream.revenue.country.worldwide' | localize) : selectedCountry?.name}}</td>
        <td width="100" class="min-width-100"></td>
        <td class="text-right purchase-amount-template-total-{{userService.localLanguage$ | async}}">{{ selectedCountry.id === 'all' ? allPurchases.purchasesAmount : selectedCountryPurchase.purchasesAmount }}</td>
        <td class="price-vat-template-total-{{userService.localLanguage$ | async}}"></td>
        <td class="net-total-cost-template-total-{{userService.localLanguage$ | async}}"></td>
        <td class="text-left net-total-cost-sum-template-total-{{userService.localLanguage$ | async}} price">{{ selectedCountry.id === 'all' ? ({amount: allPurchases.netTotalCostSum, currency: data.currency || 'EUR'} | currency): ({amount: selectedCountryPurchase.netTotalCostSum, currency: data.currency || 'EUR' } | currency)}}</td>
        <td class="price-vat-sum-template-total-{{userService.localLanguage$ | async}}"></td>
        <td class="price gross-total-cos-sum-template-total-{{userService.localLanguage$ | async}}">{{ selectedCountry.id === 'all' ? ({amount: allPurchases.grossTotalCostSum, currency: data.currency || 'EUR'} | currency): ({amount: selectedCountryPurchase.grossTotalCostSum, currency: data.currency || 'EUR' } | currency)}}</td>
    </tr>
</ng-template>
<ng-template #countryTmpl let-data="rowData">
    <div class="country-template">
        {{data.country}}
    </div>
</ng-template>

<ng-template #priceVatTmpl let-data="rowData">
    <div class="price-vat-template-{{userService.localLanguage$ | async}}">
        {{{amount: data.priceVat, currency: data.currency || 'EUR'}  | currency}}
    </div>
</ng-template>

<ng-template #purchasesAmountTmpl let-data="rowData">
    <div class="purchase-amount-template-{{userService.localLanguage$ | async}}">
        {{data.purchasesAmount}}
    </div>
</ng-template>

<ng-template #netTotalCostTmpl let-data="rowData">
    <div class="net-total-cost-template-{{userService.localLanguage$ | async}}">
        {{{amount: data.netTotalCost, currency: data.currency || 'EUR'}  | currency}}
    </div>
</ng-template>
<ng-template #netTotalCostSumTmpl let-data="rowData">
    <div class="net-total-cost-sum-template-{{userService.localLanguage$ | async}}">
        {{{amount: data.netTotalCostSum, currency: data.currency || 'EUR'}  | currency}}
    </div>
</ng-template>

<ng-template #priceVatSumTmpl let-data="rowData">
    <div class="price-vat-sum-template-{{userService.localLanguage$ | async}}">
        {{{amount: data.priceVatSum, currency: data.currency || 'EUR'}  | currency}}
    </div>
</ng-template>

<ng-template #grossTotalCostSumTmpl let-data="rowData">
    <div class="gross-total-cos-sum-template-{{userService.localLanguage$ | async}}">
        {{{amount: data.grossTotalCostSum, currency: data.currency || 'EUR'}  | currency}}
    </div>
</ng-template>
