/**
 * Created by Тима on 03.04.2020.
 */
import {Component, TemplateRef, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { DeleteAccountService } from '@src/app/pages/user-profile/components/delete-account/services/delete-account.service';
import { IFieldsConfig } from '@src/app/pages/user-profile/interfaces/interfaces-common';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import {takeUntil} from 'rxjs/operators';
import {Router} from '@angular/router';
import {AuthService} from '@src/app/services/auth/auth.service';
import {MatDialog} from "@angular/material/dialog";

@Component({
    selector: 'app-delete-account',
    templateUrl: './delete-account.component.html',
    styleUrls: ['./delete-account.component.sass'],
    providers: [DeleteAccountService]
})
export class DeleteAccountComponent {
    @ViewChild('confirm', {static: false}) confirm: TemplateRef<any>;
    public fieldsConfig: IFieldsConfig[];
    public form: FormGroup;

    constructor(
        private deleteAccountService: DeleteAccountService,
        private destroy$: AutoDestroyService,
        private fb: FormBuilder,
        private router: Router,
        private authService: AuthService,
        public dialog: MatDialog
    ) {
        this.form = this.generateForm();
        this.fieldsConfig = this.deleteAccountService.getFieldsConfig();
    }

    private generateForm(): FormGroup {
        return this.fb.group({
            reason: ['', [Validators.required]]
        });
    }
    public saveChanges(): void {
        const dialogRef = this.dialog.open(this.confirm);
        dialogRef.afterClosed()
            .pipe(takeUntil(this.destroy$))
            .subscribe((confirm: boolean) => {
                if (confirm) {
                    this.deleteAccountService.deleteAccount(this.form.getRawValue())
                        .pipe(takeUntil(this.destroy$))
                        .subscribe((res) => {
                            if (!res.success) {
                                return;
                            }

                            this.authService.logout();
                            this.router.navigate(['login']);
                        });
                }
            });
    }
}
