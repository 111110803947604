<app-resizable-element #resizableContainer
                       [title]="'stream-world-map.title' | localize"
                       [cssClass]="'worldmap'"
                       [id]="'bubbleMapContainer-' + id"
                       [hiddenClass]="!(worldMapState.worldMapActiveState$ | async) ? 'stream-world-map-hidden' : ''"
                       [embeddedClass]="(worldMapState.worldMapOverlayState$ | async) || playerDisplayMode === PLAYER_DISPLAY_MODE.EMBEDDED ? 'world-map-embedded' : ''"
                       [fullScreenClass]="fullscreen ? 'full-screen' : ''"
                       [fullScreenDisabledClass]="fullscreen ? 'full-screen-disabled' : ''"
                       [dragPosition]="dragPosition"
                       [draggable]="!(worldMapState.worldMapOverlayState$ | async) && playerDisplayMode === PLAYER_DISPLAY_MODE.DEFAULT"
                       [resizable]="!(worldMapState.worldMapOverlayState$ | async) && playerDisplayMode === PLAYER_DISPLAY_MODE.DEFAULT"
                       [footerHidden]="true"
                       [embedded]="embeddedButton"
                       [close]="closeButton"
                       (resize)="onResize($event)"
                       (resizeEnd)="resizeEnd()"
>
    <ng-container role="body">
        <div class="bubble-map-wrapper">
            <app-bubble-map *ngIf="width > 0 && height > 0" [id]="id" [dataArray]="dataArray"
                            [rzWidth]="width - 178"
                            [rzHeight]="height - 42"
                            (onBubbleHover$)="updateViewersData($event)"
                            [width]="height - 42"
                            [resize]="resize$"
            ></app-bubble-map>
            <ano-loader [isOpen]="loading"></ano-loader>
        </div>
        <p class="total-viewers">
            <span class="break-after">{{totalViewers | numberWithSeparator}}</span>
            <span class="text-xs break-after">{{'stream-world-map.viewers' | localize}}</span>
            <span class="text-xs">{{'stream-world-map.worldwide' | localize}}</span>
        </p>
        <p *ngIf="viewersInACountry && currentCountry" class="country-viewers">
            <span class="break-after">{{viewersInACountry | numberWithSeparator}}</span>
            <span class="text-xs break-after">{{'stream-world-map.viewers' | localize}}</span>
            <span class="text-xs">{{'stream-world-map.from' | localize}} {{currentCountry}}</span>
        </p>
    </ng-container>
</app-resizable-element>
