import {Pipe, PipeTransform} from '@angular/core';
import {DateHelper} from "@src/app/utils/date.helper";

@Pipe({
    name: 'dateTimeFormat'
})
export class DateTimeFormatPipe implements PipeTransform {

    transform(value: unknown, format?: string, ...args: unknown[]): unknown {
        if (!value) {
            return;
        }

        return DateHelper.formatDateTime(value, format);
    }

}
