import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {INPUT_TYPES} from "ui-elements";
import {IFieldsConfig} from "@src/app/pages/user-profile/interfaces/interfaces-common";
import {CustomValidators, isAlphanumeric} from "@src/app/utils/custom-validators.util";
import {AuthService, ICredentials} from "@src/app/services/auth/auth.service";
import {AutoDestroyService} from "@src/app/services/auto-destroy-service/auto-destroy.service";
import {takeUntil} from "rxjs/operators";
import {Router} from "@angular/router";
import {LocalizationProvider} from 'ui-elements';
import {HeaderService} from "@src/app/services/header/header.service";

@Component({
    selector: 'app-new-password',
    templateUrl: './new-password.component.html',
    styleUrls: ['./new-password.component.sass'],
    providers: [AutoDestroyService]
})
export class NewPasswordComponent implements OnInit {

    @Input() showPopup = false;
    public loading = false;
    public form: FormGroup;
    public fieldsConfig: IFieldsConfig[] = [
        {
            name: 'password',
            config: {
                inputType: INPUT_TYPES.PASSWORD,
                type: 'password',
                label: this.localizationProvider.getByKey('registration_confirmation.form.new.password'),
                placeholder: '',
                isRequired: true
            }
        },
        {
            name: 'passwordConfirm',
            config: {
                inputType: INPUT_TYPES.PASSWORD,
                label: this.localizationProvider.getByKey('registration_confirmation.form.repreat.password'),
                type: 'password',
                placeholder: '',
                isRequired: true
            }
        },
    ];

    constructor(
        private fb: FormBuilder,
        private authService: AuthService,
        private headerService: HeaderService,
        private localizationProvider: LocalizationProvider,
        private readonly destroy$: AutoDestroyService,
        private router: Router) {
    }

    ngOnInit(): void {
        const passwordControl = new FormControl('',
            [Validators.required, CustomValidators.password]);
        this.form = this.fb.group({
            password: passwordControl,
            passwordConfirm: ['', [CustomValidators.passwordsMatch(passwordControl)]],
        });
    }

    public setPassword(): void {
        this.form.markAllAsTouched();
        if (this.form.invalid) {
            return;
        }

        this.loading = true;
        const password = this.form.get('password').value;
        this.authService.storeTokenForCall(this.authService.tempToken);
        this.authService.registrationSecondStep({password: password})
            .pipe(takeUntil(this.destroy$))
            .subscribe((res) => {
                this.loading = false;
                if (!res.success) {
                    return;
                }
                this.authService.storeToken(this.authService.tempToken);
                this.headerService.hideHeaderPopup();
                // const credentials: ICredentials = {
                //     email: res.results.email,
                //     password: password
                // };
                // this.authService.storeToken(this.authService.tempToken);
                // this.loading = true;
                // this.authService.login(credentials).pipe(takeUntil(this.destroy$)).subscribe(_res => {
                //     this.loading = false;
                //     if (!_res.success) {
                //         return;
                //     }

                this.router.navigate(['home']);
                // });
            }, () => {
                this.loading = false;
                this.authService.storeToken('');
            });
    }

}
