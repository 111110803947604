import {Injectable} from '@angular/core';
import {ITableConfig, TEMPLATE_ID} from 'ui-elements';
import {IPagedResponse, PagingParams, SortParam} from '@src/app/models/response.model';
import {Observable} from 'rxjs';
import {ILead} from '@src/app/pages/stream-page/components/media-leads/config/lead-model';

@Injectable()
export class LeadsConfig {
    private config: ITableConfig<ILead>;

    constructor() {
        this.initConfig();
    }

    private initConfig(): void {
        this.config = {
            dataField: 'leadGeneration',
            searchFn: null,
            columns: [
                {
                    name: 'lead.table.field.name',
                    sortField: 'FIRST_NAME',
                    dataField: 'firstName',
                    width: 70
                }, {
                    name: 'lead.table.field.lastName',
                    sortField: 'LAST_NAME',
                    dataField: 'lastName',
                    width: 100
                }, {
                    name: 'lead.table.field.email',
                    sortField: 'EMAIL',
                    dataField: 'email',
                    width: 100
                }, {
                    name: 'lead.table.field.verified',
                    sortField: 'VERIFIED',
                    tmplId: TEMPLATE_ID.STATUS,
                    width: 100
                }, {
                    name: 'lead.table.field.city',
                    sortField: 'CITY',
                    dataField: 'city',
                    width: 100
                },
                {
                    name: 'lead.table.field.country',
                    sortField: 'COUNTRY',
                    dataField: 'country',
                    width: 100
                },
                {
                    name: 'lead.table.field.action',
                    tmplId: TEMPLATE_ID.ACTIONS,
                    width: 100
                }
            ]
        };
    }
    public resolveConfig(searchFn: (sortParams: SortParam[], pagingParams: PagingParams) => Observable<IPagedResponse<ILead>>): ITableConfig<ILead> {
        this.config.searchFn = searchFn;
        return this.config;
    }
}
