import { Injectable } from '@angular/core';
import {ApiService, IResponse} from 'ui-elements';
import {Observable} from 'rxjs/internal/Observable';
import {API_URLS_APP, UrlGenerator} from '@src/app/constants/api-urls.constant';
import {active} from 'd3-v4-typings/tmp';
import {pluck} from 'rxjs/internal/operators';
import {MEDIA_TYPE} from '@src/app/components/streamdust-player/constants/mediaType';
import {IMediaVisibility} from '@src/app/models/stream.model';

@Injectable({
  providedIn: 'root'
})
export class WorldmapService {

  constructor(
      private apiService: ApiService
  ) { }

  // public getState(mediaId: string): Observable<{active: boolean}> {
  //   return this.apiService.get(UrlGenerator.generate(API_URLS_APP.GET_MAP_STATE, {mediaId}))
  //       .pipe(pluck('results'));
  // }

  public getState(mediaType: MEDIA_TYPE, mediaId: string): Observable<IMediaVisibility> {
    return this.apiService.get(UrlGenerator.generate(API_URLS_APP.MEDIA_VISIBILITY_GET, {mediaType, mediaId}))
        .pipe(pluck('results', 'data'));
  }

  public setState(mediaType: MEDIA_TYPE, mediaId: string, body: IWorldmapStateReq): Observable<IResponse> {
    return this.apiService.put(UrlGenerator.generate(API_URLS_APP.SET_MAP_STATE, {mediaType, mediaId}), body);
  }

  public setShareState(mediaType: MEDIA_TYPE, mediaId: string, body: IWorldmapStateReq): Observable<IResponse> {
    return this.apiService.put(UrlGenerator.generate(API_URLS_APP.SET_SHARE_STATE, {mediaType, mediaId}), body);
  }

  public setVideoTitle(mediaType: MEDIA_TYPE, mediaId: string, body: IWorldmapStateReq): Observable<IResponse> {
    return this.apiService.put(UrlGenerator.generate(API_URLS_APP.SET_VIDEO_TITLE, {mediaType, mediaId}), body);
  }
}

export interface IWorldmapStateReq {
  active: boolean;
}
