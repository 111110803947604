import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-stream-survey',
    templateUrl: './stream-survey.component.html',
    styleUrls: ['./stream-survey.component.sass']
})
export class StreamSurveyComponent implements OnInit {
    constructor(
    ) {
    }

    ngOnInit(): void {
    }
}
