<footer class="footer">
    <div class="container">
        <ul class="nav-footer">
            <li><a href="http://beta.streamdust.tv/info/ueberuns/" target="_blank">{{'footer.link.aboutus' | localize}}</a></li>
            <li><a href="http://beta.streamdust.tv/info/kontakt/" target="_blank">{{'footer.link.contact' | localize}}</a></li>
            <li><a href="http://beta.streamdust.tv/info/agb/" target="_blank">{{'footer.link.agb' | localize}}</a></li>
            <li><a href="http://beta.streamdust.tv/info/impressum/" target="_blank">{{'footer.link.impression' | localize}}</a></li>
            <li><a href="http://beta.streamdust.tv/info/datenschutz/" target="_blank">{{'footer.link.dataProtection' | localize}}</a></li>
            <li><a href="http://beta.streamdust.tv/info/urheberrechtsverletzungen/" target="_blank">{{'footer.link.copyright' | localize}}</a></li>
        </ul>
    </div>
</footer>
