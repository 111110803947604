import * as moment from 'moment';

export class DateHelper {
    public static formatDateTime(timestamp: any, format: string = 'DD MMMM YYYY, HH:mm', utc = false): string {
        const language = localStorage.getItem('localLanguage');
        let hrs = language === 'EN' ? '' : ' Uhr';
        if (utc) {
            format = 'DD MMMM YYYY, HH:mm UTC Z';
            hrs = '';
        }
        return moment(timestamp).locale(language).format(format) + hrs;
    }

    public static formatDate(timestamp: any, format = 'DD MMMM YYYY', localTime = false): string {
        const language = localStorage.getItem('localLanguage');
        if (localTime) {
            return moment(timestamp).locale(language).format(format);
        }
        return moment.utc(timestamp).utcOffset('+00:00').locale(language).format(format);
    }

    public static formatTime(timestamp: any, format = 'HH:mm'): string {
        const language = localStorage.getItem('localLanguage');
        return moment(timestamp).locale(language).format(format);
    }

    public static formatDayAndTime(timestamp: any): string {
        const language = localStorage.getItem('localLanguage');
        const hrs = language === 'EN' ? ' h' : ' Uhr';
        return moment(new Date(timestamp)).locale(language).format('dddd, HH:mm') + hrs;
    }

    public static inRange(value, rangeMin, rangeMax) {
        return value >= rangeMin && value <= rangeMax;
    }

    public static timePassed(timestamp: any, minutes): boolean {
        return timestamp - (minutes * 60 * 1000) <= (new Date().getTime());
    }

    public static timeInRange(timestamp, minutes): boolean {
        return this.inRange(new Date().getTime(), timestamp - minutes * 60 * 1000, timestamp);
    }

    public static parseTime(time, type?: TIME_TYPE): number {
        const duration = time.split(':');

        if (!duration.length) {
            return time;
        }

        if (type === TIME_TYPE.MINUTES) {
            return (+duration[0]);
        }

        if (type === TIME_TYPE.MILLISECONDS) {
            return ((+duration[0] * 60) + (+duration[1] || 0)) * 1000;
        }

        return (+duration[0] * 60) + (+duration[1] || 0);
    }

    public static getMillisecondsFromString(time: string): number {
        return moment(time, 'HH:mm:ss: A').diff(moment().startOf('day'), 'milliseconds');
    }

    public static getStringFromMilliseconds(value: number): string {
        return moment.utc(value).format('HH') +
            moment.utc(value).format(':mm') + moment.utc(value).format(':ss');
    }

    public static getSecondsFromString(time: string): number {
        return moment(time, 'HH:mm:ss: A').diff(moment().startOf('day'), 'seconds');
    }

    public static getStringFromSeconds(value: number): string {
        value = value * 1000;
        return moment.utc(value).format('HH') +
            moment.utc(value).format(':mm') + moment.utc(value).format(':ss');
    }
}


export enum TIME_TYPE {
    MINUTES, MILLISECONDS
}
