import { Component, OnInit, Self } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AutoDestroyService } from '@src/app/services/auto-destroy-service/auto-destroy.service';
import { takeUntil } from 'rxjs/operators';
import { RouterEventHandlerService } from '@src/app/services/router-event-handler/router-event-handler.service';
import {HeaderService} from '@src/app/services/header/header.service';
import {IButtonConfig} from 'ui-elements';

@Component({
  selector: 'app-access-denied-page',
  templateUrl: './access-denied-page.component.html',
  styleUrls: ['./access-denied-page.component.sass'],
  providers: [AutoDestroyService]
})
export class AccessDeniedPageComponent implements OnInit {

  public config: IAccessDeniedConfig = {} as any;

  private readonly ACCESS_DENIED_PAGE_CONFIGS: TAccessDeniedPageConfigs = {
    [ACCESS_DENIED_VIOLATIONS.ACCOUNT_IS_HIDDEN]: {
      title: 'default.hideAccount.title',
      descriptions: [
        'default.hideAccount.description',
      ],
      buttons: [
        {
          text: 'default.hideAccount.button.openSettings',
          config: {
            styleType: 'primary',
            sizeType: 'block',
          },
          action: () => {
            this.router.navigateByUrl('/settings/account-management');
          },
        },
        {
          text: 'default.hideAccount.button.goBack',
          config: {
            styleType: 'secondary',
            sizeType: 'block',
          },
          action: () => {
            this.routerEventHandlerService.goToPreviousUrl();
          },
        },
      ],
    },
  };

  public constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly routerEventHandlerService: RouterEventHandlerService,
    @Self() private readonly destroy$: AutoDestroyService,
    private headerService: HeaderService
  ) {
    this.route.queryParams
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe(({ violation }) => {
        this.config = this.ACCESS_DENIED_PAGE_CONFIGS[violation] || {};
      });
  }

  public ngOnInit(): void {
    this.headerService.headerEmitter.next({
      title: 'navigation.accessDenied'
    });

    if (this.routerEventHandlerService.urlStack.length &&
        !this.routerEventHandlerService.urlStack[this.routerEventHandlerService.urlStack.length - 1].includes('search')) {
      this.routerEventHandlerService.urlStack.pop();
    }
  }

}

enum ACCESS_DENIED_VIOLATIONS {
  ACCOUNT_IS_HIDDEN = 'ACCOUNT_IS_HIDDEN',
}

interface IAccessDeniedConfig {
  title: string;
  descriptions: string[];
  buttons: { text: string, config: IButtonConfig, action: Function }[];
}

type TAccessDeniedPageConfigs = { [T in ACCESS_DENIED_VIOLATIONS]: IAccessDeniedConfig };
