<div class="panel mb-20">
    <div class="panel-header pb-0">
        <h2 class="title-lg"><b>{{'nav.revenue' | localize}}</b></h2>
    </div>
    <div class="panel-body bg-white padding-top-20 padding-bottom-20">
        <ano-table #table class="revenues"
                   [withSearch]="true"
                   [timeRangeConfig]="timeRangeConfig"
                   (timeRangeFilter$)="timeRangeFilter($event)"
                   [tableConfig]="tableConfig"
                   [showDatepicker]="true"
                   (search$)="search($event)"
        ></ano-table>
    </div>
</div>

<ng-template #actionsTmpl let-data="rowData">
    <a [routerLink]="data.id"><i class="icon icon-login text-primary"></i></a>
</ng-template>

<ng-template #idTmpl let-data="rowData">
</ng-template>

<ng-template #statusTmpl let-data="rowData">
    <i class="icon-{{ data?.payoutDone ? 'check' : 'waiting'}} text-primary"></i>
</ng-template>

<ng-template #titleTmpl let-data="rowData">
    <div class="long-text">
        <span>{{data?.title}}</span>
    </div>
</ng-template>

<ng-template #priceTmpl let-data="rowData">
    <div class="long-text">
        <span>{{{amount: data?.price, currency: 'EUR'} | currency}}</span>
    </div>
</ng-template>

<ng-template #dateTmpl let-data="rowData">
    <div class="long-text">
        <span>{{data?.payoutDate | dateFormat: 'MMM YYYY'}}</span>
    </div>
</ng-template>

<ng-template #soldAmountTmpl let-data="rowData">
    <span>{{data?.soldAmount}}</span>
</ng-template>

<ng-template #openPayoutTmpl let-data="rowData">
    <span>{{{amount: data?.income, currency: 'EUR'} | currency }}</span>
</ng-template>

<ng-template #transactionTmpl let-data="rowData">
    <span>{{data?.creditNotId}}</span>
</ng-template>

<ng-template #typeTmpl let-data="rowData">
    <span>{{('stream.revenue.' + data.productType) | localize}}</span>
</ng-template>

