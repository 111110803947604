<div class="content">
    <div class="panel panel-md">
        <div class="panel-header">
            <h2 *ngIf="(userService.userType$ | async) === USER_TYPE.VIEWER">{{'viewer-dashboard.greeting' | localize}}, <b>{{userName}}</b></h2>
            <h2 *ngIf="(userService.userType$ | async) !== USER_TYPE.VIEWER">{{'media-list.publisher.title' | localize}}</h2>
            <p class="page-description">{{'viewer-dashboard.page-description' | localize}}</p>
        </div>
        <div class="panel-body padding-0">
            <div class="section-panel">
                <div class="section-panel-body">
                    <app-media-lists [mediaLists]="viewerMediaListSettings" [activeQuery]="query"
                                     [config]="viewerMediaListsConfig"></app-media-lists>
                </div>
            </div>
        </div>
    </div>
</div>
