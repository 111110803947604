import {Pipe, PipeTransform} from '@angular/core';
import * as moment from "moment";
import {LocalizationProvider} from 'ui-elements';
import {Duration} from "moment";

@Pipe({
    name: 'dateUntil',
    pure: true
})

export class DateUntilPipe implements PipeTransform {

    // [345+ days]			in X year(s)
    // [45 - 344 days]		in X month(s)
    // [14 - 44 days] 		in X days
    // [2 - 13 days]		in X days Y hours
    // [24 - 47 hours]		in X hours
    // [1 - 23 hours] 		in X hours Y minutes
    // [10 - 59 minutes]	in X minutes
    // [1 - 9 minutes]		in X minutes Y seconds
    // [0 - 59 seconds]	in X seconds

    public startTimeStamps = [31536000000, 3888000000, 1209600000, 172800000, 86400000, 3600000, 600000, 60000, 0];
    public divider = ':';
    public config = {
        localize: true,
        useDivider: false,
        labels: {
            prefix: 'datetime.future.prefix',
            years: 'datetime.years',
            year: 'datetime.year',
            months: 'datetime.months',
            month: 'datetime.month',
            hours: 'datetime.hours',
            hour: 'datetime.hour',
            days: 'datetime.days',
            day: 'datetime.day',
            minutes: 'datetime.minutes',
            minute: 'datetime.minute',
            seconds: 'datetime.seconds',
            second: 'datetime.second'
        },
        ranges: [
            {
                // [345+ days]			in X year(s)
                start: 0,
                end: 315569520000000,
                type: TIME_FORMATS.YEARS
            },
            {
                // [45 - 344 days]		in X month(s)
                start: 0,
                end: 0,
                type: TIME_FORMATS.MONTHS
            },
            {
                // [14 - 44 days] 		in X days
                start: 0,
                end: 0,
                type: TIME_FORMATS.DAYS
            },
            {
                // [2 - 13 days]		in X days Y hours
                start: 0,
                end: 0,
                type: TIME_FORMATS.DAYS_HOURS
            },
            {
                // [24 - 47 hours]		in X hours
                start: 0,
                end: 0,
                type: TIME_FORMATS.HOURS
            },
            {
                // [1 - 23 hours] 		in X hours Y minutes
                start: 0,
                end: 0,
                type: TIME_FORMATS.HOURS_MINUTES
            },
            {
                // [10 - 59 minutes]	in X minutes
                start: 0,
                end: 0,
                type: TIME_FORMATS.MINUTES
            },
            {
                // [1 - 9 minutes]		in X minutes Y seconds
                start: 0,
                end: 0,
                type: TIME_FORMATS.MINUTES_SECONDS
            },
            {
                // [0 - 59 seconds]	in X seconds
                start: 0,
                end: 0,
                type: TIME_FORMATS.SECONDS
            }
        ]
    };

    public constructor(
        private localizationProvider: LocalizationProvider
    ) {
        this.startTimeStamps.forEach((timestamp, index) => {
            this.config.ranges[index].start = timestamp;
            if (index > 0) {
                this.config.ranges[index].end = this.config.ranges[index - 1].start - 1;
            }
        });
        if (this.config.localize) {
            Object.keys(this.config.labels).forEach(key => {
                if (!this.config.useDivider) {
                    this.config.labels[key] = ` ${this.localize(this.config.labels[key])} `;

                } else {
                    this.config.labels[key] = this.localize(this.config.labels[key]);
                }
            });
        }
        if (!this.config.useDivider) {
            this.divider = '';
        }
    }

    localize(key) {
        return this.localizationProvider.getByKey(key);
    }

    public calculateDuration(startDate: number, endDate: number, config?: DateUntilPipeConfig) {
        let diffInMs;

        if (!config?.duration) {
            diffInMs = endDate - startDate;
        } else {
            diffInMs = startDate;
        }

        return moment.duration(diffInMs);
    }

    public findFormatType(duration: Duration) {
        for (let range of this.config.ranges) {
            if (duration.asMilliseconds() <= range.end && duration.asMilliseconds() >= range.start) {
                return range.type;
            }
        }
    }

    public transform(startDate: number, endDate: number, config?: DateUntilPipeConfig): string {
        return this.formatDuration(this.calculateDuration(startDate, endDate, config));
    }

    formatDuration(duration: Duration) {
        const formatType = this.findFormatType(duration);

        switch (formatType) {
            case TIME_FORMATS.YEARS: {
                // [365+ days]			in X year(s)
                const years = duration.get('years');
                const yearsLabel = years > 1 ? this.config.labels.years : this.config.labels.year;
                return `${years}${yearsLabel}`;
            }
            case TIME_FORMATS.MONTHS: {
                // [45 - 365 days]		in X month(s)
                const months = duration.get('months');
                const monthsLabel = months > 1 ? this.config.labels.months : this.config.labels.month;
                return `${months}${monthsLabel}`;
            }
            case TIME_FORMATS.DAYS: {
                // [14 - 44 days] 		in X days
                const days = Math.floor(duration.asMilliseconds() / 1000 / 3600 / 24);
                const daysLabel = days > 1 ? this.config.labels.days : this.config.labels.day;
                return `${days}${daysLabel}`;
            }
            case TIME_FORMATS.DAYS_HOURS: {
                // [2 - 13 days]		in X days Y hours
                const days = duration.get('days');
                const daysLabel = days > 1 ? this.config.labels.days : this.config.labels.day;
                const hours = duration.get('hours');
                const hoursLabel = hours > 1 ? this.config.labels.hours : this.config.labels.hour;
                return `${days}${daysLabel}${this.divider}${hours}${hoursLabel}`;
            }
            case TIME_FORMATS.HOURS: {
                // [24 - 47 hours]		in X hours
                const hours = Math.ceil(duration.asHours());
                const hoursLabel = hours > 1 ? this.config.labels.hours : this.config.labels.hour;
                return `${hours} ${hoursLabel}`;
            }
            case TIME_FORMATS.HOURS_MINUTES: {
                // [1 - 23 hours] 		in X hours Y minutes
                const hours = duration.get('hours');
                const hoursLabel = hours > 1 ? this.config.labels.hours : this.config.labels.hour;
                const minutes = duration.get('minutes');
                const minutesLabel = minutes > 1 ? this.config.labels.minutes : this.config.labels.minute;
                return `${hours}${hoursLabel}${this.divider}${minutes}${minutesLabel}`;
            }
            case TIME_FORMATS.MINUTES: {
                // [10 - 59 minutes]	in X minutes
                const minutes = duration.get('minutes');
                const minutesLabel = minutes > 1 ? this.config.labels.minutes : this.config.labels.minute;
                return `${minutes}${minutesLabel}`;
            }
            case TIME_FORMATS.MINUTES_SECONDS: {
                // [1 - 9 minutes]		in X minutes Y seconds
                const minutes = duration.get('minutes');
                const minutesLabel = minutes > 1 ? this.config.labels.minutes : this.config.labels.minute;
                const seconds = duration.get('seconds');
                const secondsLabel = seconds > 1 ? this.config.labels.seconds : this.config.labels.seconds;
                return `${minutes}${minutesLabel}${this.divider}${seconds}${secondsLabel}`;
            }
            case TIME_FORMATS.SECONDS: {
                // [0 - 59 seconds]	in X seconds
                const seconds = duration.get('seconds');
                const secondsLabel = seconds > 1 ? this.config.labels.seconds : this.config.labels.seconds;
                return `${seconds}${secondsLabel}`;
            }
            default: {
                // return durationInMs.asMilliseconds().toString();
                return `0${this.config.labels.seconds}`;
            }
        }
    }
}

export enum TIME_FORMATS {
    YEARS = 'YEARS',
    MONTHS = 'MONTH',
    DAYS = 'DAYS',
    DAYS_HOURS = 'DAYS_HOURS',
    HOURS = 'HOURS',
    HOURS_MINUTES = 'HOURS_MINUTES',
    MINUTES = 'MINUTES',
    MINUTES_SECONDS = 'MINUTES_SECONDS',
    SECONDS = 'SECONDS'
}

interface DateUntilPipeConfig {
    duration?: boolean;
}
