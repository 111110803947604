import {EventEmitter, Injectable} from '@angular/core';
import {BehaviorSubject, Observable, ReplaySubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  // public loading: EventEmitter<boolean> = new EventEmitter();
  public loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private regularLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public regularLoading$: Observable<boolean> = this.regularLoading.asObservable();

  public progress: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  constructor() { }

  public loadingStart(): void {
    this.loading.next(true);
  }

  public regularLoadingStart(): void {
    this.regularLoading.next(true);
  }

  public regularLoadingEnd(): void {
    this.regularLoading.next(false);
  }

  public loadingEnd(): void {
    this.loading.next(false);
    this.clearProgress();
  }

  public updateProgress(progress: number): void {
    if (progress > 95) {
      progress = 95;
    }
    this.progress.next(progress);
  }

  private clearProgress(): void {
    this.progress.next(0);
  }
}
