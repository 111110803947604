import {ISideNavConfig} from '@src/app/components/side-nav/side-nav.component';
import {STREAM_STATUS} from '@src/app/components/streamdust-player/constants/status';
import {USER_TYPE} from '@src/app/constants/user-type.constant';
import {MEDIA_TYPE} from '@src/app/components/streamdust-player/constants/mediaType';
import {PAYMENT_SYSTEM} from '@src/app/models/stream.model';

export class SideNavConfigModel {
    static getLeftMenuConfig(userType?: USER_TYPE, hasConnectedPublisher?: boolean, callback = () => {
    }, premiumPublisher = false, publisherCallback = () => {
    }, invited = false): ISideNavConfig {
        if (!userType) {
            return this.getLeftMenuPublisherConfig(false, USER_TYPE.PUBLISHER, false, publisherCallback, false);
        }
        switch (userType) {
            case USER_TYPE.PUBLISHER:
            case USER_TYPE.SUB_ACCOUNT:
                return this.getLeftMenuPublisherConfig(premiumPublisher, userType, hasConnectedPublisher, publisherCallback, invited);
            case USER_TYPE.VIEWER:
                return this.getLeftMenuViewerConfig(callback);
        }
    }

    static getLeftMenuPublisherConfig(premiumPublisher = false,
                                      userType: USER_TYPE,
                                      hasConnectedPublisher: boolean,
                                      publisherCallback = () => {
                                      }, invited = false): ISideNavConfig {
        return {
            buttons: [
                {
                    show: userType === USER_TYPE.SUB_ACCOUNT && invited,
                    cssClass: 'btn btn-block btn-icon btn-blue btn-full',
                    text: 'nav.logoutAs',
                    icon: 'login',
                    callback: publisherCallback,
                    position: BUTTON_POSITION.BOTTOM
                }
            ],
            items: [
                {
                    text: 'nav.dashboard',
                    icon: 'icon-home',
                    link: '/dashboard'
                },
                {
                    text: 'nav.livestreams',
                    subItems: [
                        {
                            text: 'nav.create',
                            link: '/stream/new',
                        },
                        {
                            text: 'nav.overview',
                            link: '/streams'
                        },
                    ],
                    icon: 'icon-stream',
                    opened: false,
                },
                {
                    text: 'nav.vod',
                    icon: 'icon-video',
                    subItems: [
                        {
                            text: 'nav.create',
                            link: '/video-on-demand/new'
                        },
                        {
                            text: 'nav.overview',
                            link: '/videos-on-demand'
                        },
                    ],
                    opened: false,
                },
                {
                    text: 'nav.groups',
                    icon: 'icon-users',
                    link: '/groups',
                    hide: !premiumPublisher
                },
                {
                    text: 'nav.userProfile',
                    icon: 'icon-admin-settings',
                    opened: false,
                    subItems: [
                        {
                            text: 'nav.profile.info',
                            link: '/user/edit'
                        },
                        {
                            text: 'nav.profile.changeSubscription',
                            link: '/user/subscription-manage',
                            hide: userType === USER_TYPE.SUB_ACCOUNT
                        },
                        {
                            text: 'nav.profile.changePassword',
                            link: '/user/change-password',
                            hide: userType === USER_TYPE.SUB_ACCOUNT
                        },
                        {
                            text: 'nav.profile.storage-and-bandwidth',
                            link: '/user/space-storage-and-bandwidth'
                        },
                        {
                            text: 'nav.profile.agb',
                            link: '/user/withdrawal-and-conditions',
                            hide: userType === USER_TYPE.SUB_ACCOUNT
                        },
                        {
                            text: 'nav.profile.bankDetails',
                            link: '/user/bank-details',
                            hide: userType === USER_TYPE.SUB_ACCOUNT
                        },
                        {
                            text: 'nav.profile.accountDelete',
                            link: '/user/delete-account',
                            hide: userType === USER_TYPE.SUB_ACCOUNT
                        }
                    ]
                },
                {
                    text: 'nav.revenue',
                    icon: 'icon-money',
                    link: '/revenue',
                    hide: userType === USER_TYPE.SUB_ACCOUNT
                },
                {
                    text: 'nav.bills',
                    icon: 'icon-list',
                    link: '/bills',
                    hide: userType === USER_TYPE.SUB_ACCOUNT
                },
                {
                    text: 'nav.viewer',
                    icon: 'icon-tv-show',
                    opened: false,
                    subItems: [
                        {
                            text: 'nav.viewer.media',
                            link: '/viewer/media'
                        },
                        {
                            text: 'nav.bills',
                            link: '/viewer/bills'
                        },
                    ],
                    hide: userType === USER_TYPE.VIEWER
                },
                {
                    text: 'nav.subaccounts',
                    icon: 'icon-users',
                    link: '/subaccounts',
                    hide: userType === USER_TYPE.SUB_ACCOUNT
                },
                {
                    text: 'nav.connectedPublisher',
                    icon: 'icon-users',
                    link: '/connected-publishers',
                    hide: !hasConnectedPublisher || userType === USER_TYPE.SUB_ACCOUNT
                },
            ]
        };
    }

    static getLeftMenuViewerConfig(callback = () => {
    }): ISideNavConfig {
        return {
            buttons: [
                {
                    show: true,
                    cssClass: 'btn btn-block btn-icon btn-blue btn-full',
                    text: 'nav.upgradeToPublisher',
                    icon: 'rocket',
                    callback: callback,
                    position: BUTTON_POSITION.BOTTOM
                }
            ],
            items: [
                {
                    text: 'nav.dashboard',
                    link: '/streams',
                    icon: 'icon-home-round',
                    opened: false,
                },
                {
                    text: 'nav.userProfile',
                    icon: 'icon-user',
                    opened: false,
                    subItems: [
                        {
                            text: 'nav.profile.info',
                            link: '/user/edit'
                        },
                        {
                            text: 'nav.profile.changePassword',
                            link: '/user/change-password'
                        },
                        {
                            text: 'nav.profile.paymentCards',
                            link: '/user/payment-cards'
                        },
                        {
                            text: 'nav.profile.accountDelete',
                            link: '/user/delete-account'
                        }
                    ]
                },
                {
                    text: 'nav.bills',
                    icon: 'icon-invoice',
                    link: '/bills'
                },
            ]
        };
    }

    static getRightMenuConfig(mediaType: MEDIA_TYPE,
                              stream,
                              navigateUrl: string,
                              callback: () => void, premiumPublisher, userType: USER_TYPE, path: string): ISideNavConfig {
        const infoBoxAvail = path === 'info';
        const config = {
            infoBox: mediaType === MEDIA_TYPE.STREAM && stream?.status?.stream === STREAM_STATUS.SCHEDULED && infoBoxAvail,
            items: [
                {
                    text: 'nav.video.preview',
                    link: 'info',
                    icon: mediaType === MEDIA_TYPE.STREAM ? 'icon-stream' : 'icon-video'
                },
                {
                    text: 'nav.video.bearbeiten',
                    link: 'edit',
                    icon: 'icon-edit'
                },
                {
                    text: 'nav.video.thumbnail',
                    link: 'thumbnail',
                    icon: 'icon-image'
                },
                {
                    text: 'nav.video.marketingTools',
                    icon: 'icon-toolbox',
                    opened: false,
                    subItems: [
                        {
                            text: 'nav.video.marketingTools.discount',
                            hide: !premiumPublisher || userType === USER_TYPE.SUB_ACCOUNT || !stream?.paymentSystem.psVoucher,
                            link: 'coupons'
                        },
                        {
                            text: 'nav.video.marketingTools.previewTeaser',
                            hide: !premiumPublisher,
                            link: 'teaser'
                        },
                        {
                            text: 'nav.video.marketingTools.advertisingTrailer',
                            hide: !premiumPublisher,
                            link: 'trailer'
                        },
                        {
                            text: 'nav.video.marketingTools.overlays',
                            link: 'overlays'
                        },
                        {
                            text: 'lead.title',
                            hide: !premiumPublisher || userType === USER_TYPE.SUB_ACCOUNT
                                || stream?.paymentSystem.paymentSystemGroup === PAYMENT_SYSTEM.FREE_ACCESS
                                || stream?.paymentSystem === PAYMENT_SYSTEM.ENTERPRISE_LOGIN,
                            link: 'leads'
                        },
                        {
                            text: 'nav.video.marketingTools.surveys',
                            link: 'surveys'
                        },
                        {
                            text: 'nav.video.marketingTools.groupsOfLinks',
                            link: 'links'
                        },
                        {
                            text: 'nav.video.marketingTools.domainRestrictions',
                            link: 'domain-restrictions'
                        },
                        {
                            text: 'nav.video.marketingTools.courses',
                            hide: !premiumPublisher || userType === USER_TYPE.SUB_ACCOUNT
                                || stream?.paymentSystem.paymentSystemGroup !== PAYMENT_SYSTEM.COURSE_ACCESS,
                            link: 'courses'
                        },
                        {
                            text: 'nav.video.marketingTools.coursesOverview',
                            hide: !premiumPublisher || userType === USER_TYPE.SUB_ACCOUNT
                                || stream?.paymentSystem.paymentSystemGroup !== PAYMENT_SYSTEM.COURSE_ACCESS,
                            link: 'courses-overview'
                        },
                        {
                            text: 'nav.video.presentations',
                            link: 'presentations',
                            hide: !premiumPublisher,
                        },
                        {
                            text: 'nav.video.remind',
                            link: 'remind',
                            hide: !premiumPublisher || mediaType !== MEDIA_TYPE.STREAM
                                || (stream?.status?.stream === STREAM_STATUS.FINISHED && stream?.hasRecording)
                        },
                    ]
                },
                {
                    text: 'nav.video.includeStream',
                    icon: 'icon-code',
                    opened: false,
                    subItems: [
                        {
                            text: 'nav.video.viewerLink',
                            // link: 'streams/{id}/iframe'
                            link: 'iframe',
                            hide: mediaType !== MEDIA_TYPE.VIDEO_ON_DEMAND
                                && stream?.status?.stream === STREAM_STATUS.FINISHED && !stream?.hasRecording
                        },
                        {
                            text: 'nav.video.websiteSetup',
                            // link: 'streams/{id}/landing'
                            link: 'landing'
                        },
                    ]
                },
                // {
                //     text: 'nav.video.records',
                //     // link: 'streams/{id}/records',
                //     hide: videoType !== VIDEO_TYPE.STREAM,
                //     link: 'record/info',
                //     icon: 'icon-video'
                // },
                // {
                //     text: 'Connect video software',
                //     link: 'streams/{id}/test8',
                //     icon: 'icon-shopping-cart'
                // },
                // {
                //     text: 'Eingeloggte Kunden',
                //     link: 'streams/{id}/test10',
                //     icon: 'icon-users'
                // },
                {
                    text: 'nav.video.revenue',
                    // link: 'streams/{id}/revenue',
                    link: 'revenue',
                    icon: ' icon-money',
                    hide: userType === USER_TYPE.SUB_ACCOUNT
                },
                {
                    text: 'nav.video.statistics',
                    link: 'statistic',
                    icon: 'icon-chart'
                }
            ],
            buttons: [{
                cssClass: 'btn btn-primary btn-block btn-icon',
                text: 'stream.overview.back',
                icon: 'home',
                callback: callback,
                show: !!navigateUrl,
                position: BUTTON_POSITION.TOP

            }],
            // backButtonConfig: {
            //     show: !!navigateUrl,
            //     buttonText: 'stream.overview.back',
            //     navigateUrl
            // }
        };

        return config;
    }
}

export enum BUTTON_POSITION {
    TOP, BOTTOM
}
