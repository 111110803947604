import {Component, OnInit} from '@angular/core';
import {FILTER_TYPES} from '@src/app/components/media-list/media-list-header/media-list-header.component';
import {ISuggestedMedia} from '@src/app/pages/public-media-page/public-media-page.component';
import {IListFilterItem, IListQuery, LOAD_TYPE} from '@src/app/components/media-list/media-list.component';
import {MEDIA_FILTERS_FIELDS, MediaListService} from '@src/app/services/media-list/media-list.service';
import {MEDIA_TYPE} from '@src/app/components/streamdust-player/constants/mediaType';
import {LandingService} from '@src/app/services/landing/landing.service';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import {LoadingService} from '@src/app/services/loading/loading.service';
import {takeUntil} from 'rxjs/operators';
import {UserService} from '@src/app/services/user/user.service';
import {STREAM_STATUS} from '@src/app/components/streamdust-player/constants/status';

@Component({
  selector: 'app-my-video',
  templateUrl: './my-video.component.html',
  styleUrls: ['./my-video.component.scss'],
  providers: [AutoDestroyService]
})
export class MyVideoComponent implements OnInit {

  FILTER_TYPE = FILTER_TYPES;

  actualStreamsListConfig: ISuggestedMedia = {
    title: 'video.my.title',
    thumbnailGrayScale: true,
    fetchMethod: (filters: IListQuery) => this.mediaListService.getMediaList(filters),
    filters: {
      filterTypes: [this.FILTER_TYPE.SORT, this.FILTER_TYPE.GENRE, this.FILTER_TYPE.CONTENT_ACCESS_TYPE, this.FILTER_TYPE.COUNTRY],
      mediaTypes: []
    }
  };

  LOAD_TYPE = LOAD_TYPE;

  implicitFilters: IListFilterItem[];

  activeFilters: IListQuery;

  constructor(
      private landingService: LandingService,
      private destroy$: AutoDestroyService,
      public loadingService: LoadingService,
      private mediaListService: MediaListService,
      private userService: UserService
  ) {
  }

  ngOnInit(): void {
    this.userService.userProfile$.pipe(takeUntil(this.destroy$))
        .subscribe((profile) => {
          this.implicitFilters = [
            {
              field: MEDIA_FILTERS_FIELDS.MEDIA_TYPE,
              value: MEDIA_TYPE.VIDEO
            },
            {
              field: MEDIA_FILTERS_FIELDS.MEDIA_TYPE,
              value: MEDIA_TYPE.STREAM_RECORDING
            },
            {
              field: MEDIA_FILTERS_FIELDS.MEDIA_TYPE,
              value: MEDIA_TYPE.STREAM
            },
            {
              field: MEDIA_FILTERS_FIELDS.STREAM_STATUS,
              value: [
                STREAM_STATUS.SCHEDULED, STREAM_STATUS.ACTIVATED, STREAM_STATUS.ACTIVATION,
                STREAM_STATUS.CONNECTED, STREAM_STATUS.PAUSED, STREAM_STATUS.LIVE]

            },
            {
              field: MEDIA_FILTERS_FIELDS.PUBLISHER,
              value: profile?.ownerId
            }
          ];
          this.activeFilters = {
            paging: {
              page: 0,
              itemsOnPage: 9
            },
            filters: this.implicitFilters
          };
          this.loadingService.loadingStart();
          this.mediaListService.getMediaList(this.activeFilters).pipe(takeUntil(this.destroy$)).subscribe(response => {
            this.actualStreamsListConfig.media = response?.results?.data;
            this.actualStreamsListConfig.originalLength = response?.results?.data?.items?.length;
            this.loadingService.loadingEnd();
          });
        });
  }

}
