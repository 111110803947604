import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-info-panel',
    templateUrl: './info-panel.component.html',
    styleUrls: ['./info-panel.component.css']
})
export class InfoPanelComponent {

    @Input() infoPanel: IInfoPanel;

    constructor() {
    }
}

export interface IInfoPanel {
    textKey: string;
    info?: boolean;
}

export class InfoPanelsConfig {
    static startDisabledInfo: IInfoPanel = {
        textKey: 'player.disabled-start-button-text'
    };
    static startEnabledInfo: IInfoPanel = {
        textKey: 'player.start-stream-info-message',
        info: true
    };
    static streamScheduledInfo: IInfoPanel = {
        textKey: 'player.stream-scheduled-info',
        info: true
    };

    static streamStartedInfo: IInfoPanel = {
        textKey: 'stream.readonly-started-stream-alert-text'
    };
    static streamFinishedInfo: IInfoPanel = {
        textKey: 'stream.readonly-finished-stream-alert-text'
    };
    static streamBandwidthLimitInfo: IInfoPanel = {
        textKey: 'stream.readonly-bandwidth-limit-alert-text'
    };
    static publisherUnapprovedInfo: IInfoPanel = {
        textKey: 'streams.unapproved-publisher-alert-text'
    };
    static publisherUnapprovedContentInfo: IInfoPanel = {
        textKey: 'public-media-page.info-panel.content-wont-be-shown-info'
    };
    static streamReschedulingInfo: IInfoPanel = {
        textKey: 'streams.rescheduling-alert-text',
        info: true
    };
    static manualSurveyDisabled: IInfoPanel = {
        textKey: 'survey.manual.disabled'
    };
    static noRegistrationDuringPurchase: IInfoPanel = {
        textKey: 'media-access-modal.no-registration-warning'
    };
    static leadGenerationWarning: IInfoPanel = {
        textKey: 'media-access-modal.lead-generation-warning'
    };
    static leadGenerationAlreadyRegistered: IInfoPanel = {
        textKey: 'media-access-modal.lead-generation-already-registered',
        info: true
    };

    static accountAlreadyRegistered: IInfoPanel = {
        textKey: 'error.REGISTRATION_EMAIL_ALREADY_EXISTS'
    };

    static loggedInAsSubAccount: IInfoPanel = {
        textKey: 'subaccounts.infoPanel.loggedAsSubAccount'
    };

    static subAccountInviteSent: IInfoPanel = {
        textKey: 'subaccounts.infoPanel.inviteSent',
        info: true
    };

    static invitedSubAccount: IInfoPanel = {
        textKey: 'subaccounts.infoPanel.invited'
    };
    static needToInputCorrectPhone: IInfoPanel = {
        textKey: 'registration.needToInputCorrectPhone'
    };
    static needToInputCorrectCompanyInformation: IInfoPanel = {
        textKey: 'registration.needToInputCompanyInformation'
    };

    static needToConfirmPhone: IInfoPanel = {
        textKey: 'registration.needToConfirmPhone'
    };
    static phoneConfirmed: IInfoPanel = {
        textKey: 'registration.phoneConfirmed',
        info: true
    };

    static overlayDisabled: IInfoPanel = {
        textKey: 'overlay.disabled'
    };

    static mediaDeactivated: IInfoPanel = {
        textKey: 'media.infoPanel.deactivated'
    };

    static storageOrBandwidthLimitReached: IInfoPanel = {
        textKey: 'account.infoPanel.storageOrBandwidthLimitReached'
    };

    static deactivateSurveyToEdit: IInfoPanel = {
        textKey: 'survey.infoPanel.deactivateToEdit'
    };

    static warningAfterVideoUpload: IInfoPanel = {
        textKey: 'video.infoPanel.upload.warning'
    };
}
