import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {IStreamRecordInfoModel, IStreamRecordingData} from '@src/app/models/stream.model';
import {IInfoTable} from '@src/app/pages/stream-page/components/stream-revenue/stream-revenue.component';
import * as moment from 'moment';
import {LocalizationProvider} from 'ui-elements';
import {SnackBarService} from 'ui-elements';
import {StreamRecordService} from '@src/app/services/stream-record/stream-record.service';
import {saveAs} from 'file-saver/FileSaver';

@Component({
    selector: 'app-stream-record-info',
    templateUrl: './stream-record-info.component.html',
    styleUrls: ['./stream-record-info.component.css']
})
export class StreamRecordInfoComponent implements OnInit {

    @Input() isEmbeded = false;
    // @Input() isRecordDownloading = false;
    @Input() recordData: IStreamRecordingData[];
    @Input() startStreamTimestamp: number;
    @Input() endStreamTimestamp: number;
    @Input() streamId: string;
    recordInfo: IStreamRecordInfoModel;
    recordInfoTable: IInfoTable[] = [];

    constructor(
        private readonly activatedRoute: ActivatedRoute,
        private router: Router,
        private localizationProvider: LocalizationProvider,
        private snackBarService: SnackBarService,
        private streamRecordService: StreamRecordService
    ) {
    }

    ngOnInit(): void {
        this.recordData.forEach(data => this.parseStreamRecordData(data));
    }

    localize(key) {
        return this.localizationProvider.getByKey(key);
    }

    formatStreamTime(startTime, endTime) {
        return `${moment(startTime).format('HH.mm')} Uhr bis ${moment(endTime).format('HH.mm')} Uhr`;
    }

    msToHours(duration) {
        const seconds = Math.floor((duration / 1000) % 60),
            minutes = Math.floor((duration / (1000 * 60)) % 60),
            hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

        const hoursString = (hours < 10) ? '0' + hours : hours,
            minutesString = (minutes < 10) ? '0' + minutes : minutes,
            secondsString = (seconds < 10) ? '0' + seconds : seconds;

        return hoursString + ':' + minutesString + ':' + secondsString + 'h';
    }

    parseStreamRecordData(record) {
        record.duration = this.msToHours(record.duration);
        record.time = this.formatStreamTime(this.startStreamTimestamp, this.endStreamTimestamp);
        record.startStreamTimestamp = moment(this.startStreamTimestamp).format('DD.MM.yyyy');
        record.fileSize = `${(record.fileSize / 1000 / 1000).toFixed(2)} MB`;
        record.recordInfoTable = this.getRecordInfoTable();
    }

    getRecordInfoTable(): IInfoTable[] {
        return [
            // {
            //     title: this.localize('stream.recording.info.livestream-title'),
            //     property: 'title'
            // },
            {
                title: this.localize('stream.recording.info.recording'),
                property: 'fileUrl',
                isLink: true
            },
            // {
            //     title: this.localize('stream.recording.info.date-of-broadcast'),
            //     property: 'startStreamTimestamp'
            // },
            // {
            //     title: this.localize('stream.recording.info.time'),
            //     property: 'time'
            // },
            // {
            //     title: this.localize('stream.recording.info.duration'),
            //     property: 'duration'
            // },
            // {
            //     title: this.localize('stream.recording.info.file-size'),
            //     property: 'fileSize'
            // }
        ];
    }

    copyValue(link) {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = link;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.snackBarService.showInfo('Copied "' + this.localizationProvider.getByKey('Aufzeichnung') + '"');
    }

    downloadRecord(event, recordInfo: IStreamRecordingData) {
        event.preventDefault();
        recordInfo.isRecordDownloading = true;

        this.streamRecordService.downloadStreamRecord(this.streamId).subscribe(data => {
            saveAs(
                data.body,
                recordInfo.fileName
            );
            recordInfo.isRecordDownloading = false;
        });
    }
}
