import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {StreamService} from '@src/app/services/stream-metadata/stream.service';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import {takeUntil} from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';
import {IMediaItem, IPicture} from '@src/app/models/core.model';
import {Router} from '@angular/router';
import {environment} from '@src/environments/environment';
import {RECORD_STATUS, STREAM_STATUS} from '@src/app/components/streamdust-player/constants/status';
import {CmsService} from 'ui-elements';
import {MEDIA_TYPE} from '@src/app/components/streamdust-player/constants/mediaType';
import {IUnitKeyValue} from 'ui-elements';
import {ITEM_TYPE, SETTING_TYPE} from '@src/app/pipes/item-setting';
import {IMediaGroup} from '@src/app/models/stream-groups.model';
import {GenerateUtil} from '@src/app/utils/generate.util';
import {VIDEO_VISIBLE_SETTINGS} from '@src/app/models/video-on-demand.model';
import {IUserProfile} from '@src/app/pages/user-profile/components/profile/services/profile.service';
import {UserService} from '@src/app/services/user/user.service';

@Component({
    selector: 'app-media-card',
    templateUrl: './media-card.component.html',
    styleUrls: ['./media-card.component.sass'],
    providers: [AutoDestroyService]
})
export class MediaCardComponent implements OnInit, AfterViewInit {
    MEDIA_TYPE = MEDIA_TYPE;
    @Input() config: IMediaCardConfig;
    @Input() data: IMediaItem | IMediaGroup | any;
    @Output() click$ = new EventEmitter<IMediaItem | IMediaGroup>();
    @Output() remove$ = new EventEmitter<IMediaItem | IMediaGroup>();
    @ViewChild('confirm', {static: false}) confirm: TemplateRef<any>;
    public environment = environment;
    public STREAM_STATUS = STREAM_STATUS;
    public RECORD_STATUS = RECORD_STATUS;
    public ITEM_TYPE = ITEM_TYPE;
    public SETTING_TYPE = SETTING_TYPE;
    public genres: IUnitKeyValue[];
    public countries: IUnitKeyValue[];
    public previewPicture: IPicture;
    public id = GenerateUtil.uuidv4();
    public isOwner: boolean;

    get visibilityOnlyIframe(): boolean {
        const isEmbedded = this.router.url.includes('/embedded/');
        return this.data.visibility === VIDEO_VISIBLE_SETTINGS.EXCEPT_STREAMDUST && !isEmbedded && !this.isOwner;
    }

    constructor(
        public streamService: StreamService,
        private readonly destroy$: AutoDestroyService,
        public dialog: MatDialog,
        private router: Router,
        private cmsService: CmsService,
        private userService: UserService,
    ) {
    }

    ngOnInit(): void {
        this.genres = this.cmsService.genres;
        this.countries = this.cmsService.countriesList;
        let data = this.data as IMediaItem;

        if (this.config?.groupView) {
            data = this.getNearestStreamFromGroup(this.data as IMediaGroup);
        }

        if (!data?.previewPicture?.photoUrl) {
            return;
        }

        this.previewPicture = data?.previewPicture;

        this.userService.userProfile$.pipe(takeUntil(this.destroy$))
            .subscribe((profile: IUserProfile) => {
                this.isOwner = profile?.ownerId === this.data.owner;
            });
    }



    ngAfterViewInit(): void {
        let data = this.data as IMediaItem;
        if (!data?.previewPicture?.photoUrl) {
            return
        }

        const img = data?.previewPicture;
        const imgCreated = new Image();
        imgCreated.id = this.id;
        imgCreated.src = environment.backendApiHost + img.photoUrl;
        imgCreated.classList.add('img-responsive');
        imgCreated.classList.add('image-thumbnail');
        if (this.config?.greyscale) {
            imgCreated.classList.add('greyscale');
        }
        imgCreated.onload = () => {
            document.getElementById('picture-' + this.id)?.append(imgCreated);
        };
    }

    public getNearestStreamFromGroup(group: IMediaGroup): IMediaItem {
        const nearestStreamTimeStamp = this.getNearestStreamTimeStamp(group.mediaItems);
        return group.mediaItems.find(mediaItem => mediaItem.startTimestamp === nearestStreamTimeStamp);
    }

    getNearestStreamTimeStamp(mediaItems: IMediaItem[]): number {
        return mediaItems.reduce((min, p) => p.startTimestamp < min ? p.startTimestamp : min, mediaItems[0].startTimestamp);
    }

    private resolveInfoLines(): void {
        //TODO make infolines resolver
    }

    public toggleRemove(event): void {
        event.stopImmediatePropagation();
        const dialogRef = this.dialog.open(this.confirm);
        dialogRef.afterClosed()
            .pipe(takeUntil(this.destroy$))
            .subscribe((confirm: boolean) => {
                if (confirm) {
                    this.remove();
                }
            });
    }

    private remove(): void {
        this.remove$.emit(this.data);
    }

    click(event) {
        event.stopImmediatePropagation();
        if (this.visibilityOnlyIframe) {
            return;
        }

        this.click$.emit(this.data);
    }

    goToGroupPage(event, groupId) {
        event.stopImmediatePropagation();
        if (this.config?.publisherView) {
            this.router.navigate(['groups', groupId]);
        } else {
            this.router.navigate(['groups', groupId, 'details']);
        }
    }
}


export interface IMediaCardConfig {
    isLanding?: boolean;
    isActual?: boolean;
    publisherView?: boolean;
    greyscale?: boolean;
    groupView?: boolean;
    backgroundClass?: string;
    skeletonBackgroundClass?: string;
    streamHeaderCssClass?: string;
    hideInfoLines?: boolean;
    infoLineCssClass?: string;
    elements?: IMediaCardElements;
    actions?: {
        remove?: boolean;
        removeConfirmTitle?: string;
        removeConfirmBody?: string;
    };
    hidePrice?: boolean;
    skeleton?: {
        headerCssClass?: string;
    };
}

export interface IMediaCardElements {
    country?: boolean;
    publisher?: boolean
    group?: boolean;
}
