<div class="panel" appScrollToFirstError [forms]="getForms()">
    <div class="panel-header">
        <h2><b>{{'stream.website.title' | localize}}</b></h2>
        <p class="text-sm">{{'stream.website.description' | localize}}</p>
    </div>
    <div class="panel-body bg-white">
        <div class="row align-items-end row-padding-0">
            <ano-loader [isOpen]="loadingService.loading | async"></ano-loader>
            <div class="col-md-9 mr-auto">
                <div class="section-panel">
                    <div class="section-panel-header">
                        <div class="title-box">
                            <h4><b>{{'stream.website.image.title' | localize}}</b> {{'stream.website.image.subtitle' | localize}}</h4>
<!--                            <app-bordered-form-->
<!--                                    [disabled]="(streamService.stream$ | async)?.media?.status?.stream === STREAM_STATUS.FINISHED && !(streamService.stream$ | async)?.media?.hasRecording"-->
<!--                                    [panel]="true"-->
<!--                                    [defaultForm]="true"-->
<!--                                    [form]="websiteForm"-->
<!--                                    [fieldsConfig]="websiteToggleConfig">-->
<!--                            </app-bordered-form>-->
                        </div>
                        <p>{{'stream.website.image.description' | localize}}</p>
                    </div>
                    <div class="section-panel-body">
                        <app-bordered-form
                                [disabled]="(streamService.stream$ | async)?.media?.deactivated || ((streamService.stream$ | async)?.media?.status?.stream === STREAM_STATUS.FINISHED && !(streamService.stream$ | async)?.media?.hasRecording) || ((streamService.stream$ | async)?.media?.status?.stream === STREAM_STATUS.PENDING)"
                                [panel]="true"
                                [defaultForm]="true"
                                [form]="wallpaperForm"
                                [fieldsConfig]="wallpaperConfig"
                                (onFileUpload$)="uploadPreviewPhoto($event)"
                                (clearImage$)="clearImage()"
                                [multimediaUrl]="photoUrl">
                        </app-bordered-form>
                    </div>
                </div>
                <div class="section-panel">
                    <div class="section-panel-header border-top">
                        <h4><b>{{'stream.website.url.title' | localize}}</b></h4>
                        <p>{{'stream.website.url.description' | localize}}</p>
                    </div>
                    <div class="section-panel-body">
                        <app-bordered-form
                                [disabled]="(streamService.stream$ | async)?.media?.deactivated || ((streamService.stream$ | async)?.media?.status?.stream === STREAM_STATUS.FINISHED && !(streamService.stream$ | async)?.media?.hasRecording) || ((streamService.stream$ | async)?.media?.status?.stream === STREAM_STATUS.PENDING)"
                                [panel]="true"
                                [defaultForm]="true"
                                [form]="websiteForm"
                                [fieldsConfig]="websiteConfig">
                        </app-bordered-form>
                    </div>
                </div>
                <!--<div class="section-panel">-->
                    <!--<div class="section-panel-header border-top">-->
                        <!--<h4><b>{{'stream.website.domain.title' | localize}}</b></h4>-->
                        <!--<p>{{'stream.website.domain.description' | localize}}</p>-->
                    <!--</div>-->
                    <!--<div class="section-panel-body">-->
                        <!--<app-bordered-form-->
                                <!--[disabled]="(streamService.stream$ | async)?.media?.status?.stream === STREAM_STATUS.FINISHED && !(streamService.stream$ | async)?.media?.hasRecording"-->
                                <!--[panel]="true"-->
                                <!--[defaultForm]="true"-->
                                <!--[form]="domainForm"-->
                                <!--[fieldsConfig]="domainConfig">-->
                        <!--</app-bordered-form>-->
                    <!--</div>-->
                <!--</div>-->
            </div>
            <div class="col-auto">
                <div class="section-panel">
                    <div class="section-panel-body">
                        <button *ngIf="!(streamService.stream$ | async)?.media?.deactivated && !((streamService.stream$ | async)?.media?.status?.stream === STREAM_STATUS.FINISHED && !(streamService.stream$ | async)?.media?.hasRecording) && !((streamService.stream$ | async)?.media?.status?.stream === STREAM_STATUS.PENDING)"
                                class="btn btn-success" (click)="submit()">{{'common.button.apply' | localize}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
