import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {ITableConfig, TableComponent} from 'ui-elements';
import {IVideoOnDemandModel} from '@src/app/models/video-on-demand.model';
import {
    VideoOnDemandService
} from '@src/app/services/video-on-demand/video-on-demand.service';
import {DateHelper} from '@src/app/utils/date.helper';
import {AutoDestroyService} from "@src/app/services/auto-destroy-service/auto-destroy.service";
import {takeUntil} from "rxjs/operators";
import {IMediaItem} from "@src/app/models/core.model";
import {LoadingService} from "@src/app/services/loading/loading.service";
import {LocalizationProvider} from 'ui-elements';
import {INPUT_TYPES} from 'ui-elements';
import {FormBuilder} from "@angular/forms";
import {
    IListFilterItem,
    IListQuery,
    LOAD_TYPE
} from "@src/app/components/media-list/media-list.component";
import {ISuggestedMedia} from "@src/app/pages/public-media-page/public-media-page.component";
import {IPagedResponseResults} from "@src/app/models/response.model";
import {UserService} from "@src/app/services/user/user.service";
import {MEDIA_FILTERS_FIELDS, MediaListService} from "@src/app/services/media-list/media-list.service";
import {FILTER_MEDIA_TYPES} from "@src/app/components/media-list/media-list-header/media-list-header.component";
import {ITimerange} from 'ui-elements';
import {ITimeRangeConfig} from 'ui-elements';
import {MEDIA_TYPE} from "@src/app/components/streamdust-player/constants/mediaType";
import {STREAM_STATUS} from '@src/app/components/streamdust-player/constants/status';
import { ITEM_TYPE, SETTING_TYPE } from '@src/app/pipes/item-setting';
import {IMediaMetadata} from '@src/app/models/stream.model';
import {MatDialog} from '@angular/material/dialog';
import * as moment from 'moment';
import {StreamService} from '@src/app/services/stream-metadata/stream.service';

@Component({
    selector: 'app-video-on-demand-list',
    templateUrl: './video-on-demand-list.component.html',
    styleUrls: ['./video-on-demand-list.component.sass']
})
export class VideoOnDemandListComponent implements OnInit {
    STREAM_STATUS = STREAM_STATUS;
    ITEM_TYPE = ITEM_TYPE;
    SETTING_TYPE = SETTING_TYPE;
    public MEDIA_TYPE = MEDIA_TYPE;
    public tableConfig: ITableConfig<IVideoOnDemandModel>;
    public videos: IPagedResponseResults<IMediaItem>;

    @ViewChild('confirm', {static: false}) confirm: TemplateRef<any>;
    @ViewChild('actionsTmpl', {static: true}) public actionsTmpl: TemplateRef<any>;
    @ViewChild('statusTmpl', {static: true}) public statusTmpl: TemplateRef<any>;
    @ViewChild('titleTmpl', {static: true}) public titleTmpl: TemplateRef<any>;
    @ViewChild('priceTmpl', {static: true}) public priceTmpl: TemplateRef<any>;
    @ViewChild('dateTmpl', {static: true}) public dateTmpl: TemplateRef<any>;
    @ViewChild('tableComponent', {static: true}) public table: TableComponent;
    public disabledVideosConfig: ITableConfig<IMediaItem>;
    searchQuery: string;
    public searchStreamsTimeRange: ITimerange;

    public ownerId: string;

    INPUT_TYPES = INPUT_TYPES;
    LOAD_TYPE = LOAD_TYPE;
    vodQuery: IListQuery = {
        paging: {
            page: 0,
            itemsOnPage: 8
        }
    };

    implicitFilters: IListFilterItem[];

    videoOnDemandListConfig: ISuggestedMedia = {
        fetchMethod: () => this.mediaListService.getMediaList(this.vodQuery)
    };

    timeRangeFilterConfig: ITimeRangeConfig;
    public confirmText: string;
    public streamsYears: number[];

    constructor(
        private router: Router,
        private videoOnDemandService: VideoOnDemandService,
        private destroy$: AutoDestroyService,
        private loadingService: LoadingService,
        private localizationProvider: LocalizationProvider,
        private formBuilder: FormBuilder,
        public userService: UserService,
        private mediaListService: MediaListService,
        private dialog: MatDialog,
        private streamsService: StreamService
    ) {
    }

    ngOnInit(): void {
        this.loadingService.loadingStart();
        // this.streamsService.getStreamYears().subscribe(years => {
        //     this.streamsYears = years;
        // });

        this.userService.userProfile$.pipe(takeUntil(this.destroy$)).subscribe(data => {
            if (data) {
                this.ownerId = data.ownerId;
                this.timeRangeFilterConfig = {
                    fetchMethod: (filters) => this.mediaListService.getMediaListTimeRanges(filters),
                    filters: [
                        {
                            field: MEDIA_FILTERS_FIELDS.MEDIA_TYPE,
                            value: [FILTER_MEDIA_TYPES.VIDEO_ON_DEMAND, FILTER_MEDIA_TYPES.STREAM_RECORDING]
                        },
                        {
                            field: MEDIA_FILTERS_FIELDS.PUBLISHER,
                            value: data.ownerId
                        },
                        {
                            field: MEDIA_FILTERS_FIELDS.VISIBLE,
                            value: false
                        }
                    ]
                };
                this.implicitFilters = [
                    {
                        field: MEDIA_FILTERS_FIELDS.PUBLISHER,
                        value: this.ownerId
                    },
                    {
                        field: MEDIA_FILTERS_FIELDS.MEDIA_TYPE,
                        value: FILTER_MEDIA_TYPES.VIDEO_ON_DEMAND
                    },
                    {
                        field: MEDIA_FILTERS_FIELDS.VISIBLE,
                        value: false
                    }
                ];
                this.vodQuery.filters = this.implicitFilters;
                this.getVideos();
                this.getDisabledVideos(data.ownerId);
            }
        });
    }

    public getVideos(): void {
        this.mediaListService.getMediaList(this.vodQuery, true, true).pipe(takeUntil(this.destroy$))
            .subscribe((res) => {
                this.loadingService.loadingEnd();
                this.videos = res.results.data;
            });
    }

    public getDisabledVideos(ownerId): void {
        this.disabledVideosConfig = {
            filtersWidth100: true,
            dataField: 'data',
            matPaginator: true,
            searchFn: (sortParams, pagingParams) => {
                const filters = [
                    {
                        field: MEDIA_FILTERS_FIELDS.PUBLISHER,
                        value: ownerId
                    },
                    {
                        field: MEDIA_FILTERS_FIELDS.MEDIA_TYPE,
                        value: FILTER_MEDIA_TYPES.VIDEO_ON_DEMAND
                    },
                    {
                        field: MEDIA_FILTERS_FIELDS.VISIBLE,
                        value: false
                    },
                    {
                        field: MEDIA_FILTERS_FIELDS.DEACTIVATED,
                        value: 'DEACTIVATED_ONLY'
                    }
                ];
                const query: IListQuery = {
                    searchTerm: this.searchQuery,
                    paging: pagingParams,
                    filters,
                    sort: sortParams,
                    timeRange: this.searchStreamsTimeRange
                };
                return this.mediaListService.getMediaList(query, true, true);
            },
            columns: [
                {
                    name: 'streams.title.table.column.status',
                    tmpl: this.statusTmpl,
                    class: 'center'
                }, {
                    name: 'streams.title.table.column.livestream',
                    sortField: 'TITLE',
                    tmpl: this.titleTmpl,
                    class: 'left'
                },
                {
                    name: 'streams.title.table.column.price',
                    sortField: 'PRICE',
                    tmpl: this.priceTmpl,
                    class: 'right'
                },
                {
                    name: 'streams.title.table.column.live_date',
                    sortField: 'DATE',
                    tmpl: this.dateTmpl,
                    class: 'left'
                },
                {
                    name: 'streams.title.table.column.action',
                    tmpl: this.actionsTmpl,
                    class: 'center'
                }
            ]

        };
    }

    public removeVideo(videoId: string): void {
        this.loadingService.loadingStart();
        this.videoOnDemandService.deleteVideo(videoId)
            .pipe(takeUntil(this.destroy$))
            .subscribe((res) => {
                this.loadingService.loadingEnd();
                if (!res) {
                    return;
                }
                this.getVideos();
            });
    }

    public search(event: string): void {
        this.vodQuery.searchTerm = event;
        this.vodQuery.paging.page = 0;
        this.getVideos();
    }

    public filterByTimeRange(event: ITimerange): void {
        this.loadingService.loadingStart();
        this.vodQuery.timeRange = event;
        this.vodQuery.paging.page = 0;
        this.getVideos();
    }

    public gotToVodDashboard(vodItem: IMediaItem): void {
        this.router.navigate(['vod', vodItem.id, 'info']);
    }

    public goToAddNewVodPage(): void {
        this.router.navigate(['video-on-demand/new']);
    }

    public formatDate(date: Date): string {
        return DateHelper.formatDateTime(date);
    }

    public localize(key) {
        return this.localizationProvider.getByKey(key);
    }

    navigateToVideo(streamId) {
        this.router.navigate(['vod', streamId, 'info']);
    }

    public confirmDeleteVideo(video: IMediaMetadata | IMediaItem): void {
        this.confirmText = video.title + ' ' + this.localizationProvider.getByKey('publisher-dashboard.media-lists.remove-stream.confirm-body');
        const dialogRef = this.dialog.open(this.confirm);
        dialogRef.afterClosed()
            .pipe(takeUntil(this.destroy$))
            .subscribe((confirm: boolean) => {
                this.confirmText = '';
                if (confirm) {
                    this.deleteVideo(video);
                }
            });
    }

    private deleteVideo(video: IMediaMetadata | IMediaItem): void {
        this.videoOnDemandService.deleteVideo(video.id)
            .pipe(takeUntil(this.destroy$))
            .subscribe(res => {
                if (res)
                    this.table.resetTableData();
                this.table.refreshData({});
            });
    }

    public timeRangeFilter(event): void {
        this.searchStreamsTimeRange = event;
        this.table.refreshData({});
    }

    public tableSearch(event: string): void {
        this.searchQuery = event;
        this.table.refreshData({});
    }

    public searchByYear(event: string): void {
        if (event) {
            this.searchStreamsTimeRange = {
                start: moment(event).startOf('year').toDate().getTime(),
                end: moment(event).endOf('year').toDate().getTime(),
            };
        } else {
            this.searchStreamsTimeRange = null;
        }
        this.table.refreshData({});
    }
}
