import {
    Component,
    Input,
    OnInit,
} from '@angular/core';
import {IUserProfile} from '@src/app/pages/user-profile/components/profile/services/profile.service';
import {AuthService} from '@src/app/services/auth/auth.service';

@Component({
    selector: 'app-subscription-tariffs-table-en',
    templateUrl: './subscription-tariffs-table-en.component.html',
    styleUrls: ['./subscription-tariffs-table-en.component.sass']
})
export class SubscriptionTariffsTableENComponent implements OnInit {
    @Input() isShowFirstSection: boolean;
    @Input() userProfile: IUserProfile;
    @Input() displayOurOffers: boolean;
    @Input() displayTopPossibleCombinations: boolean;
    @Input() displayMarketingTools: boolean;
    @Input() displayEmbeddings: boolean;
    @Input() displayOtherFeatures: boolean;
    @Input() monthlyChargesPrices;
    @Input() monthlyChargesPricesToShow;
    @Input() feeAndTransactionPrices;
    @Input() streamProcessingPrices;
    @Input() payPerViewPrices;
    @Input() transactionPrices;
    @Input() currentTariffPeriod;
    @Input() TARIFF_PLAN_PERIOD;
    @Input() currencyForm;
    @Input() currencySelectOptions;
    @Input() INPUT_TYPES;
    @Input() selectedCurrency;

    constructor(
        public authService: AuthService
    ) {
    }

    ngOnInit(): void {
    }
}
