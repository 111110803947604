import {IPresentationMetadata} from './../../../../models/stream.model';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
    IFormConfig,
    IMultimediaHeaders,
    IPreviewPicture,
    StreamService
} from '@src/app/services/stream-metadata/stream.service';
import {filter, takeUntil} from 'rxjs/operators';
import {PhotoService} from '@src/app/services/photo/photo.service';
import {PhotoCropperService} from '@src/app/services/photo-cropper/photo-cropper.service';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import {IStreamPreviewTeaserTrailer, IStreamPreviewVideoMetadata} from '@src/app/models/stream.model';
import {VideoUploadService} from '@src/app/services/video-upload/video-upload.service';
import {LoadingService} from '@src/app/services/loading/loading.service';
import {FormBuilder, Validators} from '@angular/forms';
import {CustomValidators} from '@src/app/utils/custom-validators.util';
import {environment} from '@src/environments/environment';
import {IFileUploadResponse, IPlayerConfig} from 'ui-elements';
import {MEDIA_FORMAT, MEDIA_SUBTYPE} from '@src/app/components/streamdust-player/constants/mediaType';
import {IFileUploadingResponse, PresentationService} from '@src/app/services/file/presentation.service';
import {VideoUploadV2Service} from '@src/app/services/video-upload-v2/video-upload-v2.service';
import {HttpEventType, HttpResponse, HttpUploadProgressEvent} from '@angular/common/http';
import {IInfoPanel, InfoPanelsConfig} from '@src/app/components/info-panel/info-panel.component';

@Component({
    selector: 'app-stream-multimedia-upload',
    templateUrl: './stream-multimedia-upload.component.html',
    styleUrls: ['./stream-multimedia-upload.component.sass'],
    providers: [AutoDestroyService]
})
export class StreamMultimediaUploadComponent implements OnInit {
    @Input() textHeaders: IMultimediaHeaders;
    @Input() cardTitle: string;
    @Input() formsConfig: IFormConfig[];
    @Input() metadata: IPreviewPicture | IStreamPreviewVideoMetadata | IPresentationMetadata;
    @Input() multimediaType: 'photo' | 'video' | 'pdf';
    @Input() columnSize = 8;
    @Input() withTable = true;
    @Input() editMode = false;
    @Input() marginLeft15 = false;
    @Input() buttonsGroupBorderTop = false;
    @Input() disabled = false;
    @Input() thumbnail = false;
    public environment = environment;

    @Input() set multimediaUrl(url: string) {
        if (!url) {
            return;
        }
        this._multimediaUrl = url;
        this.refreshPlayerConfig();
    }

    get multimediaUrl(): string {
        return this._multimediaUrl;
    }

    @Input() set isVideoActive(value) {
        this.activateVideoForm.get('active').setValue(value, {onlySelf: false, emitEvent: false});
    }

    @Input() isSinglePage = true;
    @Input() isDataUploaded = false;
    @Input() actualResultText = '';
    @Input() aspectRatio = 91 / 51;
    @Output() clearMultimedia$: EventEmitter<IPreviewPicture | IStreamPreviewVideoMetadata> = new EventEmitter<IPreviewPicture | IStreamPreviewVideoMetadata>();
    @Output() updateMultimedia$: EventEmitter<IPreviewPicture | IStreamPreviewTeaserTrailer | any> = new EventEmitter<IPreviewPicture | IStreamPreviewTeaserTrailer | any>();
    @Output() uploadMultimedia$: EventEmitter<any> = new EventEmitter<any>();
    @Output() save$: EventEmitter<any> = new EventEmitter<any>();
    @Output() toggleVideoActive$: EventEmitter<boolean> = new EventEmitter<boolean>();

    directToUrlFormConfig: IFormConfig;
    previewFormConfig: IFormConfig;
    activateVideoForm = this.formBuilder.group({
        active: [false]
    });
    playerConfig: IPlayerConfig;
    private _multimediaUrl = '';
    public mediaLoaded: boolean;
    public afterUploadWarning: IInfoPanel = InfoPanelsConfig.warningAfterVideoUpload;
    isEditMode = false;

    constructor(
        private photoService: PhotoService,
        private photoCropperService: PhotoCropperService,
        private readonly destroy$: AutoDestroyService,
        private videoUploadService: VideoUploadService,
        public loadingService: LoadingService,
        private formBuilder: FormBuilder,
        private fileService: PresentationService,
        public streamService: StreamService,
        private videoUploadServiceV2: VideoUploadV2Service
    ) {
    }

    ngOnInit() {
        // this.uploadPdfFile({});

        this.directToUrlFormConfig = this.formsConfig.find(form => form.name === 'advertisingUrl');
        this.previewFormConfig = this.formsConfig.find(form => form.name === 'preview');
        this.activateVideoForm.get('active').valueChanges.pipe(takeUntil(this.destroy$)).subscribe(data => {
            this.toggleVideoActive$.emit(data);
        });
    }

    public uploadPreview(fileUploadResponse: IFileUploadResponse): void {
        if (fileUploadResponse.success) {
            if (this.multimediaType === 'photo') {
                this.uploadPictureFile(fileUploadResponse.files);
            } else if (this.multimediaType === 'pdf') {
                this.uploadPdfFile(fileUploadResponse.files);
            } else {
                this.uploadVideoFile(fileUploadResponse.files);
            }
        } else {
            if (this.previewFormConfig) {
                this.previewFormConfig.form.get('preview').setErrors({[fileUploadResponse.rejectReason]: true});
                this.previewFormConfig.form.get('preview').markAsTouched();
            }
            if (this.formsConfig) {
                this.formsConfig[0].form.get('file').setErrors({[fileUploadResponse.rejectReason]: true});
                this.formsConfig[0].form.get('file').markAsTouched();
            }
        }
    }

    uploadVideoFile(file: any) {
        if (!this.resolveFileName(file.name, 'video')) {
            this.setVideoUploadErrors();
            return;
        }

        this.loadingService.loadingStart();
        this.videoUploadServiceV2.uploadVideoTUS(file)
            // this.videoUploadServiceV2.uploadVideo(file)
            .pipe(takeUntil(this.destroy$), filter(res => !!res))
            .subscribe((id) => {
                const data = {id: id, url: this.wrapVideoUrl(id)};
                this._multimediaUrl = data.url;
                this.mediaLoaded = true;
                if (this.directToUrlFormConfig) {
                    this.directToUrlFormConfig.form.get('advertisingUrl').setValidators([Validators.required, CustomValidators.URL]);
                }
                this.uploadMultimedia$.emit(data);
                this.refreshPlayerConfig();

                this.loadingService.loadingEnd();
                this.videoUploadServiceV2.clearVideoId();
            }, () => {
                this.loadingService.loadingEnd();
                this.videoUploadServiceV2.clearVideoId();
            });
    }

    private resolveFileName(fileName: string, format: string): boolean {
        switch (format) {
            case 'video':
                return !!supportedVideoTypes.includes(fileName.split('.').pop().toLowerCase());
            case 'pdf':
                return fileName.split('.').pop().toLowerCase() === 'pdf';
            case 'picture':
                return !!supportedImageTypes.includes(fileName.split('.').pop().toLowerCase());
        }
    }

    private setVideoUploadErrors(): void {
        this.formsConfig.forEach(formConfig => {
            switch (formConfig.name) {
                case 'video':
                    formConfig.form.get('video').setErrors({unsupportedVideoFormat: true});
                    formConfig.form.get('video').markAsTouched();
                    break;
                case 'preview':
                    formConfig.form.get('preview').setErrors({unsupportedVideoFormat: true});
                    formConfig.form.get('preview').markAsTouched();
                    break;
            }
        });
    }

    private setImageUploadErrors(): void {
        const previewConfig = this.formsConfig.find(formConfig => formConfig.name === 'preview');
        previewConfig.form.get('preview').setErrors({unsupportedImageFormat: true});
        previewConfig.form.get('preview').markAsTouched();
    }

    private setFileUploadErrors(): void {
        const fileConfig = this.formsConfig.find(formConfig => formConfig.name === 'file');
        fileConfig.form.get('file').setErrors({unsupportedFileFormat: true});
        fileConfig.form.get('file').markAsTouched();
    }

    wrapVideoUrl(videoId): string {
        return (environment.backendApiHost || (window.location.protocol + '//' + window.location.host)) + '/api/v1/video/upload/' + videoId;
    }

    uploadPictureFile(file: any) {
        this.photoCropperService.completeWorkbenchReadySubject();

        if (!file) {
            return;
        }

        if (!this.resolveFileName(file.name, 'picture')) {
            this.setImageUploadErrors();
            return;
        }

        this.photoService.generateUploader({file, aspectRatio: this.aspectRatio})
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                ({croppingParams, workbenchId, croppedImage}) => {
                    this.mediaLoaded = true;
                    if (croppedImage) {
                        this._multimediaUrl = croppedImage;
                    } else {
                        const reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = () => {
                            this._multimediaUrl = reader.result.toString();
                        };
                    }
                    this.photoCropperService.completeWorkbenchReadySubject();
                    (this.metadata as IPreviewPicture).workbenchId = workbenchId;
                    (this.metadata as IPreviewPicture).previewSettings = croppingParams;
                    this.updateMultimedia$.emit(this.metadata);
                },
            );
    }

    uploadPdfFile(file: any) {
        if (!this.resolveFileName(file.name, 'pdf')) {
            this.setFileUploadErrors();
            return;
        }

        this.loadingService.loadingStart();
        this.fileService.uploadPresentationV2(file)
            .pipe(takeUntil(this.destroy$))
            .subscribe((event) => {
                this.mediaLoaded = true;
                if (event.type === HttpEventType.UploadProgress) {
                    this.updateProgress(event as HttpUploadProgressEvent);
                } else if (event instanceof HttpResponse) {
                    const res: IFileUploadingResponse = event.body;
                    this.uploadMultimedia$.emit(res);
                    this.loadingService.loadingEnd();
                }
            },
        () => {
                this.loadingService.loadingEnd();
            });
    }

    public uploadMultimedia(): void {
        if (this.directToUrlFormConfig) {
            this.directToUrlFormConfig.form.markAsPristine();
        }
        this.save$.emit();
    }

    private updateProgress(event: HttpUploadProgressEvent) {
        const progress = Math.floor(100 * event.loaded / event.total);
        this.loadingService.updateProgress(progress);
    }

    public getVideoUrl(metaData: any): string {
        return (metaData as IStreamPreviewVideoMetadata).videoUrl;
    }

    public clearMultimedia(): void {
        this._multimediaUrl = '';
        this.mediaLoaded = false;

        if (this.multimediaType === 'photo') {
            this.metadata = {
                workbenchId: '',
                previewSettings: {
                    x: 0,
                    y: 0,
                    width: 0,
                    height: 0,
                    rotation: 0
                }
            };
        } else {
            this.metadata = {
                videoUrl: '',
                advertisingUrl: '',
                views: null,
                clicks: null
            };
        }
        this.clearMultimedia$.emit(this.metadata);
    }

    private refreshPlayerConfig(): void {
        this.playerConfig = {
            thumbnailUrl: null,
            mediaSource: {
                url: this.multimediaUrl,
                type: MEDIA_FORMAT.VIDEO,
                subtype: MEDIA_SUBTYPE.MAIN
            },
            unstoppable: false,
            infoLines: null,
            overlays: null,
            courses: null,
            disabled: false,
            controlsDisabled: true
        };
    }

    changeEditMode() {
        if (this.isEditMode) {
            this.uploadMultimedia();
        }

        this.isEditMode = !this.isEditMode;
    }

    cancel() {
        this.isEditMode = false;
    }

    public editButtonText(): string {
        if (this.isEditMode) {
            return 'common.button.apply';
        }
        return 'common.button.edit';
    }
}

export enum SUPPORTED_VIDEO_TYPES {
    MP4 = 'mp4',
    MOV = 'mov',
    OGV = 'ogv',
    WEBM = 'webm'
}

export const supportedVideoTypes: string[] = [
    SUPPORTED_VIDEO_TYPES.MP4,
    SUPPORTED_VIDEO_TYPES.OGV,
    SUPPORTED_VIDEO_TYPES.WEBM
];

export enum SUPPORTED_IMAGE_TYPES {
    JPEG = 'jpeg',
    IMG = 'img',
    PNG = 'png',
    JPG = 'jpg'
}

export const supportedImageTypes: string[] = [
    SUPPORTED_IMAGE_TYPES.JPEG,
    SUPPORTED_IMAGE_TYPES.IMG,
    SUPPORTED_IMAGE_TYPES.PNG,
    SUPPORTED_IMAGE_TYPES.JPG
];



