import { Component, Input, OnChanges, OnInit, SimpleChanges, Directive } from '@angular/core';
import {Chart, ChartData, ChartOptions, ChartType} from 'chart.js';
import {IChartItem} from '@src/app/components/pie-chart/pie-chart.component';
import {COLORS} from '@src/app/utils/COLORS';


@Directive()
export abstract class ChartComponent {
    @Input() public title = 'Chart';
    @Input() public datasets: IChartItem[];
    public abstract type: ChartType;
    public options: ChartOptions;
    public data: ChartData;

    protected abstract generateConfig(): void;
}

@Component({
    selector: 'app-bar-chart',
    templateUrl: './bar-chart.component.html',
    styleUrls: ['./bar-chart.component.css']
})
export class BarChartComponent extends ChartComponent implements OnInit, OnChanges {

    public type: ChartType = 'horizontalBar';
    public barChartStatus = {isEmpty: true};

    constructor() {
        super();
    }

    ngOnChanges(): void {
        if(!this.datasets) {return;}
        this.barChartStatus.isEmpty = this.checkIsEmpty();
        if(this.barChartStatus.isEmpty) { return; }
        this.generateConfig();
    }

    ngOnInit() {
    }

    protected generateConfig(): void {
        this.data = {
            labels: this.datasets.map(i => i.name),
            datasets: [
                {
                    data: this.datasets.map(i => i.value),
                    backgroundColor: COLORS,
                    borderWidth: 0
                    // barThickness : 10,
                    // barPercentage: 0.3
                },
            ],
        };
        this.options = {
            legend: {
                display: false,
                position: 'top',
                labels: {
                    fontSize: 10,
                },
                fullWidth: false,
            },
            responsive: true,
            // tooltips: {
            //   enabled: false,
            //     intersect: false,
            //     mode: 'index'
            // },
            scales: {
                xAxes: [{
                    display: false,
                    ticks: {
                        fontSize: 10,
                        beginAtZero: true,
                        max: Math.max(...this.datasets.map(i => i.value)) + 10,
                        // maxTicksLimit: 10,
                    }


                }],
                yAxes: [{
                    ticks: {
                        fontFamily: 'Roboto, sans-serif;',
                        fontSize: 10,
                        padding: 10,
                    },
                    gridLines: {
                        display: false,
                        drawTicks: false,
                    }
                }]

            },
            animation: {
                duration: 0,
                onComplete: function () {
                    const chartInstance = this.chart,
                        ctx = chartInstance.ctx;

                    ctx.font = Chart.helpers.fontString(
                        10,
                        Chart.defaults.global.defaultFontStyle,
                        'Roboto, sans-serif;'
                    );
                    ctx.textAlign = 'center';
                    ctx.textBaseline = 'center';

                    this.data.datasets.forEach(function (dataset, i) {
                        const meta = chartInstance.controller.getDatasetMeta(i);
                        meta.data.forEach(function (bar, index) {
                            const data = dataset.data[index];
                            ctx.fillText(data, bar._model.x + 15, bar._model.y + 4);
                        });
                    });
                }
            },
        }
        ;
    }
    private checkIsEmpty() {
        let isEmpty = true;
        if(this.datasets.length) {
            isEmpty = false;
        }
        return isEmpty;
    }

}
