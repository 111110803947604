<div class="change-forgotten-password-form">
    <app-header-popup *ngIf="showPopup"
                      [opened]="showPopup"
                      [loading]="loading"
                      [withBackdrop]="true"
                      [title]="'change_forgotten_pass.form.title' | localize">
        <ng-container role="body">
            <ano-password-validator [password]="form.get('password').value"></ano-password-validator>

            <ano-form class="new-password"
                      [form]="form"
                      [fieldsConfig]="fieldsConfig"
                      [withoutSave]="true"
                      [borderForm]="true"
                      [forcedEditMode]="true"
                      [hidePasswordValidation]="true"
                      [isButtons]="false"
                      [defaultFields]="true">
            </ano-form>
        </ng-container>
        <ng-container role="footer">
            <div class="fit-width">
                <ano-button (click)="setPassword()"
                            [config]=" { styleType: 'success', sizeType: 'block'}">{{'common.button.apply' | localize}}</ano-button>
            </div>
        </ng-container>
    </app-header-popup>
</div>
